import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { UserTypes } from 'src/app/core/enums/user-types';
import { AuthResponse } from 'src/app/modules/auth/models/auth-response';
import { SubSink } from 'subsink';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  // public userId: number;

  public user: User;

  public isSuperAdmin: boolean;

  public isAdmin: boolean;

  public authInfo: AuthResponse;

  public profileModalRef: NgbModalRef;

  public reserPwModalRef: NgbModalRef;

  public tenantId:number;

  private subs: SubSink = new SubSink();

  public isCustomer:boolean=false;

  public _userId:number;

  @Input() userId:number;

  constructor(private userService: UserService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private modal: NgbModal) {

    if(!this.userId)
    this.userId = +localStorage.getItem(AppConstants.USER_ID);

    this.authInfo = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH)) as AuthResponse;

    const utype = localStorage.getItem(AppConstants.USER_TYPE);
    this.isSuperAdmin = utype === UserTypes.SuperUser;
    this.isAdmin = utype === UserTypes.Admin;
    this.isCustomer = utype === UserTypes.Customer;

    this.tenantId = +localStorage.getItem(AppConstants.CUSTOMER_ID);
  }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.subs.sink = this.userService.getById(this.userId).subscribe(resp => {
      this.user = resp;
      // if(this.user.role == 28) // check if customer contact
      // {
      //   localStorage.setItem(AppConstants.CURRENT_SUBORG, this.user.suborg_id.toString());
      // }
      console.log('usrProfile',resp)
      

      this.cdr.markForCheck();
    })
  }

  openProfileModal(content: any) {
    this.profileModalRef = this.modal.open(content, { centered: true });
  }

  openPasswordModal(content: any) {
    this.reserPwModalRef = this.modal.open(content, { centered: true });
  }

  onPasswordSubmittedSuccess(result: { response?: User, message: string }) {
    this.toastr.success('Success', result.message);
    this.reserPwModalRef?.dismiss();
  }

  onPasswordDataError(error: any) {
    this.toastr.error('Failed', error.message);
    this.reserPwModalRef?.dismiss();
  }

  onProfileSubmittedSuccess(result: { response?: User, message: string }) {
    this.toastr.success('Success', result.message);
    this.user = {...this.user,...result.response};
    this.profileModalRef?.dismiss();
  }

  onProfileDataError(error: any) {
    this.toastr.error('Failed', error.message);
    this.profileModalRef?.dismiss();
  }

  onProfileCancel() {
    this.profileModalRef?.dismiss();
  }

  submit(): void {

    let user = this.user;
    let opt = {
      title: `Are you sure you want to update settings?`,
      text: user.first_name,
      ButtonText: 'Yes',
    }
    popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.userService.patch(this.user.id, 
          { is_voicemail_send: user.is_voicemail_send ,
            is_voicemail_enabled: user.is_voicemail_enabled,
            voicemail_voice: user.voicemail_voice,
            voicemail_message: user.voicemail_message
          }).subscribe(
          resp => {this.toastr.success('Voicemail settings has been updated'); },
          () => {
            this.cdr.markForCheck();
            this.toastr.error('Failed to change status');
          }
        );
      }
      else {
       // this.selected.is_voicemail_send = !user.is_voicemail_send;
       // this.selected.is_voicemail_enabled = !user.is_voicemail_enabled;
        this.cdr.markForCheck();
      }
    });
    
  }
}
