import { DidsComponent } from './pages/dids/dids.component';
import { ForwardNumbersComponent } from './pages/forward-numbers/forward-numbers.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddComponent } from './pages/forward-numbers/add/add.component';
import { DidPurchaseComponent } from './pages/did-purchase/did-purchase.component';
import { ChatComponent } from './pages/chat/chat.component';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { RouteGuard } from 'src/app/core/gaurd/route.guard';
import { SipEndpointsComponent } from './pages/sip-endpoints/sip-endpoints.component';
import { AddSipComponent } from './pages/add-sip/add-sip.component';
import { BlockListComponent } from './pages/block-list/block-list.component';
import { TimeFramesComponent } from './pages/time-frames/time-frames.component';
import { AutomatedBlockListComponent } from './pages/automated-block-list/automated-block-list.component';
import { AddTfComponent } from './pages/time-frames/add-tf/add-tf/add-tf.component';
import { AutomatedAllowListComponent } from './pages/automated-allow-list/automated-allow-list.component';
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: 'dids',
        component: DidsComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_PHONE_NUMBERS,
            permissions: [PermissionConstants.VIEW],
            title: 'Phone Numbers'
        }
    },
    {
        path: 'incoming-routes',
        component: ForwardNumbersComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_FORWARD_NUMBERS,
            permissions: [PermissionConstants.VIEW],
            title: 'Incoming Routes'
        }
    },
    {
        path: 'incoming-routes/:sid/view',
        component: ForwardNumbersComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_FORWARD_NUMBERS,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Incoming Routes'
        }
    },
    {
        path: 'chats',
        component: ChatComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_CHAT,
            permissions: [PermissionConstants.VIEW],
            title: 'Communications'
        }
    },
    {
        path: 'incoming-routes/new',
        component: AddComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_FORWARD_NUMBERS,
            permissions: [PermissionConstants.CREATE],
            title: 'Create Incoming Route'
        }
    },
    {
        path: 'incoming-routes/:id/edit',
        component: AddComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_FORWARD_NUMBERS,
            permissions: [PermissionConstants.MODIFY],
            title: 'Edit Incoming Route'
        }
    },
    {
        path: 'reports/:tabname',
        component: ReportsComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_REPORTS,
            permissions: [PermissionConstants.VIEW],
            title: 'Reports'
        }
    },
    {
        path: 'reports',
        redirectTo: 'reports/calls'
    },
    {
        path: 'block-list',
        component: BlockListComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_BLOCK_LIST,
            permissions: [PermissionConstants.VIEW],
            title: "Block List"
        }
    },
    {
        path: 'buy-dids',
        component: DidPurchaseComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_PHONE_NUMBER_PURCHASE,
            permissions: [PermissionConstants.CREATE],
            title: "Buy Phone Numbers"
        }
    },
    {
        path: 'sip-endpoints',
        component: SipEndpointsComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_SIP_ENDPOINTS,
            permissions: [PermissionConstants.VIEW],
            title: "SIP Endpoints"
        }
    },
    {
        path: 'sip-endpoints/:id/view',
        component: SipEndpointsComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_SIP_ENDPOINTS,
            permissions: [PermissionConstants.VIEW_INFO],
            title: "SIP Endpoints"
        }
    },
    {
        path: 'sip-endpoints/new',
        component: AddSipComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_SIP_ENDPOINTS,
            permissions: [PermissionConstants.CREATE],
            title: 'Create SIP Endpoint'
        }
    },
    {
        path: 'sip-endpoints/:id/edit',
        component: AddSipComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_SIP_ENDPOINTS,
            permissions: [PermissionConstants.MODIFY],
            title: 'Edit SIP Endpoint'
        }
    },
    {
        path: 'time-frames',
        component: TimeFramesComponent,

        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_TIME_FRAME,
            permissions: [PermissionConstants.VIEW],
            title: "Time Frames"
        }
    },
    {
        path: 'time-frames/:id/view',
        component: TimeFramesComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_TIME_FRAME,
            permissions: [PermissionConstants.VIEW_INFO],
            title: "Time Frames"
        }
    },
    {
        path: 'time-frames/new',
        component: AddTfComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_TIME_FRAME,
            permissions: [PermissionConstants.CREATE],
            title: 'Create Time Frame'
        }
    },
    {
        path: 'time-frames/:id/edit',
        component: AddTfComponent,
        canActivate: [RouteGuard],
        data: {
            allowSuperAdmin: true,
            globalResource: ResourceConstants.R2V_MODULE,
            resource: ResourceConstants.R2V_TIME_FRAME,
            permissions: [PermissionConstants.MODIFY],
            title: 'Edit Time Frames'
        },
    },
    {
        path: 'automated-block-list',
        component: AutomatedBlockListComponent,
        canActivate:[RouteGuard],
        data:{
            allowSuperAdmin: true,
            resource: ResourceConstants.R2V_AUTOMATED_BLOCK_LIST,
            globalResource: ResourceConstants.R2V_MODULE,
            permissions: [PermissionConstants.VIEW,PermissionConstants.CREATE,PermissionConstants.MODIFY],
            title: "Automated Block List"
        }
    },
    {
        path: 'automated-allow-list',
        component: AutomatedAllowListComponent,
        canActivate:[RouteGuard],
        data:{
            allowSuperAdmin: true,
            resource: ResourceConstants.R2V_AUTOMATED_ALLOW_LIST,
            globalResource: ResourceConstants.R2V_MODULE,
            permissions: [PermissionConstants.VIEW,PermissionConstants.CREATE,PermissionConstants.MODIFY],
            title: "Automated Allow List"
        }
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class Ring2VoiceRoutingModule { }
