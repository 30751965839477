<div class="form-group">
    <label class="val-required">Choose where to ring</label>
    <ng-select class="form-select w-50 mb-2"
                placeholder="Select option"
                [searchable]="false"
                [(ngModel)]="model.ring_type"
                [clearable]="false" [ngModelOptions]="{standalone: true}">
        <ng-option value="Number">Forward Number(s)</ng-option>
        <ng-option value="Sip">SIP Users</ng-option>
        <ng-option value="Smart_Sip_Group">SIP User Groups</ng-option>
    </ng-select>

    <div *ngIf="model.ring_type==ringType.NUMBER">
        <tags-input placeholder="Type number and press Enter"
            mask="(000) 000-0000"
            [(value)]="model.numbers"
            [stringValue]="true"
            [insertOnLengthMatch]="true">
        </tags-input>
        <small class="text-secondary mt-1">&nbsp;* Maximum number of selection is 10</small>
    </div>
    <div *ngIf="model.ring_type==ringType.SIP">
        <app-sip-select [userAssignedSip]="true" (valueChange)="model.sip=$event" [value]="model.sip" [maxinput]="10" [suborgId]="subOrg_id"></app-sip-select>
        <small class="text-secondary mt-1">&nbsp;* Maximum number of selection is 10</small>
    </div>
    <div *ngIf="model.ring_type==ringType.SMART_SIP_GROUP">
        <user-group-select [(value)]="model.smartsipgroup_id" [smartSipGroup]="true"></user-group-select>
    </div>
    <div class="container-fluid mt-2">
        <div class="row">
            <div class="col-md-auto pl-0 d-flex align-items-center">
                <label class="val-required" for="ring_timeout">Set timeout for ring in seconds</label>
            </div>
            <div class="col d-flex align-items-end">
                <input class="form-control w-25" type="number" [max]="60" [min]="5" [(ngModel)]="model.ring_timeout" required PreventSpecialCharacters InputRangeValidator>
                <small class="text-secondary ml-1">*minimum value 5 and maximum value 60</small>
            </div>
        </div>
        <div class="row mt-2">
            <label class="val-required mr-3">What to do after timeout</label>
            <ng-select class="form-select w-25"
                placeholder="Select option"
                [searchable]="false"
                [(ngModel)]="model.after_timeout.ring_option"
                [clearable]="false" [ngModelOptions]="{standalone: true}">
                <ng-option value="Ring">Ring</ng-option>
                <ng-option value="Voicemail">Voicemail</ng-option>
                <ng-option value="Announcement">Announcement</ng-option>
                <ng-option value="Queue" disabled>Send to Queue</ng-option>
            </ng-select>
        </div>
        <!-- Voicemail -->
        <div class="row mt-1" *ngIf="model.after_timeout.ring_option==afterTimeout.VOICEMAIL">
            <div class="form-group col-12">
                <label class="val-required">Select SIP or SIP Group to receive voicemail</label>
                <div class="status-toggle-btn mb-2">
                    <label class="title mb-0 badge badge-info">SIP</label>
                    <div class="custom-switch custom-switch-activator custom-switch-secondary user_or_group custom-control">
                        <input type="checkbox" [(ngModel)]="model.after_timeout.user_or_group" [id]="toggle1" name="active" class="custom-control-input">
                        <label class="custom-control-label" [for]="toggle1">
                            <span></span>
                        </label>
                    </div>
                    <label class="title mb-0 badge badge-secondary">SIP Group</label>
                </div>
                <div *ngIf="!model.after_timeout.user_or_group">
                    <app-sip-select [userAssignedSip]="true" [multiple]="false" (valueChange)="model.after_timeout.sip_username=$event" [value]="model.after_timeout.sip_username" [suborgId]="subOrg_id"></app-sip-select>
                </div>
                <user-group-select *ngIf="model.after_timeout.user_or_group" [(value)]="model.after_timeout.user_group" [smartSipGroup]="true"></user-group-select>
            </div>
        </div>
        <!-- Ring -->
        <div class="row mt-1" *ngIf="model.after_timeout.ring_option==afterTimeout.RING">
            <div class="form-group col-12">
                <label class="val-required">Choose where to ring after timeout</label>
                <ng-select class="form-select w-50 mb-2"
                            placeholder="Select option"
                            [searchable]="false"
                            [(ngModel)]="model.after_timeout.ring_type"
                            [clearable]="false" [ngModelOptions]="{standalone: true}">
                    <ng-option value="Number">Forward Number(s)</ng-option>
                    <ng-option value="Sip">SIP Users</ng-option>
                    <ng-option value="Smart_Sip_Group">SIP User Groups</ng-option>
                </ng-select>
                <div *ngIf="model.after_timeout.ring_type==ringType.NUMBER">
                    <tags-input placeholder="Type number and press Enter"
                        mask="(000) 000-0000"
                        [(value)]="model.after_timeout.numbers"
                        [stringValue]="true"
                        [insertOnLengthMatch]="true">
                    </tags-input>
                    <small class="text-secondary mt-1">&nbsp;* Maximum number of selection is 10</small>
                </div>
                <div *ngIf="model.after_timeout.ring_type==ringType.SIP">
                    <app-sip-select [userAssignedSip]="true" (valueChange)="model.after_timeout.sip=$event" [value]="model.after_timeout.sip" [maxinput]="10" [suborgId]="subOrg_id"></app-sip-select>
                    <small class="text-secondary mt-1">&nbsp;* Maximum number of selection is 10</small>
                </div>
                <div *ngIf="model.after_timeout.ring_type==ringType.SMART_SIP_GROUP">
                    <user-group-select [(value)]="model.after_timeout.smartsipgroup_id" [smartSipGroup]="true"></user-group-select>
                </div>
            </div>
        </div>
        <!-- Announcement -->
        <div class="row mt-1" *ngIf="model.after_timeout.ring_option==afterTimeout.ANNOUNCEMENT">
            <ring2voice-announcement-settings [model]="model.after_timeout"></ring2voice-announcement-settings>
        </div>
    </div>

    <div class="container-fluid mt-1" *ngIf="model.after_timeout.ring_option==afterTimeout.RING">
        <div class="row">
            <div class="col-md-auto pl-0 d-flex align-items-center">
                <label class="val-required" for="nested_ring_timeout">Set next timeout for ring in seconds</label>
            </div>
            <div class="col d-flex align-items-end">
                <input class="form-control w-25" type="number" [max]="60" [min]="5" [(ngModel)]="model.after_timeout.ring_timeout" required PreventSpecialCharacters InputRangeValidator>
                <small class="text-secondary ml-1">*minimum value 5 and maximum value 60</small>
            </div>
        </div>
        <div class="row mt-2">
            <label class="val-required mr-3">What to do after timeout</label>
            <ng-select class="form-select w-25"
                placeholder="Select option"
                [searchable]="false"
                [(ngModel)]="model.after_timeout.nested_after_timeout.ring_option"
                [clearable]="false" [ngModelOptions]="{standalone: true}">
                <ng-option value="Voicemail">Voicemail</ng-option>
                <ng-option value="Announcement">Announcement</ng-option>
                <ng-option value="Queue" disabled>Send to Queue</ng-option>
            </ng-select>
        </div>
        <!-- Voicemail -->
        <div class="row mt-1" *ngIf="model.after_timeout.nested_after_timeout.ring_option==afterTimeout.VOICEMAIL">
            <div class="form-group col-12">
                <label class="val-required">Select SIP or SIP Group to receive voicemail</label>
                <div class="status-toggle-btn mb-2">
                    <label class="title mb-0 badge badge-info">SIP</label>
                    <div class="custom-switch custom-switch-activator custom-switch-secondary user_or_group custom-control">
                        <input type="checkbox" [(ngModel)]="model.after_timeout.nested_after_timeout.user_or_group" [id]="toggle2" name="nested" class="custom-control-input">
                        <label class="custom-control-label" [for]="toggle2">
                            <span></span>
                        </label>
                    </div>
                    <label class="title mb-0 badge badge-secondary">SIP Group</label>
                </div>
                <div *ngIf="!model.after_timeout.nested_after_timeout.user_or_group">
                    <app-sip-select [userAssignedSip]="true" [multiple]="false" (valueChange)="model.after_timeout.nested_after_timeout.sip_username=$event" 
                                    [value]="model.after_timeout.nested_after_timeout.sip_username" [suborgId]="subOrg_id">
                    </app-sip-select>
                </div>
                <user-group-select *ngIf="model.after_timeout.nested_after_timeout.user_or_group" 
                                    [(value)]="model.after_timeout.nested_after_timeout.user_group" [smartSipGroup]="true">
                </user-group-select>
            </div>
        </div>
        <!-- Announcement -->
        <div class="row mt-1" *ngIf="model.after_timeout.nested_after_timeout.ring_option==afterTimeout.ANNOUNCEMENT">
            <div class="form-group col-12">
                <ring2voice-announcement-settings [model]="model.after_timeout.nested_after_timeout"></ring2voice-announcement-settings>
            </div>
        </div>
    </div>
</div>
