import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { WINDOW } from 'src/app/core/services/window.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { DataFilterGroupRequest, DataFilterRequest, GFColumn, GFColumnTypes, GFilterGroup, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { BlockListRequest } from 'src/app/modules/ring2voice/models/block-list.model';
import { CallsDashBoardData } from 'src/app/modules/ring2voice/models/dids.models';
import { CallLog } from 'src/app/modules/ring2voice/models/reports.models';
import { BlockListService } from 'src/app/modules/ring2voice/services/block-list.service';
import { DidsService } from 'src/app/modules/ring2voice/services/dids.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { Ring2voiceService } from 'src/app/modules/ring2voice/services/ring2voice.service';
import { SipOutBoundFunctionService } from 'src/app/modules/ring2voice/services/sipoutboundfunctionservice';
import { CallCenterService } from 'src/app/modules/ring2voice/services/call-center.service';

@Component({
    selector: 'voice-mail-list',
    templateUrl: './voice-mail-list.component.html',
    styleUrls: ['./voice-mail-list.component.scss']
})
export class VoiceMailListComponent implements OnInit {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;
    @ViewChild('dataTable') dataTable: DatatableComponent;

    @Input() voice_mail_user_id: number = 0;
    @Input() is_delete: boolean = false;
    @Input() user;
    @Output() submit = new EventEmitter<any>();

    public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public rows: CallLog[] = []

    public selected: CallLog;

    public expanded: boolean = true;

    public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

    public columns: GFColumn[] = [
        { displayName: 'Name', columnName: 'contact_name', columnType: GFColumnTypes.string },
        { displayName: 'Smart Sipgroup', columnName: 'smartsipgroup', columnType: GFColumnTypes.ref },
        { displayName: 'Date', columnName: 'created_on', columnType: GFColumnTypes.date },
        //   { displayName: 'Date Between', columnName: 'date_created_between', columnType: GFColumnTypes.date_range },
        /*{ displayName: 'From Number', columnName: 'call_from', columnType: GFColumnTypes.phone },
        { displayName: 'To Number', columnName: 'call_to', columnType: GFColumnTypes.phone },
        { displayName: 'Duration', columnName: 'duration', columnType: GFColumnTypes.number },*/
        /*{
            displayName: 'Status',
            columnName: 'status',
            columnType: GFColumnTypes.select,
            optionValueType: GFColumnTypes.ref,
            options: [
                { displayName: 'Queued', value: 'queued' },
                { displayName: 'Busy', value: 'busy' },
                { displayName: 'No Answer', value: 'no-answer' },
                { displayName: 'Completed', value: 'completed' },
                { displayName: 'Canceled', value: 'canceled' },
                { displayName: 'Failed', value: 'failed' }
            ]
        },
        {
            displayName: 'Direction',
            columnName: 'direction',
            columnType: GFColumnTypes.select,
            optionValueType: GFColumnTypes.ref,
            options: [
                { displayName: 'Inbound', value: 'inbound' },
                { displayName: 'Outbound', value: 'outbound-dial' },
                { displayName: 'Automation Rules', value: 'outbound-api' } 
            ]
        }*/
    ];

    public search: GFilterParam[] = [
        {
            displayName: 'Combo Search', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Name', colname: 'contact_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
                { displayName: 'From Number', colname: 'from_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                { displayName: 'To Number', colname: 'to_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
            ]
        },
        { displayName: 'Name', colname: 'contact_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
        { displayName: 'From Number', colname: 'from_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
        { displayName: 'To Number', colname: 'to_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
    ];

    public defaults: GFilterParam[] = [
        // { displayName: "All Calls", colname: '', condition: 'is', value: null, operator: 'AND' },
        {
            displayName: "VoiceMails Today", colname: 'created_on', condition: 'date_between',
            value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            operator: 'AND', default: true
        },
        { displayName: "All Mails", colname: '', condition: 'is', value: null, operator: 'AND' }
    ];

    private backgroundClasses: string[] = ["bg-primary", "bg-success", "bg-danger", "bg-warning", "bg-info"];

    public module = ModuleConstants.RING2VOICE_CALL_REPORTS;

    public childCalls: Observable<CallLog[]>;

    public subs: SubSink = new SubSink();

    public SelectionType = SelectionType;

    public scrollbarH: boolean = false;

    public isSuperAdmin: boolean;

    public suborg_id: number;

    public permission = PermissionConstants;

    public user_id: number;

    public blockStatus: boolean;

    public sortLookup = {
        date_created: 'date_created',
        duration: 'duration',
        suborg_name: 'suborg_name',
        phone_number_name: 'phone_number_name',
        forward_number_name: 'forward_number_name'
    };

    public dashboardData: Observable<CallsDashBoardData>;

    public carddata: string;

    private currentPlayer: HTMLAudioElement;

    voices: Observable<any>;

    constructor(private cdr: ChangeDetectorRef,
        private didService: DidsService,
        private service: Ring2voiceService,
        private blockListService: BlockListService,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        public outboundService: SipOutBoundFunctionService,
        public callCenterService: CallCenterService,
        @Inject(WINDOW) private window: Window) {


    }

    ngOnInit(): void {

        this.voices = this.service.getVoices();

        let dFilter = this.defaults.filter(item => item.displayName === 'VoiceMails Today');

        this.getData2(dFilter);

    }

    ngOnChanges(changes: SimpleChanges) {

        if (this.user.voicemail_message == '' || this.user.voicemail_message == null) {

            let caller_name = this.user.first_name ? this.user.first_name : '' + this.user.last_name ? this.user.last_name : ''
            this.user.voicemail_message = `Thank you for calling ` + caller_name + `. Please leave a detailed message and i'll get back to you as soon as possible.`;
        }

        if (this.user.voicemail_voice == '' || this.user.voicemail_voice == null) {
            this.user.voicemail_voice = 'man';
        }
    }

    ngAfterViewChecked() {
        if (this.dataTable && this.dataTable.rowDetail) {
            this.dataTable.rowDetail.expandAllRows();
            this.cdr.detectChanges();
        }
    }


    getData2(filters: GFilterParam[] = null, offset: number = 0) {

        console.log(filters, 'filters');

        if (this.voice_mail_user_id > 0) {

            filters.unshift({
                "colname": "user",
                "condition": "equalto",
                "value": this.voice_mail_user_id,
                "operator": "WHEN"
            });
        }

        let request: DataFilterGroupRequest = {
            filters: filters,
            sort: this.sort
        };

        this.subs.sink = this.service.getVoiceMails(request, offset).pipe(
            catchError(err => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        return true;
        let current: CallLog = selected[0];
        if (current) {
            this.selected = current;
            this.expanded = false;
            this.refreshDataTable();
        }
    }



    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.refreshDataTable();
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData2(this.dataFilter.getDataFilters(), offset);
        this.scrollToTop()
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            let offset = event.offset * this.page.limit;
            this.page.offset = event.offset;
            this.getData2(this.dataFilter.getDataFilters(), offset);
        }
    }

    applyFilter(params: GFilterParam[]) {


        // this.getData(params); 
    }

    applyFilterGroup(params: GFilterParam[]) {

        this.getData2(params);


    }

    bulkExport() {
        let request = {
            base_filters: [],
            filters: this.dataFilter.getDataFilters(),
            sort: this.sort
        }
        //request.base_filters.unshift({ colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' });
        // request.filters.unshift({conditions:[{ colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' }],operator:'AND'});

        this.subs.sink = this.service.voiceMailExport(request).subscribe(
            resp => {
                var downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(resp);
                downloadLink.download = `Ring2Voice_${moment().format('MM-DD-YYYY-hh_mm')}.csv`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },
            () => {
                this.toastr.error("Unable to export");
            }
        )
    }

    scrollToTop() {
        this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    audioPlayerPlay(audio: HTMLAudioElement) {
        if (this.currentPlayer && this.currentPlayer != audio) {
            this.currentPlayer.pause();
        }
        this.currentPlayer = audio;
    }

    hasBinViewInfoPermission() {
        return this.ra.hasPermission(
            ResourceConstants.R2V_FORWARD_NUMBERS,
            [PermissionConstants.VIEW_INFO],
            ResourceConstants.R2V_MODULE
        );
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.R2V_BLOCK_LIST, [permission]);
    }

    invite(num: any) {
        alert('calling.....')
        //this.sipOutBoundFnService.setOutBoundNumber(num)
    }

    deleteVoiceMail(id: string) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.service.deleteVoiceMail(id).subscribe(
                    resp => {
                        if (resp.message && resp.message == 'deleted') {
                            popup.CompleteDelete('Voice Mail has been deleted.');
                            this.rows = this.rows.filter(x => x['id'] !== id);
                            this.cdr.markForCheck();
                        }
                        else {
                            this.toastr.error(resp.message);
                        }
                    },
                    () => this.toastr.error('Unable to delete Voice Mail')
                );
            }
        });
    }

    randomDpBackground(row:CallLog) {
        row['bgClass'] = row['bgClass'] || this.backgroundClasses[Math.floor(Math.random() * this.backgroundClasses.length)];
        return row['bgClass'];
    }

    //sainul
    callBack(row) {
        const noToCall = row.direction == 'inbound' ? row.call_from : row.call_to;
        this.outboundService.setOutBoundNumber(noToCall)
        this.callCenterService.popupOpenStatus.next({ isOpen: true, selectedTab: 2, })
    }
    //end 

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
