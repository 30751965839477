import { ResourceConstants } from "src/app/core/constants/resources.constants";
import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";

export class ProductDataHelper
{

    static get SearchFilters(): GFilterParam[] {
        return [
            {
                displayName: 'Name or P/N or SKU', colname: '', condition: '', value: '',
                children: [
                    { colname: 'product_name', condition: 'contains', value: '', operator: 'OR' },
                    { colname: 'product_part_number', condition: 'contains', value: '', operator: 'OR' },
                    { colname: 'sku', condition: 'contains', value: '', operator: 'OR' }
                ]
            },
            { displayName: 'Category', colname: 'category_name', condition: 'contains', value: '', gridColumn: 'Category', operator: 'AND' },
            { displayName: 'Manufacturer Name', colname: 'manufacturer_name', condition: 'contains', value: '', gridColumn: 'Manufacturer', operator: 'AND' },
            {
                displayName: 'Supplier Name',
                colname: 'supplier_name', condition: 'contains', value: '',
                resource: ResourceConstants.INV_ITEMS_SUPPLIERS,
                operator: 'AND'
            },
            {
                displayName: 'Supplier Part Number',
                colname: 'supplier_part_number', condition: 'contains', value: '',
                resource: ResourceConstants.INV_ITEMS_SUPPLIERS_PN,
                operator: 'AND'
            },
        ];
    }

    static get FilterColumns(): GFColumn[] {
        return [
            {
                displayName: 'Name',
                columnName: 'product_name', columnType: GFColumnTypes.string, gridColumn: 'Name'
            },
            {
                displayName: 'Part Number',
                columnName: 'product_part_number', columnType: GFColumnTypes.string, gridColumn: 'Part Number'
            },
            {
                displayName: 'SKU',
                columnName: 'sku', columnType: GFColumnTypes.string, gridColumn: 'SKU'
            },
            {
                displayName: 'Category',
                columnName: 'category_name', columnType: GFColumnTypes.string, gridColumn: 'Category'
            },
            // {
            //     displayName: 'Description',
            //     columnName: 'part_description', columnType: GFColumnTypes.string, gridColumn: 'Description'
            // },
            {
                displayName: 'Item Price',
                columnName: 'sell_price',
                columnType: GFColumnTypes.number,
                gridColumn: 'Price',
                resource: ResourceConstants.INV_ITEMS_PRICE
            },
            // {
            //     displayName: 'Manufacturer Name',
            //     columnName: 'manufacturer_name', columnType: GFColumnTypes.ref, gridColumn: 'Manufacturer'
            // },
            // {
            //     displayName: 'Supplier Name',
            //     columnName: 'supplier_name', columnType: GFColumnTypes.ref, resource: ResourceConstants.INV_ITEMS_SUPPLIERS
            // },
            // {
            //     displayName: 'Supplier Part Number',
            //     columnName: 'supplier_part_number',
            //     columnType: GFColumnTypes.string,
            //     resource: ResourceConstants.INV_ITEMS_SUPPLIERS_PN
            // },
            // {
            //     displayName: 'Quantity',
            //     columnName: 'quantity', columnType: GFColumnTypes.number, gridColumn: 'Quantity'
            // },
            // {displayName: 'Item Type', columnName: 'product_type', columnType: GFColumnTypes.ref, gridColumn: 'product_type'},
            // {displayName: 'Unit Type', columnName: 'unittype', columnType: GFColumnTypes.ref},
            // {displayName: 'Sales Account', columnName: 'sales_account', columnType: GFColumnTypes.ref}
        ];
    }

    static get defaults(): GFilterParam[] {
        return [
          { displayName: "All Products", colname: '', condition: 'is', value: null, operator: 'AND' },
        //   { displayName: "Active Categories", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
        //   { displayName: "Inactive Categories", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
        ];
      }

      static get SortLookup(): any {
        return {
            title: 'name',
        };
      }

}

