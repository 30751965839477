import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { CardTypes, Cards, PaymentMethods } from '../../models/payments-model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CardsService } from '../../services/cards.service';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'customers-payment-details-tab',
  templateUrl: './customers-payment-details-tab.component.html',
  styleUrls: ['./customers-payment-details-tab.component.scss']
})
export class CustomersPaymentDetailsTabComponent implements OnInit {
  @ViewChild('AddModal') addModalContent: any;
  @ViewChild('EditModal') editModalContent: any;

  @Input() customerId:number;
  @Input() customerSuborg:number;

  public subs: SubSink = new SubSink();

  public hideBanner:boolean = false;

  myCards:Cards[];

  cardTypes=CardTypes;

  selectedCardId:number;

  private modalRef: NgbModalRef;

  private modalRef1: NgbModalRef;

  paymentMethods = PaymentMethods;

  constructor(public modal: NgbModal,
    private cardServices: CardsService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.getCards();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getCards();
  }

  getCards(){

    this.subs.sink = this.cardServices.getCards(this.customerId).subscribe(
      resp => {
        //console.log(resp,'resp+')
        this.myCards = resp.card_details;
        this.cdr.markForCheck();
      },
      err => {
      }
    );

  }

  toggleBanner(){
    this.hideBanner = !this.hideBanner
    localStorage.setItem('isShowBanner',this.hideBanner.toString());
  }

  addNewCard(){
    this.modalRef = this.modal.open(this.addModalContent, { size: 'md', scrollable: true, centered: true });
  }

  editCard(cardId:number){
    this.selectedCardId = cardId; 
    this.modalRef1 = this.modal.open(this.editModalContent, { size: 'md', scrollable: true, centered: true });
  }

  deleteCard(card_id:number){

    let opt = {
      title: `Are you sure you want to Delete this card?`,
      text: '',
      ButtonText: "Yes",
  };
    popup.ConfirmWithButtonText(opt, result => {
        if (result.isConfirmed) {

          this.subs.sink = this.cardServices.deleteCard(card_id).subscribe(
            resp => {
              this.toastr.success('Card Deleted Successfully');
              this.getCards();
            },
            err => {
              this.toastr.error('error')
            }
          );
         
        }
       
    });
  }

  closeAddModal(){
    this.modalRef.close();
  }

  onAdded(){

    this.getCards();
    this.closeAddModal();
  }

  closeEditModal(){
    this.modalRef1.close();
  }

  onUpdated(){

    this.closeEditModal();
  }

  changeIsDefaultStatus(card:Cards) {

    

    let opt = {
      title: `Are you sure you want to change default status?`,
      text: '',
      ButtonText: "Yes",
  };
    popup.ConfirmWithButtonText(opt, result => {
        if (!result.isConfirmed) {
          card.is_default = !card.is_default;
            this.cdr.markForCheck();
            return;
        }
        let model = {'is_default':card.is_default}
        this.subs.sink = this.cardServices.updateCardsDefaultStatus(model, card.id).subscribe(
          resp => {
            this.getCards();
            this.toastr.success('Card Updated Successfully');
          },
          err => {
            this.toastr.error('error')
          }
        );
    });
}



}
