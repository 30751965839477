import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { ServiceTicket } from 'src/app/modules/service-ticket/models/service-ticket';
import { ServiceTicketService } from 'src/app/modules/service-ticket/services/service-ticket.service';
import { Location } from '@angular/common';
import { CustomerContact } from 'src/app/modules/tenant-customers/models/tenant-customer';
import { CallCenterService } from '../../services/call-center.service';
import { ToastrService } from 'ngx-toastr';
import { BlockListRequest } from '../../models/block-list.model';
import { BlockListService } from '../../services/block-list.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { UserStatusUpdateRequest } from '../../models/call-center.models';
import { Web } from 'sip.js';
import { addSO } from 'src/app/modules/sales/models/sales-orders.models';
import { SipOutBoundFunctionService } from '../../services/sipoutboundfunctionservice';
import swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { DataFilterGroupRequest, GFColumnTypes, GFilterGroup, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { CallLog } from '../../models/reports.models';
import * as moment from 'moment';
import { DidsService } from '../../services/dids.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { sipInboundFunctionService } from '../../services/sipinbound-function.service';


@Component({
  selector: 'app-call-center-info',
  templateUrl: './call-center-info.component.html',
  styleUrls: ['./call-center-info.component.scss']
})
export class CallCenterInfoComponent implements OnInit {

  @Input() session;

  @Input() ringtone;

  @Input() ringAudio;

  @Input() audioInputSource;

  @Input() videoSource;

  @Input() call_Details: any;

  @Input() agentDetails;

  @Input() wh_CallId;

  @Input() clearCallQ;

  @Input() isActiveCall;

  @Output() AddRejectedCallsIDs = new EventEmitter<string>();

  @Output() movetoAcceptedCallsQ = new EventEmitter<any>();

  @Output() clearEndedWhileonHoldCall: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() newPanelOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() block_id;

  @Input() iscallcenterEnabled;

  @Input() acceptedCallQ: any[] = [];

  @ViewChild('FormModal') modalContent: any;

  @ViewChild('newContactForm') newContactForm: any;

  public acceptTone = new Audio();

  public declineTone = new Audio();

  public onCall: boolean = true;

  public blockCall: boolean = false;

  public modelServiceTicket: ServiceTicket = new ServiceTicket();

  public isNewServiceTicket: boolean = false;
  public isNewSalesOrder: boolean = false;

  private subs: SubSink = new SubSink();

  public customerIms;

  public serviceTicketId: number = 0;

  public soId: number = 0;

  public bindingModel: CustomerContact = new CustomerContact();

  private modalRef: NgbModalRef;

  private modalRefNew: NgbModalRef;

  public permission = PermissionConstants;

  onGoingStMap = new Map<String, ServiceTicket>();

  onGoingSoMap = new Map<String, addSO>();

  onGoingST_OpenState = new Map<String, boolean>()

  onGoingSO_OpenState = new Map<String, boolean>()

  public modelSo: addSO = new addSO();

  public sort: GSortParam[] = [{ colname: 'date_created', direction: 'desc' }];

  public callHistoryList: { count: number, data: CallLog[] } = { count: 0, data: [] }

  public showCallHistory: boolean = false;

  page: Pagination = { count: 0, limit: 5, offset: 0, pageNumber: '0-0' };

  public from_Number_name: string;

  constructor(
    public location: Location,
    public modal: NgbModal,
    private service: ServiceTicketService,
    private toastr: ToastrService,
    public callCenterService: CallCenterService,
    public blockListService: BlockListService,
    private cdr: ChangeDetectorRef,
    public sipOutBoundFnService: SipOutBoundFunctionService,
    private didService: DidsService,
    private ra: ResourceAccessService,
    private phoneNumberPipe: PhoneNumberPipe,
    public sipInboundFnService: sipInboundFunctionService,
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes?.call_Details?.currentValue.call_from != changes.call_Details?.previousValue?.call_from) {
      //console.log('changes');
      this.checkOnGoingStSo(changes.call_Details?.previousValue?.call_from, changes.call_Details?.currentValue?.call_from);

    }
  }

  getCallLogHistory(filters: GFilterGroup[] = [], offset: number = 0) {

    let historyFilter: GFilterParam[] = [
      {
        displayName: "Calls This Week", colname: 'date_created_between', condition: 'date_between',
        value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
        operator: 'AND'
      },
      {
        displayName: "From Number",
        colname: "call_from",
        condition: "is",
        value: this.call_Details.call_to,
        operator: "OR",
        type: GFColumnTypes.phone
      },
      {
        displayName: "To Number",
        colname: "call_to",
        condition: "is",
        value: this.call_Details.call_to,
        operator: "OR",
        type: GFColumnTypes.phone
      }
    ]

    filters.push({ 'conditions': historyFilter, 'operator': 'AND' })

    let request: DataFilterGroupRequest = {
      base_filters: [],
      filters: filters,
      sort: this.sort
    };
    this.subs.sink = this.didService.getCallReport(request, offset).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {

      this.callHistoryList.data = response.result;
      this.callHistoryList.count = response.count;
      this.showCallHistory = true
      this.cdr.markForCheck()
    });

  }

  openModal(content: any) {
    this.modal.open(content, { scrollable: true, size: 'xl', backdrop: false });
  }

  pickkCall() {//pickup incoming call

    if (this.checkOutboundCallIsActive() == false) {
      var options = {
        'mediaConstraints': {
          'audio': { deviceId: this.audioInputSource ? { exact: this.audioInputSource } : undefined }
          , 'video': { deviceId: this.videoSource ? { exact: this.videoSource } : undefined }
        }
        // 'pcConfig': {
        // 'rtcpMuxPolicy': 'require',
        // 'iceServers': [ { 'urls': ["stun:stun.l.google.com:19302", "stun:stun1.l.google.com:19302"] } ]
        // },
      };
      if (this.session.state == 'Initial') {
        this.clearCallQ = false;
        this.stopRing();
        // this.callAcceptTone(); //moved to parent component
        this.session.accept(options);
        this.movetoAcceptedCallsQ.emit(this.session);
        localStorage.setItem('isOnCall', 'true');
        this.checkIsAlwaysOpenAddContact();
        this.cdr.markForCheck();
      }
    }
  }

  checkOutboundCallIsActive() {

    if (this.sipOutBoundFnService.isOutgoingCall) {
      const outboundSession = this.sipOutBoundFnService.currentCallDetails.active_CallerSession;

      if (outboundSession.state == 'Initial' || outboundSession.state == 'Establishing') {
        this.toastr.error('Please stop outgoing call');
        return true
      }
      else if (outboundSession.state == 'Established' && outboundSession.customValues.isCallOnHold == false) {
        this.toastr.error('Please hold current outgoing call');
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }

  showAcceptButton(session) { //condition for showing Accept button
    const isCallOnHold = this.acceptedCallQ?.some(session => session.customValues?.isCallOnHold);
    if (!session.request.headers['X-Call-Status'] && ((session.state == 'Initial' || session.state == 'Establishing') && !this.isActiveCall)) {
      return true;
    } else if (!session.request.headers['X-Call-Status'] && (session.state == 'Initial' && this.isActiveCall && isCallOnHold)) {//check any call on hold.
      return true;
    } else {
      return false;
    }
  }

  showHoldButton(session) { //condition for showing Hold button
    return (session.state == 'Established' || session.customValues.isCallOnHold) && this.wh_CallId;
  }

  hideUnholdButton(session) {
    const isActiveCall = this.acceptedCallQ?.some(session => session.state == 'Established');
    return session.customValues.isCallOnHold && isActiveCall
  }

  showMuteButton(session) { //condition for showing end button
    return session.state == 'Established';
  }

  showEndButton(session) { //condition for showing end button
    return session.state == 'Established' || session.customValues.isCallOnHold;
  }

  showRejectButton(session) { //condition for showing end button
    return !session.request.headers['X-Call-Status'] && (session.state == 'Initial' || session.state == 'Establishing');
  }

  rejectCall(session) { //hangup ongoing call
    this.AddRejectedCallsIDs.emit(this.session.id);
    if (session.state == 'Initial') {
      session.reject().then(() => {
        this.callDeclineTone();
      }).catch(() => {
        alert('Failed to reject call')
      })
    }
  }

  endCall(session) { //hangup ongoing call

    if (session.state == 'Established') {
      session.bye().then(() => {
        this.callDeclineTone();
      }).catch(() => {
        alert('Failed to end call')
      })
    } else {
      let payload = {
        call_sid: this.call_Details.call_id,
        to_number: this.call_Details.call_to
      };
      this.subs.sink = this.callCenterService.endCallFromHold(payload).subscribe(response => {
        session.customValues.isCallOnHold = false;
        this.clearEndedWhileonHoldCall.emit(false);
      },
        (error) => {
          this.toastr.error("Failed to end call");
        });
    }
  }

  muteCall() {
    this.sipInboundFnService.isMuted = !this.sipInboundFnService.isMuted;
    this.cdr.markForCheck();
    let peer = this.session.sessionDescriptionHandler.peerConnection;
    let senders = peer.getSenders();
    if (!senders.length) return;
    let that = this;
    senders.forEach(function (sender) {
      if (sender.track) sender.track.enabled = !that.sipInboundFnService.isMuted;
    });
  }

  public holdCall() {
    const re_value = !this.session.customValues.isCallOnHold;

    if (!re_value) // if unhold call
    {
      if (this.checkOutboundCallIsActive() == true) {
        return
      }
    }
    this.session.customValues.isCallOnHold = re_value;


    if (!re_value) {
      this.session.customValues.isOnGoingSt = false;
      this.session.customValues.isOnGoingSo = false;
      this.session.customValues.isUnholded = true;
    }



    const options = {
      sessionDescriptionHandlerModifiers: this.session.customValues.isCallOnHold ? [Web.holdModifier] : []
    }
    if (this.session.state == 'Established') {
      this.session.invite(options).then(() => {
        console.log(`Call ${this.session.customValues.isCallOnHold ? 'held' : 'unheld'}.`)
        if (this.session.customValues.isCallOnHold) {//put on hold
          let payload = {
            call_sid: this.call_Details.call_id,
            to_number: this.call_Details.call_to
          };
          this.subs.sink = this.callCenterService.putCallOnHold(payload).subscribe(response => {
            console.log('Response onhold', response);

          },
            (error) => {
              console.log("Error occurred");
            });
        }
        this.session.customValues.isCallOnHold = re_value;
      })
        .catch((error) => {
          console.error(`Failed to ${this.session.customValues.isCallOnHold ? 'hold' : 'unhold'} call. Error:`, error);
        });
    } else {//release from hold
      if (!this.session.customValues.isCallOnHold) {
        let payload = {
          call_sid: this.call_Details.call_id,
          to_number: this.iscallcenterEnabled ? this.call_Details.call_to : this.session.request.headers['X-Dialed-Number'][0].raw,
          // to_number: this.session.request.headers['X-Dialed-Number'][0].raw,
          sip_username: 'sip:' + this.agentDetails.sip_uri,
          call_center_enabled: this.iscallcenterEnabled
        };
        this.subs.sink = this.callCenterService.releaseCallFromHold(payload).subscribe((response) => {
          console.log('releare Hold', response);

          this.callCenterService.isOnGoingStForHold = this.isNewServiceTicket;
          this.callCenterService.isOnGoingSoForHold = this.isNewSalesOrder;

          if (response.call_ended) {

            if (this.isNewServiceTicket || this.isNewSalesOrder) {
              this.session.customValues.isUnholded = false;
              this.session.customValues.isOnGoingSt = this.isNewServiceTicket;
              this.session.customValues.isOnGoingSo = this.isNewSalesOrder;
            } else {
              this.clearEndedWhileonHoldCall.emit(response.call_ended);
            }

            this.toastr.warning('Caller hung up');
          }
        },
          (error) => {
            console.log('error', error);

          });
        console.log(this.session.customValues, "value after function");
      }
    }
  }

  // callAcceptTone(){ //moved to parent component
  //   this.acceptTone.src = "../../../../assets/ringtone/pickup tone.mp3"
  //   this.acceptTone.play();
  // }

  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play();
  }

  stopRing() {
    // clearInterval(this.ringtone);
    this.ringAudio.pause()
  }

  autoOpenToggled() {
    let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    payload.auto_open = this.callCenterService.isalwaysOpen;

    this.callCenterService.update(payload).subscribe(responds => {
      if (responds['auto_open'] == true) {
        this.toastr.success("Call log will open automatically when a call comes")
      } else {
        this.toastr.warning("Call log will not open automatically when a call comes")
      }
    },
      () => {
        this.toastr.error("Failed to change status")
        this.callCenterService.isalwaysOpen = !this.callCenterService.isalwaysOpen;
        this.cdr.markForCheck();
      })

  }


  autoOpenAddContactToggled() {
    let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    payload.auto_open_add_contact = this.callCenterService.isalwaysOpenAddContact;

    this.callCenterService.update(payload).subscribe(responds => {
      if (responds['auto_open_add_contact'] == true) {
        this.toastr.success("Add New Contact Popup will automatically open when a call is accepted")
      } else {
        this.toastr.warning("Add New Contact Popup will not automatically open when a call is accepted")
      }
    },
      () => {
        this.toastr.error("Failed to change status")
        this.callCenterService.isalwaysOpen = !this.callCenterService.isalwaysOpen;
        this.cdr.markForCheck();
      })

  }

  addToBlockList(): void {
    let payload = new BlockListRequest();
    payload.number = Number(this.call_Details.call_from);
    payload.created_user = this.blockListService.userId;

    let opt = {
      title: `Are you sure you want to Block?`,
      text: '',
      ButtonText: "Yes",
    }
    popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.blockListService.create(payload).subscribe(
          resp => {
            if (resp.status) {
              this.block_id = resp.id;
              this.blockCall = true;
              this.toastr.success("Number Blocked successfully");
              this.cdr.markForCheck();
            } else if (resp.Message) {
              this.toastr.info(resp.Message);
              if (resp.Message == 'Number Already Added as a Customer Contact') {
                this.blockCall = false;
              }
            }
          },
          () => {
            this.toastr.error('Failed to change status');
            this.blockCall = !this.blockCall;
            this.cdr.markForCheck();
          });
      } else {
        this.blockCall = !this.blockCall;
        this.cdr.markForCheck();
      }
    });
  }

  unblockNumber() {
    let opt = {
      title: `Are you sure you want to Unblock?`,
      text: '',
      ButtonText: "Yes",
    }
    popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
        this.blockListService.delete(this.block_id).subscribe(
          resp => {
            if (resp.status) {
              this.blockCall = false;
              this.toastr.success(`Number Unblocked Successfully`);
              this.block_id = null;
              this.cdr.markForCheck();
            }
          },
          error => {
            this.toastr.error("Failed to Unblock Phone Number")
          }
        )
      };
    });
  }

  addNewServiceTicket() {

    if (this.hasStPermission(PermissionConstants.CREATE)) {


      this.isNewSalesOrder = false;
      this.from_Number_name = this.phoneNumberPipe.transform(this.call_Details.call_from) + ' - ' + this.call_Details.contact_name;


      this.onGoingST_OpenState.set(this.call_Details.call_from, true)
      let st_q = this.onGoingStMap.get(this.call_Details.call_from)
      if (st_q) {
        this.modelServiceTicket = st_q;
        this.onGoingStMap.delete(this.call_Details.call_from)
      }
      else {
        this.modelServiceTicket = this.service.createModel(ServiceTicket);
        this.modelServiceTicket.tenant_customer = this.call_Details.tenant_customer_id;
        this.modelServiceTicket.tenant_customer_contact = this.call_Details.contacts_id;
        this.modelServiceTicket.tenant_customer_location = this.call_Details.location_id;

        this.modelServiceTicket.call_to_sid = this.call_Details.call_to_sid;
        this.modelServiceTicket.call_to_did = this.call_Details.call_to;
        this.modelServiceTicket.call_from = this.call_Details.call_from;
        this.modelServiceTicket.suborg = this.call_Details.call_to_suborg_id;
        this.modelServiceTicket.reason_for_call = null
        this.modelServiceTicket.task = null
      }


      this.serviceTicketId = 0;
      this.isNewServiceTicket = true;
      this.session.customValues.isOnGoingSt = true;
      this.session.customValues.isOnGoingSo = false;
      this.newPanelOpened.emit(true)
    } else {
      this.toastr.error("You don't have permission to create Service Ticket!")
    }


  }

  afterServiceSTSave() {
    this.onGoingStMap.delete(this.call_Details.call_from)
    this.onGoingST_OpenState.delete(this.call_Details.call_from)
    this.modelServiceTicket = null
    this.isNewServiceTicket = false
    this.session.customValues.isOnGoingSt = false;
  }

  closeNewPanels() {

    if (this.modelServiceTicket && this.isNewServiceTicket) {
      this.onGoingStMap.set(this.call_Details.call_from, this.modelServiceTicket);
      this.onGoingST_OpenState.set(this.call_Details.call_from, false)
    }

    if (this.modelSo && this.isNewSalesOrder) {
      this.onGoingSoMap.set(this.call_Details.call_from, this.modelSo);
      this.onGoingSO_OpenState.set(this.call_Details.call_from, false)
    }

    this.isNewSalesOrder = false;
    this.isNewServiceTicket = false;
    this.showCallHistory = false;

    this.session.customValues.isOnGoingSt = false;
    this.session.customValues.isOnGoingSo = false;

    this.clearEndedWhileonHoldCall.emit();
    this.newPanelOpened.emit(false)
  }



  addNewSalesOrder() {

    if (this.hasSoPermission(PermissionConstants.CREATE)) {

      this.isNewServiceTicket = false;

      this.onGoingSO_OpenState.set(this.call_Details.call_from, true)
      let so_q = this.onGoingSoMap.get(this.call_Details.call_from)
      if (so_q) {
        this.modelSo = so_q;
        this.onGoingSoMap.delete(this.call_Details.call_from)
      }
      else {
        this.modelSo = new addSO();
      }

      this.soId = 0;
      this.isNewSalesOrder = true;
      this.session.customValues.isOnGoingSo = true;
      this.session.customValues.isOnGoingSt = false;
      this.newPanelOpened.emit(true)
    } else {
      this.toastr.error("You don't have permission to create Sales Order!")
    }
  }

  afterSalesSOSave() {
    this.onGoingSoMap.delete(this.call_Details.call_from)
    this.onGoingSO_OpenState.delete(this.call_Details.call_from)
    this.modelSo = null
    this.isNewSalesOrder = false
    this.session.customValues.isOnGoingSo = false;
  }

  closeAddSalesOrder() {

    this.isNewServiceTicket = false;
    this.isNewSalesOrder = false;

  }

  documentEdit(document) {

    if (document.type == 'SERVICE TICKET') {

      this.isNewSalesOrder = false;

      this.serviceTicketId = document.id;
      this.subs.sink = this.service.getById(this.serviceTicketId).subscribe(serviceTicket => {
        this.modelServiceTicket = serviceTicket;
        this.isNewServiceTicket = true;

      });

    }

    if (document.type == 'SALES ORDER') {

      this.isNewServiceTicket = false;

      this.soId = document.id;
      this.isNewSalesOrder = true;

    }

  }

  openForm() {
    this.modalRef = this.modal.open(this.newContactForm, { size: 'lg', scrollable: true, centered: true });
  }

  openFormNew() {
    this.modalRefNew = this.modal.open(this.newContactForm, { size: 'lg', scrollable: true, centered: true });
  }

  openModal1() {
    this.modalRef = this.modal.open(this.modalContent, { size: 'lg', scrollable: true, centered: true });
  }



  onDataSubmittedSuccess(result: { response: CustomerContact, message: string }): void {

    if (this.call_Details.contacts_id == null && result.response.id) {
      console.log(result.response.id, 'res.resp')
      this.call_Details.contacts_id = result.response.id;
    }
    this.toastr.success('Customer Contact Updated.');
    this.closeModal1();
    this.modalRefNew?.dismiss();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal1();
  }

  onCancel(): void { this.closeModal1(); }

  onCancelNew(): void { this.modalRefNew?.dismiss(); }

  closeModal1() { this.modalRef?.dismiss(); }


  sortFunc(a, b) {
    if (a.created_on < b.created_on) {
      return -1;
    }
    if (a.created_on > b.created_on) {
      return 1;
    }
    return 0;
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.R2V_BLOCK_LIST, [permission]);
  }

  hasStPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.SERVICE_TICKETS, [permission], ResourceConstants.SERVICE_TICKET_MODULE);
  }

  hasSoPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.SALES_SALES_ORDERS, [permission], ResourceConstants.SALES_MODULE);
  }

  editContact(contact_id: number) {

    this.subs.sink = this.callCenterService.getContactDetails(contact_id).subscribe(data => {
      this.bindingModel = data;


      this.subs.sink = this.callCenterService.getContactDetails(contact_id).subscribe(data => {
        this.bindingModel = data;
      
        if(this.call_Details.contacts_id == null){

            console.log(this.bindingModel, 'this.bindingModel')
            if (this.bindingModel.phone == '' || this.bindingModel.phone == null) {
              this.bindingModel.phone = this.call_Details?.call_from;
            } else if (this.bindingModel.mobile == '' || this.bindingModel.phone == null) {
              this.bindingModel.mobile = this.call_Details?.call_from;
            } else {
              this.toastr.error('Mobile and phone numbers are already exist.Please copy paste Incoming number to any of these field to update')
            }
          }

          this.openModal1();
        },
          (error) => {
            this.toastr.error("Error occurred");
          });
      });
    }

  checkOnGoingStSo(prev_call_id, current_call_id) {


    if (this.isNewServiceTicket) {

      this.onGoingStMap.set(prev_call_id, this.modelServiceTicket);

      this.closeAddSalesOrder();
    }
    if (this.isNewSalesOrder) {

      this.onGoingSoMap.set(prev_call_id, this.modelSo);

      this.closeAddSalesOrder();
    }

    let st_q = this.onGoingStMap.get(current_call_id)
    let st_open_state = this.onGoingST_OpenState.get(current_call_id)

    if (st_q && st_open_state == true) {
      this.modelServiceTicket = st_q;
      this.isNewServiceTicket = true;
      this.onGoingStMap.delete(current_call_id)
    }

    let so_q = this.onGoingSoMap.get(current_call_id)
    let so_open_state = this.onGoingSO_OpenState.get(current_call_id)

    if (so_q && so_open_state == true) {
      this.modelSo = so_q;
      this.isNewSalesOrder = true;
      this.onGoingSoMap.delete(current_call_id)
    }


  }

  checkIsAlwaysOpenAddContact() {

    if (this.callCenterService.isalwaysOpenAddContact && !this.call_Details?.contacts_id) {
      this.openFormNew();
    }
  }

  openHistory() {

    if (!this.showCallHistory) {
      this.getCallLogHistory()
    }
    else {
      this.showCallHistory = false
    }
    this.isNewSalesOrder = false;
    this.isNewServiceTicket = false;
    this.newPanelOpened.emit(true)
  }
  onServiceTicketCancel() {
    this.isNewServiceTicket = false;
  }

  onSalesOrderCancel() {
    this.isNewSalesOrder = false
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
