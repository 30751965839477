<div class="div">
    <div class="row no-gutters" *ngIf="sipOutBoundFnService.outBoundShowType == 'dialer' ">
        <div class="d-flex mt-2">
            <div class="dialer">
                <ng-container *ngTemplateOutlet="diallerTemplate"></ng-container>
            </div>
            <div class="pl-2">
                <div class="report-container">
                    <div class="mt-1">

                        <div class="report-type-box mr-2">

                            <a class="type-switch missed-tab" (click)="outActiveSubTab=1"
                                [ngClass]="{'active-inbound' : outActiveSubTab == 1}">
                                <img src="../../../../assets/img/caller assets/missed.png" alt=""> Missed Calls
                            </a>
                            <a class="type-switch contact-tab" (click)="outActiveSubTab=2"
                                [ngClass]="{'active-outbound' : outActiveSubTab == 2}">
                                <img src="../../../../assets/img/caller assets/contacts.png" alt=""> Contacts
                            </a>
                        </div>
                        <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" [(activeId)]="outActiveSubTab"
                            class="nav-tabs out-sub-nav">
                            <li [ngbNavItem]="1">
                                <ng-template ngbNavContent>
                                    <app-call-reports [showCount]="false" [showGridFilter]="false"
                                        [enableViewDetails]="false" [defaultFilter]="basicLogFilter"
                                        [rows2]="noAnswerLogList" [tableHeight]="dynamicHeight-245" [page]="page">
                                    </app-call-reports>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2" (click)="outActiveSubTab=2">
                                <ng-template ngbNavContent>
                                    <ng-container *ngTemplateOutlet="ccTemplate"></ng-container>
                                </ng-template>
                            </li>
                        </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>

                    </div>
                </div>

            </div>
        </div>

    </div>

    <div
        *ngIf="sipOutBoundFnService.currentCallDetails.active_CallerSession && sipOutBoundFnService.outBoundShowType == 'details'">

        <app-outbound-call-center-info [audioInputSource]="selectedAudioInput"
            [session]="sipOutBoundFnService.currentCallDetails.active_CallerSession"
            [wh_CallId]="sipOutBoundFnService.currentCallDetails.callID"
            [call_Details]="sipOutBoundFnService.currentCallDetails.contactDetails"
            [agentDetails]="callcenterSharedService.sip_details" [iscallcenterEnabled]="iscallcenterEnabled"
            [acceptedCallQ]="sipOutBoundFnService .acceptedCallQ"
            (clearEndedWhileonHoldCall)="clearCompletedCallsfromCallQ($event)"
            (newPanelOpened)="onNewPanelOpened($event)">
        </app-outbound-call-center-info>
    </div>


    <ng-template #ccTemplate>
        <customer-contacts style="max-width:860px;display:block" #customerContact [newButton]="false"
            [advanceFilter]="false" [showFilter]="true" [tableHeight]="dynamicHeight-320" [enableCall]="true"
            [columnsToShow]="['first_name','tenant_customer_name_name','phone','mobile']" [readOnly]="true"
            (addForCall)="addForCall($event)" (onSearch)="selectedLetter='ALL'">
        </customer-contacts>

    </ng-template>

    <ng-template #diallerTemplate>
        <call-center-dialer #dialler (inputChange)="searchInContacts($event, customerContact)">

        </call-center-dialer>
    </ng-template>

</div>