import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat } from 'lodash';
import { GFColumn, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { InvoiceDataHelper } from 'src/app/modules/sales/helpers/invoice-data.helper';
import { InvoiceList, InvoiceStatus } from 'src/app/modules/sales/models/invoice.models';
import { InvoiceService } from 'src/app/modules/sales/services/invoice-service';
import { SalesService } from 'src/app/modules/sales/services/sales.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'customer-info-invoice',
  templateUrl: './customer-info-invoice.component.html',
  styleUrls: ['./customer-info-invoice.component.scss']
})
export class CustomerInfoInvoiceComponent implements OnInit {


  @Input() set customerId(value:number){
    this._customerId=value;
  }

  @Input() showNewButton : boolean = true;

  @Input() uniqueId : number = 0;

  @Input() filter: GFilterParam[] = []

  public _customerId;

  public invoices:InvoiceList[]=[];

  public subs:SubSink=new SubSink();

  public search:string='';

  public selected: InvoiceList= null


  public searchData: GFilterParam[] = InvoiceDataHelper.search[0].children;

  public searchOffset=0;


  public resultCount:number=0;

  public resultTotalCount:number=0;

  public customerContact:number;

  public customerLocation:number;

  public showFilter:boolean;

  public filterActive:boolean=false;

  public soStatus=InvoiceStatus;

  public shippingMethod:number;

  public statusList:any=[];

  public columns: GFColumn[] = InvoiceDataHelper.columns;

  public status:number;


  constructor(
    private cdr:ChangeDetectorRef,
    private invoiceService:InvoiceService,
    private modalService:NgbModal) { }

  ngOnInit(): void {

    this.getInvoices();

    this.getStatusList()
  }

  ngOnChanges(changes: SimpleChanges): void {

      if(changes&&changes.uniqueId&&changes.uniqueId.currentValue && changes.uniqueId.previousValue && changes.uniqueId.currentValue != changes.uniqueId.previousValue)
        this.getInvoices();
      
  }

  getInvoices(plainSearch=false, loadMore=false){

    let request={sort:[{colname: "created_on", direction: "desc"}], filters:null};

    let searchData = [];

    let tmp;

    if(this.search&&this.search!=''){
      this.searchData.forEach((val, index)=>{
        tmp={...val};
        tmp.value=this.search.toString();
        searchData.push(tmp);
      })
    }
    searchData = searchData.concat(this.filter)
    if(plainSearch==false){


      if(this.customerContact){
        searchData.push({colname: 'tenant_customer_contact', condition: 'equalto', value: this.customerContact, operator: 'AND' });
      }

      if(this.customerLocation){
        searchData.push({colname: 'tenant_customer_location', condition: 'equalto', value: this.customerLocation, operator: 'AND' });
      }

      if(this.shippingMethod){
        searchData.push({colname: 'shipment_method', condition: 'equalto', value: this.shippingMethod, operator: 'AND' });
      }

      if(this.status){
        searchData.push({colname: 'status', condition: 'equalto', value: this.status, operator: 'AND' });
      }


    }

    searchData.push({colname: 'tenant_customer', condition: 'equalto', value: this._customerId, operator: 'WHEN' });

    if(loadMore){
      this.searchOffset+=50;
    }

    request.filters=searchData;

   this.subs.sink=this.invoiceService.getAll(request).subscribe(
      response=>{
          this.invoices=response.result;

          if(loadMore){
            this.invoices=response.result=concat(response.result, this.invoices);
          }
          else{
            this.invoices=response.result;

          }

          this.checkFilterActive();

          this.resultTotalCount=response.count;

          this.resultCount=response.result.length;

          this.cdr.markForCheck();
      }
    );


  }

  getStatusList(){
    this.columns.forEach((val, index)=>{
      if(val.displayName=='Status'){

        this.statusList=val.options;
      }
    })
  }

  customerContactChange(event){

    if(event&&event.id){
      this.customerContact=event?.id;
    }
    else{
      this.customerContact=null;
    }


  }

  customerLocationChange(event){

    if(event&&event.id){
      this.customerLocation=event?.id;

    }
    else{
      this.customerLocation=null;
    }

  }

  shippingMethodChange(event){

    if(event&&event.id){
      this.shippingMethod=event?.id;

    }
    else{
      this.shippingMethod=null;
    }
  }

  resetFilter(){

    this.customerContact=null;
    this.customerLocation=null;
    this.search='';
    this.showFilter=false;
    this.shippingMethod=null;
    this.status=null;

    this.getInvoices(false);

    this.checkFilterActive();



  }


  checkFilterActive(){

    if(this.customerContact||this.customerLocation||this.shippingMethod||this.status){
      this.filterActive=true;
    }
    else{
      this.filterActive=false;
    }
  }

  toggleFilter(){
    this.showFilter=!this.showFilter;
    this.cdr.markForCheck();
  }

  statusClass(id: number) {

    let status = {
                    "8": "status-new",
                    "1": "status-draft",
                    "2": "status-send-customer",
                    "3": "status-unpaid",
                    "4": "status-paid-full",
                    "5": "status-partially-paid",
                    "6": "status-void",
                    "7": "status-write-off",
                  };

    return status[id];

  }

  openInvoiceView(modal_content: any){
    this.modalService.open(modal_content, { centered: true, scrollable: true, size: "xl" })
  }

  onCloseInvoiceView(){
    this.modalService.dismissAll()
  }


  ngOnDestroy(){

    this.subs.unsubscribe();

  }

}
