import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AccountType, Cards, PaymentMethods } from '../../models/payments-model';
import { CardsService } from '../../services/cards.service';
import { SubSink } from 'subsink';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {
  @Input() customerId: number;
  @Input() customerSuborg: number;
  @Input() cardId:number;
  @Output() cancel = new EventEmitter<any>();
  @Output() success = new EventEmitter<any>();

  public subs: SubSink = new SubSink();
  model: Cards;
  paymentMethods = PaymentMethods;
  selected_payment_method:string = this.paymentMethods.CARD;
  AccountTypes = AccountType;
  

  constructor(private cardServices: CardsService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
   
    this.model = new Cards();
    if(this.cardId){
      this.getCardDetails(this.cardId)
    }
  }

  getCardDetails(cardId:number){

    this.subs.sink = this.cardServices.getCardDetailsById(cardId).subscribe(
      resp => {

        this.model.card_number = resp.masked_card_number;
        this.model.expiry_date = resp.expiry_date;
        this.model.name = resp.name;
        this.model.zip = resp.zip;
        this.model.account = resp.masked_account_number;
        this.model.account_type = resp.account_type;
        this.model.routing = resp.masked_routing_number;
        this.selected_payment_method = (resp.payment_type==this.paymentMethods.CARD)?resp.payment_type:this.paymentMethods.ACH;
       console.log(resp,'resp+')
      },
      err => {
        this.toastr.error('error')
      }
    );

  }

  submit() {

    this.model.suborg = this.customerSuborg;
    
    if(this.cardId){

      this.subs.sink = this.cardServices.updateCard(this.model, this.cardId).subscribe(
        resp => {
          this.toastr.success('Details Updated Successfully');
          this.success?.emit();
        },
        err => {
          this.toastr.error('error')
        }
      );

    }else{

    this.subs.sink = this.cardServices.addCard(this.model, this.customerId).subscribe(
      resp => {
        this.toastr.success('Details Added Successfully');
        this.success?.emit();
      },
      err => {
        //console.log(err,'err+')
        this.toastr.error(err?.error?.message)
      }
    );

    }

  }

  onCancelClicked(): void {
    this.cancel?.emit();
  }

  clrReq(){

    if(this.selected_payment_method == this.paymentMethods.CARD){
        this.model.name = null;
        this.model.zip = null;
        this.model.account = null;
        this.model.account_type = null;
        this.model.routing = null;

    }else{
      this.model.card_number = null;
      this.model.expiry_date = null;
      this.model.name = null;
      this.model.zip = null;
    }
  }

  changePaymentMethode(methode:string){

    this.selected_payment_method = methode;
    this.clrReq();
 
  }

}
