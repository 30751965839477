<div class="select-wrapper">
    <ng-select
               placeholder="select or search Incoming Routes"
               [appendTo]="'body'"
               [items]="options | async"
               bindLabel="name"
               bindValue="sid"
               [(ngModel)]="selected"
               [readonly]="readonly"
               [typeahead]="textInput"
               [minTermLength]="2"
               [trackByFn]="trackByFn"
               typeToSearchText="Please enter 2 or more characters"
               (open)="selectOpen()"
               (clear)="onClear()">
            <ng-template ng-header-tmp>
                <div class="custom-option custom-header">
                    <div class="column width-40-per">Name</div>
                    <div class="column width-30-per">Number</div>
                    <div class="column width-30-per" *ngIf="isGlobal_Route">Suborg</div>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <div class="custom-option">
                    <div class="column width-40-per" style="padding-right: 3px;">
                        {{item.name}}
                    </div>
                    <div class="column width-30-per" style="padding-left: 3px;" *ngIf="item.forward_number">
                        {{item.forward_number | phoneNumber }}
                    </div>
                    <div class="column width-30-per" style="padding-left: 3px;" *ngIf="item.sip">
                        {{item.sip }}
                    </div>
                    <div class="column width-30-per" style="padding-left: 3px;" *ngIf="isGlobal_Route">
                        {{item.suborg_name }}
                    </div>
                    <div class="column width-30-per" style="padding-left: 3px;" *ngIf="show_RouteType">
                        <div class="badge bg-info" *ngIf="item.global_route">
                            Global Route
                        </div>
                    </div>
                </div>
            </ng-template>
        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
                <a class="add-button text-info" *ngIf="addButton && hasPermission()" (click)="openModal(content)">
                    <i class="icon icon-settings mr-1"></i> Add Incoming Routes
                </a>
            </div>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">{{isGlobal_Route?'Add Global Incoming Routes':'Add Incoming Routes'}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <forward-bin-form
                [suborg_id]="suborgId"
                [isGlobal_Route]="isGlobal_Route"
                (success)="onDataSubmittedSuccess($event)"
                (error)="onDataError($event)"
                (cancel)="onCancel()">
            </forward-bin-form>
        </div>
    </ng-template>
</div>
