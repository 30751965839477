import { GridMeta, GridMetaColumn, GridMetaInfo } from './../../models/grid-profiling.models';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { GridMetaService } from '../../services/grid-meta.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { id } from 'src/app/core/utils/global.functions';

@Component({
  selector: 'app-add-grid-meta',
  templateUrl: './add-grid-meta.component.html',
  styleUrls: ['./add-grid-meta.component.scss']
})
export class AddGridMetaComponent implements OnInit, OnDestroy {

  public metaId: number;

  public model: GridMeta = new GridMeta();

  public metaInfo: GridMetaInfo;

  public columns: GridMetaColumn[] = [];

  private columnsEdit: GridMetaColumn[];

  private deleteIds: number[] = [];

  public getSub$: Subscription;

  public postSub$: Subscription;

  public routeParamSub$: Subscription;

  constructor(private location: Location,
    route: ActivatedRoute,
    private metaService: GridMetaService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) {

    this.routeParamSub$ = route.params.subscribe(params => {
      this.metaId = +params.id || 0;
    });
  }

  ngOnInit() {
    if (this.metaId > 0) {
      this.getSub$ = this.metaService.getById(this.metaId).subscribe(info => {
        this.metaInfo = info;
        this.initModel(info);
      });
    }
    else {
      this.columns.push(new GridMetaColumn());
    }
  }

  initModel(info: GridMetaInfo) {
    let meta = new GridMeta();
    meta.id = info.id;
    meta.name = info.name;
    meta.description = info.description;
    meta.is_active = true;
    info.columns.forEach(col => col._guid = id());
    this.columns = info.columns;
    this.columnsEdit = [...info.columns];
    this.model = meta;
    this.cdr.markForCheck();
  }

  onGridMetaSubmit(): void {
    if (this.metaId <= 0)
      this.create();
    else
      this.update();
  }

  create() {
    if (!this.columns || this.columns.length <= 0) {
      return;
    }

    this.model.colnames = this.columns.filter(col => col.name).map(col => col.name);

    this.postSub$ = this.metaService.create(this.model).subscribe(
      _meta => {
        this.toastr.success(`New grid meta created`);
        this.location.back();
      },
      _error => this.toastr.error('Failed to create grid meta')
    );
  }

  update() {
    if (!this.columns || this.columns.length <= 0) {
      return;
    }

    let request: Observable<any>[] = [];

    if (this.model.name !== this.metaInfo.name || this.model.description !== this.metaInfo.description) {
      let obj = new GridMetaInfo();
      obj.name = this.model.name;
      obj.description = this.model.description;
      obj.columns = this.columnsEdit;
      request.push(this.metaService.update(this.metaId, obj));
    }

    let newColumns = this.columns.filter(col => !col.id || col.id <= 0).map(col => col.name);

    if (newColumns.length > 0) {
      request.push(this.metaService.updateGridMetaColumns(this.metaId, newColumns));
    }

    if (this.deleteIds.length > 0) {
      request.push(this.metaService.deleteGridMetaColumns(this.metaId, this.deleteIds));
    }

    if (request.length > 0) {
      this.postSub$ = forkJoin(request).subscribe(
        _results => {
          this.toastr.success(`Grid meta updated`);
          this.location.back();
        },
        _error => this.toastr.error('Failed to update grid meta')
      );
    }
  }

  onCancelClicked(): void {
    this.location.back();
  }

  addColumn() {
    this.columns.push(new GridMetaColumn());
  }

  removeColumn(col: GridMetaColumn) {
    if (col.id > 0) {
      this.deleteIds.push(col.id);
    }
    this.columns = this.columns.filter(item => item._guid !== col._guid);
  }

  ngOnDestroy(): void {
    this.postSub$?.unsubscribe();
    this.routeParamSub$?.unsubscribe();
  }
}
