export class WorkingTimeResponse{

    public id: number;

    public name: string;

    public description: string = null;

    public type: string;

    public created_by: string;

    public created_on: string;

    public modified_on: string;

    public created_user_id: number;

    public modified_user_id: number;

    public is_delete: boolean;

    public customer_id: number;

    public weekDays: string[]=[];

    public time_frame:WorkingTimeFrames[]=[];

}


export class WorkingTimeFrames {

    public id: number;

    public start_date : Date;

    public end_date : Date;

    public start_time : any;

    public end_time :  any;

    public timeframe_id : number;

}

export class TimeFrameDetailsResponse {
 
    public day_name: string;

    public time_frame: TimeDetails[]=[];
    
}

export class TimeDetails {

    public start_time: string;

    public end_time: string;

    public start_date: string;

    public end_date: string;

}



/////// payload
export class TimeFrameRequest{

    public name: string;

    public description: string;

    public type: string;

    public customer:number;

    public weekDays;

    public data:workingTimesPayload[]=[];

   
}

export class workingTimesPayload {
    
    public start_time:string;

    public end_time:string;

    public start_date:Date;

    public end_date:Date;
}

export class workingTimes{ //imp

    // weekDay:string;

    startTime:workingtimesHour=new workingtimesHour();

    endTime:workingtimesHour=new workingtimesHour();

    startDate: any = null;

    endDate: any = null;

}

export class workingtimesHour{ //imp

    public hour:number;

    public minute:number;

}