import { PaymentTermsService } from './../../../services/payment-terms.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, of } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { PaymentTerm } from '../../../models/payment-terms';

@Component({
  selector: 'payment-term-select',
  templateUrl: './payment-term-select.component.html',
  styleUrls: ['./payment-term-select.component.scss']
})
export class PaymentTermSelectComponent implements OnInit {

  @Input() suborg_id: number;

  @Input() value: number;

  @Input() readonly: boolean = false;

  @Output() valueChange = new EventEmitter<number>();

  @Input() addButton: boolean = true;

  private modalRef: NgbModalRef;

  public placeholder: string;

  public options: Observable<PaymentTerm[]>;

  public Res = ResourceConstants;

  constructor(private paymentTermServices: PaymentTermsService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    this.options = this.paymentTermServices.getAll(this.suborg_id);
    this.placeholder = this.hasPermission() && this.addButton ? "Select or add Payment Terms" : "Select Payment Terms";
  }

  openModal(content: any): void {
    this.modalRef = this.modalService.open(content);
  }

  onValueChanged() {
    this.valueChange.emit(this.value);
  }

  onDataSubmittedSuccess(result: { response: PaymentTerm, message: string }): void {
    this.toastr.success(result.message);
    this.options = concat(this.options, of([result.response]));
    this.options = this.paymentTermServices.getAll(this.suborg_id);
    this.value = result.response.id;
    this.onValueChanged();
    this.modalRef?.dismiss();//this.modalService.dismissAll();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalRef?.dismiss();//this.modalService.dismissAll();
  }

  onCancel(): void {
    this.modalRef?.dismiss();//this.modalService.dismissAll();
  }

  hasPermission() {
    return this.ra.hasPermission(this.Res.CUSTOMER_PAYMENT_TERMS, [PermissionConstants.CREATE, PermissionConstants.GLOBAL])
      || this.ra.hasPermission(this.Res.CUSTOMER_MODULE, [PermissionConstants.GLOBAL]);
  }
}
