import { DistributorService } from './../../services/distributor.service';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { GFilterParam, GFColumn, GFColumnTypes, GSortParam, DataFilterRequest } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { Distributor, DistributorLicense } from '../../models/distributor';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
  selector: 'license-number-distributors',
  templateUrl: './license-number-distributors.component.html',
  styleUrls: ['./license-number-distributors.component.scss']
})
export class LicenseNumberDistributorsComponent implements OnInit {

  @ViewChild('FormModal') modalContent: any;

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  public page = { count: 0, limit: 25, offset: 0, pageNumber: '0-0' };

  private _licenseId: number;

  private _distributorid: number;

  @Input() set licenseId(val: number) {
    this._licenseId = val;
    if (val) { this.getData(); }
  }

  get licenseId() { return this._licenseId; };

  @Input() set distributorId(val: number) {
    this._distributorid = val;
    if (val) { this.getData(); }
  }

  get distributorId() { return this._distributorid; };

  public rows: DistributorLicense[];

  public model: DistributorLicense;

  public Permissions = PermissionConstants;

  public SelectionType = SelectionType;

  public distributor: Distributor;

  private modalRef: NgbModalRef;

  public searchFilters: GFilterParam[] = [
    {
      displayName: 'Combo', colname: '', condition: '', value: '',
      children: [
        { displayName: 'Distributor Name', colname: 'distributor_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Distributor Type', colname: 'distributor_type_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Distributor Location Name', colname: 'distributor_location_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact First Name', colname: 'first_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact Second Name', colname: 'second_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact Phone', colname: 'contact_phone', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact Email', colname: 'contact_email', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact Moile', colname: 'contact_mobile', condition: 'contains', value: '', operator: 'OR' },
      ]
    },
    { displayName: 'Distributor Name', colname: 'distributor_name', condition: 'contains', value: '', operator: 'OR' },
    { displayName: 'Distributor Type', colname: 'distributor_type_name', condition: 'contains', value: '', operator: 'OR' },
  ];

  public columns: GFColumn[] = [
    { displayName: 'Distributors', columnName: 'distributor', columnType: GFColumnTypes.ref },
    { displayName: 'Distributor Type', columnName: 'distributor_type', columnType: GFColumnTypes.ref },
    { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
    { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref }
  ];

  public sort: GSortParam[] = [{ colname: 'distributor_name', direction: 'asc' }];

  public sortLookup = {
    distributor_name: 'distributor_name',
    distributor_location_name: 'distributor_location_name'
  };

  private subs: SubSink = new SubSink();

  constructor(private distributorService: DistributorService,
    private cdr: ChangeDetectorRef,
    private ra: ResourceAccessService,
    private modal: NgbModal,
    private toastr: ToastrService) { }

  ngOnInit() { }

  getData(filters: GFilterParam[] = [], offset: number = 0) {

    if (this.licenseId) {
      filters.unshift({ colname: 'license', condition: 'equalto', value: this.licenseId, operator: 'AND' });
    }
    else if (this.distributorId) {
      filters.unshift({ colname: 'distributor', condition: 'equalto', value: this.distributorId, operator: 'AND' });
    }

    let request: DataFilterRequest = { filters: filters, sort: this.sort };

    this.subs.sink = this.distributorService.getDistributorLicenses(request, offset, this.page.limit).subscribe(resp => {
      this.rows = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: DistributorLicense = selected[0];
    if (this.hasPermission(this.Permissions.MODIFY)) {
      this.model = { ...this.rows.find(item => item.id === current.id) };
      this.openModal();
    }
  }

  onNewButtonClick() {
    this.model = new DistributorLicense();
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modal.open(this.modalContent, { scrollable: true, centered: true });
  }

  closeModal() { this.modalRef?.dismiss(); }

  submit() {
    let api: Observable<DistributorLicense | DistributorLicense[]>;
    if (this.model.id > 0) {
      api = this.distributorService.updateDistributorLicense(this.model.id, this.model);
    }
    else {
      this.model.customer = this.distributorService.customerId;
      this.model.license = this.licenseId;
      api = this.distributorService.createDistributorLicense([this.model]);
    }

    this.subs.sink = api.subscribe(
      resp => {
        if (this.model.id > 0) {
          this.rows = [...this.rows.filter(item => item.id !== this.model.id), ...[resp as DistributorLicense]];
          this.toastr.success('1 row updated');
        }
        else {
          this.toastr.success('New Distributor added');
          if (this.page.count < this.page.limit) {
            this.rows = [...this.rows, ...resp as DistributorLicense[]];
            this.page.count = this.rows.length;
          }
        }
        this.cdr.markForCheck();
        this.closeModal();
      },
      () => {
        this.toastr.success('Operation failed');
        this.closeModal();
      }
    );
  }

  deleteRow(id: number): void {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.distributorService.deleteDistributorLicense(id).subscribe(
          () => {
            this.rows = this.rows.filter(x => x.id !== id);
            this.cdr.markForCheck();
            popup.CompleteDelete('Distributor has been deleted.')
          },
          () => this.toastr.error('Unable to delete distributor')
        );
      }
    });
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.DISTRIBUTORS, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.CUSTOMER_MODULE, [this.Permissions.GLOBAL]);
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    let params = this.dataFilter?.getDataFilters() || [];
    this.getData(params, offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
