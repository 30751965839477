import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { KitAssembleService } from './../../services/kit-assemble.service';
import { KitDataHelper } from './../../helpers/kit-data.helper';
import { KitAssembleWarehouseComponent } from './../../components/kit-assemble-warehouse/kit-assemble-warehouse.component';
import { SubSink } from 'subsink';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { KitAssembleWrapper } from '../../models/kit-assemble.models';
import { Location } from '@angular/common';
import * as popup from 'src/app/core/utils/popup.functions';
import { SweetAlertOptions } from 'sweetalert2';
import { AssembledKitItemsComponent } from '../../components/assembled-kit-items/assembled-kit-items.component';
import { environment } from 'src/environments/environment';
import { AppEventType, ModuleConstants } from 'src/app/core/enums/common.enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StaticFile } from 'src/app/core/models/files.models';
import { KitService } from '../../services/kit.service';
import { EventQueueService } from 'src/app/core/services/event-queue.service';
import { LabelPageTemplate } from '../../models/label.models';
import { WareHouseLocation } from '../../models/warehouse.models';
import { LabelService } from '../../services/label.service';
import { WarehouseService } from '../../services/warehouse.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-assembled-kits',
    templateUrl: './assembled-kits.component.html',
    styleUrls: ['./assembled-kits.component.scss']
})
export class AssembledKitsComponent implements OnInit, OnDestroy {

    @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

    @ViewChild('assembleGrid') assembleGrid: AssembledKitItemsComponent;

    @ViewChild('warehouseGrid') warehouseGrid: KitAssembleWarehouseComponent;

    @ViewChild('labelModal') labelModal: ElementRef;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public SelectionType = SelectionType;

    public rows: KitAssembleWrapper[] = [];

    public defaults: GFilterParam[] = KitDataHelper.AssembledKitDefaultFilters;

    public search: GFilterParam[] = KitDataHelper.AssembledKitSearchFilters;

    public columns: GFColumn[] = KitDataHelper.AssembledKitFilterColumns;

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public sortLookup = KitDataHelper.AssembledKitSortLookup;

    public sort: GSortParam[] = [{ colname: 'kit_part_number', direction: 'asc' }];

    public expanded: boolean = true;

    public assembleId: number;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public selected: KitAssembleWrapper;

    public module = ModuleConstants.KIT_ASSEMBLE;

    private subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public labelPageTemplates:LabelPageTemplate[]=[];

    public labelLocationList:WareHouseLocation[]=[];

    public labelTitle:string;

    public labelWherehouse:number=null;

    public labelLocation:number;

    public labelSlNo:{'selected':boolean, 'slNo':number}[]=[];

    public labelTemplate:number=null;

    public allSnSelected=false;

    public printCount:number=0;
    public AssembleStatus = [
        { name: 'DRAFT', value: 'Draft' }, { name: 'IN_PROGRESS', value: 'In Progress' },
        { name: 'ALLOCATED', value: 'Allocated' }, { name: 'SPECIFIED', value: 'Specified' },
        { name: 'ALLOCATED/SPECIFIED', value: 'Allocated and Specified' }, { name: 'COMPLETED', value: 'Completed' }
    ]

    public StatusIndex: number;

    public isAllSuborg: boolean;

    public images: StaticFile[];

    public slnoSearch:string;

    constructor(route: ActivatedRoute,
        private assembleService: KitAssembleService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private ra: ResourceAccessService,
        private location: Location,
        private device: DeviceDetectorService,
        private kitService: KitService,
        private router: Router,
        private eventQueue:EventQueueService,
        private labelService:LabelService,
        private modalService: NgbModal,
        private warehouseService:WarehouseService) {

        this.subs.sink = route.params.subscribe(params => this.assembleId = +params.id || 0);
        this.isAllSuborg = assembleService.isAllSuborg;
        this.scrollbarH = this.device.isMobile();

        if (this.isAllSuborg) {
            this.columns.push({ displayName: 'Sub Org', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentValue: this.assembleService.customerId });
        }
    }

    ngOnInit(): void {

        this.getData();

        if (this.assembleId > 0) {
            this.subs.sink = this.assembleService.getAssembledKitById(this.assembleId).subscribe(kit => {
                this.selected = kit;
                this.expanded = false;
                this.refreshDataTable();
                this.getStatusIndex();
                this.getImages();
            });
        }

        this.subs.sink = this.eventQueue.on(AppEventType.SuborgSwitch).subscribe(event => {
            this.location.go(`/inventory/assemblies/assembled`);
        });
    }

    getImages() {
        this.subs.sink = this.kitService.getKitImages(this.selected.kits).subscribe(resp => {
            this.images = resp;
            this.cdr.markForCheck();
        });
    }

    getStatusIndex() {
        this.StatusIndex = this.AssembleStatus.findIndex(
            (item) => item.name == this.selected.status
        )
    }

    getData(filters: GFilterParam[] = null, offset: number = 0) {
        let option = { sort: this.sort, filters: filters };
        if(this.expanded)
            this.subs.sink = this.assembleService.getAssembledKits(option).subscribe(response => {
                this.rows = response.result;
                this.setPagination(offset, response.count);
                this.cdr.markForCheck();
            });
        else{
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/inventory/assemblies/assembled', this.selected.id, 'view']);
        }
    }

    onSelect({ selected }): void {
        let current: KitAssembleWrapper = selected[0];
        if (current && this.hasPermission(this.Permissions.VIEW_INFO)) {
            this.selected = current;
            this.assembleId = current.id;
            this.expanded = false;
            this.location.go(`/inventory/assemblies/assembled/${current.id}/view`);
            this.refreshDataTable();
            this.getStatusIndex();
            this.getImages();
        }
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    deleteAssembled(id: number): void {

        let option: SweetAlertOptions = {
            title: 'Are you sure you want to Break the Built Assembly?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Break',
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        };

        popup.OpenConfirmBox(option, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.assembleService.deleteAssembledKit(id).subscribe(
                    () => {
                        this.toastr.success(`Built Assembly breaked successfully`)
                        this.expanded = true;
                        this.selected = null;
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        this.location.go(`/inventory/assemblies/assembled`);
                        this.refreshDataTable();
                    },
                    () => this.toastr.error('Unable to break Built Assembly')
                );
            }
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    closeDetailedView(): void {
        this.expanded = true;
        this.selected = null;
        this.assembleId = 0;
        // this.location.go(`/inventory/assemblies/assembled`);
        this.router.navigate(['/inventory/assemblies/assembled']);
        this.refreshDataTable();
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.INV_KITS_ASSEMBLE, [permission], ResourceConstants.INV_MODULE);
    }

    hasPermissionWR(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE);
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    onCompleteClick() {
        this.selected.status = "COMPLETED"
        this.subs.sink = this.assembleService.updateAssembledKit(this.selected.id, this.selected).subscribe(resp => {
            this.toastr.success('Build Completed');
            this.router.navigate(['/inventory/assemblies/assembled', this.selected.id, 'view']);
            this.getStatusIndex();
        });
    }

    changeStatus() {
        let status = this.selected.is_active;
        let opt = {
            title: `Are you sure you want to ${status ? 'Activate assembled Kit' : 'Deactivate assembled Kit'}?`,
            text: this.selected.kit_name,
            current: status,
        };
        popup.ConfirmActivate(opt, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.assembleService.patchAssembledKit(this.selected.id, { is_active: status }).subscribe(
                    () => { },
                    () => {
                        this.selected.is_active = !status;
                        this.cdr.markForCheck();
                        this.toastr.error("Failed to change status");
                    }
                );
            }
            else {
                this.selected.is_active = !status;
                this.cdr.markForCheck();
            }
        });
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }


    getLabelPageTemplates(){
        this.subs.sink=this.labelService.getAllPageTemplate().subscribe(
            response=>{
              this.labelPageTemplates=response;
              this.cdr.markForCheck();
            },
          );

    }


  toggleLabelModal(){




        //  this.cdr.markForCheck();

    let options: NgbModalOptions = {
      size: 'lg'
    };

    this.modalService.open(this.labelModal, options);


    // this.getLabelPageTemplates();

  }


  onLabelWhereHouseChange(warehouseId:number, locationToSelect:number=null){

    this.labelLocation=null;
    this.labelLocationList=[];
    this.warehouseService.getWareHouseLocations(warehouseId).subscribe(response=>{
        this.labelLocationList=response;
        if(locationToSelect){
        this.labelLocation=locationToSelect;
        }
    });
  }

    openPrintView(values){


        console.log(values);

        let arr=[];
        this.labelSlNo=[];
        this.slnoSearch="";

        if(values&&values.slNo&&values.slNo.length>0){
            arr=values.slNo;

        }

        if(arr.length>0){
            arr.forEach((val, index)=>{
                this.labelSlNo.push({'selected':false, 'slNo':val.trim()})
            })
        }
        else if(values.slNo&&values.slNo.length>0){
            this.labelSlNo.push({'selected':false, 'slNo':values.slNo});
        }





        let options: NgbModalOptions = {
            size: 'lg'
          };

          this.modalService.open(this.labelModal, options);


        //   this.getLabelPageTemplates();

        //   this.labelLocation=values.location;

          this.labelWherehouse=values.werehouse;


          this.onLabelWhereHouseChange(values.werehouse, values.location);

          this.cdr.markForCheck();

      }


    proceedToPrinting(){

        let urlValue="";

        let selectedSlNoCount=0;

          if(this.labelWherehouse){
              urlValue+="&wherehouse="+this.labelWherehouse;
          }

          if(this.labelLocation){
              urlValue+="&location="+this.labelLocation;
          }
          let sl_no=""

          if(this.labelSlNo&&this.labelSlNo.length>0){

              this.labelSlNo.forEach((val,index)=>{
                if(val.selected==true){
                    selectedSlNoCount++;
                      if(sl_no==""){
                          sl_no+=val.slNo;
                      }
                      else{
                          sl_no+=","+val.slNo;

                      }
                  }

              });
              urlValue+="&slNo="+sl_no;
          }

          if(!this.printCount){
            this.toastr.error('Please enter required print counts');

          }
          else if(this.printCount<1){
            this.toastr.error('Please enter a valid required print count which is greater than zero');
          }
          else if(selectedSlNoCount>this.printCount){
            this.toastr.error('Selected serial numbers are more than given print count');
          }
          else{
            this.modalService.dismissAll();
            this.router.navigate([`/inventory/labels/print`],{queryParams:{type:'builtAssembly', uniqueId:this.assembleId, werehouse: this.labelWherehouse, location: this.labelLocation, slNo:sl_no, template:this.labelTemplate, printCount:this.printCount}});
          }


          // this.router.navigateByUrl('./inventory/labels/print?type=item&uniqueId='+this.item.id);

    }

    //Select all serial numbers to print
    selectAllSn(val:boolean){
        this.labelSlNo.map(item=>item.selected=val);
        this.allSnSelected=val;

        if(this.printCount){
            if(val){
                this.printCount=this.printCount+this.labelSlNo.length;
            }
            else{
                this.printCount=(this.printCount-this.labelSlNo.length)<0?0:this.printCount-this.labelSlNo.length;

            }
        }
        else{
            if(val){
                this.printCount=this.labelSlNo.length;
            }
            else{
                this.printCount=0

            }
        }

    }
    //Select all serial numbers to print

    selectSn(){

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
