
<div class="d-flex justify-content-end" *ngIf="pagination.isEnabled">
    <ul class="pagination pagination-sm mt-2">
        
        <li class="page-item ng-star-inserted" [ngClass]="{'disabled':pagination.currentPage==1}" (click)="gotoPageNo(1)"><a class="page-link" aria-label="First"><span aria-hidden="true" class="ng-star-inserted">««</span></a></li>
        <li class="page-item ng-star-inserted" [ngClass]="{'disabled':pagination.currentPage==1}" (click)="gotoPageNo(pagination.previousPage)"><a class="page-link" aria-label="Previous"><span aria-hidden="true" class="ng-star-inserted">«</span></a></li>




        <li class="page-item ng-star-inserted" *ngIf="pagination.currentPage>1&&pagination.currentPage==pagination.totalPages&&(pagination.previousPage-1)>0" (click)="gotoPageNo(pagination.previousPage-1)"><a class="page-link ng-star-inserted"> {{pagination.previousPage-1}} </a></li>




        <li class="page-item ng-star-inserted" *ngIf="pagination.currentPage!=1" (click)="gotoPageNo(pagination.previousPage)"><a  class="page-link ng-star-inserted"> {{pagination.previousPage}} </a></li>
        <li class="page-item active ng-star-inserted" aria-current="page" (click)="gotoPageNo(pagination.currentPage)"><a  class="page-link ng-star-inserted"> {{pagination.currentPage}} <span class="sr-only ng-star-inserted">(current)</span></a></li>
        <li class="page-item ng-star-inserted"  *ngIf="pagination.currentPage!=pagination.totalPages&&pagination.nextPage<=pagination.totalPages" (click)="gotoPageNo(pagination.nextPage)"><a class="page-link ng-star-inserted"> {{pagination.nextPage}} </a></li>



        <li class="page-item ng-star-inserted" *ngIf="pagination.currentPage==1&&pagination.currentPage!=pagination.totalPages&&(pagination.nextPage+1)<=pagination.totalPages" (click)="gotoPageNo(pagination.nextPage+1)"><a class="page-link ng-star-inserted"> {{pagination.nextPage+1}} </a></li>






        <li class="page-item ng-star-inserted" [ngClass]="{'disabled':pagination.currentPage==pagination.totalPages}" (click)="gotoPageNo(pagination.nextPage)"><a class="page-link" aria-label="Next"><span aria-hidden="true" class="ng-star-inserted">»</span></a></li>
        <li class="page-item ng-star-inserted" [ngClass]="{'disabled':pagination.currentPage==pagination.totalPages}" (click)="gotoPageNo(pagination.totalPages)"><a class="page-link" aria-label="Last"><span aria-hidden="true" class="ng-star-inserted">»»</span></a></li>
    </ul>
</div>

