export class Duration {

    id:number;

    min_call_duration:number

    default_for_all:boolean;

}

export class SuperAdminSettings {

    id: number

    live_mode: boolean;

    duration: number;

    renewal_call_no: number;

    renewal_days: number;

    renewal_period: number;

    renewal_period_type: string;

}

export class Settings {

    id: number

    call_duration: number;

    default_for_all: boolean = false;

    is_active: boolean = false;

    created_on: string;

    modified_on: string;

    created_user: number;

    modified_user: number;

    customer: number;

    suborg: number;

}

export class SubOrgResponse{

    public id: number;

    customer_name:string;

    public name: string;

    public address: string;

    public city: string;

    public email: string;

    public phone_number: string;

    public state: string;

    public tax_id: string;

    public time_zone: string;

    public website: string;

    public zip: string;

    public is_root: boolean = false;

    public is_delete: boolean;

    public is_active: boolean;

    public created_by: string;

    public created_on: string;

    public customer: number;

    public images: string[] = [];

    public primary_image: string;

    public allowlist_settings: Settings = new Settings();
}

export class AllowlistSettings {
    
    id: number;

    call_duration: number;

    is_active: boolean;

    created_on: string;

    modified_on: string;

    created_user: number;

    modified_user: string;

    customer: number;

    suborg: number;
}

export class SuperAdminAllowListResponse {

    public allowlist_settings: Duration = new Duration();

    public spam_settings: SuperAdminSettings = new SuperAdminSettings();
    
}

export class AllowListResponse {

    public allowlist_settings_superadmin: Duration = new Duration();

    public allowlist_settings_tenant: Settings = new Settings();
}