import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { concat, Observable, of } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Manufacturer } from '../../../models/manufacturer';
import { ManufacturerService } from '../../../services/manufacturer.service';

@Component({
  selector: 'manufacturer-select',
  templateUrl: './manufacturer-select.component.html',
  styleUrls: ['./manufacturer-select.component.scss']
})
export class ManufacturerSelectComponent implements OnInit {

  @Input() value: number;

  @Input() readonly: boolean = false;

  @Input() addButton: boolean = true;

  @Output() valueChange = new EventEmitter<number>();

  @Output() onClear = new EventEmitter();

  public placeholder: string;

  public options: Observable<Manufacturer[]>;

  constructor(private manufactService: ManufacturerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    this.options = this.manufactService.getAll();
    this.placeholder = this.hasPermission() && this.addButton ? "Select or add Manufacturer" : "Select Manufacturer";
  }

  openModal(content: any): void {
    this.modalService.open(content);
  }

  onValueChanged() {
    this.valueChange.emit(this.value);
  }

  onDataSubmittedSuccess(result: { response: Manufacturer, message: string }): void {
    this.toastr.success(result.message);
    this.options = concat(this.options, of([result.response]));
    this.value = result.response.id;
    this.onValueChanged();
    this.modalService.dismissAll();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalService.dismissAll();
  }

  onCancel(): void {
    this.modalService.dismissAll();
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.INV_MANUFACTURERS, [
      PermissionConstants.CREATE, PermissionConstants.GLOBAL
    ]);
  }

  onSelectClear() {
    this.valueChange?.emit(null);
    this.onClear?.emit();
  }
}
