import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { DidsService } from '../../../services/dids.service';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { DIDNumber } from '../../../models/dids.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SweetAlertOptions } from 'sweetalert2';
import * as popup from 'src/app/core/utils/popup.functions';
import { ToastrService } from 'ngx-toastr';
import { ForwardBinSelectComponent } from '../../../components/forward-bin-select/forward-bin-select.component';


@Component({
  selector: 'dids-bulk-update',
  templateUrl: './dids-bulk-update.component.html',
  styleUrls: ['./dids-bulk-update.component.scss']
})
export class DidsBulkUpdateComponent implements OnInit {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild('frwdbin') frwdbin:ForwardBinSelectComponent;

  @Output() emmitTotalCount = new EventEmitter<number>();

  @Output() emmitSelectedCount = new EventEmitter<number>();

  @Output() closeBulkupdateModal = new EventEmitter<void>();

  public rows: DIDNumber[] = [];

  @Input() totalCount:number;

  public bulk_name:string = '';

  public bulk_route:string = null;

  public isGlobal_route:boolean = false;

  public selectedNumbers: DIDNumber[] = [];

  public suborgId: number;

  public totalSelectedNumbers: number;

  public nameError: boolean = false;

  public scrollbarH: boolean = false;

  public subs: SubSink = new SubSink();

  public deletedRecords: boolean = false;

  public isSuperAdmin: boolean;

  public page: Pagination = { count: 0, limit: 250, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = [
    { displayName: "All Phone Numbers", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Unassigned Numbers", colname: 'forward_bin_sid_resolved', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Local Numbers", colname: 'did_type', condition: 'is', value: 'Local', operator: 'AND' },
    { displayName: "Toll Free Numbers", colname: 'did_type', condition: 'is', value: 'Toll-Free', operator: 'AND' }
];

  public searchFilters: GFilterParam[] = [
    {
        displayName: 'Number or Name', colname: '', condition: '', value: '',
        children: [
            { displayName: 'Phone Number', colname: 'did', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
            { displayName: 'Phone Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' }
        ],
    },
    { displayName: 'Incoming Routes Name', colname: 'forward_bin_name', condition: 'contains', value: '', operator: 'OR' },
    { displayName: 'Incoming Routes', colname: 'forward_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
    { displayName: 'Area Code', colname: 'area_code', condition: 'contains', value: '', operator: 'OR' },
];

  public columns: GFColumn[] = [
    { displayName: 'Number', columnName: 'did', columnType: GFColumnTypes.phone },
    { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
    { displayName: 'Incoming Routes', columnName: 'forward_bin_sid_resolved', columnType: GFColumnTypes.ref },
    { displayName: 'Unassigned Numbers', columnName: 'is_bin_unassigned', columnType: GFColumnTypes.boolean },
    {
        displayName: 'Number Type',
        columnName: 'did_type',
        columnType: GFColumnTypes.select,
        optionValueType: GFColumnTypes.ref,
        options: [
            { displayName: 'Local', value: 'Local' },
            { displayName: 'Toll-Free', value: 'Toll-Free' },
        ]
    },
    { displayName: 'Area Code', columnName: 'area_code', columnType: GFColumnTypes.ref },
    { displayName: 'Purchased on', columnName: 'purchased_on', columnType: GFColumnTypes.date }
];

public sortLookup = {
  did: 'did',
  name: 'name',
  area_code: 'area_code',
  purchased_on: 'purchased_on',
  forward_bin_name: 'forward_bin_name',
  suborg_name: 'suborg_name'
};

public sort: GSortParam[] = [{ colname: 'purchased_on', direction: 'desc' }];

public module = ModuleConstants.RING2VOICE_DIDS;

public SelectionType = SelectionType;

public selectedCard: string = 'reassign';

public selectedBinName: string;

  constructor(
    public didService: DidsService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService
    ) { 
      this.isSuperAdmin = didService.isSuperUser;
      this.suborgId = didService.suborgId;
    }

  ngOnInit(): void {
    this.getData();
  }

  getData(filters: GFilterParam[] = null, offset: number = 0) {

    let request = {
        base_filters: [{ colname: 'is_delete', condition: 'is', value: this.deletedRecords.toString(), operator: 'AND' }],
        filters: filters,
        sort: this.sort
    }

    this.subs.sink = this.didService.getAll(request, offset, this.totalCount).pipe(
        catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
        this.rows = response.result;
        this.emmitSelectedCount.emit(0);
        // this.setPagination(offset, response.count);
        this.page.count = response.count;
        this.emmitTotalCount.emit(response.count);
        this.cdr.markForCheck();
    });
}


  numberOnSelect({ selected }){
    this.selectedNumbers = [...selected];
    // this.selectedUsersCount = this.selectedUsers.length;
    this.emmitSelectedCount.emit(this.selectedNumbers.length);
    this.cdr.markForCheck();
  }

  applyFilter(params: GFilterParam[]) {
    const filters = this.getFilters(params);
    this.getData(filters);
    this.selectedNumbers = [];
    this.emmitSelectedCount.emit(0);
}

  getFilters(params?: GFilterParam[]) {
    let filters = params || this.dataFilter.getDataFilters();
    let del_filter = filters?.find(fil => fil.colname === 'is_delete');
    this.deletedRecords = del_filter ? del_filter.value : false;
    return filters.filter(fil => fil.colname !== 'is_delete');
}

onSort(event: any) {
  if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop]) return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      const filters = this.getFilters();
      this.getData(filters);
  }
}

refreshDataTable() {
  setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
  });
}

selectCard(card: string) {
  this.selectedCard = card;
}

globalRouteChange() {
  if (this.bulk_route) {
    this.bulk_route = null;
  }
}



previousBtnClicked(){
  if(this.didService.currentStep>1){
    this.didService.currentStep--;
    if(this.didService.currentStep==2){
      this.refreshDataTable();
    }
}
}

nextBtnClicked(){
  if(this.didService.currentStep == 1){ 
    this.refreshDataTable();
    if((this.selectedCard=='rename'&&this.bulk_name.length==0)||(this.selectedCard=='reassign'&&!this.bulk_route)){
        let option: SweetAlertOptions = {
            title: 'Alert',
            text:this.selectedCard=='rename'?'Enter Name to continue':'Select an incoming route',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn',
              footer: 'flex-row-reverse'
            },
            buttonsStyling: false
          };
          popup.OpenConfirmBox(option, result => {
            if (result.isConfirmed){
              return
            }  
          });
        return
      }

    if(this.selectedCard=='reassign'){
      let selectedBin = this.frwdbin.rows.find(element=> element.sid === this.bulk_route);
      this.selectedBinName = selectedBin.name;
    }
  }
        
  if(this.didService.currentStep == 2){
    this.totalSelectedNumbers =  this.selectedNumbers.length;
    if(this.totalSelectedNumbers == 0){
      let option: SweetAlertOptions = {
          title: 'Alert',
          text:'Please select atleast one number',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn',
            footer: 'flex-row-reverse'
          },
          buttonsStyling: false
        };
        popup.OpenConfirmBox(option, result => {
          if (result.isConfirmed){
            return;
          }  
        });
      return
    }
  }

  if(this.didService.currentStep<3){
      this.didService.currentStep++;
  }
}

onFinish() {

  let checkedSidArray = this.selectedNumbers.map(item => {
    return item.sid;
  });

  let api = this.selectedCard=='reassign' ? this.didService.bulkReassign(checkedSidArray, this.bulk_route):
            this.didService.bulkRenameDid(checkedSidArray, this.bulk_name);

  this.subs.sink = api.subscribe(response=>{
    if(response.status){
      let option: SweetAlertOptions = {
        title: 'Bulk Update Successful',
        text: 'You will receive an email with the results',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-success',
          footer: 'flex-row-reverse'
        },
        buttonsStyling: false
      };
      popup.OpenConfirmBox(option, result => {
        if (result.isConfirmed) {
              this.closeBulkupdateModal?.emit();
              this.didService.currentStep=1;
            }  
          });
    }else if (response.message === "SIGNALWIRE_API_ERROR") {
      this.toastr.error(`Unable to update, Retry after some time`);
    }
  },
  error => {
    this.toastr.error(`Operation failed`);
  });
}

}
