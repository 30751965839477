import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { TimeSheetDataHelper } from 'src/app/modules/service-ticket/helper/time-sheet-data.helper';
import { TimeSheet } from 'src/app/modules/service-ticket/models/common.models';
import { ServiceTicketService } from 'src/app/modules/service-ticket/services/service-ticket.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink/dist/subsink';
import { BillingStatus } from '../../../service-ticket/models/enums';


@Component({
  selector: 'timesheet-select',
  templateUrl: './timesheet-select.component.html',
  styleUrls: ['./timesheet-select.component.scss']
})
export class TimesheetSelectComponent implements OnInit {

  @ViewChild('timesheetModal') itemsModal: any;

  @Input() value: number;

  @Input() customerId: number;

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<TimeSheet[]>();

  @Input() selectBox: boolean = true;


  public SelectionType = SelectionType;

  public fileUrl: string = environment.fileURL;

  public items: TimeSheet[];

  public expanded=true;

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = TimeSheetDataHelper.defaults;

  public search: GFilterParam[] = TimeSheetDataHelper.search;

  public sortLookup = TimeSheetDataHelper.sortLookup;

  public selectedItem: TimeSheet[] = [];

  public selected: TimeSheet[];

  public sort: GSortParam[];

  public columns: GFColumn[] = TimeSheetDataHelper.columns;

  private subs: SubSink = new SubSink();

  private modalRef: NgbModalRef;

  public scrollbarH:boolean=false;

  public billingStatusEnum = BillingStatus;

  public billingStatus: { key: string, value: number, class: string }[] = [
    { key: 'Unbilled', value: BillingStatus.Un_billed, class: 'badge bg-warning' },
    { key: 'Invoiced', value: BillingStatus.Invoiced, class: 'badge bg-info' },
    { key: 'Paid', value: BillingStatus.Paid, class: 'badge bg-success' },
    { key: 'Overdue', value: BillingStatus.Overdue, class: 'badge bg-danger' },
  ];
    modalRefPopup: NgbModalRef;

  constructor(private modal: NgbModal,
    private itemService: ServiceTicketService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService,
    private toastr:ToastrService) {
      this.scrollbarH = this.device.isMobile();
    }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue > 0) {
      this.setSelected(changes.value.currentValue);
    }
  }

  setSelected(id: number) {
    if (this.selected) return;
    this.subs.sink = this.itemService.getTimeSheetById(id).subscribe(item => {
      this.selected = [{ ...item }];
      this.cdr.markForCheck();
    });
  }

  open() {
    this.modalRef = this.modal.open(this.itemsModal, { size: 'xl', scrollable: true });
    if (!this.items || this.items.length <= 0) {
      this.getData(null);
    }
  }

  private getData(filters: GFilterParam[], offset: number = 0) {
    // {"filters":[{"colname":"created by","condition":"equalto","value":2,"operator":"AND"}],"sort":[{"colname":"assigned_date","direction":"desc"}]}
    if(filters!=null){
      filters.push({colname:'tenant_customer', condition:'equalto', value:this.customerId, operator:"AND"});
    }
    else{
      filters=[{colname:'tenant_customer', condition:'equalto', value:this.customerId, operator:"AND"}];
    }
    filters.push({colname:'is_billable', condition:'equalto', value:true, operator:"AND"});
    filters.push({colname:'billing_status', condition:'equalto', value:1, operator:"AND"});
    let option = {sort: this.sort, filters: filters };
    this.subs.sink = this.itemService.getTimesheetsFiltered(option, offset).subscribe(resp => {
      this.items = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  onSort(event: any, dataFilter: DataGridFilterComponent) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(dataFilter.getDataFilters());
    }
  }

  setPage(pageInfo: any, dataFilter: DataGridFilterComponent) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(dataFilter.getDataFilters(), offset);
  }

  onItemSelected() {
    if (this.selectedItem && this.selectedItem.length > 0) {
      let item = this.selectedItem;
      // this.value = item.id;
      this.selected = item;
      if(this.selected.length>0){
        this.valueChange?.emit(null);
      }
      else{
        this.valueChange?.emit(this.selected[0].id);
      }
      this.change?.emit(item);
    }
    this.modalRef?.dismiss();
  }

  onCanceled() {
    this.modalRef?.dismiss();
  }

  clearSelection(event: any) {
    event.stopPropagation();
    this.selected = null;
    this.valueChange?.emit(null);
    this.selectedItem = [];
  }

  getTime(timeInMi: number) {
    let h = Math.floor(timeInMi / 60);
    let m = timeInMi % 60;
    return `${h} Hours ${m} Minutes`;
  }

  getBillingStatus(val: number) {
    return this.billingStatus.find(item => item.value === val);
  }

  openModal(content: any){
    this.modalRefPopup = this.modal.open(content, { centered: true, scrollable: true, size: "xl" });
  }

  onDataSubmittedSuccess(result: { response: TimeSheet, message: string }): void {
    this.toastr.success('Success', result?.message);
    this.modalRefPopup?.dismiss()
    this.selectedItem.push(result.response)
    this.onItemSelected();
    }

    onDataError(error: any): void {
        console.error(error);
        this.toastr.error('Failed', 'Operation failed');
        this.modalRefPopup?.dismiss()
    }

    onCancel(): void {
        this.modalRefPopup?.dismiss()
    }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
