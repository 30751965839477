import { Component, Input, OnInit } from '@angular/core';
import { AfterTimeoutOption, CallRingTypeEnum } from 'src/app/core/enums/common.enum';

@Component({
  selector: 'ring2voice-ring-settings',
  templateUrl: './ring-settings.component.html',
  styleUrls: ['./ring-settings.component.scss']
})
export class RingSettingsComponent implements OnInit {

  @Input() model;

  @Input() subOrg_id;

  public ringType=CallRingTypeEnum;

  public afterTimeout=AfterTimeoutOption;

  public toggle1: string;

  public toggle2: string;

  constructor() { }

  ngOnInit(): void {
    this.generateRandomString(6);
  }

  generateRandomString = (length: number): void => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result1 = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result1 += characters.charAt(randomIndex);
    }

    let result2 = '';
    for (let i = 0; i < length+2; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result2 += characters.charAt(randomIndex);
    }
  
    this.toggle1 = result1;
    this.toggle2 = result2;
    
  };
  
}
