import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { ExtLayoutComponent } from './components/ext-layout/ext-layout.component';
import { InnerLayoutComponent } from './components/inner-layout/inner-layout.component';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import { FormsModule } from '@angular/forms';
import { Ring2VoiceModule } from '../modules/ring2voice/ring2voice.module';
import { NgSelectModule } from '@ng-select/ng-select';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    ExtLayoutComponent,
    InnerLayoutComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    SidebarDropdownDirective,
    SidebarLinkDirective,
    ExtLayoutComponent,
    InnerLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    PerfectScrollbarModule,
    FormsModule,
    Ring2VoiceModule,
    NgSelectModule
  ],
  providers:[
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})
export class BlocksModule { }
