<section class="assemble-form" *ngIf="model">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">
                {{ assembleId > 0 ? "Edit Built Assembly" : "Build Assembly" }}
            </h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form
                    name="form"
                    #formElement="ngForm"
                    (ngSubmit)="formElement.form.valid && onSubmit()"
                    novalidate>
                    <div class="row">
                        <div class="col-12 col-md-10">
                            <div class="kit-info mb-2">
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Assembly Name</label>
                                    <label class="item-label-value">{{
                                        kitInfo.name
                                    }}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Part Number</label>
                                    <label class="item-label-value">{{
                                        kitInfo.part_number
                                    }}</label>
                                </div>
                                <div class="ov-row" *ngIf="kitInfo.sku">
                                    <label class="col-md-3 p-0 item-label">SKU</label>
                                    <label class="item-label-value">{{
                                        kitInfo.sku
                                    }}</label>
                                </div>
                            </div>
                            <div
                                class="col-md-5 p-0 form-group"
                                *ngIf="model.unique_number">
                                <label>Unique Number</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    [value]="model.unique_number"
                                    readonly />
                            </div>
                            <div class="col-md-5 p-0 form-group">
                                <label class="val-required">Quantity</label>
                                <input
                                    type="number"
                                    min="1"
                                    [(ngModel)]="model.quantity"
                                    #qn="ngModel"
                                    name="quantity"
                                    class="form-control"
                                    (change)="onChangeQuantity()"
                                    [disabled]="model.status == 'COMPLETED'"
                                    required />
                                <small
                                    class="form-text text-muted danger"
                                    *ngIf="
                                        qn.invalid && (qn.dirty || qn.touched)
                                    ">
                                    <div *ngIf="qn.errors.required">
                                        Quantity is required.
                                    </div>
                                </small>
                            </div>
                        </div>
                    </div>
                    <h5 class="mt-2 section-title val-required">
                        Associated Items
                    </h5>
                    <div class="col-12 col-md-12 p-0 mb-1">
                        <assembled-kit-items
                            [kitId]="kitId"
                            [assembledId]="assembleId"
                            [bundleCount]="model.quantity">
                        </assembled-kit-items>
                    </div>
                    <h5 class="mt-3 section-title">
                        Warehouses
                        <span style="font-size: 14px">(Locations where assemblies are stored)</span>
                    </h5>
                    <div class="col-12 col-md-10 p-0 mb-4">
                        <kit-assemble-warehouse
                            [assembledId]="assembleId"
                            [quantity]="model.quantity">
                        </kit-assemble-warehouse>
                    </div>
                    <div class="row">
                        <div
                            class="col-12 col-md-10 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button
                                type="submit"
                                (click)="onCompleteClick()"
                                class="btn success mb-2 mb-sm-0 mr-sm-2"
                                [disabled]="model.status !== 'ALLOCATED/SPECIFIED'"
                                *ngIf="!isAllSuborg"
                            >
                                <i class="ft-check-square mr-1"></i> COMPLETE
                            </button>
                            <button
                                type="submit"
                                [disabled]="!formElement.form.valid"
                                class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2"
                                *ngIf="!isAllSuborg">
                                {{ assembleId > 0 ? "Save Changes" : "Save" }}
                            </button>
                            <button
                                type="button"
                                (click)="location.back()"
                                class="btn btn-secondary">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
