<form name="form" autocomplete="off" #userForm="ngForm" (ngSubmit)="userForm.form.valid && resetPassword()" novalidate>
    <div class="row">
        <div class="col-12">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 p-0 form-group" *ngIf="!isHidden('current_password')">
                        <label class="val-required">Current password</label>
                        <input type="password" autocomplete="off" name="first_name" class="form-control" [(ngModel)]="model.old_password" #current="ngModel" placeholder="********" required>
                        <small class="form-text text-muted danger" *ngIf="current.invalid && (current.dirty || current.touched)">
                            <div *ngIf="current.errors.required">
                                Current password is required
                            </div>
                        </small>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12 p-0 form-group">
                        <label class="val-required">New password</label>
                        <input type="password" autocomplete="off" name="last_name" class="form-control" [(ngModel)]="model.password" #new="ngModel" placeholder="********" required>
                        <small class="form-text text-muted danger" *ngIf="new.invalid && (new.dirty || new.touched)">
                            <div *ngIf="new.errors.required">
                                New password is required
                            </div>
                        </small>
                        <!-- <small class="form-text text-muted danger" *ngIf="(current.value!=''&&new.value!='')&&current.value===new.value">
                            <div>
                                New password and current password should not be same.
                            </div>
                        </small> -->
                    </div>
                    <div class="col-12 col-md-12 col-lg-12 p-0 form-group">
                        <label class="val-required">Repeat password</label>
                        <input type="password" autocomplete="off" name="last_name" class="form-control" [(ngModel)]="model.confirm_password" #confirm="ngModel" placeholder="********" required>
                        <small class="form-text text-muted danger" *ngIf="confirm.invalid && (confirm.dirty || confirm.touched)">
                            <div *ngIf="confirm.errors.required">
                                Confirm password is required
                            </div>
                        </small>
                        <small class="form-text text-muted danger" *ngIf="(new.value!=''&&confirm.value!=''&&new.value?.length==confirm.value?.length)&&confirm.value!=model.password">
                            <div>
                                Password and confirm password should match
                            </div>
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" class="btn gradient-pomegranate mb-2 mb-sm-0" [disabled]="!userForm.form.valid">
                Submit
            </button>
        </div>
    </div>
</form>