<app-navbar (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-navbar>

<div class="wrapper" [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty}" (click)="onWrapperClick();" (window:resize)="onResize($event)">

    <div appSidebar #sidebar class="app-sidebar main-menu menu-fixed menu-native-scroll" [ngClass]="{'expanded-delay' : config.layout.sidebar.toggleDelay}" (mouseenter)="sidebarMouseenter($event)" (mouseleave)="sidebarMouseleave($event)" data-active-color="white" data-background-color="man-of-steel">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>

    <div class="main-panel">
        <div class="main-content">
            <div class="content-overlay"></div>
            <div class="content-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer></app-footer>         
        <button class="btn btn-primary scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()"><i class="ft-arrow-up"></i></button>
    </div>

    <div class="sidenav-overlay" (click)="onOutsideClick($event);" [ngClass]="{'d-none': displayOverlayMenu && hideSidebar && !overlayContent , 'd-block': displayOverlayMenu && !hideSidebar && overlayContent && innerWidth < 1200}"></div>
    <div class="drag-target"></div>
</div>
