import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { additionalFieldCreate, additionalFieldsList, additionalFieldUpdate, additional_field_values, updateStatus } from '../../../models/additional-fields.models';
import { AdditionFieldsService } from '../../../services/addition-fields.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { AdditionalFieldsDataHelper } from '../../../helpers/additional-fields-data.helpers';
import { ModuleConstants } from 'src/app/core/enums/common.enum';

@Component({
  selector: 'app-additional-fields-list',
  templateUrl: './additional-fields-list.component.html',
  styleUrls: ['./additional-fields-list.component.scss']
})
export class AdditionalFieldsListComponent implements OnInit {

    @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;


  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ViewChild('searchInput') searchInput: ElementRef;

  public SelectionType = SelectionType;

  public rows: additionalFieldsList[];

  public rowsFlat: additionalFieldsList[];

  // public locations: WareHouseLocation[];

  public defaults: GFilterParam[] = AdditionalFieldsDataHelper.DefaultFilters;

  public search: GFilterParam[]=AdditionalFieldsDataHelper.SearchFilters;

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public sortLookup = AdditionalFieldsDataHelper.SortLookup;

  public sort: GSortParam[] = [{ colname: 'part_number', direction: 'asc' }];
  
  public columns: GFColumn[]=AdditionalFieldsDataHelper.FilterColumns;

  public additionalFieldId: number = 0;

  public locationId: number = 0;

  public model: additionalFieldsList=new additionalFieldsList();


  public module = ModuleConstants.ITEM;

  // public locationModel: WareHouseLocation;

  public expanded: boolean = true;

  private _selected: additionalFieldsList;

  public get selected() { return this._selected; }

  public set selected(value: additionalFieldsList) {
      if (this._selected !== value) {
          this._selected = value;
          if (value) {
              // this.getWareHouseLocations(value.id);
          }
      }
  }

  public locationPage = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  private subs = new SubSink();

  public scrollbarH: boolean = false;

  public nameExist: boolean = false;

  public isAllSuborg: boolean;

  public UIPermissions: UIPermission;

  public addModalReference;


  constructor(private cdr: ChangeDetectorRef,
      private modalService: NgbModal,
      private additionalFieldsService: AdditionFieldsService,
      private toastr: ToastrService,
      private ra: ResourceAccessService,
      private device: DeviceDetectorService) {

      this.isAllSuborg = this.additionalFieldsService.isAllSuborg;
      this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_WAREHOUSE, ResourceConstants.INV_MODULE);
  }

  ngOnInit() {

    

      this.scrollbarH = this.device.isMobile();

    this.getAdditionalFields();
  }

  ngAfterViewInit(): void { this.searchInput?.nativeElement.focus(); }



  getAdditionalFields(){
    this.subs.sink = this.additionalFieldsService.getAll().subscribe(response => {
        this.rows = response.data;
        this.rowsFlat = response.data;
        this.cdr.markForCheck();
    });
  }








  onSelect({ selected }) {
      let current: additionalFieldsList = selected[0];
      if (current && this.UIPermissions.viewInfo) {
          this.selected = current;
          this.additionalFieldId = current.additional_field.id;
          this.expanded = false;
          this.rows = this.rows.concat([]);
      }
  }

  delete(id: number) {
      popup.ConfirmDelete(result => {
          if (result.isConfirmed) {
              this.subs.sink = this.additionalFieldsService.delete(id).subscribe(
                  () => {
                      this.rows = this.rowsFlat.filter(x => x.additional_field.id !== id);
                      this.rowsFlat=[...this.rows];
                      this.closeDetailedView();
                      this.cdr.markForCheck();
                      popup.CompleteDelete('Additional field has been deleted.');
                  },
                  () => this.toastr.error('Unable to delete row')
              );
          }
      });
  }

  openModal(content: any, id: number = 0) {
    console.log('hi');

      this.additionalFieldId = id;
      this.model = id > 0 ? { ...this.rows.find(w => w.additional_field.id === id) } : new additionalFieldsList();
        this.addModalReference=this.modalService.open(content, { centered: true });
  }



  closeDetailedView() {
      this.expanded = true;
      this.selected = null;
      setTimeout(() => {
          this.rows = this.rows.concat([]);
          this.cdr.markForCheck();
      });
  }



  onDataSuccess(value){

    this.getAdditionalFields();

    let newVal=new additionalFieldsList();

    if(this.selected){
        this.selected.additional_field=value.response.additional_field;
        this.selected.values=value.response.additional_field_values;
    }

    this.toastr.success(value?.message);

    this.addModalReference.close();


  }

  onDataError(error){

  }

  onCancel(){

    this.addModalReference.close();

  }


  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

}
