import { catchError } from 'rxjs/operators';
import { UserGroupService } from './../../services/user-group.service';
import { SubSink } from 'subsink';
import { UserWrapper, UserWrapperFlat } from './../../models/user-wrapper';
import { SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './../../services/user.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SIPService } from 'src/app/modules/ring2voice/services/sip.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';


@Component({
  selector: 'user-select-grid',
  templateUrl: './user-select-grid.component.html',
  styleUrls: ['./user-select-grid.component.scss']
})
export class UserSelectGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit  {

  @ViewChild('userSelectModal') modalContent: ElementRef;

  @Input() userGroupId: number;

  @Input() value: UserWrapper[];

  @Output() valueChange = new EventEmitter<any[]>();

  @Input() viewMode: boolean = false;

  @Input() smart_sip_group: boolean;

  public users: UserWrapperFlat[];

  public usersTemp: UserWrapperFlat[];

  public rows: UserWrapperFlat[] = [];

  public selectedUsers: UserWrapperFlat[] = [];

  private subs = new SubSink();

  public SelectionType = SelectionType;

  public counter:number=0;

  public suborg_id:number;
  
  public selectedUserid: number;

  public assignModal: any;

  public totalUsersCount: number;

  public selectedUsersCount: number;

  public moment = moment;

  

  constructor(private userService: UserService,
    private userGroupService: UserGroupService,
    public sipservice: SIPService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public modal: NgbModal) 
    { 
      this.suborg_id = userService.suborgId;
    }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userGroupId && changes.userGroupId.currentValue > 0) {
      this.getGroupUsers();
    }
    moment.updateLocale('en', {
      relativeTime : {
          future: "in %s",
          past:   "%s ago",
          s  : 'a few seconds',
          ss : '%d seconds',
          m:  "a minute",
          mm: "%d minutes",
          h:  "%d minutes",
          hh: "%d hours",
          d:  "a day",
          dd: "%d days",
          w:  "a week",
          ww: "%d weeks",
          M:  "a month",
          MM: "%d months",
          y:  "a year",
          yy: "%d years"
      }
  });
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      const param1Value = params['smartsipgroup'];
      if(param1Value&&!this.viewMode){
        this.openModal(this.modalContent);
      }
    });
  }

  getGroupUsers(filters:any = null) {
    this.subs.sink = this.userGroupService.getUsersByUserGroup(this.userGroupId,filters).subscribe(resp => {
      this.rows = resp;
      this.valueChange?.emit(resp.map(u => u.id));
      this.cdr.markForCheck();
    });
  }

  getUsers(callback?: (value: any) => any) {
    this.subs.sink = this.userService.getUsersForDropdown().pipe(catchError(error => [])).subscribe(resp => {
      this.users = resp;
      this.usersTemp = resp;
      this.cdr.markForCheck();
      if (callback) callback(resp);
    });
  }

  openModal(content: any) {
    if (!this.users) {
      this.getUsers(users => {
        this.totalUsersCount = users.length;
        this.modal.open(content, { scrollable: true, size: 'lg', centered: true });
        let ids = this.rows.map(u => u.id);
        this.selectedUsers = users.filter(item => ids.includes(item.id));
        this.selectedUsersCount = this.selectedUsers.length;
        this.cdr.markForCheck();
      });
    }
    else {
      this.modal.open(content, { scrollable: true, size: 'lg', centered: true });
    }
  }

  openAssignModal(content: any, sel: any){
    this.selectedUserid = sel
    this.assignModal = this.modal.open(content, { size: 'md', centered: true });
  }

  userOnSelect({ selected }) {
     this.selectedUsers = [...selected];
     this.selectedUsersCount = this.selectedUsers.length;
     this.cdr.markForCheck();
    }

  removeUnassignedUsers(){
    const arrayWithoutUnassigned = this.selectedUsers.filter((item) => item.sip_name !== null);
    this.selectedUsers = [...arrayWithoutUnassigned];
    this.selectedUsersCount = this.selectedUsers.length;
  }

limitCheckboxSelection(maxSelection: number) {
  const checkboxes = document.querySelectorAll('input[name="preferred"]') as NodeListOf<HTMLInputElement>;
  let numChecked = 0;
  this.counter = numChecked
  
  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked) {
      if(this.counter<maxSelection){
        numChecked++;
      }
    }
    this.counter=numChecked
    if (this.counter > maxSelection) {
      checkboxes[i].checked = false;
    }
  }
}

  checked(row){
    this.limitCheckboxSelection(10);
    row.preffered_user=!row.preffered_user;
    this.cdr.markForCheck();
    let data:UserWrapper[]=[];
    data=this.rows.map(val=>{
      let d:UserWrapper=new UserWrapper(); 
      d.id=val.id;
      d.custname=val.customer_name;
      d.preffered_user=val.preffered_user?true:false;
      d.user_lastname=val.last_name;
      d.user_firstname=val.first_name;
      d.user_email=val.email;
      d.rolename=val.role_name;
      d.user_status=val.user_status;

      return d;
     });
    this.valueChange?.emit(data);
  }

  applySelection() {
    if (this.viewMode) {
      let request = { users: this.selectedUsers.map(u => u.id) };
      this.updateGroupUsers(this.userGroupId, request, () => {
        this.rows = [...this.selectedUsers];
        this.cdr.markForCheck();
        this.closeModal();
      });
    }
    else {
      this.rows = [...this.selectedUsers];
      this.cdr.markForCheck();
      this.valueChange?.emit(this.rows.map(u =>{ 
        u.preffered_user=false;
        return u
      }));
      this.closeModal();
    }
  }

  removeSelected(user: UserWrapperFlat) {
    if (this.viewMode) {
      let request = { users: this.rows.filter(u =>  u.id !== user.id).map(u => u) };
      this.updateGroupUsers(this.userGroupId, request, () => {
        this.removeUser(user);
        this.cdr.markForCheck();
      });
    }
    else {
      this.removeUser(user);
      this.valueChange?.emit(this.rows.map(u => u));
    }
  }

  removeUser(user: UserWrapperFlat) {
    this.selectedUsers = this.selectedUsers.filter(item => item.id !== user.id);
    this.rows = this.rows.filter(item => item.id !== user.id);
  }

  searchUser(event: any) {
    const val = event.target.value?.toLowerCase();
    this.users = this.usersTemp.filter(item => {
      return (item.sip_name&&(item.sip_name)?.toLowerCase().indexOf(val) !== -1) || (item.first_name+" "+item.last_name)?.toLowerCase().indexOf(val) !== -1|| (item.email)?.toLowerCase().indexOf(val) !== -1 || !val;
    });
  }

  updateGroupUsers(id: number, request: any, callback?: (value: any) => any) {
    this.subs.sink = this.userGroupService.patch(id, request).subscribe(resp => {
      if (callback) callback(resp);
    });
  }

  assignSipOnSave(){
    let sid = this.sipservice.assignSiptoUser;
    
    this.subs.sink = this.userService.assignSiptoUser(this.selectedUserid,sid).subscribe(resp => {
      if(resp.status){
        this.toastr.success("SIP assigned");
        this.getUsers(users => {
          let ids = this.rows.map(u => u.id);
          this.selectedUsers = users.filter(item => ids.includes(item.id));
          this.cdr.markForCheck();
        });
        this.closeAssignModal();
      }else {
        this.toastr.warning("Failed to assign");
      }
    },
    () => this.toastr.error('Opeation failed.')
    )
  }

  closeModal() {
    this.users = this.usersTemp;
    this.modal.dismissAll();
  }

  closeAssignModal(){
    this.assignModal.dismiss()
  }

  onActivate(event){

  }

  filterOnlineOffline(tp)
  {
      debugger
      let filter :any = {}

      if(tp == 1)
      {
          filter = {
            "colname":"user_status",
            "value":"Online"
        }
      }
      else
      {
          filter = {
            "colname":"user_status",
            "value":"Offline"
        }
      }

      this.getGroupUsers(filter)       
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
