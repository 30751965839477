import { IndustryService } from './../../../services/industry.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, of } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Industry } from '../../../models/industry';

@Component({
  selector: 'industry-select',
  templateUrl: './industry-select.component.html',
  styleUrls: ['./industry-select.component.scss']
})
export class IndustrySelectComponent implements OnInit {

  @Input() value: number;

  @Input() readonly: boolean = false;

  @Output() valueChange = new EventEmitter<number>();

  @Input() addButton: boolean = true;

  public options: Observable<Industry[]>;

  public placeholder: string;

  constructor(private industryService: IndustryService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    this.options = this.industryService.getAll();
    this.placeholder = this.hasPermission() && this.addButton ? "Select or add Industry" : "Select Industry";
  }

  openModal(content: any): void {
    this.modalService.open(content);
  }

  onValueChanged() {
    this.valueChange.emit(this.value);
  }

  onDataSubmittedSuccess(result: { response: Industry, message: string }): void {
    this.toastr.success(result.message);
    this.options = concat(this.options, of([result.response]));
    this.value = result.response.id;
    this.onValueChanged();
    this.modalService.dismissAll();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalService.dismissAll();
  }

  onCancel(): void {
    this.modalService.dismissAll();
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.CUSTOMER_INDUSTRY, [PermissionConstants.CREATE, PermissionConstants.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.CUSTOMER_MODULE, [PermissionConstants.GLOBAL]);
  }
}
