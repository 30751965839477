<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter [searchFilters]="searchFilters" [defaultFilters]="defaults" [columns]="columns"
                                     [expanded]="expanded"
                                     (apply)="applyFilter($event)"
                                     [export]="true"
                                     [module]="module"
                                     (exportButtonClick)="bulkexport()">

                        <ng-template filterControl [column]="'area_code'" let-row="row">
                            <area-code-select class="ng-select-sm" [(value)]="row.value"></area-code-select>
                        </ng-template>
                        <ng-template filterControl [column]="'customer'" let-row="row">
                            <app-customer-select class="ng-select-sm" [(value)]="row.value"
                                                 (change)="dataFilter.controlValueChanged('customer')">
                            </app-customer-select>
                        </ng-template>
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue"
                                           (change)="dataFilter.controlValueChanged('suborg')">
                            </suborg-select>
                        </ng-template>
                        <ng-template filterControl [column]="'forward_bin_sid_resolved'" let-row="row">
                            <forward-bin-select class="ng-select-sm" [(value)]="row.value"
                                [suborgId]="row.dependentValue ? row.dependentValue : suborg_id">
                            </forward-bin-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                                       [scrollbarH]="scrollbarH"
                                       class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [rows]="rows"
                                       [selectionType]="SelectionType.single"
                                       (select)="onSelect($event)"
                                       [count]="page.count"
                                       [externalPaging]="true"
                                       [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit"
                                       trackByProp="sid"
                                       (page)="setPage($event)" (sort)="onSort($event)"
                                       [sorts]="[{prop:'purchased_on',dir:'desc'}]">

                            <ngx-datatable-column name="Number" prop="did">
                                <ng-template let-did="value" ngx-datatable-cell-template>
                                    {{did | phoneNumber }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Number Type" prop="did_type"
                                                  [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Area Code" prop="area_code">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Incoming Route" prop="forward_bin_name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.forward_bin_name}}</div>
                                    <div class="bold" *ngIf="row.forward_number">
                                      <span *ngFor="let num of row.forward_number.split(',') as numList; let i = index">
                                          <span>{{num | phoneNumber}}</span>
                                          <span *ngIf="i !== numList.length-1">, </span>
                                      </span>
                                  </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Purchased On" prop="purchased_on">
                                <ng-template let-purchase="value" ngx-datatable-cell-template>
                                    {{purchase | dateTz:'MM/DD/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Call Duration" prop="call_duration">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value || 0 }} Sec
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Call Count" prop="call_count">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value || 0 }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg"
                                                  [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.customer_name}}</div>
                                    <div>Sub org : {{row.suborg_name}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && !isSuperAdmin" name="Suborg" prop="suborg_name">
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                             let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                                     [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                     [size]="pageSize" [count]="rowCount"
                                                     [hidden]="!((rowCount / pageSize) > 1)"
                                                     (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="warehouse-details">
                        <div class="d-flex flex-row mt-2">
                            <h5 class="item-title m-0">{{selected.did | phoneNumber }}</h5>
                            <div class="d-flex justify-content-end align-items-start">
                                <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3"
                                        (click)="closeDetailedView()">
                                    <i class="ft-x"></i>
                                </button>
                            </div>
                        </div>
                        <div class="group mt-3">
                            <div class="group-header d-flex justify-content-between">
                                Overview
                            </div>
                            <div class="group-body">
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Internal Id</label>
                                    <label class="item-label-value">{{selected.sid}}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Name</label>
                                    <label class="item-label-value">{{selected.name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected.did_type">
                                    <label class="col-md-3 p-0 item-label">Phone Number Type</label>
                                    <label class="item-label-value">{{selected.did_type}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected.area_code">
                                    <label class="col-md-3 p-0 item-label">Area Code</label>
                                    <label class="item-label-value">{{selected.area_code}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected.purchased_on">
                                    <label class="col-md-3 p-0 item-label">Purchased On</label>
                                    <label
                                           class="item-label-value">{{selected.purchased_on | dateTz}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected.created_by">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value">{{selected.created_by}}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Call Duration</label>
                                    <label class="item-label-value">{{selected.call_duration || 0}} Seconds</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Call Count</label>
                                    <label class="item-label-value">{{selected.call_count || 0}}</label>
                                </div>
                                <div class="ov-row" *ngIf="isSuperAdmin">
                                    <label class="col-md-3 p-0 item-label">Tenant</label>
                                    <label class="item-label-value">{{selected.customer_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="isSuperAdmin">
                                    <label class="col-md-3 p-0 item-label">Sub Org</label>
                                    <label class="item-label-value">{{selected.suborg_name}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="group mt-2">
                            <div class="group-header">
                                Forward Number
                            </div>
                            <div class="group-body" *ngIf="selected.forward_number">
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Internal Id</label>
                                    <label class="item-label-value">{{selected.forward_bin_sid}}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Forward Number</label>
                                    <label class="item-label-value">
                                      <span class="badge bg-light-secondary mr-1"
                                              *ngFor="let num of selected.forward_number.split(',')">
                                            {{num | phoneNumber}}
                                        </span>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="selected.forward_bin_name">
                                    <label class="col-md-3 p-0 item-label">Forward Number Name</label>
                                    <label class="item-label-value">
                                        <a *ngIf="hasBinViewInfoPermission() else binGeneral"
                                           target="_blank"
                                           [routerLink]="['/ring2voice/incoming-routes', selected.forward_bin_sid_resolved,'view']"
                                           class="text-info">
                                            {{selected.forward_bin_name}}
                                        </a>
                                    </label>
                                    <ng-template #binGeneral><span>{{selected.forward_bin_name}}</span></ng-template>
                                </div>
                            </div>
                            <div class="group-body" *ngIf="!selected.forward_number">
                                {{selected.did}} not assigned to any Forward Number
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
