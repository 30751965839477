import { GridConstants } from './../../../core/constants/grid.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { StaticFile } from './../../../core/models/files.models';
import { HttpClient } from '@angular/common/http';
import { Item, ItemWrapper, ItemStock, ItemWareHouseStock } from './../models/items';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { DataFilterResponse, GFColumn, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { ItemDataHelper } from '../helpers/item-data.helpers';
import { Kit } from '../models/kit.models';

@Injectable({
    providedIn: 'root'
})
export class ItemService extends BaseService {

    constructor(private http: HttpClient, private ra: ResourceAccessService) {
        super();
    }

    getAll(option: any, offset: number = 0, limit: number = this.dataLimit, suborg_id:number = this.suborgId) {
        let url = `${this.baseURL}/items/filter/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
        return this.http.post<DataFilterResponse<ItemWrapper[]>>(url, option);
    }

    getById(id: number) {
        return this.http.get<ItemWrapper>(`${this.baseURL}/items/${id}/`);
    }

    create(item: Item) {
        return this.http.post<Item>(`${this.baseURL}/items/`, item);
    }

    update(id: number, item: Item) {
        return this.http.put<Item>(`${this.baseURL}/items/${id}/`, item);
    }

    patch(id: number, item: any) {
        return this.http.patch<Item>(`${this.baseURL}/items/${id}/`, item);
    }

    delete(id: number) {
        return this.http.delete(`${this.baseURL}/items/${id}/`);
    }

    getItemImages(itemId: number) {
        return this.http.get<StaticFile[]>(`${this.baseURL}/items/${itemId}/images/`);
    }

    getItemStock(itemid: number) {
        return this.http.get<ItemStock[]>(`${this.baseURL}/items/stock/${itemid}/`);
    }

    createItemStock(itemstock: ItemStock) {
        return this.http.post<ItemStock>(`${this.baseURL}/items/stock/`, itemstock);
    }

    updateItemStock(id: number, itemstock: ItemStock) {
        return this.http.put<ItemStock>(`${this.baseURL}/items/stock/${id}/`, itemstock);
    }

    getFilterColumns(): GFColumn[] {
        let columns: GFColumn[] = ItemDataHelper.FilterColumns;
        return this.ra.filterGFColumns(columns, GridConstants.INV_ITEMS);
    }

    getSearchColumns(): GFilterParam[] {
        let columns: GFilterParam[] = ItemDataHelper.SearchFilters;
        return this.ra.filterSearchColumns(columns, GridConstants.INV_ITEMS);
    }

    convertToKit(itemid: number) {
        return this.http.get<Kit>(`${this.baseURL}/items/${itemid}/convert_to_kit/`);
    }

    importItems(file: any) {
        let formData = new FormData();
        formData.append("file", file.file);
        formData.append("map_object", JSON.stringify(file.columns_map));
        formData.append('customer', this.customerId.toString());
        formData.append('option', file.option.toString());
        return this.http.post(`${this.baseURL}/items/import/`, formData);
    }

    createItemWareHouseStock(itemid:number, models: ItemWareHouseStock[]) {
        return this.http.post<ItemWareHouseStock[]>(`${this.baseURL}/items/${itemid}/warehouse/stock/`, models);
    }

    updateItemWareHouseStock(itemid:number, models: ItemWareHouseStock[]) {
        return this.http.put<ItemWareHouseStock[]>(`${this.baseURL}/items/${itemid}/warehouse/stock/`, models);
    }

    deleteItemWareHouseStock(itemid:number, id: number) {
        return this.http.delete(`${this.baseURL}/items/${itemid}/warehouse/stock/?id=${id}`);
    }

    getItemWarehouseStock(itemId: number) {
        return this.http.get<ItemWareHouseStock[]>(`${this.baseURL}/items/${itemId}/warehouse/stock/`);
    }

    getItemStockInfo(itemId: number){
        return this.http.get<any>(`${this.baseURL}/items/${itemId}/info/stock/`);
    }
}
