import { PaymentTermsService } from './../../../services/payment-terms.service';
import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { PaymentTerm } from '../../../models/payment-terms';

@Component({
  selector: 'payment-term-form',
  templateUrl: './payment-term-form.component.html',
  styleUrls: ['./payment-term-form.component.scss']
})
export class PaymentTermFormComponent implements OnInit, OnDestroy {

  public model: PaymentTerm = new PaymentTerm();

  @Input() suborg_id: number = 0;

  @Input() paymentTermId: number = 0;

  @Output() onSuccess = new EventEmitter<{ response: PaymentTerm, message: string }>();

  @Output() onError = new EventEmitter<any>();

  @Output() onCancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private service: PaymentTermsService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.paymentTermId > 0) {
      this.getSub$ = this.service.getById(this.paymentTermId).subscribe(paymentTerm => {
        this.model = paymentTerm;
      });
    }
  }

  onSubmit(): void {

    let api: Observable<PaymentTerm>;

    if (this.paymentTermId > 0)
      api = this.service.update(this.paymentTermId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;
      if(this.suborg_id > 0){
        this.model.suborg = this.suborg_id;
      }
      api = this.service.create(this.model);
    }

    this.postSub$ = api.subscribe(
      response => {
        let msg = this.paymentTermId > 0 ? 'Payment Term info updated' : 'New Payment Term created';
        this.onSuccess?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.onError?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void {
    this.onCancel?.emit();
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
    this.postSub$?.unsubscribe();
  }
}
