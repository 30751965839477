import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogCartService } from '../../services/catalog-cart.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-cart-view',
  templateUrl: './side-cart-view.component.html',
  styleUrls: ['./side-cart-view.component.scss']
})
export class SideCartViewComponent implements OnInit {


  @Input() showCanvas: boolean = false;
  @Output() cartClosed = new EventEmitter<boolean>()

  public fileUrl: string = environment.apiURL + '/static/files/';

  cartItems: any[] = [];

  subTotal: number = 0;
  shipping: number = 0;
  total: number = 0;


  constructor(private catlogCartService: CatalogCartService) { }

  ngOnInit(): void {

    this.catlogCartService.currentCartItems.subscribe(res => {

      this.cartItems = res;
      console.log('cartItems', this.cartItems)

      this.findTotal()
    })
  }

  onCarClosed(): void {
    this.cartClosed.emit(true);
  }

  onCartItemDelete(index: number) {
    this.cartItems.splice(index, 1)
    this.findTotal();
    localStorage.setItem('catalog-cart-items', JSON.stringify(this.cartItems))
  }

  onChangeQuantity(item: any, tp) {


    if (tp == 0) //add
    {
      item.quantity = item.quantity + 1;
    }
    else {
      if (item.quantity != 1) {
        item.quantity = item.quantity - 1;
      }

    }
    this.findTotal();
    localStorage.setItem('catalog-cart-items', JSON.stringify(this.cartItems))
  }

  findTotal() {

    this.total = this.cartItems.reduce((acc, item) => {

      return acc + (item.quantity * item.price);
    }, 0)

  }

}
