<section class="dashlet-container d-flex flex-row flex-wrap mb-2" *ngIf="dashlet">
    <div class="status-tile" (click)="setFilter('billable','false')">
        <div class="card m-0 h-100 gradient-mint">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Non_billable || 0}}</h3>
                            <span>Not Billable</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('billing status',billingStatusEnum.Un_billed)">
        <div class="card m-0 h-100 unbilled_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet.Un_billed || 0}}</h3>
                            <span>Unbilled</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('billing status',billingStatusEnum.Invoiced)">
        <div class="card m-0 h-100 invoiced_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet.Invoiced || 0}}</h3>
                            <span>Invoiced</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('billing status',billingStatusEnum.Paid)">
        <div class="card m-0 h-100 paid_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet.Paid || 0}}</h3>
                            <span>Paid</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('billing status',billingStatusEnum.Overdue)">
        <div class="card m-0 h-100 overdue_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet.Overdue || 0}}</h3>
                            <span>Overdue</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter [searchFilters]="searchFilters" [defaultFilters]="defaults" [columns]="columns" [newButton]="UIPermissions.create" [expanded]="expanded" (newButtonClick)="openModal()" (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'tenant_customer'" let-row="row">
                            <tenant-customer-select [(value)]="row.value"></tenant-customer-select>
                        </ng-template>
                        <ng-template filterControl [column]="'created by'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" (select)="onSelect($event)" [count]="page.count"
                            [sorts]="[{prop:'assigned_date',dir:'desc'}]" [externalPaging]="true" [offset]="page.offset" [externalSorting]="true" [limit]="page.limit" (page)="setPage($event)" (sort)="onSort($event)">
                            <ngx-datatable-column name="Timesheet Date" prop="assigned_date">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.assigned_date | date:'MM/dd/YYYY h:mm a'}}</div>
                                    <div>{{row.unique_number}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Customer" prop="customer_name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="customer-details">
                                        <div class="cname">{{row.customer_name}}</div>
                                        <div *ngIf="row.location_name" class="location">
                                            Location : {{row.location_name}}
                                        </div>
                                        <div *ngIf="row.tenant_customer_contact" class="location">
                                            Contact : {{row.contact_name}}
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="ST #" prop="ticket_number"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Comments" prop="comments" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Total Time" prop="time" [sortable]="false">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <span *ngIf="value">{{getTime(value)}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Billing" prop="is_billable" [sortable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div>{{value?'Billable':'Not Billable'}}</div>
                                    <div *ngIf="getBillingStatus(row.billing_status) as stat" [class]="stat.class">
                                        {{stat.key}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Total Billable" prop="total_amount">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <span *ngIf="value">$ {{value}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by" [sortable]="false">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <user-card [name]="value"></user-card>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && (UIPermissions.delete || UIPermissions.edit)" name="Action" prop="id" [sortable]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                          <i class="ft-more-vertical text-primary"></i>
                                      </button>
                                        <div ngbDropdownMenu>
                                            <button class="w-100" *ngIf="UIPermissions.edit" (click)="openModal(id)" ngbDropdownItem>
                                              <i class="ft-edit mr-1"></i> Edit
                                          </button>
                                            <button class="w-100 bg-light-danger" *ngIf="UIPermissions.delete" (click)="deleteRow(id)" ngbDropdownItem>
                                              <i class="ft-trash-2 mr-1"></i> Delete
                                          </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="warehouse-details">
                        <div class="d-flex flex-row mt-2">
                            <h5 class="item-title m-0">
                                {{selected.unique_number}}
                            </h5>
                            <div class="d-flex justify-content-end align-items-start">
                                <a *ngIf="UIPermissions.edit" (click)="openModal(selected.id)" ngbTooltip="Edit" class="btn btn-sm bg-light-secondary">
                                    <i class="ft-edit"></i> Edit
                                </a>
                                <button *ngIf="UIPermissions.delete" class="btn btn-sm bg-light-danger ml-1" (click)="deleteRow(selected.id)">
                                  <i class="ft-trash-2 mr-1"></i> Delete
                              </button>
                                <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                  <i class="ft-x"></i>
                              </button>
                            </div>
                        </div>
                        <div class="group mt-3">
                            <div class="group-header d-flex justify-content-between">
                                Overview
                            </div>
                            <div class="group-body">
                                <div class="ov-row" *ngIf="selected?.customer_name">
                                    <label class="col-md-3 p-0 item-label">Customer</label>
                                    <label class="item-label-value">{{selected.customer_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.location_name">
                                    <label class="col-md-3 p-0 item-label">Customer Location</label>
                                    <label class="item-label-value">{{selected.location_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.tenant_customer_contact">
                                    <label class="col-md-3 p-0 item-label">Customer Contact</label>
                                    <label class="item-label-value">{{selected.contact_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.ticket_number">
                                    <label class="col-md-3 p-0 item-label">Service Ticket #</label>
                                    <label class="item-label-value">{{selected.ticket_number}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.assigned_date">
                                    <label class="col-md-3 p-0 item-label">Timesheet Date</label>
                                    <label class="item-label-value">{{selected.assigned_date | date:'MM/dd/YYYY h:mm a'}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.time">
                                    <label class="col-md-3 p-0 item-label">Total Time</label>
                                    <label class="item-label-value">{{getTime(selected.time)}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.comments">
                                    <label class="col-md-3 p-0 item-label">Comments</label>
                                    <label class="item-label-value">{{selected.comments}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.created_on">
                                    <label class="col-md-3 p-0 item-label">Created On</label>
                                    <label class="item-label-value">{{selected.created_on | date:'MM/dd/YYYY'}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.created_by">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value">{{selected.created_by}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="group mt-2">
                            <div class="group-header">Billing Details</div>
                            <div class="group-body">
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Billable</label>
                                    <label class="item-label-value">
                                        <i *ngIf="selected.is_billable else notBillable" class="ft-check-circle text-success"></i>
                                        <ng-template #notBillable>
                                            <i class="ft-x-circle text-danger"></i>
                                        </ng-template>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.total_amount">
                                    <label class="col-md-3 p-0 item-label">Total Billable Amount</label>
                                    <label class="item-label-value">${{selected.total_amount}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.billing_status">
                                    <label class="col-md-3 p-0 item-label">Billing Status</label>
                                    <label class="item-label-value">
                                        <span *ngIf="getBillingStatus(selected.billing_status) as stat" [class]="stat.class">
                                            {{stat.key}}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #formModal let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h5 class="modal-title">{{timeSheetId > 0 ? 'Edit Timesheet' : 'New Timesheet'}}</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <time-sheet-form [uniqueId]="timeSheetId" (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()"></time-sheet-form>
            </div>
        </ng-template>

    </div>
</section>
