<div class="datepicker-container">
    <div class="d-flex flex-row">
        <div class="picker-group mr-1">
            <label>{{rangePicker ? startRangeLabel : label}}</label>
            <div class="picker d-flex flex-row">
                <div class="picker-input">
                    <ng-select placeholder="HH" [clearable]="false" [(ngModel)]="startRange.hour" [items]="hours" [searchFn]="customSearch" class="ng-select-noborder" (search)="customSelectSearch($event,startRange,'hour')" (change)="inputChange()">
                        <ng-template ng-label-tmp let-opt="item">
                            {{padNumber(opt)}}
                        </ng-template>
                        <ng-template ng-option-tmp let-opt="item">
                            {{padNumber(opt)}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="picker-input">
                    <ng-select placeholder="MM" [clearable]="false" [(ngModel)]="startRange.minute" [items]="minutes" [searchFn]="customSearch" class="ng-select-noborder" (search)="customSelectSearch($event,startRange,'minute')" (change)="inputChange()">
                        <ng-template ng-label-tmp let-opt="item">
                            {{padNumber(opt)}}
                        </ng-template>
                        <ng-template ng-option-tmp let-opt="item">
                            {{padNumber(opt)}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="picker-input">
                    <ng-select [(ngModel)]="startRange.meridian" [clearable]="false" class="ng-select-noborder" (change)="inputChange()">
                        <ng-option selected value="AM">AM</ng-option>
                        <ng-option value="PM">PM</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div *ngIf="rangePicker" class="picker-group ml-1">
            <label>{{endRangeLabel}}</label>
            <div class="picker d-flex flex-row">
                <div class="picker-input">
                    <ng-select placeholder="HH" [clearable]="false" [(ngModel)]="endRange.hour" [items]="hours" [searchFn]="customSearch" class="ng-select-noborder" (search)="customSelectSearch($event,endRange,'hour')" (change)="inputChange()">
                        <ng-template ng-label-tmp let-opt="item">
                            {{padNumber(opt)}}
                        </ng-template>
                        <ng-template ng-option-tmp let-opt="item">
                            {{padNumber(opt)}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="picker-input">
                    <ng-select placeholder="MM" [clearable]="false" [(ngModel)]="endRange.minute" [items]="minutes" [searchFn]="customSearch" class="ng-select-noborder" (search)="customSelectSearch($event,endRange,'minute')" (change)="inputChange()">
                        <ng-template ng-label-tmp let-opt="item">
                            {{padNumber(opt)}}
                        </ng-template>
                        <ng-template ng-option-tmp let-opt="item">
                            {{padNumber(opt)}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="picker-seperator">
                    <ng-select [(ngModel)]="endRange.meridian" [clearable]="false" class="ng-select-noborder" (change)="inputChange()">
                        <ng-option selected value="AM">AM</ng-option>
                        <ng-option value="PM">PM</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
</div>
