
<section class="add-category-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{categoryId > 0 ? 'Edit Category' : 'New Category'}}</h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <app-item-category-form [categoryId]="categoryId" (onSuccess)="onDataSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()"></app-item-category-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>