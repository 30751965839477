import { Contact } from './../models/contacts.models';
import { ModuleConstants } from './../../../core/enums/common.enum';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { SerialNumberRequest } from '../models/warehouse.models';

@Injectable({
    providedIn: 'root'
})
export class InvCommonService extends BaseService {

    constructor(private http: HttpClient) { super() }

    getContacts(module: ModuleConstants, unique_id: number) {
        return this.http.get<Contact[]>(`${this.baseURL}/contacts/${module}/${unique_id}/`);
    }

    createContact(contact: Contact) {
        return this.http.post<Contact>(`${this.baseURL}/contacts/`, contact);
    }

    updateContact(id: number, contact: Contact) {
        return this.http.put<Contact>(`${this.baseURL}/contacts/${id}/`, contact);
    }

    deleteContact(id: number) {
        return this.http.delete<Contact>(`${this.baseURL}/contacts/${id}/`);
    }


    //Generate Serial Number
    generateSlNo(data:SerialNumberRequest) {
        return this.http.post<Contact>(`${this.baseURL}/generate/serial_number/`, data);
    }

}
