<section>
    <div class="card col-12" (click)="toggleBanner()" [hidden]="!hideBanner" style="background-color: #380e7f;cursor: pointer;">
        <div class="row p-2">
            <div class="col-11">
                <span class="banner-hidden-heading">View More About Payment Details</span>
            </div>
            <div class="col-1 d-flex align-items-center">
                <i class="fa fa-chevron-down text-white" [ngClass]="hideBanner?'':'fa-rotate-180'" aria-hidden="true"></i>
            </div>
        </div>
    </div>
    <div class="card p-0 col-12 mb-0" [ngClass]="hideBanner?'hideBanner':'showBanner'">
        <div class="card-body banner">
            <div class="col-5 centered-div">
                <div>
                    <p>Payment Details includes all cards and other methods.</p>
                    <small>
                       Terms and conditions...
                    </small>
                </div>
            </div>

            <div class="col-2 centered-div"></div>
            
            <div class="col-5 centered-div">
                <iframe width="300" height="215" class="p-4" src="https://www.youtube.com/embed/T_4nyno2r0o?si=LJW5lgZ-Rsz_jA9o" title="Simplyfuse Intro Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div class="togglebanner" [hidden]="hideBanner" (click)="toggleBanner()"><i class="fa fa-chevron-down text-white" [ngClass]="hideBanner?'':'fa-rotate-180'" aria-hidden="true"></i></div>
    
    <div class="cards_section">

        <div class="mycards" *ngFor="let card of myCards; index as i">

            <div class="d-flex justify-content-between">
            <div *ngIf="card.payment_type == paymentMethods.CARD" class="card_icon" [ngSwitch]="card.card_type">
                <img *ngSwitchCase="cardTypes.VISA"  width="55" height="20" src="/assets/img/svg/cc/visa.svg" />
                <img *ngSwitchCase="cardTypes.MASTRO"  width="60" height="25" src="/assets/img/svg/cc/master.svg" />
                
                <img *ngSwitchCase="cardTypes.AMEX"  width="60" height="25" src="/assets/img/svg/cc/amex.svg" />
                <img *ngSwitchCase="cardTypes.DISCOVER"  width="60" height="25" src="/assets/img/svg/cc/discover.svg" />
                <img *ngSwitchCase="cardTypes.DONORSFUND"  width="85" height="25" src="/assets/img/svg/cc/donors_fund.svg" />
                <img *ngSwitchCase="cardTypes.EBT"  width="60" height="25" src="/assets/img/svg/cc/ebt.svg" />
                <img *ngSwitchCase="cardTypes.PLEDGER"  width="60" height="25" src="/assets/img/svg/cc/pledger.svg" />
                <img *ngSwitchCase="cardTypes.OJC"  width="60" height="25" src="/assets/img/svg/cc/ojc.svg" />
                <img *ngSwitchDefault  width="60" height="25" src="/assets/img/svg/cc/credit_card.svg" />
            </div>
            <div *ngIf="card.payment_type == paymentMethods.CHECK" class="card_icon">
                
                <img   width="60" height="25" src="/assets/img/svg/cc/bank.svg" />
            </div>
            <div class="is_default form-group ">
                
                <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                    <input type="checkbox"  [(ngModel)]="card.is_default"
                        name="is_mail_send" class="custom-control-input"
                        id="color-switch-{{i}}"  (change)="changeIsDefaultStatus(card)">
                    <label class="custom-control-label mr-1"
                        for="color-switch-{{i}}">
                        <span></span>
                    </label>
                </div>
                <small class="font-small-2">Default</small>
            </div>
            </div>

            

            <div *ngIf="card.payment_type == paymentMethods.CARD" class="text-muted m-1 mt-2 mb-2">{{card['masked_card_number']}}</div>
            <div *ngIf="card.payment_type == paymentMethods.CHECK" class="text-muted m-1 mt-2 mb-2">{{card['masked_account_number']}}</div>
            <span class="text-muted m-1 mb-2 font-small-3"><!--Payment method Id--> xxxxxxxxxxxxxxxxxx{{card['payment_method_id'] | slice:-4}}</span>

            <div class="actions">

                <div>
                    <a (click)="editCard(card.id)"><i class="ft-edit-2" style="color: #975AFF;"></i></a> | 
                    <a (click)="deleteCard(card.id)"><i class="ft-trash-2" style="color:#FF8181"></i></a>
                </div>
            

            </div>
        </div>

        <div class="mycards d-flex flex-column justify-content-center align-items-center">
            <div class="addcards">
             <a (click)="addNewCard()"><img  width="55" height="55" src="/assets/img/svg/cc/add_new.svg" class="m-0" />
                <br/><small><b>Add New</b></small></a>
            </div>
            <p class="text-muted m-1 mb-2 font-small-3">Add New Method</p>
        </div>

    </div>

    </section>


    <ng-template #AddModal let-c="close" let-d="dismiss">
        <div class="modal-header border-0">
            <h5 class="modal-title">Add Payment Method</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body pt-0">
           <add-card (cancel)="closeAddModal()" [customerId]="customerId" [customerSuborg]="customerSuborg" (success)="onAdded()"></add-card>
        </div>
    </ng-template>

    <ng-template #EditModal let-c="close" let-d="dismiss">
        <div class="modal-header border-0">
            <h5 class="modal-title">Edit Payment Method</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body pt-0">
            <add-card (cancel)="closeEditModal()" [cardId]="selectedCardId" (success)="onUpdated()"></add-card> 
        </div>
    </ng-template>
