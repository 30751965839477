<section class="dashlet-container d-flex flex-row flex-wrap mb-2" *ngIf="dashlet">
    <div class="status-tile" (click)="setFilter('status',STStatus.New)">
        <div class="card m-0 h-100 new_ticket_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.new_tickets || 0}}</h3>
                            <span>New Tickets</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status',STStatus.In_Progress)">
        <div class="card m-0 h-100 in_progress_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.in_progress || 0}}</h3>
                            <span>In Progress</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status',STStatus.Waiting)">
        <div class="card m-0 h-100 waiting_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body text-left">
                            <h3 class="mb-0">{{dashlet?.waiting || 0}}</h3>
                            <span>Waiting for Customer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status',STStatus.Escalated_To_Manager)">
        <div class="card m-0 h-100 esc_to_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.escalated_to_manager || 0}}</h3>
                            <span>Escalated to Manager</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status',STStatus.Closed)">
        <div class="card m-0 h-100 closed_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.closed || 0}}</h3>
                            <span>Closed Tickets</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('billing_status',billingStatus.Un_Billed)">
        <div class="card m-0 h-100 un_billed_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet.un_billed || 0}}</h3>
                            <span>UnBilled</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('billing_status',billingStatus.Partially_Invoiced)">
        <div class="card m-0 h-100 partially_invoiced_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet.partially_invoiced || 0}}</h3>
                            <span>Partially Invoiced</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('billing_status',billingStatus.Fully_Invoiced)">
        <div class="card m-0 h-100 fully_invoiced_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet.fully_invoiced || 0}}</h3>
                            <span>Fully Invoiced</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('billing_status',billingStatus.Not_Billable)">
        <div class="card m-0 h-100 not_billable_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet.not_billable || 0}}</h3>
                            <span>Not Billable</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="common-grid-view">
  <st-list
        #stList
    >
  </st-list>
</section>
