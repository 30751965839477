<section>
    <div class="stock-info" *ngIf="showStockInfo">
        <div class="info-div">
            <label class="info-label">Total Stock</label>
            <label class="info-label-value">{{stockInfo.total_stock}}</label>
        </div>
        <div class="info-div">
            <label class="info-label">Total Commited on Assemblies</label>
            <label class="info-label-value">{{stockInfo?.commited_on_assemblies}}</label>
        </div>
        <div class="info-div">
            <label class="info-label">Total Draft on Assemblies</label>
            <label class="info-label-value">{{stockInfo?.draft_on_assemblies}}</label>
        </div>
        <div class="info-div">
            <label class="info-label">Total Available Stock</label>
            <label class="info-label-value">{{stockInfo?.available_stock}}</label>
        </div>
    </div>
    <table class="kit-stock-table w-100">
        <thead>
            <tr>
                <th width='25%'>WareHouse</th>
                <th width='25%'>Warehouse Location</th>
                <th style="text-align: center;" width='20%'>Quantity</th>
                <th style="text-align: center;" width='20%'>Available Quantity</th>
                <th>Action</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of rows | orderBy:'warehouse_name';let i = index">
                <td>
                    {{row.warehouse_name}}
                </td>
                <td>
                    {{row.location_name}}
                </td>
                <td style="text-align: center;">{{row.quantity}}</td>
                <td style="text-align: center;">{{row.available_quantity}}</td>
                <td>
                    <div ngbDropdown container="body">
                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                        <i class="ft-more-vertical text-primary"></i>
                        </button>
                        <div ngbDropdownMenu>
                            <button  class="w-100" *ngIf="hasPermission(Resource.INV_LABELS_PRINT,Permissions.PRINT)" (click)="printView(row.warehouse, row.warehouse_location)"  ngbDropdownItem>
                            <i class="fa ft-printer mr-1"></i> Print Label
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2"> Total Quantity</td>
                <td colspan="1" style="text-align: center;">{{total_quantity}}</td>
                <td colspan="1" style="text-align: center;">{{total_available_quantity}}</td>
                <td></td>
            </tr>
        </tbody>
    </table>
</section>
