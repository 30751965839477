import { Injectable } from '@angular/core';
import { Invitation, Referral, Session, SessionState } from 'sip.js';
import { SipConnectionService } from './sip-connection.service';
import { UserStatusUpdateRequest, callDetailsResponse } from '../models/call-center.models';
import { SubSink } from 'subsink';
import { CallCenterService } from './call-center.service';
import { ToastrService } from 'ngx-toastr';
import { StatusValueEnum } from 'src/app/core/enums/common.enum';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CallcenterSharedService } from './call-center-shared.service';

@Injectable({
  providedIn: 'root'
})
export class sipInboundFunctionService {

  public connection: any

  public allowClearCallQ: boolean = false;

  private _callQ = new BehaviorSubject<any[]>([]);

  public callQ$ = this._callQ.asObservable();

  public acceptedCallQ: any[] = [];

  public isIncomingCall: boolean = false;

  public isCall: boolean;

 // public isActiveCall: boolean;

  public totalCallCount: Subject<number> = new Subject<number>();

  public ringAudio = new Audio();

  public declineTone = new Audio();

  public acceptTone = new Audio();

  public callLogOpen: boolean = true;

  public selectedCaller: any;

  public callId: string;

  public subs: SubSink = new SubSink();

  public iscallcenterEnabled: boolean = true;

  public incoming_callDetails: callDetailsResponse = new callDetailsResponse();

  public block_id: number;

  public remoteAudio = new Audio();

  public isCallTerminated: Subject<boolean> = new Subject<boolean>();

  public isMuted: boolean = false;

  constructor(
    private callCenterService: CallCenterService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    public callcenterSharedService: CallcenterSharedService
  ) { }


  inviteCall() {

    this.connection = this.callcenterSharedService.connection
    let tis = this;

    this.callcenterSharedService.connection.delegate = {

      onInvite(invitation: Invitation): void {

        console.log('invitation', invitation)
        console.log("****call coming in****");
        const incomingSession: Session = invitation;
        tis.allowClearCallQ = false;
        if (invitation.request.headers['X-Call-Status'] && invitation.request.headers['X-Call-Status'][0].raw == 'unholded') {//for Unhold feature
          tis.autoAcceptifUnholdInvitation(invitation);
        } else {
          tis.pushtoIncomingCallQ(invitation);
        }
        tis.callCenterService.isActiveCallInbound = false;
        tis.isIncomingCall = false;
        tis.isIncomingCall = false;
        tis.activeCallCount();
        if (localStorage.getItem('isOnCall') == 'false') {
          tis.ring();
        }
        tis.checkWhichSectionTobeSelected();
        if (tis.callCenterService.isalwaysOpen && tis.callLogOpen) {
          tis.callLogOpen = false;
          this.callCenterService.popupOpenStatus.next({ isOpen: true, selectedTab: 1, })
          //tis.cdr.markForCheck();
        }
        // Setup incoming session delegate
        incomingSession.delegate = {
          // Handle incoming REFER request.
          onRefer(referral: Referral): void {
            console.log("******entered referrel section*******");
            // ...
          }
        };

        // Handle incoming session state changes.
        incomingSession.stateChange.addListener((newState: SessionState) => {

          switch (newState) {

            case SessionState.Initial:
              console.log('Initial');
              break;

            case SessionState.Establishing:
              console.log('Session is establishing.')
              // tis.ringAudio.pause();
              break;

            case SessionState.Established:


              tis.callAcceptTone()
              tis.callCenterService.agentStatusChange.next({ status: StatusValueEnum.ON_CALL, updateStatus: true })
              tis.remoteAudio = document.getElementById('audio') as HTMLAudioElement;
              tis.remoteAudio.srcObject = invitation.sessionDescriptionHandler['remoteMediaStream'];
              tis.remoteAudio.play();
              //tis.cdr.markForCheck();
              break;

            case SessionState.Terminated:
              console.log('Call Ended');
              if (!tis.isOnHold(incomingSession)) {//only enters if not on hold
                if (tis.callcenterSharedService.sip_details.status == 'On Call') {
                  tis.updateLastCallTime(incomingSession);
                }
                tis.isMuted = false;
                tis.callCenterService.isActiveCallInbound = false;
                tis.isIncomingCall = false;
                tis.isIncomingCall = false
                tis.allowClearCallQ = true;
                tis.clearCompletedCallsfromCallQ();
                tis.callDeclineTone();
                tis.activeCallCount().then(() => {
                  tis.ring();
                });
                tis.checkWhichSectionTobeSelected();
                tis.isCallTerminated.next(true)

                //tis.cdr.markForCheck();
              }

              break;
            default:
              break;
          }
          //tis.cdr.markForCheck();
        });

      }
    };

  }
  autoAcceptifUnholdInvitation(session) {
    this.clearCompletedCallsfromCallQ();
    this.movetoAcceptedCalls(session);
    this.selectedCaller = session;
    session.accept();
    this.callId = session.request.headers['X-Signalwire-Callsid'] ? session.request.headers['X-Signalwire-Callsid'][0].raw
      : null;
    this.getIncomingCallDetailsApi(this.callId);
    session.customValues.isOnGoingSt = this.callCenterService.isOnGoingStForHold;
    session.customValues.isOnGoingSo = this.callCenterService.isOnGoingSoForHold;
    // this.cdr.markForCheck();
  }

  clearCompletedCallsfromCallQ(endedWhileOnHold: boolean = false) {
    const callQ = this._callQ.value?.filter(invitation => invitation.state !== 'Terminated');
    this._callQ.next(callQ)
    // this.callQ = this.callQ?.filter(invitation => invitation.state !== 'Terminated');
    if (endedWhileOnHold) {
      // this.acceptedCallQ = this.acceptedCallQ.filter(call=> call.state!='Terminated');
      this.acceptedCallQ = this.acceptedCallQ.filter(call => call.state != 'Terminated');
      this.activeCallCount();
    } else {
      this.acceptedCallQ?.forEach((call, index) => {
        if (call.state === 'Terminated' && !call.customValues.isCallOnHold && !call.customValues.isOnGoingSt && !call.customValues.isOnGoingSo) {
          this.acceptedCallQ.splice(index, 1);
          return false;
        }
        return true;
      });
      this.activeCallCount();
    }
    // this.cdr.markForCheck();
  }

  async activeCallCount(callQ = this._callQ.value) {

    let callCount = 0;
    if (callQ && callQ.length > 0) {
      callQ.forEach(value => {
        // if(value.state=='Established'){
        //   this.isActiveCall=true;
        // }
        if (value.state == 'Initial') {
          this.isIncomingCall = true;
        }
        if (value.state == 'Initial' || value.state == 'Established') {
          callCount++;
        }
        if (callCount == 0 && value.state != 'Established') {
          this.isCall = false;
        }
        else { 
          this.isCall = true;
        }
      });
    }
    if (this.acceptedCallQ && this.acceptedCallQ.length > 0) {
      this.acceptedCallQ.forEach(call => {
        if (call.state == 'Established' || call.state == 'Establishing' || (call.customValues.isCallOnHold && call.state == 'Terminated')) {
          this.callCenterService.isActiveCallInbound = true;
          callCount++;
        } else {
          this.callCenterService.isActiveCallInbound = false;
         
        }
      });
    }
    else 
    {
      localStorage.setItem('isOnCall', 'false');
    }
    setTimeout(() => {
      
      if (!this.callCenterService.isActiveCallInbound) {

        localStorage.setItem('isOnCall', 'false');
        this.callCenterService.agentStatusChange.next({ status: StatusValueEnum.READY,updateStatus: true});  //need to handle

      }
    }, 500);

    this.totalCallCount.next(callCount);
    //this.cdr.markForCheck();
    return callCount;
  }


  movetoAcceptedCalls(session: any) {
    if (!session.customValues) {
      session.customValues = { 'isCallOnHold': false, 'callerName': '', 'isUnholded': false };
    }
    // this.acceptedCallQ.push(session);
    this.acceptedCallQ.push(session);
    // this.callQ = this.callQ?.filter(invt=> invt.id !== session.id);
    const callQ = this._callQ.value?.filter(invt => invt.id !== session.id);
    this._callQ.next(callQ)
  }

  pushtoIncomingCallQ(session) {

    const idExists = this._callQ.value.some(obj => obj.request.headers['X-Signalwire-Callsid'][0].raw === session.request.headers['X-Signalwire-Callsid'][0].raw);
    if (!idExists) {
      session.customValues = { 'isCallOnHold': false, 'callerName': '' };//adding new variables for hold&name
      // this.callQ.push(session);
      const currentValue = this._callQ.value;
      const updatedValue = [...currentValue, session];
      this._callQ.next(updatedValue);
      // this.callQ.push(session);
    }

  }

  ringSound() {
    this.ringAudio.src = "../../../../assets/ringtone/telephone-ringwav-14674.mp3"
    this.ringAudio.muted = false;
    this.ringAudio.loop = true;
    this.ringAudio.play();
  }

  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play();
  }

  ring() {

    debugger
    
    if (this.ringAudio) {
      this.ringAudio.pause();
    }
    if (this.isIncomingCall && !this.callCenterService.isActiveCallInbound  && !this.callCenterService.isActiveCallOutbound
       && (localStorage.getItem('isOnCall') == 'false') ) {
      this.ringSound();
    } else {
      this.ringAudio.pause();
    }
  }

  callAcceptTone() {
    this.acceptTone.src = "../../../../assets/ringtone/pickup tone.mp3"
    this.acceptTone.play();
  }

  checkWhichSectionTobeSelected() {

    var toBeSelected;
    var isAcceptedCall: boolean = false;

    this._callQ.value.forEach(call => {
      if (!toBeSelected && call.state == 'Initial') {
        toBeSelected = call;
      }
      if (call.state == 'Established') {
        isAcceptedCall = true;
      }
    });
    if (this.callLogOpen && toBeSelected && !isAcceptedCall) {
      this.selectedCaller = toBeSelected;
      if (this.selectedCaller.request.headers['X-Signalwire-Callsid']) {
        this.callId = this.selectedCaller.request.headers['X-Signalwire-Callsid'][0].raw;
        this.getIncomingCallDetailsApi(this.callId);

      } else {
        this.callId = null;
      }

    }
    // this.cdr.markForCheck();
  }

  getIncomingCallDetailsApi(callId = this.callId) {

    this.activeCallCount();
    let model: callDetailsResponse;
    if (callId) {
      this.subs.sink = this.callCenterService.getCallDetails(callId).subscribe(response => {

        if (response.status) {
          this.iscallcenterEnabled = true;
          this.incoming_callDetails = response;
          this.block_id = response.block_id ? response.block_id : null;
          if (this.selectedCaller.customValues) {
            this.selectedCaller.customValues.callerName = response.contact_name || null;
          }
        } else {
          this.iscallcenterEnabled = false;
          this.getDetailsofNonCallCenterNumber();

        }
        // this.cdr.markForCheck();
      });
    } else {
      this.incoming_callDetails.contacts_id = null;
      this.selectedCaller.customValues.callerName = ''
      model = null;
      // this.cdr.markForCheck();
    }
  }

  getDetailsofNonCallCenterNumber() { 
    this.activeCallCount();
    let toNumber = this.selectedCaller.request.headers['X-Dialed-Number'][0].raw || null;
    let fromNumber = this.selectedCaller.remoteIdentity.uri.user;
    this.subs.sink = this.callCenterService.getNonCallCenterDetails(toNumber, fromNumber).subscribe(resp => {
      this.incoming_callDetails = resp;
      this.incoming_callDetails.call_id = this.selectedCaller.request.headers['X-Signalwire-Callsid']?.[0]?.raw || null;
      if (this.selectedCaller.customValues) {
        this.selectedCaller.customValues.callerName = resp.contact_name || null;
      }
      // this.cdr.markForCheck();
    },
      (error) => {
        console.error(error);
        this.toastr.error("Error Occurred")
      });
  }

  isOnHold(session) {
    if (session.customValues.isCallOnHold) {
      return true;
    } else {
      return false;
    }
  }

  clearCallQ() //to clear all callq
  {
    this._callQ.next([]);
  }

  updateLastCallTime(endedCall) { 
    const isCallPresent = this.acceptedCallQ.includes(endedCall);
    if (isCallPresent) {
      let date = new Date();
      let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
      payload.call_end_time = this.datePipe.transform(date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZZZ')
      this.subs.sink = this.callCenterService.update(payload).subscribe(responds => { }
        , (err) => {
          console.log(err);
        });
    }
  }

  destroy() {
    this.subs.unsubscribe();
  }
}
