<div class="top_banner_section">

  <div class="card-body">
    <img class="float-left mr-2 mb-2" width="50" height="55"
      src="/assets/img/svg/cc/sf_logo.svg" />
    <h4 class="text-white mb-1 mt-2 font-weight-normal">SimplyFuse</h4>

    <div class="row" style="clear: both;">
      <div class="col-md-5 text-white text_total_amt">Total Amount<br>
        {{amount|currency}}</div>
      <div class="col-md-7 align-self-end text-right">
        <small class="text-white ml-1 mr-1" style="font-size: 12px;">Paying By</small>
        <img *ngIf="method == paymentMethods.CASH" width="28" class="mr-1"
          src="/assets/img/svg/cc/cash.svg" />
        <img *ngIf="method == paymentMethods.CHECK" width="28" class="mr-1"
          src="/assets/img/svg/cc/check.svg" />
        <small class="text-white mr-1" style="font-size: 12px;">{{method}}</small>
      </div>
    </div>
  </div>

</div>
<div class="content_section">

  <div class="row">
    <div class="col-12 card-info">

      <mat-card class="mat-card">
        <mat-card-content>
          <form class="mat-form">

            <div class="form-row" >
              <div class="form-group col-md-12">
                <label for="inputPassword4">Amount</label>
                <input type="text" value="{{amount|currency}}"
                  class="form-control" name="account_no"
                  placeholder="Account No." disabled>

              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="inputEmail4">Refference number</label>
                <input type="text" [(ngModel)]="paymentAddModel.reference_id"
                  class="form-control"
                  name="reference_id" placeholder="Refference number" >
                <!-- <small *ngIf="validationsErrors.reference_id" class="pl-1"
                  style="color: red;">
                  Refference no. is required.
                </small> -->
              </div>

            </div>

            <!-- <div class="form-row">
              <div class="form-group col-md-12">
                <label for="inputPassword4">Name<sup>*</sup></label>
                <input type="text"
                  [(ngModel)]="paymentAddModel.deposit_account_name"
                  class="form-control" name="deposit_account_name" placeholder="Name" (keyup)="checkValidation()">
                  <small *ngIf="validationsErrors.deposit_account_name" class="pl-1"
                  style="color: red;">
                  Name is required.
                </small>
              </div>
            </div> -->

            <div class="form-row" *ngIf="method == paymentMethods.CHECK">
              <div class="form-group col-md-12">
                <label for="inputPassword4">Check Number</label>
                <input type="text"
                  [(ngModel)]="paymentAddModel.check_number"
                  class="form-control" name="check_number" placeholder="Check Number" >
                  <!-- <small *ngIf="validationsErrors.check_number" class="pl-1"
                  style="color: red;">
                  Cheque No is required.
                </small> -->
              </div>
            </div>

            

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="inputEmail4">Comments</label>
                <textarea rows="5" [(ngModel)]="paymentAddModel.comments"
                  class="form-control" name="comments" placeholder="Comments">
                </textarea>

              </div>

            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <div class="form-check">
                  <input [(ngModel)]="paymentAddModel.send_email_to_customer"
                    class="form-check-input" type="checkbox"
                    name="send_email_to_customer" id="send_email_to_customer">
                  <label class="form-check-label" for="send_email_to_customer">
                    Email Receipts
                  </label>
                </div>
              </div>
            </div>

          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="d-flex justify-content-center ">
    <button (click)="addPayment()" type="submit"
      class="btn btn-primary btn-proceed">Proceed</button>
  </div>

</div>