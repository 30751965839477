import { Router } from '@angular/router';
import { ItemTypes, ModuleConstants } from 'src/app/core/enums/common.enum';
import { CreatedSource } from './../../../../core/enums/common.enum';
import { ItemWrapper } from './../../models/items';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ItemSupplierMap } from './../../models/supplier';
import { ToastrService } from 'ngx-toastr';
import { SupplierService } from '../../services/supplier.service';
import { ItemService } from '../../services/item.service';
import { Observable, of } from 'rxjs';
import { fabric } from 'fabric';
import { Component, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { StaticFile } from 'src/app/core/models/files.models';
import { environment } from 'src/environments/environment';
import * as popup from 'src/app/core/utils/popup.functions';
import { SubSink } from 'subsink';
import { SweetAlertOptions } from 'sweetalert2';
import { ItemStockComponent } from '../item-stock/item-stock.component';
import { LabelService } from '../../services/label.service';
import { CanvasObject, LabelPageTemplate } from '../../models/label.models';
import { WarehouseService } from '../../services/warehouse.service';
import { WareHouseLocation } from '../../models/warehouse.models';

@Component({
    selector: 'app-item-view',
    templateUrl: './item-view.component.html',
    styleUrls: ['./item-view.component.scss'],
})
export class ItemViewComponent implements OnInit, OnDestroy {

    canvas: any;

    @ViewChild('supplierMapModal') modalContent: any;

    @ViewChild('itemStockGrid') itemStockGrid: ItemStockComponent;

    public fileURL: string = environment.apiURL + '/static/files/';
  @ViewChild('labelModal') labelModal: ElementRef;


  // @ViewChild(ItemStockComponent, {static : true}) itemStockGrid : ItemStockComponent;

    public fileUrl: string = environment.fileURL;

    private innerItem:ItemWrapper;

    get item() { return this.innerItem; }

    @Input() set item(value: ItemWrapper) {

        if (this.innerItem !== value) {

            this.innerItem = value;

            if (value) {
                this.initialize();
            }
        }
    };

    @Output() onClose = new EventEmitter<void>();

    @Output() onDelete = new EventEmitter<number>();

    public selectionType = SelectionType;

    public CreatedSource = CreatedSource;

    public images: StaticFile[];

    public itemSuppliers: Observable<ItemSupplierMap[]>;

    public itemSuppliersFlat: ItemSupplierMap[];

    public supplierIds: number[];

    public itemSupplierId: number;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public ModuleConst = ModuleConstants;

    private subs: SubSink = new SubSink();

    public isAllSuborg: boolean;

    public labelLocationList:WareHouseLocation[]=[];

    public labelTitle:string;

    public labelWherehouse:number=null;

    public labelLocation:number;

    public labelSlNo:{'selected':boolean, 'slNo':number}[]=[];

    public labelTemplate:number=null;

    public printCount:number;

    public slnoSearch:string;

    public itemTypes = ItemTypes;

    public oldItem;

    constructor(
        private supplierService: SupplierService,
        private itemService: ItemService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private cdr: ChangeDetectorRef,
        private ra: ResourceAccessService,
        private router: Router,
        private labelService: LabelService,
        private warehouseService:WarehouseService
    ) {

        this.isAllSuborg = itemService.isAllSuborg;
    }

    ngOnInit(): void { }

    initialize() {
        if (this.hasPermission(this.Resource.INV_ITEMS_SUPPLIERS, this.Permissions.VIEW)) {
            this.getSupplier();
        }
        this.getImages();
        this.oldItem = {...this.item}
    }

    getSupplier() {
        this.itemSuppliers = this.supplierService
            .getSuppliersByItem(this.item.id)
            .pipe(
                map((response) => {
                    this.supplierIds = response.map((sup) => sup.supplier);
                    this.itemSuppliersFlat = response;
                    return response;
                })
            );
    }

    getImages() {
        this.subs.sink = this.itemService.getItemImages(this.item.id).subscribe(resp => {
            this.images = resp;
            this.cdr.markForCheck();
        });
    }

    deleteItem() {
        this.onDelete?.emit(this.item.id);
    }



    closeItemView(): void {
        this.onClose?.emit();
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    toggleSupplierForm(modalContent: any) {
        this.itemSupplierId = 0;
        this.modalService.open(modalContent);
    }

    onItemSMapDataSuccess(result: { response: ItemSupplierMap; message: string }): void {
        this.toastr.success(result.message);
        this.itemSuppliersFlat = this.itemSuppliersFlat ?? [];
        if (this.itemSuppliersFlat.some((sup) => sup.id === result.response.id)) {
            this.itemSuppliersFlat = this.itemSuppliersFlat.filter(
                (sup) => sup.id !== result.response.id
            );
            this.itemSuppliersFlat.push(result.response);
        } else {
            this.supplierIds.push(result.response.supplier);
            this.itemSuppliersFlat.push(result.response);
        }
        if (result.response.is_default) {
            this.itemSuppliersFlat.forEach((item) => {
                if (item.id !== result.response.id) {
                    item.is_default = false;
                }
            });
        }
        this.itemSuppliers = of([...this.itemSuppliersFlat]);
        this.closeModal();
    }

    onItemSMapDataError(_error: any) {
        this.toastr.error('Operation failed');
        this.closeModal();
    }

    editSupplierMap(id: number) {
        this.itemSupplierId = id;
        this.modalService.open(this.modalContent);
    }

    onSupplierSelect({ selected }) {
        if (this.hasPermission(this.Resource.INV_ITEMS_SUPPLIERS, this.Permissions.MODIFY) && !this.isAllSuborg) {
            this.itemSupplierId = selected[0].id;
            this.modalService.open(this.modalContent);
        }
    }

    deleteItemSupplierMap(id: number) {
        this.subs.sink = this.supplierService.deleteItemSupplierMap(id).subscribe(
            (_resp) => {
                this.toastr.success('supplier deleted');
                this.itemSuppliersFlat = this.itemSuppliersFlat.filter(
                    (sup) => sup.id !== id
                );
                this.itemSuppliers = of([...this.itemSuppliersFlat]);
                this.cdr.markForCheck();
            },
            (_err) => this.toastr.error('Failed to delete supplier')
        );
    }

    changeStatus() {
        let opt = {
            title: `Are you sure you want to ${this.item.is_active ? 'Activate Item' : 'Deactivate Item'}?`,
            text: this.item.item_name,
            current: this.item.is_active,
        };
        popup.ConfirmActivate(opt, (result) => {
            if (result.isConfirmed) {
                this.subs.sink = this.itemService
                    .patch(this.item.id, { is_active: this.item.is_active })
                    .subscribe(
                        () => { },
                        () => {
                            this.item.is_active = !this.item.is_active;
                            this.cdr.markForCheck();
                            this.toastr.error('Failed to change status');
                        }
                    );
            } else {
                this.item.is_active = !this.item.is_active;
                this.cdr.markForCheck();
            }
        });
    }

    changeShowOnCatelog() {
        let opt = {
            title: `Are you sure you want to ${this.item.show_on_catelog ? 'Add to' : 'Remove from'} Catelog?`,
            text: this.item.item_name,
            current: this.item.show_on_catelog,
        };
        popup.ConfirmActivate(opt, (result) => {
            if (result.isConfirmed) {
                this.subs.sink = this.itemService
                    .patch(this.item.id, { show_on_catelog: this.item.show_on_catelog })
                    .subscribe(
                        () => { },
                        () => {
                            this.item.show_on_catelog = !this.item.show_on_catelog;
                            this.cdr.markForCheck();
                            this.toastr.error('Failed to change');
                        }
                    );
            } else {
                this.item.show_on_catelog = !this.item.show_on_catelog;
                this.cdr.markForCheck();
            }
        });
    }

    changeInventoried(){
        let opt = {
            title: `Are you sure you want to change type?`,
            text: this.item.item_name,
            ButtonText: "Yes",
        };
        popup.ConfirmWithButtonText(opt, (result) => {
            if (result.isConfirmed) {
                this.subs.sink = this.itemService.patch(this.item.id, { item_type: this.item.item_type }).subscribe(
                        (response) => {
                            this.oldItem = response;
                            this.toastr.success('Type Changed Successfully');
                        },
                        () => {
                            this.item.item_type = this.oldItem.item_type;
                            this.toastr.error('Failed to change type');
                        }
                    );
            } else {
                this.item.item_type = this.oldItem.item_type;
                this.cdr.markForCheck();
            }
        });
    }

    hasPermission(resource: string, permission: string) {
        return this.ra.hasGlobalPermission([ResourceConstants.INV_ITEMS.toString(), ResourceConstants.INV_MODULE.toString()]) ||
            this.ra.hasPermission(resource, [permission]);
    }

    hasPermissionAny(resource: string, permission: string[]) {
        return this.ra.hasGlobalPermission([ResourceConstants.INV_ITEMS.toString(), ResourceConstants.INV_MODULE.toString()]) ||
            this.ra.hasPermission(resource, permission);
    }

    hasGlobalPermission(resources: string[]){
        return this.ra.hasGlobalPermission(resources);
    }

    convertToKit() {
        let option: SweetAlertOptions = {
            title: 'Are you sure you want to convert Item to Assembly',
            text: this.item.item_name,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Convert',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn',
                htmlContainer: 'font-small-3',
            },
            buttonsStyling: false,
        };
        popup.OpenConfirmBox(option, (response) => {
            if (response.isConfirmed) {
                this.subs.sink = this.itemService.convertToKit(this.item.id).subscribe(
                    (kit) => {
                        this.toastr.success('Item converted to Assembly');
                        this.router.navigate(['/inventory/assemblies', kit.id, 'view']);
                    },
                    () => this.toastr.error('Failed to convert Item')
                );
            }
        });
    }

    // getLabelPageTemplates() {
    //     this.subs.sink = this.labelService.getAllPageTemplate().subscribe(
    //         response => {
    //             this.labelPageTemplates = response;
    //             this.cdr.markForCheck();
    //         },
    //     );

    // }

    toggleLabelModal(content: any) {






        //  this.cdr.markForCheck();

    let options: NgbModalOptions = {
      size: 'lg'
    };

    this.modalService.open(content, options);


  }



  onLabelWhereHouseChange(warehouseId:number, locationToSelect:number=null){

    this.labelLocation=null;
    this.labelLocationList=[];
    this.warehouseService.getWareHouseLocations(warehouseId).subscribe(response=>{
        this.labelLocationList=response;
        if(locationToSelect){
        this.labelLocation=locationToSelect;
        }
    });
  }

  openPrintView(values){


    console.log(values);
    this.labelSlNo=[];
    this.slnoSearch="";

    let arr=[];

    if(values.slNo&&values.slNo.length>0){
        arr=values.slNo;

    }

    if(arr.length>0){
        arr.forEach((val, index)=>{
            this.labelSlNo.push({'selected':false, 'slNo':val.trim()})
        })
    }
    else if(values.slNo&&values.slNo.length>0){
        this.labelSlNo.push({'selected':false, 'slNo':values.slNo});
    }





    let options: NgbModalOptions = {
        size: 'lg'
      };

      this.modalService.open(this.labelModal, options);


    //   this.getLabelPageTemplates();

    //   this.labelLocation=values.location;

      this.labelWherehouse=values.werehouse;


      this.onLabelWhereHouseChange(values.werehouse, values.location);

      this.cdr.markForCheck();

        // this.canvas.loadFromJSON(this.tmpLabelTemplate, this.canvas.renderAll.bind(this.canvas), function (o, object) {
        // });
    }

  proceedToPrinting(){


      let urlValue="";

      let selectedSlNoCount=0;

        if(this.labelWherehouse){
            urlValue+="&wherehouse="+this.labelWherehouse;
        }

        if(this.labelLocation){
            urlValue+="&location="+this.labelLocation;
        }
        let sl_no=""

        if(this.labelSlNo&&this.labelSlNo.length>0){

            this.labelSlNo.forEach((val,index)=>{
                if(val.selected==true){
                    selectedSlNoCount++;
                    if(sl_no==""){
                        sl_no+=val.slNo;
                    }
                    else{
                        sl_no+=","+val.slNo;

                    }
                }

            });
            urlValue+="&slNo="+sl_no;
        }

      if(!this.printCount){
        this.toastr.error('Please enter required print counts');

      }
      else if(this.printCount<1){
        this.toastr.error('Please enter a valid required print count which is greater than zero');
      }
      else if(selectedSlNoCount>this.printCount){
        this.toastr.error('Selected serial numbers are more than given print count');
      }
      else{

        this.modalService.dismissAll();
        this.router.navigate([`/inventory/labels/print`],{queryParams:{type:'item', uniqueId:this.item.id, werehouse: this.labelWherehouse, location: this.labelLocation, slNo:sl_no, template:this.labelTemplate, printCount:this.printCount}});

      }



        // this.router.navigateByUrl('./inventory/labels/print?type=item&uniqueId='+this.item.id);

  }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
