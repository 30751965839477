<datagrid-filter
                 [searchFilters]="searchFilters"
                 [columns]="columns"
                 [refresh]="false"
                 [export]="true"
                 (exportButtonClick)="export()"
                 (apply)="applyFilter($event)">
    <ng-template filterControl [column]="'area_code'" let-row="row">
        <area-code-select class="ng-select-sm" [(value)]="row.value"></area-code-select>
    </ng-template>
</datagrid-filter>

<div class="dt-container mt-2">
    <ngx-datatable #dataTable
                   class="bootstrap core-bootstrap"
                   [columnMode]="'force'"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   rowHeight="auto"
                   [rows]="rows"
                   [count]="page.count"
                   [externalPaging]="true"
                   [offset]="page.offset"
                   [externalSorting]="true"
                   [limit]="page.limit"
                   [trackByProp]="'sid'"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [sorts]="[{prop:'purchased_on',dir:'desc'}]">

        <ngx-datatable-column name="Number" prop="did">
            <ng-template let-did="value" ngx-datatable-cell-template>
                {{did | phoneNumber }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
        <ngx-datatable-column name="Suborg" prop="suborg_name" *ngIf="globalRoute"></ngx-datatable-column>
        <ngx-datatable-column name="Number Type" prop="did_type" [sortable]="false">
        </ngx-datatable-column>
        <ngx-datatable-column name="Area Code" prop="area_code">
        </ngx-datatable-column>
        <ngx-datatable-column name="Purchased On" prop="purchased_on">
            <ng-template let-purchase="value" ngx-datatable-cell-template>
                {{purchase | date:'MM/dd/YYYY'}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                         let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                <div class="page-count">
                    <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                </div>
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                 [pagerRightArrowIcon]="'datatable-icon-right'"
                                 [pagerPreviousIcon]="'datatable-icon-prev'"
                                 [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                 [size]="pageSize" [count]="rowCount"
                                 [hidden]="!((rowCount / pageSize) > 1)"
                                 (change)="dataTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>
