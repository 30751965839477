
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class SalesService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    trimTime(date:string){
        if(date&&date.length>0){
          let data=date.split('T');
          return data[0];
        }
        else{
          return '';
        }
     }
     
    toFixedNoRounding(value,decimal:number=1){
      if(!isNaN(value)&&value!=null&&value!=''){
        let newVal=value.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
        return Number(newVal);
      }
      else{
        return null;
      }
    }

    trimNumber(number:number, maxLength:number){
      if(number&&number.toString().length>=maxLength){
        return Number(number.toString().slice(0, maxLength));
      }
      else{
        return number;
      }
    }

}