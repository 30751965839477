import { ItemTypesService } from './../../../services/item-types.service';
import { ResourceAccessService } from './../../../../../core/services/resource-access.service';
import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemType } from '../../../models/items';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';

@Component({
    selector: 'item-type-select',
    templateUrl: './item-type-select.component.html',
    styleUrls: ['./item-type-select.component.scss']
})
export class ItemTypeSelectComponent implements OnInit, OnDestroy {

    @Input() value: number;

    @Input() defaultValue: string = 'Goods';

    @Input() readonly: boolean = false;

    @Output() valueChange = new EventEmitter<number>();

    public options: ItemType[];

    public placeholder: string;

    private getSub$: Subscription;

    constructor(private itemTypesService: ItemTypesService,
        private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private ra: ResourceAccessService) {

        this.placeholder = this.hasPermission() ? "Select or add Item Type" : "Select Item Type";
    }

    ngOnInit() {
        this.getSub$ = this.itemTypesService.getAll().subscribe(categories => {
            this.options = categories;
            this.setDefault();
            this.cdr.markForCheck();
        });
    }

    setDefault() {
        if (!this.value) {
            this.value = this.options.find(x => x.name == this.defaultValue)?.id;
            this.onValueChanged();
        }
    }

    onValueChanged() {
        this.valueChange.emit(this.value);
    }

    openModal(content: any): void {
        this.modalService.open(content);
    }

    onDataSubmittedSuccess(result: { response: ItemType, message: string }): void {
        this.toastr.success(result.message);
        this.options = this.options.concat(result.response);
        this.value = result.response.id;
        this.onValueChanged();
        this.modalService.dismissAll();
    }

    onDataError(_error: any): void {
        this.toastr.error('Operation failed');
        this.modalService.dismissAll();
    }

    onCancel(): void {
        this.modalService.dismissAll();
    }

    hasPermission() {
        return this.ra.hasPermission(ResourceConstants.INV_ITEMTYPES, [PermissionConstants.CREATE, PermissionConstants.GLOBAL]);
    }

    ngOnDestroy(): void {
        this.getSub$?.unsubscribe();
    }
}
