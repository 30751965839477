import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, AfterViewInit, ContentChildren, QueryList, EventEmitter, Output, AfterContentChecked, AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { DataFilter, GFColumn, GFColumnTypes, GFCondition, GFilterGroup, GFilterParam, GFilterRow, GridTab } from 'src/app/core/models/grid-filter.models';
import { DataFilterService } from 'src/app/core/services/data-filter.service';
import { SubSink } from 'subsink';
import { GridFilterControlDirective } from '../../directives/grid-filter-control.directive';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataImportMeta } from 'src/app/core/models/common.models';




@Component({
    selector: 'datagrid-filter',
    templateUrl: './data-grid-filter.component.html',
    styleUrls: ['./data-grid-filter.component.scss']
})
export class DataGridFilterComponent implements OnInit, AfterViewInit, OnDestroy {

    private _defaultFilters: GFilterParam[];

    private _searchFilters: GFilterParam[];

    @ContentChildren(GridFilterControlDirective) controlTemplates: QueryList<GridFilterControlDirective>;

    @ViewChild('searchInput') searchInput: ElementRef;

    @Input() title: string;

    public get defaultFilters(): GFilterParam[] { return this._defaultFilters; }

    @Input() public set defaultFilters(collection: GFilterParam[]) {

        this._defaultFilters = collection;
        if (collection && collection.length) {
            this.defaultSelected = collection.find(item => item.default) || collection[0];
        }
    }

    public get searchFilters(): GFilterParam[] { return this._searchFilters; }

    @Input() public set searchFilters(collection: GFilterParam[]) {
        this._searchFilters = collection;
        if (collection && collection.length) {
            this.searchSelected = collection.find(item => item.default) || collection[0];
        }
    }

    @Input() newButton: boolean;

    @Input() newButtonUrl: string[];

    @Input() queryParams: any;

    @Input() newButtonText: string = 'New';

    @Input() newButtonPopup: any;

    @Input() newButtonModalRef: any;

    @Input() newButtonEnabled: boolean = false;

    @Input() bulk_update_btn: boolean = false;

    @Input() bulk_delete_btn: boolean = false;

    @Input() dontDisableIfAllsuborg: boolean = false;

    @Input() columns: GFColumn[];

    @Input() module: ModuleConstants;

    @Input() localSearch: boolean = false;

    @Input() expanded: boolean = true;

    @Input() searchPlaceholder: string = 'Search...';

    @Input() import: boolean = false;

    @Input() importColumns: DataImportMeta[];

    @Input() export: boolean = false;

    @Input() refresh: boolean = true;

    @Input() toggleId: string = 'boolean';

    @Input() resetToCurrentDashData: boolean = false;

    @Input() set gridTabs(value:GridTab[]){

        if(value){
            this._gridTabs=value;
        }
    }

    @Input() set selectedTabId(val:string){

        if(val){
            this._activeTab=val;
        }

    }

    @Input() showSearch:boolean = true

    @Input() showSavedFilter:boolean = true

    @Input() advanceFilter:boolean = true;

    @Output() importSubmit = new EventEmitter<any>();

    @Output() apply = new EventEmitter<GFilterParam[]>();

    @Output() apply2 = new EventEmitter<GFilterGroup[]>();

    @Output() newButtonClick = new EventEmitter<void>();

    @Output() bulkDeleteClick = new EventEmitter<void>();

    @Output() search = new EventEmitter<string>();

    @Output() exportButtonClick = new EventEmitter<any>();

    @Output() selectedGridTab = new EventEmitter<any>();

    @Output() onTabSelect  = new EventEmitter<any>();

    @Input()  reportTypeData:any

    @Input() showreportType : boolean = false;

    @Input() showDefaultFilter: boolean = true;

    public _gridTabs:GridTab[]=[];

    public conditions: GFCondition[] = [
        {
            displayName: 'is', condition: 'is',
            allowedTypes: [GFColumnTypes.string, GFColumnTypes.select, GFColumnTypes.phone]
        },
        {
            displayName: 'starts with', condition: 'startswith',
            allowedTypes: [GFColumnTypes.string, GFColumnTypes.select, GFColumnTypes.phone]
        },
        {
            displayName: 'contains', condition: 'contains',
            allowedTypes: [GFColumnTypes.string, GFColumnTypes.select, GFColumnTypes.phone]
        },
        { displayName: 'is', condition: 'equalto', allowedTypes: [GFColumnTypes.number, GFColumnTypes.ref, GFColumnTypes.boolean] },
        { displayName: 'greater than', condition: 'greater than', allowedTypes: [GFColumnTypes.number] },
        { displayName: 'less than', condition: 'less than', allowedTypes: [GFColumnTypes.number] },
        { displayName: 'less than or equal', condition: 'less than or equal', allowedTypes: [GFColumnTypes.number] },
        { displayName: 'greater than or equal', condition: 'greater than or equal', allowedTypes: [GFColumnTypes.number] },
        { displayName: 'is', condition: 'exactdate', allowedTypes: [GFColumnTypes.date] },
        { displayName: 'greater than', condition: 'dategreaterthan', allowedTypes: [GFColumnTypes.date] },
        { displayName: 'greater than or equal', condition: 'dategreaterthanequal', allowedTypes: [GFColumnTypes.date] },
        { displayName: 'less than', condition: 'datelessthan', allowedTypes: [GFColumnTypes.date] },
        { displayName: 'less than or equal', condition: 'datelessthanequal', allowedTypes: [GFColumnTypes.date] },
        { displayName: 'between', condition: 'date_between', allowedTypes: [GFColumnTypes.date_range] }
    ];

    public defaultSelected: GFilterParam;

    public searchSelected: GFilterParam;

    public advancedFilterVisible: boolean;

    @Input() searchText: string;

    public customFilterId: number = 0;

    public customFilterName: string;

    public customFilterModel:DataFilter;

    public rows: GFilterRow[] = [];

    public ColumnType = GFColumnTypes;

    private subs: SubSink = new SubSink();

    public isMobile: boolean = false;

    private importModalRef: NgbModalRef;

    public isAllSuborg: boolean;

    public _activeTab: string='';

    constructor(
        private filterService: DataFilterService,
        public modal: NgbModal,
        private toastr: ToastrService,
        private router: Router,
        private cdr:ChangeDetectorRef,
        private device: DeviceDetectorService,) {

        this.isAllSuborg = filterService.isAllSuborg;
        this._activeTab='';
    }

    ngOnInit() {
        this.isMobile = this.device.isMobile();
        if (this.module && !this.isAllSuborg && this.showSavedFilter) {
            this.getSavedFilters();
        }
    }

    ngAfterViewInit(): void {
        this.activateSearchBox();
        if (this.columns) {
            this.appendRow();
        }
        // if(this.searchText)
        // {
        //     this.applyFilter()
        // }

    }

    activateSearchBox() {
        let x = window.scrollX, y = window.scrollY;
        this.searchInput?.nativeElement.focus();
        window.scrollTo(x, y);
    }

    private getSavedFilters() {
        this.subs.sink = this.filterService.get(this.module).subscribe(resp => {

            if(resp.length > 0 && this.defaultFilters)
            {
                this.defaultFilters =  [...this.defaultFilters, ...resp];
            }

           // let custom_default = this.defaultFilters.find(item=> item.default);
        });
    }

    public appendRow() {
        let row = new GFilterRow();
        row.operator = this.rows.length <= 0 ? 'WHEN' : 'AND';
        this.rows.push(row);
    }



    public getControlTemplate(column: string) {
        return this.controlTemplates.toArray().find(c => c.column === column).template;
    }

    public removeRow(id: string) {
        this.rows = this.rows.filter(r => r.id !== id);
    }

    public columnChanged(selected: GFColumn, row: GFilterRow) {

        if (selected) {

            row.value = null;

            row.allowedConditions = this.conditions.filter(col => col.allowedTypes.includes(row.column.columnType));

            if (selected.columnType == GFColumnTypes.boolean) {
                row.value = false;
            }

            if (selected.columnType == GFColumnTypes.select) {
                row.options = selected.options || [];
                row.allowedConditions = this.conditions.filter(cond => cond.allowedTypes.includes(selected.optionValueType));
            }

            row.condition = row.allowedConditions.length === 1 ? row.allowedConditions[0] : null;

            if (selected.dependentValue) {
                row.dependentValue = selected.dependentValue;
            }
            else if (selected.dependentColumn) {
                this.setDependentColumnValue(selected, row);
            }
        }
    }

    setDependentColumnValue(selected: GFColumn, row: GFilterRow) {
        let dependentRow = this.rows.find(col => col.column.columnName === selected.dependentColumn);
        if (dependentRow) {
            row.dependentValue = dependentRow.value;
        }
    }

    public columnClear(row: GFilterRow) {
        if (row) {
            row.allowedConditions = [];
            row.condition = null;
            row.value = null;
        }
    }

    public resetFilter() {
        this.rows = [];
        this.appendRow();
        this.customFilterId = 0;
        this.customFilterName = '';
        this.advancedFilterVisible = false;
        let group = this.getDataFilters2();
        let params = this.getDataFilters();
        if (!this.resetToCurrentDashData && this.defaultFilters && this.defaultFilters.length) {
            this.defaultSelected = this.defaultFilters[0];
        }
        this.apply?.emit(this.resetToCurrentDashData?params:[]);
        this.apply2?.emit(this.resetToCurrentDashData?group:[]);
    }

    getDataFilters() {

        let params: GFilterParam[] = [];

        if (this.defaultSelected && this.defaultSelected.colname) {
            params.push(this.defaultSelected);
        }

        if (this.searchText && this.searchSelected) {

            let term = this.searchText.trim();

            if (this.searchSelected.children) {

                this.searchSelected.children.forEach(filter => {
                    filter.value = filter.type === GFColumnTypes.phone ? this.removeSpecialCharFromMobile(term) : term;
                });
                params = [...params, ...this.searchSelected.children];
            }
            else {
                this.searchSelected.value = this.searchSelected.type === GFColumnTypes.phone ? this.removeSpecialCharFromMobile(term) : term;
                params.push(this.searchSelected);
            }
        }

        let advanced = this.getAdvancedFilters();

        if (advanced && advanced.length > 0) {
            params = [...params, ...advanced];
        }

        if (params.length > 0) {
            params[0].operator = 'AND';
        }
        return params;
    }


    getDataFilters2() {

        let group: GFilterGroup[] = [];
        if (this.defaultSelected && this.defaultSelected.colname) {
            // group[0].conditions.push(this.defaultSelected);
            group.push({conditions:[this.defaultSelected],operator:'AND'})
        }

        if (this.searchText && this.searchSelected) {

            let term = this.searchText.trim();

            let searchParams:GFilterParam[]=[];

            if (this.searchSelected.children) {

                this.searchSelected.children.forEach(filter => {
                    filter.value = filter.type === GFColumnTypes.phone ? this.removeSpecialCharFromMobile(term) : term;
                });
                searchParams = [...this.searchSelected.children];
            }
            else {
                this.searchSelected.value = this.searchSelected.type === GFColumnTypes.phone ? this.removeSpecialCharFromMobile(term) : term;
                searchParams.push(this.searchSelected);
            }
            group.push({conditions:searchParams,operator:'AND'})
        }

        let advanced = this.getAdvancedFilters();

        if (advanced && advanced.length > 0) {

            group.push({conditions:advanced,operator:'AND'})
            // params = [...params, ...advanced];
        }
        // if (params.length > 0) {
        //     params[0].operator = 'AND';
        // }
        return group;
    }

    removeSpecialCharFromMobile(phone: string) {
        const cleanedPhone = phone.replace(/^\+?\s*1\s*/, '');
        return cleanedPhone.replace(/[^a-zA-Z0-9]/g, '');
    }

    private getAdvancedFilters() {
        let params: GFilterParam[] = [];
        for (let row of this.rows) {
            if (row.column && row.condition && (row.value !== undefined && row.value !== null)) {
                let p = new GFilterParam();
                p.colname = row.column.columnName;
                p.condition = row.condition.condition;
                p.value = row.value;
                p.operator = row.operator || 'AND';
                params.push(p);
            }
        }
        return params;
    }

    public applyFilter() {
        let params = this.getDataFilters();
        let group = this.getDataFilters2();
        if (params.length > 0) {
            this.apply?.emit(params);
        }
        if(group.length>0){
            this.apply2?.emit(group);
        }
    }

    onDefaultFilterChanged(selected: GFilterParam): void {
        if (this.defaultSelected.displayName === selected.displayName)
            return;
        this.defaultSelected = selected;
        if (selected.custom) {
            this.setCustomFilter(selected);
            this.advancedFilterVisible = true;
        }
        let params = this.getDataFilters();
        let group = this.getDataFilters2();
        this.apply?.emit(params);
        this.apply2?.emit(group);
    }

    setCustomFilter(param: GFilterParam) {

        this.customFilterId = param.customDataId || 0;
        this.customFilterName = param.displayName;

        this.rows = param.children.map(item => {

            let row = new GFilterRow();
            row.column = this.columns.find(c => c.columnName === item.colname);
            row.condition = this.conditions.find(c => c.condition === item.condition);
            row.operator = item.operator;
            row.value = item.value;

            if (row.column) {
                if (row.column.columnType === GFColumnTypes.select) {
                    row.options = row.column.options;
                }
                row.allowedConditions = this.conditions.filter(col => col.allowedTypes.includes(row.column.columnType));
            }
            return row;
        });
    }

    public applyAdvancedFilter() {
        this.applyFilter();
    }

    searchFilterApply() {
        if (this.searchText) {
            this.applyFilter();
        }
    }

    public getParamsForSave() {
        let params: GFilterParam[] = [];
        for (let row of this.rows) {
            if (row.column && row.condition) {
                let p = new GFilterParam();
                p.colname = row.column.columnName;
                p.condition = row.condition.condition;
                p.value = row.value;
                p.operator = row.operator || 'AND';
                params.push(p);
            }
        }
        return params;
    }

    onFilterFormSubmit() {

        let params = this.getParamsForSave();

        if (params.length <= 0) return;

        let dataFilter = new DataFilter();
        dataFilter.condition = params;
        dataFilter.customer = this.filterService.customerId;
        dataFilter.module = this.module;
        dataFilter.name = this.customFilterName;
        dataFilter.id = this.customFilterId;

        if (this.customFilterId > 0) {
            this.subs.sink = this.filterService.update(this.customFilterId, dataFilter).subscribe(resp => {
                let obj = this.defaultFilters.find(x => x.customDataId === resp.customDataId);
                obj.displayName = resp.displayName;
                obj.children = resp.children;
                this.modal.dismissAll();
            });
        }
        else {
            this.subs.sink = this.filterService.create(dataFilter).subscribe(resp => {
                this.defaultFilters.push(resp);
                this.onDefaultFilterChanged(resp);
                this.customFilterId = resp.customDataId;
                this.customFilterName = resp.displayName;
                this.modal.dismissAll();
            });
        }
    }

    deleteSavedFilter() {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.filterService.delete(this.customFilterId).subscribe(
                    () => {
                        popup.CompleteDelete(`Filter '${this.customFilterName}' deleted.`);
                        this.defaultFilters = this.defaultFilters.filter(f => f.customDataId !== this.customFilterId);
                        this.resetFilter();
                    },
                    () => this.toastr.success(`Unable to delete filter '${this.customFilterName}'`)
                );
            }
        });
    }

    onNewButtonClick() {



        if (this.newButtonUrl) {

            if(this.queryParams)
            {
                this.router.navigate(this.newButtonUrl, this.queryParams);
            }
            else
            {
                this.router.navigate(this.newButtonUrl);
            }

        }
        else if(this.newButtonModalRef){
            this.modal.open(this.newButtonModalRef, { centered: true, scrollable: true, size: "md" });
        }else {
            this.newButtonClick?.emit();
        }
    }

    onBulkDeleteClick(){
        console.log('now emit');
        this.bulkDeleteClick?.emit();
    }


    onexportButtonClick() {
        this.exportButtonClick?.emit();
    }

    clearSearch() {
        this.searchText = '';
        let params = this.getDataFilters();
        let groups = this.getDataFilters2();
        this.apply?.emit(params);
        this.apply2?.emit(groups);
    }

    public setDefaultFilter(colname: string, value: any) {
        let param: GFilterParam = this.defaultFilters.find(item => item.colname === colname && item.value === value && item.default != true);
        if (param) {
            this.onDefaultFilterChanged(param);
        }
    }

    onLocalSearch(event: any) {
        let term = event.target.value?.toLowerCase();
        this.search?.emit(term);
    }

    onImportSubmitted(map: any) {
        this.importSubmit.emit(map);
        this.importModalRef?.dismiss();
    }

    openImportModal(content: any) {
        this.importModalRef = this.modal.open(content, { centered: true, scrollable: true, size: "lg" });
    }

    closeImportModal() {
        this.importModalRef?.dismiss();
    }

    controlValueChanged(name: string) {
        let controlRow = this.rows.find(item => item.column.columnName === name);
        for (let row of this.rows) {
            if (row.column && row.column.dependentColumn === name) {
                row.dependentValue = controlRow.value;
            }
        }
    }

    ongridTabSelect(tabItem)
    {
        // this.selectedGridTabId = tabItem.id;
        this._activeTab=tabItem.id;
        this.cdr.detectChanges();
        this.selectedGridTab.emit(tabItem)
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

    // onChangReportType(val)
    // {
    //   this.onTabSelect.emit(val)
    // }
}
