import { ChangeDetectorRef, Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ServiceContractService } from '../../services/service-contract.service';
import { addSC, SCItemAdd, SCItemsList, SCKitsAdd, SCKitsList, SCList } from '../../models/service-contract.models';
import { FormControl, FormGroup, NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { CustomerLocationSelectComponent } from './../../../tenant-customers/components/select/customer-location-select/customer-location-select.component';
import { CustomerContact, CustomerLocation, TenantCustomer } from 'src/app/modules/tenant-customers/models/tenant-customer';
// import { WarrantyKitItemsComponent } from '../../components/kit-items/kit-items.component';
import { KitItemsComponent } from 'src/app/modules/inventory/components/kit-items/kit-items.component';
import { TermsService } from '../../services/terms.service';
import { getTermsCat, TermsCatResponse } from '../../models/terms.models';
import { first } from 'rxjs/operators';
import { SalesWarrantyItemsComponent } from '../../components/warranty-items/warranty-items.component';
import { formatDate, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Kit } from 'src/app/modules/inventory/models/kit.models';
import { KitService } from 'src/app/modules/inventory/services/kit.service';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { CustomField } from 'src/app/core/models/custom-field';
import { Observable } from 'rxjs';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UIPermission } from 'src/app/core/models/common.models';
import { ScItemsComponent } from '../../components/sc-items/sc-items.component';
import { WarrantyList } from '../../models/warranty.models';
import { SalesService } from '../../services/sales.service';


@Component({
  selector: 'app-service-contract-add',
  templateUrl: './service-contract-add.component.html',
  styleUrls: ['./service-contract-add.component.scss']
})
export class ServiceContractAddComponent implements OnInit {

  public addData:addSC=new addSC();

  @ViewChild('locationSelect') locationSelect: CustomerLocationSelectComponent;

  @ViewChild('kitItemGrid') SCItemsGrid: ScItemsComponent;

  @ViewChild('serviceContractForm') public scForm: NgForm;


  public userAuth=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));


  public catId:number=3;

  public kitModel: Kit = new Kit();

  public scId:number=0;

  public termsList;

  public termSelection;

  public termsCategory="";

  public warrantyForm;

  public itemData:SCItemAdd=new SCItemAdd();

  public kitData:SCKitsAdd=new SCKitsAdd();

  public subs: SubSink = new SubSink();

  // @ViewChild('kitItemGrid') kitItemsGrid: KitItemsComponent;

  public model:addSC=new addSC();

  public getTermsData:getTermsCat=new getTermsCat();

  public itemList:SCItemsList[];

  public kitList:SCKitsList[];

  public additionalFields: CustomField[];

  public api;

  public Resource = ResourceConstants;

  public Permissions = PermissionConstants;

  public submitted:boolean=false;

  public formError:boolean=false;

  public UIPermissions:UIPermission;

  public _saveAsDraft:boolean=false;


  public duration=[
            {id:1,name:'1 Year'},
            {id:2,name:'2 Years'},
            {id:3,name:'3 Years'},
            {id:4,name:'4 Years'},
            {id:5,name:'5 Years'},
            {id:0,name:'Custom end date'},
          ];

  public durationModel;

  public coverageTypeSelection=0;



  public scStarts=[
    {id:1, name:'Custom starting date'},
    {id:2, name:'After Warranty Expires'},
    {id:3, name:'After SC paid in full'},
  ];

  public startingDate:string;

  public endingDate:string;


  public testVariable:number;

  public warrantyReset:boolean=false;

  // public minDate:NgbDateStruct;


  public formData=new FormGroup({
    customer: new FormControl(this.userAuth.custid),
    title: new FormControl(),
    unique_number: new FormControl(),
    tenant_customer: new FormControl(),
    tenant_customer_contact: new FormControl(),
    tenant_customer_location: new FormControl(),
    starting_date: new FormControl(),
    ending_date: new FormControl(),
    terms_and_condition: new FormControl(),
  });



  constructor(private SCService: ServiceContractService,
    private toaster:ToastrService,
    public location: Location,
    private TermsService:TermsService,
    public datepipe: DatePipe,
    private router:Router,
    private cdr: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private ra: ResourceAccessService,
    public salesService: SalesService){
      this.subs.sink = activeRoute.params.subscribe(params => this.scId = params.id || 0);
      this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_WARRANTY, ResourceConstants.SALES_MODULE);
    }





  ngOnInit(): void {
      let test=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
      this.model.suborg=parseInt(localStorage.getItem(AppConstants.CURRENT_SUBORG));
      this.model.coverage_type=3;

      if(this.scId!=0){
        this.getById(this.scId);
      }




  }






  getById(id:number){

    this.subs.sink=this.SCService.viewSC(id).subscribe(
      response=>{
          // this.warranty=response;
          this.model=response;
          this.cdr.markForCheck();
          this.model.starting_date=this.salesService.trimTime(this.model.starting_date);
          this.model.ending_date=this.salesService.trimTime(this.model.ending_date);
          // this.setDates(this.model.starting_date, this.model.ending_date);
      },
      ()=>{
        this.toaster.error("Error occured during fetching data");
      }
    );

  }








  addSC(saveAsDraft:boolean=false){

    this.model.customer=this.userAuth.custid;
    this._saveAsDraft=saveAsDraft;

    this.formError=false;

    this.submitted=true;

    if(this.checkValidation(saveAsDraft)){
      if(!saveAsDraft)
      this.toaster.error("You should enter all mandatory fields");
      return;
    }


    this.model.starting_date=this.model.starting_date!=undefined?this.model.starting_date+'T00:00:00Z':undefined;
    this.model.ending_date=this.model.ending_date!=undefined?this.model.ending_date+'T00:00:00Z':undefined;

    if(saveAsDraft){
      this.model.status=4;
    }
    else if(this.model.sc_begins==3){
      this.model.status=1;
      this.model.starting_date=null;
      this.model.ending_date=null;
    }
    else{
      this.model.status=null;
    }



    this.api = this.scId > 0 ? this.SCService.updateSC(this.model, this.scId) : this.SCService.addSC(this.model);

    this.subs.sink = this.api.subscribe(
      response => {
        console.log(response.id);
        if(this.scId>0){
          this.addItems(this.scId);
          this.addKits(this.scId);
        }
        else{
          this.addItems(response.id);
          this.addKits(response.id);
        }

        if (this.hasPermission(this.Permissions.VIEW_INFO)) {
           this.router.navigate(['/sales/service-contracts/'+response.id+'/view']);
        }
        else {
          this.location.back();
        }

        this.toaster.success(this.scId>0?"Service Contract updated":"Service Contract Added successfully");

      },
      ()=>{
         this.toaster.error("Sorry error occured during process");
      }
    );
  }


  checkValidation(saveAsDraft:boolean=false){
    console.log(this.model.tenant_customer_location);

          if(saveAsDraft){

            if(this.model.tenant_customer==undefined){
              this.toaster.error("You should select atleast customer field to save as draft");
              return true;
            }
            else{
              return false;
            }

          }
          else{

            if(this.model.tenant_customer==undefined||
              this.model.tenant_customer_contact==undefined||
              this.model.tenant_customer_location==undefined||
              (this.model.sc_begins!=3&&(!this.model.starting_date||
              !this.model.ending_date))||
              (!this.model.ending_date&&this.model.duration==0)||
              this.model.coverage_type_selection==0||
              (this.model.coverage_type_selection==1&&
                this.model.coverage_type==0)||

              this.model.sc_begins==0||
              (this.model.sc_begins==2&&!this.model.warranty)||
              this.model.billing_interval==0||
              this.SCItemsGrid.checkValidation()){


              this.formError=true;

            }
            if((this.model.starting_date&&this.model.ending_date)&&this.calculateDiff(this.model.starting_date, this.model.ending_date)<1){
              this.toaster.error("Service Contract ending date should not be before start date");
              this.formError=true;
            }
            else if(this.SCItemsGrid.rows.length==0){
              this.toaster.error("Add atleast one item or kit");
              this.formError=true;
            }

          }

          if(this.formError){
            return true;
          }
          else{
            return false;
          }

  }


  calculateDiff(startDate, endDate){
    endDate = new Date(endDate);
    startDate = new Date(startDate);
    let test = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);

    return test;
}

  //Add items

  addItems(scId:number){

    console.log(this.SCItemsGrid.rows);
    console.log(scId);
    let data:SCItemAdd[]=this.formatItemsForSend(this.SCItemsGrid.rows,scId);
    console.log(data);
    if(data.length>0){
      let api;
      if(this.scId>0){

        api=this.SCService.itemsUpdate(data, scId);
      }
      else{

        api=this.SCService.itemsAdd(data);
      }

      api.subscribe(
        ()=>{
            // window.alert("Got the response");
            console.log("Items added");

        }
      );
    }

  }

  addKits(scId:number){
    console.log(this.SCItemsGrid.rows);
    let data:SCKitsAdd[]=this.formatKitsForSend(this.SCItemsGrid.rows,scId);
    console.log(data);

    if(data.length>0){
      let api;
      if(this.scId>0){
        api=this.SCService.kitsUpdate(data, scId);
      }
      else{
        api=this.SCService.kitsAdd(data);
      }
      api.subscribe(
        ()=>{
            // window.alert("Got the response");
            console.log("Kits added");
        }
      );
    }
  }


  //Filter items

  formatItemsForSend(rows, scId:number){
    let values=[];
      if(rows.length){
        rows.forEach(element => {
          if(element.itemType=="item"){
            values.push({"service_contract":scId,
            "associate_item":element.item_id,
            "customer":element.customer,
            "serial_number":element.serial_number,
            "quantity":element.quantity,
            "coverage_type": element.coverage_type,
            "id": element.id,
            "price":element.price,
            "is_delete":element.is_delete,
          });
          }
        });

        return values;
      }
      else{
        return [];
      }
  }

  formatKitsForSend(rows, scId:number){
    console.log(rows);

    let values=[];
      if(rows.length){
        rows.forEach(element => {
          if(element.itemType=="kit"){
            values.push({"service_contract":scId,
            "associate_kit":element.item_id,
            "customer":element.customer,
            "serial_number":element.serial_number,
            "quantity":element.quantity,
            "coverage_type": element.coverage_type,
            "id": element?.id,
            "price":element.price,
            "is_delete":element.is_delete,
           });
          }
        });

        return values;
      }
      else{
        return [];
      }
  }

  //On customer change

  customerChanged(customer:TenantCustomer){
    console.log("Customer just changed");
    this.model.tenant_customer_location=null;
    this.model.tenant_customer_contact=null;
    this.model.warranty=null;
    this.warrantyReset=true;
    // this.scForm
    // this.warrantyList=[];
    this.cdr.markForCheck();


  }

  customerContactChange(customer:CustomerContact){
    console.log('Customer contact just changed');
    console.log(customer);
      this.model.tenant_customer_location=null;
      if(this.model.tenant_customer_contact&&customer&&customer.location){
        this.model.tenant_customer_location=customer.location;
      }
  }

  // customerLocationChange(location){

  //   console.log('Customer location just changed');
  //   console.log(location);
  //   console.log(this.model.tenant_customer_location);
  //   // this.model.tenant_customer_location=location.id;
  //   this.cdr.markForCheck();
  //   console.log(this.model.tenant_customer_location);


  // }



  termsSelected(event){

    this.model.conditions=event.target.value;
    this.model.terms_and_condition=event.target.id;

    // console.log(this.model.terms_and_condition);

  }

  termsValueChange(value){
    this.model.terms_and_condition=value;
  }

  // getTerms(){
  //   this.getTermsData.customer=this.userAuth.custid;
  //   this.TermsService.getTerms(this.catId, this.getTermsData).subscribe(
  //     response=>{
  //        this.termsList=response;
  //     }
  //   );
  // }



  changeCoverageType(typeId){
      if(typeId==this.model.coverage_type)
      {
        this.model.coverage_type=0;
      }
      else{
        this.model.coverage_type=typeId;
      }
  }

  termsChange(terms){
    this.model.conditions=terms.description??terms.description;
  }

  scStartChange(event){
    console.log(event);

  }

  warrantyChange(warrantyList:WarrantyList){

    let tmpDate=this.salesService.trimTime(warrantyList.ending_date);
    let date=new Date(tmpDate);
    date.setDate(date.getDate() + 1);
    let datePipe: DatePipe = new DatePipe('en-US');
    this.model.warranty=warrantyList.id;
    this.model.starting_date=datePipe.transform(date, 'yyyy-MM-dd');
    this.cdr.markForCheck();
  }

  // setDates(startingDate:string, endingDate:string){
  //   if(startingDate!=null&&startingDate!=""){
  //     let date=new Date(startingDate);
  //     let datePipe: DatePipe = new DatePipe('en-US');
  //     this.startingDate=datePipe.transform(date, 'yyyy-MM-dd');
  //   }
  //   if(endingDate!=null&&endingDate!=""){
  //     let date=new Date(endingDate);
  //     let datePipe: DatePipe = new DatePipe('en-US');
  //     this.endingDate=datePipe.transform(date, 'yyyy-MM-dd');
  //   }
  // }

  durationChange(event){

      if(event&&event.id>0&&event.id<=5){
        let date=new Date(this.model.starting_date);
        date.setDate(date.getDate()+(event.id*365));
        let datePipe: DatePipe = new DatePipe('en-US');
        this.model.ending_date=datePipe.transform(date, 'yyyy-MM-dd');
        // console.log(this.model.ending_date);
      }
      else if(event&&event.id==0){

      }
      this.cdr.markForCheck();
  }

  hideDateSection(){
    if(this.model.sc_begins==2&&this.model.warranty!=null){
      return false;
    }
    else if(this.model.sc_begins==1){
      return false;
    }
    else{
      return true;
    }
  }

  startDateChange(event){
    window.alert("here");
    this.model.starting_date=event;
    console.log(event);
  }
  onCustomerLocationAdded() { this.locationSelect?.reload(); }

  hasPermission(permission: string) {
    return this.ra.hasPermission(this.Resource.SALES_WARRANTY, [permission, this.Permissions.GLOBAL]);
  }

  hasPermissionWR(resource: string, permission: string) {
    return this.ra.hasPermission(resource, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(this.Resource.SALES_WARRANTY, [this.Permissions.GLOBAL]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }



}
