import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { State } from '../models/kit.models';

@Injectable({
  providedIn: 'root'
})
export class StateTaxService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll() {
    return this.http.get<State[]>(`${this.baseURL}/statetax/`);
  }

  getById(id: number) {
    return this.http.get<State>(`${this.baseURL}/statetax/${id}/`);
  }

  create(state: State) {
    return this.http.post<State>(`${this.baseURL}/statetax/`, state);
  }

  update(id: number, state: State) {
    return this.http.put<State>(`${this.baseURL}/statetax/${id}/`, state);
  }

  patch(id: number, request: any) {
    return this.http.patch<State>(`${this.baseURL}/statetax/${id}/`, request);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/statetax/${id}/`);
  }
}
