import { OrderByPipe } from '../../../../shared/pipes/order-by.pipe';
import { forkJoin, Observable } from 'rxjs';
import { KitService } from 'src/app/modules/inventory/services/kit.service';
import { KitItem, KitItemRow, KitItemsType, KitWrapper, Kit2Kit } from 'src/app/modules/inventory/models/kit.models';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ItemWrapper } from 'src/app/modules/inventory/models/items';
import { ItemSelectComponent } from 'src/app/modules/inventory/components/select/item-select/item-select.component';
import { KitSelectComponent } from 'src/app/modules/inventory/components/select/kit-select/kit-select.component';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from 'src/app/modules/inventory/services/item.service';
import { SubSink } from 'subsink';
import { groupBy, map } from 'rxjs/operators';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { SalesService } from '../../services/sales.service';
import { SalesOrdersService } from '../../services/sales-orders.service';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import * as _ from 'lodash'
import { title } from 'process';
import { InvoiceItemRow, InvoiceItemsType, ItemAdd, ItemRow, Section } from '../../models/invoice.models';
import { InvoiceService } from '../../services/invoice-service';
import { TimesheetSelectComponent } from 'src/app/modules/service-ticket-select/components/timesheet-select/timesheet-select.component';
import { TimeSheet } from 'src/app/modules/service-ticket/models/common.models';
import { RowHeightCache } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { getLightColor } from 'src/app/core/utils/global.functions';
@Component({
    selector: 'sales-invoice-items',
    templateUrl: './invoice-items.component.html',
    styleUrls: ['./invoice-items.component.scss']
})
export class InvoiceItemsComponent implements OnInit {


    @ViewChild('itemSelect') itemSelect: ItemSelectComponent;

    @ViewChild('kitSelect') kitSelect: KitSelectComponent;

    @ViewChild('timesheetSelect') timesheetSelect: TimesheetSelectComponent;

    @Input() summaryRow: boolean = true;

    @Input() deleteOption: boolean = true;

    @Input() saveButton: boolean = false;

    @Input() addButton: boolean = true;

    @Input() invoiceId: number = 0;

    @Input() coverageType: number = 0;

    @Input() coverageTypeSelection: number = 0;

    @Input() submitted: boolean = false;

    @Input() tax: number;

    @Input() customerId: number;

    @Input() suborg_id: number;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public rows: InvoiceItemRow[] = [];

    public sections: Section[] = [new Section()];

    public KitIemTypes = InvoiceItemsType;

    public rowIndex: number;

    public sectionIndex: number;

    private deleteItems: number[] = [];

    private deleteKits: number[] = [];

    public hasKits: boolean = false;

    public _itemsAdd: ItemAdd[] = [];
    newItem: boolean;
    isAllSuborg: boolean;

    get itemsAdd() {
        return this.formatDataForSend();
    }

    set itemsAdd(val) {
        this._itemsAdd = this.formatDataForSend();
    }

    public duration = [
        { id: 1, name: '1 Year' },
        { id: 2, name: '2 Years' },
        { id: 3, name: '3 Years' },
        { id: 4, name: '4 Years' },
        { id: 4, name: '5 Years' },
        { id: 0, name: 'Custom end date' },
    ];



    public durationModel;

    private subs: SubSink = new SubSink();





    constructor(private toastr: ToastrService,
        private salesService: SalesService,
        private itemService: ItemService,
        private service: InvoiceService,
        private kitService: KitService,
        private cdr: ChangeDetectorRef,
        private orderBy: OrderByPipe) {
            this.isAllSuborg = salesService.isAllSuborg;
        }


    ngOnInit() {
        // console.log("invoice id ->"+this.invoiceId);

    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.invoiceId && changes.invoiceId.currentValue && changes.invoiceId.currentValue > 0) {
            // this.getSCItems();
            this.getInvoiceItems()
        }


    }



    private getInvoiceItems() {
        this.service.itemKitList(this.invoiceId).subscribe(res => {

            let sections;

            if (res && res.length > 0) {
                sections = _.chain(res)
                    .groupBy("section_title").map((value, key) => ({ title: key, rows: value })).value();

            }
            else {
                sections = [new Section()];
            }
            this.sections = sections;

        });


    }

    addSection() {
        this.sections = _.concat(this.sections, new Section);
    }

    removeSection(index: number) {
        if (this.sections.length > 1) {
            this.sections.splice(index, 1);
        }
    }

    getItemInSC(kitid: number) {
        return this.kitService.getItemsByKit(kitid).pipe(map(res => {
            let rows: ItemRow[] = [];
            if (res && res.length > 0) {
                for (let item of res) {

                    console.log(res);
                    let row = new ItemRow();
                    row.type = InvoiceItemsType.ITEM;
                    row.id = item.id;
                    row.unique_id = item.items;
                    row.primary_image = item.primary_image
                    row.customer = item.customer;
                    row.name = item.item_name;
                    row.part_number = item.part_number;
                    row.quantity = item.quantity || 1;
                    // row.serial_number=item.serial_number;
                    // row.coverage_type=item.coverage_type;
                    // row.sell_price = item.item_price;
                    row.price = item.item_price;
                    // row.sell_price_calc = item.item_price * item.quantity;
                    // row.order = item.order;
                    rows.push(row);
                }
            }
            return rows;
        }));
    }

    getKitsInSC(kitid: number) {
        return this.kitService.getKitsInKit(kitid).pipe(map(res => {
            let rows: ItemRow[] = [];
            if (res && res.length > 0) {
                for (let k2k of res) {
                    let row = new ItemRow();
                    row.type = InvoiceItemsType.KIT;
                    row.id = k2k.id;
                    row.unique_id = k2k.kit_kit;
                    row.customer = k2k.customer;
                    row.name = k2k.kit_name;
                    row.part_number = k2k.internal_number;
                    // row.kit_id = k2k.kit;
                    row.quantity = k2k.quantity || 1;
                    // row.serial_number=k2k.serial_number;
                    // row.coverage_type=k2k.coverage_type;
                    row.price = k2k.sell_price;
                    row.primary_image = k2k.primary_image;
                    // row.sell_price = k2k.sell_price;
                    // row.sell_price_calc = k2k.sell_price * k2k.quantity;
                    // row.order = k2k.order;
                    rows.push(row);
                }
            }
            return rows;
        }));
    }

    getOrderBy(rows: ItemRow[]) {
        return rows.some(item => item.order > 0) ? 'order' : 'item_name';
    }

    getRow(type: InvoiceItemsType){
        let row = new ItemRow();
        row.type = type;
        row.customer = this.itemService.customerId;
        if (type == this.KitIemTypes.TIMESHEET) {
            row.quantity = 0;
        }
        return row;
    }

    appendRow(sectionIndex: number, type: InvoiceItemsType) {
        this.newItem = true;
        this.toggleSelect(sectionIndex, type);
    }

    toggleSelect(sectionIndex: number, type: InvoiceItemsType, index?: number, ) {
        if(index >=0){
            this.newItem = false;
            this.rowIndex = index;
        }
        this.sectionIndex = sectionIndex;
        console.log(type);
        if (type === InvoiceItemsType.ITEM) {
            this.itemSelect.open();
        }
        else if (type === InvoiceItemsType.KIT) {
            this.kitSelect.open();
        }
        else if (type === InvoiceItemsType.TIMESHEET) {
            this.timesheetSelect.open();
        }
        else{
            if(this.newItem){
                let row = this.getRow(InvoiceItemsType.CUSTOM)
                this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
                this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
            }
        }
    }
    quantityOnKeyDown(row: ItemRow) {
        // if (this.validateQuantity(row))
            row.quantity = this.salesService.trimNumber(row.quantity, 5);
        // else
        //     row.quantity = row.ordered_quantity;
    }
    quantityOnChange(row: ItemRow) {
        // if (this.validateQuantity(row))
            this.rows = [...this.rows];
        // else
        //     row.quantity = row.ordered_quantity;
    }

    validateQuantity(row: ItemRow){
        if(row.ordered_quantity ==0 || row.ordered_quantity >= row.invoiced_quantity + row.quantity)
            return true;
        else
            return false
    }

    priceSummaryFunc(index) {
        return this.sections[index].rows.map(row => !isNaN(row.price) && row.type != this.KitIemTypes.TIMESHEET ? row.price * row.quantity : !isNaN(row.price) && row.type === this.KitIemTypes.TIMESHEET ? row.price : 0)?.reduce((a, b) => a + b, 0.00)?.toFixed(2);
    }

    calculateTotal() {
        let total = 0;
        this.sections.forEach((sec, index) => {
            if (sec && sec.rows && sec.rows.length > 0) {
                sec.rows.forEach((row, i) => {

                    if (!row.is_delete) {
                        if (row.type != this.KitIemTypes.TIMESHEET) {
                            total += Number(!isNaN(row.price) ? row.price : 0) * row.quantity;
                        }
                        else {
                            total += Number(!isNaN(row.price) ? row.price : 0);

                        }
                    }


                })
            }
        });

        return total.toFixed(2);
    }

    calculateGrandTotal() {
        return (Number(this.calculateTotal()) + Number(this.calculateTax())).toFixed(2);
    }

    calculateTax() {
        return this.tax > 0 ? Number((Number(this.calculateTotal()) * this.tax) / 100).toFixed(2) : 0;
    }



    onPriceChange(event, index) {
        if (!isNaN(event)) {
            let val = Number(event).toFixed(2);
            this.rows[index].price = Number(val);
        }
    }

    onPriceKeyDown(event, secIndex, index) {
        this.sections[secIndex].rows[index].price = this.salesService.toFixedNoRounding(event);
    }

    deleteItemKit(id, secIndex, rowIndex) {
        if (this.invoiceId > 0 && id) {
            this.sections[secIndex].rows[rowIndex].is_delete = true;
        }
        else {
            this.deleteRow(secIndex, rowIndex);
        }

    }


    // deleteItemKit(id, secIndex, rowIndex){
    //   if(this.invoiceId>0&&id){
    //       this.subs.sink=this.service.itemKitDelete(id).subscribe(
    //         response=>{
    //           this.toastr.success("Sales Order item deleted successfully");
    //           this.deleteRow(secIndex, rowIndex);
    //         },
    //         ()=>{
    //           this.toastr.error("Error occured during process");
    //         }
    //       );
    //   }
    //   else{
    //     this.deleteRow(secIndex, rowIndex);
    //   }
    // }



    deleteRow(secIndex, rowIndex) {
        if (this.sections[secIndex] && this.sections[secIndex].rows[rowIndex]) {
            this.sections[secIndex].rows.splice(rowIndex, 1);
            this.cdr.markForCheck();
        }
    }


    onItemChange(item: ItemWrapper) {
        if(this.newItem){
            let row = this.getRow(InvoiceItemsType.ITEM)
            this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
            this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
        }
        if (item && this.sections[this.sectionIndex] && this.sections[this.sectionIndex].rows[this.rowIndex]) {
            this.sections[this.sectionIndex].rows[this.rowIndex].unique_id = item.id;
            this.sections[this.sectionIndex].rows[this.rowIndex].name = item.item_name;
            this.sections[this.sectionIndex].rows[this.rowIndex].part_number = item.part_number;
            this.sections[this.sectionIndex].rows[this.rowIndex].primary_image = item.primary_image;
            this.sections[this.sectionIndex].rows[this.rowIndex].price = item.item_price;
            // this.sections[this.sectionIndex].rows[this.rowIndex].sell_price_calc = item.item_price;
            this.sections[this.sectionIndex].rows = [...this.sections[this.sectionIndex].rows];
        }
    }

    onKitChange(kit: KitWrapper) {
        if (this.invoiceId === kit.id) {
            this.toastr.info('Cannot select this kit');
            return;
        }
        if(this.newItem){
            let row = this.getRow(InvoiceItemsType.KIT)
            this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
            this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
        }
        if (kit && this.sections[this.sectionIndex] && this.sections[this.sectionIndex].rows[this.rowIndex]) {
            this.sections[this.sectionIndex].rows[this.rowIndex].unique_id = kit.id;
            this.sections[this.sectionIndex].rows[this.rowIndex].name = kit.name;
            this.sections[this.sectionIndex].rows[this.rowIndex].part_number = kit.internal_number;
            this.sections[this.sectionIndex].rows[this.rowIndex].price = kit.sell_price;
            this.sections[this.sectionIndex].rows[this.rowIndex].primary_image = kit.primary_image;
            // this.sections[this.sectionIndex].rows[this.rowIndex].sell_price_calc = kit.sell_price;
            this.sections[this.sectionIndex].rows = [...this.sections[this.sectionIndex].rows];
        }
    }

    onTimeSheetChange(timesheet: TimeSheet[]) {

        const pipe = new DatePipe('en-US');
        console.log(timesheet);
        if (timesheet.length > 0) {
            timesheet.forEach((val, index) => {
                if (this.invoiceId === val.id) {
                    this.toastr.info('Cannot select this kit');
                    return;
                }
                if(this.newItem){
                    let row = this.getRow(InvoiceItemsType.TIMESHEET)
                    this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
                    this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
                }
                if (val && this.sections[this.sectionIndex] && this.sections[this.sectionIndex].rows[this.rowIndex]) {
                    this.sections[this.sectionIndex].rows[this.rowIndex].unique_id = val.id;
                    this.sections[this.sectionIndex].rows[this.rowIndex].name = pipe.transform(val.created_on, 'MM/dd/YYYY') + ": " + val.comments;
                    this.sections[this.sectionIndex].rows[this.rowIndex].quantity = val.time;
                    // this.sections[this.sectionIndex].rows[this.rowIndex].partnumber = val.;
                    this.sections[this.sectionIndex].rows[this.rowIndex].price = val.total_amount;
                    // this.sections[this.sectionIndex].rows[this.rowIndex].sell_price_calc = kit.sell_price;
                    this.sections[this.sectionIndex].rows = [...this.sections[this.sectionIndex].rows];
                }
            });
        }
    }



    validate() {
        return this.rows.filter(item => item.item_id > 0).length > 0;
    }

    getApis(kitid: number): Observable<any>[] {
        this.rows.forEach(row => row.kit_id = kitid);
        let items = this.convertKitItemRow();
        let apis: Observable<any>[] = [];
        this.getItemApis(apis, items.items);
        this.getKitApis(apis, items.kits);
        return apis;
    }

    getItemApis(apis: Observable<any>[], items: KitItem[]) {
        // let add = items.filter(ki => !ki.id || ki.id <= 0);
        // if (add.length > 0) {
        //     apis.push(this.kitService.addKitItems(add));
        // }
        // let update = items.filter(ki => ki.id && ki.id > 0);
        // if (update.length > 0) {
        //     apis.push(this.kitService.updateKitItems(update));
        // }
        // if (this.deleteItems.length > 0) {
        //     apis.push(this.kitService.deleteKitItems(this.deleteItems));
        // }
    }

    getKitApis(apis: Observable<any>[], kits: Kit2Kit[]) {
        // let add = kits.filter(ki => !ki.id || ki.id <= 0);
        // if (add.length > 0) {
        //     apis.push(this.kitService.addKits2Kit(add));
        // }
        // let update = kits.filter(ki => ki.id && ki.id > 0);
        // if (update.length > 0) {
        //     apis.push(this.kitService.updateKits2Kit(update));
        // }
        // if (this.deleteKits.length > 0) {
        //     apis.push(this.kitService.deleteKits2Kit(this.deleteKits));
        // }
    }

    convertKitItemRow(): { items?: KitItem[], kits?: Kit2Kit[] } {
        let resp = { items: [], kits: [] };
        for (let i = 0; i < this.rows.length; i++) {
            let row = this.rows[i];
            if (!row.item_id || row.item_id <= 0) {
                return null;
            }
            if (row.itemType === InvoiceItemsType.ITEM) {
                let ki = new KitItem();
                ki.id = row.id;
                ki.items = row.item_id;
                ki.kits = row.kit_id;
                ki.quantity = row.quantity;
                ki.customer = row.customer;
                ki.order = row.order;
                resp.items.push(ki);
            }
            else if (row.itemType === InvoiceItemsType.KIT) {
                let ki = new Kit2Kit();
                ki.id = row.id;
                ki.kit_kit = row.item_id;
                ki.kit = row.kit_id;
                ki.quantity = row.quantity;
                ki.customer = row.customer;
                ki.order = row.order;
                resp.kits.push(ki);
            }
        }
        return resp;
    }

    submit(kitid: number, next?: (value: any) => any, error?: (value: any) => any): void {
        let apis = this.getApis(kitid);
        if (apis.length <= 0) {
            return next([]) || false;
        }
        this.subs.sink = forkJoin(apis).subscribe(
            results => {
                if (next) next(results); else this.toastr.success(`Kit Items updated`);
            },
            err => {
                if (err) error(error); else this.toastr.error(`Operation failed`);
            }
        );
    }







    formatDataForSend() {
        let itemsAdd: ItemAdd[] = [];
        this.sections.forEach((val, index) => {
            if (val.rows && val.rows.length) {

                val.rows.forEach((item, i) => {
                    let row: ItemAdd = new ItemAdd();

                    if (this.invoiceId && this.invoiceId > 0) {
                        row.invoice = this.invoiceId
                        row.id = item.id;
                    }
                    else {
                        row.invoice = null;
                    }
                    row.customer = item.customer;
                    row.unique_id = item.unique_id;
                    row.quantity = item.quantity;
                    row.serial_number = item.serial_number;
                    row.section_title = val.title ? val.title : '';
                    row.price = item.price;
                    row.type = item.type;
                    row.name = item.name;
                    row.is_delete = item.is_delete;
                    row.from_unique_number = item.from_unique_number;
                    row.ordered_quantity = item.ordered_quantity;
                    // row.invoiced_quantity += item.quantity;
                    itemsAdd.push(row);

                });

            }
        });
        // console.log(this.itemsAdd);
        return itemsAdd;
    }


    expandKit(row: ItemRow, level?:number) {
        if (!row.children) {
            if(level == 0)
                row.level = level;
            this.subs.sink = forkJoin([this.getItemInSC(row.unique_id), this.getKitsInSC(row.unique_id)]).subscribe(results => {
                if (results && results.length > 0) {
                    let items = [...results[0], ...results[1]];
                    row.children = this.orderBy.transform(items, this.getOrderBy(items));
                    row.expanded = true;
                    this.addColor(row);
                    this.cdr.markForCheck();
                }
            });
        }
        else {
            row.expanded = !row.expanded;
        }
    }

    addColor(row: ItemRow){
        row.children.map((el)=>{
            el.level = row.level + 1;
            el.color = getLightColor(el.level);
        });
    }

    toggleOtherRows(row: InvoiceItemRow) {
        for (let item of this.rows) {
            if (item.guid !== row.guid) {
                row.expanded = false;
            }
        }
    }

    checkValidation() {

        if (this.sections.length > 0) {
            let count = 0;
            this.sections.forEach((sec, idx) => {
                if (sec.rows && sec.rows.length == 0) {
                    count++;
                } else if (sec.rows && sec.rows.length > 0) {
                    sec.rows.forEach((item, index) => {
                        // if(item&&item.type!=this.KitIemTypes.TIMESHEET&&(!item.unique_id||item.unique_id==undefined)&&(!item.price||item.price==undefined)){
                        if (item && item.type != this.KitIemTypes.TIMESHEET && (!item.price || item.price == undefined)) {
                            count++;
                        }
                        else if (item && item.type != this.KitIemTypes.TIMESHEET && item.price.toString().length > 8) {
                            count++;
                        }
                        else if (item && item.type != this.KitIemTypes.TIMESHEET && item.ordered_quantity >0 && item.ordered_quantity< (item.invoiced_quantity + item.quantity)) {
                            count++;
                        }
                        else if (item && item.type != this.KitIemTypes.TIMESHEET && item.quantity ==0) {
                            count++;
                        }
                        if (item && item.type != this.KitIemTypes.CUSTOM && (!item.unique_id || item.unique_id == undefined)) {
                            count++;
                        }


                    });
                }
            });
            console.log(count);
            if (count > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }

    }

    snoUpdate(val, sectionIndex, index) {
        this.sections[sectionIndex].rows[index].serial_number = val;
    }

    minuteToHour(minutes: number) {
        if (minutes > 0) {
            return (minutes / 60).toFixed(2);
        }
        else {
            return 0;
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
