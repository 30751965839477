import { RoleViewComponent } from './pages/role-view/role-view.component';
import { Role2permissionComponent } from './pages/role2permission/role2permission.component';
import { AddRoleComponent } from './pages/add-role/add-role.component';
import { RoleListComponent } from './pages/role-list/role-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: RoleListComponent,
    data: { title: 'Roles' }
  },
  {
    path: ':id/edit',
    component: AddRoleComponent,
    pathMatch: 'full',
    data: { title: 'Edit Role' }
  },
  {
    path: ':id/perms',
    component: Role2permissionComponent,
    pathMatch: 'full',
    data: { title: 'Add Permissions' }
  },
  {
    path: ':id/view',
    component: RoleViewComponent,
    pathMatch: 'full',
    data: { title: 'Role Info' }
  },
  {
    path: 'new',
    component: AddRoleComponent,
    pathMatch: 'full',
    data: { title: 'New Roles' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolesRoutingModule { }
