import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GridMeta } from '../../models/grid-profiling.models';
import { GridMetaService } from '../../services/grid-meta.service';

@Component({
  selector: 'app-grid-meta-list',
  templateUrl: './grid-meta-list.component.html',
  styleUrls: ['./grid-meta-list.component.scss']
})
export class GridMetaListComponent implements OnInit, OnDestroy {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  public ColumnMode = ColumnMode;

  public SelectionType = SelectionType;

  public rows = new Observable<GridMeta[]>();

  private rowsFlat: GridMeta[];

  deleteSub$: Subscription;

  constructor(private router: Router,
    private metaService: GridMetaService,
    private cdr: ChangeDetectorRef,
    private toast: ToastrService) { }

  ngOnInit() {
    this.rows = this.metaService.getAll().pipe(map(resp => {
      this.rowsFlat = resp.gridresources;
      return resp.gridresources;
    }));
  }

  search(event: any) {
    const val = event.target.value.toLowerCase();
    this.rows = of(this.rowsFlat.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    }));
    this.table.offset = 0;
  }

  onSelect({ selected }) {
    let resource = selected[0];
    if (resource) {
      this.router.navigate(['/grids/meta', resource.id, 'edit']);
    }
  }

  deleteMeta(id: number) {
    this.deleteSub$ = this.metaService.delete(id).subscribe(
      _resp => {
        this.toast.success('1 row deleted');
        this.rowsFlat = this.rowsFlat.filter(m => m.id !== id);
        this.rows = of(this.rowsFlat);
        this.cdr.markForCheck();
      },
      _error => this.toast.error('Failed to delete resource')
    );
  }

  ngOnDestroy(): void {
    this.deleteSub$?.unsubscribe();
  }
}
