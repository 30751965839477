export class BlockListResponse {

    public id: number;

    public number: number;

    public type: string;

    public created_on: string

    public created_by: string

    public modified_on: string;

    public created_user: number;

    public modified_user: number;

    public is_delete: boolean;

    Message?: string;

    status?: boolean;

}

export class BlockListRequest {

    number: number;

    created_user?: number;

}