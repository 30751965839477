import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { Location } from '@angular/common';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { WarrantyService } from '../../services/warranty.service';
import { WarrantyDashBoardCounts, WarrantyList, WarrantyStatus } from '../../models/warranty.models';
import * as popup from 'src/app/core/utils/popup.functions';
import { Subscriber } from 'rxjs';
//import { WarrantyComponent } from '../warranty/warranty.component';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WarrantyDataHelper } from '../../helpers/warranty-data.helper';
import { SalesService } from '../../services/sales.service';


@Component({
  selector: 'app-warranty-list',
  templateUrl: './warranty-list.component.html',
  styleUrls: ['./warranty-list.component.scss']
})

export class WarrantyListComponent implements OnInit {

  public expanded: boolean = true;

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  //@ViewChild(WarrantyComponent) WarrantyView: WarrantyComponent;

  @ViewChild('dataTable') dataTable:DatatableComponent;

  
  public selected: any;

  private wId = 0;

  public subs: SubSink = new SubSink();

  public SelectionType = SelectionType;

  public rows: WarrantyList[] = [];

  public dashlet: WarrantyDashBoardCounts = WarrantyStatus;

  public WtyStatus = WarrantyStatus;

  public coverageType = WarrantyDataHelper.coverateType;

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

  public module: ModuleConstants = ModuleConstants.WARRANTY;

  public scrollbarH: boolean = false;

  public defaults: GFilterParam[] = WarrantyDataHelper.defaults;

  public search: GFilterParam[] = WarrantyDataHelper.search;


  public columns: GFColumn[] = WarrantyDataHelper.columns;

  public sortLookup = WarrantyDataHelper.sortLookup;

  public UIPermissions: UIPermission;

  public Permissions = PermissionConstants;

  constructor(private toastr: ToastrService,
    public location: Location,
    private WarrantyService: WarrantyService,
    private cdr: ChangeDetectorRef,
    private ra: ResourceAccessService,
    activeRoute: ActivatedRoute,
    private device: DeviceDetectorService,
    public salesService: SalesService) {

    this.subs.sink = activeRoute.params.subscribe(params => this.wId = params.id || 0);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_WARRANTY, ResourceConstants.SALES_MODULE);

    this.scrollbarH = this.device.isMobile();
  }

  ngOnInit(): void {
    this.getData(null);
    if (this.wId != 0) {
      this.getById(this.wId);
    }
    this.getDashboardCounts();
  }

  ngAfterViewInit() {

    setTimeout(()=>{                        
      this.dataTable.recalculate();
    }, 800);
    
  }

  goToLocation(path: string) {
    this.location.go(path);
  }

  onSelect({ selected }): void {
    let wrapper = selected[0];
    this.selected = wrapper;
    if(this.selected&&this.UIPermissions.viewInfo){
        this.getById(this.selected.id);
        this.expanded = false;
        this.location.go('sales/warranty/' + this.selected.id + '/view');
    }
  }

  listWarranty() {
    this.subs.sink = this.WarrantyService.listWarranty(2).subscribe(
      response => {
        this.rows = response;
        this.refreshDataTable();
      }
    );
  }

  getData(filters: GFilterParam[], offset: number = 0) {
    let option = { sort: this.sort, filters: filters };
    this.subs.sink = this.WarrantyService.getAll(option, offset).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
      this.dataTable.recalculate();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }


  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter?.getDataFilters() || [], offset);
  }

  getById(id: number) {
    this.subs.sink = this.WarrantyService.viewWarranty(id).subscribe(response => {
      this.selected = response;
      this.expanded = false;
    });
  }

  deleteWarranty(row: WarrantyList) {
    let data = { "is_delete": true };
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.WarrantyService.patchWarranty(data, row.id).subscribe(
          () => {
            this.rows = this.rows.filter(x => x.id != row.id);
            this.cdr.markForCheck();
            this.closeDetailedView();
            popup.CompleteDelete('Warranty deleted successfully');
            this.reloadData();
          },
          () => {
            this.toastr.error("Unable to delete warranty");
          }
        );
      }
    });
  }

  applyFilter(params: GFilterParam[]) {
    params.forEach((value,index)=>{
      if(value.colname=="warranty_status"&&value.value=="Expired_in_90_days"){
        value.colname="expired_in_90_days";
      }
    });
    this.getData(params);
  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go(`/sales/warranty`);
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.cdr.markForCheck();
      this.rows = [...this.rows];
    });
  }

  setFilter(colname: string, value: any) {
    this.dataFilter.setDefaultFilter(colname, value);
  }

  getDashboardCounts() {
    this.subs.sink = this.WarrantyService.getDashboardCounts().subscribe(response => {
      this.dashlet = response;
      this.cdr.markForCheck();
    });
  }

  statusClass(id: number) {
    let status = WarrantyDataHelper.statusClass;
    return status[id];
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.SALES_WARRANTY, [permission, this.Permissions.GLOBAL], ResourceConstants.SALES_MODULE)
  }

  reloadData() {
    this.getData(this.dataFilter?.getDataFilters() || [], this.page.offset);
    this.getDashboardCounts();
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }



  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
