import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { Catalog, CatalogueProductListResponse } from '../../models/catalogue.models';
import * as popup from 'src/app/core/utils/popup.functions';
import { CatalogueService } from '../../services/catalogue-service.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'producttemplate-view',
  templateUrl: './producttemplate-view.component.html',
  styleUrls: ['./producttemplate-view.component.scss']
})
export class ProducttemplateViewComponent implements OnInit {

  public subs:SubSink=new SubSink();

  @Input() product:CatalogueProductListResponse;

  @Output() onClose = new EventEmitter<void>();

  @Output() onDelete = new EventEmitter<number>();


  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;


  public fileUrl: string = environment.apiURL + '/static/files/';


  constructor(private ra:ResourceAccessService, 
    private catalogService:CatalogueService, 
    private cdr: ChangeDetectorRef, 
    private toastr:ToastrService) { }

  ngOnInit(): void {

    console.log('YOu are here');
  }

  deleteItem() {
    this.onDelete?.emit(this.product.id);
  }

  closeItemView(): void {
    this.onClose?.emit();
  }

//   changeStatus() {
//     let opt = {
//         title: `Are you sure you want to ${this.catalog.is_active ? 'Activate catalog' : 'Deactivate Catalog'}?`,
//         text: this.catalog.name,
//         current: this.catalog.is_active,
//     };
//     popup.ConfirmActivate(opt, (result) => {
//         if (result.isConfirmed) {
//             this.subs.sink = this.catalogService
//                 .updateCatalog(this.catalog.id, this.catalog)
//                 .subscribe(
//                     () => { },
//                     () => {
//                         this.catalog.is_active = !this.catalog.is_active;
//                         this.cdr.markForCheck();
//                         this.toastr.error('Failed to change status');
//                     }
//                 );
//         } else {
//             this.catalog.is_active = !this.catalog.is_active;
//             this.cdr.markForCheck();
//         }
//     });
// }

  hasPermission(resource: string, permission: string) {
      return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE)||this.isSuperAdmin;
  }

  hasPermissionAny(resource: string, permission: string[]) {
      return this.ra.hasPermission(resource, permission, ResourceConstants.INV_MODULE)||this.isSuperAdmin;
  }



  get isSuperAdmin(){
    return this.catalogService.isSuperUser;
  }


  ngOnDestroy(): void {
    this.subs?.unsubscribe();
}

}
