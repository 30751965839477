import { ItemTypesService } from './../../../services/item-types.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ItemType } from '../../../models/items';

@Component({
    selector: 'app-item-type-form',
    templateUrl: './item-type-form.component.html',
    styleUrls: ['./item-type-form.component.scss']
})
export class ItemTypeFormComponent implements OnInit, OnDestroy {

    public model: ItemType;

    @Input() itemTypeId: number = 0;

    @Output() onSuccess = new EventEmitter<{ response: ItemType, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    @ViewChild('itemTypeForm') itemTypeForm: NgForm;

    public nameExist: boolean = false;

    private getSub$: Subscription;

    private postSub$: Subscription;

    constructor(private service: ItemTypesService, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.itemTypeId > 0) {
            this.getSub$ = this.service.getById(this.itemTypeId).subscribe(itemType => {
                this.model = itemType;
                this.cdr.markForCheck();
            });
        }
        else {
            this.model = this.service.createModel(ItemType);
        }
    }

    onSubmit(): void {

        let api: Observable<ItemType>;

        if (this.itemTypeId > 0) {
            api = this.service.update(this.itemTypeId, this.model);
        }
        else {
            api = this.service.create(this.model);
        }

        this.postSub$ = api.subscribe(
            resp => {
                let msg = this.itemTypeId > 0 ? 'Item Type info updated' : 'New Item Type added';
                this.nameExist = false;
                this.onSuccess?.emit({ response: resp, message: msg });
            },
            error => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.onError?.emit(error);
                }
            }
        )
    }

    onCancelClicked(): void { this.onCancel?.emit(); }

    ngOnDestroy(): void {
        this.getSub$?.unsubscribe();
        this.postSub$?.unsubscribe();
    }
}
