import { ChangeDetectorRef, Renderer2, Component, ElementRef, Inject, OnInit, ViewChild, ContentChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { fabric } from 'fabric';
import { ToastrService } from 'ngx-toastr';
import { catchError, map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ItemSelectComponent } from '../../components/select/item-select/item-select.component';
import { KitSelectComponent } from '../../components/select/kit-select/kit-select.component';
import { Item, ItemWrapper } from '../../models/items';
import { Kit, KitWrapper } from '../../models/kit.models';
import { LabelListResponse, LabelPageCreate, LabelPageTemplate, printPagination, qrData } from '../../models/label.models';
import { WareHouse, WareHouseLocation } from '../../models/warehouse.models';
import { ItemService } from '../../services/item.service';
import { LabelService } from '../../services/label.service';
import { WarehouseService } from '../../services/warehouse.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { CurrencyPipe, Location } from '@angular/common';
// import * as jsPDF from 'jspdf'

import {jsPDF} from 'jspdf';


import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import { SafeUrl } from '@angular/platform-browser';
import { QRCodeComponent, QRCodeElementType, QRCodeModule } from 'angularx-qrcode';
import { KitAssembleService } from '../../services/kit-assemble.service';
import { KitAssembleWrapper } from '../../models/kit-assemble.models';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { KitService } from '../../services/kit.service';
import { resolve } from 'dns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from 'src/app/core/services/base.service';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-label-print',
  templateUrl: './label-print.component.html',
  styleUrls: ['./label-print.component.scss'],
  providers: [
    { provide: 'Window',  useValue: window }
  ]
})
export class LabelPrintComponent extends BaseService implements OnInit {


  @ViewChild('itemSelect') itemSelect: ItemSelectComponent;

  @ViewChild('kitSelect') kitSelect: KitSelectComponent;

  @ViewChild('carousel') carousel: any;


  @ViewChild('qrElement') qr:QRCodeComponent;
    
  public pCanvas:any;


  public type:string;

  public showPreview:boolean;

  public totalLabels:number;

  public printTemplate:number;

  public selectedTemplate:LabelPageTemplate;

  public subs:SubSink=new SubSink();

  public templateList:LabelPageTemplate[];

  public itemList:ItemWrapper[]=[];

  public uniqueId:number;

  public assemblyId:number;

  public selectedItem:ItemWrapper;



  public selectedAssembly:KitWrapper;

  public assemblyList:KitWrapper[]=[];

  // public selectecKit:KitWrapper;

  // public kitList:KitWrapper[]=[];

  // public assemblyList:KitAssembleWrapper[]=[];


  public selectedBuiltAssembly:KitAssembleWrapper;

  public builtAssemblyList:KitAssembleWrapper[]=[];

  public warehouses:WareHouse[];

  public warehouseLocations: WareHouseLocation[] = [];

  public werehouse_name:string;

  public werehouseId:number;

  public locationId:number;

  public location_name:string;

  public qrData:string=" ";


  public labelMarginTop:number=10;

  public labelMarginRight:number=10;

  public labelMarginBottom:number=10;

  public labelMarginLeft:number=10;


  public printCount:number=1;

  public showCarousel=true;

  public slNoString:string;

  public slNoArray:string[]=[];

  public newWerehouseId:number;

  public newLocationId:number;

  public newTemplateId:number;

  public qrModel:any;

  public qrUrl:SafeUrl;

  public useTemplate:boolean=true;

  public labelList:LabelListResponse[]=[];

  public selectedLabel:LabelListResponse;

  public labelId:number;

  public customPrint:boolean=false;

  public rollPaper:boolean=true;

  public paperCustomWidth:number=4.2;

  public paperCustomHeight:number;

  public saveSettings:boolean=false;

  public settingsName:string;

  public editSetting:boolean=false;
  
  public itemId:number;

  public showQr:boolean=true;

  public Permissions=PermissionConstants;

  public Resource = ResourceConstants;

  public pagination:printPagination=new printPagination();

  public canvasLoaded:boolean=false;

  public svgContent=[];

  public LimitedTextbox:any;

  public typeId:number;

  // public currencyPipe:CurrencyPipe;


  constructor(private labelService:LabelService,
    private activeRoute: ActivatedRoute,
    private itemService:ItemService,
    private kitService:KitService,
    private warehouseService: WarehouseService,
    private cdr:ChangeDetectorRef,
    private toastr:ToastrService,
    private router: Router,
    private location: Location,
    private ra: ResourceAccessService, 
    private assembleService: KitAssembleService,
    private currencyPipe:CurrencyPipe,
    private modalService: NgbModal
     
    ) {
      super();


      this.subs.sink=this.activeRoute.queryParams.subscribe(param=>{

      // console.log(param.type);
      this.type=param['type']||null;
      this.uniqueId=Number(param['uniqueId'])||null;
      this.printCount=Number(param['printCount'])||1;
      this.newLocationId=Number(param['location'])||null;
      this.newWerehouseId=Number(param['werehouse'])||null;
      this.newTemplateId=Number(param['template'])||null;
      this.slNoString=param['slNo']||'';


    });

   }

    ngOnInit(): void {


          if(this.type=='item'&&this.uniqueId){
            this.getItemById();
            this.typeId=1;
          }
          else if(this.type=='assembly'&&this.uniqueId){
            this.getKitById();
            this.typeId=3;


          }
          else if(this.type=='builtAssembly'&&this.uniqueId){
            this.getAssemblyById();
            this.typeId=2;


          }

          this.getPrintTemplates();
          this.getWarehouses();

          this.getLabelTemplates();

          if(this.slNoString&&this.slNoString.length>0){

              this.slNoArray=this.slNoString.split(",");

              if(this.slNoArray.length==0&&this.slNoString.length>0){
                this.slNoArray.push(this.slNoString);
              }
          
          }


          this.pCanvas = new fabric.Canvas('canvas');
          this.initTextBox();

    }

    initTextBox(){

      this.LimitedTextbox=fabric.util.createClass(fabric.Textbox, {
        drawCacheOnCanvas:function(ctx){
          console.log(ctx);
          this.height=this.customHeight;
          this.callSuper('drawCacheOnCanvas',ctx);

        },
        _render:function(ctx){
          this.callSuper('_render',ctx);
        },
        _renderControls:function(ctx, styleOverrideopt){
          this.callSuper('_renderControls',ctx);
        },
        initialize:function(text, options){
          this.customHeight=this.height;
          this.callSuper('initialize', text, options);

        },
    });
}

  

 /* Gets all the print templates */
  getPrintTemplates(){

    let filters=[];



    if(this.type){
      filters.push({colname: "label_template_type", condition: "is", value: this.type, operator: "AND"})
    }

      let option = { sort: [{ colname: 'created_on', direction: 'desc'}], 
      filters:filters
    };
      this.subs.sink=this.labelService.labelTemplateFilter(option).subscribe(
      response=>{
        this.templateList=response.result;
        if(this.newTemplateId){
          this.printTemplate=Number(this.newTemplateId);

        }
        this.templateList.forEach((val, index)=>{
            if(val.id==this.printTemplate){
              this.selectedTemplate=val;
              this.rollPaper=this.selectedTemplate.is_roll_paper;
              this.templateChange(val);
            }
        });
        this.cdr.markForCheck();

      }
      );
  }



  /* Calls when change in selected item */

  onItemChange(item, qr){
    this.itemList=[item];
    this.uniqueId=item.id;
    this.selectedItem=item;


    let tmp=new qrData();


    tmp.partNo=item.part_number;
    
    
    tmp.t='I';
    

    this.qrData=JSON.stringify(tmp);

    this.updateCanvas();
  }



  /* Calls when change in Assembly */
  onAssemblyChange(assembly, qr){
    this.assemblyList=[assembly];
    this.uniqueId=assembly.id;
    this.selectedAssembly=assembly;


    let tmp=new qrData();

    tmp.partNo=assembly.internal_number;

    tmp.t='A';
    


    this.qrData=JSON.stringify(tmp);


    this.updateCanvas();
  }

    /* Calls when change in Assembly */
    onBuiltAssemblyChange(builtAssembly, qr){
      this.builtAssemblyList=[builtAssembly];
      this.uniqueId=builtAssembly.id;
      this.selectedBuiltAssembly=builtAssembly;
  
  
      let tmp=new qrData();
  
      tmp.partNo=builtAssembly.part_number;
      tmp.uNo=builtAssembly.unique_number;
      // tmp.slNo='89SDF8S9D';
      tmp.t='BA';
  
      this.qrData=JSON.stringify(tmp);

      console.log(this.qrData);
  
      this.updateCanvas();
    }


  /* Calls when change in print template */
  templateChange(template){

    if(template){
      this.totalLabels=template.total_labels||0;

      this.selectedTemplate=template;

      this.rollPaper=this.selectedTemplate.is_roll_paper;
  
      this.labelMarginTop=template.margin_top;
      this.labelMarginRight=template.margin_right;
      this.labelMarginBottom=template.margin_bottom;
      this.labelMarginLeft=template.margin_left;
  
      this.updateCanvas();
    }
    else{
      this.selectedTemplate=null;
      this.showPreview=false;
    }

  }



  printCountChange(){

    this.resetPagination();
    this.updateCanvas();

    if(this.printCount<1){
      this.toastr.error('Please enter a valid print count which is greater than zero');
    }


  }

  /* Child function of updateCanvas() which adds 
    each elements to the canvas */

  addElement(left:number=20, top:number=20, width:number, height:number, labelCount:number, pagination){

    var rect=this.pCanvas.add(new fabric.Rect({
      selectable:false,
      width:width, 
      height:height,
      left:left,
      top:top,
      // fill:'#eeeeee'
      fill:'#ffffff'
    }));
    // this.pCanvas.moveTo(tmp, 100);
    

    let template;
    if(this.customPrint){
      template=this.selectedLabel.template;
    }
    else{
      template=this.selectedTemplate.label_template_template;
    }

    // var canvas=this.pCanvas;
  
    template.objects.forEach((val,index)=>{

      if(val.type=='i-text'||val.type=="textbox"){

        if(this.type=='item'){

          let text;

          if(val.name&&val.name=='item_name'&&this.selectedItem){
            text=(this.type=='item')?this.selectedItem.item_name:'';
             console.log(text);
             console.log(val.text);
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='sku'){
            text=this.selectedItem?.sku?this.selectedItem.sku:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='werehouse'){
            text=this.werehouse_name?this.werehouse_name:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='werehouse_location'){
            text=this.location_name?this.location_name:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='serial_number'){

            let lCount=labelCount;


            if(pagination.currentPage!=1){
              lCount=(pagination.labelsPerPage*pagination.previousPage)+labelCount;
            }
            // console.log(lCount);
            text=this.slNoArray[lCount]?this.slNoArray[lCount]:'';

            val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
          else if(val.name=='category'){
            text=this.selectedItem.category_name?this.selectedItem.category_name.toString():'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          
          else if(val.name=='manufacturer'){
            text=this.selectedItem.manufacturers_name?this.selectedItem.manufacturers_name.toString():'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='part_number'){
            text=this.selectedItem?.part_number?this.selectedItem.part_number.toString():'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='supplier'){
            text=this.selectedItem.suplier?this.selectedItem.suplier.toString():'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='sell_price'){
            text=this.selectedItem.item_price?this.selectedItem.item_price.toString():'';
            text=this.currencyPipe.transform(text,'USD');
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='item_type'||val.name=='type'){
            text=this.selectedItem.itemtype_name?this.selectedItem.itemtype_name.toString():'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }

        }
        else if(this.type=='builtAssembly'){

          let text;

          if(val.name&&val.name=='item_name'&&this.selectedBuiltAssembly){
            text=this.selectedBuiltAssembly.kit_name?this.selectedBuiltAssembly.kit_name:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='sku'){
            text=this.selectedBuiltAssembly?.kit_sku?this.selectedBuiltAssembly.kit_sku:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='werehouse'){
            text=this.werehouse_name?this.werehouse_name:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='werehouse_location'){
            text=this.location_name?this.location_name:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='serial_number'){
            let lCount=labelCount;


            if(pagination.currentPage!=1){
              lCount=(pagination.labelsPerPage*pagination.previousPage)+labelCount;
            }
            // console.log(lCount);
            text=this.slNoArray[lCount]?this.slNoArray[lCount]:'';

            val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
          else if(val.name=='category'){
            text=this.selectedBuiltAssembly?.kit_category_name?this.selectedBuiltAssembly.kit_category_name:'';
            // val.text=val.text;
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='manufacturer'){
            val.text='';
            // text=text=this.selectedBuiltAssembly.kit_sellprice?this.selectedBuiltAssembly.kit_sellprice.toString():'';
            // val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='part_number'){
            text=text=this.selectedBuiltAssembly.part_number?this.selectedBuiltAssembly.part_number.toString():'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='supplier'){
            text='';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='sell_price'){
            text=this.selectedBuiltAssembly.kit_sellprice?this.selectedBuiltAssembly.kit_sellprice.toString():'';
            text=this.currencyPipe.transform(text,'USD');
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
          else if(val.name=='item_type'||val.name=='type'){
            // text=this.selectedBuiltAssembly.ite
            val.text = this.wrapCanvasText(text, this.pCanvas, val);
          }
        }
        else if(this.type=='assembly'){

          let text;


          if(val.name&&val.name=='item_name'){

            text=this.selectedAssembly?.name?this.selectedAssembly?.name:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
          else if(val.name=='sku'){
            text=this.selectedAssembly?.sku?this.selectedAssembly.sku:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
          else if(val.name=='werehouse'){
            text=this.werehouse_name?this.werehouse_name:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
          else if(val.name=='werehouse_location'){
            text=this.location_name?this.location_name:'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
          else if(val.name=='serial_number'){
            val.text="";
            // text=this.slNoArray[labelCount-1]?this.slNoArray[labelCount-1]:'';
            // val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
          else if(val.name=='category'){
            val.text='';
            val.text=this.selectedAssembly.category_name?this.selectedAssembly.category_name.toString():'';
          }
          else if(val.name=='manufacturer'){
            val.text='';
            // val.text=this.selectedAssembly.manufacturers_name?this.selectedAssembly.manufacturers_name.toString():'';
          }
          else if(val.name=='part_number'){
            val.text='';
            val.text=this.selectedAssembly?.internal_number?this.selectedAssembly.internal_number.toString():'';
          }
          else if(val.name=='supplier'){
            val.text='';
            // val.text=this.selectedItem.suplier?this.selectedItem.suplier.toString():'';
          }
          else if(val.name=='sell_price'){
            text=this.selectedAssembly?.sell_price?this.selectedAssembly.sell_price.toString():'';
            text=this.currencyPipe.transform(text,'USD');

            val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
          else if(val.name=='item_type'||val.name=='type'){
            text=this.selectedAssembly?.itemtype_name?this.selectedAssembly.itemtype_name.toString():'';
            val.text = this.wrapCanvasText(text, this.pCanvas, val);

          }
        }
 
          

          var ele=rect.add(new fabric.Textbox(val.text,{
            selectable:false,
            left:left+val.left,
            width:val.width,
            height:val.height,
            top:top+val.top, 
            fontSize:val.fontSize,
            fill:val.fill?val.fill:null,
            editable: false,
            scaleX:val.scaleX,
            scaleY:val.scaleY,
            name:val.name,
            textAlign:val.textAlign
          }));

          // ele.textAlign=val.textAlign;

          // console.log(val.textAlign);


     
      }
      else if(val.type=='image'&&val.name&&val.name=='qr_code'){
        var canvas=this.pCanvas;

        //test code, Remove if its not worked
          let partNo='';
          if(this.type=='item'){
            partNo=this.selectedItem?.part_number?this.selectedItem.part_number:'';
          }
          else if(this.type=='assembly'){
            partNo=this.selectedItem?.part_number?this.selectedItem.part_number:''
          }


          // if(this.slNoArray[labelCount-1]){
          //   let text={"partNo":partNo, "slNo":this.slNoArray[labelCount-1]};
          //   // this.showQr=false;
          //   this.qrData=JSON.stringify(text);
          //   // this.showQr=true;
            
          //   console.log('Yes sl no here');
          // }
          // else{

          //   let text={"partNo":partNo};

          //   // this.qrData='';
          //   // this.qr.qrdata=JSON.stringify(text);

          //   // console.log('No sl no here');

          // }



        const parentElement=this.qr.qrcElement.nativeElement.querySelector("img").src;


        let blobData = this.convertBase64ToBlob(parentElement);
        // saves as image
        const blob = new Blob([blobData], { type: "image/png" });
        const url = window.URL.createObjectURL(blob);

      fabric.Image.fromURL(parentElement, function(myImg) {
          var img1=myImg.set({left:left+val.left,selectable:false,  top:top+val.top, width:val.width, height:val.height});
          img1.scaleToHeight(100);
          img1.scaleToWidth(100);
          img1.scaleX=val.scaleX;
          img1.scaleY=val.scaleY;
          img1.name=val.name;
          rect.add(img1); 

          });


      this.pCanvas=canvas;
      }




    });

}


/* Checks overflow of element 
    beyond canvas when rendering */
checkOverflow(val){

  let context = this.pCanvas.getContext("2d");
  context.font = val.font;
  let width = context.measureText(val.text).width;
  let formattedWidth = Math.ceil(width);

            console.log('new width:'+formattedWidth);

   console.log(val);

   console.log("Label width: "+this.selectedTemplate.lable_template_width+", text left: "+val.left+", text width:"+val.width+", total width:"+(val.left+val.width));


   let text=val.text;
   let textArray=text.split(" ");
   let wordReduceCount=0
   let letterReduceCount=0;
   if(textArray.length>1){
    wordReduceCount=textArray.length;
   }
   else{
    letterReduceCount=text.length-1;
   }

   let newText='';


   while((val.left+formattedWidth)>this.selectedTemplate.lable_template_width){
      if(textArray.length>1){
        textArray[textArray.length-wordReduceCount]=textArray[textArray.length-wordReduceCount]+'\n';
        wordReduceCount--;
        newText= textArray.join(' ');
      }
      else{
        newText=text.slice(0,letterReduceCount)+"\n"+text.slice(letterReduceCount+1, text.length);
        letterReduceCount--;
      }

      width = context.measureText(newText).width;
      formattedWidth = Math.ceil(width);
   }

   return newText;



}



/* 
  Rendering canvas
  Calls when first time page loads 
  as well as there is posibble change in canvas value.
*/

updateCanvas(){

  if((this.customPrint&&!this.selectedLabel)||(!this.customPrint&&!this.selectedTemplate)){
    return;
  }


  this.showPreview=true;

  this.canvasLoaded=false;


  this.pCanvas.clear();

  let width=150;

  let height=60;

  let rollLabelPerPage, rollHeight;

  let template=null;

  if(this.customPrint){
    template=this.selectedLabel.template
    width=this.selectedLabel.width;
    height=this.selectedLabel.length;
    this.pCanvas.setWidth(this.paperCustomWidth*96);
    this.pCanvas.setHeight(this.paperCustomHeight*96);

  }
  else{
    template=this.selectedTemplate.label_template;
    width=this.selectedTemplate.lable_template_width;

    height=this.selectedTemplate.label_template_length;
    this.pCanvas.setWidth(this.selectedTemplate.width);
    this.pCanvas.setHeight(this.selectedTemplate.height);

    if(this.editSetting&&this.selectedTemplate.is_roll_paper){
      this.pCanvas.setWidth(this.paperCustomWidth*96);
      this.pCanvas.setHeight(this.paperCustomHeight*96);
    }

      // this.pCanvas.setHeight(this.paperCustomHeight*96);


  }






  let itemCount=1;
  let rowCount=1;


  let newLeft=0;

  let newTop=this.labelMarginTop;

  let itemsPerRow=this.howmanyLabelsPerRow(this.pCanvas.width, width);

  if(this.customPrint&&this.rollPaper){
    
    let estHeight=4*(this.selectedLabel.length+this.labelMarginTop+this.labelMarginBottom);
    // let estHeight=Math.ceil(this.printCount/itemsPerRow)*(this.selectedLabel.length+this.labelMarginTop+this.labelMarginBottom);
    this.paperCustomHeight=Math.ceil(estHeight/96);
    this.pCanvas.setHeight(estHeight);

  }

  let itemsPerColumn=this.howManyLabelsPerColumn(this.pCanvas.height, height);

  
  let totalItemInPage=Number(itemsPerRow*itemsPerColumn);

  let currentPageItemCount=totalItemInPage;

  if(this.rollPaper){
    this.createPagination(4);

  }
  else{
    this.createPagination(totalItemInPage);

  }

  let totalItemToPrint=totalItemInPage*this.pagination.totalPages;



  if(this.pagination.currentPage>0){

    let balanceCount=this.printCount-((this.pagination.currentPage-1)*totalItemInPage);

    if(balanceCount<=totalItemInPage&&balanceCount>0){
      currentPageItemCount=this.printCount-((this.pagination.currentPage-1)*totalItemInPage);
    }
    else{
      currentPageItemCount=totalItemInPage;
    }




  }

  if(this.rollPaper&&currentPageItemCount<=4){
    // rollLabelPerPage=this.printCount>4?4:this.printCount;
    rollHeight=(height+this.labelMarginTop+this.labelMarginBottom)*currentPageItemCount;
    this.pCanvas.setHeight(rollHeight);

  }


  this.totalLabels=totalItemInPage;

  
  while(itemCount<=currentPageItemCount&&itemCount<=this.printCount){

       if((newLeft+this.labelMarginLeft+this.labelMarginRight+width)<this.pCanvas.width){

           newLeft=newLeft+this.labelMarginLeft;

       }
       else{

          newLeft=this.labelMarginLeft;

       }

        if(itemsPerRow<itemCount){

            rowCount=Math.ceil(itemCount/itemsPerRow);
            
        }

        
         if(rowCount==1){
          newTop=this.labelMarginTop;

         }
         else{

          newTop=this.labelMarginTop+(height+this.labelMarginBottom+this.labelMarginTop)*(rowCount-1);

         }
     


          this.addElement(newLeft,  newTop, width, height, itemCount-1, this.pagination);
          newLeft=newLeft+width+this.labelMarginRight;
          itemCount++;


  }

  this.setCanvasZoom();

}










/* 
  This function will make sure the previous will be within the viewport
*/

setCanvasZoom(){

  let newZoom=1;

  while(newZoom>.2){

    if(newZoom*this.pCanvas.width<550){
      break;
    }
    else{
      newZoom=newZoom-0.15;
    }

  }
  this.pCanvas.setZoom(newZoom);
  this.pCanvas.setWidth(this.pCanvas.width * this.pCanvas.getZoom());
  this.pCanvas.setHeight(this.pCanvas.height * this.pCanvas.getZoom());

  this.canvasLoaded=true;

}



/* 
  Create pagination for the normal paper pages
*/

createPagination(itemPerPage){

    if(this.printCount>itemPerPage){

        this.pagination.isEnabled=true;

    }
    else{
      this.pagination.isEnabled=false;
      
    }

    this.pagination.totalItems=this.printCount;
    this.pagination.totalPages=Math.ceil(this.printCount/itemPerPage);
    this.pagination.labelsPerPage=itemPerPage;

}

resetPagination(){
  this.pagination.currentPage=1;
  this.pagination.previousPage=0;
  this.pagination.nextPage=2;
}







/* 
  Calculate How many label can be placed in a row
*/
howmanyLabelsPerRow(pageWidth:number, labelWidth:number){

  let count=Math.floor(pageWidth/(labelWidth+this.labelMarginLeft+this.labelMarginRight));

  if(count>0){
    return count;
  }
  else{
    return 0;
  }

}


/* 
  Calculate howmany Label will be in a row 
*/
howManyLabelsPerColumn(pageHeight:number, labelHeight:number){
  
  let count=Math.floor(pageHeight/(labelHeight+this.labelMarginTop+this.labelMarginBottom));

  if(count>0){
    return count;
  }
  else{
    return 0;
  }


}







private convertBase64ToBlob(Base64Image: string) {
  // split into two parts
  const parts = Base64Image.split(";base64,")
  // hold the content type
  const imageType = parts[0].split(":")[1]
  // decode base64 string
  const decodedData = window.atob(parts[1])
  // create unit8array of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length)
  // insert all character code into uint8array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }
  // return blob image after conversion
  return new Blob([uInt8Array], { type: imageType })
}

    preview(){
      this.showPreview=false;


      if(this.customPrint&&this.selectedLabel){
        this.showPreview=true;
      //  this.createPagination(4);


        // this.updateCanvas();

      }
      else if(!this.customPrint&&this.selectedTemplate){
        this.showPreview=true;
        // this.createPagination(4);

        // this.updateCanvas();

      }
      else{
        this.showPreview=false;
        if(this.customPrint){
          this.toastr.error('Please select label design');

        }
        else{
          this.toastr.error('Please select a setting');

        }
      }

    }


 
  getItemById(){
    this.subs.sink = this.itemService.getById(this.uniqueId).subscribe(item => {
      this.selectedItem = item;
      this.itemList=[item];
      this.uniqueId=item.id;
      this.cdr.markForCheck();
     this.onItemChange(item, this.qr );

    });
  }

  getAssemblyById(){
    this.subs.sink = this.assembleService.getAssembledKitById(this.uniqueId).subscribe(kit => {
      this.selectedBuiltAssembly = kit;
      this.builtAssemblyList=[kit];
      this.uniqueId=kit.id;
      this.cdr.markForCheck();
     this.onBuiltAssemblyChange(kit, this.qr );
  });
  }

  getKitById(){
    this.subs.sink = this.kitService.getById(this.uniqueId).subscribe(assembly => {
      this.selectedAssembly = assembly;
      this.assemblyList=[assembly];
      this.uniqueId=assembly.id;
      this.cdr.markForCheck();
     this.onAssemblyChange(assembly, this.qr );

    });
  }


  /* 
    Get list of werehouses
  */
  getWarehouses() {
    this.subs.sink=this.warehouseService.getAll().subscribe(response=>{
      this.warehouses=response;

      if(this.newWerehouseId){
        this.werehouseId=Number(this.newWerehouseId);

        this.warehouses.forEach((val)=>{

          if(val.id==this.newWerehouseId){
            this.werehouse_name=val.warehouse_name;
          }

        });
        this.getWarehouseLocation(this.werehouseId);

      }
    });
  }


  /* Gets list of location */

  getWarehouseLocation(warehouseId: number) {
      this.subs.sink = this.warehouseService.getWareHouseLocations(warehouseId).pipe(
        map(resp => resp),
        catchError(err => [])
      ).subscribe(locations => {
        this.warehouseLocations = locations;
        if(this.newLocationId){
          this.locationId=Number(this.newLocationId);

          this.warehouseLocations.forEach((val)=>{

            if(val.id==this.newLocationId){
              this.location_name=val.location;
            }
  
          });
        }
        this.cdr.markForCheck();

      });
  }



  /* Get list of label template */

  getLabelTemplates(){

    let filters=[];


    if(this.type){
      filters.push({colname: "type", condition: "is", value: this.type, operator: "AND"})
    }

      let option = { sort: [{ colname: 'created_on', direction: 'desc'}], 
      filters:filters
    };

    this.subs.sink=this.labelService.labelDesignFilter(option).subscribe(
      response=>{
        this.labelList=response.result;

        if(this.labelId){
          this.updateCanvas();
        }
        // this.selectedLabel=this.labelList.filter(x=>x.id==this.selectedPageTemplate.label_template)[0];
        this.cdr.markForCheck();
      }
     );
  }





  /* 
    Function prints and downloads pdf files
  */

 download(print=false){

      let zoom=this.pCanvas.getZoom();

      this.pCanvas.setZoom(1);

      let pdfWidth;

      let pdfHeight;

      let proceed=false;

      if(this.customPrint){
        this.pCanvas.setWidth(this.paperCustomWidth*96);
        this.pCanvas.setHeight(this.paperCustomHeight*96);
        pdfWidth=this.paperCustomWidth*96;
        pdfHeight=this.paperCustomHeight*96;

      }
      else{
        this.pCanvas.setWidth(this.selectedTemplate.width);
        this.pCanvas.setHeight(this.selectedTemplate.height);
        pdfWidth=this.selectedTemplate.width;

        if(this.rollPaper){
          pdfHeight=this.selectedTemplate.label_template_length+this.labelMarginTop+this.labelMarginBottom;
        }
        else{
          pdfHeight=this.selectedTemplate.height;

        }
      }

      // let svgContent=[];



      if(!this.rollPaper&&this.pagination.totalPages){

        // console.log(this.pagination);

        let currentPage=this.pagination.currentPage;

        this.svgContent=[];

        this.setDownloadContent(0, pdfWidth, pdfHeight, zoom, print);
                
        this.pagination.currentPage=1;
        this.pagination.previousPage=this.pagination.totalPages;
        this.pagination.nextPage=2;

        this.updateCanvas();

        if(this.svgContent.length==this.pagination.totalPages){

          console.log('you are here');
          proceed=true;
          console.log(this.svgContent);

        }

      }
      else{

        this.pCanvas.clear();
        this.svgContent=[];
        this.setRollDownloadContent(0, pdfWidth, pdfHeight, zoom, print);

      }

  }

  


  setDownloadContent(count=0, pdfWidth, pdfHeight, zoom, print){
   
    if(count<this.pagination.totalPages){

      this.pagination.currentPage=count+1;
      this.pagination.previousPage=count;
      this.pagination.nextPage=count+2;
      this.updateCanvas();
  
      setTimeout(()=>{
          this.svgContent.push({svg:this.pCanvas.toSVG(), width:pdfWidth*0.75, height: pdfHeight*0.75,});
          this.setDownloadContent(count+1, pdfWidth, pdfHeight, zoom, print);
  
      },10);

    }

    if(count==this.pagination.totalPages){
            const documentDefinition = {
              pageSize: {
              width: pdfWidth*0.75,
              height: pdfHeight*0.75,
            }, 
            pageMargins: 0,
            content:this.svgContent
          };

        if(print){
          pdfMake.createPdf(documentDefinition).print();


        }else{
          pdfMake.createPdf(documentDefinition).download();

        }

        this.pCanvas.setZoom(zoom);

        this.pagination.currentPage=1;
        this.pagination.previousPage=this.pagination.totalPages;
        this.pagination.nextPage=2;


        this.updateCanvas();

    }

  }





  /* Set seperated content for roll paper based printers */

   setRollDownloadContent(count, pdfWidth, pdfHeight, zoom, print){

    console.log(count+"/"+this.printCount);

    if(this.rollPaper){

      let newCount=count;

      let template, width, height;

      // let svg=[];

      this.pagination.currentPage=1;
      this.pagination.previousPage=0;
      this.pagination.nextPage=2;

      if(this.customPrint){

        template=this.selectedLabel.template

        width=this.selectedLabel.width;

        height=this.selectedLabel.length;
        pdfHeight=this.selectedLabel.length+this.labelMarginTop+this.labelMarginBottom;
        
      }
      else{

        template=this.selectedTemplate.label_template;

        width=this.selectedTemplate.lable_template_width;
    
        height=this.selectedTemplate.label_template_length;

        pdfHeight=this.selectedTemplate.label_template_length+this.labelMarginTop+this.labelMarginBottom;

      }
      this.pCanvas.setHeight(pdfHeight);

      this.addElement(this.labelMarginLeft, this.labelMarginTop, width, height, newCount, this.pagination);



      if(newCount<this.printCount){

             newCount=newCount+1;


              setTimeout(()=>{

                this.svgContent.push({svg:this.pCanvas.toSVG(), width:this.pCanvas.width*0.75, height: this.pCanvas.height*0.75,});
                this.setRollDownloadContent(newCount, pdfWidth, pdfHeight, zoom, print);


             },10);

      
        

      }
      else if(newCount==this.printCount){

                const documentDefinition = {
                  pageSize: {
                  width: pdfWidth*0.75,
                  height: pdfHeight*0.75,
                }, 
                pageMargins: 0,
                content:this.svgContent
              };

            if(print){
              pdfMake.createPdf(documentDefinition).print();


            }else{
              pdfMake.createPdf(documentDefinition).download();

            }

            this.pCanvas.setZoom(zoom);

            this.pagination.currentPage=1;
            this.pagination.previousPage=this.pagination.totalPages;
            this.pagination.nextPage=2;


            this.updateCanvas();

         }


  


        
    }
    else{

      console.log('This function is only for rollpaper based printers');
     


    }

  }



  // async fun1(){
  //   await this.rollDownloadCallback(newCount);
  // }

  // rollDownloadCallback(newCount){

  //   return new Promise(resolve=>{
  //         setTimeout(()=>{
  //           resolve("\t\t This is second promise");
  //           this.svgContent.push({svg:this.pCanvas.toSVG(), width:this.pCanvas.width*0.75, height: this.pCanvas.height*0.75,});
  //           this.setRollDownloadContent(newCount);

  //     },10);
  //   })
  // }




 /*
    Save template settings after editing
 */
  saveSetting(){

    let page:LabelPageCreate=new LabelPageCreate();

    page.title=this.settingsName;

    page.customer=this.labelService.customerId;

    page.margin_bottom=this.labelMarginBottom;

    page.margin_top=this.labelMarginTop;

    page.margin_left=this.labelMarginLeft;

    page.margin_right=this.labelMarginRight;

    page.total_labels=this.totalLabels;
    page.is_roll_paper=this.rollPaper;


   if(this.editSetting){

    page.label_template=this.selectedTemplate.label_template;

   }
   else{
    page.label_template=this.selectedLabel.id;

   }

    page.width=this.paperCustomWidth*96;

    page.height=this.paperCustomHeight*96;

  

    
    
    

    let api;
    if(this.editSetting&&this.selectedTemplate&&this.selectedTemplate.id>0){
      api=this.labelService.updatePageTemplate(this.selectedTemplate.id, page);
    }
    else{
      api=this.labelService.createPageTemplate(page);
    }

    this.subs.sink=api.subscribe(response=>{
        if(this.selectedTemplate&&this.selectedTemplate.id>0){
          this.toastr.success('New label settings updated')
          this.editSetting=false;
        }
        else{
          this.getPrintTemplates();

          
          this.toastr.success('New label settings created')
        }
        this.router.navigate([],{queryParamsHandling: 'merge', queryParams:{template:response.id}});

        this.getPrintTemplates();
        this.customPrint=false;
        this.saveSettings=false;
        this.cdr.markForCheck();

    });

  }


  /* Delete saved print setting */
  deleteSetting(){

    if(this.selectedTemplate){
      
      popup.ConfirmDelete(result => {
        if (result.isConfirmed) {
  
          this.subs.sink=this.labelService.deletePageTemplate(this.selectedTemplate.id).subscribe(
            response=>{
              this.toastr.success('Print settings deleted');
              this.selectedTemplate=null;
              this.printTemplate=null;
              this.showPreview=false;
              this.getPrintTemplates();
              this.cdr.markForCheck();
            }
          );
  
        }
  
  
    });
    }

  }

  editSettings(){

    this.editSetting=true;
    this.labelMarginBottom=this.selectedTemplate.margin_bottom;
    this.labelMarginLeft=this.selectedTemplate.margin_left;
    this.labelMarginRight=this.selectedTemplate.margin_right;
    this.labelMarginTop=this.selectedTemplate.margin_top;
    this.rollPaper=this.selectedTemplate.is_roll_paper;

    this.paperCustomWidth=this.selectedTemplate.width/96;
    this.paperCustomHeight=this.selectedTemplate.height/96;

    this.settingsName=this.selectedTemplate.title;

    this.cdr.markForCheck();

  }



  cancelEditing(){
    this.editSetting=false;
    this.saveSettings=false;
  }



  labelDesignChange(design){
    if(design){
      this.selectedLabel=design;

      if(this.rollPaper){
        let val=((this.selectedLabel.width+this.labelMarginLeft+this.labelMarginRight)/96);

        // this.paperCustomWidth=val;
        this.paperCustomWidth=parseFloat(this.formatter.format(parseFloat(val.toFixed(3))));
      }
      else{
        this.paperCustomWidth=8.5;
        this.paperCustomHeight=11;
      }

      // this.createPagination(4);
       this.resetPagination();

      this.updateCanvas();
    }
    else{
      this.selectedLabel=null;
    }

  }



  /* 
      Wraptext within the given textbox rectangle
   */

      wrapCanvasText(t, canvas, element) {

        // console.log("H is here"+element)




 
       let maxH=0, maxW=0;
 
       if (typeof maxH === "undefined") {
           maxH = 0;
       }
       else{
         maxH=element.height;
       }
 
       if (typeof maxW === "undefined") {
         maxW = 0;
       }
       else{
         maxW=element.width;
       }
      
       // var words = t.text.split(" ");

       var words, sansBreaks;

       if(t){
        words = t.split(" ");
        sansBreaks = t.replace(/(\r\n|\n|\r)/gm, "");


       }
       else{
         words = [t];
        sansBreaks = t;

       }

       var formatted = '';
   
       // clear newlines
       // var sansBreaks = t.text.replace(/(\r\n|\n|\r)/gm, "");  
       // calc line height
       // var lineHeight = new fabric.Text(sansBreaks, {
       //     fontFamily: t.fontFamily,
       //     fontSize: 25 //t.fontSize
       // }).height;
   
       var lineHeight=element.lineHeight;
       // var lineHeight=2;
       
       // adjust for vertical offset
       var maxHAdjusted = maxH > 0 ? maxH - lineHeight : 0;
       var context = canvas.getContext("2d");
 
       // console.log('maxh adjustes:'+maxHAdjusted);
   
   
       context.font = element.fontSize + "px " + element.fontFamily;
       var currentLine = "";
       var breakLineCount = 0;
   
       for (var n = 0; n < words.length; n++) {
   
           var isNewLine = currentLine == "";
           var testOverlap = currentLine + ' ' + words[n];
 
         // console.log('maxW:'+maxW+', maxH:'+maxH+' current Word:'+n+", testOverlap:"+w+", word:"+words[n]+", font:"+t.fontSize + "px " + t.fontFamily);
 
   
           // are we over width?
           var w = context.measureText(testOverlap).width;
   
           if (w < maxW) { // if not, keep adding words
               currentLine += words[n] + ' ';
               formatted += words[n] += ' ';
           } else {
   
               // if this hits, we got a word that need to be hypenated
               if (isNewLine) {
                   var wordOverlap = "";
   
                   // test word length until its over maxW
                   for (var i = 0; i < words[n].length; ++i) {
   
                       wordOverlap += words[n].charAt(i);
                       var withHypeh = wordOverlap + "-";
   
                       if (context.measureText(withHypeh).width >= maxW) {
                           // add hyphen when splitting a word
                           withHypeh = wordOverlap.substring(0, wordOverlap.length - 2) + "-";
                           // update current word with remainder
                           words[n] = words[n].substring(wordOverlap.length - 1, words[n].length);
                           formatted += withHypeh; // add hypenated word
                           break;
                       }
                   }
               }
               n--; // restart cycle
               formatted += '\n';
               breakLineCount++;
               currentLine = "";
           }
 
           var adjustedBreakLine=breakLineCount==1?breakLineCount:breakLineCount+1;
 
           var formattedTextboxHeight=(context.measureText(t).fontBoundingBoxAscent+context.measureText(t).fontBoundingBoxDescent)*(breakLineCount+1)*lineHeight;
 
           // console.log('formattedTextboxHeight: '+formattedTextboxHeight);
 
           // console.log('line break count'+breakLineCount);
           
           if (breakLineCount>0&&maxHAdjusted > 0 && formattedTextboxHeight > maxHAdjusted) {
               // add ... at the end indicating text was cutoff
               formatted = formatted.substring(0, formatted.length - 3) + "...\n";
               break;
           }
       }
       // get rid of empy newline at the end
       formatted = formatted.substring(0, formatted.length - 1);
 
 
   
       return formatted;
   }




  //Callback function from childcomponent

  paginationChange(pagination){
    this.pagination=pagination;
    this.updateCanvas();
  }


   formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
 });

  paperTypeChange(){
    if(this.rollPaper){
      if(this.customPrint){
        if(this.selectedLabel){
        var val=(this.selectedLabel.width+this.labelMarginLeft+this.labelMarginRight)/96;

        }


      }
      else{
        var val=(this.selectedTemplate.lable_template_width+this.labelMarginLeft+this.labelMarginRight)/96;
      }

      this.paperCustomWidth=parseFloat(this.formatter.format(parseFloat(val.toFixed(3))));

    }
    else{
        this.paperCustomWidth=8.5;
        this.paperCustomHeight=11;

    }
    this.resetPagination();
    this.updateCanvas();

  }




  goBack(){
    this.location.back();
  }

  openModal(content: any): void {
    this.modalService.open(content,{size: "xl"});
  }

  createDesignSuccess(event){
    // this.labelList.push(event);
    this.selectedLabel=event;
    this.labelId=event.id;
    this.getLabelTemplates();
    this.modalService.dismissAll();
    this.labelMarginLeft=0;
    this.labelMarginRight=0;
    this.toastr.success('Label created successfully');
    this.updateCanvas();

  }
        // console.log('maxW:'+maxW+', maxH:'+maxH+' current Word:'+n+", testOverlap:"+w+", word:"+words[n]+", font:"+textProp.fontSize + "px " + textProp.fontFamily);
        

  createDesignFailure(event){

  }
  createDesignCancel(event){
    this.modalService.dismissAll();
  }

  hasPermission(resource: string, permission: string) {
    return this.ra.hasPermission(resource, [permission, this.Permissions.GLOBAL]);
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }



}


