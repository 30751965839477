import { Customer } from "../../customer/models/customer";

export class addTerms{
    public title:string;
    public customer:number;
    public category:number;
    public description:string;
    public suborg:number;
}

export class getTermsCat{
    public customer:string;
}

export class TermsCatResponse{
    public category:number;
    public created_on:Date;
    public created_user:number;
    public customer:number;
    public description:string;
    public id:number;
    public is_active:boolean;
    public is_delete:false;
    public modified_on:Date;
    public modified_user:number;
    public title:string;
    public suborg:number;
}

export class listTerms{
    public category:number;
    public created_by:string;
    public created_on:Date;
    public created_user:number;
    public customer:Customer;
    public description:string;
    public id:number;
    public is_active:boolean;
    public is_delete:boolean;
    public modified_on:Date;
    public modified_user:Date;
    public title:string;
    public suborg:number;
}


export class TermsComments {

    public id: number;

    public terms: number;

    public comments: string;

    public parent: number;

    public customer: number;

    public created_by: string;

    public created_on: Date;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public is_delete: boolean;

    public is_active: boolean;

    edit: boolean;

    inputRows: number = 1;
}
