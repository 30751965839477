import { UserTypes } from '../../../core/enums/user-types';
import { AppConstants } from '../../../core/constants/app.constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, Output, EventEmitter, OnDestroy, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LayoutService } from '../../../core/services/layout.service';
import { SubOrg } from 'src/app/modules/suborg/models/suborg.models';
import { EventQueueService } from 'src/app/core/services/event-queue.service';
import { AppEventType } from 'src/app/core/enums/common.enum';
import { AppEvent } from 'src/app/core/models/common.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Location } from '@angular/common'
import { SubSink } from 'subsink';
import { SIPdetailsResponse, UserStatusUpdateRequest } from 'src/app/modules/ring2voice/models/call-center.models';
import { CallCenterComponent } from 'src/app/modules/ring2voice/pages/call-center/call-center.component';
import { CallCenterService } from 'src/app/modules/ring2voice/services/call-center.service';
import { SipConnectionService } from 'src/app/modules/ring2voice/services/sip-connection.service';
import { CallcenterAgentComponent } from 'src/app/modules/ring2voice/components/callcenter-agent/callcenter-agent.component';
import { AgentStatusService } from 'src/app/modules/ring2voice/services/agent-status.service';
import { CallcenterSharedService } from 'src/app/modules/ring2voice/services/call-center-shared.service';

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, OnDestroy {

    toggleClass = "ft-maximize";

    isSmallScreen = false;

    protected innerWidth: any;

    searchOpenClass = "";

    hideSidebar: boolean = true;

    public isCollapsed = true;

    private layoutSub: Subscription;

    public UserTypeEnum = UserTypes;

    public userType: UserTypes;

    public userName: string;

    public userEmail: string;

    public suborgs: SubOrg[];

    public suborgSelected: SubOrg;

    public Subs:SubSink=new SubSink();

    public sip_details:SIPdetailsResponse=new SIPdetailsResponse();

    @ViewChild(CallCenterComponent) callCenterComponent: CallCenterComponent;

    @ViewChild(CallcenterAgentComponent) callCenterAgentComponent: CallcenterAgentComponent;
    
    @ViewChild('search') searchElement: ElementRef;

    @ViewChildren('searchResults') searchResults: QueryList<any>;

    @Output() toggleHideSidebar = new EventEmitter<Object>();

    @Output() seachTextEmpty = new EventEmitter<boolean>();

    // public statusValue:any[]=[{value:'Ready'},{value:'Busy'},{value:'Off'},{value:'On Phone Call'}]

    control = new FormControl();

    userAuth

    public total_count:number = 0

    constructor(private layoutService: LayoutService,
        private router: Router,
        private authService: AuthService,
        private eventQueue: EventQueueService,
        private location: Location,
        private cdr:ChangeDetectorRef,
        private ra: ResourceAccessService,
        private callCenterService:CallCenterService,
        private agentStatusService :AgentStatusService, 
        private callcenterSharedService: CallcenterSharedService
        
    ) {

        this.innerWidth = window.innerWidth;
        this.layoutSub = layoutService.toggleSidebar$.subscribe(isShow => this.hideSidebar = !isShow);
    }

    ngOnInit() {

        this.isSmallScreen = this.innerWidth < 1200;
        this.userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
        if (this.userAuth) {
            this.userName = `${this.userAuth.firstname || ''} ${this.userAuth.lastname || ''}`;
            this.userEmail = this.userAuth.email;
        }
        this.userType = localStorage.getItem(AppConstants.USER_TYPE) as UserTypes || UserTypes.User;
        this.initSuborgDropdown();
    }

    initSuborgDropdown() {

        if (this.userType !== this.UserTypeEnum.SuperUser) {

            this.suborgs = JSON.parse(localStorage.getItem(AppConstants.USER_SUBORGS)) || [];

            const current = +localStorage.getItem(AppConstants.CURRENT_SUBORG);

            if (this.suborgs.length) {

                this.suborgSelected = this.suborgs.find(item => item.id == current);
                this.total_count = this.suborgs.length
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.innerWidth = event.target.innerWidth;
        this.isSmallScreen = this.innerWidth < 1200;
    }

    removeActiveClass() {
        if (this.searchResults && this.searchResults.length > 0) {
            this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
        }
    }

    redirectTo(value: any) {
        this.router.navigate([value]);
        this.seachTextEmpty.emit(true);
    }

    ToggleClass() {
        this.toggleClass = this.toggleClass === "ft-maximize" ? "ft-minimize" : "ft-maximize";
    }

    toggleSearchOpenClass(display:any) {
        this.control.setValue("");
        if (display) {
            this.searchOpenClass = 'open';
            setTimeout(() => this.searchElement.nativeElement.focus(), 0);
        }
        else {
            this.searchOpenClass = '';
        }
        this.seachTextEmpty.emit(true);
    }

    toggleNotificationSidebar() {
        this.layoutService.toggleNotificationSidebar(true);
    }

    toggleSidebar() {
        this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
    }

    onOpenDD(){
        this.total_count = this.suborgs.length
        this.cdr.markForCheck();
    }

   async logout():Promise<void> {
       await this.changeAgentStatus().then((res)=>{
        if (res){
            this.authService.logout();
            this.ra.reset();
        }
       })
    }

    async changeAgentStatus():Promise<boolean>{

        if(this.callCenterAgentComponent&&(this.callcenterSharedService.sip_details.status!='Offline')){

            let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
            payload.status='Offline';
            payload.associated_user_id=this.callCenterService.userId;
            payload.associated_username=this.sip_details.associated_username;
            payload.sip_id=this.sip_details.sip_id;
            payload.id=this.sip_details.status_id;

            this.Subs.sink=this.callCenterService.update(payload).subscribe(responds=>{
            if (responds) {
                this.sip_details.status = 'Offline';
                this.callcenterSharedService.sip_details.status='Offline';
                this.agentStatusService.checkStatus('Offline');
                this.cdr.markForCheck();
            }
            })
            return true;
        }else{
            return true;
        }
    }

    setSuborg(suborg: any) {
        // if (suborg.id === this.suborgSelected?.id) return;
        // this.suborgSelected = suborg;
        localStorage.setItem(AppConstants.CURRENT_SUBORG, suborg.id.toString());

        this.eventQueue.dispatch(new AppEvent(AppEventType.SuborgSwitch, suborg));

        let currentUrl = this.router.url;

        console.log(this.router.url.split('?')[0] );

        let path=this.router.url.split('?')[0];

        if(path&&path=='/inventory/labels/print'){
            this.location.back();
        }
        else{
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([currentUrl]);
            });
        }


    }
    onSearch(event:any){
        this.total_count = event.items.length
        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        this.layoutSub?.unsubscribe();
    }
}
