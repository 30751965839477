import { Routes } from '@angular/router';
import { PayNowComponent } from 'src/app/modules/payments/pages/pay-now/pay-now.component';
import { PaymentSuccessComponent } from 'src/app/modules/payments/pages/payment-success/payment-success.component';

export const EXT_LAYOUT_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('../../modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'paynow/:id',
        component: PayNowComponent
    },
    {
        path: 'payment-success/:id',
        component: PaymentSuccessComponent
    }
];
