
<div id="bs-canvas-right" [ngClass]="{ 'show-canvas': _showPanel}"
    class="bs-canvas w-100">
    
<div class="bs-canvas-content">
    <div class="card border-0 m-0 sec-info-container">

        <header class="bs-canvas-header p-2 bg-primary  sec-header">
    
            <h6 class="d-inline-block text-white mb-0 float-left">Table Of Content</h6>
  
        </header>

    
        <div class="sec-items-container text-secondary">
            <div class="section-info font-weight-bold" *ngFor="let item of sectionInfo; let secindx = index">
                <ul>
                    <li [attr.data-toggle]="'collapse' + secindx" [ngClass]="{'select':secindx == collapseIndx }"
                        [attr.data-bs-target]="'#collapse'+secindx" aria-expanded="true" [attr.aria-controls]="'collapse'+secindx"
                        (click)="onClickPages(item.secPageNumber - 1,secindx, item.secPageNumber)">
                        <span class="text-sm-left" style="text-transform: capitalize">{{item.sectionName}}</span> <span
                            class="float-right mr-1"><span>Pg.</span>{{item.secPageNumber}}</span>
                    </li>
                </ul>
    
                <div [id]="'collapse'+secindx" class="collapse" [ngClass]="{ 'show': showMode == 1 }"
                    [attr.aria-labelledby]="'heading'+secindx" data-parent="#accordionExample" style="background:none;">
                    <div class="card-body">
                        <div class="row page-img-list pt-1" *ngFor="let svgstr of item.svgString ; let pageindex = index">
    
                                <div *ngIf="pageindex == 0 && svgstr != ''"  class="img-thumbnail" [innerHtml]="svgstr"
                                [ngClass]="{'img-select': pageindex == selectedPageIndexInSection && secindx == selectedSection }"
                                (click)="onClickPages(pageindex,secindx, item.secPageNumber)"
                                ></div>

                                <div class="img-thumbnail"  *ngIf="pageindex == 0 && svgstr == ''"
                                      [ngClass]="{'img-select': pageindex == selectedPageIndexInSection && secindx == selectedSection }"
                                (click)="onClickPages(pageindex,secindx, item.secPageNumber)">
                                <img  *ngIf = "!item.image_link"  class=" img-fluid" src="../../../../../assets/img/svg/product-avatar.svg" width="100" height="100">
                                </div>

                              
    
                        </div>
                    </div>
                </div>
    
    
            </div>
        </div>
    </div>
</div>

