import { id } from "src/app/core/utils/global.functions";

export class GridMeta {

    public id: number;

    public name: string;

    public description: string;

    public colnames: string[];

    public is_active: boolean = true;
}

export class GridMetaInfo {

    public id: number;

    public name: string;

    public description: string;

    public columns: GridMetaColumn[];
}

export class GridMetaColumn {

    _guid: string;

    public id: number;

    public name: string;

    public description: string;

    public is_active: boolean = true;

    public resource: number;

    public selected: boolean;

    constructor() {
        this._guid = id();
    }
}

export class GridView {

    public name: string;

    public description: string;

    public grid: number;

    public cols: number[];

    public customer: number;

    columnids: number[];
}

export class GridViewInfo {

    public id: number;

    public name: string;

    public description: string;

    public resource: GridMeta;

    public columns: GridMetaColumn[];
}
