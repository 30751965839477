

<section class="invoice-template">
    <div>
        <div class="d-flex flex-row mt-2 justify-content-between">
            <h5 class="item-title m-0 text-left">{{item?.unique_number}} <span *ngIf="item?.from_unique_number"> - {{item?.from_unique_number}}</span></h5>
            <div class="d-flex justify-content-end align-items-start">
                <a href="/make-payment/{{item.id}}"><button class="btn btn-sm btn-info mr-2" >
                    <i class="fa ft-dollar-sign"></i>New Payment
                </button></a>

                <button class="btn btn-sm btn-primary mr-2" (click)="download(true)">
                    <i class="fa ft-download"></i>Download
                </button>
                <button class="btn btn-sm btn-primary mr-2" (click)="download(false)">
                    <i class="fa ft-printer"></i>Print
                </button>
                <a *ngIf="UIPermissions.edit && showEditButton" [routerLink]="['/sales/invoice', item.id, 'edit']" ngbTooltip="Edit"
                   class="btn btn-sm bg-light-secondary">
                    <i class="ft-edit"></i> Edit
                </a>
                <button *ngIf="UIPermissions.delete && showDeleteButton" class="btn btn-sm bg-light-danger ml-1" (click)="deleteIN(item)">
                    <i class="ft-trash-2 mr-1"></i> Delete
                </button>
                <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                    <i class="ft-x"></i>
                </button>
            </div>
        </div>
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Overview</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div class="group mt-2" >
                        <div class="container progress-steps">
                            <div class="arrow-steps clearfix" style="justify-content: center;">
                                <div class="step" [ngClass]="{'active':item.status!=0}">
                                    <strong *ngIf="item.status>1">Created</strong>
                                    <strong *ngIf="item.status==1">Draft</strong>
                                    <small>{{item.created_on|date:'MM/dd/YYYY h:mm a'}}</small>
                                </div>
                                <div class="step" [ngClass]="{'active':item.status==2,'inactive':item.status!==2 }">
                                    <strong>Sent</strong>
                                    <small *ngIf="item.status==2">{{item.mail_sent_date|date:'MM/dd/YYYY h:mm a'}}</small>
                                    <small *ngIf="item.status==8">Not sent to customer yet</small>
                                </div>
                                <div class="step" style="background-color:#95A3BD; border-left-color:#95A3BD">
                                    <strong>Payment pending</strong>
                                    <small *ngIf="item.due_date!=null&&item.status>1">Due in {{dateDiff(item.due_date)}} days</small>
                                </div>
                                <div class="step" style="background-color:#95A3BD; border-left-color:#95A3BD">
                                    <strong>Fully paid</strong>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card pdf-template-card">
                                    <div class="status-history-container">
                                        <div class="ribbon3" [class]="getStatusClass(item.status)" >
                                            <span>{{invStatus[item.status] | replace : '_' : ' '}}  </span>
                                        </div>
                                        <div class="history-container" *ngIf="item.status==2">
                                            <div class="view-status vs-viewed" *ngIf="item.client_viewed">
                                                <div>
                                                    <i class="ft-eye"></i>&nbsp; <span>Last Viewed :</span>
                                                    <span style="font-weight: 500;"> {{item.client_viewed_date | date:'MM/dd/YYYY, h:mm a'}}</span>
                                                </div>
                                                <div style="margin-left: 4em;">
                                                    No of times viewed : <span style="font-weight: 500;"> {{email_view_count}} </span>
                                                </div>
                                                <button (click)="viewHistory = !viewHistory">
                                                    <i class="ft-chevron-down" *ngIf="!viewHistory"></i>
                                                    <i class="ft-chevron-up" *ngIf="viewHistory"></i>
                                                </button>
                                            </div>
                                            <div class="view-status vs-notviewed" *ngIf="!item.client_viewed">
                                                <i class="ft-eye-off"></i>&nbsp; <span >Not viewed</span>
                                            </div>
                                        </div>
                                        <div class="button-div btn-group me-3" *ngIf="showSendToButton" disableIfAllsuborg>
                                            <button type="button" (click)="checkSesVerified(invoice_email_modal)" class="btn btn-sm bg-light-info" disableIfAllsuborg>Send to Customer</button>
                                            <div class="btn-group d-inline-block float_right" ngbDropdown role="group" aria-label="Button group with nested dropdown" placement="bottom-right" disableIfAllsuborg>
                                                <button class="btn bg-light-info btn-sm dropdown-toggle-split" ngbDropdownToggle disableIfAllsuborg></button>
                                                <div class="dropdown-menu" ngbDropdownMenu disableIfAllsuborg>
                                                    <button ngbDropdownItem (click)="comingInFuture()" style="width: 100%;"
                                                    *ngFor="let val of dropdownStatus; index as i" disableIfAllsuborg>
                                                        {{val.value}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="viewed-history" *ngIf="item.client_viewed && viewHistory">
                                        <div *ngFor="let history of email_history" style="margin: 0.5em; position: relative;margin-top: 0;">
                                            <span class="view-count badge badge-warning">{{history.dcount}}</span>
                                            <div class="view-date-cont">
                                                <div class="view-date">
                                                    <div>{{history.created_on__date | date:'MMM' | uppercase}} </div>
                                                    <div class="view-date-day">{{history.created_on__date | date:'dd'}}</div>
                                                </div>
                                            </div>
                                            <!-- <div class="view-date-time">3.30 am</div> -->
                                        </div>
                                    </div>
                                    <div class="card-content p-3 mt-2"  [class.no-event] = "isReadOnly"
                                        style="padding-top: 0 !important;margin-top: 0 !important;">
                                        <div id="invoice-template" class="card-body p-0" id="pdfTable" #pdfTable>
                                        <!-- Invoice Company Details starts -->
                                            <div id="invoice-company-details" class="row">
                                                <div class="col-md-12 text-center">
                                                    <h4 class="text-center text-bold-700" style="margin:auto;text-transform: uppercase; font-size: 16px; margin-bottom: 35px;">{{item.title}}</h4>

                                                <!-- <p class="text-muted mb-1">Org/Suborg</p> -->
                                                </div>

                                                <div class="col-md-8">
                                                    <div class="row">
                                                        <div class="col-12 sub-org">
                                                            <div class="media">
                                                                <img *ngIf="item.suborg_primary_image" width="240" height="108" [src]="fileUrl + item.suborg_primary_image" />
                                                                <img *ngIf="!item.suborg_primary_image" src="assets/img/svg/product-avatar1.svg" alt="company logo" width="80" height="80">
                                                                <div class="media-body ml-4">
                                                                    <ul class="m-0 list-unstyled">
                                                                        <li class="text-bold-800">{{item.suborg_name}}</li>
                                                                        <li>{{item.suborg_address}}</li>
                                                                        <li>{{item.suborg_city}} {{item.suborg_zip}} {{(item.suborg_zip!=null||item.suborg_city!=null)?',':''}} {{item.suborg_state}}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 text-right">
                                                    <h2 class="primary text-uppercase">INVOICE</h2>
                                                    <p class="mb-0">{{item.unique_number}}</p>
                                                    <div>
                                                        <div class="d-flex justify-content-between">
                                                            <span>Invoice Total</span>
                                                            <span class="font-medium-2 text-bold-700"> {{item.total_price|currency}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <span>Payments Made</span>
                                                            <span class="font-medium-2 text-bold-700"> {{item.payments_made|currency}}</span>
                                                        </div>
                                                        <div *ngIf="item['credit_amount'] && item['credit_amount']>0" class="d-flex justify-content-between">
                                                            <span>Credit Amount</span>
                                                            <span class="font-medium-2 text-bold-700"> {{item['credit_amount']|currency}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <span>Balance Due</span>
                                                            <span class="font-medium-2 text-bold-700"> {{item.balance_due|currency}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <span>Material Total</span>
                                                            <span class="font-medium-2 text-bold-700"> {{item.material_total|currency}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <span>Labor Total</span>
                                                            <span class="font-medium-2 text-bold-700"> {{item.timesheet_total|currency}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Invoice Company Details ends -->
                                            <!-- Invoice Customer Details starts -->
                                            <div id="invoice-customer-details" class="row my-2 p-1" style="background-color: #edf2ff;">
                                                <div class="col-md-8 p-0">
                                                    <div class="row">
                                                        <div class="col-md-6 p-0">
                                                            <div class="col-md-12 text-left">
                                                                <p class="text-bold-600 mb-1">Bill To</p>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <ul class="m-0 list-unstyled">
                                                                    <li class="text-bold-800">{{item.tenant_customer_name}}</li>
                                                                    <li>{{item.tenant_customer_contact_name}}</li>
                                                                    <li>{{item.tenant_customer_location_name}}</li>
                                                                    <li>{{item.tenant_customer_location_address}}</li>
                                                                    <li *ngIf="item.tenant_customer_location_city||item.tennant_customer_location_state||item.tenant_customer_location_zip">{{item.tenant_customer_location_city}} {{item.tennant_customer_location_state}}, {{item.tenant_customer_location_zip}}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-12 pl-0" >
                                                            <div class="col-12 text-left">
                                                                <p class="text-bold-600 mb-1">Ship To:</p>
                                                            </div>
                                                            <div class="col-md-12 col-12">
                                                                <ul class="m-0 list-unstyled">
                                                                <li class="text-bold-800">{{item.tenant_customer_name}}</li>
                                                                <li class="text-bold-800">{{item.tenant_customer_contact_name}}</li>
                                                                <li>{{item.shipping_location_name}}</li>
                                                                <li>{{item.shipping_location_address}}</li>
                                                                <li *ngIf="item.shipping_location_city||item.shipping_location_state||item.shipping_location__zip">{{item.shipping_location_city}} {{item.shipping_location_state}}, {{item.shipping_location__zip}}</li>

                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 text-right">
                                                    <div>
                                                        <div class="d-flex justify-content-between" *ngIf="item.created_on"><span>Invoice Date:</span> <span > {{salesService.trimTime(item.created_on)|date:'mediumDate'}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between" *ngIf="item.payment_terms"><span>Payment Terms:</span> <span > {{item.payments_terms_name}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between" *ngIf="item.due_date"><span>Due date:</span> <span > {{item.due_date|date:'mediumDate'}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between" *ngIf="item.shipment_method"><span>Shipping Method:</span> <span > {{item.shipment_method_name}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between" *ngIf="item.reference_id"><span>Reference ID:</span> <span > {{item.reference_id}}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-between" *ngIf="item.tax"><span>Tax:</span> <span > {{item.tax}}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Invoice Customer Details ends -->
                                            <!-- Invoice Items Details starts -->
                                            <div id="invoice-items-details" class="row">
                                                <invoice-material-grid class="col-md-12 p-0"
                                                [inv_id]="item.id"
                                                [suborg_id]="item.suborg"
                                                [tax]="item.tax"
                                                (onDataLoaded)="setMaterials($event)"
                                                (onUpdate)="onUpdateMaterials()">
                                                </invoice-material-grid>
                                            </div>
                                            <div id="st-timesheet-info" class="row">
                                                <invoice-timesheet-grid #tscomponent class="col-md-12 p-0"
                                                [InvoiceId]="item.id"
                                                [suborg_id]="item.suborg"
                                                [tenantCustomerId]="item.tenant_customer"
                                                [tenantCustomerLocationId]="item.tenant_customer_location"
                                                [tenantCustomerContactId]="item.tenant_customer_contact"
                                                (onDataLoaded)="setTimesheets($event)"
                                                (onUpdate)="onUpdateMaterials()"
                                                ></invoice-timesheet-grid>
                                            </div>
                                            <div id="payment_section">
                                                <div class="row mt-3 mt-md-0">
                                                    <div class="col-md-6 col-12 text-left">
                                                    <p class="text-bold-700 mb-1 ml-1">Payment Methods:</p>
                                                    <div class="row">
                                                        <div class="col-12">
                                                        <!-- <table class="table table-borderless table-sm">
                                                            <tbody>
                                                            <tr>
                                                                <td>Bank name:</td>
                                                                <td class="text-right">ABC Bank, USA</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Acc name:</td>
                                                                <td class="text-right">Amanda Orton</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IBAN:</td>
                                                                <td class="text-right">FGS165461646546AA</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SWIFT code:</td>
                                                                <td class="text-right">BTNPP34</td>
                                                            </tr>
                                                            </tbody>
                                                        </table> -->
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-6 col-12 p-0">
                                                        <p class="text-bold-700 mb-2 ml-4">Total due</p>
                                                        <div class="table-responsive">
                                                            <table class="table invoice-items-table">
                                                            <tbody>
                                                                <tr>
                                                                <td>Sub Total</td>
                                                                <td class="text-right">{{item.subtotal|currency}}</td>
                                                                </tr>
                                                                <tr>
                                                                <td>Tax Amount</td>
                                                                <td class="text-right">{{item.tax_amount|currency}}</td>
                                                                </tr>
                                                                <tr>
                                                                <td class="text-bold-800">Total</td>
                                                                <td class="text-bold-800 text-right"> {{item.total_price |currency}}</td>
                                                                </tr>
                                                                <tr>
                                                                <td>Payment Made</td>
                                                                <td class="text-right">{{item.payments_made |currency}}</td>
                                                                </tr>
                                                                <tr class="text-bold-500">
                                                                <td>Balance Due</td>
                                                                <td class="text-right">{{item.balance_due|currency}}</td>
                                                                </tr>
                                                            </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Invoice Items Details ends -->

                                            <!-- Invoice Footer starts -->
                                            <div id="invoice-footer">
                                                <div class="row mt-2 mt-sm-0">
                                                <div class="col-md-6 col-12 d-flex align-items-center">
                                                    <div class="terms-conditions mb-2">
                                                        <div>
                                                            <div class="text-bold-700" *ngIf="item.conditions">Terms & Condition :</div>
                                                            <span>{{item.conditions}}
                                                                <!-- <a class="text-primary" (click)="openTerms()">More</a> -->
                                                            </span>
                                                        </div>
                                                        <br/>
                                                        <p><span>Created by :</span> {{item.created_by}}</p>
                                                        <p><span>Created on :</span> {{salesService.trimTime(item.created_on)|date:'mediumDate'}}</p>

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-12">
                                                    <!-- <div class="signature text-center">
                                                    <p>Authorized person</p>
                                                    <h6 class="mt-4">Amanda Orton</h6>
                                                    <p class="text-muted">Managing Director</p>
                                                    </div> -->
                                                </div>
                                                </div>
                                                <div class="row">
                                                <div class="col-12 text-center text-sm-right">
                                                    <!-- <button type="button" class="btn btn-primary btn-print mt-2 mt-md-1" (click)="downloadAsPDF()"><i class="ft-printer mr-1"></i>Print Warranty</button> -->
                                                </div>
                                                </div>
                                            </div>
                                        <!-- Invoice Footer ends -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Comments</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div style="max-width: 500px;">
                        <app-comments [uniqueId]="item.id" [moduleId]="14" [suborgId]="item.suborg"></app-comments>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">History</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div style="max-width: 500px;">
                        <history-view [module]="'INVOICE'" [uniqueId]="item.id"></history-view>
                    </div>
                </ng-template>
            </li>

            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Payments</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div>
                        <invoices-payment-history [invoiceId]="item.id"></invoices-payment-history>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" ></div>
    </div>
  </section>

  <ng-template #invoice_email_modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Emai To : {{item.tenant_customer_name}}</h5>
        <div class="float-right">
            <button type="submit" class="btn btn-success mr-2" (click)="sendMail()">Send</button>
            <button type="button" class="btn btn-light" (click)="d('Cross click')">Cancel</button>
        </div>
        <!-- <button type="button" class="close " aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="modal-body invoice-email-modal">
        <form>
            <div class="form-group row">
                <label class="col-md-2 col-form-label" for="From">From</label>
                <div class="col-md-10 ">
                    <input type="email" name="from" [(ngModel)]="email.from" class="form-control" id="From" placeholder="Enter email">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label" for="to">Send To</label>
                <div class="col-md-10">
                    <input type="email" name="to" [(ngModel)]="email.to" class="form-control" id="to" placeholder="Enter email">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label" for="cc">Cc</label>
                <div class="col-md-10">
                    <input type="email" name="cc" [(ngModel)]="email.cc" class="form-control" id="cc" placeholder="Enter email">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label" for="subject">Subject</label>
                <div class="col-md-10">
                    <input type="text" name="subject" [(ngModel)]="email.subject" class="form-control" id="subject" placeholder="Enter subject">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-2"></div>
                <!-- <div class="quil-container col-md-10">
                    <quill-editor #editor
                    name="body" [(ngModel)]="email.body"
                    [modules]="defaultModules" format="html"
                    [styles]="{'min-height': '250px'}"></quill-editor>
                </div> -->
                <div class="col-md-10">
                    <div [ngxSummernoteView]="email.body" [ngxSummernote]="config" name="body" [(ngModel)]="email.body"></div>
                </div>
            </div>
            <!-- <div class="form-group row">
                <div class="col-md-2"></div>
                <div class="col-md-10">
                    <label class="form-check-label mr-4" for="exampleCheck1">Attach Invoice</label>
                    <input type="checkbox" name="invoice_checkbox" [(ngModel)]="email.attach_invoice" class="form-check-input" id="exampleCheck1">
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-10">
                    <div class=" float-right">
                        <button type="submit" class="btn btn-success mr-2" (click)="sendMail()">Send</button>
                        <button type="button" class="btn btn-light" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
