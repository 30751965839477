import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { AppConstants } from 'src/app/core/constants/app.constants';

@Directive({
    selector: '[disableIfAllsuborg]'
})
export class DisableIfAllsuborgDirective implements OnDestroy, AfterViewInit {

    private isAllSuborg = (parseInt(localStorage.getItem(AppConstants.CURRENT_SUBORG)) === -1)

    private popup: any;

    private timer: NodeJS.Timeout;

    private clone: any;

    private delay = 100

    @Input() hide:boolean = false;

    constructor(private el: ElementRef, private renderer: Renderer2) {

        if (this.isAllSuborg) {
            this.el.nativeElement.classList.add("action-disabled");
        }
    }

    ngAfterViewInit(): void {
        if (this.isAllSuborg) {
            if (this.hide) {
                this.el.nativeElement.remove();
            }
            else {
                this.clone = this.el.nativeElement.cloneNode(true);
                this.el.nativeElement.replaceWith(this.clone)
                this.renderer.listen(this.clone, 'mouseenter', this.onMouseEnter.bind(this));
                this.renderer.listen(this.clone, 'mouseleave', this.onMouseLeave.bind(this));
            }
        }
    }

    onMouseEnter() {
        this.timer = setTimeout(() => {
            let x = this.clone.getBoundingClientRect().left + this.clone.offsetWidth / 2;
            let y = this.clone.getBoundingClientRect().top + this.clone.offsetHeight + 6;
            this.createTooltipPopup(x, y);
        }, this.delay)
    }

    onMouseLeave() {
        if (this.timer) clearTimeout(this.timer);
        this.popup?.remove()
    }

    private createTooltipPopup(x: number, y: number) {
        let popup = document.createElement('div');
        popup.innerHTML = "Switch suborg";
        popup.setAttribute("class", "all-sub-tooltip");
        popup.style.top = y.toString() + "px";
        popup.style.left = x.toString() + "px";
        document.body.appendChild(popup);
        this.popup = popup;
        setTimeout(() => {
            this.popup?.remove();
        }, 5000);
    }

    ngOnDestroy(): void {
        this.popup?.remove()
    }
}
