import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { CustomerType } from '../models/tenant-customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerTypeService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<CustomerType[]>(`${this.baseURL}/customer_types/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0) {
    let url = `${this.baseURL}/filter_customer_type/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<CustomerType[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<CustomerType>(`${this.baseURL}/customer_types/${id}/`);
  }

  create(model: CustomerType) {
    return this.http.post<CustomerType>(`${this.baseURL}/customer_types/`, model);
  }

  update(id: number, model: CustomerType) {
    return this.http.put<CustomerType>(`${this.baseURL}/customer_types/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<CustomerType>(`${this.baseURL}/customer_types/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/customer_types/${id}/`);
  }
}
