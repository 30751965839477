import moment from "moment";
import { GFColumn, GFColumnTypes, GFilterParam, GridTab } from "src/app/core/models/grid-filter.models";


export class CallReportsDataHelper{

    static get FilterColumns():GFColumn[] {

        return [
            { displayName: 'Incoming Route', columnName: 'forward_number_name', columnType: GFColumnTypes.string },
            { displayName: 'Customer Name', columnName: 'tenant_customer_name', columnType: GFColumnTypes.string },
            { displayName: 'Contact Name', columnName: 'customer_contact_name', columnType: GFColumnTypes.string },
            
            { displayName: 'Date', columnName: 'date_created', columnType: GFColumnTypes.date },
            { displayName: 'Date Between', columnName: 'date_created_between', columnType: GFColumnTypes.date_range },
            { displayName: 'From Number', columnName: 'call_from', columnType: GFColumnTypes.phone },
            { displayName: 'To Number', columnName: 'call_to', columnType: GFColumnTypes.phone },
            { displayName: 'To Name', columnName: 'phone_number_name', columnType: GFColumnTypes.string },
            { displayName: 'Duration', columnName: 'duration', columnType: GFColumnTypes.number },
            {
                displayName: 'Status',
                columnName: 'real_status',
                columnType: GFColumnTypes.select,
                optionValueType: GFColumnTypes.ref,
                options: [
                    { displayName: 'Queued', value: 'queued' },
                    { displayName: 'Busy', value: 'busy' },
                    { displayName: 'No Answer', value: 'no-answer' },
                    { displayName: 'Completed', value: 'completed' },
                    { displayName: 'Canceled', value: 'canceled' },
                    { displayName: 'Failed', value: 'failed' },
                    { displayName: 'Blocked', value: 'blocked' }
                ]
            },
        ];
        
    }

    static get SearchFilters(): GFilterParam[] {
        return [
            {
                displayName: 'Combo Search', colname: '', condition: '', value: '',
                children: [
                    { displayName: 'Customer', colname: 'tenant_customer_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
                    { displayName: 'Customer Contact', colname: 'customer_contact_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
                    { displayName: 'From Number', colname: 'call_from', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                    { displayName: 'To Number', colname: 'call_to', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                ]
            },
            { displayName: 'Customer', colname: 'tenant_customer_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
            { displayName: 'Customer Contact', colname: 'customer_contact_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
            { displayName: 'From Number', colname: 'call_from', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
            { displayName: 'To Number', colname: 'call_to', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
        ];
    }

    static get DefaultFilters(): GFilterParam[] {
        return [
            // { displayName: "All Calls", colname: '', condition: 'is', value: null, operator: 'AND' },
            {
                displayName: "Calls Today", colname: 'date_created_between', condition: 'date_between',
                value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                operator: 'AND', default: true
            },
            { displayName: "Queued", colname: 'real_status', condition: 'is', value: 'queued', operator: 'AND' },
            { displayName: "No Answer", colname: 'real_status', condition: 'is', value: 'no-answer', operator: 'AND' },
            { displayName: "Completed", colname: 'real_status', condition: 'is', value: 'completed', operator: 'AND' },
            { displayName: "Canceled", colname: 'real_status', condition: 'is', value: 'canceled', operator: 'AND' },
            {
                displayName: "Calls Yesterday", colname: 'date_created_between', condition: 'date_between',
                value: [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Calls This Week", colname: 'date_created_between', condition: 'date_between',
                value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Calls Last Week", colname: 'date_created_between', condition: 'date_between',
                value: [moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'), moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Calls This Month", colname: 'date_created_between', condition: 'date_between',
                value: [moment().startOf('month').startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Calls Last Month", colname: 'date_created_between', condition: 'date_between',
                value: [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
                operator: 'AND'
            }
        ];
    }

    static get TabFilters(): GFilterParam[]{
        return [

            { displayName: 'All', colname: 'real_status', condition: 'equalto', value: '', operator: 'WHEN', type: GFColumnTypes.string },
            { displayName: 'Inbound', colname: 'direction', condition: 'is', value: 'inbound', operator: 'WHEN', type: GFColumnTypes.string },
            { displayName: 'Inbound', colname: 'real_status', condition: 'is', value: 'completed', operator: 'AND', type: GFColumnTypes.string },
            { displayName: 'Outbound', colname: 'direction', condition: 'is', value: 'outbound-dial', operator: 'WHEN', type: GFColumnTypes.string },
            { displayName: 'Missed', colname: 'direction', condition: 'equalto', value: 'inbound', operator: 'WHEN' },
            { displayName: 'Missed', colname: 'real_status', condition: 'equalto', value: 'no-answer', operator: 'WHEN' },
            { displayName: 'Agents', colname: 'real_status', condition: 'equalto', value: '', operator: 'WHEN', type: GFColumnTypes.string },
    
        ];
    }

    static get TabItems(): GridTab[]{
        return [
            { name: "Inbound", id: "inbound", selected: true, icon:"assets/img/svg/incomingcall.svg", iconHeight:16, iconWidth:16 },
            { name: "Outbound", id: "outbound", selected: false, icon:"/assets/img/svg/outgoingcall.svg", iconHeight:16, iconWidth:16 },
            // { name: "Missed", id: "missed", selected: false, icon:"assets/img/caller assets/missed.png", iconHeight:11, iconWidth:16 },
        ];
    }

    static get BaseFilter(): GFilterParam[]{
        return [{ colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' }];
    }

    static get SortLookup(){
        return {
            date_created: 'date_created',
            duration: 'duration',
            suborg_name: 'suborg_name',
            phone_number_name: 'phone_number_name',
            forward_number_name: 'forward_number_name'
        };
    }
}