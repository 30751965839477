import { BaseService } from '../../../core/services/base.service';
import { Customer, TenantSettings } from '../models/customer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll() {
    return this.http.get<Customer[]>(`${this.baseURL}/customers/`);
  }

  getFiltered(option: any, offset: number = 0, limit: number = this.dataLimit) {
    let url = `${this.baseURL}/customers/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<{ count: number, result: Customer[] }>(url, option);
  }

  getById(id: number) {
    return this.http.get<Customer>(`${this.baseURL}/customers/${id}/`);
  }

  create(customer: Customer) {
    return this.http.post<Customer>(`${this.baseURL}/customers/`, customer);
  }

  update(id: number, customer: Customer) {
    return this.http.put<Customer>(`${this.baseURL}/customers/${id}/`, customer);
  }

  patch(id: number, req: any) {
    return this.http.patch<Customer>(`${this.baseURL}/customers/${id}/`, req);
  }

  getTenantSettings(tenantId: number) {
    return this.http.get<TenantSettings>(`${this.baseURL}/customers/${tenantId}/settings/`);
  }

  updateTenantSettings(tenantId: number, request: any) {
    return this.http.patch<TenantSettings>(`${this.baseURL}/customers/${tenantId}/settings/`, request);
  }
}
