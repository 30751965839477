import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { AllowListResponse, SubOrgResponse, SuperAdminAllowListResponse } from '../models/automated-allow-list.models';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';

@Injectable({
  providedIn: 'root'
})
export class AutomatedAllowListService extends BaseService {

  constructor(private http: HttpClient) { super();}

  getSubOrgList(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit){
    let url = `${this.baseURL}/allow_list_settings_suborg/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<SubOrgResponse[]>>(url, request);
  }

  getAllowSettingsforSuperAdmin(){
    return this.http.get<SuperAdminAllowListResponse>(`${this.baseURL}/allow_list_settings`);
  }

  createAllowSettingsforSuperAdmin(payload: any){
    return this.http.post<{ allowlist_settings: { id: number, min_call_duration: number }}>(`${this.baseURL}/allow_list_settings`, payload);
  }

  getAllowSettings(){
    return this.http.get<AllowListResponse>(`${this.baseURL}/allow_list_settings_tenant`);
  }

  createAllowSettings(payload: any){
    return this.http.post<{ status: boolean, message: string }>(`${this.baseURL}/allow_list_settings_tenant`, payload);
  }

  saveIndividualAllowSettings(payload: any){
    return this.http.post<SubOrgResponse>(`${this.baseURL}/allow_list_settings_suborg`, payload);
  }
}
