import { SalesAccountService } from './../../services/sales-account.service';
import { SalesAccount } from './../../models/sales-account';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataImportMeta, UIPermission } from 'src/app/core/models/common.models';
import { SubSink } from 'subsink';
import { ModuleConstants } from 'src/app/core/enums/common.enum';


@Component({
    selector: 'app-sales-accounts',
    templateUrl: './sales-accounts.component.html',
    styleUrls: ['./sales-accounts.component.scss']
})
export class SalesAccountsComponents implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @ViewChild('salesAccountModal') postModal: any;

    @ViewChild('searchInput') searchInput: ElementRef;

    public SelectionType = SelectionType;

    public rows: SalesAccount[];

    public rowsFlat: SalesAccount[];

    public salesAccId: number;

    private subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public isAllSuborg: boolean;

    public UIPermissions: UIPermission;

    private importModalRef: NgbModalRef;

    public module = ModuleConstants.ITEM;

    public importColumns: DataImportMeta[] = [
        { table_name: 'Items', actual_column_name: 'name', display_name: 'Name', excel_column_name: undefined },

        { table_name: 'Items', actual_column_name: 'description', display_name: 'Description', excel_column_name: undefined },

    ];

    isSuperAdmin: boolean;

    constructor(private modalService: NgbModal,
        private salesAccService: SalesAccountService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        private device: DeviceDetectorService) {

        this.isSuperAdmin = salesAccService.isSuperUser;
        this.isAllSuborg = this.salesAccService.isAllSuborg;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_SALES_ACCOUNTS, ResourceConstants.INV_MODULE);
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
        this.subs.sink = this.salesAccService.getAll().subscribe(response => {
            this.rows = response;
            this.rowsFlat = response;
            this.cdr.markForCheck();
        });
    }

    ngAfterViewInit(): void { this.searchInput?.nativeElement.focus(); }

    search(event: any) {
        const val = event.target.value?.toLowerCase();
        this.rows = this.rowsFlat.filter(function (d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.table.offset = 0;
    }

    onSelect({ selected }) {
        let current = selected[0];
        if (current && this.UIPermissions.edit && !this.isAllSuborg) {
            this.salesAccId = current.id;
            this.modalService.open(this.postModal, { centered: true });
        }
    }

    openModal() {
        this.salesAccId = 0;
        this.modalService.open(this.postModal, { centered: true });
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    onCancel(): void {
        this.closeModal();
    }

    deleteState(id: number) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.salesAccService.delete(id).subscribe(
                    () => {
                        this.rows = this.rowsFlat.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        popup.CompleteDelete('Sales account has been deleted.');
                    },
                    () => this.toastr.error('Unable to delete row')
                );
            }
        });
    }

    removeFromCached(id: number) {
        this.rows = this.rowsFlat.filter(x => x.id !== id);
        this.cdr.markForCheck();
    }

    onDataSubmittedSuccess(result: { response: SalesAccount, message: string }): void {
        this.toastr.success(result.message);
        if (this.salesAccId > 0) {
            this.rows = [...this.rows.filter(x => x.id !== this.salesAccId), ...[result.response]];
        }
        else {
            this.rows = [...this.rows, ...[result.response]];
        }
        this.rowsFlat = this.rows;
        this.cdr.markForCheck();
        this.closeModal();
    }

    onDataError(_error: any): void {
        this.toastr.error('Operation failed');
        this.closeModal();
    }

    openImportModal(content: any) {
        this.importModalRef = this.modalService.open(content, { centered: true, scrollable: true, size: "lg" });
    }

    closeImportModal() {
        this.importModalRef?.dismiss();
    }

    onImportSubmit(request: any) {
        // this.subs.sink = this.itemService.importItems(request).subscribe(
        //     resp => {
        //         this.toastr.success('Import has started you will be emailed when it is finished.');
        //         this.getData()
        //     },
        //     err => {
        //         this.toastr.error('Import failed')
        //     }
        // );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
