<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw"
                    [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}" *ngIf="showGridFilter">
                    <datagrid-filter #dataFilter [searchFilters]="searchFilters" [defaultFilters]="defaults"
                        [columns]="columns" [expanded]="expanded" [newButton]="UIPermissions.create"
                        [newButtonUrl]="['/ring2voice/sip-endpoints/new']" (apply)="applyFilter($event)"
                        [module]="module">

                        <ng-template filterControl [column]="'customer'" let-row="row">
                            <app-customer-select class="ng-select-sm" [(value)]="row.value"
                                (change)="dataFilter.controlValueChanged('customer')">
                            </app-customer-select>
                        </ng-template>
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue"
                                (change)="dataFilter.controlValueChanged('suborg')">
                            </suborg-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <div class="d-flex mb-1">
                            <a class="btn btn-info btn-text-center btn-sm text-nowrap mr-1"
                                (click)="filterOnlineOffline(0)" style ="width:60px">
                                All
                            </a>
                            <a class="btn btn-success btn-text-center btn-sm text-nowrap  mr-1"
                                (click)="filterOnlineOffline(1)" style ="width:60px">
                                Online
                            </a>
                            <a class="btn btn-danger btn-text-center btn-sm text-nowrap"
                                (click)="filterOnlineOffline(2)" style ="width:60px">
                                Offline
                            </a>
                        </div>
                        <ngx-datatable #dataTable [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap"
                            [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                            [rows]="rows" [selectionType]="SelectionType.single" (select)="onSelect($event)"
                            [count]="page.count" [externalPaging]="true" [offset]="page.offset" [externalSorting]="true"
                            [limit]="page.limit" [trackByProp]="'sid'" (page)="setPage($event)" (sort)="onSort($event)"
                            [sorts]="[{prop:'created_on',dir:'desc'}]">

                            <ngx-datatable-column name="Username" prop="username" [width]="250">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div><b>{{row.username}}</b></div>
                                    <div>{{row.id}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Assigned to" prop="associated_username" [width]="250">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="d-flex align-items-center">
                                        <img src="assets/img/caller assets/agent.svg"
                                            class="avatar" height="20" width="20"
                                            [ngClass]="{'agentOnline': row.associated_user&&row.status=='Ready', 'agentOffline':row.associated_user&&row.status=='Offline','agentBusy': row.associated_user&&row.status=='Busy'}">
                                        <div class="ml-1">{{row.associated_username}}</div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Caller ID" prop="caller_id"></ngx-datatable-column>
                            <ngx-datatable-column name="Send As" prop="send_as">
                                <ng-template let-val="value" ngx-datatable-cell-template>
                                    {{val | phoneNumber }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Encryption" prop="encryption"
                                [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created On" prop="created_on">
                                <ng-template let-purchase="value" ngx-datatable-cell-template>
                                    {{purchase | dateTz}}
                                    <!-- {{purchase | date:'MM/dd/YYYY'}} -->
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg"
                                prop="suborg_name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.customer_name}}</div>
                                    <div>Sub org : {{row.suborg_name}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && !isSuperAdmin" name="Suborg" prop="suborg_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && showAction" name="Action" prop="id" [sortable]="false"
                                [canAutoResize]="false" [width]="80">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                            (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="UIPermissions.viewInfo" class="w-100"
                                                (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>
                                            <button *ngIf="UIPermissions.edit"
                                                [routerLink]="['/ring2voice/sip-endpoints',id,'edit']" class="w-100"
                                                ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </button>
                                            <button *ngIf="UIPermissions.delete" (click)="deleteSIP(id)"
                                                class="w-100 bg-light-danger" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- <ngx-datatable-column *ngIf="expanded" name="" prop="id" [sortable]="false"
                            [canAutoResize]="false" [width]="80">
                            <ng-template let-id="value" let-current="row" let-row="row" ngx-datatable-cell-template>
                                <div class="d-flex align-items-center" *ngIf = "row.associated_user&&row.status=='Ready'">
                                    <img width="30" height="30" title="Call Back" (click)="callAgent(row)"
                                    src="assets/img/svg/callback.svg" style="margin-top: -5px; margin-left: 7px;cursor: pointer;" />
                                </div>
                            </ng-template>
                            </ngx-datatable-column> -->
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                        [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="warehouse-details">
                        <div class="d-flex flex-row mt-2">
                            <div class="flex-1">
                                <h5 class="item-title m-0">{{selected.username}}</h5>
                                <p>{{selected.id}}</p>
                            </div>
                            <div class="d-flex justify-content-end align-items-start">
                                <button *ngIf="UIPermissions.edit"
                                    [routerLink]="['/ring2voice/sip-endpoints/',selected.id,'edit']" ngbTooltip="Edit"
                                    class="btn btn-sm bg-light-secondary">
                                    <i class="ft-edit"></i> Edit
                                </button>
                                <button *ngIf="UIPermissions.delete" class="btn btn-sm bg-light-danger ml-1"
                                    (click)="deleteSIP(selected.id)">
                                    <i class="ft-trash-2 mr-1"></i> Delete
                                </button>
                                <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3"
                                    (click)="closeDetailedView()">
                                    <i class="ft-x"></i>
                                </button>
                            </div>
                        </div>
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center" >
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group-body">
                                        <div class="ov-row" *ngIf="!selected.is_sync">
                                            <label class="col-md-3 p-0 item-label">Password</label>
                                            <label class="item-label-value" *ngIf="!selected.password else passwordPlain">
                                                <div class="password-container" *ngIf="!verificationSend">
                                                    <button class="btn btn-sm btn-info" (click)="sendVerificationCode()">
                                                        Show Password
                                                    </button>
                                                </div>
                                                <div class="2fa-container" *ngIf="verificationSend">
                                                    <div class="d-flex flex-row">
                                                        <input type="text" name="otp" [(ngModel)]="code" class="form-control form-control-sm mr-2" placeholder="Enter the verification code"/>
                                                        <button [disabled]="!code || code.length != 6" class="btn btn-sm btn-success" (click)="verifyCode()">
                                                            Verify
                                                        </button>
                                                    </div>
                                                </div>
                                            </label>
                                            <ng-template #passwordPlain>
                                                <b>{{selected.password}}</b>
                                            </ng-template>
                                        </div>
                                        <div class="ov-row" *ngIf="selected.caller_id">
                                            <label class="col-md-3 p-0 item-label">Caller ID</label>
                                            <label class="item-label-value">{{selected.caller_id}}</label>
                                        </div>
                                        <div class="ov-row" *ngIf="selected.send_as">
                                            <label class="col-md-3 p-0 item-label">Send As</label>
                                            <label class="item-label-value">{{selected.send_as | phoneNumber}}</label>
                                        </div>
                                        <div class="ov-row" *ngIf="selected.encryption">
                                            <label class="col-md-3 p-0 item-label">Encryption</label>
                                            <label class="item-label-value">{{selected.encryption}}</label>
                                        </div>
                                        <div class="ov-row" *ngIf="selected.created_on">
                                            <label class="col-md-3 p-0 item-label">Created On</label>
                                            <label class="item-label-value">{{selected.created_on | dateTz}}</label>
                                        </div>
                                        <div class="ov-row" *ngIf="selected.created_by">
                                            <label class="col-md-3 p-0 item-label">Created By</label>
                                            <label class="item-label-value">{{selected.created_by}}</label>
                                        </div>
                                        <div class="ov-row" *ngIf="selected.associated_username">
                                            <label class="col-md-3 p-0 item-label">Assigned to</label>
                                            <label class="item-label-value">
                                            <img class="avatar" src="assets/img/gallery/user-avatar.jpg" alt="avatar" height="30" width="30" />
                                            {{selected.associated_username}}</label>
                                        </div>
                                        <div class="ov-row" *ngIf="isSuperAdmin">
                                            <label class="col-md-3 p-0 item-label">Tenant</label>
                                            <label class="item-label-value">{{selected.customer_name}}</label>
                                        </div>
                                        <div class="ov-row" *ngIf="isSuperAdmin">
                                            <label class="col-md-3 p-0 item-label">Sub Org</label>
                                            <label class="item-label-value">{{selected.suborg_name}}</label>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem *ngIf="selected.associated_user">
                                <a ngbNavLink class="d-flex align-items-center" >
                                    <span class="d-none d-sm-block">History</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <agent-status-log [agentId]="selected.associated_user"></agent-status-log>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
