import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ChatHead, ChatHeadRequest, Message} from '../models/chat.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService  extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getMessages(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
    let url = `${this.baseURL}/messages/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<Message[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getChatHeads(request:ChatHeadRequest) {
    return this.http.post<{result:ChatHead[],count:number}>(`${this.baseURL}/messages/chatheads/`,request);
  }

  markMessages(sent_to:string){
    return this.http.get(`${this.baseURL}/messages/${sent_to}/mark/`);
  }
}
