import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { printPagination } from '../../models/label.models';

@Component({
  selector: 'label-print-pagination',
  templateUrl: './label-print-pagination.component.html',
  styleUrls: ['./label-print-pagination.component.scss']
})
export class LabelPrintPaginationComponent implements OnInit {


  @Input() pagination:printPagination;


  @Output() change=new EventEmitter<printPagination>();


  
  constructor() { }

  ngOnInit(): void {

    this.pagination.currentPage=1;
    this.pagination.previousPage=0;
    this.pagination.nextPage=2;


  }

  gotoPageNo(pageNo:number){

    console.log(pageNo);

    if(pageNo>0&&pageNo<=this.pagination.totalPages){
        console.log('You are here');
        this.pagination.currentPage=pageNo;
        this.pagination.previousPage=pageNo-1;
        this.pagination.nextPage=pageNo+1;
        this.changeInPagination();
    }
    else{
      console.log('You are on else condition');
    }

  }

  changeInPagination(){
    this.change.emit(this.pagination);
  }

}
