<section class="add-tenant-customer" *ngIf="model">
    <!-- {{model|json}} -->
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">{{customerId > 0 ? 'Edit Customer' : 'New Customer'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()" novalidate>
                    <h6 class="text-info">BASIC DETAILS</h6>

                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label class="val-required">Name</label>
                                <input type="text" [(ngModel)]="model.name" #custname="ngModel" name="name" class="form-control" required>
                                <small class="form-text text-muted danger" *ngIf="custname.invalid && (custname.dirty || custname.touched)">
                                    <div *ngIf="custname.errors.required">
                                        Name is required.
                                    </div>
                                </small>
                                <small class="danger mt-1" *ngIf="nameExist">Name must be unique</small>
                            </div>
                            <div class="form-group">
                                <label>Account Number</label>
                                <input type="text" [(ngModel)]="model.account_number" name="account_number" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Customer Type</label>
                                <customer-type-select [(value)]="model.customer_type"></customer-type-select>
                            </div>
                            <div class="form-group">
                                <label>Customer System Type</label>
                                <customer-system-type-select [fetchWhenOpen]="true" [(value)]="model.customer_system_type"></customer-system-type-select>
                            </div>

                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [(ngModel)]="model.is_service_ticket_billable" name="stbillable" id="custom-check-1">
                                    <label class="custom-control-label" for="custom-check-1">
                                        <span>Service Ticket Billable</span>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group taxable-toggle-div">
                                <div class="taxable-toggle-btn">
                                    <label>VIP Customer: </label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [(ngModel)]="model.is_vip" name="is_vip" class="custom-control-input" id="is_vip">
                                        <label class="custom-control-label" for="is_vip">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>VIP Note</label>
                                <textarea [(ngModel)]="model.vip_note" [disabled]="!model.is_vip" placeholder="Note for VIP user" name="vip_note" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 d-flex flex-column">

                            <div class="customer-logo w-100 mb-3">
                                <app-file-uploader [isActive]="false" [limit]="5" [multiple]="false"></app-file-uploader>
                            </div>
                            <div class="form-group">
                                <label>License Number</label>
                                <license-number-select [multiple]="true" [(value)]="licenseNumbers" [fetchWhenOpen]="false" [clearable]="false" (remove)="onLicenseNumberRemove($event)"></license-number-select>
                            </div>
                            <div class="form-group">
                                <label>Industry</label>
                                <industry-select [(value)]="model.industry"></industry-select>
                            </div>
                            <div class="form-group">
                                <label>Website</label>
                                <input type="text" [(ngModel)]="model.website" name="website" class="form-control">
                            </div>

                        </div>
                    </div>


                    <div class="row-seperator"></div>
                    <h6 class="text-info">CREDIT DETAILS</h6>
                    <br/>
                    <div class="row">
                        <div class="col-12 col-md-12 credit-div">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Credit Limit</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input type="number" [(ngModel)]="model.credit_limit" #credit_limit="ngModel" name="credit_limit" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Credit In Use</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input type="number" [(ngModel)]="model.credit_balance" name="credit_balance" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group taxable-toggle-div">
                                    <div class="taxable-toggle-btn">
                                        <label>{{model.credit_enforced ? 'Enforced' : 'Not Enforced'}} : </label>
                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                            <input type="checkbox" [(ngModel)]="model.credit_enforced" name="credit_enforced" class="custom-control-input" id="credit_enforced">
                                            <label class="custom-control-label" for="credit_enforced">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-seperator"></div>
                    <h6 class="text-info">PAYMENTS DETAILS</h6>
                    <br/>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>Currency</label>
                                <currency-select [(value)]="model.currency"></currency-select>
                            </div>
                            <div class="form-group">
                                <label>Payment Terms</label>
                                <payment-term-select [(value)]="model.payment_terms"></payment-term-select>
                            </div>
                            <div class="form-group">
                                <label>Comment</label>
                                <textarea class="form-control" [(ngModel)]="model.comments" name="comment"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="form-group taxable-toggle-div">
                                <div class="taxable-toggle-btn">
                                    <label>Taxable : </label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [(ngModel)]="model.taxable" name="taxable" class="custom-control-input" id="taxable">
                                        <label class="custom-control-label" for="taxable">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="model.taxable">
                                <label class="val-required">Tax</label>
                                <tax-select [(value)]="model.tax"></tax-select>
                            </div>
                            <div class="form-group">
                                <label>Account Rep</label>
                                <user-select [(value)]="model.account_rep" [multiple]="true"></user-select>
                            </div>
                        </div>
                    </div>

                    <div class="row-seperator"></div>
                    <div class="d-flex justify-content-between">
                    <h6 class="text-info">SHIPMENT METHODS</h6>
                    <button type="button" (click)="openSMModal(smModal)" class="btn btn-sm bg-light-primary ml-4"><i class="ft ft-plus"></i> Add</button>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <ul class="list-group">
                                <div *ngFor="let sm of model.shipment_methods; index as index">
                                <li class="list-group-item d-flex justify-content-between" *ngIf="!sm.is_delete"  >
                                    <div >
                                        <strong class="">{{sm.shipment_method_name}} {{sm.is_delete}}</strong> &nbsp;<i class="fi ft-flag text-success" placement="bottom" ngbTooltip="Default shipment method" *ngIf="sm.is_default"></i>
                                        <span class="text-muted">&nbsp;{{sm.account_number}} </span>
                                    </div>
                                    <a class="btn-icon text-info " (click)="deleteShipmentMethod(index)"><i class="ft ft-trash"></i></a>
                                </li>
                                </div>
                              </ul>
                            <p *ngIf="model.shipment_methods&&model.shipment_methods.length==0" class="text-muted ml-4">No shipment method added</p>
                        </div>
                    </div>
                    <div class="row-seperator"></div>
                    <div class="d-flex justify-content-between">
                    <h6 class="text-info">
                        ADDITIONAL FIELDS
                    </h6>
                    <button type="button" (click)="openCustomFieldModal(content)" class="btn btn-sm  bg-light-primary ml-4 ">
                        <i class="ft ft-plus"></i>Add</button>
                     </div>
                    <div class="row mt-3">
                        <div class="form-group col-md-5" *ngFor="let customField of additionalFields">
                            <label>{{customField.name}}</label>
                            <div class="d-flex flex-row">
                                <input [(ngModel)]="customField.value" [name]="customField.id" class="form-control" type="text" />
                                <button (click)="deleteCustomField(customField.id)" class="btn af-delete-btn" type="button">
                                    <i class="ft-trash-2"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                {{customerId > 0 ? 'Save Changes' : 'Save'}}
                            </button>
                            <button type="button" (click)="location.back()" class="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Custom Field</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Key</label>
                <input type="text" [(ngModel)]="customFieldInputs.key" name="key" class="form-control">
            </div>
            <div class="form-group">
                <label>Value</label>
                <input type="text" [(ngModel)]="customFieldInputs.value" name="value" class="form-control">
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="customFieldInputs.key && addCustomField()" class="btn gradient-pomegranate">Save</button>
            <button type="button" (click)="modal.close('Save click')" class="btn btn-secondary">Cancel</button>
        </div>
    </ng-template>

    <ng-template #smModal let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add shipping method</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Shipment method</label>
                <ng-select [items]="shipmentMethodList" bindLabel="name" bindValue="id" [(ngModel)]="shipmentMethodId"
                (change)="shipmentMethodName=$event.name" name="shipmentMethodId"></ng-select>
            </div>
            <div class="form-group">
                <label>Shipping Account No.</label>
                <input type="text" class="form-control" [(ngModel)]="shipmentMethodAccNo" name="shipmentMethodAccNo" />
            </div>

            <div class="form-group">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" [(ngModel)]="shipmentMethodIsDefault" name="stbillable" id="custom-check-2">
                    <label class="custom-control-label" for="custom-check-2">
                        <span>Set as default shipping method</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="addShippingMethod()" class="btn gradient-pomegranate">Add</button>
            <button type="button" (click)="modal.close('Save click')" class="btn btn-secondary">Cancel</button>
        </div>
    </ng-template>
</section>
