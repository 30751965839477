<div *ngIf="selectBox" class="item-select-container select-sm" (click)="open()">
    <div class="value-container">
        <div *ngIf="!selected" class="placeholder">Select Item</div>
        <div class="combobox">
            <!-- <label class="w-100 m-0 item-name-label" *ngIf="selected">{{selected.item_name}}</label> -->
        </div>
    </div>
    <span *ngIf="selected" (click)="clearSelection($event)" class="clear-wrapper d-flex" title="Clear all">
        <span aria-hidden="true" class="clear">×</span>
    </span>
    <span class="arrow-wrapper"><span class="arrow"></span></span>
</div>

<ng-template #timesheetModal let-c="close" let-d="dismiss">
    <div class="modal-body p-0">
        <div class="w-100 p-2">

            <datagrid-filter
            #dataFilter
            [searchFilters]="search"
            [defaultFilters]="defaults"
            [columns]="columns"
            [newButton]="true"
            (newButtonClick)="openModal(content)"
            (apply)="applyFilter($event)"
            >
                <ng-template filterControl [column]="'tenant_customer'" let-row="row">
                    <tenant-customer-select [(value)]="row.value"></tenant-customer-select>
                </ng-template>
                <ng-template filterControl [column]="'created by'" let-row="row">
                    <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                </ng-template>
            </datagrid-filter>
        </div>
        <ngx-datatable #dataTable [scrollbarH]="scrollbarH"
        class="bootstrap core-bootstrap grid-selection-custom-style"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [rows]="items"
        [selectionType]="SelectionType.single"
        [count]="page.count"
        [sorts]="[{prop:'assigned_date',dir:'desc'}]"
        [externalPaging]="true"
        [offset]="page.offset"
        [externalSorting]="true"
        [limit]="page.limit"
        [selected]="selectedItem"
        (page)="setPage($event, dataFilter)"
        (sort)="onSort($event, dataFilter)">
        <ngx-datatable-column name="Timesheet Date" prop="assigned_date">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div>{{row.assigned_date | date:'MM/dd/YYYY h:mm a'}}</div>
                <div>{{row.unique_number}}</div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Customer" prop="customer_name">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="customer-details">
                    <div class="cname">{{row.customer_name}}</div>
                    <div *ngIf="row.location_name" class="location">
                        Location : {{row.location_name}}
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="ST #" prop="ticket_number"></ngx-datatable-column>
        <ngx-datatable-column *ngIf="expanded" name="Comments" prop="comments" [sortable]="false"></ngx-datatable-column>
        <ngx-datatable-column *ngIf="expanded" name="Total Time" prop="time" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span *ngIf="value">{{getTime(value)}}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="expanded" name="Billing" prop="is_billable" [sortable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div>{{value?'Billable':'Not Billable'}}</div>
                <div *ngIf="getBillingStatus(row.billing_status) as stat" [class]="stat.class">
                    {{stat.key}}
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="expanded" name="Total Billable" prop="total_amount">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span *ngIf="value">$ {{value}}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <user-card [name]="value"></user-card>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                <div class="page-count" *ngIf="expanded">
                    <span>{{this.page.pageNumber}}/{{rowCount}} | {{selectedItem.length}} timesheet{{selectedItem.length>1?'s':''}} selected </span>
                </div>
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="dataTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="onItemSelected()" class="btn gradient-pomegranate">Select</button>
        <button type="button" (click)="onCanceled()" class="btn btn-secondary">Cancel</button>
    </div>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">New Timesheet</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <time-sheet-form (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()"></time-sheet-form>
    </div>
</ng-template>
