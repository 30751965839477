import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { GridConstants } from 'src/app/core/constants/grid.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { GSortParam } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { GridViewInfo } from 'src/app/modules/grid-profiling/models/grid-profiling.models';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { ItemDataHelper } from '../../helpers/item-data.helpers';
import { Product } from '../../models/catalogue.models';
import { CatalogueService } from '../../services/catalogue-service.service';

@Component({
  selector: 'catalog-associated-products',
  templateUrl: './catalog-products.component.html',
  styleUrls: ['./catalog-products.component.scss']
})
export class CatalogProductsComponent implements OnInit {


  public expanded:boolean=true;

  public scrollbarH:boolean=false;

  public fileUrl: string = environment.apiURL + '/static/files/';

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public SelectionType = SelectionType;


  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public grid: GridViewInfo;

  public isAdmin: boolean;

  public selected:Product;

  public UIPermissions:UIPermission;


  public sortLookup = ItemDataHelper.SortLookup;

  

  public rows: Product[] = [];

  public subs:SubSink=new SubSink();

  public isAllSuborg: boolean;

  @Input() set products(value:Product[]){
      this.rows=value;
      console.log(value);
 
      this.refreshDataTable();
  }



  constructor(private cdr:ChangeDetectorRef, 
             private ra:ResourceAccessService,
             private catalogService:CatalogueService) {
              this.isAdmin = this.catalogService.isAdmin;

           this.isAllSuborg = catalogService.isAllSuborg;
           this.grid = ra.getGridView(GridConstants.INV_ITEMS);
        }

  ngOnInit(): void {
  }


  onSelect({ selected }): void {
    let wrapper: Product = selected[0];
    if (wrapper && this.UIPermissions.viewInfo) {
        this.selected = wrapper;
        this.expanded = false;
        // this.location.go(`/inventory/items/${wrapper.id}/view`);
        this.refreshDataTable();
    }
}

  onSort(event: any) {
      if (event.sorts && event.sorts.length > 0) {
          let current = event.sorts[0];
          if (!this.sortLookup[current.prop])
              return;
          let sort = new GSortParam();
          sort.colname = this.sortLookup[current.prop];
          sort.direction = current.dir;
          // this.sort = [sort];
          // this.getData(this.dataFilter?.getDataFilters() || []);
      }
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    // this.getData(this.dataFilter?.getDataFilters() || [], offset);
}

  isProfiled(columName: string, admin_column = false) {
    return this.ra.isColumnProfiled(this.grid, columName, admin_column);
  }

  refreshDataTable() {
    setTimeout(() => {
        this.rows = [...this.rows];
        this.cdr.markForCheck();
    });
  }

  hasPermission(resource: string, permission: string) {
    return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE);
}

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  

}
