
    <td *ngIf="customField.additional_field||customField.type==1">


        <additional-field-select 
        [value]="customField.additional_field"
        [addButton]="true"
        (valueChange)="additionalFieldSelected($event)"
        ></additional-field-select>

        
    </td>
    <td *ngIf="customField.additional_field||customField.type==1">
        <ng-select 
            placeholder="Select value" 
            [items]="valueOptions" 
            [disabled]="!customField.additional_field" 
            [name]="'value'+index" 
            [(ngModel)]="customField.value" 
            bindLabel="field_value" 
            bindValue="id">
        </ng-select>
    </td>
    <td>
        <button class="btn-borderless danger" type="button" (click)="deleteCustomField(index)">
            <i class="ft-trash-2"></i>
        </button>
    </td>
