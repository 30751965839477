import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { ItemCategoryWrapper, ItemCategory } from '../models/items';

@Injectable({
    providedIn: 'root'
})
export class CategoryService extends BaseService {

    constructor(private http: HttpClient) {
        super();
    }

    getAll(offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/itemcategories/filter/?limit=${limit}&offset=${offset}`;
        return this.http.get<DataFilterResponse<ItemCategoryWrapper[]>>(url);
    }

    getAllFiltered(option: any, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/itemcategories/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<ItemCategoryWrapper[]>>(url, option);
    }

    getById(id: number) {
        return this.http.get<ItemCategory>(`${this.baseURL}/itemcategories/${id}/`);
    }

    create(category: ItemCategory) {
        return this.http.post<ItemCategory>(`${this.baseURL}/itemcategories/`, category);
    }

    update(id: number, item: ItemCategory) {
        return this.http.put<ItemCategory>(`${this.baseURL}/itemcategories/${id}/`, item);
    }

    delete(id: number) {
        return this.http.delete(`${this.baseURL}/itemcategories/${id}/`);
    }

    patch(id: number, request: any) {
        return this.http.patch<ItemCategory>(`${this.baseURL}/itemcategories/${id}/`, request);
    }

    isPartNumberUnique(pn: string) {
        return this.http.post<{ is_unique: boolean }>(`${this.baseURL}/part_number/unique/`, { part_number: pn });
    }

    generatePartNumber(categoryId: number) {
        return this.http.get<{ part_number: string, status: number }>(`${this.baseURL}/part_number/generate/${categoryId}/`);
    }

    getLastGeneratedPartnumber(categoryId: number): Observable<string> {

        let url = `${this.baseURL}/part_number/last/${categoryId}/`;

        return this.http.get<{ part_number: string, status_code: number, message: string }>(url).pipe(
            map(resp => {
                return resp.part_number ? `Last generated Part Number is '${resp.part_number}'` : 'Part Number has not been created yet!'
            }),
            catchError(() => 'Last generated Part Number not found!')
        );
    }

    importCategories(file: any) {
        let formData = new FormData();
        formData.append("file", file.file);
        formData.append("map_object", JSON.stringify(file.columns_map));
        formData.append('customer', this.customerId.toString());
        formData.append('option', file.option.toString());
        return this.http.post(`${this.baseURL}/item_category/import/`, formData);
    }
}
