<div class="customer-type-select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" [placeholder]="placeholder" [items]="options | async | orderBy:'number'" bindLabel="number" bindValue="id" [(ngModel)]="selected" [readonly]="readonly" [multiple]="multiple" [typeahead]="textInput" [minTermLength]="2"
        [trackByFn]="trackByFn" typeToSearchText="Please enter 2 or more characters" [closeOnSelect]="!multiple" [clearable]="clearable" (open)="selectOpen()" (change)="onValueChanged($event)" (add)="onAdd($event)" (clear)="onClear()" (remove)="onRemove($event)">

        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input *ngIf="multiple" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
            <span class="ml-1">{{item.number}}</span>
        </ng-template>

        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
                <a class="add-button text-info" *ngIf="addButton && hasPermission()" (click)="openModal(content)">
                    <i class="icon icon-settings mr-1"></i> Add License Number
                </a>
            </div>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Licence Number</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <license-number-form (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()">
            </license-number-form>
        </div>
    </ng-template>
</div>