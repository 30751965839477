import { ThrowStmt } from '@angular/compiler';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { LabelPage, LabelPageTemplate } from '../../models/label.models';
import { LabelService } from '../../services/label.service';
import * as popup from 'src/app/core/utils/popup.functions';


@Component({
  selector: 'app-label-pages-list',
  templateUrl: './label-pages-list.component.html',
  styleUrls: ['./label-pages-list.component.scss']
})
export class LabelPagesListComponent implements OnInit {


  public subs:SubSink=new SubSink();

  public pageList:LabelPageTemplate[]=[];

  public selectedPage:LabelPage;

  public selectedPageId:number=0;


  constructor(
    private labelService:LabelService, 
    private toastr:ToastrService,
    private activeRoute:ActivatedRoute,
    private cdr:ChangeDetectorRef
  ) { 
      // this.activeRoute.params.subscribe(params=>{
      //   this.selectedPageId=+params.id||0;
      // });
  }

  ngOnInit(): void {

    this.getAllPages();
  }





  getAllPages(){
    this.subs.sink=this.labelService.getAllPageTemplate().subscribe(
      response=>{
        this.pageList=response;
        this.cdr.markForCheck();
      },
    );
  }



  deletePage(id:number, index:number){

    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {

        this.subs.sink=this.labelService.deleteLabel(id).subscribe(
          response=>{
            this.toastr.success('Label page template deleted');
            this.pageList.splice(index, 1);
            this.cdr.markForCheck();
          }
        );

      }


  });
}


  ngOnDestroy(){
    this.subs.unsubscribe();
  }

  

}
