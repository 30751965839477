export class LabelListRquest{

}


export class LabelListResponse{

    public id:number;

    public title:string;

    public type:string;

    public template:CanvasObject;

    public customer:number;

    public created_user:number;

    public created_on:string;

    public modified_on:string;

    public modified_user:number;

    public is_delete:boolean;

    public created_by:string;

    public width:number;

    public length:number;

    public image:string;

    
}

export class LabelCreateRequest{

    public id:number;
    
    public title:string;

    public type:string;

    public template:string;

    public customer:number;

    public width:number;

    public length:number;

    public suborg:number;

    public image:string;
    
}





//----------Label Page/Paper template models ------------//


export class LabelPageCreate{

    public title:string;

    public customer:number;

    // public length:number;

    // public bredth:number;

    public margin_top:number;

    public margin_bottom:number;

    public margin_left:number;

    public margin_right:number;

    public total_labels:number;

    public description:string;

    public label_template:number;

    public label_papersize:number;

    public template:string;

    public width:number;

    public height:number;

    public is_roll_paper:boolean;
}


export class LabelPage{

    public id:number;

    public title:string;

    public type:string;

    public template:string;

    public customer:number;

    public length:number;

    public bredth:number;
}



//----------Label Page/Paper models ------------//



// ---------Label page template model -----------//

   export class LabelPageTemplate{

    public id:number;

    public title:string;

    public description:string;

    public created_on:string;

    public modified_on:string;

    public is_delete:boolean;

    public label_template:number;

    public label_papersize:number;

    public customer:number;

    public margin_top:number;

    public margin_bottom:number;

    public margin_left:number;

    public margin_right:number;

    public total_labels:number;

    public label_papersize_title:string;

    public label_template_title:string;

    public template:CanvasObject;

    public width:number;

    public height:number;

    public lable_template_width:number;

    public label_template_length:number;

    public label_template_template:CanvasObject;

    public is_roll_paper:boolean;


   }



// ---------Label page template model -----------//


export class CanvasObject{
    
    public objects:CanvasObjects[];

    public version:string;
}


export class CanvasObjects{


    public left:number;

    public top:number;

    public fill:string;

    public text:string=null;

    public type:string;

    public angle:number;

    public width:number;

    public height:number;

    public opacity:number;

    public fontSize:number;

    public src:string="";

    public scaleX:number;

    public scaleY:number;

    public filters:[];

    public crossOrigin:string;

    public path:string;

    public cropX:number;

    public cropY:number;

    public flipX:boolean;

    public flipY:boolean;

    public skewX:number;

    public skewY:number;

    public shadow:string;

    public stroke:number;

    public styles:{}={};

    public originX:string;

    public originY:string;

    public version:string;
    
    public visible:boolean;

    public fillRule:string;

    public overline:boolean;

    public pathSide:string;

    public direction:string;

    public fontStyle:string;

    public pathAlign:string;

    public textAlign:string;

    public underline:boolean;

    public fontFamily:string;

    public fontWeight:string;

    public lineHeight:number;

    public paintFirst:string;

    public charSpacing:number;

    public linethrough:boolean;

    public strokeWidth:number;

    public strokeLineCap:string;

    public strokeUniform:boolean;

    public strokeLineJoin:string;

    public backgroundColor:string;

    public pathStartOffset:number;

    public strokeDashArray:[];

    public strokeDashOffset:number;

    public strokeMiterLimit:number;

    public textBackgroundColor:string;

    public globalCompositeOperation:string;

    public name:string;
    



}

export class qrData{

    public partNo:string;
    public uNo:string;
    public slNo:string;
    public t:string;

}


export class printPagination{

    public isEnabled:boolean=false;

    public totalItems:number;

    public totalPages:number;

    public currentPage:number;

    public previousPage:number;

    public nextPage:number;

    public labelsPerPage:number;

}

