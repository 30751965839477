import { DataFilter, GFilterParam } from '../models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModuleConstants } from '../enums/common.enum';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DataFilterService extends BaseService {

    constructor(private http: HttpClient) {
        super();
    }

    delete(id: number) {
        return this.http.delete<DataFilter>(`${this.baseURL}/data/filters/${id}/`);
    }

    get(module: ModuleConstants) {
        return this.http.get<DataFilter[]>(`${this.baseURL}/data/filters/${module}/list/`).pipe(map(resp => {
            return this.dataFilters2GFParams(resp);
        }));
    }

    create(req: DataFilter) {
        return this.http.post<DataFilter>(`${this.baseURL}/data/filters/`, req).pipe(map(resp => {
            return this.dataFilters2GFParams([resp])[0];
        }));
    }

    update(id: number, req: DataFilter) {
        return this.http.put<DataFilter>(`${this.baseURL}/data/filters/${id}/`, req).pipe(map(resp => {
            return this.dataFilters2GFParams([resp])[0];
        }));;
    }

    dataFilters2GFParams(dataFilters: DataFilter[]) {
        return dataFilters.map(filter => {
            let gf = new GFilterParam();
            gf.displayName = filter.name;
            gf.children = filter.condition;
            gf.custom = true;
            gf.customDataId = filter.id;
            gf.default = filter.default;
            return gf;
        });
    }
}
