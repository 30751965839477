import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class WebSockectService extends BaseService {

  public socket$!: WebSocketSubject<any>;

  public socketOpen$=new BehaviorSubject<boolean>(false);

  public pongTimeStamp:any;

  public connectingSocket$=new BehaviorSubject<boolean>(null);

  constructor() {
    super();
   }

  connect() { 

    
    this.connectingSocket$.next(true);
    console.warn('<----Connecting socket---->');
    this.socket$ = webSocket({
      url:this.callcenterSocketURL,
      openObserver: {
        next: (event) => {
          console.warn("<======Connection established with socket======>", event);
          this.socketOpen$.next(true);
          this.connectingSocket$.next(false);
          let time=moment().format('YYYY-MM-DD HH:mm:ss');
          this.pongTimeStamp=moment.utc(time, 'YYYY-MM-DD HH:mm:ss');
        }
      },
      closeObserver: {
        next:(event)=> {
          console.warn("<=====Connection with socket is closed=====>", event)
          this.socketOpen$.next(false);
          this.connectingSocket$.next(false);
        }
      }
    }); 

    this.socket$.subscribe({
      next: msg => console.log('message received: ',msg), 
      error: err => {
        this.connectingSocket$.next(false);
        console.error("<===========Error in socket===========>", err);
      }, 
      complete: () => {
        this.socketOpen$.next(false);
        this.connectingSocket$.next(false);
      }
     });

  } 





  disconnect() { 
    console.warn('<--- Disconnecting socket --->');
    this.socket$.complete(); 
  } 

  isConnected(): boolean { 
    return (this.socket$ == null||undefined ? false : !this.socket$?.closed); 
  } 

  onMessage(): Observable<any> { 
    return this.socket$!.asObservable().pipe( 
    map(message => message) 
    ); 
  } 

  send(message: any) { 

    console.log('socket status', this.socketOpen$.getValue());

    if(this.socketOpen$.getValue()){
      console.warn('<---- connected & Sending msg to socket ---->');
      this.socket$.next(message);
    }


  }
}
