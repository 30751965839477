import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UserGroup } from '../../models/user';
import { UserGroupService } from '../../services/user-group.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSelectGridComponent } from '../../components/user-select-grid/user-select-grid.component';
import { Ring2voiceService } from 'src/app/modules/ring2voice/services/ring2voice.service';

@Component({
  selector: 'app-add-user-group',
  templateUrl: './add-user-group.component.html',
  styleUrls: ['./add-user-group.component.scss']
})
export class AddUserGroupComponent implements OnInit, OnDestroy {

  @ViewChild('userSelectGrid') userSelectGrid:UserSelectGridComponent;

  public model: UserGroup = new UserGroup();

  public userGroupId: number = 0;

  public groupUsers: any[];

  public SelectionType = SelectionType;

  private subs: SubSink = new SubSink();

  voices: Observable<any>;

  constructor(private userGroupService: UserGroupService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private r2vservice: Ring2voiceService) {

    this.subs.add(route.params.subscribe(params => this.userGroupId = +params.id || 0));
  }

  ngOnInit() {

    this.voices = this.r2vservice.getVoices();
    if (this.userGroupId > 0) {
      this.subs.add(this.userGroupService.getById(this.userGroupId).subscribe(resp => {
        this.model = resp;
        
        this.initVoiceMailModel();


        this.route.queryParams.subscribe(params => {
          const param = params['smartsipgroup'];
          if(param){
            this.model.smart_sip_group = true;
          }
        });
        this.cdr.markForCheck();
      }));
    }else{
      this.initVoiceMailModel();
    }

  }

  submit(): void {

    if (this.model.users.length <= 0) return;

    let api: Observable<UserGroup>;

    if (this.userGroupId > 0) {
      api = this.userGroupService.update(this.userGroupId, this.model);
    }
    else {
      this.model.customer = this.userGroupService.customerId;
      api = this.userGroupService.create(this.model);
    }

    this.subs.add(api.subscribe(
      resp => {
        if (resp.status){
          this.toastr.success(this.userGroupId > 0 ? 'User group info updated' : 'New user group created.');
          this.router.navigate(['/users/groups', resp.id, 'view']);
        }else {
          this.userSelectGrid.openModal(this.userSelectGrid.modalContent);
          this.toastr.warning("Remove unassigned User or Assign SIP")
        }
      },
      () => this.toastr.error('Opeation failed.')
    ));
  }

  cancel(): void { this.location.back(); }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initVoiceMailModel(){

    if(this.model.voicemail_voice == '' || this.model.voicemail_voice == null){
      this.model.voicemail_voice = 'man';   
  } 
  }
}
