<section class="roles-view" *ngIf="role">
    <div class="row">
        <div class="col-12 col-sm-7">
            <div class="media d-flex align-items-center">
                <div class="media-body d-flex align-items-center">
                    <h4 class="m-0">{{role?.name}}</h4>
                    <div *ngIf="role.is_customer_role" class="ml-2 badge" style="border-radius: 2rem;" [ngClass]="{'bg-light-warning': role?.is_customer_role,'bg-light-danger': !role?.is_customer_role }">
                        {{ role?.is_customer_role ? 'Customer role' : '' }}
                    </div>
                    <div *ngIf="role" class="ml-2 badge" style="border-radius: 2rem;" [ngClass]="{'bg-light-success': role?.is_active,'bg-light-danger': !role?.is_active }">
                        {{ role?.is_active ? 'Active' : 'Inactive' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-5 d-flex justify-content-end align-items-center">
            <a [routerLink]="['/roles/new']" class="btn btn-text-center btn-sm gradient-pomegranate mr-1">
                <i class="ft-plus mr-1"></i> New
            </a>
            <a *ngIf="!systemRole && isEditable" [routerLink]="['/roles',roleId,'edit']" class="btn btn-sm bg-light-secondary mr-2">
                <i class="ft-edit mr-1"></i> Edit
            </a>
            <button (click)="cloneRole()" class="btn btn-sm bg-light-secondary mr-2">
                <i class="ft-copy mr-1"></i> Clone
            </button>
            <button *ngIf="!systemRole" (click)="deleteRole()" class="btn btn-sm bg-light-danger">
                <i class="ft-trash-2 mr-1"></i> Delete
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-body">
                        <div class="col-12 p-0 d-flex flex-row">
                            <div class="col-6 pl-0">
                                <div class="user-info">
                                    <div class="account-info" *ngIf="customerName">
                                        <span *ngIf="customerTitle">{{customerTitle}}</span>
                                        <span class="account-name">{{customerName}}</span>
                                    </div>
                                    <div class="item">
                                        <span class="icon">
                                            <i class="icon-calendar"></i>
                                        </span>
                                        <span class="text">
                                            Created on {{role.created_on | date:'MM/dd/YYYY'}}
                                         </span>
                                    </div>
                                    <div class="item" *ngIf="role?.created_by">
                                        <span class="icon">
                                            <i class="icon-user"></i>
                                        </span>
                                        <span class="text">
                                            Created By : {{role?.created_by}}
                                        </span>
                                    </div>
                                    <div class="item">
                                        <span class="icon">
                                            <i class="ft-file-text"></i>
                                        </span>
                                        <span class="text">
                                            {{role?.description}}
                                         </span>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="!systemRole">
                                <div class="col-6 pr-0">
                                    <div class="d-flex flex-row float-right" style="margin-right: -15px;">
                                        <div class="mr-2">{{role.is_active ? 'Deactivate' : 'Activate'}} Role</div>
                                        <div class="custom-switch custom-switch-success custom-control">
                                            <input type="checkbox" class="custom-control-input" id="color-switch-3" (change)="changeStatus()" [(ngModel)]="role.is_active">
                                            <label class="custom-control-label mr-1" for="color-switch-3">
                                              <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-12 p-0 mt-3" *ngIf="suborgs | async as subs">
                          <div class="d-flex flex-row flex-space-bw mb-2">
                              <h5 class="card-title">Suborgs <span>({{subs.length}})</span></h5>
                          </div>
                          <div class="w-100">
                            <span *ngFor="let org of subs" class="badge bg-light-primary mr-2">
                              {{org.name}}
                            </span>
                          </div>
                      </div>
                        <div class="col-12 p-0 mt-3" *ngIf="!systemRole">
                            <div class="d-flex flex-row flex-space-bw mb-2">
                                <h5 class="card-title">Privileges <span>({{previlagesCount}})</span></h5>
                                <a [routerLink]="['/roles',roleId,'perms']" class="btn btn-sm btn-info">
                                    <i class="ft-plus mr-1"></i> Add Privilege
                                </a>
                            </div>
                            <role-previleges [roleId]="roleId" [(count)]="previlagesCount" [footer]="false" [actions]="true"></role-previleges>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!systemRole && roleId > 0">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-flex flex-row flex-space-bw">
                    <h5 class="card-title">Grid Views <span>({{gridViewsCount}})</span></h5>
                    <button (click)="role2view.showAddViewPopup()" class="btn btn-sm btn-info">
                        <i class="ft-plus mr-1"></i> Add View
                    </button>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <app-views2role-list #role2view [roleId]="roleId" [roleName]="role?.name" [(count)]="gridViewsCount"></app-views2role-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
