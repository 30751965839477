import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, concat, of } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { CustomerSystemType } from '../../../models/system-type';
import { SystemTypeService } from '../../../services/system-type.service';

@Component({
  selector: 'customer-system-type-select',
  templateUrl: './customer-system-type-select.component.html',
  styleUrls: ['./customer-system-type-select.component.scss']
})
export class CustomerSystemTypeSelectComponent implements OnInit {

  @Input() readonly: boolean = false;

  private _value: any;

  get value(): any { return this._value; }

  @Input() set value(val: any) {
    this._value = val;
    if (val > 0 && this.selected !== val) {
      this.setValue(val);
    }
    else {
      this._selected = val;
    }
  };

  @Output() valueChange = new EventEmitter<any>();

  @Output() change = new EventEmitter<any>();

  private _selected: number;

  public get selected(): any { return this._selected; }

  public set selected(value: any) {
    this._selected = value;
    this.valueChange?.emit(value);
  }

  @Input() addButton: boolean = true;

  @Input() multiple: boolean = false;

  @Input() fetchWhenOpen: boolean = false;

  public options: Observable<CustomerSystemType[]>;

  public placeholder: string;

  public totalCount: number = 0;

  public currentCount: number = 0;

  public textInput = new Subject<string>();

  public initial: CustomerSystemType;

  public firstOpen: boolean = true;

  private modalRef: NgbModalRef;

  constructor(private service: SystemTypeService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    this.placeholder = this.hasPermission() && this.addButton ? "Select or add System Type" : "Select System Type";
    if (!this.fetchWhenOpen) {
      this.loadInitial();
    }
  }

  loadInitial() {
    this.options = concat(this.getData(), this.textInput.pipe(
      filter(res => res !== null && res.length >= 2),
      distinctUntilChanged(),
      switchMap(term => this.getData(term))
    ));
  }

  getData(search?: string) {
    let option = {
      sort: [{ colname: 'customer_system_type_name', direction: 'asc' }],
      filters: search ? [{ colname: 'customer_system_type_name', condition: 'contains', value: search, operator: 'AND' }] : null
    };
    return this.service.getFiltered(option).pipe(
      map(resp => {
        if (this.initial && this.initial.id === this.selected) {
          resp.result = resp.result.filter(item => item.id !== this.selected).concat(this.initial);
        }
        this.totalCount = resp.count;
        this.currentCount = resp.result.length;
        return resp.result;
      })
    );
  }

  setValue(id: number) {
    this.options = this.service.getById(id).pipe(map(response => {
      this.initial = response;
      return [response];
    }));
    this._selected = id;
  }

  selectOpen() {
    if (this.fetchWhenOpen && (!this.options || this.firstOpen)) {
      this.loadInitial();
      this.firstOpen = false;
    }
  }

  trackByFn(item: CustomerSystemType) { return item.id; }

  openModal(content: any): void { this.modalRef = this.modalService.open(content); }

  onValueChanged(license: CustomerSystemType) { this.change?.emit(license); }

  onDataSubmittedSuccess(result: { response: CustomerSystemType, message: string }): void {
    this.toastr.success(result.message);
    this.options = concat(this.options, of([result.response]));
    this.value = result.response.id;
    this.onValueChanged(result.response);
    this.modalRef?.dismiss();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalRef?.dismiss();
  }

  onCancel(): void { this.modalRef?.dismiss(); }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.CUSTOMER_SYSTEM_TYPES,
      [
        PermissionConstants.CREATE,
        PermissionConstants.GLOBAL
      ],
      ResourceConstants.CUSTOMER_MODULE);
  }
}
