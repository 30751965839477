<div class="call_listing_box w-100">
    <div class="incoming-section d-flex justify-content-center h-50">

        <div class="incoming-call-list ml-1">
            <div class="d-flex justify-content-center mt-1 mb-2 incomingCallListTitle"><b>Incoming Calls</b></div>
            <div class="noCallsMsg text-secondary" *ngIf="(this.sipInboundFnService.callQ$ |async)?.length==0 else incomingCallsList">
                <small>No incoming calls</small>
            </div>
            <ng-template #incomingCallsList>
                <incoming-calls-info *ngFor="let session of (this.sipInboundFnService.callQ$ |async)" class="modal-title incoming mb-1"
                (click)="getIncomingCallId(session)" [session]="session"
                [ngClass]="{'selectedTitle' : sipInboundFnService.selectedCaller==session}"></incoming-calls-info>
           
            </ng-template>
        </div>
        <div class="incoming-call-list ml-1 mr-1">
            <div class="d-flex justify-content-center mt-1 mb-2 onHoldCallListTitle"><b>Ongoing/OnHold</b></div>
            <div class="noCallsMsg text-secondary" *ngIf="this.sipInboundFnService.acceptedCallQ.length==0 else AcceptedCallList">
                <small>No ongoing calls</small>
            </div>
            <ng-template #AcceptedCallList>
                <accepted-calls-info *ngFor="let session of this.sipInboundFnService.acceptedCallQ"  class="modal-title mb-1"
                                    (click)="getIncomingCallId(session)"
                                    [ngClass]="{'onGoingCall_title' : sipInboundFnService.selectedCaller&&answeredCall(session),
                                    'selectedTitle' : sipInboundFnService.selectedCaller==session,'onHoldTitle' :session.customValues?.isCallOnHold || session.customValues.isUnholded, 'endedCallTitle' :session.state=='Terminated' && !session.customValues.isUnholded}"
                                    [session]="session" (holdCall) = "onHoldCall()">
                </accepted-calls-info>
            </ng-template>
        </div>

    </div>
    <div class="outgoin-section d-flex justify-content-center h-50 ">
        <div class="incoming-call-list ml-1">
            <div class="d-flex justify-content-center mt-1 mb-2 incomingCallListTitle"><b>Outgoing Calls</b></div>
            <div class="noCallsMsg text-secondary" *ngIf="this.sipOutBoundService.callQ.length==0 else outgoingCallList">
                <small>No outgoing calls</small>
            </div>
            <ng-template #outgoingCallList>
                <incoming-calls-info *ngFor="let session of this.sipOutBoundService.callQ" class="modal-title incoming mb-1"
                (click)="getSelOutgoingCallInfo(session)" [session]=" this.sipOutBoundService.currentCallDetails.active_CallerSession"
                [ngClass]="{'selectedTitle' :  this.sipOutBoundService.currentCallDetails.active_CallerSession==session}"></incoming-calls-info>
            </ng-template>
        </div>
        <div class="incoming-call-list ml-1 mr-1">
            <div class="d-flex justify-content-center mt-1 mb-2 onHoldCallListTitle"><b>Ongoing/OnHold</b></div>
            <div class="noCallsMsg text-secondary" *ngIf="sipOutBoundService.acceptedCallQ?.length==0">
                <small>No ongoing calls</small>
            </div>

            <ng-template  [ngIf]="sipOutBoundService.acceptedCallQ.length != 0">
                <accepted-call-outbound *ngFor="let session of sipOutBoundService.acceptedCallQ"  class="modal-title mb-1"
                                    (click)="getSelOutgoingCallInfo(session)"
                                    [ngClass]="{'onGoingCall_title' : this.sipOutBoundService.currentCallDetails&&answeredCall(session),
                                    'selectedTitle' : this.sipOutBoundService.currentCallDetails.active_CallerSession==session,
                                    'onHoldTitle' :session.customValues?.isCallOnHold,
                                     'endedCallTitle' :session.state=='Terminated'}"
                                    [session]="session">
                </accepted-call-outbound>
            </ng-template>
        </div>

    </div>

</div>


