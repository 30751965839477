import { KitAssembleService } from './../../services/kit-assemble.service';
import { SerialNumberRequest, WareHouseLocation } from './../../models/warehouse.models';
import { WarehouseService } from './../../services/warehouse.service';
import { SubSink } from 'subsink';
import { KitAssembleWareHouseStock } from './../../models/kit-assemble.models';
import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { WareHouse } from '../../models/warehouse.models';
import { map } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { InvCommonService } from '../../services/inv-common.service';

@Component({
    selector: 'kit-assemble-warehouse',
    templateUrl: './kit-assemble-warehouse.component.html',
    styleUrls: ['./kit-assemble-warehouse.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class KitAssembleWarehouseComponent implements OnInit, OnDestroy, OnChanges {

    @Input() quantity: number;

    @Input() saveButton: boolean = false;

    @Input() status: string;

    private _assembleId: number;
    Operation: string;
    warehouse_location_name: any;
    warehouse_name: any;
    index: number;
    modal_title: string;
    warehouse_detail: string;
    comments: any;
    warehouse_quantity: number;
    total_quantity: number = 0;
  total_available_quantity: number;
    moveRow: KitAssembleWareHouseStock;
    move_locations: Observable<WareHouseLocation[]>;
    move_warehouseId: any;
    move_warehouse_locationId: any;
    move_quantity: any;

    get assembledId(): number {
        return this._assembleId;
    };

    @Input() set assembledId(value: number) {
        this._assembleId = value;
        if (value > 0) {
            this.rows.length = 0;
            this.getAssembledWarehouses();
        }
    };

    @Output() openPrint: EventEmitter<any> = new EventEmitter();


    public rows: KitAssembleWareHouseStock[] = [];

    public warehouses: WareHouse[];

    public warehouseLocations = {};

    private subs: SubSink = new SubSink();

    public isAllSuborg:boolean;

    public slnoRowQty:number=0; ///Qty of a row

    public slnoGenerateAll:boolean=true; // For slNo generation

    public slnoToGenerate:number=0; // Count of SlNo to generate

    public existingslNos:string[]=[]; //Existing slNos in arrays

    public selectedStockRow:KitAssembleWareHouseStock; // For store selected row

    constructor(
        private warehouseService: WarehouseService,
        private assembleService: KitAssembleService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private invCommonService: InvCommonService,
        private router: Router) {
            this.isAllSuborg = assembleService.isAllSuborg;
        }

    ngOnChanges(changes: SimpleChanges): void {
        // if (changes.quantity) {
        //     if (this.quantity < this.total_quantity) {
        //         this.toastr.info('Quantity mismatch');
        //         return;
        //     }
        // }
    }

    ngOnInit() { this.getWarehouses(); }

    getAssembledWarehouses() {
        this.subs.sink = this.assembleService.getKitAssembledWarehousestock(this.assembledId).pipe(map(resp => {
            return resp.map(w => {
                if (w.warehouse_location > 0) {
                    let loc = new WareHouseLocation();
                    loc.location = w.location_name;
                    loc.id = w.warehouse_location;
                    w.locations = of([loc]);
                }
                return w;
            });
        })).subscribe(resp => {
            this.rows = resp.sort((a, b) => a.id - b.id);
            if (resp.length <= 0) {
                this.appendRow();
            }
            this.getAggregateQuantity();
            this.cdr.markForCheck();
        });
    }

    appendRow() {
        let aw = new KitAssembleWareHouseStock();
        aw.customer = this.warehouseService.customerId;
        this.rows.push(aw);
    }

    removeRow(row:KitAssembleWareHouseStock) {
        if (row.id) {
            this.subs.sink = this.assembleService.deleteKitAssembleWareHouseStock(row.id, row.kit_assemble).subscribe(resp => {
                this.removeRowFromCollection(row);
            });
        }
        else {
            this.removeRowFromCollection(row);
        }
    }

    removeRowFromCollection(row:KitAssembleWareHouseStock) {
        this.rows = this.rows.filter(item => item !== row);
        this.cdr.markForCheck();
        this.toastr.success(`Warehouse Deleted`);
        this.getAggregateQuantity();
    }

    getWarehouses() {
        this.subs.sink = this.warehouseService.getAll().subscribe(resp => {
            this.warehouses = resp;
            this.cdr.markForCheck();
        });
    }

    onWarehouseChange(wh: WareHouse, index: number) {
        if (!wh) {
            this.rows[index].warehouse_location = null;
            this.rows[index].locations = null;
            return;
        }
        this.rows[index].warehouse_location = null;
        if (this.warehouseLocations[wh.id]) {
            this.rows[index].locations = of(this.warehouseLocations[wh.id])
        }
        else {
            this.setLocations(wh.id, index);
        }
        this.warehouse_name = wh.warehouse_name;
    }

    setLocations(warehouseId: number, index: number) {
        this.rows[index].locations = this.warehouseService.getWareHouseLocations(warehouseId).pipe(map(resp => {
            this.warehouseLocations[warehouseId] = resp;
            return resp;
        }));
    }

    onLocationSelectOpen(wh: KitAssembleWareHouseStock, index: number) {
        if (wh.warehouse > 0 && !this.warehouseLocations[wh.warehouse]) {
            this.setLocations(wh.warehouse, index);
        }
    }

    onWarehouseLocationChange(whl: any, index: number) {
        if (whl) {
            this.warehouse_location_name = whl.location;
        }
    }

    toggleStockModal(content: any, wh: KitAssembleWareHouseStock, opr: string, index: number) {
        if (wh.location_name)
            this.warehouse_location_name = wh.location_name
        if (wh.warehouse_name)
            this.warehouse_name = wh.warehouse_name
        this.Operation = opr;
        this.index = index;
        this.modal_title = opr + " Qty To";
        this.warehouse_detail = this.warehouse_name + " - " + this.warehouse_location_name
        this.comments = wh.comments;
        this.warehouse_quantity = 0;
        this.modalService.open(content);
    }

    getAggregateQuantity() {
        this.total_quantity = this.rows.reduce((sum, current) => sum + current.quantity, 0)
        this.total_available_quantity = this.rows.reduce((sum, current) => sum + current.available_quantity, 0)
    }

    modifyQuantity() {
        if (this.warehouse_quantity < 0) {
            this.toastr.info('Value should be greater than or equal to Zero');
            return;
        }
        let new_total: number;
        if (this.Operation == "Add")
            new_total = this.total_quantity + this.warehouse_quantity;
        else if (this.Operation == "Subtract")
            new_total = this.total_quantity - this.warehouse_quantity;
        else
        new_total = this.rows.reduce((sum, current) => sum + current.quantity, 0)
        if (this.quantity >= new_total) {
            if (this.Operation == "Add")
                this.rows[this.index].quantity = this.rows[this.index].quantity + this.warehouse_quantity
            else if (this.Operation == "Subtract")
                this.rows[this.index].quantity = this.rows[this.index].quantity - this.warehouse_quantity
            this.getAggregateQuantity()
            this.rows[this.index].comments = this.comments;
            this.modalService.dismissAll();
        }
        else {
            this.toastr.info('Quantity mismatch');
            return;
        }
    }

    quantityCheck(): boolean {
        this.getAggregateQuantity()
        return !(this.quantity < this.total_quantity)
    }

    getApis(assembleid: number): Observable<any>[] {
        let apis: Observable<any>[] = [];
        let add = [], update = [];
        this.rows.map(item => item.kit_assemble = assembleid);
        for(let row of this.rows) {
            row.kit_assemble = assembleid
            if (row.warehouse && row.warehouse_location && row.quantity) {
                if (row.id) {
                    update.push(row);
                }
                else {
                    add.push(row)
                }
            }
        }
        // let update = this.rows.filter(item => item.id);
        // let add = this.rows.filter(item => !item.id);
        if (add.length) {
            apis.push(this.assembleService.createKitAssembleWareHouseStock(add, assembleid));
        }
        if (update.length) {
            apis.push(this.assembleService.updateKitAssembleWareHouseStock(update, assembleid));
        }
        return apis;
    }

    ModifyDuplicate(){
        let result=[];
        this.rows.forEach(function (a, index) {
            if (!this[a.warehouse_location]) {
                this[a.warehouse_location] = a;
                result.push(this[a.warehouse_location]);
            }
            else{
            this[a.warehouse_location].quantity += a.quantity;
            }
        }, []);
        this.rows = result;
    }

    submit(assembleid: number, next?: (value: any) => any, error?: (value: any) => any): void {
        if (!this.validate()) {
            this.toastr.info('Warehouse location required');
            return;
        }
        if (!this.quantityCheck()) {
            this.toastr.info('Quantity mismatch');
            return;
        }
        this.ModifyDuplicate();
        let apis = this.getApis(assembleid);

        if (apis.length <= 0) {
            return next(null) || false;
        }
        this.subs.sink = forkJoin(apis).subscribe(
            results => {
                if (next)
                next(results);
                else {
                this.toastr.success(`Warehouse updated`);
                // this.getAssembledWarehouses()
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['/inventory/assemblies/assembled', assembleid, 'view']);
                }
            },
            err => {
                if (err) error(error); else this.toastr.error(`Operation failed`);
            }
        );
    }

    getWarehouseIds() {
        return this.rows.filter(kw => kw.id > 0).map(kw => kw.id) || [];
    }

    printView(werehouse:number, location:number, slNo:string[]){

        this.openPrint.emit({"werehouse":werehouse, "location":location, "slNo":slNo});
    }

    reloadPage(){
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/inventory/assemblies/assembled', this.assembledId, 'view']);
    }

    showMoveStockModal(content, row:KitAssembleWareHouseStock){
        this.moveRow = row
        this.modalService.open(content);
    }

    onMoveWarehouseChange(wh: WareHouse) {
        this.setMoveLocations(wh.id);
    }

    setMoveLocations(warehouseId: number) {
        this.move_locations = this.warehouseService.getWareHouseLocations(warehouseId).pipe(map(resp => {
            return resp;
        }));
    }

    onMoveValidate(){
        if(!this.move_warehouseId || !this.move_warehouse_locationId || !this.move_quantity){
            this.toastr.error(`All fields are mandatory`);
            return  false;
        }
        else if(this.move_quantity > this.moveRow.available_quantity){
            this.toastr.error(`Quantity should be less than available quantity`);
            return  false;
        }
        else if(this.moveRow.warehouse == this.move_warehouseId && this.moveRow.warehouse_location == this.move_warehouse_locationId){
            this.toastr.error(`Source and Destination should not be same`);
            return  false;
        }
        else
            return true;
    }

    onMoveQuantity(){
        if(this.onMoveValidate()){
            let update_models = []
            let create_models = []
            let source_row = this.rows.filter(item => item.warehouse == this.moveRow.warehouse && item.warehouse_location == this.moveRow.warehouse_location)[0]
            source_row.quantity -= this.move_quantity;
            update_models.push(source_row);
            let dest_row = this.rows.filter(item => item.warehouse == this.move_warehouseId && item.warehouse_location == this.move_warehouse_locationId)[0]
            if(dest_row){
                dest_row.quantity += this.move_quantity;
                update_models.push(dest_row);
            }
            else{
                this.appendRow();
                this.rows[this.rows.length - 1].kit_assemble = this.assembledId;
                this.rows[this.rows.length - 1].quantity = this.move_quantity;
                this.rows[this.rows.length - 1].warehouse = this.move_warehouseId;
                this.rows[this.rows.length - 1].warehouse_location = this.move_warehouse_locationId;
                create_models.push(this.rows[this.rows.length - 1])
            }
            let api=[];
            if(update_models.length)
                api.push(this.assembleService.updateKitAssembleWareHouseStock(update_models, this.assembledId))
            if(create_models.length)
                api.push(this.assembleService.createKitAssembleWareHouseStock(create_models, this.assembledId))
            this.subs.sink = forkJoin(api).subscribe(resp => {
                if(resp){
                    this.cdr.markForCheck();
                    this.modalService.dismissAll();
                    this.toastr.success(`Moved Succesfully`);
                    this.reloadPage();
                }
                else{
                    this.cdr.markForCheck();
                    this.modalService.dismissAll();
                    this.toastr.success(`operation failed..`);
                }
            });
        }
    }

    generateSerialNumber(content, row:KitAssembleWareHouseStock){
        this.slnoRowQty=row.quantity;
        this.selectedStockRow=row;
        this.slnoToGenerate=row.quantity;
        if(row.serial_number&&row.serial_number.length>0){
            this.existingslNos=row.serial_number;

        }
        this.modalService.open(content);
    }

    generateSlNo(){

        let data=new SerialNumberRequest();
        data.requested_quantity=this.slnoToGenerate;
        data.unique_id=this.assembledId;
        data.warehouse=this.selectedStockRow.warehouse;
        data.warehouse_location=this.selectedStockRow.warehouse_location;
        data.type='KIT_ASSEMBLE';
        this.invCommonService.generateSlNo(data).subscribe(
            response=>{
                this.toastr.success('Successfully generated the serial numbers');
                 this.modalService.dismissAll();
                 this.getAssembledWarehouses();

            }
        )
    }

    changeSlNoQty(){
        let max=this.slnoRowQty-this.existingslNos.length;

        if(this.slnoToGenerate>max){
            this.toastr.error('You can only generate maximum '+max+' number of serial numbers');
        }
    }



    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    validate(): boolean {
        return !this.rows.some(item => !item.warehouse_location || item.warehouse_location <= 0);
    }

}
