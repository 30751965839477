<form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && submit()" novalidate>

    <div class="row mt-2">
        <div class="col-12">
            <div class="form-group">
                <label class="val-required">Search Existing Customer</label>
                <tenant-customer-select class="select-md" 
                        [(value)]="model.tenant_customer_id"
                        (valueChange)="customerChanged()"
                        (change)="customerChanged()"
                        [suborg_id]="incomingcall_Details.call_to_suborg_id">
                </tenant-customer-select>
            </div>
            <div class="form-group" *ngIf="model.tenant_customer_id">
                <label >Customer contact (<small style="color: red;text-transform: none;">Select if you want to update on existing contact</small>)</label>
                <div class="row">
                    <div class="col-12">
                <tenant-customer-contact-select
                    [tenantCustomerId]="model.tenant_customer_id"
                    [addButton]="false" 
                    [(value)]="contact_id"
                    (valueChange)="openEdit()"
                >
                </tenant-customer-contact-select>
                    </div>
                    <!--<div class="col-2">
                <button  type="button" class="btn btn-sm btn-primary" (click)="onUpdateContact()" style="margin-top: 4px;margin-left: -10px;">Add to Contact</button>
                    </div>  -->   
            </div>
            </div>
        </div>
        
        <div *ngIf="model.tenant_customer_id" style="width:100%">
        <div class="col-12 create_new_contact_tag" style="border-bottom: none;">OR</div>
        <div class="col-12 create_new_contact_tag">Create New Contact<img src="assets/img/caller assets/down-arrow-svgrepo-com.svg" alt=""></div>
        </div>
        
        <div class="col-12 create_new_contact_tag" style="border-bottom: none;" *ngIf="!model.tenant_customer_id">OR</div>
        <div class="col-12 create_new_contact_tag" *ngIf="!model.tenant_customer_id">Create New Customer<img src="assets/img/caller assets/down-arrow-svgrepo-com.svg" alt=""></div>
        
        <div class="col-6">
            <h6 class="text-info">Contact Details</h6>
            <div class="form-group" *ngIf="!customerCheckBox&&!model.tenant_customer_id">
                <label class="val-required">Customer Name</label>
                <input type="text" [(ngModel)]="model.tenant_customer_name" placeholder="Enter Customer Name" #cutomername="ngModel" name="first_name" class="form-control" required>
                <small class="form-text text-muted danger" *ngIf="cutomername.invalid && (cutomername.dirty || cutomername.touched)">
                    <div *ngIf="firstname.errors.required">
                        Name is required.
                    </div>
                </small>
            </div>
            <span *ngIf="!model.tenant_customer_id">
                <input type="checkbox" name="cutomer_name" [(ngModel)]="customerCheckBox">
                <label class="text-info ml-1">Customer name same as contact name</label>
            </span>
            <div class="form-group">
                <label class="val-required">First Name</label>
                <input type="text" [(ngModel)]="model.contact_first_name" #firstname="ngModel" name="first_name" class="form-control" required>
                <small class="form-text text-muted danger" *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)">
                    <div *ngIf="firstname.errors.required">
                        Name is required.
                    </div>
                </small>
            </div>
            
            <div class="form-group">
                <label>Last Name</label>
                <input type="text" [(ngModel)]="model.contact_last_name" name="last_name" class="form-control">
            </div>
            <div class="form-group">
                <label>Mobile</label>
                <div class="position-relative has-icon-left">
                    <input type="text" [(ngModel)]="model.contact_mobile" mask="(000) 000-0000" name="mobile" class="form-control">
                    <div class="form-control-position">
                        <i class="ft-smartphone"></i>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Phone</label>
                <div class="position-relative has-icon-left">
                    <input type="text" [(ngModel)]="model.contact_phone" mask="(000) 000-0000" name="phone" class="form-control">
                    <div class="form-control-position">
                        <i class="ft-phone"></i>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Email</label>
                <div class="position-relative has-icon-left">
                    <input type="text" [(ngModel)]="model.contact_email" name="email" class="form-control">
                    <div class="form-control-position">
                        <i class="ft-mail"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <h6 class="text-info">Location Details</h6>
            <div class="form-group" *ngIf="model.tenant_customer_id">
                <label>Search Location</label>
                <tenant-customer-location-select [appendToBody]="true" [addButton]="false" #location [tenantCustomerId]="tenantCustomerId" [newContact_model]="model" [(value)]="model.location_id" (itemAdded)="onLocationAdded()"></tenant-customer-location-select>
            </div>
            <div class="form-group" *ngIf="!locationCheckBox">
                <label>Location Name</label>
                <input type="text" [(ngModel)]="model.location_name" #locationname="ngModel" name="location_name" class="form-control">
            </div>
            <span *ngIf="!model.location_id">
                <input type="checkbox" name="cutomer_name" [(ngModel)]="locationCheckBox">
                <label class="text-info ml-1">Set Location name same as Address</label>
            </span>
            <div class="form-group">
                <label>Address</label>
                <textarea type="text" [(ngModel)]="model.location_address" #add="ngModel" name="address" class="form-control" [disabled]="model.location_id>0"></textarea>
            </div>
            <div class="form-group">
                <label>City</label>
                <input type="text" [(ngModel)]="model.location_city" name="city" class="form-control" [disabled]="model.location_id>0">
            </div>
            <div class="form-group">
                <label>State</label>
                <input type="text" [(ngModel)]="model.location_state" name="state" class="form-control" [disabled]="model.location_id>0">
            </div>
            <div class="form-group">
                <label>Zip</label>
                <input type="text" [(ngModel)]="model.location_zip" name="zip" class="form-control" [disabled]="model.location_id>0">
            </div>
            <div class="form-group pull-right">
                <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                    Add Contact
                </button>
                  <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
            </div>
        </div>
    </div>
</form>