<div class="form-container">
    <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()">

        <div class="form-group">
            <label class="val-required">Module</label>
            <ng-select 
            [(ngModel)]="model.module_id" 
            placeholder="Select module"
            #module="ngModel" 
            name="module"
            required>
                <ng-option  [value]="1">Service Ticket</ng-option>
             </ng-select>

            <small class="form-text text-muted danger" *ngIf="module.invalid && (module.dirty || module.touched)">
                <div *ngIf="module.errors.required">
                  Module is required.
                </div>
            </small>
        </div>

        <div class="form-group">
            <label class="val-required">Reason</label>

            <ng-select
              [items]="rfsList"
              bindLabel="name"
              bindValue="id"
              (change)="rfsChange($event)"
              placeholder="Select reason"
              [(ngModel)]="model.reason_for_service"
              name="reason_for_service"
              #reasonForService="ngModel"
              required
              >
            </ng-select>

            <small class="form-text text-muted danger" *ngIf="reasonForService.invalid && (reasonForService.dirty || reasonForService.touched)">
                <div *ngIf="reasonForService.errors.required">
                  Reason is required.
                </div>
            </small>
        </div>
        <div class="form-group">
            <label class="val-required">Message</label>
            <textarea type="text" [(ngModel)]="model.message" #message="ngModel" name="description" class="form-control" required></textarea>

            <small class="form-text text-muted danger" *ngIf="message.invalid && (message.dirty || message.touched)">
                <div *ngIf="message.errors.required">
                  Message is required.
                </div>
            </small>
        </div>

        <div class="form-group" >
          <div class="checkbox">
              <input type="checkbox" [(ngModel)]="model.required_confirmation"  name="required_confirmation" id="required_confirmation" class=""
              placeholder="Enter title for this document" />
              <label class="" for="required_confirmation">Required confirmation</label>
          </div>
       </div>
        <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
            {{uniqueId > 0 ? 'Save Changes' : 'Create'}}
          </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</div>