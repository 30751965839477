import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";


export class SalesOrderDataHelper{


    static get defaults(): GFilterParam[] { return [
        { displayName: "All Sales Orders", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "New", colname: 'status', condition: 'equalto', value: '1', operator: 'AND' },
        { displayName: "Draft", colname: 'status', condition: 'equalto', value: '2', operator: 'AND' },
        { displayName: "Send to Customers", colname: 'status', condition: 'equalto', value: '3', operator: 'AND' },
        { displayName: "Approved", colname: 'status', condition: 'equalto', value: '5', operator: 'AND' },
        { displayName: "Rejected", colname: 'status', condition: 'equalto', value: '4', operator: 'AND' },
        { displayName: "Partially Invoiced", colname: 'status', condition: 'equalto', value: '6', operator: 'AND' },
        { displayName: "Fully Invoiced", colname: 'status', condition: 'equalto', value: '7', operator: 'AND' },
        { displayName: "Converted to Workorder", colname: 'status', condition: 'equalto', value: '8', operator: 'AND' },
      ];}

      static get search(): GFilterParam[] { return  [
        {
          displayName: 'Combo Search', colname: '', condition: '', value: '',
          children: [
            { displayName: 'Title', colname: 'title', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Contact Name', colname: 'customer_contact_firstname', condition: 'contains', value: '', operator: 'OR' },
            { colname: 'customer_contact_lastname', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Contact Phone', colname: 'customer_contact_phone', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Contact Email', colname: 'customer_contact_email', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer A/c Number', colname: 'customer_account', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Location', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Location A/c Number', colname: 'location_account', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Unique Number', colname: 'unique_number', condition: 'contains', value: '', operator: 'OR' },
        ]
        },
        { displayName: 'Title', colname: 'title', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'OR' },
        {
          displayName: 'Customer Contact Name', colname: '', condition: '', value: '',
          children: [
            { colname: 'customer_contact_firstname', condition: 'contains', value: '', operator: 'OR' },
            { colname: 'customer_contact_lastname', condition: 'contains', value: '', operator: 'OR' },
          ]
        },
        { displayName: 'Customer Contact Phone', colname: 'customer_contact_phone', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Contact Email', colname: 'customer_contact_email', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer A/c Number', colname: 'customer_account', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Location', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Location A/c Number', colname: 'location_account', condition: 'contains', value: '', operator: 'OR' },

    ]
    }


      static get columns(): GFColumn[]{ return [
        {
          displayName: 'Status', columnName: 'status', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
          options: [
            { displayName: 'New', value: '1' },
            { displayName: 'Draft', value: '2' },
            { displayName: 'Send to Customers', value: '3' },
            { displayName: 'Approved', value: '5' },
            { displayName: 'Rejected', value: '4' },
            { displayName: 'Partially Invoiced', value: '6' },
            { displayName: 'Fully Invoiced', value: '7' },
            { displayName: 'Converted to WorkOrder', value: '8' },
          ]
        },
        {
            displayName: 'View Status', columnName: 'client_viewed', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
            options: [
              { displayName: 'Viewed', value: true },
              { displayName: 'Not Viewd', value: false }
            ]
        },
        { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
        { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
        { displayName: 'Shipping date', columnName: 'shipping_date', columnType: GFColumnTypes.date },
      ];
    }

      static get sortLookup() {
          return  {
            created_on:"created_on",
            title:"title",
            tenant_customer_name:"customer_name",
            status:"status",
            total_price:"total_price",
            shipping_date:"shipping_date"
        };
    }

   static get statusClass(){

            return {
              "1": "status-new",
              "2": "status-draft",
              "3": "status-send-customer",
              "4": "status-rejected",
              "5": "status-rejected",
              "6": "status-converted-invoice",
              "7": "status-converted-workorder",
            };
    }



}
