import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GFilterParam } from 'src/app/core/models/grid-filter.models';
import { SIPService } from '../../services/sip.service';
import { SubSink } from 'subsink';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AgentStatusLog } from '../../models/sip.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';

@Component({
  selector: 'agent-status-log',
  templateUrl: './agent-status-log.component.html',
  styleUrls: ['./agent-status-log.component.scss']
})
export class AgentStatusLogComponent implements OnInit {

    private _agentId: number;

    @Input() set agentId(val: number) {
        if (val !== this._agentId) {
            this._agentId = val;
            this.getData();
        }
    }

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    public page = { count: 0, limit: 200, offset: 0, pageNumber: '0-0' };

    private subs: SubSink = new SubSink();

    public rows: AgentStatusLog[] = [];

    constructor(
        private sipService: SIPService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
    }

    getData(filters: GFilterParam[] = null, offset: number = 0) {
        this.subs.sink = this.sipService.getAgentStatusLog(this._agentId, this.page.limit).pipe(
            catchError(err => of([]))
        ).subscribe(response => {
            console.log(response)
            this.rows = response;
            this.setPagination(offset, this.rows.length);
            this.cdr.markForCheck();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    setPage(pageInfo: any) {
        this.getData();
    }

}
