import { WHBaseModel } from "src/app/core/models/common.models";

export class WareHouse extends WHBaseModel {

    public id: number;

    public warehouse_name: string;

    public description: string;

    is_quickbook: string;
}

export class WareHouseLocation extends WHBaseModel{

    public id: number;

    public location: string;

    public description: string;

    public warehouse: number;
}

export class SerialNumberRequest{

    public id:number;

    public warehouse:number;

    public warehouse_location:number;

    public requested_quantity:number;

    public type:string;

    public unique_id:number;
}
