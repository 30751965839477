<form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()" novalidate>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label class="val-required">Name</label>
                <input type="text" [(ngModel)]="model.manufacturers_name" #name="ngModel" name="manufacturers_name" class="form-control" required>
                <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                  <div *ngIf="name.errors.required">
                      Name is required.
                  </div>
                </small>
                <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
            </div>
            <div class="form-group">
                <label>Address</label>
                <textarea type="text" [(ngModel)]="model.address" name="address" class="form-control"></textarea>
            </div>
            <div class="form-group">
                <label>Account Number</label>
                <input type="text" [(ngModel)]="model.account_number" name="account_number" class="form-control">
            </div>
        </div>
        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
              {{manufacturerId > 0 ? 'Save Changes' : 'Add Manufacturer'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </div>
</form>