<ngx-datatable class="bootstrap core-bootstrap row-detail-custom" [scrollbarH]="false" [headerHeight]="40" rowHeight="auto" [columnMode]="'force'" [rows]="rows" [selectionType]="SelectionType.single" (select)="onSelect($event)" [sorts]="[{prop:'first_name',dir:'asc'}]">
    <ngx-datatable-row-detail [rowHeight]="100" #myDetailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <div class="contact-detail d-flex flex-row pt-1">
                <div class="col-6">
                    <span class="badge-pill" [ngClass]="{'bg-light-success':row.portal_access,'bg-light-secondary':!row.portal_access}">
                        {{ row.portal_access ? 'Portal access' : 'No portal access' }}
                    </span>
                    <div class="d-flex mt-2" *ngIf="row.department">
                        <div class="detail-key">Department</div>
                        <div class="detail-value">{{row.department}}</div>
                    </div>
                    <div class="d-flex" *ngIf="row.fax">
                        <div class="detail-key">Fax</div>
                        <div class="detail-value">{{row.fax | mask:'(000) 000-0000'}}</div>
                    </div>
                    <ng-container *ngFor="let field of row.additional_fields | customfields as custFields">
                        <div class="d-flex">
                            <div class="detail-key" [ngbTooltip]="field.name">{{field.name}}</div>
                            <div class="detail-value">{{field.value || 'NIL'}}</div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-6">
                    <div class="d-flex" *ngIf="row.twitter">
                        <div class="detail-key"><i class="ft-twitter"></i> Twitter</div>
                        <a target="_blank" [href]="row.twitter" class="detail-value">{{row.twitter}}</a>
                    </div>
                    <div class="d-flex" *ngIf="row.instagram">
                        <div class="detail-key"><i class="ft-instagram"></i> Instagram</div>
                        <a target="_blank" [href]="row.instagram" class="detail-value">{{row.instagram}}</a>
                    </div>
                    <div class="d-flex" *ngIf="row.facebook">
                        <div class="detail-key"><i class="ft-facebook"></i> Facebook</div>
                        <a target="_blank" [href]="row.facebook" class="detail-value">{{row.facebook}}</a>
                    </div>
                    <div class="d-flex" *ngIf="row.linkedin">
                        <div class="detail-key"><i class="ft-linkedin"></i> LinkedIn</div>
                        <a target="_blank" [href]="row.linkedin" class="detail-value">{{row.linkedin}}</a>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="$event.stopPropagation();toggleExpandRow(row)"></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Name" prop="first_name" [minWidth]="150">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="d-flex align-items-center">
                <div *ngIf="row.picture > 0 ; else customAvatar">
                    <img class="rounded-circle mr-1" height="32" width="32" alt="datatable-avatar" />
                </div>
                <ng-template #customAvatar>
                    <div class="avatar mr-1 ml-0" [class]="randomDpBackground(row)">
                        <div class="avatar-content">{{ row.first_name + ' ' + (row.last_name || '') | shortName}}</div>
                    </div>
                </ng-template>
                <div class="cell-line-height">
                    <p class="line-height-1 mb-0">{{ row.first_name + ' ' + (row.last_name || '')}}</p>
                    <span class="text-muted font-small-2">{{ row.title }}</span>
                </div>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Email" prop="email">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <a (click)="$event.stopPropagation()" [href]="'mailto:'+value">{{value}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Phone" prop="phone">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            {{value | mask:'(000) 000-0000'}} <span *ngIf="row.extension">Ext {{row.extension}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Mobile" prop="mobile">
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | mask:'(000) 000-0000'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Location" prop="location_name"></ngx-datatable-column>
    <ngx-datatable-column name="Action" prop="id" [sortable]="false" [width]="60" [canAutoResize]="false">
        <ng-template let-id="value" let-row="row" ngx-datatable-cell-template>
            <div ngbDropdown container="body">
                <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                    <i class="ft-more-vertical text-primary"></i>
                </button>
                <div ngbDropdownMenu>
                    <button (click)="onSelect({selected:[row]})" *ngIf="hasPermission(Permissions.MODIFY)" class="w-100" ngbDropdownItem>
                        <i class="ft-edit mr-1"></i> Edit
                    </button>
                    <button (click)="deleteRow(id)" *ngIf="hasPermission(Permissions.DELETE)" class="w-100 bg-light-danger" ngbDropdownItem>
                        <i class="ft-trash-2 mr-1"></i> Delete
                    </button>
                </div>
            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<ng-template #FormModal let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title">{{bindingModel ? 'Edit Contact' : 'New Contact'}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-0">
        <distributor-contact-form [distributorId]="distributorId" [bindingModel]="bindingModel" (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()">
        </distributor-contact-form>
    </div>
</ng-template>