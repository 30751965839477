import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { SubOrg } from '../models/suborg.models';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataFilterRequest, DataFilterResponse, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { R2VSettings } from '../models/ring2voicesettings.models';
import { StaticFile } from 'src/app/core/models/files.models';
import { QbooksSettings } from '../models/qbookssettings.model';

@Injectable({
    providedIn: 'root'
})
export class SuborgService extends BaseService {

    constructor(private http: HttpClient) { super() }

    getFiltered(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/suborgs/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<SubOrg[]>>(url, request).pipe(
            catchError(() => of({ count: 0, result: [] }))
        );
    }

    getSuborgImages(suborgId: number) {
        return this.http.get<StaticFile[]>(`${this.baseURL}/suborgs/${suborgId}/images/`);
    }

    getSuborgByTenant(tenantid: number) {
        let param: GFilterParam = { colname: "customer", condition: "equalto", value: tenantid, operator: "AND" };
        return this.getFiltered({ filters: [param], sort: null }).pipe(
            map(response => response.result)
        );
    }

    getAll() {
        return this.http.get<SubOrg[]>(`${this.baseURL}/suborgs/`);
    }

    getById(suborgId: number) {
        return this.http.get<SubOrg>(`${this.baseURL}/suborgs/${suborgId}/`);
    }

    create(suborg: SubOrg) {
        return this.http.post<SubOrg>(`${this.baseURL}/suborgs/`, suborg);
    }

    update(suborgId: number, suborg: any) {
        return this.http.put<SubOrg>(`${this.baseURL}/suborgs/${suborgId}/`, suborg);
    }

    patch(suborgId: number, request: any) {
        return this.http.patch(`${this.baseURL}/suborgs/${suborgId}/`, request);
    }

    getRoleSuborgs(roleid: number) {
        return this.http.get<SubOrg[]>(`${this.baseURL}/role/${roleid}/suborgs/`)
    }

    getR2VSettingsBySuborgId(suborg_id: number) {
        return this.http.get<{message:string,data:R2VSettings}>(`${this.baseURL}/ring2voice_settings/suborg/${suborg_id}/`)
    }

    updateRing2VoiceSettings(setngsId: any, data: any) {
        return this.http.put<R2VSettings>(`${this.baseURL}/ring2voice_settings/${setngsId}/`, data);
    }

    deleteRing2voiceSettings(id:number) {
      return this.http.delete(`${this.baseURL}/ring2voice_settings/${id}/`);
    }

    addRing2VoiceSettings(data: R2VSettings) {
        return this.http.post<R2VSettings>(`${this.baseURL}/ring2voice_settings/`, data)
    }

    syncCalls(suborg_id: number, days: number) {
        return this.http.post<{ status: boolean }>(`${this.baseURL}/calls/sync/`, {
          "suborg_id": suborg_id,
          "days": days
        });
    }

    syncPhonenumbers(suborg_id: number) {
        return this.http.get<{status:boolean}>(`${this.baseURL}/did/sync/${suborg_id}/`)
    }

    syncForwardNumbers(suborgId: any) {
        return this.http.get<{status:boolean}>(`${this.baseURL}/forwardbins/sync/${suborgId}/`)
    }

    syncMessage(suborg_id: any, days: number) {
        return this.http.post(`${this.baseURL}/messages/sync/`, {
            "suborg_id": suborg_id,
            "days": days
        })
    }

    syncSIP(suborg_id: any) {
        return this.http.get(`${this.baseURL}/sip/sync/${suborg_id}/`);
    }

    getQbooksSettingsBySuborgId(suborg_id:number){
        return this.http.get<{message:string,quickbook_cred:QbooksSettings}>(`${this.baseURL}/quickbook/settings/${suborg_id}/`);
    }

    saveQbooksSettings(suborg_id:number,payload:QbooksSettings){
        return this.http.post<{message:string,quickbook_cred:QbooksSettings}>(`${this.baseURL}/quickbook/settings/${suborg_id}/`,payload);
    }

    syncQbooksSettings(sync_type: string,suborg_id:number) {
        let payload = {'sync_type' : sync_type};
        const params = new HttpParams().set('sync_type', sync_type);
        return this.http.post<{ status: boolean }>(`${this.baseURL}/quickbook/bulksync/${suborg_id}/`, payload, {
            params: params
        });
    }

    sendQbooksVerificationCode(suborg_id:number){
        return this.http.get(`${this.baseURL}/quickbook/${suborg_id}/send_verification_code/`);
    }

    verifyQbooksVerificationCode(suborg_id:number,otp:string){
        return this.http.post<{quickbook_cred:QbooksSettings}>(`${this.baseURL}/quickbook/${suborg_id}/verify_code/`,{otp: otp});
    }

    connectToQbooks(suborg_id:number){

        return this.http.get(`${this.baseURL}/quickbook/get_auth_url/${suborg_id}/`);
    }
    
}
