import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CallLog } from '../../models/reports.models';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import * as popup from 'src/app/core/utils/popup.functions';
import { BlockListRequest } from '../../models/block-list.model';
import { SubSink } from 'subsink';
import { BlockListService } from '../../services/block-list.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'call-history-view-details',
  templateUrl: './call-history-view-details.component.html',
  styleUrls: ['./call-history-view-details.component.scss']
})
export class CallHistoryViewDetailsComponent implements OnInit {

  @Input() selected:CallLog;
  @Input() childCalls:Observable<CallLog[]>
  @Output() closeView = new EventEmitter();
  @Output() playAudio = new EventEmitter();

  public permission = PermissionConstants;
  public subs: SubSink = new SubSink();
  public pattern = /^[0-9\-]*$/;
  public isSuperAdmin: boolean;
  public user_id: number;
  public suborg_id: number;
  public scrollbarH: boolean = false;
  public tabHeaderHeight: number = 50;

  constructor(private ra: ResourceAccessService,
    private blockListService: BlockListService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,) {

        this.isSuperAdmin = blockListService.isSuperUser;
        this.user_id = blockListService.userId;
        this.suborg_id = this.blockListService.suborgId;
     }

  ngOnInit(): void {
  }

  closeDetailedView(){

    this.closeView.emit();

  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.R2V_BLOCK_LIST, [permission]);
}

addToBlockList(): void {
  let selected = this.selected
  let payload = new BlockListRequest();
  payload.number = selected.call_from;
  payload.created_user = this.user_id;

  let opt = {
      title: `Are you sure you want to ${selected.blocked_status ? 'Block From Number' : 'Unblock From Number'}?`,
      text: '',
      ButtonText: "Yes",
  }
  popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
          if (selected.blocked_status) {
              this.subs.sink = this.blockListService.create(payload).subscribe(
                  resp => {
                      if (resp.status) {
                          this.selected.blocked_id = resp.id;
                          this.toastr.success("Number Blocked successfully");
                          this.cdr.markForCheck();
                      }else if(resp.Message){
                          selected.blocked_status = !selected.blocked_status
                          this.cdr.markForCheck();
                          this.toastr.error(resp.Message);
                      }
                  },
                  () => {
                      this.toastr.error('Failed to change status');
                  });
          }
      } else {
          selected.blocked_status = !selected.blocked_status
          this.cdr.markForCheck();
      }
  });
}

unblockNumber() {
  let opt = {
      title: `Are you sure you want to Unblock?`,
      text: '',
      ButtonText: "Yes",
  }
  popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
          this.blockListService.delete(this.selected.blocked_id).subscribe(
              resp => {
                  if (resp.status) {
                      this.selected.blocked_status = false;
                      this.toastr.success(`Number Unblocked Successfully`);
                      this.cdr.markForCheck();
                  }
              },
              error => {
                  this.toastr.error("Failed to Unblock Phone Number")
              }
          )
      };
  });
}

audioPlayerPlay(audioElement:HTMLAudioElement){
  this.playAudio.emit(audioElement);
}

hasBinViewInfoPermission() {
  return this.ra.hasPermission(
      ResourceConstants.R2V_FORWARD_NUMBERS,
      [PermissionConstants.VIEW_INFO],
      ResourceConstants.R2V_MODULE
  );
}

}
