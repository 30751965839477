import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CatalogueCatagoryResponse } from 'src/app/modules/inventory/models/catalogue.models';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { CatalogueService } from 'src/app/modules/inventory/services/catalogue-service.service';

export class CatalogueCatagoryResponseFlat{

  public id:number;

  public level:number;
  public expandable:boolean;

  public customer:number;

  public suborg:number;

  public created_on:string;

  public modified_on:string;

  public created_user:number;

  public modified_user:number;

  public is_delete:boolean;

  public is_active:boolean;

  public is_imported:boolean;

  public source:string;

  public category_name:string;

  public category_prefix:string;

  public description:string;

  public parent:number;

  public additional_fields:string;
  
  public start_range:string;

  public end_range:number;

  public digits:number;

  public create_partnumber:boolean;

  public show_on_catelog:boolean;

  public child_record:CatalogueCatagoryResponse[];


}


export class CategoryNode {
  children: CategoryNode[];
  item: string;
}

export class CategoryFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

const TREE_DATA={};


/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<CatalogueCatagoryResponse[]>([]);

  get data(): CatalogueCatagoryResponse[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.


    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): CatalogueCatagoryResponse[] {

    return Object.keys(obj).reduce<CatalogueCatagoryResponse[]>((accumulator, key) => {
      const value = obj[key];


    
      const node = new CatalogueCatagoryResponse();
      node.category_name = value.category_name;
      node.id= value.id;

      

   

       if (value != null) {
         if (typeof value === 'object' && value.child_record?.length>0) {
     
            node.child_record = this.buildFileTree(value.child_record, level + 1);
            
      //   } else {
      //     node.category_name = value;
         }
       }
      //console.log(node);

      return accumulator.concat(node);
    }, []);
  }
}
@Component({
  selector: 'app-catalog-product-select-category',
  templateUrl: './catalog-product-select-category.component.html',
  styleUrls: ['./catalog-product-select-category.component.scss'],
  providers: [ChecklistDatabase],
})
export class CatalogProductSelectCategoryComponent implements OnInit {

  public catalogCatagories:CatalogueCatagoryResponse[];

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<CatalogueCatagoryResponseFlat, CatalogueCatagoryResponse>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<CatalogueCatagoryResponse, CatalogueCatagoryResponseFlat>();

  /** A selected parent node to be inserted */
  selectedParent: CatalogueCatagoryResponseFlat | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<CatalogueCatagoryResponseFlat>;

  treeFlattener: MatTreeFlattener<CatalogueCatagoryResponse, CatalogueCatagoryResponseFlat>;

  dataSource: MatTreeFlatDataSource<CatalogueCatagoryResponse, CatalogueCatagoryResponseFlat>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<CatalogueCatagoryResponseFlat>(true /* multiple */);

  @Output() onChangeCatogorySelection = new EventEmitter<any>();

  @Input() selectedCategories:any = []
  @Input() selectedSectionIndex:number

  constructor(private _database: ChecklistDatabase, private catalogueService:CatalogueService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<CatalogueCatagoryResponseFlat>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngOnInit(){

    this.getCategories();
    // this.checklistSelection.selected = this.selectedCategories

  }


  getCategories(){
    this.catalogueService.getCatalogueCategory().subscribe(response=>{
      this.catalogCatagories=response;


      // this.catalogCatagories = [{id:1,category_name:'main_catagory1',
      //                        child_record:[{id:11,category_name:'sub_catogory1',child_record:null},
      //                                     {id:12,category_name:'sub_catogory2',child_record:null},
      //                                     {id:13,category_name:'sub_catogory3',child_record:[
      //                                       {id:13,category_name:'sub_catogory4',child_record:null}
      //                                     ]}]},

      //                                     {id:2,category_name:'main_catagory2',
      //                                     child_record:[{id:21,category_name:'sub_catogory1',child_record:null},
      //                                                  {id:22,category_name:'sub_catogory2',child_record:null}]}]


      const data = this._database.buildFileTree(this.catalogCatagories, 0);



      console.log('treedata',data)

     // data.forEach(x=>{
          // this.selectedCategories.forEach(y => {

          //   if(x.id == y.id){
         //     this.checklistSelection.select(x)
           // }
        
        // this.checklistSelection.select(x)
     // });

     // })

      // this.selectedCategories.forEach(x => {
        
      //   this.checklistSelection.select(x)
      // });
      // Notify the change.
      this._database.dataChange.next(data);

      console.log('treeControl',this.treeControl)

      this.treeControl.dataNodes.forEach(x1=>{

        if(this.selectedCategories[this.selectedSectionIndex])
        {

          this.selectedCategories[this.selectedSectionIndex].forEach(x2 => {

            if(x1.id == x2.id)
          
            this.checklistSelection.select(x1)
          });
        }


       
      })


    });
  }

  getLevel = (node: CatalogueCatagoryResponseFlat) => node.level;

  isExpandable = (node: CatalogueCatagoryResponseFlat) => node.expandable;

  getChildren = (node: CatalogueCatagoryResponse): CatalogueCatagoryResponse[] => node.child_record;

  hasChild = (_: number, _nodeData: CatalogueCatagoryResponseFlat) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: CatalogueCatagoryResponseFlat) => _nodeData.category_name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: CatalogueCatagoryResponse, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.category_name === node.category_name ? existingNode : new CatalogueCatagoryResponseFlat();
    flatNode.category_name = node.category_name;
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.expandable = !!node.child_record?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: CatalogueCatagoryResponseFlat): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: CatalogueCatagoryResponseFlat): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: CatalogueCatagoryResponseFlat): void {

    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.onChangeCatogorySelection.emit(this.checklistSelection.selected)
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: CatalogueCatagoryResponseFlat): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.onChangeCatogorySelection.emit(this.checklistSelection.selected)
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: CatalogueCatagoryResponseFlat): void {
    let parent: CatalogueCatagoryResponseFlat | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: CatalogueCatagoryResponseFlat): void {

    console.log('node',node)
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }

  }

  /* Get the parent node of a node */
  getParentNode(node: CatalogueCatagoryResponseFlat): CatalogueCatagoryResponseFlat | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
