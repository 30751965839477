<section class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
    <div class="d-flex flex-row flex-1 h-100">
        <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
            <div class="d-flex align-items-center flex-space-bw" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                <div class="col-xs-12">
                    <h4 class="card-title title-common m-0">Warehouses</h4>
                </div>
                <div class="d-flex flex-row col-xs-12">
                    <button *ngIf="UIPermissions.create" (click)="openWareHouseModal(wareHouseModal)" class="btn btn-sm btn-text-center gradient-pomegranate mr-1" disableIfAllsuborg>
                            <i class="ft-plus mr-1"></i> New
                        </button>
                    <input #searchInput *ngIf="expanded" class="form-control form-control-sm width-200" type="text" placeholder="Search warehouse..." />
                </div>
            </div>
            <div class="card-content">
                <div [ngClass]="{'card-body':expanded}">
                    <ngx-datatable #dataTable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" (select)="onSelect($event)" [sorts]="[{prop:'warehouse_name',dir:'asc'}]">
                        <ngx-datatable-column name="Name" prop="warehouse_name"></ngx-datatable-column>
                        <ngx-datatable-column name="Description" prop="description" [sortable]="false"></ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded" name="Created Date" prop="created_on">
                            <ng-template let-crdate="value" ngx-datatable-cell-template>
                                {{crdate | date:'MM/dd/YYYY'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row.is_quickbook? 'Quickbooks Admin' : row.created_by}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded && !isAllSuborg" name="Action" prop="id" [sortable]="false">
                            <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                <div ngbDropdown container="body">
                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                        <i class="ft-more-vertical text-primary"></i>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <button class="w-100" *ngIf="UIPermissions.edit" (click)="openWareHouseModal(wareHouseModal,id)" ngbDropdownItem>
                                            <i class="ft-edit mr-1"></i> Edit
                                        </button>
                                        <button class="w-100 bg-light-danger" *ngIf="UIPermissions.delete" (click)="deleteWarehouse(id)" ngbDropdownItem>
                                            <i class="ft-trash-2 mr-1"></i> Delete
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                                <div style="padding: 0 .7rem;">
                                    <span>{{rowCount}} Total</span>
                                </div>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
        <div class="parellax-detail" *ngIf="!expanded && selected">
            <div class="parellax-detail-content">
                <div class="warehouse-details">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">{{selected?.warehouse_name}}</h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a *ngIf="UIPermissions.edit && !isAllSuborg" (click)="openWareHouseModal(wareHouseModal,selected.id)" class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button *ngIf="UIPermissions.delete && !isAllSuborg" class="btn btn-sm bg-light-danger ml-1" (click)="deleteWarehouse(selected.id)">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="group mt-2">
                        <div class="group-header">
                            Overview
                        </div>
                        <div class="group-body">
                            <div class="ov-row" *ngIf="selected?.created_on">
                                <label class="col-md-3 p-0 item-label">Created On</label>
                                <label class="item-label-value">{{selected.created_on | date:'MM/dd/YYYY'}}</label>
                            </div>
                            <div class="ov-row" *ngIf="selected">
                                <label class="col-md-3 p-0 item-label">Created By</label>
                                <label class="item-label-value">{{selected.is_quickbook?'Quickbooks Admin':selected.created_by}}</label>
                            </div>
                            <div class="ov-row" *ngIf="selected?.description">
                                <label class="col-md-3 p-0 item-label">Description</label>
                                <label class="item-label-value">{{selected.description}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="group mt-3">
                        <div class="group-header d-flex flex-row">
                            Locations
                            <input #locationSearch class="form-control form-control-sm width-200 d-none" type="text" placeholder="Search location..." />
                            <button (click)="openLocationModal(locationModal)" class="btn btn-sm btn-secondary ml-auto" disableIfAllsuborg>
                                Add Location
                            </button>
                        </div>
                        <div class="group-body group-body-bordered">
                            <ngx-datatable #locationTable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH" [rows]="locations" [headerHeight]="40" rowHeight="auto" [columnMode]="'force'" [selectionType]="SelectionType.single" [sorts]="[{prop:'location',dir:'asc'}]" (select)="openLocationModal(locationModal,$event.selected[0].id)">
                                <ngx-datatable-column name="Name" prop="location"></ngx-datatable-column>
                                <ngx-datatable-column name="Description" prop="description"></ngx-datatable-column>
                                <ngx-datatable-column name="Created Date" prop="created_on">
                                    <ng-template let-crdate="value" ngx-datatable-cell-template>
                                        {{crdate | date:'MM/dd/YYYY'}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Created By" prop="created_by"></ngx-datatable-column>
                                <ngx-datatable-column *ngIf="!isAllSuborg" name="Action" prop="id" [sortable]="false">
                                    <ng-template let-id="value" ngx-datatable-cell-template>
                                        <div ngbDropdown container="body">
                                            <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                <i class="ft-more-vertical text-primary"></i>
                                            </button>
                                            <div ngbDropdownMenu>
                                                <button (click)="openLocationModal(locationModal,id)" class="w-100" ngbDropdownItem>
                                                    <i class="ft-edit mr-1"></i> Edit
                                                </button>
                                                <button (click)="deleteWarehouseLocation(id)" class="w-100 bg-light-danger" ngbDropdownItem>
                                                    <i class="ft-trash-2 mr-1"></i> Delete
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                        <div class="page-count">
                                            {{this.locationPage.pageNumber}}/{{rowCount}}
                                        </div>
                                        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                            (change)="locationTable.onFooterPage($event)">
                                        </datatable-pager>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #wareHouseModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{wareHouseId > 0 ? 'Edit Warehouse' : 'New Warehouse'}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()">
                <div class="form-group">
                    <label class="val-required">Name</label>
                    <input type="text" [(ngModel)]="model.warehouse_name" #sn="ngModel" name="name" class="form-control" required>
                    <small class="form-text text-muted danger" *ngIf="sn.invalid && (sn.dirty || sn.touched)">
                      <div *ngIf="sn.errors.required">
                            Name is required.
                      </div>
                    </small>
                    <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
                </div>
                <div class="form-group">
                    <label>Description</label>
                    <textarea type="text" [(ngModel)]="model.description" name="description" class="form-control"></textarea>
                </div>
                <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                    <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                      {{wareHouseId > 0 ? 'Save Changes' : 'Save'}}
                    </button>
                    <button type="button" (click)="c('cancel clicked')" class="btn btn-secondary">Cancel</button>
                </div>
            </form>
        </div>
    </ng-template>

    <ng-template #locationModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{locationId > 0 ? 'Edit Location' : 'New Location'}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && onLocationSubmit()">
                <div class="form-group">
                    <label class="val-required">Name</label>
                    <input type="text" [(ngModel)]="locationModel.location" #sn="ngModel" name="location" class="form-control" required>
                    <small class="form-text text-muted danger" *ngIf="sn.invalid && (sn.dirty || sn.touched)">
                      <div *ngIf="sn.errors.required">
                            Name is required.
                      </div>
                    </small>
                </div>
                <div class="form-group">
                    <label>Description</label>
                    <textarea type="text" [(ngModel)]="locationModel.description" name="description" class="form-control"></textarea>
                </div>
                <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                    <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                      {{locationId > 0 ? 'Save Changes' : 'Save'}}
                    </button>
                    <button type="button" (click)="c('cancel clicked')" class="btn btn-secondary">Cancel</button>
                </div>
            </form>
        </div>
    </ng-template>
</section>
