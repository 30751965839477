<form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && submit()" novalidate>
    <div class="row">
        <div class="d-flex flex-row col-12">
            <div class="col-6">
                <div class="form-group">
                    <label class="val-required">Name</label>
                    <input type="text" [(ngModel)]="model.name" #name="ngModel" name="name" class="form-control" required>
                    <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                      <div *ngIf="name.errors.required">
                          Name is required.
                      </div>
                  </small>
                </div>
                <div class="form-group">
                    <label>Location Type</label>
                    <location-type-select [fetchWhenOpen]="true" [(value)]="model.location_types" (change)="locationTypeChange($event)"></location-type-select>
                </div>
                <div class="form-group">
                    <label>Location System Type</label>
                    <location-system-type-select [fetchWhenOpen]="true" [(value)]="model.location_system_types" (change)="locationSystemTypeChange($event)"></location-system-type-select>
                </div>
                <div class="form-group">
                    <label>Address</label>
                    <textarea type="text" [(ngModel)]="model.address" #add="ngModel" name="address" class="form-control"></textarea>
                </div>
                <div class="form-group">
                    <label>State</label>
                    <input type="text" [(ngModel)]="model.state" name="state" class="form-control">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>City</label>
                    <input type="text" [(ngModel)]="model.city" name="city" class="form-control">
                </div>
                <div class="form-group">
                    <label>Zip</label>
                    <input type="text" [(ngModel)]="model.zip" name="zip" class="form-control">
                </div>
                <div class="form-group">
                    <label>Account Number</label>
                    <input type="text" [(ngModel)]="model.account_number" name="account_number" class="form-control">
                </div>
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [(ngModel)]="model.is_billing" name="is_billing" id="billing">
                        <label class="custom-control-label" for="billing">
                          <span>Billing Location</span>
                      </label>
                    </div>
                </div>
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [(ngModel)]="model.is_shipping" name="is_shipping" id="shipping">
                        <label class="custom-control-label" for="shipping">
                          <span>Shipping Location</span>
                      </label>
                    </div>
                </div>
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [(ngModel)]="model.service_ticket_billable" name="billable" id="st-billable">
                        <label class="custom-control-label" for="st-billable">
                          <span>Service Ticket Billable</span>
                      </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
              {{bindingModel ? 'Save Changes' : 'Add Location'}}
          </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </div>
</form>