<div class="form-container">
    <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()">
        <div class="form-group">
            <label class="val-required">Name</label>
            <input type="text" [(ngModel)]="model.name" #name="ngModel" name="name" class="form-control" required>
            <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
              <div *ngIf="name.errors.required">
                Name is required.
              </div>
            </small>
            <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea type="text" [(ngModel)]="model.description" name="description" class="form-control"></textarea>
        </div>
        <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
              {{locationSystemTypeId > 0 ? 'Save Changes' : 'Save'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</div>