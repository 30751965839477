<section class="pay-now vh-100">
<div class="d-flex justify-content-center align-items-center success-payment p-3">
    <div>
        <div class="mb-4 text-center">
            <img class="m-1"  width="60" height="60" src="/assets/img/svg/cc/verified.svg" />
        </div>
        <div class="text-center">
            <h1>Payment Done!</h1>
            <p>Thank you for completing your secure online payment. </p>
            <p>Have a great day!</p><br/>
        </div>
        <div class="mb-4 text-center">
            <img class="m-1"  width="60" height="60" src="/assets/img/svg/cc/sf_logo.svg" />
            <p>SimplyFuse</p>
        </div>
    </div>
</div>
</section>