import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { id } from 'src/app/core/utils/global.functions';
export class addSO{
    public customer:any;
    public title:string;
    public unique_number:string;
    public tenant_customer:any;
    public tenant_customer_contact:number;
    public tenant_customer_location:number;
    public terms_and_condition:number;
    public conditions:string="";
    public coverage_type:number=0;
    public status:number;
    public suborg:number;
    public reference_id:string;
    public shipping_date:string
    public payment_terms:number;
    public shipment_method:number;
    public total_price:number;
    public tax:number;
    public shipping_location:number;
    public mail_sent_date :string;
    public from_module:number
    public invoiced_total:number;
    public balance_due:number;
    public subtotal:number;
    public tax_amount:number;

}

export class SOList{
    public created_by:string;
    public created_on:string;
    public created_user:number;
    public customer:number;
    public id:number;
    public is_active:boolean;
    public is_delete:boolean;
    public modified_on:string;
    public modified_user:string;
    public tenant_customer:number;

    public tenant_customer_contact_name:string;
    public tenant_customer_location:string;
    public tenant_customer_location_name:string;
    public tenant_customer_location_city:string;
    public tennant_customer_location_state:string;
    public tenant_customer_location_address:string;
    public tenant_customer_location_zip:string;

    public tenant_customer_name:string;
    public terms_and_condition:string;
    public title:string;
    public unique_number:string;
    public conditions:string;
    public service_contract_status:string;
    public status:number;
    public expired_in_90_days:boolean;
    public coverage_type:number;
    public sc_begins:number=0;
    public warranty:number;
    public price:number;
    public item_price:number=0;
    public duration:number;
    public reference_id:string;
    public shipping_date:string;
    public payment_terms:number;
    public shipment_method:number;
    public total_price:number;
    public payments_terms_name:string;
    public shipment_method_name:string;
    public shipping_location_name:string;
    public shipping_location:string;
    public shipping_location__zip:string;
    public shipping_location_address:string;
    public shipping_location_city:string;
    public shipping_location_state:string;
    public tax:number;
    public suborg_name:string;
    public suborg_address:string;
    public suborg_city:string;
    public suborg_zip:string;
    public suborg_state:string;
    public suborg_primary_image:string;
    public created_user_email:string;
    public tenant_customer_contact_email: string;
    public client_viewed:boolean;
    public mail_sent_date :string;
    public client_viewed_date :string;
    public from_module :ModuleConstants;
    public from_module_name :string;
    public invoiced_total:number;
    public balance_due:number;
    public subtotal:number;
    public tax_amount:number;
    public suborg:number;

}


export class SOItemAdd{
    public sales_order:number;
    public associate_item:number;
    public customer:number;
    public quantity:number;
    public serial_number:number;
    public id:number;
    public billing_interval:number;
    public price:number;
}

export class SOKitsAdd{
    public sales_order:number;
    public associate_kit:number;
    public customer:number;
    public quantity:number;
    public serial_number:number;
    public id:number;
    public billing_interval:number;
    public price:number;
    public starting_date:Date;
    public ending_date:Date;

}


export class SOItemsList{
    public associate_item:number;
    public created_on:Date;
    public created_user:number;
    public customer:number;
    public ending_date:Date;
    public id:number;
    public primary_image:string;
    public is_active:boolean;
    public is_delete:boolean;
    public item_name:string;
    public item_price:number;
    public modified_on:string;
    public modified_user:string;
    public part_number:string;
    public starting_date:Date;
    public sales_order:number;
    public quantity:number;
    public serial_number:string;
    public coverage_type:number;
    public billing_interval:number;
    public price:number;
}

export class SOKitsList{
    public associate_kit:number;
    public created_on:Date;
    public created_user:number;
    public customer:number;
    public ending_date:Date;
    public id:number;
    public internal_number:string;
    public is_active:boolean;
    public is_delete:boolean;
    public kit_name:string;
    public modified_on:string;
    public modified_user:string;
    public starting_date:Date;
    public sales_order:number;
    public quantity:number;
    public serial_number:string;
    public coverage_type:number;
    public billing_interval:number;
    public price:number;
}

export class SODashBoardCounts{
    public New: number=0;
    public Draft: number=0;
    public Send_To_Customers: number=0;
    public Rejected: number=0;
    public Approved:number=0;
    public Partially_Invoiced:number=0;
    public Fully_Invoiced:number=0;
    public Converted_To_WorkOrder:number=0;
}

export enum SOStatus{
    New = <any>1,
    Draft = <any>2,
    Sent = <any>3,
    Rejected = <any>4,
    Approved = <any>5,
    Partially_Invoiced = <any>6,
    Fully_Invoiced = <any>7,
    Converted_To_WorkOrder = <any>8,
}

export enum Status{
    New = 1,
    Draft = 2,
    Sent = 3,
    Rejected = 4,
    Approved = 5,
    Partially_Invoiced = 6,
    Fully_Invoiced = 7,
    Converted_To_WorkOrder = 8,
}


export class SOComments {

    public id: number;

    public sales_order: number;

    public comments: string;

    public parent: number;

    public customer: number;

    public created_by: string;

    public created_on: Date;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public is_delete: boolean;

    public is_active: boolean;

    edit: boolean;

    inputRows: number = 1;
}

export class Section{
    public title:string;
    public rows:ItemRow[]=[];
    public section_total:string;
}

export class ItemAdd{
    public id:number;
    public sales_order:number;
    public customer:number;
    public unique_id:number;
    public quantity:number;
    public section_title:string;
    public price:number;
    public type:string;
    public serial_number:string;
    public is_delete:boolean;
    public name:string;
    public part_number:string;
    public sku:string;
    public uom:string;
    public primary_image:string;
    public description:string;
    suborg: number;
    is_show_in_pdf: any;
    is_billable: boolean;
    order: number;
}


export class ItemRow {
    public id:number;
    public sales_order:number;
    public unique_id:number;
    public created_on:string;
    public created_user:number;
    public modified_on:string;
    public modified_user:string;
    public is_delete:boolean;
    public is_active:boolean;
    public serial_number:string;
    public quantity:number=1;
    public section_title:string;
    public price:number;
    public type:SOItemsType;
    public name:string;
    public customer:number;
    public part_number:string;
    public uom:string;
    public sku:string;
    public expanded:boolean = false;
    public children: ItemRow[];
    public primary_image:string;
    public row_total:string;
    public order:number;
    public level:number;
    public color:string;
    public partnumber:string;
    public image_link:string;
    public description:string;
    public invoiced_quantity:number = 0;
    is_billable: boolean;
    is_show_in_pdf: any;
    showErrorMessage: boolean;
    previous_quantity: number;
    service_ticket_quantity:number = 0;
    service_ticket_invoiced_quantity:number = 0;
}



export class SOItemRow {

    public id: number;

    public item_id: number;

    public itemType: SOItemsType;

    public quantity: number = 1;

    public kit_id: number;

    public part_number: string;

    public sell_price: number = 0;

    public sell_price_calc: number = 0;

    public primary_image: string;

    public item_name: string;

    public guid: string;

    public customer: number;

    public children: SOItemRow[];

    public expanded:boolean = false;

    public order:number;

    public serial_number:string;

    public coverage_type:number;

    public billing_interval:number;

    public price:number;


    constructor(){
        this.guid = id();
    }
}

export enum SOItemsType {
    ITEM = 'item',
    KIT = 'kit'
}

export class SOPdf{
    public item: SOList;
    public sections: Section[];
    public total_price: string;
    public subtotal: string;
    public balance_due: string;
    public tax_amount: string;
}


