import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { LocationSystemType, LocationType } from '../models/location-types';

@Injectable({
  providedIn: 'root'
})
export class LocationTypeService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll(request: DataFilterRequest, offset: number = 0, suborg_id: number = this.suborgId) {
    let url = `${this.baseURL}/filter_location_types/?limit=${this.dataLimit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<LocationType[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<LocationType>(`${this.baseURL}/location_types/${id}/`);
  }

  create(model: LocationType) {
    return this.http.post<LocationType>(`${this.baseURL}/location_types/`, model);
  }

  update(id: number, model: LocationType) {
    return this.http.put<LocationType>(`${this.baseURL}/location_types/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<LocationType>(`${this.baseURL}/location_types/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/location_types/${id}/`);
  }

  getSystemTypes(request: DataFilterRequest, offset: number = 0, suborg_id: number = this.suborgId) {
    let url = `${this.baseURL}/filter_location_system_types/?limit=${this.dataLimit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<LocationSystemType[]>>(url, request);
  }

  getSystemTypeById(id: number) {
    return this.http.get<LocationSystemType>(`${this.baseURL}/location_system_types/${id}/`);
  }

  createSystemType(model: LocationSystemType) {
    return this.http.post<LocationSystemType>(`${this.baseURL}/location_system_types/`, model);
  }

  updateSystemType(id: number, model: LocationSystemType) {
    return this.http.put<LocationSystemType>(`${this.baseURL}/location_system_types/${id}/`, model);
  }

  patchSystemType(id: number, req: any) {
    return this.http.patch<LocationSystemType>(`${this.baseURL}/location_system_types/${id}/`, req);
  }

  deleteSystemType(id: number) {
    return this.http.delete(`${this.baseURL}/location_system_types/${id}/`);
  }
}
