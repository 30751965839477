import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { id } from '../utils/global.functions';
import { WHBaseModel } from './common.models';

export interface GFColumn {

  displayName: string;

  columnName: string;

  columnType: GFColumnTypes;

  resource?: ResourceConstants;

  gridColumn?: string;

  options?: { displayName: string, value: any }[];

  optionValueType?: GFColumnTypes;

  dependentColumn?:string;

  dependentValue?:any;
}

export interface GFCondition {

  displayName: string;

  condition: string;

  allowedTypes: string[];
}

export enum GFColumnTypes {
  string = 'string',
  boolean = 'boolean',
  number = 'number',
  json = 'json',
  ref = "ref",
  date = "date",
  select = 'select',
  phone = 'phone',
  date_range = 'date_range'
}

export class GFilterRow {

  id: string = id();

  column: GFColumn;

  condition: GFCondition;

  operator: string;

  value: any;

  allowedConditions: GFCondition[];

  options?: { displayName: string, value: any }[];

  dependentValue?:any;
}

export class GFilterGroup{

  public conditions?:GFilterParam[];

  public operator?:string;
  
}


export class GFilterParam {

  public displayName?: string;

  public colname: string;

  public condition: string;

  public operator?: string;

  public value: any;

  public resource?: ResourceConstants;

  public gridColumn?: string;

  public children?: GFilterParam[];

  public custom?: boolean;

  public customDataId?: number;

  public type?: GFColumnTypes;

  public default?:boolean;
}

export class GSortParam {

  public colname: string;

  public direction: string;
}

export class DataFilter extends WHBaseModel{

  public id: number;

  public name: string;

  public condition: any;

  public module: number;

  public default:boolean;
}

export interface DataFilterRequest {

  parent_filters?: GFilterParam[];

  base_filters?: GFilterParam[];

  filters: GFilterParam[];

  sort: GSortParam[];
}

export interface DataFilterGroupRequest {

  base_filters?: GFilterParam[];

  filters: GFilterGroup[];

  sort: GSortParam[];
}

export interface DataFilterResponse<T> {

  total_count? : number;

  count: number;

  result: T;
}

export interface IPagingView {

  page: Pagination;

  sort: GSortParam[];

  sortLookup: any;
}

export interface Pagination {

  count: number;

  limit: number;

  offset: number;

  pageNumber: string;
}

export class GridTab{

  name:string;

  id:string;

  selected:boolean;

  icon?:string;

  iconWidth?:number;

  iconHeight?:number;

  textColor?:string='';

  textHoverColor?:string='';


}
