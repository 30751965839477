<div class="date-range-picker">
    <input type="text" class="form-control form-control-sm" matInput
        placeholder="Choose date range"
        ngxDaterangepickerMd
        [locale]="{format: 'MM/DD/YYYY'}"
        startKey="start"
        endKey="end"
        [autoApply]="true"
        opens="left"
        [alwaysShowCalendars]="range"
        [keepCalendarOpeningWithRange]="range"
        [ranges]="range ? ranges : null"
        [(ngModel)]="selected"
        name="daterange"/>
</div>
