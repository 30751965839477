import { Injectable } from '@angular/core';
import { AccountingNewPayment } from '../models/accounting-model';
import { HttpClient } from '@angular/common/http';
import { PaymentAdd, PaymentRequest } from '../../payments/models/payments-model';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingService extends BaseService {

  model:AccountingNewPayment;

  constructor(private http: HttpClient) {super() }

  setNewPaymentReqModel(model:AccountingNewPayment){

    this.model = model;
  
  }

  getNewPaymentReqModel(){

    return this.model;
  
  }

  accountingMakePayment(paymentReqModel:PaymentRequest|PaymentAdd, model:AccountingNewPayment, customer_id:string) {
    console.log(paymentReqModel,'paymentReqModel+*')
    console.log(model,'model+*')
    let payLoad = {...paymentReqModel, ...model};
    console.log(payLoad,'payLoad+*')
    return this.http.post<any>(`${this.baseURL}/sales_app/payment_received/customer/${customer_id}/`, payLoad);
  }

 
}


