export class QbooksSettings{

    id: number;

    suborg: number;

    refresh_token: string;

    access_token: string;

    client_id: string;

    client_secret: string;

    is_two_way_sync: boolean = false;

    realm_id: string;

    redirect_uri: string;

    environment: string = 'sandbox';

    last_sync_date: string;

    last_quickbook_sync_date: string;

}