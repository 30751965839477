import { SubSink } from 'subsink';
import { UnitsService } from './../../../services/units.service';
import { UnitType } from './../../../models/items';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-unit-form',
    templateUrl: './unit-form.component.html',
    styleUrls: ['./unit-form.component.scss']
})
export class UnitFormComponent implements OnInit, OnDestroy {

    public model: UnitType;

    @Input() unitId: number = 0;

    @Output() onSuccess = new EventEmitter<{ response: UnitType, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    public nameExist: boolean = false;

    private subs: SubSink = new SubSink();

    constructor(private service: UnitsService, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.unitId > 0) {
            this.subs.sink = this.service.getById(this.unitId).subscribe(unitType => {
                this.model = unitType;
                this.cdr.markForCheck();
            });
        }
        else {
            this.model = this.service.createModel(UnitType);
        }
    }

    onSubmit(): void {

        let api: Observable<UnitType>;

        if (this.unitId > 0) {
            api = this.service.update(this.unitId, this.model);
        }
        else {
            api = this.service.create(this.model);
        }

        this.subs.sink = api.subscribe(
            resp => {
                let msg = this.unitId > 0 ? 'Unit Type info updated' : 'New Unit Type created';
                this.nameExist = false;
                this.onSuccess?.emit({ response: resp, message: msg });
            },
            error => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.onError?.emit(error);
                }
            }
        );
    }

    onCancelClicked(): void {
        this.onCancel?.emit();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
