import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { DataFilterRequest, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { DIDNumber } from '../../models/dids.models';
import { DidsService } from '../../services/dids.service';

@Component({
    selector: 'did-select',
    templateUrl: './did-select.component.html',
    styleUrls: ['./did-select.component.scss']
})
export class DidSelectComponent implements OnInit {

    private innerValue: string;

    @Output() isShowOverlayAlert =  new EventEmitter<any>();

    @Output() isOpen = new EventEmitter<boolean>()

    @Input() dialerView:boolean = false;

    @Input() set value(val: string) {

        this.innerValue = val;

        if (val !== this.selected) {
            this.innerSelected = val;
           // this.getPhoneNumber(this.selected);
        }
    }

    get value() { return this.innerValue; }

    @Input() readonly: boolean = false;

    @Input() isMultiple: boolean = false;

    @Output() valueChange = new EventEmitter<string>();

    @Input() bindValue:string = 'sid';

    @Input() filterValue:GFilterParam[] = [];

    private suborgid: number;

    get suborgId() { return this.suborgid; }

    @Input() set suborgId(val: number) {
        this.suborgid = val;
    }

    private innerSelected: string;

    public get selected(): string { return this.innerSelected; }

    public set selected(value: string) {
        this.innerSelected = value;
        this.valueChange?.emit(value);
    }

    public options: Observable<DIDNumber[]>;

    public textInput = new Subject<string>();

    constructor(private service: DidsService) {
        if (!this.service.isSuperUser && this.service.suborgId !== -1) {
            this.suborgId = this.service.suborgId;
        }
    }

    ngOnInit(): void {}

    initialize(selectedValue: Observable<any> = of([])) {
        this.options = concat(
            selectedValue,
            this.getData(),
            this.textInput.pipe(
                filter(res => res !== null && res.length >= 2),
                distinctUntilChanged(),
                switchMap(term => this.getData(term))
            )
        );
        this.options.subscribe(result => { console.log(result.length,'didcomp'); this.isShowOverlayAlert.emit(result.length)})
    }

    getData(search?: string) {

        let option: DataFilterRequest = {
            sort: [{ colname: 'name', direction: 'asc' }],
            filters: [],
            base_filters: [
                { colname: 'suborg', condition: 'equalto', value: this.suborgId, operator: 'AND' },
                { colname: 'is_delete', condition: 'equalto', value: false, operator: 'AND'}
            ]
        };
        
        if(this.filterValue.length!=0){ option.base_filters = option.base_filters.concat(this.filterValue); }
        
        if (search) {
            option.filters.push(...[
                { colname: 'did', condition: 'contains', value: search, operator: 'AND' },
                { colname: 'name', condition: 'contains', value: search, operator: 'OR' }
            ]);
        }
        return this.service.getAll(option).pipe(
            catchError(err => of({ count: 0, result: [] })),
            map(resp => resp.result)
        );
    }

    trackByFn(item: DIDNumber) { return item.sid; }

    selectOpen() {
        if (!this.options) {
            this.initialize();
        }
        this.isOpen.emit(true)
    }

    selectClose()
    {
        this.isOpen.emit(false)
    }

    getPhoneNumber(val: string) {
        let option: DataFilterRequest = {
            sort: [{ colname: 'name', direction: 'asc' }],
            filters: [{ colname: this.bindValue, condition: 'equalto', value: val, operator: 'AND' }]
        };
        this.initialize(this.service.getAll(option).pipe(map(resp => resp.result)));
    }

    onClear() {
        this.initialize();
    }
}
