<section class="common-grid-view invoice">
    <div class="list-parellax pt-2"
        [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div
                [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                    [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">

                    <datagrid-filter #dataGridFilter
                        [defaultFilters]="defaults"
                        [searchFilters]="searchFilters"
                        [columns]="columns"
                        [newButton]="true"
                        [newButtonUrl]="['accountings/new/payment']"
                        [newButtonEnabled]="true"
                        [expanded]="expanded"
                        (apply)="applyFilter($event)">

                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable [rows]="rows"
                            class="bootstrap core-bootstrap"
                            [columnMode]="'force'"
                            [headerHeight]="50"
                            [footerHeight]="50"
                            rowHeight="auto"
                            [scrollbarH]="scrollbarH"
                            [selectionType]="SelectionType.single"
                            [count]="page.count"
                            [externalPaging]="true"
                            [offset]="page.offset"
                            [externalSorting]="true"
                            [limit]="page.limit"
                            (select)="onSelect($event)"
                            (page)="setPage($event)"
                            (sort)="onSort($event)"
                            [sorts]="[{prop:'name',dir:'asc'}]">

                            <ngx-datatable-row-detail [rowHeight]="75"
                                #myDetailRow>
                                <ng-template let-row="row" let-value="value"
                                    ngx-datatable-row-detail-template>
                                    <div class="row row-detail">
                                        <div
                                            class="customer-contact-details row-item"
                                            [ngClass]="expanded ? 'col-md-2' : 'col-md-6'">
                                            <div class="customer-name">
                                                <div
                                                    class="badge badge-warning name-badge w-100">{{row.tenant_customer_name}}</div>
                                            </div>
                                            <div
                                                *ngIf="row.tenant_customer_contact_name"
                                                class="contact">
                                                <img
                                                    src="../../../../../../assets/img/gallery/user-avatar.jpg"
                                                    class="rounded-pill img-view"
                                                    alt="Cinque Terre">
                                                <span
                                                    class="contact-name">{{row.tenant_customer_contact_name}}</span>
                                            </div>
                                        </div>
                                        <div
                                            class="customer-location-details row-item ml-2"
                                            [ngClass]="expanded ? 'col-md-2' : 'col-md-4'">
                                            <div class="div">
                                                <label for
                                                    class="item-head">Reference
                                                    Id:</label>
                                            </div>
                                            <div class="location">
                                                <span>{{row?.reference_id}}</span>

                                            </div>
                                        </div>
                                      <div class="col-md-3 row-item"
                                            *ngIf="expanded">
                                            <div class="div">
                                                <label for
                                                    class="item-head">Paid
                                                    By:</label>
                                            </div>
                                            <div class="location">
                                                <span>{{row?.paid_by_user}}</span>

                                            </div>
                                        </div>
                                        <div class="col-md-2 row-item"
                                            *ngIf="expanded">
                                            <div class="div">
                                                <div class="doc-row d-flex">
                                                    <div class="info-box"
                                                        [ngSwitch]="row.payment_method">
                                                        <div
                                                            *ngSwitchCase="paymentMethods.CARD">
                                                            <label>Card No
                                                                :</label>
                                                            <p>{{row.masked_card_number}}</p>
                                                        </div>

                                                        <div
                                                            *ngSwitchCase="paymentMethods.ACH">
                                                            <label>Account No
                                                                :</label>
                                                            <p>{{row.masked_account_number}}</p>
                                                        </div>

                                                        <div
                                                            *ngSwitchCase="paymentMethods.CHECK">
                                                            <label>Check No
                                                                :</label>
                                                            <p>{{row.check_number}}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 row-item"
                                            *ngIf="expanded">
                                            <div>
                                                <label
                                                    class="item-head">Suborg</label>
                                            </div>
                                            <div>
                                                <div>{{row.suborg_name}}</div>
                                            </div>
                                        </div>

                                    </div>
                                </ng-template>
                            </ngx-datatable-row-detail>
                            <ngx-datatable-column name="Date" prop="created_on"
                                [minWidth]="150">
                                <ng-template let-row="row"
                                    ngx-datatable-cell-template>
                                    <div>{{row.created_on |
                                        date:'MM/dd/YYYY h:mm a'}}</div>
                                    <div>{{row.unique_id}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Payment Date" prop="payment_date"
                            [minWidth]="150" [sortable]="false">
                            <ng-template let-row="row"
                                ngx-datatable-cell-template>
                                <div>{{row.payment_date | date:'MM/dd/YYYY'}}</div>
                            </ng-template>
                        </ngx-datatable-column>
                            <ngx-datatable-column name="Payment Amount"
                                prop="payment_amount" [minWidth]="100" [sortable]="false">
                                <ng-template let-row="row" let-status="value"
                                    ngx-datatable-cell-template>
                                    <ng-container>
                                        {{row.payment_amount | currency}}
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Payment Method"
                                prop="payment_method" [sortable]="false"
                                [minWidth]="150"
                                [cellClass]="'invoice-total-cell'">
                                <ng-template let-row="row"
                                    ngx-datatable-cell-template>
                                    <ng-container>
                                        <p>
                                            <span class="badge bg-primary"
                                                style="min-width: 105px;">
                                                <img
                                                    *ngIf="row.payment_method == paymentMethods.CASH"
                                                    width="18" class="mr-1"
                                                    src="/assets/img/svg/cc/cash.svg" />
                                                <img
                                                    *ngIf="row.payment_method == paymentMethods.CHECK"
                                                    width="18" class="mr-1"
                                                    src="/assets/img/svg/cc/check.svg" />
                                                <img
                                                    *ngIf="row.payment_method == paymentMethods.CARD"
                                                    width="18" class="mr-1"
                                                    src="/assets/img/svg/cc/credit_card.svg" />
                                                <img
                                                    *ngIf="row.payment_method == paymentMethods.ACH"
                                                    width="18" class="mr-1"
                                                    src="/assets/img/svg/cc/bank.svg" />
                                                {{row.payment_method}}</span>
                                        </p>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- <ngx-datatable-column name="Payment Id" prop="payment_id" [minWidth]="150">
                                    </ngx-datatable-column> -->

                            <ngx-datatable-column name="Status" prop="status"
                                [sortable]="false">
                                <ng-template let-row="row" let-status="value"
                                    ngx-datatable-cell-template>
                                    <ng-container>
                                        <div
                                            [class]="{'bg-success':row.status=='Approved', 'status-unpaid':row.status!='Approved'}"
                                            class="status-badge text-center">{{row.status}}</div>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template
                                    let-rowCount="rowCount"
                                    let-pageSize="pageSize"
                                    let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}}
                                        </span>
                                    </div>
                                    <datatable-pager
                                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'"
                                        [page]="curPage"
                                        [size]="pageSize" [count]="rowCount"
                                        [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">
                            {{selected.payment.unique_id}}
                        </h5>
                        <div
                            class="d-flex justify-content-end align-items-start">

                            <button class="btn ml-2 p-0 no-hover font-medium-3"
                                (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row flex-column">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span
                                        class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent
                                    class="tab-content-pd">

                                    <div class="group mt-2">

                                        <div class="row">
                                            <div class="col-12">
                                                <div
                                                    class="card pdf-template-card">
                                                    <div
                                                        class="status-history-container">
                                                        <div class="ribbon3"
                                                            [class]="{'bg-success':selected.payment.status=='Approved', 'status-unpaid':selected.payment.status!='Approved'}">
                                                            <span>{{selected.payment.status}}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="card-content p-3 mt-2"
                                                        style="padding-top: 0 !important;margin-top: 0 !important;">
                                                        <div
                                                            id="invoice-template"
                                                            class="card-body p-0"
                                                            id="pdfTable"
                                                            #pdfTable>
                                                            <!-- Invoice Company Details starts -->
                                                            <div
                                                                id="invoice-company-details"
                                                                class="row">

                                                                <div
                                                                    class="col-md-8">
                                                                    <div
                                                                        class="row">

                                                                        <div class="col-md-12 sub-org">
                                                                            <div class="media">
                                                                            <img *ngIf="selected.payment.suborg_image" width="240" height="108" [src]="fileUrl + selected.payment.suborg_image" />
                                                                            <img *ngIf="!selected.payment.suborg_image" src="assets/img/svg/product-avatar1.svg" alt="company logo" width="80" height="80">
                                                                            <div class="media-body ml-4">
                                                                                <ul class="m-0 list-unstyled">
                                                                                <li class="text-bold-800">{{selected.payment.suborg_name}}</li>
                                                                                <li>{{selected.payment.suborg_address}}</li>
                                                                                <li>{{selected.payment.suborg_city}} {{selected.payment.suborg_zip}} {{(selected.payment.suborg_zip!=null||selected.payment.suborg_city!=null)?',':''}} {{selected.payment.suborg_state}}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-md-4 text-right">
                                                                    <h2
                                                                        class="primary text-uppercase">Payment</h2>
                                                                    <p
                                                                        class="mb-0">{{selected.payment.unique_id}}</p>
                                                                    <div>
                                                                        <div
                                                                            class="d-flex justify-content-between">
                                                                            <span>Amount</span>
                                                                            <span
                                                                                class="font-medium-2 text-bold-700">
                                                                                {{selected.payment.payment_amount
                                                                                |
                                                                                currency}}</span>
                                                                        </div>
                                                                        <div *ngIf="selected.payment.over_payment_amount > 0"
                                                                            class="d-flex justify-content-between">
                                                                            <span>Credit Amount</span>
                                                                            <span
                                                                                class="font-medium-2 text-bold-700">
                                                                                {{selected.payment.over_payment_amount
                                                                                |
                                                                                currency}}</span>
                                                                        </div>
                                                                        <div
                                                                            class="d-flex justify-content-between">
                                                                            <span>Date</span>
                                                                            <span
                                                                                class="font-medium-2 text-bold-700">
                                                                                {{selected.payment.payment_date
                                                                                |
                                                                                date:'mediumDate'}}</span>
                                                                        </div>
                                                                        <!-- <div class="d-flex justify-content-between">
                                                                            <span>Payments Id</span>
                                                                            <span class="font-medium-2 text-bold-700"> {{selected.payment.payment_id}}</span>
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- Invoice Company Details ends -->
                                                            <!-- Invoice Customer Details starts -->
                                                            <div
                                                                id="invoice-customer-details"
                                                                class="row my-2 p-1"
                                                                style="background-color: #edf2ff;">
                                                                <div
                                                                    class="col-md-8 p-0">
                                                                    <div
                                                                        class="row">
                                                                        <div
                                                                            class="col-md-6 p-0">
                                                                            <div
                                                                                class="col-md-12 text-left">
                                                                                <p
                                                                                    class="text-bold-600 mb-1">Customer</p>
                                                                            </div>
                                                                            <div
                                                                                class="col-md-12">
                                                                                <ul
                                                                                    class="m-0 list-unstyled">
                                                                                    <li
                                                                                        class="text-bold-800 mb-1">
                                                                                        <div
                                                                                            class="customer-name">
                                                                                            <div
                                                                                                class="badge badge-warning name-badge w-100">{{selected.payment.tenant_customer_name}}</div>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li *ngIf="selected.payment.email">Email
                                                                                        :
                                                                                        {{selected.payment.email}}</li>
                                                                                    <li *ngIf="selected.payment.mobile">Mobile
                                                                                        :
                                                                                        {{selected.payment.mobile}}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-md-6 col-12 pl-0">
                                                                            <div
                                                                                class="col-12 text-left">
                                                                                <p
                                                                                    class="text-bold-600 mb-1">Payment
                                                                                    Method:</p>
                                                                            </div>
                                                                            <div
                                                                                class="col-md-12 col-12">
                                                                                <ul
                                                                                    class="m-0 list-unstyled">
                                                                                    <li
                                                                                        class="text-bold-800">
                                                                                        <p>
                                                                                            <span
                                                                                                class="badge bg-primary"
                                                                                                style="min-width: 105px;">
                                                                                                <img
                                                                                                    *ngIf="selected.payment.payment_method == paymentMethods.CASH"
                                                                                                    width="18"
                                                                                                    class="mr-1"
                                                                                                    src="/assets/img/svg/cc/cash.svg" />
                                                                                                <img
                                                                                                    *ngIf="selected.payment.payment_method == paymentMethods.CHECK"
                                                                                                    width="18"
                                                                                                    class="mr-1"
                                                                                                    src="/assets/img/svg/cc/check.svg" />
                                                                                                <img
                                                                                                    *ngIf="selected.payment.payment_method == paymentMethods.CARD"
                                                                                                    width="18"
                                                                                                    class="mr-1"
                                                                                                    src="/assets/img/svg/cc/credit_card.svg" />
                                                                                                <img
                                                                                                    *ngIf="selected.payment.payment_method == paymentMethods.ACH"
                                                                                                    width="18"
                                                                                                    class="mr-1"
                                                                                                    src="/assets/img/svg/cc/bank.svg" />
                                                                                                {{selected.payment.payment_method}}</span>
                                                                                        </p>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-md-4 text-right">
                                                                    <div>
                                                                        <div
                                                                            class="d-flex justify-content-between">
                                                                            <div
                                                                                class="doc-row d-flex">
                                                                                <div
                                                                                    class="info-box"
                                                                                    [ngSwitch]="selected.payment.payment_method">

                                                                                    <span
                                                                                        *ngSwitchCase="paymentMethods.CARD">Card
                                                                                        No.
                                                                                        :
                                                                                        {{selected.payment.masked_card_number}}</span>
                                                                                    <span
                                                                                        *ngSwitchCase="paymentMethods.ACH">Account
                                                                                        No.
                                                                                        :
                                                                                        {{selected.payment.masked_account_number}}</span>
                                                                                    <span
                                                                                        *ngSwitchCase="paymentMethods.CHECK">Check
                                                                                        No.
                                                                                        :
                                                                                        {{selected.payment.check_number}}</span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="d-flex justify-content-between">
                                                                            <span>Reference
                                                                                Id
                                                                                :
                                                                                {{selected.payment.reference_id}}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-1">
                                                        <table
                                                            class="table invoice-items-table st-items-table">
                                                            <thead><tr
                                                                    class="row-head">
                                                                    <th width="100">Invoice</th>
                                                                    <th width="150">Suborg</th>
                                                                    <th width="150">Invoice Date</th>
                                                                    <!-- <th  width="150" class="text-right">Invoice Total</th> -->
                                                                    <th  width="150" class="text-right">Payment Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="summary-tr" *ngFor="let transaction of selected.transactions ">
                                                                    <td class="text-left"><a [routerLink]="['/sales/invoice', transaction.invoice.id, 'view']" target="_blank">{{transaction.invoice.unique_number}}</a></td>
                                                                    <td class="text-left">{{transaction.invoice.suborg_name}}</td>
                                                                    <td class="text-left">{{transaction.invoice.created_on |
                                                                        date:'MM/dd/YYYY h:mm a'}}</td>
                                                                    <!-- <td class="text-right">{{transaction.invoice.subtotal | currency}}</td> -->
                                                                    <td class="text-right">{{transaction.payment_amount | currency}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    <div id="invoice-footer">
                                                        <div class="row mt-2 mt-sm-0">
                                                            <div class="col-md-6 d-flex align-items-center">
                                                            <div class="terms-conditions mb-2">
                                                               
                                                                <br/>
                                                                <div><span class="ml-3">Created by :</span> {{selected.payment.paid_by_user}}</div>
                                                                
                                                                <div class="mt-2"><span class="ml-3">Created on :</span> {{selected.payment.created_on |
                                                                    date:'mediumDate'}}</div>
                                                                <br/>
                
                                                            </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                          
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="text-center text-sm-right">
                                                            <!-- <button type="button" class="btn btn-primary btn-print mt-2 mt-md-1" (click)="downloadAsPDF()"><i class="ft-printer mr-1"></i>Print Warranty</button> -->
                                                            </div>
                                                        </div>
                                                        </div>
                                                    <!-- Invoice Footer ends -->

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </ng-template>
                            </li>

                            <li ngbNavItem >
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span
                                        class="d-none d-sm-block">Credit History</span>
                                </a>
                                <ng-template ngbNavContent
                                    class="tab-content-pd">

                                    <div class="group mt-2">

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card pdf-template-card">

                                                    <div class="col-12 p-0">
                                                        <table
                                                            class="table invoice-items-table st-items-table">
                                                            <thead><tr
                                                                    class="row-head">
                                                                    <th width="100">Id</th>
                                                                    <th width="150">Invoice Id</th>
                                                                    <th width="150">Date</th>
                                                                    <th  width="150" class="text-right">Credit Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="summary-tr" *ngFor="let credits of selected.credit_document ">
                                                                    <td class="text-left">{{credits.id}}</td>
                                                                    <td class="text-left">{{credits.invoice_unique_id}}</td>
                                                                    <td class="text-left">{{credits.created_on|
                                                                        date:'MM/dd/YYYY h:mm a'}}</td>
                                                                    <td class="text-right">{{credits.amount | currency}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                         </div>
                                    </div>
                                </ng-template>
                            </li>


                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
