import { environment } from './../../../environments/environment';
import { AppConstants } from "../constants/app.constants";
import { UserTypes } from "../enums/user-types";
import { WHBaseModel } from '../models/common.models';

export abstract class BaseService {

    public baseURL: string = environment.apiURL;

    public webhookURL : string = environment.webhookURL;

    public callcenterSocketURL : string = environment.callcenterSocketURL;

    public dataLimit: number = 50;

    public agentStatusURL : string = environment.agentStatusURL;

    public get userType(): UserTypes {
        return localStorage.getItem(AppConstants.USER_TYPE) as UserTypes;
    }

    public get customerId(): number {
        return parseInt(localStorage.getItem(AppConstants.CUSTOMER_ID));
    };

    public get suborgId(): number {
        return parseInt(localStorage.getItem(AppConstants.CURRENT_SUBORG));
    }

    public get isSuperUser(): boolean {
        return this.userType === UserTypes.SuperUser;
    }

    public get isAdmin(): boolean {
        return this.userType === UserTypes.Admin;
    }

    public get isCustomer(): boolean {
        return this.userType === UserTypes.Customer;
    }

    public get userId(): number {
        return parseInt(localStorage.getItem(AppConstants.USER_ID));
    }

    public get isAllSuborg(): boolean {
        return !this.isSuperUser && this.suborgId === -1;
    }

    public get userEmail(): string {
        return JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["email"];
    }
    public get userTimeZone(): string {
        let tz=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["time_zone"];
         if(!tz){
            tz="UTC";
         }
        return tz;
    }

    public get userSesVerified(): boolean {
        return JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["ses_verified"];
    }

    constructor() { }

    public getSuborgs() {
        const suborgs: any[] = JSON.parse(localStorage.getItem(AppConstants.USER_SUBORGS)) || [];
        return suborgs.filter(item => item.id !== -1);
    }

    public createModel<T>(C: { new(): T }): T {
        const instance = new C();
        if (instance instanceof WHBaseModel) {
            instance.customer = this.customerId;
            instance.suborg = this.suborgId;
        }
        return instance;
    }

    public get userCallcenterSubscriptionStatus(): boolean {


        let status=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["is_call_center"];
        if(status){
        return status;
        }else{
           return false;
        }
    }
}
