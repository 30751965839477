<div class="user-settings" *ngIf="settings">
    <div class="group">
        <div class="group-header d-flex flex-row">
            Two Factor Authentication
            <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0 ml-3">
                <input type="checkbox" class="custom-control-input" id="2fa"
                       [checked]="settings.two_factor_authentication"
                       (change)="update2FA($event)">
                <label class="custom-control-label" for="2fa">
                    <span></span>
                </label>
            </div>
        </div>
        <div class="group-body">
            <div class="default">
                <div class="form-group col-6 p-0">
                    <label class="text-transform-none">Default 2FA method</label>
                    <ng-select class="ng-select-white" placeholder="Choose Default"
                               [(ngModel)]="settings.two_factor_default" (change)="changeDefault2faMethod($event)">
                        <ng-option [disabled]="!settings.email_verified" [value]="TwoFactorType.EMAIL">Email</ng-option>
                        <ng-option [disabled]="!settings.two_factor_authapp"
                                   [value]="TwoFactorType.GOOGLE_AUTHENTICATOR">Google Authenticator App</ng-option>
                    </ng-select>
                    <div class="mt-1" style="font-size: 11px;">
                        <span class="text-danger mr-1">*</span>Enable 2FA method before set default
                    </div>
                </div>
            </div>
            <div class="2fa-types d-flex flex-row">
                <div class="col-6 p-0">
                    <div class="card disabled">
                        <div class="card-header d-flex flex-row justify-content-between">
                            Google Authenticator app
                            <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                                <input type="checkbox" class="custom-control-input" id="google-auth"
                                       [checked]="settings.two_factor_authapp"
                                       (change)="authenticatorVerificationChange($event)">
                                <label class="custom-control-label" for="google-auth">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                Receive a verificaton code on Authenticator app (Android or iOS).
                                Please install this app on your mobile device before continuing.
                                <div class="mt-2">
                                    <button class="btn btn-sm btn-info" (click)="openModal(authenticatorSetup)">
                                        <i class="ft-settings mr-1"></i>Set up Authenticator
                                    </button>
                                </div>
                                <div class="d-flex flex-row align-items-center app-link-section mt-2">
                                    <div class="width-50-per d-flex flex-column align-items-center">
                                        <a class="app-link" target="_blank"
                                           href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img alt='Get it on Google Play'
                                                 src="/assets/img/svg/google-play-badge.svg" />
                                        </a>
                                        <div class="app-qr mt-2">
                                            <img src="/assets/img/gallery/gauth-android-qr.png" />
                                        </div>
                                    </div>
                                    <div class="width-50-per d-flex flex-column left-border align-items-center">
                                        <a class="app-link" target="_blank"
                                           href="https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200">
                                            <img alt="Download on the App Store" src="/assets/img/svg/ios-badge.svg">
                                        </a>
                                        <div class="app-qr mt-2">
                                            <img src="/assets/img/gallery/gauth-ios-qr.jpg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 pr-0">
                    <div class="card">
                        <div class="card-header d-flex flex-row justify-content-between">
                            Email verification
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div *ngIf="user">{{user.email}}</div>
                                <div class="d-flex flex-row justify-content-between align-items-center mt-1">
                                    <span class="badge"
                                          [ngClass]="{'bg-light-danger':!settings.email_verified,'bg-light-success': settings.email_verified}">
                                        {{settings.email_verified ? 'Email Verified' : 'Email not verified'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #authenticatorSetup let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Set up Authenticator</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>1. Open Google Authenticator App.</div>
        <div>2. In the App select <b>Set up account</b>.</div>
        <div>3. Choose <b>Scan a barcode</b> and scan the QR code below.</div>
        <div class="qr-code d-flex mt-2 justify-content-center">
            <qrcode [qrdata]="settings.authenticator_url"></qrcode>
        </div>
        <div class="form-group mt-2">
            <label style="text-transform: none;">Enter the 6-digit code you see in the app.</label>
            <input mask="000000" type="text" [(ngModel)]="code" class="form-control" />
            <small *ngIf="invalid_otp" class="danger">Invalid OTP</small>
        </div>
    </div>
    <div class="modal-footer">
        <button [disabled]="!code" (click)="verifyCode()" class="btn btn-info">Verify</button>
    </div>
</ng-template>
