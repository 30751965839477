import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";


export class UserDataHelper{


    static get defaults(): GFilterParam[] { return [
        { displayName: "All Users", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Active Users", colname: 'is_active', condition: 'equalto', value: true, operator: 'AND' },
        { displayName: "Inactive Users", colname: 'is_active', condition: 'equalto', value: false, operator: 'AND' },
        { displayName: "Deleted Users", colname: 'is_delete', condition: 'equalto', value: true, operator: 'AND' },
      ];}

      static get search(): GFilterParam[] { return  [
        {
          displayName: 'Combo Search', colname: '', condition: '', value: '',
          children: [
            { displayName: 'First Name', colname: 'first_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Last Name', colname: 'last_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Role', colname: 'role_name', condition: 'contains', value: '', operator: 'OR' },
            ]
        },
            { displayName: 'First Name', colname: 'first_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Last Name', colname: 'last_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Role', colname: 'role_name', condition: 'contains', value: '', operator: 'OR' },
      ]
    }


      static get columns(): GFColumn[]{ return [
        {
            displayName: 'Status', columnName: 'is_active', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
            options: [
              { displayName: 'Active', value: true },
              { displayName: 'Inactive', value: false }
            ]
        },
        {displayName: 'Role', columnName: 'role', columnType: GFColumnTypes.ref},
        {
            displayName: 'Call center', columnName: 'subscription', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
            options: [
              { displayName: 'Enabled', value: true },
              { displayName: 'Not enabled', value: false }
            ]
        },
        {
            displayName: 'Dispatch', columnName: 'is_dispatch', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
            options: [
              { displayName: 'Enabled', value: true },
              { displayName: 'Not enabled', value: false }
            ]
        },
      ];
    }

      static get sortLookup() {
          return  {
            created_on:"created_on",
            // title:"title",
            // tenant_customer_name:"customer_name",
            // status:"status",
            // total_price:"total_price"
        };
      }



}
