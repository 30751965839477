import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { concat } from 'lodash';
import { GFColumn, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { WarrantyDataHelper } from 'src/app/modules/sales/helpers/warranty-data.helper';
import { WarrantyList, WarrantyStatus } from 'src/app/modules/sales/models/warranty.models';
import { WarrantyService } from 'src/app/modules/sales/services/warranty.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'customer-info-warranty',
  templateUrl: './customer-info-warranty.component.html',
  styleUrls: ['./customer-info-warranty.component.scss']
})
export class CustomerInfoWarrantyComponent implements OnInit {

  @Input() set customerId(value:number){

    this._customerId=value;

  }

  public _customerId:number;

  public warranty:WarrantyList[]=[];

  public search:string='';

  public customerContact:number;

  public customerLocation:number;

  public showFilter:boolean;

  public filterActive:boolean;

  public subs:SubSink=new SubSink();

  public searchOffset:number=0;

  public resultTotalCount:number;

  public resultCount:number;

  public searchData: GFilterParam[] = WarrantyDataHelper.search[0].children;

  public columns: GFColumn[] = WarrantyDataHelper.columns;

  public statusList=[];

  public status:number;

  public warrantyStatus=WarrantyStatus;

  public coverageTypes = WarrantyDataHelper.coverateType;

  public coverateTypeList=[];

  public coverageType:number;






  constructor(private cdr:ChangeDetectorRef, private warrantyService: WarrantyService,) { }

  ngOnInit(): void {

    this.getWarranty();
    this.getStatusList();
    this.createCoverateTypeList();
  }

  getStatusList(){
    console.log('you are here');
    this.columns.forEach((val, index)=>{

      if(val.displayName=='Status'){

        this.statusList=val.options;
      }
    })
  }

  getStatusClass(status){

    let statusClass=WarrantyDataHelper.statusClass;

    return statusClass[status];

  }

  getStatusString(s:string){
    if(s){

      const re = /_/gi;
      let status= s.replace(re,' ');

      return status;

    }
    else{
      return '-';
    }

  }

  createCoverateTypeList(){
    this.coverateTypeList=[];
    Object.keys(this.coverageTypes).forEach((val, index)=>{
      this.coverateTypeList.push({value:val, label:this.coverageTypes[val]});
    });

  }



  getWarranty(plainSearch=false,loadMore=false){


    let request={ sort:null, filters: null };

    let searchData=[];

    let tmp;


    if(this.search!=''){
      this.searchData.forEach((val, index)=>{
        tmp={...val};
        tmp.value=this.search.toString();
        searchData.push(tmp);
      })
    }


    if(plainSearch==false){


      if(this.customerContact){
        searchData.push({colname: 'tenant_customer_contact', condition: 'equalto', value: this.customerContact, operator: 'AND' });
      }

      if(this.customerLocation){
        searchData.push({colname: 'tenant_customer_location', condition: 'equalto', value: this.customerLocation, operator: 'AND' });
      }

      if(this.status){
        searchData.push({colname: 'warranty_status', condition: 'equalto', value: this.status, operator: 'AND' });
      }

      if(this.coverageType){
        searchData.push({colname: 'coverage_type', condition: 'equalto', value: this.coverageType, operator: 'AND' });
      }



    }

    searchData.push({colname: 'tenant_customer', condition: 'equalto', value: this._customerId, operator: 'WHEN' });



    request.filters=searchData;
    request.sort = [{colname: "created_on", direction: "desc"}]

    if(loadMore){
      this.searchOffset+=50;
    }




   this.subs.sink= this.warrantyService.getAll(request, this.searchOffset).subscribe(
      response=>{
          this.warranty=response.result;

          this.checkFilterActive();
          if(loadMore){
            this.warranty=response.result=concat(response.result, this.warranty);


          }
          else{
            this.warranty=response.result;


          }

          this.resultTotalCount=response.count;

          this.resultCount=response.result.length;

          this.cdr.markForCheck();

      }
    );

    return false;


  }

  customerContactChange(event){

    if(event&&event.id){
      this.customerContact=event?.id;
    }
    else{
      this.customerContact=null;
    }


  }

  customerLocationChange(event){

    if(event&&event.id){
      this.customerLocation=event?.id;

    }
    else{
      this.customerLocation=null;
    }

  }

  clearSearchInput(){

    this.search='';
    this.getWarranty(true);

  }

  resetFilter(){

    this.customerContact=null;
    this.customerLocation=null;
    this.search='';
    this.showFilter=false;
    this.status=null;
    this.coverageType=null;


    this.getWarranty(false);

    this.checkFilterActive();



  }

  checkFilterActive(){

    if(this.coverageType||this.status||this.customerContact||this.customerLocation){
      this.filterActive=true;
    }
    else{
      this.filterActive=false;
    }
  }

  toggleFilter(){
    this.showFilter=!this.showFilter;
    this.cdr.markForCheck();
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

}
