import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { ProductLine } from '../../../models/items';
import { ProductLineService } from '../../../services/product_lines.service';

@Component({
    selector: 'product-line-form',
    templateUrl: './product-line-form.component.html',
    styleUrls: ['./product-line-form.component.scss']
})
export class ProductLineFormComponent implements OnInit, OnDestroy {

    public model: ProductLine;

    @Input() productLineId: number = 0;

    @Output() onSuccess = new EventEmitter<{ response: ProductLine, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    public nameExist: boolean = false;

    private subs:SubSink = new SubSink();

    constructor(private service: ProductLineService, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.productLineId) {
            this.subs.sink = this.service.getById(this.productLineId).subscribe(obj => {
                this.model = obj;
                this.cdr.markForCheck();
            });
        }
        else {
            this.model = this.service.createModel(ProductLine);
        }
    }

    onSubmit(): void {

        let api: Observable<ProductLine>;

        if (this.productLineId > 0) {
            api = this.service.update(this.productLineId, this.model);
        }
        else {
            api = this.service.create(this.model);
        }

        this.subs.sink = api.subscribe(
            resp => {
                let msg = this.productLineId > 0 ? 'Product Line info updated' : 'New Product Line added';
                this.nameExist = false;
                this.onSuccess?.emit({ response: resp, message: msg });
            },
            error => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.onError?.emit(error);
                }
            }
        )
    }

    onCancelClicked(): void { this.onCancel?.emit(); }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
