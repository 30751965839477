
  <!-- <div class="col-12">
    <div class="form-check form-check-inline">
      <input [disabled]="this.cardId?true:false" class="form-check-input" type="radio" [(ngModel)]="selected_payment_method" name="payment_method"
        id="inlineRadio1" value="{{paymentMethods.CARD}}" (ngModelChange)="clrReq()">
      <label class="form-check-label" for="inlineRadio1">Card</label>
    </div>
    <div class="form-check form-check-inline">
      <input [disabled]="this.cardId?true:false" class="form-check-input" type="radio" [(ngModel)]="selected_payment_method" name="payment_method"
        id="inlineRadio2" value="{{paymentMethods.ACH}}" (ngModelChange)="clrReq()">
      <label class="form-check-label" for="inlineRadio2">ACH</label>
    </div>
  </div> -->


  <div class="d-flex payment_methods justify-content-center mt-2"
    style="margin-bottom: -20px;">
    <div *ngIf="!this.cardId || (this.cardId && this.selected_payment_method == paymentMethods.CARD)" class="icon_div"
      [ngClass]="{'selected_icon_div': (this.selected_payment_method == paymentMethods.CARD)}"
      (click)="!this.cardId && changePaymentMethode(paymentMethods.CARD)">
      <img class="m-1" width="42" height="32"
        src="/assets/img/svg/cc/debit_card.svg" />
      <p class="s_title">Credit/Debit Card</p>
    </div>
    <div *ngIf="!this.cardId || (this.cardId && this.selected_payment_method == paymentMethods.ACH)" class="icon_div"
      [ngClass]="{'selected_icon_div': (this.selected_payment_method == paymentMethods.ACH)}"
      (click)="!this.cardId && changePaymentMethode(paymentMethods.ACH)">
      <img class="m-1" width="45" height="32" src="/assets/img/svg/cc/bank.svg" />
      <p class="s_title">ACH</p>
    </div>

  </div>


<mat-card class="mat-card">
  <mat-card-content>

    <form name="form" class="mat-form" #formElement="ngForm" autocomplete="off" (ngSubmit)="formElement.form.valid && submit()"
      novalidate>
      <div class="row">
        <div *ngIf="selected_payment_method == paymentMethods.CARD" class="d-flex flex-row col-12">
          <div class="col-6">
            <div class="form-group">
              <label class="val-required">Card Number</label>
              <input [disabled]="this.cardId?true:false" [ngClass]="{'disabled':this.cardId}" maxlength="16" type="text"
                [(ngModel)]="model.card_number" #card_number="ngModel" name="card_number" class="form-control" required>
              <small class="form-text text-muted danger"
                *ngIf="card_number.invalid && (card_number.dirty || card_number.touched)">
                <div *ngIf="card_number.errors.required">
                  Card Number is required.
                </div>
              </small>
            </div>

            <div class="form-group">
              <label class="val-required">Name on Card</label>
              <input type="text" [(ngModel)]="model.name" #name="ngModel" name="name" class="form-control" required>
              <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
              </small>
            </div>


          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="val-required">Exp date</label>
              <input mask="00/00" type="text" [(ngModel)]="model.expiry_date" #expiry_date="ngModel" name="expiry_date"
                class="form-control" required>
              <small class="form-text text-muted danger"
                *ngIf="expiry_date.invalid && (expiry_date.dirty || expiry_date.touched)">
                <div *ngIf="expiry_date.errors.required">
                  Exp Date is required.
                </div>
              </small>
            </div>

            <div class="form-group">
              <label class="val-required">Zipcode</label>
              <input type="text" [(ngModel)]="model.zip" #zip="ngModel" name="zip" class="form-control" required>
              <small class="form-text text-muted danger" *ngIf="zip.invalid && (zip.dirty || zip.touched)">
                <div *ngIf="zip.errors.required">
                  Zipcode is required.
                </div>
              </small>
            </div>

          </div>
        </div>


        <div *ngIf="selected_payment_method == paymentMethods.ACH" class="d-flex flex-row col-12">
          <div class="col-6">
            <div class="form-group">
              <label class="val-required">Account Number</label>
              <input [disabled]="this.cardId?true:false" [ngClass]="{'disabled':this.cardId}" maxlength="16" type="text"
                [(ngModel)]="model.account" #account="ngModel" name="account" class="form-control" required>
              <small class="form-text text-muted danger"
                *ngIf="account.invalid && (account.dirty || account.touched)">
                <div *ngIf="account.errors.required">
                  Account No is required.
                </div>
              </small>
            </div>

            <div class="form-group">
              <label class="val-required">Name on ACH</label>
              <input type="text" [(ngModel)]="model.name" #name="ngModel" name="name" class="form-control" required>
              <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
              </small>
            </div>

            <div class="form-group">
              <label class="val-required">Zipcode</label>
              <input type="text" [(ngModel)]="model.zip" #zip="ngModel" name="zip" class="form-control" required>
              <small class="form-text text-muted danger" *ngIf="zip.invalid && (zip.dirty || zip.touched)">
                <div *ngIf="zip.errors.required">
                  Zipcode is required.
                </div>
              </small>
            </div>


          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="val-required">Routing Number</label>
              <input [disabled]="this.cardId?true:false" [ngClass]="{'disabled':this.cardId}" type="text" [(ngModel)]="model.routing" #routing="ngModel" name="routing" class="form-control" required>
              <small class="form-text text-muted danger" *ngIf="routing.invalid && (routing.dirty || routing.touched)">
                <div *ngIf="routing.errors.required">
                  Roting No. is required.
                </div>
              </small>
            </div>
            <div class="form-group">
              <label class="val-required">Account Type</label>


                <ng-select  placeholder="Select Type" [(ngModel)]="model.account_type" #account_type="ngModel" name="account_type" >
                  <!-- <ng-option value="">Select Type</ng-option> -->
                   <ng-option value="{{AccountTypes.CHECKING}}">{{AccountTypes.CHECKING}}</ng-option>
                   <ng-option value="{{AccountTypes.SAVINGS}}">{{AccountTypes.SAVINGS }}</ng-option>
               </ng-select>

              <small class="form-text text-muted danger"
                *ngIf="account_type.invalid && (account_type.dirty || account_type.touched)">
                <div *ngIf="account_type.errors.required">
                  Account Type is required.
                </div>
              </small>
            </div>

          </div>
        </div>

        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
          <button type="submit" [disabled]="!formElement.form.valid"
            class="btn-new btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
            {{this.cardId?'Save' : 'Add'}}
          </button>
          <button type="button" (click)="onCancelClicked()" class="btn-new btn btn-secondary">Cancel</button>
        </div>
      </div>
    </form>

  </mat-card-content>
</mat-card>