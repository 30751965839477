import { Component, Input, OnInit } from '@angular/core';
import { DataHistory } from 'src/app/core/models/common.models';

@Component({
    selector: 'history-message',
    templateUrl: './history-view-message.component.html',
    styleUrls: ['./history-view-message.component.scss']
})
export class HistoryViewMessageComponent implements OnInit {

    @Input() history: DataHistory;

    constructor() { }

    ngOnInit(): void {}
}
