<div class="docList docListPayments">

    <p class="mt-2 mb-2 no-record" *ngIf="paymentsList&&paymentsList?.length==0">No
        Payments to display.</p>

    <div class="d-flex flex-column doc-list-row">

        <div class="doc-box mb-3" *ngFor="let payment of paymentsList">

            <div class="doc-body mb-2">

                <div class="doc-row d-flex">
                    <div class="info-box">
                        <label>#</label>
                        <p>{{payment.unique_id}}</p>
                    </div>
                    <div class="info-box">
                        <label>Amount</label>
                        <p><strong>{{payment.payment_amount|currency}}</strong></p>
                    </div>
                </div>

                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Date</label>
                        <p>{{payment.payment_date|date:'MM/dd/YYYY h:mm a'}}</p>
                    </div>
                    <div class="info-box">
                        <label>Status</label>
                        <p><span class="badge bg-success">{{payment.status}}</span></p>
                    </div>

                </div>

                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Payment Method</label>
                        <p>
                            <span class="badge bg-primary">
                                <img
                                    *ngIf="payment.payment_method == paymentMethods.CASH"
                                    width="18" class="mr-1"
                                    src="/assets/img/svg/cc/cash.svg" />
                                <img
                                    *ngIf="payment.payment_method == paymentMethods.CHECK"
                                    width="18" class="mr-1"
                                    src="/assets/img/svg/cc/check.svg" />
                                <img  *ngIf="payment.payment_method == paymentMethods.CARD"
                                width="18" class="mr-1"  src="/assets/img/svg/cc/credit_card.svg" />
                                <img  *ngIf="payment.payment_method == paymentMethods.ACH"
                                width="18" class="mr-1"  src="/assets/img/svg/cc/bank.svg" />
                                {{payment.payment_method}}</span>
                        </p>
                    </div>
                    <div class="info-box">
                        <label>Refference Id</label>
                        <p>{{payment.reference_id}}</p>
                    </div>

                </div>

                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Customer</label>
                        <p><strong>{{payment.tenant_customer_name}}</strong></p>
                    </div>

                    <div class="info-box">
                        <label>Paid By</label>
                        <p><strong>{{payment.paid_by_user}}</strong></p>
                    </div>
                </div>

                <div class="doc-row d-flex">
                    <div class="info-box" [ngSwitch]="payment.payment_method">
                        <div *ngSwitchCase="paymentMethods.CARD">
                            <label>Card No.</label>
                            <p>{{payment.masked_card_number}}</p>
                        </div>

                        <div *ngSwitchCase="paymentMethods.ACH">
                            <label>Account No.</label>
                            <p>{{payment.masked_account_number}}</p>
                        </div>

                        <div *ngSwitchCase="paymentMethods.CHECK">
                            <label>Check No.</label>
                            <p>{{payment.check_number}}</p>
                        </div>

                    </div>
                </div>

            </div>

            <div class="doc-footer mt-1">
                <ul class="d-flex justify-content-between">
                    <a class="w-100" (click)="viewPayments(payment)"> <li><i
                                class="ft-eye"></i> View</li></a>

                </ul>

            </div>

        </div>

    </div>

</div>

<ng-template #payment_view_modal let-c="close" let-d="dismiss">

    <div class="modal-header border-0">
        <h5 class="modal-title" id="modal-basic-title">{{selectedPayment?.unique_id+" - "+invoiceDetails?.unique_number}}</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="docList docListPayments">
            <div class="d-flex flex-column doc-list-row">

                <div class="doc-box mb-3">

                    <div class="doc-body mb-2">

                        <div class="doc-row d-flex">
                            <div class="info-box">
                                <label>#</label>
                                <p>{{selectedPayment.unique_id}}</p>
                            </div>
                            <div class="info-box">
                                <label class="text-c">Payment for</label>
                                <p><strong>{{selectedPayment.suborg_name}}</strong></p>
                            </div>
                        </div>

                        <div class="doc-row d-flex">

                            <div class="info-box">
                                <label>Invoice Total</label>
                                <p>{{invoiceDetails.total_price|currency}}</p>
                            </div>
                            <div class="info-box">
                                <label>Amount</label>
                                <p>{{selectedPayment.payment_amount|currency}}</p>
                            </div>

                        </div>

                        <div class="doc-row d-flex">

                            <div class="info-box">
                                <label>Customer</label>
                                <p><strong>{{selectedPayment.tenant_customer_name}}</strong></p>
                            </div>

                            <div class="info-box">
                                <label>Paid By</label>
                                <p><strong>{{selectedPayment.paid_by_user}}</strong></p>
                            </div>

                            <!-- <div class="info-box">
                            <label>Customer Email</label>
                            <p>{{selectedPayment.email}}</p>
                        </div> -->
                        </div>

                        <div class="doc-row d-flex">

                            <div class="info-box">
                                <label>Customer Phone</label>
                                <p><strong>{{selectedPayment.mobile}}</strong></p>
                            </div>

                            <div class="info-box">
                                <label>Payment Method</label>
                                <p><span class="badge bg-primary">
                                        <img
                                            *ngIf="selectedPayment.payment_method == paymentMethods.CASH"
                                            width="18" class="mr-1"
                                            src="/assets/img/svg/cc/cash.svg" />
                                        <img
                                            *ngIf="selectedPayment.payment_method == paymentMethods.CHECK"
                                            width="18" class="mr-1"
                                            src="/assets/img/svg/cc/check.svg" />
                                        <img  *ngIf="selectedPayment.payment_method == paymentMethods.CARD"
                                        width="18" class="mr-1"  src="/assets/img/svg/cc/credit_card.svg" />
                                        <img  *ngIf="selectedPayment.payment_method == paymentMethods.ACH"
                                        width="18" class="mr-1"  src="/assets/img/svg/cc/bank.svg" />
                                        {{selectedPayment.payment_method}}</span></p>
                            </div>
                        </div>

                        <div class="doc-row d-flex">
                            <div class="info-box">
                                <label>Refference Id</label>
                                <p>{{selectedPayment.reference_id}}</p>
                            </div>
                            <div class="info-box">
                                <label>Status</label>
                                <p><span class="badge bg-success">{{selectedPayment.status}}</span></p>
                            </div>
                        </div>

                        <div class="info-box"
                            [ngSwitch]="selectedPayment.payment_method">
                            <div class="doc-row d-flex"
                                *ngSwitchCase="paymentMethods.CARD">
                                <div class="info-box">
                                    <label>Card No</label>
                                    <p>{{selectedPayment.masked_card_number}}</p>
                                </div>
                                <div class="info-box">
                                    <label>Name on the Card</label>
                                    <p>{{selectedPayment.name}}</p>
                                </div>
                            </div>

                            <div class="doc-row d-flex"
                                *ngSwitchCase="paymentMethods.CASH">
                                <div class="info-box">
                                    <label>Name</label>
                                    <p>{{selectedPayment.deposit_account_name}}</p>
                                </div>
                            </div>

                            <div class="doc-row d-flex"
                                *ngSwitchCase="paymentMethods.CHECK">
                                <div class="info-box">
                                    <label>Check No</label>
                                    <p>{{selectedPayment.check_number}}</p>
                                </div>
                                <div class="info-box">
                                    <label>Name</label>
                                    <p>{{selectedPayment.deposit_account_name}}</p>
                                </div>
                            </div>

                            <div class="doc-row d-flex"
                                *ngSwitchCase="paymentMethods.ACH">
                                <div class="info-box">
                                    <label>Account No</label>
                                    <p>{{selectedPayment.masked_account_number}}</p>
                                </div>
                                <div class="info-box">
                                    <label>Name</label>
                                    <p>{{selectedPayment.name}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="doc-row d-flex">
                            <div class="info-box" style="width: 100%;">
                                <label>Comments</label>
                                <p>{{selectedPayment.comments}}</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>
</ng-template>
