<div class="form-container">
    <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()">
        <div class="form-group">
            <label class="val-required">Name</label>
            <input type="text" [(ngModel)]="model.name" #name="ngModel" name="name" class="form-control" required>
            <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
            <div *ngIf="name.errors.required">
              Name is required.
            </div>
          </small>
            <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea type="text" [(ngModel)]="model.description" name="description" class="form-control"></textarea>
        </div>
        <div class="form-group">
          <label>Priority</label>
          <priority-select [(value)]="model.priority" (change)="priorityChange($event)"></priority-select>
          </div>
        <div class="form-group" >
          <div class="checkbox">
              <input type="checkbox" [(ngModel)]="model.show_message"  name="show_message" id="show_message" class=""
              placeholder="Enter title for this document" />
              <label class="" for="show_message">Show message</label>
          </div>
       </div>
       <div class="form-group" *ngIf="model.show_message">
        <label class="val-required">Message</label>
        <textarea type="text" [(ngModel)]="model.message" #message="ngModel" name="message" class="form-control" required></textarea>
        <small class="form-text text-muted danger" *ngIf="message.invalid && (message.dirty || message.touched)">
          <div *ngIf="model.show_message&&message.errors.required">
            Message is required.
          </div>
        </small>
       </div>

        <div class="form-group" *ngIf="model.show_message">
          <div class="checkbox">
              <input type="checkbox" [(ngModel)]="model.required_confirmation"  name="required_confirmation" id="required_confirmation" class=""
              placeholder="Enter title for this document" />
              <label class="" for="required_confirmation">Required confirmation</label>
          </div>
       </div>

       <div class="form-group">
        <div class="checkbox">
            <input type="checkbox" [(ngModel)]="model.is_dispatch_required"  name="dispatch_required" id="dispatch_required" class=""
            placeholder="Dispatch required" />
            <label class="" for="dispatch_required">Dispatch required</label>
        </div>
      </div>

      <div class="form-group">
        <div class="checkbox">
            <input type="checkbox" [(ngModel)]="model.is_global"  name="global" id="global" class=""
            placeholder="Global required" />
            <label class="" for="global">Global </label>
        </div>
      </div>

        <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
            {{uniqueId > 0 ? 'Save Changes' : 'Save'}}
          </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</div>