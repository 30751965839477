import { Time } from "@angular/common";
import { TwoFactorTypes } from "src/app/core/enums/common.enum";
import { UserWrapper } from "./user-wrapper";

export class User {

  public id: number;

  public customer: number[] = [];

  public role: number;

  public password: string;

  public last_login: Date;

  public email: string;

  public date_of_birth: Date;

  public first_name: string;

  public last_name: string;

  public is_active: boolean = true;

  public subscription: boolean = false;

  public picture: number;

  public start_date: Date;

  public phone_number: string;

  public extension: string;

  public mobile: string;

  public fax: string;

  public job_title: string;

  public time_zone: string;

  public time_zone_auto_update:boolean=false;

  public gender: string;

  public email_verified:boolean;

  public generate_password:boolean = true;

  public color: string;

  public deactivation_date : Date;

  public deactivation_time : string;

  public deactivate:boolean = false;

  public suborg_id:number;

  public role_name:string;

  public rolename:string;

  public is_voicemail_enabled:boolean;

  public is_voicemail_send:boolean;

  public voicemail_voice:string;

  public voicemail_message:string;
  
  public is_dispatch:boolean=false;

  public is_overtime:boolean=false;

  public custname:string;

  public customer_is_subscribed:boolean;

  public is_mask_contact:boolean = false;

  public is_delete:boolean;

}

export class UserGroup {

  public id: number;

  public name: string;

  public description: string;

  public users: UserWrapper[] = [];

  public created_on: Date;

  public created_by: string;

  public modified_on: Date;

  public modified_by: string;

  public is_delete: boolean;

  public is_active: boolean;

  public customer: number;

  public smart_sip_group: boolean= false;

  public status: boolean;

  Message?:string;

  public is_voicemail_enabled:boolean;

  public is_voicemail_send:boolean;

  public voicemail_email:string;

  public voicemail_message:string;

  public voicemail_voice:string;
  
  public is_dispatch:boolean=false;
}

export class UserSettings {

  public id: number;

  public user: number;

  public two_factor_authentication: boolean;

  public two_factor_default: TwoFactorTypes;

  public two_factor_email: boolean;

  public two_factor_authapp: boolean;

  public authenticator_url: string;

  public email_verified: boolean;
}


export class WorkingDays{

  public day:string;

  public id:number;

  public times:WorkingTime[]
}
export class WorkingTime{

  public start_time:string;

  public end_time:string;

  public type:number;

  public date:string;

  public reason:string;

  public day:number;

  public customer:number;

  public suborg:number;

  public id:number;
}

export class workingTimes{
  startTime:workingtimesHour=new workingtimesHour();
  endTime:workingtimesHour=new workingtimesHour();
}

export class workingtimesHour{
  public hour:number;

  public minute:number;
}


export class UserWorkingTime{

  public start_time:string;

  public end_time:string;

  public date:string;

  public customer:number;

  public day:number;

}


export enum Days{
  Monday=0,
  Tuesday=1,
  Wednesday=2,
  Thursday=3,
  Friday=4,
  Saturday=5,
  Sunday=6

}

export class WorkingTimeResponse{

  public day:number;

  public day_name:string;

  public availabilty:UserWorkingTime[]=[];


}

export class WorkingRates{

  public id:number;

  public timesheet_type_name:string;

  public normal_rate:number;

  public overtime_rate:number;

  public is_delete:boolean;

  public user:number;

  public timesheet_type:number;


}