import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { AppEventType, ModuleConstants } from 'src/app/core/enums/common.enum';
import { UIPermission } from 'src/app/core/models/common.models';
import { GFilterParam, GFColumn, GSortParam, GFColumnTypes, DataFilterRequest } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';
import * as popup from 'src/app/core/utils/popup.functions';
import { Distributor } from '../../models/distributor';
import { DistributorService } from '../../services/distributor.service';
import { DistributorDataHelper } from '../../helpers/distributor-data.helper';
import { EventQueueService } from 'src/app/core/services/event-queue.service';

@Component({
  selector: 'app-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.scss']
})
export class DistributorsComponent implements OnInit, OnDestroy {

  @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = DistributorDataHelper.DefaultFilters;

  public search: GFilterParam[] = DistributorDataHelper.SearchFilters;

  public columns: GFColumn[] = DistributorDataHelper.FilterColumns;

  public sortLookup = DistributorDataHelper.SortLookup;

  public customerReps: Observable<string>;

  public distributorLicenses: Observable<string>;

  public SelectionType = SelectionType;

  public rows: Distributor[] = [];

  public distributorId: number;

  public contactId: number;

  public expanded: boolean = true;

  public selected: Distributor;

  public Permissions = PermissionConstants;

  public Resources = ResourceConstants;

  public sort: GSortParam[] = [{ colname: 'distributor_name', direction: 'asc' }];

  public module: ModuleConstants = ModuleConstants.DISTRIBUTORS;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public UIPermissions: UIPermission;

  constructor(private distributorService: DistributorService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    public location: Location,
    route: ActivatedRoute,
    private device: DeviceDetectorService,
    private eventQueue: EventQueueService) {

    this.subs.sink = route.params.subscribe(params => this.distributorId = +params.id || 0);
    this.subs.sink = route.queryParams.subscribe(params => this.contactId = +params.cid || 0);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.DISTRIBUTORS,ResourceConstants.CUSTOMER_MODULE);
  }

  ngOnInit(): void {

    this.getData(null);

    this.scrollbarH = this.device.isMobile();

    if (this.distributorId > 0) {
      this.getDistributor();
    }
    this.subscribeEvents();
  }

  subscribeEvents() {
    this.subs.sink = this.eventQueue.on(AppEventType.SuborgSwitch).subscribe(event => {
      this.location.go(`/customers/distributors`);
    });
  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let option = { sort: this.sort, filters: filters };

    this.subs.sink = this.distributorService.getAll(option, offset).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }

  getDistributor() {
    this.subs.sink = this.distributorService.getById(this.distributorId).subscribe(customer => {
      this.selected = customer;
      this.expanded = false;
      this.refreshDataTable();
      this.getCustomerRep();
      this.getDistributorLiceseNumbers();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: Distributor = selected[0];
    if (current && this.UIPermissions.viewInfo) {
      this.distributorId = current.id;
      this.selected = current;
      this.expanded = false;
      this.location.go(`/customers/distributors/${current.id}/view`);
      this.refreshDataTable();
      this.getCustomerRep();
      this.getDistributorLiceseNumbers();
    }
  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go(`/customers/distributors`);
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
    });
  }

  deleteDistributor(id: number) {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.distributorService.delete(id).subscribe(
          () => {
            popup.CompleteDelete('Distributor has been deleted.');
            this.rows = this.rows.filter(x => x.id !== id);
            this.cdr.markForCheck();
            this.closeDetailedView();
          },
          () => this.toastr.error('Unable to delete row')
        );
      }
    });
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.DISTRIBUTORS, [permission, this.Permissions.GLOBAL],ResourceConstants.CUSTOMER_MODULE);
  }

  hasPermissionAny(resource: string, permission: string[]) {
    permission.push(this.Permissions.GLOBAL);
    return this.ra.hasPermission(resource, permission);
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    let params = this.dataFilter?.getDataFilters() || [];
    this.getData(params, offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  changeStatus() {

    let status = this.selected.is_active;

    let popupOption = {
      title: `Are you sure you want to ${status ? 'Activate Distributor' : 'Deactivate Distributor'}?`,
      text: this.selected.name,
      current: status,
    };
    popup.ConfirmActivate(popupOption, result => {
      if (!result.isConfirmed) {
        this.selected.is_active = !status;
        this.cdr.markForCheck();
        return;
      }
      this.subs.sink = this.distributorService.patch(this.selected.id, { is_active: status }).subscribe(
        () => { },
        () => {
          this.selected.is_active = !status;
          this.toastr.error("Failed to change status");
          this.cdr.markForCheck();
        }
      );
    });
  }

  getCustomerRep() {
    this.customerReps = this.distributorService.getAccountRep(this.selected.id).pipe(map(resp => {
      return resp.map(item => item.rep_name).join(', ');
    }));
  }

  getDistributorLiceseNumbers() {
    let request: DataFilterRequest = {
      filters: [{ colname: 'distributor', condition: 'equalto', value: this.selected.id, operator: 'AND' }],
      sort: null
    };
    this.distributorLicenses = this.distributorService.getDistributorLicenses(request).pipe(map(resp => {
      return resp.result.map(item => item.license_number).join(', ');
    }))
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
