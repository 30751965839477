import { Pipe, PipeTransform } from '@angular/core';
import { CustomField } from 'src/app/core/models/custom-field';

@Pipe({
  name: 'customfields'
})
export class CustomFieldsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    if (!value) return null;

    let fields = [], keys = [];

    let obj = this.parse(value) || {};

    for (let key in obj) {
      let customField = new CustomField();
      if (!keys.includes(key)) {
        customField.name = key;
        customField.value = obj[key];
        fields.push(customField);
        keys.push(key);
      }
    }
    return fields;
  }

  parse(obj: any) {
    if (typeof obj === 'string') {
      try { obj = JSON.parse(obj); }
      catch (exception) { obj = null; }
    }
    return obj;
  }
}
