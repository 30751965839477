<div class="customer-type-select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" [placeholder]="placeholder" [items]="options | async | orderBy:'name'" bindLabel="name" bindValue="id" [(ngModel)]="value" [readonly]="readonly" (change)="onValueChanged()">
        <ng-template ng-footer-tmp *ngIf="addButton && hasPermission()">
            <button type="button" class="select-footer-btn" (click)="openModal(content)">
            <i class="icon icon-settings"></i>
            <span class="text">Add Payment Term</span>
        </button>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Payment Term</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <payment-term-form [suborg_id]="suborg_id" (onSuccess)="onDataSubmittedSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()"></payment-term-form>
        </div>
    </ng-template>
</div>