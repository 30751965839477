<div class="select-wrapper">
    <ng-select
        placeholder="Select voice"
        [items]="rows | async"
        bindLabel="name"
        bindValue="value"
        [(ngModel)]="value"
        (change)="onValueChanged()">
    </ng-select>
</div>
