import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { StockInfo } from '../../../models/items';
import { KitWareHouseAggregate } from '../../../models/kit.models';
import { KitService } from '../../../services/kit.service';

@Component({
    selector: 'app-kit-stock',
    templateUrl: './kit-stock.component.html',
    styleUrls: ['./kit-stock.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class KitStockComponent implements OnInit, OnDestroy {

    public total_quantity: number;

    private innerKitId: number;
    showStockInfo: boolean;
    total_available_quantity: number;

    get kitId(): number { return this.innerKitId; };

    @Input() set kitId(value: number) {

        if (value && this.innerKitId !== value) {

            this.innerKitId = value;
            this.rows.length = 0;
            this.getData()
        }
    };

    @Output() openPrint: EventEmitter<any> = new EventEmitter();


    private subs: SubSink = new SubSink();

    public rows: KitWareHouseAggregate[] = [];

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    stockInfo: StockInfo = new StockInfo();

    constructor(private kitservices: KitService, private ra: ResourceAccessService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}

    getData() {
        this.subs.sink = this.kitservices.getLocationWiseStockByKit(this.kitId).subscribe(resp => {
            this.rows = resp;
            if(this.rows.length == 0)
            this.showStockInfo = false;
            else{
            this.getStockInfo();
            this.showStockInfo = true;
            }
            this.total_quantity = this.rows.reduce((sum, current) => sum + current.quantity, 0)
            this.total_available_quantity = this.rows.reduce((sum, current) => sum + current.available_quantity, 0)
            this.cdr.markForCheck();
        });
    }


    getStockInfo() {
        this.subs.sink = this.kitservices.getKitStockInfo(this.kitId).subscribe(
            resp => {
                console.log(resp);
                this.stockInfo.total_stock = resp.total_stock;
                this.stockInfo.available_stock = resp.available_stock;
                this.stockInfo.commited_on_assemblies = resp.total_on_committed_assemblies;
                this.stockInfo.draft_on_assemblies = resp.total_on_draft_assemblies;
                this.cdr.markForCheck();
            }
        );

    }


    hasPermission(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE);
    }

    hasPermissionAny(resource: string, permission: string[]) {
        return this.ra.hasPermission(resource, permission, ResourceConstants.INV_MODULE);
    }

    printView(werehouse:number, location:number){

        this.openPrint.emit({"werehouse":werehouse, "location":location});
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
