<section class="common-grid-view">

    <div class="list-parellax pt-2" [ngClass]="{ 'height-min-fit': expanded, 'height-fit': !expanded }">
        <div class="d-flex flex-row flex-1 h-100">
            <div
                 [ngClass]="{'parellax-shrinked parellax-shrinked-bg': !expanded,'parellax-expanded card m-0': expanded}">
                <div class="d-flex align-items-center flex-space-bw"
                     [ngClass]="{'card-header': expanded,'parellax-dt-header mb-2': !expanded}">
                    <datagrid-filter
                                     #dataGridFilter
                                     [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [searchFilters]="searchFilters"
                                     [newButton]="true"
                                     [newButtonUrl]="['/tenants/new']"
                                     [expanded]="expanded"
                                     (apply)="applyFilter($event)">
                    </datagrid-filter>
                </div>
          
                <div class="card-content">
                    <div [ngClass]="{ 'card-body': expanded }">
                        <ngx-datatable #dataTable
                                       class="bootstrap core-bootstrap"
                                       [scrollbarH]="scrollbarH"
                                       [columnMode]="'force'"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [rows]="rows"
                                       [selectionType]="SelectionType.single"
                                       (select)="onSelect($event)"
                                       [count]="page.count"
                                       [sorts]="[{prop:'name',dir:'desc'}]"
                                       [externalPaging]="true"
                                       [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit"
                                       (page)="setPage($event)"
                                       (sort)="onSort($event)">
                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Email" prop="email"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Address" prop="address">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.address}}</div>
                                    <div>{{row.city}}</div>
                                    <div *ngIf="row.state || row.zipcode">{{[row.state,row.zipcode].join(', ')}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Phone" prop="phone">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{ value | mask: "(000) 000-0000" }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created Date" prop="account.start_date" [sortable]="false">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{ value | date: "MM/dd/YYYY" }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="active" [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status}">
                                        {{ status ? "Active" : "Inactive" }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Action" [sortable]="false" prop="id">
                              <ng-template let-id="value" ngx-datatable-cell-template>
                                  <div ngbDropdown container="body">
                                      <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                          <i class="ft-more-vertical text-primary"></i>
                                      </button>
                                      <div ngbDropdownMenu>
                                          <a class="w-100" [routerLink]="['/tenants',id,'edit']" ngbDropdownItem>
                                              <i class="ft-edit mr-1"></i> Edit
                                          </a>
                                      </div>
                                  </div>
                              </ng-template>
                          </ngx-datatable-column>
                            <ngx-datatable-footer>
                              <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                  <div class="page-count" *ngIf="expanded">
                                      <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                  </div>
                                  <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                      (change)="dataTable.onFooterPage($event)">
                                  </datatable-pager>
                              </ng-template>
                          </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">{{ selected?.name }}</h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a
                               ngbTooltip="Edit"
                               [routerLink]="['/tenants', selected.id, 'edit']"
                               class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button
                                    class="btn btn-sm ml-2 p-0 no-hover font-medium-3"
                                    (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row flex-column">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <div class="group-header d-flex justify-content-between">
                                            Overview
                                            <div class="status-toggle-btn">
                                                <label class="title">Status :</label>
                                                <div
                                                     class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                    <input type="checkbox" [(ngModel)]="selected.active" id="status" name="active"
                                                           class="custom-control-input"
                                                           (change)="changeStatus()"/>
                                                    <label class="custom-control-label" for="status">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group-body">
                                            <div class="ov-row" *ngIf="selected.email">
                                                <label class="col-md-3 p-0 item-label">Email</label>
                                                <label class="item-label-value">
                                                  <a [href]="'mailto:' + selected.email" class="text-primary">{{ selected.email }}</a>
                                                </label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.phone">
                                                <label class="col-md-3 p-0 item-label">Phone</label>
                                                <label class="item-label-value">{{ selected.phone }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.address">
                                                <label class="col-md-3 p-0 item-label">Address</label>
                                                <label class="item-label-value">{{ selected.address }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.city">
                                              <label class="col-md-3 p-0 item-label">City</label>
                                              <label class="item-label-value">{{ selected.city }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.state">
                                              <label class="col-md-3 p-0 item-label">State</label>
                                              <label class="item-label-value">{{ selected.state }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.zipcode">
                                                <label class="col-md-3 p-0 item-label">Zip</label>
                                                <label class="item-label-value">{{ selected.zipcode }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.account?.start_date">
                                                <label class="col-md-3 p-0 item-label">Created On</label>
                                                <label
                                                       class="item-label-value">{{ selected.account.start_date | date: "MM/dd/YYYY" }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="group mt-2">
                                        <div class="group-header">Settings</div>
                                        <div class="group-body">
                                            <div class="col-12 col-md-6 p-0">
                                                <tenant-settings [tenantId]="selected.id"></tenant-settings>
                                            </div>
                                        </div>
                                    </div> -->
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Org/Sub-Org</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                  <tenant-suborgs [tenantId]="selected.id"></tenant-suborgs>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Subscription</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <div class="group-header d-flex">
                                            Subscription
                                        </div>
                                        <div class="group-body">
                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">Call center</label>
                                                <label class="item-label-value">
                                                    <div class="status-toggle-btn">
                                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                            <input type="checkbox" [(ngModel)]="selected.subscription" id="callCenter" name="active"
                                                                   class="custom-control-input"
                                                                   (change)="subscriptionChange('subscription')"/>
                                                            <label class="custom-control-label" for="callCenter">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">Dispatch</label>
                                                <label class="item-label-value">
                                                    <div class="status-toggle-btn">
                                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                            <input type="checkbox" [(ngModel)]="selected.is_dispatch" id="dispatch" name="active"
                                                                   class="custom-control-input"
                                                                   (change)="subscriptionChange('is_dispatch')"/>
                                                            <label class="custom-control-label" for="dispatch">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">Quickbooks</label>
                                                <label class="item-label-value">
                                                    <div class="status-toggle-btn">
                                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                            <input type="checkbox" [(ngModel)]="selected.is_quickbook" id="quickbooks" name="active"
                                                                   class="custom-control-input"
                                                                   (change)="subscriptionChange('is_quickbook')"/>
                                                            <label class="custom-control-label" for="quickbooks">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <!-- <div class="ov-row" *ngIf="selected.email">
                                                <label class="col-md-3 p-0 item-label">Email</label>
                                                <label class="item-label-value">
                                                  <a [href]="'mailto:' + selected.email" class="text-primary">{{ selected.email }}</a>
                                                </label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.phone">
                                                <label class="col-md-3 p-0 item-label">Phone</label>
                                                <label class="item-label-value">{{ selected.phone }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.address">
                                                <label class="col-md-3 p-0 item-label">Address</label>
                                                <label class="item-label-value">{{ selected.address }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.city">
                                              <label class="col-md-3 p-0 item-label">City</label>
                                              <label class="item-label-value">{{ selected.city }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.state">
                                              <label class="col-md-3 p-0 item-label">State</label>
                                              <label class="item-label-value">{{ selected.state }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.zipcode">
                                                <label class="col-md-3 p-0 item-label">Zip</label>
                                                <label class="item-label-value">{{ selected.zipcode }}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.account?.start_date">
                                                <label class="col-md-3 p-0 item-label">Created On</label>
                                                <label
                                                       class="item-label-value">{{ selected.account.start_date | date: "MM/dd/YYYY" }}</label>
                                            </div> -->
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
