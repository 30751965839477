import { Manufacturer } from './../models/manufacturer';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { DataFilterResponse } from 'src/app/core/models/grid-filter.models';

@Injectable({
    providedIn: 'root'
})
export class ManufacturerService extends BaseService {

    constructor(private http: HttpClient) { super() }

    getAll() {
        return this.http.get<Manufacturer[]>(`${this.baseURL}/manufacturers/`);
    }

    getAllFiltered(option: any, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/manufacturers/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<Manufacturer[]>>(url, option);
    }

    getById(id: number) {
        return this.http.get<Manufacturer>(`${this.baseURL}/manufacturers/${id}/`);
    }

    create(manufacturer: Manufacturer) {
        return this.http.post<Manufacturer>(`${this.baseURL}/manufacturers/`, manufacturer);
    }

    update(id: number, manufacturer: Manufacturer) {
        return this.http.put<Manufacturer>(`${this.baseURL}/manufacturers/${id}/`, manufacturer);
    }

    patch(id: number, request: any) {
        return this.http.patch<Manufacturer>(`${this.baseURL}/manufacturers/${id}/`, request);
    }

    delete(id: number) {
        return this.http.delete<Manufacturer>(`${this.baseURL}/manufacturers/${id}/`);
    }
}
