<form name="form" [formGroup]="accountForm" novalidate>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label>Start Date</label>
                <input type="text" formControlName="start_date" class="form-control" readonly required>
            </div>
            <div class="form-group">
                <label>End Date</label>
                <input type="text" formControlName="end_date" class="form-control" readonly required>
            </div>
            <!-- <div class="form-group">
                <div class="controls check-group">
                    <label class="mr-1">Paid</label>
                    <div class="checkbox">
                        <input type="checkbox" formControlName="paid" id="paid" readonly>
                        <label for="paid"></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="controls check-group">
                    <label class="mr-1">Active</label>
                    <div class="checkbox">
                        <input type="checkbox" formControlName="active" id="status" readonly>
                        <label for="status"></label>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</form>