import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/gaurd/auth.gaurd';
import { EXT_LAYOUT_ROUTES } from './core/routes/ext-layout.routes';
import { INNER_LAYOUT_ROUTES } from './core/routes/inner-layout.routes';
import { ExtLayoutComponent as ExtLayoutComponent } from './blocks/components/ext-layout/ext-layout.component';
import { InnerLayoutComponent as InnerLayoutComponent } from './blocks/components/inner-layout/inner-layout.component';
import { MakePaymentComponent } from './modules/payments/pages/make-payment/make-payment.component';
import { AccountingPayNowComponent } from './modules/payments/pages/accounting-pay-now/accounting-pay-now.component';

const routes: Routes = [
  {
    path: '',
    component: InnerLayoutComponent,
    children: INNER_LAYOUT_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: ExtLayoutComponent,
    children: EXT_LAYOUT_ROUTES
  },
  {
    path: 'make-payment/:id',
    component: MakePaymentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-payment/:customer_id',
    component: AccountingPayNowComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule { }
