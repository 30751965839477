import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { UserGroupService } from './../../services/user-group.service';
import { UserService } from './../../services/user.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { UserGroup } from '../../models/user';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserSelectGridComponent } from '../../components/user-select-grid/user-select-grid.component';
import { Ring2voiceService } from 'src/app/modules/ring2voice/services/ring2voice.service';

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit, OnDestroy {

  @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

  @ViewChild('FormModal') modalContent: any;

  @ViewChild(UserSelectGridComponent) UserSelectGridComponent: UserSelectGridComponent;

  public row:any;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public expanded: boolean = true;

  public scrollbarH: boolean = false;

  public selected: UserGroup;

  public rows: UserGroup[];

  public SelectionType = SelectionType;

  public defaults: GFilterParam[] = [
    { displayName: "All User Groups", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Active User Groups", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
    { displayName: "Inactive User Groups", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
  ];

  public searchFilters: GFilterParam[] = [
    { displayName: 'Name', colname: 'gropuname', condition: 'contains', value: '', operator: 'AND' }
  ];

  public columns: GFColumn[] = [
    { displayName: 'Name', columnName: 'gropuname', columnType: GFColumnTypes.string },
    { displayName: 'Description', columnName: 'description', columnType: GFColumnTypes.string },
    { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
    { displayName: 'User', columnName: 'users', columnType: GFColumnTypes.ref },
    { displayName: 'User Role', columnName: 'role', columnType: GFColumnTypes.string },
    { displayName: 'User Email', columnName: 'email', columnType: GFColumnTypes.string }
  ];

  public sort: GSortParam[] = [{ colname: 'gropuname', direction: 'asc' }];

  public sortLookup = {
    name: 'gropuname',
    description:'description',
    created_on:'created_on'
  };

  public userGroupId: number;

  public subs: SubSink = new SubSink();

  private modalRef: NgbModalRef;

  voices: Observable<any>;

  constructor(private userGroupService: UserGroupService,
    private cdr: ChangeDetectorRef,
    route: ActivatedRoute,
    public location: Location,
    private toastr: ToastrService,
    private router: Router,
    device: DeviceDetectorService,
    public modal: NgbModal,
    private userService: UserService,
    private r2vservice: Ring2voiceService) {

    this.subs.sink = route.params.subscribe(params => this.userGroupId = +params.id || 0);
    this.scrollbarH = device.isMobile();
  }

  ngOnInit() {

    this.getData(null);

    this.voices = this.r2vservice.getVoices();

    if (this.userGroupId > 0) {
      this.subs.add(this.userGroupService.getById(this.userGroupId).subscribe(response => {
        this.selected = response;
        this.expanded = false;
        this.refreshDataTable();
      }));
    }

  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let option = { sort: this.sort, filters: filters };

    this.subs.sink = this.userGroupService.getAll(option, offset).pipe(catchError(() => [])).subscribe(
      response => {
        this.rows = response.result;
        this.setPagination(offset, response.count);
        this.cdr.markForCheck();
      }
    );
  }

  onSelect({ selected }) {
    let current: UserGroup = selected[0];
    if (current) {
      this.selected = current;
      this.initVoiceMailData();
      this.expanded = false;
      this.location.go(`/users/groups/${current.id}/view`);
      this.refreshDataTable();
    }
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    let params = this.dataFilter?.getDataFilters() || [];
    this.getData(params, offset);
  }

  refreshDataTable() {
    setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
    });
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  deleteGroup(userGroupId) {
    popup.ConfirmDelete(result => {
        if (result.isConfirmed) {
            this.subs.sink = this.userGroupService.delete(userGroupId).subscribe(
                (response) => {
                  if(response.status){
                    popup.CompleteDelete('Group has been deleted.', () => {
                      this.closeDetailedView();
                      this.applyFilter(null);
                    });
                  }else if (!response.status){
                    this.toastr.warning(response.message)
                  }
                },
                () => this.toastr.error('Failed to delete Group')
            );
        }
    });
}

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go('/users/groups');
    this.refreshDataTable();
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  changeStatus() {
    let status = this.selected.is_active;
    let popupOption = {
      title: `Are you sure you want to ${status ? 'Activate User Group' : 'Deactivate User Group'}?`,
      text: this.selected.name,
      current: status,
    };
    popup.ConfirmActivate(popupOption, result => {
      if (!result.isConfirmed) {
        this.selected.is_active = !status;
        this.cdr.markForCheck();
        return;
      }
      this.subs.sink = this.userGroupService.patch(this.selected.id, { is_active: status }).subscribe(
        () => {this.toastr.success("Status updated");},
        () => {
          this.selected.is_active = !status;
          this.toastr.error("Failed to change status");
          this.cdr.markForCheck();
        }
      );
    });
  }

  changeSmartSipGroupStatus() {
    let status = this.selected.smart_sip_group;
    let popupOption = {
      title: `Are you sure you want to ${status ? 'Activate Smart SIP Group' : 'Deactivate Smart SIP Group'}?`,
      text: this.selected.name,
      current: status,
    };
    popup.ConfirmActivate(popupOption, result => {
      if (!result.isConfirmed) {
        this.selected.smart_sip_group = !status;
        this.cdr.markForCheck();
        return;
      }
      this.subs.sink = this.userGroupService.patch(this.selected.id, { smart_sip_group: status }).subscribe(
        (resp) => {
          if(resp.id){
            this.toastr.success("Smart SIP group status updated");
          }else if(resp.Message){
            this.toastr.warning(resp.Message);
            this.selected.smart_sip_group = !status;
            const queryParams = {
              smartsipgroup: 'true'
            };
            this.router.navigate([`/users/groups/${this.selected.id}/edit`], { queryParams });
          }
        },
        () => {
          this.selected.smart_sip_group = !status;
          this.toastr.error("Failed to change status");
          this.cdr.markForCheck();
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  refreshSmartSip(){
    this.UserSelectGridComponent.getGroupUsers();
  }

  /*voiceMailStatus() {
    
    let user = this.selected;
   
    this.selected.is_voicemail_enabled = !user.is_voicemail_enabled;
         
  }

  voiceMailEmailStatus() {
   
   let user = this.selected;
   this.selected.is_voicemail_send = !user.is_voicemail_send;
          
  }*/

  submit(): void {

    let user = this.selected;
    let opt = {
      title: `Are you sure you want to update settings?`,
      text: user.name,
      ButtonText: 'Yes',
    }
    popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.userGroupService.patch(this.selected.id, 
          { is_voicemail_send: user.is_voicemail_send ,
            is_voicemail_enabled: user.is_voicemail_enabled,
            voicemail_voice: user.voicemail_voice,
            voicemail_email: user.voicemail_email,
            voicemail_message: user.voicemail_message
          }).subscribe(
          resp => { this.toastr.success('Voicemail settings has been updated');},
          () => {
           // this.selected.is_voicemail_enabled = !user.is_voicemail_enabled;
           // this.selected.is_voicemail_send = !user.is_voicemail_send;
            this.cdr.markForCheck();
            this.toastr.error('Failed to change status');
          }
        );
      }
      else {
       // this.selected.is_voicemail_send = !user.is_voicemail_send;
       // this.selected.is_voicemail_enabled = !user.is_voicemail_enabled;
        this.cdr.markForCheck();
      }
    });
    
  }

  initVoiceMailData(){

    if(this.selected.voicemail_voice == '' || this.selected.voicemail_voice == null){
      this.selected.voicemail_voice = 'man';   
  } 
  }
}
