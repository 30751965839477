import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Material } from '../models/items';

@Injectable({
    providedIn: 'root'
})
export class MaterialService extends BaseService {

    constructor(private http: HttpClient) { super() }

    get() {
        return this.http.get<Material[]>(`${this.baseURL}/materials/`);
    }

    getById(id: number) {
        return this.http.get<Material>(`${this.baseURL}/materials/${id}/`);
    }

    create(model: Material) {
        return this.http.post<Material>(`${this.baseURL}/materials/`, model);
    }

    update(id: number, model: Material) {
        return this.http.put<Material>(`${this.baseURL}/materials/${id}/`, model);
    }

    delete(id: number) {
        return this.http.delete(`${this.baseURL}/materials/${id}/`);
    }
}
