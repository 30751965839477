import { AdminRouteGuard } from './../../core/gaurd/admin.route.gaurd';
import { SUAdminRouteGuard } from '../../core/gaurd/su-or-admin.route.gaurd';
import { SuperAdminRouteGuard } from '../../core/gaurd/super-admin.route.gaurd';
import { GridViewListComponent } from './pages/grid-view-list/grid-view-list.component';
import { AddGridViewComponent } from './pages/add-grid-view/add-grid-view.component';
import { AddGridMetaComponent } from './pages/add-grid-meta/add-grid-meta.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GridMetaListComponent } from './pages/grid-meta-list/grid-meta-list.component';

const routes: Routes = [
  {
    path: 'meta',
    pathMatch: 'full',
    component: GridMetaListComponent,
    canActivate: [SuperAdminRouteGuard],
    data: { title: 'Grid Meta' }
  },
  {
    path: 'meta/new',
    pathMatch: 'full',
    component: AddGridMetaComponent,
    canActivate: [SuperAdminRouteGuard],
    data: { title: 'New Grid Meta' }
  },
  {
    path: 'meta/:id/edit',
    pathMatch: 'full',
    component: AddGridMetaComponent,
    canActivate: [SuperAdminRouteGuard],
    data: { title: 'Edit Grid Meta' }
  },
  {
    path: 'views/new',
    pathMatch: 'full',
    component: AddGridViewComponent,
    canActivate: [AdminRouteGuard],
    data: { title: 'New Grid View' }
  },
  {
    path: 'views/:id/edit',
    pathMatch: 'full',
    component: AddGridViewComponent,
    canActivate: [AdminRouteGuard],
    data: { title: 'Edit Grid View' }
  },
  {
    path: 'views',
    pathMatch: 'full',
    component: GridViewListComponent,
    canActivate: [AdminRouteGuard],
    data: { title: 'Grid Views' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GridProfilingRoutingModule { }
