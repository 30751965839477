import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { QbooksSettings } from '../../models/qbookssettings.model';
import { SubSink } from 'subsink';
import { SuborgService } from '../../services/suborg.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'qbooks-settings',
  templateUrl: './qbooks-settings.component.html',
  styleUrls: ['./qbooks-settings.component.scss']
})
export class QbooksSettingsComponent implements OnInit {

  public settings: QbooksSettings;

  public model: QbooksSettings;

  public verificationSend: boolean = false;

  public is_create: boolean = false;

  public showViewButton: boolean = true;

  public subs: SubSink = new SubSink();

  private sub_org: number;

  public code: string;

  public modalRef: NgbModalRef;

  @Input() set suborgId(value: number) {
    this.sub_org = value;
    if (value) {
        this.getSettings()
    }
  }

  get suborgId(){

    return this.sub_org;
  }

  constructor(private suborgService: SuborgService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    public modalService: NgbModal) {}

  ngOnInit(): void {}

  getSettings(){
    this.subs.sink = this.suborgService.getQbooksSettingsBySuborgId(this.sub_org).subscribe(resp => {
      if(resp.quickbook_cred){
        this.settings = resp.quickbook_cred;
        this.is_create = false;
        this.showViewButton = true;
      }else{
        this.settings = null;
        this.showViewButton = true;
      }
      this.cdr.markForCheck();
  });
  }

  syncSettings(sync_type:string) {
    this.subs.sink = this.suborgService.syncQbooksSettings(sync_type,this.sub_org).subscribe(
        resp => {
            this.toastr.success(`Successfully synced ${sync_type}`);
        },
        error => this.toastr.error(`Failed to sync ${sync_type}`)
    );
  }

  openSettingsForm(modalContent: any,type:string=null) {
    if(type=='create'){
      this.is_create = true;
    }
    if(this.settings&&this.showViewButton){
      this.sendVerificationCode();
    }
    if (this.settings) {
        this.model = { ...this.settings }
    }
    else {
        this.model = new QbooksSettings();
        this.model.suborg = this.suborgId;
    }
    this.modalRef = this.modalService.open(modalContent, { centered: true, backdrop:'static' });
}

  deleteSettings() {
    console.log('delete');
  }

  sendVerificationCode() {
    this.subs.sink = this.suborgService.sendQbooksVerificationCode(this.sub_org).subscribe(
        resp => {
            this.toastr.success("A verification code has been sent to your email address");
            this.verificationSend = true;
            setTimeout(() => {
              let otpField = document.getElementById('otpField') as HTMLInputElement;
              if(otpField){
                  otpField.focus();
              }
            }, 800);
            this.cdr.markForCheck();
        },
        error => {
            this.toastr.error("Unable to send Verificaton code");
        }
    );
  }

  verifyCode() {
      this.subs.sink = this.suborgService.verifyQbooksVerificationCode(this.sub_org, this.code).subscribe(
          resp => {
              this.settings = resp.quickbook_cred;
              // this.model = resp.quickbook_cred;
              this.model = { ...this.settings }
              this.verificationSend = false;
              this.showViewButton = false;
              this.code = null;
              this.cdr.markForCheck();
          },
          error => {
              this.toastr.error("The Verificaton code you entered is Invalid");
          }
      );
  }

  onSubmit() {
    let edit = false
    if (this.model.id) {
        edit = true;
    }
    let api:Observable<{message:string,quickbook_cred:QbooksSettings}> = null;
    api = this.suborgService.saveQbooksSettings(this.sub_org,this.model);

    this.subs.sink = api.subscribe(
        resp => {
          if((!this.showViewButton&&!this.verificationSend)||this.is_create){
            this.settings = resp.quickbook_cred;
          }
            this.toastr.success(edit ? `Quickbooks Settings info updated` : `New Quickbooks Settings created`);
            this.is_create = false;
            this.modalRef?.dismiss();
            this.cdr.markForCheck()
        },
        http_error => {
            if (http_error.error == "INVALID_SW_CREDENTIALS") {
                this.toastr.error("Invalid credentials");
            }
            else if (http_error.error == "DUPLICATE") {
                this.toastr.error("Credentials already exists");
            }
            else {
                this.toastr.error(edit ? `Failed to update Quickbooks Settings` : `Failed to create Quickbooks Settings`)
            }
        }
    )
}

connectToQb(suborg_id:number){

  this.subs.sink = this.suborgService.connectToQbooks(suborg_id).subscribe(resp =>{

    if(resp && resp['auth_url']){
    console.log(resp,'resp++');
    window.open(resp['auth_url'], "_blank");
    }else{
      this.toastr.error("Failed to connect Quickbooks.Please try again later.");
    }
  })
}

  ngOnDestroy(): void { this.subs.unsubscribe(); }

}
