import { SubSink } from 'subsink';
import { CategoryService } from './../../services/category.service';
import { ItemCategoryWrapper } from './../../models/items';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { CreatedSource, ModuleConstants } from 'src/app/core/enums/common.enum';
import * as popup from 'src/app/core/utils/popup.functions';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { DataImportMeta, UIPermission } from 'src/app/core/models/common.models';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
    selector: 'app-item-categories',
    templateUrl: './item-categories.component.html',
    styleUrls: ['./item-categories.component.scss']
})
export class ItemCategoriesComponent implements OnInit, OnDestroy {

    @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

    @ViewChild('searchInput') searchInput: ElementRef;

    public SelectionType = SelectionType;

    public CreatedSource = CreatedSource;

    public rows: ItemCategoryWrapper[];

    public searchText: string;

    public scrollbarH: boolean = false;

    public defaults: GFilterParam[] = [
        { displayName: "All Categories", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Active Categories", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
        { displayName: "Inactive Categories", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
    ];

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Name or Prefix', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Name', colname: 'categoryname', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Prefix', colname: 'categoryprefix', condition: 'contains', value: '', operator: 'OR' }
            ]
        },
        { displayName: 'Parent Category', colname: 'parentname', condition: 'contains', value: '', operator: 'AND' },
        { displayName: 'Description', colname: 'description', condition: 'contains', value: '', operator: 'AND' },
    ];

    public columns: GFColumn[] = [
        { displayName: 'Name', columnName: 'categoryname', columnType: GFColumnTypes.string },
        { displayName: 'Prefix', columnName: 'categoryprefix', columnType: GFColumnTypes.string },
        { displayName: 'Parent Category', columnName: 'parentname', columnType: GFColumnTypes.string },
        { displayName: 'Status', columnName: 'is_active', columnType: GFColumnTypes.boolean },
        { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date }
    ]

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public sortLookup = { "category_name": 'categoryname', 'category_prefix': 'categoryprefix', 'parent_name': 'parentname' };

    public sort: GSortParam[] = [{ colname: 'categoryprefix', direction: 'asc' }];

    public expanded: boolean = true;

    public selected: ItemCategoryWrapper;

    private subs: SubSink = new SubSink();

    public module: ModuleConstants = ModuleConstants.ITEM_CATEGORIES;

    public importColumns: DataImportMeta[] = [
        { table_name: 'ItemsCategories', actual_column_name: 'category_name', display_name: 'Category - Name', excel_column_name: undefined },
        { table_name: 'ItemsCategories', actual_column_name: 'parent', display_name: 'Category - Parent Category', excel_column_name: undefined },
        { table_name: 'ItemsCategories', actual_column_name: 'category_prefix', display_name: 'Category - Prefix', excel_column_name: undefined },
        { table_name: 'ItemsCategories', actual_column_name: 'start_range', display_name: 'Category - Start Range', excel_column_name: undefined },
        { table_name: 'ItemsCategories', actual_column_name: 'end_range', display_name: 'Category - End Range', excel_column_name: undefined },
        { table_name: 'ItemsCategories', actual_column_name: 'digits', display_name: 'Category - Digits', excel_column_name: undefined },
        { table_name: 'ItemsCategories', actual_column_name: 'create_partnumber', display_name: 'Category - Create Partnumber(Boolean)', excel_column_name: undefined },
        { table_name: 'ItemsCategories', actual_column_name: 'description', display_name: 'Category - Description', excel_column_name: undefined }
    ];

    public isAllSuborg:boolean;

    public UIPermissions:UIPermission;

    constructor(private categoryService: CategoryService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        device: DeviceDetectorService,
        private ra: ResourceAccessService) {

        this.scrollbarH = device.isMobile();
        this.isAllSuborg = categoryService.isAllSuborg;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_CATEGORIES, ResourceConstants.INV_MODULE);

        if (this.isAllSuborg) {
            this.columns.push({ displayName: 'Sub Org', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentValue: this.categoryService.customerId });
        }
    }

    ngOnInit(): void {
        this.getData();
    }

    getData(filters?: GFilterParam[], offset: number = 0): void {

        let option = { sort: this.sort, filters: filters };

        this.subs.sink = this.categoryService.getAllFiltered(option, offset, 50).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }): void {
        let current: ItemCategoryWrapper = selected[0];
        if (current && this.UIPermissions.viewInfo) {
            this.selected = current;
            this.expanded = false;
            this.refreshDataTable();
        }
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    deleteCategory(id: number): void {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.categoryService.delete(id).subscribe(
                    () => {
                        popup.CompleteDelete('Category has been deleted.');
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        this.closeDetailedView();
                    },
                    () => this.toastr.error('Unable to delete row')
                );
            }
        });
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.refreshDataTable();
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    changeStatus() {
        let status = this.selected.is_active;
        let opt = {
            title: `Are you sure you want to ${status ? 'Activate Category' : 'Deactivate Category'}?`,
            text: this.selected.category_name,
            current: status,
        };
        popup.ConfirmActivate(opt, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.categoryService.patch(this.selected.id, { is_active: status }).subscribe(
                    () => { },
                    () => {
                        this.selected.is_active = !status;
                        this.toastr.error("Failed to change status");
                        this.cdr.markForCheck();
                    }
                );
            }
            else {
                this.selected.is_active = !status;
                this.cdr.markForCheck();
            }
        });
    }

    changeShowOnCatelog() {
        let opt = {
            title: `Are you sure you want to ${this.selected.show_on_catelog ? 'Add to' : 'Remove from'} Catelog?`,
            text: this.selected.category_name,
            current: this.selected.show_on_catelog,
        };
        popup.ConfirmActivate(opt, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.categoryService.patch(this.selected.id, { show_on_catelog: this.selected.show_on_catelog }).subscribe(
                    () => { },
                    () => {
                        this.selected.show_on_catelog = !this.selected.show_on_catelog;
                        this.toastr.error("Failed to change status");
                        this.cdr.markForCheck();
                    }
                );
            }
            else {
                this.selected.show_on_catelog = !this.selected.show_on_catelog;
                this.cdr.markForCheck();
            }
        });
    }

    onImportSubmit(request: any) {
        this.subs.sink = this.categoryService.importCategories(request).subscribe(
            resp => {
                this.toastr.success('Import has started you will be emailed when it is finished.');
                this.getData()
            },
            err => {
                this.toastr.error('Import failed')
            }
        );
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
