import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { GridViewInfo } from '../../models/grid-profiling.models';
import { GridViewsService } from '../../services/grid-views.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-views2role-list',
  templateUrl: './views2role.component.html',
  styleUrls: ['./views2role.component.scss']
})
export class Views2RoleComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ViewChild('content') modal: any;

  @Input() roleId: number;

  @Input() count: number;

  @Input() roleName: string;

  @Output() countChange = new EventEmitter<number>();

  public rows: GridViewInfo[];

  private gridViewIds: number[];

  public viewExist: boolean = false;

  private subs: SubSink = new SubSink();

  public scrollbarH: boolean;

  constructor(private viewService: GridViewsService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService) {

    this.scrollbarH = this.device.isMobile();
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roleId && changes.roleId.currentValue && changes.roleId.currentValue > 0) {
      this.getData();
    }
  }

  getData(): void {
    this.subs.sink = this.viewService.getViewsByRole(this.roleId).subscribe(resp => {
      this.countChange?.emit(resp.gridviews.length);
      this.gridViewIds = resp.gridviews.map(item => item.id);
      this.rows = resp?.gridviews || [];
      this.cdr.markForCheck();
    });
  }

  showAddViewPopup() {
    this.modalService.open(this.modal, { centered: true });
  }

  onSubmit(view: GridViewInfo) {
    this.subs.sink = this.viewService.assignView2Role(this.roleId, view.id).subscribe(
      _resp => {
        this.gridViewIds = this.gridViewIds ?? [];
        this.gridViewIds.push(view.id);
        this.rows = this.rows.concat(view);
        this.countChange?.emit(this.rows.length);
        this.cdr.markForCheck();
        this.modalService.dismissAll();
      },
      _error => {
        this.modalService.dismissAll();
        this.toastr.error('Failed to add view to role');
      }
    );
  }

  deleteView2Role(id: number) {
    this.subs.sink = this.viewService.unAssignView2Role(this.roleId, id).subscribe(
      () => {
        this.toastr.success('1 row deleted');
        this.rows = this.rows.filter(m => m.id !== id);
        this.gridViewIds = this.gridViewIds.filter(viewid => viewid !== id);
        this.countChange?.emit(this.rows.length);
        this.cdr.markForCheck();
      },
      _error => this.toastr.error('Delete error')
    );
  }

  viewSelectOnChange(view: GridViewInfo) {
    this.viewExist = this.gridViewIds && this.gridViewIds.includes(view.id);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
