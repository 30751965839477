import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Supplier } from '../../models/supplier';
import { SupplierService } from '../../services/supplier.service';

@Component({
  selector: 'app-supplier-select',
  template:`<ng-select
              [placeholder]="placeholder" 
              [items]="options | orderBy:'supplier_name'" 
              bindLabel="supplier_name" 
              bindValue="id" 
              [(ngModel)]="selected" 
              [readonly]="readonly" 
              (change)="onValueChanged($event)" 
              (clear)="onSelectClear()" 
              [searchable]="true">
            </ng-select>`
})
export class SupplierSelectComponent implements OnInit, OnDestroy {

  @Input() value: any;

  @Input() readonly: boolean = false;

  @Input() placeholder: string = "Select Supplier";

  @Output() valueChange = new EventEmitter<any>();

  @Output() onChange = new EventEmitter<Supplier>();

  @Output() onClear = new EventEmitter();

  selected: any;

  getSub$: Subscription;

  options: Supplier[];

  public Permissions = PermissionConstants;

  constructor(private supplierService: SupplierService,
    private cdr: ChangeDetectorRef,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    this.getSub$ = this.supplierService.getAll().subscribe(suppliers => {
      this.options = suppliers;
      this.cdr.markForCheck();
    });
  }

  onValueChanged(value: any) {
    this.value = value.id;
    this.valueChange.emit(value.id);
    this.onChange?.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      this.selected = changes.value.currentValue;
      this.cdr.markForCheck();
    }
  }

  onSelectClear() {
    this.valueChange?.emit(undefined);
    this.onClear?.emit();
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.INV_SUPPLIERS, [permission, this.Permissions.GLOBAL]);
  }

  public getSelected(): Supplier {
    if (this.value > 0) {
      return this.options.find(sup => sup.id === this.value);
    }
    return null;
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
  }
}
