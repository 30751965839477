<section class="pay-now" *ngIf="!this.invalidLink">

    <div class="payment-req-form">

        <a (click)="goBack($event)" href  class="float-left mt-2 ml-3" style="font-size: 16px;"><i class="ft-chevrons-left"></i> Back</a>

        <div class="bg-white shadow-sm pt-4 pl-2 pr-2 pb-2 mt-2">
            
            <!-- Credit card form tabs -->
            <ul role="tablist" class="nav bg-light nav-pills rounded nav-fill mb-3">
                <li class="nav-item"> <a data-toggle="pill"  class="nav-link active " (click)="changePaymentMethod(paymentMethods.CARD)"> 
                    <img  width="30"  src="/assets/img/svg/cc/credit_card.svg" /> 
                    Online Payment </a> </li>
                <li class="nav-item"> <a data-toggle="pill"  class="nav-link " (click)="changePaymentMethod(paymentMethods.CASH)">
                    <img  width="30"  src="/assets/img/svg/cc/cash.svg" /> 
                    Cash </a> </li>
                <li class="nav-item"> <a data-toggle="pill"  class="nav-link " (click)="changePaymentMethod(paymentMethods.CHECK)">
                    <img  width="30"  src="/assets/img/svg/cc/check.svg" /> 
                    Check </a> </li>
            </ul>
        </div>




        <div style="padding: 35px;">
            <!-- <h6 class="mb-3 card-title" style="font-size: 18px;">Payment Details</h6> -->

            <div class="form-group" style="margin-bottom: 1.5rem">
                <label class="text-muted">Payment for</label>
                <h6 class="mb-2 card-title">Suborg Name</h6>
            </div>

            <div class="form-group">

                <div class="row">
                    <div class="col-md-7">
                        <label class="text-muted">AMOUNT PAYABLE</label>
                        <h6 class="mb-2 card-title font-weight-bolder">
                            {{model.amount | currency}}
                            </h6>
                    </div>
                    <!-- <div class="col-md-5">
                        <label class="text-muted">Balance Due</label>
                        <h6 class="mb-2 card-title font-weight-bolder">
                           </h6>
                    </div> -->
                </div>

            </div>

            

            <!-- <div class="form-group" style="clear: both;">
                <label class="text-muted">Customer Details</label>
                <p class="font-small-3 font-weight-normal mb-1 mt-1">Name :
                     </p>
                <div class="row font-small-3 font-weight-normal">
                    <div class="col-md-7"><p>Email :
                            
                        </p></div>
                    <div class="col-md-5"><p>Number :
                          
                        </p></div>
                </div>

            </div> -->

        </div>

        <div class="payment-req-form-bottom text-muted">
            <small>For any queries please contact simplyfuse</small><br>
            <small><i _ngcontent-snc-c170 class="ft-mail mr-1"></i>support@simplyfuse.com</small>
        </div>

    </div>

    <div class="payment-form" *ngIf="customersPaymentDatas">

         <app-payment *ngIf="selectedPaymentMethod == paymentMethods.CARD"
            [myCards]="customersPaymentDatas?.customer_card"
            [default_card]="customersPaymentDatas?.default_card"
            [myChecks]="customersPaymentDatas?.customer_check"
            [default_check]="customersPaymentDatas?.default_check"
            [amount]="this.model.amount"
            [model]="this.model"
            (onSuccess)="paymentSuccess()"
            (onError)="paymentError($event)"
            [context]="this"
            ></app-payment>

        <cash-check-payment *ngIf="selectedPaymentMethod == paymentMethods.CASH || selectedPaymentMethod == paymentMethods.CHECK"
        [amount]="this.model.amount"
        [model]="this.model"
        [method]="this.selectedPaymentMethod"
        (onSuccess)="paymentSuccess()"
        (onError)="paymentError($event)"
        [context]="this"></cash-check-payment>      

    </div>

</section>

<div class="row auth-height full-height-vh" *ngIf="this.invalidLink">
    <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="row">
            <div class="col-12 text-center">
                <img src="/assets/img/gallery/error.png" alt height="300"
                    width="400" class="img-fluid error-img mt-2">
                <h1 class="mt-4">Invalid Link!</h1>
                <div class="w-75 error-text mx-auto mt-4">
                    <p>The Link you are looking for is not unavailable.</p>
                </div>
                <a (click)="goBack($event)" href="/" class="btn btn-warning my-2">Go Back</a>
            </div>
        </div>
    </div>
</div>