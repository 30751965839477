export class SubOrg {

  public id: number;

  public name: string;

  public address: string;

  public city: string;

  public email: string;

  public phone_number: string;

  public state: string;

  public tax_id: string;

  public time_zone: string;

  public website: string;

  public zip: string;

  public is_root: boolean = false;

  public is_delete: boolean;

  public is_active: boolean;

  public created_by: string;

  public created_on: string;

  public customer: number;

  customer_name:string;

  public images: string[] = [];

  public primary_image: string;

  use_global_reason: boolean = true;
}
