import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from "@angular/core";
import { PermissionConstants } from "src/app/core/constants/permissions.constants";
import { ResourceConstants } from "src/app/core/constants/resources.constants";
import { ResourceAccessService } from "src/app/core/services/resource-access.service";



@Directive({
    selector:'[callCenterDragExpand]'
})

export class callCenterDragExpandDirective{

    public startY:number;

    public mouseDown:boolean=false;

    public height:number;

    public startHeight:number;

    public startTop:number;

    @Input() target:any;

    @Input() minHeight:number;

    @Output() resized=new EventEmitter<any>();



    public listenerFn = () => {};


    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;


    constructor(private elementRef:ElementRef, 
      private renderer:Renderer2, 
      private ra:ResourceAccessService){
        
    }
    ngOnInit(){

        // console.log(this.target.getBoundingClientRect(), 'targer');

    }
 
    
    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {

          this.mouseDown=true;

          event.preventDefault();

          this.startY = event.clientY;

          this.startHeight=this.target.getBoundingClientRect().height;

          this.startTop=this.elementRef.nativeElement.getBoundingClientRect().top;
    
          // console.log('mouse down ', this.startX);
    
          this.renderer.addClass(document.body, 'slide-expand-selection');
          this.listenerFn=this.renderer.listen('window', 'mousemove', this.onMouseMove.bind(this));
          this.renderer.listen('window', 'mouseup', this.onMouseUp.bind(this));




    }

    onMouseMove(event: MouseEvent) {


        if(this.mouseDown&&this.startY&&this.startY>0&&event.clientX&&event.clientX>0){

            if(this.startY&&this.startY!=null&&event.clientX!=null){
                const diff=(event.clientY - this.startY);
              this.height=diff;

              // console.log(this.width,'diff')

              let width=this.target.getBoundingClientRect().width;
              let newHeight=this.startHeight+diff;

              // let parentWidth=this.parent.nativeElement?.getBoundingClientRect()?.width;

              if(newHeight>=this.minHeight){
                this.renderer.setStyle(this.target, 'height',  newHeight+ 'px');
        //   this.renderer.setStyle(this.eventDiv.toArray()[this.index].nativeElement, 'width', width + 'px');
                
                 this.resized.emit({width:this.target.getBoundingClientRect().width, complete:false});

                

              }

            // this.renderer.setStyle(this.elementRef.nativeElement, 'width',  this.startLeft+diff+ 'px');


            }
            else{
                this.height=0;
            }
    
    
            // console.log('mouse on move');


        }
        else{
            // console.log('Moving over an event');
        }


  
        //   this.renderer.setStyle(this.eventDiv.toArray()[this.index].nativeElement, 'width', width + 'px');
        //   this.renderer.setStyle(this.eventDiv.toArray()[this.index].nativeElement, 'left',  this.initLeft+diff+ 'px');
  
      }

      onMouseUp(event:MouseEvent) {


            if(this.mouseDown&&this.startY&&this.height){

                this.renderer.removeClass(document.body, 'slide-expand-selection');
      

                    //   console.log('mouse up');
              
                      this.listenerFn();



            
                this.resized.emit({height:this.target.getBoundingClientRect().height, complete:true});

              }
              else{
                // console.log('clicked on a event');
              }

              this.mouseDown=false;
              this.startY=null;
              this.height=null;




       }


        hasPermission(resource: string, permission: string) {
          return this.ra.hasPermission(resource, [permission], ResourceConstants.DISPATCH_MODULE);
        }
        

}