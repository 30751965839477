import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TwoFactorTypes } from 'src/app/core/enums/common.enum';
import { AuthService } from 'src/app/core/services/auth.service';
import { SubSink } from 'subsink';
import { User, UserSettings } from '../../models/user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  private userid: number;

  @Input() set userId(value: number) {
    this.userid = value;
    if (value) {
      this.getUserSettings()
    }
  }

  get userId() { return this.userid; }

  @Input() user: User;

  public settings: UserSettings;

  public code: string;

  private subs = new SubSink();

  private modalRef: NgbModalRef;

  public TwoFactorType = TwoFactorTypes;

  public invalid_otp:boolean = false;

  constructor(private userService: UserService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    public modal: NgbModal,
    private authService: AuthService) {
  }

  ngOnInit(): void {}

  getUserSettings() {
    this.subs.sink = this.userService.getUserSettings(this.userId).subscribe(resp => {
      this.settings = resp;
      this.cdr.markForCheck();
    })
  }

  openModal(content: any) {
    this.code = '';
    this.modalRef = this.modal.open(content)
  }

  verifyCode() {
    if (this.code && this.code.length == 6) {

      let request = { userid: this.userId, otp: this.code, type: TwoFactorTypes.GOOGLE_AUTHENTICATOR };

      this.subs.sink = this.authService.verifyOtp(request).subscribe(resp => {
        this.invalid_otp = resp.valid;
        this.cdr.markForCheck();
        if (resp.valid) {
          this.toastr.success("Authenticator verified :)");
          this.modalRef?.dismiss();
        }
        else {
          this.toastr.error("Verification failed");
        }
      });
    }
  }

  update2FA(event: any) {
    const checked = event.target.checked;
    this.subs.add(this.userService.updateUserSettings(this.userId, { two_factor_authentication: checked }).subscribe(resp => {
      this.settings.two_factor_authentication = resp.two_factor_authentication;
      this.cdr.markForCheck();
    }));
  }

  changeDefault2faMethod(type: TwoFactorTypes) {
    type = type ?? null;
    this.subs.add(this.userService.updateUserSettings(this.userId, { two_factor_default: type }).subscribe(resp => {
      this.cdr.markForCheck();
    }));
  }

  authenticatorVerificationChange(event: any) {
    let checked = event.target.checked;
    this.subs.add(this.userService.updateUserSettings(this.userId, { two_factor_authapp: checked }).subscribe(resp => {
      this.settings.two_factor_authapp = resp.two_factor_authapp;
      this.cdr.markForCheck();
    }))
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
