import { StatusValueEnum } from "src/app/core/enums/common.enum";
import { CallLog } from "./reports.models";

export class SIPdetailsResponse {

  public sip_uri:string;

  public password:string;

  public websocket_uri:string;

  public via_transport:string;

  public register_server:string;

  public contact_uri:string;

  public status:string=StatusValueEnum.OFFLINE;

  public associated_username:string;

  public agent_name:string;

  public sip_id:string;

  public status_id:number;

  tenant_subscription:boolean;

  user_subscription:boolean;

  auto_open:boolean;

  auto_open_add_contact:boolean;

  public send_as:string = '';

}


export class UserStatusUpdateRequest {

  public id:number;

  public sip_id:string;

  public associated_user_id:number;

  public status:string;

  public associated_username:string;

  public auto_open:boolean;

  public auto_open_add_contact:boolean;

  public call_end_time: string;
}

export class callDetailsResponse {

  public call_id: string;

  public sw_project_id: string;

  public call_from: number;

  public call_to: number;

  public call_to_sid: string;

  public created_on: string;

  public modified_on: string;

  public is_delete: boolean;

  public call_to_org_name: string;

  public call_to_suborg_name: string;

  public call_to_suborg_id: string;

  public call_to_did_name: string;

  public contact_name: string;

  public contact_email: string;

  public contact_mobile: number;

  public contact_phone: number;

  public tenant_customer_name: string;

  public location_name: string;

  public location_address: string;

  public location_city: string;

  public location_state: string;

  public location_zip: number;

  public documents : Documents[];

  block_id:number;

  contacts_id:number;

  tenant_customer_id:number;

  message?:string;

  status?:boolean;
  
  contact_call_history: CallLog[] = []

}

export class Documents {

  public id: number;

  public type: string;

  public created_on: string;

  public reason: string;

  public unique_number: string;
}

export class STandSOresponseFromAgentUi{

  public id: number;

  public type: string;

  public created_on: Date;

  public reason: string;

  public unique_number: string;

}
 export class CallPanelOpenModel{
 
   public isOpen:boolean = false

   public selectedTab:number

 }

 export class CallCenterSpec{

  public left:number;

  public top:number;

  public height:number;

  public width:number;

 }



