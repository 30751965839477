<div class="select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" [placeholder]="placeholder" [items]="options | async" bindValue="id" [(ngModel)]="selected" [readonly]="readonly" [searchFn]="searchFn" (change)="onChange($event)">
        <ng-template ng-label-tmp let-item="item">
            {{item.first_name}} {{item.last_name}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{item.first_name}} {{item.last_name}}
        </ng-template>
        <ng-template ng-footer-tmp *ngIf="addButton && hasPermission()">
            <button type="button" class="select-footer-btn" (click)="openModal(content)">
                <i class="icon icon-settings"></i><span class="text">Add Contact</span>
            </button>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Contact</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <distributor-contact-form [distributorId]="distributorId" (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()" (locationAdd)="onLocationAdd()"></distributor-contact-form>
        </div>
    </ng-template>
</div>