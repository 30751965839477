import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { RolePrivilege } from './../../models/role-previlege';
import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { RoleService } from '../../services/role.service';
import { SubSink } from 'subsink';

@Component({
    selector: 'role-previleges',
    templateUrl: './previleges.component.html',
    styleUrls: ['./previleges.component.scss']
})
export class PrevilegesComponent implements OnInit, OnDestroy {

    private innerRoleId: number;

    @Input() set roleId(value: number) {
        if (value !== this.innerRoleId) {
            this.innerRoleId = value;
            this.getPrevilages();
        }
    };

    get roleId() { return this.innerRoleId; }

    @Input() markable: boolean = false;

    @Input() markAllDefault: boolean = false;

    @Input() actions: boolean = false;

    @Input() count: number;

    @Output() countChange = new EventEmitter<number>();

    @Input() footer: boolean = true;

    public privilleges: RolePrivilege[];

    selected: RolePrivilege[] = [];

    public get selectionType() {
        return this.markable ? SelectionType.checkbox : null;
    }

    private subs: SubSink = new SubSink();

    public scrollbarH: boolean;

    constructor(
        private roleService: RoleService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private device: DeviceDetectorService) {

        this.scrollbarH = this.device.isMobile();
    }

    ngOnInit() { }

    getPrevilages() {
        this.subs.sink = this.roleService.getPrivileges(this.roleId).subscribe(privilages => {
            this.privilleges = privilages;
            this.countChange.emit(this.privilleges.length);
            this.cdr.markForCheck();
        });
    }

    public getSelected(): RolePrivilege[] {
        return this.selected;
    }

    changePrevilegeStatus(row: RolePrivilege): void {
        this.subs.sink = this.roleService.patchPrevilage(row.id, row.role, { allowed: row.allowed }).subscribe(
            prev => { },
            error => {
                console.error(error);
                row.allowed = !row.allowed;
            }
        );
    }

    deletePrivilege(id: number) {
        this.subs.sink = this.roleService.deletePrivilege(this.roleId, id).subscribe(
            () => {
                this.toastr.success('Privilege deleted');
                this.privilleges = this.privilleges.filter(p => p.id !== id);
                this.cdr.markForCheck();
            },
            error => this.toastr.error('Failed to delete previlege')
        )
    }

    public pushPrivilege(privilege: RolePrivilege) {
        this.privilleges = this.privilleges.concat(privilege);
    }

    public exist(resourceId: number, permissionId: number) {
        return this.privilleges.some(privilege => {
            return privilege.resource === resourceId && privilege.permission === permissionId
        });
    }
    // this.privillegesChange.emit(this.privilleges);
    // this.countChange?.emit(this.privilleges.length);
    // this.cdr.markForCheck();
  



  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
