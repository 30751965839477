import { DistributorCustomer } from './../models/distributor';
import { BaseService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Distributor, DistributorContact, DistributorLicense, DistributorLocation } from '../models/distributor';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LicenseNumber } from '../../tenant-customers/models/license-number';
import { CustomerRep } from '../../tenant-customers/models/tenant-customer';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';

@Injectable({
  providedIn: 'root'
})
export class DistributorService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll(option: any, offset: number = 0) {
    let url = `${this.baseURL}/filter_distributor/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<{ count: number, result: Distributor[] }>(url, option).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getById(id: number) {
    return this.http.get<Distributor>(`${this.baseURL}/distributors/${id}/`);
  }

  create(model: Distributor) {
    return this.http.post<Distributor>(`${this.baseURL}/distributors/`, model);
  }

  update(id: number, model: Distributor) {
    return this.http.put<Distributor>(`${this.baseURL}/distributors/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<Distributor>(`${this.baseURL}/distributors/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/distributors/${id}/`);
  }

  getDistributorContacts(distributors_id: number) {
    return this.http.get<DistributorContact[]>(`${this.baseURL}/distributors/${distributors_id}/contacts/`);
  }

  getDistributorContactById(contactid: number) {
    return this.http.get<DistributorContact>(`${this.baseURL}/distributors/contacts/${contactid}/`);
  }

  createDistributorContact(model: DistributorContact) {
    return this.http.post<DistributorContact>(`${this.baseURL}/distributors/contacts/`, model);
  }

  updateDistributorContact(id: number, model: DistributorContact) {
    return this.http.put<DistributorContact>(`${this.baseURL}/distributors/contacts/${id}/`, model);
  }

  patchDistributorContact(id: number, request: any) {
    return this.http.patch<DistributorContact>(`${this.baseURL}/distributors/contacts/${id}/`, request);
  }

  deleteDistributorContact(id: number) {
    return this.http.delete(`${this.baseURL}/distributors/contacts/${id}/`);
  }

  getDistributorLocations(distributors_id: number) {
    return this.http.get<DistributorLocation[]>(`${this.baseURL}/distributors/${distributors_id}/location/`);
  }

  createDistributorLocation(model: DistributorLocation) {
    return this.http.post<DistributorLocation>(`${this.baseURL}/distributors/location/`, model);
  }

  updateDistributorLocation(id: number, model: DistributorLocation) {
    return this.http.put<DistributorLocation>(`${this.baseURL}/distributors/location/${id}/`, model);
  }

  patchDistributorLocation(id: number, request: any) {
    return this.http.patch<DistributorLocation>(`${this.baseURL}/distributors/location/${id}/`, request);
  }

  deleteDistributorLocation(id: number) {
    return this.http.delete(`${this.baseURL}/distributors/location/${id}/`);
  }

  getAccountRep(distributors_id: number) {
    return this.http.get<CustomerRep[]>(`${this.baseURL}/distributor/${distributors_id}/accountrep/`);
  }

  getLicenseNumbers(distributors_id: number) {
    return this.http.get<LicenseNumber>(`${this.baseURL}/distributors/${distributors_id}/license/`)
  }

  getDistributorLicenses(request: DataFilterRequest, offset: number = 0, limit: number = 25) {
    let url = `${this.baseURL}/filter_distributor_license/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<DistributorLicense[]>>(url, request);
  }

  getDistributorLicenseById(id: number) {
    return this.http.get<DistributorLicense>(`${this.baseURL}/distributors_license/${id}/`);
  }

  createDistributorLicense(models: DistributorLicense[]) {
    return this.http.post<DistributorLicense[]>(`${this.baseURL}/distributors_license/`, models);
  }

  updateDistributorLicense(id: number, model: any) {
    return this.http.put<DistributorLicense>(`${this.baseURL}/distributors_license/${id}/`, model);
  }

  deleteDistributorLicense(id: number) {
    return this.http.delete(`${this.baseURL}/distributors_license/${id}/`);
  }

  //distributor associated customers apis

  getDistributorCustomers(request: DataFilterRequest, offset: number = 0, limit: number = 25) {
    let url = `${this.baseURL}/filter_distributor_tenantcustomer/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<DistributorCustomer[]>>(url, request);
  }

  getDistributorCustomerById(id: number) {
    return this.http.get<DistributorCustomer>(`${this.baseURL}/distributors/tenant_customer/${id}/`);
  }

  createDistributorCustomer(model: DistributorCustomer) {
    return this.http.post<DistributorCustomer>(`${this.baseURL}/distributors/tenant_customer/`, model);
  }

  updateDistributorCustomer(id: number, model: any) {
    return this.http.put<DistributorCustomer>(`${this.baseURL}/distributors/tenant_customer/${id}/`, model);
  }

  deleteDistributorCustomer(id: number) {
    return this.http.delete(`${this.baseURL}/distributors/tenant_customer/${id}/`);
  }
}
