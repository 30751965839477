
export class ChatHeadRequest {

    public customer_id?:number;

    public suborg_id: number;

    public limit: number = 50;

    public offset: number = 0;

    public search?: string;

    updateOffset() {
      this.offset = this.offset + this.limit;
    }

    reset(){
      this.offset = 0;
    }
}

export interface ChatHead {

    name: string;

    count: number;

    did: string;

    last_message_on: string;

    read:boolean;

    sub_org_id:number;
}

export interface Message {

    sid: string;

    body: string;

    direction: string;

    sent_from: string;

    status: string;

    sent_to: string;

    date_sent: Date;

    has_media: boolean;

    media_uri: string;

    sub_org: number;

    read: boolean;
}
