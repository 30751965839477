import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription, Observable, Subject, concat, of } from 'rxjs';
import { ItemCategory, ItemCategoryWrapper } from '../../../models/items';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { CategoryService } from '../../../services/category.service';

@Component({
    selector: 'category-select',
    templateUrl: './item-category-select.component.html',
    styleUrls: ['./item-category-select.component.scss']
})
export class ItemCategorySelectComponent implements OnInit, OnDestroy {

    public Permissions = PermissionConstants;

    @Input() value: number;

    @Input() readonly: boolean = false;

    @Input() showDescription: boolean = false;

    @Input() placeholder: string = "Select Category";

    @Input() addButton: boolean = false;

    @Output() setDefault = new EventEmitter<ItemCategory>();

    @Output() valueChange = new EventEmitter<number>();

    @Output() change = new EventEmitter<ItemCategory>();

    @Output() clear = new EventEmitter();

    public categories: Observable<ItemCategoryWrapper[]>;

    public categoryInput = new Subject<string>();

    public selected: ItemCategoryWrapper;

    public totalCount: number = 0;

    public currentCount: number = 0;

    public isHeaderVisible: boolean;

    public sort = [{ colname: 'categoryname', direction: 'asc' }];

    private getSub$: Subscription;

    constructor(private categoryService: CategoryService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private ra: ResourceAccessService) { }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.categories = concat(this.getCategories(null, 0, 50), this.categoryInput.pipe(
            filter(res => {
                return res !== null && res.length >= 2
            }),
            distinctUntilChanged(),
            switchMap(term => this.getCategories(term))
        ));
    }

    getCategories(search: string, offset: number = 0, limit: number = 0) {
        let option = { sort: this.sort, filters: null };
        if (search) {
            option.filters = [{ colname: 'categoryname', condition: 'contains', value: search, operator: 'AND' }]
        }
        return this.categoryService.getAllFiltered(option, offset, limit).pipe(
            map(resp => {
                if (resp.count) {
                    this.totalCount = resp.count;
                }
                this.isHeaderVisible = resp.result.length > 0;
                this.currentCount = resp.result.length;
                return resp.result;
            }),
            catchError(() => of([])),
        );
    }

    trackByFn(category: ItemCategoryWrapper) {
        return category.id;
    }

    setSelected(id: number) {
        if (this.selected) return;
        this.getSub$ = this.categoryService.getById(id).subscribe(category => {
            this.selected = { ...new ItemCategoryWrapper(), ...category };
            this.setDefault?.emit(this.selected);
            this.cdr.markForCheck();
        });
    }

    onValueChanged(selected: ItemCategoryWrapper) {
        if (selected) {
            this.value = selected.id;
            this.valueChange?.emit(selected.id);
            this.change?.emit(selected);
        }
        else {
            this.value = null;
            this.valueChange?.emit(null);
        }
    }

    openModal(content: any): void {
        this.modalService.open(content, { size: 'lg' });
    }

    onDataSuccess(result: { response: ItemCategory, message: string }): void {

        this.toastr.success('Success', result.message);
        let obj = { ...new ItemCategoryWrapper(), ...result.response };
        this.categories = concat(this.categories, of([obj]));
        this.selected = obj;

        this.valueChange?.emit(result.response.id);
        this.change?.emit(result.response);
        this.modalService.dismissAll();
    }

    onDataError(_error: any): void {
        this.toastr.error('Operation failed');
        this.modalService.dismissAll();
    }

    onCancel(): void {
        this.modalService.dismissAll();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && changes.value.currentValue > 0) {
            this.setSelected(changes.value.currentValue);
        }
    }

    onSelectionClear() {
        this.valueChange?.emit(undefined);
        this.clear?.emit();
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.INV_CATEGORIES, [permission, this.Permissions.GLOBAL]);
    }

    ngOnDestroy(): void {
        this.getSub$?.unsubscribe();
    }
}
