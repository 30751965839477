import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { GFilterParam, GSortParam, Pagination, GFColumn, DataFilterRequest, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { SelectionType } from '@swimlane/ngx-datatable';
import * as popup from 'src/app/core/utils/popup.functions';
import { Ring2voiceService } from '../../services/ring2voice.service';
import { SubSink } from 'subsink';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AdvSettingsTimeFrame, AdvancedCallSettingsReqest, AdvancedCallSettingsResponse, AfterTimeout, ForwardBin, IntelligentMenuPayload, IntelligentMenuRouting, MenuDetailsResponse, MenuSettings, RoutingSection, TimeFramePayload, nestedAfterTimeOut } from '../../models/forward-bin.models';
import { ToastrService } from 'ngx-toastr';
import { AfterTimeoutOption, CallRingTypeEnum, CallRoutingOptionEnum, ModuleConstants, NumberBlockTypeEnum } from 'src/app/core/enums/common.enum';
import { WINDOW } from 'src/app/core/services/window.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import * as momentTz from 'moment-timezone';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-forward-numbers',
    templateUrl: './forward-numbers.component.html',
    styleUrls: ['./forward-numbers.component.scss']
})
export class ForwardNumbersComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    public rows: ForwardBin[];

    public expanded: boolean = true;

    public isSuperAdmin: boolean;

    public subs: SubSink = new SubSink();

    public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public selected: ForwardBin;

    public advancedSettings: AdvancedCallSettingsResponse=new AdvancedCallSettingsResponse();

    public ringType=CallRingTypeEnum;

    public SelectionType = SelectionType;

    public blockType = NumberBlockTypeEnum;

    public routingSection:RoutingSection[]=[];

    public IntelligentMenuRouting:IntelligentMenuRouting[]=[];

    public OthertimeMenuRouting:IntelligentMenuRouting[]=[];

    public columns: GFColumn[] = [
        { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
        { displayName: 'Incoming Routes', columnName: 'forward_number', columnType: GFColumnTypes.phone },
        { displayName: 'Whisper', columnName: 'is_whisper', columnType: GFColumnTypes.boolean },
        { displayName: 'Announce', columnName: 'is_announce', columnType: GFColumnTypes.boolean },
        { displayName: 'Caller Input', columnName: 'is_gather', columnType: GFColumnTypes.boolean },
        { displayName: 'Created on', columnName: 'created_on', columnType: GFColumnTypes.date },
        { displayName: 'Call Center', columnName: 'call_center', columnType: GFColumnTypes.boolean },
        { displayName: 'Global Route', columnName: 'global_route', columnType: GFColumnTypes.boolean }
    ];

    public defaults: GFilterParam[] = [
        { displayName: "All Incoming Routes", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Active Incoming Routes", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
        { displayName: "Inactive Incoming Routes", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
    ];

    public search: GFilterParam[] = [
        {
            displayName: 'Combo Search', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Incoming Routes', colname: 'forward_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone }
            ]
        },
        { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Incoming Routes', colname: 'forward_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone }
    ];

    public sort: GSortParam[] = [{colname:'created_on',direction:'desc'}];

    public sortLookup = {
        name: 'name',
        forward_number: 'forward_number',
        created_on: 'created_on',
        suborg_name:'suborg_name'
    };

    public scrollbarH: boolean;

    public module = ModuleConstants.RING2VOICE_FORWARD_BINS;

    public UIPermissions: UIPermission;

    public selectedSid: string;

    public ringSelect: string;

    public routingOptionSelect: string[] = ['Ring', 'Announcement', 'Voicemail', 'Intelligent Menu', 'Time Based Routing'];

    public InnerRingOptionSelect: string[] = ['Ring', 'Announcement', 'Voicemail', 'Intelligent Menu'];

    public allOtherTimeRingOption: string[] = ['Ring', 'Announcement', 'Voicemail', 'Intelligent Menu'];

    public menuNumber : number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    public voices:any = {};

    public timezones:string[]=[];

    public showFullText:boolean=false;

    public afterTimeoutOption = AfterTimeoutOption;

    private ngbModalRef: NgbModalRef;

    public selected_menu;

    public selectedMenuInputs: any[];

    constructor(private ring2voiceService: Ring2voiceService,
        device: DeviceDetectorService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        public location: Location,
        route: ActivatedRoute,
        private modalService: NgbModal,
        @Inject(WINDOW) private window: Window) {

        this.isSuperAdmin = ring2voiceService.isSuperUser

        if (this.isSuperAdmin) {
            this.columns.push(...[
                { displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref },
                { displayName: 'Sub Org (Choose Tenant First)', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentColumn: 'customer' }
            ]);
        }
        this.scrollbarH = device.isMobile();
        this.subs.sink = route.params.subscribe(params => this.selectedSid = params.sid);
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.R2V_FORWARD_NUMBERS, ResourceConstants.R2V_MODULE, true);
    }

    ngOnInit(): void {
        this.timezones=momentTz.tz.names();
        this.getVoices();
        this.getData();
        if (this.selectedSid) {
            this.getById(this.selectedSid);
            this.cdr.markForCheck();
        }

    }

    getData(filters: GFilterParam[] = null, offset: number = 0) {

        let request: DataFilterRequest = { filters: filters, sort: this.sort }

        this.subs.sink = this.ring2voiceService.getForwardBins(request, offset).pipe(
            catchError(() => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    getById(sid: string) {
        this.subs.sink = this.ring2voiceService.getForwardBinById(sid).subscribe(response => {
            this.selected = response;
            this.expanded = false;
            this.refreshDataTable();
            if(response.call_center){
                this.getAdvancedCallSettings(sid);
            }else{
                this.advancedSettings = new AdvancedCallSettingsResponse();
            }
            this.cdr.markForCheck();
        },
        err=> {
            let option: SweetAlertOptions = {
                title: err.error.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn btn-success',
                    htmlContainer: 'font-small-3',
                },
                buttonsStyling: false,
            };
            popup.OpenConfirmBox(option, (response) => {
                if (response.isConfirmed) {
                    this.location.go(`/ring2voice/incoming-routes`);
                }});
        })
    }

    getVoices() {
        this.subs.add(this.ring2voiceService.getVoices().subscribe(resp=> {
            this.voices = {};
            resp.forEach(obj => this.voices[obj.value] = obj.name);
        }));
    }

    getAdvancedCallSettings(sId:string){
        this.routingSection = [];
        this.IntelligentMenuRouting = [];
        this.OthertimeMenuRouting = [];
        this.ring2voiceService.getAdvancedCallSettings(sId).subscribe(res=>{
            if(!res.is_updated||res.time_frames.length==0){
                this.addNewRoutingSection();//creates new section 
                if(this.IntelligentMenuRouting.length<1){
                    // this.addNewMenuRouting();
                    // this.addNew2ndMenuRouting();
                }
            }
            // this.advancedSettings = res;
            if(res.time_frames.length!=0){
                this.advancedSettings.time_frames = res.time_frames;
                this.advancedSettings.time_frames.forEach(tf =>{
                    if(this.routingSection.length<3){
                        
                        const v = new RoutingSection(); // Create a new instance inside the loop
                        v.time_frame_id=tf.time_frame_id;
                        v.ring_option=tf.ring_option || 'Ring';
                        v.ring_type=tf.ring_type || this.ringType.NUMBER;
                        v.smartsipgroup_id=tf.smartsipgroup_id;
                        v.numbers=tf.numbers?tf.numbers.join(","):null
                        v.ring_timeout = tf.ring_timeout || null;
                        v.after_timeout.user_or_group = tf.after_timeout?.user_or_group || false;
                        v.after_timeout.user_group = tf.after_timeout?.user_group || null;
                        v.after_timeout.sip_username = tf.after_timeout?.sip_username || null;
                        v.after_timeout.ring_option = tf.after_timeout?.ring_option || this.afterTimeoutOption.RING;
                        v.after_timeout.ring_type = tf.after_timeout?.ring_type || this.ringType.NUMBER;
                        v.after_timeout.sip = tf.after_timeout?.sip;
                        v.after_timeout.numbers = tf.after_timeout?.numbers?tf.after_timeout?.numbers.join(","):null;
                        v.after_timeout.smartsipgroup_id = tf.after_timeout?.smartsipgroup_id;
                        v.after_timeout.announce_message = tf.after_timeout?.announce_message;
                        v.after_timeout.announce_gender = tf.after_timeout?.announce_gender;
                        v.after_timeout.ring_timeout = tf.after_timeout?.ring_timeout;

                        v.after_timeout.nested_after_timeout.ring_option = tf?.after_timeout?.nested_after_timeout?.ring_option||this.afterTimeoutOption.VOICEMAIL;
                        v.after_timeout.nested_after_timeout.announce_gender = tf.after_timeout?.nested_after_timeout?.announce_gender;
                        v.after_timeout.nested_after_timeout.announce_message = tf.after_timeout?.nested_after_timeout?.announce_message;
                        v.after_timeout.nested_after_timeout.sip_username = tf.after_timeout?.nested_after_timeout?.sip_username;
                        v.after_timeout.nested_after_timeout.user_group = tf.after_timeout?.nested_after_timeout?.user_group;
                        v.after_timeout.nested_after_timeout.user_or_group = tf.after_timeout?.nested_after_timeout?.user_or_group||false;
                        v.sip=tf.sip;
                        v.smartsipgroup=tf.smartsipgroup;
                        v.announce_gender = tf.announce_gender || null;
                        v.announce_message=tf.announce_message;
                        v.gather_gender = tf.gather_gender || null;
                        v.gather_message = tf.gather_message || null;
                        v.user_or_group = tf.user_or_group || false;//now
                        v.sip_username = tf.sip_username || null;
                        v.user_group = tf.user_group || null;
                        v.no_of_loops = tf.no_of_loops || null;
                        v.not_responding_message = tf.not_responding_message || null;
                        v.call_router = tf.call_router;
                        v.menu_settings = tf.menu_settings;
                        
                        this.routingSection.push(v);
                        if(v.call_router){
                            v.call_router?.forEach(element => {
                                const m = new IntelligentMenuRouting();
                                m.input = element.input;
                                m.nested_routing_option = element.nested_routing_option;
                                //
                                if(element.nested_routing_option=='Ring'){
                                    m.ring_type = element.ring_type;
                                    m.numbers = element.numbers?element.numbers.join(","):null;
                                    m.sip = element.sip;
                                    m.smartsipgroup_id = element.smartsipgroup_id;
                                    m.ring_timeout = element.ring_timeout;
                                    m.after_timeout.user_or_group = element.after_timeout?.user_or_group;
                                    m.after_timeout.user_group = element.after_timeout?.user_group;
                                    m.after_timeout.sip_username = element.after_timeout?.sip_username;
                                    m.after_timeout.ring_option = element.after_timeout?.ring_option||this.afterTimeoutOption.RING;
                                    m.after_timeout.ring_timeout = element.after_timeout.ring_timeout || null;

                                    m.after_timeout.ring_type = element.after_timeout?.ring_type || this.ringType.NUMBER;
                                    m.after_timeout.sip = element.after_timeout?.sip;
                                    m.after_timeout.numbers = element.after_timeout?.numbers?element.after_timeout?.numbers.join(","):null;
                                    m.after_timeout.smartsipgroup_id = element.after_timeout?.smartsipgroup_id;
                                    m.after_timeout.announce_message = element.after_timeout?.announce_message;
                                    m.after_timeout.announce_gender = element.after_timeout?.announce_gender;
                                    if(element.after_timeout?.ring_option==this.afterTimeoutOption.RING){
                                        m.after_timeout.nested_after_timeout = element.after_timeout.nested_after_timeout;
                                    }
                                }else if(element.nested_routing_option=='Announcement'){
                                    m.announce_gender = element.announce_gender;
                                    m.announce_message = element.announce_message;
                                }else if(element.nested_routing_option=='Voicemail'){
                                    m.user_or_group = element.user_or_group||false;
                                    m.user_group = element.user_group;
                                    m.sip_username = element.sip_username;
                                }else{//IntelligentMenu
                                    m.gather_gender = element.gather_gender;
                                    m.gather_message = element.gather_message;
                                    m.no_of_loops = element.no_of_loops;
                                    m.not_responding_message = element.not_responding_message;
                                    m.menu_settings = element.menu_settings;

                                    element?.call_router?.forEach(element2=>{
                                        const route = new IntelligentMenuRouting();
                                        route.input = element2.input;
                                        route.nested_routing_option = element2.nested_routing_option;
                                        //
                                        if(element2.nested_routing_option=='Ring'){
                                            route.ring_type = element2.ring_type;
                                            route.numbers = element2.numbers?element2.numbers.join(","):null;
                                            route.sip = element2.sip;
                                            route.smartsipgroup_id = element2.smartsipgroup_id;
                                            route.ring_timeout = element2.ring_timeout;
                                            route.after_timeout.user_group = element2.after_timeout?.user_group;
                                            route.after_timeout.sip_username = element2.after_timeout?.sip_username;
                                            route.after_timeout.user_or_group = element2.after_timeout?.user_or_group;
                                            route.after_timeout.ring_option = element2.after_timeout?.ring_option||this.afterTimeoutOption.RING;
                                            route.after_timeout.ring_timeout = element2.after_timeout?.ring_timeout||null;

                                            route.after_timeout.ring_type = element2.after_timeout?.ring_type || this.ringType.NUMBER;
                                            route.after_timeout.sip = element2.after_timeout?.sip;
                                            route.after_timeout.numbers = element2.after_timeout?.numbers?element2.after_timeout?.numbers.join(","):null;
                                            route.after_timeout.smartsipgroup_id = element2.after_timeout?.smartsipgroup_id;
                                            route.after_timeout.announce_message = element2.after_timeout?.announce_message;
                                            route.after_timeout.announce_gender = element2.after_timeout?.announce_gender;
                                            if(element2.after_timeout?.ring_option==this.afterTimeoutOption.RING){
                                                route.after_timeout.nested_after_timeout = element2.after_timeout.nested_after_timeout;
                                            }
                                        }else if(element2.nested_routing_option=='Announcement'){
                                            route.announce_gender = element2.announce_gender;
                                            route.announce_message = element2.announce_message;
                                        }else if(element2.nested_routing_option=='Voicemail'){
                                            route.user_or_group = element2.user_or_group||false;
                                            route.user_group = element2.user_group;
                                            route.sip_username = element2.sip_username;
                                        }else{//IntelligentMenu
                                            route.gather_gender = element2.gather_gender;
                                            route.gather_message = element2.gather_message;
                                            route.no_of_loops = element2.no_of_loops;
                                            route.not_responding_message = element2.not_responding_message;
                                            route.menu_settings = element2.menu_settings;

                                            element2?.call_router?.forEach(element3=>{
                                                const nextRoute = new IntelligentMenuRouting();
                                                nextRoute.input = element3.input;
                                                nextRoute.nested_routing_option = element3.nested_routing_option;
                                                //
                                                if(element3.nested_routing_option=='Ring'){
                                                    nextRoute.ring_type = element3.ring_type;
                                                    nextRoute.numbers = element3.numbers?element3.numbers.join(","):null;
                                                    nextRoute.sip = element3.sip;
                                                    nextRoute.smartsipgroup_id = element3.smartsipgroup_id;
                                                    nextRoute.ring_timeout = element3.ring_timeout;
                                                    nextRoute.after_timeout.sip_username = element3.after_timeout?.sip_username;
                                                    nextRoute.after_timeout.user_or_group = element3.after_timeout?.user_or_group;
                                                    nextRoute.after_timeout.user_group = element3.after_timeout?.user_group;
                                                    nextRoute.after_timeout.ring_option = element3.after_timeout?.ring_option||this.afterTimeoutOption.RING;
                                                    nextRoute.after_timeout.ring_timeout = element3.after_timeout?.ring_timeout||null;

                                                    nextRoute.after_timeout.ring_type = element3.after_timeout?.ring_type || this.ringType.NUMBER;
                                                    nextRoute.after_timeout.sip = element3.after_timeout?.sip;
                                                    nextRoute.after_timeout.numbers = element3.after_timeout?.numbers?element3.after_timeout?.numbers.join(","):null;
                                                    nextRoute.after_timeout.smartsipgroup_id = element3.after_timeout?.smartsipgroup_id;
                                                    nextRoute.after_timeout.announce_message = element3.after_timeout?.announce_message;
                                                    nextRoute.after_timeout.announce_gender = element3.after_timeout?.announce_gender;
                                                    if(element3.after_timeout?.ring_option==this.afterTimeoutOption.RING){
                                                        nextRoute.after_timeout.nested_after_timeout = element3.after_timeout.nested_after_timeout;
                                                    }
                                                }else if(element3.nested_routing_option=='Announcement'){
                                                    nextRoute.announce_gender = element3.announce_gender;
                                                    nextRoute.announce_message = element3.announce_message;
                                                }else if(element3.nested_routing_option=='Voicemail'){
                                                    nextRoute.user_or_group = element3.user_or_group||false;
                                                    nextRoute.user_group = element3.user_group;
                                                    nextRoute.sip_username = element3.sip_username;
                                                }
                                                route.call_router.push(nextRoute);
                                            })
                                        }
                                        m.call_router.push(route);
                                    })
                                }
                                
                                
                                this.IntelligentMenuRouting.push(m);
                                this.cdr.markForCheck();
                            });
                        }else{
                            // this.addNewMenuRouting();
                            // this.addNew2ndMenuRouting();
                        }
                        
                    }
                });
            }
            if(res.all_other_time){
                this.advancedSettings.all_other_time = res.all_other_time;
                console.log('starting:',this.advancedSettings.all_other_time);
                
                this.advancedSettings.all_other_time.menu_settings = res.all_other_time.menu_settings;
                this.advancedSettings.all_other_time.ring_type = res.all_other_time.ring_type?res.all_other_time.ring_type:this.ringType.NUMBER;
                this.advancedSettings.all_other_time.numbers = res.all_other_time.numbers?res.all_other_time.numbers.join(','):null;
                //after timeout new 
                if(res.all_other_time.after_timeout){
                    this.advancedSettings.all_other_time.after_timeout.ring_option = res.all_other_time?.after_timeout?.ring_option||this.afterTimeoutOption.RING;
                }
                if(res.all_other_time?.after_timeout?.ring_option==this.afterTimeoutOption.VOICEMAIL){
                    this.advancedSettings.all_other_time.after_timeout.user_or_group = res.all_other_time.after_timeout?.user_or_group;
                    this.advancedSettings.all_other_time.after_timeout.sip_username = res.all_other_time.after_timeout.sip_username;
                    this.advancedSettings.all_other_time.after_timeout.user_group = res.all_other_time.after_timeout.user_group;
                }else if(res.all_other_time?.after_timeout?.ring_option==this.afterTimeoutOption.RING){
                    this.advancedSettings.all_other_time.after_timeout.ring_type = res.all_other_time.after_timeout.ring_type?res.all_other_time.after_timeout.ring_type:this.ringType.NUMBER;
                    this.advancedSettings.all_other_time.after_timeout.numbers = res.all_other_time.after_timeout.numbers?res.all_other_time.after_timeout.numbers.join(","):null;
                    this.advancedSettings.all_other_time.after_timeout.sip = res.all_other_time.after_timeout.sip;
                    this.advancedSettings.all_other_time.after_timeout.smartsipgroup_id = res.all_other_time.after_timeout.smartsipgroup_id;
                    // this.advancedSettings.all_other_time.after_timeout.nested_after_timeout = res.all_other_time?.after_timeout?.nested_after_timeout;
                }else if(res.all_other_time?.after_timeout?.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                    this.advancedSettings.all_other_time.after_timeout.announce_gender = res.all_other_time.after_timeout.announce_gender;
                    this.advancedSettings.all_other_time.after_timeout.announce_message = res.all_other_time.after_timeout.announce_message;
                };
                // 
                if(this.advancedSettings.all_other_time.ring_option=="Intelligent Menu"){
                    this.advancedSettings.all_other_time.call_router?.forEach(menu=>{
                        const otherTime = new IntelligentMenuRouting();
                        otherTime.input = menu.input;
                        otherTime.nested_routing_option = menu.nested_routing_option;
                        otherTime.menu_settings = menu.menu_settings;
                        //
                        if(menu.nested_routing_option=='Ring'){
                            otherTime.ring_type = menu.ring_type?menu.ring_type:this.ringType.NUMBER;
                            otherTime.numbers = menu.numbers?menu.numbers.join(","):null;
                            otherTime.sip = menu.sip;
                            otherTime.smartsipgroup_id = menu.smartsipgroup_id;
                            otherTime.ring_timeout = menu.ring_timeout;
                            // after timeout new
                            otherTime.after_timeout.ring_option = menu.after_timeout?.ring_option?menu.after_timeout?.ring_option:this.afterTimeoutOption.RING;
                            if(menu.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                otherTime.after_timeout.user_group = menu.after_timeout?.user_group;
                                otherTime.after_timeout.sip_username = menu.after_timeout?.sip_username;
                                otherTime.after_timeout.user_or_group = menu.after_timeout?.user_or_group;
                            }else if(menu.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                otherTime.after_timeout.ring_timeout = menu.after_timeout?.ring_timeout;
                                otherTime.after_timeout.ring_type = menu.after_timeout?.ring_type?menu.after_timeout?.ring_type:this.ringType.NUMBER;
                                otherTime.after_timeout.numbers = menu.after_timeout?.numbers?menu.after_timeout?.numbers.join(","):null;
                                otherTime.after_timeout.sip = menu.after_timeout?.sip;
                                otherTime.after_timeout.smartsipgroup_id = menu.after_timeout?.smartsipgroup_id;
                            }else if(menu.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                otherTime.after_timeout.announce_gender = menu.after_timeout.announce_gender;
                                otherTime.after_timeout.announce_message = menu.after_timeout.announce_message;
                            };
                            if(menu.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                if(menu.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                    otherTime.after_timeout.nested_after_timeout.announce_gender = menu.after_timeout?.nested_after_timeout?.announce_gender;
                                    otherTime.after_timeout.nested_after_timeout.announce_message = menu.after_timeout?.nested_after_timeout?.announce_message;
                                }else if(menu.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                    otherTime.after_timeout.nested_after_timeout.ring_option = menu.after_timeout?.nested_after_timeout?.ring_option;
                                    otherTime.after_timeout.nested_after_timeout.sip_username = menu.after_timeout?.nested_after_timeout?.sip_username;
                                    otherTime.after_timeout.nested_after_timeout.user_group = menu.after_timeout?.nested_after_timeout?.user_group;
                                    otherTime.after_timeout.nested_after_timeout.user_or_group = menu.after_timeout?.nested_after_timeout?.user_or_group||false;
                                }
                            }

                        }else if(menu.nested_routing_option=='Announcement'){
                            otherTime.announce_gender = menu.announce_gender;
                            otherTime.announce_message = menu.announce_message;
                        }else if(menu.nested_routing_option=='Voicemail'){
                            otherTime.user_or_group = menu.user_or_group||false;
                            otherTime.sip_username = menu.sip_username;
                            otherTime.user_group = menu.user_group;
                        }else{
                            otherTime.gather_gender = menu.gather_gender;
                            otherTime.gather_message = menu.gather_message;
                            otherTime.no_of_loops = menu.no_of_loops;
                            otherTime.not_responding_message = menu.not_responding_message;
                            otherTime.menu_settings = menu.menu_settings;

                            menu?.call_router?.forEach(element2=>{
                                const route = new IntelligentMenuRouting();
                                route.input = element2.input;
                                route.nested_routing_option = element2.nested_routing_option;
                                //
                                if(element2.nested_routing_option=='Ring'){
                                    route.ring_type = element2.ring_type;
                                    route.numbers = element2.numbers?element2.numbers.join(","):null;
                                    route.sip = element2.sip;
                                    route.smartsipgroup_id = element2.smartsipgroup_id;
                                    route.ring_timeout = element2.ring_timeout;
                                    // after timeout new
                                    route.after_timeout.ring_option = element2.after_timeout?.ring_option||this.afterTimeoutOption.RING;
                                    if(element2.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                        route.after_timeout.user_group = element2.after_timeout?.user_group;
                                        route.after_timeout.sip_username = element2.after_timeout?.sip_username;
                                        route.after_timeout.user_or_group = element2.after_timeout?.user_or_group;
                                    }else if(element2.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                        route.after_timeout.ring_timeout = element2.after_timeout?.ring_timeout;
                                        route.after_timeout.ring_type = element2.after_timeout?.ring_type?element2.after_timeout?.ring_type:this.ringType.NUMBER;
                                        route.after_timeout.numbers = element2.after_timeout?.numbers?element2.after_timeout?.numbers.join(","):null;
                                        route.after_timeout.sip = element2.after_timeout?.sip;
                                        route.after_timeout.smartsipgroup_id = element2.after_timeout?.smartsipgroup_id;
                                    }else if(element2.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                        route.after_timeout.announce_gender = element2.after_timeout.announce_gender;
                                        route.after_timeout.announce_message = element2.after_timeout.announce_message;
                                    };
                                    if(element2.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                        if(element2.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                            route.after_timeout.nested_after_timeout.announce_gender = element2.after_timeout?.nested_after_timeout?.announce_gender;
                                            route.after_timeout.nested_after_timeout.announce_message = element2.after_timeout?.nested_after_timeout?.announce_message;
                                        }else if(element2.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                            route.after_timeout.nested_after_timeout.ring_option = element2.after_timeout?.nested_after_timeout?.ring_option;
                                            route.after_timeout.nested_after_timeout.sip_username = element2.after_timeout?.nested_after_timeout?.sip_username;
                                            route.after_timeout.nested_after_timeout.user_group = element2.after_timeout?.nested_after_timeout?.user_group;
                                            route.after_timeout.nested_after_timeout.user_or_group = element2.after_timeout?.nested_after_timeout?.user_or_group||false;
                                        }
                                    }
                                    // 
                                }else if(element2.nested_routing_option=='Announcement'){
                                    route.announce_gender = element2.announce_gender;
                                    route.announce_message = element2.announce_message;
                                }else if(element2.nested_routing_option=='Voicemail'){
                                    route.user_or_group = element2.user_or_group||false;
                                    route.sip_username = element2.sip_username;
                                    route.user_group = element2.user_group;
                                }else{//IntelligentMenu
                                    route.gather_gender = element2.gather_gender;
                                    route.gather_message = element2.gather_message;
                                    route.no_of_loops = element2.no_of_loops;
                                    route.not_responding_message = element2.not_responding_message;
                                    route.menu_settings = element2.menu_settings;

                                    element2?.call_router?.forEach(element3=>{
                                        const nextRoute = new IntelligentMenuRouting();
                                        nextRoute.input = element3.input;
                                        nextRoute.nested_routing_option = element3.nested_routing_option;
                                        //
                                        if(element3.nested_routing_option=='Ring'){
                                            nextRoute.ring_type = element3.ring_type;
                                            nextRoute.numbers = element3.numbers?element3.numbers.join(","):null;
                                            nextRoute.sip = element3.sip;
                                            nextRoute.smartsipgroup_id = element3.smartsipgroup_id;
                                            nextRoute.ring_timeout = element3.ring_timeout;
                                            // after timeout new
                                            nextRoute.after_timeout.ring_option = element3.after_timeout?.ring_option||this.afterTimeoutOption.RING;
                                            if(element3.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                                nextRoute.after_timeout.user_group = element3.after_timeout?.user_group;
                                                nextRoute.after_timeout.sip_username = element3.after_timeout?.sip_username;
                                                nextRoute.after_timeout.user_or_group = element3.after_timeout?.user_or_group;
                                            }else if(element3.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                                nextRoute.after_timeout.ring_timeout = element3.after_timeout?.ring_timeout;
                                                nextRoute.after_timeout.ring_type = element3.after_timeout?.ring_type?element3.after_timeout?.ring_type:this.ringType.NUMBER;
                                                nextRoute.after_timeout.numbers = element3.after_timeout?.numbers?element3.after_timeout?.numbers.join(","):null;
                                                nextRoute.after_timeout.sip = element3.after_timeout?.sip;
                                                nextRoute.after_timeout.smartsipgroup_id = element3.after_timeout?.smartsipgroup_id;
                                            }else if(element3.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                                nextRoute.after_timeout.announce_gender = element3.after_timeout.announce_gender;
                                                nextRoute.after_timeout.announce_message = element3.after_timeout.announce_message;
                                            }
                                            
                                        }else if(element3.nested_routing_option=='Announcement'){
                                            nextRoute.announce_gender = element3.announce_gender;
                                            nextRoute.announce_message = element3.announce_message;
                                        }else if(element3.nested_routing_option=='Voicemail'){
                                            nextRoute.user_or_group = element3.user_or_group||false;
                                            nextRoute.sip_username = element3.sip_username;
                                            nextRoute.user_group = element3.user_group;
                                        }
                                        route.call_router.push(nextRoute);
                                    })
                                }
                                otherTime.call_router.push(route);
                            })
                        }
                        this.OthertimeMenuRouting.push(otherTime);
                    })
                }
            }
            this.advancedSettings.block_list_enabled = res.block_list_enabled==null?true:res.block_list_enabled;
            this.advancedSettings.block_list_type = res.block_list_type?res.block_list_type:this.blockType.NONE;
            this.advancedSettings.routing_option = res.routing_option?res.routing_option:CallRoutingOptionEnum.RING;
            this.advancedSettings.is_updated = res.is_updated;
            this.advancedSettings.time_zone = res.time_zone;
            console.log('ithan model:',this.advancedSettings);
            
            this.cdr.markForCheck();
        },
        err=>{ 
            this.addNewRoutingSection();
            this.addNewMenuRouting();
            this.toastr.error("Error occurred while loading advanced settings");
        }
        )
        this.cdr.markForCheck();
    }

    addNewRoutingSection(){
        if(this.routingSection.length<3){
            let v=new AdvSettingsTimeFrame();
            v.time_frame_id=null;
            v.ring_option='Ring';
            v.ring_type=this.ringType.NUMBER;
            v.smartsipgroup_id=null;
            v.numbers=null;
            v.sip=null;
            v.smartsipgroup=null;
            v.announce_gender = null;
            v.announce_message = null;
            v.sip_username = null;
            v.user_or_group = false;
            v.user_group = null;
            
            this.routingSection.push(v);
            this.cdr.markForCheck();
        }else{
            this.toastr.warning("You can only create upto 3 Time Frames");
        }
    }

    addNewMenuRouting(){
        if(this.IntelligentMenuRouting.length<9){
            let m=new MenuDetailsResponse();
            let c=new MenuDetailsResponse();
            let t=new MenuDetailsResponse();
            m.input = null;
            m.ring_type = this.ringType.NUMBER;
            m.nested_routing_option = 'Ring';
            m.smartsipgroup_id = null;
            m.numbers = null;
            m.sip = null;
            m.announce_gender = null;
            m.announce_message = null;
            m.gather_gender = null;
            m.gather_message = null;
            m.no_of_loops = null;
            m.not_responding_message = null;
            m.user_or_group = false;
            m.sip_username = null;
            m.user_group = null;
            // m.menu_settings.no_key_is_pressed = 'Hung up';
            // m.menu_settings.unassigned_key_is_pressed = 'Hung up';

            this.IntelligentMenuRouting.push(m);
            this.IntelligentMenuRouting[this.IntelligentMenuRouting.indexOf(m)].call_router.push(c);
            let thirdRoute = this.IntelligentMenuRouting[this.IntelligentMenuRouting.indexOf(m)].call_router;
            thirdRoute[thirdRoute.indexOf(c)].call_router.push(t);
            this.cdr.markForCheck();
        }else{
            this.toastr.warning("You can only create upto 9 digits");
        }
    }

    addNew2ndMenuRouting(menuIndex:number){
        if(this.IntelligentMenuRouting[menuIndex].call_router.length<9){
            let m=new MenuDetailsResponse();
            this.IntelligentMenuRouting[menuIndex].call_router.push(m);
            this.cdr.markForCheck();
        }else{
            this.toastr.warning("You can only create upto 9 digits")
        }
    }

    addNew3rdMenuRouting(menuIndex:number,secondChild:number){
        if(this.IntelligentMenuRouting[menuIndex].call_router[secondChild].call_router.length<9){
            let m=new MenuDetailsResponse();
            this.IntelligentMenuRouting[menuIndex].call_router[secondChild].call_router.push(m);
            this.cdr.markForCheck();
        }else{
            this.toastr.warning("You can only create upto 9 digits")
        }
    }

    addNewOtherTimeMenuRouting(){
        if(this.OthertimeMenuRouting.length<9){
            let m=new MenuDetailsResponse();
            let c=new MenuDetailsResponse();
            let t=new MenuDetailsResponse();
            m.input = null;
            m.nested_routing_option = 'Ring'
            m.ring_type = this.ringType.NUMBER;
            m.smartsipgroup_id = null;
            m.numbers = null;
            m.sip = null;
            m.sip_username = null;
            m.user_or_group = false;
            m.user_group = null;
            m.after_timeout = new AfterTimeout();
            m.after_timeout.nested_after_timeout = new nestedAfterTimeOut()
            
            this.OthertimeMenuRouting.push(m);
            this.OthertimeMenuRouting[this.OthertimeMenuRouting.indexOf(m)].call_router.push(c);
            let thirdRoute = this.OthertimeMenuRouting[this.OthertimeMenuRouting.indexOf(m)].call_router;
            thirdRoute[thirdRoute.indexOf(c)].call_router.push(t);

            this.advancedSettings.all_other_time.after_timeout = new AfterTimeout();
            this.cdr.markForCheck();
        }else{
            this.toastr.warning("You can only create upto 9 digits");
        } 
    }

    addNew2ndOtherTimeMenu(parentIndex:number){
        if(this.OthertimeMenuRouting[parentIndex].call_router.length<9){
            let m=new MenuDetailsResponse();
            let c=new MenuDetailsResponse();
            this.OthertimeMenuRouting[parentIndex].call_router.push(m);
            let thirdRoute = this.OthertimeMenuRouting[parentIndex].call_router;
                thirdRoute[thirdRoute.indexOf(m)].call_router.push(c);
            this.cdr.markForCheck();
        }else{
            this.toastr.warning("You can only create upto 9 digits");
        }
        
    }

    addNew3rdOtherTimeMenu(parentIndex:number,firstChildIndex:number){
        if(this.OthertimeMenuRouting[parentIndex].call_router[firstChildIndex].call_router.length<9){
            let m=new MenuDetailsResponse();
            this.OthertimeMenuRouting[parentIndex].call_router[firstChildIndex].call_router.push(m);
            this.cdr.markForCheck();
        }else{
            this.toastr.warning("You can only create upto 9 digits");
        }
    }

    removeTimeRow(index:number){
    this.routingSection.splice(index,1);
    }

    removeMenuRow(index:number){
        this.IntelligentMenuRouting.splice(index,1);
    }

    remove2ndMenuRow(parentIndex:number,index:number){
        this.IntelligentMenuRouting[parentIndex].call_router.splice(index,1);
    }

    remove3rdMenuRow(menuIndex:number,secondIndex:number,thirdIndex:number){
        this.IntelligentMenuRouting[menuIndex].call_router[secondIndex].call_router.splice(thirdIndex,1);
    }

    removeOtherTimeMenuRow(index:number){
        this.OthertimeMenuRouting.splice(index,1);
    }

    removeOtherTime2ndMenuRow(parentIndex:number,firstChildIndex:number){
        this.OthertimeMenuRouting[parentIndex].call_router.splice(firstChildIndex,1);
    }

    removeOtherTime3rdMenuRow(parentIndex:number,secondIndex:number,thirdIndex:number){
        this.OthertimeMenuRouting[parentIndex].call_router[secondIndex].call_router.splice(thirdIndex,1);
    }

    isNumberSelected(number: number): boolean {
        return this.IntelligentMenuRouting.some(route => route.input === number);
    }

    isNumberSelectedin2ndlevel(parentIndex:number,number: number): boolean {
        return this.IntelligentMenuRouting[parentIndex].call_router.some(route => route.input === number);
    }

    isNumberSelectedin3rdlevel(parentIndex:number,secondChild:number,number: number): boolean {
        return this.IntelligentMenuRouting[parentIndex].call_router[secondChild].call_router.some(route => route.input === number);
    }

    isNumberSelectedinOtherTime(number: number): boolean {
        return this.OthertimeMenuRouting.some(route => route.input === number);
    }

    isNumberSelectedin2ndOtherTime(parentIndex:number,number: number): boolean {
        return this.OthertimeMenuRouting[parentIndex].call_router.some(route => route.input === number);
    }

    isNumberSelectedin3rdOtherTime(parentIndex:number,secondChild:number,number: number): boolean {
        return this.OthertimeMenuRouting[parentIndex].call_router[secondChild].call_router.some(route => route.input === number);
    }
      
    onInputChanged(event: any) {
        const inputValue = event.target.value;
        if (!inputValue || inputValue.length < 70) {
          this.showFullText = false;
        }
      }

    toggleText(){
        this.showFullText = !this.showFullText;
    }

    openModal(content,route,level:number=null,firstIndex=null,secondIndex=null,isAllotherTime=false){
        let selectedNumbers=[];
        if(isAllotherTime){
            
            switch (level) {
                case 1:
                    this.OthertimeMenuRouting.filter(route => {
                        if(route.input){
                            selectedNumbers.push(route.input);
                        }
                    });
                  break;

                case 2:
                  console.log('Option 2 selected');
                  this.OthertimeMenuRouting[firstIndex].call_router.filter(route =>{ 
                    if(route.input){
                        selectedNumbers.push(route.input);
                    }
                });
                  break;
          
                case 3:
                  console.log('Option 3 selected');
                  this.OthertimeMenuRouting[firstIndex].call_router[secondIndex].call_router.filter(route =>{ 
                    if(route.input){
                        selectedNumbers.push(route.input);
                    }
                })
                  break;
          
                default:
                  console.log('Unknown option selected');
                  break;
            }
            
        }else{
            switch (level) {
                case 1:
                  console.log('Option 1 selected');
                  this.IntelligentMenuRouting.filter(route =>{ 
                    if(route.input){
                        selectedNumbers.push(route.input);
                    }
                });
                  break;

                case 2:
                  console.log('Option 2 selected');
                  this.IntelligentMenuRouting[firstIndex].call_router.filter(route =>{ 
                    if(route.input){
                        selectedNumbers.push(route.input);
                    }
                });
                  break;
          
                case 3:
                  console.log('Option 3 selected');
                  this.IntelligentMenuRouting[firstIndex].call_router[secondIndex].call_router.filter(route =>{ 
                    if(route.input){
                        selectedNumbers.push(route.input);
                    }
                })
                  break;
          
                default:
                  console.log('Unknown option selected');
                  break;
            }
        }
        this.selectedMenuInputs = selectedNumbers;
        this.ngbModalRef = this.modalService.open(content, { centered: true, backdrop: 'static' });
        this.selected_menu = route;
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        let current: ForwardBin = selected[0];
        this.selectedSid = current.sid;

        if (current && this.UIPermissions.viewInfo) {
            this.getById(current.sid)
            this.expanded = false;
            this.location.go(`/ring2voice/incoming-routes/${current.sid}/view`);
            this.refreshDataTable();
            this.cdr.markForCheck();
        }
        this.cdr.markForCheck();
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.location.go(`/ring2voice/incoming-routes`);
        this.refreshDataTable();
        this.cdr.markForCheck();

    }

    applyFilter(params: GFilterParam[]) { this.getData(params); }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    changeStatus(): void {
        let option = {
            title: `Are you sure you want to ${this.selected.is_active ? 'Activate  Bin' : ' Deactivate Bin'}?`,
            text: this.selected.name,
            current: this.selected.is_active,
        }
        popup.ConfirmActivate(option, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.ring2voiceService.activateForwardBin(this.selected.sid, { status: this.selected.is_active }).pipe(
                    catchError(err => of({ status: false, message: 'http error' }))
                ).subscribe(resp => {
                    if (resp.status) {
                        this.rows.find(bin => bin.sid === this.selected.sid).is_active = this.selected.is_active;
                        this.toastr.success("Status updated")
                    }
                    else {
                        this.selected.is_active = !this.selected.is_active;
                        this.toastr.error("Unable to change status")
                    }
                    this.cdr.markForCheck();
                })
            }
            else {
                this.selected.is_active = !this.selected.is_active;
                this.cdr.markForCheck();
            }
        });
    }

    changeGlobalRouteStatus(): void {
        let option = {
            title: `Are you sure you want to ${this.selected.global_route ? 'Activate  Global Route' : ' Deactivate Global Route'}?`,
            text: this.selected.name,
            current: this.selected.global_route,
        }
        popup.ConfirmActivate(option, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.ring2voiceService.activateGlobalRoute(this.selected.sid, { global_route: this.selected.global_route }).pipe(
                    catchError(err => of({ global_route: false, message: 'http error' }))
                ).subscribe(resp => {  
                    if (resp.global_route) {
                        this.rows.find(bin => bin.sid === this.selected.sid).global_route = this.selected.global_route;
                        this.toastr.success("Global Route status updated")
                    }
                    else {
                        this.selected.global_route = !this.selected.global_route;
                        this.toastr.warning("Unable to change Status, Number(s) from different Sub-orgs are assigned")
                    }
                    this.cdr.markForCheck();
                },
                ()=>{
                    this.selected.global_route = !this.selected.global_route;
                    this.toastr.error("Error occurred")
                });
            }
            else {
                this.selected.global_route = !this.selected.global_route;
                this.cdr.markForCheck();
            }
        });
    }

    changeCallcenterStatus(): void {
        let option = {
            title: `Are you sure you want to ${this.selected.call_center ? 'Activate  Call Center' : ' Deactivate Call Center'}?`,
            text: this.selected.name,
            current: this.selected.call_center,
        }
        popup.ConfirmActivate(option, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.ring2voiceService.activateCallCenter(this.selected.sid, { call_center: this.selected.call_center }).pipe(
                    catchError(err => of({ callcenter: false, message: 'http error', background:false }))
                ).subscribe(resp => {
                    if (resp.callcenter&&!resp.background) {
                        this.toastr.success("Call Center status updated");
                        let condition = this.rows.find(bin => bin.sid === this.selected.sid);
                        if(condition){
                            condition.call_center = this.selected.call_center;
                        }
                        if(this.selected.call_center){
                            this.getAdvancedCallSettings(this.selected.sid);
                        }
                    }else if(resp.callcenter&&resp.background){
                        this.showBackgroundAlert();
                    }else {
                        this.selected.call_center = !this.selected.call_center;
                        this.toastr.error("Unable to change Call Center status")
                    }
                    this.cdr.markForCheck();
                })
            }
            else {
                this.selected.call_center = !this.selected.call_center;
                this.cdr.markForCheck();
            }
        });
    }

    showBackgroundAlert(){
        let option: SweetAlertOptions = {
            title: 'Process Successful',
            text: 'You will receive an email once the changes reflect',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-success',
              footer: 'flex-row-reverse'
            },
            buttonsStyling: false
        };
        popup.OpenConfirmBox(option, result => {
            if (result.isConfirmed) {
                    return;
            }  
        });
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        let params = this.dataFilter?.getDataFilters() || [];
        this.getData(params, offset);
        this.scrollToTop()
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            let params = this.dataFilter?.getDataFilters() || [];
            this.getData(params, 0);
        }
    }

    deleteForwardBin(sid: string) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.ring2voiceService.deleteForwardBin(sid).subscribe(
                    resp => {
                        if (resp.status) {
                            popup.CompleteDelete('Incoming Routes has been deleted.');
                            this.rows = this.rows.filter(x => x.sid !== sid);
                            this.cdr.markForCheck();
                            this.closeDetailedView();
                        }
                        else {
                            this.toastr.error(resp.message);
                        }
                    },
                    () => this.toastr.error('Unable to delete Incoming Routes')
                );
            }
        });
    }

    daySelect(day){
        console.log(day,'day');
        
    }

    routingOptionsSelect(option){
        if (this.routingSection.length > 0) {
            // Keep the first object and remove the rest
            this.routingSection = [];
            this.IntelligentMenuRouting = [];
            this.OthertimeMenuRouting = [];
            this.addNewRoutingSection();
            this.addNewMenuRouting();
            this.addNewOtherTimeMenuRouting();
          }
    }

    ringOptionSelect(){
        const menu = new MenuDetailsResponse();
        this.IntelligentMenuRouting = [menu];
    }

    firstNestedRouteSelect(parentIndex:number){
        this.IntelligentMenuRouting[parentIndex].call_router=[];
        let m=new MenuDetailsResponse();
        let c=new MenuDetailsResponse();
        this.IntelligentMenuRouting[parentIndex].call_router.push(m);
        let thirdRoute = this.IntelligentMenuRouting[parentIndex].call_router;
            thirdRoute[thirdRoute.indexOf(m)].call_router.push(c);
        this.cdr.markForCheck();
    }

    nestedRouteSelect(parentIndex:number,secondChild:number){
        if (this.routingSection.length > 0) {
            // Keep the first object and remove the rest
            let m=new MenuDetailsResponse();
            this.IntelligentMenuRouting[parentIndex].call_router[secondChild].call_router=[];
            this.IntelligentMenuRouting[parentIndex].call_router[secondChild].call_router.push(m);
          }
    }

    otherTimeFirstNestedRouteSelect(parentIndex:number){
        this.OthertimeMenuRouting[parentIndex].call_router=[];
        let m=new MenuDetailsResponse();
        let c=new MenuDetailsResponse();
        this.OthertimeMenuRouting[parentIndex].call_router.push(m);
        let thirdRoute = this.OthertimeMenuRouting[parentIndex].call_router;
            thirdRoute[thirdRoute.indexOf(m)].call_router.push(c);
        this.cdr.markForCheck();
    }

    OtherTimenestedRouteSelect(parentIndex:number,secondChild:number){
        if (this.routingSection.length > 0) {
            // Keep the first object and remove the rest
            let m=new MenuDetailsResponse();
            this.OthertimeMenuRouting[parentIndex].call_router[secondChild].call_router=[];
            this.OthertimeMenuRouting[parentIndex].call_router[secondChild].call_router.push(m);
          }
    }

    AllOtherRingOptionsSelect(){
        this.OthertimeMenuRouting = [];
        this.addNewOtherTimeMenuRouting();
    }

    // validateFormOnSave(){
    //     let errors:string[]=[];
    //     if(this.advancedSettings.routing_option=='Time Based Routing'&&!this.advancedSettings.time_zone){
    //         errors.push("Select Time Zone");
    //     }
    //     else{
    //         let e=false;
    //         this.routingSection.forEach((v)=>{
    //             if(this.advancedSettings.routing_option=='Time Based Routing'){
    //                 if  ( !v.time_frame_id ||
    //                     ((v.ring_option=='Ring'&&v.ring_type==this.ringType.NUMBER&&(!v.numbers||v.numbers.length<10)) ||
    //                     (v.ring_option=='Ring'&&v.ring_type==this.ringType.SIP&&(!v.sip||v.sip.length==0))||
    //                     (v.ring_option=='Ring'&&v.ring_type==this.ringType.SMART_SIP_GROUP&&!v.smartsipgroup_id)||
    //                     (v.ring_option=='Announcement'&&(!v.announce_gender||!v.announce_message))||
    //                     (v.ring_option=='Intelligent Menu'&&(!v.gather_gender||!v.gather_message||v.no_of_loops==null||!v.not_responding_message)))) {
                        
    //                     e=true;
    //                 }
    //                 if  (v.ring_option=='Intelligent Menu'){
    //                     this.IntelligentMenuRouting.forEach(menu_Route=>{
    //                         if  (menu_Route.input==null||((menu_Route.ring_type==this.ringType.NUMBER&&(!menu_Route.numbers||menu_Route.numbers.length<10)) ||
    //                             (menu_Route.ring_type==this.ringType.SIP&&(!menu_Route.sip||menu_Route.sip.length==0))||
    //                             (menu_Route.ring_type==this.ringType.SMART_SIP_GROUP&&!menu_Route.smartsipgroup_id))){
    //                                 e=true;
    //                         }
    //                     })
    //                 }
    //             }else if (this.advancedSettings.routing_option=='Ring'){
    //                 if  ((v.ring_type==this.ringType.NUMBER&&(!v.numbers||v.numbers.length<10)) ||
    //                     (v.ring_type==this.ringType.SIP&&(!v.sip||v.sip.length==0))||
    //                     (v.ring_type==this.ringType.SMART_SIP_GROUP&&!v.smartsipgroup_id)){
    //                     e=true;
    //                 }
    //             }else if (this.advancedSettings.routing_option=='Announcement'){
    //                 if(!v.announce_gender||!v.announce_message){
    //                     e=true;
    //                 }
    //             }else if(this.advancedSettings.routing_option=='Intelligent Menu'){
    //                 if(!v.gather_gender||!v.gather_message||v.no_of_loops==null||!v.not_responding_message){
    //                     e=true;
    //                 }
    //                 this.IntelligentMenuRouting.forEach(menu_Route=>{
    //                     if(menu_Route.input==null||((menu_Route.ring_type==this.ringType.NUMBER&&(!menu_Route.numbers||menu_Route.numbers.length<10)) ||
    //                     (menu_Route.ring_type==this.ringType.SIP&&(!menu_Route.sip||menu_Route.sip.length==0))||
    //                     (menu_Route.ring_type==this.ringType.SMART_SIP_GROUP&&!menu_Route.smartsipgroup_id))){
    //                         e=true;
    //                     }
    //                 });
    //             }
    //         });
            

    //         // all other times conditions
    //         if(this.advancedSettings.routing_option=='Time Based Routing'){
    //             if(this.advancedSettings.all_other_time.ring_option=='Ring'){
    //                 if  ((this.advancedSettings.all_other_time.ring_type==this.ringType.NUMBER&&(!this.advancedSettings.all_other_time.numbers||this.advancedSettings.all_other_time.numbers.length<10)) ||
    //                     (this.advancedSettings.all_other_time.ring_type==this.ringType.SIP&&(!this.advancedSettings.all_other_time.sip||this.advancedSettings.all_other_time.sip.length==0)) ||
    //                     (this.advancedSettings.all_other_time.ring_type==this.ringType.SMART_SIP_GROUP&&!this.advancedSettings.all_other_time.smartsipgroup_id)){
    //                     e=true;
    //                 }
    //             }else if (this.advancedSettings.all_other_time.ring_option=='Intelligent Menu'){
    //                 if(!this.advancedSettings.all_other_time.gather_gender||!this.advancedSettings.all_other_time.gather_message||this.advancedSettings.all_other_time.no_of_loops==null||!this.advancedSettings.all_other_time.not_responding_message){
    //                     e=true;
    //                 }
    //                 this.OthertimeMenuRouting.forEach(otherTime=>{
    //                     if(otherTime.input==null||((otherTime.ring_type==this.ringType.NUMBER&&(!otherTime.numbers||otherTime.numbers.length<10)) ||
    //                     (otherTime.ring_type==this.ringType.SIP&&(!otherTime.sip||otherTime.sip.length==0))||
    //                     (otherTime.ring_type==this.ringType.SMART_SIP_GROUP&&!otherTime.smartsipgroup_id))){
    //                         e=true;
    //                     }
    //                 });
    //             }else if (this.advancedSettings.all_other_time.ring_option=='Announcement'){
    //                 if(!this.advancedSettings.all_other_time.announce_gender||!this.advancedSettings.all_other_time.announce_message){
    //                     e=true;
    //                 }
    //             }
    //         }
            
    //         if(e){
    //             errors.push('Please fill in all fields');
    //         }
    //     }
           
        
    //     if(errors.length>0){
    //         errors.forEach((val,index)=>{
    //             this.toastr.error(val);
    //         });
    //         return true;
    //     }
    //     else{
    //         return false;
    //     }

    // }


    onSaveClick(){
        // if(!this.validateFormOnSave()){

       
            // let model : ForwardBin = new ForwardBin();

            const payload = new AdvancedCallSettingsReqest();
            payload.block_list_enabled = this.advancedSettings.block_list_enabled;
            payload.routing_option = this.advancedSettings.routing_option;

            if(this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING){
                payload.all_other_time.other_time_ring_option = this.advancedSettings.all_other_time.ring_option;
                if(this.advancedSettings.all_other_time.ring_option=='Ring'){
                    payload.all_other_time.other_time_ring_type = this.advancedSettings.all_other_time.ring_type;
                    payload.all_other_time.agents = this.advancedSettings.all_other_time.ring_type==this.ringType.NUMBER?this.advancedSettings.all_other_time.numbers :
                                                    this.advancedSettings.all_other_time.ring_type==this.ringType.SIP?this.advancedSettings.all_other_time.sip :
                                                    this.advancedSettings.all_other_time.smartsipgroup_id;
                    payload.all_other_time.ring_timeout = this.advancedSettings.all_other_time.ring_timeout;
                    payload.all_other_time.after_timeout.ring_option = this.advancedSettings.all_other_time.after_timeout.ring_option;
                    if(this.advancedSettings.all_other_time.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                        payload.all_other_time.after_timeout.user_or_group = this.advancedSettings.all_other_time.after_timeout.user_or_group;
                        payload.all_other_time.after_timeout.sip_username = !this.advancedSettings.all_other_time.after_timeout.user_or_group?this.advancedSettings.all_other_time.after_timeout.sip_username:null;
                        payload.all_other_time.after_timeout.user_group = this.advancedSettings.all_other_time.after_timeout.user_or_group?this.advancedSettings.all_other_time.after_timeout.user_group:null;
                    }else if(this.advancedSettings.all_other_time.after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                        //not implemented
                    }else if(this.advancedSettings.all_other_time.after_timeout.ring_option==this.afterTimeoutOption.RING){//new afterTimeout
                        payload.all_other_time.after_timeout.ring_timeout = this.advancedSettings.all_other_time.after_timeout.ring_timeout;
                        payload.all_other_time.after_timeout.ring_type = this.advancedSettings.all_other_time.after_timeout.ring_type;
                        payload.all_other_time.after_timeout.agents = this.advancedSettings.all_other_time.after_timeout.ring_type==this.ringType.NUMBER?this.advancedSettings.all_other_time.after_timeout.numbers :
                                                                    this.advancedSettings.all_other_time.after_timeout.ring_type==this.ringType.SIP?this.advancedSettings.all_other_time.after_timeout.sip :
                                                                    this.advancedSettings.all_other_time.after_timeout.smartsipgroup_id;
                    }else if(this.advancedSettings.all_other_time.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                        payload.all_other_time.after_timeout.announce_gender = this.advancedSettings.all_other_time.after_timeout.announce_gender;
                        payload.all_other_time.after_timeout.announce_message = this.advancedSettings.all_other_time.after_timeout.announce_message;
                        delete payload.all_other_time.after_timeout.agents;
                        delete payload.all_other_time.after_timeout.numbers;
                        delete payload.all_other_time.after_timeout.sip;
                        delete payload.all_other_time.after_timeout.user_or_group;
                        delete payload.all_other_time.after_timeout.ring_type;
                    };
                    if(this.advancedSettings.all_other_time.after_timeout.ring_option==this.afterTimeoutOption.RING){//nested after timeout
                        payload.all_other_time.after_timeout.nested_after_timeout.ring_option = this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.ring_option;
                        if(this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                            payload.all_other_time.after_timeout.nested_after_timeout.announce_gender = this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.announce_gender;
                            payload.all_other_time.after_timeout.nested_after_timeout.announce_message = this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.announce_message;
                            delete payload.all_other_time.after_timeout.nested_after_timeout.user_or_group;
                        }else if(this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                            payload.all_other_time.after_timeout.nested_after_timeout.user_or_group = this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.user_or_group;
                            payload.all_other_time.after_timeout.nested_after_timeout.sip_username = !this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.user_or_group?this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.sip_username:null;
                            payload.all_other_time.after_timeout.nested_after_timeout.user_group = this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.user_or_group?this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.user_group:null;
                        }else if(this.advancedSettings.all_other_time.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                            //not implemented;
                        }
                    }else{
                        payload.all_other_time.after_timeout.nested_after_timeout = null;
                    }
                    delete payload.all_other_time.menus;
                }
                if(this.advancedSettings.all_other_time.ring_option=='Announcement'){
                    payload.all_other_time.announce_gender = this.advancedSettings.all_other_time.announce_gender;
                    payload.all_other_time.announce_message = this.advancedSettings.all_other_time.announce_message;
                    delete payload.all_other_time.agents;
                    delete payload.all_other_time.menus;
                    delete payload.all_other_time.after_timeout;
                }
                if(this.advancedSettings.all_other_time.ring_option=='Intelligent Menu'){
                    payload.all_other_time.gather_gender = this.advancedSettings.all_other_time.gather_gender;
                    payload.all_other_time.gather_message = this.advancedSettings.all_other_time.gather_message;
                    payload.all_other_time.no_of_loops = this.advancedSettings.all_other_time.no_of_loops;
                    payload.all_other_time.not_responding_message = this.advancedSettings.all_other_time.not_responding_message;
                    payload.all_other_time.menu_settings.maximum_key_press_to_collect = this.advancedSettings.all_other_time?.menu_settings?.maximum_key_press_to_collect||null;
                    payload.all_other_time.menu_settings.no_key_is_pressed = this.advancedSettings.all_other_time?.menu_settings?.no_key_is_pressed||'hangup';
                    payload.all_other_time.menu_settings.timeout_after_first_key_press = this.advancedSettings.all_other_time?.menu_settings?.timeout_after_first_key_press||null;
                    payload.all_other_time.menu_settings.timeout_before_first_key_press = this.advancedSettings.all_other_time?.menu_settings?.timeout_before_first_key_press||null;
                    payload.all_other_time.menu_settings.unassigned_key_is_pressed = this.advancedSettings.all_other_time?.menu_settings?.unassigned_key_is_pressed||'hangup';
                    delete payload.all_other_time.agents;
                    delete payload.all_other_time.after_timeout;

                    this.OthertimeMenuRouting.forEach(element=>{
                        const m = new IntelligentMenuPayload();
                        m.menu_option = element.input;
                        m.ring_option = element.nested_routing_option;
                        //
                        if(element.nested_routing_option=='Ring'){
                            m.ring_type = element.ring_type;
                            m.agents = element.ring_type==this.ringType.NUMBER?element.numbers :
                                       element.ring_type==this.ringType.SIP?element.sip :
                                       element.smartsipgroup_id.toString();
                            m.ring_timeout = element.ring_timeout;
                            m.after_timeout.ring_option = element.after_timeout.ring_option;
                            
                            if(element.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                m.after_timeout.user_or_group = element.after_timeout.user_or_group;
                                m.after_timeout.sip_username = !element.after_timeout.user_or_group?element.after_timeout.sip_username:null;
                                m.after_timeout.user_group = element.after_timeout.user_or_group?element.after_timeout.user_group:null;
                            }else if(element.after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                //not implemented
                            }else if(element.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                m.after_timeout.ring_timeout = element.after_timeout.ring_timeout;
                                m.after_timeout.ring_type = element.after_timeout.ring_type;
                                m.after_timeout.agents = element.after_timeout.ring_type==this.ringType.NUMBER?element.after_timeout.numbers :
                                                                            element.after_timeout.ring_type==this.ringType.SIP?element.after_timeout.sip :
                                                                            element.after_timeout.smartsipgroup_id;
                            }else if(element.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                m.after_timeout.announce_gender = element.after_timeout.announce_gender;
                                m.after_timeout.announce_message = element.after_timeout.announce_message;
                                delete m.after_timeout.agents;
                                delete m.after_timeout.numbers;
                                delete m.after_timeout.sip;
                                delete m.after_timeout.user_or_group;
                                delete m.after_timeout.ring_type;
                            };
                            if(element.after_timeout.ring_option==this.afterTimeoutOption.RING){//nested after timeout
                                m.after_timeout.nested_after_timeout.ring_option = element.after_timeout.nested_after_timeout.ring_option;
                                if(element.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                    m.after_timeout.nested_after_timeout.announce_gender = element.after_timeout.nested_after_timeout.announce_gender;
                                    m.after_timeout.nested_after_timeout.announce_message = element.after_timeout.nested_after_timeout.announce_message;
                                    delete m.after_timeout.nested_after_timeout.user_or_group;
                                }else if(element.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                    m.after_timeout.nested_after_timeout.user_or_group = element.after_timeout.nested_after_timeout.user_or_group;
                                    m.after_timeout.nested_after_timeout.sip_username = !element.after_timeout.nested_after_timeout.user_or_group?element.after_timeout.nested_after_timeout.sip_username:null;
                                    m.after_timeout.nested_after_timeout.user_group = element.after_timeout.nested_after_timeout.user_or_group?element.after_timeout.nested_after_timeout.user_group:null;
                                }else if(element.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                    //not implemented;
                                }
                            }else{
                                m.after_timeout.nested_after_timeout = null;
                            }
                            delete m.menus;
                        }else if(element.nested_routing_option=='Announcement'){
                            m.announce_gender = element.announce_gender;
                            m.announce_message = element.announce_message;
                            delete m.agents;
                            delete m.menus;
                            delete m.after_timeout;
                        }else if(element.nested_routing_option=='Voicemail'){
                            m.user_or_group = element.user_or_group;
                            m.sip_username = !element.user_or_group?element.sip_username:null;
                            m.user_group = element.user_or_group?element.user_group:null;
                            delete m.agents;
                            delete m.menus;
                            delete m.after_timeout;
                        }else if(element.nested_routing_option=='Intelligent Menu'){
                            m.gather_gender = element.gather_gender;
                            m.gather_message = element.gather_message;
                            m.no_of_loops = element.no_of_loops;
                            m.not_responding_message = element.not_responding_message;
                            m.menu_settings.maximum_key_press_to_collect = element.menu_settings?.maximum_key_press_to_collect||null;
                            m.menu_settings.no_key_is_pressed = element.menu_settings?.no_key_is_pressed||'hangup';
                            m.menu_settings.timeout_after_first_key_press = element.menu_settings?.timeout_after_first_key_press||null;
                            m.menu_settings.timeout_before_first_key_press = element.menu_settings?.timeout_before_first_key_press||null;
                            m.menu_settings.unassigned_key_is_pressed = element.menu_settings?.unassigned_key_is_pressed||'hangup';
                            delete m.after_timeout;

                            element.call_router.forEach(first_child=>{
                                const first = new IntelligentMenuPayload();

                                first.menu_option = first_child.input;
                                first.ring_option = first_child.nested_routing_option;
                                //
                                if(first_child.nested_routing_option=='Ring'){
                                    first.ring_type = first_child.ring_type;
                                    first.agents = first_child.ring_type==this.ringType.NUMBER?first_child.numbers :
                                                   first_child.ring_type==this.ringType.SIP?first_child.sip :
                                                   first_child.smartsipgroup_id.toString();
                                    first.ring_timeout = first_child.ring_timeout;
                                    first.after_timeout.ring_option = first_child.after_timeout.ring_option;
                                    first.after_timeout.user_or_group = first_child.after_timeout.user_or_group;

                                    if(first_child.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                        first.after_timeout.user_or_group = first_child.after_timeout.user_or_group;
                                        first.after_timeout.sip_username = !first_child.after_timeout.user_or_group?first_child.after_timeout.sip_username:null;
                                        first.after_timeout.user_group = first_child.after_timeout.user_or_group?first_child.after_timeout.user_group:null;
                                    }else if(first_child.after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                        //not implemented
                                    }else if(first_child.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                        first.after_timeout.ring_timeout = first_child.after_timeout.ring_timeout;
                                        first.after_timeout.ring_type = first_child.after_timeout.ring_type;
                                        first.after_timeout.agents = first_child.after_timeout.ring_type==this.ringType.NUMBER?first_child.after_timeout.numbers :
                                                                            first_child.after_timeout.ring_type==this.ringType.SIP?first_child.after_timeout.sip :
                                                                            first_child.after_timeout.smartsipgroup_id;
                                    }else if(first_child.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                        first.after_timeout.announce_gender = first_child.after_timeout.announce_gender;
                                        first.after_timeout.announce_message = first_child.after_timeout.announce_message;
                                        delete first.after_timeout.agents;
                                        delete first.after_timeout.numbers;
                                        delete first.after_timeout.sip;
                                        delete first.after_timeout.user_or_group;
                                        delete first.after_timeout.ring_type;
                                    };
                                    if(first_child.after_timeout.ring_option==this.afterTimeoutOption.RING){//nested after timeout
                                        first.after_timeout.nested_after_timeout.ring_option = first_child.after_timeout.nested_after_timeout.ring_option;
                                        if(first_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                            first.after_timeout.nested_after_timeout.announce_gender = first_child.after_timeout.nested_after_timeout.announce_gender;
                                            first.after_timeout.nested_after_timeout.announce_message = first_child.after_timeout.nested_after_timeout.announce_message;
                                            delete first.after_timeout.nested_after_timeout.user_or_group;
                                        }else if(first_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                            first.after_timeout.nested_after_timeout.user_or_group = first_child.after_timeout.nested_after_timeout.user_or_group;
                                            first.after_timeout.nested_after_timeout.sip_username = !first_child.after_timeout.nested_after_timeout.user_or_group?first_child.after_timeout.nested_after_timeout.sip_username:null;
                                            first.after_timeout.nested_after_timeout.user_group = first_child.after_timeout.nested_after_timeout.user_or_group?first_child.after_timeout.nested_after_timeout.user_group:null;
                                        }else if(first_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                            //not implemented;
                                        }
                                    }else{
                                        first.after_timeout.nested_after_timeout = null;
                                    }
                                    delete first.menus;
                                }else if(first_child.nested_routing_option=='Announcement'){
                                    first.announce_gender = first_child.announce_gender;
                                    first.announce_message = first_child.announce_message;
                                    delete first.agents;
                                    delete first.menus;
                                    delete first.after_timeout;
                                }else if(first_child.nested_routing_option=='Voicemail'){
                                    first.user_or_group = first_child.user_or_group;
                                    first.sip_username = !first_child.user_or_group?first_child.sip_username:null;
                                    first.user_group = first_child.user_or_group?first_child.user_group:null;
                                    delete first.agents;
                                    delete first.menus;
                                    delete first.after_timeout;
                                }else{
                                    first.gather_gender = first_child.gather_gender;
                                    first.gather_message = first_child.gather_message;
                                    first.no_of_loops = first_child.no_of_loops;
                                    first.not_responding_message = first_child.not_responding_message;
                                    first.menu_settings.maximum_key_press_to_collect = first_child.menu_settings?.maximum_key_press_to_collect||null;
                                    first.menu_settings.no_key_is_pressed = first_child.menu_settings?.no_key_is_pressed||'hangup';
                                    first.menu_settings.timeout_after_first_key_press = first_child.menu_settings?.timeout_after_first_key_press||null;
                                    first.menu_settings.timeout_before_first_key_press = first_child.menu_settings?.timeout_before_first_key_press||null;
                                    first.menu_settings.unassigned_key_is_pressed = first_child.menu_settings?.unassigned_key_is_pressed||'hangup';
                                    delete first.after_timeout;

                                    first_child.call_router.forEach(second_child=>{
                                        const second = new IntelligentMenuPayload();

                                        second.menu_option = second_child.input;
                                        second.ring_option = second_child.nested_routing_option;
                                        //
                                        if(second_child.nested_routing_option=='Ring'){
                                            second.ring_type = second_child.ring_type;
                                            second.agents = second_child.ring_type==this.ringType.NUMBER?second_child.numbers :
                                                            second_child.ring_type==this.ringType.SIP?second_child.sip :
                                                            second_child.smartsipgroup_id.toString();
                                            second.ring_timeout = second_child.ring_timeout;
                                            second.after_timeout.ring_option = second_child.after_timeout.ring_option;
                                            second.after_timeout.user_or_group = second_child.after_timeout.user_or_group;

                                            if(second_child.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                                second.after_timeout.user_or_group = second_child.after_timeout.user_or_group;
                                                second.after_timeout.sip_username = !second_child.after_timeout.user_or_group?second_child.after_timeout.sip_username:null;
                                                second.after_timeout.user_group = second_child.after_timeout.user_or_group?second_child.after_timeout.user_group:null;
                                            }else if(second_child.after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                                //not implemented
                                            }else if(second_child.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                                second.after_timeout.ring_timeout = second_child.after_timeout.ring_timeout;
                                                second.after_timeout.ring_type = second_child.after_timeout.ring_type;
                                                second.after_timeout.agents = second_child.after_timeout.ring_type==this.ringType.NUMBER?second_child.after_timeout.numbers :
                                                                                    second_child.after_timeout.ring_type==this.ringType.SIP?second_child.after_timeout.sip :
                                                                                    second_child.after_timeout.smartsipgroup_id;
                                            }else if(second_child.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                                second.after_timeout.announce_gender = second_child.after_timeout.announce_gender;
                                                second.after_timeout.announce_message = second_child.after_timeout.announce_message;
                                                delete second.after_timeout.agents;
                                                delete second.after_timeout.numbers;
                                                delete second.after_timeout.sip;
                                                delete second.after_timeout.user_or_group;
                                                delete second.after_timeout.ring_type;
                                            };
                                            if(second_child.after_timeout.ring_option==this.afterTimeoutOption.RING){//nested after timeout
                                                second.after_timeout.nested_after_timeout.ring_option = second_child.after_timeout.nested_after_timeout.ring_option;
                                                if(second_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                                    second.after_timeout.nested_after_timeout.announce_gender = second_child.after_timeout.nested_after_timeout.announce_gender;
                                                    second.after_timeout.nested_after_timeout.announce_message = second_child.after_timeout.nested_after_timeout.announce_message;
                                                    delete second.after_timeout.nested_after_timeout.user_or_group;
                                                }else if(second_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                                    second.after_timeout.nested_after_timeout.user_or_group = second_child.after_timeout.nested_after_timeout.user_or_group;
                                                    second.after_timeout.nested_after_timeout.sip_username = !second_child.after_timeout.nested_after_timeout.user_or_group?second_child.after_timeout.nested_after_timeout.sip_username:null;
                                                    second.after_timeout.nested_after_timeout.user_group = second_child.after_timeout.nested_after_timeout.user_or_group?second_child.after_timeout.nested_after_timeout.user_group:null;
                                                }else if(second_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                                    //not implemented;
                                                }
                                            }else{
                                                second.after_timeout.nested_after_timeout = null;
                                            }
                                            delete second.menus;
                                        }else if(second_child.nested_routing_option=='Announcement'){
                                            second.announce_gender = second_child.announce_gender;
                                            second.announce_message = second_child.announce_message;
                                            delete second.agents;
                                            delete second.menus;
                                            delete second.after_timeout;
                                        }else if(second_child.nested_routing_option=='Voicemail'){
                                            second.user_or_group = second_child.user_or_group;
                                            second.sip_username = !second_child.user_or_group?second_child.sip_username:null;
                                            second.user_group = second_child.user_or_group?second_child.user_group:null;
                                            delete second.agents;
                                            delete second.menus;
                                            delete second.after_timeout;
                                        }
                                        first.menus.push(second);
                                    });
                                };
                                m.menus.push(first);
                            });
                        }
                        payload.all_other_time.menus.push(m);
                    })
                }
                if(this.advancedSettings.all_other_time.ring_option=='Voicemail'){
                    payload.all_other_time.user_or_group = this.advancedSettings.all_other_time.user_or_group?this.advancedSettings.all_other_time.user_or_group:false;
                    payload.all_other_time.sip_username = !this.advancedSettings.all_other_time.user_or_group?this.advancedSettings.all_other_time.sip_username:null;
                    payload.all_other_time.user_group = this.advancedSettings.all_other_time.user_or_group?this.advancedSettings.all_other_time.user_group:null;
                    delete payload.all_other_time.agents;
                    delete payload.all_other_time.menus;
                    delete payload.all_other_time.after_timeout;
                }
                
            }else{
                payload.all_other_time = null;
            }
            
            payload.time_zone = this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING? this.advancedSettings.time_zone : null;
            
            this.routingSection.forEach(element => {
                let route=new TimeFramePayload();

                if(this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING){
                    route.time_frame_id = this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING? element.time_frame_id : null;
                    route.ring_option = element.ring_option;
                }
                if(this.advancedSettings.routing_option==CallRoutingOptionEnum.RING||(this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING&&element.ring_option=='Ring')){
                    route.ring_type = element.ring_type;
                    route.time_frame_id = this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING? element.time_frame_id : null;
                    route.agents =  element.ring_type==this.ringType.NUMBER?element.numbers :
                                    element.ring_type==this.ringType.SIP?element.sip :
                                    element.smartsipgroup_id.toString();
                    route.ring_timeout = element.ring_timeout;
                    route.after_timeout.ring_option = element.after_timeout.ring_option;
                    if(element.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                        route.after_timeout.user_or_group = element.after_timeout.user_or_group;
                        route.after_timeout.sip_username = !element.after_timeout.user_or_group?element.after_timeout.sip_username:null;
                        route.after_timeout.user_group = element.after_timeout.user_or_group?element.after_timeout.user_group:null;
                    }else if(element.after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                        //not implemented
                    }else if(element.after_timeout.ring_option==this.afterTimeoutOption.RING){
                        route.after_timeout.ring_timeout = element.after_timeout.ring_timeout;
                        route.after_timeout.ring_type = element.after_timeout.ring_type;
                        route.after_timeout.agents = element.after_timeout.ring_type==this.ringType.NUMBER?element.after_timeout.numbers :
                                                            element.after_timeout.ring_type==this.ringType.SIP?element.after_timeout.sip :
                                                            element.after_timeout.smartsipgroup_id;
                    }else if(element.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                        route.after_timeout.announce_gender = element.after_timeout.announce_gender;
                        route.after_timeout.announce_message = element.after_timeout.announce_message;
                        delete route.after_timeout.agents;
                        delete route.after_timeout.numbers;
                        delete route.after_timeout.sip;
                        delete route.after_timeout.user_or_group;
                        delete route.after_timeout.ring_type;
                    };
                    if(element.after_timeout.ring_option==this.afterTimeoutOption.RING){//nested after timeout
                        route.after_timeout.nested_after_timeout.ring_option = element.after_timeout.nested_after_timeout.ring_option;
                        if(element.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                            route.after_timeout.nested_after_timeout.announce_gender = element.after_timeout.nested_after_timeout.announce_gender;
                            route.after_timeout.nested_after_timeout.announce_message = element.after_timeout.nested_after_timeout.announce_message;
                            delete route.after_timeout.nested_after_timeout.user_or_group;
                        }else if(element.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                            route.after_timeout.nested_after_timeout.user_or_group = element.after_timeout.nested_after_timeout.user_or_group;
                            route.after_timeout.nested_after_timeout.sip_username = !element.after_timeout.nested_after_timeout.user_or_group?element.after_timeout.nested_after_timeout.sip_username:null;
                            route.after_timeout.nested_after_timeout.user_group = element.after_timeout.nested_after_timeout.user_or_group?element.after_timeout.nested_after_timeout.user_group:null;
                        }else if(element.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                            //not implemented;
                        }
                    }else{
                        route.after_timeout.nested_after_timeout = null;
                    }
                };
                if(this.advancedSettings.routing_option==CallRoutingOptionEnum.ANNOUNCEMENT||(this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING&&element.ring_option=='Announcement')){
                    route.announce_gender = element.announce_gender;
                    route.announce_message = element.announce_message;
                    delete route.menu_settings;
                    delete route.agents;
                    delete route.menus;
                    delete route.after_timeout;
                };
                if(this.advancedSettings.routing_option==CallRoutingOptionEnum.VOICEMAIL||(this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING&&element.ring_option=='Voicemail')){
                    route.user_or_group = element.user_or_group;
                    route.sip_username = !element.user_or_group?element.sip_username:null;
                    route.user_group = element.user_or_group?element.user_group:null;
                    delete route.agents;
                    delete route.menus;
                    delete route.after_timeout;
                    delete route.menu_settings;
                };
                if(this.advancedSettings.routing_option==CallRoutingOptionEnum.INTELLIGENT_MENU||(this.advancedSettings.routing_option==CallRoutingOptionEnum.TIME_BASED_ROUTING&&element.ring_option=='Intelligent Menu')){
                    route.gather_gender = element.gather_gender;
                    route.gather_message = element.gather_message
                    route.no_of_loops = element.no_of_loops;
                    route.not_responding_message = element.not_responding_message;
                    route.menu_settings.maximum_key_press_to_collect = element.menu_settings?.maximum_key_press_to_collect||null;
                    route.menu_settings.no_key_is_pressed = element.menu_settings?.no_key_is_pressed||'hangup';
                    route.menu_settings.timeout_after_first_key_press = element.menu_settings?.timeout_after_first_key_press||null;
                    route.menu_settings.timeout_before_first_key_press = element.menu_settings?.timeout_before_first_key_press||null;
                    route.menu_settings.unassigned_key_is_pressed = element.menu_settings?.unassigned_key_is_pressed||'hangup';
                    delete route.agents;
                    delete route.after_timeout;

                    this.IntelligentMenuRouting.forEach(element2 => {
                        const im = new IntelligentMenuPayload();
                        im.menu_option = element2.input;
                        im.ring_option = element2.nested_routing_option;
                        // 
                        if(element2.nested_routing_option=='Ring'){
                            im.ring_type = element2.ring_type;
                            im.agents = element2.ring_type==this.ringType.NUMBER?element2.numbers :
                                        element2.ring_type==this.ringType.SIP?element2.sip :
                                        element2.smartsipgroup_id.toString();
                            im.ring_timeout = element2.ring_timeout;
                            im.after_timeout.ring_option = element2.after_timeout.ring_option;
                            if(element2.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                im.after_timeout.user_or_group = element2.after_timeout.user_or_group;
                                im.after_timeout.sip_username = !element2.after_timeout.user_or_group?element2.after_timeout.sip_username:null;
                                im.after_timeout.user_group = element2.after_timeout.user_or_group?element2.after_timeout.user_group:null;
                            }else if(element2.after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                //not implemented
                            }else if(element2.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                im.after_timeout.ring_timeout = element2.after_timeout.ring_timeout;
                                im.after_timeout.ring_type = element2.after_timeout.ring_type;
                                im.after_timeout.agents = element2.after_timeout.ring_type==this.ringType.NUMBER?element2.after_timeout.numbers :
                                                                 element2.after_timeout.ring_type==this.ringType.SIP?element2.after_timeout.sip :
                                                                 element2.after_timeout.smartsipgroup_id;
                            }else if(element2.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                im.after_timeout.announce_gender = element2.after_timeout.announce_gender;
                                im.after_timeout.announce_message = element2.after_timeout.announce_message;
                                delete im.after_timeout.agents;
                                delete im.after_timeout.numbers;
                                delete im.after_timeout.sip;
                                delete im.after_timeout.user_or_group;
                                delete im.after_timeout.ring_type;
                            };
                            if(element2.after_timeout.ring_option==this.afterTimeoutOption.RING){//nested after timeout
                                im.after_timeout.nested_after_timeout.ring_option = element2.after_timeout.nested_after_timeout.ring_option;
                                if(element2.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                    im.after_timeout.nested_after_timeout.announce_gender = element2.after_timeout.nested_after_timeout.announce_gender;
                                    im.after_timeout.nested_after_timeout.announce_message = element2.after_timeout.nested_after_timeout.announce_message;
                                    delete im.after_timeout.nested_after_timeout.user_or_group;
                                }else if(element2.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                    im.after_timeout.nested_after_timeout.user_or_group = element2.after_timeout.nested_after_timeout.user_or_group;
                                    im.after_timeout.nested_after_timeout.sip_username = !element2.after_timeout.nested_after_timeout.user_or_group?element2.after_timeout.nested_after_timeout.sip_username:null;
                                    im.after_timeout.nested_after_timeout.user_group = element2.after_timeout.nested_after_timeout.user_or_group?element2.after_timeout.nested_after_timeout.user_group:null;
                                }else if(element2.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                    //not implemented;
                                }
                            }else{
                                im.after_timeout.nested_after_timeout = null;
                            }
                            delete im.menus;
                        }else if(element2.nested_routing_option=='Announcement'){
                            im.announce_gender = element2.announce_gender;
                            im.announce_message = element2.announce_message;
                            delete im.agents;
                            delete im.menus;
                            delete im.after_timeout;
                        }else if(element2.nested_routing_option=='Voicemail'){
                            im.user_or_group = element2.user_or_group;
                            im.sip_username = element2.sip_username;
                            im.user_group = element2.user_group;
                            delete im.agents;
                            delete im.menus;
                            delete im.after_timeout;
                        }else if(element2.nested_routing_option=='Intelligent Menu'){
                            im.gather_gender = element2.gather_gender;
                            im.gather_message = element2.gather_message;
                            im.no_of_loops = element2.no_of_loops;
                            im.not_responding_message = element2.not_responding_message;
                            im.menu_settings.maximum_key_press_to_collect = element2.menu_settings?.maximum_key_press_to_collect||null;
                            im.menu_settings.no_key_is_pressed = element2.menu_settings?.no_key_is_pressed||'hangup';
                            im.menu_settings.timeout_after_first_key_press = element2.menu_settings?.timeout_after_first_key_press||null;
                            im.menu_settings.timeout_before_first_key_press = element2.menu_settings?.timeout_before_first_key_press||null;
                            im.menu_settings.unassigned_key_is_pressed = element2.menu_settings?.unassigned_key_is_pressed||'hangup';
                            delete im.after_timeout;
                            
                            element2.call_router.forEach(first_child=>{
                                    const first = new IntelligentMenuPayload();

                                    first.menu_option = first_child.input;
                                    first.ring_option = first_child.nested_routing_option;
                                    //
                                    if(first_child.nested_routing_option=='Ring'){
                                        first.ring_type = first_child.ring_type;
                                        first.agents = first_child.ring_type==this.ringType.NUMBER?first_child.numbers :
                                                       first_child.ring_type==this.ringType.SIP?first_child.sip :
                                                       first_child.smartsipgroup_id.toString();
                                        first.ring_timeout = first_child.ring_timeout;
                                        first.after_timeout.ring_option = first_child.after_timeout.ring_option;
                                        if(first_child.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                            first.after_timeout.user_or_group = first_child.after_timeout.user_or_group;
                                            first.after_timeout.sip_username = !first_child.after_timeout.user_or_group?first_child.after_timeout.sip_username:null;
                                            first.after_timeout.user_group = first_child.after_timeout.user_or_group?first_child.after_timeout.user_group:null;
                                        }else if(first_child.after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                            //not implemented
                                        }else if(first_child.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                            first.after_timeout.ring_timeout = first_child.after_timeout.ring_timeout;
                                            first.after_timeout.ring_type = first_child.after_timeout.ring_type;
                                            first.after_timeout.agents = first_child.after_timeout.ring_type==this.ringType.NUMBER?first_child.after_timeout.numbers :
                                                                                first_child.after_timeout.ring_type==this.ringType.SIP?first_child.after_timeout.sip :
                                                                                first_child.after_timeout.smartsipgroup_id;
                                        }else if(first_child.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                            first.after_timeout.announce_gender = first_child.after_timeout.announce_gender;
                                            first.after_timeout.announce_message = first_child.after_timeout.announce_message;
                                            delete first.after_timeout.agents;
                                            delete first.after_timeout.numbers;
                                            delete first.after_timeout.sip;
                                            delete first.after_timeout.user_or_group;
                                            delete first.after_timeout.ring_type;
                                        };
                                        if(first_child.after_timeout.ring_option==this.afterTimeoutOption.RING){//nested after timeout
                                            first.after_timeout.nested_after_timeout.ring_option = first_child.after_timeout.nested_after_timeout.ring_option;
                                            if(first_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                                first.after_timeout.nested_after_timeout.announce_gender = first_child.after_timeout.nested_after_timeout.announce_gender;
                                                first.after_timeout.nested_after_timeout.announce_message = first_child.after_timeout.nested_after_timeout.announce_message;
                                                delete first.after_timeout.nested_after_timeout.user_or_group;
                                            }else if(first_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                                first.after_timeout.nested_after_timeout.user_or_group = first_child.after_timeout.nested_after_timeout.user_or_group;
                                                first.after_timeout.nested_after_timeout.sip_username = !first_child.after_timeout.nested_after_timeout.user_or_group?first_child.after_timeout.nested_after_timeout.sip_username:null;
                                                first.after_timeout.nested_after_timeout.user_group = first_child.after_timeout.nested_after_timeout.user_or_group?first_child.after_timeout.nested_after_timeout.user_group:null;
                                            }else if(first_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                                //not implemented;
                                            }
                                        }else{
                                            first.after_timeout.nested_after_timeout = null;
                                        }
                                        delete first.menus;
                                    }else if(first_child.nested_routing_option=='Announcement'){
                                        first.announce_gender = first_child.announce_gender;
                                        first.announce_message = first_child.announce_message;
                                        delete first.agents;
                                        delete first.menus;
                                        delete first.after_timeout;
                                    }else{
                                        first.gather_gender = first_child.gather_gender;
                                        first.gather_message = first_child.gather_message;
                                        first.no_of_loops = first_child.no_of_loops;
                                        first.not_responding_message = first_child.not_responding_message;
                                        first.menu_settings.maximum_key_press_to_collect = first_child.menu_settings?.maximum_key_press_to_collect||null;
                                        first.menu_settings.no_key_is_pressed = first_child.menu_settings?.no_key_is_pressed||'hangup';
                                        first.menu_settings.timeout_after_first_key_press = first_child.menu_settings?.timeout_after_first_key_press||null;
                                        first.menu_settings.timeout_before_first_key_press = first_child.menu_settings?.timeout_before_first_key_press||null;
                                        first.menu_settings.unassigned_key_is_pressed = first_child.menu_settings?.unassigned_key_is_pressed||'hangup';
                                        delete first.after_timeout;

                                        first_child.call_router.forEach(second_child=>{
                                            const second = new IntelligentMenuPayload();

                                            second.menu_option = second_child.input;
                                            second.ring_option = second_child.nested_routing_option;
                                            //
                                            if(second_child.nested_routing_option=='Ring'){
                                                second.ring_type = second_child.ring_type;
                                                second.agents = second_child.ring_type==this.ringType.NUMBER?second_child.numbers :
                                                                second_child.ring_type==this.ringType.SIP?second_child.sip :
                                                                second_child.smartsipgroup_id.toString();
                                                second.ring_timeout = second_child.ring_timeout;
                                                second.after_timeout.ring_option = second_child.after_timeout.ring_option;
                                                if(second_child.after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                                    second.after_timeout.user_or_group = second_child.after_timeout.user_or_group;
                                                    second.after_timeout.sip_username = !second_child.after_timeout.user_or_group?second_child.after_timeout.sip_username:null;
                                                    second.after_timeout.user_group = second_child.after_timeout.user_or_group?second_child.after_timeout.user_group:null;
                                                }else if(second_child.after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                                    //not implemented
                                                }else if(second_child.after_timeout.ring_option==this.afterTimeoutOption.RING){
                                                    second.after_timeout.ring_timeout = second_child.after_timeout.ring_timeout;
                                                    second.after_timeout.ring_type = second_child.after_timeout.ring_type;
                                                    second.after_timeout.agents = second_child.after_timeout.ring_type==this.ringType.NUMBER?second_child.after_timeout.numbers :
                                                                                     second_child.after_timeout.ring_type==this.ringType.SIP?second_child.after_timeout.sip :
                                                                                     second_child.after_timeout.smartsipgroup_id;
                                                }else if(second_child.after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                                    second.after_timeout.announce_gender = second_child.after_timeout.announce_gender;
                                                    second.after_timeout.announce_message = second_child.after_timeout.announce_message;
                                                    delete second.after_timeout.agents;
                                                    delete second.after_timeout.numbers;
                                                    delete second.after_timeout.sip;
                                                    delete second.after_timeout.user_or_group;
                                                    delete second.after_timeout.ring_type;
                                                };
                                                if(second_child.after_timeout.ring_option==this.afterTimeoutOption.RING){//nested after timeout
                                                    second.after_timeout.nested_after_timeout.ring_option = second_child.after_timeout.nested_after_timeout.ring_option;
                                                    if(second_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.ANNOUNCEMENT){
                                                        second.after_timeout.nested_after_timeout.announce_gender = second_child.after_timeout.nested_after_timeout.announce_gender;
                                                        second.after_timeout.nested_after_timeout.announce_message = second_child.after_timeout.nested_after_timeout.announce_message;
                                                        delete second.after_timeout.nested_after_timeout.user_or_group;
                                                    }else if(second_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.VOICEMAIL){
                                                        second.after_timeout.nested_after_timeout.user_or_group = second_child.after_timeout.nested_after_timeout.user_or_group;
                                                        second.after_timeout.nested_after_timeout.sip_username = !second_child.after_timeout.nested_after_timeout.user_or_group?second_child.after_timeout.nested_after_timeout.sip_username:null;
                                                        second.after_timeout.nested_after_timeout.user_group = second_child.after_timeout.nested_after_timeout.user_or_group?second_child.after_timeout.nested_after_timeout.user_group:null;
                                                    }else if(second_child.after_timeout.nested_after_timeout.ring_option==this.afterTimeoutOption.QUEUE){
                                                        //not implemented;
                                                    }
                                                }else{
                                                    first.after_timeout.nested_after_timeout = null;
                                                }
                                                delete second.menus;
                                            }else if(second_child.nested_routing_option=='Announcement'){
                                                second.announce_gender = second_child.announce_gender;
                                                second.announce_message = second_child.announce_message;
                                                delete second.agents;
                                                delete second.menus;
                                                delete second.after_timeout;
                                            }
                                            first.menus.push(second);
                                        });
                                    };
                                    im.menus.push(first);
                            });
                        };
                        route.menus.push(im);
                    });
                }
                payload.time_frames.push(route);
            });

                let api = this.ring2voiceService.createAdvancedSettings(this.selectedSid, payload);
        //         let api2 = this.ring2voiceService.updateForwardBinSmartSipGroup(this.selectedSid, model)


                this.subs.sink = api.subscribe(
                    resp => {


                        // if(resp.status){
                        //     this.toastr.success("Advance Call Settings Updated");
                        //     if(payload.ring_type==this.ringType.NUMBER){
                        //         this.advancedSettings.sip =null;
                        //         this.advancedSettings.smartsipgroup_id =null;
                        //     }else if (payload.ring_type==this.ringType.SIP){
                        //         this.advancedSettings.number = null;
                        //         this.advancedSettings.smartsipgroup_id =null;
                        //     }else{
                        //         this.advancedSettings.sip =null;
                        //         this.advancedSettings.number =null;
                        //     }
                        // }else{
                        //     this.toastr.warning("Failed to update")
                        // }


                        if(resp.field_empty){
                            this.toastr.error("Fill all mandatory fields");
                        }else{
                            this.toastr.success("Settings Updated Successfully");
                        }
                    },
                    error => {
                        this.toastr.error("Error occurred")
                    }
                )



                // model.smartsipgroup = this.advancedSettings.smartsipgroup_id
                // if(payload.ring_type==this.ringType.SMART_SIP_GROUP){
                //     this.subs.sink = api2.subscribe(resp =>{

                //     },
                //     error=>{console.log(error);
                //     })
                // }
                //  }else{this.toastr.warning('Field cannot be Empty')}
        // }
    }

    scrollToTop() {
        this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}