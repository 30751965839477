import { id } from 'src/app/core/utils/global.functions';
export class addSC{
    public customer:any;
    public title:string;
    public unique_number:string;
    public tenant_customer:any;
    public tenant_customer_contact:number;
    public tenant_customer_location:number;
    public starting_date:string=undefined;
    public ending_date:string=undefined;
    public terms_and_condition:number;
    public conditions:string="";
    public coverage_type:number=0;
    public status:number;
    public sc_begins:number;
    public warranty:number;
    public duration:number;
    public timeframe:number;
    public billing_interval:number=0;
    public location_restriction:boolean;
    public coverage_type_selection:number;
    public suborg:number;


}

export class SCList{
    public created_by:string;
    public created_on:string;
    public created_user:number;
    public customer:number;
    public ending_date:string;
    public id:number;
    public is_active:boolean;
    public is_delete:boolean;
    public modified_on:string;
    public modified_user:string;
    public starting_date:string;
    public tenant_customer:string;
    public tenant_customer_contact_name:string;
    public tenant_customer_location:string;
    public tenant_customer_location_name:string;
    public tenant_customer_name:string;
    public terms_and_condition:string;
    public title:string;
    public unique_number:string;
    public conditions:string;
    public service_contract_status:string;
    public status:number;
    public expired_in_90_days:boolean;
    public coverage_type:number;
    public sc_begins:number=0;
    public warranty:number;
    public price:number;
    public item_price:number=0;
    public duration:number;
    public coverage_type_selection:number;
    public billing_interval:number;
    public location_restriction:boolean;

}


export class SCItemAdd{
    public service_contract:number;
    public associate_item:number;
    public customer:number;
    public quantity:number;
    public serial_number:number;
    public id:number;
    public billing_interval:number;
    public price:number;
}

export class SCKitsAdd{
    public service_contract:number;
    public associate_kit:number;
    public customer:number;
    public quantity:number;
    public serial_number:number;
    public id:number;
    public billing_interval:number;
    public price:number;
    public starting_date:Date;
    public ending_date:Date;

}


export class SCItemsList{
    public associate_item:number;
    public created_on:Date;
    public created_user:number;
    public customer:number;
    public ending_date:Date;
    public id:number;
    public image_link:string;
    public is_active:boolean;
    public is_delete:boolean;
    public item_name:string;
    public item_price:number;
    public modified_on:string;
    public modified_user:string;
    public part_number:string;
    public starting_date:Date;
    public service_contract:number;
    public quantity:number;
    public serial_number:string;
    public coverage_type:number;
    public billing_interval:number;
    public price:number;
}

export class SCKitsList{
    public associate_kit:number;
    public created_on:Date;
    public created_user:number;
    public customer:number;
    public ending_date:Date;
    public id:number;
    public internal_number:string;
    public is_active:boolean;
    public is_delete:boolean;
    public kit_name:string;
    public modified_on:string;
    public modified_user:string;
    public starting_date:Date;
    public service_contract:number;
    public quantity:number;
    public serial_number:string;
    public coverage_type:number;
    public billing_interval:number;
    public price:number;
}

export interface SCDashBoardCounts{
    Pending: number;
    Active: number;
    Expired: number;
    Draft: number;
    Expired_in_90_days: number;
}

export enum SCStatus{
    Pending = 1,
    Active = 2, 
    Expired = 3, 
    Draft = 4, 
    Expired_in_90_days = 5
}


export class SCComments {

    public id: number;

    public service_contract: number;

    public comments: string;

    public parent: number;

    public customer: number;

    public created_by: string;

    public created_on: Date;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public is_delete: boolean;

    public is_active: boolean;

    edit: boolean;

    inputRows: number = 1;
}

export class SCItemRow {

    public id: number;

    public item_id: number;

    public itemType: SCItemsType;

    public quantity: number = 1;

    public kit_id: number;

    public part_number: string;

    public sell_price: number = 0;

    public sell_price_calc: number = 0;

    public image_link: string;

    public item_name: string;

    public guid: string;

    public customer: number;

    public children: SCItemRow[];

    public expanded:boolean = false;

    public order:number;

    public serial_number:string;

    public coverage_type:number;

    public billing_interval:number;

    public price:number;

    public is_delete:boolean;


    constructor(){
        this.guid = id();
    }
}

export enum SCItemsType {
    ITEM = 'item',
    KIT = 'kit'
}