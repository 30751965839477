import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { ItemDataHelper } from '../../../helpers/item-data.helpers';
import { ItemWareHouseStock, ItemWrapper } from '../../../models/items';
import { KitAssembleMaterialLocation, KitAssembleMaterialRow } from '../../../models/kit-assemble.models';
import { WareHouse, WareHouseLocation } from '../../../models/warehouse.models';
import { ItemService } from '../../../services/item.service';
import { WarehouseService } from '../../../services/warehouse.service';

@Component({
    selector: 'app-item-warehouse-select',
    templateUrl: './item-warehouse-select.component.html',
    styleUrls: ['./item-warehouse-select.component.scss']
})
export class ItemWarehouseSelectComponent implements OnInit {

    @Input() selectBox: boolean = true;

    @Input() quantity_rq: number;

    @ViewChild('itemsModal') itemsModal: any;

    @Output() valueChange = new EventEmitter<number>();

    @Output() change = new EventEmitter<ItemWrapper>();

    @Output() select = new EventEmitter<KitAssembleMaterialLocation[]>();

    public fileUrl: string = environment.apiURL + '/static/files/';

    private modalRef: NgbModalRef;

    public items: ItemWrapper[];

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = ItemDataHelper.DefaultFilters;

    public search: GFilterParam[] = ItemDataHelper.SearchFilters;

    public sortLookup = ItemDataHelper.SortLookup;

    public selectedItem: ItemWrapper[] = [];

    public selected: ItemWrapper;

    public sort: GSortParam[];

    public columns: GFColumn[] = ItemDataHelper.FilterColumns;

    private subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public expanded: boolean = true;

    public Locs: KitAssembleMaterialLocation[] = [];

    public warehouses: WareHouse[];

    public warehouseLocations = {};

    total_quantity: number;

    total_selected_quantity: number;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public SelectionType = SelectionType;

    public ChangedItem: KitAssembleMaterialRow;

    IsChanged: boolean = false;

    bundlecount: number;

    total_required_quantity: number;

    public required_quantity: number = 0;

    isAllocate: boolean;

    constructor(private modal: NgbModal,
        private itemService: ItemService,
        private warehouseService: WarehouseService,
        private ra: ResourceAccessService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService) { }

    ngOnInit(): void {}

    open(row?: KitAssembleMaterialRow, bundlecount: number = 1, isAllocate: boolean = false) {
      this.expanded = true;
        this.ChangedItem = row;
        this.bundlecount = bundlecount
        this.isAllocate = isAllocate;
        let size = (this.isAllocate) ? 'lg' : 'xl'
        this.modalRef = this.modal.open(this.itemsModal, { size: size, scrollable: true });
        let filters = []
        if (this.isAllocate && row && row.part_number) {
            filters.push({ colname: "part_number", condition: "is", value: row.part_number, operator: "AND" });
        }
        this.getData(filters);
    }

    private getData(filters?: GFilterParam[], offset: number = 0) {
        let option = { sort: this.sort, filters: filters };
        this.subs.sink = this.itemService.getAll(option, offset, 50).subscribe(resp => {
            this.items = resp.result;
            console.log(resp.result)
            this.setPagination(offset, resp.count);
            this.cdr.markForCheck();
            if (this.isAllocate) {
                this.onSelect({ "selected": this.items })
            }
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }

    onSort(event: any, dataFilter: DataGridFilterComponent) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(dataFilter.getDataFilters());
        }
    }

    setPage(pageInfo: any, dataFilter: DataGridFilterComponent) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(dataFilter.getDataFilters(), offset);
    }

    onSelect({ selected }): void {
        let wrapper: ItemWrapper = selected[0];
        if (wrapper && this.hasPermission(this.Permissions.VIEW_INFO)) {
            this.selected = wrapper;
            this.required_quantity = this.ChangedItem.quantity;
            this.total_required_quantity = this.bundlecount * this.ChangedItem.quantity;
            this.expanded = false;
            this.getWarehouses();
            this.getStockByItem();
        }
    }

    getQuantityInfo() {
        this.subs.sink = this.warehouseService.getAll().subscribe(resp => {
            this.warehouses = resp;
            this.cdr.markForCheck();
        });
    }

    refreshDataTable() {
        setTimeout(() => {
            this.items = [...this.items];
            this.cdr.markForCheck();
        });
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.INV_ITEMS, [permission], ResourceConstants.INV_MODULE);
    }

    onCanceled() {
        this.expanded = true;
        this.selected = null;
        this.selectedItem = [];
        this.modalRef?.dismiss();
        this.refreshDataTable()
    }

    clearSelection(event: any) {
        event.stopPropagation();
        this.selected = null;
        this.valueChange?.emit(null);
        this.selectedItem = [];
        this.refreshDataTable()
    }

    onItemViewClose(): void {
        if (this.isAllocate) {
            this.modalRef?.dismiss();
        }
        else {
            this.expanded = true;
            this.selected = null;
            this.refreshDataTable();
        }
    }

    onSelected(): boolean {
        if (this.total_selected_quantity > this.total_required_quantity) {
            this.toastr.error(`Quantity mismatch`);
            return false;
        }
        if (!this.isAllocate) {
          this.change?.emit(this.selected);
      }
        this.select?.emit(this.Locs)
        this.modalRef?.dismiss();
        return true;
    }


    getWarehouses() {
        this.subs.sink = this.warehouseService.getAll().subscribe(resp => {
            this.warehouses = resp;
            this.cdr.markForCheck();
        });
    }

    getStockByItem() {
        this.subs.sink = this.itemService.getItemWarehouseStock(this.selected.id).subscribe(resp => {
            resp.map(w => {
                if (w.warehouse_location > 0) {
                    let loc = new WareHouseLocation();
                    loc.location = w.location_name;
                    loc.id = w.warehouse_location;
                    w.locations = of([loc]);
                }
            })
            this.Locs = []
            if(this.ChangedItem && this.ChangedItem.selected_stock && this.ChangedItem.selected_stock.length > 0){
              resp.forEach(
                (res, inx) =>{
                  let exist = this.ChangedItem.selected_stock.filter((row, index) =>{
                    return row.warehouse == res.warehouse && row.warehouse_location == res.warehouse_location
                  })
                  let elem = new KitAssembleMaterialLocation()
                  elem.unique_id = res.item;
                  elem.warehouse = res.warehouse
                  elem.warehouse_location = res.warehouse_location
                  elem.warehouse_name = res.warehouse_name
                  elem.location_name = res.location_name
                  elem.selected_quantity = 0
                  elem.available_quantity = res.available_quantity

                  if(exist.length > 0){
                    elem.id = exist[0].id;
                    elem.selected_quantity = exist[0].selected_quantity;
                  }
                  this.Locs.push(elem);
              })
            }
            else{
              resp.forEach(
                (res, inx) =>{
                  let elem = new KitAssembleMaterialLocation()
                  elem.unique_id = res.item;
                  elem.warehouse = res.warehouse
                  elem.warehouse_location = res.warehouse_location
                  elem.warehouse_name = res.warehouse_name
                  elem.location_name = res.location_name
                  elem.selected_quantity = 0
                  elem.available_quantity = res.available_quantity
                  this.Locs.push(elem)
                }
              )
              this.Locs.map(element => element.selected_quantity = 0);
            }
            this.Locs = this.Locs.sort((a,b)=> a.warehouse - b.warehouse);
            this.getAggregateQuantity();
            this.getSelectedAggregateQuantity()
            this.cdr.markForCheck();
        });
    }

    getAggregateQuantity() {
        this.total_quantity = this.Locs.reduce((sum, current) => sum + current.available_quantity, 0)
    }

    getSelectedAggregateQuantity() {
        this.total_selected_quantity = this.Locs.reduce((sum, current) => sum + current.selected_quantity, 0)
    }

    validateQuantity(row: KitAssembleMaterialLocation): boolean {
        if (this.total_selected_quantity > this.total_required_quantity) {
            this.toastr.error(`Quantity mismatch`);
            row.selected_quantity = row.selected_quantity - 1;
            this.getSelectedAggregateQuantity()
            return false;
        }
        return true;
    }
}
