<section class="grid-meta-form">
    <div class="col-12 col-md-10 col-lg-10 p-0 card">
        <div class="card-header">
            <h4 class="card-title">{{viewId > 0 ? 'Edit Grid View' : 'New Grid View'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form name="form" #viewForm="ngForm" (ngSubmit)="viewForm.form.valid && submit()" novalidate>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label class="val-required">Name</label>
                                <input type="text" [(ngModel)]="model.name" name="name" #name="ngModel" class="form-control" required>
                                <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                                    <div *ngIf="name.errors.required">
                                        Name is required.
                                    </div>
                                </small>
                                <small *ngIf="nameExists" class="form-text text-muted danger mt-1">
                                    Name already exists
                                </small>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Grid</label>
                                <app-grid-meta-select [(value)]="model.grid" (onChange)="onGridChanged($event)"></app-grid-meta-select>
                            </div>
                            <div class="form-group m-0">
                                <label class="val-required">Description</label>
                                <textarea type="text" [(ngModel)]="model.description" #desc="ngModel" name="description" class="form-control" required></textarea>
                                <small class="form-text text-muted danger" *ngIf="desc.invalid && (desc.dirty || desc.touched)">
                                <div *ngIf="desc.errors.required">
                                    Description is required.
                                </div>
                              </small>
                            </div>
                        </div>
                        <div class="col-12 col-md-7 d-flex flex-column">
                            <label>Columns</label>
                            <div class="dt-container">
                                <table class="meta-col-table w-100">
                                    <thead>
                                        <tr>
                                            <th [width]="30">
                                                <input type="checkbox" [(ngModel)]="allSelected" name="allselect" (change)="markAll()" />
                                            </th>
                                            <th [width]="150">Name</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let col of gridColumns | async | orderBy:'name' as columns; let i = index">
                                            <td>
                                                <input type="checkbox" [(ngModel)]="col.selected" [name]="'col'+i" />
                                            </td>
                                            <td>{{col.name}}</td>
                                            <td>{{col.description}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="grid-selection-message" *ngIf="!model.grid">Grid not selected</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-end mt-3">
                        <button type="submit" [disabled]="!viewForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                            {{viewId > 0 ? 'Save Changes' : 'Create'}}
                        </button>
                        <button (click)="location.back()" class="btn btn-secondary">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
