import { ToastrService } from 'ngx-toastr';
import { ResoureAccess } from './../../../resources/models/resource-access';
import { RoleService } from '../../services/role.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { RolePrivilege } from '../../models/role-previlege';
import { Permission } from 'src/app/modules/permissions/models/permission';
import { PrevilegesComponent } from '../../components/previleges/previleges.component';
import { ResourceService } from 'src/app/modules/resources/services/resources.service';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { Role } from '../../models/role';

@Component({
    selector: 'app-role2permission',
    templateUrl: './role2permission.component.html',
    styleUrls: ['./role2permission.component.scss']
})
export class Role2permissionComponent implements OnInit, OnDestroy {

    @ViewChild(PrevilegesComponent) privilegeComponent: PrevilegesComponent;

    role:Observable<Role>;

    resources: Observable<ResoureAccess[]>;

    resList:ResoureAccess[]=[];

    permissions: Observable<Permission[]>;

    roleId: number = 0;

    submit$: Subscription;

    resourceId: number;

    permissionId: number;

    @ViewChild('resourceSelect') resourceSelect: NgSelectComponent;

    @ViewChild('permissionSelect') permissionSelect: NgSelectComponent;

    constructor(private roleService: RoleService,
        route: ActivatedRoute,
        private toasts: ToastrService,
        private resourceService: ResourceService,
        private permissionServices: PermissionsService) {

        this.roleId = +route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        this.resources = this.resourceService.getAllCached();
        this.permissions = this.permissionServices.getAllCached();
        this.role = this.roleService.getById(this.roleId);

        this.resourceService.getAllCached().subscribe(response=>{
            this.resList=response;
        });


    }

    getResources(role:Role){

            if(role.is_customer_role){
                return this.resList.filter(val=>val.customer_has_access==true);
            }
            else{
                return this.resList;
            }

    }

    onSaveClicked() {
        if (this.resourceId && this.permissionId) {

            if (this.privilegeComponent.exist(this.resourceId, this.permissionId)) {
                this.toasts.info('Privilege exist!');
                return;
            }
            let privilege = new RolePrivilege();
            privilege.role = this.roleId;
            privilege.resource = this.resourceId;
            privilege.permission = this.permissionId;
            privilege.allowed = true;

            this.createPrevilege(privilege);
        }
    }

    createPrevilege(privilege: RolePrivilege) {
        this.submit$ = this.roleService.createPrivileges(privilege).subscribe(resp => {
            if (resp) {
                this.toasts.success('Success', 'New Privilege added');
                this.privilegeComponent.pushPrivilege(resp);
                this.resourceSelect?.handleClearClick();
                this.permissionSelect?.handleClearClick();
            }
        });
    }

    ngOnDestroy(): void {
        this.submit$?.unsubscribe();
    }
}
