import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { of } from 'rxjs';
import * as popup from 'src/app/core/utils/popup.functions';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { DistributorService } from '../../services/distributor.service';
import { DistributorContact } from '../../models/distributor';

@Component({
  selector: 'distributor-contacts',
  templateUrl: './distributor-contacts.component.html',
  styleUrls: ['./distributor-contacts.component.scss']
})
export class DistributorContactsComponent implements OnInit, OnDestroy {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ViewChild('FormModal') modalContent: any;

  private _distid: number;

  @Input() set distributorId(value: number) {
    this._distid = value;
    if (value) {
      this.getData();
    }
  }

  get distributorId() { return this._distid; };

  @Input() contactId: number;

  public rows: DistributorContact[];

  public SelectionType = SelectionType;

  public bindingModel: DistributorContact;

  public Permissions = PermissionConstants;

  private backgroundClasses: string[] = ["bg-primary", "bg-success", "bg-danger", "bg-warning", "bg-info"];

  private subs: SubSink = new SubSink();

  private modalRef: NgbModalRef;

  constructor(private service: DistributorService,
    private modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() { }

  getData() {
    this.subs.sink = this.service.getDistributorContacts(this.distributorId).pipe(
      catchError(() => of([]))
    ).subscribe(resp => {
      this.rows = resp;
      if (this.contactId > 0) {
        this.bindingModel = { ...this.rows.find(item => item.id === this.contactId) };
        this.openModal();
      }
      this.cdr.markForCheck();
    });
  }

  open() {
    this.bindingModel = undefined;
    this.openModal();
  }

  onSelect({ selected }) {
    let current: DistributorContact = selected[0];
    if (this.hasPermission(this.Permissions.MODIFY)) {
      this.bindingModel = { ...this.rows.find(item => item.id === current.id) };
      this.openModal();
    }
  }

  openModal() {
    this.modalRef = this.modal.open(this.modalContent, { size: 'lg', scrollable: true, centered: true });
  }

  deleteRow(id: number): void {

    popup.ConfirmDelete(result => {

      if (!result.isConfirmed) return;

      this.subs.sink = this.service.deleteDistributorContact(id).subscribe(
        () => {
          this.rows = this.rows.filter(x => x.id !== id);
          this.cdr.markForCheck();
          popup.CompleteDelete('Contact has been deleted.')
        },
        () => this.toastr.error('Unable to delete contact')
      );
    });
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.DISTRIBUTOR_CONTACTS, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.CUSTOMER_MODULE, [this.Permissions.GLOBAL]);
  }

  randomDpBackground(contact: DistributorContact) {
    contact.bgClass = contact.bgClass || this.backgroundClasses[Math.floor(Math.random() * this.backgroundClasses.length)];
    return contact.bgClass;
  }

  toggleExpandRow(row: DistributorContact) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDataSubmittedSuccess(result: { response: DistributorContact, message: string }): void {
    this.rows = [...this.rows.filter(item => item.id !== result.response.id), ...[result.response]];
    this.toastr.success(result.message);
    this.cdr.markForCheck();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeModal() { this.modalRef?.dismiss(); }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
