<div id="bs-canvas-right" [ngClass]="{ 'show-canvas': showCanvas }"
    class="bs-canvas bs-canvas-right show position-fixed bg-light h-100">
    <header class="bs-canvas-header p-1 bg-primary overflow-auto  cart-header">
        <button type="button" class="bs-canvas-close float-left close" aria-label="Close" aria-expanded="false"><span
                aria-hidden="true" class="text-white" (click)="onCarClosed()">&times;</span></button>
        <h5 class="text-white  font-weight-bold mb-0 text-center"><i class="fa fa-shopping-cart" aria-hidden="true"></i>&nbsp;Cart Items</h5>
    </header>
    <div class="bs-canvas-content">
        <div class="card  m-0">
            <div class="card-header">
                <p class="card-text font-weight-bold  mt-md-1  mb-2 space text-center">YOUR ORDERS </p>
                <hr class="my-2">
            </div>
            <div class="card-body pt-0">
                <div class="row  justify-content-left" *ngFor="let item of cartItems ; let i = index">
                    <div class="col -auto col-md-12">
                        <div class="media flex-column flex-sm-row">
                            <!-- <img *ngIf="item.image_link" class=" img-fluid" [src]="item.image_link" width="62" height="62">
                            <img *ngIf="!item.image_link" class=" img-fluid" src="../../../../../assets/img/svg/product-avatar.svg" width="62" height="62"> -->
                            <div class="media-body  my-auto">


                                <div class="row">
                                    <div class="col-md-4">
                                        <img *ngIf = "item.image_link"  class=" img-fluid" [src]="fileUrl + item.image_link" width="62" height="62">
                                        <img  *ngIf = "!item.image_link"  class=" img-fluid" src="../../../../../assets/img/svg/product-avatar.svg" width="62" height="62">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="mb-0"><b>{{item.name}}</b></p>
                                        <p><b>{{ (item.quantity * item.price) | currency : 'USD' : true : '1.2-2'}}</b></p>
                                    </div>
                                </div>
                                <div class="row mt-1">

                                    <div class="col-md-8">

                                        <div class="input-group">
                                            <input type="button" (click)="onChangeQuantity(item,1)" value="-" class="button-minus border-0   icon-shape icon-sm mx-1 " data-field="quantity">
                                            <input type="number" step="1" max="100" [(ngModel)]="item.quantity"  name="quantity" class="quantity-field border text-center mr-1" readonly>
                                            <input type="button" (click)="onChangeQuantity(item,0)" value="+" class="button-plus border-0  icon-shape icon-sm lh-0" data-field="quantity">
                                         </div>
                                    </div>
                                    <div class="col-md-4">
                                        <button (click)="onCartItemDelete(i)" type="button" class="btn-sm btn-outline-dark rounded-0">Remove</button>                          
                                    </div>

                           
                                </div>
                               
                                <!-- <div class="row ">
                                    
                                   <div class=" pl-0 d-flex my-auto">
                                    <p class="mb-0"><b>{{item.name}}</b></p>
                                    <p><b>{{ item.quantity * item.price}} $</b></p>
                                   </div>
                                    <div class=" pl-0 d-flex my-auto">
                                        <p (click)="onChangeQuantity(item,0)" class="btn-qty"><b>+</b></p>
                                        <p class="boxed-1">{{item.quantity}}</p>
                                        <p (click)="onChangeQuantity(item,1)" class="btn-qty"><b>-</b></p>
                                    </div>
                                    <p (click)="onCartItemDelete(i)" class="delete">Delete</p> -->


                                    <!-- <p class="mb-0"><b>{{item.name}}</b></p>
                                    <p><b>{{ item.quantity * item.price}} $</b></p> -->
                                        <!-- <div class="col-auto">
                                            <p class="mb-0"><b>{{item.name}}</b></p>
                                            <p><b>{{ item.quantity * item.price}} $</b></p>

                                            <div class=" pl-0 d-flex my-auto">
                                                <p (click)="onChangeQuantity(item,0)" class="btn-qty"><b>+</b></p>
                                                <p class="boxed-1">{{item.quantity}}</p>
                                                <p (click)="onChangeQuantity(item,1)" class="btn-qty"><b>-</b></p>
                                            </div>
                                        </div> -->
                                <!-- </div> -->
                            </div>
                        </div>
                        <hr class="my-2">
                    </div>
                    
                    <!-- <div class=" pl-0 flex-sm-col col-auto  my-auto ">
                       
                        <p (click)="onCartItemDelete(i)" class="delete">Delete</p>
                    </div> -->
                  
                </div>
                
                <!-- <hr class="my-2"> -->
                <div class="row ">
                    <div class="col">
                        <div class="row justify-content-center">
                            <div class="col-4">
                                <p class="mb-1"><b>Total</b></p>
                            </div>
                            <div class="flex-sm-col col-auto">
                                <p class="mb-1"><b>{{total | currency : 'USD' : true : '1.2-2'}}</b></p>
                            </div>
                        </div>
                        <!-- <div class="row justify-content-center">
                            <div class="col-4">
                                <p class="mb-1"><b>Shipping</b></p>
                            </div>
                            <div class="flex-sm-col col-auto">
                                <p class="mb-1"><b>{{shipping}} $</b></p>
                            </div>
                        </div> -->
                        <!-- <div class="row justify-content-center">
                            <div class="col-4">
                                <p><b>Total</b></p>
                            </div>
                            <div class="flex-sm-col col-auto">
                                <p class="mb-1"><b>{{total}} $</b></p>
                            </div>
                        </div> -->
                        <hr class="my-0">
                    </div>
                </div>
                <div class="row mb-5 mt-4 ">
                    <div class="col-md-7 col-lg-6 mx-auto">
                        <button type="button" class="btn btn-primary"
                            [disabled]="cartItems.length == 0" [routerLink]="['/sales/sales-orders/add']">Checkout</button>
                    </div>

                </div>
            </div>
        </div>
    </div>