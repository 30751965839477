<section class="grid-meta-form">
    <div class="col-12 col-md-8 p-0 card">
        <div class="card-header">
            <h4 class="card-title">{{metaId > 0 ? 'Edit Grid Meta' : 'New Grid Meta'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form name="form" #metaForm="ngForm" (ngSubmit)="metaForm.form.valid && onGridMetaSubmit()" novalidate>
                    <div class="col-12 p-0" style="min-height: 250px;">
                        <div class="form-group p-0 col-12 col-md-8">
                            <label class="val-required">Name</label>
                            <input type="text" [(ngModel)]="model.name" name="name" #name="ngModel" class="form-control" required>
                            <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                              <div *ngIf="name.errors.required">
                                  Name is required.
                              </div>
                            </small>
                        </div>
                        <div class="form-group p-0 col-12 col-md-8">
                            <label class="val-required">Description</label>
                            <textarea type="text" [(ngModel)]="model.description" #desc="ngModel" name="description" class="form-control" required></textarea>
                            <small class="form-text text-muted danger" *ngIf="desc.invalid && (desc.dirty || desc.touched)">
                              <div *ngIf="desc.errors.required">
                                  Description is required.
                              </div>
                            </small>
                        </div>
                        <div class="form-group">
                            <label class="val-required">Columns</label>
                            <div class="dt-container">
                                <table class="meta-col-table w-100">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let col of columns; let i = index" [id]="col._guid">
                                            <td>
                                                <input class="custom-input" type="text" [(ngModel)]="col.name" [name]="'col'+ i" placeholder="Enter Column Name" />
                                            </td>
                                            <td>
                                                <input class="custom-input" type="text" [(ngModel)]="col.description" [name]="'desc'+ i" placeholder="Enter Description" />
                                            </td>
                                            <td>
                                                <button (click)="removeColumn(col)" class="delete-btn text-danger">
                                                    <i class="ft-trash-2"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="link-section mt-1">
                                <a class="text-info" (click)="addColumn()"><i class="ft-plus"></i> Add Column</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 d-flex flex-sm-row justify-content-end mt-3 mt-sm-2">
                        <button type="submit" [disabled]="!metaForm.form.valid && columns.length <=0" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                          {{metaId > 0 ? 'Save Changes' : 'Create'}}
                        </button>
                        <button (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>