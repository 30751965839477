import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fabric } from 'fabric';
import { environment } from 'src/environments/environment';
import { CatalogueFunctionalService } from '../../../services/catalogue-functional.service';
import { promise } from 'protractor';
import { reject } from 'lodash';


@Component({
  selector: 'page-canvas-component',
  templateUrl: './page-canvas-component.component.html',
  styleUrls: ['./page-canvas-component.component.scss']
})
export class PageCanvasComponentComponent implements OnInit {


  public canvas: any;

  @Input() id: number;

  @Input() active: boolean = false;

  @Input() width: number;

  @Input() height: number;

  @Input() zoom: number;

  @Input() productIndexStart: number = 0;

  @Output() checkProductCount = new EventEmitter<any>();

  @Output() renderAll = new EventEmitter<any>();

  @Output() colorChanged = new EventEmitter<any>();

  @Input() data: any;

  @Input() enableControls: boolean = true;

  @Input() objectSelectable: boolean = true;

  @Input() customerView: boolean= false;

  public LimitedTextbox: any;

  public newLeft: number = 0; newTop: number = 0;

  public fileUrl: string = environment.apiURL + '/static/files/'

  public canvasBgColor: string = "#ffffff";


  public selectedColor: string = "#333333";


  public productImg: any = null
  // routPath:string


  canvasElement;






  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    // if(event.key === 'd'||event.key === 'D'||event.key==='Delete')
    if (event.key === 'Delete') {
      this.deleteElement();
    }
  }

  constructor(private catalogueFnService: CatalogueFunctionalService, private cdr: ChangeDetectorRef, private route: ActivatedRoute) {

    // const pathArray = this.route.snapshot.url.map(segment => segment.path); // get an array of path segments
    // const lastTwoPaths = pathArray.slice(-2); // get the last two path segments
    // this.routPath = lastTwoPaths.toString()

    // alert(this.routPath)

  }

  ngOnInit(): void {

    this.catalogueFnService.newLeft = 10;
    this.catalogueFnService.newRight = 10;


  }
  ngAfterViewInit(): void {


    // if(this.id==0)
    setTimeout(() => {
      this.canvasInit();

    }, 9);

  }

  canvasInit() {

    this.canvas = new fabric.Canvas('canvasid' + this.id,
      {
        width: this.width * 96 * this.zoom,
        height: this.height * 96 * this.zoom,
        selection: false,
      });

    this.canvas.preserveObjectStacking = true;

    this.canvas.preserveObjectStacking = true;

    this.canvas.setZoom(this.zoom);


    // this.initTextBox();


    // this.canvas.setZoom(this.zoom/100);

    // this.canvas.on('text:changed', window.alert('hi'));

    this.LimitedTextbox = this.catalogueFnService.customTextBox();


    // this.canvasZoom=Math.round(this.canvas.getZoom()*100);

    // if(this.width&&this.height){
    //   this.catalogueCanvas[0].setDimensions({width:this.width*96, height:this.height*96});
    //   this.canvas2.setDimensions({width:this.width*96, height:this.height*96});
    // }
    // this.updateCanvasSize(this.catalogueCanvas[0]);


    // this.canvas.on( 'object:moving', this.preventLeaving);

    let preventLeaving = this.catalogueFnService.preventLeaving;
    let preventTextboxScaling = this.catalogueFnService.preventTextboxScaling;

    let checkProducts = this.checkProducts;
    // let updateCanvas2=this.updateCanvas2;
    let canvas = this.canvas;
    // let canvas2=this.canvas2;
    // let dummyData=this.dummyData;
    // let wrapCanvasText=this.wrapCanvasText;
    // let testFun=this.testFun;
    let aThis = this;


    this.canvas.on('cpPresetColorsChange', function(event) {
      
      // Event handling code
      console.log('cpPresetColorsChange event triggered!');
      console.log(event); // You can access event data if any
    });

    this.canvas.on('object:modified', function (e) {
      //  testFun(e);
      //  preventLeaving(e);
    });

    this.canvas.on('after:render', function (e) {

      // checkProducts();

      //  preventLeaving(e);
      // this.canvas2=updateCanvas2(canvas, canvas2, wrapCanvasText, dummyData, aThis);
    });


    this.canvas.on('object:scaling', function (e) {
      preventTextboxScaling(e, canvas);
      //  preventLeaving(e);
      //  testFun(e);
    });

    canvas.on('object:resizing', function (e) {
      preventTextboxScaling(e, canvas);

      //  preventLeaving(e);

    });


    canvas.on('object:moving', function (e) {

      // preventLeaving(e);

    });
    // canvas.on('mouse:down', (event: fabric.IEvent) => {


    //  // alert('ondown');
    //   const target = canvas.findTarget(event.e);
    //   console.log('btn target', target)

    //   if (target && target?.type == 'group' && target?.name == "button") {

    //     console.log('down')

    //     target.scale(1.1);
    //     canvas.renderAll();

    //     this.selectedObject.emit(target)
    //     console.log('btn clicked')
    //   }
    //   else{
    //     this.selectedObject.emit(target)
    //     console.log('btn not clicked')
    //   }


    //  })

   // this.canvas.on('drop', function (evn: DragEvent) {

     // console.log('evn', evn)
      // this.addImageToCanvasOnDrag(evn)

   // }.bind(this));


    // this.canvas.on( 'before:transform', function(e){
    //   testFun(e);
    // });

    // this.canvas.on('object:modified',);
    // this.canvas.on('selection:created', this.updateTextboxSize);


    var input = document.getElementById('myInput');

    var fn = this;

    // var canvas=this.canvas;

    document.addEventListener('keydown', function (event) {
      //  alert(event.code);
      if (canvas.getActiveObject() && !canvas.getActiveObject()?.isEditing) {
        if (event.code == 'Backspace' || event.code == 'Delete') {
          fn.deleteElement();
        }
      }

    });
    
  }

  addImageToCanvasOnDrag(evn) {

    let html = evn.e.dataTransfer.getData('text/html');
    let src = new DOMParser().parseFromString(html, "text/html")
      .querySelector('img').src;

    console.log('html', html)

    const sizeof100 = (this.width * 96 * 100) / 100
    let img1, canvas = this.canvas;
    let newLeft = (evn.e.offsetX * (sizeof100 / this.canvas.width))// - ((84.38 / 2) * (sizeof100 / this.canvas.width))
    let newTop = (evn.e.offsetY * (sizeof100 / this.canvas.width)) //- ((84.38 / 2) * (sizeof100 / this.canvas.width))

    console.log('left', newLeft)
    console.log('top', newTop)
    fabric.Image.fromURL(src, function (myImg) {
      img1 = myImg.set({ left: newLeft, top: newTop });
      img1.scaleToHeight(84.38);
      img1.scaleToWidth(84.38);
      img1.name = 'item_image';
      canvas.add(img1);
    }.bind(this), {
      crossOrigin: 'anonymous'
    });
    this.canvas = canvas
  }

  addCanvasElement(item,left=0,top=0) {

    this.catalogueFnService.addNewAttribute(item, this.canvas, this.objectSelectable,left,top);

  }

  addElement2(item, canvas, group, newLeft1, newTop1, newScaleX1, newScaleY1) {

    // canvas=this.canvas;

    let newLeft, newTop, newScaleX, newScaleY;

    if (newLeft1) {
      newLeft = newLeft1;
      newTop = newTop1;
    }
    else {
      newLeft = item.left;
      newTop = item.top;
    }

    if (newScaleX1) {
      newScaleX = newScaleX1;
      newScaleY = newScaleY1;
    }
    else {
      newScaleX = item.scaleX;
      newScaleY = item.scaleY;
    }






    if (item.type == 'textbox') {

      var obj = new this.LimitedTextbox(item.text, {
        width: item.width,
        // height:item.customHeight,
        left: newLeft,
        top: newTop,
        fontSize: item.fontSize,
        editable: true,
        name: item.name,
        fill: item.fill,
        customHeight: item.customHeight,
        lockScalingY: false,
        scaleY: newScaleY,
        scaleX: newScaleX,
      });
      obj.set({ height: item.height });
      obj.selectable = this.objectSelectable;

      group.addWithUpdate(obj, { width: group.width, height: group.height, scaleX: group.scaleX, scaleY: group.scaleY });

      // this.canvas.add(obj);
      // this.canvas.renderAll();
      // this.updateCanvas2(this.canvas, this.canvas2, this.wrapCanvasText, this.dummyData, this);

      // return canvas;

    }
    else if (item.type == 'image') {
      var img1

      fabric.Image.fromURL(item.src, function (myImg) {


        img1 = myImg.set({ left: newLeft, top: newTop, crossOrigin: 'anonymous' });
        img1.scaleToHeight(item.height * newScaleY);
        img1.scaleToWidth(item.width * newScaleX);
        img1.editable = false;
        img1.name = item.name;
        img1.selectable = this.objectSelectable;






        // canvas.add(img1);
        // canvas.renderAll();
        group.addWithUpdate(img1, { width: group.width, height: group.height, scaleX: group.scaleX, scaleY: group.scaleY });
        canvas.renderAll();



      }.bind(this), {
        crossOrigin: 'anonymous'
      });



    }
    else if (item.type == 'rect') {
      var rect = new fabric.Rect({
        top: newTop,
        left: newLeft,
        width: item.width,
        height: item.height,
        scaleX: newScaleX,
        scaleY: newScaleY,
        fill: item.fill,
        rx: item.rx,
        ry: item.ry,
        opacity: item.opacity,
        shadow: item.shadow
      });

      rect.selectable = this.objectSelectable;

      group.addWithUpdate(rect, { width: group.width, height: group.height, scaleX: group.scaleX, scaleY: group.scaleY });

    }

    return canvas;

  }

  addElementGroup(event) {


    this.catalogueFnService.addElementGroup(this.canvas, event.componentName, event.component, event.subElement, event.group);


    setTimeout(() => {
      this.checkDataChange();

    }, 30);

    setTimeout(() => {

      this.renderAll.emit();

    }, 40);
    // this.checkDataChange();
    // this.checkProducts();
  }

  updateVal(newIndex, data, element,newJson,Objindex,childobjIndx)
  {
    return new Promise((resolve, reject) => {

      element.selectable = false

          if (element.name && element?.name == 'item_image') { //create catalog

            const promise = this.setProductImage(element, data[newIndex])
            promise.then((result:any) => {  
              result.name = "proImgGroup"
              newJson.objects[Objindex].objects[childobjIndx] = result
              resolve(true);
            })
          }
         
          else if(element?.type == 'group' && element?.name == 'proImgGroup') //filter
          {
            const promise = this.setProductImage(element, data[newIndex],true)
                promise.then((result:any) => {  
                  result.name = "proImgGroup"
                  newJson.objects[Objindex].objects[childobjIndx] = result
                  resolve(true);
                })
          }
          else {
            element = this.updateData(element, data[newIndex]);
            resolve(true);  
          }

    });
  }
  addPageToCanvas(json, secIndex = 0, productiIndex = 0, data: any = []) {

    
    this.canvas.clear();
    let newJson = JSON.parse(JSON.stringify(json));
    const promises = [];

    let filterMode:boolean=this.catalogueFnService.filterMode;

    let newJsonData={...newJson};

    newJsonData.objects=[];

    let updatedData;

    if (newJson) {

      
      newJson.objects.forEach((val, index) => {

        
        updatedData='';

        let newIndex = Number(productiIndex) + Number(index);

        
        if(filterMode){
          
          newJsonData.objects.push(val);
          if (val.name == "productComponent" && data[newIndex]) {
    
            val.objects.forEach((element, i) => {
  
            updatedData=this.updateVal(newIndex, data,element,newJson,index,i);
             promises.push(updatedData); 
            });
  
            
          } 
          // else if(!val.name||val.name!='productComponent'){
          //   newJsonData.objects.push(val);
          // }
        }
        else{

          newJsonData.objects.push(val);

          
          if (val.name == "productComponent" && data[newIndex]) {

            val.visible = true
            val.objects.forEach((element, i) => {
                   
             updatedData=this.updateVal(newIndex, data,element,newJson,index,i);
             promises.push(updatedData);
  
            });
  
          } 
          else if(val.name == "productComponent" && !data[newIndex])
          {
            val.visible = false
            val.objects.forEach((element, i) => {
            })
          }

        }

      });
    }
    return Promise.all(promises).then(() => {

      
      return new Promise((resolve, reject) => {

      
        this.canvas.loadFromJSON(newJsonData, this.canvas.renderAll.bind(this.canvas), function (o, object) {

          console.log('newJsonData', newJsonData)
          
          // object.set({'selectable':})=this.objectSelectable;
          object.selectable = this.objectSelectable;
          // this.canvas.renderAll.bind(this.canvas);
          this.checkProducts(this.canvas.getObjects(), this);
        }.bind(this));
        resolve(true)
      })
      
    })

  }

  setProductImage(val: any, data,isGroup : boolean = false) {
    return new Promise((resolve, reject) => {

      
      var url = '/assets/img/catalogue/item-image-placeholder.jpeg'
      // if(data?.product_image == undefined && data?.primary_image == undefined)
      // {
      //     url = '/assets/img/catalogue/item-image-placeholder.jpeg'
      // }
      if( data?.product_image != undefined && data.product_image != null)
      {
        url = this.fileUrl + data.product_image
      }
      if( data?.primary_image != undefined && data.primary_image != null)
      {
        url = this.fileUrl + data.primary_image
      }
      
      console.log('imgURL',data.product_image)
      fabric.Image.fromURL(url, function (img) {
     
        img.set({crossOrigin: 'anonymous' });
        var group:any
        var rect :any

        if(!isGroup)
        {
          
          rect = this.addRect(val)
          group = new fabric.Group([rect],{
          });
          resolve(this.addProductImage(group,img))
        }
        else{

          val.objects.splice(1,1)
          fabric.Group.fromObject(val,(grpobj)=>{
            
            group = grpobj

            resolve(this.addProductImage(group,img))

          });         
        }

     }.bind(this),{
      crossOrigin: 'anonymous'
    });


    })


  }

  addProductImage(group:any,img:any){

    let bounds = group.getBoundingRect();
      

    const scaleFactor = Math.min(
      Math.min(1, (bounds.width / (img.width + 100))), // /2 for middle
      Math.min(1, (bounds.height / (img.height + 100))) 
    );
    img.scale(scaleFactor);

    img.set({ 
      top: bounds.top + (Math.max(bounds.height - img.height * scaleFactor, 0) / 2),
      left: bounds.left + (Math.max(bounds.width - img.width * scaleFactor, 0) / 2),
    });

     group.addWithUpdate(img);

     
     const groupJson = JSON.stringify(group.toObject());

     
      return JSON.parse(groupJson)

  }
  updateData(val, data) {


    let text;

    // template.forEach((val, index) => {

    if (data) {



      if (val.name && val.name == 'item_name') {
        text = data?.product_name ? data.product_name : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }

      else if (val.name == 'sku') {
        text = data?.sku ? data.sku : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'category') {
        text = data?.category_name ? data.category_name.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'manufacturer') {
        text = data?.manufacturer_name ? data.manufacturer_name.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'part_number') {
        text = data?.product_part_number ? data.product_part_number.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'supplier') {
        text = data?.suplier ? data.suplier.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'sell_price') {
        text = data?.sellprice ? data?.sellprice.toString() : '';
        if(text == '')
        {
          text = data?.sell_price ? data?.sell_price.toString() : '';
        }
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'item_type' || val.name == 'type') {
        text = data?.product_type ? data.product_type.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }


      ///// new
      else if (val.name == 'qr_code') {
        text = data?.qr_code ? data.qr_code.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'werehouse') {
        text = data?.werehouse ? data.werehouse.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'werehouse_location') {
        text = data?.werehouse_location ? data.werehouse_location.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
      else if (val.name == 'serial_number') {
        text = data?.serial_number ? data.serial_number.toString() : '';
        val.text = this.catalogueFnService.wrapCanvasText(text, this.canvas, val);
      }
    }
    // });


    return val;

  }

  checkDataChange(secIndex = 0) {


    //To persis additional fields added in the canvas object
    const originalToObject = fabric.Object.prototype.toObject;
    const myAdditional = ['name', 'customHeight'];
    fabric.Object.prototype.toObject = function (additionalProperties) {

      return originalToObject.call(this, myAdditional.concat(additionalProperties));
    }
    //To persis additional fields added in the canvas object


    let canJson = this.canvas.toJSON();
    

    canJson.objects.forEach((val, index) => {

      let newIndex = this.productIndexStart + index;


      if (val.name == "productComponent") {
        if (val.objects) {


          if (this.catalogueFnService.catalogData[secIndex] && this.catalogueFnService.catalogData[secIndex].length && this.catalogueFnService.catalogData[secIndex][newIndex]) {

            
             val.objects = this.updateData(val.objects, this.catalogueFnService.catalogData[secIndex][newIndex]);
     

          }

        }
      }

      val.selectable = this.objectSelectable;

    });





    let aThis = this;
    this.canvas.loadFromJSON(canJson, function () {
      // aThis.canvas.renderAll.bind(aThis.canvas);
      aThis.checkProducts(aThis.canvas.getObjects(), aThis);

    });

  }
  addRect(obj) {

    var rect = new fabric.Rect({
      left: obj.left,
      top: obj.top,
      originX: 'left',
      originY: 'top',
      width: obj.width,
      height: obj.height,
      angle: 0,
      selectable: false,
      fill: '#FFFFFF',
      stroke: 'black',  
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,//0.11,
      transparentCorners: false,
      name: 'productimgbox'
    });

    return rect


    // return new Promise ((resolve, reject) => {
    //   this.canvas.add(rect);
    // })

  }







  setZoom(zoom) {
    this.canvas.setZoom(zoom);
    this.canvas.renderAll();
  }




  newElementLeft(canvas = this.canvas) {


    if ((this.newLeft + 200 < canvas.width)) {
      this.newLeft += 10;
      return this.newLeft;
    }
    else {
      return this.newLeft = canvas.width / 3;

    }


  }

  newElementTop(canvas = this.canvas) {

    if ((this.newTop + 50 < canvas.height)) {
      this.newTop += 10;
      return this.newTop;
    }
    else {
      return this.newTop = canvas.height / 3;

    }
  }

  elementBold() {

    if (this.canvas.getActiveObject().fontWeight == 'bold') {
      this.canvas.getActiveObject().fontWeight = 'normal';
    }
    else {
      this.canvas.getActiveObject().fontWeight = 'bold'
    }

    // let text = this.wrapCanvasText(this.canvas.getActiveObject().text, this.canvas, this.canvas.getActiveObject());

    // this.canvas.getActiveObject().text=text;
    this.canvas.getActiveObject().dirty = true;

    this.canvas.renderAll();
  }

  elementItalic() {

    if (this.canvas.getActiveObject().fontStyle == 'italic') {
      this.canvas.getActiveObject().fontStyle = 'normal';
    }
    else {
      this.canvas.getActiveObject().fontStyle = 'italic';
    }
    this.canvas.getActiveObject().dirty = true;


    this.canvas.renderAll();
  }

  elementUnderline() {

    if (this.canvas.getActiveObject().underline) {
      this.canvas.getActiveObject().underline = false;
    }
    else {
      this.canvas.getActiveObject().underline = true;
    }
    this.canvas.getActiveObject().dirty = true;
    this.canvas.renderAll();
  }

  deleteElement() {
    this.canvas.remove(this.canvas.getActiveObject());

    this.renderAll.emit();
    // this.updateCanvas2(this.canvas, this.canvas2, this.wrapCanvasText, this.dummyData, this);

  }

  addBigText(fontSize: number, text: string ,left = 0,top = 0) {

    
    let newLeft;
    let newTop;

    if(left > 0)
    {
      newLeft = left;
      newTop = top;
    }
    else
    {
      newLeft = this.newElementLeft(this.canvas);
      newTop = this.newElementTop(this.canvas);
    }
    var obj = new this.LimitedTextbox(text, {
      left: newLeft,
      top: newTop,
      fontSize: fontSize,
      height: 50,
      width: 400,
      fill: '#333333',
      customHeight: 50,
      fontWeight: 'bold',
      editable: true,
      name: 'plaintext',
    });
    // obj.set({height:item.height});
    this.canvas.add(obj);
    this.canvas.renderAll();
  }


  checkProducts(objects, aThis = this) {
    //.filter(obj => obj.name ===  mponent')
    // let objects=this.canvas.getObjects();
    aThis.checkProductCount.emit({ objects: objects, index: this.id });
  }

  getCanvasObjects() {
    return this.canvas.getObjects();
  }

  canvasBgChange(value) {

    this.canvas.set({ backgroundColor: value.color });
    this.canvas.renderAll();
    this.canvasBgColor = value.color;
    this.colorChanged.emit(true)
    this.cdr.markForCheck();
  }

  onPresetColorsChange(color) {

    this.canvas.set({ backgroundColor: color });
    this.canvas.renderAll();
    this.canvasBgColor = color;
    this.colorChanged.emit(true)
    this.cdr.markForCheck();
  }

  changeColor(value) {


    this.canvas.getActiveObject().set({ fill: value.color });

    this.canvas.renderAll();

  }
  presetColorsChange(color)
  {
    this.canvas.getActiveObject().set({ fill: color });

    this.canvas.renderAll();
  }
  addImageToCanvas(src) {

    var img1, canvas = this.canvas;
    let newLeft = this.newElementLeft();
    let newTop = this.newElementTop();

    fabric.Image.fromURL(src, function (myImg) {
      var img1 = myImg.set({ left: newLeft, top: newTop });
      img1.scaleToHeight(67.5);
      img1.scaleToWidth(100);
      img1.name = 'item_image';
      canvas.add(img1);
    }.bind(this), {
      crossOrigin: 'anonymous'
    });
    this.canvas = canvas


    console.log('svgcanvas', this.canvas.toSVG())
  }
}
