import { CustomerContact } from './../../models/tenant-customer';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TenantCustomerService } from '../../services/tenant-customer.service';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, ElementRef, Renderer2, EventEmitter, Output } from '@angular/core';
import { SelectionType, DatatableComponent, elementsFromPoint, DataTableBodyComponent } from '@swimlane/ngx-datatable';
import * as popup from 'src/app/core/utils/popup.functions';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { GFilterParam, DataFilterRequest, GSortParam, GFColumn, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';

@Component({
    selector: 'customer-contacts',
    templateUrl: './customer-contacts.component.html',
    styleUrls: ['./customer-contacts.component.scss']
})
export class CustomerContactsComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @ViewChild('dataTable') dataTable: ElementRef;

    @ViewChild('testTitle') testTitle: ElementRef;

    @ViewChild('FormModal') modalContent: any;

    public page = { count: 0, limit: 25, offset: 0, pageNumber: '0-0' };

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Combo', colname: '', condition: '', value: '',
            children: [
                { displayName: 'First Name', colname: 'first_name', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Last Name', colname: 'last_name', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Mobile', colname: 'mobile', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                { displayName: 'Phone', colname: 'phone', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                { displayName: 'Location Name', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
            ]
        }
    ];

    public columns: GFColumn[] = [
        { displayName: 'First Name', columnName: 'first_name', columnType: GFColumnTypes.string },
        { displayName: 'Last Name', columnName: 'last_name', columnType: GFColumnTypes.string },
        { displayName: 'Active', columnName: 'is_active', columnType: GFColumnTypes.boolean },
        { displayName: 'Location', columnName: 'location', columnType: GFColumnTypes.ref },
        { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
        { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
    ];

    public sort: GSortParam[] = [{ colname: 'first_name', direction: 'asc' }];

    public sortLookup = {
        first_name: 'first_name',
        email: 'email',
        phone: 'phone',
        mobile: 'mobile',
        location_name: 'location_name'
    };

    private _teantcustid: number;

    get tenantCustId() { return this._teantcustid; };

    @Input() set tenantCustId(value: number) {
        this._teantcustid = value;
        // if (value) {
        //     this.getData([], 0, true);
        // }
    }

    @Input() contactId: number;

    @Input() suborg_id: number;

    @Input() newButton:boolean=true;

    @Input() advanceFilter:boolean=true;

    @Input() readOnly:boolean=false;

    @Input() showSubOrg:boolean=false;

    @Input() showFilter:boolean=true;

    @Input() enableCall:boolean=false;

    @Input() set tableHeight(value: number) {
        if (value) {
          this._tableHeight = value;
      
          const newHeight = this._tableHeight;
          const datatableBody = this.elementRef.nativeElement.querySelector('.datatable-body');
          if (datatableBody) {
            datatableBody.style.maxHeight = `${newHeight}px`;
            datatableBody.style.overflowY = 'auto';
          }
        }
      }

    @Input() columnsToShow:string[]=['first_name','email','phone','mobile','location_name', 'portal_access', 'action'];

    @Output() onSearch=new EventEmitter<any>();

    @Output() addForCall=new EventEmitter<any>();

    public _tableHeight:number;

    public rows: CustomerContact[];

    public SelectionType = SelectionType;

    public bindingModel: CustomerContact;

    public Permissions = PermissionConstants;

    private backgroundClasses: string[] = ["bg-primary", "bg-success", "bg-danger", "bg-warning", "bg-info"];

    private subs: SubSink = new SubSink();

    private modalRef: NgbModalRef;

    public alphabets:string[]=['ALL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

    public selectedLetter:string='ALL';

    @Input() showDataTableSection:boolean = true;

    @Input() showAlphabeticFilter:boolean = true;

    constructor(private tenantCustService: TenantCustomerService,
        private elementRef: ElementRef,
        private modal: NgbModal,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private renderer2:Renderer2,
        private ra: ResourceAccessService) { }

    ngOnInit() { 

    }
    ngAfterContentInit(){


    }

    ngAfterViewInit(){

        if(this._tableHeight){
            const newHeight = this._tableHeight;
            this.elementRef.nativeElement
              .querySelector('.datatable-body')
              .setAttribute('style', `max-height: ${newHeight}px; overflow-y: auto;`);
        }
        this.getData([], 0, false);

    }

    getData(filters: GFilterParam[] = [], offset: number = 0, activeContact = false, hideLoading:boolean=false) {

        let request: DataFilterRequest = {
            filters: filters,
            sort: this.sort,
            base_filters: this._teantcustid?[{ colname: 'tenant_customer', condition: 'equalto', value: this.tenantCustId, 'operator':'AND' }]:[]
        };

        this.subs.sink = this.tenantCustService.getCustomerContactsFiltered(request, this.page.limit, offset, hideLoading).subscribe(resp => {
            this.rows = resp.result;
            this.setPagination(offset, resp.count);
            if (activeContact && this.contactId > 0) {
                this.bindingModel = { ...this.rows.find(item => item.id === this.contactId) };
                this.openModal();
            }
            this.cdr.markForCheck();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    openForm() {
        this.bindingModel = undefined;
        this.openModal();
    }

    onSelect({ selected }) {
        if(!this.readOnly){

            let current: CustomerContact = selected[0];
            if (this.hasPermission(this.Permissions.MODIFY)) {
                this.bindingModel = { ...this.rows.find(item => item.id === current.id) };
                this.openModal();
            }

        }

    }

    openModal() {
        this.modalRef = this.modal.open(this.modalContent, { size: 'lg', scrollable: true, centered: true });
    }

    deleteRow(id: number): void {

        popup.ConfirmDelete(result => {

            if (!result.isConfirmed) return;

            this.subs.sink = this.tenantCustService.deleteCustomerContact(id).subscribe(
                () => {
                    this.rows = this.rows.filter(x => x.id !== id);
                    this.setPagination(this.page.offset, this.page.count - 1);
                    this.cdr.markForCheck();
                    popup.CompleteDelete('Contact has been deleted.')
                },
                () => this.toastr.error('Unable to delete contact')
            );
        });
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.CUSTOMER_CONTACTS, [permission, this.Permissions.GLOBAL], ResourceConstants.CUSTOMER_MODULE);
    }

    randomDpBackground(contact: CustomerContact) {
        contact.bgClass = contact.bgClass || this.backgroundClasses[Math.floor(Math.random() * this.backgroundClasses.length)];
        return contact.bgClass;
    }

    toggleExpandRow(row: CustomerContact) {
        this.table.rowDetail.toggleExpandRow(row);
    }

    onDataSubmittedSuccess(result: { response: CustomerContact, message: string }): void {
        this.rows = [...this.rows.filter(item => item.id !== result.response.id), ...[result.response]];
        if (!this.bindingModel) {
            this.page.count += 1;
        }
        this.toastr.success(result.message);
        this.cdr.markForCheck();
        this.closeModal();
    }

    onDataError(_error: any): void {
        this.toastr.error('Operation failed');
        this.closeModal();
    }

    onCancel(): void { this.closeModal(); }

    closeModal() { this.modalRef?.dismiss(); }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        let params = this.dataFilter?.getDataFilters() || [];

        if(this.tenantCustService.externalFilter.length){
            params=params.concat(this.tenantCustService.externalFilter);
        }
        this.getData(params, offset);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            let params = this.dataFilter?.getDataFilters() || [];
            this.getData(params, 0);
        }
    }

    applyFilter(params: GFilterParam[], hideLoading:boolean=false, externalFilter:boolean=false,datafilter=null) {
        if(datafilter){
            this.dataFilter = datafilter;
        }
        if(externalFilter){
            this.tenantCustService.externalFilter=params;
        }
        else{
            this.onSearch.emit(params);
            this.tenantCustService.externalFilter=[];
        }
        this.getData(params, 0, false, hideLoading );
    }

    showColumn(column:string){

        if(this.columnsToShow.includes(column)){
            return true;
        }
        else{
            return false;
        }
    }

    callNumber(number:string){

        this.addForCall.emit(number);

    }

    filterContactByAlphabet(letter:string){
        this.selectedLetter=letter;
        let filter=[];
        if(letter!="ALL"){
            filter=[
            {displayName: "First Name", colname: "first_name", condition: "startswith", value: letter, operator: "OR"},
            // {displayName: "Last Name", colname: "last_name", condition: "startswith", value: letter, operator: "OR"}
            ];
        }
        this.applyFilter(filter)
    }

    ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
