<div class="select-wrapper">
    <ng-select class="ngselect"
               placeholder="select or search Time Frames"
               [appendTo]="'body'"
               [items]="options | async"
               bindLabel="name"
               bindValue="id"
               [(ngModel)]="selected"
               [readonly]="readonly"
               [typeahead]="textInput"
               [minTermLength]="2"
               [trackByFn]="trackByFn"
               typeToSearchText="Please enter 2 or more characters"
               (open)="selectOpen()"
               (clear)="onClear()">
            <ng-template ng-label-tmp let-item="item" class="w-100">
                <div class=" w-100">
                    <div class="col-12 pl-0 d-flex justify-content-between">
                        <div>{{item.name}}</div>
                        <div><span class="badge badge-secondary" [ngClass]="item.type=='Always'?'badge-secondary':item.type=='Days/Time'?'badge-info':'badge-warning'">{{item.type}}</span></div>
                    </div>
                </div>
            </ng-template>
            <ng-template ng-header-tmp>
                <div class="custom-option custom-header">
                    <div class="column width-50-per">Name</div>
                    <div class="column width-50-per">Type</div>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <div class="custom-option">
                    <div class="column width-50-per" style="padding-right: 3px;">
                        {{item.name}}
                    </div>
                    <div class="column width-50-per" style="padding-left: 3px;" *ngIf="item.type">
                        {{item.type}}
                    </div>
                </div>
            </ng-template>
        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
                <a class="add-button text-info" *ngIf="addButton" (click)="openModal(content)">
                    <i class="icon icon-settings mr-1"></i> Create New Time Frame
                </a>
            </div>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Time Frame</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-add-time-frame
                (success)="onDataSubmittedSuccess($event)"
                (error)="onDataError($event)"
                (cancel)="onCancel()">
            </app-add-time-frame>
        </div>
    </ng-template>
</div>
