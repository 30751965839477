import { OrderByPipe } from './../../../../shared/pipes/order-by.pipe';
import { ServiceTicketService } from './../../services/service-ticket.service';
import { TimeSheet } from '../../models/common.models';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { Pagination, GFilterParam, GSortParam, DataFilterRequest, GFColumn, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { BillingStatus } from '../../models/enums';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TimeSheetDataHelper } from '../../helper/time-sheet-data.helper';
@Component({
  selector: 'app-time-sheets',
  templateUrl: './time-sheets.component.html',
  styleUrls: ['./time-sheets.component.scss']
})
export class TimeSheetsComponent implements OnInit, OnDestroy {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild('formModal') postModal: any;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = TimeSheetDataHelper.defaults;

  public searchFilters: GFilterParam[] = TimeSheetDataHelper.search;

  public columns: GFColumn[] = TimeSheetDataHelper.columns;

  public sort: GSortParam[] = [{ colname: 'assigned_date', direction: 'desc' }];

  public sortLookup = TimeSheetDataHelper.sortLookup;

  public billingStatusEnum = BillingStatus;

  public billingStatus: { key: string, value: number, class: string }[] = [
    { key: 'Unbilled', value: BillingStatus.Un_billed, class: 'badge bg-warning' },
    { key: 'Invoiced', value: BillingStatus.Invoiced, class: 'badge bg-info' },
    { key: 'Paid', value: BillingStatus.Paid, class: 'badge bg-success' },
    { key: 'Overdue', value: BillingStatus.Overdue, class: 'badge bg-danger' },
  ];

  public SelectionType = SelectionType;

  public rows: TimeSheet[];

  public timeSheetId: number;

  public expanded: boolean = true;

  public selected: TimeSheet;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public UIPermissions: UIPermission;

  public dashlet: { Billable: number, Invoiced: number, Non_billable: number, Overdue: number, Paid: number, Un_billed: number };

  constructor(private modalService: NgbModal,
    private service: ServiceTicketService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private device: DeviceDetectorService,
    private order: OrderByPipe) {

    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.ST_TIMSHEET, ResourceConstants.SERVICE_TICKET_MODULE);
  }

  ngOnInit(): void {
    this.scrollbarH = this.device.isMobile();
    this.getData(null);
    this.getDashlet();
  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let request: DataFilterRequest = { filters: filters, sort: this.sort }

    this.subs.sink = this.service.getTimesheetsFiltered(request, offset).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }

  getDashlet() {
    this.subs.sink = this.service.getTimesheetDashlet().subscribe(resp => {
      this.dashlet = resp;
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: TimeSheet = selected[0];
    if (current && this.UIPermissions.viewInfo) {
      this.timeSheetId = current.id;
      this.selected = current;
      this.expanded = false;
      this.refreshDataTable();
    }
  }

  openModal(id: number = 0) {
    this.timeSheetId = id;
    this.modalService.open(this.postModal, { centered: true, size: 'lg', scrollable: true });
  }

  closeModal() { this.modalService.dismissAll(); }

  onDataSubmittedSuccess(result: { response: TimeSheet, message: string }): void {
    this.toastr.success(result.message);
    if (this.page.count < this.page.limit) {
      this.rows = this.order.transform([...this.rows.filter(w => w.id !== this.timeSheetId), result.response], 'assigned_date', true);
      this.page.count = this.rows.length;
    }
    this.selected = result.response;
    this.expanded = false;
    this.refreshDataTable();
    this.getDashlet();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
    });
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter.getDataFilters(), offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop]) return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(this.dataFilter.getDataFilters());
    }
  }

  deleteRow(id: number) {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.service.deleteTimeSheet(id).subscribe(
          () => {
            popup.CompleteDelete('Timesheet has been deleted.');
            this.rows = this.rows.filter(x => x.id !== id);
            this.cdr.markForCheck();
            this.closeDetailedView();
          },
          () => this.toastr.error('Unable to delete row')
        );
      }
    });
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }

  getTime(timeInMi: number) {
    let h = Math.floor(timeInMi / 60);
    let m = timeInMi % 60;
    return `${h} Hours ${m} Minutes`;
  }

  getBillingStatus(val: number) {
    return this.billingStatus.find(item => item.value === val);
  }

  setFilter(colname: string, value: any) {
    this.dataFilter.setDefaultFilter(colname, value);
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
