import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'timepicker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  private _value: Time | TimeRange;

  get value(): Time | TimeRange {
    return this._value;
  }

  @Input() set value(val: Time | TimeRange) {
    this._value = val;
    if (val instanceof TimeRange) {
    this.startRange = val.start;
    this.endRange = val.end;
    }
    else if(val){
        this.startRange = val
    }
  }

  @Output() valueChange = new EventEmitter<Time | TimeRange>();

  @Output() change = new EventEmitter<Time | TimeRange>();

  @Input() rangePicker: boolean;

  @Input() label: string;

  @Input() startRangeLabel: string;

  @Input() endRangeLabel: string;

  public startRange: Time = new Time();

  public endRange: Time = new Time();

  public hours: number[] = [...Array(13).keys()].slice(1);

  public minutes: number[] = [...Array(60).keys()];

  constructor() {}

  ngOnInit(): void {}

  inputChange() {
    this.change?.emit(
      this.rangePicker
        ? { start: this.startRange, end: this.endRange }
        : this.startRange
    );
  }

  padNumber(value: number) {
    return value > 9 ? value : '0' + value;
  }

  customSelectSearch(search:{ term: string, items: number[] }, model: Time, prop: string) {
    let exact:number = search.items?.find(num => num === parseInt(search.term));
    if (exact >= 0) {
      model[prop] = exact;
      this.inputChange();
    }
  }

  customSearch(term: string, item: any) {
    return `${item}`.indexOf(`${parseInt(term)}`) > -1;
  }
}

export class Time {
  hour: number;

  minute: number;

  second: number;

  meridian: string = 'AM';

  constructor(hour?: number, minute?: number) {
    if (hour >= 0) {
      this.meridian = hour >= 12 ? 'PM' : 'AM';
      this.hour = hour % 12 || 12;
    }
    this.minute = minute;
  }

  padNum(value: number) {
    return value > 9 ? value : '0' + value;
  }

  to24Format() {
    let hour24 = this.getHours();
    return `${this.padNum(hour24)}:${this.padNum(
      this.minute || 0
    )}:${this.padNum(this.second || 0)}`;
  }

  getHours() {
    let hour = this.hour;
    if (hour === 12) {
      hour = 0;
    }
    if (this.meridian === 'PM') {
      hour = hour + 12;
    }
    return hour;
  }
}

export class TimeRange {
  start: Time;

  end: Time;
}
