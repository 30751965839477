<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataFilter [searchFilters]="searchFilters" [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [expanded]="expanded"
                                     [newButton]="true" 
                                     [dontDisableIfAllsuborg]="true"
                                     (apply)="applyFilter($event)" 
                                     (newButtonClick)="open(content)"
                                     [module]="module">
                            <ng-template filterControl [column]="'customer'" let-row="row">
                            <app-customer-select class="ng-select-sm"
                                [(value)]="row.value"
                                (change)="dataFilter.controlValueChanged('customer')">
                            </app-customer-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <ngx-datatable #dataTable
                                       [scrollbarH]="scrollbarH"
                                       class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [rows]="rows"
                                       [count]="page.count"
                                       [externalPaging]="true"
                                       [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit"
                                       (page)="setPage($event)" (sort)="onSort($event)"
                                       [sorts]="[{prop:'created_on',dir:'desc'}]">

                            <ngx-datatable-column name="Number" prop="number">
                                <ng-template let-number="value" ngx-datatable-cell-template>
                                    {{number | phoneNumber }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Type" prop="type">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Blocked By" prop="created_by">
                                <ng-template let-row="row" let-created_by="value" ngx-datatable-cell-template>
                                    {{row.type==='System_added'?'SYSTEM ADDED':created_by}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Blocked On" prop="created_on">
                                <ng-template let-blocked_on="value" ngx-datatable-cell-template>
                                    {{blocked_on | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Expire On" prop="expiry_time" *ngIf="!deletedRecords">
                                <ng-template let-expiry_time="value" ngx-datatable-cell-template>
                                    {{expiry_time | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Unblocked On" prop="unblocked_on" *ngIf="deletedRecords">
                                <ng-template let-unblocked_on="value" ngx-datatable-cell-template>
                                    {{unblocked_on | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Unblocked By" prop="unblocked_by" *ngIf="deletedRecords">
                                <ng-template let-row="row" let-unblocked_user="value" ngx-datatable-cell-template>
                                    {{row.is_system_removed?'SYSTEM REMOVED':unblocked_user}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Tenant" prop="tenant_name" [sortable]="false">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Action" prop="id" *ngIf="isSuperAdmin||hasPermissiontoDelete()&&!deletedRecords" [sortable]="false">
                                <ng-template let-id="value" ngx-datatable-cell-template>
                                    <button class="badge bg-light-danger" (click)="unblockNumber(id)">
                                        Unblock
                                    </button>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- <ngx-datatable-column *ngIf="expanded" name="Action" prop="sid" [sortable]="false"
                                                  [canAutoResize]="false" [width]="80">
                                <ng-template let-sid="value" let-current="row" ngx-datatable-cell-template>
                                    <div *ngIf="UIPermissions.viewInfo || canDelete(current.purchased_on)" ngbDropdown
                                         container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                        
                                            <button *ngIf="canDelete(current.purchased_on) && !deletedRecords"
                                                    (click)="didDelete(sid)"
                                                    class="w-100 bg-light-danger" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column> -->
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                             let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                                     [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                     [size]="pageSize" [count]="rowCount"
                                                     [hidden]="!((rowCount / pageSize) > 1)"
                                                     (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Add New Block number</h5>
            <button type="button" class="close" aria-label="Close" (click)="dismissModal(modal)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="d-flex justify-content-between">
                    <input type="text" mask="(000) 000-0000" id="blockNumberField" [(ngModel)]="enteredNumber" placeholder="Enter Phone Number" name="name" class="form-control"
                    (keyup.enter)="searchFilterApply()" required>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button [disabled]="enteredNumber&&enteredNumber.length!=10" class="btn gradient-pomegranate"
                    (click)="blockNumber()">Create</button>
            <button type="button" class="btn" (click)="dismissModal(modal)">Cancel</button>
        </div>
    </ng-template>
</section>
