import { DistributorService } from '../../../services/distributor.service';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CustomField } from 'src/app/core/models/custom-field';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { DistributorContact } from '../../../models/distributor';
import { DistributorLocationSelectComponent } from '../../select/distributor-location-select/distributor-location-select.component';

@Component({
  selector: 'distributor-contact-form',
  templateUrl: './distributor-contact-form.component.html',
  styleUrls: ['./distributor-contact-form.component.scss']
})
export class DistributorContactFormComponent implements OnInit, OnDestroy {

  @ViewChild(DistributorLocationSelectComponent) locationSelect: DistributorLocationSelectComponent;

  public model: DistributorContact = new DistributorContact();

  @Input() distributorId: number;

  @Output() success = new EventEmitter<{ response: DistributorContact, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  private binding: DistributorContact;

  get bindingModel(): DistributorContact { return this.binding; };

  @Input() set bindingModel(value: DistributorContact) {
    if (value) {
      this.model = value;
      this.binding = value;
      this.additionalFields = this.customFields.obj2CustomFields(this.model.additional_fields) || [];
    }
  };

  @Output() locationAdd = new EventEmitter();

  public additionalFields: CustomField[] = [];

  public emailExist: boolean = false;

  private subs: SubSink = new SubSink();

  constructor(private service: DistributorService,
    private customFields: CustomFieldsService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() { }

  submit() {

    let api: Observable<DistributorContact>;

    if (this.bindingModel) {
      api = this.service.updateDistributorContact(this.bindingModel.id, this.model);
    }
    else {
      this.model.distributor = this.distributorId;
      api = this.service.createDistributorContact(this.model);
    }

    this.model.additional_fields = this.customFields.customFields2Obj(this.additionalFields);

    this.subs.sink = api.subscribe(
      resp => {
        this.emailExist = false;
        if (resp.location > 0) {
          resp.location_name = this.locationSelect?.getLocation(resp.location)?.name;
        }
        let msg = this.bindingModel ? 'Contact info updated' : 'New Contact added';
        this.success?.emit({ response: resp, message: msg });
      },
      (err) => {
        if (err.error && err.error['email']) {
          this.emailExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(err);
        }
      }
    );
  }

  addCustomField() {
    this.additionalFields.push(new CustomField());
  }

  deleteCustomField(id: string) {
    this.additionalFields = this.additionalFields.filter(x => x.id !== id);
  }

  onCancelClicked(): void { this.cancel?.emit(); }

  onLocationAdded() { this.locationAdd?.emit(); }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
