import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { DidsService } from '../../../services/dids.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { ForwardBinSelectComponent } from '../../../components/forward-bin-select/forward-bin-select.component';
import { DIDNumber } from '../../../models/dids.models';
import { SubSink } from 'subsink';
import { SelectionType } from '@swimlane/ngx-datatable';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { SweetAlertOptions } from 'sweetalert2';
import * as popup from 'src/app/core/utils/popup.functions';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';

@Component({
  selector: 'dids-bulk-delete',
  templateUrl: './dids-bulk-delete.component.html',
  styleUrls: ['./dids-bulk-delete.component.scss']
})
export class DidsBulkDeleteComponent implements OnInit {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @Output() emmitTotalCount = new EventEmitter<number>();

  @Output() emmitSelectedCounttoDelete = new EventEmitter<number>();

  @Output() closeBulkupdateModaltoDelete = new EventEmitter<void>();

  public rows: DIDNumber[] = [];

  @Input() totalCount:number;

  public selectedNumbers: DIDNumber[] = [];

  public suborgId: number;

  public totalSelectedNumbers: number;

  public scrollbarH: boolean = false;

  public subs: SubSink = new SubSink();

  public deletedRecords: boolean = false;

  public isSuperAdmin: boolean;

  public non_deleted_numbers: any = [];

  public deleted_numbers: any = [];

  public submitted: boolean = false;

  public page: Pagination = { count: 0, limit: 250, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = [
    { displayName: "All Phone Numbers", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Unassigned Numbers", colname: 'forward_bin_sid_resolved', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Local Numbers", colname: 'did_type', condition: 'is', value: 'Local', operator: 'AND' },
    { displayName: "Toll Free Numbers", colname: 'did_type', condition: 'is', value: 'Toll-Free', operator: 'AND' }
  ];

  public searchFilters: GFilterParam[] = [
    {
        displayName: 'Number or Name', colname: '', condition: '', value: '',
        children: [
            { displayName: 'Phone Number', colname: 'did', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
            { displayName: 'Phone Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' }
        ],
    },
    { displayName: 'Incoming Routes Name', colname: 'forward_bin_name', condition: 'contains', value: '', operator: 'OR' },
    { displayName: 'Incoming Routes', colname: 'forward_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
    { displayName: 'Area Code', colname: 'area_code', condition: 'contains', value: '', operator: 'OR' },
  ];

  public columns: GFColumn[] = [
    { displayName: 'Number', columnName: 'did', columnType: GFColumnTypes.phone },
    { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
    { displayName: 'Incoming Routes', columnName: 'forward_bin_sid_resolved', columnType: GFColumnTypes.ref },
    { displayName: 'Unassigned Numbers', columnName: 'is_bin_unassigned', columnType: GFColumnTypes.boolean },
    {
        displayName: 'Number Type',
        columnName: 'did_type',
        columnType: GFColumnTypes.select,
        optionValueType: GFColumnTypes.ref,
        options: [
            { displayName: 'Local', value: 'Local' },
            { displayName: 'Toll-Free', value: 'Toll-Free' },
        ]
    },
    { displayName: 'Area Code', columnName: 'area_code', columnType: GFColumnTypes.ref },
    { displayName: 'Purchased on', columnName: 'purchased_on', columnType: GFColumnTypes.date }
  ];

  public sortLookup = {
    did: 'did',
    name: 'name',
    area_code: 'area_code',
    purchased_on: 'purchased_on',
    forward_bin_name: 'forward_bin_name',
    suborg_name: 'suborg_name'
  };

  public sort: GSortParam[] = [{ colname: 'purchased_on', direction: 'desc' }];

  public module = ModuleConstants.RING2VOICE_DIDS;

  public SelectionType = SelectionType;

  public selectedCard: string = 'selectNumbers';

  public terms: boolean = false;

  public selectedFile: File;

  public fileNotValid: boolean = true;

  public phoneNumbersFromFile: string[] = [];

  constructor(
    public didService: DidsService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService ) { 
      this.isSuperAdmin = didService.isSuperUser;
      this.suborgId = didService.suborgId;
    }

  ngOnInit(): void {
    this.emmitSelectedCounttoDelete.emit(0);
  }

  getDids(filters: GFilterParam[] = null, offset: number = 0) {
    const date = moment().subtract(16, 'days').format('YYYY-MM-DD');
    let formattedDate = moment(date, 'YYYY-MM-DDTHH:mm:ssZ').tz(this.didService.userTimeZone).format("YYYY-MM-DD");
    let request = {
        base_filters: [{ colname: 'is_delete', condition: 'is', value: this.deletedRecords.toString(), operator: 'AND' }],
        filters: filters,
        sort: this.sort
    }
    if (request.filters === null) {
      request.filters = [];
    }
    request.filters.push({ colname: 'purchased_on', condition: 'datelessthan', value: formattedDate, operator: 'AND' });

    this.subs.sink = this.didService.getAll(request, offset, this.totalCount).pipe(
        catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
        this.rows = response.result;
        this.emmitSelectedCounttoDelete.emit(0);
        this.selectedNumbers = [];
        this.page.count = response.count;
        this.emmitTotalCount.emit(response.count);
        this.cdr.markForCheck();
    });
  }

  numberOnSelect({ selected }){
    this.selectedNumbers = [...selected];
    this.emmitSelectedCounttoDelete.emit(this.selectedNumbers.length);
    this.cdr.markForCheck();
  }

  selectCard(card: string) {
    this.selectedCard = card;
  }

  applyFilter(params: GFilterParam[]) {
    const filters = this.getFilters(params);
    this.getDids(filters);
    this.selectedNumbers = [];
    this.emmitSelectedCounttoDelete.emit(0);
  }

  getFilters(params?: GFilterParam[]) {
    let filters = params || this.dataFilter.getDataFilters();
    let del_filter = filters?.find(fil => fil.colname === 'is_delete');
    this.deletedRecords = del_filter ? del_filter.value : false;
    return filters.filter(fil => fil.colname !== 'is_delete');
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
        let current = event.sorts[0];
        if (!this.sortLookup[current.prop]) return;
        let sort = new GSortParam();
        sort.colname = this.sortLookup[current.prop];
        sort.direction = current.dir;
        this.sort = [sort];
        const filters = this.getFilters();
        this.getDids(filters);
    }
  }

  refreshDataTable() {
    setTimeout(() => {
        this.rows = [...this.rows];
        this.cdr.markForCheck();
    });
  }

  onFileSelected(event: any): void {
    this.phoneNumbersFromFile = [];
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.readFile(this.selectedFile);
    }
  }
  
  readFile(file: File): void {
    // Check if the file type is CSV
    if (!file.name.endsWith('.csv')) {
      this.toastr.warning("Please upload CSV files only.");
      this.selectedFile = null;
      return;
    }
  
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const csvData: string = e.target.result;
      this.processCsvData(csvData);
    };
    reader.readAsText(file);
  }
  
  processCsvData(csvData: string): void {
    const rows: string[] = csvData.split('\n');
    const headers: string[] = rows[0].split(',');
    // Find the index of the "Phone Numbers" column
    const phoneNumbersIndex: number = headers.findIndex(header => header.trim() === 'Phone Number');
    if (phoneNumbersIndex !== -1) {
      this.fileNotValid = false;
      // Iterate over the rows starting from the second row (index 1)
      for (let i = 1; i < rows.length; i++) {
        const columns: string[] = rows[i].split(',');
        if (columns.length > phoneNumbersIndex && columns[0]!== "" ) {
          const phoneNumber: string = columns[phoneNumbersIndex].trim();
          const cleanedPhoneNumber: string = this.cleanPhoneNumber(phoneNumber);
          this.phoneNumbersFromFile.push(cleanedPhoneNumber);
        }
      }
    } else {
      this.toastr.warning("Phone Number column was not found");
      this.fileNotValid = true;
      this.selectedFile = null;
    }
  }

  cleanPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters
  }

  clearSelectedFile(fileInput: any){
    this.selectedFile = null;
    this.phoneNumbersFromFile = [];
    fileInput.value = null;
  }

  previousBtnClicked(){
    if(this.didService.currentStep>1){
      this.didService.currentStep--;
      if(this.didService.currentStep==2){
        this.terms = false;
        if(this.selectedCard=='selectNumbers'){
          this.refreshDataTable();
        }
      }
    }
  }
  
  nextBtnClicked(){
    if(this.didService.currentStep == 1){
      if(this.selectedCard=='selectNumbers'){
        this.fileNotValid = true;
        this.selectedFile = null;
        this.getDids();
      };
    }
          
    if(this.didService.currentStep == 2){
      this.totalSelectedNumbers =  this.selectedNumbers.length;
      if((this.selectedCard=='selectNumbers'&&this.totalSelectedNumbers == 0) || (this.selectedCard=='importFile'&&(!this.selectedFile||this.fileNotValid))){
        let option: SweetAlertOptions = {
            title: 'Alert',
            text:`${this.selectedCard=='selectNumbers'?'Please select atleast one number':'Please select a valid file'}`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn',
              footer: 'flex-row-reverse'
            },
            buttonsStyling: false
          };
          popup.OpenConfirmBox(option, result => {
            if (result.isConfirmed){
              return;
            }  
          });
        return
      }
    }
  
    if(this.didService.currentStep<3){
        this.didService.currentStep++;
    }
  }
  
  onFinish() {
    let checkedDidArray;
    if(this.selectedCard=='selectNumbers'){
      checkedDidArray = this.selectedNumbers.map(item => {
        return item.did;
      });
    }else{
      checkedDidArray = this.phoneNumbersFromFile;
    }
    
    let api = this.didService.bulkDelete(checkedDidArray);
  
    this.subs.sink = api.subscribe(response=>{
      this.submitted = true;
      let option: SweetAlertOptions = {
        title: 'Bulk Delete Successful',
        text: `${response.background?'You will receive an email with the results':''}`,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-success',
          footer: 'flex-row-reverse'
        },
        buttonsStyling: false
      };
      if(response.background){
        this.closeBulkupdateModaltoDelete?.emit();
        this.didService.currentStep=1;
        popup.OpenConfirmBox(option, result => {
          if (result.isConfirmed) { return; }
        });
      }else if (!response.background&&!response.delete_all) {//check deleted & non deleted numbers
        this.deleted_numbers = response.deleted_numbers;
        this.non_deleted_numbers = response.non_deleted_numbers;
      }else{
        popup.OpenConfirmBox(option, result => {
          if (result.isConfirmed) { return; }
        });
        this.didService.currentStep=1;
        this.closeBulkupdateModaltoDelete?.emit();//
      }
    },
    error => {
      this.toastr.error(`Operation failed`);
    });
  }

  ngOnDestroy(): void { this.subs.unsubscribe(); }

}
