import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {

    private _selected: any;

    @Input() value: string[];

    @Output() valueChange = new EventEmitter<string[]>();

    get selected() {
        return this._selected;
    }

    set selected(value: { start: any, end: any }) {
        if (value && value.start && value.end) {
            this.valueChange.emit([value.start.format('YYYY-MM-DD'), value.end.format('YYYY-MM-DD')]);
        }
    }

    @Input() range:boolean = false;

    ranges: any = {
      'Today': [moment(),moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'This Week': [moment().startOf('week'), moment().endOf('week')],
      'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      'This Month': [moment().startOf('month').startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    constructor() { }
}
