<section class="row">
    <div class="col-md-6" >
        <div class="card">
            <div class="card-body">
                <div class="form-group">
                    <label class="val-required">Label page template title</label>
                    <input type="text" [(ngModel)]="title" #title1="ngModel" name="title" class="form-control" placeholder="Enter a title" required/>
                    <small class="form-text text-muted danger" *ngIf="title1.invalid&&(title1.dirty || title1.touched)">
                        <div *ngIf="title1.errors.required">
                            Title is required
                        </div>
                    </small>
                </div>

                <div class="form-group">
                    <label class="">Description</label>
                    <textarea placeholder="Enter description" [(ngModel)]="description" class="form-control"></textarea>
                    <!-- <small class="form-text text-muted danger" *ngIf="title1.invalid&&(title1.dirty || title1.touched)">
                        <div *ngIf="title1.errors.required">
                            Descript is required
                        </div>
                    </small> -->
                </div>

                <div class="form-group">
                    <label class="val-required">Label page</label>
                    <ng-select [items]="pageList" bindLabel="title" [(ngModel)]="labelPage" bindValue="id" (change)="selectedPage=$event" placeholder="Select page">

                    </ng-select>

                </div>

                <div class="form-group">
                    <label class="val-required">Label</label>
                    <ng-select [items]="labelList" bindLabel="title" [(ngModel)]="labelTemplate" bindValue="id" (change)="selectedLabel=$event"  placeholder="Select page">

                    </ng-select>

                </div>

                <div class="form-group">
                    <label class="val-required">Label margin</label>
                    <div class="d-flex">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text">&nbsp;&nbsp;&nbsp;Top&nbsp;&nbsp;&nbsp;</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="labelMarginTop" (change)="calculateLabelPosition()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Px</span>
                            </div>
                          </div>

                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">right</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="labelMarginRight" (change)="calculateLabelPosition()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Px</span>
                            </div>
                          </div>
                    </div>
                    <div class="d-flex">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Bottom</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="labelMarginBottom" (change)="calculateLabelPosition()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Px</span>
                            </div>
                          </div>

                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Left</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="labelMarginLeft" (change)="calculateLabelPosition()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Px</span>
                            </div>
                          </div>
                    </div>

                </div>

                <div class="form-group float-right mt-2">
                    <a class="btn btn-secondary mr-2" [routerLink]="['/inventory/labels/pages']">Cancel</a>
                    <button type="submit" class="btn btn-primary mr-2" (click)="createPage()">{{selectedTemplateId>0?'Update':'Create'}}</button>
                    <button type="submit" class="btn btn-primary" (click)="calculateLabelPosition();">Preview</button>
                </div>

                
            </div>
        </div>
    </div>
    <div class="col-md-6 pt-3" [hidden]="!showPreview">

        <!-- <canvas id="canvas" [ngStyle]="{'width':canvasWidth+'px','height':canvasHeight+'px'}">  -->
            <canvas id="canvas"> 

        </canvas>
        <br/>
        <span class="text-muted mt-2">Total <strong>{{totalLabels}}</strong> labels</span>

    </div>

</section>