import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Ring2voiceService } from '../../services/ring2voice.service';

@Component({
  selector: 'area-code-select',
  templateUrl: './area-code-select.component.html',
  styleUrls: ['./area-code-select.component.scss']
})
export class AreaCodeSelectComponent implements OnInit {

  @Input() value: string;

  @Input() readonly: boolean = false;

  @Output() valueChange = new EventEmitter<string>();

  public placeholder:string = "Select Area Code";

  public options: Observable<string[]>;

  constructor(private r2vService: Ring2voiceService) { }

  ngOnInit(): void {
    this.options = this.r2vService.getAreaCodes();
  }

  onValueChanged() {
    this.valueChange.emit(this.value);
  }
}
