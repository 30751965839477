import { OrderByPipe } from '../../../../shared/pipes/order-by.pipe';
import { forkJoin, Observable } from 'rxjs';
import { KitService } from 'src/app/modules/inventory/services/kit.service';
import { KitItem, KitItemRow, KitItemsType, KitWrapper, Kit2Kit } from 'src/app/modules/inventory/models/kit.models';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ItemWrapper } from 'src/app/modules/inventory/models/items';
import { ItemSelectComponent } from 'src/app/modules/inventory/components/select/item-select/item-select.component';
import { KitSelectComponent } from 'src/app/modules/inventory/components/select/kit-select/kit-select.component';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from 'src/app/modules/inventory/services/item.service';
import { SubSink } from 'subsink';
import { map } from 'rxjs/operators';
import { WarrantyService } from '../../services/warranty.service';
import { WarrantyItemRow, WarrantyItemsType } from '../../models/warranty.models';
import { SalesService } from '../../services/sales.service';


@Component({
    selector: 'sales-warranty-items',
    templateUrl: './warranty-items.component.html',
    styleUrls: ['./warranty-items.component.scss']
})
export class SalesWarrantyItemsComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('itemSelect') itemSelect: ItemSelectComponent;

    @ViewChild('kitSelect') kitSelect: KitSelectComponent;

    @Input() summaryRow: boolean = true;

    @Input() deleteOption: boolean = true;

    @Input() saveButton: boolean = false;

    @Input() addButton: boolean = true;

    @Input() wId: number = 0;

    @Input() coverageType: number = 0;

    @Input() submitted: boolean = false;

    @Input() coverageTypeSelection: number = 0;

    public fileUrl: string = environment.fileURL;

    public rows: WarrantyItemRow[] = [];

    public KitIemTypes = WarrantyItemsType;

    public rowIndex: number;

    private deleteItems: number[] = [];

    private deleteKits: number[] = [];

    public hasKits: boolean = false;

    private subs: SubSink = new SubSink();
    newItem: boolean;

    constructor(private toastr: ToastrService,
        private itemService: ItemService,
        private WarrantyService: WarrantyService,
        private kitService: KitService,
        private cdr: ChangeDetectorRef,
        private orderBy: OrderByPipe,
        private salesService: SalesService) { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.wId && changes.wId.currentValue && changes.wId.currentValue > 0) {
            this.getWarrantyItems();
        }


    }

    private getWarrantyItems() {
        this.subs.sink = forkJoin([this.getItemInWarranty(this.wId), this.getKitsInWarranty(this.wId)]).subscribe(
            results => {
                if (results && results.length > 0) {
                    let items = [...results[0], ...results[1]];
                    this.rows = this.orderBy.transform(items, this.getOrderBy(items));
                    this.cdr.markForCheck();
                }
            }
        );
    }

    getItemInWarranty(kitid: number) {
        return this.WarrantyService.itemsList(kitid).pipe(map(res => {
            let rows: WarrantyItemRow[] = [];
            if (res && res.length > 0) {
                for (let item of res) {
                    let row = new WarrantyItemRow();
                    row.itemType = WarrantyItemsType.ITEM;
                    row.id = item.id;
                    // row.item_id = item.items;
                    row.image_link = item.image_link
                    row.customer = item.customer;
                    row.item_name = item.item_name;
                    row.part_number = item.part_number;
                    row.kit_id = this.wId;
                    row.quantity = item.quantity || 1;
                    row.serial_number = item.serial_number;
                    row.coverage_type = item.coverage_type;
                    row.sell_price = item.item_price;
                    // row.sell_price_calc = item.item_price * item.quantity;
                    // row.order = item.order;
                    rows.push(row);
                }
            }
            return rows;
        }));
    }

    getKitsInWarranty(kitid: number) {
        return this.WarrantyService.kitsList(kitid).pipe(map(res => {
            let rows: WarrantyItemRow[] = [];
            if (res && res.length > 0) {
                for (let k2k of res) {
                    let row = new WarrantyItemRow();
                    row.itemType = WarrantyItemsType.KIT;
                    row.id = k2k.id;
                    // row.item_id = k2k.kit_kit;
                    row.customer = k2k.customer;
                    row.item_name = k2k.kit_name;
                    row.part_number = k2k.internal_number;
                    // row.kit_id = k2k.kit;
                    row.quantity = k2k.quantity || 1;
                    row.serial_number = k2k.serial_number;
                    row.coverage_type = k2k.coverage_type;
                    // row.sell_price = k2k.sell_price;
                    // row.sell_price_calc = k2k.sell_price * k2k.quantity;
                    // row.order = k2k.order;
                    rows.push(row);
                }
            }
            return rows;
        }));
    }

    getOrderBy(rows: WarrantyItemRow[]) {
        return rows.some(item => item.order > 0) ? 'order' : 'item_name';
    }

    getRow(type: WarrantyItemsType){
        let row = new WarrantyItemRow();
        row.itemType = type;
        row.customer = this.itemService.customerId;
        row.coverage_type = 0;
        return row;
    }

    appendRow(type: WarrantyItemsType) {
        this.newItem = true;
        this.toggleSelect(type);
    }

    toggleSelect(type: WarrantyItemsType, index?: number) {
        if(index >=0){
            this.newItem = false;
            this.rowIndex = index;
        }
        if (type === WarrantyItemsType.ITEM) {
            this.itemSelect.open();
        }
        else if (type === WarrantyItemsType.KIT) {
            this.kitSelect.open();
        }
    }

    quantityOnKeyDown(row: WarrantyItemRow) {
        row.quantity = this.salesService.trimNumber(row.quantity, 5);
    }

    quantityOnChange(row: WarrantyItemRow) {
        row.sell_price_calc = row.quantity * row.sell_price;
        this.rows = [...this.rows];
    }

    priceSummaryFunc(rows: WarrantyItemRow[]) {
        return '$ ' + rows.map(row => row.sell_price_calc)?.reduce((a, b) => a + b, 0.00)?.toFixed(2);
    }

    deleteItemRow(row: WarrantyItemRow, index: number) {
        if (this.wId > 0 && row.id) {
            this.rows[index].is_delete = true;
        }
        else {
            this.deleteRow(row);
        }
    }

    deleteKitRow(row: WarrantyItemRow, index: number) {
        if (this.wId > 0 && row.id) {

            this.rows[index].is_delete = true;
        }
        else {
            this.deleteRow(row);
        }
    }

    deleteRow(row: WarrantyItemRow) {
        if (row.id && row.id > 0) {
            if (row.itemType === WarrantyItemsType.ITEM) {
                this.deleteItems.push(row.id);
            }
            else if (row.itemType === WarrantyItemsType.KIT) {
                this.deleteKits.push(row.id);
            }
            this.rows = this.rows.filter(ki => ki.id !== row.id);
        }
        else {
            this.rows = this.rows.filter(ki => ki.guid !== row.guid);
        }
        this.cdr.markForCheck();
    }

    onItemChange(item: ItemWrapper) {
        if(this.newItem){
            let row = this.getRow(WarrantyItemsType.ITEM)
            this.rows = this.rows.concat(row);
            this.rowIndex = this.rows.length - 1;
        }
        if (item && this.rows[this.rowIndex]) {
            this.rows[this.rowIndex].item_id = item.id;
            this.rows[this.rowIndex].item_name = item.item_name;
            this.rows[this.rowIndex].part_number = item.part_number;
            this.rows[this.rowIndex].image_link = item.image_link;
            this.rows[this.rowIndex].sell_price = item.item_price;
            this.rows[this.rowIndex].sell_price_calc = item.item_price;
            this.rows = [...this.rows];
        }
    }

    onKitChange(kit: KitWrapper) {
        if (this.wId === kit.id) {
            this.toastr.info('Cannot select this kit');
            return;
        }
        if(this.newItem){
            let row = this.getRow(WarrantyItemsType.KIT)
            this.rows = this.rows.concat(row);
            this.rowIndex = this.rows.length - 1;
        }
        if (kit && this.rows[this.rowIndex]) {
            this.rows[this.rowIndex].item_id = kit.id;
            this.rows[this.rowIndex].item_name = kit.name;
            this.rows[this.rowIndex].part_number = kit.internal_number;
            this.rows[this.rowIndex].sell_price = kit.sell_price;
            this.rows[this.rowIndex].sell_price_calc = kit.sell_price;
            this.rows = [...this.rows];
        }
    }

    validate() {
        return this.rows.filter(item => item.item_id > 0).length > 0;
    }

    getApis(kitid: number): Observable<any>[] {
        this.rows.forEach(row => row.kit_id = kitid);
        let items = this.convertKitItemRow();
        let apis: Observable<any>[] = [];
        this.getItemApis(apis, items.items);
        this.getKitApis(apis, items.kits);
        return apis;
    }

    getItemApis(apis: Observable<any>[], items: KitItem[]) {
        // let add = items.filter(ki => !ki.id || ki.id <= 0);
        // if (add.length > 0) {
        //   apis.push(this.kitService.addKitItems(add));
        // }
        // let update = items.filter(ki => ki.id && ki.id > 0);
        // if (update.length > 0) {
        //   apis.push(this.kitService.updateKitItems(update));
        // }
        // if (this.deleteItems.length > 0) {
        //   apis.push(this.kitService.deleteKitItems(this.deleteItems));
        // }
    }

    getKitApis(apis: Observable<any>[], kits: Kit2Kit[]) {
        // let add = kits.filter(ki => !ki.id || ki.id <= 0);
        // if (add.length > 0) {
        //     apis.push(this.kitService.addKits2Kit(add));
        // }
        // let update = kits.filter(ki => ki.id && ki.id > 0);
        // if (update.length > 0) {
        //     apis.push(this.kitService.updateKits2Kit(update));
        // }
        // if (this.deleteKits.length > 0) {
        //     apis.push(this.kitService.deleteKits2Kit(this.deleteKits));
        // }
    }

    convertKitItemRow(): { items?: KitItem[], kits?: Kit2Kit[] } {
        let resp = { items: [], kits: [] };
        for (let i = 0; i < this.rows.length; i++) {
            let row = this.rows[i];
            if (!row.item_id || row.item_id <= 0) {
                return null;
            }
            if (row.itemType === WarrantyItemsType.ITEM) {
                let ki = new KitItem();
                ki.id = row.id;
                ki.items = row.item_id;
                ki.kits = row.kit_id;
                ki.quantity = row.quantity;
                ki.customer = row.customer;
                ki.order = row.order;
                ki.is_delete = row.is_delete;
                resp.items.push(ki);
            }
            else if (row.itemType === WarrantyItemsType.KIT) {
                let ki = new Kit2Kit();
                ki.id = row.id;
                ki.kit_kit = row.item_id;
                ki.kit = row.kit_id;
                ki.quantity = row.quantity;
                ki.customer = row.customer;
                ki.order = row.order;
                ki.is_delete = row.is_delete;
                resp.kits.push(ki);
            }
        }
        return resp;
    }

    submit(kitid: number, next?: (value: any) => any, error?: (value: any) => any): void {
        let apis = this.getApis(kitid);
        if (apis.length <= 0) {
            return next([]) || false;
        }
        this.subs.sink = forkJoin(apis).subscribe(
            results => {
                if (next) next(results); else this.toastr.success(`Kit Items updated`);
            },
            err => {
                if (err) error(error); else this.toastr.error(`Operation failed`);
            }
        );
    }

    expandKit(row: WarrantyItemRow) {
        if (!row.children) {
            this.subs.sink = forkJoin([this.getItemInWarranty(row.item_id), this.getKitsInWarranty(row.item_id)]).subscribe(results => {
                if (results && results.length > 0) {
                    let items = [...results[0], ...results[1]];
                    row.children = this.orderBy.transform(items, this.getOrderBy(items));
                    row.expanded = true;
                    this.cdr.markForCheck();
                }
            });
        }
        else {
            row.expanded = !row.expanded;
        }
    }

    toggleOtherRows(row: WarrantyItemRow) {
        for (let item of this.rows) {
            if (item.guid !== row.guid) {
                row.expanded = false;
            }
        }
    }


    checkValidation() {
        if (this.rows.length > 0 && this.coverageTypeSelection == 2) {
            let count = 0;
            this.rows.forEach((item, index) => {
                if (!item.coverage_type || item.coverage_type == 0) {
                    count++;
                }
                else if ((!item.item_id || item.item_id == undefined) && (!item.kit_id || item.kit_id == undefined)) {
                    count++;
                }
            });

            if (count > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }

    }



    snoUpdate(val, index) {
        this.rows[index].serial_number = val;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
