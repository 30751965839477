import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, ViewChild } from '@angular/core';
import { PaymentsService } from '../../services/payments.service';
import { InvoiceData, InvoiceDetails, PaymentMethods, PaymentReccord } from '../../models/payments-model';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'invoices-payment-history',
  templateUrl: './invoices-payment-history.component.html',
  styleUrls: ['./invoices-payment-history.component.scss']
})
export class InvoicesPaymentHistoryComponent implements OnInit {

  @ViewChild('payment_view_modal') modal: any;

  public _invoiceId:number;

  get invoiceId(){

    return this._invoiceId;
  }

  @Input() set invoiceId(id:number){

    this._invoiceId = id;
  }

  public paymentsList: PaymentReccord[];

  public invoiceDetails: any;

  public selectedPayment: PaymentReccord;

  public paymentMethods = PaymentMethods;

  constructor(private paymentServices:PaymentsService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,) { }

  ngOnInit(): void {

    this.getPayments();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes&&changes.invoiceId&&changes.invoiceId.currentValue && changes.invoiceId.previousValue && changes.invoiceId.currentValue != changes.invoiceId.previousValue)
      this.getPayments();
    
}

  getPayments(){

    this.paymentServices.getInvoicesPayments(this.invoiceId).subscribe(resp =>{

      this.invoiceDetails = resp.invoice_details;
      this.paymentsList = resp.payment;
      console.log(this.invoiceDetails,'invoiceDetails++')

      this.cdr.markForCheck();

    });

  }

  viewPayments(payment:PaymentReccord){

    this.selectedPayment = payment;
    this.modalService.open(this.modal, { centered: true, scrollable: true, size: "md" })
  }

}
