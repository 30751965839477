import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { ForwardBin } from '../../models/forward-bin.models';
import { Ring2voiceService } from '../../services/ring2voice.service';
import { SweetAlertOptions } from 'sweetalert2';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
    selector: 'forward-bin-form',
    templateUrl: './forward-bin-form.component.html',
    styleUrls: ['./forward-bin-form.component.scss']
})
export class ForwardBinFormComponent implements OnInit, OnDestroy {

    public model: ForwardBin;

    @Input() suborg_id:number;

    @Input() sid: string;

    @Input() isGlobal_Route: boolean;

    public isSuperAdmin: boolean;

    public allSuborgs: boolean;

    public nameError: boolean;

    @Output() success = new EventEmitter<{ response: ForwardBin, message: string }>();

    @Output() error = new EventEmitter<any>();

    @Output() cancel = new EventEmitter<any>();

    private subs: SubSink = new SubSink();

    constructor(private ring2voiceService: Ring2voiceService,
        private cdr: ChangeDetectorRef) {

        this.isSuperAdmin = ring2voiceService.isSuperUser;
        this.allSuborgs = ring2voiceService.suborgId === -1;
    }

    ngOnInit(): void {
        if (this.sid) {
            this.subs.add(this.ring2voiceService.getForwardBinById(this.sid).subscribe(bin => {
                this.model = bin;
                this.cdr.markForCheck();
            }));
        }
        else {
            this.model = new ForwardBin();
            if (this.allSuborgs) {
                this.model.customer = this.ring2voiceService.customerId;
            }
            else {
                this.model.sub_org = this.ring2voiceService.suborgId;
            }
        }

        if(this.suborg_id){
            this.model.sub_org=this.suborg_id
        }
    }

    onSubmit() {

        if ((!this.model.sip && !this.model.forward_number) || !this.model.name) {
            return;
        }
        if(this.isGlobal_Route){
            this.model.global_route = true;
        }else{
            this.model.global_route = false;
        }
        if (this.model.forwardbin_or_sip==true) {
            this.model.forward_number=null;
        }
        else{
            this.model.sip=null;
        }

        this.model.smartsipgroup=null;

        let api: Observable<ForwardBin> = this.sid ? this.ring2voiceService.updateForwardBin(this.sid, this.model)
            : this.ring2voiceService.createForwardBin(this.model);

        this.subs.sink = api.subscribe(resp => {
            let msg = ''
            this.nameError = false;
            if(resp.background){
                this.showBackgroundAlert();
            }else{
                msg = this.sid ? `Incoming Routes info updated` : `New Incoming Routes created`;
            }
            this.success.emit({ response: resp, message: msg });
        },
        error => {
            if (error.error === 'NAME_DUPLICATION') {
                this.nameError = true;
            }
            else {
                this.nameError = false;
                this.error.emit(error);
            }
        }
        )
    }

    showBackgroundAlert(){
        let option: SweetAlertOptions = {
            title: 'Process Successful',
            text: 'You will receive an email once the changes reflect',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-success',
              footer: 'flex-row-reverse'
            },
            buttonsStyling: false
          };
          popup.OpenConfirmBox(option, result => {
            if (result.isConfirmed) {
                    return;
                }  
              });
    }

    onCancelClicked(): void { this.cancel.emit(); }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
