<section class="user-profile">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-body">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <i class="ft-user mr-1"></i>
                                    <span class="d-none d-sm-block">Personal Info</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="col-12 col-md-6 col-lg-6 p-0 mt-2">
                                        <app-user-form [userId]="userId" [readOnlyFields]="['email','role']"
                                        [hiddenFields]="['is_active','password','customer']"
                                        (success)="onProfileSubmittedSuccess($event)"
                                        (error)="onProfileDataError($event)">
                                        </app-user-form>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <i class="ft-lock mr-1"></i>
                                    <span class="d-none d-sm-block">Reset password</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="col-12 col-md-6 col-lg-6 p-0 mt-2">
                                        <app-user-reset-password  [userId]="userId"
                                          (onSuccess)="onProfileSubmittedSuccess($event)"
                                          (onProfileDataError)="onProfileDataError($event)">
                                        </app-user-reset-password>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                              <a ngbNavLink class="d-flex align-items-center">
                                  <i class="ft-cog mr-1"></i>
                                  <span class="d-none d-sm-block">Security</span>
                              </a>
                              <ng-template ngbNavContent class="tab-content-pd">
                                <div class="col-10 p-0 mt-2">
                                  <user-settings [userId]="userId" [user]="currentUser"></user-settings>
                                </div>
                              </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
