<div *ngIf="selectBox" class="item-select-container select-sm" (click)="open()">
    <div class="value-container">
        <div *ngIf="!selected" class="placeholder">Select Assembly</div>
        <div class="combobox">
            <label class="w-100 m-0 item-name-label" *ngIf="selected">{{selected.name}}</label>
        </div>
    </div>
    <span *ngIf="selected" (click)="clearSelection($event)" class="clear-wrapper d-flex" title="Clear all">
      <span aria-hidden="true" class="clear">×</span>
    </span>
    <span class="arrow-wrapper"><span class="arrow"></span></span>
</div>

<ng-template #kitsModal let-c="close" let-d="dismiss">
    <div class="modal-body p-0">
        <div class="w-100 p-2">
            <datagrid-filter #dataFilter
            [columns]="columns"
            [searchFilters]="search"
            [defaultFilters]="defaults"
            [newButton]="true"
            [dontDisableIfAllsuborg]="suborg_id?true:false"
            (newButtonClick)="openModal(content)"
            (apply)="applyFilter($event)">
                <ng-template filterControl [column]="'category'" let-row="row">
                    <category-select class="ng-select-sm" [(value)]="row.value"></category-select>
                </ng-template>
                <ng-template filterControl [column]="'items'" let-row="row">
                    <item-select class="select-sm" [selectBox]="true" [(value)]="row.value"></item-select>
                </ng-template>
            </datagrid-filter>
        </div>
        <ngx-datatable #dataTable [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap grid-selection-custom-style" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="kits" [selectionType]="SelectionType.single" [count]="page.count"
            [externalPaging]="true" [offset]="page.offset" [externalSorting]="true" [sorts]="[{prop:'internal_number',dir:'asc'}]" [limit]="page.limit" [selected]="selectedItem" (page)="setPage($event,dataFilter)" (sort)="onSort($event,dataFilter)">
            <ngx-datatable-column [width]="90" name="Image" prop="primary_image" [sortable]="false">
                <ng-template let-url="value" ngx-datatable-cell-template>
                    <img *ngIf="url" width="80" height="80" [src]="fileUrl + url + '.jpg'" />
                    <img *ngIf="!url" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
            <ngx-datatable-column name="Part Number" prop="internal_number"></ngx-datatable-column>
            <ngx-datatable-column name="SKU" prop="sku"></ngx-datatable-column>
            <ngx-datatable-column name="Category" prop="category_name">
            </ngx-datatable-column>
            <ngx-datatable-column name="Sell Price" prop="sell_price" [sortable]="true">
                <ng-template let-price="value" ngx-datatable-cell-template>
                    <span *ngIf="price">
                        ${{price || 0}}
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Cost Price" prop="cost_price" [sortable]="true">
                <ng-template let-price="value" ngx-datatable-cell-template>
                    <span *ngIf="price">
                        ${{price || 0}}
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Available Qty" prop="available_stock" [sortable]="false"></ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="is_active" [sortable]="false" [width]="70">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <div class="badge" [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                        {{ status ? 'Active' : 'Inactive' }}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                    <div class="page-count">
                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                    </div>
                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                        (change)="dataTable.onFooterPage($event)">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="onItemSelected()" class="btn gradient-pomegranate">Select</button>
        <button type="button" (click)="onCanceled()" class="btn btn-secondary">Cancel</button>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Add Item</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <app-kit-form [suborg_id]="suborg_id" (onSuccess)="onDataSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()">
        </app-kit-form>
    </div>
</ng-template>
