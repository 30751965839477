import { SalesAccount } from './../models/sales-account';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SalesAccountService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll() {
    return this.http.get<SalesAccount[]>(`${this.baseURL}/salesaccount/`);
  }

  getById(id: number) {
    return this.http.get<SalesAccount>(`${this.baseURL}/salesaccount/${id}/`);
  }

  create(acc: SalesAccount) {
    return this.http.post<SalesAccount>(`${this.baseURL}/salesaccount/`, acc);
  }

  update(id: number, acc: SalesAccount) {
    return this.http.put<SalesAccount>(`${this.baseURL}/salesaccount/${id}/`, acc);
  }

  patch(id: number, request: any) {
    return this.http.patch<SalesAccount>(`${this.baseURL}/salesaccount/${id}/`, request);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/salesaccount/${id}/`);
  }
}
