import { ModuleConstants } from './../../../core/enums/common.enum';
export class Contact {

    public id: number;

    public first_name: string;

    public last_name: string;

    public title: string;

    public phone_number: string;

    public extension: string;

    public email: string;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public is_active: boolean;

    public allow_portal_login:boolean;

    public module_id:ModuleConstants;

    public unique_id:number;

    public customer: number;
}