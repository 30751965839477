import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Pagination, GFilterParam, GFColumn, GSortParam, DataFilterRequest, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { SubOrg } from '../../../suborg/models/suborg.models';
import { SuborgService } from '../../../suborg/services/suborg.service';

@Component({
    selector: 'tenant-suborgs',
    templateUrl: './tenant-suborgs.component.html',
    styleUrls: ['./tenant-suborgs.component.scss']
})
export class TenantSubOrgsComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    private _tenantId: number;

    @Input() set tenantId(value: number) {
        this._tenantId = value;
        if (value) {
            this.getData();
        }
    }

    get tenantId(): number { return this._tenantId; }

    public page: Pagination = { count: 0, limit: 25, offset: 0, pageNumber: '0-0' };

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Combo Search', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Phone', colname: 'phone_number', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Address', colname: 'address', condition: 'contains', value: '', operator: 'OR' },
            ]
        },
        { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Phone', colname: 'phone_number', condition: 'contains', value: '', operator: 'OR' }
    ];

    public columns: GFColumn[] = [
        { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
        { displayName: 'Email', columnName: 'email', columnType: GFColumnTypes.string },
        { displayName: 'Phone', columnName: 'phone_number', columnType: GFColumnTypes.string },
        { displayName: 'Main Org', columnName: 'is_root', columnType: GFColumnTypes.boolean },
        { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
    ];

    public sortLookup = {
        name: "name",
        email: "email",
        address: "address",
        phone_number: "phone_number",
        created_on: "created_on"
    };

    public rows: SubOrg[];

    private subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public isSuperAdmin: boolean;

    public sort: GSortParam[];

    constructor(private suborgService: SuborgService,
        private device: DeviceDetectorService,
        private cdr: ChangeDetectorRef) {

        this.isSuperAdmin = suborgService.isSuperUser;
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
    }

    getData(filters: GFilterParam[] = [], offset: number = 0) {

        filters.unshift({ colname: 'customer', value: this.tenantId, condition: "equalto", operator: 'AND' })

        let request: DataFilterRequest = { filters: filters, sort: this.sort }

        this.subs.sink = this.suborgService.getFiltered(request, offset, this.page.limit).pipe(
            catchError(err => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) { this.getData(params); }

    openSuborgForm() {

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
