import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConstants } from '../constants/app.constants';
import { UserTypes } from '../enums/user-types';

@Injectable({
    providedIn: 'root'
})
export class AdminRouteGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.isAdmin) {
            return true;
        }
        else {
            this.router.navigate(['access-denied']);
            return false;
        }
    }

    public get userType(): UserTypes {
        return localStorage.getItem(AppConstants.USER_TYPE) as UserTypes;
    }

    public get isAdmin(): boolean {
        return this.userType === UserTypes.Admin;
    }
}
