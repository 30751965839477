import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Cards, GetInvoicesPayments, InvoiceDetails, PaymentAdd, PaymentDetails, PaymentReccord, PaymentRequest, SuborgsPaymentAccountSettings } from '../models/payments-model';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CustomersPendingInvoicesData } from '../../accounting/models/accounting-model';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService extends BaseService {

  constructor(private http: HttpClient) { super() }


  getSuborgsPaymentSettings(id:number){

    return this.http.get<any>(`${this.baseURL}/suborgs/${id}/payment/`);
  }

  updateSuborgsPaymentSettings(payLoad: {'api_key':string}, id:number) {
    return this.http.post<any>(`${this.baseURL}/suborgs/${id}/payment/`, payLoad);
}

getSuborgsPaymentAccountSettings(id:number){

  return this.http.get<SuborgsPaymentAccountSettings>(`${this.baseURL}/sales_app/suborg/settings/${id}/`);
}

updateSuborgsPaymentAccountSettings(payLoad:SuborgsPaymentAccountSettings, id:number){

  return this.http.put<any>(`${this.baseURL}/sales_app/suborg/settings/${id}/`, payLoad);
}

getInvoiceDetails(id:string){
  return this.http.get<InvoiceDetails>(`${this.baseURL}/sales_app/payment/details/${id}/`);

}

payNow(payLoad:PaymentRequest, id:string) {
  return this.http.post<any>(`${this.baseURL}/sales_app/payment/details/${id}/`, payLoad);
}

getInvoiceToken(id:number){

  return this.http.get<any>(`${this.baseURL}/sales_app/invoice/payment/${id}/uuid/`);
}
getInvoicesPayments(id:number){

  return this.http.get<GetInvoicesPayments>(`${this.baseURL}/sales_app/invoice/payment/${id}/`);
}

sendSuborgVerification(id:number){

  return this.http.get<any>(`${this.baseURL}/payment_credential/${id}/send_verification_code/`);
}

verifySuborg(payLoad: {'otp':string}, id:number) {
  return this.http.post<any>(`${this.baseURL}/payment_credential/${id}/verify_code/`, payLoad);
}

getInvoiceDetailsById(id:string){
  return this.http.get<InvoiceDetails>(`${this.baseURL}/sales_app/invoice/payment/${id}/`);

}

makePayment(payLoad:PaymentRequest|PaymentAdd, id:string) {
  return this.http.post<any>(`${this.baseURL}/sales_app/invoice/payment/${id}/`, payLoad);
}

getFiltered(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
  let url = `${this.baseURL}/sales_app/payment_received/filter/?limit=${limit}&offset=${offset}`;
  return this.http.post<DataFilterResponse<PaymentReccord[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
  );
}

getPaymentDetailsById(id:number){
  return this.http.get<PaymentDetails>(`${this.baseURL}/sales_app/payment_received/details/${id}/`);

}

getCustomerUnpaidInvoices(id:number, filterString:string = 'all'){
  return this.http.get<CustomersPendingInvoicesData>(`${this.baseURL}/sales_app/payment_received/customer/${id}/?date_filter=${filterString}`);

}

getCustomersPaymentDatas(id:string){

  return this.http.get<any>(`${this.baseURL}/sales_app/payment_received/customer_card/${id}/`);
}

}
