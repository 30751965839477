import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LocationSystemType, LocationType } from '../../../models/location-types';
import { CustomerLocation } from '../../../models/tenant-customer';
import { TenantCustomerService } from '../../../services/tenant-customer.service';
import { OpenStreetMapProvider  } from 'leaflet-geosearch';
import { SubSink } from 'subsink';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Mapboxservice } from 'src/app/core/services/mapboxservice.service';

//'SearchResult<RawResult>[]'
@Component({
  selector: 'customer-location-form',
  templateUrl: './customer-location-form.component.html',
  styleUrls: ['./customer-location-form.component.scss']
})
export class CustomerLocationFormComponent implements AfterViewInit, OnDestroy {



  @ViewChild('searchSuggest') searchSuggest: ElementRef;

  @ViewChild('searchInput') searchInput: ElementRef;

  @ViewChild('geoSerach') geoSerach: NgSelectComponent;

  @ViewChild('mapview') mapView:ElementRef;

  @ViewChildren('suggestItem') suggestItem: QueryList<ElementRef>;

  @ViewChild('name', { static: false, read: ElementRef }) nameControl: ElementRef;

  public model: CustomerLocation = new CustomerLocation();

  @Input() tenantCustomerId: number;

  @Input() customerSuborg_id: number;

  @Output() success = new EventEmitter<{ response: CustomerLocation, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  private binding: CustomerLocation;

  get bindingModel(): CustomerLocation { return this.binding; };

  @Input() set bindingModel(value: CustomerLocation) {
    if (value) {
      this.model = value;
      this.binding = value;
      
    }
  };

  public nameExist: boolean = false;

  private sub: SubSink=new SubSink();

  private OSMProvider = new OpenStreetMapProvider({
    params: {
      'accept-language': 'en', // render results in Dutch
      countrycodes: 'us', // limit search results to the Netherlands
      addressdetails: 3, // include additional address detail parts
    },
  });

  // public searchSuggestions:BehaviorSubject<any[]>=new BehaviorSubject([]);

  public searchSub$:any;

  public searchLoading:boolean=false;

  public searchReqCount:number=0;

  public searchKey:string='';

  // public subs:SubSink=new SubSink();

  constructor(private service: TenantCustomerService, private mapboxService:Mapboxservice,
    private cdr:ChangeDetectorRef, private renderer: Renderer2) { 
      this.renderer.listen('window', 'click',(e:Event)=>{
        /**
         * Only run when toggleButton is not clicked
         * If we don't check this, all clicks (even on the toggle button) gets into this
         * section which in the result we might never see the menu open!
         * And the menu itself is checked here, and it's where we check just outside of
         * the menu and button the condition abbove must close the menu
         */
      if(e.target !== this.searchSuggest?.nativeElement&&e.target !== this.searchInput?.nativeElement){

        this.searchSub$?.unsubscribe();
        this.searchKey='';
        
      }
   });
    }

  ngAfterViewInit(): void {
    this.nameControl?.nativeElement.focus();
    if(this.model.id)
    {
      if(this.model?.location_latitude?.toString().length > 0 && this.model?.location_longitude?.toString().length > 0)
      {
  
        setTimeout(() => {
        
          this.viewMap(this.model?.location_latitude,this.model?.location_longitude)
 
        }, 100);     }
    }

  }

  ngOnInit(){

  }

  locationTypeChange(type: LocationType) {
    this.model.location_types_name = type?.name;
  }

  locationSystemTypeChange(type: LocationSystemType) {
    this.model.location_system_types_name = type?.name;
  }

  submit() {
    let api: Observable<CustomerLocation>;

    this.model.customer = this.service.customerId;
    this.model.suborg = this.customerSuborg_id?this.customerSuborg_id:this.service.suborgId;

    if (this.bindingModel) {
      api = this.service.updateCustomerLocation(this.bindingModel.id, this.model);
    }
    else {
      this.model.tenant_customer = this.tenantCustomerId;
      api = this.service.createCustomerLocation(this.model);
    }

    this.sub.sink = api.subscribe(
      resp => {
        let msg = this.bindingModel ? 'Location info updated' : 'New Location added';
        resp.location_types_name = this.model.location_types_name;
        resp.location_system_types_name = this.model.location_system_types_name;
        this.success?.emit({ response: resp, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void { this.cancel?.emit(); }


  valueSelected(event){



    if(event){
      // this.model.address_2=event.label;

      // this.model.location_latitude=event.y.toFixed(6);
      
      // this.model.location_longitude=event.x.toFixed(6);

      const coordinates: any = event.geometry.coordinates
      this.model.address_2=event.place_name;

      this.model.location_latitude=coordinates[0].toFixed(6);
      
      this.model.location_longitude=coordinates[1].toFixed(6);

      setTimeout(() => {
        this.viewMap(coordinates[0],coordinates[1])     
      }, 100);

    }

    this.cdr.markForCheck();

     
  }

  viewMap(latitude,longitude) {

    
      this.mapboxService.removeMap(); // Remove existing map if any
      this.mapboxService.createMap('map',latitude,longitude)

  }


  clearSearch(){

    this.model.address_2=null;

    this.model.location_latitude=null;
    
    this.model.location_longitude=null;

    this.cdr.markForCheck();

  }

  ngOnDestroy(): void { 

    this.mapboxService.removeMap()
    this.sub?.unsubscribe(); 

  }
}
