import { Component, OnInit } from '@angular/core';
//import { CatalogCartItem } from '../../models/catalog-cartitem.model';
import { CatalogCartService } from '../../services/catalog-cart.service';

@Component({
  selector: 'app-cart-view',
  templateUrl: './cart-view.component.html',
  styleUrls: ['./cart-view.component.scss']
})
export class CartViewComponent implements OnInit {

  cartItems: any[] = [];

  subTotal: number = 0;
  shipping: number = 0;
  total: number = 0;

  
  constructor(private catlogCartService: CatalogCartService) { }

  ngOnInit(): void {

    this.catlogCartService.currentCartItems.subscribe(res => {

      this.cartItems = res;
      console.log('cartItems', this.cartItems)

      this.findTotal()
    })
  }

  onChangeQuantity(item: any, tp) {


    if (tp == 0) //add
    {
      item.quantity = item.quantity + 1;
      item.totalPrice = item.quantity * item.unitPrice;
    }
    else {
      if (item.quantity != 1) {
        item.quantity = item.quantity - 1;
        item.totalPrice = item.quantity * item.unitPrice;
      }

    }
    this.findTotal();
    localStorage.setItem('catalog-cart-items', JSON.stringify(this.cartItems))
  }

  onCartItemDelete(index: number) {
    this.cartItems.splice(index, 1)
    this.findTotal();
    localStorage.setItem('catalog-cart-items', JSON.stringify(this.cartItems))
  }

  findTotal() {
    this.subTotal = this.cartItems.reduce((acc, item) => {

      return acc + item.totalPrice;
    }, 0)

    this.total = this.cartItems.reduce((acc, item) => {

      return acc + item.totalPrice + this.shipping
    }, 0)
  }

}
