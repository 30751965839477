import { SubSink } from 'subsink';
import { CustomerService } from './../../services/customer.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Customer } from '../../models/customer';
import { DatePipe, Location } from '@angular/common';

@Component({
    selector: 'app-add-customer',
    templateUrl: './add-customer.component.html',
    styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit, OnDestroy {

    public model: Customer;

    public customerId: number;

    private subs: SubSink = new SubSink();

    public autogenPassword: boolean = true;

    public nameExist: boolean = false;

    constructor(
        private customerService: CustomerService,
        route: ActivatedRoute,
        private toastr: ToastrService,
        public location: Location,
        private datePipe: DatePipe,
        private cdr: ChangeDetectorRef) {

        this.customerId = +route.snapshot.paramMap.get('id')
    }

    ngOnInit(): void {
        if (this.customerId) {
            this.subs.sink = this.customerService.getById(this.customerId).subscribe(customer => {
                this.model = customer;
                this.cdr.markForCheck();
            });
        }
        else {
            this.model = new Customer();
        }
    }

    customerFormOnSubmit() {
        if (this.customerId <= 0) {
            this.createCustomer();
        }
        else {
            this.updateCustomer();
        }
    }

    createCustomer() {
        let date = new Date();
        this.model.account.start_date = this.datePipe.transform(date, 'yyyy-MM-dd');
        this.model.account.end_date = this.datePipe.transform(date, 'yyyy-MM-dd');
        this.model.account.active = true;

        this.subs.sink = this.customerService.create(this.model).subscribe(
            customer => {
                this.toastr.success(`New Tenant created`);
                this.model = customer;
                this.location.back();
            },
            error => {
                if (error.error && error.name) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.toastr.error('Failed to create Tenant')
                }
            }
        );
    }

    updateCustomer() {
        this.subs.sink = this.customerService.update(this.customerId, this.model).subscribe(
            customer => {
                this.toastr.success(`Tenant info updated`);
                this.model = customer;
                this.location.back();
            },
            error => {
                if (error.error && error.name) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.toastr.error('Failed to update Tenant info')
                }
            }
        );
    }

    updateStatus() {
        if (this.customerId > 0) {
            let req = {
                account: this.model.account,
                active: this.model.active
            };
            this.subs.sink = this.customerService.patch(this.customerId, req).subscribe(
                resp => this.toastr.success('Status updated'),
                error => {
                    this.model.active = !this.model.active;
                    this.toastr.error('Failed to update Status');
                }
            );
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
