<section class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
    <div class="d-flex flex-row flex-1 h-100">
        <div class="pt-0" [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
            <!-- <div class="d-flex align-items-center flex-space-bw" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">

            </div> -->
            <div class="card-content">

                <div class="settings-div d-flex">

                    <div class="settings-menu">

                        <h6 class="title"><i class="ft ft-settings"></i> Settings</h6>

                        <ul class="menu-options">
                            <li class="active">General Settings</li>
                        </ul>
                       
                    </div>
                    <div class="settings-content">
                        <!-- <h5 class="item-title mt-2 mb-3">Sub-org :</h5> -->
                        <div class="row">

                            <div class="col-12 col-md-6" [ngStyle]="services.suborgId<0?{'opacity': '0.5'} : {'opacity': '1'}">

                                
                                <div class="form-group pb-2 d-flex justify-content-between">
                                    <label>Partial Payments</label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [(ngModel)]="settings.is_partial_payment" [disabled]="services.suborgId<0"  
                                        name="is_partial_payment" id="is_partial_payment" class="custom-control-input ng-untouched ng-pristine ng-valid" 
                                        ng-reflect-name="inventoried" ng-reflect-model="true" ng-reflect-is-disabled="false">
                                        <label  for="is_partial_payment" class="custom-control-label"><span ></span></label></div>
                                </div>

                                <div class="form-group pb-2 d-flex justify-content-between">
                                    <label>Over Payments</label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [disabled]="services.suborgId<0" [(ngModel)]="settings.is_over_payment"  name="is_over_payment" id="is_over_payment" class="custom-control-input ng-untouched ng-pristine ng-valid" ng-reflect-name="inventoried" ng-reflect-model="true" ng-reflect-is-disabled="false">
                                        <label  for="is_over_payment" class="custom-control-label"><span ></span></label></div>
                                </div>
                                
                            </div>
                            <div class="col-md-6"></div>

                        </div>
                        <div class="row content-footer">
                            <div class="col-12 col-md-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                <button type="submit" (click)="updateSettings()"
                                    class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                    Save
                                </button>
                                <button type="button"  class="btn btn-secondary" (click)="location.back()">Cancel</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
