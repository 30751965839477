<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter
                                     [module]="module"
                                     [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [searchFilters]="search"
                                     [newButton]="UIPermissions.create"
                                     [newButtonUrl]="['/sales/terms-conditions/add']"
                                     [expanded]="expanded"
                                     (apply)="applyFilter($event)">
                                     <ng-template filterControl [column]="'created_user'" let-row="row">
                                        <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                                    </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                                       class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [scrollbarH]="scrollbarH"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [selectionType]="SelectionType.single"
                                       [rows]="termsList"
                                       [externalPaging]="true"
                                       [externalSorting]="true"
                                       [offset]="page.offset"
                                       [count]="page.count"
                                       [limit]="page.limit"
                                       (select)="onSelect($event)"
                                       (page)="setPage($event)"
                                       (sort)="onSort($event)"
                                       [sorts]="[{prop:'created_on',dir:'desc'}]">

                            <ngx-datatable-column name="Title" prop="title"></ngx-datatable-column>
                            <!-- <ngx-datatable-column name="Category" prop="category">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{getCategory(row.category)}}
                                </ng-template>
                            </ngx-datatable-column> -->

                            <ngx-datatable-column *ngIf="expanded" name="Created on" prop="created_on">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.created_on|date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Modified on" prop="modified_on">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.modified_on|date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column *ngIf="expanded" name="Actions" [sortable]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="UIPermissions.viewInfo" class="w-100" (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>
                                            <a *ngIf="UIPermissions.edit" class="w-100" [routerLink]="['/sales/terms-conditions/',current.id,'edit']" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button *ngIf="UIPermissions.delete" class="w-100 bg-light-danger" (click)="deleteTerms(current)" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>

            <div class="parellax-detail" *ngIf="!expanded && selected">

                <div class="parellax-detail-content" >
                    <div class="category-details">
                        <div class="d-flex flex-row mt-2">
                            <h5 class="item-title m-0 text-left">{{selected?.title}}</h5>
                            <!-- {{selected|json}} -->
                            <div class="d-flex justify-content-end align-items-start">
                                <a *ngIf="UIPermissions.edit" [routerLink]="['/sales/terms-conditions/',selected.id,'edit']" ngbTooltip="Edit"
                                   class="btn btn-sm bg-light-secondary">
                                    <i class="ft-edit"></i> Edit
                                </a>
                                <button *ngIf="UIPermissions.delete" class="btn btn-sm bg-light-danger ml-1" (click)="deleteTerms(selected)">
                                    <i class="ft-trash-2 mr-1"></i> Delete
                                </button>
                                <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                    <i class="ft-x"></i>
                                </button>
                            </div>
                        </div>


                        <div class="group mt-2">

                            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                <li ngbNavItem>
                                    <a ngbNavLink class="d-flex align-items-center">
                                        <span class="d-none d-sm-block">Overview</span>
                                    </a>
                                    <ng-template ngbNavContent class="tab-content-pd">
                                        <div class="group mt-2">
                                            <div class="d-flex flex-row">
                                                <div class="col-12 p-0">
                                                    <div class="ov-row" *ngIf="selected?.title">
                                                        <label class="col-md-3 p-0 item-label">Title</label>
                                                        <label class="item-label-value">{{selected.title}}</label>
                                                    </div>
                                                    <div class="ov-row" *ngIf="selected?.description">
                                                        <label class="col-md-3 p-0 item-label">Description</label>
                                                        <label class="item-label-value">{{selected.description}}</label>
                                                    </div>
                                                    <!-- <div class="ov-row" *ngIf="selected?.category">
                                                        <label class="col-md-3 p-0 item-label">Category</label>
                                                        <label
                                                               class="item-label-value">{{getCategory(selected.category)}}</label>
                                                    </div> -->
                                                    <div class="ov-row" *ngIf="selected?.created_on">
                                                        <label class="col-md-3 p-0 item-label">Created at</label>
                                                        <label
                                                               class="item-label-value">{{selected.created_on|date:'MM/dd/YYYY'}}</label>
                                                    </div>
                                                    <div class="ov-row" *ngIf="selected?.modified_on">
                                                        <label class="col-md-3 p-0 item-label">Modified on</label>
                                                        <label
                                                               class="item-label-value">{{selected.modified_on|date:'MM/dd/YYYY'}}</label>
                                                    </div>
                                                    <div class="ov-row" *ngIf="selected?.created_by">
                                                        <label class="col-md-3 p-0 item-label">Created by</label>
                                                        <label class="item-label-value">{{selected.created_by}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink class="d-flex align-items-center">
                                        <span class="d-none d-sm-block">History</span>
                                    </a>
                                    <ng-template ngbNavContent class="tab-content-pd">
                                        <div style="max-width: 500px;">
                                            <!-- <history-view [module]="module" [uniqueId]="selected.id"></history-view> -->
                                            <!-- <terms-comments [termsId]="selected.id"></terms-comments>
                                             -->
                                            <history-view [module]="module" [uniqueId]="selected.id"></history-view>


                                        </div>
                                    </ng-template>
                                </li>

                            </ul>
                            <div [ngbNavOutlet]="nav"></div>


                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
