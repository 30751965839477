import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConstants } from "../constants/app.constants";
import { AllSuborgCreateAlert } from "../utils/popup.functions"
import * as momentTz from 'moment-timezone';
import { SpinnerVisibilityService } from "ng-http-loader";

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

    constructor(private router:Router, private spinnerService:SpinnerVisibilityService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const accessToken = localStorage.getItem(AppConstants.AUTH_TOKEN);

        const suborg = localStorage.getItem(AppConstants.CURRENT_SUBORG);

        let hideLoading=request.headers.get('Hideloading');

        // let timezone=momentTz.tz.guess();

        let customHeaders = {};

        if (accessToken) {
            customHeaders['Authorization'] = `Token ${accessToken}`;
        }

        if (suborg&&!request.headers.has('Suborg')) {
            customHeaders['Suborg'] = suborg;
        }



        
        // customHeaders['time_zone']=timezone;

        request = request.clone({ setHeaders: customHeaders });

        if(hideLoading=="true"){
            setTimeout(() => {
                this.spinnerService.hide()
                    
            }, 10);

            request = request.clone({
                headers: request.headers.delete('Hideloading'),
              });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((httpErrorResponse: HttpErrorResponse, _: Observable<HttpEvent<any>>) => {
                if (httpErrorResponse.status === 401) {
                    localStorage.clear();
                    this.router.navigate(['/login']);
                }
                if (httpErrorResponse.error && httpErrorResponse.error.suborg && suborg === '-1') {
                    AllSuborgCreateAlert();
                }
                return throwError(httpErrorResponse);
            })
        );
    }
}
