import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StaticFile } from 'src/app/core/models/files.models';
import { GFilterParam, DataFilterRequest, GSortParam, Pagination, GFColumn, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { SubOrg } from '../../models/suborg.models';
import { SuborgService } from '../../services/suborg.service';
import { ActivatedRoute } from '@angular/router';
import * as popup from 'src/app/core/utils/popup.functions';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-sub-orgs',
    templateUrl: './sub-orgs.component.html',
    styleUrls: ['./sub-orgs.component.scss']
})
export class SubOrgsComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = [
        { displayName: "All Sub-orgs", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Active Sub-orgs", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
        { displayName: "Inactive Sub-orgs", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
        { displayName: "Main Sub-orgs", colname: 'is_root', condition: 'is', value: 'true', operator: 'AND' },
    ];

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Combo Search', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Phone', colname: 'phone_number', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Address', colname: 'address', condition: 'contains', value: '', operator: 'OR' },
            ]
        },
        { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Phone', colname: 'phone_number', condition: 'contains', value: '', operator: 'OR' }
    ];

    public columns: GFColumn[] = [
        { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
        { displayName: 'Email', columnName: 'email', columnType: GFColumnTypes.string },
        { displayName: 'Phone', columnName: 'phone_number', columnType: GFColumnTypes.string },
        { displayName: 'Main Org', columnName: 'is_root', columnType: GFColumnTypes.boolean },
        { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
        { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
    ];

    public rows: SubOrg[];

    public expanded: boolean = true;

    public selected: SubOrg;

    public suborgId: number;

    public SelectionType = SelectionType;

    private subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public isSuperAdmin: boolean;

    public is_quickbook_subscription: boolean = false;

    public sort: GSortParam[];

    public activeTab: number = 1;

    public sortLookup = {
        name: "name",
        email: "email",
        address: "address",
        phone_number: "phone_number",
        created_on: "created_on",
        customer_name: "customer_name",
        created_by: "created_by"
    };
    itemImages: StaticFile[];

    constructor(private suborgService: SuborgService,
        private device: DeviceDetectorService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService) {

        this.isSuperAdmin = suborgService.isSuperUser;
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
        this.getData(null);
        if (this.isSuperAdmin) {
            this.columns.unshift({ displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref })
        }

        this.activatedRoute.queryParams.subscribe(params => {
            const suborgId = params['suborg'];
            const tab = params['tab'];
            if(suborgId){ 
            console.log(suborgId, 'suborgId+');
            this.suborgService.getById(suborgId).subscribe(resp => {
               
                console.log(resp,'resp+')
                this.onSelect({selected:[resp]});
                if(tab && tab=='qb'){
                this.activeTab = 3;
                }
            })
            }
          });
    }

    getData(filters: GFilterParam[], offset: number = 0) {

        let request: DataFilterRequest = { filters: filters, sort: this.sort }

        this.subs.sink = this.suborgService.getFiltered(request, offset, this.page.limit).pipe(
            catchError(err => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.is_quickbook_subscription = response['is_quickbook_subscription'];
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
        if (!offset) {
            this.page.offset = 0;
        }
    }

    onSelect({ selected }) {
        let current: SubOrg = selected[0];
        if (current) {
            this.suborgId = current.id;
            this.selected = current;
            this.getImages()
            this.expanded = false;
            this.refreshDataTable();
        }
    }

    getImages(): void {
        this.subs.sink = this.suborgService.getSuborgImages(this.suborgId).subscribe(resp=> {
            this.itemImages = resp;
            this.cdr.markForCheck();
        });
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.refreshDataTable();
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) { this.getData(params); }

    globalRFSOnChange(): void {
        let user = this.selected;
        let opt = {
          title: `Are you sure you want to ${user.use_global_reason ? 'turn on Global Reason' : 'turn off Global Reason'}?`,
          text: user.name,
          current: user.use_global_reason,
          ButtonText: "Yes"
        }
        popup.ConfirmWithButtonText(opt, result => {
          if (result.isConfirmed) {
            this.subs.sink = this.suborgService.patch(user.id, { use_global_reason: user.use_global_reason }).subscribe(
              resp => { },
              () => {
                this.selected.use_global_reason = !user.use_global_reason;
                this.cdr.markForCheck();
                this.toastr.error('Failed to change status');
              }
            );
          }
          else {
            this.selected.use_global_reason = !user.use_global_reason;
            this.cdr.markForCheck();
          }
        });
      }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
