<section class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
    <div class="row h-100">
        <div class="col-12 d-flex flex-row h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center"
                    [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter [defaultFilters]="defaults" [module]="module"
                        [searchFilters]="search" [newButton]="false" [expanded]="expanded" [columns]="columns"
                        (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'items'" let-row="row">
                            <item-select class="select-sm" [selectBox]="true" [(value)]="row.value"></item-select>
                        </ng-template>
                        <ng-template filterControl [column]="'kit_id'" let-row="row">
                            <kit-select class="select-sm" [selectBox]="true" [(value)]="row.value"></kit-select>
                        </ng-template>
                        <ng-template filterControl [column]="'kit_category'" let-row="row">
                            <category-select class="ng-select-sm" [(value)]="row.value"></category-select>
                        </ng-template>
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue"></suborg-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap"
                            [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                            [rows]="rows" [selectionType]="SelectionType.single" [count]="page.count"
                            [externalPaging]="true"
                            [offset]="page.offset" [externalSorting]="true" [sorts]="[{prop:'part_number',dir:'asc'}]"
                            [limit]="page.limit" (select)="onSelect($event)" (page)="setPage($event)"
                            (sort)="onSort($event)">
                            <ngx-datatable-column name="Image" prop="kit_image" [sortable]="false" [width]="90"
                                [canAutoResize]="false">
                                <ng-template let-url="value" ngx-datatable-cell-template>
                                    <img *ngIf="url" width="80" height="80" [src]="fileUrl + url + '.jpg'" />
                                    <img *ngIf="!url" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Created Date" prop="created_on" [width]="150">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.created_on | date:'MM/dd/YYYY h:mm a'}}</div>
                                    <div>{{row.unique_number}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Name" prop="kit_name"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Part Number" prop="part_number">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="SKU" prop="kit_sku"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Available Qty" prop="available_stock"
                                [sortable]="false">
                                <ng-template let-quantity="value" ngx-datatable-cell-template>
                                    {{quantity ? quantity : '0'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Assembled By" prop="created_by"
                                [sortable]="false">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isAllSuborg" name="Suborg" prop="suborg_name"
                                [sortable]="false">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="status" [sortable]="false">
                                <ng-template let-status="value" let-row="row" ngx-datatable-cell-template>
                                    <div class="badge bg-light-success">
                                        {{ status }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && !isAllSuborg" name="Action" prop="id"
                                [sortable]="false"
                                [width]="80" [canAutoResize]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                            (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="hasPermission(Permissions.VIEW_INFO)" class="w-100"
                                                (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>
                                            <a *ngIf="hasPermission(Permissions.MODIFY)" class="w-100"
                                                [routerLink]="['/inventory/assemblies/assembled',id]" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button (click)="deleteAssembled(id)"
                                                *ngIf="hasPermission(Permissions.DELETE)" class="w-100" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Break
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                        [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail pb-2" *ngIf="!expanded && selected">
                <div class="parellax-detail-content assembled-details">
                    <div class="progress-steps">
                        <div class="arrow-steps clearfix">
                            <div class="step active">
                                <strong>DRAFT</strong>
                            </div>
                            <div class="step"
                                [ngClass]="{'active': StatusIndex >0, 'inactive': StatusIndex == 0}">
                                <strong>IN_PROGRESS</strong>
                            </div>
                            <div class="step"
                                [ngClass]="{'active': selected?.status == 'ALLOCATED' || StatusIndex >=4, 'inactive': StatusIndex <= 1 || selected?.status == 'SPECIFIED'}">
                                <strong>ALLOCATED</strong>
                                <small>All material</small>
                            </div>
                            <div class="step"
                                [ngClass]="{'active': selected?.status == 'SPECIFIED' || StatusIndex >=4, 'inactive': StatusIndex <= 2 }">
                                <strong>SPECIFIED</strong>
                                <small>Warehouse location</small>
                            </div>
                            <div class="step"
                                [ngClass]="{'active': selected?.status == 'COMPLETED', 'inactive': selected?.status !== 'COMPLETED'}">
                                <strong>COMPLETED</strong>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row mt-2">
                        <div class="item-title d-flex flex-column">
                            <h5 class="m-0">{{selected.kit_name}}</h5>
                            <p class="mt-1 mb-0">{{selected.unique_number}}</p>
                        </div>
                        <div class="d-flex justify-content-end align-items-start">
                            <button class="btn btn-sm btn-info ml-1 mr-1" *ngIf="hasPermissionWR(Resource.INV_LABELS_PRINT,Permissions.PRINT)||hasPermissionWR(Resource.INV_MODULE,Permissions.GLOBAL)" (click)="toggleLabelModal()">
                                <i class="fa ft-printer"></i> Print Label
                            </button>
                            <div class="complete-btn mr-1">
                                <button
                                    (click)="onCompleteClick()"
                                    class="btn btn-sm success ml-1"
                                    [disabled]="selected.status !== 'ALLOCATED/SPECIFIED'"
                                    *ngIf="!isAllSuborg" >
                                    <i class="ft-check-square mr-1"></i> COMPLETE
                                </button>
                            </div>
                            <a *ngIf="hasPermission(Permissions.MODIFY) && !isAllSuborg"
                                [routerLink]="['/inventory/assemblies/assembled',selected.id]"
                                ngbTooltip="Edit" class="btn btn-sm bg-light-secondary"
                                [class]="{'disabled':selected.status == 'COMPLETED'}">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button (click)="deleteAssembled(selected.id)"
                            *ngIf="hasPermission(Permissions.DELETE) && !isAllSuborg"
                            class="btn btn-sm bg-light-danger ml-1">
                                <i class="ft-trash-2 mr-1"></i> Break
                            </button>
                            <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex row flex-column">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">

                                    <div class="group">
                                        <div class="group-body d-flex flex-row">
                                            <div class="col-12 p-0" [ngClass]="{ 'col-md-7' : images }">
                                                <div class="ov-row" *ngIf="selected.part_number">
                                                    <label class="col-md-3 p-0 item-label">Internal Number</label>
                                                    <label class="item-label-value">{{selected.part_number}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.kit_sku">
                                                    <label class="col-md-3 p-0 item-label">SKU</label>
                                                    <label class="item-label-value">{{selected.kit_sku}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.quantity">
                                                    <label class="col-md-3 p-0 item-label">Quantity</label>
                                                    <label class="item-label-value">{{selected.quantity}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.created_on">
                                                    <label class="col-md-3 p-0 item-label">Created On</label>
                                                    <label
                                                        class="item-label-value">{{selected.created_on | date:'MM/dd/YYYY'}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.created_by">
                                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                                    <label class="item-label-value">{{selected.created_by}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.modified_on">
                                                    <label class="col-md-3 p-0 item-label">Modified On</label>
                                                    <label
                                                        class="item-label-value">{{selected.modified_on | date:'MM/dd/YYYY'}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.modified_by">
                                                    <label class="col-md-3 p-0 item-label">Modified By</label>
                                                    <label class="item-label-value">{{selected.modified_by}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.suborg_name && isAllSuborg">
                                                    <label class="col-md-3 p-0 item-label">Suborg</label>
                                                    <label class="item-label-value">{{selected.suborg_name}}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-5 p-1 d-flex primary-image-container" *ngIf="images && images.length">
                                                <app-file-uploader [primary]="selected.kit_image" [files]="images" [readonly]="true"></app-file-uploader>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group mt-3">
                                        <div class="group-header">
                                            Associated Items
                                        </div>
                                        <div class="group-body">
                                            <assembled-kit-items #assembleGrid [bundleCount]="selected.quantity"
                                                [viewMode]="true"
                                                [assembledId]="selected.id">
                                            </assembled-kit-items>
                                        </div>
                                    </div>
                                    <div class="group mt-3">
                                        <div class="group-header">Warehouses <span style="font-size:14px;">(Locations
                                                where assemblies
                                                are stored)</span></div>
                                        <div class="group-body">
                                            <kit-assemble-warehouse #warehouseGrid
                                                [saveButton]="hasPermissionWR(Resource.INV_KITS_ASSEMBLE_WAREHOUSE,Permissions.MODIFY)"
                                                [assembledId]="assembleId"
                                                [quantity]="selected.quantity"
                                                [status]="selected.status"
                                                (openPrint)="openPrintView($event)"
                                                >
                                            </kit-assemble-warehouse>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">History</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="history-container" *ngIf="selected">
                                        <history-view [module]="'KIT_ASSEMBLE'" [uniqueId]="selected.id"></history-view>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #labelModal let-c="close" let-d="dismiss">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="Print Label">Print Label</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
             <form >

                <div class="row">
                    <div class="col-md-12">


                            <div class="form-group" [hidden]="true">
                                <label class="val-required">Wherehouse</label>
                                <ng-select [items]="warehouseGrid.warehouses"
                                (change)="onLabelWhereHouseChange($event.id)"
                                [(ngModel)]="labelWherehouse"
                                #lWherehouse="ngModel"
                                name="wherehouse"
                                placeholder="Select Wherehouse"
                                bindLabel="warehouse_name"
                                bindValue="id"
                                appendTo="body" >

                                </ng-select>

                                <small *ngIf="lWherehouse.invalid&&(lWherehouse.dirty||lWherehouse.touched)">
                                    <div *ngIf="lWherehouse.errors.required">
                                        Select wherehouse
                                    </div>
                                </small>
                            </div>

                            <div class="form-group" [hidden]="true">
                                <label class="val-required">Location</label>
                                <ng-select [items]="labelLocationList"
                                [(ngModel)]="labelLocation"
                                #lLocation="ngModel"
                                name="location"
                                placeholder="Select Wherehouse location"
                                bindLabel="location"
                                bindValue="id"
                                [disabled]="!labelWherehouse"
                                appendTo="body" >

                                </ng-select>


                                <small *ngIf="lLocation.invalid&&(lLocation.dirty||lLocation.touched)">
                                    <div *ngIf="lLocation.errors.required">
                                        Select wherehouse location
                                    </div>
                                </small>
                            </div>



                            <div class="form-group" style="max-width:200px">
                                <label class="val-required">Number of Labels to print</label>
                                        <input type="number"
                                        class="form-control"
                                        placeholder="Enter count"
                                        name="labelCount"
                                        [(ngModel)]="printCount" #labelCount="ngModel" required/>

                                <small class="form-text text-muted danger" *ngIf="labelCount.invalid &&(labelCount.dirty||labelCount.touched)">
                                    <div *ngIf="labelCount.errors.required">
                                       Please enter number of labels to print
                                    </div>

                                </small>
                            </div>



                            <!-- <hr/> -->
                            <div class="mb-3 d-flex flex-row justify-content-between" *ngIf="labelWherehouse&&labelLocation&&labelSlNo.length" >
                                <h6 class="mb-0 p-1">Serial Numbers
                                    <small *ngIf="!allSnSelected"><a (click)="selectAllSn(true)">(Select all)</a></small>
                                    <small *ngIf="allSnSelected"><a (click)="selectAllSn(false)">(Unselect all)</a></small>
                                </h6>
                                <input type="text" placeholder="Search serial number" [(ngModel)]="slnoSearch" name="slnoSearch" class="form-control form-control-sm" style="max-width:250px">                                <!-- {{slnoSearch}} -->

                                <!-- <button class="btn btn-sm btn-link"><i class="ft ft-settings"></i> Generate</button> -->
                            </div>

                            <ul class="sl-no-ul d-flex align-content-start flex-wrap" *ngIf="labelWherehouse&&labelLocation" >
                                <li class="d-flex mb-2" *ngFor="let val of labelSlNo|Search:slnoSearch;index as index" (click)="val.selected=!val.selected">
                                    <input type="checkbox" [(ngModel)]="val.selected" name="slNo{{index}}" class="mr-1" />{{val.slNo}}
                                </li>


                            </ul>

                            <!-- {{labelSlNo|json}} -->
                            <!-- <div class="d-flex mt-1" *ngIf="labelWherehouse&&labelLocation">
                                <div class="input-group input-group-sm" style="max-width:250px">
                                    <input type="number" class="form-control" placeholder="Serial Number count"/>
                                    <div class="input-group-append" >
                                        <button class="btn btn-outline-secondary" name="addSlNo">Generate</button>
                                    </div>
                                </div>

                            </div> -->


                    </div>
                    <!-- <div class="col-md-6">
                        <p>Label design comes here</p>
                        <canvas id="canvas" style="border:1px solid #ccc" width="384" height="192"></canvas>

                    </div> -->

                    <div class="col-md-12 form-group float-right mt-4">
                        <button class="btn btn-secondary mr-1" name="cancel" (click)="d('Cross click')">Cancel</button>
                        <button class="btn btn-primary" name="next" (click)="proceedToPrinting()">Next</button>


                        </div>

                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</section>
