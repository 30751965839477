import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomerContact } from '../../tenant-customers/models/tenant-customer';
import { ToastrService } from 'ngx-toastr';
import { CallCenterService } from '../../ring2voice/services/call-center.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {

  @Input() tenant_customer_id;
  @Input() cust_name;
  @Input() cust_contact_name;
  @Input() cust_contact_email;
  @Input() cust_contact_phone;

  @Input() call_to_suborg_id;

  @Input() location_name;
  @Input() location_address;
  @Input() location_city;
  @Input() location_state;
  @Input() location_zip;

  @ViewChild('newContactForm') newContactForm: any;
  @ViewChild('FormModal') modalContent: any;

  private subs: SubSink = new SubSink();

  private modalRefNew: NgbModalRef;

  private modalRef: NgbModalRef;

  public bindingModel: CustomerContact = new CustomerContact();

  constructor(public modal: NgbModal, private toastr: ToastrService, public callCenterService:CallCenterService,) { }

  ngOnInit(): void {
  }

  openFormNew() {
    this.modalRefNew = this.modal.open(this.newContactForm, { size: 'lg', scrollable: true, centered: true });
  }

  openModal1() {
    this.modalRef = this.modal.open(this.modalContent, { size: 'lg', scrollable: true, centered: true });
  }

  editContact(contact_id:number){

    this.subs.sink = this.callCenterService.getContactDetails(contact_id).subscribe(data => {
      this.bindingModel = data;
     
      // if(this.cust_contact_phone == null){
  
        console.log(this.bindingModel,'this.bindingModel')
        if(this.bindingModel.phone=='' || this.bindingModel.phone==null){
          this.bindingModel.phone = this.cust_contact_phone;
        }else if(this.bindingModel.mobile=='' || this.bindingModel.phone==null){
          this.bindingModel.mobile = this.cust_contact_phone;
        }else{
          this.toastr.error('Mobile and phone numbers are already exist.Please copy paste Incoming number to any of these field to update')
        }
      // }
  
      this.openModal1();
    },
    (error)=>{
      this.toastr.error("Error occurred");
    }); 
  }

  onDataSubmittedSuccess(result: { response: CustomerContact, message: string }): void {
  
    //console.log(result.response,'result.response+')
    this.cust_name = result.response.tenant_customer_name;
    this.location_name = result.response.location_name;
    this.location_address = result.response.location_address;
    this.location_city = result.response.location_city;
    this.location_state = result.response.location_state;
    this.location_zip = result.response.location_zip;
    this.cust_contact_email = result.response.email;
    this.cust_contact_name = result.response.first_name;
    this.tenant_customer_id = result.response.id;
    this.toastr.success('Customer Contact Updated.');
    this.closeModal1();
    this.modalRefNew?.dismiss();
  }
  
  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal1();
  }
  
  onCancel(): void { this.closeModal1(); }
  
  onCancelNew(): void { this.modalRefNew?.dismiss(); }
  
  closeModal1() { this.modalRef?.dismiss(); }

}
