<section id="item-view" *ngIf="item">
    <div class="d-flex mt-2" [ngClass]="item.item_name.length <= 30 ? 'flex-row': 'flex-column'">
        <div class="item-title m-0">{{item.item_name}}</div>
        <div class="d-flex justify-content-end align-items-start">
            <button class="btn btn-sm btn-info ml-1 mr-1" *ngIf="hasPermission(Resource.INV_LABELS_PRINT,Permissions.PRINT)" (click)="toggleLabelModal(labelModal)">
                <i class="fa ft-printer"></i> Print Label
            </button>

            <!-- <a class="btn btn-sm btn-info ml-1 mr-1" href="/inventory/labels/print?type=item&uniqueId={{item.id}}">
                <i class="fa ft-printer"></i> Print Label
            </a> -->


            <button disableIfAllsuborg [hide]="true" *ngIf="hasPermission(Resource.INV_KITS,Permissions.CREATE)" class="btn btn-sm btn-info ml-1 mr-1" (click)="convertToKit()">
                Convert to Assembly
            </button>
            <div ngbDropdown placement="bottom-right" disableIfAllsuborg [hide]="true">
                <button class="btn btn-sm" ngbDropdownToggle>
                    More
                </button>
                <div ngbDropdownMenu>
                    <a class="w-100" ngbDropdownItem *ngIf="hasPermission(Resource.INV_ITEMS,Permissions.MODIFY)"
                        [routerLink]="['/inventory/items',item.id,'edit']" ngbTooltip="Edit">
                        <i class="ft-edit mr-1"></i> Edit
                    </a>
                    <a ngbDropdownItem [routerLink]="['/inventory/items',item.id,'clone']"
                        *ngIf="hasPermission(Resource.INV_ITEMS,Permissions.CREATE)" class="w-100">
                        <i class="ft-copy mr-1"></i> Clone
                    </a>
                    <button ngbDropdownItem *ngIf="hasPermission(Resource.INV_ITEMS,Permissions.DELETE)"
                        class="bg-light-danger w-100" (click)="deleteItem()">
                        <i class="ft-trash-2 mr-1"></i> Delete
                    </button>
                </div>
            </div>
            <button class="btn btn-sm ml-1 font-medium-3 pt-0 pb-0" (click)="closeItemView()">
                <i class="ft-x"></i>
            </button>
        </div>
    </div>
    <div class="col-12 col-md-12 p-0">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Overview</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div class="group">
                        <div class="group-header d-flex flex-row justify-content-between">
                            Overview
                            <div class="status-toggle-btn">
                                <label class="title">Status :</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="item.is_active" name="active"
                                        class="custom-control-input" (change)="changeStatus()" id="status"
                                        [disabled]="item.is_active ? !hasPermission(Resource.INV_ITEMS,Permissions.INACTIVE):!hasPermission(Resource.INV_ITEMS,Permissions.ACTIVE)">
                                    <label class="custom-control-label" for="status">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <div class="status-toggle-btn">
                                <label class="title">Show On Catelog :</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="item.show_on_catelog" name="show_on_catelog"
                                        class="custom-control-input" (change)="changeShowOnCatelog()" id="show_on_catelog"
                                        >
                                    <label class="custom-control-label" for="show_on_catelog">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row">
                            <div class="col-12 p-0" [ngClass]="{'col-md-7':images}">

                                <div class="ov-row" *ngIf="item.part_number">
                                    <label class="col-md-3 p-0 item-label">Part Number</label>
                                    <label class="item-label-value">{{item.part_number}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.sku">
                                    <label class="col-md-3 p-0 item-label">SKU</label>
                                    <label class="item-label-value">{{item.sku}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.itemtype_name">
                                    <label class="col-md-3 p-0 item-label">Item Type</label>
                                    <label class="item-label-value">{{item.itemtype_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.category_name">
                                    <label class="col-md-3 p-0 item-label">Item Category</label>
                                    <label class="item-label-value">{{item.category_name}}{{(item['category_description']) ? " - "+item['category_description']:''}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.product_line_name">
                                    <label class="col-md-3 p-0 item-label">Product Line</label>
                                    <label class="item-label-value">{{item.product_line_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.material_name">
                                    <label class="col-md-3 p-0 item-label">Material</label>
                                    <label class="item-label-value">{{item.material_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.replace_by_part_number">
                                    <label class="col-md-3 p-0 item-label">Replaced By</label>
                                    <label class="item-label-value">{{item?.replace_by_part_number}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.unittype_name">
                                    <label class="col-md-3 p-0 item-label">Unit Of Measure</label>
                                    <label class="item-label-value">{{item.unittype_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.manufacturers_name">
                                    <label class="col-md-3 p-0 item-label">Manufacturer</label>
                                    <label class="item-label-value">{{item.manufacturers_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.part_description">
                                    <label class="col-md-3 p-0 item-label">Description</label>
                                    <label class="item-label-value">{{item.part_description}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.comments">
                                    <label class="col-md-3 p-0 item-label">Comments</label>
                                    <label class="item-label-value">{{item.comments}}</label>
                                </div>
                                <div class="ov-row" *ngIf="item.spec_sheet">
                                    <label class="col-md-3 p-0 item-label">Spec Sheet</label>
                                    <label class="item-label-value">
                                        <a (click)="specModal.toggle()" class="text-info">Read</a>
                                        <quill-modal #specModal title="Spec Sheet" [viewMode]="true"
                                            [value]="item.spec_sheet"></quill-modal>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="item.instructions_sheet">
                                    <label class="col-md-3 p-0 item-label">Instruction Sheet</label>
                                    <label class="item-label-value">
                                        <a (click)="instrModal.toggle()" class="text-info">Read</a>
                                        <quill-modal #instrModal title="Instruction Sheet" [viewMode]="true"
                                            [value]="item.instructions_sheet"></quill-modal>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="item.created_on">
                                    <label class="col-md-3 p-0 item-label">Created On</label>
                                    <label class="item-label-value">{{item.created_on | date:'MM/dd/YYYY'}}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value">
                                        {{item.quickbook_id?'Quickbooks Admin':item.created_by}}
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="item.suborg_name && isAllSuborg">
                                    <label class="col-md-3 p-0 item-label">Suborg</label>
                                    <label class="item-label-value">{{item.suborg_name}}</label>
                                </div>
                                <ul class="list-unstyled mt-3 mb-2">
                                    <ng-container *ngFor="let itemType of ['Inventory', 'Non Inventory', 'Service']; let i = index">
                                        <li class="d-inline-block mr-2">
                                            <div class="radio radio-success">
                                                <input type="radio" name="radio" [id]="'radio-' + (i + 1)" [(ngModel)]="item.item_type" [value]="itemType" 
                                                    [disabled]="!hasPermission(Resource.INV_ITEMS_INVENTORIED, Permissions.MODIFY)" (change)="changeInventoried()">
                                                <label [for]="'radio-' + (i + 1)">
                                                    {{ itemType === itemTypes.INVENTORY ? 'Inventoried' : itemType === itemTypes.NONINVENTORY ? 'Non Inventoried' : 'Service' }}
                                                </label>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                            <div class="col-12 col-md-5 p-1 d-flex primary-image-container"
                                *ngIf="images && images.length">
                                <app-file-uploader [primary]="item.primary_image" [files]="images" [readonly]="true">
                                </app-file-uploader>
                            </div>
                        </div>
                    </div>
                    <div class="group mt-2" *ngIf="item.item_price || item.tax_value || item.salesaccount_name">
                        <div class="group-header">
                            Sales Information
                        </div>
                        <div class="col-12 p-0">
                            <div class="ov-row" #tes
                                *ngIf="item.item_price && hasPermission(Resource.INV_ITEMS_PRICE,Permissions.VIEW)">
                                <label class="col-md-3 p-0 item-label">Sell Price</label>
                                <label class="item-label-value">${{item.item_price}}</label>
                            </div>
                            <div class="ov-row" *ngIf="item.tax_value">
                                <label class="col-md-3 p-0 item-label">Tax</label>
                                <label class="item-label-value">{{item.tax_value}} %</label>
                            </div>
                            <div class="ov-row" *ngIf="item.salesaccount_name">
                                <label class="col-md-3 p-0 item-label">Sales Account</label>
                                <label class="item-label-value">{{item.salesaccount_name}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="group group-supplier mt-3"
                        *ngIf="hasPermission(Resource.INV_ITEMS_SUPPLIERS,Permissions.VIEW)">
                        <div class="group-header">
                            Suppliers
                            <button
                                *ngIf="hasPermissionAny(Resource.INV_ITEMS_SUPPLIERS,[Permissions.CREATE,Permissions.MODIFY])"
                                (click)="toggleSupplierForm(supplierMapModal)"
                                class="btn btn-sm btn-secondary float-right" disableIfAllsuborg [hide]="true">
                                Add Supplier
                            </button>
                        </div>
                        <div class="group-body col-12 p-0">
                            <ngx-datatable class="bootstrap core-bootstrap" [scrollbarH]="true" [headerHeight]="40"
                                rowHeight="auto" [columnMode]="'force'" [rows]="itemSuppliers | async"
                                [selectionType]="selectionType.single"
                                [sorts]="[{prop:'supplier_name',dir:'asc'}]"
                                (select)="onSupplierSelect($event)">
                                <ngx-datatable-column name="Supplier Name" prop="supplier_name"></ngx-datatable-column>
                                <ngx-datatable-column
                                    *ngIf="hasPermission(Resource.INV_ITEMS_SUPPLIERS_PN,Permissions.VIEW)"
                                    name="Supplier P/N" prop="supplier_part_number"
                                    [sortable]="false"></ngx-datatable-column>
                                <ngx-datatable-column
                                    *ngIf="hasPermission(Resource.INV_ITEMS_SUPPLIERS_COST,Permissions.VIEW)"
                                    name="Supplier Cost" prop="price" [sortable]="false">
                                    <ng-template let-cost="value" ngx-datatable-cell-template>
                                        $ {{cost}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Created Date" prop="created_on" [sortable]="false">
                                    <ng-template let-crdate="value" ngx-datatable-cell-template>
                                        {{crdate |date:'MM/dd/YYYY'}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Created By" prop="created_by" [sortable]="false">
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Default" prop="is_default" [sortable]="false">
                                    <ng-template let-isdefault="value" ngx-datatable-cell-template>
                                        <div [ngClass]="{'text-success': isdefault,'text-danger': !isdefault }">
                                            {{ isdefault ? 'True' : 'False' }}
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column *ngIf="!isAllSuborg"
                                    name="Action" prop="id" [sortable]="false">
                                    <ng-template let-id="value" ngx-datatable-cell-template>
                                        <div ngbDropdown container="body">
                                            <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                <i class="ft-more-vertical text-primary"></i>
                                            </button>
                                            <div ngbDropdownMenu>
                                                <button
                                                    *ngIf="hasPermission(Resource.INV_ITEMS_SUPPLIERS,Permissions.MODIFY)"
                                                    class="w-100" (click)="editSupplierMap(id)" ngbDropdownItem>
                                                    <i class="ft-edit mr-1"></i> Edit
                                                </button>
                                                <button
                                                    *ngIf="hasPermission(Resource.INV_ITEMS_SUPPLIERS,Permissions.DELETE)"
                                                    class="w-100 bg-light-danger"
                                                    (click)="deleteItemSupplierMap(id)" ngbDropdownItem>
                                                    <i class="ft-trash-2 mr-1"></i> Delete
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                    </div>
                    <ng-container *ngIf="item.item_type!=itemTypes.NONINVENTORY && hasPermission(Resource.INV_ITEMS_STOCK,Permissions.VIEW)">
                        <div class="group mt-3">
                            <div class="group-header d-flex flex-row">
                                Stock
                            </div>
                            <app-item-stock #itemStockGrid (openPrint)="openPrintView($event)" [itemId]="item.id">

                            </app-item-stock>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="item && item.additional_fields && item.additional_fields | customfields as custFields">
                        <div class="group mt-3 additional-info" *ngIf="custFields && custFields.length > 0">
                            <div class="group-header">
                                Additional Info
                            </div>
                            <div class="col-12 p-0">
                                <ng-container *ngFor="let field of custFields">
                                    <div class="ov-row">
                                        <label class="col-md-3 p-0 item-label">{{field.name}}</label>
                                        <label class="item-label-value">{{field.value || 'NIL'}}</label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">History</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div class="history-container" *ngIf="item">
                        <history-view [module]="'ITEM'" [uniqueId]="item.id"></history-view>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>

    <ng-template #supplierMapModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{itemSupplierId > 0 ? 'Edit' : 'Add'}} Supplier</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-item-supplier-form
                [currentValues]="supplierIds"
                [itemId]="item?.id"
                [itemSMapId]="itemSupplierId"
                (onSuccess)="onItemSMapDataSuccess($event)"
                (onError)="onItemSMapDataError($event)"
                (onCancel)="closeModal()">
            </app-item-supplier-form>
        </div>
    </ng-template>

    <ng-template #labelModal let-c="close" let-d="dismiss">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="Print Label">Print Label</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
             <form >

                <div class="row">
                    <div class="col-md-12">





                            <div class="form-group" style="max-width:200px">
                                <label class="val-required">Number of Labels to print</label>
                                        <input type="number"
                                        class="form-control"
                                        placeholder="Enter count"
                                        name="labelCount"
                                        [(ngModel)]="printCount" #labelCount="ngModel" required/>

                                <small class="form-text text-muted danger" *ngIf="labelCount.invalid &&(labelCount.dirty||labelCount.touched)">
                                    <div *ngIf="labelCount.errors.required">
                                       Please enter number of labels to print
                                    </div>

                                </small>
                            </div>

                            <!-- <div class="form-group">
                                <label class="val-required">Label template</label>
                                <ng-select
                                [items]="labelPageTemplates"
                                [(ngModel)]="labelTemplate"
                                #lTemplate="ngModel"
                                name="labeltemplate"
                                (change)="labelTemplateChange($event)"
                                placeholder="Select label templte"
                                bindLabel="title"
                                bindValue="id"
                                appendTo="body" >

                                </ng-select>

                                <small *ngIf="lTemplate.invalid&&(lTemplate.dirty||lTemplate.touched)">
                                    <div *ngIf="lTemplate.errors.required">
                                        Please select the label template
                                    </div>
                                </small>
                            </div> -->


                            <!-- <hr/> -->
                            <div class="mb-3 mt-2 d-flex flex-row justify-content-between" *ngIf="labelWherehouse&&labelLocation&&labelSlNo.length" >
                                <h6 class="mb-0 p-1">Serial Numbers</h6>
                                <!-- <button class="btn btn-sm btn-link"><i class="ft ft-settings"></i> Generate</button> -->

                                <input type="text" placeholder="Search serial number" [(ngModel)]="slnoSearch" name="slnoSearch" class="form-control form-control-sm" style="max-width:250px">                                <!-- {{slnoSearch}} -->
                            </div>

                            <ul class="sl-no-ul d-flex align-content-start flex-wrap" *ngIf="labelWherehouse&&labelLocation">
                                <li class="d-flex mb-2" *ngFor="let val of labelSlNo|Search:slnoSearch;index as index">
                                    <input type="checkbox" [(ngModel)]="val.selected" name="slNo{{index}}" id="id{{index}}" class="mr-1" />
                                    <label for="id{{index}}">{{val.slNo}}</label>
                                </li>
                            </ul>



                    </div>


                    <div class="col-md-12 form-group float-right mt-4">
                        <button class="btn btn-secondary mr-1" name="cancel" (click)="d('Cross click')">Cancel</button>
                        <button class="btn btn-primary" name="next" (click)="proceedToPrinting()">Next</button>


                        </div>

                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</section>

<!-- {{toggleLabelModal(labelModal)}} -->
