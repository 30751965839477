import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'replaceHtml'})
export class ReplaceHtmlPipe implements PipeTransform {
  transform(value: string): string {

    if(!value)
    {
      return value;
    }
    return value.replace(/<[^>]*>/g, '')
  }
}
