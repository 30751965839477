<section  *ngIf="showCount">
    <app-callreport-dashboard [dashboardData] = "dashboardData"  [carddata] = "carddata"
    (displayNameChange) = "setFilter($event)"></app-callreport-dashboard>
</section>

<section class="common-grid-view call-history">
    <div class="list-parellax" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}" *ngIf = "showGridFilter">

                 
                    <datagrid-filter #dataFilter

                                    [gridTabs] = "_gridTabs"
                                    [selectedTabId]="_activeTab"
                                    (selectedGridTab)="onSelectGridTabs($event)"


                                     [searchFilters]="search"
                                     [expanded]="expanded"
                                     [columns]="columns"
                                     [defaultFilters]="defaults"
                                     [resetToCurrentDashData]="true"
                                     [module]="module"
                                     [export]="showExport"
                                     (exportButtonClick)="bulkExport()"
                                     (apply2)="applyFilterGroup($event)"
                                     [showSearch] = "showSearch"
                                     [showSavedFilter] = "_showSavedFilter"
                                     [reportTypeData] = "reportTypeData"
                                     [showreportType] = "showreportType"
                                     [showDefaultFilter] = "showDefaultFilter">

                        <ng-template filterControl [column]="'customer'" let-row="row">
                            <app-customer-select class="ng-select-sm" [(value)]="row.value"
                                                 (change)="dataFilter.controlValueChanged('customer')">
                            </app-customer-select>
                        </ng-template>
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue">
                            </suborg-select>
                        </ng-template>
                        <ng-template filterControl column="forward_number_sid" let-row="row">
                            <forward-bin-select class="ng-select-sm"
                                [(value)]="row.value"
                                [suborgId]="suborg_id">
                            </forward-bin-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                    <ng-container>
                        <div class="card-content">
                            <div [ngClass]="{'card-body':expanded}">
                               
                                <ngx-datatable #dataTable
                                               [rows]="rows" class="bootstrap core-bootstrap"
                                               [columnMode]="'force'"
                                               [footerHeight]="50"
                                               rowHeight="auto"
                                               [scrollbarH]="scrollbarH"
                                               [selectionType]="SelectionType.single"
                                               [count]="page.count"
                                               [externalPaging]="true"
                                               [offset]="page.offset"
                                               [externalSorting]="true"
                                               [limit]="page.limit"
                                               trackByProp="sid"
                                               (select)="onSelect($event)"
                                               (page)="setPage($event)"
                                               (sort)="onSort($event)"
                                               [sorts]="[{prop:'date_created',dir:'desc'}]">
        
        
                                    <ngx-datatable-row-detail [rowHeight]="75" #myDetailRow>
                                    <ng-template let-row="row" let-value="value" ngx-datatable-row-detail-template>
                                    <div class="row row-detail">
        
                                        <div class="row-item" [ngClass]="expanded?'col-md-1':'col-md-4'"  style="margin-right: 25px;">
                                           
                                            <div>
                                                {{ row.date_created | dateTz:'MM/DD/YYYY h:mm a' }}
                                            </div>
                                        </div>
        
                                        <div class="col-md-3 row-item" *ngIf="expanded">
                                        <div  class="contact">
                                            
                                                <div class="avatar mr-1 ml-0" [class]="randomDpBackground(row)">
                                                    <div class="avatar-content">{{ row.customer_contact_name | shortName}}
                                                    </div>
                                                </div>
                                            
                                            
                                            <div>
                                            <span class="contact-name">{{row.customer_contact_name?row.customer_contact_name:'Unknown'}}</span>
                                            <div *ngIf="row.customer_contact_title">{{row.customer_contact_title?row.customer_contact_title:'Unknown'}}</div>
                                            </div>
                                        
                                        </div>
                                        
                                        </div>
        
                                        <div class=" row-item" [ngClass]="expanded?'col-md-3':'col-md-7'">
                                            <div class="d-flex flex-row">
                                                <span class="number-label">From</span>
                                                <!-- <span>{{row.call_from | phoneNumber}}</span> -->

                                                <span *ngIf = "!pattern.test(row.call_from)">{{row.call_from}} </span>
                                                <span  *ngIf = "pattern.test(row.call_from)">{{row.call_from | phoneNumber}}</span>
                                            </div>
                                            <div class="d-flex flex-row">
                                                <span class="number-label">To</span>
                                                <!-- <span>{{row.call_to | phoneNumber}}
                                                    <img width="30" height="30" title="Call Back" (click)="callBack(row)"
                                                    src="assets/img/svg/callback.svg" style="margin-top: -5px; margin-left: 7px;cursor: pointer;" /></span> -->
                                                    <span *ngIf = "!pattern.test(row.call_to)">{{row.call_to}} 
                                                        <img width="30" height="30" title="Call Back" (click)="callBack(row)"
                                                        src="assets/img/svg/callback.svg" style="margin-top: -5px; margin-left: 7px;cursor: pointer;" />
                                                    </span>
    
                                                    <span *ngIf = "pattern.test(row.call_to)">{{row.call_to | phoneNumber}} 
                                                        <img width="30" height="30" title="Call Back" (click)="callBack(row)"
                                                        src="assets/img/svg/callback.svg" style="margin-top: -5px; margin-left: 7px;cursor: pointer;" />
                                                    </span>
                                            </div>
                                            <!-- <div class="d-flex flex-row">
                                                <span class="number-label">Name</span>
                                                <span>{{row.phone_number_name}}</span>
                                            </div> -->
                                        </div>
        
                                        <div class="col-md-2 row-item" *ngIf="expanded">
                                            <div>Duration:{{row.duration}}</div>
                                           
                                        </div>
        
                                        <!-- <div class="col-md-2 row-item" *ngIf="expanded">
                                            <div>Route:</div>
                                            <div>{{row.forward_number_name}}</div>
                                            <div class="bold" *ngIf="row.forward_number">
                                                <span *ngFor="let num of row.forward_number.split(',') as numList; let i = index">
                                                    <span>{{num | phoneNumber}}</span>
                                                    <span *ngIf="i !== numList.length-1">, </span>
                                                </span>
                                            </div>
                                        </div> -->
        
                                        <div class="col-md-1 row-item" *ngIf="expanded">
        
                                            <div class="badge"
                                                 [ngClass]="{'bg-light-success': row.real_status=='completed','bg-light-danger': row.real_status=='no-answer' }">
                                                {{ row.real_status }}
                                            </div>
                                            <div class="badge bg-secondary justify-content-around d-flex mt-1" placement="left" *ngIf="row.is_voicemail">
                                                Voicemail
                                            </div>
                                            <div placement="left" [ngbTooltip]="row.spam_reason?row.spam_reason:''" 
                                                class="badge bg-light-danger justify-content-around d-flex mt-1" *ngIf="row.is_spam">
                                                Spam
                                            </div>
                                           
                                        </div>
        
                                        
        
                                    </div>
                                    </ng-template>
                                    </ngx-datatable-row-detail>               
        
                                    <ngx-datatable-column  name="Direction" [sortable]="false" [minWidth]="145" [maxWidth]="125">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div style="align-items: center; display: flex;"><i  aria-hidden="true"><img src="../../../../assets/img/svg/outgoingcall.svg" alt=""></i>&nbsp;{{row.direction}}</div>
                                        <!-- <div *ngIf="!expanded">
                                            {{ row.created_on | dateTz:'MM/DD/YYYY h:mm a' }}
                                        </div> -->
                                    </ng-template>
                                    </ngx-datatable-column>
                                 
                                    
                                    <ngx-datatable-column [sortable]="false"  name="Customer" prop="customer_name" [minWidth]="100" *ngIf="expanded">
                                        <ng-template let-row="row" ngx-datatable-cell-template>   
                                        <div class="customer-name">
                                            <div class="badge badge-warning name-badge w-100">{{row.tenant_customer_name?row.tenant_customer_name:'Unknown'}}</div>
                                        </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    
                                    <ngx-datatable-column [sortable]="false"  name="Dialed By" prop="answered_by" [minWidth]="115">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div class="flexColDiv">
                                            <!-- <label>Answered By:</label> -->
                                            <div>{{row.call_from || '--'}}</div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column *ngIf="expanded" name="Recording" prop="recording_url_list"
                                                          [sortable]="false" [width]="250" [canAutoResize]="false">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div *ngIf="row.recording_url_list">
                                                <audio *ngIf="row.recording_url_list[0]" controls class="audio-control" #audioElement
                                                    (play)="audioPlayerPlay(audioElement)">
                                                <source [src]="row.recording_url_list[0]" type="audio/wav">
                                                not supported
                                                </audio>
                                                <small class="pull-right mr-1" *ngIf="row.recording_url_list.length>1">+{{row.recording_url_list.length-1}} {{row.recording_url_list.length-1 === 1 ? 'recording' : 'recordings'}}</small>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
        
                                    <ngx-datatable-column [sortable]="false" *ngIf="expanded && !isSuperAdmin" name="Suborg" prop="suborg_name" [minWidth]="100">
                                        <ng-template let-suborg="value" ngx-datatable-cell-template>
                                            
                                            <div>
                                                <div>{{suborg}}</div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                   
                                    <!-- <ngx-datatable-column *ngIf="expanded" name="Actions"  [sortable]="false" [minWidth]="90">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                <a (click)="$event.stopPropagation();readComments(row.sid)" class="text-info">Read Comments</a>
                                        </ng-template>
                                    </ngx-datatable-column>  -->
                                    <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg"
                                                          [sortable]="false">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div>{{row.customer_name}}</div>
                                            <div>Sub org : {{row.suborg_name}}</div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    
        
                                    <!-- <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false" [minWidth]="50"> 
                                        <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                            <div ngbDropdown container="body">
                                                <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                    <i class="ft-more-vertical text-primary"></i>
                                                </button>
                                                <div ngbDropdownMenu>
                                                    <button [routerLink]="['/users/groups',id,'edit']" class="w-100" ngbDropdownItem>
                                                        <i class="ft-edit mr-1"></i> Edit
                                                    </button>
                                                    <button class="w-100 bg-light-danger"  ngbDropdownItem>
                                                        <i class="fa fa-ban mr-1"></i> Add to Block
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column> -->
        
                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                     let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                            <div class="page-count" *ngIf="expanded">
                                                <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                            </div>
                                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                             [pagerRightArrowIcon]="'datatable-icon-right'"
                                                             [pagerPreviousIcon]="'datatable-icon-prev'"
                                                             [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                             [size]="pageSize"
                                                             [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                                             (change)="dataTable.onFooterPage($event)">
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>
                                </ngx-datatable>
                            </div>
                        </div>
                    </ng-container>         
           
          
            </div>

            <div class="parellax-detail" 
            parellaxTabSlider 
            [minWidth]="800"
            [width]="950"
            [target]="target"
            *ngIf="!expanded && selected">
            <div #target>
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">From {{selected.call_from | phoneNumber }}
                            <span #copyBtn ngxClipboard
                                      [cbContent]="selected?.call_from == null ? '':selected?.call_from.toString()"
                                      ngbTooltip="copied"
                                      triggers="click:blur"
                                      class="ft-copy copy-btn mr-1">
                            </span> 
                            to
                            {{selected?.call_to | phoneNumber }}
                            <span #copyBtn ngxClipboard
                                      [cbContent]="selected?.call_to"
                                      ngbTooltip="copied"
                                      triggers="click:blur"
                                      class="ft-copy copy-btn">
                            </span>
                        </h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div> 

                    </div>
                    <div class="row flex-column">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Call Info</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    

                                    <div class="group">
                                        <div class="row">
                                        <div class="col-md-6"> 
                                        <div class="group-header d-flex justify-content-between">
                                            Call Info
                                            <!-- <div class="status-toggle-btn" *ngIf="!selected.blocked_status&&hasPermission(permission.CREATE)">
                                                <label class="title">Block Number :</label>
                                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                    <input type="checkbox" [(ngModel)]="selected.blocked_status"
                                                           id="status" name="active"
                                                           class="custom-control-input"
                                                           (change)="addToBlockList()">
                                                    <label class="custom-control-label" for="status">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <button class="badge bg-light-danger" *ngIf="selected.blocked_status&&hasPermission(permission.DELETE)" (click)="unblockNumber()">
                                                Unblock
                                            </button> -->
                                        </div>
                                        <div class="group-body">
                                            <div class="ov-row" *ngIf="selected.sid">
                                                <label class="col-md-3 p-0 item-label">Call SID</label>
                                                <label class="item-label-value">{{selected.sid}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.status">
                                                <label class="col-md-3 p-0 item-label">Status</label>
                                                <label class="item-label-value">{{selected.real_status}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.direction">
                                                <label class="col-md-3 p-0 item-label">Direction</label>
                                                <label class="item-label-value">{{selected.direction}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.call_from">
                                                <label class="col-md-3 p-0 item-label">Call from</label>
                                                <label class="item-label-value" *ngIf = "pattern.test(selected.call_from.toString())">{{selected.call_from | phoneNumber }}</label>
                                                <label class="item-label-value" *ngIf = "!pattern.test(selected.call_from.toString())">{{selected.call_from}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.call_to">
                                                <label class="col-md-3 p-0 item-label">Call to</label>
                                                <label class="item-label-value" *ngIf = "pattern.test(selected.call_to.toString())">{{selected.call_to | phoneNumber }}</label>
                                                <label class="item-label-value" *ngIf = "!pattern.test(selected.call_to.toString())">{{selected.call_to}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.duration">
                                                <label class="col-md-3 p-0 item-label">Call duration</label>
                                                <label class="item-label-value">{{selected.duration}} Seconds</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.recording_url">
                                                <label class="col-md-3 p-0 item-label">Recording</label>
                                                <div class="d-flex flex-column">
                                                    <span *ngFor="let urls of selected.recording_url_list">
                                                        <audio *ngIf="urls" controls class="audio-control" #audioElement
                                                            (play)="audioPlayerPlay(audioElement)">
                                                        <source [src]="urls" type="audio/wav">
                                                        not supported
                                                    </audio>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.start_time">
                                                <label class="col-md-3 p-0 item-label">Start time</label>
                                                <label
                                                       class="item-label-value">{{selected.start_time | dateTz:'MM/DD/YYYY h:mm:ss a'}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.end_time">
                                                <label class="col-md-3 p-0 item-label">End time</label>
                                                <label
                                                       class="item-label-value">{{selected.end_time | dateTz:'MM/DD/YYYY h:mm:ss a'}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="isSuperAdmin">
                                                <label class="col-md-3 p-0 item-label">Tenant</label>
                                                <label class="item-label-value">{{selected.customer_name}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="isSuperAdmin">
                                                <label class="col-md-3 p-0 item-label">Sub Org</label>
                                                <label class="item-label-value">{{selected.suborg_name}}</label>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="col-md-6"> 
                                            <customer-info
                                            [tenant_customer_id]="selected.tenant_customer_id"
                                            [cust_name]="selected.tenant_customer_name"
                                            [cust_contact_name]="selected.customer_contact_name"
                                            [cust_contact_email]="selected.customer_contact_email"
                                            [cust_contact_phone]="selected.call_from"
                                            [location_name]="selected.customer_contact_location_name"
                                            [location_address]="selected.customer_contact_location_address"
                                            [location_city]="selected.customer_contact_location_city"
                                            [location_state]="selected.customer_contact_location_state"
                                            [location_zip]="selected.customer_contact_location_zip"

                                            [call_to_suborg_id]="selected.sub_org"
                                            ></customer-info>
                                        </div>
                                        </div>
                                    </div>




                                 
                                    
                                    <div class="row">
                                        <div class="col-12 col-md-10">
                                    <div class="group mt-3" *ngIf="childCalls | async as childCalls">
                                        <div class="group-header">
                                            Child Calls
                                        </div>
                                        
                                        <div class="group-body group-body-bordered">
                                            <ngx-datatable [rows]="childCalls" class="bootstrap core-bootstrap"
                                                           [columnMode]="'force'"
                                                           [headerHeight]="50"
                                                           [footerHeight]="50"
                                                           rowHeight="auto"
                                                           [scrollbarH]="scrollbarH"
                                                           [sorts]="[{prop:'date_created',dir:'desc'}]">
                                                <ngx-datatable-column name="Sid" prop="sid" [sortable]="false">
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Date" prop="date_created">
                                                    <ng-template let-value="value" ngx-datatable-cell-template>
                                                        {{ value | dateTz:'MM/DD/YYYY h:mm a' }}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="From/To" [sortable]="false" [width]="180">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        <div class="d-flex flex-row">
                                                            <span class="number-label">From</span>
                                                            <span>{{row.call_from | phoneNumber}}</span>
                                                        </div>
                                                        <div class="d-flex flex-row">
                                                            <span class="number-label">To</span>
                                                            <span>{{row.answered ? (row.call_to) : (row.call_to | phoneNumber)}}</span>
                                                            
                                                        </div>
                                                        <div class="d-flex flex-column" *ngIf="row.answered">
                                                            <span class="float-left">Answered :</span>       
                                                            <div class="media py-1">
                                                                <span class="avatar avatar-md mr-1 mytooltip tooltip-effect-1" [style.backgroundColor]="row.color">
                                                                <div *ngIf="row.answered" class="user-avatar-content tooltip-item">{{ row.answered | shortName }}</div>
                                                                <span class="tooltip-content clearfix">
                                                                    <div class="media py-1">
                                                                    <span class="avatar avatar-md mr-1" [style.backgroundColor]="row.color">
                                                                    <div *ngIf="row.answered" class="user-avatar-content ">{{ row.answered | shortName }}</div>
                                                                    </span>
                                                                </div>
                                                                <span class="tooltip-text">{{row.answered}} <br>
                                                                <a type="button" class="btn btn-dark btn-sm profile-btn" [routerLink]="['/users']" target="_blank">View profile</a>
                                                                </span>
                                                                </span>
                                                                </span>
                                                                <div class="media-body">
                                                                    <label class="mt-2 font-weight-bold">
                                                                        {{row.answered}} <span *ngIf="row.answered!=row.call_to">- {{row.call_to}}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Duration" prop="duration">
                                                    <ng-template let-duration="value" ngx-datatable-cell-template>
                                                        {{duration}} Sec
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Status" prop="real_status"
                                                                      [sortable]="false">
                                                    <ng-template let-status="value" ngx-datatable-cell-template>
                                                        <div class="badge"
                                                             [ngClass]="{'bg-light-success': status=='completed','bg-light-danger': status=='no-answer' }">
                                                            {{ status }}
                                                        </div>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </div>
                                    </div>

                                </div>
                                </div>
                               

                               
                                
                                </ng-template>
                            </li>

                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Comments</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div style="max-width: 500px;">
                                        <app-comments [uniqueId]="selected.sid" [moduleId]="22"></app-comments>
                                    </div>
                                </ng-template>
                            </li>

                            <li ngbNavItem [ngbNavItem]="2" *ngIf="selected">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Service Ticket</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <customer-info-st 
                                    #stTab
                                    [tabHeaderHeight]="tabHeaderHeight"
                                    [customerId]="selected.tenant_customer_id"
                
                                    >
                
                                    </customer-info-st>
                                </ng-template>
                            </li>
                            <li ngbNavItem [ngbNavItem]="4" *ngIf="selected">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Invoices</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                
                
                                    <customer-info-invoice
                
                                        [customerId]="selected.tenant_customer_id"
                                    >
                
                                    </customer-info-invoice>
                                    <!-- <dispatch-event-info-invoice
                                    [tabHeaderHeight]="tabHeaderHeight"
                                    [invoices]="eventDetails?.invoices"
                                    >
                
                                    </dispatch-event-info-invoice> -->
                
                                </ng-template>
                            </li>
                            <li ngbNavItem [ngbNavItem]="5" *ngIf="selected">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Sales Order</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <!-- <dispatch-event-info-so
                                    [tabHeaderHeight]="tabHeaderHeight"
                                    [salesOrders]="eventDetails?.sales_order"
                                    >
                
                                    </dispatch-event-info-so> -->
                
                                    <customer-info-so
                                    [customerId]="selected.tenant_customer_id" 
                                    >
                                        
                                    </customer-info-so>
                                </ng-template>
                            </li>
                            <li ngbNavItem [ngbNavItem]="6" *ngIf="selected">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Warranty</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <!-- <dispatch-event-info-warranty
                                    [tabHeaderHeight]="tabHeaderHeight"
                                    [warranty]="eventDetails?.warranty"
                                    >
                
                                    </dispatch-event-info-warranty> -->
                
                                    <customer-info-warranty
                                        [customerId]="selected.tenant_customer_id"
                                    >
                
                                    </customer-info-warranty>
                
                
                                </ng-template>
                            </li>
                            <li ngbNavItem [ngbNavItem]="7" *ngIf="selected">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Service Contracts</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <!-- <dispatch-event-info-sc
                                        [tabHeaderHeight]="tabHeaderHeight"
                                        [serviceContracts]="eventDetails?.service_contract"
                                    >
                
                                    </dispatch-event-info-sc> -->
                
                                    <customer-info-sc
                                    [customerId]="selected.tenant_customer_id"
                                    >
                
                                    </customer-info-sc>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>

            </div>
        </div>
    </div>

    <ng-template #commentsModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">Comments</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="max-width: 500px;">
                <app-comments [uniqueId]="sIdForComment" [moduleId]="22"></app-comments>
            </div>
        </div>
    </ng-template>
</section>
