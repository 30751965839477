<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter
                                     [defaultFilters]="defaults"
                                     [module]="module"
                                     [searchFilters]="search"
                                     [newButton]="UIPermissions.create"
                                     [newButtonUrl]="['/customers/new']"
                                     [expanded]="expanded"
                                     [columns]="columns"
                                     [import]="true"
                                     [importColumns]="importColumns"
                                     (importSubmit)="onImportSubmit($event)"
                                     (apply)="applyFilter($event)">

                        <ng-template filterControl [column]="'customer_type_id'" let-row="row">
                            <customer-type-select [addButton]="false" class="ng-select-sm" [(value)]="row.value">
                            </customer-type-select>
                        </ng-template>
                        <ng-template filterControl [column]="'customer_system_type_id'" let-row="row">
                            <customer-system-type-select [addButton]="false" class="ng-select-sm" [(value)]="row.value">
                            </customer-system-type-select>
                        </ng-template>
                        <ng-template filterControl [column]="'currency_id'" let-row="row">
                            <currency-select [addButton]="false" class="ng-select-sm" [(value)]="row.value">
                            </currency-select>
                        </ng-template>
                        <ng-template filterControl [column]="'industry_id'" let-row="row">
                            <industry-select [addButton]="false" class="ng-select-sm" [(value)]="row.value">
                            </industry-select>
                        </ng-template>
                        <ng-template filterControl [column]="'payment_terms_id'" let-row="row">
                            <payment-term-select [addButton]="false" class="ng-select-sm" [(value)]="row.value">
                            </payment-term-select>
                        </ng-template>
                        <ng-template filterControl [column]="'rep_id'" let-row="row">
                            <user-select class="ng-select-sm" [multiple]="false" [(value)]="row.value"></user-select>
                        </ng-template>
                        <ng-template filterControl [column]="'created_user'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows"
                                       [selectionType]="SelectionType.single" (select)="onSelect($event)"
                                       [sorts]="[{prop:'name',dir:'asc'}]"
                                       [count]="page.count" [externalPaging]="true" [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit" (page)="setPage($event)" (sort)="onSort($event)">
                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Type" prop="customer_type_name"></ngx-datatable-column>
                            <ngx-datatable-column name="System Type" prop="customer_system_type_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Industry" prop="industry_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Currency" prop="currency_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Payment Terms" prop="payment_terms_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="VIP" prop="is_vip">

                                <ng-template let-vip="value" ngx-datatable-cell-template>
                                    <i *ngIf="vip"
                                                       class="ft-check-circle text-success"></i>
                                     <i *ngIf="!vip"
                                                       class="ft-x-circle text-danger"></i>
                                </ng-template>

                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [width]="80"
                                                  [canAutoResize]="false"
                                                  [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'On Hold' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && (UIPermissions.delete || UIPermissions.edit)"
                                                  name="Action"
                                                  prop="id" [sortable]="false" [canAutoResize]="false" [width]="80">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <a [routerLink]="['/customers',id,'edit']" *ngIf="UIPermissions.edit"
                                               class="w-100"
                                               ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button (click)="deleteTenantCustomer(id)" *ngIf="UIPermissions.delete"
                                                    class="w-100 bg-light-danger" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                             let-pageSize="pageSize"
                                             let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                                     [pagerNextIcon]="'datatable-icon-skip'"
                                                     [page]="curPage" [size]="pageSize" [count]="rowCount"
                                                     [hidden]="!((rowCount / pageSize) > 1)"
                                                     (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">{{selected?.name}}</h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a [routerLink]="['/customers',selected.id,'edit']" *ngIf="UIPermissions.edit"
                               ngbTooltip="Edit"
                               class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button (click)="deleteTenantCustomer(selected.id)" *ngIf="UIPermissions.delete"
                                    class="btn btn-sm bg-light-danger ml-1">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row flex-column">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="tavActivId" class="nav-tabs">
                            <li ngbNavItem [ngbNavItem]="1">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <div class="group-header d-flex justify-content-between">
                                            Overview
                                            <div class="status-toggle-btn">
                                                <label class="title">Status :</label>
                                                <div
                                                     class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                    <input type="checkbox" [(ngModel)]="selected.is_active" id="status"
                                                           name="active"
                                                           class="custom-control-input"
                                                           [disabled]="selected.is_active ? !UIPermissions.deactivate:!UIPermissions.activate"
                                                           (change)="changeStatus()">
                                                    <label class="custom-control-label" for="status">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group-body">
                                            <div class="ov-row" *ngIf="selected.customer_type_name">
                                                <label class="col-md-3 p-0 item-label">Customer Type</label>
                                                <label class="item-label-value">{{selected.customer_type_name}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.customer_system_type_name">
                                                <label class="col-md-3 p-0 item-label">Customer System Type</label>
                                                <label
                                                       class="item-label-value">{{selected.customer_system_type_name}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.account_number">
                                                <label class="col-md-3 p-0 item-label">Account Number</label>
                                                <label class="item-label-value">{{selected.account_number}}</label>
                                            </div>
                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">Service Ticket Billable</label>
                                                <label class="item-label-value">
                                                    <i *ngIf="selected.is_service_ticket_billable"
                                                       class="ft-check-circle text-success"></i>
                                                    <i *ngIf="!selected.is_service_ticket_billable"
                                                       class="ft-x-circle text-danger"></i>
                                                </label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.industry_name">
                                                <label class="col-md-3 p-0 item-label">Industry</label>
                                                <label class="item-label-value">{{selected.industry_name}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.currency_name">
                                                <label class="col-md-3 p-0 item-label">Currency</label>
                                                <label class="item-label-value">{{selected.currency_name}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.tax_value">
                                                <label class="col-md-3 p-0 item-label">Tax</label>
                                                <label class="item-label-value">{{selected.tax_value}} %</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected.payment_terms_name">
                                                <label class="col-md-3 p-0 item-label">Payment Terms</label>
                                                <label class="item-label-value">{{selected.payment_terms_name}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.created_on">
                                                <label class="col-md-3 p-0 item-label">Created On</label>
                                                <label class="item-label-value">{{selected.created_on |
                                                        date:'MM/dd/YYYY'}}</label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.created_user_name">
                                                <label class="col-md-3 p-0 item-label">Created By</label>
                                                <label class="item-label-value">
                                                    {{(selected.quickbook_id)? 'Quickbook Admin':selected.created_user_name}}
                                                </label>
                                            </div>
                                            <div class="ov-row" *ngIf="customerReps | async as custReps">
                                                <label class="col-md-3 p-0 item-label">Account Rep</label>
                                                <label class="item-label-value text-info">
                                                    {{custReps}}
                                                </label>
                                            </div>

                                            <div class="ov-row">
                                                <label class="col-md-3 p-0 item-label">VIP Customer</label>
                                                <label class="item-label-value">
                                                    <i *ngIf="selected.is_vip"
                                                       class="ft-check-circle text-success"></i>
                                                    <i *ngIf="!selected.is_vip"
                                                       class="ft-x-circle text-danger"></i>
                                                </label>
                                            </div>

                                            <div class="ov-row" *ngIf="selected.vip_note">
                                                <label class="col-md-3 p-0 item-label">VIP Note</label>
                                                <label class="item-label-value">
                                                    {{selected.vip_note}}
                                                </label>
                                            </div>

                                            <div class="ov-row" *ngIf="customerLicenses | async as custlicenses">
                                                <label class="col-md-3 p-0 item-label">License Numbers</label>
                                                <label class="item-label-value text-info">
                                                    {{custlicenses}}
                                                </label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.credit_limit">
                                                <label class="col-md-3 p-0 item-label">Credit Limit</label>
                                                <label class="item-label-value">
                                                    ${{selected.credit_limit}}
                                                </label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.credit_balance">
                                                <label class="col-md-3 p-0 item-label">Credit In Use</label>
                                                <label class="item-label-value">
                                                    ${{selected.credit_balance}}
                                                </label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.credit_enforced">
                                                <label class="col-md-3 p-0 item-label">Enforced</label>
                                                <label class="item-label-value">
                                                    {{selected.credit_enforced}}
                                                </label>
                                            </div>
                                            <div class="ov-row" *ngIf="selected?.website">
                                                <label class="col-md-3 p-0 item-label">Website</label>
                                                <a [href]="'https://'+selected.website" target="_blank">
                                                    {{selected.website}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group mt-3"
                                         *ngIf="hasPermissionAny(Resources.CUSTOMER_LOCATIONS,[Permissions.VIEW]) || hasPermission(Permissions.GLOBAL)">
                                        <div class="group-header">
                                            Locations
                                        </div>
                                        <div class="group-body col-12 p-0">
                                            <customer-locations [tenantCustId]="selected.id" [suborg_id]="selected.suborg"></customer-locations>
                                        </div>
                                    </div>
                                    <div class="group mt-3"
                                         *ngIf="hasPermissionAny(Resources.CUSTOMER_CONTACTS,[Permissions.VIEW]) || hasPermission(Permissions.GLOBAL)">
                                        <div class="group-header">
                                            Contacts
                                        </div>
                                        <div class="group-body col-12 p-0">
                                            <customer-contacts #custContact [tenantCustId]="selected.id" [showAlphabeticFilter]="false"
                                                               [contactId]="contactId" [suborg_id]="selected.suborg">
                                            </customer-contacts>
                                        </div>
                                    </div>
                                    <ng-container
                                                  *ngIf="selected && selected.additional_fields && selected.additional_fields | customfields as custFields">
                                        <div class="group mt-3 additional-info"
                                             *ngIf="custFields && custFields.length > 0">
                                            <div class="group-header">
                                                Additional Info
                                            </div>
                                            <div class="col-12 p-0">
                                                <ng-container *ngFor="let field of custFields">
                                                    <div class="ov-row">
                                                        <label class="col-md-3 p-0 item-label">{{field.name}}</label>
                                                        <label class="item-label-value">{{field.value || 'NIL'}}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </li>
                            <li ngbNavItem [ngbNavItem]="2">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">History</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="history-container" *ngIf="selected">
                                        <history-view [module]="'CUSTOMERS'" [uniqueId]="selected.id"></history-view>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem [ngbNavItem]="3" *ngIf="hasPermissionAny(Resources.CUSTOMERS_IMPORTANT_MESSAGES,[Permissions.VIEW])">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Important messages</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="history-container" *ngIf="selected">
                                        <!-- <history-view [module]="module" [uniqueId]="selected.id"></history-view> -->
                                        <important-messages
                                        [customer]="selected">

                                        </important-messages>
                                    </div>
                                </ng-template>
                            </li>

                            <li ngbNavItem [ngbNavItem]="4">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Payment Details</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="history-container" *ngIf="selected">
                                        <customers-payment-details-tab [customerId]="selected.id" [customerSuborg]="selected.suborg"></customers-payment-details-tab>
                                    </div>
                                </ng-template>
                            </li>

                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
