import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ResourceAccessService } from '../services/resource-access.service';

/**
 * Allow routes wwhen current ser is super admin or admin
 */

@Injectable({
    providedIn: 'root'
})
export class SUAdminRouteGuard implements CanActivate {

    constructor(private router: Router, private ra: ResourceAccessService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.ra.isSuperUser || this.ra.isAdmin) {
            return true;
        }
        else {
            this.router.navigate(['access-denied']);
            return false;
        }
    }
}
