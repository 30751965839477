<section class="service-ticket-template">
    <div>
        <div class="d-flex flex-row mt-2 justify-content-between">
            <h5 class="item-title m-0 text-left">{{item?.unique_number}}<span *ngIf="item?.from_unique_number"> - {{item?.from_unique_number}}</span></h5>
            <div class="d-flex justify-content-end align-items-start">
                <button class="btn btn-sm btn-primary mr-2" (click)="download(true)">
                    <i class="fa ft-download"></i>Download
                </button>
                <button class="btn btn-sm btn-primary mr-2" (click)="download(false)">
                    <i class="fa ft-printer"></i>Print
                </button>
                <button *ngIf="UIPermissions.edit && showEditButton" [routerLink]="['/service-tickets', item.id, 'edit']"
                 class="btn btn-sm bg-light-secondary">
                    <i class="ft-edit"></i> Edit
                </button>
                <button *ngIf="UIPermissions.delete && showDeleteButton" class="btn btn-sm bg-light-danger ml-1"
                (click)="deleteSC(item)">
                    <i class="ft-trash-2 mr-1"></i> Delete
                </button>
                <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                    <i class="ft-x"></i>
                </button>
            </div>
        </div>

        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Overview</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div class="group mt-2">
                        <div class="container progress-steps">
                            <div class="arrow-steps clearfix" style="justify-content: center;">
                                <div class="step" [ngClass]="{'active':item.status>=1}">
                                    <strong>Created </strong>
                                    <small>{{item.created_on|date:'MM/dd/YYYY h:mm a'}}</small>
                                </div>
                                <div class="step" [ngClass]="{'active':item.status>=2,'inactive':item.status<2 }">
                                    <strong>In Progress</strong>
                                </div>
                                <div class="step" [ngClass]="{'active':item.status>=3,'inactive':item.status<3 }">
                                    <strong>Waiting for Customer</strong>
                                </div>
                                <div class="step" [ngClass]="{'active':item.status>=4,'inactive':item.status<4 }">
                                    <strong>Escalated To Manager</strong>
                                </div>
                                <div class="step" [ngClass]="{'active':item.status>=5,'inactive':item.status<5 }">
                                    <strong>Closed Tickets</strong>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                            <div class="card pdf-template-card">
                                <div class="status-history-container">
                                    <div class="ribbon3" [class]="StatusMap[item.status].class">
                                        <span>{{StatusMap[item.status].displayName}} </span>
                                    </div>
                                    <div class="history-container" *ngIf="item.status>=3">
                                        <div class="view-status vs-viewed" *ngIf="item.client_viewed">
                                            <div>
                                                <i class="ft-eye"></i>&nbsp; <span>Last Viewed :</span>
                                                <span style="font-weight: 500;"> {{item.client_viewed_date | date:'MM/dd/YYYY, h:mm a'}}</span>
                                            </div>
                                            <div style="margin-left: 4em;">
                                                No of times viewed : <span style="font-weight: 500;"> {{email_view_count}} </span>
                                            </div>
                                            <button (click)="viewHistory = !viewHistory">
                                                <i class="ft-chevron-down" *ngIf="!viewHistory"></i>
                                                <i class="ft-chevron-up" *ngIf="viewHistory"></i>
                                            </button>
                                        </div>
                                        <div class="view-status vs-notviewed" *ngIf="!item.client_viewed">
                                            <i class="ft-eye-off"></i>&nbsp; <span >Not viewed</span>
                                        </div>
                                    </div>
                                    <div class="button-div btn-group me-3" *ngIf="showSendToButton" disableIfAllsuborg>
                                        <button type="button" (click)="checkSesVerified(service_ticket_email_modal)" class="btn btn-sm bg-light-info">Send to Customer</button>
                                        <div class="btn-group d-inline-block float_right" ngbDropdown role="group" aria-label="Button group with nested dropdown" placement="bottom-right">
                                            <button class="btn bg-light-info btn-sm dropdown-toggle-split" ngbDropdownToggle></button>
                                            <div class="dropdown-menu" ngbDropdownMenu>
                                                <button ngbDropdownItem *ngFor="let key of dropdownStatus " [disabled]="StatusMap[item.status].value == key.id" (click)="changeSTStatus(key)" style="width: 100%;">
                                                    Mark as {{StatusMap[key.id].displayName}}
                                                </button>
                                                <button ngbDropdownItem [disabled]="sections.length==0 || StatusMap[item.status].value == STStatus.Closed" (click)="convertToInvoice()" style="width: 100%;">
                                                    Convert To Invoice
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="viewed-history" *ngIf="item.client_viewed && viewHistory">
                                    <div *ngFor="let history of email_history" style="margin: 0.5em; position: relative;margin-top: 0;">
                                        <span class="view-count badge badge-warning">{{history.dcount}}</span>
                                        <div class="view-date-cont">
                                            <div class="view-date">
                                                <div>{{history.created_on__date | date:'MMM' | uppercase}} </div>
                                                <div class="view-date-day">{{history.created_on__date | date:'dd'}}</div>
                                            </div>
                                        </div>
                                        <!-- <div class="view-date-time">3.30 am</div> -->
                                    </div>
                                </div>
                                <div class="card-content p-3" [class.no-event] = "isReadOnly" style="padding-top: 0 !important;margin-top: 0 !important;">
                                    <div id="service-ticket-template" class="card-body p-0" id="pdfTable" #pdfTable>
                                        <div id="st-company-details" class="row">
                                            <div class="col-12 text-center">
                                                <h4 class="text-center text-bold-700"style="margin:auto;text-transform: uppercase; font-size: 16px; margin-bottom: 35px;">{{item.title}}</h4>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="row">
                                                    <div class="col-12 sub-org">
                                                            <div class="media">
                                                            <img *ngIf="item.suborg_primary_image" width="240" height="108" [src]="fileUrl + item.suborg_primary_image" />
                                                            <img *ngIf="!item.suborg_primary_image" src="assets/img/svg/product-avatar1.svg" alt="company logo" width="80" height="80">
                                                            <div class="media-body ml-4">
                                                                <ul class="m-0 list-unstyled">
                                                                <li class="text-bold-800">{{item.suborg_name}}</li>
                                                                <li>{{item.suborg_address}}</li>
                                                                <li>{{item.suborg_city}} {{item.suborg_zip}} {{(item.suborg_zip!=null||item.suborg_city!=null)?',':''}} {{item.suborg_state}}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12"></div>
                                            <div class="col-md-4 col-12 text-right">
                                                <div class="step">
                                                </div>
                                                <h2 class="primary text-uppercase">
                                                    <span style="color: #370D7E;">Service</span>
                                                    <span style="color: #D52095;" class="ml-1">Ticket</span>
                                                </h2>
                                                <div class="d-flex justify-content-between">
                                                    <div class="total-head">
                                                        Billable Totals
                                                    </div>
                                                    <div class="mb-0">{{item.unique_number}}</div>
                                                </div>
                                                <div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>Service Ticket Total</span>
                                                        <span class="font-medium-2 text-bold-700"> {{item.total_price|currency}}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>Invoiced Total</span>
                                                        <span class="font-medium-2 text-bold-700"> {{item.invoiced_total|currency}}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>Service Ticket Balance</span>
                                                        <span class="font-medium-2 text-bold-700"> {{item.balance_due|currency}}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>Material Total</span>
                                                        <span class="font-medium-2 text-bold-700"> {{item.material_total|currency}}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>Labor Total</span>
                                                        <span class="font-medium-2 text-bold-700"> {{item.timesheet_total|currency}}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>Tax Amount</span>
                                                        <span class="font-medium-2 text-bold-700"> {{item.tax_amount | currency}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="st-customer-details" class="row mt-2 p-1 pl-3" style="background-color: #edf2ff;">
                                            <div class="col-md-6 col-12">
                                                <div class="col-md-12">
                                                    <div class="col-12 text-left">
                                                        <div class="text-bold-600 mb-1 primary">Customer</div>
                                                    </div>
                                                    <div class="col-md-12 col-12">
                                                        <div>
                                                            <div class="d-flex"><span class="col-md-6 st-item-label p-0 text-left">Name</span><span class="text-bold-500">{{item.tenant_customer_name}}</span></div>
                                                            <div class="d-flex"><span class="col-md-6 st-item-label p-0 text-left">Contact Name</span><span class="text-bold-500">{{item.tenant_customer_contact_name}}</span></div>
                                                            <div class="d-flex"><span class="col-md-6 st-item-label p-0 text-left">Location Name</span><span class="text-bold-500">{{item.tenant_customer_location_name}}</span></div>
                                                            <div class="d-flex"><span class="col-md-6 st-item-label p-0 text-left">Location Address</span><span class="text-bold-500">{{item.tenant_customer_location_address}}</span></div>
                                                            <div class="d-flex" *ngIf="item.tenant_customer_location_city||item.tenant_customer_location_state||item.tenant_customer_location_zip">
                                                                <span class="col-md-6 p-0 text-left"></span>
                                                                <span class="text-bold-500">
                                                                    {{item.tenant_customer_location_city}} {{item.tenant_customer_location_state}},
                                                                    {{item.tenant_customer_location_zip}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-12 p-0">
                                                <div>
                                                    <img *ngIf="item.tenant_customer_is_vip" src="assets/img/svg/vip.svg">
                                                </div>
                                            </div>
                                            <div class="col-md-5 col-12">
                                                <div class="st-props">
                                                    <div class="st-item d-flex" *ngIf="item.created_by">
                                                        <div class="col-md-6 st-item-label p-0 text-left">Created By</div>
                                                        <div>{{item.created_by}}</div>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.created_on">
                                                        <span class="col-md-6 st-item-label p-0 text-left">Created On</span>
                                                        <span> {{item.created_on |date:'mediumDate'}}</span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.reason_for_call_name">
                                                        <span class="col-md-6 st-item-label p-0 text-left">Reason For Service</span>
                                                        <span class="badge bg-light-info mr-1 mb-1"> {{item.reason_for_call_name}}</span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.priority_name">
                                                        <span class="col-md-6 st-item-label p-0 text-left">Priority</span>
                                                        <span [style.color]="item.color">{{item.priority_name}}</span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.escalation_period_name">
                                                        <span class="col-md-6 st-item-label p-0 text-left">Escalation Period</span>
                                                        <span> {{item.escalation_period_name}}</span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.next_action_name">
                                                        <span class="col-md-6 st-item-label p-0 text-left">Next Action</span>
                                                        <span>{{item.next_action_name}}</span>
                                                    </div>
                                                    <div class="d-flex" *ngIf="item.expected_service_start_date">
                                                        <span class="col-md-6 st-item-label p-0 text-left">Expected Service Date</span>
                                                        <span>{{item.expected_service_start_date | date:'mediumDate'}}</span>
                                                    </div>
                                                    <div class="d-flex">
                                                        <span class="col-md-6 st-item-label p-0 text-left">Tax</span>
                                                        <span>{{item.tax}}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="st-materials-info" class="row">
                                            <st-materials-grid class="col-12 p-0"
                                            [st_id]="item.id"
                                            [suborg_id]="item.suborg"
                                            [tax]="item.tax"
                                            (onDataLoaded)="setMaterials($event)"
                                            (onUpdate)="onUpdateMaterials()">
                                            </st-materials-grid>
                                        </div>
                                        <div id="st-timesheet-info" class="row">
                                            <time-sheet-grid #tscomponent class="col-12 p-0"
                                                [serviceTicketId]="item.id"
                                                [suborg_id]="item.suborg"
                                                [tenantCustomerId]="item.tenant_customer"
                                                [tenantCustomerLocationId]="item.tenant_customer_location"
                                                [tenantCustomerContactId]="item.tenant_customer_contact"
                                                (onDataLoaded)="setTimesheets($event)"
                                                (onUpdate)="onUpdateMaterials()">
                                            </time-sheet-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center" (click)="testfunc()">
                    <span class="d-none d-sm-block">Comments</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div style="max-width: 500px;">
                        <!-- <so-comments [soId]="item.id"></so-comments> -->
                        <app-comments [uniqueId]="item.id" [moduleId]="7" [suborgId]="item.suborg"></app-comments>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">History</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div style="max-width: 500px;">
                        <history-view [module]="'SERVICE_TICKET'" [uniqueId]="item.id"></history-view>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center" *ngIf="st_task_count">
                    <span class="d-none d-sm-block">Task List<span class="badge badge-light" >{{st_pending_task_count}}</span></span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd" *ngIf="st_task_count">
                    <div class="group">
                        <div class="group-header d-flex justify-content-between">{{item.task_name}}</div>
                        <st-task-list-form (setStTaskCount)="setStTaskCount()" [stId]="item.id"></st-task-list-form>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Invoices</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div>
                        <customer-info-invoice [uniqueId]="item.id" [customerId]="item.tenant_customer"
                        [showNewButton]="false" [filter]="filter"></customer-info-invoice>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem >
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Call History</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div *ngIf="item.call_sid">
                        <st-callhistory [stId]="item.id"></st-callhistory>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" ></div>
    </div>
</section>
<ng-template #service_ticket_email_modal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">Emai To : {{item.tenant_customer_name}}</h5>
            <div class="float-right">
                <button type="submit" class="btn btn-success mr-2" (click)="sendMail()">Send</button>
                <button type="button" class="btn btn-light" (click)="d('Cross click')">Cancel</button>
            </div>
        </div>
        <div class="modal-body sales-order-email-modal">
            <form>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label" for="From">From</label>
                    <div class="col-md-10 ">
                        <input type="email" name="from" [(ngModel)]="email.from" class="form-control" id="From" placeholder="Enter email">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label" for="to">Send To</label>
                    <div class="col-md-10">
                        <input type="email" name="to" [(ngModel)]="email.to" class="form-control" id="to" placeholder="Enter email">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label" for="cc">Cc</label>
                    <div class="col-md-10">
                        <input type="email" name="cc" [(ngModel)]="email.cc" class="form-control" id="cc" placeholder="Enter email">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label" for="subject">Subject</label>
                    <div class="col-md-10">
                        <input type="text" name="subject" [(ngModel)]="email.subject" class="form-control" id="subject" placeholder="Enter subject">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2"></div>
                    <div class="col-md-10">
                        <div [ngxSummernoteView]="email.body" [ngxSummernote]="config" name="body" [(ngModel)]="email.body"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-10">
                        <div class=" float-right">
                            <button type="submit" class="btn btn-success mr-2" (click)="sendMail()">Send</button>
                            <button type="button" class="btn btn-light" (click)="d('Cross click')">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
</ng-template>
