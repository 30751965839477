import { WHBaseModel } from "src/app/core/models/common.models";

export class Supplier extends WHBaseModel{

    id: number;

    supplier_name: string;

    address: string;

    account_number: string;

    quickbook_id: string;
}

export class ItemSupplierMap {

    public id: number;

    public supplier_part_number: string;

    public price: number;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public customer: number;

    public is_active: boolean;

    public replaced_by: string;

    public items: number;

    public supplier: number;

    public is_default: boolean = false;

    supplier_name: string;
}
