
export class Industry {

    public id: number;

    public name: string;

    public description: string;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public is_delete: boolean;

    public is_active: boolean;

    public customer: number;

    public suborg:number;
}
