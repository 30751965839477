<section class="dashlet-container d-flex flex-row flex-wrap mb-2" *ngIf="dashlet">
    <div class="status-tile" (click)="setFilter('status','8')">
        <div class="card m-0 h-100 new_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.New || 0}}</h3>
                            <span>New</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','1')">
        <div class="card m-0 h-100 draft_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Draft || 0}}</h3>
                            <span>Draft</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','2')">
        <div class="card m-0 h-100 send_to_customer_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Sent || 0}}</h3>
                            <span>Sent to Customer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','3')">
        <div class="card m-0 h-100 rejected_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Unpaid || 0}}</h3>
                            <span>Unpaid</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','4')">
        <div class="card m-0 h-100 approved_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Paid_in_Full || 0}}</h3>
                            <span>Paid in Full</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','5')">
        <div class="card m-0 h-100 gradient-mint">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Partially_Paid || 0}}</h3>
                            <span>Partially Paid</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','6')">
        <div class="card m-0 h-100 gradient-king-yna">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Void || 0}}</h3>
                            <span>Void</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','7')">
        <div class="card m-0 h-100 write_off_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Write_Off || 0}}</h3>
                            <span>Write Off</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('add_on_status','1')">
        <div class="card m-0 h-100 past_due_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Past_Due || 0}}</h3>
                            <span>Past Due</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
