import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataImportMeta } from 'src/app/core/models/common.models';
import { FilesService } from 'src/app/core/services/files.service';
import { SubSink } from 'subsink';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss']
})
export class DataImportComponent implements OnInit,OnDestroy {

  @Input() columns: DataImportMeta[];

  @Output() submit = new EventEmitter<any>();

  @Output() cancel = new EventEmitter();

  public file: any;

  public ext: any;

  public csvColumns: any;

  public tempcsvColumns: any;

  private subs: SubSink = new SubSink();

  option: number = 0;

  profiles: string[];

  @Input() module: ModuleConstants;

  public profile: any;

  public selectedProfile: any

  public profile_name:any

  public profileId: number = 0;

  public isCustom: boolean;


  constructor(
    private fileService: FilesService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    this.isCustom = false;
    this.columns.map(this.onMapfunction);
    console.log("init")
  }

  importFileOnChange(event: any) {
    if (event.target.files.length) {
      this.csvColumns = null;
      this.columns.map(this.onMapfunction);
      this.selectedProfile = null;
      this.profile = null;
      this.profile_name = null;
      this.profileId = 0;
      this.isCustom = false;
      this.file = event.target.files[0];
      this.ext = this.file.name.split('.').pop()
      if(this.ext !== "csv")
        this.getImportColumnMeta()
    }
  }

  changedValue(event: any) {
    this.option = event.currentTarget.value | 0
    this.getImportColumnMeta()
  }

  getImportColumnMeta() {
    this.subs.add(this.fileService.getCsvColumns(this.file, this.option, this.module).subscribe(resp => {
      this.csvColumns = resp.column;
      if(resp.profile.length > 0 )
        this.profile = resp.profile;
      else
        this.isCustom = true;
      this.cdr.markForCheck();
    }))
  }

  onChangeNgSelect(event: any){
    this.isCustom = false;
    this.columns.map(this.onMapfunction);
    this.selectedProfile = null;
    this.profile_name = null;
    this.profileId = 0;
    if(event)
    {
      this.isCustom = true;
      this.profileId = event.id
      this.profile_name = event.name;
      let hh =  JSON.parse(event.details)
      try{
        this.columns.forEach(element => {
          hh.forEach(item => {
            if (element.table_name == item.table_name && element.actual_column_name == item.actual_column_name){
              element.excel_column_name = item.excel_column_name;
              element.excel_column_index = this.csvColumns.find(item => item.column_name == element.excel_column_name).column_index;
            }
          });
        });
      }
      catch (Error)
      {
        this.toastr.error(`Profile and file mismatch`)
      }
    }
  }

  onMapfunction( element ){
    element.excel_column_name=undefined;
    element.excel_column_index=undefined;
  }

  onSubmit() {
    let maps = this.columns.filter(item => item.excel_column_name != undefined && item.excel_column_name != null);
    if (maps.length) {
      this.submit.emit({
        file: this.file,
        columns_map: maps,
        option: this.option
      })
    }
  }

  onSaveButtonClicked(){
    let maps = this.columns.filter(item => item.excel_column_name != undefined && item.excel_column_name != null);
    if(this.profileId > 0){
      let obj ={}
      obj["name"] = this.profile_name
      obj["details"] = JSON.stringify(maps)
      obj["module"] = this.module
      this.subs.add(this.fileService.updateProfile(this.profileId, obj).subscribe(resp => {
        this.toastr.success(`Profile updated successfully`)
      }))
    }
    else{
      let obj ={}
      obj["name"] = this.profile_name
      obj["details"] = JSON.stringify(maps)
      obj["module"] = this.module
      this.subs.add(this.fileService.createProfile(obj).subscribe(resp => {
        this.toastr.success(`Profile created successfully`)
        this.profileId = resp.id;
        this.profile_name = resp.name;
      }))
    }

  }

  onDeleteButtonClicked(){
    this.subs.add(this.fileService.deleteProfile(this.profileId).subscribe(resp => {
      this.toastr.success(`Profile deleted successfully`)
      this.cancel?.emit();
    }))
  }

  onCustomButtonClicked(){
    this.isCustom = true;
    this.columns.map(this.onMapfunction);
    this.selectedProfile = null;
    this.profile_name = null;
    this.profileId = 0;
  }

  getfileName(str){
    let str1 = str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1);
    return "Import_"+str1+"_Template.xlsx";
  }

  onTemplateBtnClicked(){
    let filename = this.getfileName(ModuleConstants[this.module].toString())
    this.subs.add(this.fileService.downloadTemplate(JSON.stringify(this.columns)).subscribe(resp => {
      var blob = new Blob([resp], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }))
  }

  onCancelButtonClicked () {
    this.cancel?.emit();
  }

  onChangeColumnNgselect(event: any , col: DataImportMeta){
    console.log(event)
    console.log(col)
    col.excel_column_index = event.column_index
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
