<section *ngIf="model">
    <div class="card col-12 col-md-6 col-sm-8">
        <div class="card-header">
            <h4 class="card-title">{{sipId ? 'Edit SIP Endpoint' : 'Add SIP Endpoint'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && onSubmit()" novalidate>
                    <div class="row">
                        <div class="col-md-6" *ngIf="isSuperAdmin">
                            <div class="form-group">
                                <label class="val-required">Tenant</label>
                                <app-customer-select [(value)]="model.customer" [readonly]="sipId && sipId.length > 0"></app-customer-select>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="isSuperAdmin || allSuborgs">
                            <div class="form-group">
                                <label class="val-required">Suborg</label>
                                <suborg-select [tenantId]="model.customer" [readonly]="!model.customer || (sipId && sipId.length > 0)" [(value)]="model.sub_org">
                                </suborg-select>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <label>Associated User</label>
                        <user-select [(value)]="model.associated_user"></user-select>
                    </div> -->
                    <div class="form-group">
                        <label class="val-required">USERNAME</label>
                        <input (keypress)="onlyNumberKey($event)" maxlength="6" type="text" name="username" mask="A*" class="form-control" [(ngModel)]="model.username" required>
                        <small class="danger mt-1" *ngIf="model.username && nameError">Username already exists</small>
                    </div>
                    <div class="form-group" *ngIf="sipId && !model.is_sync && !showPasswordField">
                        <input type="checkbox" class="form-control" name="chpw" [(ngModel)]="showPasswordField" class="pb-5" id="chpw">
                        <label class="ml-1" for="chpw" style="vertical-align: middle;">Change Password</label>
                    </div>
                    <div class="form-group" *ngIf="showPasswordField">
                        <label class="val-required">PASSWORD</label>
                        <input type="password" name="password" class="form-control" [(ngModel)]="model.password" required>
                    </div>
                    <div class="form-group">
                        <label>Caller Id</label>
                        <input type="text" name="caller_id"  class="form-control" [(ngModel)]="model.caller_id">
                    </div>
            <!-- assigning user in sip -->
                    <div class="form-group">
                        <label class="val-required">Assign user</label>
                        <user-select (valueChange)="model.associated_user=$event" [value]="model.associated_user"></user-select>
                    </div>

                    <div class="form-group">
                        <label class="val-required">Send As</label>
                        <did-select [(value)]="model.send_as" bindValue="did" [suborgId]="model.sub_org"></did-select>
                    </div>
                    <div class="form-group">
                        <label class="val-required">Call Handler</label>
                        <ng-select name="call_handler" [(ngModel)]="model.call_handler">
                            <ng-option value="default">Default</ng-option>
                            <ng-option value="call_center">Call Center</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label class="val-required">Encryption</label>
                        <ng-select name="encryption" [(ngModel)]="model.encryption">
                            <ng-option value="default">Use Default Setting</ng-option>
                            <ng-option value="required">Required</ng-option>
                            <ng-option value="optional">Optional</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label class="val-required">DEFAULT CODECS</label>
                        <div class="d-flex flex-row my-1">
                            <div class="radio radio-sm mr-2">
                                <input type="radio" value="default" [(ngModel)]="codec" name="codec_radio" id="codec_radio">
                                <label for="codec_radio">Default Codecs</label>
                            </div>
                            <div class="radio radio-sm">
                                <input type="radio" value="custom" [(ngModel)]="codec" name="codec_radio" id="codec_radio1">
                                <label for="codec_radio1">Custom Codecs</label>
                            </div>
                        </div>
                        <ng-select *ngIf="codec == 'custom'" name="codecs" [(ngModel)]="model.codecs"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            placeholder="Select codecs">
                            <ng-option value="OPUS">OPUS</ng-option>
                            <ng-option value="G722">G722</ng-option>
                            <ng-option value="PCMU">PCMU</ng-option>
                            <ng-option value="PCMA">PCMA</ng-option>
                            <ng-option value="G729">G729</ng-option>
                            <ng-option value="VP8">VP8</ng-option>
                            <ng-option value="H264">H264</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label class="val-required">DEFAULT CIPHERS</label>
                        <div class="d-flex flex-row my-1">
                            <div class="radio radio-sm mr-2">
                                <input type="radio" value="default" [(ngModel)]="cipher" name="cipher_radio" id="cipher_radio">
                                <label for="cipher_radio">Default Ciphers</label>
                            </div>
                            <div class="radio radio-sm">
                                <input type="radio" value="custom" [(ngModel)]="cipher" name="cipher_radio" id="cipher_radio1">
                                <label for="cipher_radio1">Custom Ciphers</label>
                            </div>
                        </div>
                        <ng-select *ngIf="cipher == 'custom'" name="ciphers" [(ngModel)]="model.ciphers"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            placeholder="Select ciphers">
                            <ng-option value="AEAD_AES_256_GCM_8">AEAD_AES_256_GCM_8</ng-option>
                            <ng-option value="AES_256_CM_HMAC_SHA1_80">AES_256_CM_HMAC_SHA1_80</ng-option>
                            <ng-option value="AES_CM_128_HMAC_SHA1_80">AES_CM_128_HMAC_SHA1_80</ng-option>
                            <ng-option value="AES_256_CM_HMAC_SHA1_32">AES_256_CM_HMAC_SHA1_32</ng-option>
                            <ng-option value="AES_CM_128_HMAC_SHA1_32">AES_CM_128_HMAC_SHA1_32</ng-option>
                        </ng-select>
                    </div>
                    <div class="d-flex flex-sm-row flex-column justify-content-end mt-3">
                        <button type="submit" [disabled]="!addForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                            {{ sipId ? 'Save Changes' : 'Save'}}
                        </button>
                        <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
