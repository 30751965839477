import { Observable, Subscription } from 'rxjs';
import { WareHouseLocation } from './../../../models/warehouse.models';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { WareHouse } from '../../../models/warehouse.models';
import { WarehouseService } from '../../../services/warehouse.service';
import { map, catchError } from 'rxjs/operators';

@Component({
  selector: 'warehouse-select',
  templateUrl: './warehouse-select.component.html',
  styleUrls: ['./warehouse-select.component.scss']
})
export class WarehouseSelectComponent implements OnInit, OnChanges, OnDestroy {

  @Input() orientation: string = 'hor';

  @Input() classes: string;

  @Input() warehouse: number;

  @Output() warehouseChange = new EventEmitter<number>();

  @Input() location: number;

  @Output() locationChange = new EventEmitter<number>();

  public get isHor() { return this.orientation === 'hor' }

  public warehouses: Observable<WareHouse[]>;

  public warehouseLocations: WareHouseLocation[] = [];

  public warehouseMap = {};

  private sub: Subscription;

  constructor(private warehouseService: WarehouseService, private cdr: ChangeDetectorRef) { }

  ngOnInit() { this.getWarehouses() }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.warehouse && changes.warehouse.currentValue > 0) {
      this.getWarehouseLocation(changes.warehouse.currentValue);
    }
  }

  getWarehouses() {
    this.warehouses = this.warehouseService.getAll();
  }

  warehouseSelectOpen() { }

  warehouseSelectChange(warehouse: WareHouse) {
    if (warehouse) {
      this.warehouseChange?.emit(warehouse.id);
      this.getWarehouseLocation(warehouse.id);
    }
    else {
      this.clearWarehouse();
    }
  }

  getWarehouseLocation(warehouseId: number) {

    if (this.warehouseMap[warehouseId]) {
      this.warehouseLocations = this.warehouseMap[warehouseId];
    }
    else {
      this.sub = this.warehouseService.getWareHouseLocations(warehouseId).pipe(
        map(resp => resp),
        catchError(err => [])
      ).subscribe(locations => {
        this.warehouseMap[warehouseId] = locations;
        this.warehouseLocations = locations;
        this.cdr.markForCheck();
      });
    }
  }

  warehouseLocationSelectChange(wl: WareHouseLocation) {
    this.locationChange?.emit(wl ? wl.id : null);
  }

  clearWarehouse() {
    this.warehouse = null;
    this.warehouseChange?.emit(null);
    this.location = null;
    this.locationChange?.emit(null);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
