import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { ManufacturerService } from './../../services/manufacturer.service';
import { Manufacturer } from './../../models/manufacturer';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as popup from 'src/app/core/utils/popup.functions';
import { GFilterParam, GFColumn, GFColumnTypes, GSortParam } from 'src/app/core/models/grid-filter.models';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UIPermission } from 'src/app/core/models/common.models';


@Component({
    selector: 'app-manufacturer-list',
    templateUrl: './manufacturer-list.component.html',
    styleUrls: ['./manufacturer-list.component.scss']
})
export class ManufacturerListComponent implements OnInit, OnDestroy {

    @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = [
        { displayName: "All Manufacturers", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Active Manufacturers", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Inactive Manufacturers", colname: '', condition: 'is', value: null, operator: 'AND' },
    ];

    public search: GFilterParam[] = [
        { displayName: 'Name', colname: 'manufacturers_name', condition: 'contains', value: '', operator: 'AND' },
        { displayName: 'Account Number', colname: 'account_number', condition: 'contains', value: '', operator: 'AND' }
    ];

    public columns: GFColumn[] = [
        { displayName: 'Name', columnName: 'manufacturers_name', columnType: GFColumnTypes.string },
        { displayName: 'Account Number', columnName: 'account_number', columnType: GFColumnTypes.string },
        { displayName: 'Address', columnName: 'address', columnType: GFColumnTypes.string },
        { displayName: 'Created On', columnName: 'created_on', columnType: GFColumnTypes.date },
    ];

    public sort: GSortParam[] = [{ colname: 'manufacturers_name', direction: 'asc' }];

    public sortLookup = {
        'manufacturers_name': 'manufacturers_name',
        'address': 'address',
        'created_on': 'created_on',
        'account_number': 'account_number'
    };

    public SelectionType = SelectionType;

    public rows: Manufacturer[];

    public Permissions = PermissionConstants;

    public expanded: boolean = true;

    public selected: Manufacturer;

    public manufactureId: number;

    public model: Manufacturer;

    public module = ModuleConstants.MANUFACTURER;

    private modalRef: NgbModalRef;

    private subs: SubSink = new SubSink()

    public scrollbarH: boolean = false;

    public isAllSuborg: boolean;

    public UIPermissions: UIPermission;

    constructor(private manService: ManufacturerService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        private modal: NgbModal,
        private device: DeviceDetectorService) {

        this.isAllSuborg = this.manService.isAllSuborg;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_MANUFACTURERS, ResourceConstants.INV_MODULE);

        if (this.isAllSuborg) {
            this.columns.push({ displayName: 'Sub Org', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentValue: this.manService.customerId });
        }
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
        this.getData(null, 0);
    }

    getData(filters: GFilterParam[], offset: number = 0): void {

        let option = { sort: this.sort, filters: filters };

        this.subs.sink = this.manService.getAllFiltered(option, offset).subscribe(
            response => {
                this.rows = response.result;
                this.setPagination(offset, response.count);
                this.cdr.markForCheck();
            },
            () => {
                this.rows = [];
                this.setPagination(offset, 0);
                this.cdr.markForCheck();
            }
        );
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        let current = selected[0];
        if (current && this.UIPermissions.viewInfo) {
            this.selected = current;
            this.expanded = false;
            this.refreshTable();
        }
    }

    deleteManufacturer(id: number): void {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.manService.delete(id).subscribe(
                    () => {
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        popup.CompleteDelete('Manufacturer has been deleted.');
                        this.closeDetailedView();
                    },
                    () => this.toastr.error('Unable to delete row')
                );
            }
        });
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.refreshTable();
    }

    refreshTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    openManufactureForm(content: any, id: number = 0) {
        this.manufactureId = id;
        this.modalRef = this.modal.open(content, { centered: true });
    }

    onDataSuccess(res: { response: Manufacturer, message: string }) {
        this.toastr.success(res.message);
        this.modalRef?.dismiss();
        if (this.page.count < this.page.limit) {
            this.rows = [...this.rows.filter(w => w.id !== this.manufactureId), res.response];
            this.page.count += this.rows.length;
        }
        this.expanded = false;
        this.selected = res.response;
        this.cdr.markForCheck();
    }

    onDataError(_error: any) {
        this.toastr.error('Operation failed');
        this.modalRef?.dismiss();
    }

    onCancel() { this.modalRef?.dismiss(); }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    changeStatus() {
        let option = {
            title: `Are you sure you want to ${this.selected.is_active ? 'Activate Supplier' : 'Deactivate Supplier'}?`,
            text: this.selected.manufacturers_name,
            current: this.selected.is_active,
        };
        popup.ConfirmActivate(option, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.manService.patch(this.selected.id, { is_active: this.selected.is_active }).subscribe(
                    () => { },
                    () => {
                        this.selected.is_active = !this.selected.is_active;
                        this.cdr.markForCheck();
                        this.toastr.error("Failed to change status");
                    }
                );
            }
            else {
                this.selected.is_active = !this.selected.is_active;
                this.cdr.markForCheck();
            }
        });
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
