import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Email, EmailHistory, ItemRow, STPdf, STStatusHelper, STStatusMapItem, Section, ServiceTicket, ServiceTicketStatus } from '../../../service-ticket/models/service-ticket';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';
import { UIPermission } from 'src/app/core/models/common.models';
import { GFilterParam } from 'src/app/core/models/grid-filter.models';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { CurrencyPipe } from '@angular/common';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServiceTicketService } from '../../../service-ticket/services/service-ticket.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { TimeSheetGridComponent } from '../time-sheet-grid/time-sheet-grid.component';
import { StMaterialsGridComponent } from '../st-materials-grid/st-materials-grid.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { SweetAlertOptions } from 'sweetalert2';
import * as popup from 'src/app/core/utils/popup.functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesService } from 'src/app/modules/sales/services/sales.service';
import { AddServiceTicketComponent } from '../../../service-ticket/pages/add-service-ticket/add-service-ticket.component';
import { ChecklistService } from '../../../service-ticket/services/checklist.service';
import { STSubTask, TimeSheet } from '../../../service-ticket/models/common.models';
import { StTaskListFormComponent } from '../st-task-list-form/st-task-list-form.component';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'service-ticket-view',
  templateUrl: './service-ticket-view.component.html',
  styleUrls: ['./service-ticket-view.component.scss']
})
export class ServiceTicketViewComponent implements OnInit {

    @ViewChild('pdfTable') pdfTable: ElementRef;

    @ViewChild(TimeSheetGridComponent) timesheet_grid: TimeSheetGridComponent;

    @ViewChild(StMaterialsGridComponent) materials_grid: StMaterialsGridComponent;

    @ViewChild(AddServiceTicketComponent) add_service_ticket: AddServiceTicketComponent;

    @ViewChild(StTaskListFormComponent) task_list: StTaskListFormComponent;

    @Input() item:ServiceTicket;

    @Input() showEditButton:boolean = true;

    @Input() showDeleteButton:boolean = true;

    @Input() showSendToButton:boolean = true;

    @Input() isReadOnly: boolean = false;

    @Output() onDelete = new EventEmitter<ServiceTicket>();

    @Output() onClose = new EventEmitter();

    @Output() onRefresh = new EventEmitter();

    public STStatus=ServiceTicketStatus;

    public StatusMap = STStatusHelper.maping;

    public selectedStatus: STStatusMapItem;

    public subs: SubSink = new SubSink();

    public fileUrl: string = environment.apiURL + '/static/files/';

    public UIPermissions: UIPermission;

    public Permissions = PermissionConstants;

    public filter :GFilterParam[] = []

    public sections:Section[] = []

    public timesheets: TimeSheet[] = [];

    public email = new Email()

    public viewHistory:boolean=false;

    public email_history: EmailHistory[];

    public email_view_count: number;

    public userSesVerified: boolean;

    public userEmail: string;

    public st_task_count: number = 0;

    public st_pending_task_count: number = 0;

    public subtasks: STSubTask[] = [];

    public isdownload:boolean;

    public sendMailActive:boolean;

    public sections_pdf: Section[];

    public Pdf_instance = new STPdf();

    public dropdownStatus=[
        {"id":1,"value":"Mark as New"},
        {"id":2,"value":"Mark as In progress"},
        {"id":3,"value":"Mark as Waiting for Customer"},
        {"id":4,"value":"Mark as Escalated To Manager"},
        {"id":5,"value":"Mark as Closed Ticket"}
        ];

    config = {
        placeholder: '',
        tabsize: 2,
        // height: '200px',
        toolbar: [
        ['misc', ['undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ],
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    }

    constructor(private service: ServiceTicketService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private ra: ResourceAccessService,
        private currencyPipe: CurrencyPipe,
        private authservice:AuthService,
        private modalService: NgbModal,
        private salesService: SalesService,
        private checklistService: ChecklistService) {
            this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SERVICE_TICKETS, ResourceConstants.SERVICE_TICKET_MODULE);
            this.userSesVerified = this.service.userSesVerified;
            this.userEmail = this.service.userEmail;
    }

    ngOnInit(): void {
        this.filter = [{colname: 'from_unique_number', condition: 'equalto', value: this.item.unique_number, operator: 'AND' }]
        this.setStTaskCount();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item.currentValue&&changes.item.previousValue&&changes.item.currentValue.id&&changes.item.currentValue.id>0&&changes.item.previousValue.id&&changes.item.previousValue.id!=changes.item.currentValue.id) {
            this.setStTaskCount();
        }
    }

    testfunc(){
        this.onRefresh.emit();
    }

    setMaterials(_sections: Section[]){
        this.sections= _sections
        this.item.material_total = Number(this.materials_grid.calculateMaterialsTotal());
    }

    setTimesheets(_timesheets: TimeSheet[]){
        this.timesheets= _timesheets
    }

    setStTaskCount(count=0){
        this.subs.add(this.checklistService.getTaskDetailsByStId(this.item.id).subscribe(resp => {
            this.subtasks = resp;
            this.st_task_count = resp.length;
            this.st_pending_task_count = resp.filter(res => res.is_checked == false).length;
            this.cdr.markForCheck();
        }));
    }

    changeSTStatus(item : any){
        this.item.status=item.id;
        this.subs.sink = this.service.update(this.item.id, this.item).subscribe(
            response => {
                this.toastr.success("Status changed successfully");
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['/service-tickets/'+this.item.id+'/view']);
                this.cdr.markForCheck();
            },
            ()=>{
                this.toastr.error("Unable to change status");
            }
        );
    }

    convertToInvoice(){
        this.subs.sink = this.service.convertToInvoice(this.item.unique_number).subscribe(
            response => {
                console.log(response)
                if (response && response.item) {
                    this.router.navigate(['/sales/invoice'],
                    {state:
                        {
                            invoice_id: JSON.stringify(response.item),
                        }
                    });
                }
                else{
                    popup.ShowMessage("No items to  convert to invoice");
                }
            },
            ()=>{
                this.toastr.error("Unable to convert to invoice");
            }
        );
    }

    addSpaceAfterComa(str:string){
        var re = /,/gi;
        if(str){
            return str.replace(re,', ');
        }
        else{
            return str;
        }
    }

    openEmail(content: any){
        let temp = `<!doctype html>
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
          <head>
            <title>

            </title>
            <!--[if !mso]><!-->
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <!--<![endif]-->
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <style type="text/css">
              #outlook a { padding:0; }
              body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
              table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
              img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
              p { display:block;margin:13px 0; }
            </style>
            <!--[if mso]>
            <noscript>
            <xml>
            <o:OfficeDocumentSettings>
              <o:AllowPNG/>
              <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
            </xml>
            </noscript>
            <![endif]-->
            <!--[if lte mso 11]>
            <style type="text/css">
              .mj-outlook-group-fix { width:100% !important; }
            </style>
            <![endif]-->

              <!--[if !mso]><!-->
                <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
                <style type="text/css">
                  @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
                </style>
              <!--<![endif]-->



            <style type="text/css">
              @media only screen and (min-width:480px) {
                .mj-column-per-100 { width:100% !important; max-width: 100%; }
        .mj-column-per-33-333333333333336 { width:33.333333333333336% !important; max-width: 33.333333333333336%; }
              }
            </style>
            <style media="screen and (min-width:480px)">
              .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
        .moz-text-html .mj-column-per-33-333333333333336 { width:33.333333333333336% !important; max-width: 33.333333333333336%; }
            </style>


            <style type="text/css">



            @media only screen and (max-width:480px) {
              table.mj-full-width-mobile { width: 100% !important; }
              td.mj-full-width-mobile { width: auto !important; }
            }

            </style>
            <style type="text/css">

            </style>

          </head>
          <body style="word-spacing:normal;background-color:#FFFFFF;">


              <div
                 style="background-color:#FFFFFF;"
              >


              <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#370D7E" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


              <div  style="background:#370D7E;background-color:#370D7E;margin:0px auto;max-width:600px;">

                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#370D7E;background-color:#370D7E;width:100%;"
                >
                  <tbody>
                    <tr>
                      <td
                         style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"
                      >
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->

              <div
                 class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
              >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
              >
                <tbody>

                      <tr>
                        <td
                           align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-bottom:0px;word-break:break-word;"
                        >

              <div
                 style="font-family:Helvetica,Arial,sans-serif;font-size:24px;line-height:120%;text-align:center;color:#D52095;"
              >SERVICE TICKET<strong style="text-decoration:none;color:#FFFFFF; "># ${this.item.unique_number}</strong>
            </div>

                        </td>
                      </tr>

                </tbody>
              </table>

              </div>

                  <!--[if mso | IE]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>


              <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


              <div  style="margin:0px auto;max-width:600px;">

                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                >
                  <tbody>
                    <tr>
                      <td
                         style="direction:ltr;font-size:0px;text-align:center;"
                      >
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->

              <div
                 class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
              >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:#FFFFFF;vertical-align:top;" width="100%"
              >
                <tbody>

                      <tr>
                        <td
                           align="left" style="font-size:0px;padding:10px 25px;padding-top:25px;padding-bottom:25px;word-break:break-word;"
                        >

              <div
                 style="font-family:Helvetica,Arial,sans-serif;font-size:14px;line-height:120%;text-align:left;color:#000000;"
              >Dear ${this.item.tenant_customer_contact_name};</div>

                        </td>
                      </tr>

                      <tr>
                        <td
                           align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-bottom:25px;word-break:break-word;"
                        >

              <div
                 style="font-family:Helvetica,Arial,sans-serif;font-size:14px;line-height:120%;text-align:left;color:#000000;"
              >As discussed yesterday, please see attached proposals. I have also attached recurring payment forms for the monthly amounts. Please complete and email back to me.
        Please let me know if you have any questions.</div>

                        </td>
                      </tr>

                </tbody>
              </table>

              </div>

                  <!--[if mso | IE]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>


              <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


              <div  style="margin:0px auto;max-width:600px;">

                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                >
                  <tbody>
                    <tr>
                      <td
                         style="direction:ltr;font-size:0px;text-align:center;"
                      >
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->

              <div
                 class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
              >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
              >
                <tbody>

                      <tr>
                        <td
                           align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                        >

              <div
                 style="font-family:Helvetica,Arial,sans-serif;font-size:18px;line-height:1;text-align:center;color:#D52095;"
              >SERVICE TICKET AMOUNT<strong style="text-decoration:none;color:#370D7E;margin-left: 1em;">${this.currencyPipe.transform(this.item.total_price, 'USD')}</strong></div>

                        </td>
                      </tr>

                </tbody>
              </table>

              </div>

                  <!--[if mso | IE]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>


              <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#F6F5FD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


              <div  style="background:#F6F5FD;background-color:#F6F5FD;margin:0px auto;max-width:600px;">

                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F6F5FD;background-color:#F6F5FD;width:100%;"
                >
                  <tbody>
                    <tr>
                      <td
                         style="direction:ltr;font-size:0px;padding:20px 20px;text-align:center;"
                      >
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td align="left" class="" style="" ><![endif]-->

              <div
                 style="font-family:Helvetica,Arial,sans-serif;font-size:15px;font-weight:bold;line-height:12px;text-align:left;color:#000000;"
              ><div>Service Ticket: <span href="" style="text-decoration:none;color:#370D7E"># ${this.item.unique_number}
        </span></div></br><div style="margin-top: 12px;">Service Ticket Date: <span style="text-decoration:none;color:#370D7E"> ${new Date(this.salesService.trimTime(this.item.created_on.toString())).toDateString()}
        </span></div></div>

                  <!--[if mso | IE]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>


              <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


              <div  style="margin:0px auto;max-width:600px;">

                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                >
                  <tbody>
                    <tr>
                      <td
                         style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"
                      >
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


              <div  style="margin:0px auto;max-width:600px;">

                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                >
                  <tbody>
                    <tr>
                      <td
                         style="direction:ltr;font-size:0px;padding:10px 0;text-align:center;"
                      >
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:200px;" ><![endif]-->

              <div
                 class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
              >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
              >
                <tbody>

                      <tr>
                        <td
                           align="center" vertical-align="middle" style="font-size:0px;padding:5px 2px;word-break:break-word;"
                        >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;"
              >
                <tbody>
                  <tr>
                    <td
                       align="center" bgcolor="#370D7E" role="presentation" style="border:none;border-radius:3px;cursor:auto;height:50px;mso-padding-alt:10px 25px;background:#370D7E;" valign="middle"
                    >
                      <p
                         href="" style="display:inline-block;background:#370D7E;color:#ffffff;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"
                      >
                        <span style="letter-spacing: 2px;">REQUEST CHANGES</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

                        </td>
                      </tr>

                </tbody>
              </table>

              </div>

                  <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:200px;" ><![endif]-->

              <div
                 class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
              >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
              >
                <tbody>

                      <tr>
                        <td
                           align="center" vertical-align="middle" style="font-size:0px;padding:5px 2px;word-break:break-word;"
                        >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;"
              >
                <tbody>
                  <tr>
                    <td
                       align="center" bgcolor="#D52095" role="presentation" style="border:none;border-radius:3px;cursor:auto;height:50px;mso-padding-alt:10px 25px;background:#D52095;" valign="middle"
                    >
                      <p
                         href="" style="display:inline-block;background:#D52095;color:#ffffff;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"
                      >
                        <span style="letter-spacing: 2px;">APPROVE</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

                        </td>
                      </tr>

                </tbody>
              </table>

              </div>

                  <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:200px;" ><![endif]-->

              <div
                 class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
              >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
              >
                <tbody>

                      <tr>
                        <td
                           align="center" vertical-align="middle" style="font-size:0px;padding:5px 2px;word-break:break-word;"
                        >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;"
              >
                <tbody>
                  <tr>
                    <td
                       align="center" bgcolor="#616D89" role="presentation" style="border:none;border-radius:3px;cursor:auto;height:50px;mso-padding-alt:10px 25px;background:#616D89;" valign="middle"
                    >
                      <p
                         href="" style="display:inline-block;background:#616D89;color:#ffffff;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"
                      >
                        <span style="letter-spacing: 2px;">REJECT</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

                        </td>
                      </tr>

                </tbody>
              </table>

              </div>

                  <!--[if mso | IE]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>


              <!--[if mso | IE]></td></tr></table></td><td class="" style="" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->


              <div  style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">

                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;"
                >
                  <tbody>
                    <tr>
                      <td
                         style="direction:ltr;font-size:0px;padding:20px 0;padding-top:20px;text-align:center;"
                      >
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->

              <div
                 class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
              >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
              >
                <tbody>

                      <tr>
                        <td
                           align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                        >

              <table
                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
              >
                <tbody>
                  <tr>
                    <td  style="width:100px;">
                <center>
              <img
                 alt="" height="auto" src="https://simplyfuse.com/wp-content/uploads/2023/01/simplyfuse-logo-dark.svg" style="border:none;display:block;outline:none;text-decoration:none;height:auto;width:100px;font-size:13px;" width="100"
              /></center>

                    </td>
                  </tr>
                </tbody>
              </table>

                        </td>
                      </tr>

                      <tr>
                        <td
                           align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                        >

              <div
                 style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:1;text-align:center;color:#000000;"
              >Powered by</div>

                        </td>
                      </tr>

                </tbody>
              </table>

              </div>

                  <!--[if mso | IE]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>


              <!--[if mso | IE]></td></tr></table></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>


              <!--[if mso | IE]></td></tr></table><![endif]-->


              </div>

          </body>
        </html>`

        this.email.body = temp;
        this.email.from = this.userEmail;
        this.email.subject = "Service Ticket #"+this.item.unique_number
        this.email.to = this.item.tenant_customer_contact_email
        this.modalService.open(content, { centered: true, scrollable: true, size: "xl" })
    }

    checkSesVerified(content: any){
        if(!this.userSesVerified){
            this.subs.sink = this.authservice.checkSesVerified().subscribe(
                (resp) => {
                    let title;
                    if(Object.keys(resp['VerificationAttributes']).length === 0){
                        title = `your email address ${this.userEmail} is not verified to send emails`;
                        this.sesVerify(title);
                    }
                    else if(resp['VerificationAttributes'][this.userEmail].VerificationStatus == 'Pending'){
                        this.toastr.error(`Already verification mail send to ${this.userEmail}. It will expire soon..Please confirm it..`);
                    }
                    else if(resp['VerificationAttributes'][this.userEmail].VerificationStatus == 'Failed'){
                        title = `Your Verification link  to ${this.userEmail} Expired.`;
                        this.sesVerify(title);
                    }
                    else{
                        this.openEmail(content);
                    }
                },
                () => this.toastr.error('Failed')
            );
        }
        else
            this.openEmail(content);
      }

    sesVerify(title:string) {
        let option: SweetAlertOptions = {
            title: title,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Click here to verify',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn',
                htmlContainer: 'font-small-3',
            },
            buttonsStyling: false,
        };
        popup.OpenConfirmBox(option, (response) => {
            if (response.isConfirmed) {
                this.subs.sink = this.authservice.sendSesVerificationEmailByUser().subscribe(
                    (resp) => {
                        this.toastr.success('Verification Email send successfully');
                    },
                    () => this.toastr.error('Failed')
                );
            }
        });
    }

    modifySTBeforePDF(){
        this.subs.add(this.checklistService.getTaskDetailsByStId(this.item.id).subscribe(resp => {
            this.Pdf_instance.subtasks = resp.filter(subtask => subtask.is_render_in_pdf);
            this.modifyST();
        }));
    }

    modifyST(){
        this.Pdf_instance.item = JSON.parse(JSON.stringify(this.item));
        this.Pdf_instance.item["image_url"] = this.fileUrl + this.item.suborg_primary_image;
        this.Pdf_instance.timesheets = JSON.parse(JSON.stringify(this.timesheets)).filter(row => row.is_show_in_pdf);
        this.Pdf_instance.sections = JSON.parse(JSON.stringify(this.sections));
        this.Pdf_instance.sections.forEach((sec, index)=>{
            if(sec&&sec.rows&&sec.rows.length>0){
                sec.rows = sec.rows.filter(row => row.is_show_in_pdf);
                sec.rows.forEach((row,i)=>{
                    row.row_total = (row.price*row.quantity).toFixed(2);
                    row.image_link = this.fileUrl + row.primary_image
                })
                sec.section_total = this.priceSummaryFunc(sec.rows);
            }
        });
        this.Pdf_instance.subtasks.forEach(sub =>{
            sub.images_list.forEach((image,i)=>{
                if (image.type=='image/png' || image.type=='image/jpeg')
                    image.image_link = this.fileUrl + image.url;
            })
        })

        this.Pdf_instance.item.total_price = Number(this.calculatePDFGrandTotal());
        this.Pdf_instance.item.material_total = Number(this.calculateMaterialsPDFTotal());
        this.Pdf_instance.item.timesheet_total = Number(this.calculateTimesheetPDFTotal());
        this.Pdf_instance.item.tax_amount = Number(this.calculatePDFTax());
        this.Pdf_instance.item.balance_due = this.Pdf_instance.item.total_price - this.Pdf_instance.item.invoiced_total;

        if (this.sendMailActive)
            this.sendMailFunc();
        else
            this.generatePdf()
    }

    priceSummaryFunc(rows: ItemRow[]) {
        return rows.map(row => (!isNaN(row.price) && !row.is_delete && row.is_billable) ? row.price * row.quantity : 0)?.reduce((a, b) => a + b, 0.00)?.toFixed(2);
    }

    calculateMaterialsPDFTotal() {
        let total = 0;
        this.Pdf_instance.sections.forEach((sec, index) => {
            if (sec && sec.rows && sec.rows.length > 0) {
                sec.rows.forEach((row, i) => {
                    if (!row.is_delete && row.is_billable && row.is_show_in_pdf) {
                        total += Number(!isNaN(row.price) ? row.price : 0) * row.quantity;

                    }
                })
            }
        });
        return total.toFixed(2);
    }

    calculateTimesheetPDFTotal() {
        let total = 0;
        this.Pdf_instance.timesheets.forEach((row, index) => {
            if (!row.is_delete && row.is_billable) {
                total += Number(!isNaN(row.total_amount) ? row.total_amount : 0);

            }
        });
        return total.toFixed(2);
    }

    calculatePDFTotal(){
        return Number(this.calculateMaterialsPDFTotal()) + Number(this.calculateTimesheetPDFTotal());
    }

    calculatePDFGrandTotal() {
        return (Number(this.calculatePDFTotal()) + Number(this.calculatePDFTax())).toFixed(2);
    }

    calculatePDFTax() {
        return this.item.tax > 0 ? Number((Number(this.calculatePDFTotal()) * this.item.tax) / 100).toFixed(2) : 0;
    }

    sendMail(){
        this.sendMailActive = true;
        this.modifySTBeforePDF();
    }

    sendMailFunc(){
        this.subs.sink = this.service.sendMail(this.email, this.Pdf_instance).subscribe(
            response => {
                this.toastr.success("mail sent succesfully")
                this.modalService.dismissAll()
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['/service-tickets/'+this.item.id+'/view']);
                this.sendMailActive = false;
                // this.service.getDashboardCounts();
                this.cdr.markForCheck();
            },
            ()=>{
                this.toastr.error("Unable to send mail");
            }
        );
    }

    download(isdownload: boolean){
        this.isdownload = isdownload;
        this.modifySTBeforePDF();
    }

    generatePdf(){
        this.subs.sink = this.service.generate_pdf(this.Pdf_instance).subscribe(resp => {
            this.toastr.success("Generated..............")
            var blob = new Blob([resp], {type: "application/pdf"});
            var blob_url = window.URL.createObjectURL(blob);
            if(this.isdownload){
                var link = document.createElement('a');
                link.href = blob_url
                link.download = this.item.unique_number;
                link.click();
            }
            else{
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = blob_url;
                document.body.appendChild(iframe);
                iframe.contentWindow.print();
                // document.body.removeChild(iframe)
            }
            window.URL.revokeObjectURL(blob_url);
        });
    }

    closeDetailedView() {
        this.onClose.emit()
    }

    deleteSC(item: any){
        this.onDelete.emit(item)
    }

    calculateTotal(){
        return Number(this.materials_grid.calculateMaterialsTotal()) + Number(this.timesheet_grid.calculateTimesheetTotal());
    }

    calculateGrandTotal() {
        return (Number(this.calculateTotal()) + Number(this.calculateTax())).toFixed(2);
    }

    calculateTax() {
        return this.item.tax > 0 ? Number((Number(this.calculateTotal()) * this.item.tax) / 100).toFixed(2) : 0;
    }

    onUpdateMaterials(){
        this.sections = this.materials_grid.sections;
        this.timesheets = this.timesheet_grid.rows;
        this.modifySTObject();
    }

    modifySTObject(){
        this.item.total_price = Number(this.calculateGrandTotal());
        this.item.material_total = Number(this.materials_grid.calculateMaterialsTotal());
        this.item.timesheet_total = Number(this.timesheet_grid.calculateTimesheetTotal());
        this.item.tax_amount = Number(this.calculateTax());
        this.item.balance_due = this.item.total_price - this.item.invoiced_total;
        this.onSaveST();
        this.cdr.detectChanges();
    }

    onSaveST(){
        this.service.update(this.item.id, this.item).subscribe(
            resp => {
            },
            () => {
                this.toastr.error("Error occured...");
            }
        );
    }

    afterTabResize(){
        this.timesheet_grid.table.recalculate();
        this.materials_grid.table.recalculate();
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

}
