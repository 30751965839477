import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, TemplateRef, ElementRef, IterableDiffers } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { UserService } from '../../services/user.service';
import { UserWrapper } from '../../models/user-wrapper';
import { ToastrService } from 'ngx-toastr';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SubSink } from 'subsink';
import { SystemRoles } from 'src/app/core/enums/user-types';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/core/services/auth.service';
import { User, UserWorkingTime, WorkingDays, WorkingRates, WorkingTime, WorkingTimeResponse, workingTimes } from '../../models/user';
import { NgbActiveModal, NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from 'src/app/core/services/base.service';
import { TransitionCheckState } from '@angular/material/checkbox';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { UserDataHelper } from '../../helpers/user-data.helper';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ViewChild('addTime') addTime: ElementRef;
  public ColumnMode = ColumnMode;

  public SelectionType = SelectionType;

  // public rows: UserWrapper[];
  public rows: User[];

  private tempData: User[];

  public isSuperAdmin: boolean;

  public isAdmin: boolean;

  

  public defaultSort: string;

  public scrollbarH: boolean = false;

  public expanded: boolean = true;

  public selected: User;

  public systemRoles = SystemRoles;

  public userId: number;

  public currentRolePrevCount:number;

  public subs: SubSink = new SubSink();

  public addDayType:string="All";

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public sort: GSortParam[] = [{ colname: 'first_name', direction: 'desc' }];

  public defaults: GFilterParam[] = UserDataHelper.defaults;

  public search: GFilterParam[] = UserDataHelper.search;

  public columns: GFColumn[] = UserDataHelper.columns

  public UIPermissions: UIPermission;

  public weekDays=[
    {day:"Monday", checked:false},
    {day:"Tuesday", checked:false},
    {day:"Wednesday", checked:false},
    {day:"Thursday", checked:false},
    {day:"Friday", checked:false},
    {day:"Saturday", checked:false},
    {day:"Sunday", checked:false}
  ];

  public workingDays:WorkingDays[]=[
    {day:"Monday", id:0, "times":[]},
    {day:"Tuesday", id:1, "times":[]},
    {day:"Wednesday", id:2, "times":[]},
    {day:"Thursday", id:3, "times":[]},
    {day:"Friday", id:4,  "times":[]},
    {day:"Saturday",id:5, "times":[]},
    {day:"Sunday", id:6, "times":[]},
  ];

  public workingTimes:workingTimes[]=[];


  public uWorkingtime:WorkingTimeResponse[]=[];


  public workingRates:WorkingRates[]=[];

  public iterableDiffer:any;

  public enablePriceSaveBtn:boolean=false;

  public customerId:number;

  public isDeleteFilterActive:boolean=false;

  constructor(private userService: UserService,
    route: ActivatedRoute,
    private toastr: ToastrService,
    private device: DeviceDetectorService,
    private cdr: ChangeDetectorRef,
    public location: Location,
    public modelService:NgbModal,
    public ra:ResourceAccessService,
    public activeModal: NgbActiveModal,
    private authService:AuthService, 
    private router: Router,) {

    this.scrollbarH = this.device.isMobile();
    this.isSuperAdmin = userService.isSuperUser;
    this.customerId=userService.customerId;
    this.isAdmin = userService.isAdmin;
    this.defaultSort = this.isSuperAdmin ? 'custname' : 'user.first_name';
    this.subs.sink = route.params.subscribe(params =>{ 
      this.userId = +params.id || 0
    });

    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_INVOICES, ResourceConstants.SALES_MODULE, true);



  }

  ngOnInit(): void {

    // this.subs.sink = this.userService.getAll().subscribe(resp => {
    //   this.tempData = resp
    //   this.rows = resp;
    //   if (this.userId) {
    //     this.selected = this.rows.find(item => item.user.id === this.userId);
    //     if (this.selected) {
    //       this.expanded = false;
    //       this.refreshDataTable();
    //     }
    //   }
    //   this.cdr.markForCheck();
    // });

    this.getUsers([]);
    this.clearWorkingTimePopup();

    if(this.userId>0){
      this.getWorkingTime();
      this.getWorkingRates();
    }

  }

  getUsers(filters: GFilterParam[], offset: number = 0){

    

    if(this.checkDeleteFilter(filters)){
      this.isDeleteFilterActive=true;
    }
    else{
      this.isDeleteFilterActive=false;
    }

    let option = { sort: this.sort, filters: filters };
    // let option={};

    this.subs.sink=this.userService.filter(option).subscribe(res=>{

      this.tempData = res.result;
      this.rows = res.result;
      if (this.userId) {
        this.selected = this.rows.find(item => item.id === this.userId);
        if (this.selected) {
          this.expanded = false;
          this.refreshDataTable();
        }
      }
      this.cdr.detectChanges();
    })
  }


  public searchUser(term: string) {
    this.rows = this.tempData.filter(item => {
      return (item.first_name + " " + item.last_name)?.toLowerCase().indexOf(term) !== -1 || !term;
    });
    this.table.offset = 0;
  }

  onSelect({ selected }) {
    let current: User = selected[0];
    if (current&&!current.is_delete) {
      this.selected = current;
      this.expanded = false;
      this.userId=current.id;
      this.getWorkingRates();
      this.getWorkingTime();
      this.location.go(`/users/${current.id}/view`);
      this.refreshDataTable();
    }
  }

  deleteUser(id: number): void {

    if(this.userService.isAdmin||this.userService.isSuperUser){
      popup.ConfirmDelete(result => {
        if (result.isConfirmed) {
          this.subs.sink = this.userService.delete(id).subscribe(
            () => {
              this.tempData = this.tempData?.filter(item => item.id !== id);
              this.rows = this.tempData;

              popup.CompleteDelete('User has been deleted.');

              setTimeout(() => {
                this.closeDetailedView();
              }, 100);
             

            },
            () => this.toastr.error('Failed to delete User')
          );
        }
      });
    }
    else{
      this.toastr.error('Looks like you dont have permission to perform this action');
    }
    



  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.userId=0;
    this.location.go(`/users`);
    this.refreshDataTable();
  }

  refreshDataTable() {

    setTimeout(() => {
      this.table.recalculate();
      // this.rows = [...this.rows];
      // this.cdr.detectChanges();
    },100);
  }

  changeStatus(): void {
    if (this.selected.rolename === SystemRoles.SuperAdmin) {
      this.selected.is_active = !this.selected.is_active;
      return;
    }
    let user = this.selected;
    let opt = {
      title: `Are you sure you want to ${user.is_active ? 'Activate User' : 'Deactivate User'}?`,
      text: user.first_name + ' ' + user.last_name,
      current: user.is_active,
    }
    popup.ConfirmActivate(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.userService.patch(user.id, { is_active: user.is_active }).subscribe(
          resp => { },
          () => {
            this.selected.is_active = !user.is_active;
            this.cdr.markForCheck();
            this.toastr.error('Failed to change status');
          }
        );
      }
      else {
        this.selected.is_active = !user.is_active;
        this.cdr.markForCheck();
      }
    });
  }

  subscriptionChange(sub:string) {
    if (this.selected.rolename === SystemRoles.SuperAdmin) {
      this.selected.is_active = !this.selected.is_active;
      return;
    }
    let user = this.selected;

    let status=user[sub];
    let opt = {
      title: `Are you sure you want to ${status ? 'activate' : 'deactivate'} this subscription`,
      text: user.first_name + ' ' + user.last_name,
      current: status,
    }
    popup.ConfirmActivate(opt, result => {
      if (result.isConfirmed) {

        let data={};
        data[sub]=status;
        this.subs.sink = this.userService.patch(user.id, data).subscribe(
          resp => { },
          () => {
            this.selected.subscription = !user.subscription;
            this.cdr.markForCheck();
            this.toastr.error('Failed to change status');
          }
        );
      }
      else {
        this.selected.subscription = !user.subscription;
        this.cdr.markForCheck();
      }
    });
  }

  sendVerificationLink() {
    this.subs.add(this.authService.sendUserActivationLink(this.selected.id).subscribe(
      resp=>{
        this.toastr.success('Activation link has been sent');
      },
      error => this.toastr.error('Operation Failed')
    ))
  }

  /*voiceMailStatus() {
    if (this.selected.rolename === SystemRoles.SuperAdmin) {
      this.selected.user.is_active = !this.selected.user.is_active;
      return;
    }
    let user = this.selected.user;
    let opt = {
      title: `Are you sure you want to ${user.is_voicemail_enabled ? 'Activate Voice Mail' : 'Deactivate Voice Mail'}?`,
      text: user.first_name + ' ' + user.last_name,
      current: user.is_voicemail_enabled,
    }
    popup.ConfirmActivate(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.userService.patch(user.id, { is_voicemail_enabled: user.is_voicemail_enabled }).subscribe(
          resp => { },
          () => {
            this.selected.user.is_voicemail_enabled = !user.is_voicemail_enabled;
            this.cdr.markForCheck();
            this.toastr.error('Failed to change status');
          }
        );
      }
      else {
        this.selected.user.is_voicemail_enabled = !user.is_voicemail_enabled;
        this.cdr.markForCheck();
      }
    });
  }

  voiceMailEmailStatus() {
    if (this.selected.rolename === SystemRoles.SuperAdmin) {
      this.selected.user.is_active = !this.selected.user.is_active;
      return;
    }
    let user = this.selected.user;
    let opt = {
      title: `Are you sure you want to ${user.is_voicemail_send ? 'Activate Send Mail' : 'Deactivate Send Mail'}?`,
      text: user.first_name + ' ' + user.last_name,
      current: user.is_voicemail_send,
    }
    popup.ConfirmActivate(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.userService.patch(user.id, { is_voicemail_send: user.is_voicemail_send }).subscribe(
          resp => { },
          () => {
            this.selected.user.is_voicemail_send = !user.is_voicemail_send;
            this.cdr.markForCheck();
            this.toastr.error('Failed to change status');
          }
        );
      }
      else {
        this.selected.user.is_voicemail_send = !user.is_voicemail_send;
        this.cdr.markForCheck();
      }
    });
  }

  voiceMailVoiceStatus() {
    if (this.selected.rolename === SystemRoles.SuperAdmin) {
      this.selected.user.is_active = !this.selected.user.is_active;
      return;
    }
    let user = this.selected.user;
    
    
        this.subs.sink = this.userService.patch(user.id, { voicemail_voice: user.voicemail_voice }).subscribe(
          resp => { },
          () => {
            this.cdr.markForCheck();
            this.toastr.error('Failed to change status');
          }
        );
     
   
  }*/

  submit(): void {

    let user = this.selected;
    let opt = {
      title: `Are you sure you want to update settings?`,
      text: user.first_name,
      ButtonText: 'Yes',
    }
    popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.userService.patch(this.selected.id, 
          { is_voicemail_send: user.is_voicemail_send ,
            is_voicemail_enabled: user.is_voicemail_enabled,
            voicemail_voice: user.voicemail_voice,
            voicemail_message: user.voicemail_message
          }).subscribe(
          resp => { this.toastr.success('Voicemail settings has been updated'); },
          () => {
            this.cdr.markForCheck();
            this.toastr.error('Failed to updates');
          }
        );
      }
      else {
       // this.selected.is_voicemail_send = !user.is_voicemail_send;
       // this.selected.is_voicemail_enabled = !user.is_voicemail_enabled;
        this.cdr.markForCheck();
      }
    });
    
  }

  addTimeModel(type:string="All"){

    if(this.workingTimes.length==0){
      this.addNewTime();

    }
    
    if(type=="All"){

      this.weekDays.forEach((val,index)=>{
         if(index<6){
          val.checked=true;
         }
         else{
          val.checked=false;
         }
      });
    }
    else{

      // this.weekDays.find(val=>val.day==type).checked=true;
      this.weekDays.forEach((val,index)=>{
         if(val.day.toUpperCase()==type){
          val.checked=true
         }
         else{
          val.checked=false;
         }

      });

      this.cdr.markForCheck();

      console.log(this.weekDays,'After calculation');

    }

    this.modelService.open(this.addTime);
    
  }

  addNewTime(){
    let v=new workingTimes();
    v.startTime.hour=10;
    v.startTime.minute=0;
    v.endTime.hour=12;
    v.endTime.minute=0;
    this.workingTimes.push(v);
  }

  saveNewTime(){

   if(!this.validateTimesOnAdd()){

        let data:UserWorkingTime[]=[];

        if(this.workingTimes.length>0){

          this.weekDays.forEach((val,index)=>{


            if(val.checked){


              this.workingTimes.forEach(val2=>{
                let d=new UserWorkingTime();
                d.start_time=val2.startTime.hour+':'+val2.startTime.minute+':00';
                d.end_time=val2.endTime.hour+':'+val2.endTime.minute+':00';
                d.day=index;
                d.customer=this.userService.customerId;

                data.push(d);
              
              })
            }

          })

        }

        if(data&&data.length>0){

        this.subs.sink= this.userService.addWorkingTime(this.userId, data).subscribe(response=>{
              this.toastr.success('Working time added successfully');
              this.clearWorkingTimePopup();
              this.getWorkingTime();
              this.modelService.dismissAll();
          },
          ()=>{
            this.toastr.error('Error occured during process. Please try again');
          });
        }

   }




  }

  clearWorkingTimePopup(){
    this.weekDays.forEach((val,i)=>{

      if(i!=6){
        val.checked=true;
      }
      else{
        val.checked=false;
      }
    })

    this.workingTimes=[];

    this.addNewTime();

    
  }

  getWorkingTime(){

    this.subs.sink=this.userService.getWorkingTime(this.userId).subscribe(response=>{
        console.log(response, 'working time response');
        this.uWorkingtime=response.data;
    });

  }

  removeTimeRow(index:number){
    this.workingTimes.splice(index,1);
  }

  validateTimesOnAdd(){
    let errors:string[]=[];
     if(this.weekDays.filter(val=>val.checked==true).length==0){
       errors.push("Select atleast one day");
     }
     if(this.workingTimes.length==0){
      errors.push("Add atleast one time range");
     }
     else{
      let e=false;
      this.workingTimes.forEach((v,i)=>{
        let sTime1=Number(v.startTime.hour+v.startTime.minute);
        let eTime1=Number(v.endTime.hour+v.endTime.minute);
        this.workingTimes.forEach((v2,i2)=>{
          let sTime2=Number(v2.startTime.hour+v2.startTime.minute);
          let eTime2=Number(v2.endTime.hour+v2.endTime.minute);

          if(i!=i2&&((sTime1>=sTime2&&sTime1<=eTime2)||(eTime1>=sTime2&&eTime1<=eTime2))){
            e=true;
          }
            
        })
      });

      let e2=false;

       if(this.uWorkingtime.length){


        //  this.uWorkingtime.forEach((val,index)=>{

        //     tempArray=tempArray.concat(val.availabilty);

        //  });




          this.workingTimes.forEach((v,i)=>{
            let sTime1=Number(v.startTime.hour+v.startTime.minute);
            let eTime1=Number(v.endTime.hour+v.endTime.minute);

            this.uWorkingtime.forEach((v2,i2)=>{
              if(this.weekDays[v2.day].checked&&v2.availabilty.length){

                    v2.availabilty.forEach((v3,i3)=>{
                      
                      let sSplit=v3.start_time.split(":",2);
                      let eSplit=v3.end_time.split(":",2);
                      let sTime2=Number(sSplit[0])+Number(sSplit[1]);
                      let eTime2=Number(eSplit[0])+Number(eSplit[1]);
                      // let eTime2=v2.endTime.hour+v2.endTime.minute;
        
                  
        
                      if(((sTime1>=sTime2&&sTime1<=eTime2)||(eTime1>=sTime2&&eTime1<=eTime2))){
                        e2=true;
                      }
                    })

              }
                

            })
          });

     

       }



      if(e){
        errors.push('Please rectify time ranges overlaping to proceed');
      }
      else if(e2){

        errors.push('New time ranges overlaping the existing working time');

      }

     }

     if(errors.length>0){
      errors.forEach((val,index)=>{
          this.toastr.error(val);
      });
      return true;
     }
     else{
      return false;
     }


  }

  deleteWorkingTime(id:number, rowIndex, index){

    this.subs.sink=this.userService.deleteWorkingTime(id).subscribe(response=>{

      this.toastr.success('Working time deleted successfully');

      this.uWorkingtime[rowIndex].availabilty.splice(index,1);

    });

  }

  getWorkingRates(){

    this.subs.sink=this.userService.getWorkingRates(this.userId).subscribe(res=>{
      this.workingRates=res.data;
      this.enablePriceSaveBtn=false;
      this.cdr.markForCheck();
    });


  }

  saveRates(){
     
    if(this.workingRates.length){
      this.subs.sink=this.userService.updateWorkingRates(this.userId,this.workingRates).subscribe(res=>{
        this.toastr.success('Rates updated successfully');
        this.enablePriceSaveBtn=false;
        this.cdr.markForCheck();
      },
      ()=>{
        this.toastr.error('Error occured during process');
      });
    }
  }

  updateWorkingOvertimeStatus(){
    this.subs.sink=this.userService.update(this.userId, this.selected).subscribe(res=>{
        this.toastr.success('Overtime status changed successfully');
    },
    ()=>{
      this.toastr.error('Error occured during process');
    });
  }


  checkForPriceChange(){
    this.enablePriceSaveBtn=true;
    this.cdr.markForCheck();

  }

  checkDeleteFilter(filters:GFilterParam[]){

    let status=false;

    if(filters.length>0){
      filters.forEach((val,index)=>{
        if(val.colname=='is_delete'&&val.value==true){
            status=true;
        }
      })
    }

    return status;
  }

  MobileOnChange(): void {
    let selected = this.selected;
    let opt = {
      title: `Are you sure you want to ${selected.is_mask_contact ? 'Mask' : 'Unmask'} number?`,
      text: '',
      ButtonText: "Yes"
    }
    popup.ConfirmWithButtonText(opt, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.userService.patch(selected.id, { is_mask_contact: selected.is_mask_contact }).subscribe(
          resp => {
            this.toastr.success(`${selected.is_mask_contact? 'Masked':'Unmasked'} successfully`);
           },
          () => {
            this.selected.is_mask_contact = !selected.is_mask_contact;
            this.cdr.markForCheck();
            this.toastr.error('Failed to change');
          }
        );
      }
      else {
        this.selected.is_mask_contact = !selected.is_mask_contact;
        this.cdr.markForCheck();
      }
    });
  }

  ngOnDestroy() { this.subs.unsubscribe(); }
}
