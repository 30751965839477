import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { of } from 'rxjs';
import * as popup from 'src/app/core/utils/popup.functions';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { catchError } from 'rxjs/operators';
import { DistributorLocation } from '../../models/distributor';
import { DistributorService } from '../../services/distributor.service';

@Component({
  selector: 'distributor-locations',
  templateUrl: './distributor-locations.component.html',
  styleUrls: ['./distributor-locations.component.scss']
})
export class DistributorLocationsComponent implements OnInit, OnDestroy {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ViewChild('FormModal') modalContent: any;

  private _distid: number;

  @Input() set distributorId(value: number) {
    this._distid = value;
    if (value) {
      this.getData();
    }
  }

  get distributorId() { return this._distid; };

  public rows: DistributorLocation[];

  public SelectionType = SelectionType;

  public bindingModel: DistributorLocation;

  public Permissions = PermissionConstants;

  private subs: SubSink = new SubSink();

  private modalRef: NgbModalRef;

  constructor(private distributorService: DistributorService,
    private modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() { }

  getData() {
    this.subs.sink = this.distributorService.getDistributorLocations(this.distributorId).pipe(
      catchError(() => of([]))
    ).subscribe(resp => {
      this.rows = resp;
      this.cdr.markForCheck();
    });
  }

  open() {
    this.bindingModel = undefined;
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modal.open(this.modalContent, { size: 'lg', scrollable: true, centered: true });
  }

  onSelect({ selected }) {
    let current: DistributorLocation = selected[0];
    if (this.hasPermission(this.Permissions.MODIFY)) {
      this.bindingModel = { ...this.rows.find(item => item.id === current.id) };
      this.openModal();
    }
  }

  onDataSubmittedSuccess(result: { response: DistributorLocation, message: string }): void {
    this.toastr.success(result.message);
    this.rows = [...this.rows.filter(item => item.id !== result.response.id), ...[result.response]];
    this.cdr.markForCheck();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeModal() { this.modalRef?.dismiss(); }

  deleteLocation(id: number): void {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.distributorService.deleteDistributorLocation(id).subscribe(
          () => {
            this.rows = this.rows.filter(x => x.id !== id);
            this.cdr.markForCheck();
            popup.CompleteDelete('Location has been deleted.')
          },
          () => this.toastr.error('Unable to delete location')
        );
      }
    });
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.DISTRIBUTOR_LOCATIONS,
      [
        permission,
        this.Permissions.GLOBAL
      ], 
      ResourceConstants.CUSTOMER_MODULE);
  }

  toggleExpandRow(row: DistributorLocation) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
