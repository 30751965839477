<div class="info-container" (mouseover)="showAction = true" (mouseleave)="showAction = false">
    <div class="row justify-content-center holdBadge" *ngIf="session.customValues?.isCallOnHold || session.customValues.isUnholded">
       <i>on Hold</i> 
    </div>
    <div class="row justify-content-center holdBadge" *ngIf="!session.customValues?.isCallOnHold && session.state=='Terminated' && !session.customValues.isUnholded">
        <i>call Ended</i> 
     </div>
    <div class="row p-0 m-0 mb-1">
        <div class="col-2 p-0">
            <img src="assets/img/caller assets/caller_avatar.png" alt="" width="16">
        </div>
        <div class="col p-0">
            {{callerName(session)}}
        </div>
    </div>
    <div class="row p-0 m-0 mb-1">
        <div class="col-2 p-0">
            <img src="assets/img/caller assets/Incoming cals.svg" alt="" width="17">
        </div>
        <div class="col p-0">
            {{callerNumber(session)}}
        </div>
    </div>
    <div class="row p-0 m-0 mb-1">
        <div class="col-2 p-0">
            <img src="assets/img/caller assets/timer.png" alt="">
        </div>
        <div class="col p-0">
            {{callDuration|async}}
        </div>
    </div>
    <div class="row justify-content-center ipBadge" *ngIf="session.customValues?.isOnGoingSt">
        <i>ST in progress</i> 
     </div>

     <div class="row justify-content-center ipBadge" *ngIf="session.customValues?.isOnGoingSo">
        <i>SO in progress</i> 
     </div>

     <!-- <div class="d-flex justify-content-around action-badge pl-3 pr-3" *ngIf = "session.state !='Terminated' && showAction"
     (mouseover)="showAction = true" (mouseleave)="showAction = false">
        <a (click)="onHoldCall()"><img src="{{session.customValues.isCallOnHold ? 'assets/img/caller assets/unhold.svg':'assets/img/caller assets/hold.svg' }}"
         alt=""></a>
         <a> <img src="{{session.customValues?.isMuted ? 'assets/img/caller assets/mute.svg':'assets/img/caller assets/unmute.svg' }}"
            alt=""></a>
        <a> <img src="assets/img/caller assets/decline.svg" alt=""></a>
     </div> -->
    
</div>
