import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SalesOrdersService } from '../../../sales/services/sales-orders.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ItemSelectComponent } from 'src/app/modules/inventory/components/select/item-select/item-select.component';
import { KitSelectComponent } from 'src/app/modules/inventory/components/select/kit-select/kit-select.component';
import { environment } from 'src/environments/environment';
import { ItemAdd, ItemRow, SOItemsType, Section } from '../../../sales/models/sales-orders.models';
import { SubSink } from 'subsink';
import { ItemWrapper } from 'src/app/modules/inventory/models/items';
import { KitWrapper } from 'src/app/modules/inventory/models/kit.models';
import { SweetAlertOptions } from 'sweetalert2';
import * as popup from 'src/app/core/utils/popup.functions';
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'so-materials-grid',
  templateUrl: './so-materials-grid.component.html',
  styleUrls: ['./so-materials-grid.component.scss']
})
export class SoMaterialsGridComponent implements OnInit {

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @ViewChild('itemSelect') itemSelect: ItemSelectComponent;

    @ViewChild('kitSelect') kitSelect: KitSelectComponent;

    @Input() so_id:number;

    @Input() suborg_id:number;

    @Input() tax: number = 0;

    @Output() onUpdate = new EventEmitter();

    @Output() onDataLoaded = new EventEmitter();

    public fileUrl: string = environment.apiURL + '/static/files/';

    public sections: Section[] = [new Section()];

    public subs :SubSink = new SubSink();

    public itemtype =  SOItemsType;

    public newItem: boolean = false;

    public rowIndex: number = 0;

    public sectionIndex: number = 0;

    itemCount: number = 0;

    constructor(
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private service: SalesOrdersService
    ) { }

    ngOnInit(): void {
        this.getItemsKits()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.so_id.currentValue&&changes.so_id.previousValue) {
        this.getItemsKits();
        }
    }

    getItemsKits(){
        this.sections=[];
        this.subs.sink=this.service.itemKitList(this.so_id).subscribe(
        response=>{
            if (response && response.length > 0) {
                this.groupBysection(response);
            }
            else{
                this.addSection();
                this.cdr.markForCheck();
            }
        },
        ()=>{
            this.toastr.error("Unable to get items");
        }
        );
    }

    groupBysection(response){
        this.itemCount = response.length;
        this.sections= _.chain(response)
        .groupBy("section_title").map((value, key) => ({ title: key, rows: value, section_total:'' })).value();
        this.sections = this.sections.filter((value, index)=>{
            return value.rows && value.rows.length
        })
        this.sections.map(sec=> sec.rows.map(row=> row.previous_quantity = row.quantity))
        this.onDataLoaded.emit(this.sections)
        this.cdr.markForCheck();
    }

    addSection() {
        this.sections = _.concat(this.sections, new Section);
    }

    removeSection(index: number) {
        if (this.sections.length > 1) {
            let item_exist = false;
            this.sections[index].rows.map(item =>{
                item.is_delete=true;
                item_exist = true;
            })
            if (!item_exist)
                this.sections.splice(index, 1)
            this.saveMaterials("Section removed successfully")
        }
    }

    appendRow(sectionIndex: number, type: SOItemsType) {
        this.newItem = true;
        this.toggleSelect(sectionIndex, type);
    }

    toggleSelect(sectionIndex: number, type: SOItemsType, index?: number) {
        if(index >=0){
            this.newItem = false;
            this.rowIndex = index;
        }
        this.sectionIndex = sectionIndex;
        if (type === SOItemsType.ITEM) {
            this.itemSelect.open();
        }
        else if (type === SOItemsType.KIT) {
            this.kitSelect.open();
        }
    }

    onItemChange(item: ItemWrapper) {
        if(this.newItem){
            let row = this.getRow(SOItemsType.ITEM)
            this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
            this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
        }
        if (item && this.sections[this.sectionIndex] && this.sections[this.sectionIndex].rows[this.rowIndex]) {
            this.sections[this.sectionIndex].rows[this.rowIndex].unique_id = item.id;
            this.sections[this.sectionIndex].rows[this.rowIndex].name = item.item_name;
            this.sections[this.sectionIndex].rows[this.rowIndex].part_number = item.part_number;
            this.sections[this.sectionIndex].rows[this.rowIndex].primary_image = item.primary_image;
            this.sections[this.sectionIndex].rows[this.rowIndex].price = item.item_price || 0.00;
            this.sections[this.sectionIndex].rows[this.rowIndex].sku = item.sku;
            this.sections[this.sectionIndex].rows[this.rowIndex].uom = item.unittype_name;
            this.sections[this.sectionIndex].rows[this.rowIndex].description = item.part_description;
            this.sections[this.sectionIndex].rows = [...this.sections[this.sectionIndex].rows];
            this.saveMaterials("Item added successfully");
        }
    }

    onKitChange(kit: KitWrapper) {
        if(this.newItem){
            let row = this.getRow(SOItemsType.KIT)
            this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
            this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
        }
        if (kit && this.sections[this.sectionIndex] && this.sections[this.sectionIndex].rows[this.rowIndex]) {
            this.sections[this.sectionIndex].rows[this.rowIndex].unique_id = kit.id;
            this.sections[this.sectionIndex].rows[this.rowIndex].name = kit.name;
            this.sections[this.sectionIndex].rows[this.rowIndex].part_number = kit.internal_number;
            this.sections[this.sectionIndex].rows[this.rowIndex].price = kit.sell_price || 0.00;
            this.sections[this.sectionIndex].rows[this.rowIndex].primary_image = kit.primary_image;
            this.sections[this.sectionIndex].rows[this.rowIndex].sku = kit.sku;
            this.sections[this.sectionIndex].rows[this.rowIndex].uom = kit.unittype_name;
            this.sections[this.sectionIndex].rows[this.rowIndex].description = kit.description;
            this.sections[this.sectionIndex].rows = [...this.sections[this.sectionIndex].rows];
            this.saveMaterials("Item added successfully");
        }
    }

    getRow(type: SOItemsType){
        let row = new ItemRow();
        row.sales_order = this.so_id;
        row.type = type;
        row.customer = this.service.customerId;
        row.quantity = 1;
        row.order = this.itemCount++;
        return row;
    }

    onSectionTitleChange(index){
        if(this.sections[index].rows && this.sections[index].rows.length)
            this.saveMaterials("Section title modified successfully")
    }

    priceSummaryFunc(index) {
        return this.sections[index].rows.map(row => (!isNaN(row.price) && !row.is_delete && row.is_billable) ? row.price * row.quantity : 0)?.reduce((a, b) => a + b, 0.00)?.toFixed(2);
    }

    calculateMaterialsTotal() {
        let total = 0;
        this.sections.forEach((sec, index) => {
            if (sec && sec.rows && sec.rows.length > 0) {
                sec.rows.forEach((row, i) => {
                    if (!row.is_delete && row.is_billable) {
                        total += Number(!isNaN(row.price) ? row.price : 0) * row.quantity;

                    }
                })
            }
        });
        return total.toFixed(2);
    }

    onQuantityChange(row: ItemRow){
        row.showErrorMessage = false;
        if(row.invoiced_quantity || row.service_ticket_quantity){
            if (row.quantity > 0 && (row.quantity >= row.invoiced_quantity + row.service_ticket_quantity)){
                row.previous_quantity = row.quantity;
                return true;
            }
            row.quantity = row.previous_quantity;
            row.showErrorMessage = true;
            return false
        }
        else
            return true;
    }

    formatDataForSend() {
        let itemsAdd: ItemAdd[] = [];
        this.sections.forEach((val, index) => {
            if (val.rows && val.rows.length) {
                val.rows.forEach((item, i) => {
                    let row: ItemAdd = new ItemAdd();
                    if (this.so_id && this.so_id > 0) {
                        row.sales_order = this.so_id
                        row.id = item.id;
                    }
                    else {
                        row.sales_order = null;
                    }
                    row.customer = item.customer;
                    row.suborg = this.suborg_id;
                    row.unique_id = item.unique_id;
                    row.quantity = item.quantity;
                    row.serial_number = item.serial_number;
                    row.section_title = val.title ? val.title : '';
                    row.price = item.price;
                    row.type = item.type;
                    row.is_delete = item.is_delete;
                    row.name = item.name;
                    row.part_number = item.part_number;
                    row.sku = item.sku;
                    row.uom = item.uom;
                    row.primary_image = item.primary_image;
                    row.description = item.description;
                    row.is_show_in_pdf = item.is_show_in_pdf;
                    row.is_billable = item.is_billable;
                    row.order = item.order;
                    itemsAdd.push(row);
                });
            }
        });
        return itemsAdd;
    }

    saveMaterials(message: string){
        let data = this.formatDataForSend();
        if(data && data.length>0){
            let api = (this.so_id>0) ? this.service.itemKitUpdate(data) : this.service.itemKitAdd(data);
            api.subscribe(
                (response)=>{
                    this.groupBysection(response);
                    this.toastr.success(message);
                    this.onUpdate?.emit();
                },
                ()=>{
                    this.toastr.error("Some thing went wrong");
                  }
            );
        }
    }

    deleteItemConfirmation(id, secIndex, rowIndex) {
        popup.ConfirmDeleteWithTitle("Do you want to delete this item", (result) => {
            if (result.isConfirmed) {
                this.deleteItemKit(id, secIndex, rowIndex);
                this.saveMaterials("Item removed successfully")
            }
        });
    }

    deleteItemKit(id, secIndex, rowIndex) {
        if (this.so_id > 0 && id) {
            this.sections[secIndex].rows[rowIndex].is_delete = true;
        }
        else {
            this.deleteRow(secIndex, rowIndex);
        }
    }

    deleteRow(secIndex, rowIndex) {
        if (this.sections[secIndex] && this.sections[secIndex].rows[rowIndex]) {
            this.sections[secIndex].rows.splice(rowIndex, 1);
            this.cdr.markForCheck();
        }
    }

    deleteSectionConfirmation(index) {
        popup.ConfirmDeleteWithTitle("Do you want to remove this section", (result) => {
            if (result.isConfirmed) {
                this.removeSection(index);
            }
        });
    }

    changeShowInPdf(row: ItemRow, index: number) {
        let option: SweetAlertOptions = {
            title: 'Are you sure want to change the Show in Pdf status',
            text: '',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Change',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn',
                htmlContainer: 'font-small-3'
            },
            buttonsStyling: false
        };
        popup.OpenConfirmBox(option, response => {
            if (response.isConfirmed) {
                this.saveMaterials("Successfully changed the Show in PDF status")
            }
            else{
                row.is_show_in_pdf = !row.is_show_in_pdf;
                this.cdr.markForCheck();
            }
        });
    }

    changeIsBillable(row: ItemRow, index: number) {
        let option: SweetAlertOptions = {
            title: 'Are you sure want to change the Is Billable status',
            text: '',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Change',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn',
                htmlContainer: 'font-small-3'
            },
            buttonsStyling: false
        };
        popup.OpenConfirmBox(option, response => {
            if (response.isConfirmed) {
                if(row.is_billable){
                    row.is_show_in_pdf = true;
                }
                this.saveMaterials("Successfully changed the Billable status")
            }
            else{
                row.is_billable = !row.is_billable;
                this.cdr.markForCheck();
            }
        });
    }

    drop(event: CdkDragDrop<string[]>, index: number) {
        moveItemInArray(this.sections[index].rows, event.previousIndex, event.currentIndex);
        this.sections[index].rows.map((item, index) => item.order=index);
        this.saveMaterials("Materials order changed..")
        this.cdr.detectChanges()
    }

    afterTabResize(){
        this.table.recalculate();
    }

}
