import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";
import { BillingStatus } from "../models/enums";
import { ServiceTicketStatus } from "../models/service-ticket";


export class TimeSheetDataHelper{


    static get defaults(): GFilterParam[] { return [
      { displayName: "All Timesheets", colname: '', condition: 'is', value: null, operator: 'AND' },
      { displayName: "Billable", colname: 'billable', condition: 'is', value: 'true', operator: 'AND' },
      { displayName: "Non Billable", colname: 'billable', condition: 'is', value: 'false', operator: 'AND' },
      { displayName: "Unbilled", colname: 'billing status', condition: 'equalto', value: BillingStatus.Un_billed, operator: 'AND' },
      { displayName: "Invoiced", colname: 'billing status', condition: 'equalto', value: BillingStatus.Invoiced, operator: 'AND' },
      { displayName: "Paid", colname: 'billing status', condition: 'equalto', value: BillingStatus.Paid, operator: 'AND' },
      { displayName: "Overdue", colname: 'billing status', condition: 'equalto', value: BillingStatus.Overdue, operator: 'AND' }
    ];}
    
      static get search(): GFilterParam[] { return  [
        {
          displayName: 'Combo Search', colname: '', condition: '', value: '',
          children: [
            { displayName: 'Tmesheet Number', colname: 'timesheet_number', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Location Name', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Service Ticket Number', colname: 'ticket_number', condition: 'contains', value: '', operator: 'OR' },
          ]
        },
        { displayName: 'Timesheet Number', colname: 'timesheet_number', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Service Ticket Number', colname: 'ticket_number', condition: 'contains', value: '', operator: 'OR' }
      ]
    }
    
    
      static get columns(): GFColumn[]{ return [
        { displayName: 'Customer', columnName: 'tenant_customer', columnType: GFColumnTypes.ref },
        {
          displayName: 'Billing Status', columnName: 'billing status', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
          options: [
            { displayName: 'Unbilled', value: BillingStatus.Un_billed },
            { displayName: 'Non Billable', value: BillingStatus.Non_Billable },
            { displayName: 'Invoiced', value: BillingStatus.Invoiced },
            { displayName: 'Paid', value: BillingStatus.Paid },
            { displayName: 'Overdue', value: BillingStatus.Overdue }
          ]
        },
        { displayName: 'Timesheet Date', columnName: 'assigned_date', columnType: GFColumnTypes.date },
        { displayName: 'Created On', columnName: 'created date', columnType: GFColumnTypes.date },
        { displayName: 'Created By', columnName: 'created by', columnType: GFColumnTypes.ref },
      ];
    }
    
      static get sortLookup() { 
          return  {
            customer_name: 'customer_name',
            ticket_number: 'ticket_number',
            created_on: 'created date',
            assigned_date: 'assigned_date',
            total_amount: 'amount'
          };
    }



}