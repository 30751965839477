<div class="group1">
    <h5 class="title1">Customer</h5>
    
    <div class="group-body">
        <div class="ov-row" >
            <label class="col-md-3 p-0 item-label">Name</label>
            <label class="item-label-value badge badge-warning name-badge">{{cust_name}}</label>
        </div>
        <div class="ov-row" >
            <label class="col-md-3 p-0 item-label">Contact</label>
            <label class="item-label-value">{{cust_contact_name}}</label>
        </div>
        <div class="ov-row" >
            <label class="col-md-3 p-0 item-label">Email</label>
            <label class="item-label-value">{{cust_contact_email}}</label>
        </div>
        <div class="ov-row" >
            <label class="col-md-3 p-0 item-label">Phone</label>
            <label class="item-label-value">{{cust_contact_phone | mask:'(000) 000-0000'}}</label>
        </div>
        
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Location</label>
            <!--<label class="item-label-value">{{selected.tenant_customer_location_name}}</label>-->
            <div class="address-column">
                <div>{{location_name}}</div>
                <div>{{location_address}}</div>
                <div *ngIf="location_city">{{location_city}}</div>
                <div *ngIf="location_state || location_zip">{{[location_state,location_zip].join(', ')}}</div>
              </div>
        </div>
        <div  class="col-md-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
        <button class="btn btn-primary" (click)="openFormNew()" *ngIf="!tenant_customer_id">Create New Contact</button>
        </div>
    </div>

    <ng-template #newContactForm let-c="close" let-d="dismiss">
        <div class="modal-header border-0">
            <h5 class="modal-title">New Contact</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body pt-0">
            <quick-contact-form 
            [call_to_suborg_id]="call_to_suborg_id" 
            [call_from]="cust_contact_phone"
            (edit)="editContact($event.contact_id)" 
            (cancel)="onCancelNew()" 
            (error)="onDataError($event)"
            (success)="onDataSubmittedSuccess($event)"
            >
            </quick-contact-form>
        </div>
    </ng-template>

    <ng-template #FormModal let-c="close" let-d="dismiss">
        <div class="modal-header border-0">
            <h5 class="modal-title">Edit Contact</h5>
            
        </div>
        <div class="modal-body pt-0">
            <tenant-customer-contact-form  [bindingModel]="bindingModel" [tenentSelection]="true"
                                          [suborg_id]="call_to_suborg_id" (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)"
                                          (cancel)="onCancel()" [disable_change]='true'  *ngIf="bindingModel">
            </tenant-customer-contact-form>
        </div>
    </ng-template>