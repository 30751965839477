import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { LabelListResponse } from '../../models/label.models';
import { LabelService } from '../../services/label.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { fabric } from 'fabric';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/core/services/base.service';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
  selector: 'app-lable-list',
  templateUrl: './lable-list.component.html',
  styleUrls: ['./lable-list.component.scss']
})
export class LableListComponent extends BaseService implements OnInit {

  public canvas:any;

  public scrollbarH:boolean=false;

  public labelList:LabelListResponse[];

  public selected:LabelListResponse;

  public subs:SubSink=new SubSink();

  public Permissions=PermissionConstants;

  public SelectionType=SelectionType;

  public expanded = true;

  public labelId:number=0;

  public baseUrl:string=environment.apiURL;


  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private toastr:ToastrService,
    private ra: ResourceAccessService, 
    private device: DeviceDetectorService,
    private labelService:LabelService,
    private location: Location,

  ) {
    super();
    this.subs.sink = route.params.subscribe(params => this.labelId = +params.id || 0);
   }

  ngOnInit(): void {

    this.scrollbarH=this.device.isMobile();
    this.getLabelList();


    if (this.labelId > 0) {

        
        this.subs.sink = this.labelService.getLabel(this.labelId).subscribe(response => {


            this.onSelect({selected:[response]})

            this.refreshDataTable();
        });
    }



  }

  getLabelList(){

      this.subs.sink=this.labelService.getAlllabels().subscribe(
        response=>{
          this.labelList=response;
          this.cdr.markForCheck();
        }
      );

  }

  deleteLabel(id:number){

    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
          this.subs.sink=this.labelService.deleteLabel(id).subscribe(
            response=>{
              this.getLabelList();
              this.location.go(`/inventory/labels`);
              this.expanded=true;
              this.cdr.markForCheck();

               popup.CompleteDelete('Label deleted successfully');
              
            }
          );
      }});
  }

  onSelect({ selected }): void {
    let current: LabelListResponse = selected[0];

    console.log(selected);

    if (current && this.hasPermission(this.Permissions.VIEW_INFO)) {
        this.selected = current;
        this.labelId = current.id;
        this.expanded = false;
        this.location.go(`/inventory/labels/${current.id}/view`);

        // if(!this.canvas)
        this.canvas = new fabric.Canvas('canvas');

        this.canvas.setWidth(this.selected.width);
        this.canvas.setHeight(this.selected.length);

        this.canvas.loadFromJSON(this.selected.template, this.canvas.renderAll.bind(this.canvas), function(o, object) {
          object.selectable=false;
        });
        this.refreshDataTable();




    }
}


    closeDetailedView(): void {
      this.expanded = true;
      this.selected = null;
      this.labelId = 0;
      this.location.go(`/inventory/labels`);
      this.cdr.markForCheck();
      this.refreshDataTable()
  }

    refreshDataTable() {
      setTimeout(() => {
          if(this.labelList)
          this.labelList = [...this.labelList];
          this.cdr.markForCheck();
      });
    }

  hasPermission(permission:string){
    if(this.ra.hasPermission(ResourceConstants.INV_LABELS, [permission, this.Permissions.GLOBAL])||this.ra.hasPermission(ResourceConstants.INV_MODULE, [permission, this.Permissions.GLOBAL])){
      return true;
    }
    else{
      return false;
    }
  }



  ngOnDestroy(){
    this.subs.unsubscribe();
  }

}
