<section *ngIf="model">
         <form #formElement="ngForm" (ngSubmit)="onSubmit()" >
                <div class="form-group">
                    <label class="val-required">Name</label>
                    <input type="text" [(ngModel)]="model.additional_field.field_name" #sn="ngModel" placeholder="Additional field name" name="name" class="form-control" required>
                    <small class="form-text text-muted danger" *ngIf="sn.invalid && (sn.dirty || sn.touched)">
                      <div *ngIf="sn.errors.required">
                            Name is required.
                      </div>
                    </small>
                    <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
                </div>
       
                <div class="form-group">
                    <label class="val-required">Values</label>
                    <div class="d-flex mb-1" *ngFor="let value of model.values;let index=index">
                        <ng-container *ngIf="!value.is_delete">

                            <div class="flex-fill">
                                <input type="text" #val="ngModel" [(ngModel)]="model.values[index].field_value" name="value{{index}}" placeholder="Enter value"  class="form-control mr-1" />
                                <small class="form-text text-muted danger" *ngIf="val.invalid && (val.dirty || val.touched)">

                                    <div *ngIf="val.errors.required">
                                        Value is required
                                    </div>
                                </small>
                            </div>
                            <button type="button" class="btn" (click)="deleteValue(index)"><i class="fa fa-trash"></i></button>


                        </ng-container>

           
                        <!-- <button class="btn btn-primary" *ngIf="model.value.length==(index+1)" (click)="addValue()"><i class="fa fa-plus"></i> </button> -->
                    </div>

                    <div class="d-flex justify-content-end mb-1">
                        

                        <div></div>
                        <button type="button" class="btn btn-sm btn-primary" (click)="addValue()"><i class="fa fa-plus"></i> Add </button>
                    </div>

                    <!-- <textarea type="text" [(ngModel)]="model.additional_field.description" name="description" class="form-control"></textarea> -->
                </div>
                <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                    <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                      {{additionalFieldId > 0 ? 'Save Changes' : 'Save'}}
                    </button>
                    <!-- <button type="button" (click)="c('cancel clicked')" class="btn btn-secondary">Cancel</button> -->
                </div>
        </form>
</section>
