<section id="createCanvas" class="pt-2 p-3" >

    <p class="mb-3 mt-3" >Double-click <!-- or Drag and Drop --> the field names from the Available fields column(on the left below) into the label place holder area on the right</p>
<!-- ->{{editingMode}} -->
<div >
    <div class="row  d-flex flex-row" >
        <div class="col-4 items-box">
            <div class="">
                <ng-select #categorySelect placeholder="Select type" [disabled]="lockCategory" class="mb-3" (change)="changeType()" [(ngModel)]="category"  [items]="categoryList" bindLabel="name" bindValue="id" #category1="ngModel" name="item_name" required>

                </ng-select>

                <!-- --{{category}} -->
                <h6 class="pl-1">Available fields</h6></div>

            <div class="mt-3 avail-fields">
                <ul class="item-box-ul d-flex flex-column" cdkDropList  >

                    <li class="ml-1 mb-2 p-2 mr-1 d-flex " (dblclick)="addElement('plaintext')">
                        <span class="flex-grow-1">Plain text</span>
                    </li>
                    <!-- cdkDrag -->
                    <li class="ml-1 mb-2 p-2 mr-1 d-flex " *ngFor="let item of availFields"  (dblclick)="addElement(item)">
                        <span class="flex-grow-1">{{item.title}}</span>
                        <!-- <img src="../../../../../assets/img/gallery/arrows-drag.svg" /> -->
                    </li>

 
                </ul>
            </div>
        </div>

        <div class="col-2 d-flex flex-column align-content-center pt-5" >

            <div *ngIf="canvas&&!canvas.getActiveObject()||canvas&&canvas.getActiveObject()&&canvas.getActiveObject().type!='textbox'" class="mt-5 d-flex flex-column align-content-center">
                <img src="../../../../../assets/img/gallery/big-arrow.svg" class="mb-2" width="100"/>
                <strong class="mb-2">Double-Click <!-- or Drag and Drop --></strong>
                <p>TIP : Add commas, spaces and other text between fields here.</p>
            </div>

            <div class="d-flex flex-column toolbar mt-5" *ngIf="canvas&&canvas.getActiveObject()&&canvas.getActiveObject().type=='textbox'">
                <div role="group" class="btn-group mb-2">
                    <button class="btn btn-sm bg-light-secondary " 
                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='left'}" 
                        (click)="updateTextAlignment('left')">
                        <i class="ft-align-left font-medium-2"></i>
                    </button>
                    <button class="btn btn-sm bg-light-secondary" 
                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='center'}" 
                        (click)="updateTextAlignment('center')">
                        <i class="ft-align-center font-medium-2"></i>
                    </button>
                    <button class="btn btn-sm bg-light-secondary" 
                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='right'}" 
                        (click)="updateTextAlignment('right');">
                        <i class="ft-align-right font-medium-2"></i>
                    </button>
                    
                    <button class="btn btn-sm bg-light-secondary" 
                    [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='justify'}" 
                    (click)="updateTextAlignment('justify')">
                    <i class="ft-align-justify font-medium-2"></i>
                     </button>
                    
                </div>

                <div role="group" class="btn-group mb-2 ml-1">
                    <button type="button" class="btn btn-sm bg-light-secondary" 
                        data-toggle="tooltip" 
                        data-placement="top" 
                        data-original-title="Make font bold" 
                        [ngClass]="{'btn-active':textStyle.fontWeight=='bold'}"
                        (click)="elementBold()"
                        >
                        <i class="ft-bold font-small-3"></i>
                    </button>
                    <button type="button" class="btn btn-sm bg-light-secondary"
                        [ngClass]="{'btn-active':textStyle.fontStyle=='italic'}"
                        (click)="elementItalic()"
                    >
                        <i class="ft-italic font-small-3"></i>
                    </button>
                    <button type="button" class="btn btn-sm bg-light-secondary"
                        [ngClass]="{'btn-active':textStyle.underline==true}"
                        (click)="elementUnderline()"
                    >
                        <i class="ft-underline font-small-3"></i>
                    </button>
                </div>



                <div class="font-size d-flex mb-2" >
                    <div class="mr-1 font-small-3" style="width:100px">Font size </div>
                    <div class="mr-1 font-small-3"><input type="number" [(ngModel)]="textStyle.fontSize"  (change)="fontSizeChange()"/></div>
                    <!-- <span class="font-small-3">Px</span> -->
                </div>

                <div role="group" class="btn-group mb-2 ml-1">
                    <button type="button" class="btn btn-sm bg-light-secondary" data-toggle="tooltip" data-placement="top" data-original-title="Delete" (click)="deleteElement()" >
                        <i class="ft-trash-2 font-small-3"></i> Delete
                    </button>
                </div>
            </div>

            <div>
                    {{selectedElement}}
            </div>

            <!-- width: {{canvas.getActiveObject().width}}, Height: {{canvas.getActiveObject().height}} -->

        </div>

        <div class="col-6 label-box p-3">
            <div class="d-flex flex-row">
                <!-- <span>Dimention</span> -->
                <div class="d-flex flex-row">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Width</span>
                        </div>
                        <input type="number" width="50" (change)="changeDimention()" style="max-width:60px" class="form-control" [(ngModel)]="width"/>
                        <div class="input-group-append">
                          <span class="input-group-text">Inch</span>
                        </div>
                      </div>
                </div>
                <div class="ml-2 mr-2 pt-1">X</div>
                <div class="d-flex flex-row">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Height</span>
                        </div>
                        <input type="number" class="form-control" (change)="changeDimention()" style="max-width:60px" [(ngModel)]="height" aria-label="Amount (to the nearest dollar)">
                        <div class="input-group-append">
                          <span class="input-group-text">Inch</span>
                        </div>
                      </div>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-1">
                <div class="mt-1">
                <span class="text-danger">*</span><input type="text" class="title-input" placeholder="Enter label title" [(ngModel)]="title" />

                </div>

                <div class="d-flex flex-column mt-2 ">
                    <!-- <small><i>Dimention: {{width}}"x{{height}}"</i> <i class="ft ft-edit"></i></small> -->
                    <!-- <small><i>Zoom: {{canvas.getZoom()*100|number: '1.0-0'}}%</i></small> -->
                    <div class="d-flex flex-row align-content-center">
                        <small class="pt-1"><i>Zoom: </i></small>
                        <input type="number" class="percentage-input" width="40" [(ngModel)]="canvasZoom" (change)="zoomChanged(canvasZoom)" />
                        <small class="pt-1"><i>%</i></small>
                    
                    </div>
                </div>

            </div>

            <!-- <div class="d-flex toolbar" *ngIf="canvas&&canvas.getActiveObject()">
                <div role="group" class="btn-group mb-2">
                    <button class="btn btn-sm bg-light-secondary " 
                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='left'}" 
                        (click)="canvas.getActiveObject().textAlign='left';canvas.renderAll()">
                        <i class="ft-align-left font-medium-2"></i>
                    </button>
                    <button class="btn btn-sm bg-light-secondary" 
                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='center'}" 
                        (click)="canvas.getActiveObject().textAlign='center';canvas.renderAll()">
                        <i class="ft-align-center font-medium-2"></i>
                    </button>
                    <button class="btn btn-sm bg-light-secondary" 
                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='right'}" 
                        (click)="canvas.getActiveObject().textAlign='right';canvas.renderAll()">
                        <i class="ft-align-right font-medium-2"></i>
                    </button>
                    
                    <button class="btn btn-sm bg-light-secondary" 
                    [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='justify'}" 
                    (click)="canvas.getActiveObject().textAlign='justify';canvas.renderAll()">
                    <i class="ft-align-justify font-medium-2"></i>
                     </button>
                    
                </div>

                <div role="group" class="btn-group mb-2 ml-1">
                    <button type="button" class="btn btn-sm bg-light-secondary" 
                        data-toggle="tooltip" 
                        data-placement="top" 
                        data-original-title="Make font bold" 
                        [ngClass]="{'btn-active':canvas.getActiveObject().fontWeight=='bold'}"
                        (click)="canvas.getActiveObject().fontWeight=='bold'?canvas.getActiveObject().fontWeight='normal':canvas.getActiveObject().fontWeight='bold';canvas.renderAll()"
                        >
                        <i class="ft-bold font-small-3"></i>
                    </button>
                    <button type="button" class="btn btn-sm bg-light-secondary"
                        [ngClass]="{'btn-active':canvas.getActiveObject().fontStyle=='italic'}"
                        (click)="canvas.getActiveObject().fontStyle=='italic'?canvas.getActiveObject().fontStyle='normal':canvas.getActiveObject().fontStyle='italic';canvas.renderAll()"
                    >
                        <i class="ft-italic font-small-3"></i>
                    </button>
                    <button type="button" class="btn btn-sm bg-light-secondary"
                        [ngClass]="{'btn-active':canvas.getActiveObject().underline==true}"
                        (click)="elementUnderline()"
                    >
                        <i class="ft-underline font-small-3"></i>
                    </button>
                </div>

                <div role="group" class="btn-group mb-2 ml-1">
                    <button type="button" class="btn btn-sm bg-light-secondary" data-toggle="tooltip" data-placement="top" data-original-title="Delete" (click)="deleteElement()" >
                        <i class="ft-trash-2 font-small-3"></i>
                    </button>

                </div>

                <div class="font-size">
                    
                </div>
            </div> -->
            <canvas id="dCanvas"  (keydown)="keyDown($event)" cdkDropList></canvas>

            <br/>
            <h6 class="text-muted">Preview with dummy data</h6>
            <canvas id="dCanvas2"  (keydown)="keyDown($event)" cdkDropList></canvas>


        </div>
    </div>
</div>

    <div class="mt-2 float-right">
        <a class="btn btn-secondary mr-2"  (click)="cancel()">Cancel</a>
        <!-- <button class="btn btn-secondary mr-2">Print</button> -->
        <button class="btn btn-primary" (click)="save()">{{labelId>0?'Update':'Create'}}</button>
    </div>
    <br/><br/>
</section>