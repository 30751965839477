import { id } from 'src/app/core/utils/global.functions';
export class addInvoice{
    public customer:any;
    public title:string;
    public unique_number:string;
    public tenant_customer:any;
    public tenant_customer_contact:number;
    public tenant_customer_location:number;
    public shipping_location:number;
    public terms_and_condition:number;
    public conditions:string="";
    public coverage_type:number=0;
    public status:number;
    public suborg:number;
    public reference_id:string;
    public shipping_date:string
    public payment_terms:number;
    public shipment_method:number;
    public total_price:number;
    public tax:number;
    public mail_sent_date :string;
    public from_module:number;
    public from_unique_number:string;
    public balance_due:number;
    public subtotal:number;
    public tax_amount:number;
    public payments_made:number;



}

export class InvoiceList{
    public created_by:string;
    public created_on:string;
    public created_user:number;
    public customer:number;
    public id:number;
    public is_active:boolean;
    public is_delete:boolean;
    public modified_on:string;
    public modified_user:string;
    public tenant_customer:number;
    public tenant_customer_contact_name:string;

    public tenant_customer_location:number;
    public tenant_customer_location_name:string;
    public tenant_customer_location_address:string;
    public tenant_customer_location_city:string;
    public tenant_customer_location_zip:string;
    public tennant_customer_location_state:string;

    public tenant_customer_name:string;
    public terms_and_condition:string;
    public title:string;
    public unique_number:string;
    public conditions:string;
    public service_contract_status:string;
    public status:number;
    public expired_in_90_days:boolean;
    public coverage_type:number;
    public sc_begins:number=0;
    public warranty:number;
    public price:number;
    public item_price:number=0;
    public duration:number;
    public reference_id:string;
    public shipping_date:string;
    public payment_terms:number;
    public shipment_method:number;
    public total_price:number;
    public payments_terms_name:string;
    public shipment_method_name:string;

    public shipping_location_name:string;
    public shipping_location:string;
    public shipping_location__zip:string;
    public shipping_location_address:string;
    public shipping_location_city:string;
    public shipping_location_state:string;

    public client_viewed:boolean;
    public tax:number;
    public tax_name:string;
    public suborg_name:string;
    public suborg_address:string;
    public suborg_city:string;
    public suborg_zip:string;
    public suborg_state:string;
    public due_date:Date;
    public suborg_primary_image:string;
    public created_user_email:string;
    public tenant_customer_contact_email: string;
    public credit_limit: number;
    public credit_balance: number;
    public credit_enforced: boolean;
    public tenant_customer_contact:number;
    public suborg:number;
    public created_user_ses_verified :boolean
    public mail_sent_date :string;
    public client_viewed_date :string;
    public from_unique_number:string
    public balance_due:number;
    public subtotal:number;
    public tax_amount:number;
    public payments_made:number
    timesheet_total: number;
    material_total: number;
}


export class InvoiceItemsList{
    public associate_item:number;
    public created_on:Date;
    public created_user:number;
    public customer:number;
    public ending_date:Date;
    public id:number;
    public primary_image:string;
    public is_active:boolean;
    public is_delete:boolean;
    public item_name:string;
    public item_price:number;
    public modified_on:string;
    public modified_user:string;
    public part_number:string;
    public starting_date:Date;
    public invoice:number;
    public quantity:number;
    public serial_number:string;
    public coverage_type:number;
    public billing_interval:number;
    public price:number;
    public type:string;
}

export class InvoiceKitsList{
    public associate_kit:number;
    public created_on:Date;
    public created_user:number;
    public customer:number;
    public ending_date:Date;
    public id:number;
    public internal_number:string;
    public is_active:boolean;
    public is_delete:boolean;
    public kit_name:string;
    public modified_on:string;
    public modified_user:string;
    public starting_date:Date;
    public invoice:number;
    public quantity:number;
    public serial_number:string;
    public coverage_type:number;
    public billing_interval:number;
    public price:number;
}

export class InvoiceDashBoardCounts{
    public New: number=0;
    public Draft: number=0;
    public Sent:number=0;
    public Unpaid:number=0;
    public Paid_in_Full: number=0;
    public Partially_Paid: number=0;
    public Void:number=0;
    public Write_Off:number=0;
    public Past_Due:number=0;

}

export enum InvoiceStatus{
    New = <any>8,
    Draft = <any>1,
    Sent = <any>2,
    Unpaid = <any>3,
    Paid_in_Full = <any>4,
    Partially_Paid = <any>5,
    Void = <any>6,
    Write_Off = <any>7,
}

export enum InvoiceAddOnStatus{
    Past_Due = <any>1
}

export enum Status{
    New = 8,
    Draft = 1,
    Sent = 2,
    Unpaid = 3,
    Paid_in_Full = 4,
    Partially_Paid = 5,
    Void = 6,
    Write_Off = 7,
}



export class Section{
    public title:string;
    public rows:ItemRow[]=[];
    public section_total:string;
}

export class ItemAdd{
    public id:number;
    public invoice:number;
    public customer:number;
    public unique_id:number;
    public quantity:number;
    public section_title:string;
    public price:number;
    public type:string;
    public name:string;
    public serial_number:string;
    public is_delete:boolean;
    from_unique_number: string
    ordered_quantity: number = 0;
    invoiced_quantity: any;
    suborg: number;
    part_number: string;
    sku: string;
    uom: string;
    primary_image: string;
    description: string;
    order: number;
    total: string;
    previous_quantity: number;
}


export class ItemRow {
    public id:number;
    public invoice:number;
    public unique_id:number;
    public created_on:string;
    public created_user:number;
    public modified_on:string;
    public modified_user:string;
    public is_delete:boolean;
    public is_active:boolean;
    public serial_number:string;
    public quantity:number=1;
    public section_title:string;
    public price:number;
    public type:InvoiceItemsType;
    public name:string;
    public customer:number;
    public part_number:string;
    public uom:string;
    public expanded:boolean = false;
    public children: ItemRow[];
    public primary_image:string;
    public row_total:string;
    public level:number;
    public color:string;
    public order:number;
    image_link: string;
    from_unique_number: string;
    ordered_quantity : number = 0;
    invoiced_quantity: any;
    sku: string;
    description: string;
    unique_number:string;
    time
    comments
    assigned_date
    total_amount
    previous_quantity: any;
    showErrorMessage: boolean;
}



export class InvoiceItemRow {

    public id: number;

    public item_id: number;

    public itemType: InvoiceItemsType;

    public quantity: number = 1;

    public kit_id: number;

    public part_number: string;

    public sell_price: number = 0;

    public sell_price_calc: number = 0;

    public primary_image: string;

    public name: string;

    public guid: string;

    public customer: number;

    public children: InvoiceItemRow[];

    public expanded:boolean = false;

    public order:number;

    public serial_number:string;

    public coverage_type:number;

    public billing_interval:number;

    public price:number;


    constructor(){
        this.guid = id();
    }
}

export enum InvoiceItemsType {
    ITEM = 'item',
    KIT = 'kit',
    TIMESHEET = 'timesheet',
    CUSTOM = 'custom'
}

export class Email{

    public from: string;

    public to: string;

    public cc: string;

    public subject: string;

    public body: string;

    public attach_invoice : boolean

}

export class EmailHistory{

    public created_on__date: string

    public dcount: number
}


