import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { WarehouseService } from 'src/app/modules/inventory/services/warehouse.service';
import { SubSink } from 'subsink';
import { SOComments } from '../../models/sales-orders.models';
import { SalesOrdersService } from '../../services/sales-orders.service';

import { ServiceContractService } from '../../services/service-contract.service';

@Component({
  selector: 'so-comments',
  templateUrl: './so-comments.component.html',
  styleUrls: ['./so-comments.component.scss']
})
export class SoCommentsComponent implements OnInit {


  @Input() soId: number=0;

  // @Input() set warrantyId(value: number) {
  //   this.scId = value;
  //   this.getComments(this.scId);
  // }

  @Input() timelineView: boolean = true;

  get _soId() { return this.soId; }

  public comments: SOComments[];

  public model: SOComments = new SOComments();

  public userName: string;

  public rows: number = 1;

  public currentUser: number;

  public isAdmin: boolean;

  private subs: SubSink = new SubSink();

  constructor(private service: SalesOrdersService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) {

    this.currentUser = service.userId;
    this.isAdmin = service.isAdmin;
  }

  ngOnInit() {
    if(this.soId>0){
      this.getComments(this.soId);
    }
    let userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
    if (userAuth) {
      this.userName = `${userAuth.firstname || ''} ${userAuth.lastname || ''}`;
    }
    if (!this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  getComments(scId: number) {
    if (scId > 0) {
      this.subs.sink = this.service.getComments(scId).subscribe(resp => {
        this.comments = resp;
        this.cdr.markForCheck();
      });
    }
    else {
      this.comments = [];
    }
  }

  headerInputFocus() {
    if (this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  createComment() {

    if(!this.model.comments) return;

    this.model.sales_order = this.soId;

    this.model.customer = this.service.customerId;
    this.model.inputRows=undefined;
    this.subs.sink = this.service.createComment([this.model], this.soId).subscribe(
      resp => {
        this.comments = [...resp, ...this.comments];
        this.model.inputRows = 1;
        this.model.comments = '';
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to save comment')
    );
  }

  updateComment(comment: SOComments, value: string) {

    if(!value) return;

    let com = { ...comment };
    com.comments = value;
    this.subs.sink = this.service.updateComment(comment.id, com).subscribe(
      () => {
        comment.comments = value;
        comment.edit = false;
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to modify comment')
    );
  }

  deleteComment(commentid: number) {
    this.subs.sink = this.service.deleteComment(commentid).subscribe(
      () => {
        this.comments = this.comments.filter(c => c.id !== commentid);
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to delete comment')
    );
  }

  public saveComment(soId: number, next?: (value: any) => any) {
    if (!this.model.comments && next) {
      next(null);
    }
    else {
      this.model.sales_order = soId;
      this.model.customer = this.service.customerId;
      this.subs.sink = this.service.createComment([this.model], this.soId).pipe(
        catchError(() => [])
      ).subscribe(resp => {
        if (next) { next(resp) }
      });
    }
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }


}
