import { User } from "src/app/modules/user/models/user";

export class UserWrapper {

    user: User;

    custname: string;

    rolename: string;

    id: number;

    preffered_user: boolean=false;

    user_firstname: string;

    user_lastname: string;

    user_email: string;

    is_subscribed: string;

    user_status: string;

    sip_name: string;

    call_end_time: string;

    is_dispatch:boolean;

}

export class UserWrapperFlat extends User {

    preffered_user: boolean=false;

    customer_name: string;

    role_name: string;

    user_status: string;

    sip_name?: string;

    call_end_time?: string;
}