import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { Location } from '@angular/common';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as popup from 'src/app/core/utils/popup.functions';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as _ from 'lodash';
import { InvoiceList, InvoiceStatus, Section } from '../../models/invoice.models';
import { InvoiceService } from '../../services/invoice-service';
import { InvoiceComponent } from '../../../sales-shared/sales-invoice/invoice/invoice.component';
import { InvoiceDataHelper } from '../../helpers/invoice-data.helper';
import { InvoiceDashboardComponent } from '../invoice-dashboard/invoice-dashboard.component';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {

  @Input() viewDashboard: boolean = true;

  public expanded: boolean = true;

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild(InvoiceDashboardComponent) dashboard: InvoiceDashboardComponent;

  @ViewChild(InvoiceComponent) InvoiceView: InvoiceComponent;

  @ViewChild('dataTable') dataTable:DatatableComponent;

  public selected: any;

  private invoiceId = 0;

  public subs: SubSink = new SubSink();

  public SelectionType = SelectionType;

  public rows: InvoiceList[] = [];


  public SOStatus = InvoiceStatus;


  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

  public module: ModuleConstants = ModuleConstants.INVOICE;

  public scrollbarH: boolean = false;

  public defaults: GFilterParam[] = InvoiceDataHelper.defaults;

  public search: GFilterParam[] = InvoiceDataHelper.search;


  public columns: GFColumn[] = InvoiceDataHelper.columns

  public sortLookup = InvoiceDataHelper.sortLookup

  public UIPermissions: UIPermission;

  public Permissions = PermissionConstants;

  public soStatus=InvoiceStatus;

  public statusButton:number=3;

  public dropdownStatus=[
                  {"id":8,"value":"Mark as New"},
                  {"id":1,"value":"Move to Draft"},
                  {"id":2,"value":"Send to Customers"},
                  {"id":3,"value":"Unpaid"},
                  {"id":4,"value":"Paid in full"},
                  {"id":5,"value":"Partially paid"},
                  {"id":6,"value":"Void"},
                  {"id":7,"value":"Write off"}
                ];

  public statusActionId=3;

  public get activeDropdownStatus(){
      let test=this.statusActionId;
      return _.find(this.dropdownStatus,function(val)  {
        return val.id==test?val.value:'';
      });
  }

  constructor(private toastr: ToastrService,
    public location: Location,
    private service: InvoiceService,
    private cdr: ChangeDetectorRef,
    private ra: ResourceAccessService,
    activeRoute: ActivatedRoute,
    private device: DeviceDetectorService,
    private router: Router) {

    this.subs.sink = activeRoute.params.subscribe(params => this.invoiceId = params.id || 0);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_INVOICES, ResourceConstants.SALES_MODULE);

    // this.UIPermissions.viewInfo=false;

    // console.log(this.UIPermissions);

    this.scrollbarH = this.device.isMobile();
    if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state){
        this.invoiceId =JSON.parse(this.router.getCurrentNavigation().extras.state.invoice_id) as number
        this.getById(this.invoiceId);
    }
  }

  ngOnInit(): void {
    this.getData(null);
    if (this.invoiceId != 0) {
      this.getById(this.invoiceId);
    }
    // this.dataTable.recalculate();
  }
  ngAfterViewInit() {

    setTimeout(()=>{
      this.dataTable.recalculate();
    }, 800);

  }

  ngAfterViewChecked() {
    if (this.dataTable && this.dataTable.rowDetail) {
      this.dataTable.rowDetail.expandAllRows();
      this.cdr.detectChanges();
    }
  }

  goToLocation(path: string) {
    this.location.go(path);
  }

  onSelect({ selected }): void {
    let wrapper = selected[0];
    this.selected = wrapper;
    if(this.selected&&this.UIPermissions.viewInfo){
        this.getById(this.selected.id);
    }
  }

  getData(filters: GFilterParam[], offset: number = 0) {
    let option = { sort: this.sort, filters: filters };
    this.subs.sink = this.service.getAll(option, offset).subscribe(response => {
      this.rows = response.result;
      this.page.count=response.count;

      console.log(response.result);
      // this.setPagination(offset, response.count);
      this.cdr.markForCheck();
      this.dataTable.recalculate();
    });
  }

  updateSO(){
    this.service.updateIN(this.selected, this.selected.id).subscribe(
      response=>{
        this.toastr.error("Invoice updated successfully");

    },
    ()=>{
      this.toastr.error("Sorry error occured during process");
   });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }


  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter?.getDataFilters() || [], offset);
  }

  getById(id: number) {
    this.subs.sink = this.service.viewIN(id).subscribe(response => {
      this.selected = response;
      this.expanded = false;
      this.location.go('sales/invoice/' + this.selected.id + '/view')
    });
  }

  deleteSC(row: InvoiceList) {
    let data = { "is_delete": true };
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.service.patchIN(data, row.id).subscribe(
          () => {
            this.rows = this.rows.filter(x => x.id != row.id);
            this.cdr.markForCheck();
            this.closeDetailedView();
            popup.CompleteDelete('Invoice deleted successfully');
            this.reloadData();
          },
          () => {
            this.toastr.error("Unable to delete Invoice");
          }
        );
      }
    });
  }

  applyFilter(params: GFilterParam[]) {

    params.forEach((value,index)=>{
      if(value.colname=="service_contract_status"&&value.value=="Expired_in_90_days"){
        value.colname="expired_in_90_days";
      }
    });

    this.getData(params);
  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go(`/sales/invoice`);
    this.refreshDataTable();
  }

  reloadInvoiceView(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/sales/invoice/'+this.selected.id+'/view']);
    this.dashboard.getDashboardCounts();
  }



  refreshDataTable() {
    setTimeout(() => {
      this.cdr.markForCheck();
      this.rows = [...this.rows];
    });
  }


  statusClass(id: number) {

    let status = {
                    "8": "status-new",
                    "1": "status-draft",
                    "2": "status-send-customer",
                    "3": "status-unpaid",
                    "4": "status-paid-full",
                    "5": "status-partially-paid",
                    "6": "status-void",
                    "7": "status-write-off",
                  };

    return status[id];

  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.SALES_INVOICES, [permission, this.Permissions.GLOBAL], ResourceConstants.SALES_MODULE)
  }

  reloadData() {
    this.getData(this.dataFilter?.getDataFilters() || [], this.page.offset);
    this.dashboard.getDashboardCounts();
  }

  setFilter(obj: any) {
    console.log(obj.colname+','+obj.value);
    this.dataFilter.setDefaultFilter(obj.colname, obj.value);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  trimTime(date:string){
    if(date&&date.length>0){
      let data=date.split('T');
      return data[0];
    }
    else{
      return '';
    }
 }

 comingInFuture(){
   window.alert("Sit tight, this feature is coming soon");
 }

 dateDiff(date:Date){
   if(date!=null){
    let dueDate = new Date(date);
    let currentDate = new Date();

    let days = Math.floor((dueDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    if(days>=0){
      return days;

    }
    else{
      return 0;
    }
   }
   else{
     return null;
   }

 }

 onClone(elem :ElementRef){
    console.log(elem.nativeElement)
 }

 afterTabResize(){
    this.dataTable.recalculate();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
