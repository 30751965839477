<div class="modal-header">
    <div class="modal-title">
        <div>Options</div>
    </div>
    <button type="button" class="close stock-close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group">
        <label class="val-required">If no key is pressed</label>
        <ng-select class="form-select"
            placeholder="Select option"
            [searchable]="false"
            [(ngModel)]="noKeyPressed"
            [clearable]="false" [ngModelOptions]="{standalone: true}"
            appendTo="body"
            (change)="noKeyChange()">
        <ng-option *ngFor="let order of menuNumbers" [value]="order.id">{{order.name}}</ng-option>
    </ng-select>
    </div>
    <div class="form-group">
        <label class="val-required" for="unassigned-key" style="vertical-align: middle;">If unassigned key is pressed</label>
        <ng-select class="form-select"
            placeholder="Select option"
            [searchable]="false"
            [(ngModel)]="unAssignedKey"
            [clearable]="false" [ngModelOptions]="{standalone: true}"
            appendTo="body"
            (change)="unAssignedChange()">
        <ng-option *ngFor="let order of menuNumbers" [value]="order.id">{{order.name}}</ng-option>
    </ng-select>
    </div>
</div>
<div class="modal-footer">
    <div class="d-flex flex-row justify-content-end">
        <button type="button" (click)="d('cancel click')" class="btn btn-secondary">Close</button>
    </div>
</div>