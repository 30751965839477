import { RoleService } from 'src/app/modules/roles/services/role.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Role } from 'src/app/modules/roles/models/role';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-role-select',
    templateUrl: './role-select.component.html',
    styleUrls: ['./role-select.component.scss']
})
export class RoleSelectComponent implements OnInit, OnDestroy, OnChanges {

    @Input() value: number = 0;

    @Input() readonly: boolean = false;

    @Output() valueChange = new EventEmitter<number>();

    private innerCustomerId:number;

    @Input() set customerId(value:number) {

        this.innerCustomerId = value;

        if (value) {
            this.getData();
        }
    }

    get customerId() { return this.innerCustomerId; }

    public roles: Role[];

    public selectedRole: number;

    private subs:SubSink = new SubSink();

    constructor(private roleService: RoleService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}

    getData() {
        this.subs.sink = this.roleService.rolesByCustomer(this.innerCustomerId).subscribe(roles => {
            this.roles = roles;
            this.cdr.markForCheck();
        });
    }

    onValueChanged(role: Role) {
        this.value = role ? role.id : null;
        this.valueChange.emit(role ? role.id : null);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && changes.value.currentValue) {
            this.selectedRole = changes.value.currentValue;
            this.cdr.markForCheck();
        }
    }

    getRoleType(customerId: number) {
        return customerId == this.roleService.customerId ? "PRIVATE" : "SYSTEM";
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
