import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Material } from '../../../models/items';
import { SubSink } from 'subsink';
import { MaterialService } from '../../../services/materials.service';

@Component({
    selector: 'material-form',
    templateUrl: './material-form.component.html',
    styleUrls: ['./material-form.component.scss']
})
export class MaterialFormComponent implements OnInit, OnDestroy {

    public model: Material;

    @Input() materialId: number = 0;

    @Output() onSuccess = new EventEmitter<{ response: Material, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    public nameExist: boolean = false;

    private subs:SubSink = new SubSink();

    constructor(private service: MaterialService, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.materialId > 0) {
            this.subs.sink = this.service.getById(this.materialId).subscribe(obj => {
                this.model = obj;
                this.cdr.markForCheck();
            });
        }
        else {
            this.model = this.service.createModel(Material);
        }
    }

    onSubmit(): void {

        let api: Observable<Material>;

        if (this.materialId > 0) {
            api = this.service.update(this.materialId, this.model);
        }
        else {
            api = this.service.create(this.model);
        }

        this.subs.sink = api.subscribe(
            resp => {
                let msg = this.materialId > 0 ? 'Material info updated' : 'New Material added';
                this.nameExist = false;
                this.onSuccess?.emit({ response: resp, message: msg });
            },
            error => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.onError?.emit(error);
                }
            }
        )
    }

    onCancelClicked(): void { this.onCancel?.emit(); }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
