import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { BlockListRequest, BlockListResponse } from '../models/block-list.model';

@Injectable({
  providedIn: 'root'
})
export class BlockListService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
    let url = `${this.baseURL}/blocked-numbers/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<BlockListResponse[]>>(url, request);
  }

  create(payload: BlockListRequest) {
    return this.http.post<BlockListResponse>(`${this.baseURL}/blocked-numbers/`, payload);
  }

  delete(id: number) {
    return this.http.delete<{ status: boolean}>(`${this.baseURL}/blocked-numbers/${id}/`);
}
}
