import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { Catalog } from '../../models/catalogue.models';
import * as popup from 'src/app/core/utils/popup.functions';
import { CatalogueService } from '../../services/catalogue-service.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'catalog-view',
  templateUrl: './catalog-view.component.html',
  styleUrls: ['./catalog-view.component.scss']
})
export class CatalogViewComponent implements OnInit {
  
  // public catalog:Catalog=new Catalog();

  public subs:SubSink=new SubSink();

  @Input() catalog:Catalog;

  @Output() onClose = new EventEmitter<void>();

  @Output() onDelete = new EventEmitter<number>();


  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;


  public fileUrl: string = environment.apiURL + '/static/files/';


  constructor(private ra:ResourceAccessService, 
    private catalogService:CatalogueService, 
    private cdr: ChangeDetectorRef, 
    private toastr:ToastrService) { }

  ngOnInit(): void {

    console.log('YOu are here');
  }

  deleteItem() {
    this.onDelete?.emit(this.catalog.id);
  }

  closeItemView(): void {
    this.onClose?.emit();
  }

  changeShowPrice() {
    let opt = {
        title: `Are you sure you want to ${this.catalog.show_price ? 'Show Price on Catalog' : 'Disable Show Price on Catalog'}?`,
        text: this.catalog.name,
        current: this.catalog.show_price,
    };
    popup.ConfirmActivate(opt, (result) => {
        if (result.isConfirmed) {
            this.subs.sink = this.catalogService
                .updateCatalog(this.catalog.id, this.catalog)
                .subscribe(
                    () => { },
                    () => {
                        this.catalog.show_price = !this.catalog.show_price;
                        this.cdr.markForCheck();
                        this.toastr.error('Failed to change status');
                    }
                );
        } else {
            this.catalog.show_price = !this.catalog.show_price;
            this.cdr.markForCheck();
        }
    });
}

  changeStatus() {
    let opt = {
        title: `Are you sure you want to ${this.catalog.is_active ? 'Activate catalog' : 'Deactivate Catalog'}?`,
        text: this.catalog.name,
        current: this.catalog.is_active,
    };
    popup.ConfirmActivate(opt, (result) => {
        if (result.isConfirmed) {
            this.subs.sink = this.catalogService
                .updateCatalog(this.catalog.id, this.catalog)
                .subscribe(
                    () => { },
                    () => {
                        this.catalog.is_active = !this.catalog.is_active;
                        this.cdr.markForCheck();
                        this.toastr.error('Failed to change status');
                    }
                );
        } else {
            this.catalog.is_active = !this.catalog.is_active;
            this.cdr.markForCheck();
        }
    });
}

  hasPermission(resource: string, permission: string) {
      return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE)||this.isSuperAdmin;
  }

  hasPermissionAny(resource: string, permission: string[]) {
      return this.ra.hasPermission(resource, permission, ResourceConstants.INV_MODULE)||this.isSuperAdmin;
  }

  downloadPDF()
  {
       this.subs.sink = this.catalogService.getPDF(this.catalog.file_name).subscribe(blob=>{

        const file =  new Blob([blob], {type: 'application/pdf'});
        var FileSaver = require('file-saver');
        FileSaver.saveAs(file, this.catalog.file_name);
       })
  }

  get isSuperAdmin(){
    return this.catalogService.isSuperUser;
  }


  ngOnDestroy(): void {
    this.subs?.unsubscribe();
}

}
