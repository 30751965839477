import { CustomerService } from 'src/app/modules/customer/services/customer.service';
import { RoleService } from './../../services/role.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '../../models/role';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { SuborgService } from 'src/app/modules/suborg/services/suborg.service';
import { SubOrg } from 'src/app/modules/suborg/models/suborg.models';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-role-view',
    templateUrl: './role-view.component.html',
    styleUrls: ['./role-view.component.scss']
})
export class RoleViewComponent implements OnInit, OnDestroy {

    public roleId: number;

    public role: Role;

    public customerTitle: string;

    public customerName: string;

    public isEditable: boolean = true;

    public isSuperAdmin: boolean;

    public previlagesCount: number = 0;

    public gridViewsCount: number = 0;

    public systemRole: boolean;

    public suborgs: Observable<SubOrg[]>;

    private subs: SubSink = new SubSink();

    constructor(route: ActivatedRoute,
        private roleService: RoleService,
        private customerService: CustomerService,
        private cdr: ChangeDetectorRef,
        private suborgService: SuborgService,
        private toastr: ToastrService,
        private router: Router) {

        this.isSuperAdmin = this.roleService.isSuperUser;
        this.subs.sink = route.params.subscribe(params => this.roleId = +params.id || 0);
    }
    ngOnInit() {
        this.getData();
        this.getSuborgs();
    }

    getData() {
        if (this.roleId > 0) {
            this.subs.sink = this.roleService.getById(this.roleId).subscribe(role => {
                this.role = role;
                this.systemRole = this.roleService.isSystemRole(role);
                if (role.customer !== this.roleService.customerId && !this.isSuperAdmin) {
                    this.isEditable = false;
                }
                this.cdr.markForCheck();
                this.getCustomerName(role.customer);
            });
        }
    }

    getSuborgs() {
        this.suborgs = this.suborgService.getRoleSuborgs(this.roleId);
    }

    getCustomerName(customerId: number) {
        if (this.isSuperAdmin) {
            if (this.customerService.customerId == customerId) {
                this.customerName = "SYSTEM ROLE";
            }
            else {
                this.subs.sink = this.customerService.getById(customerId).subscribe(customer => {
                    this.customerName = customer.name;
                    this.customerTitle = `Account : `
                    this.cdr.markForCheck();
                });
            }
        }
        else {
            this.customerName = this.roleService.customerId == customerId ? "PRIVATE ROLE" : "SYSTEM ROLE";
        }
    }

    deleteRole() {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.roleService.delete(this.roleId).subscribe(
                    () => {
                        popup.CompleteDelete('Role has been deleted.', () => {
                            this.router.navigate(['/roles']);
                        });
                    },
                    () => this.toastr.error('Failed to delete Role')
                );
            }
        });
    }

    cloneRole(): void {
        this.subs.sink = this.roleService.clone(this.roleId).subscribe(
            cloned => {
                this.toastr.success(`Role cloning complete`);
                this.router.navigate(['/roles', cloned.role.id, 'view']);
                this.roleId = cloned.role.id;
                this.getData();
            },
            _error => this.toastr.error(`Failed to clone role ${this.role.name}`)
        );
    };

    changeStatus(): void {
        let opt = {
            title: `Are you sure you want to ${this.role.is_active ? 'Activate Role' : 'Deactivate Role'}?`,
            text: this.role.name,
            current: this.role.is_active,
        }
        popup.ConfirmActivate(opt, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.roleService.patch(this.roleId, { is_active: this.role.is_active }).subscribe(
                    role => { },
                    () => {
                        this.role.is_active = !this.role.is_active;
                        this.toastr.error('Failed to change status');
                        this.cdr.markForCheck();
                    }
                );
            }
            else {
                this.role.is_active = !this.role.is_active;
                this.cdr.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
