<section class="common-grid-view service-ticket">
    <div  class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100" >
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter
                    [defaultFilters]="defaults"
                    [module]="module"
                    [searchFilters]="search"
                    [newButton]="UIPermissions.create&&!readonly"
                    [newButtonUrl]="['/service-tickets/new']"
                    [expanded]="expanded"
                    [columns]="columns"
                    [resetToCurrentDashData]="true"
                    (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'customer_type'" let-row="row">
                            <customer-type-select class="ng-select-sm" [(value)]="row.value"></customer-type-select>
                        </ng-template>
                        <ng-template filterControl [column]="'industry'" let-row="row">
                            <industry-select class="ng-select-sm" [(value)]="row.value"></industry-select>
                        </ng-template>
                        <ng-template filterControl [column]="'payment_terms'" let-row="row">
                            <payment-term-select class="ng-select-sm" [(value)]="row.value"></payment-term-select>
                        </ng-template>
                        <ng-template filterControl [column]="'currency'" let-row="row">
                            <currency-select class="ng-select-sm" [(value)]="row.value"></currency-select>
                        </ng-template>
                        <ng-template filterControl [column]="'rep_id'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                        <ng-template filterControl [column]="'created_user'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                        <ng-template filterControl [column]="'assigned_user'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                        <ng-template filterControl [column]="'assigned_user_group'" let-row="row">
                            <user-group-select class="ng-select-sm" [(value)]="row.value"></user-group-select>
                        </ng-template>
                        <ng-template filterControl [column]="'product_type'" let-row="row">
                            <product-type-select class="ng-select-sm" [(value)]="row.value"></product-type-select>
                        </ng-template>
                        <ng-template filterControl [column]="'escalation_period'" let-row="row">
                            <escalation-select class="ng-select-sm" [(value)]="row.value"></escalation-select>
                        </ng-template>
                        <ng-template filterControl [column]="'reason_for_call'" let-row="row">
                            <call-reason-select class="ng-select-sm" [(value)]="row.value"></call-reason-select>
                        </ng-template>
                        <ng-template filterControl [column]="'priority'" let-row="row">
                            <priority-select class="ng-select-sm" [(value)]="row.value"></priority-select>
                        </ng-template>
                        <ng-template filterControl [column]="'next_action'" let-row="row">
                            <action-select class="ng-select-sm" [(value)]="row.value"></action-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                        [scrollbarH]="true"
                        class="bootstrap core-bootstrap st-table"
                        [columnMode]="'force'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        rowHeight="auto"
                        [rows]="rows"
                        [selectionType]="SelectionType.single"
                        (select)="onSelect($event)"
                        [sorts]="[{prop:'created_on',dir:'desc'}]"
                        [count]="page.count"
                        [externalPaging]="true"
                        [offset]="page.offset"
                        [externalSorting]="true"
                        [limit]="page.limit"
                        (page)="setPage($event)"
                        (sort)="onSort($event)">
                            <ngx-datatable-row-detail [rowHeight]="75" #myDetailRow>
                                <ng-template let-row="row" let-value="value" ngx-datatable-row-detail-template>
                                    <div class="row row-detail">
                                        <div class="customer-contact-details row-item" [ngClass]="expanded ? 'col-md-2' : 'col-md-6'">
                                            <div class="customer-name">
                                                <div class="badge badge-warning name-badge w-100">{{row.tenant_customer_name}}</div>
                                            </div>
                                            <div *ngIf="row.tenant_customer_contact_name" class="contact">
                                                <img src="../../../../../../assets/img/gallery/user-avatar.jpg" class="rounded-pill img-view" alt="Cinque Terre">
                                                <span class="contact-name">{{row.tenant_customer_contact_name}}</span>
                                            </div>
                                        </div>
                                        <div class="customer-location-details row-item" [ngClass]="expanded ? 'col-md-2' : 'col-md-6'">
                                            <div class="div">
                                                <label for="" class="item-head">Location</label>
                                            </div>
                                            <div class="location">
                                                <span>{{row?.tenant_customer_location_name}}</span>
                                                <span>{{row?.tenant_customer_location_address}}</span>
                                                <div>
                                                    <span>{{row?.tennant_customer_location_state}}</span>
                                                    <span *ngIf="row?.tennant_customer_location_state && row?.tenant_customer_location_city">,</span>
                                                    <span>{{row?.tenant_customer_location_city}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 row-item" *ngIf="expanded">
                                            <div>
                                                <label class="item-head">Escalation</label>
                                            </div>
                                            <div>
                                                <div >
                                                    {{row.escalation_period_name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 row-item" *ngIf="expanded">
                                            <div>
                                                <label class="item-head">Next Action</label>
                                            </div>
                                            <div>
                                                <div >
                                                    {{row.next_action_name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 row-item" *ngIf="expanded">
                                            <div>
                                                <label class="item-head">Assigned To</label>
                                            </div>
                                            <div>
                                                <div class="d-flex align-items-center" *ngIf="row.assigned_user else userGroupAvatar">
                                                    <user-card [name]="row.assigned_user_name" [role]="row.assigned_user_rolename"></user-card>
                                                </div>
                                                <ng-template #userGroupAvatar>{{ row.assigned_user_group_name }}</ng-template>
                                            </div>
                                        </div>
                                        <div class="col-md-1 row-item" *ngIf="expanded">
                                            <div>
                                                <label class="item-head">Suborg</label>
                                            </div>
                                            <div>
                                                <div>{{row.suborg_name}}</div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-1 row-item" *ngIf="expanded" style="justify-content: center;">
                                            <div>
                                                <img *ngIf="row.tenant_customer_is_vip" src="assets/img/svg/vip.svg">
                                            </div>
                                        </div> -->
                                        <div class="col-md-1 flex-row row-item align-items-center" *ngIf="expanded" style="justify-content: center;">
                                            <div>
                                                <img *ngIf="row.tenant_customer_is_vip" src="assets/img/svg/vip.svg">
                                            </div>
                                            <div ngbDropdown container="body" *ngIf="expanded&&(UIPermissions.viewInfo||UIPermissions.edit||UIPermissions.delete)">
                                                <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                        (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                    <i class="ft-more-vertical" style="font-size: large;color: #370D7E !important;"></i>
                                                </button>
                                                <div ngbDropdownMenu>
                                                    <button *ngIf="UIPermissions.viewInfo" class="w-100" (click)="onSelect({selected:[row]})"
                                                            ngbDropdownItem>
                                                        <i class="ft-book-open mr-1"></i> View
                                                    </button>
                                                    <a class="w-100" *ngIf="UIPermissions.edit" [routerLink]="['/service-tickets',row.id,'edit']"
                                                    [ngClass]="{'disable-btn' : row.status == 6 || row.status == 7}" ngbDropdownItem>
                                                        <i class="ft-edit mr-1"></i> Edit
                                                    </a>
                                                    <button *ngIf="UIPermissions.delete" class="w-100 bg-light-danger" (click)="deleteRow(row)"
                                                    [ngClass]="{'disable-btn' : row.status == 6 || row.status == 7}" ngbDropdownItem>
                                                        <i class="ft-trash-2 mr-1"></i> Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-row-detail>
                            <ngx-datatable-column name="Created Date" prop="created_on" [minWidth]="150">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.created_on | date:'MM/dd/YYYY h:mm a'}}</div>
                                    <div>{{row.unique_number}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Title" prop="title" *ngIf="expanded"></ngx-datatable-column>
                            <ngx-datatable-column name="Total" [minWidth]="180" [cellClass]="'so-total-cell'">
                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                    <ng-container>
                                        <div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <label class="item-head">ST Total</label>
                                                </div>
                                                <div>
                                                    <div>{{row.total_price.toFixed(2) | currency}}</div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <label class="item-head">Inv.Total</label>
                                                </div>
                                                <div>
                                                    <div>{{row.invoiced_total.toFixed(2) | currency}}</div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <label class="item-head">Balance</label>
                                                </div>
                                                <div>
                                                    <div>{{row.balance_due.toFixed(2) | currency}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="!expanded" [sortable]="false" [cellClass]="'status-column'">
                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                    <ng-container *ngIf="StatusMap[row.status || STStatus.New] as map">
                                        <div [class.status-badge]="true" [class]="map.class">{{map.displayName}}</div>
                                        <div class="badge bg-light-info mt-1">{{row.reason_for_call_name}}</div>
                                        <div class="font-weight-500" [style.color]="row.color">
                                            {{row.priority_name}}
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Reason for Service" prop="reason_for_call_name" [sortable]="false" [minWidth]="150">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="badge bg-light-info mr-1 mb-1">{{row.reason_for_call_name}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Priority" *ngIf="expanded" prop="priority_name" [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="font-weight-500" [style.color]="row.color">
                                        {{row.priority_name}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Billing" *ngIf="expanded" prop="billing_status">
                                <ng-template let-row="row" let-billing_status="value" ngx-datatable-cell-template>
                                    <ng-container *ngIf="STBillingStatusMap[billing_status] as map">
                                        <div [class.status-badge]="true" [class]="map.class">{{map.displayName}}</div>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="status" [sortable]="false">
                                <ng-template let-row="row" let-status="value" ngx-datatable-cell-template>
                                    <ng-container *ngIf="StatusMap[status || STStatus.New] as map">
                                        <div [class.status-badge]="true" [class]="map.class">{{map.displayName}}</div>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="table.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" parellaxTabSlider
            [width]="950" [minWidth]="800" (resized)="afterTabResize()" [page]="'service_ticket'" [target]="slideExpandTarget"
            *ngIf="!expanded && selected">
                <div #slideExpandTarget class="parellax-detail-content">
                    <service-ticket-view [item]="selected" (onClose)="closeDetailedView()" (onDelete)="deleteRow($event)"
                    (onRefresh)="refreshDataTable()"></service-ticket-view>
                </div>
            </div>


        </div>
    </div>
</section>
