export class SpamSettings {

    public id: number;

    public live_mode: boolean = false;

    public duration: number;

    public renewal_call_no: number;

    public renewal_days: number;

    public renewal_period: number;

    public renewal_period_type: string;

}

export class SpamConditions {

    public id: number;

    public no_of_calls: number;

    public duration: number;

    public duration_type: string;

    public period: number;

    public period_type: string;
}

export class allowSettings {

    public created_on: string;

    public created_user_id: number;

    public default_for_all: number;

    public id: number;

    public min_call_duration: number;

    public modified_on: string;

    public modified_user_id: number;
}
export class AutomatedBlockList {

    public spam_settings: SpamSettings[] = [{
                                                "id":null,
                                                "live_mode": false,
                                                "duration": null,
                                                "renewal_call_no": null,
                                                "renewal_days": null,
                                                "renewal_period":null,
                                                "renewal_period_type":"Month"
                                            }];

    public spam_conditions: SpamConditions[] = [];

    public allowlist_settings: allowSettings = new allowSettings();

}

export const Test = [
    {
        "id": null,
        "no_of_calls": null,
        "duration": null,
        "duration_type": "Minute",
        "period": null,
        "period_type": "Week"
    },
    {
        "id": null,
        "no_of_calls": null,
        "duration": null,
        "duration_type": "Minute",
        "period": null,
        "period_type": "Week"
    },
    {
        "id": null,
        "no_of_calls": null,
        "duration": null,
        "duration_type": "Minute",
        "period": null,
        "period_type": "Week"
    }
]