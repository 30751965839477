import { ChangeDetectorRef, EventEmitter, Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ItemWareHouseStock, StockInfo } from '../../models/items';
import { SerialNumberRequest, WareHouse, WareHouseLocation } from '../../models/warehouse.models';
import { ItemService } from '../../services/item.service';
import { WarehouseService } from './../../services/warehouse.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { InvCommonService } from '../../services/inv-common.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-item-stock',
    templateUrl: './item-stock.component.html',
    styleUrls: ['./item-stock.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ItemStockComponent implements OnInit {

    private _itemId: number;

    deleteArray: any;

    total_quantity: number;

    total_available_quantity: number;

    showStockInfo: boolean  = false;

    isAllSuborg: boolean;
    move_from_string: string;
    move_locations: any;
    move_warehouseId:number;
    move_warehouse_locationId:number;
    move_quantity:number;
    moveRow: ItemWareHouseStock;

    get itemId(): number {
        return this._itemId;
    };

    @Input() set itemId(value: number) {
        this._itemId = value;
        if (value > 0) {
            this.rows.length = 0;
            this.getStockByItem()
        }
    };

    @Output() openPrint: EventEmitter<any> = new EventEmitter();

    private subs: SubSink = new SubSink();

    public warehouses: WareHouse[];

    public rows: ItemWareHouseStock[] = [];

    public warehouseLocations = {}

    public modal_title: string;

    public warehouse_name: string;

    public warehouse_location_name: string;

    warehouse_detail: any;

    Operation: string;

    public comments: string;

    index: number;

    quantity: number;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    stockInfo: StockInfo = new StockInfo();

    public slnoRowQty:number=0; ///Qty of a row

    public slnoGenerateAll:boolean=true; // For slNo generation

    public slnoToGenerate:number=0; // Count of SlNo to generate

    public existingslNos:string[]=[]; //Existing slNos in arrays

    public selectedStockRow:ItemWareHouseStock; // For store selected row

    constructor(
        private warehouseService: WarehouseService,
        private itemservices: ItemService,
        private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private invCommonService: InvCommonService,
        private ra: ResourceAccessService,
        private router: Router) {
            this.isAllSuborg = itemservices.isAllSuborg;
        }



    ngOnInit(): void {
        this.getWarehouses()
    }

    getStockByItem() {
        this.subs.sink = this.itemservices.getItemWarehouseStock(this.itemId).subscribe(resp => {
            resp.map(w => {
                if (w.warehouse_location > 0) {
                    let loc = new WareHouseLocation();
                    loc.location = w.location_name;
                    loc.id = w.warehouse_location;
                    w.locations = of([loc]);
                }
            })
            this.rows = resp.sort((a, b) => a.id - b.id);
            if (resp.length <= 0) {
                this.appendRow();
                this.showStockInfo = false;
            }
            else{
                this.getStockInfo();
                this.showStockInfo = true;
            }
            this.getAggregateQuantity();
            this.cdr.markForCheck();
        });
    }

    getStockInfo() {
        this.subs.sink = this.itemservices.getItemStockInfo(this.itemId).subscribe(
            resp => {
                console.log(resp);
                this.stockInfo.total_stock = resp.total_stock;
                this.stockInfo.available_stock = resp.available_stock;
                this.stockInfo.commited_on_assemblies = resp.total_on_committed_assemblies;
                this.stockInfo.draft_on_assemblies = resp.total_on_draft_assemblies;
                this.cdr.markForCheck();
            }
        );
    }

    getWarehouses() {
        this.subs.sink = this.warehouseService.getAll().subscribe(resp => {
            this.warehouses = resp;
            this.cdr.markForCheck();
        });
    }

    onWarehouseChange(wh: WareHouse, index: number) {
        if (!wh) {
            this.rows[index].warehouse_location = null;
            this.rows[index].locations = null;
            return;
        }
        this.rows[index].warehouse_location = null;
        if (this.warehouseLocations[wh.id]) {
            this.rows[index].locations = of(this.warehouseLocations[wh.id])
        }
        else {
            this.setLocations(wh.id, index);
        }
        this.warehouse_name = wh.warehouse_name;
    }

    setLocations(warehouseId: number, index: number) {
        this.rows[index].locations = this.warehouseService.getWareHouseLocations(warehouseId).pipe(map(resp => {
            this.warehouseLocations[warehouseId] = resp;
            return resp;
        }));
    }

    onLocationSelectOpen(wh: ItemWareHouseStock, index: number) {
        if (wh.warehouse > 0 && !this.warehouseLocations[wh.warehouse]) {
            this.setLocations(wh.warehouse, index);
        }
    }

    onWarehouseLocationChange(whl: any, index: number) {
        this.warehouse_location_name = whl.location;
        // const found = this.rows.findIndex((obj, index) => {
        //     return obj.warehouse_location == whl.id;
        // })
        // if(found != -1){
        //     this.toastr.error('Already exist');
        //     this.rows[found].warehouse_location = null;
        // }
    }

    appendRow() {
        let aw = this.itemservices.createModel(ItemWareHouseStock);
        aw.item = this.itemId;
        this.rows.push(aw);
    }

    removeRow(obj: ItemWareHouseStock) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                if (obj.id) {
                    this.subs.sink = this.itemservices.deleteItemWareHouseStock(this.itemId, obj.id).subscribe(
                        () => {
                            popup.CompleteDelete('Warehouse Deleted');
                            this.rows = this.rows.filter(x => x.id !== obj.id);
                            this.cdr.markForCheck();
                            this.reloadPage();
                            // this.getAggregateQuantity();
                        },
                        () => this.toastr.error('Unable to delete Warehouse')
                    );
                }
                else {
                    this.rows = this.rows.filter(x => x !== obj);
                    this.cdr.markForCheck();
                    this.toastr.success(`Warehouse Deleted`);
                    this.reloadPage();
                    // this.getAggregateQuantity();
                }
            }
        });
    }

    toggleStockModal(content: any, wh: ItemWareHouseStock, opr: string, index: number) {
        if (wh.location_name)
            this.warehouse_location_name = wh.location_name
        if (wh.warehouse_name)
            this.warehouse_name = wh.warehouse_name
        this.Operation = opr;
        this.index = index;
        this.modal_title = opr + " Qty To";
        this.warehouse_detail = this.warehouse_name + " - " + this.warehouse_location_name
        this.comments = wh.comments;
        this.quantity = 0;
        this.modalService.open(content);
    }

    getAggregateQuantity() {
        this.total_quantity = this.rows.reduce((sum, current) => sum + current.quantity, 0)
        this.total_available_quantity = this.rows.reduce((sum, current) => sum + current.available_quantity, 0)
    }

    modifyQuantity() {
        if (this.Operation == "Add")
            this.rows[this.index].quantity = this.rows[this.index].quantity + this.quantity || 0
        else
            this.rows[this.index].quantity = this.rows[this.index].quantity - this.quantity || 0
        this.rows[this.index].comments = this.comments;
        this.getAggregateQuantity();
        this.modalService.dismissAll();
    }

    ModifyDuplicate(){
        let result=[];
        this.rows.forEach(function (a, index) {
            if (!this[a.warehouse_location]) {
                this[a.warehouse_location] = a;
                result.push(this[a.warehouse_location]);
            }
            else{
            this[a.warehouse_location].quantity += a.quantity;
            }
        }, []);
        this.rows = result;
    }

    onSaveStock() {
        if (!this.validate()) {
            this.toastr.info('Warehouse location required');
            return;
        }
        this.ModifyDuplicate();
        let rowsss = []
        let update_models = this.rows.filter(item => item.id);
        let create_models = this.rows.filter(item => !item.id);

        if (create_models.length) {
            this.subs.sink = this.itemservices.createItemWareHouseStock(this.itemId, create_models).subscribe(resp => {
                rowsss = rowsss.concat(resp);
            });
        }

        if (update_models.length) {
            this.subs.sink = this.itemservices.updateItemWareHouseStock(this.itemId, update_models).subscribe(resp => {
                rowsss = rowsss.concat(resp);
            });
        }
        this.cdr.markForCheck();

        this.toastr.success(`Warehouse updated`);
        // this.getStockByItem();
        this.reloadPage();
    }

    reloadPage(){
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/inventory/items/', this.itemId, 'view']);
    }

    showMoveStockModal(content, row:ItemWareHouseStock){
        this.moveRow = row
        this.modalService.open(content);
    }

    onMoveWarehouseChange(wh: WareHouse) {
        this.setMoveLocations(wh.id);
    }

    setMoveLocations(warehouseId: number) {
        this.move_locations = this.warehouseService.getWareHouseLocations(warehouseId).pipe(map(resp => {
            return resp;
        }));
    }

    onMoveValidate(){
        if(!this.move_warehouseId || !this.move_warehouse_locationId || !this.move_quantity){
            this.toastr.error(`All fields are mandatory`);
            return  false;
        }
        else if(this.move_quantity > this.moveRow.available_quantity){
            this.toastr.error(`Quantity should be less than available quantity`);
            return  false;
        }
        else if(this.moveRow.warehouse == this.move_warehouseId && this.moveRow.warehouse_location == this.move_warehouse_locationId){
            this.toastr.error(`Source and Destination should not be same`);
            return  false;
        }
        else
            return true;
    }

    onMoveQuantity(){
        if(this.onMoveValidate()){
            let update_models = []
            let create_models = []
            let row = this.rows.filter(item => item.warehouse == this.moveRow.warehouse && item.warehouse_location == this.moveRow.warehouse_location)[0]
            row.quantity -= this.move_quantity;
            update_models.push(row);
            let dest_row = this.rows.filter(item => item.warehouse == this.move_warehouseId && item.warehouse_location == this.move_warehouse_locationId)[0]
            if(dest_row){
                dest_row.quantity += this.move_quantity;
                update_models.push(dest_row);
            }
            else{
                this.appendRow();
                this.rows[this.rows.length - 1].quantity = this.move_quantity;
                this.rows[this.rows.length - 1].warehouse = this.move_warehouseId;
                this.rows[this.rows.length - 1].warehouse_location = this.move_warehouse_locationId;
                create_models.push(this.rows[this.rows.length - 1])
            }
            let api=[];
            if(create_models.length)
                api.push(this.itemservices.createItemWareHouseStock(this.itemId, create_models))
            if(update_models.length)
                api.push(this.itemservices.updateItemWareHouseStock(this.itemId, update_models))
            this.subs.sink = forkJoin(api).subscribe(resp => {
                if(resp){
                this.cdr.markForCheck();
                this.modalService.dismissAll();
                this.toastr.success(`Moved Succesfully`);
                this.reloadPage();
                }
                else{
                    this.cdr.markForCheck();
                    this.modalService.dismissAll();
                    this.toastr.success(`operation failed..`);
                }
            });
        }
    }

    // onMoveLocationSelectOpen() {
    //     if (!this.move_locations) {
    //         this.setMoveLocations(wh.warehouse,);
    //     }
    // }

    generateSerialNumber(content, row:ItemWareHouseStock){
        this.slnoRowQty=row.quantity;
        this.selectedStockRow=row;
        this.slnoToGenerate=row.quantity;
        if(row.serial_number&&row.serial_number.length>0){
            this.existingslNos=row.serial_number;

        }
        this.modalService.open(content);
    }

    changeSlNoQty(){
        let max=this.slnoRowQty-this.existingslNos.length;

        if(this.slnoToGenerate>max){
            this.toastr.error('You can only generate maximum '+max+' number of serial numbers');
        }
    }

    generateSlNo(){

        let data=new SerialNumberRequest();
        data.requested_quantity=this.slnoToGenerate;
        data.unique_id=this.itemId;
        data.warehouse=this.selectedStockRow.warehouse;
        data.warehouse_location=this.selectedStockRow.warehouse_location;
        data.type='ITEM';
        this.invCommonService.generateSlNo(data).subscribe(
            response=>{
                this.toastr.success('Successfully generated the serial numbers');
                 this.modalService.dismissAll();
                 this.getStockByItem();

            }
        )
    }

    validate(): boolean {
        return !this.rows.some(item => !item.warehouse_location || item.warehouse_location <= 0);
    }

    hasPermission(resource: string, permission: string) {
        return this.ra.hasGlobalPermission([ResourceConstants.INV_ITEMS_STOCK, ResourceConstants.INV_ITEMS, ResourceConstants.INV_MODULE]) ||
            this.ra.hasPermission(resource, [permission]);
    }

    hasPermissionAny(resource: string, permission: string[]) {
        return this.ra.hasGlobalPermission([ResourceConstants.INV_ITEMS_STOCK, ResourceConstants.INV_ITEMS, ResourceConstants.INV_MODULE]) ||
            this.ra.hasPermission(resource, permission);
    }

    hasGlobalPermission(resources: string[]){
        return this.ra.hasGlobalPermission(resources);
    }

    printView(werehouse:number, location:number, slNo:string[]){
        this.openPrint.emit({ "werehouse": werehouse, "location": location, "slNo": slNo });
    }
}
