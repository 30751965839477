import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { ModuleConstants } from './../../../../core/enums/common.enum';
import { SupplierService } from './../../services/supplier.service';
import { Observable } from 'rxjs';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Supplier } from '../../models/supplier';
import { ToastrService } from 'ngx-toastr';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UIPermission } from 'src/app/core/models/common.models';

@Component({
    selector: 'app-supplier-list',
    templateUrl: './supplier-list.component.html',
    styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit, OnDestroy {

    @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = [
        { displayName: "All Suppliers", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Active Suppliers", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
        { displayName: "Inactive Suppliers", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
    ];

    public search: GFilterParam[] = [
        { displayName: 'Name', colname: 'supplier_name', condition: 'contains', value: '', operator: 'AND' },
        { displayName: 'Account Number', colname: 'account_number', condition: 'contains', value: '', operator: 'AND' }
    ];

    public columns: GFColumn[] = [
        { displayName: 'Name', columnName: 'supplier_name', columnType: GFColumnTypes.string },
        { displayName: 'Account Number', columnName: 'account_number', columnType: GFColumnTypes.string },
        { displayName: 'Address', columnName: 'address', columnType: GFColumnTypes.string },
        { displayName: 'Created On', columnName: 'created_on', columnType: GFColumnTypes.date },
    ];

    public SelectionType = SelectionType;

    public rows: Supplier[];

    public expanded: boolean = true;

    public selected: Supplier;

    public supplierId: number = 0;

    public model: Supplier;

    public sort: GSortParam[] = [{ colname: 'supplier_name', direction: 'asc' }];

    public sortLookup = {
        'supplier_name': 'supplier_name',
        'address': 'address',
        'created_on': 'created_on',
        'account_number': 'account_number'
    };

    public module = ModuleConstants.SUPPLIER;

    public subs: SubSink = new SubSink();

    private modalRef: NgbModalRef;

    public scrollbarH: boolean = false;

    public nameExist: boolean = false;

    public isAllSuborg:boolean;

    public UIPermissions:UIPermission;

    constructor(private supplierService: SupplierService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        private modal: NgbModal,
        private device: DeviceDetectorService) {

        this.scrollbarH = this.device.isMobile();
        this.isAllSuborg = supplierService.isAllSuborg;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_SUPPLIERS, ResourceConstants.INV_MODULE);

        if (this.isAllSuborg) {
            this.columns.push({ displayName: 'Sub Org', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentValue: this.supplierService.customerId });
        }
    }

    ngOnInit(): void {
        this.getData();
    }

    getData(filters: GFilterParam[] = null, offset: number = 0): void {

        let option = { sort: this.sort, filters: filters };

        this.subs.sink = this.supplierService.getAllFiltered(option, offset).subscribe(
            response => {
                this.rows = response.result;
                this.setPagination(offset, response.count);
                this.cdr.markForCheck();
            },
            () => {
                this.rows = [];
                this.setPagination(offset, 0);
                this.cdr.markForCheck();
            }
        );
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        let current = selected[0];
        if (current && this.UIPermissions.viewInfo) {
            this.selected = current;
            this.expanded = false;
            this.refreshDataTable();
        }
    }

    deleteSupplier(id: number): void {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.supplierService.delete(id).subscribe(
                    () => {
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        this.closeDetailedView();
                        popup.CompleteDelete('Supplier has been deleted.')
                    },
                    () => this.toastr.error('Unable to delete row')
                );
            }
        });
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.refreshDataTable();
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    openSupplierForm(content: any, id: number = 0) {
        this.supplierId = id;
        this.nameExist = false;
        if (id) {
            this.model = { ...this.rows.find(s => s.id === id) };
        }
        else {
            this.model = this.supplierService.createModel(Supplier);
        }
        this.modalRef = this.modal.open(content, { centered: true });
    }

    supplierOnSubmit() {
        let api: Observable<Supplier>;
        if (this.supplierId > 0) {
            api = this.supplierService.update(this.supplierId, this.model);
        }
        else {
            this.model.customer = this.supplierService.customerId;
            api = this.supplierService.create(this.model);
        }
        this.subs.sink = api.subscribe(
            resp => {
                this.nameExist = false;
                this.toastr.success(this.supplierId > 0 ? `Supplier info updated` : 'New supplier added');
                this.modalRef?.dismiss();
                if (this.page.count < this.page.limit) {
                    this.rows = [...this.rows.filter(w => w.id !== this.supplierId), resp];
                    this.page.count = this.rows.length;
                }
                this.expanded = false;
                this.selected = resp;
                this.cdr.markForCheck();
            },
            error => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.toastr.error('Operation failed');
                    this.modalRef?.dismiss();
                }
            }
        );
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }

    changeStatus() {
        let opt = {
            title: `Are you sure you want to ${this.selected.is_active ? 'Activate Supplier' : 'Deactivate Supplier'}?`,
            text: this.selected.supplier_name,
            current: this.selected.is_active,
        };
        popup.ConfirmActivate(opt, result => {
            if (result.isConfirmed) {
                this.subs.sink = this.supplierService.patch(this.selected.id, { is_active: this.selected.is_active }).subscribe(
                    () => { },
                    () => {
                        this.selected.is_active = !this.selected.is_active;
                        this.cdr.markForCheck();
                        this.toastr.error("Failed to change status");
                    }
                );
            }
            else {
                this.selected.is_active = !this.selected.is_active;
                this.cdr.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
