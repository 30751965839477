import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { TenantSettings } from '../../models/customer';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'tenant-settings',
  templateUrl: './tenant-settings.component.html',
  styleUrls: ['./tenant-settings.component.scss']
})
export class TenantSettingsComponent implements OnInit {

  private _tenantId: number;

  @Input() set tenantId(value: number) {
    this._tenantId = value;
    if (value) {
      this.getCustomerSettings();
    }
  };

  get tenantId() { return this._tenantId; };

  public settings: TenantSettings;

  private subs: SubSink = new SubSink();

  constructor(private tenantService: CustomerService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  getCustomerSettings() {
    this.subs.add(this.tenantService.getTenantSettings(this.tenantId).subscribe(resp => {
      this.settings = resp;
      this.cdr.markForCheck();
    }));
  }

  change2FASettings(event: any) {
    let checked = event.target.checked;
    this.subs.add(this.tenantService.updateTenantSettings(this.tenantId, {
      two_factor_authentication: checked
    }).subscribe(resp => {
      this.settings.two_factor_authentication = checked;
      this.cdr.markForCheck();
    }));
  }
}

