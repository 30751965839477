import { WHBaseModel } from "src/app/core/models/common.models";

export class Cards {
  id?: number;
  card_number: number;
  expiry_date: string;
  name: string;
  zip: number;
  is_default: boolean = false;
  card_type: string;
  suborg: number;
  account: string;
  routing: string;
  account_type: string;
  payment_type: string;
}

export enum PaymentMethods {
  CARD = 'Credit Card',
  CHECK = 'Check',
  ACH = 'ACH',
  CASH = 'Cash'
}

export enum CardTypes {
  VISA = 'Visa',
  MASTRO = 'MasterCard',
  DISCOVER = 'Discover',
  AMEX = 'Amex',
  EBT = 'EBT',
  PLEDGER = 'Pledger',
  DONORSFUND = 'DonorsFund',
  OJC = 'OJC'

}

export enum AccountType {
  SAVINGS = 'savings',
  CHECKING = 'checking',

}

export class SuborgsPaymentAccountSettings {
  is_partial_payment: boolean;
  is_over_payment: boolean
}

export class PaymentRequest {
  name: string;
  mobile?: number;
  email?: string;
  card_number: string;
  zip_code?: string;
  expiry_date: string;
  cvv: number;
  comments?: string;
  amount: number;
  is_saved_to_file: boolean;
  token: string;
  send_email_to_customer: boolean;
  over_payment_amount: number;
  account: string;
  account_type: string;
  routing: string;
  payment_way: string;
  payment_method: string;

}

export class InvoiceDetails {
  is_over_payment: boolean
  is_partial_payment: boolean
  invoice_data: InvoiceData
  customer_card: CustomerCard[]
  customer_check: CustomerCheck[]
  default_card: DefaultCard
  default_check: DefaultCheck
}

export interface InvoiceData {
  id: number
  title: any
  balance_due: number
  tax_amount: number
  subtotal: number
  total_price: number
  tenant_customer__name: string
  tenant_customer_contact__email: any
  tenant_customer_contact__mobile: any
  tenant_customer_contact__phone: any
  payments_made: number
  credit_amount: number
  amountPayable?: number;
  suborg__name: string;
}

export interface CustomerCard {
  id: number
  masked_card_number: string
  is_default?: boolean
  token: string
}

export interface CustomerCheck {
  id: number
  masked_account_number: string
  masked_routing_number: string
  is_default?: boolean
  token: string
}

export interface DefaultCard {
  id: number
  masked_card_number: string
  card_type: string
  name: string
  zip: string
  expiry_date: string
  token: string
}

export interface DefaultCheck {
  id: number
  masked_account_number: string
  masked_routing_number: string
  name: string
  zip: string
  expiry_date: string
  token: string
}

export class PaymentValidation {
  number: boolean;
  exp: boolean;
  name: boolean;
  cvv: boolean;
  account: boolean;
  routing: boolean;
  account_type: boolean;
}

export class AddPaymentValidation {
  deposit_account_name: boolean;
  reference_id: boolean;
  check_number: boolean;
}

export class GetInvoicesPayments {
  invoice_details: InvoiceDetails
  payment: PaymentReccord[]
  is_over_payment: boolean
  is_partial_payment: boolean
}

export interface PaymentReccord {
  id: number
  payment_id: number
  invoice_id: number
  payment_amount: number
  tenant_customer_name: string
  suborg_name: string
  status: string
  reference_id: string
  payments_id: string
  unique_id: string
  name: string
  email: string
  mobile: string
  over_payment_amount: number
  payment_date: string
  comments: string
  masked_card_number: string
  masked_account_number: string
  card_type: string
  payment_method: string
  deposit_account_name: string
  deposit_account_number: string
  currency: string
  attachment: string
  zip_code: string
  check_number: string
  customer_note: string
  created_on: string
  modified_on: string
  paid_by_user: string;
  suborg_image?: string;
  suborg_address?: string;
  suborg_city?: string;
  suborg_state?: string;
  suborg_zip?: string;
}

export class PaymentAdd {
  amount: number
  name: string
  email: string
  mobile: number
  comments: string
  payment_method: string
  check_number: string
  deposit_account_name: string
  reference_id: string
  send_email_to_customer: boolean
  over_payment_amount: number
  deposit_account_number: string

}

export class PaymentDetails {
  credit_document: Credits[]
  transactions: Transaction[]
  payment: PaymentReccord
}

export class Transaction {
  id: number
  invoice: Invoice
  payment_amount: number
  over_payment_amount: string
  payment: number
}

export class Invoice {
  id: number
  created_by: string
  modified_by: string
  suborg_name: string
  created_on: string
  modified_on: string
  is_delete: boolean
  is_active: boolean
  is_imported: boolean
  source: string
  title: any
  unique_number: string
  conditions: string
  status: number
  add_on_status: number
  reference_id: any
  shipping_date: any
  subtotal: string
  total_price: string
  balance_due: string
  payments_made: string
  mail_sent_date: string
  client_viewed: boolean
  client_viewed_date: string
  due_date: any
  tax: any
  tax_amount: string
  from_module: number
  from_unique_number: string
  credit_amount: string
  customer: number
  suborg: number
  created_user: number
  modified_user: number
  tenant_customer: number
  tenant_customer_contact: number
  tenant_customer_location: number
  terms_and_condition: any
  payment_terms: number
  shipment_method: any
  shipping_location: any
}

export class Credits {
  id: number
  unique_id: string
  payment_id: number
  transaction_id: number
  invoice_id: number
  amount: number
  tenant_customer_id: number
  is_credit: boolean
  created_on: string
  modified_on: string
  tenant_customer_name: string
  invoice_unique_id: string
  invoice_title: any
}

export class CustomersPaymentDatas {
  is_over_payment: boolean
  is_partial_payment: boolean
  customer_card: CustomerCard[]
  customer_check: CustomerCheck[]
  default_card: DefaultCard
  default_check: DefaultCheck
}