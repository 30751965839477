<div class="row" *ngIf="!submitted" style="border-bottom: 1px solid #504e4e33;">
    <div class="col-md-12 mt-2">
        <div class="stepper-wrapper">
            <div class="stepper-item" [ngClass]="didService.currentStep==1?'active':didService.currentStep>1?'completed':''">
                <div class="step-counter" [ngClass]="didService.currentStep==1?'active':didService.currentStep>1?'completed':''">
                    <i class="fa fa-file-text-o text-white" aria-hidden="true"></i>
                </div>
              <div class="step-name">Select option</div>
            </div>
            <div class="stepper-item" [ngClass]="didService.currentStep==2?'active':didService.currentStep>2?'completed':''">
                <div class="step-counter" [ngClass]="didService.currentStep==2?'active':didService.currentStep>2?'completed':''">
                    <i class="fa fa-check-square text-white" aria-hidden="true"></i>
                </div>
                <div class="step-name">{{selectedCard === 'selectNumbers'?'Select numbers to delete':'Import file to delete'}}</div>
            </div>
            
            <div class="stepper-item" [ngClass]="didService.currentStep==3?'active':''">
                <div class="step-counter" [ngClass]="didService.currentStep==3?'active':''">
                    <i class="fa fa-check text-white" aria-hidden="true"></i>
                </div>
                <div class="step-name">Confirm delete</div>
            </div>
        </div>
    </div>
</div>

<section class="row common-view" [hidden]="didService.currentStep!=1">
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col justify-content-center d-flex">
                <div class="card" [ngClass]="selectedCard === 'selectNumbers'?'selected-card':'bulk-delete-card'" (click)="selectCard('selectNumbers')">
                    <div class="card-body align-items-center d-flex flex-column">
                      <h1 class="card-text">Delete</h1>
                      <h6 class="card-subtitle mb-2 text-muted">Delete by selecting numbers</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-auto align-self-center">
              <h1 style="color: #3A1180;">OR</h1>
            </div>
            <div class="col justify-content-center d-flex" >
                <div class="card " [ngClass]="selectedCard === 'importFile'?'selected-card':'bulk-delete-card'" (click)="selectCard('importFile')">
                    <div class="card-body align-items-center d-flex flex-column">
                      <h1 class="card-text">Import</h1>
                      <h6 class="card-subtitle mb-2 text-muted">Delete by importing file</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- remove this after checking backend change -->
        <div class="row justify-content-md-center p-3">
            <div style="width: 50%;">
                <div class="form-group d-flex justify-content-center">
                    <label class="val-required text-info font-italic">Numbers can only be deleted if they are 16 days after purchase.</label>
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-md-center p-5" *ngIf="selectedCard=='rename'">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="val-required">Rename Number</label>
                    <input type="text" placeholder="Enter name" name="forwardbin_name" class="form-control" maxlength="255" [(ngModel)]="bulk_name" required>
                    <small class="danger mt-1" *ngIf="bulk_name && nameError">Name already exists</small>
                </div>
            </div>
        </div> -->
    </div>
</section>

<section class="common-view phone-numbers" [hidden]="didService.currentStep!=2" *ngIf="selectedCard=='selectNumbers'">
    <div class="card-header d-flex align-items-center flex-space-bw">
        <datagrid-filter #dataFilter [searchFilters]="searchFilters" [defaultFilters]="defaults"
                            [columns]="columns"
                            (apply)="applyFilter($event)"
                            [module]="module"
                            [toggleId]="'unassignToggle'">

            <ng-template filterControl [column]="'area_code'" let-row="row">
                <area-code-select class="ng-select-sm" [(value)]="row.value"></area-code-select>
            </ng-template>
            <ng-template filterControl [column]="'customer'" let-row="row">
                <app-customer-select class="ng-select-sm" [(value)]="row.value"
                                        (change)="dataFilter.controlValueChanged('customer')">
                </app-customer-select>
            </ng-template>
            <ng-template filterControl [column]="'suborg'" let-row="row">
                <suborg-select class="ng-select-sm" [(value)]="row.value"
                                [tenantId]="row.dependentValue"
                                (change)="dataFilter.controlValueChanged('suborg')">
                </suborg-select>
            </ng-template>
            <ng-template filterControl [column]="'forward_bin_sid_resolved'" let-row="row">
                <forward-bin-select class="ng-select-sm" [(value)]="row.value"
                                    [suborgId]="suborgId" [show_RouteType]="true"></forward-bin-select>
            </ng-template>
        </datagrid-filter>
    </div>
    <div class="card-content">
        <div class="">
            <ngx-datatable #dataTable
                            [scrollbarH]="scrollbarH"
                            class="bootstrap core-bootstrap"
                            [columnMode]="'force'"
                            [headerHeight]="50"
                            [footerHeight]="50"
                            rowHeight="auto"
                            [rows]="rows"
                            [selectionType]="SelectionType.checkbox"
                            [count]="page.count"
                            [externalPaging]="true"
                            [offset]="page.offset"
                            [externalSorting]="true"
                            [limit]="page.limit"
                            [trackByProp]="'sid'"
                            (sort)="onSort($event)"
                            [selected]="selectedNumbers"
                            (select)="numberOnSelect($event)"
                            [sorts]="[{prop:'purchased_on',dir:'desc'}]">

                <ngx-datatable-column [sortable]="false" name="Number" prop="did">

                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                        <div class="custom-checkbox ml-1">
                            <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/> All
                        </div>
                    </ng-template>
                    

                    <ng-template let-did="value" ngx-datatable-cell-template let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <div class="custom-checkbox ml-1">
                            <input type="checkbox" [checked]="isSelected" (click)=" onCheckboxChangeFn($event,row);"/>
                        </div>
                        
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Number" prop="did">
                    <ng-template let-did="value" ngx-datatable-cell-template>
                        {{did | phoneNumber }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                <ngx-datatable-column name="Number Type" prop="did_type"
                                        [sortable]="false"></ngx-datatable-column>
                <ngx-datatable-column name="Area Code" prop="area_code">
                </ngx-datatable-column>
                <ngx-datatable-column name="Incoming Routes" prop="forward_bin_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div>{{row.forward_bin_name}}</div>
                        <div class="bold" *ngIf="row.forward_number">
                            <span
                                    *ngFor="let num of row.forward_number.split(',') as numList; let i = index">
                                <span>{{num | phoneNumber}}</span>
                                <span *ngIf="i !== numList.length-1">, </span>
                            </span>
                        </div>
                        <div class="bold" *ngIf="row.sip">
                            <span
                                    *ngFor="let num of row.sip.split(',') as numList; let i = index">
                                <span>{{num}}</span>
                                <span *ngIf="i !== numList.length-1">, </span>
                            </span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Purchased On" prop="purchased_on">
                    <ng-template let-purchase="value" ngx-datatable-cell-template>
                        {{purchase | dateTz}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="deletedRecords" name="Deleted On"
                                        prop="modified_on">
                    <ng-template let-purchase="value" ngx-datatable-cell-template>
                        {{purchase | dateTz}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="deletedRecords" name="Deleted By"
                                        prop="modified_by"></ngx-datatable-column>
                <ngx-datatable-column *ngIf="isSuperAdmin" name="Tenant/Suborg"
                                        prop="suborg_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div>{{row.customer_name}}</div>
                        <div>Sub org : {{row.suborg_name}}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Outbound SMS" prop="is_sms_outbound_active">
                    <ng-template let-status="value" ngx-datatable-cell-template>
                        <div class="badge" style="width: 5.5rem;"
                                [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                            {{ status ? 'Activated' : ' Deactivated' }}
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="!isSuperAdmin" name="Suborg" prop="suborg_name">
                </ngx-datatable-column>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                        <div class="page-count" >
                            <span>Total {{rowCount}} Numbers </span>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </div>
    </div>
</section>

<section class="row common-view mt-2" [hidden]="didService.currentStep!=2" *ngIf="selectedCard=='importFile'">
    <div class="card-content d-flex flex-column align-items-center justify-content-center">
        <p class="text-info" *ngIf="!selectedFile">Please upload a CSV file</p>
        <div class="file-controls">
            <div class="file-input">
                <input type="file" class="d-none" accept=".csv" (change)="onFileSelected($event)" #fileInput />
                <button (click)="fileInput.click();" class="btn btn-sm mr-1 bg-light-primary text-nowrap filter-button">
                    <i class="mr-1" [ngClass]="selectedFile?'fa fa-refresh':'ft-download'"></i> {{selectedFile?'Change File':'Choose File'}}
                </button>
                <span *ngIf="selectedFile">{{selectedFile.name}}
                    <i class="btn btn-sm fa fa-times text-danger" ngbTooltip="Remove" (click)="clearSelectedFile(fileInput)"></i>
                </span>
            </div>
            <!-- <button type="button" (click)="onTemplateBtnClicked()" class="template-btn btn btn-text-center btn-sm text-nowrap gradient-pomegranate mr-1">
              Download Template
            </button> -->
        </div>
        <div class="mt-3 font-italic text-info" *ngIf="phoneNumbersFromFile.length">
            {{phoneNumbersFromFile.length}} number(s) selected to delete
        </div>
        <div class="card" *ngIf="phoneNumbersFromFile.length">
            <div class="card-body">
                <label>Numbers that are selected to delete</label>
                <div class="pn_div">
                    <button class="btn btn-sm pnumbtn bg-light-danger" *ngFor="let num of phoneNumbersFromFile">
                        {{num | phoneNumber }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="row common-view" [ngClass]="submitted?'flex-column':''" [hidden]="didService.currentStep!=3">
    <div class="container" *ngIf="!submitted else nondeleted">
        <div class="row justify-content-between">
            <div class="col justify-content-center d-flex">
                <div class="card selected-card confirmCard rounded">
                    <div class="card-body align-items-center d-flex flex-column">
                      <h1 class="card-text"><i class="ft-alert-triangle caution-icon"></i>&nbsp;Selected number(s) will be deleted</h1>
                      <!-- <h6 class="card-subtitle mt-2 text-muted"><span>{{totalSelectedNumbers}} number(s) will be updated with new {{selectedCard=='rename'?'Name':'Incoming Route'}} : <span class="text-primary">{{selectedCard=='rename'?bulk_name:selectedBinName}}</span></span></h6> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col justify-content-center d-flex">
                <ul>
                    <li><i>Accept <span style="color: #593cc2;">Terms & Conditions</span> Then Click <span style="color: #bf525d;"> Finish </span> To Delete</i></li>
                    <li><i>Click <span style="color: #616d89;"> Previous </span> To Change Current Selection</i></li>
                </ul>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col justify-content-center d-flex">
                <div class="custom-control custom-checkbox d-flex align-items-center">
                    <input type="checkbox" [(ngModel)]="terms" name="customCheck" id="custom-check-1" class="custom-control-input">
                    <label for="custom-check-1" class="custom-control-label">
                      <span></span>
                    </label>
                    <p class="mb-0 mt-3 font-italic">I understand that this action cannot be undone, and deleting these numbers will permanently remove them from this account</p>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #nondeleted>
    <div class="row justify-content-between">
        <div class="col justify-content-center d-flex">
            <h6 class="card-subtitle mt-2 text-info">
                <span><i class="ft-alert-triangle text-warning"></i>&nbsp;{{totalSelectedNumbers}} number(s) selected for deletion. {{deleted_numbers?.length}} number(s) were deleted, and {{non_deleted_numbers?.length}} number(s) were not deleted.</span>
            </h6>
        </div>
    </div>
    <div class="row justify-content-between mt-4">
        <div class="col justify-content-center d-flex">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="submitted">
                        <div *ngIf="non_deleted_numbers!=''">
                            <label>Numbers that weren't able to be deleted</label>
                            <div class="pn_div">
                                <button class="btn btn-sm pnumbtn bg-light-danger" *ngFor="let num of non_deleted_numbers">
                                    {{num | phoneNumber }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>