import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: 'quill-modal',
    template: `<ng-template #sheetModal let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title" id="modal-basic-title">{{title}}</h5>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-0">
                        <div class="quil-container">
                            <quill-editor *ngIf="!viewMode" [(ngModel)]="value" [styles]="{'min-height': '250px'}"></quill-editor>
                            <div class="w-100" style="min-height:250px;">
                                <quill-view *ngIf="viewMode" [content]="value" theme="snow"></quill-view>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-0">
                        <button type="button" (click)="buttonClicked()" class="btn gradient-pomegranate">
                            {{viewMode ? 'Close' : 'Save'}}
                        </button>
                    </div>
                </ng-template>`
})
export class QuillModalComponent {

    @ViewChild('sheetModal') modalContent: any;

    @Input() value: string;

    @Output() valueChange = new EventEmitter<string>();

    @Input() title: string;

    @Input() viewMode: boolean = false;

    @Output() submit = new EventEmitter<void>();

    constructor(private ngModal: NgbModal) { }

    toggle() {
        this.ngModal.open(this.modalContent, { size: 'xl', scrollable: true });
    }

    buttonClicked() {
        this.valueChange?.emit(this.value);
        this.submit?.emit();
        this.ngModal.dismissAll();
    }
}