import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { collapseAnimation } from 'angular-animations';
import { SIP } from '../../models/sip.models';
import { SIPService } from '../../services/sip.service';

@Component({
  selector: 'app-sip-select',
  templateUrl: './sip-select.component.html',
  styleUrls: ['./sip-select.component.scss']
})
export class SipSelectComponent implements OnInit {

  public count:number;

  public isAllSuborg:boolean;

  @Input() value: any;

  @Input() suborgId: number;

  @Input() readonly: boolean = false;

  @Input() placeholder: string;

  @Input() multiple: boolean = true;

  @Output() valueChange = new EventEmitter<any>();

  @Output() change = new EventEmitter<any>();

  @Input() maxinput: number;

  @Input() userAssignedSip: boolean = false;

  public inputValues: string[] = [];

  public selected: any;

  public limit: number = 50;

  public offset: number = 0;

  public options: SIP[];

  constructor(private sipService: SIPService,
    private cdr: ChangeDetectorRef) {
      this.isAllSuborg = sipService.isAllSuborg;
     }

  ngOnInit() {
    this.getData();
    this.placeholder = this.multiple ? 'Select SIPs' : 'Select SIP';
  }


  getData(){
    let request = { 
      filters: [], sort: [],
    }
    if (this.isAllSuborg) {
      request.filters.push(...[
          { colname: 'suborg', condition: 'equalto', value: this.suborgId, operator: 'AND' }
      ]);
    }
    if(this.userAssignedSip){
      request.filters.push(...[
        { colname: 'is_user_assigned', condition: 'equalto', value: true, operator: 'AND' }
    ]);
    }
    this.sipService.get(request, this.offset , -1).subscribe(response => {
      this.options = response.result;
      this.cdr.markForCheck();
    });
  }

  onValueChanged(value: any) {
    if (this.multiple) {
      this.value = value ? value.map((item: SIP) => item.username) : null;
      this.valueChange.emit(this.value);
    }
    else {
      this.value = value ? value.username : null;
      this.valueChange.emit(this.value);
    }
    this.change?.emit(this.value);
    this.count = value.length
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.username.toLocaleLowerCase().indexOf(term) > -1 || item.associated_username.toLocaleLowerCase().indexOf(term) > -1;
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      this.selected = changes.value.currentValue;
      this.cdr.markForCheck();
    }
  }
}
