import { SubTask, Task } from './../../models/common.models';
import { catchError } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ToastrService } from 'ngx-toastr';
import { BillingStatus } from '../../models/enums';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ServiceTicketService } from '../../services/service-ticket.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Component, Input, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SubSink } from 'subsink';
import { Observable, of } from 'rxjs';
import * as popup from 'src/app/core/utils/popup.functions';
import { ChecklistService } from '../../services/checklist.service';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
    selector: 'sub-task-grid',
    templateUrl: './sub-task-grid.component.html',
    styleUrls: ['./sub-task-grid.component.scss']
})
export class SubTaskGridComponent implements OnInit, OnDestroy {

    @ViewChild('modalContent') modalContent: any;

    public model:Task;

    public rows: SubTask[] = [];

    public selectionType = SelectionType;

    private tid: number;

    public  sid: number;

    @Input() set tId(value: number) {
        this.tid = value;
        this.getTaskById(value);
    }

    get tId(): number { return this.tid; };

    private modalRef: NgbModalRef;

    public scrollbarH: boolean = false;

    private subs: SubSink = new SubSink();

    public Permissions = PermissionConstants;

    public subTask: SubTask;

    

    constructor(private modal: NgbModal,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        private checklistService: ChecklistService,) { }

    ngOnInit(): void {

        
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
        
        let api: Observable<Task>;
        api = this.checklistService.update(this.tid, this.model);
        this.subs.sink = api.subscribe(
          response => {
            let msg = "SubTask's sort order updated.";
            this.onDataSubmittedSuccess({ response: response, message: msg });
          },
          error => {
              this.getTaskById(this.tid)
              this.onDataError();
            
          }
        );
      }

    getTaskById(id: number) {
        this.subs.add(this.checklistService.getById(id).subscribe(resp => {
            this.model = resp;
            this.rows = resp.sub_tasks;
            this.cdr.markForCheck();
        }));
    }

    onSelect({ selected }) {
        if (selected.length && this.hasPermission(PermissionConstants.MODIFY)) {
            this.open(selected[0].id);
        }
    }



    public open(id: number = 0) {

        this.sid = id;

        if(id==0){
            this.subTask = this.checklistService.createModel(SubTask);
        }else{
            //console.log(this.rows,'rows')
            this.subTask = this.rows.find(sub => sub.id === id);
        }
        this.modalRef = this.modal.open(this.modalContent, { centered: true, scrollable: true, size: 'xl' });
    }

    public close() { this.modalRef?.dismiss(); }

    deleteRow(id: number) {
        popup.ConfirmDelete(result => {
            if (!result.isConfirmed) return;
            this.subTask = this.rows.find(sub => sub.id === id);
            this.subTask.is_delete = true;

            this.onSubmit();
          
        });
    }

   

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.ST_TIMSHEET, [permission], ResourceConstants.SERVICE_TICKET_MODULE);
    }

    getTime(timeInMi: number) {
        let h = Math.floor(timeInMi / 60);
        let m = timeInMi % 60;
        return `${h} Hours ${m} Minutes`;
    }

    onDataSubmittedSuccess(result: { response: Task, message: string }): void {
        //this.rows = [...this.rows.filter(item => item.id !== result.response.id), ...[result.response]];
        this.toastr.success(result.message);
        this.close();
        this.cdr.markForCheck();
    }

    onDataError(): void {
        this.toastr.error('Operation failed');
        this.close();
    }

    ngOnDestroy(): void { this.subs?.unsubscribe(); }


    onSubmit(): void {

        let api: Observable<Task>;
    
       
        if(this.sid==0){
          this.subTask.suborg =  this.model.suborg; 
          this.model.sub_tasks.push(this.subTask);
          this.cdr.markForCheck();
        }

          api = this.checklistService.update(this.tid, this.model);
       
    
        this.subs.sink = api.subscribe(
          response => {
            let msg = this.tId > 0 ? 'Task info updated' : 'New Task created';
            this.onDataSubmittedSuccess({ response: response, message: msg });
            this.getTaskById(this.tid)
            //this.rows = response.sub_tasks;
            this.cdr.markForCheck();
          },
          error => {
              this.getTaskById(this.tid)
              this.onDataError();
            
          }
        );
      }

}
