import { NgForm } from '@angular/forms';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from '../../services/role.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Role } from '../../models/role';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-add-role',
    templateUrl: './add-role.component.html',
    styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit, OnDestroy {

    public model: Role;

    public roleId: number = 0;

    public isSuperAdmin: boolean;

    private subs: SubSink = new SubSink();

    constructor(private roleService: RoleService,
        route: ActivatedRoute,
        private toastr: ToastrService,
        private location: Location,
        private cdr: ChangeDetectorRef,
        private router: Router) {

        this.subs.sink = route.params.subscribe(params => this.roleId = +params.id || 0);
        this.isSuperAdmin = roleService.isSuperUser;
    }

    ngOnInit(): void {
        if (this.roleId > 0) {
            this.subs.sink = this.roleService.getById(this.roleId).subscribe(role => {
                this.model = role;
                this.cdr.markForCheck();
            });
        }
        else {
            this.model = new Role();
            this.model.customer = this.isSuperAdmin ? 0 : this.roleService.customerId;
        }
    }

    roleFormOnSubmit() {

        let api = this.roleId ? this.roleService.update(this.roleId, this.model) : this.roleService.create(this.model);

        this.subs.sink = api.subscribe(resp => {
            if (resp) {
                this.toastr.success(this.roleId ? 'Role info updated' : `Role created`);
                this.router.navigate(['/roles', resp.id, 'view']);
            }
        },
            err => {
                this.toastr.error('Operation Failed');
            })
    }

    onCancelClicked(): void {
        this.location.back();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
