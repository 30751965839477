import { WHBaseModel } from "src/app/core/models/common.models";

export class Manufacturer extends WHBaseModel{

    id:number;

    manufacturers_name:string;

    address:string;

    account_number:string;
}
