import { ChangeDetectorRef, Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { CustomField } from 'src/app/core/models/custom-field';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { FilesService } from 'src/app/core/services/files.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { SubSink } from 'subsink';
import { Item, ItemCategory } from '../../../models/items';
import { CategoryService } from '../../../services/category.service';
import { ItemService } from '../../../services/item.service';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss']
})
export class ItemFormComponent implements OnInit {

    @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;

    @Output() onSuccess = new EventEmitter<{ response: Item, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    @Input() suborg_id: number;

    public model: Item;

    private partNumberInitial: string;

    public itemId: number = 0;

    public itemImages:StaticFile[];

    public additionalFields: CustomField[];

    public customFieldInputs = { key: '', value: '' };

    public partNumberHelper = { valid: true, message: '', visible: false };

    public lastGenPartNumber: Observable<string>;

    public currentCategory: ItemCategory;

    public previousCategory: ItemCategory;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public isClone: boolean = false;

    private subs: SubSink = new SubSink();


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        // public location: Location,
        private cdr: ChangeDetectorRef,
        private itemService: ItemService,
        public modalService: NgbModal,
        private catService: CategoryService,
        private customFields: CustomFieldsService,
        private fileService: FilesService,
        private ra: ResourceAccessService) { }

    ngOnInit(): void {
        this.model = this.itemService.createModel(Item);
        if(this.suborg_id > 0){
            this.model.suborg = this.suborg_id;
          }
    }

    onSubmit() {
        this.model.additional_fields = this.customFields.customFields2Obj(
            this.additionalFields
        );

        let thumbails: ThumbNail[] = this.fileUploader.getFiles();

        if (thumbails.length) {

            this.subs.sink = this.fileService.uploadFiles(thumbails, 'ITEMS').subscribe(response => {

                let primary = this.fileUploader.thumbNails.find(t=> t.isPrimary);

                if (primary) {
                    this.model.primary_image = primary.data_id || response.find(f=> f.name = primary.name)?.id;
                }
                else if(response.length){
                    this.model.primary_image = this.fileUploader.thumbNails[0].data_id || response[0].id;
                }

                this.model.images.push(...response.map(item => item.id));

                this.submit();
            });
        }
        else {
            this.model.primary_image = this.fileUploader.getPrimary()?.data_id || null;
            this.submit();
        }
    }

    submit() {
        this.model.customer = this.itemService.customerId;

        let api =  this.itemService.create(this.model);

        this.subs.sink = api.subscribe(
            (resp) => {
                this.onSuccess.emit({response:resp, message:"New Item created"})
            },
            () =>{
                this.onError.emit()
            }
        );
    }

    addCustomField() {
        this.additionalFields = this.additionalFields ?? [];

        if (
            !this.additionalFields.some((x) => x.name === this.customFieldInputs.key)
        ) {
            let customField = new CustomField();
            customField.name = this.customFieldInputs.key;
            customField.value = this.customFieldInputs.value || null;
            this.additionalFields.push(customField);
        } else {
            this.toastr.info(`Field '${this.customFieldInputs.key}' exist`);
        }
        this.modalService.dismissAll();
    }

    openModal(content: any) {
        this.customFieldInputs.key, (this.customFieldInputs.value = '');
        this.modalService.open(content, { centered: true });
    }

    deleteCustomField(id: string) {
        this.additionalFields = this.additionalFields.filter((x) => x.id !== id);
    }

    onCategoryChanged(category: ItemCategory) {
        this.previousCategory = this.currentCategory;
        this.currentCategory = category;
        this.updateAdditionalFields(category);
        this.lastGenPartNumber = !category.create_partnumber
            ? null
            : this.catService.getLastGeneratedPartnumber(category.id);
    }

    generatePartNumber() {
        if (!this.model.category || !this.currentCategory?.create_partnumber)
            return;

        this.subs.sink = this.catService
            .generatePartNumber(this.model.category)
            .subscribe(
                (response) => {
                    this.model.part_number = response.part_number;
                    this.partNumberHelper.valid = true;
                    this.cdr.markForCheck();
                },
                (error) => {
                    this.model.part_number = '';
                    this.partNumberHelper.valid = false;
                    this.partNumberHelper.message =
                        error?.message || 'Unable to create Part Number';
                    this.cdr.markForCheck();
                }
            );
    }

    updateAdditionalFields(current: ItemCategory) {
        let removeTemplate = this.previousCategory?.additional_fields;
        let fields = this.customFields.removeFieldsByObjectsKeys(
            this.additionalFields || [],
            removeTemplate
        );
        let currentFields = this.customFields.obj2CustomFields(
            current.additional_fields
        );
        this.additionalFields = currentFields
            ? this.customFields.merge(fields, currentFields)
            : fields;
    }

    categoryClear() {
        this.model.part_number = '';
        this.lastGenPartNumber = null;
        if (this.currentCategory && this.currentCategory.additional_fields) {
            let removeTemplate = this.currentCategory.additional_fields;
            this.additionalFields = this.customFields.removeFieldsByObjectsKeys(
                this.additionalFields || [],
                removeTemplate
            );
        }
    }

    partNumberOnChange(event: any) {
        let value: string = event.target.value;

        if (!value || value === this.partNumberInitial) {
            this.partNumberHelper.valid = true;
            return;
        }

        this.subs.sink = this.catService.isPartNumberUnique(value).subscribe(
            (response) => {
                this.partNumberHelper.valid = response.is_unique;
                this.cdr.markForCheck();
            },
            () => {
                this.partNumberHelper.valid = false;
                this.partNumberHelper.message = 'Part Number exist';
                this.cdr.markForCheck();
            }
        );
    }

    saveSheet(prop: string) {
        let req = {};
        req[prop] = this.model[prop];
        if (this.itemId > 0) {
            this.subs.sink = this.itemService
                .patch(this.itemId, req)
                .subscribe((resp) => {
                    this.toastr.success('Sheet updated');
                });
        }
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(this.Resource.INV_ITEMS, [permission], this.Resource.INV_MODULE);
    }

    hasPermissionWR(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], this.Resource.INV_MODULE);
    }

    priceVisible() {
        let perm =
            this.itemId > 0 ? this.Permissions.MODIFY : this.Permissions.CREATE;
        return this.hasPermissionWR(this.Resource.INV_ITEMS_PRICE, perm);
    }

    onFileDelete(fileId:string) {
        if (this.model.images) {
            this.model.images = this.model.images.filter(id => id != fileId);
            if (this.model.primary_image === fileId) {
                this.model.primary_image = null;
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
