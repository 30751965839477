import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InvoiceDashBoardCounts } from '../../models/invoice.models';
import { InvoiceService } from '../../services/invoice-service';
import { SubSink } from 'subsink';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';

@Component({
  selector: 'app-invoice-dashboard',
  templateUrl: './invoice-dashboard.component.html',
  styleUrls: ['./invoice-dashboard.component.scss']
})
export class InvoiceDashboardComponent implements OnInit {

    @Output() ApplyFilter = new EventEmitter<any>();

    public dashlet: InvoiceDashBoardCounts = new InvoiceDashBoardCounts();

    public subs: SubSink = new SubSink();

    constructor(
        private service: InvoiceService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.getDashboardCounts()
    }

    getDashboardCounts() {
        this.subs.sink = this.service.getDashboardCounts().subscribe(response => {
            this.dashlet = response;
            this.cdr.markForCheck();
        });
    }

    setFilter(colname: string, value: any) {
        console.log(colname+','+value);
        this.ApplyFilter.emit({"colname":colname, "value":value});
    }

}
