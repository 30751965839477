import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";


export class ServiceContractDataHelper{


    static get defaults(): GFilterParam[] { return [
        { displayName: "All Service Contracts", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Active", colname: 'service_contract_status', condition: 'equalto', value: 'Active', operator: 'AND' },
        { displayName: "Pending", colname: 'service_contract_status', condition: 'equalto', value: 'Pending', operator: 'AND' },
        { displayName: "Draft", colname: 'service_contract_status', condition: 'equalto', value: 'Draft', operator: 'AND' },
        { displayName: "Expired", colname: 'service_contract_status', condition: 'equalto', value: 'Expired', operator: 'AND' },
        { displayName: "Expiring in 90 days", colname: 'expired_in_90_days', condition: 'equalto', value: 'Expired_in_90_days', operator: 'AND' },
      ];}
    
      static get search(): GFilterParam[] { return  [
        {
          displayName: 'Combo Search', colname: '', condition: '', value: '',
          children: [
            { displayName: 'Title', colname: 'title', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Contact Name', colname: 'customer_contact_firstname', condition: 'contains', value: '', operator: 'OR' },
            { colname: 'customer_contact_lastname', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Contact Phone', colname: 'customer_contact_phone', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Contact Email', colname: 'customer_contact_email', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer A/c Number', colname: 'customer_account', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Location', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Customer Location A/c Number', colname: 'location_account', condition: 'contains', value: '', operator: 'OR' },
            { displayName: 'Service Contract number', colname: 'unique_number', condition: 'contains', value: '', operator: 'OR' },
          ]
        },
        { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'OR' },
        {
          displayName: 'Customer Contact Name', colname: '', condition: '', value: '',
          children: [
            { colname: 'customer_contact_firstname', condition: 'contains', value: '', operator: 'OR' },
            { colname: 'customer_contact_lastname', condition: 'contains', value: '', operator: 'OR' },
          ]
        },
        { displayName: 'Customer Contact Phone', colname: 'customer_contact_phone', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Contact Email', colname: 'customer_contact_email', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer A/c Number', colname: 'customer_account', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Location', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Location A/c Number', colname: 'location_account', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Service Contract number', colname: 'unique_number', condition: 'contains', value: '', operator: 'OR' },
      ];
    }
    
    
      static get columns(): GFColumn[]{ return [
        {
          displayName: 'Status', columnName: 'service_contract_status', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
          options: [
            { displayName: 'Active', value: 'Active' },
            { displayName: 'Pending', value: 'Pending' },
            { displayName: 'Expired', value: 'Expired' },
            { displayName: 'Draft', value: 'Draft' },
            { displayName: 'Expiring in 90 days', value: 'Expired_in_90_days' },
          ]
        },
        { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
        { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
        { displayName: 'Start Date', columnName: 'starting_date', columnType: GFColumnTypes.date },
        { displayName: 'End Date', columnName: 'ending_date', columnType: GFColumnTypes.date },
      ];
    }
    
      static get sortLookup() { 
          return  {
            created_on:"created_on",
            title:"title",
            tenant_customer_name:"customer_name",
            service_contract_status:"service_contract_status",
            coverage_type:"coverage_type",
          };
    }

    static get statusClass(){
      return { "Active": "wty-status-active", "Pending": "wty-status-pending", "Draft": "wty-status-draft", "Expired": "wty-status-expired" };
    }



}