import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ServiceTicketService } from '../../../service-ticket/services/service-ticket.service';
import { StCallHistory } from '../../../service-ticket/models/service-ticket';
import { CallLog } from 'src/app/modules/ring2voice/models/reports.models';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'st-callhistory',
  templateUrl: './st-callhistory.component.html',
  styleUrls: ['./st-callhistory.component.scss']
})
export class StCallhistoryComponent implements OnInit {

  @ViewChild('dataTable') dataTable: DatatableComponent;
  @ViewChild('commentsModal') commentModal: any;

  @Input() stId:number;

  public isSuperAdmin: boolean;
  public callHistory:StCallHistory;
  public expanded:boolean = true;
  private currentPlayer: HTMLAudioElement;
  private backgroundClasses: string[] = ["bg-primary", "bg-success", "bg-danger", "bg-warning", "bg-info"];
  public sIdForComment:string;
  public currentAudio:number = 0;

  constructor(private stServices:ServiceTicketService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,) {

    this.isSuperAdmin = stServices.isSuperUser;
  }

  ngOnInit(): void {

    this.stServices.getStCallHistory(this.stId).subscribe(res =>{

      this.callHistory = res.call_history;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewChecked() {
    if (this.dataTable && this.dataTable.rowDetail) {
        this.dataTable.rowDetail.expandAllRows();
        this.cdr.detectChanges();
    }
}

  audioPlayerPlay(audio: HTMLAudioElement) {
    if (this.currentPlayer && this.currentPlayer != audio) {
        this.currentPlayer.pause();
    }
    this.currentPlayer = audio;
}

  randomDpBackground(row:CallLog) {
    row['bgClass'] = row['bgClass'] || this.backgroundClasses[Math.floor(Math.random() * this.backgroundClasses.length)];
    return row['bgClass'];
}

readComments(sid: string) {

  this.sIdForComment = sid;
  if (sid) {
    const modalRef = this.modalService.open(this.commentModal, { centered: true, scrollable: true })

    modalRef.result.then((data) => {
  }, (reason) => {



  });
  }
}

changeAudio(action:string){

    if(action == 'next'){

        this.currentAudio++;
    }else{
        this.currentAudio--;
    }

}

}
