import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DataFilterGroupRequest, DataFilterRequest, GFColumn, GFColumnTypes, GFilterGroup, GFilterParam, GSortParam, GridTab, Pagination } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { CallLog } from '../../../models/reports.models';
import * as moment from 'moment';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { Observable, of } from 'rxjs';
import { SubSink } from 'subsink';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { DidsService } from '../../../services/dids.service';
import { BlockListService } from '../../../services/block-list.service';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { WINDOW } from 'src/app/core/services/window.service';
import { CallCenterService } from '../../../services/call-center.service';
import { SipOutBoundFunctionService } from '../../../services/sipoutboundfunctionservice';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { CallsDashBoardData } from '../../../models/dids.models';
import { CallReportsDataHelper } from '../../../helpers/call-reports-data.helpers';

@Component({
  selector: 'app-outbound-call-reports',
  templateUrl: './outbound-call-reports.component.html',
  styleUrls: ['./outbound-call-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutboundCallReportsComponent implements OnInit {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;
  @ViewChild('dataTable') dataTable: DatatableComponent;
  @ViewChild('commentsModal') commentModal: any;

  @Input() page: Pagination = { count: 0, limit: 20, offset: 0, pageNumber: '0-0' };

  @Input() rows: CallLog[] = []

  @Input() public rows2: {count : number,data :CallLog[]} = {count:0, data:[]}

  @Input() set gridTabs(value: GridTab[]){

      if(value){
          this._gridTabs=value;

      }

  }

  @Input() set activeTab(value:string){
    if(value){
      this._activeTab=value;
    }
  }

  @Output() onSelectFilterTab=new EventEmitter<any>();

  


  public _gridTabs: GridTab[]=[
      { name: "Inbound", id: "inbound", selected: false, icon:"assets/img/svg/incomingcall.svg", iconHeight:16, iconWidth:16 },
      { name: "Outbound", id: "outbound", selected: true, icon:"/assets/img/svg/outgoingcall.svg", iconHeight:16, iconWidth:16 },
      // { name: "Missed", id: "missed", selected: false, icon:"assets/img/caller assets/missed.png", iconHeight:11, iconWidth:16 },
  ];

  public _activeTab:string='outbound';

  public selected: CallLog;

  public expanded: boolean = true;

  public sort: GSortParam[] = [] // [{ colname: 'date_created', direction: 'desc' }];

  public columns: GFColumn[] = CallReportsDataHelper.FilterColumns;

  public search: GFilterParam[] = CallReportsDataHelper.SearchFilters;

@Input() defaults: GFilterParam[] = [
  // { displayName: "All Calls", colname: '', condition: 'is', value: null, operator: 'AND' },
  {
      displayName: "Calls Today", colname: 'date_created_between', condition: 'date_between',
      value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      operator: 'AND', default: true
  },
  // { displayName: "Queued", colname: 'real_status', condition: 'is', value: 'queued', operator: 'AND' },
  { displayName: "No Answer", colname: 'status', condition: 'is', value: 'no-answer', operator: 'AND' },
  { displayName: "Completed", colname: 'status', condition: 'is', value: 'completed', operator: 'AND' },
  { displayName: "Canceled", colname: 'status', condition: 'is', value: 'canceled', operator: 'AND' },
  {
      displayName: "Calls Yesterday", colname: 'date_created', condition: 'date_between',
      value: [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
      operator: 'AND'
  },
  {
      displayName: "Calls This Week", colname: 'date_created', condition: 'date_between',
      value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
      operator: 'AND'
  },
  {
      displayName: "Calls Last Week", colname: 'date_created', condition: 'date_between',
      value: [moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'), moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')],
      operator: 'AND'
  },
  {
      displayName: "Calls This Month", colname: 'date_created', condition: 'date_between',
      value: [moment().startOf('month').startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      operator: 'AND'
  },
  {
      displayName: "Calls Last Month", colname: 'date_created', condition: 'date_between',
      value: [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
      operator: 'AND'
  }
];

private backgroundClasses: string[] = ["bg-primary", "bg-success", "bg-danger", "bg-warning", "bg-info"];

public module = ModuleConstants.RING2VOICE_CALL_REPORTS;

public childCalls: Observable<CallLog[]>;

public subs: SubSink = new SubSink();

public SelectionType = SelectionType;

public scrollbarH: boolean = false;

public isSuperAdmin: boolean;

public suborg_id: number;

public permission = PermissionConstants;

public user_id: number;

public blockStatus: boolean;

public showSearch:boolean = true

public sortLookup = {
    date_created: 'date_created',
    duration: 'duration',
    suborg_name: 'suborg_name',
    phone_number_name: 'phone_number_name',
    forward_number_name: 'forward_number_name'
};

@Input() showCount: boolean = true

@Input() showGridFilter: boolean = true

@Input() showExport: boolean = true

@Input() showAdvancedFilter: boolean = true //to Show hide advanced filter on grid filter

@Input() baseFilter: GFilterParam[] =  [{ colname: 'direction', condition: 'is', value: 'outbound', operator: 'AND' }];

@Input() defaultFilter: GFilterParam[];

public dateFilter = this.defaults.filter(item => item.displayName === 'Calls Today');

@Input() enableViewDetails:boolean = true

@Input() set showSavedFilter(value:boolean){

  this._showSavedFilter=value;

}

public _showSavedFilter:boolean=true;

public carddata: string;

private currentPlayer: HTMLAudioElement;

public selectedGridTabId = "all"

public tabHeaderHeight: number = 50;

public sIdForComment:string;

@Input() showreportType:boolean =true

//ublic reportType:string = "inbound"
   
public reportTypeTitle:string = "Outbound"

@Input() reportTypeData:any

@Output() reportTypeToggleVal  = new EventEmitter<any>();

@Input() isAllSuborg:boolean = false; //to show reports from all suborg

@Input() showDefaultFilter:boolean = true;

public dashboardData: Observable<CallsDashBoardData>;

public pattern = /^[0-9\-]*$/;




  constructor(
    private cdr: ChangeDetectorRef,
    private didService: DidsService,
    private blockListService: BlockListService,
    private toastr: ToastrService,
    public callCenterService: CallCenterService,
    public outboundService: SipOutBoundFunctionService,
    private modalService: NgbModal,
    private ra: ResourceAccessService,
    @Inject(WINDOW) private window: Window
  ) {


    this.isSuperAdmin = didService.isSuperUser;
    this.user_id = blockListService.userId;
    this.suborg_id = this.didService.suborgId;

    if (this.showAdvancedFilter) {

        if (this.isSuperAdmin && this.showAdvancedFilter) {
            this.columns.push(...[
                { displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref },
                { displayName: 'Sub Org  (Choose Tenant First)', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentColumn: 'customer' },
                { displayName: 'Incoming Routes   (Choose Sub Org First)', columnName: 'forward_number_sid', columnType: GFColumnTypes.ref, dependentColumn: 'suborg' },
            ]);
        }
        else if (!this.didService.isAllSuborg && this.showAdvancedFilter) {
            this.columns.push({ displayName: 'Incoming Route', columnName: 'forward_number_sid', columnType: GFColumnTypes.ref })
        }
    }
   }

  ngOnInit(): void {

    if(this.rows2.data.length > 0) {

      this.rows = [...this.rows2.data]
      this.setPagination(0, this.rows2.count);

    }
    else{
          this.getData([{ 'conditions': this.dateFilter, 'operator': 'AND' }])
          // this.getDashboardData();

      
      this.getDashboardData();

    }


  if (!this.showAdvancedFilter) {
      this.columns = undefined
  }
  }



  ngAfterViewInit()
  {
    console.log('default filter2', this.defaultFilter)
  }
  ngAfterViewChecked() {
    if (this.dataTable && this.dataTable.rowDetail) {
        this.dataTable.rowDetail.expandAllRows();
        this.cdr.detectChanges();
    }
}

selectedDashBoardCard(filters) {
  filters.forEach((filter) => {
      const conditions = filter.conditions;
      conditions.forEach((condition) => {
          const displayName = condition.displayName;
          if (displayName === 'Calls Today' ||
              displayName === 'Calls Yesterday' ||
              displayName === 'Calls This Week' ||
              displayName === 'Calls Last Week' ||
              displayName === 'Calls This Month' ||
              displayName === 'Calls Last Month') {
              this.carddata = displayName;
              this.cdr.markForCheck();
          }
      });
  });
}

getDashboardData() {
  this.dashboardData = this.didService.getOutboundCallsDashboardData();
}

setFilter(displayName: string) {
  const filter = this.defaults.find(item => item.displayName === displayName);
  if (filter) {
      this.dataFilter.setDefaultFilter(filter.colname, filter.value);
  }
  
  this.cdr.markForCheck();
}

setPagination(offset: number, total: number) {
  this.page.count = total;
  let upperLimit = offset + this.page.limit;
  if (upperLimit > total) {
      upperLimit = total;
  }
  this.page.pageNumber = offset + '-' + upperLimit;
}

setPage(pageInfo: any) {
  let offset = pageInfo.offset * this.page.limit;
  this.page.offset = pageInfo.offset;

  if (this.showGridFilter) {

      this.getData(this.dataFilter.getDataFilters2(), offset);
  }
  else {
      this.getData([], offset)
  }

  this.scrollToTop()
}

scrollToTop() { 
  this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}

getData(filters: GFilterGroup[] = [], offset: number = 0) {

  if (this.showCount == true) {
    this.selectedDashBoardCard(filters);
}
  if(this.defaultFilter)
  {
      if(filters == null)
      {
          filters = []
      }
      filters.push({ 'conditions': this.defaultFilter, 'operator': 'AND' })
  }

  let request: DataFilterGroupRequest = {
      base_filters: this.baseFilter,
      filters: filters,
      sort: this.sort
  };



  if (filters != null) {
      let hasDirectioninFilter = filters.some(filter => filter.conditions.some((condition) => condition?.colname === 'direction'));
      if (hasDirectioninFilter) {
          request.base_filters = [];
      }
  }

  console.log('req', request, 'offset', offset)

  this.subs.sink = this.didService.getCallReport(request, offset, this.isAllSuborg).pipe(
      catchError(err => of({ count: 0, result: [] }))
  ).subscribe(response => {
      this.rows = response.result;
     // this.rows2 =response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
  });
}
bulkExport() {
  let request = {
      base_filters: [],
      filters: this.dataFilter.getDataFilters2(),
      sort: this.sort
  }
  request.base_filters.unshift({ colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' });
  // request.filters.unshift({conditions:[{ colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' }],operator:'AND'});

  this.subs.sink = this.didService.callReportExport(request).subscribe(
      resp => {
          var downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(resp);
          downloadLink.download = `Ring2Voice_${moment().format('MM-DD-YYYY-hh_mm')}.csv`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
      },
      () => {
          this.toastr.error("Unable to export");
      }
  )
}

applyFilterGroup(groups: GFilterGroup[]) {

  this.getData(groups);
}

onSort(event: any) {
  if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop]) return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let offset = event.offset * this.page.limit;
      this.page.offset = event.offset;
      this.getData(this.dataFilter.getDataFilters2(), offset);
  }
}

getOutBoundCall(inbound_sid: string) {
  if (inbound_sid) {
      let request: DataFilterRequest = {
          filters: [],
          base_filters: [
              { colname: 'direction', condition: 'is', value: 'outbound-dial', operator: 'AND' },
              { colname: 'parent_call_sid', condition: 'is', value: inbound_sid, operator: 'AND' },
          ],
          sort: null
      };
      this.childCalls = this.didService.getCallReport(request).pipe(
          catchError(err => of({ count: 0, result: [] })),
          map(resp => resp.result)
      );
  }
  else {
      this.childCalls = of([]);
  }
}
onSelect({ selected }) {
  if(this.enableViewDetails == true)
  {
      let current: CallLog = selected[0];
      if (current) {
          this.selected = current;
          this.expanded = false;
          this.getOutBoundCall(current.sid);
         console.log('current',current)
          this.refreshDataTable();
      }
  }
}

refreshDataTable() {
  setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
  });
}
randomDpBackground(row: CallLog) {
  row['bgClass'] = row['bgClass'] || this.backgroundClasses[Math.floor(Math.random() * this.backgroundClasses.length)];
  return row['bgClass'];
}
callBack(row) {
  const noToCall = row.direction == 'inbound' ? row.call_from : row.call_to;
  this.outboundService.setOutBoundNumber(noToCall)
  this.callCenterService.popupOpenStatus.next({ isOpen: true, selectedTab: 2, })
}

audioPlayerPlay(audio: HTMLAudioElement) {
  if (this.currentPlayer && this.currentPlayer != audio) {
      this.currentPlayer.pause();
  }
  this.currentPlayer = audio;
}


readComments(sid: string) {
  this.sIdForComment = sid;
  if (sid) {
    this.modalService.open(this.commentModal, { centered: true, scrollable: true })
  }
}

addToBlockList()
{

}
closeDetailedView() {
  this.expanded = true;
  this.selected = null;
  this.refreshDataTable();
}
hasBinViewInfoPermission() {
  return this.ra.hasPermission(
      ResourceConstants.R2V_FORWARD_NUMBERS,
      [PermissionConstants.VIEW_INFO],
      ResourceConstants.R2V_MODULE
  );
}
// onChangeReportType(event)
// {
//   this.carddata = "Calls Today"
//   this.reportTypeToggleVal.next(event)
// }


onSelectGridTabs(event){
  this.carddata = "Calls Today"
  this.onSelectFilterTab.emit(event);

}

}
