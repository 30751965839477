import { GridMeta } from '../../models/grid-profiling.models';
import { GridMetaService } from './../../services/grid-meta.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-grid-meta-select',
  templateUrl: './grid-meta-select.component.html',
  styleUrls: ['./grid-meta-select.component.scss']
})
export class GridMetaSelectComponent implements OnInit {

  @Input() value: number;

  @Input() placeholder: string = "Select grid meta";

  @Output() valueChange = new EventEmitter<number>();

  @Output() onChange = new EventEmitter<GridMeta>();

  public selected: number;

  public options = new Observable<GridMeta[]>();

  constructor(private metaService: GridMetaService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.options = this.metaService.getAll().pipe(map(resp => {
      return resp.gridresources;
    }));
  }

  onValueChanged(value: GridMeta) {
    this.value = value ? value.id : null;
    this.valueChange.emit(value ? value.id : null);
    this.onChange?.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      this.selected = changes.value.currentValue;
      this.cdr.markForCheck();
    }
  }
}
