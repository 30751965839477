import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";

export class CustomerDataHelper {

    static get FilterColumns(): GFColumn[] {
        return [
            { displayName: 'Customer Name', columnName: 'tenant_customer_name', columnType: GFColumnTypes.string },
            { displayName: 'Customer Account Number', columnName: 'account_number', columnType: GFColumnTypes.string },
            { displayName: 'Customer Account Rep', columnName: 'rep_id', columnType: GFColumnTypes.ref },
            { displayName: 'Customer Type', columnName: 'customer_type_id', columnType: GFColumnTypes.ref },
            { displayName: 'Customer System Type', columnName: 'customer_system_type_id', columnType: GFColumnTypes.ref },
            { displayName: 'Industry', columnName: 'industry_id', columnType: GFColumnTypes.ref },
            { displayName: 'Currency', columnName: 'currency_id', columnType: GFColumnTypes.ref },
            { displayName: 'Payment Term', columnName: 'payment_terms_id', columnType: GFColumnTypes.ref },
            { displayName: 'Contact First Name', columnName: 'first_name', columnType: GFColumnTypes.string },
            { displayName: 'Contact Last Name', columnName: 'last_name', columnType: GFColumnTypes.string },
            { displayName: 'Contact Email', columnName: 'contact_email', columnType: GFColumnTypes.string },
            { displayName: 'Contact Phone', columnName: 'contact_phone', columnType: GFColumnTypes.string },
            { displayName: 'Contact Mobile', columnName: 'contact_mobile', columnType: GFColumnTypes.string },
            { displayName: 'Location Account Number', columnName: 'location_number', columnType: GFColumnTypes.string },
            { displayName: 'Service Ticket Billable', columnName: 'service_ticket_billable', columnType: GFColumnTypes.boolean },
            { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
            { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref }
        ];
    }

    static get DefaultFilters(): GFilterParam[] {
        return [
            { displayName: "All Customers", colname: '', condition: 'is', value: null, operator: 'AND' },
            { displayName: "Active Customers", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
            { displayName: "Inactive Customers", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
        ];
    }

    static get SearchFilters(): GFilterParam[] {
        return [
            {
                displayName: 'Combo Search', colname: '', condition: '', value: '',
                children: [
                    { displayName: 'Customer Name', colname: 'tenant_customer_name', condition: 'contains', value: '', operator: 'OR' },
                    { displayName: 'Address', colname: 'location_address', condition: 'contains', value: '',operator: 'OR' },
                    { displayName: 'Location Name', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
                    { displayName: 'City', colname: 'location_city', condition: 'contains', value: '', operator: 'OR' },
                    { displayName: 'State', colname: 'location_state', condition: 'contains', value: '', operator: 'OR' },
                    { displayName: 'Contact First Name', colname: 'first_name', condition: 'contains', value: '', operator: 'OR' },
                    { displayName: 'Contact Second Name', colname: 'last_name', condition: 'contains', value: '', operator: 'OR' },
                    { displayName: 'Customer Account Number', colname: 'account_number', condition: 'contains', value: '', operator: 'OR' },
                    { displayName: 'Location Account Number', colname: 'location_number', condition: 'contains', value: '', operator: 'OR' },
                    { displayName: 'Contact Email', colname: 'contact_email', condition: 'contains', value: '', operator: 'OR' },
                    {
                        displayName: 'Contact Phone', colname: 'contact_phone',
                        condition: 'contains', value: '', operator: 'OR',
                        type: GFColumnTypes.phone
                    },
                    {
                        displayName: 'Contact Mobile', colname: 'contact_mobile',
                        condition: 'contains', value: '', operator: 'OR',
                        type: GFColumnTypes.phone
                    }
                ]
            },
            { displayName: 'Customer Type', colname: 'customer_type_name', condition: 'contains', operator: 'AND', value: '' },
            { displayName: 'Customer System Type', colname: 'customer_system_type_name', condition: 'contains', operator: 'AND', value: '' },
            { displayName: 'Industry', colname: 'industry_name', condition: 'contains', operator: 'AND', value: '' },
            { displayName: 'Currency', colname: 'currency_name', condition: 'contains', operator: 'AND', value: '' },
            { displayName: 'Payment Term', colname: 'payment_terms_name', condition: 'contains', operator: 'AND', value: '' }
        ];
    }

    static get SortLookup(): any {
        return {
            name: 'tenant_customer_name',
            account_number: 'account_number',
            customer_type_name: 'customer_type_name',
            industry_name: 'industry_name',
            currency_name: 'currency_name',
            payment_terms_name: 'payment_terms_name',
            license_number: 'license_number',
            customer_system_type_name: 'customer_system_type_name'
        };
    }
}
