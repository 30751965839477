import { CustomerLocation } from './../../../tenant-customers/models/tenant-customer';
import { ServiceTicketCommentsComponent } from './../../components/service-ticket-comments/service-ticket-comments.component';
import { CustomerLocationSelectComponent } from './../../../tenant-customers/components/select/customer-location-select/customer-location-select.component';
import { PermissionConstants } from './../../../../core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { Observable, of } from 'rxjs';
import { ServiceTicketService } from './../../services/service-ticket.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SubSink } from 'subsink';
import { ItemAdd, ServiceTicket } from '../../models/service-ticket';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { CustomerContact, TenantCustomer } from 'src/app/modules/tenant-customers/models/tenant-customer';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { ImportantMessagesService } from 'src/app/modules/tenant-customers/services/important-messages.service';
import { imList } from 'src/app/modules/tenant-customers/models/important-messages.models';
import { SalesService } from 'src/app/modules/sales/services/sales.service';
import { PriorityService } from '../../services/priority.service';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { FilesService } from 'src/app/core/services/files.service';
import { StItemsComponent } from '../../components/st-items/st-items.component';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { CommentsComponent } from 'src/app/shared/components/comments/comments.component';
@Component({
    selector: 'app-add-service-ticket',
    templateUrl: './add-service-ticket-new.component.html',
    styleUrls: ['./add-service-ticket.component.scss']
})
export class AddServiceTicketComponent implements OnInit, OnDestroy {

    @ViewChild('locationSelect') locationSelect: CustomerLocationSelectComponent;

    @ViewChild('stcomment') stComment: CommentsComponent;

    @ViewChild('MatrialGrid') STMaterialGrid: StItemsComponent;

    @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;

    @Input() callCenter : boolean = false; // to add service with minimal data

    @Input() incomingcall_Details;

    @Input() model: ServiceTicket;

    @Input() serviceTicketId: number = 0;

    @Input() suborgId: number;

    public customerIms;

    private subs: SubSink = new SubSink();

    public images: StaticFile[];

    public submitted: boolean = false;

    @Input() from_Number_name: string;

    @Output() isCancelClicked : EventEmitter<boolean> = new EventEmitter<boolean>()

    @Output() isSubmitted : EventEmitter<boolean> = new EventEmitter()


    constructor(public location: Location,
        private service: ServiceTicketService,
        private toastr: ToastrService,
        route: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private ra: ResourceAccessService,
        private salesService: SalesService,
        private imService: ImportantMessagesService,
        private priorityService: PriorityService,
        private fileService: FilesService,
        private phoneNumberPipe: PhoneNumberPipe) {

        this.subs.sink = route.params.subscribe(params => this.serviceTicketId = +params.id || 0);
    }

    ngOnInit() { 
        debugger
        if (this.serviceTicketId > 0) {
            this.subs.sink = this.service.getById(this.serviceTicketId).subscribe(serviceTicket => {
                this.model = serviceTicket;
                this.getImportantMessages(this.model.tenant_customer);
                this.cdr.markForCheck();
                this.getImages();
            });
        }
        else{
            if(!this.model)
            {
                this.model = this.service.createModel(ServiceTicket);
            }
        }
    }
    customerChanged(customer: TenantCustomer) {
        if (customer) {
            this.model.tenant_customer_contact = undefined;
            this.model.tenant_customer_location = undefined;
            this.model.reason_for_call = null;
            this.model.message_isuser_confirmation = null;
            this.model.reason_for_call_message = null;
            this.model.reason_for_call_show = null;
            this.model.is_billable = customer.is_service_ticket_billable;
            this.model.tax=(customer&&customer.tax_percentage)?customer.tax_percentage:0;
            this.getImportantMessages(customer.id);
        }
    }

    customerContactChanged(contact: CustomerContact) {
        if (contact && contact.location > 0) {
            this.model.tenant_customer_location = contact?.location;
        }
        else {
            this.locationSelect?.clear();
        }
    }

    customerLocationChanged(location: CustomerLocation) {
        this.model.is_billable = location?.is_billing || false;
    }

    validate() {
        if(this.model.tenant_customer==undefined ||
            this.model.tenant_customer_contact==undefined ||
            this.model.tenant_customer_location==undefined){
                return false
        }
        else
            return true
    }

    submit() {

        if (!this.validate()){
            this.toastr.error("You should enter all mandatory fields");
            return;
        }

        console.log(this.model.expected_service_start_date,'this.model.expected_service_start_date')
        if (this.model.expected_service_start_date) {
            let _date = this.model.expected_service_start_date.toString() + ' ' + ('00:00:00')
            this.model.expected_service_start_date = new Date(_date);
        }

        if(this.fileUploader)
        {
            let thumbails: ThumbNail[] = this.fileUploader.getFiles();

            if (thumbails.length) {

                this.subs.sink = this.fileService.uploadFiles(thumbails, 'SERVICE_TICKET').subscribe(response => {

                    let primary = thumbails.find(t=> t.isPrimary);

                    if (primary) {
                        this.model.primary_image = response.find(f=> f.name = primary.name)?.id;
                    }
                    else if(response.length){
                        primary = this.fileUploader.getPrimary();
                        this.model.primary_image = (primary) ? primary.data_id : response[0].id;
                    }

                    this.model.images.push(...response.map(item => item.id));
                    this.afterSubmit();
                });
            }
            else {
                this.model.primary_image = this.fileUploader.getPrimary()?.data_id || null;
                this.afterSubmit();
            }
        }
        else
        {
            this.afterSubmit();
        }

    }

    afterSubmit(){
        let api: Observable<ServiceTicket>;

        this.submitted = true;

        if (this.serviceTicketId > 0) {
            api = this.service.update(this.serviceTicketId, this.model);
        }
        else {
            api = this.service.create(this.model);
        }

        this.subs.sink = api.subscribe(
            resp => {
                this.toastr.success(this.serviceTicketId > 0 ? `Ticket info updated` : `New Service Ticket created`);
                if (!this.serviceTicketId) {
                    this.stComment?.saveComment(resp.id, resp.suborg, comment => {
                        this.changeRoute(resp);
                    });
                }
                else {
                    this.changeRoute(resp);
                }
                this.isSubmitted.emit(true)
            },
            () => {
                let errorMessage = this.serviceTicketId > 0 ? `Failed to update Service Ticket` : `Failed to create Service Ticket`;
                this.toastr.error(errorMessage);
            }
        );
    }

    callReasonChange(reason) {

        if(reason.priority){

            this.model.priority=reason.priority;
        }else{
            this.model.priority=undefined;
        }

        this.setEscalation(this.model.priority);

        this.model.reason_for_call_message = null;
        this.model.confirmation_required = null;
        this.model.message_isuser_confirmation = null;

        let condition = false;
        if (this.customerIms && this.customerIms.length) {
            console.log(this.customerIms);

            this.customerIms.forEach((value: imList, index) => {
                if (value.reason_for_service == reason.id) {
                    console.log("Condition satisfied");
                    this.model.reason_for_call_message = value.message;
                    this.model.confirmation_required = value.required_confirmation;
                    condition = true;
                }
            });
        }

        if (!condition) {
            this.model.reason_for_call_message = reason.message;
            this.model.confirmation_required = reason.required_confirmation;
        }
    }

    priorityChange(priority) {

        if(priority.escalation_period){

            this.model.escalation_period=priority.escalation_period;
        }else{
            this.model.escalation_period=undefined;
        }

    }

    setEscalation(priority_id){

        if (priority_id > 0) {
            this.subs.sink = this.priorityService.getById(priority_id).subscribe(priority => {
                this.priorityChange(priority);
            });
        }else{
            this.priorityChange([])
        }

    }

    getImportantMessages(id: number) {

        let request = { filters: [{ "colname": "is_active", "condition": "contains", "value": true, "operator": "AND" }], sort: null }

        this.subs.sink = this.imService.getFiltered(id, request, 0).pipe(
            catchError(err => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.customerIms = response.result;
        });
    }

    getImages() {
        this.subs.sink = this.service.getSTImages(this.serviceTicketId).subscribe(resp => {
            this.images = resp;
            this.model.images = resp.map(file => file.id);
            this.cdr.markForCheck();
        });
    }

    subTotalChange() {
        this.model.sub_total = this.salesService.trimNumber(this.model.sub_total, 4);
    }

    changeRoute(ticket: ServiceTicket) {
        if (this.hasPermission(PermissionConstants.VIEW_INFO)) {
            this.router.navigate(['/service-tickets', ticket.id, 'view']);
        }
        else {
            this.location.back();
        }
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.SERVICE_TICKETS, [permission], ResourceConstants.SERVICE_TICKET_MODULE);
    }

    onCustomerLocationAdded() { this.locationSelect?.reload(); }

    onBillableChanged(value:boolean) {
        if(!value) {
            this.model.sub_total = null;
        }
    }

    onFileDelete(fileId:string) {
        if (this.model.images) {
            this.model.images = this.model.images.filter(id => id != fileId);
            if (this.model.primary_image === fileId) {
                this.model.primary_image = null;
            }
        }
    }

    onCancel()
    {
        if(!this.callCenter)
        {
            this.location.back();
        }
        this.isCancelClicked.emit(true)
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
