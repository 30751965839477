<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter [module]="module"
                    [searchFilters]="searchFilters"
                    [defaultFilters]="defaults"
                    [columns]="columns"
                    [newButton]="UIPermissions.create"
                    [newButtonUrl]="['/inventory/item-categories/new']"
                    [expanded]="expanded"
                    [import]=true
                    [importColumns]="importColumns"
                    (importSubmit)="onImportSubmit($event)"
                    (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue"></suborg-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" [limit]="50" [count]="page.count" [externalPaging]="true"
                            [externalSorting]="true" [sorts]="[{prop:'category_prefix',dir:'asc'}]" [offset]="page.offset" [limit]="page.limit" (page)="setPage($event)" (select)="onSelect($event)" (sort)="onSort($event)">
                            <ngx-datatable-column name="Name" prop="category_name"></ngx-datatable-column>
                            <ngx-datatable-column name="Description" prop="description" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column name="Prefix" prop="category_prefix" [width]="150"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Parent" prop="parent_name"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created Date" prop="created_on" [sortable]="true" [width]="100">
                                <ng-template let-crdate="value" ngx-datatable-cell-template>
                                    {{crdate | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isAllSuborg" name="Suborg" prop="suborg_name" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [sortable]="false" [width]="60">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge" [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && !isAllSuborg" name="Action" prop="id" [sortable]="false" [width]="60">
                                <ng-template let-id="value" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                        <i class="ft-more-vertical text-primary"></i>
                                    </button>
                                        <div ngbDropdownMenu>
                                            <a *ngIf="UIPermissions.edit" class="w-100" [routerLink]="['/inventory/item-categories',id,'edit']" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button *ngIf="UIPermissions.delete" class="w-100 bg-light-danger" (click)="deleteCategory(id)" ngbDropdownItem>
                                            <i class="ft-trash-2 mr-1"></i> Delete
                                        </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="category-details">
                        <div class="d-flex flex-row mt-2">
                            <h5 class="item-title m-0">{{selected?.category_name}}</h5>
                            <div class="d-flex justify-content-end align-items-start">
                                <a [routerLink]="['/inventory/item-categories',selected.id,'edit']" *ngIf="UIPermissions.edit && !isAllSuborg" ngbTooltip="Edit" class="btn btn-sm bg-light-secondary">
                                    <i class="ft-edit"></i> Edit
                                </a>
                                <button *ngIf="UIPermissions.delete && !isAllSuborg" class="btn btn-sm bg-light-danger ml-1" (click)="deleteCategory(selected.id)">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                                <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                            </div>
                        </div>
                        <div class="group mt-2">
                            <div class="group-header d-flex justify-content-between">
                                Overview
                                <div class="status-toggle-btn">
                                    <label class="title">Status :</label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [(ngModel)]="selected.is_active" (change)="changeStatus()" id="status" name="active" class="custom-control-input"
                                            [disabled]="selected.is_active ? !UIPermissions.deactivate : !UIPermissions.activate">
                                        <label class="custom-control-label" for="status">
                                        <span></span>
                                    </label>
                                    </div>
                                </div>
                                <div class="status-toggle-btn">
                                    <label class="title">Show On Catelog :</label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [(ngModel)]="selected.show_on_catelog" name="show_on_catelog"
                                            class="custom-control-input" (change)="changeShowOnCatelog()" id="show_on_catelog"
                                            >
                                        <label class="custom-control-label" for="show_on_catelog">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="group-body">
                                <div class="ov-row" *ngIf="selected?.category_prefix">
                                    <label class="col-md-3 p-0 item-label">Prefix</label>
                                    <label class="item-label-value">{{ selected.category_prefix }}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.parent_name">
                                    <label class="col-md-3 p-0 item-label">Parent</label>
                                    <label class="item-label-value">{{ selected.parent_name }}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Start Range</label>
                                    <label class="item-label-value">{{ selected?.start_range }}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">End Range</label>
                                    <label class="item-label-value">{{ selected?.end_range }}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.digits">
                                    <label class="col-md-3 p-0 item-label">Digits</label>
                                    <label class="item-label-value">{{ selected.digits }}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Part Number Creation</label>
                                    <label class="item-label-value">
                                    <span class="text-success" *ngIf="selected.create_partnumber">Enabled</span>
                                    <span class="text-danger" *ngIf="!selected.create_partnumber">Disabled</span>
                                </label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.description">
                                    <label class="col-md-3 p-0 item-label">Description</label>
                                    <label class="item-label-value">{{selected.description}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.created_on">
                                    <label class="col-md-3 p-0 item-label">Created On</label>
                                    <label class="item-label-value">{{selected.created_on | date:'MM/dd/YYYY'}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.created_by">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value">
                                        {{selected.created_source === CreatedSource.Import ? CreatedSource.Import :
                                        selected.created_by}}
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="selected.suborg_name && isAllSuborg">
                                    <label class="col-md-3 p-0 item-label">Suborg</label>
                                    <label class="item-label-value">{{selected.suborg_name}}</label>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="selected.additional_fields && selected.additional_fields | customfields as custFields">
                            <div class="group mt-2" *ngIf="custFields && custFields.length > 0">
                                <div class="group-header">
                                    Additional Info
                                </div>
                                <div class="group-body">
                                    <ng-container *ngFor="let field of custFields">
                                        <div class="ov-row">
                                            <label class="col-md-3 p-0 item-label">{{field.name}}</label>
                                            <label class="item-label-value">{{field.value || 'NIL'}}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
