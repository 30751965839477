<section class="dashlet-container d-flex flex-row flex-wrap mb-2" *ngIf="dashlet">
    <div class="status-tile" (click)="setFilter('status','1')">
        <div class="card m-0 h-100 new_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.New || 0}}</h3>
                            <span>New</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','2')">
        <div class="card m-0 h-100 draft_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body text-left">
                            <h3 class="mb-0">{{dashlet?.Draft || 0}}</h3>
                            <span>Draft</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','3')">
        <div class="card m-0 h-100 send_to_customer_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body text-left">
                            <h3 class="mb-0">{{dashlet?.Send_To_Customers || 0}}</h3>
                            <span>Sent to Customer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','5')">
        <div class="card m-0 h-100 approved_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Approved || 0}}</h3>
                            <span>Approved</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','4')">
        <div class="card m-0 h-100 rejected_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Rejected || 0}}</h3>
                            <span>Rejected</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','6')">
        <div class="card m-0 h-100 partially_invoiced_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Partially_Invoiced || 0}}</h3>
                            <span>Partially Invoiced</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','7')">
        <div class="card m-0 h-100 fully_invoiced_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Fully_Invoiced || 0}}</h3>
                            <span>Fully Invoiced</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('status','8')">
        <div class="card m-0 h-100 workorder_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Converted_To_WorkOrder || 0}}</h3>
                            <span>Not Billable</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
