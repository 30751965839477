import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { AutomatedBlockList } from '../models/automated-blocklist.models';

@Injectable({
  providedIn: 'root'
})
export class AutomatedBlockService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getBlockList(){
    return this.http.get<AutomatedBlockList>(this.baseURL+`/spam_settings`);
  }

  saveSettings(payload: AutomatedBlockList){
    return this.http.post<AutomatedBlockList>(this.baseURL+`/spam_settings`,payload);
  }
  
}
