import { KitAssembleService } from './../../services/kit-assemble.service';
import { ResourceConstants } from './../../../../core/constants/resources.constants';
import { KitAssembleWarehouseComponent } from './../../components/kit-assemble-warehouse/kit-assemble-warehouse.component';
import { forkJoin } from 'rxjs';
import { KitAssemble } from './../../models/kit-assemble.models';
import { KitService } from './../../services/kit.service';
import { KitWrapper } from './../../models/kit.models';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { AssembledKitItemsComponent } from '../../components/assembled-kit-items/assembled-kit-items.component';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
    selector: 'app-assemble-kit',
    templateUrl: './assemble-kit.component.html',
    styleUrls: ['./assemble-kit.component.scss']
})
export class AssembleKitComponent implements OnInit, OnDestroy {

    @ViewChild(AssembledKitItemsComponent) assembleGrid: AssembledKitItemsComponent;

    @ViewChild(KitAssembleWarehouseComponent) warehouseGrid: KitAssembleWarehouseComponent;

    public assembleId: number = 0;

    public kit: KitWrapper;

    public kitInfo = { name: '', part_number: '', sku: '' };

    public kitId: number;

    public model: KitAssemble;

    public Resources = ResourceConstants;

    public Permissions = PermissionConstants;

    private subs = new SubSink();

    public isAllSuborg:boolean;

    public oldQuantity: number;

    constructor(public location: Location,
        private toastr: ToastrService,
        route: ActivatedRoute,
        private router: Router,
        private kitService: KitService,
        private assembleService: KitAssembleService,
        private ra: ResourceAccessService,
        private cdr: ChangeDetectorRef) {
        this.subs.sink = route.params.subscribe(params => {
            this.kitId = +params.kitid || 0;
            this.assembleId = +params.id || 0;
        });
        this.isAllSuborg = assembleService.isAllSuborg;
    }

    ngOnInit() {
        if (this.kitId) {
            this.subs.sink = this.kitService.getById(this.kitId).subscribe(resp => {
                this.kit = resp;
                this.kitInfo.name = resp.name;
                this.kitInfo.part_number = resp.internal_number;
                this.kitInfo.sku = resp.sku;
                this.cdr.markForCheck();
            });
        }

        if (this.assembleId) {
            this.subs.sink = this.assembleService.getAssembledKitById(this.assembleId).subscribe(resp => {
                this.model = resp;
                this.kitInfo.name = resp.kit_name;
                this.kitInfo.part_number = resp.part_number;
                this.kitInfo.sku = resp.kit_sku;
                this.oldQuantity = this.model.quantity;
                this.cdr.markForCheck();
            });
        }
        else {
            this.model = this.assembleService.createModel(KitAssemble);
            this.oldQuantity = this.model.quantity;
        }
    }

    onSubmit() {

        if (!this.assembleGrid.validate()) {
            this.toastr.info('Select at least one Item or Built Assembly');
            return;
        }
        if (!this.warehouseGrid. quantityCheck()) {
            this.toastr.info('Quantity mismatch');
            return;
        }
        this.warehouseGrid.ModifyDuplicate();
        if (this.assembleId) {
            this.subs.sink = this.assembleService.updateAssembledKit(this.assembleId, this.model).subscribe(resp => {
                this.updateItemsAndWarehouse(resp);
            });
        }
        else {
            this.model.customer = this.kitService.customerId;
            this.model.kits = this.kitId;
            this.subs.sink = this.assembleService.createAssembledKit(this.model).subscribe(resp => {
                this.updateItemsAndWarehouse(resp);
            });
        }
    }

    updateItemsAndWarehouse(kitAssemble: KitAssemble) {
        let itemApis = this.assembleGrid.getApis(kitAssemble.id);
        let whApis = this.warehouseGrid.getApis(kitAssemble.id);
        let apis = [...itemApis, ...whApis];
        if (apis.length > 0) {
            this.subs.sink = forkJoin(apis).subscribe(
                results => {
                    this.toastr.success(this.assembleId > 0 ? 'Assemble Info updated' : 'Assemble request success');
                    this.navigate(kitAssemble);
                },
                err => {
                    this.toastr.error(this.assembleId > 0 ? 'Failed to update Assemble Info' : 'Assemble request failed');
                    this.navigate(kitAssemble);;
                }
            );
        }
        else {
            this.navigate(kitAssemble);
        }
    }

    navigate(kitAssemble: KitAssemble) {
        if (this.hasPermission(this.Permissions.VIEW_INFO)) {
            this.router.navigate(['/inventory/assemblies/assembled', kitAssemble.id, 'view']);
        }
        else {
            this.router.navigate(['/inventory/assemblies/assembled']);
        }
    }

    onChangeQuantity(){
        if(this.model.status && this.model.status !== "DRAFT"){
            if(this.model.quantity < this.oldQuantity){
                let ti = "Inventory Allocation and Warehouses specified will be reversed"
                popup.ConfirmDeleteWithTitle(ti, result => {
                    if(result.isConfirmed){
                        this.subs.sink = this.assembleService.reverseAllocation(this.model.id, this.model.quantity).subscribe(
                            resp => {
                                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                                this.router.onSameUrlNavigation = 'reload';
                                this.router.navigate(['/inventory/assemblies/assembled', this.model.id]);
                            }
                        );
                    }
                    else{
                        this.model.quantity = this.oldQuantity
                    }
                });
            }
        }
        else{
            return;
        }
    }

    onCompleteClick() {
        this.model.status = "COMPLETED"
        this.subs.sink = this.assembleService.updateAssembledKit(this.model.id, this.model).subscribe(resp => {
            this.toastr.success('Build Completed');
            this.router.navigate(['/inventory/assemblies/assembled', this.model.id, 'view']);
        });
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(this.Resources.INV_KITS_ASSEMBLE, [permission], this.Resources.INV_MODULE);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
