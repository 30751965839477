import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Industry } from '../../../models/industry';
import { IndustryService } from '../../../services/industry.service';

@Component({
  selector: 'industry-form',
  templateUrl: './industry-form.component.html',
  styleUrls: ['./industry-form.component.scss']
})
export class IndustryFormComponent implements OnInit, OnDestroy {

  public model: Industry = new Industry();

  @Input() industryId: number = 0;

  @Output() onSuccess = new EventEmitter<{ response: Industry, message: string }>();

  @Output() onError = new EventEmitter<any>();

  @Output() onCancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private service: IndustryService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.industryId > 0) {
      this.getSub$ = this.service.getById(this.industryId).subscribe(industry => {
        this.model = industry;
      });
    }
  }

  onSubmit(): void {

    let api: Observable<Industry>;

    if (this.industryId > 0)
      api = this.service.update(this.industryId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;
      api = this.service.create(this.model);
    }

    this.postSub$ = api.subscribe(
      response => {
        let msg = this.industryId > 0 ? 'Industry info updated' : 'New Industry created';
        this.onSuccess?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.onError?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void {
    this.onCancel?.emit();
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
    this.postSub$?.unsubscribe();
  }
}
