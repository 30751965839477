<div class="" *ngFor="let secPageList of _productListbyPage ; let secindx = index">

  <div cdkDropListGroup>
    <div class="example-container" *ngFor="let sectionPage of secPageList ; let pageindx = index" [ngStyle]="{'margin-top':pageindx == 0 && secindx == 0?'122px':pageindx == 0 && secindx > 0?'268px':'128px'}"> 
      <!-- <p>Page No: {{pageindx + 1}}</p> -->
    <mat-table *ngIf = "sectionPage.length > 0"
      [dataSource]="sectionPage" 
      #tableRef="matTable"
      class="mat-elevation-z8"
      cdkDropList
      (cdkDropListDropped)="drop($event, pageindx, secindx)"
      [cdkDropListData]="sectionPage"
      [cdkDropListDisabled]="dragDisabled" 
      [style.height.px] = "_canvasHeightInPixel"
      [style.max-height.px] = "_canvasHeightInPixel"
      style="overflow: auto">
    
      <ng-container matColumnDef="product_name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" class="cell-content" style="width: 400px;"> {{ (element.product_name?.length>20)? (element.product_name | slice:0:20)+'...':(element.product_name) }} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="product_part_number">
        <mat-header-cell *matHeaderCellDef> Part Number </mat-header-cell>
        <mat-cell *matCellDef="let element" style="width: 100px;"> {{element.product_part_number}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="product_type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let element" style="width: 100px;"> {{element.product_type}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="sku">
        <mat-header-cell *matHeaderCellDef> SKU </mat-header-cell>
        <mat-cell *matCellDef="let element" style="width: 100px;"> {{element.sku}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="category_name">
        <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
        <mat-cell *matCellDef="let element" style="width: 100px;"> {{element.category_name}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="sellprice">
        <mat-header-cell *matHeaderCellDef> Price </mat-header-cell>
        <mat-cell *matCellDef="let element" style="width: 100px;"> {{element.sellprice}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action" sticky>
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell *matCellDef="let element;index as rowindx;" style="width: 100px;">
          <mat-icon class="dragCursor"  (mousedown)="dragDisabled = false;">control_camera</mat-icon>

          <button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #appMenu="matMenu">
          <!-- <button mat-menu-item >Delete</button> -->
          <button mat-menu-item (click) = "onReorderItems(element,secindx,pageindx)">Remove And ReOrder</button>
          <button mat-menu-item (click) = "onItemDelete(element,secindx,pageindx,rowindx)">Remove Data And Template</button>
        </mat-menu>  
          
        </mat-cell>
      </ng-container>
    
    
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row 
        *matRowDef="let row; columns: displayedColumns;"
        [cdkDragData]="row"
        (cdkDragExited)="setPageIndex(pageindx)"
        cdkDrag></mat-row>
    </mat-table>

    <div class="norecords d-flex align-items-center justify-content-center"

    [style.height.px] = "_canvasHeightInPixel" 
     *ngIf = "sectionPage.length == 0">
    
      <!-- <h5  class = "text-muted">No Associated Items</h5> -->

    </div>
   </div>
</div>
</div>


