import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { ForwardBinSelectComponent } from '../../components/forward-bin-select/forward-bin-select.component';
import { DIDPurchaseRequest, DIDRequest, DidTypes, IDidPurchaseResponse, IPhoneNumberAvail } from '../../models/dids.models';
import { Ring2voiceService } from '../../services/ring2voice.service';
import { SweetAlertOptions } from 'sweetalert2';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
    selector: 'app-did-purchase',
    templateUrl: './did-purchase.component.html',
    styleUrls: ['./did-purchase.component.scss']
})
export class DidPurchaseComponent implements OnInit, OnDestroy {

    @ViewChild('frwdbin') frwdbin:ForwardBinSelectComponent;

    public searchRequest: DIDRequest = new DIDRequest();

    public purchaseRate: number;

    public model: DIDPurchaseRequest = new DIDPurchaseRequest();

    public numbers: IPhoneNumberAvail[];

    public cartCount: number = 0;

    public selectedNumbers: IPhoneNumberAvail[] = [];

    public purchased_numbers: any = [];

    public non_purchased_numbers: any = [];

    private subs: SubSink = new SubSink();

    public isSuperAdmin: boolean;

    public allSuborgs: boolean;

    public tenantId: number;

    public didTypes = DidTypes;

    private modalRef: NgbModalRef;

    public copied: boolean = false;

    public submitted: boolean = false;

    public isGlobalRoute: boolean;

    constructor(private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private ring2voiceService: Ring2voiceService,
        private toastr: ToastrService,
        public location: Location,
        private clipboardService: ClipboardService) {

        this.isSuperAdmin = this.ring2voiceService.isSuperUser;
        this.allSuborgs = ring2voiceService.suborgId === -1;

        if (!this.isSuperAdmin && !this.allSuborgs) {
            this.searchRequest.suborg_id = this.ring2voiceService.suborgId;
        }

        if (this.allSuborgs) {
            this.tenantId = this.ring2voiceService.customerId;
        }
    }

    ngOnInit(): void { }

    searchPhoneNumbers() {
        if (!this.searchRequest.suborg_id) return;
        this.subs.sink = this.ring2voiceService.availableDids(this.searchRequest).subscribe(response => {
            this.numbers = response.numbers
            this.purchaseRate = response.purchase_rate
            this.selectedNumbers = this.numbers.filter(num => num.selected);
            this.cdr.markForCheck();
        })
    }

    addToCart(number: IPhoneNumberAvail) {
        number.selected = !number.selected;
        if (number.selected) {
            this.selectedNumbers.push(number);
        }
        else {
            this.selectedNumbers = this.selectedNumbers.filter(num => num.phone_number !== number.phone_number);
        }
    }

    removeFromCart(number: IPhoneNumberAvail) {
        number.selected = false;
        this.selectedNumbers = this.selectedNumbers.filter(num => num.phone_number !== number.phone_number);
    }

    openPurchaseModal(content: any) {
        this.submitted = false;
        this.modalRef = this.modalService.open(content, { centered: true, size: 'lg', scrollable: true });
    }

    closeModal() {
        this.modalRef?.dismiss();
    }

    purchaseDids() {
        this.model.phone_numbers = this.selectedNumbers.map(item => item.phone_number);
        this.model.did_type = this.searchRequest.number_type;
        this.model.suborg_id = this.searchRequest.suborg_id;

        this.ring2voiceService.purchaseDids(this.model).subscribe(resp => {
            this.submitted = true;
            this.numbers.length = 0;
            this.selectedNumbers.length = 0;
            if(resp.background){
                this.closeModal();
                this.showBackgoundAlert();
            }else{
                this.showPurchaseStatus(resp);
            }
            },
            () => this.toastr.error(`Purchase error`)
        );
    }

    showBackgoundAlert(){
        let option: SweetAlertOptions = {
            title: 'Process Successful',
            text: 'You will receive an email with the results',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-success',
              footer: 'flex-row-reverse'
            },
            buttonsStyling: false
          };
          popup.OpenConfirmBox(option, result => {
            if (result.isConfirmed) {
                    return;
                }  
              });
    }

    showPurchaseStatus(response: IDidPurchaseResponse) {
        this.purchased_numbers = response.purchased_numbers;
        this.non_purchased_numbers = response.non_purchased_numbers;
    }

    copyToClipboard() {
        const phoneNumbers = this.selectedNumbers.map(item => item.phone_number).join(',');
        this.clipboardService.copy(phoneNumbers);
        this.copied = true;
    }

    suborgChange() {
        this.numbers = [];
        this.selectedNumbers = [];
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
