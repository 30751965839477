<div class="docList">

    <div class="d-flex">
        <form class="mr-1 w-100" (submit)="getInvoices(true);">
            <div class="input-group input-clear input-group-sm mb-0 mr-1">
                <input type="text" [(ngModel)]="search" name="search" class="form-control search-input" placeholder="Search Invoices" />


                <div class="input-group-append">
                    <button [hidden]="!search.length" type="button" (click)="search='';getInvoices(true);" class="input-clear-btn btn btn-sm">
                        <i class="ft-x"></i>
                    </button>
                <button class="btn btn-outline-secondary"  type="submit" ><i class="ft ft-search"></i></button>
                </div>
            </div>
        </form>
        <button class="btn btn-secondary btn-sm mb-2 mr-1 filter-btn-active" [ngClass]="{'filter-btn-active':filterActive}" type="button" (click)="toggleFilter()"><i class="ft-filter"></i></button>

        <button class="btn btn-primary btn-sm mb-2" *ngIf="showNewButton">New</button>

    </div>
    <div class="d-flex flex-wrap mb-1 filter-row" *ngIf="showFilter">

        <tenant-customer-contact-select
        [tenantCustomerId]="_customerId"
        [readonly]="false"
        [addButton]="false"
        [value]="customerContact"
        [size]="'sm'"
        (change)="customerContactChange($event)"
        class="ng-select-sm mr-2 mb-1"
        (clear)="customerContact=null;getInvoices()"
        >
        </tenant-customer-contact-select>

        <tenant-customer-location-select
        #locationSelect
        style="min-width:200px"
        [size]="'sm'"
        [addButton]="false"
        [value]="customerLocation"
        (change)="customerLocationChange($event)"
        (clear)="customerLocation=null;getInvoices()"
        [tenantCustomerId]="_customerId"
         class="mr-1 mb-1"
        >
        </tenant-customer-location-select>

        <shipping-methods-select
            class="ng-select-sm mr-1 mb-1"
            (change)="shippingMethodChange($event)"
            [addButton]="false"
            [(value)]="shippingMethod">
        </shipping-methods-select>

        <ng-select
        style="min-width:150px"
         placeholder="Select status"
         class="ng-select-sm mr-1 mb-1"
         [items]="statusList"
         [(ngModel)]="status"
         bindLabel="displayName"
         bindValue="value"

        >

        </ng-select>


        <button class="btn btn-info btn-sm mr-2 mb-1" (click)="getInvoices(false)">Apply Filter</button>
        <a class="text-warning pt-1 text-sm mb-1" (click)="resetFilter()">Reset</a>


    </div>

    <p class="mt-2 mb-2 no-record" *ngIf="invoices&&invoices?.length==0">No invoices to display. Create new one.</p>

    <div class="d-flex flex-column doc-list-row">

        <div class="doc-box mb-3" *ngFor="let invoice of invoices">

            <div class="doc-body mb-2">

               <div class="doc-row d-flex">
                    <div class="info-box">
                        <label>#</label>
                        <p><a [routerLink]="['/sales/invoice', invoice.id, 'view']" target="_blank"
                        style="font-size: 12px;font-weight: 500;opacity: 0.9;">
                            {{invoice.unique_number}}
                        </a></p>
                        <!-- <p class="text-danger">{{invoice.unique_number}}</p> -->
                    </div>
                    <div class="info-box">
                        <label>Total</label>
                        <p><strong>{{invoice.total_price|currency}}</strong></p>
                    </div>
               </div>


               <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Customer name</label>
                        <p>{{invoice.tenant_customer_name}}</p>
                    </div>
                    <div class="info-box">
                        <label>Balance Due</label>
                        <p><strong>{{invoice.balance_due|currency}}</strong></p>
                    </div>

                </div>
                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Customer contact name</label>
                        <p>{{invoice.tenant_customer_contact_name}}</p>
                    </div>
                    <div class="info-box">
                        <label>Status</label>
                        <p><span class="badge" [class]="statusClass(invoice.status)">{{soStatus[invoice.status]}}</span></p>
                    </div>

                </div>
                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Customer location</label>
                        <p>{{invoice.tenant_customer_location_name}}</p>
                    </div>
                    <div class="info-box">
                        <label>Created On</label>
                        <p>{{invoice.created_on|date:'mm-dd-YYYY hh:mm a'}}</p>
                    </div>
               </div>
               <div class="doc-row d-flex">

                <div class="info-box">
                    <label>VIP customer</label>
                    <p>Coming soon</p>
                </div>
                <div class="info-box">
                    <label>Created By</label>
                    <p>{{invoice.created_by}}</p>
                </div>
           </div>



            </div>

            <div class="doc-footer mt-1">
                <ul class="d-flex justify-content-between">
                    <a class="w-100" (click)="selected=invoice;openInvoiceView(invoice_view_modal)"> <li><i class="ft-eye"></i> View</li></a>
                    <!-- <a class="w-100" > <li><i class="ft-external-link"></i> Open</li></a> -->
                    <!-- <li><i class="ft-printer"></i></li>
                    <li><i class="ft-edit-2"></i></li>
                    <li class="text-warning"><i class="ft-trash-2"></i></li> -->
                </ul>

            </div>


        </div>


        <!-- <div class="invoice-box">

        </div> -->
    </div>

    <div class="d-flex justify-content-center mb-1 mt-1" *ngIf="resultTotalCount>resultCount">
        <a class="text-info" (click)="getInvoices(false, true)">Load more</a>
    </div>
    <div class="result-count mb-1 pl-1">
        Showing {{resultCount}}/{{resultTotalCount}}
    </div>

</div>

<ng-template #invoice_view_modal let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <sales-invoice [item]="selected" [showEditButton]="false"
                [showDeleteButton]="false" [showSendToButton]="false" [isReadOnly]="true" (onClose)="onCloseInvoiceView()"
                #InvoiceView></sales-invoice>
            </div>
        </div>
    </div>
</ng-template>
