import { SubSink } from 'subsink';
import { ProductTypeService } from '../../../service-ticket/services/product-type.service';
import { ProductType } from '../../../service-ticket/models/common.models';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'product-type-form',
  templateUrl: './product-type-form.component.html',
  styleUrls: ['./product-type-form.component.scss']
})
export class ProductTypeFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('name', { static: false, read: ElementRef }) nameControl: ElementRef;

  public model: ProductType = new ProductType();

  @Input() bindingModel: ProductType;

  @Input() uniqueId: number = 0;

  @Output() success = new EventEmitter<{ response: ProductType, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private subs: SubSink = new SubSink();

  constructor(private service: ProductTypeService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {

    if (this.uniqueId > 0) {
        if (this.bindingModel) {
            this.model = { ... this.bindingModel };
        }
        else{
            this.subs.add(this.service.getById(this.uniqueId).subscribe(resp => {
                this.model = resp;
                this.cdr.markForCheck();
            }));
        }
    }
    else{
        this.model = this.service.createModel(ProductType)
    }
  }

  ngAfterViewInit(): void {
    this.nameControl?.nativeElement.focus();
  }

  onSubmit(): void {

    let api: Observable<ProductType>;

    if (this.uniqueId > 0)
      api = this.service.update(this.uniqueId, this.model);
    else {
      this.model.customer = this.service.customerId;
      api = this.service.create(this.model);
    }

    this.subs.sink = api.subscribe(
      response => {
        let msg = this.uniqueId > 0 ? 'Product Type info updated' : 'New Product Type created';
        this.success?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void {
    this.cancel?.emit();
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
