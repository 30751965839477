import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { STSubTask, SubTask, Task } from '../models/common.models';
import { StaticFile } from 'src/app/core/models/files.models';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<Task[]>(`${this.baseURL}/task/`);
  }

  getById(id: number) {
    return this.http.get<Task>(`${this.baseURL}/task/${id}/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0, suborg_id:number = this.suborgId, limit = this.dataLimit) {
    let url = `${this.baseURL}/filter/task/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<Task[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  patch(id: number, req: any) {
    return this.http.patch<Task>(`${this.baseURL}/task/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/task/${id}/`);
  }

  create(model: Task) {
    return this.http.post<Task>(`${this.baseURL}/task/`, model);
  }

  update(id: number, model: Task) {
    return this.http.put<Task>(`${this.baseURL}/task/${id}/`, model);
  }

  getTaskDetailsByStId(id: number) {
    return this.http.get<STSubTask[]>(`${this.baseURL}/service_ticket/${id}/sub_tasks/`);
  }

  updateStTaskList(model: STSubTask) {
    return this.http.put<STSubTask>(`${this.baseURL}/service_ticket/sub_tasks/${model.id}/`, model);
  }

  getSubTaskImages(stId: number,subTaskId: number) {
    return this.http.get<StaticFile[]>(`${this.baseURL}/service_ticket/sub_tasks/${stId}/${subTaskId}/images/`);
}

}
