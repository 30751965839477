import { LicenseNumberService } from './../../../services/license-number.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, of, Subject } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { LicenseNumber } from '../../../models/license-number';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'license-number-select',
    templateUrl: './license-number-select.component.html',
    styleUrls: ['./license-number-select.component.scss']
})
export class LicenseNumberSelectComponent implements OnInit {

    @Input() readonly: boolean = false;

    private innerValue: number | number[];

    get value(): number | number[] { return this.innerValue; }

    @Input() set value(val: number | number[]) {

        this.innerValue = val;

        if (val && this.innerSelected !== val) {
            this.innerSelected = val;
        }
    };

    @Output() valueChange = new EventEmitter<any>();

    @Output() add = new EventEmitter<number>();

    @Output() change = new EventEmitter<any>();

    @Output() remove = new EventEmitter<number>();

    @Output() clear = new EventEmitter();

    private innerSelected: number | number[];

    public get selected(): number | number[] { return this.innerSelected; }

    public set selected(value: number | number[]) {
        this.innerSelected = value;
        this.valueChange?.emit(value);
    }

    @Input() addButton: boolean = true;

    @Input() multiple: boolean = false;

    @Input() fetchWhenOpen: boolean = false;

    @Input() clearable: boolean = true;

    public options: Observable<LicenseNumber[]>;

    public placeholder: string;

    public totalCount: number = 0;

    public currentCount: number = 0;

    public textInput = new Subject<string>();

    public firstOpen: boolean = true;

    private modalRef: NgbModalRef;

    constructor(private service: LicenseNumberService,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private ra: ResourceAccessService) { }

    ngOnInit() {
        this.placeholder = this.hasPermission() && this.addButton ? "Select or add License Number" : "Select License Number";
        if (!this.fetchWhenOpen) {
            this.loadInitial();
        }
    }

    loadInitial() {
        this.options = concat(this.getData(), this.textInput.pipe(
            filter(res => res !== null && res.length >= 2),
            distinctUntilChanged(),
            switchMap(term => this.getData(term))
        ));
    }

    getData(search?: string) {
        let option = {
            sort: [{ colname: 'license_number', direction: 'asc' }],
            filters: search ? [{ colname: 'license_number', condition: 'contains', value: search, operator: 'AND' }] : null
        };
        return this.service.getFiltered(option, 0, 1000).pipe(
            map(resp => {
                this.totalCount = resp.count;
                this.currentCount = resp.result.length;
                return resp.result;
            })
        );
    }

    selectOpen() {
        if (this.fetchWhenOpen && (!this.options || this.firstOpen)) {
            this.loadInitial();
            this.firstOpen = false;
        }
    }

    trackByFn(item: LicenseNumber) { return item.id; }

    openModal(content: any): void {
        this.modalRef = this.modalService.open(content);
    }

    onValueChanged(license: LicenseNumber) {
        this.change?.emit(license);
    }

    onDataSubmittedSuccess(result: { response: LicenseNumber, message: string }): void {
        this.toastr.success(result.message);
        this.options = concat(this.options, of([result.response]));
        if (this.multiple) {
            this.selected ??= [];
            this.selected = [...this.selected as number[], ...[result.response.id]];
        }
        else {
            this.selected = result.response.id;
        }
        this.modalRef?.dismiss();
    }

    onDataError(_error: any): void {
        this.toastr.error('Operation failed');
        this.modalRef?.dismiss();
    }

    onCancel(): void { this.modalRef?.dismiss(); }

    onAdd(license: LicenseNumber) { this.add?.emit(license?.id); }

    onClear() {
        this.selected = [];
        this.clear?.emit();
    }

    onRemove(item: any) { this.remove?.emit(item?.value?.id); }

    hasPermission() {
        return this.ra.hasPermission(
            ResourceConstants.CUSTOMER_LICENSE_NUMBERS,
            [PermissionConstants.CREATE],
            ResourceConstants.CUSTOMER_MODULE
        );
    }
}
