<div class="select-wrapper">
    <ng-select
        placeholder="Select or Search Phone Number"
        [appendTo]="'body'"
        [items]="options | async"
        bindLabel="did"
        [bindValue]="bindValue"
        [(ngModel)]="selected"
        [readonly]="readonly"
        [typeahead]="textInput"
        [minTermLength]="2"
        [trackByFn]="trackByFn"
        typeToSearchText="Please enter 2 or more characters"
        (open)="selectOpen()"
        (close)="selectClose()"
        (clear)="onClear()"
        [multiple]="isMultiple"
        [closeOnSelect]="!isMultiple"
        >

        <ng-template ng-label-tmp let-item="item">
            <span>{{item.did | phoneNumber }}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index">

            <input *ngIf="isMultiple" id="item-{{index}}" type="checkbox" [ngModel]="item.selected" />
            <span [ngClass]='{"ng-option-phoneNumber":dialerView}' class="">{{item.did | phoneNumber }}</span>
            <span class="pull-right" [ngClass]='{"ng-option-name":dialerView}'>{{item.name}}</span>
        </ng-template>

    </ng-select>
</div>
