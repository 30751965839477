import { Account } from '../models/account';

export class Customer {

    public id: number;

    public account: Account = new Account();

    public name: string;

    public email: string;

    public phone: string;

    public address: string;

    public state: string;

    public city: string;

    public zipcode: string;

    public active: boolean;

    public subscription: boolean=false;

    public is_dispatch:boolean=false;

    public is_quickbook:boolean=false;

    public root: boolean;

    public first_name: string;

    public last_name: string;

    public password: string;
}

export class TenantSettings {

    public customer: number;

    public two_factor_authentication: boolean;
}
