import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeFrameRequest, WorkingTimeResponse, workingTimes, workingTimesPayload } from '../../models/time-frame.model';
import { TimeFrameService } from '../../services/time-frame.service';

@Component({
  selector: 'app-add-time-frame',
  templateUrl: './add-time-frame.component.html',
  styleUrls: ['./add-time-frame.component.scss']
})
export class AddTimeFrameComponent implements OnInit {

  public model: WorkingTimeResponse;

  public isSuperAdmin: boolean;

  public allSuborgs: boolean;

  public nameError: boolean;

  public workingTimes:workingTimes[]=[];

  public payload:TimeFrameRequest=new TimeFrameRequest()

  private subs: SubSink = new SubSink();

  @Input() selected_Id:number;
  
  @Output() success = new EventEmitter<{ response: WorkingTimeResponse, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  public weekDays=[
    {day:"Monday", checked:true},
    {day:"Tuesday", checked:true},
    {day:"Wednesday", checked:true},
    {day:"Thursday", checked:true},
    {day:"Friday", checked:true},
    {day:"Saturday", checked:false},
    {day:"Sunday", checked:false}
  ];

  constructor(public location: Location,
    route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private timeFrameService: TimeFrameService
    ) { 
      this.subs.sink = route.params.subscribe(params =>{ 
        this.selected_Id = +params.id || 0
      });
    }

  ngOnInit(): void {

    if (this.selected_Id>0) {
      this.getById(this.selected_Id);
    }
    else {
      this.model = new WorkingTimeResponse();
      this.model.type = 'Always';
      this.addNewTime();
    }
  }


getById(id:number){
  this.subs.add(this.timeFrameService.getById(id).subscribe(response => {
    if(response){
      this.model = response;
    if(this.model.type==='Days/Time'){
      this.weekDays.forEach((val,index)=>{
        if(this.model.weekDays.includes(val.day)){
         val.checked=true;
        }
        else{
         val.checked=false;
        }
      });
    }

    this.model.time_frame.forEach(tf =>{
        const v = new workingTimes(); // Create a new instance inside the loop
        const startTimeParts = tf.start_time.split(":");
        const endTimeParts = tf.end_time.split(":");
        v.startTime.hour = Number(startTimeParts[0]);
        v.startTime.minute = Number(startTimeParts[1]);
        v.endTime.hour = Number(endTimeParts[0]);
        v.endTime.minute = Number(endTimeParts[1]);
        v.startDate = tf.start_date;
        v.endDate = tf.end_date;
        this.workingTimes.push(v); // Push the new instance into the array
    });
    this.cdr.markForCheck();
    }
    
  }));
}

removeTimeRow(index:number){
  this.workingTimes.splice(index,1);
}

addNewTime(){
  let v=new workingTimes();
  v.startTime.hour=9;
  v.startTime.minute=0;
  v.endTime.hour=12;
  v.endTime.minute=0;
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  v.startDate = formattedDate;
  v.endDate = formattedDate;
  this.workingTimes.push(v);
  this.cdr.markForCheck();
}

 validateTimesOnAdd(){
  let errors:string[]=[];
   if(this.model.type==='Days/Time'&&this.weekDays.filter(val=>val.checked==true).length==0){
     errors.push("Select atleast one day");
   }
   if(this.model.type!='Always'&&this.workingTimes.length==0){
    errors.push("Add atleast one time range");
   }
   else{
    let e=false;
    this.workingTimes.forEach((v,i)=>{
      let sTime1=Number(v.startTime.hour+v.startTime.minute);
      let eTime1=Number(v.endTime.hour+v.endTime.minute);
      this.workingTimes.forEach((v2,i2)=>{
        let sTime2=Number(v2.startTime.hour+v2.startTime.minute);
        let eTime2=Number(v2.endTime.hour+v2.endTime.minute);
        
        if(this.model.type!='Always'&&(i!=i2&&((sTime1>=sTime2&&sTime1<=eTime2)||(eTime1>=sTime2&&eTime1<=eTime2)))){
          e=true;
        }
      })
    });

    // let e2=false;
    //  if(this.uWorkingtime.length){

    //   //  this.uWorkingtime.forEach((val,index)=>{

    //   //     tempArray=tempArray.concat(val.availabilty);

    //   //  });

    //     this.workingTimes.forEach((v,i)=>{
    //       let sTime1=Number(v.startTime.hour+v.startTime.minute);
    //       let eTime1=Number(v.endTime.hour+v.endTime.minute);

    //       this.uWorkingtime.forEach((v2,i2)=>{
    //         if(this.weekDays[v2.day].checked&&v2.availabilty.length){

    //               v2.availabilty.forEach((v3,i3)=>{
                    
    //                 let sSplit=v3.start_time.split(":",2);
    //                 let eSplit=v3.end_time.split(":",2);
    //                 let sTime2=Number(sSplit[0])+Number(sSplit[1]);
    //                 let eTime2=Number(eSplit[0])+Number(eSplit[1]);
    //                 // let eTime2=v2.endTime.hour+v2.endTime.minute;
      
      
    //                 if(((sTime1>=sTime2&&sTime1<=eTime2)||(eTime1>=sTime2&&eTime1<=eTime2))){
    //                   e2=true;
    //                 }
    //               })
    //         }
              
    //       })
    //     });
    //  }

    if(e){
      errors.push('Please rectify time ranges overlaping to proceed');
    }
    // else if(e2){

    //   errors.push('New time ranges overlaping the existing working time');

    // }

   }

   if(errors.length>0){
    errors.forEach((val,index)=>{
        this.toastr.error(val);
    });
    return true;
   }
   else{
    return false;
   }


}

 onSubmit() {
  if(!this.validateTimesOnAdd()){
    const payload = new TimeFrameRequest();
    payload.customer = this.timeFrameService.customerId;
    payload.description = this.model.description;
    payload.name = this.model.name;
    payload.type = this.model.type;
    if(this.workingTimes.length>0){
      const checkedDays = this.weekDays.filter(day => day.checked).map(day => day.day);
      payload.weekDays = checkedDays
    }
    if(this.model.type!='Always'){
      this.workingTimes.forEach(elem=>{
        let d=new workingTimesPayload();
        d.start_time = elem.startTime.hour+':'+elem.startTime.minute+':00';
        d.end_time = elem.endTime.hour+':'+elem.endTime.minute+':00';
        d.start_date = this.model.type==='Specific Date/Ranges'?elem.startDate:null;
        d.end_date = this.model.type==='Specific Date/Ranges'?elem.endDate:null;

        payload.data.push(d)
      })
    }else{
      payload.weekDays = [];
      payload.data = [];
    }
    
    if(payload&&payload.data){
      let api: Observable<WorkingTimeResponse> = this.selected_Id ? this.timeFrameService.updateTimeFrame(this.selected_Id,payload)
            : this.timeFrameService.createTimeFrame(payload);
      this.subs.sink= api.subscribe(response=>{
          this.nameError = false;
          let msg = this.selected_Id ? `Time Frame updated successfully` : `Time Frame created successfully`;
          this.success.emit({ response: response, message: msg });
      },
      (error)=>{
        if (error.error['non_field_errors'] && (error.error['non_field_errors'][0] === 'Name already exists.')) {
          this.nameError = true;
          this.cdr.markForCheck();
        }
        else {
          this.nameError = false;
          this.error.emit(error);
        }
      });
    }
  }
}

onCancelClicked(): void { this.cancel.emit(); }


ngOnDestroy() { this.subs.unsubscribe(); }

}


