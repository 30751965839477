import { callDetailsResponse } from "./call-center.models";
import { CallLog } from "./reports.models";
import { SIP } from "./sip.models";

export class outCallDetailsModel{

    state:string = ""
    totalCallCount:number = 0
    fromNumber:string = ""
    toNumber:string = ""  
    acceptedCallsIDs:any[]=[];
    contactDetails:callDetailsResponse;
    agentDetails:SIP;
    active_CallerSession:any
    callID:string
    block_id:number

  

  }