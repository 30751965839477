<section class="add-kit" *ngIf="model">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">
                {{kitId > 0 ? (isClone ? 'Clone Assembly' : 'Edit Assembly') : 'New Assembly'}}
            </h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()" novalidate>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label class="val-required">Name</label>
                                <input type="text" [(ngModel)]="model.name" #kitname="ngModel" name="name"
                                    class="form-control" required>
                                <small class="form-text text-muted danger"
                                    *ngIf="kitname.invalid && (kitname.dirty || kitname.touched)">
                                    <div *ngIf="kitname.errors.required">
                                        Name is required.
                                    </div>
                                </small>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Category</label>
                                <category-select [(value)]="model.category" (setDefault)="currentCategory = $event"
                                    [addButton]="true" (change)="onCategoryChanged($event)" (clear)="categoryClear()">
                                </category-select>
                                <div *ngIf="currentCategory && !currentCategory.create_partnumber"
                                    class="mt-1 category-warning-text">
                                    <span class="warning">&#8226; This category is not configured to generate Part
                                        Number.</span>
                                </div>
                                <div class="mt-1" *ngIf="lastGenPartNumber | async as lastpn">
                                    <small class="text-info">{{lastpn}}</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Part Number</label>
                                <div class="d-flex flex-row">
                                    <input type="text" [(ngModel)]="model.internal_number"
                                        (change)="partNumberOnChange($event)" #partnum="ngModel" name="internal_number"
                                        class="form-control" required>
                                    <button type="button"
                                        [disabled]="!model.category || !currentCategory?.create_partnumber"
                                        (click)="generatePartNumber()" class="btn btn-info text-nowrap ml-1 pl-2 pr-2">
                                        <i class="fa fa-cog"></i> Generate
                                    </button>
                                </div>
                                <small *ngIf="!partNumberHelper.valid" class="text-muted danger">
                                    {{partNumberHelper.message}}
                                </small>
                                <small class="form-text text-muted danger"
                                    *ngIf="partnum.invalid && (partnum.dirty || partnum.touched)">
                                    <div *ngIf="partnum.errors.required">
                                        Part Number is required.
                                    </div>
                                </small>
                            </div>
                            <div class="form-group">
                                <label>Type</label>
                                <item-type-select [(value)]="model.item_type"></item-type-select>
                            </div>
                            <div class="form-group">
                                <label>Sku</label>
                                <input type="text" [(ngModel)]="model.sku" name="sku" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Unit Of Measure</label>
                                <unit-type-select [(value)]="model.unit_type"></unit-type-select>
                            </div>

                            <div class="form-group">
                                <label>Product Line</label>
                                <app-product-line-select [(value)]="model.product_line"></app-product-line-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-5" style="padding-top: 25px;">
                            <div class="row">
                                <app-file-uploader
                                    [limit]="5"
                                    [primary]="model.primary_image"
                                    [files]="kitImages"
                                    [multiple]="true"
                                    (delete)="onFileDelete($event)">
                                </app-file-uploader>
                            </div>
                            <div class="">
                                <div class="form-group taxable-toggle-div mt-3 mb-3">
                                    <div class="taxable-toggle-btn">
                                        <label>Inventoried : </label>
                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                            <input type="checkbox" [(ngModel)]="model.is_inventoried" name="inventoried"
                                            class="custom-control-input" id="inventoried"
                                            [disabled]="model.is_inventoried ? !hasPermission(Resources.INV_KITS_INVENTORIED,Permissions.INACTIVE):!hasPermission(Resources.INV_KITS_INVENTORIED,Permissions.ACTIVE)">
                                            <label class="custom-control-label" for="inventoried">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="form-group taxable-toggle-div mt-3 mb-3">
                                    <div class="taxable-toggle-btn">
                                        <label>Show on Catalog : </label>
                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                            <input type="checkbox" [(ngModel)]="model.show_on_catelog"
                                            name="show_on_catelog" class="custom-control-input" id="show_on_catelog" 
                                            >
                                            <label class="custom-control-label" for="show_on_catelog" >
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Material</label>
                                <app-material-select [(value)]="model.material"></app-material-select>
                            </div>

                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>Product Line</label>
                                <app-product-line-select [(value)]="model.product_line"></app-product-line-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>Material</label>
                                <app-material-select [(value)]="model.material"></app-material-select>
                            </div>
                        </div>
                    </div> -->
                    <h5 class="mt-2 section-title">Associate Items</h5>
                    <div class="col-12 col-md-10 p-0 mb-4">
                        <kit-items [kitId]="kitId" [clone]="isClone" #kitItemGrid
                            [saveButton]="hasPermissionAny(Resources.INV_KITS_ITEMS,[Permissions.CREATE, Permissions.MODIFY])"
                            [deleteOption]="hasPermission(Resources.INV_KITS_ITEMS,Permissions.DELETE)"
                            [addButton]="hasPermissionAny(Resources.INV_KITS_ITEMS,[Permissions.CREATE, Permissions.MODIFY])">
                        </kit-items>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group" *ngIf="hasPricePermission(Resources.INV_KITS_SELL_PRICE)">
                                <label>Selling Price</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="number" step=".01" [(ngModel)]="model.sell_price" name="sell_price"
                                        class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Account</label>
                                <sales-account-select [(value)]="model.sales_account"></sales-account-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="form-group" *ngIf="hasPricePermission(Resources.INV_KITS_COST_PRICE)">
                                <label>Cost Price</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="number" [(ngModel)]="model.cost_price" name="cost_price"
                                        class="form-control">
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label>Tax</label>
                                <tax-select [(value)]="model.tax"></tax-select>
                            </div> -->
                            <div class="form-group taxable-toggle-div">
                                <div class="taxable-toggle-btn">
                                    <label>Taxable : </label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [(ngModel)]="model.taxable" name="taxable" class="custom-control-input" id="taxable">
                                        <label class="custom-control-label" for="taxable">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea type="text" [(ngModel)]="model.description" name="description"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>Note</label>
                                <textarea type="text" [(ngModel)]="model.notes" name="note"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex flex-row">
                            <div class="sheet">
                                <label class="sheet-label">
                                    Spec Sheet
                                </label>
                                <div class="sheet-link">
                                    <i class="ft-file-text file-icon"></i>
                                    <button (click)="specSheet.toggle()" type="button"
                                        class="btn btn-sm sheet-edit-btn btn-info">
                                        <i class="ft-edit"></i> Edit
                                    </button>
                                    <quill-modal #specSheet title="Spec Sheet" [(value)]="model.spec_sheet"
                                        (submit)="saveSheet('spec_sheet')"></quill-modal>
                                </div>
                            </div>
                            <div class="sheet ml-4">
                                <label class="sheet-label mt-1">
                                    Instruction Sheet
                                </label>
                                <div class="sheet-link">
                                    <i class="ft-file-text file-icon"></i>
                                    <button (click)="instrSheet.toggle()" type="button"
                                        class="btn btn-sm sheet-edit-btn btn-info">
                                        <i class="ft-edit"></i> Edit
                                    </button>
                                    <quill-modal #instrSheet title="Instruction Sheet"
                                        [(value)]="model.instructions_sheet" (submit)="saveSheet('instructions_sheet')">
                                    </quill-modal>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 class="mt-3 section-title">Additional Fields
                        <!-- <button type="button" (click)="openModal(content)"
                            class="btn btn-sm bg-light-secondary ml-4">Add New</button> -->
                    </h5>

                    <!-- --{{assemblyCustomField|json}} -->
                    <div class="row mt-3">
                        <div class="form-group col-md-5" *ngFor="let customField of assemblyCustomField.additional_data;let index=index" [hidden]="customField.action==3">
                            <label *ngIf="customField.additional_field&&customField.type==1">{{nameFromAdditionalFields(customField.additional_field)}}</label>
                            <div *ngIf="customField.additional_field||customField.type==1" class="d-flex flex-row">
                                

                                <ng-select 
                                placeholder="Select value" 
                                [items]="valuesOfAdditionalField(customField.additional_field)" 
                                [(ngModel)]="customField.value"
                                [name]="'val'+customField.id"
                                bindLabel="field_value" 
                                style="width:100%"
                                bindValue="id"
                                *ngIf="customField"
                                >
                                 </ng-select>

                                 <!-- {{valuesOfAdditionalField(customField.additional_field)|json}} -->

                                 <!-- {{valuesOfAdditionalField(customField.additional_field).length}} -->

                                <button (click)="deleteCustomField(index)" class="af-delete-btn"
                                    type="button">
                                    <i class="ft-trash-2"></i>
                                </button>
                            </div>

                            <label *ngIf="customField.custom_additional_field||customField.type==2">{{customField.custom_additional_field}} </label>
                            <div *ngIf="customField.custom_additional_field||customField.type==2" class="d-flex flex-row">
                                <input type="text" class="form-control" [(ngModel)]="customField.custom_additional_field_value" name="custom_additional_field_value{{index}}"/>
                                <button (click)="deleteCustomField(index)" class="af-delete-btn" type="button">
                                    <i class="ft-trash-2"></i>
                                </button>
                            </div>

                            


                        </div>
                    </div>
                    <div>
                        <a class="text-info font-small-3" (click)="openModal(content)"><i class="ft-plus"></i> Add from list</a> 
                        <a class="text-info font-small-3 ml-2" (click)="openModal(content2)"><i class="ft-plus"></i> Add custom</a>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-10 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button type="submit" [disabled]="!formElement.form.valid || !model.category"
                                class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                {{kitId > 0 && !isClone ? 'Save Changes' : 'Save'}}
                            </button>
                            <button type="button" (click)="location.back()" class="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>


    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Custom Field</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Key</label>

                <additional-field-select 
                [afList]="additionalFieldz"
                [value]="additionalFieldId"
                [addButton]="true"
                (valueChange)="additionalFieldSelected($event)"
                >
                </additional-field-select>

            </div>
            <div class="form-group">
                <label>Value</label>

                <ng-select 
                    placeholder="Select value" 
                    [items]="addAdditionalFieldValues" 
                    [(ngModel)]="additionalFieldValue"
                    bindLabel="field_value" 
                    bindValue="id">
                </ng-select>
                <!-- <input type="text" [(ngModel)]="customFieldInputs.value" name="value" class="form-control"> -->
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="addAdditionalField('fromlist')" class="btn gradient-pomegranate">Add</button>
            <button type="button" (click)="modal.close('Save click')" class="btn btn-secondary">Cancel</button>
        </div>
    </ng-template>


    <ng-template #content2 let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Custom Field</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label class="val-required">Key</label>
                <input type="text" class="form-control" [(ngModel)]="customAdditionalFieldKey" name="customAdditionalFieldKey" />
                <!-- <input type="text" [(ngModel)]="customFieldInputs.key" name="key" class="form-control"> -->
            </div>
            <div class="form-group">
                <label class="val-required">Value</label>

                <input type="text" class="form-control" [(ngModel)]="customAdditionalFieldValue" name="customAdditionalFieldValue" />

                <!-- <input type="text" [(ngModel)]="customFieldInputs.value" name="value" class="form-control"> -->
            </div>

        </div>
        <div class="modal-footer d-flex justify-content-between">
            <div class="" style="">
                <!-- <a class="text-primary"><i class="fa fa-plus"></i> Add custom additional field</a> -->
            </div>
            <div class="">
                <button type="button" (click)="addAdditionalField('custom')" class="btn gradient-pomegranate">Save</button>
                <button type="button" (click)="modal.close('Save click')" class="btn btn-secondary">Cancel</button>
            </div>
        </div>
    </ng-template>
</section>
