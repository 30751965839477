<section id="kit-view" *ngIf="kit">
    <div class="d-flex mt-2" [ngClass]="kit.name.length <= 30 ? 'flex-row': 'flex-column'">
        <h5 class="item-title m-0">{{kit.name}}</h5>
        <div class="d-flex justify-content-end align-items-start">
            <button class="btn btn-sm btn-info ml-1 mr-1" *ngIf="hasPermission(Resource.INV_LABELS_PRINT,Permissions.PRINT)" (click)="toggleLabelModal(labelModal)">
                <i class="fa ft-printer"></i> Print Label
            </button>

            <a *ngIf="hasPermission(Resource.INV_KITS_ASSEMBLE,Permissions.CREATE)" class="btn btn-sm btn-info ml-1"
                [routerLink]="['/inventory/assemblies',kit.id,'assemble']" disableIfAllsuborg [hide]="true">
                Assemble
            </a>
            <button *ngIf="hasPermission(Resource.INV_ITEMS,Permissions.CREATE)"
                class="btn btn-sm bg-light-secondary ml-1 mr-1" (click)="convertToItem()" disableIfAllsuborg
                [hide]="true">
                Convert to Item
            </button>
            <div ngbDropdown placement="bottom-right" disableIfAllsuborg [hide]="true">
                <button class="btn btn-sm" ngbDropdownToggle>
                    More
                </button>
                <div ngbDropdownMenu>
                    <button ngbDropdownItem *ngIf="hasPermission(Resource.INV_KITS,Permissions.MODIFY)"
                        [routerLink]="['/inventory/assemblies',kit.id,'edit']" class="w-100">
                        <i class="ft-edit mr-1"></i> Edit
                    </button>
                    <button ngbDropdownItem [routerLink]="['/inventory/assemblies',kit.id,'clone']"
                        *ngIf="hasPermission(Resource.INV_KITS,Permissions.CREATE)" class="w-100">
                        <i class="ft-copy mr-1"></i> Clone
                    </button>
                    <button ngbDropdownItem *ngIf="hasPermission(Resource.INV_KITS,Permissions.DELETE)"
                        class="bg-light-danger w-100" (click)="deleteKit()" disableIfAllsuborg>
                        <i class="ft-trash-2 mr-1"></i> Delete
                    </button>
                </div>
            </div>
            <button class="btn btn-sm font-medium-3 pt-0 pb-0" (click)="closeView()">
                <i class="ft-x"></i>
            </button>
        </div>
    </div>
    <div class="col-12 col-md-12 p-0">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Overview</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div class="group">
                        <div class="group-header d-flex flex-row justify-content-between">
                            Overview
                            <div class="status-toggle-btn">
                                <label class="title">Status :</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="kit.is_active" name="active"
                                        class="custom-control-input" (change)="changeStatus()" id="status"
                                        [disabled]="kit.is_active ? !hasPermission(Resource.INV_KITS,Permissions.INACTIVE):!hasPermission(Resource.INV_KITS,Permissions.ACTIVE)">
                                    <label class="custom-control-label" for="status">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <div class="status-toggle-btn">
                                <label class="title">Show On Catelog :</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="kit.show_on_catelog" name="show_on_catelog"
                                        class="custom-control-input" (change)="changeShowOnCatelog()" id="show_on_catelog">
                                    <label class="custom-control-label" for="show_on_catelog">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row">
                            <div class="col-12 p-0" [ngClass]="{'col-md-7':images}">

                                <div class="ov-row" *ngIf="kit.internal_number">
                                    <label class="col-md-3 p-0 item-label">Part Number :</label>
                                    <label class="item-label-value">{{kit.internal_number}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.sku">
                                    <label class="col-md-3 p-0 item-label">SKU</label>
                                    <label class="item-label-value">{{kit.sku}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.itemtype_name">
                                    <label class="col-md-3 p-0 item-label">Item Type</label>
                                    <label class="item-label-value">{{kit.itemtype_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.category_name">
                                    <label class="col-md-3 p-0 item-label">Category</label>
                                    <label class="item-label-value">{{kit.category_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.product_line_name">
                                    <label class="col-md-3 p-0 item-label">Product Line</label>
                                    <label class="item-label-value">{{kit.product_line_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.material_name">
                                    <label class="col-md-3 p-0 item-label">Material</label>
                                    <label class="item-label-value">{{kit.material_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.unittype_name">
                                    <label class="col-md-3 p-0 item-label">Unit Of Measure</label>
                                    <label class="item-label-value">{{kit?.unittype_name}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.description">
                                    <label class="col-md-3 p-0 item-label">Description</label>
                                    <label class="item-label-value">{{kit.description}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.notes">
                                    <label class="col-md-3 p-0 item-label">Notes</label>
                                    <label class="item-label-value">{{kit.notes}}</label>
                                </div>
                                <div class="ov-row" *ngIf="kit.spec_sheet">
                                    <label class="col-md-3 p-0 item-label">Spec Sheet</label>
                                    <label class="item-label-value">
                                        <a (click)="specModal.toggle()" class="text-info">Read</a>
                                        <quill-modal #specModal title="Spec Sheet"
                                            [viewMode]="true"
                                            [value]="kit.spec_sheet">
                                        </quill-modal>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="kit.instructions_sheet">
                                    <label class="col-md-3 p-0 item-label">Instruction Sheet</label>
                                    <label class="item-label-value">
                                        <a (click)="instrModal.toggle()" class="text-info">Read</a>
                                        <quill-modal #instrModal title="Instruction Sheet"
                                            [viewMode]="true"
                                            [value]="kit.instructions_sheet">
                                        </quill-modal>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="kit.created_on">
                                    <label class="col-md-3 p-0 item-label">Created On</label>
                                    <label class="item-label-value">{{kit.created_on | date:'MM/dd/YYYY'}}</label>
                                </div>
                                <div class="ov-row">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value">
                                        {{kit.created_by}}
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="kit.suborg_name && isAllSuborg">
                                    <label class="col-md-3 p-0 item-label">Suborg</label>
                                    <label class="item-label-value">{{kit.suborg_name}}</label>
                                </div>
                                <div class="taxable-toggle-div">
                                    <div class="taxable-toggle-btn">
                                        <label>Inventoried : </label>
                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                            <input type="checkbox" [(ngModel)]="kit.is_inventoried" (change)="changeInventoried()"
                                            name="inventoried" class="custom-control-input" id="inventoried"
                                            [disabled]="kit.is_inventoried ? !hasPermission(Resource.INV_KITS_INVENTORIED,Permissions.INACTIVE):!hasPermission(Resource.INV_KITS_INVENTORIED,Permissions.ACTIVE)">
                                            <label class="custom-control-label" for="inventoried">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-5 p-1 d-flex primary-image-container" *ngIf="images && images.length">
                                <app-file-uploader [primary]="kit.primary_image" [files]="images" [readonly]="true">
                                </app-file-uploader>
                            </div>
                        </div>
                    </div>
                    <div class="group"
                        *ngIf="kit.sell_price || kit.cost_price || kit.salesaccount_name || kit.tax_value">
                        <div class="group-header">
                            Sales Information
                        </div>
                        <div class="col-12 p-0">
                            <div class="ov-row"
                                *ngIf="kit && hasPermission(Resource.INV_KITS_SELL_PRICE,Permissions.VIEW)">
                                <label class="col-md-3 p-0 item-label">Selling Price</label>
                                <label class="item-label-value">${{kit.sell_price || 0}}</label>
                            </div>
                            <div class="ov-row"
                                *ngIf="kit && hasPermission(Resource.INV_KITS_COST_PRICE,Permissions.VIEW)">
                                <label class="col-md-3 p-0 item-label">Cost Price</label>
                                <label class="item-label-value">${{kit.cost_price || 0}}</label>
                            </div>
                            <div class="ov-row" *ngIf="kit?.tax_value">
                                <label class="col-md-3 p-0 item-label">Tax</label>
                                <label class="item-label-value">{{kit.tax_value}} %</label>
                            </div>
                            <div class="ov-row" *ngIf="kit?.salesaccount_name">
                                <label class="col-md-3 p-0 item-label">Sales Account</label>
                                <label class="item-label-value">{{kit.salesaccount_name}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="group mt-2">
                        <div class="group-header d-flex flex-row justify-content-between">
                            Associated Items
                        </div>
                        <div class="group-body border-0">
                            <kit-items #kitItemGrid
                                [saveButton]="hasPermissionAny(Resource.INV_KITS_ITEMS,[Permissions.CREATE, Permissions.MODIFY])"
                                [deleteOption]="hasPermission(Resource.INV_KITS_ITEMS,Permissions.DELETE)"
                                [addButton]="hasPermissionAny(Resource.INV_KITS_ITEMS,[Permissions.CREATE, Permissions.MODIFY])"
                                [kitId]="kit.id">
                            </kit-items>
                        </div>
                    </div>
                    <div class="group mt-3" *ngIf="kit && kit.is_inventoried">
                        <div class="group-header d-flex flex-row">
                            Stock
                        </div>
                        <div class="group-body border-0">
                            <app-kit-stock #itemStockGrid (openPrint)="openPrintView($event)" [kitId]="kit.id"></app-kit-stock>
                        </div>
                    </div>
                    <ng-container
                        *ngIf="kit && kit.additional_fields && kit.additional_fields | customfields as custFields">
                        <div class="group mt-2 additional-info" *ngIf="custFields && custFields.length > 0">
                            <div class="group-header">
                                Additional Info
                            </div>
                            <div class="col-12 p-0">
                                <ng-container *ngFor="let field of custFields">
                                    <div class="ov-row">
                                        <label class="col-md-3 p-0 item-label">{{field.name}}</label>
                                        <label class="item-label-value">{{field.value || 'NIL'}}</label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">History</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div class="history-container" *ngIf="kit">
                        <history-view [module]="'KIT'" [uniqueId]="kit.id"></history-view>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>

    <ng-template #labelModal let-c="close" let-d="dismiss">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="Print Label">Print Label</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
             <form >

                <div class="row">
                    <div class="col-md-12">





                            <div class="form-group" style="max-width:200px">
                                <label class="val-required">Number of Labels to print</label>
                                        <input type="number"
                                        class="form-control"
                                        placeholder="Enter count"
                                        name="labelCount"
                                        [(ngModel)]="printCount" #labelCount="ngModel" required/>

                                <small class="form-text text-muted danger" *ngIf="labelCount.invalid &&(labelCount.dirty||labelCount.touched)">
                                    <div *ngIf="labelCount.errors.required">
                                       Please enter number of labels to print
                                    </div>

                                </small>
                            </div>






                    </div>


                    <div class="col-md-12 form-group float-right mt-4">
                        <button class="btn btn-secondary mr-1" name="cancel" (click)="d('Cross click')">Cancel</button>
                        <button class="btn btn-primary" name="next" (click)="proceedToPrinting()">Next</button>


                        </div>

                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</section>
