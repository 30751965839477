import { ChangeDetectorRef, Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { WarrantyService } from '../../services/warranty.service';
import { addWarranty, WarrantyItemAdd, WarrantyItemsList, WarrantyKitsAdd, WarrantyKitsList, WarrantyList } from '../../models/warranty.models';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { CustomerLocationSelectComponent } from './../../../tenant-customers/components/select/customer-location-select/customer-location-select.component';
import { CustomerContact, TenantCustomer } from 'src/app/modules/tenant-customers/models/tenant-customer';
// import { WarrantyKitItemsComponent } from '../../components/kit-items/kit-items.component';
import { KitItemsComponent } from 'src/app/modules/inventory/components/kit-items/kit-items.component';
import { TermsService } from '../../services/terms.service';
import { getTermsCat, TermsCatResponse } from '../../models/terms.models';
import { first } from 'rxjs/operators';
import { SalesWarrantyItemsComponent } from '../../components/warranty-items/warranty-items.component';
import { formatDate, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Kit } from 'src/app/modules/inventory/models/kit.models';
import { KitService } from 'src/app/modules/inventory/services/kit.service';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { CustomField } from 'src/app/core/models/custom-field';
import { Observable } from 'rxjs';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UIPermission } from 'src/app/core/models/common.models';
import { SalesService } from '../../services/sales.service';

@Component({
  selector: 'app-warranty-add',
  templateUrl: './warranty-add.component.html',
  styleUrls: ['./warranty-add.component.scss']
})

export class WarrantyAddComponent implements OnInit {

  public addData:addWarranty=new addWarranty();

  @ViewChild('locationSelect') locationSelect: CustomerLocationSelectComponent;

  @ViewChild('kitItemGrid') warrantyItemsGrid: SalesWarrantyItemsComponent;



  public userAuth=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));


  public catId:number=3;

  public kitModel: Kit = new Kit();

  public wId:number=0;

  public termsList;

  public termSelection;

  public termsCategory="";

  public warrantyForm;

  public itemData:WarrantyItemAdd=new WarrantyItemAdd();

  public kitData:WarrantyKitsAdd=new WarrantyKitsAdd();

  public subs: SubSink = new SubSink();

  // @ViewChild('kitItemGrid') kitItemsGrid: KitItemsComponent;

  public model:addWarranty=new addWarranty();

  public getTermsData:getTermsCat=new getTermsCat();

  public itemList:WarrantyItemsList[];

  public kitList:WarrantyKitsList[];

  public additionalFields: CustomField[];

  public api;

  public Resource = ResourceConstants;

  public Permissions = PermissionConstants;

  public submitted:boolean=false;

  public formError:boolean=false;

  public UIPermissions:UIPermission;

  public _saveAsDraft:boolean=false;

  public endDateAltered:boolean=false;


  // public minDate:NgbDateStruct;

  public duration=[
    {id:1,name:'1 Year'},
    {id:2,name:'2 Years'},
    {id:3,name:'3 Years'},
    {id:4,name:'4 Years'},
    {id:5,name:'5 Years'},
    {id:0,name:'Custom end date'},
  ];


  public formData=new FormGroup({
    customer: new FormControl(this.userAuth.custid),
    title: new FormControl(),
    unique_number: new FormControl(),
    tenant_customer: new FormControl(),
    tenant_customer_contact: new FormControl(),
    tenant_customer_location: new FormControl(),
    starting_date: new FormControl(),
    ending_date: new FormControl(),
    terms_and_condition: new FormControl(),
  });



  constructor(private WarrantyService: WarrantyService,
    private toaster:ToastrService,
    public location: Location,
    private TermsService:TermsService,
    public datepipe: DatePipe,
    private router:Router,
    private cdr: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private ra: ResourceAccessService,
    public salesService: SalesService)
    {
      this.subs.sink = activeRoute.params.subscribe(params => this.wId = params.id || 0);
      this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_WARRANTY, ResourceConstants.SALES_MODULE);
      this.model.suborg=parseInt(localStorage.getItem(AppConstants.CURRENT_SUBORG));
    }





  ngOnInit(): void {
      let test=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
      console.log(test.custid);


      // this.getTerms();
      this.model.coverage_type=2; // need to change


      if(this.wId!=0){
        this.getById(this.wId);
      }

  }






  getById(id:number){

    this.subs.sink=this.WarrantyService.viewWarranty(id).subscribe(
      response=>{
          // this.warranty=response;
          this.model=response;
          this.model.starting_date=this.salesService.trimTime(this.model.starting_date);
          this.model.ending_date=this.salesService.trimTime(this.model.ending_date);
          this.cdr.markForCheck();
      },
      ()=>{
        this.toaster.error("Error occured during fetching data");
      }
    );

  }


  getItems(){
    this.subs.sink=this.WarrantyService.itemsList(this.wId).subscribe(
      response=>{
          this.itemList=response;
          this.cdr.markForCheck();


      },
      ()=>{
        this.toaster.error("Unable to get items");
      }
    );
  }

  getKits(){
    this.subs.sink=this.WarrantyService.kitsList(this.wId).subscribe(
      response=>{

        //  window.alert("Got the response");
          this.kitList=response;
          this.cdr.markForCheck();


      },
      ()=>{
        this.toaster.error("Unable to get items");
      }
    );
  }



  addWarranty(saveAsDraft:boolean=false){
    this.model.customer=this.userAuth.custid;

    this._saveAsDraft=saveAsDraft;

    this.submitted=true;

    this.formError=false;

    if(this.checkValidation(saveAsDraft)){
      return;
    }

    if(saveAsDraft){
      this.model.status=4;
    }
    else{
      this.model.status=null;
    }


      this.model.starting_date=(this.model.starting_date!=undefined&&this.model.starting_date!='')?this.model.starting_date+'T00:00:00Z':null;
      this.model.ending_date=(this.model.ending_date!=undefined&&this.model.ending_date!='')?this.model.ending_date+'T00:00:00Z':null;



    this.api = this.wId > 0 ? this.WarrantyService.updateWarranty(this.model, this.wId) : this.WarrantyService.addWarranty(this.model);

    this.subs.sink = this.api.subscribe(
      response => {
        console.log(response.id);
        this.addItems(response.id);
        this.addKits(response.id);
        if (this.hasPermission(this.Permissions.VIEW_INFO)) {
           this.router.navigate(['/sales/warranty/'+response.id+'/view']);
        }
        else {
          this.location.back();
        }

        this.toaster.success(this.wId>0?"Warranty updated":"New warranty document added successfully");

      },
      ()=>{
         this.toaster.error("Sorry error occured during process");
      }
    );
  }


  checkValidation(saveAsDraft:boolean){

    if(saveAsDraft){
        if(this.model.tenant_customer==undefined){
          this.toaster.error("Select atleast customer to save as drafrt");
          return true;
        }
        else{
          return false;
        }
    }
    else{

      if(this.model.tenant_customer==undefined||
        this.model.tenant_customer_contact==undefined||
        this.model.tenant_customer_location==undefined||
        !this.model.starting_date||
        !this.model.ending_date||
        (!this.model.ending_date&&this.model.duration==0)||
        this.warrantyItemsGrid.checkValidation()){
        this.formError=true;
      }
      if((this.model.starting_date&&this.model.ending_date)&&this.calculateDiff(this.model.starting_date, this.model.ending_date)<1){
        console.log("Inside the condition");
        this.toaster.error("Warranty ending date should not be before start date");
        this.formError=true;
      }
      else if(this.warrantyItemsGrid.rows.length==0){
        this.toaster.error("Add atleast one item or kit");
        this.formError=true;

      }
    }




    if(this.formError==true){
      this.toaster.error("Enter values in all madatory fields");
      return true;
    }
    else{
      return false;
    }

  }



  calculateDiff(startDate, endDate){
    endDate = new Date(endDate);
    startDate = new Date(startDate);
    let test = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);

    return test;
}

  //Add items

  addItems(warrantyId:number){

    console.log(this.warrantyItemsGrid.rows);
    let data:WarrantyItemAdd[]=this.formatItemsForSend(this.warrantyItemsGrid.rows,warrantyId);
    console.log(data);
    if(data.length>0){
      let api;
      if(this.wId>0){

        api=this.WarrantyService.itemsUpdate(data, this.wId);
      }
      else{

        api=this.WarrantyService.itemsAdd(data);
      }

      api.subscribe(
        ()=>{
            // window.alert("Got the response");
            console.log("Items added");

        }
      );
    }

  }

  addKits(warrantyId:number){
    console.log(this.warrantyItemsGrid.rows);
    let data:WarrantyKitsAdd[]=this.formatKitsForSend(this.warrantyItemsGrid.rows,warrantyId);
    console.log(data);

    if(data.length>0){
      let api;
      if(this.wId>0){
        api=this.WarrantyService.kitsUpdate(data, this.wId);
      }
      else{
        api=this.WarrantyService.kitsAdd(data);
      }
      api.subscribe(
        ()=>{
            // window.alert("Got the response");
            console.log("Kits added");
        }
      );
    }
  }


  //Filter items

  formatItemsForSend(rows, warrantyId:number){
    let values=[];
      if(rows.length){
        rows.forEach(element => {
          if(element.itemType=="item"){
            values.push(
              {"warranty":warrantyId,
               "associate_item":element.item_id,
               "customer":element.customer,
               "serial_number":element.serial_number,
               "quantity":element.quantity,
               "coverage_type": element.coverage_type,
               "id": element?.id,
               "is_delete": element?.is_delete,
              });
          }
        });

        return values;
      }
      else{
        return [];
      }
  }

  formatKitsForSend(rows, warrantyId:number){
    let values=[];
      if(rows.length){
        rows.forEach(element => {
          if(element.itemType=="kit"){
            values.push(
          {"warranty":warrantyId,
            "associate_kit":element.item_id,
            "customer":element.customer,
            "serial_number":element.serial_number,
            "quantity":element.quantity,
            "coverage_type": element.coverage_type,
            "id": element?.id,
            "is_delete": element?.is_delete,
          });
          }
        });

        return values;
      }
      else{
        return [];
      }
  }

  //On customer change

  customerChanged(customer:TenantCustomer){
    this.model.tenant_customer_location=null;
    this.model.tenant_customer_contact=null;
    this.cdr.markForCheck();
  }

  customerContactChange(customer:CustomerContact){
      this.model.tenant_customer_location=null;
      if(this.model.tenant_customer_contact&&customer&&customer.location){
        this.model.tenant_customer_location=customer.location;
      }
      else{
        this.model.tenant_customer_location=null;
      }
      this.cdr.markForCheck();
  }


  termsSelected(event){

    this.model.conditions=event.target.value;
    this.model.terms_and_condition=event.target.id;

    // console.log(this.model.terms_and_condition);

  }

  termsValueChange(value){
    this.model.terms_and_condition=value;
  }



  durationChange(event){

    if(event&&event.id>0&&event.id<=5){
      let date=new Date(this.model.starting_date);
      date.setDate(date.getDate()+(event.id*365));
      let datePipe: DatePipe = new DatePipe('en-US');
      this.model.ending_date=datePipe.transform(date, 'yyyy-MM-dd');
      console.log(this.model.ending_date);
      this.endDateAltered=false;

    }
    else if(event&&event.id==0){

    }

    this.cdr.markForCheck();
}

  startDateChange(){

    console.log(this.model.starting_date);
    console.log(this.model.duration);
     if(this.model.duration&&this.model.duration!=0){
       let duration=this.model.duration;
      let date=new Date(this.model.starting_date);
      date.setDate(date.getDate()+(this.model.duration*365));
      let datePipe: DatePipe = new DatePipe('en-US');
      this.model.ending_date=datePipe.transform(date, 'yyyy-MM-dd');
      this.endDateAltered=false;
      this.model.duration=duration;

     }
  }

  endDateChange(event){
      if(this.endDateAltered){
        this.model.duration=0;
        this.endDateAltered=false;
      }
      else{
        this.endDateAltered=true;
      }
  }

  termsChange(terms){
    this.model.conditions=terms.description??terms.description;
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(this.Resource.SALES_WARRANTY, [permission, this.Permissions.GLOBAL]);
  }

  hasPermissionWR(resource: string, permission: string) {
    return this.ra.hasPermission(resource, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(this.Resource.SALES_WARRANTY, [this.Permissions.GLOBAL]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }



}
