<section id="userlist-datatable">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header row">
                    <div class="col-xs-12 col-sm-6"><h4 class="card-title">Roles</h4></div>
                    <div class="d-flex flex-row col-xs-12 col-sm-6 justify-content-end">
                        <a [routerLink]="['/roles/new']" class="btn btn-text-center btn-sm gradient-pomegranate mr-1">
                            <i class="ft-plus mr-1"></i> New
                        </a>
                        <input class="form-control form-control-sm width-200" type="text" placeholder="Search role..." (keyup)="searchRoles($event)" />
                    </div>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <ngx-datatable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" [sorts]="defaultSort" (select)="onSelect($event)">
                            <ngx-datatable-column name="Name" prop="role.name" [width]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="Role Type" prop="isroot" [width]="100" *ngIf="!isSuperUser">
                                <ng-template let-isroot="value" ngx-datatable-cell-template>
                                    {{isroot ? 'SYSTEM' : 'PRIVATE' }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Customer role" prop="role.is_customer_role" [sortable]="false" [width]="100">
                                <ng-template let-role="value" ngx-datatable-cell-template>
                                    <div class="badge" *ngIf="role" [ngClass]="{'bg-light-warning': role,'bg-light-danger': !role }">
                                        {{ role ? 'Customer role' : '' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Account" prop="custname" *ngIf="isSuperUser"></ngx-datatable-column>
                            <ngx-datatable-column name="Created By" prop="role.created_by" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column name="Created Date" prop="role.created_on">
                                <ng-template let-crdate="value" ngx-datatable-cell-template>
                                    {{crdate | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Status" prop="role.is_active" [sortable]="false" [width]="60">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge" [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Action" [sortable]="false" prop="role.id" [width]="60">
                                <ng-template let-id="value" let-role="row.role" let-row="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <a class="w-100" [routerLink]="['/roles',id,'perms']" ngbDropdownItem>
                                                <i class="ft-lock mr-1"></i> Permissions
                                            </a>
                                            <a class="w-100" [routerLink]="['/roles',id,'view']" ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </a>
                                            <a class="w-100" [routerLink]="['/roles',id,'edit']" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button class="w-100" (click)="cloneRole(id)" ngbDropdownItem>
                                                <i class="ft-copy mr-1"></i> Clone
                                            </button>
                                            <button *ngIf="role.customer === customerId || isSuperUser" class="w-100 bg-light-danger" (click)="deleteRole(id)" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                                    <div style="padding: 0 .7rem;">
                                        <span>{{rowCount}} Total</span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
