import { SubSink } from 'subsink';
import { GridMetaService } from './../../services/grid-meta.service';
import { GridViewsService } from './../../services/grid-views.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { GridMeta, GridMetaColumn, GridView, GridViewInfo } from '../../models/grid-profiling.models';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-add-grid-view',
    templateUrl: './add-grid-view.component.html',
    styleUrls: ['./add-grid-view.component.scss']
})
export class AddGridViewComponent implements OnInit, OnDestroy {

    public viewId: number;

    public model: GridView = new GridView();

    public gridColumns: Observable<GridMetaColumn[]>;

    public columns: GridMetaColumn[];

    public allSelected: boolean;

    public subs: SubSink = new SubSink();

    public nameExists: boolean = false;

    constructor(public location: Location,
        route: ActivatedRoute,
        private viewService: GridViewsService,
        private metaService: GridMetaService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef) {

        this.subs.add(route.params.subscribe(params => this.viewId = +params.id || 0));
    }

    ngOnInit() {
        if (this.viewId > 0) {
            this.subs.sink = this.viewService.getById(this.viewId).subscribe(meta => {
                this.setModel(meta.gridview);
                this.cdr.markForCheck();
            });
        }
    }

    setModel(gridInfo: GridViewInfo) {
        let obj = new GridView();
        obj.name = gridInfo.name;
        obj.description = gridInfo.description;
        obj.grid = gridInfo.resource.id;
        obj.cols = gridInfo.columns.map(col => col.id);
        this.model = obj;
        this.getGridColumns(obj.grid);
    }

    submit(): void {

        this.model.customer = this.viewService.customerId;

        this.model.cols = this.columns.filter(c => c.selected).map(col => col.id);

        if (!this.model.cols || this.model.cols.length <= 0) {
            this.toastr.info("Atleast one column should be selected");
            return;
        }

        let api: Observable<any>;

        if (this.viewId > 0) {
            this.model.columnids = this.model.cols;
            this.model.cols = null;
            api = this.viewService.update(this.viewId, this.model);
        }
        else {
            api = this.viewService.create(this.model);
        }

        this.subs.sink = api.subscribe(
            resp => {
                this.nameExists = false;
                this.toastr.success(this.viewId > 0 ? `Grid view updated` : `New grid view created`);
                this.location.back();
            },
            error => {
                if (error.error === "NAME_DUPLICATION") {
                    this.nameExists = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.toastr.error('Operation failed');
                }
            }
        );
    }

    onGridChanged(meta: GridMeta) {
        if (meta) {
            this.getGridColumns(meta.id);
        }
        else {
            this.columns = [];
            this.allSelected = false;
        }
    }

    getGridColumns(metaId: number) {
        this.gridColumns = this.metaService.getMetaColumns(metaId).pipe(map(resp => {
            this.columns = resp.gridcolumns;
            if (this.viewId > 0) {
                resp.gridcolumns.forEach(item => item.selected = this.model.cols.includes(item.id));
                this.allSelected = this.model.cols.length == this.columns.length;
            }
            return resp.gridcolumns;
        }));
    }

    markAll() {
        this.columns.forEach(col => col.selected = this.allSelected);
        this.gridColumns = of(this.columns);
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
