<div class="group">
    <div class="group-header d-flex flex-row">
        <span>Overview</span>
        <!-- <button *ngIf="settings" class="btn btn-sm bg-light-danger ml-auto mr-1"
                (click)="deleteSettings()">
            <i class="ft-trash-2 mr-1"></i> Delete
        </button> -->
        <!-- <button *ngIf="settings" class="btn btn-sm bg-light-secondary ml-auto"
                (click)="openSettingsForm(settingsModal)">
            <i class="ft-edit mr-1"></i> Edit Settings
        </button>
        <button *ngIf="!settings" class="btn btn-sm btn-info ml-auto" (click)="openSettingsForm(settingsModal,'create')">
            <i class="ft-add mr-1"></i> Add Settings
        </button> -->

        <a  class="ml-auto qb_btns" (click)="connectToQb(suborgId)">
            <img src="assets/img/qb/C2QB_green_btn_short_default_2x.png" class="img-fluid" width="200" >
            <img src="assets/img/qb/C2QB_green_btn_short_hover_2x.png" class="img-fluid" width="200" >   
        </a>
    </div>
    <div class="group-body" *ngIf="settings">
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">2 Way Sync</label>
            <label class="item-label-value" [ngClass]="settings.is_two_way_sync?'text-success':'text-danger'"><i class="ft-check-circle "></i></label>
        </div>
        <div class="ov-row" *ngIf="settings.realm_id">
            <label class="col-md-3 p-0 item-label">Realm ID</label>
            <label class="item-label-value">{{settings.realm_id}}</label>
        </div>
        <div class="ov-row" *ngIf="settings.environment">
            <label class="col-md-3 p-0 item-label">Environment</label>
            <label class="item-label-value">{{settings.environment}}</label>
        </div>
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Refresh Token</label>
            <label class="item-label-value" *ngIf="settings.refresh_token else hiddenData">{{settings.refresh_token}}</label>
        </div>
        <div class="ov-row" *ngIf="settings.client_id">
            <label class="col-md-3 p-0 item-label">Client ID</label>
            <label class="item-label-value" *ngIf="settings.client_id else hiddenData">{{settings.client_id}}</label>
        </div>
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Client Secret</label>
            <label class="item-label-value" *ngIf="settings.client_secret else hiddenData">{{settings.client_secret}}</label>
        </div>
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Redirect Uri</label>
            <label class="item-label-value" *ngIf="settings.redirect_uri else hiddenData">{{settings.redirect_uri}}</label>
        </div>
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Access Token</label>
            <textarea class="item-label-value tokenBox" rows="10" cols="80" readonly *ngIf="settings.access_token else hiddenData">{{settings.access_token}}</textarea>
        </div>
        <ng-template #hiddenData>
            <label class="item-label-value">x x x - x x x - x x x - x x x x</label>
        </ng-template>
    </div>
    <div class="group-body" *ngIf="settings&&showViewButton">
        <div class="ov-row" *ngIf="settings">
            <label class="col-md-auto p-0">
                <div class="token-container" *ngIf="!verificationSend">
                    <button class="btn btn-sm btn-info view-all-button" (click)="sendVerificationCode()">
                        View All Secret
                    </button>
                </div>
                <div class="2fa-container" *ngIf="verificationSend">
                    <div class="d-flex flex-row">
                        <input type="text" name="otp" id="otpField" [(ngModel)]="code" class="form-control form-control-sm mr-2" placeholder="Enter the verification code"/>
                        <button [disabled]="!code || code.length != 6" class="btn btn-sm btn-success" (click)="verifyCode()">
                            Verify
                        </button>
                    </div>
                </div>
            </label>
            <!-- <label class="item-label-value"></label> -->
        </div>
    </div>
</div>




<div class="group mt-2" *ngIf="settings">
    <div class="group-header">Synchronize</div>
    <div class="group-body">
        <div class="ov-row align-items-center">
            <label class="col-md-3 p-0 item-label">Customer</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncSettings('customer')">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
    </div>
    <div class="group-body">
        <div class="ov-row align-items-center">
            <label class="col-md-3 p-0 item-label">Category</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncSettings('category')">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
    </div>



    <div class="group-body">
        <div class="ov-row align-items-center">
            <label class="col-md-3 p-0 item-label">Item</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncSettings('item')">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
    </div>
    <div class="group-body">
        <div class="ov-row align-items-center">
            <label class="col-md-3 p-0 item-label">Invoice</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncSettings('invoice')">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
    </div>
    <div class="group-body">
        <div class="ov-row align-items-center">
            <label class="col-md-3 p-0 item-label">Payment</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncSettings('payment')">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
    </div>
</div>

<ng-template #settingsModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">
            {{model.id ? 'Edit Settings' : 'Add Settings'}}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="is_create||(settings&&!showViewButton&&!verificationSend) else verify">
        <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()" novalidate>
            <div class="form-group">
                <label class="val-required">Refresh Token</label>
                <input type="text" [(ngModel)]="model.refresh_token" #refresh_token="ngModel" name="refresh_token"
                       class="form-control" required>
                <small class="form-text text-muted danger"
                       *ngIf="refresh_token.invalid && (refresh_token.dirty || refresh_token.touched)">
                    <div *ngIf="refresh_token.errors.required">
                        Refresh Token is required.
                    </div>
                </small>
            </div>
            <div class="d-flex">
                <div class="col-12 col-md-6 col-lg-6 pl-0 form-group">
                    <label class="val-required">Client ID</label>
                    <input type="text" [(ngModel)]="model.client_id" #client_id="ngModel" name="client_id" class="form-control" required>
                </div>
                <div class="col-12 col-md-6 col-lg-6 p-0 form-group">
                    <label class="val-required">Realm ID</label>
                    <input type="text" [(ngModel)]="model.realm_id" #realm_id="ngModel" name="realm_id" class="form-control" required>
                </div>
            </div>
            <div class="d-flex">
                <div class="col-12 col-md-6 col-lg-6 pl-0 form-group">
                    <label class="val-required">Client Secret</label>
                    <input type="text" [(ngModel)]="model.client_secret" #client_secret="ngModel" name="client_secret" class="form-control" required>
                </div>
                <div class="col-12 col-md-6 col-lg-6 p-0 form-group">
                    <label class="val-required">Environment</label>
                    <ng-select class="form-select"
                                placeholder="Select option"
                                [searchable]="false"
                                [(ngModel)]="model.environment"
                                [clearable]="false" [ngModelOptions]="{standalone: true}">
                        <ng-option value="sandbox">Sandbox</ng-option>
                        <ng-option value="production" disabled>Production</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="form-group">
                <label class="val-required">Redirect URI</label>
                <input type="text" [(ngModel)]="model.redirect_uri" #redirect_uri="ngModel" name="redirect_uri"
                       class="form-control" required>
                <small class="text-muted danger"
                       *ngIf="redirect_uri.invalid && (redirect_uri.dirty || redirect_uri.touched)">
                    <div *ngIf="redirect_uri.errors.required">
                        Redirect URI is Required
                    </div>
                </small>
            </div>
            <div class="form-group">
                <label class="val-required">Access Token</label>
                <input type="text" [(ngModel)]="model.access_token" #access_token="ngModel" name="access_token"
                       class="form-control" required>
                <small class="form-text text-muted danger"
                       *ngIf="access_token.invalid && (access_token.dirty || access_token.touched)">
                    <div *ngIf="access_token.errors.required">
                        Access Token is required.
                    </div>
                </small>
            </div>
            <div class="form-group">
                <div class="d-flex flex-row align-items-center"><label for="2ws" class="val-required ">2 Way Sync</label>
                    <div class="custom-switch custom-switch-success custom-control ml-1">
                        <input type="checkbox" class="custom-control-input" id="2ws"  [(ngModel)]="model.is_two_way_sync" 
                            [checked]="model.is_two_way_sync" [ngModelOptions]="{standalone: true}">
                        <label class="custom-control-label" for="2ws">
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row">
                <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mr-1 ml-auto">Save</button>
                <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')">Cancel</button>
            </div>
        </form>
    </div>
    <ng-template #verify>
        <div class="modal-body">
            <label *ngIf="settings" class="col-md-auto p-5 justify-content-center d-flex">
                <div class="2fa-container" *ngIf="verificationSend">
                    <div class="d-flex flex-row">
                        <input type="text" name="otp" id="otpField" [(ngModel)]="code" class="form-control form-control-sm mr-2" placeholder="Enter the verification code"/>
                        <button [disabled]="!code || code.length != 6" class="btn btn-sm btn-success" (click)="verifyCode()">
                            Verify
                        </button>
                    </div>
                </div>
            </label>
        </div>
    </ng-template>
</ng-template>