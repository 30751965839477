import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { KitAssembleWareHouseStock, KitAssembleWrapper } from '../../../models/kit-assemble.models';
import { WareHouse, WareHouseLocation } from '../../../models/warehouse.models';
import { KitAssembleService } from '../../../services/kit-assemble.service';
import { WarehouseService } from '../../../services/warehouse.service';

@Component({
  selector: 'app-build-assemble-select-view',
  templateUrl: './build-assemble-select-view.component.html',
  styleUrls: ['./build-assemble-select-view.component.scss']
})
export class BuildAssembleSelectViewComponent implements OnInit {

  // @Input() assemble: KitAssembleWrapper;

  @Output() onClose = new EventEmitter<void>();

  @Output() onSelectStock=new EventEmitter<KitAssembleWareHouseStock[]>();

  _assemble: KitAssembleWrapper
  total_quantity: number;


  get assemble(): KitAssembleWrapper {
    return this._assemble;
  };

  @Input() set assemble(value: KitAssembleWrapper) {
    this._assemble = value;
    this.assembledId = this._assemble.id
    if (value) {
      this.rows.length = 0;
      this.getAssembledWarehouses();
    }
  };

  public assembledId:number

  public rows: KitAssembleWareHouseStock[] = [];

  private subs: SubSink = new SubSink();

  public warehouses: WareHouse[];

  public warehouseLocations = {};

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  constructor(
    private warehouseService: WarehouseService,
    private assembleService: KitAssembleService,
    private ra: ResourceAccessService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getWarehouses()
  }

  getWarehouses() {
    this.subs.sink = this.warehouseService.getAll().subscribe(resp => {
      this.warehouses = resp;
      this.cdr.markForCheck();
    });
  }

  getAssembledWarehouses() {
    this.subs.sink = this.assembleService.getKitAssembledWarehousestock(this.assembledId).pipe(map(resp => {
      return resp.map(w => {
        if (w.warehouse_location > 0) {
          let loc = new WareHouseLocation();
          loc.location = w.location_name;
          loc.id = w.warehouse_location;
          w.locations = of([loc]);
        }
        return w;
      });
    })).subscribe(resp => {
      this.rows = resp.sort((a,b)=> a.id - b.id);
      this.getAggregateQuantity();
      this.cdr.markForCheck();
    });
  }

  getAggregateQuantity(){
    this.total_quantity = this.rows.reduce((sum, current)=> sum + current.quantity, 0)
  }

  closeItemView(): void {
    this.onClose?.emit();
  }

  hasPermission(resource: string, permission: string) {
    return this.ra.hasPermission(
      resource,
      [permission, this.Permissions.GLOBAL],
      ResourceConstants.INV_MODULE
    );
  }

  hasPermissionAny(resource: string, permission: string[]) {
    permission.push(this.Permissions.GLOBAL);
    return this.ra.hasPermission(resource, permission);
  }

}
