import { CustomerDataHelper } from './../../../helpers/customer-data.helper';
import { TenantCustomerService } from './../../../services/tenant-customer.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { TenantCustomer } from '../../../models/tenant-customer';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'tenant-customer-select',
  templateUrl: './tenant-customer-select.component.html',
  styleUrls: ['./tenant-customer-select.component.scss']
})
export class TenantCustomerSelectComponent implements OnInit {

  @ViewChild('modalElement') selectModal: any;

  @ViewChild('newCustomer') newCustomer: any;

  private _value: number;

  get value(): number { return this._value; }

  @Input() set value(val: number) {
    this._value = val;
    if (this.value > 0 && (!this.selected || this.selected.id !== val)) {
      this.setValue(val);
    }
  };

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<TenantCustomer>();

  @Output('default') defaultSetEvent = new EventEmitter<TenantCustomer>();

  @Output() clear = new EventEmitter();

  @Input() selectBox: boolean = true;

  @Input() readonly: boolean;

  @Input() suborg_id: number;

  public SelectionType = SelectionType;

  public fileUrl: string = environment.fileURL;

  public items: TenantCustomer[];

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = CustomerDataHelper.DefaultFilters;

  public search: GFilterParam[] = CustomerDataHelper.SearchFilters;

  public columns: GFColumn[] = CustomerDataHelper.FilterColumns;

  public sortLookup = CustomerDataHelper.SortLookup;

  public selectedItem: TenantCustomer[] = [];

  public selected: TenantCustomer;

  public sort: GSortParam[] = [{ colname: 'tenant_customer_name', direction: 'asc' }];

  private modalRef: NgbModalRef;

  public scrollbarH: boolean = false;

  public customerName: string;

  private subs: SubSink = new SubSink();

  constructor(private modal: NgbModal,
    private service: TenantCustomerService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService) {

    this.scrollbarH = this.device.isMobile();
  }

  ngOnInit() {
     console.log('Customer');
   }

  setValue(id: number) {
    this.subs.sink = this.service.getById(id).subscribe(item => {
      this.selected = item;
      this.defaultSetEvent?.emit(item);
      this.selectedItem.push(item);
      this.cdr.markForCheck();
    });
  }

  open() {
     
    this.modalRef = this.modal.open(this.selectModal, { size: 'xl', scrollable: true });

    this.getData(null);
    // if (!this.items || this.items.length <= 0) {
    //   this.getData(null);
    // }
  }

  private getData(filters: GFilterParam[], offset: number = 0) {
    let option = { sort: this.sort, filters: filters };
    this.subs.sink = this.service.getAll(option, offset, this.suborg_id).subscribe(resp => {
      this.items = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();

      console.log('items', this.items)
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  onSort(event: any, dataFilter: DataGridFilterComponent) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(dataFilter?.getDataFilters());
    }
  }

  setPage(pageInfo: any, dataFilter: DataGridFilterComponent) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(dataFilter?.getDataFilters(), offset);
  }

  onItemSelected() {
    if (this.selectedItem && this.selectedItem.length > 0) {
      let item = this.selectedItem[0];
      this.selected = item;
      this.valueChange?.emit(item.id);
      this.change?.emit(item);
    }
    this.modalRef?.dismiss();
  }

  createCustomer(){
    console.log(this.customerName,'customer created');
    alert('pending')
  }

  applyValues() {
    if (this.customerName) {
        this.createCustomer();
    }
}

dismissModal(modal: any) {
  modal.dismiss('closed');
}
  onCanceled() { this.modalRef?.dismiss(); }

  clearSelection(event: any) {
    event.stopPropagation();
    this.selected = null;
    this.valueChange?.emit(null);
    this.clear?.emit();
    this.selectedItem = [];
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
