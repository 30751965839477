import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { PaymentsService } from '../../services/payments.service';
import { InvoiceDetails } from '../../models/payments-model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { InvoiceItemsList, InvoiceItemsType, InvoiceKitsList, InvoiceList, InvoiceStatus, ItemRow, Section } from 'src/app/modules/sales/models/invoice.models';
import { InvoiceService } from 'src/app/modules/sales/services/invoice-service';
import * as _ from 'lodash';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: ['./pay-now.component.scss']
})
export class PayNowComponent implements OnInit {

  public item:InvoiceList;

  public fileUrl: string = environment.apiURL + '/static/files/';

  public itemList:InvoiceItemsList[];

  public itemKitsList:ItemRow[];

  public invItemType=InvoiceItemsType;

  public kitList:InvoiceKitsList[];

  public sections:Section[];

  public sc:InvoiceList;

  public itemSlNo:number=0;

  public invStatus=InvoiceStatus;

  public subs: SubSink = new SubSink();
  public invalidLink: boolean = false;
  public invoiceDetails: InvoiceDetails;
  public isEditAmount: boolean = false;
  public invoiceUrlToken: string;

  constructor(private activatedRoute: ActivatedRoute,
    private services: PaymentsService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private invoiceService: InvoiceService,
    private router: Router,
    private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {

    let url = this.activatedRoute.snapshot.url.join().split(',');
    this.invoiceUrlToken = url[1];
    this.getInvoiceDetails(this.invoiceUrlToken)
  }

  getInvoiceDetails(invoiceToken: string) {


    this.subs.sink = this.services.getInvoiceDetails(invoiceToken).subscribe(
      resp => {
        this.invalidLink = false;
        this.invoiceDetails = resp;
        this.invoiceDetails.invoice_data.amountPayable = this.invoiceDetails.invoice_data.balance_due;
        console.log(this.invoiceDetails, 'this.invoiceDetails+')
        this.cdr.markForCheck();
      },
      err => {
        this.invalidLink = true;
        this.cdr.markForCheck();
      }
    );

  }

  paymentSuccess() {

    this.router.navigate(['/payment-success/'+this.invoiceUrlToken]);
  }

  paymentError(resp) {
    this.toastr.error((resp?.error?.message) ? resp.error.message : 'Payment Error.Please try again after some time.');
  }

  checkIsValidAmount() {

    if(this.invoiceDetails.invoice_data.amountPayable < 1){

      this.toastr.error(`You Can't make a payment with amount lesser than $ 1.00`);
      return false;
    }

    if (!this.invoiceDetails.is_over_payment &&
      this.invoiceDetails.invoice_data.amountPayable > this.invoiceDetails.invoice_data.balance_due) {
      this.toastr.error(`You Can't make a payment with amount higher than ${this.currencyPipe.transform(this.invoiceDetails.invoice_data.balance_due, 'USD')}`);
      return false;
    } else if (!this.invoiceDetails.is_partial_payment &&
      this.invoiceDetails.invoice_data.amountPayable < this.invoiceDetails.invoice_data.balance_due) {
      this.toastr.error(`You Can't make a payment with amount lesser than ${this.currencyPipe.transform(this.invoiceDetails.invoice_data.balance_due, 'USD')}`);
      return false;
    }
    else {
      return true;
    }
  }

  getOverPaidAmount(){
    if (this.invoiceDetails.invoice_data.balance_due < this.invoiceDetails.invoice_data.amountPayable){

      return (this.invoiceDetails.invoice_data.amountPayable - this.invoiceDetails.invoice_data.balance_due);
    }else{
      return 0;
    }
  }

  download(isdownload: boolean, items, sections){

    let timesheets = sections.filter(res => res.type == this.invItemType.TIMESHEET)
    this.item=items;
    this.sections= _.chain(sections.filter(res => res.type != this.invItemType.TIMESHEET) )
          .groupBy("section_title").map((value, key) => ({ title: key, rows: value, section_total:'0' })).value();

    //console.log(this.sections,'this.sections++')
    this.caculations();
    this.subs.sink = this.invoiceService.generate_pdf(this.item, this.sections, timesheets).subscribe(resp => {
        this.toastr.success("Generated..............")
        var blob = new Blob([resp], {type: "application/pdf"});
            var blob_url = window.URL.createObjectURL(blob);
            if(isdownload){
                var link = document.createElement('a');
                link.href = blob_url
                link.download = this.item.unique_number;
                link.click();
            }
            else{
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = blob_url;
                document.body.appendChild(iframe);
                iframe.contentWindow.print();
                // document.body.removeChild(iframe)
            }
            window.URL.revokeObjectURL(blob_url);
    });
    }

    caculations(){
      this.item["image_url"] = this.fileUrl + this.item.suborg_primary_image
      this.sections.forEach((sec, index)=>{
          if(sec&&sec.rows&&sec.rows.length>0){
            sec.rows.forEach((row,i)=>{
              if(row.type==this.invItemType.TIMESHEET){
                  row.row_total = '0.00';
              }
              else{
                  row.row_total = (row.price*row.quantity).toFixed(2);
                  row.image_link = this.fileUrl + row.primary_image
              }
            })
            sec.section_total = this.priceSummaryFunc(sec.rows);
          }
  
        });
    }
    priceSummaryFunc(rows: ItemRow[]) {
      return Number(rows.map(row => (!row.is_delete&&!isNaN(row.price)&&row.type!=this.invItemType.TIMESHEET)?row.price*row.quantity:(!row.is_delete&&!isNaN(row.price)&&row.type===this.invItemType.TIMESHEET)?row.price:0)?.reduce((a, b) => a + b, 0.00))?.toFixed(2);
  
    }    

}
