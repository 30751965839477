
<section >
    <div class="d-flex close-bar mr-2 justify-content-between">
        <span class="mt-2"></span>
        <button class="btn btn-sm ml-1 font-medium-3 pt-0 pb-0" (click)="closePanel()"><i class="ft-x"></i></button>
    </div>
    <div class="col-12 col-md-12 p-0 tab-header"  #tabHeader >

        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
            <!-- <li ngbNavItem  [ngbNavItem]="1">
                <a ngbNavLink class="d-flex align-items-center" (click)="eventSelected()" >
                    <span class="d-none d-sm-block">Event Info</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                
                    <dispatch-event-info-details 
                        [rowWidth]="mapRowWidth"
                        [tabHeaderHeight]="tabHeaderHeight"
                        [st]="eventDetails?.event_service_ticket"
                        [selected]="eventDetails?.event">

                    </dispatch-event-info-details>



                </ng-template>
            </li> -->
            <li ngbNavItem [ngbNavItem]="2" *ngIf="selected&&selected.type==1">
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Service Ticket</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <customer-info-st 
                    #stTab
                    [tabHeaderHeight]="tabHeaderHeight"
                    [customerId]="_customerId"

                    >

                    </customer-info-st>
                </ng-template>
            </li>
            <!-- <li ngbNavItem [ngbNavItem]="3" *ngIf="selected&&selected.type==1">
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">History</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div [style.paddingTop.px]="tabHeaderHeight">
                         <history-view [style.paddingTop.px]="tabHeaderHeight" [module]="'EVENT'" [uniqueId]="selected?.id?selected?.id:null"></history-view>

                    </div>

                </ng-template>
            </li>

            <li ngbNavItem [ngbNavItem]="4" *ngIf="selected&&selected.type==1">
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Invoices</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">

                    <dispatch-event-info-invoice
                    [tabHeaderHeight]="tabHeaderHeight"
                    [invoices]="eventDetails?.invoices"
                    >

                    </dispatch-event-info-invoice>

                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="5" *ngIf="selected&&selected.type==1">
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Sales Order</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <dispatch-event-info-so
                    [tabHeaderHeight]="tabHeaderHeight"
                    [salesOrders]="eventDetails?.sales_order"
                    >

                    </dispatch-event-info-so>
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="6" *ngIf="selected&&selected.type==1">
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Warranty</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <dispatch-event-info-warranty
                    [tabHeaderHeight]="tabHeaderHeight"
                    [warranty]="eventDetails?.warranty"
                    >

                    </dispatch-event-info-warranty>
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="7" *ngIf="selected&&selected.type==1">
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Service Contracts</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <dispatch-event-info-sc
                        [tabHeaderHeight]="tabHeaderHeight"
                        [serviceContracts]="eventDetails?.service_contract"
                    >

                    </dispatch-event-info-sc>
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="8" *ngIf="selected&&selected.type==1">
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Dispatch Events</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <h1>Dispatch details comes here</h1>
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="9" *ngIf="selected&&selected.type==1">
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Alerts</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <h1>Alerts section comes here</h1>
                </ng-template>
            </li> -->
        </ul>
    </div>
    

    <!-- [style.maxHeight.px]="boardHeight-12"
    [style.width.px]="mapRowWidth+20"  -->
    <div [ngbNavOutlet]="nav" 

         class="mt-2 tab-body"
       
    >

    </div>

</section>
