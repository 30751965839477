<nav class="navbar navbar-expand-lg navbar-light header-navbar navbar-sticky">
    <div class="container-fluid navbar-wrapper">
        <div class="navbar-header d-flex">
            <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
                 data-toggle="collapse" (click)="toggleSidebar()">
                <i class="ft-menu font-medium-3"></i>
            </div>
            <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
                <li class="nav-item h-100 d-none d-lg-block">
                    <div class="h-100 header-flex-center" *ngIf="userType == UserTypeEnum.Admin">
                        <img src="assets/img/gallery/admin.png" height="25" />
                        <span class="ml-1 text-primary">Admin</span>
                    </div>
                    <div class="h-100 header-flex-center" *ngIf="userType == UserTypeEnum.SuperUser">
                        <img src="assets/img/gallery/admin.png" height="25" />
                        <span class="ml-1 text-primary">Super Admin</span>
                    </div>
                    <div class="h-100 header-flex-center" *ngIf="userType == UserTypeEnum.User">
                        <img src="assets/img/gallery/user.png" height="22" />
                        <span class="ml-1 text-primary">User</span>
                    </div>
                    <div class="h-100 header-flex-center" *ngIf="userType == UserTypeEnum.Customer">
                        <img src="assets/img/gallery/user.png" height="22" />
                        <span class="ml-1 text-primary">Customer</span>
                    </div>
                </li>
                <li class="v-seperator"></li>
                <!-- <li class="nav-item mr-2 d-none d-lg-block">
                    <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
                </li> -->
                <li class="nav-item nav-search">
                    <a class="nav-link nav-link-search" id="navbar-search" href="javascript:"
                       (click)="toggleSearchOpenClass(true)">
                        <i class="ft-search font-medium-3"></i>
                    </a>
                    <div class="search-input {{searchOpenClass}}">
                        <div class="search-input-icon">
                            <i class="ft-search font-medium-3"></i>
                        </div>
                        <input #search class="input" type="text" placeholder="Search..." tabindex="0" autofocus
                               data-search="template-search" />
                        <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
                            <i class="ft-x font-medium-3"></i>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
                <ul class="navbar-nav">
               
                    <!-- <li class="outbound-container">
                        <app-outbound-call-center></app-outbound-call-center>
                    </li> -->
                    <li *ngIf="suborgs && suborgs.length">
                        <ring2voice-callcenter-agent></ring2voice-callcenter-agent>
                    </li>
                    <li *ngIf="suborgs && suborgs.length" class="mr-2">
                        <div class="suborg-select-wrapper">
                            <ng-select
                            ngClass="ng-select"
                            [items]="suborgs"
                            bindLabel="name"
                            [(ngModel)]="suborgSelected"
                            [clearable]="false"
                            (change)="setSuborg($event)"
                            (search)="onSearch($event)"
                            (open)="onOpenDD()"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        {{item.name}} <span *ngIf="item.is_root" >(main)</span>
                                    </span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    {{item.name}} <span *ngIf="item.is_root" class="badge bg-success suborg-main-badge ml-2">main</span>
                                </ng-template>
                                <ng-template ng-footer-tmp>
                                    <div class="footer-container p-1">
                                        <div class="paging-label"><b>Count :</b> {{total_count}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </li>
                    <!-- <li *ngIf="suborgs && suborgs.length" class="dropdown nav-item mr-2 suborg-dropdown" placement="bottom-left" display="static" ngbDropdown>
                        <a class="nav-link dropdown-toggle text-primary " id="suborgs" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
                            <span>{{suborgSelected?.name }}</span>
                            <span *ngIf="suborgSelected?.is_root" class="ml-1">(main)</span>
                            <span class="caret ml-1"></span>
                        </a>
                        <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="suborgs" ngbDropdownMenu>
                            <a *ngFor="let org of suborgs" class="dropdown-item" (click)="setSuborg(org)">
                                <div class="d-flex align-items-center">
                                  {{org.name}}
                                  <span *ngIf="org.is_root" class="badge bg-success suborg-main-badge ml-2">main</span>
                                </div>
                            </a>
                        </div>
                    </li> -->
                    <li class="nav-item border-style">
                        <a target="_blank" href="https://scribehow.com/page/SimplyFuse_Knowledge_Base__RPmxynTaQ5aD2zC3fkOuLw">
                            <img src="assets/img/gallery/help.png" style="width:35px;height:35px;">
                        </a>
                    </li>
                    <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
                        <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
                           href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
                            <div class="user d-md-flex d-none mr-2">
                                <span class="text-right">{{userName}}</span>
                                <span class="text-right text-muted font-small-3">{{userEmail}}</span>
                            </div>
                            <img class="avatar" src="assets/img/gallery/user-avatar.jpg" alt="avatar" height="35"
                                 width="35" />
                        </a>
                        <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0"
                             aria-labelledby="dropdownBasic2" ngbDropdownMenu>
                            <a class="dropdown-item" [routerLink]="['/users/my-profile']">
                                <div class="d-flex align-items-center ">
                                    <i class="ft-edit mr-2 "></i><span>Edit Profile</span>
                                </div>
                            </a>
                            <a class="dropdown-item" (click)="logout()">
                                <div class="d-flex align-items-center ">
                                    <i class="ft-power mr-2 "></i><span>Logout</span>
                                </div>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
