<!-- Sidebar Header starts -->
<div class="sidebar-header">
    <div class="logo clearfix">
        <a [routerLink]="['/']" class="logo-text float-left">
            <span class="text align-middle">SimplyFuse</span>
            <span class="version-label">{{appVersion}}</span>
        </a>
        <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;" (click)="toggleSidebar()">
            <i #toggleIcon [ngClass]="{
                'ft-toggle-left': config.layout.sidebar.collapsed,
                'ft-toggle-right': !config.layout.sidebar.collapsed
            }" class="toggle-icon"></i>
        </a>
        <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="closeSidebar()">
            <i class="ft-x"></i>
        </a>
    </div>
</div>
<!-- Sidebar Header Ends -->
<div class="sidebar-content main-menu-content" [perfectScrollbar]>
    <div class="nav-container">
        <ul class="navigation" appSidebarDropdown>

            <ng-container *ngFor="let menuItem of menuItems">

                <li appSidebarlink *ngIf="isLinkVisible(menuItem)" [menuItem]="menuItem" [level]="1" [ngClass]="menuItem.class" [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'" [routerLinkActiveOptions]="{exact: false}">

                    <a appSidebarAnchorToggle *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink">
                        <i [ngClass]="[menuItem.icon]"></i>
                        <span class="menu-title">{{menuItem.title }}</span>
                        <span *ngIf="menuItem.badge && menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                    </a>
                    <a appSidebarAnchorToggle routerLink="{{menuItem.path}}" *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
                        <i [ngClass]="[menuItem.icon]"></i>
                        <span class="menu-title">{{menuItem.title }}</span>
                        <span *ngIf="menuItem.badge && menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                        <span *ngIf="menuItem.newLink" class="ft-plus new-link"></span>
                    </a>
                    <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink">
                        <i [ngClass]="[menuItem.icon]"></i>
                        <span class="menu-title">{{menuItem.title }}</span>
                        <span *ngIf="menuItem.badge && menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                    </a>

                    <!-- Second level menu -->
                    <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0">

                        <ng-container *ngFor="let menuSubItem of menuItem.submenu">

                            <li appSidebarlink *ngIf="isLinkVisible(menuSubItem)" [menuItem]="menuSubItem" [level]="2" [ngClass]="menuSubItem.class" [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'" [routerLinkActiveOptions]="{exact: true}">

                                <a appSidebarAnchorToggle *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
                                    <i [ngClass]="[menuSubItem.icon]"></i>
                                    <span class="menu-title">{{menuSubItem.title }}</span>
                                    <span *ngIf="menuSubItem.badge && menuSubItem.badge != ''" [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                                </a>
                                <a appSidebarAnchorToggle routerLink="{{menuSubItem.path}}" *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink">
                                    <i [ngClass]="[menuSubItem.icon]"></i>
                                    <span class="menu-title">{{menuSubItem.title }}</span>
                                    <span *ngIf="menuSubItem.badge && menuSubItem.badge != ''" [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                                    <span *ngIf="menuSubItem.newLink" class="ft-plus new-link" (click)="navigateByUrl($event,menuSubItem.newLink)"></span>
                                </a>
                                <a routerLink="{{menuSubItem.path}}" target="_blank" *ngIf="menuSubItem.isExternalLink">
                                    <i [ngClass]="[menuSubItem.icon]"></i>
                                    <span class="menu-title">{{menuSubItem.title }}</span>
                                    <span *ngIf="menuSubItem.badge && menuSubItem.badge != ''" [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                                </a>

                                <!-- Third level menu -->
                                <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">

                                    <ng-container *ngFor="let menuSubsubItem of menuSubItem.submenu">

                                        <li appSidebarlink *ngIf="isLinkVisible(menuSubItem)" [level]="3" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [ngClass]="menuSubsubItem.class">
                                            <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}" *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                                                <i [ngClass]="[menuSubsubItem.icon]"></i>
                                                <span class="menu-title">{{menuSubsubItem.title }}</span>
                                                <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != ''" [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                                <span *ngIf="menuSubsubItem.newLink" class="ft-plus new-link"></span>
                                            </a>
                                            <ng-template #externalSubSubLinkBlock>
                                                <a routerLink="{{menuSubsubItem.path}}" target="_blank">
                                                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                                                    <span class="menu-title">{{menuSubsubItem.title}}</span>
                                                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != ''" [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                                </a>
                                            </ng-template>
                                        </li>

                                    </ng-container>
                                </ul>
                            </li>
                        </ng-container>
                    </ul>
                </li>

            </ng-container>
        </ul>
    </div>
</div>
<!-- Sidebar Content Ends -->