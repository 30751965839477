
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { addWarranty, WarrantyComments, WarrantyDashBoardCounts, WarrantyItemAdd, WarrantyItemsList, WarrantyKitsAdd, WarrantyKitsList, WarrantyList } from '../models/warranty.models';

@Injectable({
    providedIn: 'root'
})
export class WarrantyService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    getAll(option: any, offset: number = 0) {
        let url = `${this.baseURL}/warranty/filter/?limit=${this.dataLimit}&offset=${offset}`;
        return this.http.post<{ count: number, result: WarrantyList[] }>(url, option).pipe(
            catchError(() => of({ count: 0, result: [] }))
        );
    }

    listWarranty(customer: number) {
        let url = `${this.baseURL}/warranty/`;
        return this.http.get<WarrantyList[]>(url);
    }

    addWarranty(data) {
        let url = `${this.baseURL}/warranty/`;
        return this.http.post<WarrantyList>(url, data);
    }

    viewWarranty(id: number) {
        let url = `${this.baseURL}/warranty/` + id + `/`;
        return this.http.get<addWarranty>(url);
    }

    getById(id: number) {
        let url = `${this.baseURL}/warranty/` + id + `/`;
        return this.http.get<WarrantyList>(url);
    }

    updateWarranty(data, id) {
        let url = `${this.baseURL}/warranty/` + id + `/`;
        return this.http.put<{ data }>(url, data);
    }

    patchWarranty(request: any, id: number) {
        return this.http.patch(`${this.baseURL}/warranty/${id}/`, request);
    }

    getDashboardCounts() {
        let url = `${this.baseURL}/warranty/dashboard/`;
        return this.http.get<WarrantyDashBoardCounts>(url);
    }

    itemsAdd(data: WarrantyItemAdd[]) {
        let url = `${this.baseURL}/warranty/items/`;
        return this.http.post<{ data }>(url, data);
    }
    itemsUpdate(data: WarrantyItemAdd[], id: number) {
        let url = `${this.baseURL}/warranty/items/edit/delete/`;
        return this.http.put<{ data }>(url, data);
    }

    itemsList(warrantyId: number) {
        let url = `${this.baseURL}/warranty/` + warrantyId + `/items/`;
        return this.http.get<WarrantyItemsList[]>(url);
    }

    itemDelete(id: number) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: id,
            },
        };
        // let value={"id":id};
        let url = `${this.baseURL}/warranty/items/edit/delete/`;
        return this.http.delete<{ data }>(url, options);
    }

    kitsAdd(data: WarrantyKitsAdd[]) {
        let url = `${this.baseURL}/warranty/kits/`;
        return this.http.post<{ data }>(url, data);
    }

    kitsUpdate(data: WarrantyKitsAdd[], id) {
        let url = `${this.baseURL}/warranty/kits/edit/delete/`;
        return this.http.put<{ data }>(url, data);
    }

    kitsList(warrantyId: number) {
        let url = `${this.baseURL}/warranty/` + warrantyId + `/kits/`;
        return this.http.get<WarrantyKitsList[]>(url);
    }

    kitsDelete(id: number) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: id,
            },
        };
        // let value={"id":id};
        let url = `${this.baseURL}/warranty/kits/edit/delete/`;
        return this.http.delete<{ data }>(url, options);
    }


    createComment(comments: WarrantyComments[], id: number) {
        return this.http.post<WarrantyComments[]>(`${this.baseURL}/create_warranty_comments/`, comments);
    }

    updateComment(id: number, comment: WarrantyComments) {
        return this.http.put(`${this.baseURL}/warranty_comments/${id}/`, comment);
    }

    getComments(ticketId: number) {
        return this.http.get<WarrantyComments[]>(`${this.baseURL}/get_warranty_comments/${ticketId}/`);
    }

    deleteComment(id: number) {
        return this.http.delete(`${this.baseURL}/warranty_comments/${id}/`);
    }



}
