<!-- <div *ngIf="selectBox" class="item-select-container select-sm" (click)="open()">
    <div class="value-container">
        <div *ngIf="!selected" class="placeholder">Select Item</div>
        <div class="combobox">
            <label class="w-100 m-0 item-name-label" *ngIf="selected">{{selected.item_name}}</label>
        </div>
    </div>
    <span *ngIf="selected" (click)="clearSelection($event)" class="clear-wrapper d-flex" title="Clear all">
        <span aria-hidden="true" class="clear">×</span>
    </span>
    <span class="arrow-wrapper"><span class="arrow"></span></span>
</div> -->

<ng-template #productModal let-c="close" let-d="dismiss">

    <div class="modal-body p-3">
        <div class="row">
            <div class="col-md-12 mt-2">
                <div class="stepper-wrapper">
                    <div class="stepper-item" [ngClass]="currentStep==1?'active':currentStep>1?'completed':''">
                      <div class="step-counter">1</div>
                      <div class="step-name">Select products</div>
                    </div>
                    <div class="stepper-item" [ngClass]="currentStep==2?'active':currentStep>2?'completed':''">
                      <div class="step-counter">2</div>
                      <div class="step-name">Choose Page Layout</div>
                    </div>
                    <div class="stepper-item" [ngClass]="currentStep==3?'active':''">
                      <div class="step-counter">3</div>
                      <div class="step-name">Finish</div>
                    </div>
                </div>
            <hr/>

            </div>
        </div>
        <div class="row" [hidden]="currentStep!=1">
            <div class="col-md-3 leftbox">
                <div class="category-div">
                    <h6>CATEGORIES</h6>

                    <app-catalog-product-select-category [selectedCategories] = "selectedCategories" [selectedSectionIndex] = "selectedSectionIndex" (onChangeCatogorySelection) = "filterProductListByCategory($event)"></app-catalog-product-select-category>

                 
                </div>


            </div>
            <div class="col-md-9">


                <div class="w-100 p-2">

                    <datagrid-filter #dataGridFilter
                    [defaultFilters]="defaults"
                    [module]="module"
                    [searchFilters]="search"
                    [expanded]="expanded"
                    [columns]="columns"
                    (apply)="applyFilter($event)">
    
                    <ng-template filterControl [column]="'category'" let-row="row">
                        <category-select class="ng-select-sm" [(value)]="row.value"></category-select>
                    </ng-template>
                    <ng-template filterControl [column]="'manufacturer'" let-row="row">
                        <manufacturer-select class="ng-select-sm" [(value)]="row.value" [addButton]="false">
                        </manufacturer-select>
                    </ng-template>
                    <ng-template filterControl [column]="'supplier'" let-row="row">
                        <app-supplier-select class="ng-select-sm" [(value)]="row.value"></app-supplier-select>
                    </ng-template>
                    <ng-template filterControl [column]="'suborg'" let-row="row">
                        <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue"></suborg-select>
                    </ng-template>

                </datagrid-filter>
                </div>
                <ngx-datatable #dataTable
                class="bootstrap core-bootstrap"
                [scrollbarH]="scrollbarH"
                [columnMode]="'force'"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [rows]="rows"
                [selectionType]="SelectionType.multiClick"
                [count]="page.count"
                [externalPaging]="true"
                [offset]="page.offset"
                [externalSorting]="true"
                [sorts]="[{prop:'part_number',dir:'asc'}]"
                [limit]="page.limit"
                (page)="setPage($event)"
                (sort)="onSort($event)"
                [rowIdentity]="getId"
                >
            <ngx-datatable-column name="selected"  [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <mat-checkbox color="primary" [(ngModel)]="row.selected" (change)="onChangeProductSelect(row.selected,row)"></mat-checkbox>
                </ng-template>
              </ngx-datatable-column>


            
    
                <ngx-datatable-column *ngIf="isProfiled('Image',true)" name="Image" prop="product_image"
                    [sortable]="false" [width]="90" [canAutoResize]="false">
                    <ng-template let-url="value" ngx-datatable-cell-template>
                        <img *ngIf="url" width="45" height="45" [src]="fileUrl + url" />
                        <img *ngIf="!url" width="45" height="45"
                            src="/assets/img/svg/product-avatar.svg" />
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="isProfiled('Name',true)" name="Name" prop="product_name">
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="isProfiled('Part Number',true)" name="Part Number"
                    prop="product_part_number" [sortable]="false"></ngx-datatable-column>
                <ngx-datatable-column *ngIf="isProfiled('Type',true)" name="type"
                    prop="product_type" [sortable]="false"></ngx-datatable-column>
                <ngx-datatable-column *ngIf="expanded && isProfiled('SKU',true)" name="SKU" prop="sku" [sortable]="false">
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="isProfiled('Category',true)" name="Category"
                    prop="category_name"></ngx-datatable-column>
                <ngx-datatable-column
                    *ngIf="expanded && isProfiled('Price',true) && hasPermission(Resource.INV_ITEMS_PRICE,Permissions.VIEW)"
                    name="Price" prop="sellprice"  [sortable]="false">
                    <ng-template let-price="value" ngx-datatable-cell-template>
                        <span *ngIf="price">
                            ${{price}}
                        </span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="expanded && isProfiled('Available Qty',true)"
                    name="Available Qty" prop="available_stock" [sortable]="false">
                    <ng-template let-quantity="value" ngx-datatable-cell-template>
                        {{quantity || '0'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="expanded && isProfiled('Manufacturer')" name="Manufacturer"
                    prop="manufacturers_name"></ngx-datatable-column>
                <ngx-datatable-column *ngIf="expanded && isProfiled('Description')" name="Description"
                    prop="part_description"></ngx-datatable-column>
                <ngx-datatable-column *ngIf="expanded && isProfiled('Replaced By')" name="Replaced By"
                    prop="replace_by" [sortable]="false"></ngx-datatable-column>
                <ngx-datatable-column *ngIf="expanded && isProfiled('Comment')" name="Comment"
                    prop="comments" [sortable]="false"></ngx-datatable-column>
                <ngx-datatable-column *ngIf="expanded && isAllSuborg" name="Suborg" prop="suborg_name"
                    [sortable]="false"></ngx-datatable-column>
                <!-- <ngx-datatable-column *ngIf="expanded && isProfiled('Status',true)" name="Status"
                    prop="is_active" [width]="80" [canAutoResize]="false" [sortable]="false">
                    <ng-template let-status="row" ngx-datatable-cell-template>
                        <div class="badge"
                            [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                            {{ status ? 'Active' : 'Inactive' }}
                        </div>
                    </ng-template>
                </ngx-datatable-column> -->
    
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                        let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                        <div class="page-count" *ngIf="expanded">
                            <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                        </div>
                        <datatable-pager
                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                            [pagerRightArrowIcon]="'datatable-icon-right'"
                            [pagerPreviousIcon]="'datatable-icon-prev'"
                            [pagerNextIcon]="'datatable-icon-skip'"
                            [page]="curPage" [size]="pageSize"
                            [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                            (change)="dataTable.onFooterPage($event)">
                        </datatable-pager>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>

            </div>
        </div>

        <div class="" [hidden]="currentStep!=2">
            <!-- <p>Here page design will come</p> -->
            <div class="d-flex justify-content-between">
                <h5>Select a page design to proceed</h5>

                <div>
                    <input type="text" placeholder="Search page designs"/>
                </div>
            </div>

            <div class="row page-select-row">
                <div  *ngFor="let page of pageTemplates"  class="col-lg-4 col-md-6 col-sm-12 page-box" (click)="onPageSelect(page)">
                    <div class="card" [ngClass]="selectedPage&&(page.id==selectedPage.id)?'selected-page':''">
                        <div class="card-content">
                            <img [src]="fileBasePath+page.image" alt="Card image cap" class="card-img-top img-fluid">
                            <div class="card-body">
                                <p class="card-text">{{page.title}}</p>
                                </div>
                            </div>
                        </div>
                 </div>
            </div>

        </div>

        <div class="row" [hidden]="currentStep!=3">
            <div class="col-md-8">
                <p style="font-size: 22px;">Number of products selected: <strong> {{totalSelectedProductCount}}</strong></p>
                <!-- <p>Categories selected from:</p> -->
                <i style="font-size: 18px;">New <strong>{{totalPageCountToAdd}}</strong> pages will be added to the catalog</i>
            </div>
            <div class="col-md-4" *ngIf="selectedPage">
                <h5>Selected page design</h5>
                <img [src]="fileBasePath+selectedPage.image" style="border:1px solid #ccc" alt="Card image cap" class="card-img-top img-fluid">
                
            </div>
        </div>



    </div>
    <div class="modal-footer">
        <button type="button" (click)="onCanceled()" *ngIf="currentStep==1" class="btn btn-secondary">Cancel</button>
        <button type="button" (click)="prev()" *ngIf="currentStep!=1" class="btn btn-secondary">Previous</button>
        <button type="button" (click)="onFinish()"  *ngIf="currentStep==3" class="btn gradient-pomegranate">Done</button>
        <button type="button" (click)="next()" *ngIf="currentStep!=3"  class="btn gradient-pomegranate">Next</button>

    </div>
</ng-template>


