import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { PaymentsService } from '../../services/payments.service';

@Component({
  selector: 'suborg-payment-settings-tab',
  templateUrl: './suborg-payment-settings-tab.component.html',
  styleUrls: ['./suborg-payment-settings-tab.component.scss']
})
export class SuborgPaymentSettingsTabComponent implements OnInit {

  @ViewChild('EditModal') editModalContent: any;

  @Input() suborgId:number;

  public subs: SubSink = new SubSink();

  public hideBanner:boolean = false;

  private modalRef: NgbModalRef;

  cardKnoxKey:string;

  isVerified:boolean = false;

  verificationCode:string;

  constructor(public modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private service: PaymentsService) { }

  ngOnInit(): void {

    this.getSuborgPaymentSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes&&changes.suborgId&&changes.suborgId.currentValue && changes.suborgId.previousValue && changes.suborgId.currentValue != changes.suborgId.previousValue)
      this.getSuborgPaymentSettings();
    
}

  toggleBanner(){
    this.hideBanner = !this.hideBanner
    localStorage.setItem('isShowBanner',this.hideBanner.toString());
  }

  editKey(){

    this.cardKnoxKey = '';
    this.verificationCode = '';
    
    if(!this.isVerified){
    this.service.sendSuborgVerification(this.suborgId).subscribe(resp =>{

      this.toastr.success('A verification code has been sent to your email address');
      this.modalRef = this.modal.open(this.editModalContent, { size: 'md', scrollable: true, centered: true });

    });
  }
  else{
    this.modalRef = this.modal.open(this.editModalContent, { size: 'md', scrollable: true, centered: true });

  }
   
  }

  getSuborgPaymentSettings(){

    this.subs.sink = this.service.getSuborgsPaymentSettings(this.suborgId).subscribe(
      resp => {
        this.isVerified = false;
       if(resp?.key_added == false){

        this.isVerified = true;

       }
       this.cdr.markForCheck();
      }
    );

  }

  verifyKey(){

    if(this.verificationCode){
    let payLoad={otp:this.verificationCode};

    this.service.verifySuborg(payLoad, this.suborgId).subscribe(resp =>{

      if(resp.key){
        this.cardKnoxKey = resp.key;
        this.isVerified = true;
      }
     
    },
    err =>{

      this.toastr.error('The Verificaton code you entered is Invalid')
    });

  }else{

    this.toastr.error('Enter The Verificaton code')
  }

  }

  updateKey(){

    if(this.cardKnoxKey){
      let model = {'api_key':this.cardKnoxKey}
      this.subs.sink = this.service.updateSuborgsPaymentSettings(model, this.suborgId).subscribe(
        resp => {
          this.isVerified = false;
          this.toastr.success('Key Updated Successfully');
          this.modalRef.close();
        },
        err => {
          this.toastr.error('error')
        }
      );

    }else{
      this.toastr.error('Cardknox Key is required')
    }

   }
  

}
