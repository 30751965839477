<section class="w-100">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="tabName" (navChange)="onNavChange($event)">
        <li ngbNavItem="calls">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Call Reports</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <app-call-reports class="w-100"></app-call-reports>
            </ng-template>
        </li>
        <li ngbNavItem="did">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Phone Number Reports</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <did-reports class="w-100"></did-reports>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</section>
