import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { TimesheetType } from '../../../models/timesheet-type.models';
import { TimesheetTypeService } from '../../../services/timesheet-type.service';

@Component({
  selector: 'timesheet-type-form',
  templateUrl: './timesheet-type-form.component.html',
  styleUrls: ['./timesheet-type-form.component.scss']
})
export class TimesheetTypeFormComponent implements OnInit {

  public model:TimesheetType=new TimesheetType();

  public nameExist:boolean=false;

  @Input() uniqueId:number;

  @Input() bindingModel;

  @Output() cancel=new  EventEmitter<any>();

  @Output() success=new  EventEmitter<any>();

  public sub:SubSink=new SubSink();



  constructor(
    private timesheetTypeService:TimesheetTypeService, 
    private toastr:ToastrService, 
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    if (this.uniqueId > 0) {
      if (this.bindingModel) {
          this.model = { ... this.bindingModel };
      }
      else{
          this.sub.add(this.timesheetTypeService.getById(this.uniqueId).subscribe(resp => {
              this.model = resp;
              this.cdr.markForCheck();
          }));
      }
  }
  else{
      this.model = this.timesheetTypeService.createModel(TimesheetType)
  }

  }

  onCancelClicked(){

    this.cancel.emit();

  }

  onSubmit(){

    let api=this.model.id>0?this.timesheetTypeService.update(this.model):this.timesheetTypeService.create(this.model);

    this.model.customer=this.timesheetTypeService.customerId;
    this.sub.sink=api.subscribe(response=>{

      if(this.model.id>0){
      this.toastr.success('Timesheet type updated successfully');

      }
      else{
        this.toastr.success('Timesheet type created successfully');
        this.resetForm();
      }
      

      this.cancel.emit();
      this.success.emit();
      
    },
    ()=>{
      this.toastr.error('Error occured during process');
    });
    

  }

  resetForm(){
   this.model=new TimesheetType();
  }

}
