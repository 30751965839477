<div class="select-wrapper">
    <ng-select ngClass="ng-select-custom-footer"
    
    [ngClass]="{'ng-select-sm':size=='sm'}"
    [placeholder]="placeholder" 
    [items]="options | async" 
    bindLabel="name" 
    bindValue="id" 
    [(ngModel)]="selected" 
    [readonly]="readonly" 
    (clear)="reset()"
    [appendTo]="appendToBody ? 'body' : null" 
    (change)="itemSelected($event)">
        <ng-template ng-label-tmp let-item="item">
            {{item.name}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <div class="option-template">
                <div class="option-title">{{item.name}}</div>
                <div class="option-desc">
                    <span *ngIf="item.address">{{item.address}}, </span>
                    <span *ngIf="item.state">{{item.state}}, </span>
                    <span *ngIf="item.city">{{item.city}}, </span>
                    <span *ngIf="item.zip">Zip: {{item.zip}} </span>
                </div>
            </div>
        </ng-template>
        <ng-template ng-footer-tmp *ngIf="addButton && hasPermission()">
            <button type="button" class="select-footer-btn" (click)="openModal(content)">
                <i class="icon icon-settings"></i><span class="text">Add Location</span>
            </button>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Location</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <customer-location-form [tenantCustomerId]="tenantCustomerId" [customerSuborg_id]="customer_suborg_id" (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()"></customer-location-form>
        </div>
    </ng-template>
</div>
