<section class="add-customer" *ngIf="model">
    <div class="card col-md-8 p-0">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">
                {{ customerId > 0 ? 'Edit Tenant': 'New Tenant'}}
            </h4>
            <div *ngIf="customerId > 0 && !model.root" class="form-group d-flex align-items-center">
                <label class="m-0 mr-2">Status :</label>
                <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                    <input type="checkbox" [(ngModel)]="model.active" name="active"
                           class="custom-control-input"
                           id="color-switch-3" (change)="updateStatus()">
                    <label class="custom-control-label mr-1" for="color-switch-3">
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form name="form" #custForm="ngForm" (ngSubmit)="custForm.form.valid && customerFormOnSubmit()"
                      novalidate>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label class="val-required">Tenant Name</label>
                                <input type="text" [(ngModel)]="model.name" name="name" #name="ngModel"
                                       class="form-control"
                                       maxlength="50" required>
                                <small class="form-text text-muted danger"
                                       *ngIf="name.invalid && (name.dirty || name.touched)">
                                    <div *ngIf="name.errors.required">
                                        Tenant name is required.
                                    </div>
                                    <div *ngIf="name.errors.maxlength">
                                        Name must be max 50 characters long.
                                    </div>
                                </small>
                                <small class="danger mt-1" *ngIf="model.name && nameExist">Name already exists</small>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group" *ngIf="!customerId">
                                <label class="val-required">First Name</label>
                                <input type="text" [(ngModel)]="model.first_name" #fn="ngModel" name="first_name"
                                       class="form-control"
                                       [required]="!customerId">
                                <small class="form-text text-muted danger"
                                       *ngIf="fn.invalid && (fn.dirty || fn.touched)">
                                    <div *ngIf="fn.errors.required">
                                        First name is required.
                                    </div>
                                </small>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Email</label>
                                <input type="email" [(ngModel)]="model.email" name="email" #email="ngModel"
                                       class="form-control"
                                       [autocomplete]="false"
                                       (ngModelChange)="model.email = $event.toLowerCase()"
                                       [readonly]="customerId > 0" email required>
                                <small class="form-text text-muted danger"
                                       *ngIf="email.invalid && (email.dirty || email.touched)">
                                    <div *ngIf="email.errors.required">
                                        Email is required.
                                    </div>
                                    <div *ngIf="email.errors?.email">
                                        Please enter a valid email address.
                                    </div>
                                </small>
                            </div>
                        </div>
                        <div class="col-12 col-md-6" *ngIf="!customerId">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" [(ngModel)]="model.last_name" #fn="ngModel" name="first_name"
                                       class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="!customerId">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" [(ngModel)]="autogenPassword"
                                   name="gen_password" id="custom-check-1">
                            <label class="custom-control-label" for="custom-check-1">
                                <span>Generate Temporary Password</span>
                            </label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group" *ngIf="!autogenPassword">
                                <label class="val-required">Password</label>
                                <input type="password" [(ngModel)]="model.password" #pw="ngModel" name="password"
                                       class="form-control"
                                       autocomplete="off" [required]="!autogenPassword">
                                <small class="form-text text-muted danger"
                                       *ngIf="pw.invalid && (pw.dirty || pw.touched)">
                                    <div *ngIf="pw.errors.required">
                                        Password required.
                                    </div>
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Phone</label>
                                <input type="text" [(ngModel)]="model.phone" name="phone" mask="(000) 000-0000"
                                       class="form-control">
                            </div>
                            <div class="form-group">
                                <label>City</label>
                                <input type="city" [(ngModel)]="model.city" name="city" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Zip Code</label>
                                <input type="text" [(ngModel)]="model.zipcode" name="zipcode" class="form-control">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Address</label>
                                <textarea [(ngModel)]="model.address" name="address" class="form-control"></textarea>
                            </div>
                            <div class="form-group">
                                <label>State</label>
                                <input type="text" [(ngModel)]="model.state" name="state" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button type="submit" [disabled]="!custForm.form.valid"
                                    class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                {{customerId > 0 ? 'Save Changes' : 'Create'}}
                            </button>
                            <button (click)="location.back()" class="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
