import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fabric } from 'fabric';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
// import { CanvasObject } from '../../models/canvas.models';
import { LabelListResponse, LabelPage, LabelPageCreate, LabelPageTemplate } from '../../models/label.models';
import { LabelService } from '../../services/label.service';

@Component({
  selector: 'app-label-pages-add',
  templateUrl: './label-pages-add.component.html',
  styleUrls: ['./label-pages-add.component.scss']
})
export class LabelPagesAddComponent implements OnInit {

  canvas:any;


  public title:string;

  public description:string;

  public length:number;

  public width:number;

  public selectedTemplateId:number=0;

  private subs:SubSink=new SubSink();

  public selectedPage:LabelPage=null;

  public selectedPageTemplate:LabelPageTemplate=null;

  public selectedLabel:LabelListResponse=null;

  public pageList:LabelPage[];

  public labelList:LabelListResponse[];

  public labelMargin:number=5;

  public labelMarginTop:number=10;

  public labelMarginRight:number=10;

  public labelMarginBottom:number=10;

  public labelMarginLeft:number=10;

  public totalLabels:number=0;

  public labelPage:number;

  public labelTemplate:number;

  public showPreview:boolean=false;

  




  constructor(
    private labelService:LabelService,
    private toastr:ToastrService,
    private activeRoute:ActivatedRoute,
    private router:Router,
    private cdr:ChangeDetectorRef
    ) { 
      this.subs.sink=this.activeRoute.params.subscribe(param=>{
        this.selectedTemplateId=param.id||0;
      });
    }

  ngOnInit(): void {

    this.canvas = new fabric.Canvas('canvas');

    // this.addTmpRect();

  //   this.canvas.add(new fabric.Rect({ 
  //     width: this.canvas.width-20, 
  //     height: this.canvas.height-20, 
  //     left: 10, 
  //     top: 10, 
  //     fill:'#fff',
  //     selectable:false,
  //     // stroke: 'black',
  //     // strokeWidth:1,
  //     stroke: '#cccccc',
  //     borderColor:'#ccc',
      
  //     strokeDashArray: [5, 5]
  // }));

    if(this.selectedTemplateId>0){
      this.getPageTemplateById(this.selectedTemplateId);
    }
    else{
      this.getPageList();

      this.getLabelList();
    }



    // this.calculateLabelPosition();

  } 


  createPage(){

    let page:LabelPageCreate=new LabelPageCreate();



    page.title=this.title;

    page.description=this.description;

    // page.bredth=this.width;

    // page.length=this.length;

    page.customer=this.labelService.customerId;

    page.margin_bottom=this.labelMarginBottom;

    page.margin_top=this.labelMarginTop;

    page.margin_left=this.labelMarginLeft;

    page.margin_right=this.labelMarginRight;

    page.total_labels=this.totalLabels;

    page.label_papersize=this.selectedPage.id;

    page.label_template=this.selectedLabel.id;

    const originalToObject = fabric.Object.prototype.toObject;

    const myAdditional = ['name'];

    fabric.Object.prototype.toObject = function (additionalProperties) {
        return originalToObject.call(this, myAdditional.concat(additionalProperties));
    }

    page.template=this.canvas.toJSON();

    page.width=this.selectedPage.bredth*96;

    page.height=this.selectedPage.length*96;

  

    
    
    

    let api;
    if(this.selectedTemplateId>0){
      api=this.labelService.updatePageTemplate(this.selectedTemplateId, page);
    }
    else{
      api=this.labelService.createPageTemplate(page);
    }

    this.subs.sink=api.subscribe(response=>{
        if(this.selectedTemplateId>0){
          this.toastr.success('Label page template updated')
        }
        else{
          this.toastr.success('Label page template created')

        }
        this.router.navigate(['/inventory/labels/pages']);
    });


  }

  getPageTemplateById(id:number){
    this.subs.sink=this.labelService.getPageTemplate(id).subscribe(
      response=>{
        this.getPageList();

        this.getLabelList();

        this.selectedPageTemplate=response;

        this.title=response.title;
        this.description=response.description;
        this.totalLabels=response.total_labels;
        this.labelMarginBottom=response.margin_bottom;
        this.labelMarginLeft=response.margin_left;
        this.labelMarginRight=response.margin_right;
        this.labelMarginTop=response.margin_top;
        this.labelPage=response.label_papersize
        this.labelTemplate=response.label_template

        this.cdr.markForCheck();
      }
    );
}

getPageList(){

   this.subs.sink=this.labelService.getAllPages().subscribe(
    response=>{
      this.pageList=response;
      this.selectedPage=this.pageList.filter(x=>x.id==this.selectedPageTemplate.label_papersize)[0];
      this.cdr.markForCheck();
    }
   );
}

getLabelList(){

  this.subs.sink=this.labelService.getAlllabels().subscribe(
    response=>{
      this.labelList=response;
      this.selectedLabel=this.labelList.filter(x=>x.id==this.selectedPageTemplate.label_template)[0];
      this.cdr.markForCheck();
    }
   );

}

testFn(){
  let val={
    "objects": [
      {
        "top": 55,
        "fill": "rgb(0,0,0)",
        "left": 29,
        "path": null,
        "text": "<Item/Kit name>",
        "type": "i-text",
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "skewX": 0,
        "skewY": 0,
        "width": 277.27,
        "height": 45.2,
        "scaleX": 0.5,
        "scaleY": 0.5,
        "shadow": null,
        "stroke": null,
        "styles": {},
        "opacity": 1,
        "originX": "left",
        "originY": "top",
        "version": "5.2.1",
        "visible": true,
        "fillRule": "nonzero",
        "fontSize": 40,
        "overline": false,
        "pathSide": "left",
        "direction": "ltr",
        "fontStyle": "normal",
        "pathAlign": "baseline",
        "textAlign": "left",
        "underline": false,
        "fontFamily": "Times New Roman",
        "fontWeight": "normal",
        "lineHeight": 1.16,
        "paintFirst": "fill",
        "charSpacing": 0,
        "linethrough": false,
        "strokeWidth": 1,
        "strokeLineCap": "butt",
        "strokeUniform": false,
        "strokeLineJoin": "miter",
        "backgroundColor": "",
        "pathStartOffset": 0,
        "strokeDashArray": null,
        "strokeDashOffset": 0,
        "strokeMiterLimit": 4,
        "textBackgroundColor": "",
        "globalCompositeOperation": "source-over"
      },
      {
        "top": 88,
        "fill": "#666",
        "left": 49,
        "path": null,
        "text": "<Serial number>",
        "type": "i-text",
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "skewX": 0,
        "skewY": 0,
        "width": 94.71,
        "height": 15.82,
        "scaleX": 1,
        "scaleY": 1,
        "shadow": null,
        "stroke": null,
        "styles": {},
        "opacity": 1,
        "originX": "left",
        "originY": "top",
        "version": "5.2.1",
        "visible": true,
        "fillRule": "nonzero",
        "fontSize": 14,
        "overline": false,
        "pathSide": "left",
        "direction": "ltr",
        "fontStyle": "normal",
        "pathAlign": "baseline",
        "textAlign": "left",
        "underline": false,
        "fontFamily": "Times New Roman",
        "fontWeight": "normal",
        "lineHeight": 1.16,
        "paintFirst": "fill",
        "charSpacing": 0,
        "linethrough": false,
        "strokeWidth": 1,
        "strokeLineCap": "butt",
        "strokeUniform": false,
        "strokeLineJoin": "miter",
        "backgroundColor": "",
        "pathStartOffset": 0,
        "strokeDashArray": null,
        "strokeDashOffset": 0,
        "strokeMiterLimit": 4,
        "textBackgroundColor": "",
        "globalCompositeOperation": "source-over"
      },
      {
        "src": "https://camo.githubusercontent.com/5c0e557ce429b13dfd71ef0c05124eda691129db9c7ca21787790a488ab5030d/68747470733a2f2f656e64726f69642e6e6c2f71722d636f64652f64656661756c742f4c6966652532306973253230746f6f25323073686f7274253230746f253230626525323067656e65726174696e672532305152253230636f646573",
        "top": 13.38,
        "fill": "rgb(0,0,0)",
        "left": 202.38,
        "type": "image",
        "angle": 0,
        "cropX": 0,
        "cropY": 0,
        "flipX": false,
        "flipY": false,
        "skewX": 0,
        "skewY": 0,
        "width": 320,
        "height": 320,
        "scaleX": 0.51,
        "scaleY": 0.51,
        "shadow": null,
        "stroke": null,
        "filters": [],
        "opacity": 1,
        "originX": "left",
        "originY": "top",
        "version": "5.2.1",
        "visible": true,
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "crossOrigin": null,
        "strokeWidth": 0,
        "strokeLineCap": "butt",
        "strokeUniform": false,
        "strokeLineJoin": "miter",
        "backgroundColor": "",
        "strokeDashArray": null,
        "strokeDashOffset": 0,
        "strokeMiterLimit": 4,
        "globalCompositeOperation": "source-over"
      }
    ],
    "version": "5.2.1"
  };

  val.objects.forEach((val, index)=>{
    if(val.type=='i-text'){
      this.addIText(val);
    }
  });

  
}




addIText(obj){
  this.canvas.add(new fabric.IText(obj.text,{
    left:obj.left, 
    top:obj.top, 
    fontSize:obj.fontSize,
    editable: false,
  }));
}

  addElement(left:number=20, top:number=20, width:number, height:number){

      // var obj;

      var rect=this.canvas.add(new fabric.Rect({
        width:width, 
        height:height,
        left:left,
        top:top,
        fill:'#eeeeee'
      }));
      // this.canvas.moveTo(tmp, 100);

      this.selectedLabel.template.objects.forEach((val,index)=>{

        if(val.type=='i-text'){
            var ele=rect.add(new fabric.IText(val.text,{
              left:left+val.left, 
              top:top+val.top, 
              fontSize:val.fontSize,
              fill:val.fill?val.fill:null,
              editable: false,
              scaleX:val.scaleX,
              scaleY:val.scaleY,
              name:val.name
            }));

        }
        else if(val.type=='image'){
          var canvas=this.canvas;

          fabric.Image.fromURL(val.src, function(myImg) {
            var img1=myImg.set({left:left+val.left, top:top+val.top, width:val.width, height:val.height});
            img1.scaleToHeight(100);
            img1.scaleToWidth(100);
            img1.name=val.name;
            rect.add(img1); 
            });
        this.canvas=canvas;
        }
      });



  }



  calculateLabelPosition(){



    if(!this.selectedLabel||!this.selectedPage){
      window.alert('Please select both the page and label');
      return;
    }

    this.showPreview=true;


    this.canvas.clear();

    let width=150;

    let height=60;

    if(this.selectedLabel){
        console.log(this.selectedLabel);
        width=this.selectedLabel.width;

        height=this.selectedLabel.length;
    }

    if(this.selectedPage){
      this.canvas.setWidth(this.selectedPage.bredth*96);
      this.canvas.setHeight(this.selectedPage.length*96);


    }





    //Calculations comes here


    let itemCount=1;
    let rowCount=1;


    let newLeft=0;

    let newTop=this.labelMarginTop;

    let itemsPerRow=this.howmanyLabelsPerRow(this.canvas.width, width);



    let itemsPerColumn=this.howManyLabelsPerColumn(this.canvas.height, height);



    let totalItems=Number(itemsPerRow*itemsPerColumn);

    this.totalLabels=totalItems;

    console.log('Per row: '+itemsPerRow+', Per column: '+itemsPerColumn+', Total: '+totalItems);

    //Start

    // const originalToObject = fabric.Object.prototype.toObject;

    // const myAdditional = ['name'];

    // fabric.Object.prototype.toObject = function (additionalProperties) {
    //     return originalToObject.call(this, myAdditional.concat(additionalProperties));
    // }

    //Start


    
    while(itemCount<=totalItems){

         if((newLeft+this.labelMarginLeft+this.labelMarginRight+width)<this.canvas.width){

             newLeft=newLeft+this.labelMarginLeft;

         }
         else{

            newLeft=this.labelMarginLeft;

         }

            if(itemsPerRow<itemCount){

                rowCount=Math.ceil(itemCount/itemsPerRow);
                
            }

            console.log('item count: '+itemCount+', Row count: '+rowCount);
          
           if(rowCount==1){
            newTop=this.labelMarginTop;

           }
          //  else if(rowCount==2){
          //   newTop=this.labelMarginTop+(height+this.labelMarginBottom+this.labelMarginTop)*(rowCount-1);

          //  }
           else{

            newTop=this.labelMarginTop+(height+this.labelMarginBottom+this.labelMarginTop)*(rowCount-1);

           }
       



            

            console.log('new Left: '+newLeft, 'new Top: '+newTop);

            this.addElement(newLeft,  newTop, width, height);
            newLeft=newLeft+width+this.labelMarginRight;
            // newTop=newTop+height;

            itemCount++;


    }

    let newZoom=1;

    while(newZoom>.2){

      if(newZoom*this.canvas.width<550){
        break;
      }
      else{
        newZoom=newZoom-0.15;
      }

    }
    this.canvas.setZoom(newZoom);
    this.canvas.setWidth(this.canvas.width * this.canvas.getZoom());
    this.canvas.setHeight(this.canvas.height * this.canvas.getZoom());

     

  }







  //Calculate How many label can be placed in a row
  howmanyLabelsPerRow(pageWidth:number, labelWidth:number){

    let count=Math.floor(pageWidth/(labelWidth+this.labelMarginLeft+this.labelMarginRight));

    if(count>0){
      return count;
    }
    else{
      return 0;
    }

  }


  //Calculate howmany Label will be in a row 
  howManyLabelsPerColumn(pageHeight:number, labelHeight:number){
    
    let count=Math.floor(pageHeight/(labelHeight+this.labelMarginTop+this.labelMarginBottom));

    if(count>0){
      return count;
    }
    else{
      return 0;
    }


  }




  ngOnDestroy(){
    this.subs.unsubscribe();
  }



  


}
