import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { DIDNumberReport } from '../../../models/dids.models';
import { DidsService } from '../../../services/dids.service';
import * as moment from 'moment';
import { WINDOW } from 'src/app/core/services/window.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';

@Component({
    selector: 'did-reports',
    templateUrl: './did-reports.component.html',
    styleUrls: ['./did-reports.component.scss']
})
export class DidReportsComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = [
        { displayName: "All Phone Numbers", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Zero Calls", colname: 'call_count', condition: 'equalto', value: 0, operator: 'AND' },
    ];

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Number or Name', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Phone Number', colname: 'did', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                { displayName: 'Phone Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' }
            ],
        },
        { displayName: 'Incoming Route Name', colname: 'forward_bin_name', condition: 'contains', value: '', operator: 'OR' },
        // { displayName: 'Forward Number', colname: 'forward_number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
        { displayName: 'Area Code', colname: 'area_code', condition: 'contains', value: '', operator: 'OR' },
    ];

    public columns: GFColumn[] = [
        { displayName: 'Phone Number', columnName: 'did', columnType: GFColumnTypes.phone },
        { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
        {
            displayName: 'Number Type',
            columnName: 'did_type',
            columnType: GFColumnTypes.select,
            optionValueType: GFColumnTypes.ref,
            options: [
                { displayName: 'Local', value: 'Local' },
                { displayName: 'Toll-Free', value: 'Toll-Free' }
            ]
        },
        { displayName: 'Area Code', columnName: 'area_code', columnType: GFColumnTypes.ref },
        { displayName: 'Purchased on', columnName: 'purchased_on', columnType: GFColumnTypes.date },
        { displayName: 'Call Duration', columnName: 'call_duration', columnType: GFColumnTypes.number },
        { displayName: 'Call Count', columnName: 'call_count', columnType: GFColumnTypes.number }
    ];

    public sort: GSortParam[] = [{ colname: 'purchased_on', direction: 'desc' }];

    public sortLookup = {
        did: 'did',
        name: 'name',
        area_code: 'area_code',
        purchased_on: 'purchased_on',
        forward_bin_name: 'forward_bin_name',
        call_duration: 'call_duration',
        call_count: 'call_count',
        suborg_name: 'suborg_name'
    };

    public SelectionType = SelectionType;

    public rows: DIDNumberReport[] = [];

    public uniqeId: string;

    public expanded: boolean = true;

    public selected: DIDNumberReport;

    public subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public isSuperAdmin: boolean;

    public module = ModuleConstants.RING2VOICE_DID_REPORTS;

    public suborg_id: number;

    constructor(private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private device: DeviceDetectorService,
        private didService: DidsService,
        private ra:ResourceAccessService,
        @Inject(WINDOW) private window: Window) {

        this.isSuperAdmin = didService.isSuperUser;
        this.suborg_id = didService.suborgId;
        this.addFilterColumns();
    }

    addFilterColumns() {
        if (this.isSuperAdmin) {
            this.columns.push(...[
                { displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref },
                { displayName: 'Sub Org    (Choose Tenant First)', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentColumn: 'customer' },
                { displayName: 'Incoming Route    (Choose Sub Org First)', columnName: 'forward_bin_sid_resolved', columnType: GFColumnTypes.ref, dependentColumn: 'suborg' }
            ]);
        }
        else if (!this.didService.isAllSuborg) {
            this.columns.push({ displayName: 'Incoming Route', columnName: 'forward_bin_sid_resolved', columnType: GFColumnTypes.ref })
        }
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
        this.getData();
    }

    getData(filters: GFilterParam[] = null, offset: number = 0) {

        let request = { filters: filters, sort: this.sort }

        this.subs.sink = this.didService.getDidReport(request, offset, this.page.limit).pipe(
            catchError(err => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        let current: DIDNumberReport = selected.length ? selected[0] : null;
        if (current) {
            this.uniqeId = current.sid;
            this.selected = current;
            this.expanded = false;
            this.refreshDataTable();
        }
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
        this.scrollToTop()
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) { this.getData(params); }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.refreshDataTable();
    }

    bulkexport() {
        let request = {
            filters: this.dataFilter.getDataFilters(),
            sort: this.sort
        }
        this.subs.sink = this.didService.didReportExport(request).subscribe(
            resp => {
                var downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(resp);
                downloadLink.download = `Ring2Voice_${moment().format('MM-DD-YYYY-hh_mm')}.csv`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },
            () => this.toastr.error("Unable to export")
        )
    }

    scrollToTop() {
        this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    hasBinViewInfoPermission() {
      return this.isSuperAdmin || this.ra.hasPermission(
          ResourceConstants.R2V_FORWARD_NUMBERS,
          [PermissionConstants.VIEW_INFO],
          ResourceConstants.R2V_MODULE
      );
    }

    ngOnDestroy(): void { this.subs.unsubscribe(); }
}
