
export enum AppConstants {
    AUTH_TOKEN = "workhorse.cloud.token",
    USER_ID = "workhorse.cloud.uid",
    CUSTOMER_ID = "workhorse.cloud.custid",
    USER_OBJ = "workhorse.cloud.uo",
    USER_TYPE = "workhorse.cloud.ut",
    // ROLES = "workhorse.cloud.roles",
    APP_RESOURCES = "workhorse.cloud.resources",
    APP_PERMISSIONS = "workhorse.cloud.permissions",
    USER_PREVILEGES = "workhorse.cloud.user.previleges",
    USER_AUTH = "workhorse.cloud.user.auth",
    USER_GRIDS = "workhorse.cloud.user.grids",
    USER_SUBORGS = "workhorse.cloud.user.suborgs",
    CURRENT_SUBORG = "workhorse.cloud.user.suborgs.current",
    USER_EMAIL = "workhorse.cloud.user.auth.email",
    TAB_WIDTH = "workhorse.cloud.tabwidth",
    CALLCENTER_SPEC = "workhorse.cloud.callcenter.spec",
}
