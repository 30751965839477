import { Priority } from './../models/common.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PriorityService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<Priority[]>(`${this.baseURL}/priority/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0, suborg_id:number = this.suborgId, limit = this.dataLimit) {
    let url = `${this.baseURL}/filter/priority/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<Priority[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<Priority>(`${this.baseURL}/priority/${id}/`);
  }

  create(model: Priority) {
    return this.http.post<Priority>(`${this.baseURL}/priority/`, model);
  }

  update(id: number, model: Priority) {
    return this.http.put<Priority>(`${this.baseURL}/priority/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<Priority>(`${this.baseURL}/priority/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/priority/${id}/`);
  }

  updateStatus(id: number, status: boolean): Observable<boolean> {
    return this.patch(id, { is_active: status }).pipe(
      map(resp => true),
      catchError(() => of(false))
    );
  }
}
