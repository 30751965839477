import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as mapboxgl from 'mapbox-gl';

@Injectable({
  providedIn: 'root'
})
export class Mapboxservice {

  private mapboxToken = environment.mapbox.accessToken;

  private map : mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';

  constructor(private http: HttpClient) {}

  geocodeAddress(address: string): Observable<any[]> {

    const country = 'us';
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=${country}&access_token=${this.mapboxToken}`;

    // Specify the type of the response using GeocodingResponse
    return this.http.get<GeocodingResponse>(url).pipe(
      map((response) => response.features),
      catchError((error) => {
        console.error('Error geocoding address:', error);
        // Handle the error, e.g., return an empty array
        return of([]);
      })
    );
  }

  createMap(containerId: string,latitude:number,longitude:number): void {

    Object.getOwnPropertyDescriptor(mapboxgl, "accessToken").set(environment.mapbox.accessToken);

    this.map = new mapboxgl.Map({
      container: containerId,
      style: 'mapbox://styles/mapbox/streets-v11',
      center:[latitude, longitude],
      zoom: 15,
      attributionControl:false
      
    });

    
    new mapboxgl.Marker().setLngLat([latitude, longitude]).addTo(this.map);
  }
  removeMap(): void {
    
    if (this.map) {
      this.map.remove();
      this.map = undefined
    }
  }
}


export interface GeocodingResponse {
  features: any[];
}