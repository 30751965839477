<table class="warehouse-table w-100">
    <thead>
        <tr>
            <th>#</th>
            <th>Warehouse</th>
            <th>Warehouse Location</th>
            <th>Serial Number</th>
            <th [width]="150">Total Quantity</th>
            <th [width]="150">Available Quantity</th>
            <th *ngIf="!isAllSuborg">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of rows;let i = index">
            <td>
                {{i+1}}
            </td>
            <td class="p-0">
                <ng-select *ngIf="!isAllSuborg && status !== 'COMPLETED'" class="custom-ng-select" [items]="warehouses | orderBy:'warehouse_name'"
                           bindLabel="warehouse_name" bindValue="id" [(ngModel)]="row.warehouse"
                           placeholder="Select warehouse" (change)="onWarehouseChange($event,i)">
                </ng-select>
                <div *ngIf="isAllSuborg || status == 'COMPLETED'">{{row.warehouse_name}}</div>
            </td>
            <td class="P-0">
                <ng-select *ngIf="!isAllSuborg  && status !== 'COMPLETED'" class="custom-ng-select" [items]="row.locations | async | orderBy:'location'"
                           bindLabel="location" bindValue="id" [(ngModel)]="row.warehouse_location"
                           placeholder="Select warehouse" (open)="onLocationSelectOpen(row,i)"
                           (change)="onWarehouseLocationChange($event, i)">
                </ng-select>
                <div *ngIf="isAllSuborg || status == 'COMPLETED'">{{row.location_name}}</div>
            </td>
            <td>
                <span *ngFor="let slno of row.serial_number;let index=index">{{row.serial_number.length==index+1?slno:slno+', '}} </span>

            </td>
            <td class="quantity-td">
                <button [disabled]="isAllSuborg || !row.warehouse_location || status == 'COMPLETED'" class="btn btn-outline-secondary opr" type="button"
                        (click)="toggleStockModal(quantityModal,row,'Subtract', i)"><i class="fa fa-minus"></i></button>
                <input [disabled]="isAllSuborg || status == 'COMPLETED'" type="number" placeholder="Enter quantity" min="0" [(ngModel)]="row.quantity" (change)="modifyQuantity()"/>
                <button [disabled]="isAllSuborg || !row.warehouse_location || status == 'COMPLETED'" class="btn btn-outline-secondary opr" type="button"
                        (click)="toggleStockModal(quantityModal, row,'Add', i)"><i class="fa fa-plus"></i></button>
            </td>
            <td style="text-align: center;">{{row.available_quantity}}</td>
            <td>
                <div ngbDropdown container="body" disableIfAllsuborg [hide]="true">
                    <button type="button" class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" ngbDropdownToggle>
                    <i class="ft-more-vertical text-primary"></i>
                    </button>
                    <div ngbDropdownMenu>
                        <button  [disabled]="status == 'COMPLETED'" class="w-100" (click)="printView(row.warehouse, row.warehouse_location, row.serial_number)"  ngbDropdownItem>
                        <i class="fa ft-printer mr-1"></i> Print Label
                        </button>
                        <button  [disabled]="status == 'COMPLETED'" class="w-100" (click)="generateSerialNumber(slnoModal, row)"  ngbDropdownItem>
                            <i class="ft ft-settings mr-1"></i> Generate Serial Number
                        </button>
                        <button *ngIf="row.id" class="w-100" (click)="showMoveStockModal(moveStockModal, row)" ngbDropdownItem>
                            <i class="ft-trash-2 mr-1"></i> Move Stock
                        </button>
                        <button  [disabled]="status == 'COMPLETED'" class="w-100 bg-light-danger" [disabled]="status == 'COMPLETED'" (click)="removeRow(row)" ngbDropdownItem>
                            <i class="ft-trash-2 mr-1"></i> Delete
                        </button>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4"> Total Quantity</td>
            <td colspan="1" style="text-align: center;">{{total_quantity}}</td>
            <td colspan="1" style="text-align: center;">{{total_available_quantity}}</td>
            <td></td>
        </tr>
    </tbody>
</table>
<div class="d-flex flex-row mt-2" [class]="{'disabled':isAllSuborg || status == 'COMPLETED'}">
    <div class="link-section">
        <!-- <a (click)="appendRow()" class="text-info"
        style="font-weight: normal;font-size: 13px;" [class]="{'disabled': isAllSuborg || status == 'COMPLETED'}">
            <i class="ft-plus"></i> Add location
        </a> -->
        <button type="button" [disabled]="isAllSuborg || status == 'COMPLETED'"
            class="btn btn-sm btn-info" (click)="appendRow()">Add location</button>
    </div>
    <div class="link-section ml-3" (click)="assembledId && submit(assembledId)" *ngIf="saveButton">
        <button type="button" *ngIf="rows.length > 0"
            [disabled]="isAllSuborg || status == 'COMPLETED'"
            class="btn btn-sm btn-info">Save Changes</button>
    </div>
</div>

<ng-template #quantityModal let-c="close" let-d="dismiss">
    <div class="modal-header stock-header">
        <div class="modal-title stock-title">
            <div>{{modal_title}}</div>
            <div>{{warehouse_detail}}</div>
        </div>
        <button type="button" class="close stock-close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>{{Operation}} Qty Amount</label>
            <input type="number" min="0" class="form-control" style="text-align: right;"
                   [(ngModel)]="warehouse_quantity" />
        </div>
        <div class="form-group">
            <label>Comments</label>
            <textarea name="" id="" cols="30" rows="2" class="form-control" [(ngModel)]="comments"></textarea>
        </div>
        <div class="d-flex flex-row justify-content-end">
            <button type="button" (click)="modifyQuantity()" class="btn gradient-pomegranate mr-1">Update
                Quantity</button>
            <button type="button" (click)="d('cancel click')" class="btn btn-secondary">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #slnoModal let-c="close" let-d="dismiss">
    <div class="modal-header stock-header">
        <div class="modal-title stock-title">
            Generate Serial Number
        </div>
        <button type="button" class="close stock-close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert bg-light-warning mb-2 alert-light-warning" *ngIf="slnoRowQty==0">
            The quantity is zero. Please add quantity before generating serial number
        </div>
        <div class="form-group">
            <div class="form-check form-check-inline" >
                <input class="form-check-input" type="radio" [(ngModel)]="slnoGenerateAll" name="inlineRadioOptions" id="inlineRadio1" [value]="true">
                <label class="form-check-label" for="inlineRadio1">For entire quantity(Total {{slnoRowQty}})</label>
              </div>
              <div class="form-check form-check-inline" >
                <input class="form-check-input" type="radio" [(ngModel)]="slnoGenerateAll"  name="inlineRadioOptions" id="inlineRadio2" [value]="false">
                <label class="form-check-label" for="inlineRadio2">Custom</label>
              </div>
        </div>
        <div class="form-group" style="max-width:300px" *ngIf="!slnoGenerateAll">
            <label>Number of serial numbers to generate</label>

            <div class="input-group mb-3">
                <input type="number" min="0" class="form-control" placeholder="Numbers to generate" [(ngModel)]="slnoToGenerate" (change)="changeSlNoQty()"  aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <span class="input-group-text" style="text-align:right" id="basic-addon2">/{{slnoRowQty-existingslNos.length}}</span>
                </div>
             </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
            <button type="button" (click)="d('cancel click')" class="btn btn-secondary  mr-1">Cancel</button>
            <button type="button" (click)="generateSlNo()" class="btn gradient-pomegranate" [disabled]="slnoRowQty==0">Generate</button>
        </div>
    </div>
</ng-template>

<ng-template #moveStockModal let-c="close" let-d="dismiss">
    <div class="modal-header stock-header">
        <div class="modal-title stock-title">
            <div>Move Stock</div>
        </div>
        <button type="button" class="close stock-close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Source Warehouse : </label>
            <label>{{moveRow.warehouse_name}}</label>
        </div>
        <div class="form-group">
            <label>Source Location : </label>
            <label>{{moveRow.location_name}}</label>
        </div>
        <div class="form-group">
            <label>Destinaton Warehouse</label>
            <ng-select  class="custom-ng-select" [items]="warehouses | orderBy:'warehouse_name'"
            bindLabel="warehouse_name" bindValue="id" [(ngModel)]="move_warehouseId" placeholder="Select warehouse"
            (change)="onMoveWarehouseChange($event)">
            </ng-select>
        </div>
        <div class="form-group">
            <label>Destinaton Location</label>
            <ng-select  class="custom-ng-select" [items]="move_locations | async | orderBy:'location'"
            bindLabel="location" bindValue="id" [(ngModel)]="move_warehouse_locationId" placeholder="Select location"
            >
            </ng-select>
        </div>
        <div class="form-group">
            <label> Qty to be Moved</label>
            <input type="number" min="0" class="form-control" style="text-align: right;" [(ngModel)]="move_quantity"/>
        </div>
        <div class="d-flex flex-row justify-content-end">
            <button type="button" (click)="onMoveQuantity()" class="btn gradient-pomegranate mr-1">Move
                Quantity</button>
            <button type="button" (click)="d('cancel click')" class="btn btn-secondary">Cancel</button>
        </div>
    </div>
</ng-template>
