import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { additionalFieldCreate, additionalFieldsList, additionalFieldUpdate, additional_field_values, updateStatus } from '../../../models/additional-fields.models';
import { AdditionFieldsService } from '../../../services/addition-fields.service';

@Component({
  selector: 'app-additional-field-form',
  templateUrl: './additional-field-form.component.html',
  styleUrls: ['./additional-field-form.component.scss']
})
export class AdditionalFieldFormComponent implements OnInit {

  @Input() set value(val:additionalFieldsList){
      this.model=val;
      this.additionalFieldId=this.model?.additional_field?.id;
  }
  model: additionalFieldsList=new additionalFieldsList();

  public nameExist: boolean = false;

  public additionalFieldId: number = 0;

  public subs:SubSink=new SubSink();

  public scrollbarH:boolean;

  public rows: additionalFieldsList[];



  @Output() onSuccess = new EventEmitter<{ response: additionalFieldsList, message: string }>();

  @Output() onError = new EventEmitter<any>();

  @Output() onCancel = new EventEmitter<any>();


  constructor(
    private cdr: ChangeDetectorRef,
      private modalService: NgbModal,
      private additionalFieldsService: AdditionFieldsService,
      private toastr: ToastrService,
      private ra: ResourceAccessService,
      private device: DeviceDetectorService

  ) { }

    ngOnInit(): void {

      this.scrollbarH = this.device.isMobile();

      this.subs.sink = this.additionalFieldsService.getAll().subscribe(response => {
          this.rows = response.data;
          this.cdr.markForCheck();
      });
    }

    onSubmit() {

      let valueError=false;
  
      let api;
      if (this.additionalFieldId > 0) {
        let data:additionalFieldUpdate=new additionalFieldUpdate();

        data.values=[];

        data.field_name=this.model.additional_field.field_name;

        if(this.model.values&&this.model.values.length){
            this.model.values.forEach(val=>{

              if(!val?.field_value){
                valueError=true
              }
              let action;

              if(val.id){
                if(val.is_delete){
                  action=updateStatus.delete;

                }
                else{
                  action=updateStatus.update;

                }
                data.values.push({id:val.id, value:val.field_value, action:action })

              }
                else{
                  action=updateStatus.new;
                  if(!val.is_delete){
                    data.values.push({id:val.id, value:val.field_value, action:action })
                
                  }

                }


      
              });

        }
        else{
          valueError=true;
        }


          api = this.additionalFieldsService.update(this.additionalFieldId, data);
      }
      else {
        let data:additionalFieldCreate=new additionalFieldCreate();

        data.values=[];

        data.field_name=this.model.additional_field.field_name;
        this.model.values.forEach(val=>{
          if(!val?.field_value){
            valueError=true
          }
          data.values.push(val.field_value);
        });

          api = this.additionalFieldsService.create(data);
      }

      if(valueError){
        this.toastr.error('Enter values');
      }
      else{

        this.subs.sink = api.subscribe(
          response => {
              // this.nameExist = false;
              this.rows = [...this.rows.filter(w => w.additional_field.id !== this.additionalFieldId), response.data];
              let message = this.additionalFieldId > 0 ? 'Additional field updated' : 'Additional Field created';
              this.onSuccess?.emit({ response: response.data, message: message });
              // this.toastr.success(this.additionalFieldId > 0 ? 'Additional field updated' : "New additional field created");
              this.additionalFieldId = response?.data?.additional_field?.id;

              this.cdr.markForCheck();
           
          },
          error => {
              if (error.error && error.error["non_field_errors"]) {
                  this.nameExist = true;
                  this.cdr.markForCheck();
              }
              else {
                  this.onError?.emit(error);
                  this.toastr.error("Failed to create Additional field");
                  this.modalService.dismissAll();
              }
          }
      );

      }


  }

  addValue(){
    this.model.values.push(new additional_field_values());
  }

  deleteValue(index){

    if(this.additionalFieldId>0){
      this.model.values[index].is_delete=true;
    }
    else{
      this.model.values.splice(index, 1);
    }
  }

  onCancelClicked(): void {
    this.onCancel?.emit();
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

}
