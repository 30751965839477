import { KitItemsComponent } from './../../components/kit-items/kit-items.component';
import { CategoryService } from './../../services/category.service';
import { CustomFieldsService } from './../../../../core/services/custom-fields.service';
import { KitService } from './../../services/kit.service';
import { Observable } from 'rxjs';
import { ItemCategory } from '../../models/items';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { Kit } from '../../models/kit.models';
import { CustomField } from 'src/app/core/models/custom-field';
import { SubSink } from 'subsink';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { FilesService } from 'src/app/core/services/files.service';
import { additionalFieldsList, assemblyCustomField, catCustomFieldData, itemCustomField } from '../../models/additional-fields.models';
import { AdditionFieldsService } from '../../services/addition-fields.service';
import { x } from 'pdfkit';

@Component({
    selector: 'app-add-kit',
    templateUrl: './add-kit.component.html',
    styleUrls: ['./add-kit.component.scss']
})
export class AddKitComponent implements OnInit, OnDestroy {

    @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;

    @ViewChild('kitItemGrid') kitItemsGrid: KitItemsComponent;

    public model: Kit;

    private partNumberInitial: string;

    public kitId: number = 0;

    public kitImages: StaticFile[];

    public additionalFields: CustomField[];

    public customFieldInputs = { key: '', value: '' };

    public partNumberHelper = { valid: true, message: '', visible: false };

    public lastGenPartNumber: Observable<string>;

    public currentCategory: ItemCategory;

    public previousCategory: ItemCategory;

    public Permissions = PermissionConstants;

    public Resources = ResourceConstants;

    public isClone: boolean = false;

    private subs = new SubSink();


    public additionalFieldz: additionalFieldsList[];

    public assemblyCustomField:assemblyCustomField=new assemblyCustomField();

    public addAdditionalFieldValues:any[];

    public additionalFieldId:any;

    public additionalFieldValue:any;

    public customAdditionalFieldKey:string;

    public customAdditionalFieldValue:string;

    constructor(route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        public location: Location,
        private cdr: ChangeDetectorRef,
        private kitService: KitService,
        private modalService: NgbModal,
        private catService: CategoryService,
        private customFields: CustomFieldsService,
        private fileService: FilesService,
        private ra: ResourceAccessService,
        private additionalFieldService:AdditionFieldsService) {

        this.kitId = +route.snapshot.paramMap.get('id')
        this.isClone = this.router.url.includes('clone');
    }

    ngOnInit(): void {
        if (this.kitId) {
            this.subs.sink = this.kitService.getById(this.kitId).subscribe(kit => {
                this.model = kit;
                if (this.isClone) {
                    this.model.id = 0;
                    this.model.internal_number = '';
                    this.model.revision = 0;
                    this.lastGenPartNumber = kit.category ? this.catService.getLastGeneratedPartnumber(kit.category) : null;
                }
                this.additionalFields = this.customFields.obj2CustomFields(kit.additional_fields);
                this.partNumberInitial = kit?.internal_number;
                this.cdr.markForCheck();
                this.getImages();
            });
        }
        else {
            this.model = this.kitService.createModel(Kit);
        }

        this.getAdditionalFields();
    }

    getImages(): void {
        this.subs.sink = this.kitService.getKitImages(this.kitId).subscribe(resp => {
            this.kitImages = resp;
            this.model.images = resp.map(file => file.id);
        });
    }

    onSubmit() {
        if (!this.kitItemsGrid.validate()) {
            this.toastr.info('Select at least one item or Assembly');
            return;
        }

        let error=this.additionalFieldService.validateAF(this.assemblyCustomField.additional_data);

        if(error!=""){
            this.toastr.error(error);            
        }
        else{

                    this.model.additional_fields = this.customFields.customFields2Obj(this.additionalFields);

                    let thumbails: ThumbNail[] = this.fileUploader.getFiles();

                    if (thumbails.length) {

                        this.subs.sink = this.fileService.uploadFiles(thumbails, 'KITS').subscribe(response => {

                            let primary = thumbails.find(t => t.isPrimary);

                            if (primary) {
                                this.model.primary_image = response.find(f => f.name = primary.name)?.id;
                            }
                            else if(response.length) {
                                // this.model.primary_image = response[0].id;
                                primary = this.fileUploader.getPrimary();
                                this.model.primary_image = (primary) ? primary.data_id : response[0].id;
                            }

                            this.model.images.push(...response.map(item => item.id));

                            this.submit();
                        });
                    }
                    else {
                        this.model.primary_image = this.fileUploader.getPrimary()?.data_id || null;
                        this.submit();
                    }
            }
    }

    private submit() {

        let isedit = this.kitId && !this.isClone;

        let api = isedit ? this.kitService.update(this.kitId, this.model) : this.kitService.create(this.model);

        this.subs.sink = api.subscribe(kit => {
            this.updateItems(kit.id, isedit);
            this.updateAdditionalField(kit.id);

            // isedit?this.updateAdditionalField():this.saveAdditionalField();
        });
    }

    updateItems(kitid: number, isedit: boolean) {
        this.kitItemsGrid.submit(kitid, result => {
            this.toastr.success(isedit ? 'Assembly info updated' : `New Assembly created`);
            if (this.hasPermission(this.Resources.INV_KITS, this.Permissions.VIEW_INFO)) {
                this.router.navigate(['/inventory/assemblies', kitid, 'view']);
            }
            else {
                this.location.back();
            }
        });
    }

    addCustomField() {
        this.additionalFields = this.additionalFields ?? [];
        if (!this.additionalFields.some(x => x.name === this.customFieldInputs.key)) {
            let customField = new CustomField();
            customField.name = this.customFieldInputs.key;
            customField.value = this.customFieldInputs.value || null;
            this.additionalFields.push(customField);
        }
        else {
            this.toastr.info(`Field '${this.customFieldInputs.key}' exist`);
        }
        this.modalService.dismissAll();
    }

    openModal(content: any) {
        this.customFieldInputs.key, this.customFieldInputs.value = '';
        this.modalService.open(content);
    }

    deleteCustomField(index: number) {
        // this.additionalFields = this.additionalFields.filter(x => x.id !== id.toString());
        // if(this.assemblyCustomField.additional_data.filter((x) => x.additional_field == id)[0]){
        //     this.assemblyCustomField.additional_data.filter((x) => x.additional_field == id).forEach((val)=>val.action=3);

        // }

        this.assemblyCustomField.additional_data[index].action=3;

        // if(this.assemblyCustomField.additional_data[index].id){
        //     this.assemblyCustomField.additional_data[index].action=3;
        // }
        // else{
        //     this.assemblyCustomField.additional_data.splice(index,1);
        // }

        this.cdr.markForCheck();


    }

    onCategoryChanged(category: ItemCategory) {

        if(this.kitId==0&&category?.id){
        this.getCategoryAdditionalFields(category.id);
        }


        this.previousCategory = this.currentCategory;
        this.currentCategory = category;
        this.updateAdditionalFields(category);
        this.lastGenPartNumber = !category.create_partnumber ? null : this.catService.getLastGeneratedPartnumber(category.id);
    }

    generatePartNumber() {

        if (!this.model.category || !this.currentCategory?.create_partnumber) return;

        this.subs.sink = this.catService.generatePartNumber(this.model.category).subscribe(
            response => {
                this.model.internal_number = response.part_number;
                this.partNumberHelper.valid = true;
                this.cdr.markForCheck();
            },
            error => {
                this.model.internal_number = '';
                this.partNumberHelper.valid = false;
                this.partNumberHelper.message = error?.message || 'Unable to create Part Number';
                this.cdr.markForCheck();
            }
        );
    }

    updateAdditionalFields(current: ItemCategory) {
        let removeTemplate = this.previousCategory?.additional_fields;
        let fields = this.customFields.removeFieldsByObjectsKeys(this.additionalFields || [], removeTemplate);
        let currentFields = this.customFields.obj2CustomFields(current.additional_fields);
        this.additionalFields = currentFields ? this.customFields.merge(fields, currentFields) : fields;
    }

    categoryClear() {
        this.model.internal_number = '';
        this.lastGenPartNumber = null;
        if (this.currentCategory && this.currentCategory.additional_fields) {
            let removeTemplate = this.currentCategory.additional_fields;
            this.additionalFields = this.customFields.removeFieldsByObjectsKeys(this.additionalFields || [], removeTemplate);
        }
    }

    partNumberOnChange(event: any) {

        let value = event.target.value;

        if (!value || value === this.partNumberInitial) {
            this.partNumberHelper.valid = true;
            return
        }
        this.subs.sink = this.catService.isPartNumberUnique(value).subscribe(
            response => {
                this.partNumberHelper.valid = response.is_unique;
                this.cdr.markForCheck();
            },
            () => {
                this.partNumberHelper.valid = false;
                this.partNumberHelper.message = "Part Number exist";
                this.cdr.markForCheck();
            }
        );
    }

    saveSheet(prop: string) {
        if (this.kitId > 0) {
            let req = {};
            this.model.revision = this.model.revision += 1;
            req[prop] = this.model[prop];
            req['revision'] = this.model.revision;
            this.subs.sink = this.kitService.patch(this.kitId, req).subscribe(resp => {
                this.toastr.success('Sheet updated');
            });
        }
    }

    hasPermission(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE)
    }

    hasPermissionAny(resource: string, permission: string[]) {
        return this.ra.hasPermission(resource, permission, ResourceConstants.INV_MODULE)
    }

    // hasPermission(permission: string) {
    //     return this.ra.hasPermission(this.Resources.INV_KITS, [permission], this.Resources.INV_MODULE);
    // }

    hasPermissionWR(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], this.Resources.INV_MODULE);
    }

    hasPricePermission(resource: string) {
        let perm = this.kitId > 0 ? this.Permissions.MODIFY : this.Permissions.CREATE;
        return this.hasPermissionWR(resource, perm);
    }

    onFileDelete(fileId:string) {
        if (this.model.images) {
            this.model.images = this.model.images.filter(id => id != fileId);
            if (this.model.primary_image === fileId) {
                this.model.primary_image = null;
            }
        }
    }


    // Additional fields


    addAdditionalField(type:string){

        let field:catCustomFieldData=new catCustomFieldData();

        field.action=1;
        if(type=='fromlist'){
            field.additional_field=this.additionalFieldId;
            field.value=this.additionalFieldValue;
            field.type=1;
        }
        else if(type=='custom'){
            field.custom_additional_field=this.customAdditionalFieldKey;
            field.custom_additional_field_value=this.customAdditionalFieldValue;
            field.type=2;
        }



        

        if((type=='fromlist'&&!this.assemblyCustomField.additional_data.find(val=>val.additional_field==this.additionalFieldId))||type=='custom'&&!this.assemblyCustomField.additional_data.find(val=>val.custom_additional_field==this.customAdditionalFieldKey)){

            if(!this.additionalFieldValue&&!this.customAdditionalFieldValue){
                this.toastr.error('Please select a value');

                return;

            }
            this.assemblyCustomField.additional_data.push(field);
            this.additionalFieldId=null;
            this.additionalFieldValue=null;

            
    
            this.modalService.dismissAll();
            this.cdr.markForCheck();

        }
        else{
            this.toastr.error('The additional field already exists');
        }


    }




    getAdditionalFields(callAssemblyAF=true){

        this.additionalFieldService.getAll().subscribe(response=>{

            this.additionalFieldz=response.data;

            if(this.kitId>0&&callAssemblyAF){
                this.getAssemblyAdditionalField();
            }


            

        });

    }


    

    getAdditionFieldValues(fieldId){

        let values=[];

        if(this.additionalFieldz&&this.additionalFieldz.length){
           values= this.additionalFieldz.find((val)=>val.additional_field.id==fieldId)?.values;
        }
        return values;
    }

    clearAdditionalFieldValue(fieldId){

        if(this.assemblyCustomField.additional_data&&this.assemblyCustomField.additional_data.length){
            this.assemblyCustomField.additional_data.find((val)=>val.additional_field==fieldId).value=null;
         }

    }

    getCategoryAdditionalFields(catId:number){

        this.assemblyCustomField.additional_data=[];

        this.additionalFieldService.getCatAdditionalField(catId).subscribe(response=>{

            if(response&&response?.data.data&&response.data.data.length){
                // console.log(response.data, 'get response');



                response.data.data.forEach(element => {

                    let newVal:catCustomFieldData=new catCustomFieldData();
                    newVal.additional_field=element.additional_field_id;
                    newVal.id=element.category_id?null:element.id;
                    newVal.action=element.category_id?1:2;
                    newVal.category_additional_field=element.category_id?element.id:null;
                    newVal.type=element.type?element.type:element.additional_field_id?1:2;
                    newVal.value=element.value_id_id;
                    newVal.custom_additional_field=element.custom_additional_field;
                    newVal.custom_additional_field_value=element.custom_additional_field_value;

                    this.assemblyCustomField.additional_data.push(newVal);
                    // this.assemblyCustomField.additional_data.push({'additional_field':element.additional_field_id,'id':element.kit_id?element.id:null,'value':element.value_id_id, 'action':2, 'category_additional_field':element.category_id?element.id:null, 'custom_additional_field':'', 'custom_additional_field_value':''});
                    
                });
            }
            else{
                // this.addCustomField();
            }
            this.cdr.markForCheck();
        })



    }



    getAssemblyAdditionalField(){

        this.assemblyCustomField.additional_data=[];

        this.additionalFieldService.getAssemblyAdditionalField(this.kitId).subscribe(response=>{

            if(response&&response?.data.data&&response.data.data.length){
                // console.log(response.data, 'get response');



                response.data.data.forEach(element => {

                    let newVal:catCustomFieldData=new catCustomFieldData();
                    newVal.additional_field=element.additional_field_id;
                    newVal.id=element.category_id?null:element.id;
                    newVal.action=element.category_id?1:2;
                    newVal.category_additional_field=element.category_id?element.id:null;
                    newVal.type=element.type?element.type:element.additional_field_id?1:2;
                    newVal.value=element.value_id_id;
                    newVal.custom_additional_field=element.custom_additional_field;
                    newVal.custom_additional_field_value=element.custom_additional_field_value;

                    this.assemblyCustomField.additional_data.push(newVal);
                    // this.assemblyCustomField.additional_data.push({'additional_field':element.additional_field_id,'id':element.kit_id?element.id:null,'value':element.value_id_id, 'action':2, 'category_additional_field':element.category_id?element.id:null, 'custom_additional_field':'', 'custom_additional_field_value':''});
                    
                });
            }
            else{
                // this.addCustomField();
            }
            this.cdr.markForCheck();
        })
    }

    saveAdditionalField(){

        if(this.assemblyCustomField.additional_data.length>0){

            if(this.assemblyCustomField.additional_data.length==1&&!this.assemblyCustomField.additional_data[0].additional_field){
                return;
            }
            this.assemblyCustomField.kit=this.kitId;

            this.additionalFieldService.createAssemblyAdditionalField(this.assemblyCustomField).subscribe(response=>{

                console.log(response, 'Item additional field');

            });

        }

    }

    updateAdditionalField(kitId=this.kitId){

        if(this.assemblyCustomField.additional_data.length>0){

            if(this.assemblyCustomField.additional_data.length==1&&!this.assemblyCustomField.additional_data[0].additional_field){
                return;
            }
            this.assemblyCustomField.kit=kitId;

            this.additionalFieldService.updateAssemblyAdditionalField(kitId,this.assemblyCustomField).subscribe(response=>{

                console.log(response, 'category additional field');

            });

        }

    }

    nameFromAdditionalFields(id:number){

        let name="";
        if(this.additionalFieldz&&this.additionalFieldz.length){
            name=this.additionalFieldz.filter(val=>val.additional_field.id==id)[0]?.additional_field?.field_name;
        }


        if(name)
        return name.toString();
        else return '';
    }

    valuesOfAdditionalField(id:number){

  


        let values=[];
        if(this.additionalFieldz&&this.additionalFieldz.length){
            values=this.additionalFieldz.filter(val=>val.additional_field.id==id)[0]?.values;
        }

        console.log(values);

        return values;
    }

    additionalFieldSelected(value){
        ;
        this.getAdditionalFields(false);
        this.additionalFieldId=value.additional_field.id;
        this.addAdditionalFieldValues=value.values;
        this.cdr.markForCheck();
    }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}

