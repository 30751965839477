<div class="unit-type-select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" [placeholder]="placeholder" [items]="options | orderBy:'state_name'"
    bindLabel="tax" bindValue="id" [(ngModel)]="value" [readonly]="readonly" (change)="onValueChanged($event)" required>
        <ng-template ng-label-tmp let-opt="item">
            {{opt.tax}} %
        </ng-template>
        <ng-template ng-header-tmp>
            <div class="custom-option custom-header">
                <div class="column width-70-per">State Name</div>
                <div class="column width-30-per" style="margin-left: -10px;">Tax (%)</div>
            </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <div class="custom-option">
                <div class="column width-70-per" style="padding-right: 3px;">
                    {{item.state_name}}
                </div>
                <div class="column width-30-per" style="padding-left: 3px;">
                    {{item.tax}}
                </div>
            </div>
        </ng-template>
        <ng-template ng-footer-tmp *ngIf="hasPermission()">
            <button type="button" class="select-footer-btn" (click)="openModal(content)">
                <i class="icon icon-settings"></i>
                <span class="text">Add Tax</span>
            </button>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Tax</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-state-tax-form (onSuccess)="onDataSubmittedSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()"></app-state-tax-form>
        </div>
    </ng-template>
</div>
