import { ToastrService } from 'ngx-toastr';
import { CustomerContact } from './../../../models/tenant-customer';
import { concat, Observable, of } from 'rxjs';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TenantCustomerService } from '../../../services/tenant-customer.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tenant-customer-contact-select',
  templateUrl: './customer-contact-select.component.html',
  styleUrls: ['./customer-contact-select.component.scss']
})
export class CustomerContactSelectComponent implements OnInit {

  private _custId: number;

  get tenantCustomerId(): number { return this._custId; }

  @Input() set tenantCustomerId(val: number) {
    this._custId = val;
    if (this._custId > 0) {
      this.getData();
    }
  };

  private _value: number;

  get value(): number { return this._value; }

  @Input() set value(val: number) {
    this._value = val;
    if (this._value && this.selected !== val) {
      this.selected = this._value;
    }
    else if(val==null){
      this.selected=null;
    }
    this.cdr.detectChanges();
  };

  @Input() size:string;

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<CustomerContact>();

  @Output() locationAdd = new EventEmitter();

  private _selected: number;

  public get selected(): number { return this._selected; }

  public set selected(value: number) {
    this._selected = value;
    this.valueChange?.emit(value);
  }

  @Input() addButton: boolean = true;

  @Input() readonly: boolean = false;

  @Output() clear=new EventEmitter();

  @Input() suborg_id:number;

  public options: Observable<CustomerContact[]>;

  public placeholder: string;

  private modalRef: NgbModalRef;

  constructor(private tenantCustService: TenantCustomerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private cdr : ChangeDetectorRef) { }

  ngOnInit() {
    this.placeholder = this.hasPermission() && this.addButton ? "Select or add Contact" : "Select Contact";
  }

  getData() {
    this.options = this.tenantCustService.getCustomerContacts(this.tenantCustomerId);
    // console.log(this.options);
  }

  openModal(content: any): void {
    this.modalRef = this.modalService.open(content, { centered: true, size: 'lg' });
  }

  onDataSubmittedSuccess(result: { response: CustomerContact, message: string }): void {
    this.toastr.success(result.message);
    // this.options = concat(this.options, of([result.response]));
    this.getData();
    this.selected = result.response.id;
    this.change?.emit(result.response);
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  onChange(contact: CustomerContact) { this.change?.emit(contact); }

  closeModal() { this.modalRef?.dismiss(); }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.CUSTOMER_CONTACTS, [PermissionConstants.CREATE, PermissionConstants.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.CUSTOMER_MODULE, [PermissionConstants.GLOBAL]);
  }

  searchFn(term: string, item: CustomerContact) {
    return (item.first_name + ' ' + item.last_name).toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1;
  }

  onLocationAdd() { this.locationAdd?.emit(); }

  reset(){
    this.clear.emit();
  }
}
