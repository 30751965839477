  import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

  @Component({
    selector: 'row-col-selector',
    templateUrl: './row-col-selector.component.html',
    styleUrls: ['./row-col-selector.component.scss']
  })
  export class RowColSelectorComponent implements OnInit {

    @Input() rowColCount : number = 6
    

    selRowIndex : number = 0

    selColIndex : number = 0

    @Output() selRowColCount  =  new EventEmitter<any>();

    generatedArrayRows:any = [];
    generatedArrayColumns:any = [];

    constructor() { }

    ngOnInit(): void {

      this.generateRowArray(this.rowColCount)
    }

    generateRange(count: number): number[] {
      return Array(count).fill(0).map((_, index) => index + 1);
    }

    generateRowArray(length: number): void {

      this.generatedArrayRows = Array.from({ length }, (_, index) => ({
        isSelected: false,
      }));
      this.generatedArrayColumns = Array.from({ length }, (_, index) => ({
        isSelected: false,
      }));
    }

    handleMouseOver(rowindex:number, colindex:number)
    {

      this.selRowIndex = rowindex;
      this.selColIndex = colindex
      this.setSelectedCells()

    }

    setSelectedCells()
    {
      for(let r=0;r < this.rowColCount;r ++)
      {
        if(r <= this.selRowIndex )
        {
          this.generatedArrayRows[r].isSelected = true
        }
        else
        {
          this.generatedArrayRows[r].isSelected = false
        }
      }
      for(let c=0; c < this.rowColCount; c++)
      {
        if(c <= this.selColIndex )
        {
          this.generatedArrayColumns[c].isSelected = true
        }
        else
        {
          this.generatedArrayColumns[c].isSelected = false
        }
      
      }
    }

    setRowColCount()
    {
        this.selRowColCount.emit({row:this.selRowIndex + 1,col:this.selColIndex + 1})
    }


  }
