<!-- {{sections|json}} -->
<div class="grid-section mb-4" *ngFor="let section of sections;let index=index">
    <!-- <button (click)="testData()">Click</button> -->
    <div class="grid-section-title row mb-3">
        <div class="col-md-6">
            <input type="text" placeholder="Enter section title" maxlength="50" [(ngModel)]="section.title" class="form-control" />
        </div>
        <div class="col-md-6 mr-auto text-right pt-1">
            <a class="text-info font-small-2" (click)="removeSection(index)" *ngIf="sections.length>1">Remove</a>
        </div>


    </div>
    <div class="grid-section-body">
    <div class="kit-item-container mt-2">
        <div class="table-responsive">
        <table class="kit-items-table table m-0" [ngStyle]="{'display':rows.length>0?'table':'table'}">
            <thead>
                <tr>
                    <th width="100" class="header-label">Image</th>
                    <th width="200" class="header-label">Name</th>
                    <!-- <th class="header-label">Part Number</th> -->
                    <th width="80" class="header-label">Ordered <br> Quantity</th>
                    <th width="80" class="header-label">Invoiced <br> Quantity</th>
                    <th width="100" class="header-label" >Quantity</th>
                    <!-- <th class="header-label">Price</th> -->
                    <th width="120" class="header-label">Serial Number</th>
                    <th *ngIf="coverageType==0&&coverageTypeSelection==2" class="header-label">Coverage type</th>
                    <th width="100" class="header-label">Unit Price($)</th>
                    <th width="100" class="header-label">Total</th>
                    <th width="60" class="header-label">Action</th>
                </tr>
            </thead>
            <tbody>

                <ng-container *ngFor="let row of section.rows;let i = index">
                    <tr *ngIf="!row.is_delete">
                        <td>
                            <!-- <img *ngIf="row.image_link" width="30" height="30" [src]="fileUrl + '/' + row.image_link" />
                            <img *ngIf="!row.image_link" width="30" height="30" src="/assets/img/svg/product-avatar.svg" /> -->
                            <img *ngIf="row.primary_image" width="80" height="80" [src]="fileUrl + row.primary_image + '.jpg'"/>
                            <img *ngIf="!row.primary_image" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                        </td>
                        <td>
                            <div class="position-relative d-flex align-items-center item-selector w-100 h-100 cursor-pointer" (click)="toggleSelect(index, row.type, i)">
                                <div class="flex-1 item-name" *ngIf="row.type != KitIemTypes.CUSTOM">
                                    {{row.name || 'Click to select ' + (row.type === KitIemTypes.ITEM ? 'an Item' : row.type === KitIemTypes.KIT? 'an Assembly':  row.type === KitIemTypes.TIMESHEET? 'a timesheet':'' )}}
                                </div>
                                <div class="flex-1 item-name" *ngIf="row.type === KitIemTypes.CUSTOM">
                                    <input type="text" [(ngModel)]="row.name" placeholder="Enter item name" class="form-control" />
                                </div>
                                <span class="arrow-icon-down" *ngIf="row.type != KitIemTypes.CUSTOM">
                                    <span class="icon-down"></span>
                                </span>
                            </div>
                            <div class="row-partnumber">P/N : {{row.part_number}}</div>
                            <p class="badge badge-info" style="padding: 0.2rem 0.1rem;">{{row.from_unique_number}}</p>
                            <p class="text-danger error-text" *ngIf="submitted&&row.type != KitIemTypes.CUSTOM&&(!row.unique_id||row.unique_id==undefined||row.unique_id==null)">Please select from the list</p>
                            <p class="text-danger error-text" *ngIf="submitted&&row.type === KitIemTypes.CUSTOM&&(row.name==''||row.name==undefined)">Please enter a name</p>

                        </td>
                        <!-- <td style="padding-left: 2px;">{{row.part_number}} <br>
                            <p class="badge badge-info" style="padding: 0.2rem 0.1rem;">{{row.from_unique_number}}</p>
                        </td> -->
                        <td>{{row.ordered_quantity}}</td>
                        <td>{{row.invoiced_quantity}}</td>
                        <td>

                            <input type="number"
                            min="0"
                            max="999999"
                            width="100"
                            [(ngModel)]="row.quantity"
                            [name]="'quan'+ i"
                            class="form-control"
                            (keydown)="quantityOnKeyDown(row)"
                            (change)="quantityOnChange(row)" *ngIf="KitIemTypes.TIMESHEET!=row.type"/>
                            <div  *ngIf="KitIemTypes.TIMESHEET==row.type" style="margin-top: 10px; text-align: center;">
                                <i class="ft-clock"></i> {{minuteToHour(row.quantity)}} Hrs
                            </div>

                            <p class="text-danger error-text" *ngIf="submitted&&(row.ordered_quantity >0 && row.ordered_quantity < (row.invoiced_quantity + row.quantity))">Quantity mismatch</p>

                            <p class="text-danger error-text" *ngIf="submitted&&(row.quantity==0)">Quantity can not be zero</p>



                            <!-- <div class="input-group mb-3" *ngIf="KitIemTypes.TIMESHEET!=row.type"> -->
                                <!-- <div class="input-group-prepend" >
                                  <span class="input-group-text" id="basic-addon1">Nos.</span>
                                </div>

                                <div class="input-group-prepend" *ngIf="KitIemTypes.TIMESHEET===row.type">
                                    <span class="input-group-text" id="basic-addon1">Min.</span>
                                  </div>

                                <input type="number"
                                min="0"
                                max="999999"
                                [(ngModel)]="row.quantity"
                                [name]="'quan'+ i"
                                class="form-control"
                                (keydown)="quantityOnKeyDown(row)"
                                (change)="quantityOnChange(row)" placeholder="Qty/Time" aria-label="Qty/time" aria-describedby="basic-addon1">
                              </div> -->



                            <!-- </div> -->
                        </td>
                        <!-- <td>{{row.sell_price}}</td> -->
                        <!-- <td><input type="text" class="form-control" placeholder="Enter serial number" [(ngModel)]="row.serial_number"/></td> -->
                        <td><serial-number-select [sno]="row.serial_number" [qty]="row.quantity" (out)="snoUpdate($event, index, i)"></serial-number-select>

                        </td>
                        <td *ngIf="row.type!=KitIemTypes.TIMESHEET"><input type="number" placeholder="Enter value" name="price"
                            [(ngModel)]="row.price"
                            class="form-control"
                            (change)="onPriceChange($event, i)"
                            (keydown)="onPriceKeyDown(row.price, index, i)"
                            />

                            <p class="text-danger error-text" *ngIf="submitted&&(!row.price||row.price==undefined)">Enter price</p>
                            <p class="text-danger error-text" *ngIf="submitted&&row.price&&row.price.toString().length>8">Price can't exceed 8 digits</p>
                        </td>
                        <td  *ngIf="row.type===KitIemTypes.TIMESHEET">

                        </td>
                        <td>
                            <p class="mt-1" *ngIf="row.price&&row.type!=KitIemTypes.TIMESHEET">{{row.price*row.quantity|currency}}</p>
                            <p class="mt-1" *ngIf="row.type===KitIemTypes.TIMESHEET">{{row.price|currency}}</p>
                            <p class="mt-1" *ngIf="!row.price">$0.00</p>
                        </td>


                        <!-- <td>
                            <div class="h-100 d-flex flex-column">
                                <div style="font-weight: 500;">$ {{row.quantity * row.sell_price}}</div>
                                <div style="font-size: 10px;color:#7b7b7b">${{row.sell_price || 0}} per unit</div>
                            </div>
                        </td> -->
                        <td>
                            <div class="d-flex flex-row align-items-center">
                                <button type="button" class="delete-btn text-danger" (click)="deleteItemKit(row.id, index, i)">
                                    <i class="ft-trash-2"></i>
                                </button>
                                <!-- <button type="button" class="delete-btn text-danger" *ngIf="row.type === KitIemTypes.ITEM" (click)="deleteItemKit(row.id, index, i)">
                                    <i class="ft-trash-2"></i>
                                </button> -->

                                <a type="button" class="toggle-button text-info" placement="left" ngbTooltip="Expand Assembly" *ngIf="row.unique_id && row.type === KitIemTypes.KIT" (click)="expandKit(row, 0)">
                                    <i *ngIf="!row.expanded" class="ft-chevron-right"></i>
                                    <i *ngIf="row.expanded" class="ft-chevron-down"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                    <ng-container *ngTemplateOutlet="templateChildren; context:{row: row, secindex:index}">
                    </ng-container>
                </ng-container>
                <tr class="summary-tr" *ngIf="section.rows && section.rows.length > 0">
                    <td [attr.colspan]="coverageTypeSelection==2?'7':'6'" >
                        <span *ngIf="rows && rows.length > 0">
                            <!-- <b>{{rows.length}}</b> {{rows.length == 1 ? 'item' : 'items'}} selected -->
                        </span>
                    </td>
                    <td>
                        <div class="w-100 text-right">
                            Section Total:
                        </div>
                    </td>
                    <td colspan="2">
                        <span class="summary-title">{{priceSummaryFunc(index)|currency}}</span>
                    </td>
                </tr>
                <tr *ngIf="!section.rows || section.rows.length <= 0">
                    <td colspan="4">No items selected.</td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>

    <div class="d-flex flex-row mt-2">
        <div class="link-section" *ngIf="addButton">
            <a (click)="appendRow(index, KitIemTypes.ITEM)" class="text-info"><i class="ft-plus"></i> Add Item</a>
        </div>
        <div class="link-section ml-2" *ngIf="addButton">
            <a (click)="appendRow(index, KitIemTypes.KIT)" class="text-info"><i class="ft-plus"></i> Add Assembly</a>
        </div>
        <div class="link-section ml-2" *ngIf="addButton">
            <a (click)="appendRow(index, KitIemTypes.TIMESHEET)" class="text-info"><i class="ft-plus"></i> Add Timesheet</a>
        </div>
        <div class="link-section ml-2" *ngIf="addButton">
            <a (click)="appendRow(index, KitIemTypes.CUSTOM)" class="text-info"><i class="ft-plus"></i> Add Custom item</a>
        </div>
        <div class="link-section" (click)="invoiceId && submit(invoiceId)" *ngIf="saveButton">
            <button type="button" [disabled]="rows.length <= 0" class="btn btn-sm btn-info">Save Changes</button>
        </div>
    </div>
</div>
</div>

<!-- <tr class="summary-tr" *ngIf="section.rows && section.rows.length > 0">
    <td [attr.colspan]="coverageTypeSelection==2?'6':'5'" >
        <span *ngIf="section.rows && section.rows.length > 0">
            <b>{{section.rows.length}}</b> {{section.rows.length == 1 ? 'item' : 'items'}} selected
        </span>
    </td>
    <td>
        <div class="w-100 text-right">
            Total ($):
        </div>
    </td>
    <td colspan="2">
        <span class="summary-title">{{calculateTotal()}}</span>
    </td>
</tr> -->
<div class="row total-section">
    <div class="col-md-6"><a (click)="addSection()" class="btn btn-outline-primary btn-sm"><i class="ft ft-plus"></i>Add Section</a></div>
    <div class="col-md-6">
        <!-- Grand Total($): <strong>{{calculateTotal()}}</strong> -->

        <!-- <p class="text-bold-700 mb-2 ml-4">Total due</p> -->
        <div class="table-responsive mt-4">
          <table class="table">
            <tbody>
              <tr>
                <td>Sub Total</td>
                <td class="text-right">{{calculateTotal()|currency}}</td>
              </tr>
              <tr>
                <td>Tax Amount</td>
                <td class="text-right">{{calculateTax()|currency}}</td>
              </tr>
              <tr class="text-bold-700 font-medium-2">
                <td class="text-bold-800">Total</td>
                <td class="text-bold-800 text-right"> {{calculateGrandTotal()|currency}}</td>
              </tr>
              <!-- <tr>
                <td>Payment Made</td>
                <td class="danger text-right">(-) $4,688.00</td>
              </tr>
              <tr class="text-bold-500">
                <td>Balance Due</td>
                <td class="text-right">$12,000.00</td>
              </tr> -->
            </tbody>
          </table>
        </div>

    </div>
</div>



<item-select #itemSelect [selectBox]="false" (change)="onItemChange($event)" [suborg_id]="suborg_id"></item-select>

<kit-select #kitSelect [selectBox]="false" (change)="onKitChange($event)" [suborg_id]="suborg_id"></kit-select>

<timesheet-select #timesheetSelect [customerId]="customerId" [selectBox]="false" (change)="onTimeSheetChange($event)"></timesheet-select>

<ng-template #templateChildren let-row="row" let-secindex="index">
    <ng-container *ngIf="row.children && row.expanded" >
        <ng-container *ngFor="let innerRow of row.children | orderBy:'item_name';let k = index" >
            <tr class="kit-item-childrentr" [style]="'background-color:' + innerRow.color">
                <td class="text-center">
                    <img *ngIf="innerRow.primary_image" width="80" height="80"
                        [src]="fileUrl + innerRow.primary_image + '.jpg'" />
                    <img *ngIf="!innerRow.primary_image" width="30" height="30"
                        src="/assets/img/svg/product-avatar.svg" />
                    <div class="itemtype" *ngIf="innerRow.type == KitIemTypes.ITEM"> Item
                    </div>
                    <div class="assemblytype" *ngIf="innerRow.type == KitIemTypes.KIT">
                        Assembly</div>
                </td>
                <td>{{innerRow.name}}</td>
                <td>{{innerRow.part_number}}</td>
                <td></td>
                <td></td>
                <td>{{innerRow.quantity}}</td>
                <td><serial-number-select [sno]="innerRow.serial_number" [qty]="row.quantity" (out)="snoUpdate($event, secindex, k)"></serial-number-select>

                </td>
                <td>{{innerRow.price|currency}}</td>

                <td>{{(innerRow.quantity*innerRow.price)|currency}}</td>
                <td class="align-middle">
                    <a type="button" class="toggle-button text-info" placement="left"
                                    ngbTooltip="Expand Assembly" *ngIf="innerRow.unique_id && innerRow.type === KitIemTypes.KIT"
                                    (click)="expandKit(innerRow)">
                                    <i *ngIf="!innerRow.expanded" class="ft-chevron-right"></i>
                                    <i *ngIf="innerRow.expanded" class="ft-chevron-down"></i>
                    </a>
                </td>
            </tr>
            <ng-container *ngTemplateOutlet="templateChildren; context:{row: innerRow,secindex:secindex }">
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="(!row.children || row.children.length <= 0) && row.expanded">
        <tr class="kit-item-childrentr">
            <td colspan="9">No items in the Assembly</td>
        </tr>
    </ng-container>
</ng-template>
