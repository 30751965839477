import { ItemTypesService } from './../../services/item-types.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ItemType } from '../../models/items';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UIPermission } from 'src/app/core/models/common.models';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-item-types',
    templateUrl: './item-types.component.html',
    styleUrls: ['./item-types.component.scss']
})
export class ItemTypesComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @ViewChild('itemTypeModal') postModal: any;

    @ViewChild('searchInput') searchInput: ElementRef;

    public SelectionType = SelectionType;

    public rows: ItemType[];

    private tempData: ItemType[];

    public itemTypeId: number;

    private subs:SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public isAllSuborg: boolean;

    public UIPermissions: UIPermission;

    constructor(private modalService: NgbModal,
        private itemTypesService: ItemTypesService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        private device: DeviceDetectorService) {

        this.isAllSuborg = this.itemTypesService.isAllSuborg;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_ITEMTYPES, ResourceConstants.INV_MODULE);
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
        this.subs.sink = this.itemTypesService.getAll().subscribe(types => {
            this.rows = types;
            this.tempData = types;
            this.cdr.markForCheck();
        });
    }

    ngAfterViewInit(): void { this.searchInput?.nativeElement.focus(); }

    search(event: any) {
        const val = event.target.value?.toLowerCase();
        this.rows = this.tempData.filter(function (d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.table.offset = 0;
    }

    onSelect({ selected }) {
        let current = selected[0];
        if (current && this.UIPermissions.edit && !this.isAllSuborg) {
            this.itemTypeId = current.id;
            this.modalService.open(this.postModal, { centered: true });
        }
    }

    openModal() {
        this.itemTypeId = 0;
        this.modalService.open(this.postModal, { centered: true });
    }

    closeModal() { this.modalService.dismissAll(); }

    onDataSubmittedSuccess(result: { response: ItemType, message: string }): void {
        this.toastr.success('Success', result.message);
        if (this.itemTypeId <= 0) {
            this.rows = this.rows.concat(result.response);
        }
        else {
            let temp = this.rows.filter(x => x.id !== this.itemTypeId);
            this.rows = temp.concat(result.response);
        }
        this.cdr.markForCheck();
        this.closeModal();
    }

    onDataError(_error: any): void {
        this.toastr.error('Failed', 'Operation failed');
        this.closeModal();
    }

    onCancel(): void { this.closeModal(); }

    deleteItemType(id: number) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.itemTypesService.delete(id).subscribe(
                    () => {
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        popup.CompleteDelete('Item type has been deleted.');
                    },
                    (error) => {
                        this.toastr.error('Unable to delete row')
                    }
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
