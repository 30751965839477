<div class="group">
    <div class="group-header d-flex flex-row">
        <span>Overview</span>
        <button *ngIf="settings" class="btn btn-sm bg-light-danger ml-auto mr-1"
                (click)="deleteSettings()">
            <i class="ft-trash-2 mr-1"></i> Delete
        </button>
        <button *ngIf="settings" class="btn btn-sm bg-light-secondary"
                (click)="openSettingsForm(settingsModal)">
            <i class="ft-edit mr-1"></i> Edit Settings
        </button>
        <button *ngIf="!settings" class="btn btn-sm btn-info ml-auto" (click)="openSettingsForm(settingsModal)">
            <i class="ft-add mr-1"></i> Add Settings
        </button>
    </div>
    <div class="group-body" *ngIf="settings">
        <div class="ov-row" *ngIf="settings.sw_space_url">
            <label class="col-md-3 p-0 item-label">Space URL</label>
            <label class="item-label-value">{{settings.sw_space_url}}</label>
        </div>
        <div class="ov-row" *ngIf="settings.sip_uri">
            <label class="col-md-3 p-0 item-label">Sip URI</label>
            <label class="item-label-value">{{settings.sip_uri}}</label>
        </div>
        <div class="ov-row" *ngIf="settings.sw_project_id">
            <label class="col-md-3 p-0 item-label">Project Id</label>
            <label class="item-label-value">{{settings.sw_project_id}}</label>
        </div>
        <div class="ov-row" *ngIf="settings.sw_token">
            <label class="col-md-3 p-0 item-label">Token</label>
            <label class="item-label-value">{{settings.sw_token}}</label>
        </div>
        <div class="ov-row" *ngIf="settings.did_purchase_rate">
            <label class="col-md-3 p-0 item-label">Purchase Rate</label>
            <label class="item-label-value">{{settings.did_purchase_rate}}</label>
        </div>
        <div class="ov-row" *ngIf="settings.did_call_rate">
            <label class="col-md-3 p-0 item-label">Call Rate</label>
            <label class="item-label-value">{{settings.did_call_rate}}</label>
        </div>
    </div>
</div>
<div class="group" *ngIf="settings">
    <div class="group-header">Synchronize</div>
    <div class="group-body">
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Incoming Routes</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncForwardNumbers()">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Phone Numbers</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncPhoneNumbers()">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">SIP Endpoints</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncSIPEnpoints()">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Calls</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncCalls()">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
        <div class="ov-row">
            <label class="col-md-3 p-0 item-label">Messages</label>
            <label class="item-label-value">
                <button class="btn btn-sm btn-info" (click)="syncMessages()">
                    <i class="ft-refresh-cw mr-1"></i> Sync Now
                </button>
            </label>
        </div>
    </div>
</div>

<ng-template #settingsModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">
            {{model.id ? 'Edit Settings' : 'Add Settings'}}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()" novalidate>
            <div class="form-group">
                <label class="val-required">Space Url</label>
                <input type="text" [(ngModel)]="model.sw_space_url" #sw_space_url="ngModel" name="sw_space_url"
                       class="form-control" required>
                <small class="form-text text-muted danger"
                       *ngIf="sw_space_url.invalid && (sw_space_url.dirty || sw_space_url.touched)">
                    <div *ngIf="sw_space_url.errors.required">
                        Space url is required.
                    </div>
                </small>
            </div>
            <div class="d-flex">
                <div class="col-12 col-md-6 col-lg-6 pl-0 form-group">
                    <label>Purchase rate</label>
                    <input type="text" [(ngModel)]="model.did_purchase_rate" #did_purchase_rate="ngModel"
                           mask="separator.2"
                           thousandSeparator=","
                           name="did_purchase_rate"
                           class="form-control">
                </div>
                <div class="col-12 col-md-6 col-lg-6 p-0 form-group">
                    <label>CALL RATE</label>
                    <input type="text" [(ngModel)]="model.did_call_rate" #did_call_rate="ngModel" name="did_call_rate"
                      mask="separator.2"
                      thousandSeparator=","
                      class="form-control">
                </div>
            </div>

            <div class="form-group">
                <label class="val-required">Project Id</label>
                <input type="text" [(ngModel)]="model.sw_project_id" #sw_project_id="ngModel" name="sw_project_id"
                       class="form-control" required>
                <small class="text-muted danger"
                       *ngIf="sw_project_id.invalid && (sw_project_id.dirty || sw_project_id.touched)">
                    <div *ngIf="sw_project_id.errors.required">
                        Project Id is Required
                    </div>
                </small>
            </div>
            <div class="form-group">
                <label class="val-required">Token</label>
                <input type="text" [(ngModel)]="model.sw_token" #sw_token="ngModel" name="sw_token"
                       class="form-control" required>
                <small class="form-text text-muted danger"
                       *ngIf="sw_token.invalid && (sw_token.dirty || sw_token.touched)">
                    <div *ngIf="sw_token.errors.required">
                        Token is required.
                    </div>
                </small>
            </div>
            <div class="form-group" *ngIf="model.sip_uri">
                <label class="val-required">Sip URI</label>
                <input type="text" [(ngModel)]="model.sip_uri" name="sip_uri" class="form-control" readonly>
            </div>
            <div class="d-flex flex-row">
                <button type="submit" class="btn gradient-pomegranate mr-1 ml-auto">Save</button>
                <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')">Cancel</button>
            </div>
        </form>
    </div>
</ng-template>
