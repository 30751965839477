<div class="role-select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" 
    [placeholder]="placeholder" 
    [items]="categories | async" 
    bindLabel="category_name" 
    [(ngModel)]="selected" 
    [readonly]="readonly" 
    [minTermLength]="2" 
    [trackByFn]="trackByFn" 
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="categoryInput" 
    (change)="onValueChanged($event)" 
    (clear)="onSelectionClear()" 
    (open)="placeholder = 'Search Category...'" (close)="placeholder = 'Select Category'">

    <ng-template ng-label-tmp let-item="item">
        <span class="ng-value-label">{{item.category_name }} {{ (showDescription && item.description) ? ' - ' + item.description : ''}}</span>
        
    </ng-template>

        <ng-template ng-header-tmp *ngIf="isHeaderVisible">
            <div class="custom-option custom-header">
                <div class="column width-50-per">Name</div>
                <div class="column width-50-per">Parent</div>
            </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <div class="custom-option">
                <div class="column width-50-per" style="padding-right: 3px;">
                    {{item.category_name }} {{ (showDescription && item.description) ? ' - ' + item.description : ''}}
                </div>
                <div class="column width-50-per" style="padding-left: 3px;">
                    {{item.parent_name}}
                </div>
            </div>
        </ng-template>
        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
                <a class="add-button text-info" *ngIf="addButton && hasPermission(Permissions.CREATE)" (click)="openModal(content)">
                    <i class="icon icon-settings mr-1"></i> Add Category
                </a>
            </div>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Item Category</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-item-category-form *ngIf="addButton" (onSuccess)="onDataSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()">
            </app-item-category-form>
        </div>
    </ng-template>
</div>