<div class="docList">

    <div class="d-flex">
        <form class="mr-1 w-100" (submit)="searchST(true);">
            <div class="input-group input-clear input-group-sm mb-0 mr-1">
                <input type="text" [(ngModel)]="search" name="search" class="form-control search-input" placeholder="Search Service Tickets" />


                <div class="input-group-append">
                    <button [hidden]="!search.length" type="button" (click)="search='';searchST(true);" class="input-clear-btn btn btn-sm">
                        <i class="ft-x"></i>
                    </button>
                <button class="btn btn-outline-secondary"  type="submit" ><i class="ft ft-search"></i></button>
                </div>
            </div>
        </form>
        <button class="btn btn-secondary btn-sm mb-2 mr-1 filter-btn-active" [ngClass]="{'filter-btn-active':filterActive}" type="button" (click)="toggleFilter()"><i class="ft-filter"></i></button>

        <button class="btn btn-primary btn-sm mb-2" *ngIf="showNewButton">New</button>

    </div>
    <div class="d-flex flex-wrap mb-1 filter-row" *ngIf="showFilter">

        <tenant-customer-contact-select
        [tenantCustomerId]="_customerId"
        [readonly]="false"
        [addButton]="false"
        [size]="'sm'"
        [value]="customerContact"
        (change)="customerContactChange($event)"
        class="mr-2 mb-1"
        (clear)="customerContact=null;searchST()"
        >
        </tenant-customer-contact-select>

        <tenant-customer-location-select
        #locationSelect
        style="min-width:200px"
        [size]="'sm'"
        [addButton]="false"
        [value]="customerLocation"
        (change)="customerLocationChange($event)"
        (clear)="customerLocation=null;searchST()"
        [tenantCustomerId]="_customerId"
         class="mr-1 mb-1"
        >
        </tenant-customer-location-select>

        <call-reason-select
            style="min-width:200px"
            [size]="'sm'"
            [addButton]="false"
            class="mr-1 mb-1"
            [value]="reasonForService"
            (change)="changeReason($event)"

        >

        </call-reason-select>

        <priority-select
            style="min-width:150px"
            [size]="'sm'"
            [addButton]="false"
            class="mr-1 mb-1"
            [value]="priority"
            (valueChange)="changePriority($event)"

        >

        </priority-select>

        <ng-select
        style="min-width:150px"
         placeholder="Select status"
         class="ng-select-sm mr-1 mb-1"
         [items]="statusList"
         [(ngModel)]="status"
         bindLabel="displayName"
         bindValue="value"
        >
        </ng-select>



        <button class="btn btn-info btn-sm mr-2 mb-1" (click)="searchST(false)">Apply Filter</button>
        <a class="text-warning pt-1 text-sm mb-1" (click)="resetFilter()">Reset</a>


    </div>

    <p class="mt-2 mb-2 no-record" *ngIf="_st&&_st?.length==0">Sorry, No Service Ticket to display</p>


    <div class="d-flex flex-column doc-list-row">

        <div class="doc-box mb-3" *ngFor="let ticket of _st">

            <div class="doc-body">

               <div class="doc-row d-flex">
                    <div class="info-box">
                        <label>#</label>
                        <p class="">{{ticket.unique_number}}</p>
                    </div>
                    <div class="info-box">
                        <label>Title</label>
                        <p><strong>{{ticket.title}}</strong></p>
                    </div>
               </div>


               <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Customer name</label>
                        <p>{{ticket.tenant_customer_name}}</p>
                    </div>
                    <div class="info-box">
                        <label>Created contact name</label>
                        <p>{{ticket.tenant_customer_contact_name}}</p>
                    </div>

                </div>
                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Customer location</label>
                        <p>{{ticket.tenant_customer_location_name}}</p>
                    </div>
                    <div class="info-box">
                        <label>Priority</label>
                        <p>{{ticket.priority_name?ticket.priority_name:'-'}}</p>
                    </div>

                </div>
                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Reason for Call</label>
                        <p>{{ticket.reason_for_call_name?ticket.reason_for_call_name:'-'}}</p>
                    </div>
                    <div class="info-box">
                        <label>Status</label>
                        <p *ngIf="StatusMap[ticket.status || STStatus.New] as map"><span class="badge" [class]="map.class">{{ticket.status_name}}</span></p>

                    </div>

                </div>
                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Created On</label>
                        <p>{{ticket.created_on|date:'MM-dd-YYYY hh:mm a'}}</p>
                    </div>
                    <div class="info-box">
                        <label>Created By</label>
                        <p>{{ticket.created_by}}</p>
                    </div>

               </div>





            </div>

            <div class="doc-footer mt-1">
                <ul class="d-flex justify-content-between">
                    <a class="w-100" (click)="selected=ticket;openSTView(st_view_modal)"> <li><i class="ft-eye"></i> View</li></a>
                    <!-- <li><i class="ft-printer"></i></li>
                    <li><i class="ft-edit-2"></i></li>
                    <li class="text-warning"><i class="ft-trash-2"></i></li> -->
                </ul>

            </div>


        </div>


        <!-- <div class="invoice-box">

        </div> -->

    </div>
    <div class="d-flex justify-content-center mb-1 mt-1" *ngIf="resultTotalCount>resultCount">
        <a class="text-info" (click)="searchST(false, true)">Load more</a>
    </div>
    <div class="result-count mb-1 pl-1">
        Showing {{resultCount}}/{{resultTotalCount}}
    </div>
</div>

<ng-template #st_view_modal let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <service-ticket-view [item]="selected"
                [showEditButton]="false"
                [showDeleteButton]="false" [showSendToButton]="false"
                [isReadOnly]="true" (onClose)="onCloseInvoiceView()"
                #StView></service-ticket-view>
            </div>
        </div>
    </div>
</ng-template>
