export class TimesheetType{

    public id:number;

    public name:string;

    public description:string;

    public customer:number;

    public created_by:string;

    public modified_by:string;

    public is_billable:boolean=false;

    public is_default:boolean;

    public created_on:string;

    public modified_on:string;

    
}