import { id } from 'src/app/core/utils/global.functions';
export class addWarranty{
    public customer:any;
    public title:string;
    public unique_number:string;
    public tenant_customer:any;
    public tenant_customer_contact:number;
    public tenant_customer_location:any;
    public starting_date:string=undefined;
    public ending_date:string=undefined;
    public terms_and_condition:number;
    public conditions:string="";
    public coverage_type:number=0;
    public status:number;
    public coverage_type_selection:number=0;
    public duration:number;
    public suborg:number;
}

export class WarrantyList{
    public created_by:string;
    public created_on:string;
    public created_user:number;
    public customer:number;
    public ending_date:string;
    public id:number;
    public is_active:boolean;
    public is_delete:boolean;
    public modified_on:string;
    public modified_user:string;
    public starting_date:string;
    public tenant_customer:string;
    public tenant_customer_contact_name:string;
    public tenant_customer_location:string;
    public tenant_customer_location_name:string;
    public tenant_customer_name:string;
    public terms_and_condition:string;
    public title:string;
    public unique_number:string;
    public conditions:string;
    public warranty_status:string;
    public status:number;
    public expired_in_90_days:boolean;
    public coverage_type:number;
    public coverage_type_selection:number;
    public duration:number;

}


export class WarrantyItemAdd{
    public warranty:number;
    public associate_item:number;
    public customer:number;
    public quantity:number;
    public serial_number:number;
    public id:number;
}

export class WarrantyKitsAdd{
    public warranty:number;
    public associate_kit:number;
    public customer:number;
    public quantity:number;
    public serial_number:number;
    public id:number;
}


export class WarrantyItemsList{
    associate_item:number;
    created_on:Date;
    created_user:number;
    customer:number;
    ending_date:Date;
    id:number;
    image_link:string;
    is_active:boolean;
    is_delete:boolean;
    item_name:string;
    item_price:number;
    modified_on:string;
    modified_user:string;
    part_number:string;
    starting_date:Date;
    warranty:number;
    quantity:number;
    serial_number:string;
    coverage_type:number;
}

export class WarrantyKitsList{
    associate_kit:number;
    created_on:Date;
    created_user:number;
    customer:number;
    ending_date:Date;
    id:number;
    internal_number:string;
    is_active:boolean;
    is_delete:boolean;
    kit_name:string;
    modified_on:string;
    modified_user:string;
    starting_date:Date;
    warranty:number;
    quantity:number;
    serial_number:string;
    coverage_type:number;
}

export interface WarrantyDashBoardCounts{
    Pending: number;
    Active: number;
    Expired: number;
    Draft: number;
    Expired_in_90_days: number;
}

export enum WarrantyStatus{
    Pending = 1,
    Active = 2, 
    Expired = 3, 
    Draft = 4, 
    Expired_in_90_days = 5
}


export class WarrantyComments {

    public id: number;

    public warranty: number;

    public comments: string;

    public parent: number;

    public customer: number;

    public created_by: string;

    public created_on: Date;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public is_delete: boolean;

    public is_active: boolean;

    edit: boolean;

    inputRows: number = 1;
}

export class WarrantyItemRow {

    public id: number;

    public item_id: number;

    public itemType: WarrantyItemsType;

    public quantity: number = 1;

    public kit_id: number;

    public part_number: string;

    public sell_price: number = 0;

    public sell_price_calc: number = 0;

    public image_link: string;

    public item_name: string;

    public guid: string;

    public customer: number;

    public children: WarrantyItemRow[];

    public expanded:boolean = false;

    public order:number;

    public serial_number:string;

    public coverage_type:number=0;

    public is_delete:boolean;

    constructor(){
        this.guid = id();
    }
}

export enum WarrantyItemsType {
    ITEM = 'item',
    KIT = 'kit'
}