<div class="message-container" *ngIf="history">
    <div class="transaction-desc m-0" *ngIf="!history.changes||!history.changes.length else changesGrid">
       <div *ngIf="history.render_type==1">{{history.created_by}} {{history.message}}</div>
       <div *ngIf="history.render_type==2" [innerHTML]="history.message | replace : '&nbsp;' : '' "></div>
    </div>
    <ng-template #changesGrid>
        <div class="transaction-desc m-0" *ngIf="history.changes.length === 1">
            <div>{{history.created_by}} {{history.message}}</div>
            <div *ngIf="!history.changes[0].message_source">
                <div *ngIf="!history.changes[0].old_value">
                    <span class="field-name">{{history.changes[0].name}}</span> to
                    <span class="new-value">{{history.changes[0].new_value_display_name || history.changes[0].new_value}}</span>
                </div>
                <div *ngIf="history.changes[0].old_value">
                    <span class="field-name">{{history.changes[0].name}}</span> from
                    <span class="old-value">{{history.changes[0].old_value_display_name || history.changes[0].old_value}}</span> to
                    <span class="new-value">{{history.changes[0].new_value_display_name || history.changes[0].new_value}}</span>
                </div>
            </div>
        </div>
        <div class="transaction-desc m-0" *ngIf="history.changes.length > 1">
            <div class="pb-1">{{history.created_by}} {{history.message}}</div>
            <table class="table field-changes-table">
                <thead>
                    <th>Name</th>
                    <th>Previous Value</th>
                    <th>Current Value</th>
                </thead>
                <tbody>
                    <tr *ngFor="let change of history.changes">
                        <td>{{change.name}}</td>
                        <td>{{change.old_value_display_name || change.old_value}}</td>
                        <td>{{change.new_value_display_name || change.new_value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-template>
</div>
