
export class TenantCustomer {

  public id: number;

  public name: string;

  public account_number: string;

  public customer_unique_id: string;

  public customer_type: number;

  public customer_system_type: number;

  public industry: number;

  public account_rep: number[];

  public currency: number;

  public payment_terms: number;

  public comments: number;

  public additional_fields: any;

  public created_user:number;

  public created_on: Date;

  public modified_on: Date;

  public modified_by: string;

  public logo: number;

  public is_delete: boolean;

  public is_active: boolean;

  public customer: number;

  public suborg:number;

  public taxable: boolean = true;

  public tax: number;

  public tax_percentage:number;

  public is_service_ticket_billable: boolean;

  public shipment_methods:ShipmentMethods[]=[];

  public shipping_method:number;

  public shipping_method_account_number:string;

  public shipping_method_name:string;

  public credit_limit:number;

  public credit_balance:number;

  public credit_enforced:boolean;

  public website:string;

  //resolved



  customer_type_name: string;

  customer_system_type_name: string;

  currency_name: string;

  industry_name: string;

  payment_terms_name: string;

  tax_value: string;

  created_user_name:string;

  public is_vip:boolean=false;

  public vip_note:string;

  quickbook_id?:string;


}

export class ShipmentMethods{

  public shipping_method:number;

  public shipment_method_name:string;

  public account_number:string;

  public is_default:boolean;

  public is_delete:boolean;

  public customer:number;

  public id:number;

  // public tenant_customer:number=19;

}

export class CustomerType {

  public id: number;

  public name: string;

  public description: string;

  public created_on: Date;

  public created_by: string;

  public modified_on: Date;

  public modified_by: string;

  public customer: number;

  public suborg: number;

  public is_active: boolean;

  quickbook_id?:string;
}

export class CustomerLocation {

  public id: number;

  public name: string;

  public address: string;

  public address_2: string;

  public city: string;

  public state: string;

  public zip: string;

  public account_number: string;

  public is_billing: boolean;

  public is_shipping: boolean;

  public created_on: Date;

  public created_by: string;

  public modified_on: Date;

  public modified_by: string;

  public tenant_customer: number;

  public service_ticket_billable: boolean;

  public location_types: number;

  public location_system_types: number;

  public customer_system_type: number;

  location_types_name: string;

  location_system_types_name: string;

  public  customer: number;

  public  suborg: number;

  public location_latitude:string;

  public location_longitude:string;
}

export class CustomerContact {

  public id: number;

  public first_name: string;

  public last_name: string;

  public email: string;

  public phone: string;

  public extension: string;

  public mobile: string;

  public fax: string;

  public title: string;

  public department: string;

  public portal_access: boolean;

  public twitter: string;

  public facebook: string;

  public instagram: string;

  public linkedin: string;

  public additional_fields: any;

  public send_welcome_email: boolean;

  public created_on: Date;

  public created_by: string;

  public modified_on: Date;

  public modified_by: string;

  public tenant_customer: number;

  public picture: number;

  public location: number;

  location_name: string;

  bgClass: string;

  public customer: number;

  public suborg: number;

  public role:number=null;

  public user_id:number;

  message?:string;

  tenant_customer_name?:string;

  location_address?:string;

  location_city?:string;

  location_state?:string;
  
  location_zip?:string;

}

export interface CustomerRep {

  rep_id: number;

  rep_name: string;
}

export class QuickContactRequest {

  public tenant_customer_id:number=null;//customer dropdown eg: simply

  public tenant_customer_name:string;

  public customer:number;//eg: workhorse

  public suborg:number;

  public contact_first_name:string;

  public contact_last_name:string=null;

  public contact_phone:string=null;

  public contact_mobile:number;

  public contact_email:number=null;

  public location_id:number=null;

  public location_name:string;

  public location_address:string=null;

  public location_city:string=null;

  public location_state:string=null;

  public location_zip:string=null;

}


export class CustomerRoles{
  
  public id:number;

  public name:string;

  public description:string;

  public customer_id:number;

  public is_active:boolean;

  public created_on:string;

  public created_by:string;

  public modified_on:string;

  public modified_by:string;

  public is_customer_role:boolean;
}