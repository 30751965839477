import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { WINDOW } from 'src/app/core/services/window.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { BlockListRequest, BlockListResponse } from '../../models/block-list.model';
import { BlockListService } from '../../services/block-list.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';

@Component({
  selector: 'app-block-list',
  templateUrl: './block-list.component.html',
  styleUrls: ['./block-list.component.scss']
})
export class BlockListComponent implements OnInit, OnDestroy {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;


  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = [
      { displayName: "All Blocked Numbers", colname: '', condition: 'is', value: null, operator: 'AND' },
      { displayName: "User Blocked Numbers", colname: 'type', condition: 'is', value: 'User_added', operator: 'AND' },
      { displayName: "System Blocked Numbers", colname: 'type', condition: 'is', value: 'System_added', operator: 'AND' },
      { displayName: "Removed from Blocklist", colname: 'is_delete', condition: 'is', value: 'true', operator: 'AND' }
  ];

  public searchFilters: GFilterParam[] = [
      {
          displayName: 'Number or Type', colname: '', condition: '', value: '',
          children: [
              { displayName: 'Phone Number', colname: 'number', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
              { displayName: 'Type', colname: 'type', condition: 'contains', value: '', operator: 'OR' }
          ],
      }
  ];

  public columns: GFColumn[] = [
      { displayName: 'Number', columnName: 'number', columnType: GFColumnTypes.phone },
      {
        displayName: 'Type',
        columnName: 'type',
        columnType: GFColumnTypes.select,
        optionValueType: GFColumnTypes.ref,
        options: [
            { displayName: 'User Added', value: 'User_added' },
            { displayName: 'System Added', value: 'System_added' },
        ]
    },
      { displayName: 'Blocked on', columnName: 'created_on', columnType: GFColumnTypes.date }

  ];

  public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

  public sortLookup = {
      number: 'number',
      name: 'name',
      type: 'type',
      created_on: 'created_on',
      created_by: 'created_by',
      is_unblock: 'is_unblock',
      expiry_time:'expiry_time'
  };

  public SelectionType = SelectionType;

  public model: BlockListResponse;

  public rows: BlockListResponse[]=[];

  public expanded: boolean = true;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public isSuperAdmin: boolean;

  public suborg_id: number;

  public user_id: number;

  private ngbModalRef: NgbModalRef;

  public module = ModuleConstants.RING2VOICE_BLOCK_LIST;

  public UIPermissions: UIPermission;

  public deletedRecords: boolean = false;

  public isGlobalRoute: boolean;

  public enteredNumber: string;

  constructor(private cdr: ChangeDetectorRef,
      private toastr: ToastrService,
      private device: DeviceDetectorService,
      private blockListService: BlockListService,
      private modalService: NgbModal,
      private ra: ResourceAccessService,
      @Inject(WINDOW) private window: Window,
      ) {
      this.isSuperAdmin = blockListService.isSuperUser;
      this.suborg_id = blockListService.suborgId;
      this.user_id = blockListService.userId;
      this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.R2V_BLOCK_LIST, ResourceConstants.R2V_MODULE, true);
      this.addFilterColumns();
  }

  addFilterColumns() {
      if (this.isSuperAdmin) {
          this.columns.push(...[
              { displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref }
          ]);
      }
  }

  ngOnInit(): void {
      this.scrollbarH = this.device.isMobile();
      this.getData();
  }

  getData(filters: GFilterParam[] = null, offset: number = 0) {

    let request = {
        base_filters: [{ colname: 'is_delete', condition: 'is', value: this.deletedRecords.toString(), operator: 'AND' }],
        filters: filters,
        sort: this.sort
    }

    this.subs.sink = this.blockListService.getAll(request, offset, this.page.limit).pipe(
        catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
        this.rows = response.result;
        this.setPagination(offset, response.count);
        this.cdr.markForCheck();
    });
}

  setPagination(offset: number, total: number) {
      this.page.count = total;
      let upperLimit = offset + this.page.limit;
      if (upperLimit > total) {
          upperLimit = total;
      }
      this.page.pageNumber = offset + '-' + upperLimit;
  }

  setPage(pageInfo: any) {
      let offset = pageInfo.offset * this.page.limit;
      this.page.offset = pageInfo.offset;
      const filters = this.getFilters();
      this.getData(filters, offset);
      this.scrollToTop();
  }

  onSort(event: any) {
      if (event.sorts && event.sorts.length > 0) {
          let current = event.sorts[0];
          if (!this.sortLookup[current.prop]) return;
          let sort = new GSortParam();
          sort.colname = this.sortLookup[current.prop];
          sort.direction = current.dir;
          this.sort = [sort];
          const filters = this.getFilters();
          this.getData(filters);
      }
  }

  applyFilter(params: GFilterParam[]) {
      const filters = this.getFilters(params);
      this.getData(filters);
  }

  getFilters(params?: GFilterParam[]) {
      let filters = params || this.dataFilter.getDataFilters();
      let del_filter = filters?.find(fil => fil.colname === 'is_delete');
      this.deletedRecords = del_filter ? del_filter.value : false;
      return filters.filter(fil => fil.colname !== 'is_delete');
  }


  unblockNumber(id: number) {
    let model = this.model
    let opt = {
        title: `Are you sure you want to Unblock?`,
        text: '',
        ButtonText: "Yes",
      }
      popup.ConfirmWithButtonText(opt, result => {
          if (result.isConfirmed) {
              this.blockListService.delete(id).subscribe(
                  resp => {
                      if (resp.status) {
                        this.toastr.success(`Number Unblocked Successfully`);
                        this.applyFilter(null);
                      }
                  },
                  error => {
                      this.toastr.error("Failed to Unblock Phone Number")
                  }
            )};
      });
  }

  open(content: any) {    
    this.ngbModalRef = this.modalService.open(content, { centered: true });
    let blockNumberField = document.getElementById('blockNumberField') as HTMLInputElement;
    if(blockNumberField){
        blockNumberField.focus();
    }
  }

  searchFilterApply() {
    if (this.enteredNumber&&this.enteredNumber.length==10) {
        this.blockNumber();
    }
}

  blockNumber() {
    let payload = new BlockListRequest();
    payload.number = Number(this.enteredNumber);
    payload.created_user = this.user_id;

    let api = this.blockListService.create(payload);

    this.subs.sink = api.subscribe(resp => {
        if (resp.status) {
            this.toastr.success(`Added to Block List`);
            this.ngbModalRef.close();
            this.getData();
        }
        else if(resp.Message){
            this.toastr.info(`Number Already Blocked`);
            this.ngbModalRef.close();
        }
    },
        err => {
            this.toastr.error('Operation Failed');
        });

    this.enteredNumber = '';
  }

  scrollToTop() {
      this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  dismissModal(modal: any) {
      modal.dismiss('closed');
  }

  hasPermissiontoCreate() {
    return this.ra.hasPermission(ResourceConstants.R2V_BLOCK_LIST, [
        PermissionConstants.CREATE
  ]);
}

  hasPermissiontoDelete() {
    return this.ra.hasPermission(ResourceConstants.R2V_BLOCK_LIST, [
        PermissionConstants.DELETE
  ]);
}

  ngOnDestroy(): void { this.subs.unsubscribe(); }
}
