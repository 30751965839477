
    <ng-container>
        <div class="card-content call-history">
            <div [ngClass]="{'card-body':expanded}">

                <ngx-datatable #dataTable
                               [rows]="[callHistory]" class="bootstrap core-bootstrap"
                               [columnMode]="'force'"
                               [footerHeight]="0"
                               [headerHeight]="0"
                               rowHeight="auto">


                    <ngx-datatable-row-detail [rowHeight]="75" #myDetailRow>
                    <ng-template let-row="row" let-value="value" ngx-datatable-row-detail-template>
                    <div class="row row-detail">

                        <div class="row-item" [ngClass]="expanded?'col-md-1':'col-md-4'"  style="margin-right: 25px;">

                            <div>
                                {{ row.date_created | dateTz:'MM/DD/YYYY h:mm a' }}
                            </div>
                        </div>

                        <div class="col-md-2 row-item" *ngIf="expanded">
                        <div  class="contact">

                                <div class="avatar mr-1 ml-0" [class]="randomDpBackground(row)">
                                    <div class="avatar-content">{{ row.customer_contact_name | shortName}}
                                    </div>
                                </div>


                            <div>
                            <span class="contact-name">{{row.customer_contact_name?row.customer_contact_name:'Unknown'}}</span>
                            <div *ngIf="row.customer_contact_title">{{row.customer_contact_title?row.customer_contact_title:'Unknown'}}</div>
                            </div>

                        </div>

                        </div>

                        <div class=" row-item" [ngClass]="expanded?'col-md-3':'col-md-7'">
                            <div class="d-flex flex-row">
                                <span class="number-label">From</span>
                                <span>{{row.call_from | phoneNumber}}

                                </span>
                            </div>
                            <div class="d-flex flex-row">
                                <span class="number-label">To</span>
                                <span>{{row.call_to | phoneNumber}}</span>
                            </div>
                            <div class="d-flex flex-row">
                                <span class="number-label">Name</span>
                                <span>{{row.phone_number_name}}</span>
                            </div>
                        </div>

                        <div class="col-md-2 row-item" *ngIf="expanded">
                            <div>Duration:{{row.duration}}</div>

                        </div>

                        <div class="col-md-2 row-item" *ngIf="expanded">
                            <div>Route:</div>
                            <div>{{row.forward_number_name}}</div>
                            <div class="bold" *ngIf="row.forward_number">
                                <span *ngFor="let num of row.forward_number.split(',') as numList; let i = index">
                                    <span>{{num | phoneNumber}}</span>
                                    <span *ngIf="i !== numList.length-1">, </span>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-1 row-item" *ngIf="expanded">

                            <div class="badge"
                                 [ngClass]="{'bg-light-success': row.real_status=='completed','bg-light-danger': row.real_status=='no-answer' }">
                                {{ row.real_status }}
                            </div>
                            <div class="badge bg-secondary justify-content-around d-flex mt-1" placement="left" *ngIf="row.is_voicemail">
                                Voicemail
                            </div>
                            <div placement="left" [ngbTooltip]="row.spam_reason?row.spam_reason:''"
                                class="badge bg-light-danger justify-content-around d-flex mt-1" *ngIf="row.is_spam">
                                Spam
                            </div>

                        </div>



                    </div>
                    </ng-template>
                    </ngx-datatable-row-detail>

                    <ngx-datatable-column  name="Direction" [sortable]="false" [minWidth]="145" [maxWidth]="125">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div style="align-items: center; display: flex;"><i  aria-hidden="true"><img src="{{row.direction == 'inbound' ? '../../../../assets/img/svg/incomingcall.svg':'../../../../assets/img/svg/outgoingcall.svg' }}" alt=""></i>&nbsp;{{row.direction}}</div>

                    </ng-template>
                    </ngx-datatable-column>


                    <ngx-datatable-column [sortable]="false"  name="Customer" prop="customer_name" [minWidth]="100" *ngIf="expanded">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="customer-name">
                            <label>Customer:</label>
                            <div class="badge badge-warning name-badge w-100">{{row.tenant_customer_name?row.tenant_customer_name:'Unknown'}}</div>
                        </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="false"  name="Answered By" prop="answered_by" [minWidth]="115">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="flexColDiv">
                            <label>Answered By:</label>
                            <div>{{row.answered || '--'}}</div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column *ngIf="expanded" name="Recording" prop="recording_url_list"
                                          [sortable]="false" [minWidth]="240" [canAutoResize]="false">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div *ngIf="row.recording_url_list">
                                <ng-container *ngFor="let reccording of row.recording_url_list;let i = index">
                                <audio *ngIf="reccording && i == currentAudio" controls class="audio-control" >
                                <source [src]="reccording" type="audio/wav">
                                not supported
                                </audio>
                               </ng-container>

                               <div class="next-prev" style="text-align: center;" *ngIf="row.recording_url_list.length>1">
                                <a class="mr-2" (click)="currentAudio > 0 && changeAudio('prev')" ><i class="ft-rewind"></i></a>
                                <small class="dot ml-1 mr-1" >
                                    <b>{{currentAudio+1}}</b> / {{row.recording_url_list.length}}
                                 </small>
                                <a class="ml-2" (click)="currentAudio < row.recording_url_list.length-1 && changeAudio('next')" ><i class="ft-fast-forward"></i></a>

                               </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="false" *ngIf="expanded && !isSuperAdmin" name="Suborg" prop="suborg_name" [minWidth]="100">
                        <ng-template let-suborg="value" ngx-datatable-cell-template>

                            <div>
                                <div>{{suborg}}</div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <!-- <ngx-datatable-column *ngIf="expanded" name="Actions"  [sortable]="false" [minWidth]="90">
                        <ng-template let-row="row" ngx-datatable-cell-template >
                          <a  (click)="$event.stopPropagation();readComments(row.sid)" class="text-info">{{row.comments >0?'Read':'Add'}} Comments</a>
                        </ng-template>
                    </ngx-datatable-column> -->
                    <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg"
                                          [sortable]="false">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div>{{row.customer_name}}</div>
                            <div>Sub org : {{row.suborg_name}}</div>
                        </ng-template>
                    </ngx-datatable-column>


                </ngx-datatable>
            </div>
        </div>

        <ng-template #commentsModal let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h5 class="modal-title">Comments</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="max-width: 500px;">
                    <!-- <app-comments [uniqueId]="sIdForComment" [moduleId]="22"></app-comments> -->
                </div>
            </div>
        </ng-template>
    </ng-container>

