<section *ngIf="model">
    <form #stateTaxForm="ngForm" (ngSubmit)="stateTaxForm.form.valid && onSubmit()">
        <div class="form-group">
            <label class="val-required">Name</label>
            <input type="text" [(ngModel)]="model.state_name" #sn="ngModel" name="state_name" class="form-control"
                   required>
            <small class="form-text text-muted danger" *ngIf="sn.invalid && (sn.dirty || sn.touched)">
                <div *ngIf="sn.errors.required">
                    Name is required.
                </div>
            </small>
            <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
        </div>
        <div class="form-group">
            <label class="val-required">Tax (%)</label>
            <input type="number" [(ngModel)]="model.tax" #tax="ngModel" name="tax" class="form-control" required />
        </div>
        <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!stateTaxForm.form.valid"
                    class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                {{stateId > 0 ? 'Save Changes' : 'Save'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</section>
