import { ReasonForCall } from '../../../service-ticket/models/common.models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, of, Subject } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { CallReasonService } from '../../../service-ticket/services/call-reason.service';
import { filter, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'call-reason-select',
  templateUrl: './call-reason-select.component.html',
  styleUrls: ['./call-reason-select.component.scss']
})
export class CallReasonSelectComponent implements OnInit {

  private _value: number;

  get value(): number {
    return this._value;
  }

  @Input() set value(val: number) {
    this._value = val;
    if (val > 0 && this.selected !== val) {
      this.setValue(val);
    }

    if(val==null){
      this.selected=null;
    }


  };

  @Input() suborg_id: number;

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<ReasonForCall>();

  @Input() addButton: boolean = true;

  @Input() fetchWhenOpen: boolean = false;

  @Input() readonly:boolean=false;

  @Input() size:string='sm';

  @Output() clear= new EventEmitter<any>();

  private modalRef: NgbModalRef;

  public options: Observable<ReasonForCall[]>;

  public placeholder: string;

  public totalCount: number = 0;

  public currentCount: number = 0;

  private _selected: number;

  public get selected(): number {
    return this._selected;
  }

  public set selected(value: number) {
    this._selected = value;
    this.valueChange?.emit(value);
  }

  public textInput = new Subject<string>();

  public initial: ReasonForCall;

  public firstOpen: boolean = true;

  constructor(private service: CallReasonService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    this.placeholder = this.hasPermission() && this.addButton ? "Select or add Reason for Service" : "Select Reason for Service";
    if (!this.fetchWhenOpen) {
      this.loadInitial();
    }
  }

  loadInitial() {
    this.options = concat(this.getData(), this.textInput.pipe(
      filter(res => res !== null),
      distinctUntilChanged(),
      switchMap(term => this.getData(term))
    ));
  }

  getData(search?: string) {
    let option = {
      sort: [{ colname: 'reason_for_call_name', direction: 'asc' }],
      filters: search ? [{ colname: 'reason_for_call_name', condition: 'contains', value: search, operator: 'AND' }] : null,
      parent_filters: [{ colname: 'is_global', condition: 'is', value: "true", operator: 'AND' }]
    };
    return this.service.getFiltered(option,0,this.suborg_id,-1).pipe(
      map(resp => {
        if (this.initial && this.initial.id === this.selected) {
          resp.result = resp.result.filter(item => item.id !== this.selected).concat(this.initial);
        }
        this.totalCount = resp.count;
        this.currentCount = resp.result.length;
        return resp.result;
      })
    );
  }

  setValue(id: number) {
    this.options = this.service.getById(id).pipe(map(response => {
      this.initial = response;
      return [response];
    }));
    this._selected = id;
  }

  selectOpen() {
    if (this.fetchWhenOpen && (!this.options || this.firstOpen)) {
      this.loadInitial();
      this.firstOpen = false;
    }
  }

  trackByFn(item: ReasonForCall) { return item.id; }

  openModal(content: any): void { this.modalRef = this.modalService.open(content, { centered: true }); }

  onChange(event){
    this.change?.emit(event);
  }

  onClear(){
    this.loadInitial();
  }

  onSearch(){
    this._selected = 0;
  }

  onDataSubmittedSuccess(result: { response: ReasonForCall, message: string }): void {
    this.toastr.success(result.message);
    this.options = concat(this.options, of([result.response]));
    this.selected = result.response.id;
    //this.modalService.dismissAll();
    this.modalRef?.dismiss();
    this.change?.emit(result.response);
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    //this.modalService.dismissAll();
    this.modalRef?.dismiss();
  }

  onCancel(): void { this.modalRef?.dismiss();
    //this.modalService.dismissAll();
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.ST_REASONS_FOR_SERVICE, [PermissionConstants.CREATE, PermissionConstants.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.SERVICE_TICKET_MODULE, [PermissionConstants.GLOBAL]);
  }

  reset(){
    this.clear.emit();
  }
}
