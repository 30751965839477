import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { InvoiceComponent } from '../sales-shared/sales-invoice/invoice/invoice.component';
import { SalesRoutingModule } from './sales-routing.module';
import { ProposalComponent } from './pages/proposal/proposal.component';
//import { WarrantyComponent } from './pages/warranty/warranty.component';
//import { ServiceContractComponent } from './pages/service-contract/service-contract.component';
import { InvoiceAddComponent } from './pages/invoice-add/invoice-add.component';
import { WarrantyAddComponent } from './pages/warranty-add/warranty-add.component';
import { WarrantyListComponent } from './pages/warranty-list/warranty-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { TermsComponent } from './pages/terms/terms.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { TermsAddComponent } from './pages/terms-add/terms-add.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TenantCustomersModule } from '../tenant-customers/tenant-customers.module';
import { ItemSelectComponent } from '../inventory/components/select/item-select/item-select.component';
import { KitSelectComponent } from '../inventory/components/select/kit-select/kit-select.component';
import { SalesWarrantyItemsComponent } from './components/warranty-items/warranty-items.component';
import { InventoryModule } from '../inventory/inventory.module';
//import { WarrantyCommentsComponent } from './components/warranty-comments/warranty-comments.component';
import { UserModule } from '../user/user.module';
import { SerialNumberSelectComponent } from './components/select/serial-number-select/serial-number-select.component';
import { TermsSelectComponent } from './components/select/terms-select/terms-select.component';
import { TermsFormComponent } from './components/terms-form/terms-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ServiceContractListComponent } from './pages/service-contract-list/service-contract-list.component';
import { ServiceContractAddComponent } from './pages/service-contract-add/service-contract-add.component';
//import { ScCommentsComponent } from './components/sc-comments/sc-comments.component';
import { ScItemsComponent } from './components/sc-items/sc-items.component';
import { WarrantySelectComponent } from './components/select/warranty-select/warranty-select.component';
import { SalesService } from './services/sales.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
//import { SalesOrdersComponent } from './pages/sales-orders/sales-orders.component';
import { SalesOrdersAddComponent } from './pages/sales-orders-add/sales-orders-add.component';
import { SoItemsComponent } from './components/so-items/so-items.component';
import { SoCommentsComponent } from './components/so-comments/so-comments.component';
import { SalesOrdersListComponent } from './pages/sales-orders-list/sales-orders-list.component';
import { InvoiceListComponent } from './pages/invoice-list/invoice-list.component';
import { InvoiceItemsComponent } from './components/invoice-items/invoice-items.component';
import { CommentsComponent } from 'src/app/shared/components/comments/comments.component';
import { ServiceTicketModule } from '../service-ticket/service-ticket.module';
import { QuillModule } from 'ngx-quill';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CatalogCustomerViewComponent } from './pages/catalog-customer-view/catalog-customer-view/catalog-customer-view.component';
import { CustomerCatalogListComponent } from './pages/catalog-customer-view/customer-catalog-list/customer-catalog-list.component';
import { SideCartViewComponent } from './components/side-cart-view/side-cart-view.component';
import { CartViewComponent } from './pages/cart-view/cart-view.component';
import { SideSectionViewComponent } from './components/side-section-index-view/side-section-index-view.component';
import { SectionThumbViewComponent } from './components/section-thumb-view/section-thumb-view.component';
import { ProductOrderPreviewComponent } from './components/product-order-preview/product-order-preview.component';
import { InvoiceDashboardComponent } from './pages/invoice-dashboard/invoice-dashboard.component';
import { SalesOrdersDashboardComponent } from './pages/sales-orders-dashboard/sales-orders-dashboard.component';
import { TenantCustomerInfoModule } from '../tenant-customer-info/tenant-customer-info.module';
//import { SoMaterialsGridComponent } from './components/so-materials-grid/so-materials-grid.component';
import { SalesSharedModule } from '../sales-shared/sales-shared.module';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { ServiceTicketSelectModule } from '../service-ticket-select/service-ticket-select.module';
@NgModule({
  declarations: [
    ProposalComponent,
   // WarrantyComponent,
    //ServiceContractComponent,
    InvoiceAddComponent,
    WarrantyAddComponent,
    WarrantyListComponent,
    TermsComponent,
    NotificationComponent,
    TermsAddComponent,
    SalesWarrantyItemsComponent,
    //WarrantyCommentsComponent,
    SerialNumberSelectComponent,
    TermsSelectComponent,
    TermsFormComponent,
    ServiceContractListComponent,
    ServiceContractAddComponent,
   // ScCommentsComponent,
    ScItemsComponent,
    WarrantySelectComponent,
    //SalesOrdersComponent,
    SalesOrdersAddComponent,
    SoItemsComponent,
    SoCommentsComponent,
    SalesOrdersListComponent,
    InvoiceListComponent,
    InvoiceItemsComponent,
    CatalogCustomerViewComponent,
    CustomerCatalogListComponent,
    SideCartViewComponent,
    CartViewComponent,
    SideSectionViewComponent,
    SectionThumbViewComponent,
    ProductOrderPreviewComponent,
    InvoiceDashboardComponent,
    SalesOrdersDashboardComponent,
    //SoMaterialsGridComponent
  ],
  imports: [
    CommonModule,
    SalesRoutingModule,
    NgxDatatableModule,
    SharedModule,
    NgbModule,
    TenantCustomersModule,
    InventoryModule,
    UserModule,
    NgSelectModule,
    ServiceTicketModule,
    QuillModule.forRoot(),
    NgxSummernoteModule,
    FormsModule,
    TenantCustomerInfoModule,
    SalesSharedModule,
    DragDropModule,
    MatIconModule,
    ServiceTicketSelectModule
  ],
  exports:[TermsSelectComponent,
    SoItemsComponent,
    SerialNumberSelectComponent,
    SalesOrdersAddComponent
    ],
  providers:    [ CurrencyPipe ]
})
export class SalesModule { }
