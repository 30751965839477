import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: '[filterControl]' })
export class GridFilterControlDirective {

  @Input() column: string;

  

  constructor(public template: TemplateRef<any>) { }
}
