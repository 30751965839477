import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { WarehouseService } from 'src/app/modules/inventory/services/warehouse.service';
import { SubSink } from 'subsink';
import { SCComments } from '../../../sales/models/service-contract.models';

import { ServiceContractService } from '../../../sales/services/service-contract.service';

@Component({
  selector: 'sc-comments',
  templateUrl: './sc-comments.component.html',
  styleUrls: ['./sc-comments.component.scss']
})
export class ScCommentsComponent implements OnInit {

  @Input() scId: number=0;


  @Input() timelineView: boolean = true;

  get _scId() { return this.scId; }

  public comments: SCComments[];

  public model: SCComments = new SCComments();

  public userName: string;

  public rows: number = 1;

  public currentUser: number;

  public isAdmin: boolean;

  private subs: SubSink = new SubSink();

  constructor(private SCService: ServiceContractService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) {

    this.currentUser = SCService.userId;
    this.isAdmin = SCService.isAdmin;
  }

  ngOnInit() {
    if(this.scId>0){
      this.getComments(this.scId);
    }
    let userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
    if (userAuth) {
      this.userName = `${userAuth.firstname || ''} ${userAuth.lastname || ''}`;
    }
    if (!this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  getComments(scId: number) {
    if (scId > 0) {
      this.subs.sink = this.SCService.getComments(scId).subscribe(resp => {
        this.comments = resp;
        this.cdr.markForCheck();
      });
    }
    else {
      this.comments = [];
    }
  }

  headerInputFocus() {
    if (this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  createComment() {

    if(!this.model.comments) return;

    this.model.service_contract = this.scId;

    this.model.customer = this.SCService.customerId;
    this.model.inputRows=undefined;
    this.subs.sink = this.SCService.createComment([this.model], this.scId).subscribe(
      resp => {
        this.comments = [...resp, ...this.comments];
        this.model.inputRows = 1;
        this.model.comments = '';
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to save comment')
    );
  }

  updateComment(comment: SCComments, value: string) {

    if(!value) return;

    let com = { ...comment };
    com.comments = value;
    this.subs.sink = this.SCService.updateComment(comment.id, com).subscribe(
      () => {
        comment.comments = value;
        comment.edit = false;
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to modify comment')
    );
  }

  deleteComment(commentid: number) {
    this.subs.sink = this.SCService.deleteComment(commentid).subscribe(
      () => {
        this.comments = this.comments.filter(c => c.id !== commentid);
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to delete comment')
    );
  }

  public saveComment(scId: number, next?: (value: any) => any) {
    if (!this.model.comments && next) {
      next(null);
    }
    else {
      this.model.service_contract = scId;
      this.model.customer = this.SCService.customerId;
      this.subs.sink = this.SCService.createComment([this.model], this.scId).pipe(
        catchError(() => [])
      ).subscribe(resp => {
        if (next) { next(resp) }
      });
    }
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }

}
