<section *ngIf="model">
    <form #categoryForm="ngForm" (ngSubmit)="categoryForm.form.valid && onSubmit()">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">Name</label>
                    <input type="text" [(ngModel)]="model.category_name" #name="ngModel" name="name" class="form-control" required>
                    <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                      <div *ngIf="name.errors.required">Name is required.</div>
                    </small>
                </div>
                <div class="form-group">
                    <label>Parent</label>
                    <category-select [(value)]="model.parent" (change)="onCategoryChanged($event)" (clear)="onParentSelectClear()"></category-select>
                </div>
                <div class="form-group">
                    <label class="val-required">Prefix</label>
                    <input type="text" placeholder="000-000..." [(ngModel)]="model.category_prefix" #pnum="ngModel" name="category_prefix" class="form-control" required>
                    <small class="form-text text-muted danger" *ngIf="pnum.invalid && (pnum.dirty || pnum.touched)">
                        <div *ngIf="pnum.errors.required">Prefix is required.</div>
                    </small>
                </div>
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [(ngModel)]="model.create_partnumber" name="create_partnumber" id="custom-check-1">
                        <label class="custom-control-label" for="custom-check-1">
                            <span>Create Part Number for this category</span>
                        </label>
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <div class="col-6 p-0 pr-1 form-group">
                        <label [class.val-required]="model.create_partnumber">Start Range</label>
                        <input type="number" min="0" [(ngModel)]="model.start_range" #sr="ngModel" name="start_range" class="form-control" [required]="model.create_partnumber" [disabled]="!model.create_partnumber">
                        <div class="info-label mt-1" *ngIf="model.create_partnumber" [class.danger]="model.start_range && model.start_range < 0">
                            Must be 0 or greater.
                        </div>
                    </div>
                    <div class="col-6 p-0 pl-1 form-group">
                        <label [class.val-required]="model.create_partnumber">End Range</label>
                        <input type="number" min="-1" [(ngModel)]="model.end_range" #er="ngModel" name="end_range" class="form-control" [required]="model.create_partnumber" [disabled]="!model.create_partnumber">
                        <div class="info-label mt-1" *ngIf="model.create_partnumber" [class.danger]="model.end_range && model.end_range != -1 && model.end_range < model.start_range">
                            Must be -1 or greater than start range.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label [class.val-required]="model.create_partnumber">Range Digits</label>
                    <input type="number" min="1" [(ngModel)]="model.digits" #rd="ngModel" name="digits" class="form-control" [required]="model.create_partnumber" [disabled]="!model.create_partnumber">
                    <div class="info-label mt-1" *ngIf="model.create_partnumber" [class.danger]="model.digits && model.digits <= 0">
                        Must be greater than 0.
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>Description</label>
                    <textarea type="text" [(ngModel)]="model.description" name="description" class="form-control"></textarea>
                </div>
                <div class="form-group">
                    <div class="status-toggle-btn">
                        <label class="mr-1 pt-1">Show On Catelog :</label>
                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                            <input type="checkbox" [(ngModel)]="model.show_on_catelog" name="show_on_catelog"
                                class="custom-control-input"  id="show_on_catelog"
                                >
                            <label class="custom-control-label" for="show_on_catelog">
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="additional-fields-container">
                    <div class="af-body">
                        <div class="af-header d-flex align-items-center">
                            <label>Additional Fields</label>
                            <!-- <button type="button" (click)="addCustomField()" class="btn btn-sm btn-secondary">Add</button> -->
                        </div>
                        <!-- {{catCustomField|json}} -->
                        <table class="af-content w-100" *ngIf="catCustomField.additional_data && catCustomField.additional_data.length > 0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            
                            <tbody >
                                <tr  *ngFor="let customField of catCustomField.additional_data;let index=index" [hidden]="customField.action==3">

                                        <!-- <cat-additional-field-add *ngIf="customField.additional_field||customField.type==1" [hidden]="customField.action==3" [customField]="customField" [index]="index"></cat-additional-field-add> -->
                                <!-- + {{customField.additional_field|json}} -->
                                        <td *ngIf="customField.additional_field||customField.type==1">

                                            <!-- {{customField.additional_field}} -->
                                            <additional-field-select
                                            [afList]="additionalFieldz"
                                            [value]="customField.additional_field"
                                            [addButton]="true"
                                            (valueChange)="additionalFieldSelected($event, index)"
                                            (onAFListChange)="onAFListChange($event)"
                                            ></additional-field-select>
                                    
                                            
                                        </td>
                                        <td *ngIf="customField.additional_field||customField.type==1">
                                            <ng-select 
                                                placeholder="Select value" 
                                                [items]="customField.category_value_list" 
                                               
                                                [name]="'value'+index" 
                                                [(ngModel)]="customField.value" 
                                                bindLabel="field_value" 
                                                bindValue="id">
                                            </ng-select>
                                        </td>

                                        <td *ngIf="customField.custom_additional_field||customField.type==2" [hidden]="customField.action==3">
                                            <input type="text" 
                                            placeholder="Additional field name" 
                                            [(ngModel)]="customField.custom_additional_field"
                                            [name]="'custom_additional_field'+index"
                                            class="form-control" />
                            
                                        </td>
                                        <td *ngIf="customField.custom_additional_field||customField.type==2" [hidden]="customField.action==3">
                                            <input type="text"
                                            placeholder="Additional field value" 
                                            [(ngModel)]="customField.custom_additional_field_value"
                                            [name]="'custom_additional_field_value'+index"
                                            class="form-control" />
                                        </td>
                                        <td>
                                            <button class="btn-borderless danger" type="button" (click)="deleteCustomField(index)">
                                                <i class="ft-trash-2"></i>
                                            </button>
                                        </td>

                                   
                                </tr>
                            </tbody>
                      
                        </table>
 

                        <div class="mt-2">
                            <a class="text-info font-small-3" (click)="addCustomField(1)"><i class="ft-plus"></i> Add from list</a> 
                            <a class="text-info font-small-3 ml-2" (click)="addCustomField(2)"><i class="ft-plus"></i> Add custom</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!categoryForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
              {{categoryId > 0 ? 'Save Changes' : 'Save'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</section>
