<div class="dt-container mt-2">
    <ngx-datatable #dataTable
                   class="bootstrap core-bootstrap"
                   [columnMode]="'force'"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [rowHeight]="50"
                   [rows]="rows"
                   [count]="page.count"
                   [externalPaging]="true"
                   [offset]="page.offset"
                   [externalSorting]="false"
                   [limit]="page.limit"
                   >
        <ngx-datatable-column name="Updated On" prop="updatedAt" [sortable]="false" >
            <ng-template let-date="value" ngx-datatable-cell-template>
                {{date | datetimeTz}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status change" prop="userStatus" [sortable]="false"  [cellClass]="'status-cell'">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex justify-content-around">
                    <div class="badge" [ngClass]="{'agentOnline': row.oldStatus=='Ready', 'agentOffline':row.oldStatus=='Offline','agentBusy': row.oldStatus=='Busy'}">
                        {{row.oldStatus}}
                    </div>
                    <i class="ft-arrow-right"></i>
                    <div class="badge" [ngClass]="{'agentOnline': row.userStatus=='Ready', 'agentOffline':row.userStatus=='Offline','agentBusy': row.userStatus=='Busy'}">
                        {{row.userStatus}}
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column name="Current Status" prop="userStatus">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div [ngClass]="{'agentOnline': row.userStatus=='Ready', 'agentOffline':row.userStatus=='Offline','agentBusy': row.userStatus=='Busy'}">{{row.userStatus}}</div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Old Status" prop="oldStatus">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div [ngClass]="{'agentOnline': row.oldStatus=='Ready', 'agentOffline':row.oldStatus=='Offline','agentBusy': row.oldStatus=='Busy'}">{{row.oldStatus}}</div>
            </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column name="Action" prop="reason" [sortable]="false" ></ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                         let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                <div class="page-count">
                    <span *ngIf="rowCount==page.limit">Last {{rowCount}} records </span>
                    <span *ngIf="rowCount!=page.limit">{{rowCount}} records </span>
                </div>
                <!-- <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                 [pagerRightArrowIcon]="'datatable-icon-right'"
                                 [pagerPreviousIcon]="'datatable-icon-prev'"
                                 [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                 [size]="pageSize" [count]="rowCount"
                                 [hidden]="!((rowCount / pageSize) > 1)"
                                 (change)="dataTable.onFooterPage($event)">
                </datatable-pager> -->
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>
