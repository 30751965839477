<section *ngIf="model">
    <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && onSubmit()" novalidate>
        <div class="row">
            <div class="col-md-6" *ngIf="isSuperAdmin">
                <div class="form-group">
                    <label>Tenant</label>
                    <app-customer-select [(value)]="model.customer" [readonly]="sid && sid.length > 0"></app-customer-select>
                </div>
            </div>
            <div class="col-md-6" *ngIf="isSuperAdmin || allSuborgs">
                <div class="form-group">
                    <label class="val-required">Suborg</label>
                    <suborg-select [tenantId]="model.customer" [readonly]="!model.customer || (sid && sid.length > 0) || suborg_id > 0" [(value)]="model.sub_org">
                    </suborg-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="val-required">Incoming Routes name</label>
                    <input type="text" name="forwardbin_name"  class="form-control" [(ngModel)]="model.name" required>
                    <small class="danger mt-1" *ngIf="model.name && nameError">Name already exists</small>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
              <div class="form-group">

                <div class="d-flex">
                  <p class="badge badge-success">Forward Number(s)</p>&nbsp;
                    <div class="form-group d-flex align-items-center">
                        <div class="custom-switch custom-switch-info custom-control mb-1 mb-xl-0">
                            <input type="checkbox" [(ngModel)]="model.forwardbin_or_sip" name="is_active" class="custom-control-input" id="color-switch-3" checked>        
                            <label class="custom-control-label" for="color-switch-3">
                                <span></span>
                            </label>
                        </div>
                    </div>
                  <p class="badge badge-info">SIP</p>
                </div>
                <div *ngIf="!model.forwardbin_or_sip">
                  <tags-input placeholder="Type number and press Enter"
                    mask="(000) 000-0000"
                    [(value)]="model.forward_number"
                    [stringValue]="true"
                    [insertOnLengthMatch]="true">
                  </tags-input>
                </div>
                <div *ngIf="model.forwardbin_or_sip">
                    <app-sip-select (valueChange)="model.sip=$event.join(',')" [value]="model.sip_list" [maxinput]="10" [suborgId]="model.sub_org"></app-sip-select>
                </div>
                <small class="text-secondary mt-1">&nbsp;* Maximum number of selection is 10</small>
              </div>
          </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="row-seperator"></div>
                <div class="form-group">
                    <input type="checkbox" class="form-control" id="whisper" name="whisper" [(ngModel)]="model.whisper" class="pb-5">
                    <label for="whisper" class="" style="vertical-align: middle;"> &nbsp;Whisper </label>
                    <span class="infomsg"> Announce a message to the agent that is answering the call so
                        they can know for which company or for which advertisement campaign the call is
                        coming from. the caller DOES NOT hear this message</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="model.whisper">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">Whisper voice</label>
                    <voice-select [(value)]="model.whisper_gender"></voice-select>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">Whisper message</label>
                    <input type="text" name="whisper_message" class="form-control"
                           [(ngModel)]="model.whisper_message" required>
                </div>
            </div>
        </div>
        <div class="row-seperator"></div>
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="form-group">
                    <input type="checkbox" class="form-control" id="announce" name="announce" [(ngModel)]="model.announce"
                           class="pb-5">
                    <label for="announce" style="vertical-align: middle;">
                        &nbsp;Announce</label> <span class="infomsg"> A message TO CALLER right before it
                        starts ringing. For example: Thank you for calling, this call may be monitored for
                        quality assurance.</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="model.announce">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">ANNOUNCE voice</label>
                    <voice-select [(value)]="model.announce_gender"></voice-select>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">ANNOUNCE MESSAGE</label>
                    <input type="text" name="announce_message" class="form-control"
                           [(ngModel)]="model.announce_message" required>
                </div>
            </div>
        </div>
        <div class="row-seperator"></div>
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="form-group">
                    <input type="checkbox" class="form-control" name="gather" [(ngModel)]="model.gather" class="pb-5"
                           id="gather">
                    <label for="gather" style="vertical-align: middle;"> &nbsp;REQUIRE CALLER
                        INPUT </label> <span class="infomsg"> Require the caller to press a button. This
                        option is mainly used to prevent SPAM callers. A typical message for the caller
                        would be "Thank you for calling our service line, press 1 to be connected with an
                        agent.</span>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="model.gather">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">AUTO ATTENDANT voice</label>
                    <voice-select [(value)]="model.gather_gender"></voice-select>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">HOW MANY LOOPS?</label>
                    <input type="number" name="no_of_loops" [(ngModel)]="model.no_of_loops"
                           class="form-control" min="0" required PreventSpecialCharacters>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">AUTO ATTENDANT</label>
                    <input type="text" name="gather_message" class="form-control"
                           [(ngModel)]="model.gather_message" required>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="val-required">IF CALLER DOES NOT PRESS ANY BUTTON</label>
                    <input type="text" name="not_responding_message"
                           [(ngModel)]="model.not_responding_message" class="form-control" required>
                </div>
            </div>
        </div>
        <div class="d-flex flex-sm-row flex-column justify-content-end mt-3">
            <button type="submit" [disabled]="!addForm.form.valid"
                    class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                {{ sid ? 'Save Changes' : 'Save'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</section>
