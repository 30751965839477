import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { User } from 'src/app/modules/user/models/user';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { ToastrService } from 'ngx-toastr';
import { UserTypes } from 'src/app/core/enums/user-types';
import { SubSink } from 'subsink';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  public userId: number;

  public isSuperUser: boolean;

  public isAdmin: boolean;

  public currentUser: User;

  private subs: SubSink = new SubSink();

  constructor(private toastr: ToastrService,
    private userService: UserService,
    private cdr: ChangeDetectorRef) {

    const uid = localStorage.getItem(AppConstants.USER_ID);
    if (uid) {
      this.userId = parseInt(uid);
    }
    const utype = localStorage.getItem(AppConstants.USER_TYPE);
    this.isSuperUser = utype === UserTypes.SuperUser;
    this.isAdmin = utype === UserTypes.Admin;
  }

  ngOnInit() {
    this.subs.sink = this.userService.getById(this.userId).subscribe(resp => {
      this.currentUser = resp;
      this.cdr.markForCheck();
    });
  }

  onProfileSubmittedSuccess(result: { response?: User, message: string }) {
    this.toastr.success('Success', result.message);
  }

  onProfileDataError(error: any) {
    this.toastr.error('Failed', error.message);
  }
}
