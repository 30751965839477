import { ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";

@Directive({
    selector:'[sfBtnGroup]'
})
export class SFBtnGroupDirective implements OnInit{

    constructor(private renderer:Renderer2, 
        private elementRef:ElementRef){

    }
    ngOnInit(): void {

    }

    ngAfterViewInit(){
        this.renderer.addClass(this.elementRef.nativeElement, 'sf-group-btn');

    }



}


@Directive({
    selector:'[sfBtnGroupItem]'
})
export class SFBtnGroupItemDirective implements OnInit, OnDestroy{


    @Input() icon:string;

    @Input() iconWidth:number=25;

    @Input() iconHeight:number=25;

    @Input() text:string;

    @Input() textColor:string;

    @Input() textHoverColor:string;

    @ViewChild('img') img:ElementRef;

    @ViewChild('span') span:ElementRef;
    
    
    constructor(private elementRef:ElementRef, 
    private cdr:ChangeDetectorRef, 
    private renderer:Renderer2){
        

    }

    ngOnInit(){


    }

    ngAfterViewInit(){
        this.renderer.addClass(this.elementRef.nativeElement, 'sf-group-btn-item');


        let html='';
        if(this.icon){
            html=`<img #img class='button-icon' src='${this.icon}' width='${this.iconWidth}' height='${this.iconHeight}' />`;
        }

        if(this.text){
            html+=` <span class='button-text' #span>${this.text}</span>`;
        }

        // console.log(html, 'inner html')


        this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', html);



        this.elementRef.nativeElement
              .querySelector('.button-icon')
              .setAttribute('style', `width: ${this.iconWidth}px; height: ${this.iconHeight}px;`);

        console.log('width', this.iconWidth);
        console.log('height', this.iconHeight);

        // this.renderer.setStyle(this.img, 'width', this.iconWidth+"px");
    }


    ngOnDestroy(){

    }
    
}