<div class="ti-container">
    <p class="alert alert-warning pull-right"  *ngIf="count == maxLength">! Maximum selection reached</p>
    <div class="ti-inner-container">
        <div class="ti-badges">
            <span *ngFor="let value of inputValues" class="ti-badge">
                <span *ngIf="maskPattern">{{value | mask : maskPattern}}</span>
                <span *ngIf="!maskPattern">{{value}}</span>
                <span class="ft-x ml-1 cursor-pointer" (click)="removeValue(value)"></span>
            </span>
        </div>
        <form #inputForm="ngForm" (ngSubmit)="inputForm.form.valid && submit($event)" class="ti-badge-input" *ngIf="inputValues.length < maxLength" novalidate>

            <input *ngIf="maskPattern" type="text"
                   [placeholder]="placeholder"
                   [mask]="maskPattern"
                   name="ti-input"
                   [(ngModel)]="inputBinding"
                   (ngModelChange)="modelValueChanged($event)"
                   required />

            <input *ngIf="!maskPattern" type="text"
                   [placeholder]="placeholder"
                   name="ti-input"
                   [(ngModel)]="inputBinding"
                   (ngModelChange)="modelValueChanged($event)"
                   required />
        </form>
    </div>
</div>
