<div class="settings-wraper" *ngIf="settings">
    <div class="settings">
      <div class="settings-label">Two Factor Authentication</div>
      <div class="settings-control">
        <div class="custom-switch custom-switch-success custom-control">
          <input type="checkbox" class="custom-control-input" id="tfa-switch"
            [checked]="settings.two_factor_authentication"
            (change)="change2FASettings($event)">
          <label class="custom-control-label" for="tfa-switch">
            <span></span>
          </label>
        </div>
      </div>
    </div>
</div>

