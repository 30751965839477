import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { BaseService } from "src/app/core/services/base.service";
import { TimesheetType } from "../models/timesheet-type.models";

@Injectable({
  providedIn:'root'
})

export class TimesheetTypeService extends BaseService{

    constructor(private http:HttpClient){
        super();
    }

    getAll() {
        return this.http.get<TimesheetType[]>(`${this.baseURL}/dispatch/timesheet_type/`);
    }

    getById(id: number) {
        return this.http.get<TimesheetType>(`${this.baseURL}/dispatch/timesheet_type/${id}/`);
      }

    filter(option: any, offset: number = 0) {
        let url = `${this.baseURL}/dispatch/timesheet_type/filter/?limit=${this.dataLimit}&offset=${offset}`;
        return this.http.post<{ count: number, result: TimesheetType[] }>(url, option).pipe(
          catchError(() => of({ count: 0, result: [] }))
        );
    }
    create(data:TimesheetType){
        return this.http.post(`${this.baseURL}/dispatch/timesheet_type/`, data);
    }

    update(data:TimesheetType){
        return this.http.put(`${this.baseURL}/dispatch/timesheet_type/${data.id}/`, data);
    }

    delete(id:number){
        return this.http.delete(`${this.baseURL}/dispatch/timesheet_type/${id}/`);
    }
}