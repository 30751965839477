import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { CustomerLicense, LicenseNote, LicenseNumber } from '../models/license-number';

@Injectable({
  providedIn: 'root'
})
export class LicenseNumberService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<LicenseNumber[]>(`${this.baseURL}/license_number/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0, limit:number = this.dataLimit) {
    let url = `${this.baseURL}/filter_license_number/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<LicenseNumber[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<LicenseNumber>(`${this.baseURL}/license_number/${id}/`);
  }

  create(model: LicenseNumber) {
    return this.http.post<LicenseNumber>(`${this.baseURL}/license_number/`, model);
  }

  update(id: number, model: LicenseNumber) {
    return this.http.put<LicenseNumber>(`${this.baseURL}/license_number/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<LicenseNumber>(`${this.baseURL}/license_number/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/license_number/${id}/`);
  }

  createComment(comments: LicenseNote) {
    return this.http.post<LicenseNote>(`${this.baseURL}/create_license_notes/`, comments);
  }

  updateComment(id: number, comment: LicenseNote) {
    return this.http.put(`${this.baseURL}/license_notes/${id}/`, comment);
  }

  getComments(licenseId: number) {
    return this.http.get<LicenseNote[]>(`${this.baseURL}/get_license_notes/${licenseId}/`);
  }

  deleteComment(id: number) {
    return this.http.delete(`${this.baseURL}/license_notes/${id}/`);
  }

  getCustomerLicenses(request: DataFilterRequest, offset: number = 0, limit: number = 25) {
    let url = `${this.baseURL}/filter_customer_license/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<CustomerLicense[]>>(url, request);
  }

  getCustomerLicenseById(id: number) {
    return this.http.get<CustomerLicense>(`${this.baseURL}/customer_license/${id}/`);
  }

  createCustomerLicense(models: CustomerLicense[]) {
    return this.http.post<CustomerLicense[]>(`${this.baseURL}/customer_license/`, models);
  }

  updateCustomerLicense(id: number, model: any) {
    return this.http.put<CustomerLicense>(`${this.baseURL}/customer_license/${id}/`, model);
  }

  deleteCustomerLicense(id: number) {
    return this.http.delete(`${this.baseURL}/customer_license/${id}/`);
  }
}
