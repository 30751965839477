import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { concat, Observable, of, Subject } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { DataFilterRequest } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ForwardBin } from '../../models/forward-bin.models';
import { Ring2voiceService } from '../../services/ring2voice.service';

@Component({
    selector: 'forward-bin-select',
    templateUrl: './forward-bin-select.component.html',
    styleUrls: ['./forward-bin-select.component.scss']
})
export class ForwardBinSelectComponent implements OnInit {

    private innerValue: string;

    @ViewChild('content') modalContent: ElementRef;

    @Input() addButton: boolean = false;

    @Input() isGlobal_Route: boolean = false;

    @Input() show_RouteType: boolean = false;

    public rows: ForwardBin[];

    @Input() set value(val: string) {

        this.innerValue = val;

        if (val !== this.selected) {
            this.innerSelected = val;
        }
    }

    get value() { return this.innerValue; }

    @Input() readonly: boolean = false;

    @Output() valueChange = new EventEmitter<string>();

    private suborgid: number;

    get suborgId() { return this.suborgid; }

    @Input() set suborgId(val: number) {

        this.suborgid = val;

        this.innerSelected = undefined;

        if (this.options) {
            this.initialize();
        }
    }

    private innerSelected: string;

    public get selected(): string { return this.innerSelected; }

    public set selected(value: string) {
        this.innerSelected = value;
        this.valueChange?.emit(value);
    }

    public options: Observable<ForwardBin[]>;

    public textInput = new Subject<string>();

    public totalCount: number = 0;

    public currentCount: number = 0;

    public firstOpen: boolean = true;

    private modalRef:NgbModalRef;

    constructor(private service: Ring2voiceService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private ra:ResourceAccessService) { }

    ngOnInit(): void {

        // if (!this.service.isSuperUser && this.service.suborgId !== -1) {
        //     this.suborgId = this.service.suborgId;
        // }

        if (this.selected) {
            this.initialize(this.getSelectedBin(this.selected));
            this.firstOpen = false;
        }
    }

    initialize(selectedValue: Observable<any> = of([])) {
        this.options = concat(
            selectedValue,
            this.getData(),
            this.textInput.pipe(
                filter(res => res !== null && res.length >= 2),
                distinctUntilChanged(),
                switchMap(term => this.getData(term))
            )
        );
    }

    getData(search?: string) {

        let option: DataFilterRequest = {
            sort: [{ colname: 'name', direction: 'asc' }],
            filters: [],
            base_filters: [],
            parent_filters: []
        };

        option.parent_filters.push(...[
            { colname: 'global_route', condition: 'equalto', value: true, operator: 'AND' }
        ]);

        if(!this.isGlobal_Route){
            option.base_filters.push(...[
                { colname: 'suborg', condition: 'equalto', value: this.suborgId, operator: 'AND' },
                { colname: 'global_route', condition: 'equalto', value: false, operator: 'AND' }
            ]);
        }
        if (search) {
            option.filters.push(...[
                { colname: 'forward_number', condition: 'contains', value: search, operator: 'AND' },
                { colname: 'name', condition: 'contains', value: search, operator: 'OR' }
            ]);
        }
        
        let api = this.isGlobal_Route?this.service.getGlobalForwardBins(option):this.service.getForwardBins(option);
        return api.pipe(
            catchError(err => of({ count: 0, result: [] })),
            map(resp => {
                this.totalCount = resp.count;
                this.currentCount = resp.result.length;
                this.rows = resp.result;
                return resp.result;
            })
        );
    }

    trackByFn(item: ForwardBin) { return item.sid; }

    selectOpen() {
        // if (!this.options && this.firstOpen) {
            this.initialize();
            // this.firstOpen = false;
        // }
    }

    getSelectedBin(sid: string) {
        return this.service.getForwardBinById(sid).pipe(map(response => {
            return [response];
        }));
    }

    onClear() {
        this.initialize();
    }

    openModal(content: any): void {
        this.modalRef = this.modalService.open(content, { size: 'lg', scrollable:true });
    }

    onDataSubmittedSuccess(result: { response: ForwardBin, message: string }): void {
        this.toastr.success(result.message);
        let obj = { ...new ForwardBin(), ...result.response };
        this.options = concat(this.options, of([obj]));
        this.selected = obj.sid;
        this.modalRef?.dismiss();
    }

    onDataError(_error: any): void {
        this.toastr.error('Operation failed');
        this.modalRef?.dismiss();
    }

    onCancel(): void {
        this.modalRef?.dismiss();
    }

    hasPermission() {
        return this.ra.hasPermission(
            ResourceConstants.R2V_FORWARD_NUMBERS,
            [PermissionConstants.CREATE],
            ResourceConstants.R2V_MODULE
        );
    }
}
