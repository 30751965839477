<div class="select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" 
    [placeholder]="placeholder" 
    [items]="options | async" 
    bindLabel="name" 
    bindValue="id" 
    [(ngModel)]="selected" 
    [typeahead]="textInput" 
    [trackByFn]="trackByFn" 
    [disabled]="readonly"
    typeToSearchText="Please enter 2 or more characters"
    (open)="selectOpen()"
    (clear)="onClear()"
    (search)="onSearch()"
    >

    <ng-template ng-option-tmp let-item="item">
        {{item.name}} <span *ngIf="item.is_global" class="badge bg-warning">Global</span>
    </ng-template>

        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
             
            </div>
        </ng-template>
    </ng-select>

    
</div>