<div>
    <div class="row p-0 m-0 mb-1">
        <div class="col-2 p-0">
            <img src="assets/img/caller assets/caller_avatar.png" alt width="16">
        </div>
        <div class="col p-0">
            {{callerName(session)}}
        </div>
    </div>
    <div class="row p-0 m-0 mb-1">
        <div class="col-2 p-0">
            <img src="assets/img/caller assets/Incoming cals.svg" alt width="17">
        </div>
        <div class="col p-0">
            {{callerNumber(session)}}
        </div>
    </div>
    <div class="row p-0 m-0 mb-1">
        <div class="col-2 p-0">
            <img src="assets/img/caller assets/timer.png" alt>
        </div>
        <div class="col p-0">
            {{callDuration|async}}
        </div>
    </div>
</div>