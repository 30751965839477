<section class="pay-now" *ngIf="!this.invalidLink">

    <div class="payment-req-form">

        <div style="padding: 35px;">
            <h6 class="mb-3 card-title" style="font-size: 18px;">Payment Details</h6>

            <div class="form-group" style="margin-bottom: 1.5rem">
                <label class="text-muted">Payment for</label>
                <h6 class="mb-2 card-title">{{invoiceDetails?.invoice_data?.suborg__name}}</h6>
            </div>

            <div class="form-group">

                <div class="row">
                    <div class="col-md-7">
                        <label class="text-muted">Invoice Total</label>
                        <h6 class="mb-2 card-title font-weight-bolder">
                            {{invoiceDetails?.invoice_data?.total_price|currency}}</h6>
                    </div>
                    <div class="col-md-5">
                        <label class="text-muted">Balance Due</label>
                        <h6 class="mb-2 card-title font-weight-bolder">
                            {{invoiceDetails?.invoice_data?.balance_due|currency}}</h6>
                    </div>
                </div>

            </div>

            <div class="form-group">

                <div class="row">
                    <div class="col-md-7">
                        <label class="text-muted">Amount Payable</label>
                        <h6 *ngIf="!isEditAmount" style="margin-bottom: -12px !important;"
                            class="mb-2 card-title font-weight-bolder">
                            {{invoiceDetails?.invoice_data?.amountPayable|currency}}
                            <a class="editAmtLink ml-2"
                                *ngIf="invoiceDetails?.is_over_payment || invoiceDetails?.is_partial_payment"
                                (click)="isEditAmount=!isEditAmount"><i
                                    class="ft-edit-2" style="color: #975AFF;"></i></a>
                        </h6>
                        <br/>
                        <input *ngIf="isEditAmount" type="number"
                            [(ngModel)]="invoiceDetails.invoice_data.amountPayable"
                            class="editAmtTxt">
                        <i class="ft-x" *ngIf="isEditAmount"
                            (click)="invoiceDetails.invoice_data.amountPayable=invoiceDetails.invoice_data.balance_due;
                     isEditAmount=!isEditAmount" style="color: #975AFF;"></i>
                    </div>
                    <div class="col-md-5">
                        <label class="text-muted">View Invoice</label>
                        <div>
                            <a title="Download Invoice" (click)="download(true, invoiceDetails.invoice_data, invoiceDetails['item_kit'])">
                                <i _ngcontent-snc-c170 class="ft-download mr-2"></i>
                            </a>
                            <a title="Print Invoice" (click)="download(false, invoiceDetails.invoice_data, invoiceDetails['item_kit'])">
                                <i _ngcontent-snc-c170 class="ft-printer mr-1"></i>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="form-group" style="clear: both;">
                <label class="text-muted">Customer Details</label>
                <p class="font-small-3 font-weight-normal mb-1 mt-1">Name :
                    {{invoiceDetails?.invoice_data?.tenant_customer__name}} </p>
                <div class="row font-small-3 font-weight-normal">
                    <div class="col-md-7"><p>Email :
                            {{invoiceDetails?.invoice_data?.tenant_customer_contact__email}}
                        </p></div>
                    <div class="col-md-5"><p>Number :
                            {{invoiceDetails?.invoice_data?.tenant_customer_contact__mobile | mask:'(000) 000-0000'}}
                        </p></div>
                </div>

            </div>

        </div>

        <div class="payment-req-form-bottom text-muted">
            <small>For any queries please contact simplyfuse</small><br>
            <small><i _ngcontent-snc-c170 class="ft-mail mr-1"></i>support@simplyfuse.com</small>
        </div>

    </div>

    <div class="payment-form" *ngIf="invoiceDetails">

        <app-payment
            [myCards]="invoiceDetails?.customer_card"
            [default_card]="invoiceDetails?.default_card"
            [myChecks]="invoiceDetails?.customer_check"
            [default_check]="invoiceDetails?.default_check"
            [amount]="invoiceDetails?.invoice_data?.amountPayable"
            [email]="invoiceDetails?.invoice_data?.tenant_customer_contact__email"
            [mobile]="invoiceDetails?.invoice_data?.tenant_customer_contact__mobile"
            [invoiceUrlToken]="invoiceUrlToken"
            (onSuccess)="paymentSuccess()"
            (onError)="paymentError($event)"
            [context]="this"
            ></app-payment>

    </div>

</section>

<div class="row auth-height full-height-vh" *ngIf="this.invalidLink">
    <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="row">
            <div class="col-12 text-center">
                <img src="/assets/img/gallery/error.png" alt height="300"
                    width="400" class="img-fluid error-img mt-2">
                <h1 class="mt-4">Invalid Link!</h1>
                <div class="w-75 error-text mx-auto mt-4">
                    <p>The Link you are looking for is not unavailable.</p>
                </div>
                <a href="/" class="btn btn-warning my-2">Back To Home</a>
            </div>
        </div>
    </div>
</div>