<div class="search-dropdown" [ngClass]="{'open':shown ,'small':size=='small'}">
  <div class="search-dropdown__btn" (click)="show()" style="font-size: 28px"><span>{{value | titlecase}}</span></div>
  <div class="search-dropdown__dropdown">
    <input class="search-dropdown__dropdown__search" type="text" placeholder="Search Contacts or Type Number" [(ngModel)]="keyword"
      (ngModelChange)="getData(keyword)" style="font-size: 19px;" #phoneNumberInput />
    <div class="search-dropdown__dropdown__scroller">
      <div class="search-dropdown__dropdown__msg" *ngIf="options?.length == 0">No Result Found</div>
      <ng-template #externalSubSubLinkBlock [ngIf]="options?.length > 0">
        <table>
          <thead>
            <tr>
              <th style="width: 50%;padding: 4px;color: white;" class="bg-primary">Name</th>
              <th style="width:25%;padding: 4px;color: white;" class="bg-primary">Phone</th>
              <th style="width:25%;padding: 4px;color: white;" class="bg-primary">Mobile</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of options ; let i = index"
              [ngStyle]="{'background':i== selectedIndex?'#eee4 ff':'white'}" class="search-dropdown__dropdown__item"  (click) = "selectOption()">
              <td>{{item?.first_name}}</td>
              <td  [ngClass]="{'selectedCell':i== selectedIndex && selNoType == 'phone'}" ><span>{{item?.phone | phoneNumber }}</span></td>
              <td  [ngClass]="{'selectedCell':i== selectedIndex && selNoType == 'mobile'}" ><span>{{item?.mobile | phoneNumber}}</span></td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
  </div>
</div>