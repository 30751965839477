import { SubSink } from 'subsink';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InvCommonService } from './../../services/inv-common.service';
import { ModuleConstants } from './../../../../core/enums/common.enum';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Contact } from '../../models/contacts.models';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
    selector: 'contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('contactFormModal') modalContent: any;

    @Input() module: ModuleConstants;

    @Input() unique_id: number;

    public model: Contact;

    public contactId: number;

    public selectionType = SelectionType;

    public rows: Contact[];

    private modalRef: NgbModalRef;

    private subs: SubSink = new SubSink();

    public isAllSuborg: boolean;

    constructor(private invCommonService: InvCommonService,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef) {

        this.isAllSuborg = invCommonService.isAllSuborg;
    }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.unique_id && changes.unique_id.currentValue > 0) {
            this.getContacts();
        }
    }

    getContacts() {
        this.subs.sink = this.invCommonService.getContacts(this.module, this.unique_id).subscribe(contacts => {
            this.rows = contacts;
            this.cdr.markForCheck();
        });
    }

    open() {
        this.modalRef = this.modalService.open(this.modalContent, {
            centered: true
        });
    }

    onSelect({ selected }) {
        let current = selected[0];
        if (current && !this.isAllSuborg) {
            this.contactId = current.id;
            this.model = { ...{}, ...current };
            this.open();
        }
    }

    toggleForm() {
        this.contactId = 0;
        this.model = this.getModel();
        this.open();
    }

    getModel() {
        let model = new Contact();
        model.customer = this.invCommonService.customerId;
        model.module_id = this.module;
        model.unique_id = this.unique_id;
        return model;
    }

    contactOnSubmit() {

        let api: Observable<Contact>;

        if (this.contactId > 0) {
            api = this.invCommonService.updateContact(this.contactId, this.model);
        }
        else {
            api = this.invCommonService.createContact(this.model);
        }

        this.subs.sink = api.subscribe(resp => {
            this.rows = [...this.rows.filter(r => r.id !== resp.id), ...[resp]];
            this.toastr.success(this.contactId > 0 ? 'Contact info updated' : 'New contact created');
            this.modalRef?.dismiss();
            this.cdr.markForCheck();
        });
    }

    deleteContact(id: number) {
        popup.ConfirmDelete(resp => {
            if (resp.isConfirmed) {
                this.subs.sink = this.invCommonService.deleteContact(id).subscribe(
                    () => {
                        popup.CompleteDelete('Contact has been deleted.');
                        this.rows = this.rows.filter(r => r.id !== id);
                        this.cdr.markForCheck();
                    },
                    () => this.toastr.error('Failed to delete contact')
                )
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
