import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { LabelListResponse, LabelPage, LabelPageTemplate } from '../models/label.models';

@Injectable({
  providedIn: 'root'
})


export class LabelService extends BaseService {

  constructor(private http:HttpClient) {
     super();
   }


  labelDesignFilter(option:any, offset:number=0){
    let url = `${this.baseURL}/labels/template/filter/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<{ count: number, result: LabelListResponse[] }>(url, option).pipe(
        catchError(() => of({ count: 0, result: [] }))
    );
  }

  
  getAlllabels(){
    return this.http.get<LabelListResponse[]>(`${this.baseURL}/labels/template/`);
  }

  deleteLabel(id:number){

    return this.http.delete(`${this.baseURL}/labels/template/${id}/`, {});
  }

  createLabel(data){
    return this.http.post(`${this.baseURL}/labels/template/`, data);
  }

  updateLabel(id:number, data){
    return this.http.put(`${this.baseURL}/labels/template/${id}/`, data);
  }

  getLabel(id:number){
    return this.http.get<LabelListResponse>(`${this.baseURL}/labels/template/${id}/`);
  }




  

  //--------Label page templates ------------

  getAllPages(){
    return this.http.get<LabelPage[]>(`${this.baseURL}/lable/paper/size/`);
  }


  deletePage(id:number){

    return this.http.delete(`${this.baseURL}/lable/paper/size/${id}/`,{});
  }

  createPage(data){
    return this.http.post(`${this.baseURL}/lable/paper/size/`, data);
  }

  updatePage(id:number, data){
    return this.http.post(`${this.baseURL}/lable/paper/size/${id}/`, data);
  }

  getPage(id:number){
    return this.http.get<LabelPage>(`${this.baseURL}/lable/paper/size/${id}/`);
  }

  //------- Lable page  end --------------






  //------- Label page template  ----------------

    labelTemplateFilter(option:any, offset:number=0){
      let url = `${this.baseURL}/label/page/template/filter/?limit=${this.dataLimit}&offset=${offset}`;
      return this.http.post<{ count: number, result: LabelPageTemplate[] }>(url, option).pipe(
          catchError(() => of({ count: 0, result: [] }))
      );
    }

    getAllPageTemplate(){
      return this.http.get<LabelPageTemplate[]>(`${this.baseURL}/label/page/template/`);
    }

 
    deletePageTemplate(id:number){

      return this.http.delete(`${this.baseURL}/label/page/template/${id}/`,{});
    }
  
    createPageTemplate(data){
      return this.http.post(`${this.baseURL}/label/page/template/`, data);
    }
  
    updatePageTemplate(id:number, data){
      return this.http.put<LabelPageTemplate>(`${this.baseURL}/label/page/template/${id}/`, data);
    }
  
    getPageTemplate(id:number){
      return this.http.get<LabelPageTemplate>(`${this.baseURL}/label/page/template/${id}/`);
    }

  //-------Label p[age temple end ---------------

}
