import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { UIPermission } from 'src/app/core/models/common.models';
import { StaticFile } from 'src/app/core/models/files.models';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ItemWrapper } from 'src/app/modules/inventory/models/items';
import { KitWrapper } from 'src/app/modules/inventory/models/kit.models';
import { ItemService } from 'src/app/modules/inventory/services/item.service';
import { KitService } from 'src/app/modules/inventory/services/kit.service';
import { CustomerContact } from 'src/app/modules/tenant-customers/models/tenant-customer';
import { TenantCustomerService } from 'src/app/modules/tenant-customers/services/tenant-customer.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { ServiceTicketDataHelper } from '../../helper/service-ticket-data.helper';
import { ServiceTicket, ServiceTicketStatus, STBillingStatus, STBillingStatusHelper, STDashBoardData, STStatusHelper, STStatusMapItem } from '../../models/service-ticket';
import { ChecklistService } from '../../services/checklist.service';
import { ServiceTicketService } from '../../services/service-ticket.service';
import { Location } from '@angular/common';
import * as popup from 'src/app/core/utils/popup.functions';
import { SweetAlertOptions } from 'sweetalert2';
import { ServiceTicketViewComponent } from '../../../sales-shared/service-ticket/service-ticket-view/service-ticket-view.component';

@Component({
  selector: 'st-list',
  templateUrl: './st-list.component.html',
  styleUrls: ['./st-list.component.scss']
})
export class StListComponent implements OnInit {


  @Input() readonly:boolean=false;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

  @ViewChild(ServiceTicketViewComponent) st_view: ServiceTicketViewComponent;

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = ServiceTicketDataHelper.defaults

  public search: GFilterParam[] = ServiceTicketDataHelper.search;

  public columns: GFColumn[] = ServiceTicketDataHelper.columns;

  public sortLookup = ServiceTicketDataHelper.sortLookup;

  public SelectionType = SelectionType;

  public rows: ServiceTicket[] = [];

  public serviceTicketId: number;

  private _selected: ServiceTicket;

  public get selected(): ServiceTicket { return this._selected; }

  public set selected(value: ServiceTicket) {
    this._selected = value;
    this.setRelatedDetails();
  }

  public Permissions = PermissionConstants;

  public Resources = ResourceConstants;

  public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

  public module: ModuleConstants = ModuleConstants.SERVICE_TICKETS;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public UIPermissions: UIPermission;

  public StatusMap = STStatusHelper.maping;

  public selectedStatus: STStatusMapItem;

  public STStatus = ServiceTicketStatus;

  public STBillingStatus = STBillingStatus;

  public STBillingStatusMap = STBillingStatusHelper.maping;

  public dashlet: STDashBoardData;

  public wideScreen: boolean;

  public selectedContact: Observable<CustomerContact>;

  public selectedItem: Observable<ItemWrapper>;

  public selectedKit: Observable<KitWrapper>;

  public headerContactName:string='';

  public st_task_count:number =0;

  public st_pending_task_count:number =0;

  public isAllSuborg:boolean

  public images: StaticFile[];

  public expanded: boolean = true;

  @Output() change=new EventEmitter<ServiceTicket>();

  @Input() selectionMode:boolean=false;


  constructor(private ticketService: ServiceTicketService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    public location:Location,
    route: ActivatedRoute,
    private device: DeviceDetectorService,
    private customerService: TenantCustomerService,
    private itemService: ItemService,
    private kitService: KitService,
    private checklistService: ChecklistService,
    private router: Router) {

    this.subs.sink = route.params.subscribe(params => this.serviceTicketId = +params.id || 0);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SERVICE_TICKETS, ResourceConstants.SERVICE_TICKET_MODULE);
    this.isAllSuborg = ticketService.isAllSuborg;
    if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state){
        this.serviceTicketId =JSON.parse(this.router.getCurrentNavigation().extras.state.serviceTicketId) as number
        this.getTicket();
    }
}

  ngOnInit(): void {

    this.wideScreen = window.innerWidth > 1400;
    let param = this.defaults.filter(param => param.default)
    this.getData(param);
    this.getDashlet();
    this.scrollbarH = this.device.isMobile();

    if (this.serviceTicketId > 0) {
      this.getTicket();
      this.setStTaskCount();
      this.getImages();
    }
  }

  ngAfterViewChecked() {
    if (this.table && this.table.rowDetail) {
      this.table.rowDetail.expandAllRows();
      this.cdr.detectChanges();
    }
  }

  getDashlet() {
    this.subs.sink = this.ticketService.getDashBoardData().subscribe(resp => {
      this.dashlet = resp;
      this.cdr.markForCheck();
    });
  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let option = { sort: this.sort, filters: filters };

    this.subs.sink = this.ticketService.getAll(option, offset).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }

  getTicket() {
    this.subs.sink = this.ticketService.getById(this.serviceTicketId).subscribe(ticket => {
      this.selected = ticket;
      this.selectedStatus = this.StatusMap[ticket.status || this.STStatus.New];
      this.expanded = false;
      this.refreshDataTable();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: ServiceTicket = selected[0];
    if(this.selectionMode){
      this.change.emit(current);
    }
    else if (current && this.UIPermissions.viewInfo) {
      this.serviceTicketId = current.id;
      this.selected = current;
      this.selectedStatus = this.StatusMap[current.status || this.STStatus.New];
      this.expanded = false;
      this.location.go(`/service-tickets/${current.id}/view`);
      this.refreshDataTable();
      this.setStTaskCount();
      this.getImages()
    }
  }

  setRelatedDetails() {

    if (this.selected) {

      let contactid = this.selected.tenant_customer_contact;
      this.selectedContact = contactid ? this.customerService.getCustomerContactById(contactid) : null;


      if(this.selectedContact){
        this.selectedContact.subscribe(response=>{
          console.log(response);

          if(response){
            var fName=response.first_name?response.first_name+' ':'';
            var lName=response.last_name?response.last_name:'';

            this.headerContactName= fName+lName;
            console.log(this.headerContactName);
            this.cdr.markForCheck();
          }

        });
      }




      this.selectedItem = this.selected.associate_item ? this.itemService.getById(this.selected.associate_item) : null;

      this.selectedKit = this.selected.associate_kit ? this.kitService.getById(this.selected.associate_kit) : null;
    }
  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go(`/service-tickets`);
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
    });
  }

  deleteRow(row: ServiceTicket) {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.ticketService.delete(row.id).subscribe(
          () => {
            popup.CompleteDelete('Service Ticket has been deleted.');
            this.rows = this.rows.filter(x => x.id !== row.id);
            this.cdr.markForCheck();
            this.closeDetailedView();
          },
          () => this.toastr.error('Unable to delete row')
        );
      }
    });
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.SERVICE_TICKETS, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.SERVICE_TICKET_MODULE, [this.Permissions.GLOBAL]);
  }

  hasPermissionAny(resource: string, permission: string[]) {
    permission.push(this.Permissions.GLOBAL);
    return this.ra.hasPermission(resource, permission) || this.hasGlobal();
  }

  hasCustomerContactPerm() {
    return this.ra.hasPermission(ResourceConstants.CUSTOMER_CONTACTS, [this.Permissions.MODIFY, this.Permissions.GLOBAL])
      || this.hasGlobal();
  }

  hasChangeStatusPerm() {
    return this.ra.hasPermission(ResourceConstants.SERVICE_TICKETS, [this.Permissions.MODIFY]) || this.hasGlobal();
  }

  private hasGlobal(): boolean {
    return this.ra.hasPermission(ResourceConstants.CUSTOMER_MODULE, [this.Permissions.GLOBAL]);
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    let params = this.dataFilter?.getDataFilters() || [];
    this.getData(params, offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  changeStatus(item: STStatusMapItem) {

    if (this.selected.status == item.value) return;

    let oldValue = this.selected.status || this.STStatus.New;

    let option: SweetAlertOptions = {
      title: 'Are you sure you want to change status to',
      text: item.displayName,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn',
        footer: 'flex-row-reverse'
      },
      buttonsStyling: false
    };
    popup.OpenConfirmBox(option, result => {
      if (result.isConfirmed) {
        this.subs.sink = this.ticketService.patch(this.selected.id, { status: item.value }).subscribe(resp => {
          this.selected.status = item.value;
          this.selectedStatus = this.StatusMap[item.value];
          this.updateDashlet(item.value, oldValue);
          this.cdr.markForCheck();
        });
      }
    });
  }

  updateDashlet(newValue: number, oldStatus: number) {
    this.dashlet[this.getDashletProp(newValue)] += 1;
    this.dashlet[this.getDashletProp(oldStatus)] -= 1;
  }

  getDashletProp(value: number) {

    if (value === this.STStatus.New) {
      return 'new_tickets';
    }
    else if (value === this.STStatus.In_Progress) {
      return 'in_progress';
    }
    else if (value === this.STStatus.Waiting) {
      return 'waiting';
    }
    else if (value === this.STStatus.Escalated_To_Manager) {
      return 'escalated_to_manager';
    }
    else if (value === this.STStatus.Closed) {
      return 'closed';
    }
    return null;
  }


  setFilter(colname: string, value: any) {
    this.dataFilter.setDefaultFilter(colname, value);
  }



  setStTaskCount(count=0){
    this.subs.add(this.checklistService.getTaskDetailsByStId(this.serviceTicketId).subscribe(resp => {
      this.st_task_count = resp.length;
      resp=resp.filter(res => res.is_checked == false)
      this.st_pending_task_count = resp.length;
      this.cdr.markForCheck();
    }));
  }

  getStTaskCount(){
    return this.st_task_count;
  }

  getStPendingTaskCount(){
    return this.st_pending_task_count;
  }

  getImages() {
    this.subs.sink = this.ticketService.getSTImages(this.serviceTicketId).subscribe(resp => {
        this.images = resp;
        this.cdr.markForCheck();
    });
  }

  afterTabResize(){
    this.table.recalculate();
    // this.st_view.afterTabResize();
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }



}
