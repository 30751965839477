import { Component, OnInit } from '@angular/core';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  public expanded: boolean = true;

  public Permissions = PermissionConstants;

  public tenantCustId: number;

  constructor(private ra: ResourceAccessService) {
    this.tenantCustId = ra.customerId;
   }

  ngOnInit(): void {
  }

}
