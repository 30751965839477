import { Component, Input, OnInit, Output, EventEmitter, ViewChild, } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../models/user';
// import {UserService} from '../../services/user.service';
import { AuthService } from '../../../../core/services/auth.service';
import { IResetPasswordRequest } from 'src/app/modules/auth/models/auth-response';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss']
})
export class UserResetPasswordComponent implements OnInit {


  @Input() userId: number = 0;
  @Input() resetType: string = "resetOwn";

  @Input() hiddenFields: string[];

  // @Output() onSuccess = new EventEmitter<{message: string }>();
  @Output() onSuccess = new EventEmitter<any>();
  @Output() onProfileDataError = new EventEmitter<any>();
  public model = { user_id: this.userId, old_password: "", password: "", confirm_password: "" };

  private postUserSub$: Subscription;

  @ViewChild('userForm') userForm: NgForm;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {

    if (this.userId > 0) {
      this.model.user_id = this.userId;
    }
    this.hiddenFields = this.hiddenFields ?? [];
  }


  resetPassword() {
    if (this.resetType == "resetOwn") {
      var call = this.authService.resetPassword(this.model);
    }
    else {
      var call = this.authService.resetUserPassword(this.model);
    }
    this.postUserSub$ = call.subscribe(
      (response) => {
        if (response && response["status"] == true) {
          this.onSuccess?.emit({ message: response["message"] });

        }
        else if (response && response["status"] == false) {
          this.onProfileDataError?.emit(response["message"]);
        }
        else {
          this.onProfileDataError?.emit(response);
        }
        this.userForm.reset();
      },
      error => {
        this.onProfileDataError?.emit(error);
      }
    );
  }
  isHidden(name: string) {
    return this.hiddenFields.includes(name);
  }



}
