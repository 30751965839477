<div class="timesheet-container">
    <div class="col-md-12 group mt-2 p-0">
        <div class="d-flex justify-content-between mb-1">
            <div class="st-group-header">
                <span style="color: #370D7E;">Labor</span>
                <span style="color: #D52095;" class="ml-1">Section</span>
            </div>
        </div>
        <div class="group-body">
            <div class="row m-0">
                <div class="table-responsive col-12 p-0">
                    <div class="col-12 p-0">
                        <table class="table invoice-items-table st-items-table">
                            <thead>
                                <tr class="row-head">
                                    <th width="30">#</th>
                                    <th width="100">Date</th>
                                    <th width="350">Comments</th>
                                    <th width="150">Total Time</th>
                                    <th width="200" class="text-right">Total Amount</th>
                                    <th width="50">Action</th>
                                </tr>
                            </thead>
                            <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                                <ng-container *ngFor="let row of rows; index as i;">
                                    <tr (click)="open(row.id)" class="timesheet-tr" cdkDrag cdkDragLockAxis="y">
                                        <td cdkDragHandle ><mat-icon>control_camera</mat-icon></td>
                                        <td>
                                            <div>{{row.assigned_date |date:'MM/dd/YYYY'}}</div>
                                            <div>{{row.unique_number}}</div>
                                        </td>
                                        <td>
                                            <div>{{row.comments}}</div>
                                        </td>
                                        <td>
                                            <div>{{getTime(row.time)}}</div>
                                        </td>
                                        <td class="text-right">
                                            <div>
                                                {{row.total_amount|currency}}
                                            </div>
                                        </td>
                                        <td (click)="$event.stopPropagation();">
                                            <div>
                                                <button type="button" class="delete-btn text-danger" (click)="deleteRow(row.id)">
                                                    <i class="ft-trash-2"></i>
                                                </button>
                                            </div>
                                         </td>
                                         <!-- Preview -->
                                        <div *cdkDragPreview matchSize="true"
                                        class="container dragdrop-placeholder" style="width: 50%;">

                                        <span>{{row.unique_number}}</span>
                                        <span>{{row.comments}}</span>
                                        <span>{{getTime(row.time)}}</span>
                                    </div>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="!rows || rows.length <= 0">
                                    <td colspan="7">No items selected.</td>
                                </tr>
                                <tr class="summary-tr">
                                    <td colspan="3" class="text-left">
                                        <button *ngIf="hasPermission(Permissions.CREATE)" (click)="open()"
                                        class="btn btn-sm st-group-btn">
                                        <span class="icon-span">
                                            <i class="ft-plus plus-icon" style="color: #D52095;"></i>
                                        </span>
                                            Add Labor
                                        </button>
                                    </td>
                                    <td class="text-right">Total</td>
                                    <td class="text-right">{{calculateTimesheetTotal()|currency}}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{this.timeSheetId > 0 ? 'Edit TimeSheet' : 'Add TimeSheet'}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <time-sheet-form
            [uniqueId]="timeSheetId"
            [bindingModel]="timeSheet"
            [suborg_id]="suborg_id"
            [tenantCustomerId]="tenantCustomerId"
            [tenantCustomerLocationId]="tenantCustomerLocationId"
            [tenantCustomerContactId]="tenantCustomerContactId"
            [customerSelectReadonly]="true"
            (success)="onDataSubmittedSuccess($event)"
            (error)="onDataError()" (cancel)="close()">
        </time-sheet-form>
    </div>
</ng-template>
