import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { callDetailsResponse, CallPanelOpenModel, SIPdetailsResponse, UserStatusUpdateRequest } from '../models/call-center.models';
import { CustomerContact } from '../../tenant-customers/models/tenant-customer';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CallCenterService extends BaseService {

  public isalwaysOpen:boolean= false;

  public isalwaysOpenAddContact:boolean= false;

  public isOnGoingStForHold: boolean = false;

  public isOnGoingSoForHold: boolean = false;

  public openIncomingDetPopup = new BehaviorSubject(false)

  public popupOpenStatus: Subject<CallPanelOpenModel> = new Subject<CallPanelOpenModel>();

  public agentStatusChange: Subject<{status:any,updateStatus:boolean}> = new Subject<{status:any,updateStatus:boolean}>();

  public isOpenCallCenter : boolean = false

  public isActiveCallInbound : boolean = false

  public sip_details: SIPdetailsResponse = new SIPdetailsResponse();
  
  public isActiveCallOutbound : boolean = false

  constructor(private http: HttpClient) { super(); }

  getById() {
    return this.http.get<SIPdetailsResponse>(this.baseURL+`/call/connect/${this.userId}/user/`);
  }

  update(sipdetailsresponse: UserStatusUpdateRequest) {
    return this.http.patch(this.baseURL+`/sip/${this.userId}/user/`, sipdetailsresponse);
  }

  getCallDetails(callerId:string) {
    return this.http.get<callDetailsResponse>(this.webhookURL+`ring2voice/callcenter_updated/api/agent/call_details/${callerId}/`);
  }

  getContactDetails(contactId:number) {
    return this.http.get<CustomerContact>(this.baseURL+`/tenant/customer/contacts/${contactId}/`);
  }

  getNonCallCenterDetails(toNumber: string, fromNumber:string) {
    return this.http.get<callDetailsResponse>(this.baseURL+`/did/call_center/disable/${toNumber}/${fromNumber}/`);
  }

  putCallOnHold(body: { call_sid: string, to_number:string }){
    return this.http.post(this.webhookURL+`ring2voice/callcenter_updated/api/hold/`,body);
  }

  releaseCallFromHold( body: { call_sid: string, to_number:string, sip_username:string, call_center_enabled:boolean }){
    return this.http.post<{ call_ended: boolean, status: boolean }>(this.webhookURL+`ring2voice/callcenter_updated/api/unhold/`,body);
  }

  endCallFromHold(body: { call_sid: string, to_number:string }){
    return this.http.post(this.webhookURL+`ring2voice/callcenter_updated/api/end_call_from_hold/`,body);
  }

  getOutboundCallLogId(body: { from: string, to:string, suborg_id:number})
  {
        return this.http.post<{ data: any, status: boolean }>(this.baseURL+`/outbound/call/`,body);
  }

  getNonCallCenterDetailsOutbound(toNumber: string, fromNumber:string) {
    return this.http.get<callDetailsResponse>(this.baseURL+`/did/call_center/disable/${toNumber}/${fromNumber}/outbound/`);
  }

  getSIPCallDetails(body: {from_number: string, sip_id: string} ){
    
    return this.http.post<any>(this.baseURL+`/outbound_call_sip/`,body);
  }
}
