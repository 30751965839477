import { DeviceDetectorService } from 'ngx-device-detector';
import { GridViewsService } from './../../services/grid-views.service';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { GridViewInfo } from '../../models/grid-profiling.models';
import { ToastrService } from 'ngx-toastr';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
  selector: 'app-grid-view-list',
  templateUrl: './grid-view-list.component.html',
  styleUrls: ['./grid-view-list.component.scss']
})
export class GridViewListComponent implements OnInit, OnDestroy {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  public SelectionType = SelectionType;

  public rows = new Observable<GridViewInfo[]>();

  private rowsFlat: GridViewInfo[];

  public horrScroll: boolean;

  public isSuperAdmin: boolean;

  private deleteSub$: Subscription;

  constructor(private router: Router,
    private viewService: GridViewsService,
    private cdr: ChangeDetectorRef,
    private toast: ToastrService,
    private device: DeviceDetectorService) {

    this.horrScroll = this.device.isMobile();
    this.isSuperAdmin = viewService.isSuperUser;
  }

  ngOnInit() {
    this.rows = this.viewService.getAll().pipe(map(resp => {
      this.rowsFlat = resp.gridviews;
      return resp.gridviews;
    }));
  }

  search(event: any) {
    const val = event.target.value.toLowerCase();
    this.rows = of(this.rowsFlat.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    }));
    this.table.offset = 0;
  }

  onSelect({ selected }) {
    let view = selected[0];
    if (view) {
      this.router.navigate(['/grids/views', view.id, 'edit']);
    }
  }

  deleteView(id: number) {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.deleteSub$ = this.viewService.delete(id).subscribe(
          () => {
            this.rowsFlat = this.rowsFlat.filter(m => m.id !== id);
            this.rows = of(this.rowsFlat);
            this.cdr.markForCheck();
            popup.CompleteDelete('View has been deleted.');
          },
          () => this.toast.error('Failed to delete resource')
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.deleteSub$?.unsubscribe();
  }
}
