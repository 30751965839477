import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { ProductType } from '../models/common.models';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<ProductType[]>(`${this.baseURL}/product_types/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0, suborg_id:number = this.suborgId, limit = this.dataLimit) {
    let url = `${this.baseURL}/filter/product_type/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<ProductType[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getById(id: number) {
    return this.http.get<ProductType>(`${this.baseURL}/product_types/${id}/`);
  }

  create(model: ProductType) {
    return this.http.post<ProductType>(`${this.baseURL}/product_types/`, model);
  }

  update(id: number, model: ProductType) {
    return this.http.put<ProductType>(`${this.baseURL}/product_types/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<ProductType>(`${this.baseURL}/product_types/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/product_types/${id}/`);
  }
}
