import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { addTerms, TermsCatResponse } from '../../models/terms.models';
import { TermsService } from '../../services/terms.service';
import { Location } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-terms-form',
  templateUrl: './terms-form.component.html',
  styleUrls: ['./terms-form.component.scss']
})
export class TermsFormComponent implements OnInit {


  public userAuth=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));

  public terms:addTerms=new addTerms();

  public subs: SubSink = new SubSink();

  // public termsId:number=0;

  public UIPermissions:UIPermission;

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public api:any;

  public formError:Observable<any>;
  // public formError={ "title": [ "terms and conditions with this Title already exists." ] };


  get catDefault():number{
      return this.terms.category;
  }

  @Input() suborg_id: number = 0;

  @Output() success = new EventEmitter<{response: TermsCatResponse, message: string}>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter();

  @Input() disableCat:boolean=false;

  @Input() termsId:number=0;

  @Input() set catDefault(value:number){
    this.terms.category=value;
  }

  public cat=[
    { id: 1, name: 'Proposal' },
    { id: 2, name: 'Invoice' },
    { id: 3, name: 'Warranty' },
    { id: 4, name: 'Service Contract' },
    { id: 5, name: 'Sales Orders' },
  ];

  public placeholder:string="Select type";



  constructor(private TermsService:TermsService,
    private toaster:ToastrService,
    public location: Location,
    private router:Router,
    private cdr: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private ra: ResourceAccessService ) { }

  ngOnInit(): void {
    if(this.termsId!=0){
      this.getById(this.termsId);
    }
    this.terms.suborg=parseInt(localStorage.getItem(AppConstants.CURRENT_SUBORG));

  }

  addTerms(){
    // console.log(this.terms);
    if(this.termsId==0){
    let cust=localStorage.getItem(AppConstants.USER_ID);
    this.terms.customer=this.userAuth.custid;


    }

    if(this.suborg_id > 0){
      this.terms.suborg = this.suborg_id;
    }



    if(this.terms.title!=null&&this.terms.title.length>0&&this.terms.description!=null&&this.terms.description.length>0){


      this.api = this.termsId > 0 ? this.TermsService.updateTerms(this.terms, this.termsId) : this.TermsService.addTerms(this.terms);

      this.api.subscribe(
        response=>{
            let msg = this.termsId > 0 ? 'Terms & Conditions updated' : 'New terms & conditions added';
          // this.terms=new addTerms();
            this.success?.emit({response: response, message: msg});
        },
        (err) => {
          console.log(err.error);
          if(err.error){

            this.formError=err.error;
            this.cdr.markForCheck();
            this.toaster.error("Got error response");

          }else{
            this.toaster.error("Error occured during process");
          }
        }


      );

    }
    else{
      this.toaster.error("All fields are mandatory");

      // this.error?.emit("All fields are mandatory")

    }



  }

  getById(id:number){

    this.subs.sink=this.TermsService.getByid(id).subscribe(
      response=>{
        this.terms=response;
        this.cdr.markForCheck();
      }
    );

  }

  onCancel():void {
    this.cancel?.emit();
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(this.Resource.SALES_TERMS, [permission, this.Permissions.GLOBAL]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
