<div class="select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" [placeholder]="placeholder" [items]="options | async" bindLabel="name" bindValue="id" [(ngModel)]="selected" [typeahead]="textInput" [minTermLength]="2" [trackByFn]="trackByFn" typeToSearchText="Please enter 2 or more characters"
        (open)="selectOpen()" (change)="itemChanged($event)">
        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
                <a *ngIf="addButton && hasPermission()" class="add-button text-info" (click)="openModal(content)">
                    <i class="icon icon-settings"></i> Add Location System Type
                </a>
            </div>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Location System Type</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <location-system-type-form (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()"></location-system-type-form>
        </div>
    </ng-template>
</div>