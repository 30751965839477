<section id="state-tax-list">
    <div class="card">
        <div class="card-header d-flex flex-space-bw">
            <div class="col-xs-12">
                <h4 class="card-title">Sales Accounts</h4>
            </div>
            <div class="d-flex flex-row col-xs-12">

                <button  (click)="openImportModal(importModal)"
                class="btn btn-sm mr-1 bg-light-primary text-nowrap filter-button" disableIfAllsuborg>
                <i class="ft-download mr-1"></i> Import
            </button>

                <button *ngIf="UIPermissions.create" (click)="openModal()" class="btn btn-sm btn-text-center gradient-pomegranate mr-1" disableIfAllsuborg>
                    <i class="ft-plus mr-1"></i> New
                </button>
                <input #searchInput class="form-control form-control-sm width-200" type="text" placeholder="Search state..." (keyup)="search($event)" />
            </div>
        </div>
        <div class="card-content">
            <div class="card-body">
                <ngx-datatable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" [sorts]="[{prop:'name',dir:'asc'}]" (select)="onSelect($event)">
                    <ngx-datatable-column name="Name" prop="name" [sortable]="true"></ngx-datatable-column>
                    <ngx-datatable-column name="Description" prop="description" [sortable]="true"></ngx-datatable-column>
                    <ngx-datatable-column name="Created Date" prop="created_on" [sortable]="true" [width]="100">
                        <ng-template let-crdate="value" ngx-datatable-cell-template>
                            {{crdate | date:'MM/dd/YYYY'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Created By" prop="created_by" [sortable]="false"></ngx-datatable-column>
                    <ngx-datatable-column *ngIf="isAllSuborg" name="Suborg" prop="suborg_name" [sortable]="false"></ngx-datatable-column>
                    <ngx-datatable-column *ngIf="!isAllSuborg" name="Action" prop="id" [sortable]="false" [width]="100">
                        <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                            <div ngbDropdown container="body">
                                <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                          <i class="ft-more-vertical text-primary"></i>
                                      </button>
                                <div ngbDropdownMenu>
                                    <button class="w-100" *ngIf="UIPermissions.edit" (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                              <i class="ft-edit mr-1"></i> Edit
                                          </button>
                                    <button class="w-100 bg-light-danger" *ngIf="UIPermissions.delete" (click)="deleteState(id)" ngbDropdownItem>
                                              <i class="ft-trash-2 mr-1"></i> Delete
                                          </button>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                            <div style="padding: 0 .7rem;">
                                <span>{{rowCount}} Total</span>
                            </div>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </div>
    </div>

    <ng-template #salesAccountModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{salesAccId > 0 ? 'Edit Sales Account' : 'New Sales Account'}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-sales-account-form [salesAccId]="salesAccId" (onSuccess)="onDataSubmittedSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()"></app-sales-account-form>
        </div>
    </ng-template>


    <ng-template #importModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">Import</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <data-import [columns]="importColumns" [module]="module"
                (submit)="onImportSubmit($event)"
                (cancel)="closeImportModal()"></data-import>
        </div>
    </ng-template>


</section>
