<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter
                                     [defaultFilters]="defaults"
                                     [searchFilters]="searchFilters"
                                     [columns]="columns"
                                     [newButton]="true"
                                     [newButtonUrl]="['/suborgs/new']"
                                     [newButtonEnabled]="true"
                                     [expanded]="expanded"
                                     (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'created_user'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                        <ng-template filterControl [column]="'customer'" let-row="row">
                          <app-customer-select class="ng-select-sm" [(value)]="row.value"></app-customer-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable [rows]="rows"
                                       class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [scrollbarH]="scrollbarH"
                                       [selectionType]="SelectionType.single"
                                       [count]="page.count"
                                       [externalPaging]="true"
                                       [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit"
                                       (select)="onSelect($event)"
                                       (page)="setPage($event)"
                                       (sort)="onSort($event)"
                                       [sorts]="[{prop:'name',dir:'asc'}]">

                            <ngx-datatable-column name="Name" prop="name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.name}}</div>
                                    <div class="badge bg-light-primary p-1" *ngIf="row.is_root">Main</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Address" prop="address">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="data-group">
                                        <div *ngIf="row.address">{{row.address}}</div>
                                        <div *ngIf="row.city">{{row.city}}</div>
                                        <div *ngIf="row.state || row.zip">{{[row.state,row.zip].join(', ')}}</div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Email" prop="email"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Phone" prop="phone_number">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value | mask:'(000) 000-0000'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant" prop="customer_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created Date" prop="created_on">
                                <ng-template let-crdate="value" ngx-datatable-cell-template>
                                    {{crdate | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <user-card [name]="value"></user-card>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <a [routerLink]="['/suborgs',id,'edit']" class="w-100" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                             let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                                     [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                     [size]="pageSize" [count]="rowCount"
                                                     [hidden]="!((rowCount / pageSize) > 1)"
                                                     (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">
                          {{selected.name}}
                        </h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a [routerLink]="['/suborgs',selected.id,'edit']" ngbTooltip="Edit"
                               class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row flex-column">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <span class="badge bg-light-success" *ngIf="selected.is_root">Main org</span>
                                        <div class="group-body d-flex flex-row">
                                            <div class="col-12 p-0" [ngClass]="{'col-md-7':selected.primary_image}">
                                                <div class="ov-row" *ngIf="selected.customer_name && isSuperAdmin">
                                                    <label class="col-md-3 p-0 item-label">Tenant</label>
                                                    <label class="item-label-value">{{selected.customer_name}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.email">
                                                    <label class="col-md-3 p-0 item-label">Email</label>
                                                    <label class="item-label-value">{{selected.email}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.phone_number">
                                                    <label class="col-md-3 p-0 item-label">Phone</label>
                                                    <label
                                                        class="item-label-value">{{selected.phone_number | mask:'(000) 000-0000'}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.address">
                                                    <label class="col-md-3 p-0 item-label">Address</label>
                                                    <label class="item-label-value">{{selected.address}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.city">
                                                <label class="col-md-3 p-0 item-label">City</label>
                                                <label class="item-label-value">{{selected.city}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.state">
                                                    <label class="col-md-3 p-0 item-label">State</label>
                                                    <label class="item-label-value">{{selected.state}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.zip">
                                                    <label class="col-md-3 p-0 item-label">Zip</label>
                                                    <label class="item-label-value">{{selected.zip}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.website">
                                                    <label class="col-md-3 p-0 item-label">Website</label>
                                                    <label class="item-label-value">
                                                    <a class="text-primary" target="_blank" [href]="selected.website">{{selected.website}}</a>
                                                    </label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.time_zone">
                                                <label class="col-md-3 p-0 item-label">Time Zone</label>
                                                <label class="item-label-value">{{selected.time_zone}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.tax_id">
                                                    <label class="col-md-3 p-0 item-label">Tax ID</label>
                                                    <label class="item-label-value">{{selected.tax_id}}</label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected.created_by">
                                                    <label class="col-md-3 p-0 item-label">Created by</label>
                                                    <label class="item-label-value">
                                                        <user-card [name]="selected.created_by"></user-card>
                                                    </label>
                                                </div>
                                                <div class="ov-row" *ngIf="selected?.created_on">
                                                    <label class="col-md-3 p-0 item-label">Created on</label>
                                                    <label
                                                        class="item-label-value">{{selected.created_on|date:'MM/dd/YYYY'}}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-5 p-1 d-flex primary-image-container"
                                                *ngIf="selected.primary_image">
                                                <app-file-uploader [primary]="selected.primary_image" [files]="itemImages" [readonly]="true">
                                                </app-file-uploader>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Payments Settings</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                  <suborg-payment-settings-tab [suborgId]="selected.id"></suborg-payment-settings-tab>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2" *ngIf="isSuperAdmin">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Ring2Voice Settings</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                  <ring2voice-settings [suborgId]="selected.id"></ring2voice-settings>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="3" *ngIf="isSuperAdmin||is_quickbook_subscription">
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Quickbooks Settings</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                  <qbooks-settings [suborgId]="selected.id"></qbooks-settings>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Reason For Service</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="group">
                                        <div class="group-body">
                                            <div class="ov-row">
                                                <div class="status-toggle-btn">
                                                    <label class="title">Allow the use of Global Reason For Service? :</label>
                                                    <div
                                                         class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                        <input type="checkbox" [(ngModel)]="selected.use_global_reason"
                                                               id="globalRFS" name="active"
                                                               class="custom-control-input"
                                                               (change)="globalRFSOnChange()">
                                                        <label class="custom-control-label" for="globalRFS">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
