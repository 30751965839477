import { LicenseNumberService } from './../../services/license-number.service';
import { AppEventType, ModuleConstants } from 'src/app/core/enums/common.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { DataGridFilterComponent } from './../../../../shared/components/data-grid-filter/data-grid-filter.component';
import { TenantCustomerService } from './../../services/tenant-customer.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { TenantCustomer } from '../../models/tenant-customer';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { DataFilterRequest, GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataImportMeta, UIPermission } from 'src/app/core/models/common.models';
import { CustomerDataHelper } from '../../helpers/customer-data.helper';
import { EventQueueService } from 'src/app/core/services/event-queue.service';


@Component({
    selector: 'app-tenant-customers',
    templateUrl: './tenant-customers.component.html',
    styleUrls: ['./tenant-customers.component.scss']
})
export class TenantCustomersComponent implements OnInit, OnDestroy {

    @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = CustomerDataHelper.DefaultFilters;

    public search: GFilterParam[] = CustomerDataHelper.SearchFilters;

    public columns: GFColumn[] = CustomerDataHelper.FilterColumns;

    public sortLookup = CustomerDataHelper.SortLookup;

    public customerReps: Observable<string>;

    public customerLicenses: Observable<string>;

    public SelectionType = SelectionType;

    public rows: TenantCustomer[] = [];

    public tenantCustomerId: number;

    public contactId: number;

    public expanded: boolean = true;

    public selected: TenantCustomer;

    public Permissions = PermissionConstants;

    public Resources = ResourceConstants;

    public sort: GSortParam[] = [{ colname: 'tenant_customer_name', direction: 'asc' }];

    public module: ModuleConstants = ModuleConstants.CUSTOMERS;

    public subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public UIPermissions: UIPermission;

    public tavActivId: number = 1;

    public importColumns: DataImportMeta[] = [
        { table_name: 'TenantCustomer', actual_column_name: 'name', display_name: 'Customers - Name' },
        { table_name: 'TenantCustomer', actual_column_name: 'account_number', display_name: 'Customers - Account Number' },
        { table_name: 'TenantCustomer', actual_column_name: 'customer_type', display_name: 'Customers - Customer Type' },
        { table_name: 'TenantCustomer', actual_column_name: 'industry', display_name: 'Customers - Industry' },
        { table_name: 'TenantCustomer', actual_column_name: 'tax', display_name: 'Customers - Tax(Decimal)' },
        { table_name: 'TenantCustomer', actual_column_name: 'currency', display_name: 'Customers - currency' },
        { table_name: 'TenantCustomer', actual_column_name: 'payment_terms', display_name: 'Customers - payment_terms' },
        { table_name: 'TenantCustomer', actual_column_name: 'comments', display_name: 'Customers - comments' },
        { table_name: 'TenantCustomer', actual_column_name: 'is_service_ticket_billable', display_name: 'Customers - Customer_Billable(Boolean)' },
        { table_name: 'LicenseNumber', actual_column_name: 'number', display_name: 'LicenseNumber - Number' },
        { table_name: 'CustomerLocation', actual_column_name: 'name', display_name: 'Location - Name' },
        { table_name: 'CustomerLocation', actual_column_name: 'account_number', display_name: 'Location - Account Number' },
        { table_name: 'CustomerLocation', actual_column_name: 'address', display_name: 'Location - Address' },
        { table_name: 'CustomerLocation', actual_column_name: 'city', display_name: 'Location - City' },
        { table_name: 'CustomerLocation', actual_column_name: 'state', display_name: 'Location - State' },
        { table_name: 'CustomerLocation', actual_column_name: 'zip', display_name: 'Location - Zip' },
        { table_name: 'CustomerLocation', actual_column_name: 'location_types', display_name: 'Location - Location Type' },
        { table_name: 'CustomerLocation', actual_column_name: 'service_ticket_billable', display_name: 'Location - Location_Billable(Boolean)' },
        { table_name: 'CustomerContacts', actual_column_name: 'first_name', display_name: 'Contacts - First Name' },
        { table_name: 'CustomerContacts', actual_column_name: 'last_name', display_name: 'Contacts - Last Name' },
        { table_name: 'CustomerContacts', actual_column_name: 'email', display_name: 'Contacts - Email' },
        { table_name: 'CustomerContacts', actual_column_name: 'mobile', display_name: 'Contacts - Mobile' },
        { table_name: 'CustomerContacts', actual_column_name: 'phone', display_name: 'Contacts - Phone' },
        { table_name: 'CustomerContacts', actual_column_name: 'fax', display_name: 'Contacts - Fax' },
        { table_name: 'CustomerContacts', actual_column_name: 'title', display_name: 'Contacts - Title' },
        { table_name: 'CustomerContacts', actual_column_name: 'department', display_name: 'Contacts - Department' }
    ];

    constructor(private tenatCustService: TenantCustomerService,
        private licenseService: LicenseNumberService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        public location: Location,
        route: ActivatedRoute,
        private device: DeviceDetectorService,
        private eventQueue: EventQueueService) {

        this.subs.sink = route.params.subscribe(params => this.tenantCustomerId = +params.id || 0);
        this.subs.sink = route.queryParams.subscribe(params => this.contactId = +params.cid || 0);
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.CUSTOMERS, ResourceConstants.CUSTOMER_MODULE);
    }

    ngOnInit(): void {

        this.getData(null);

        this.scrollbarH = this.device.isMobile();

        if (this.tenantCustomerId > 0) {
            this.getCustomer();
        }
        this.subscribeEvents();
    }

    subscribeEvents() {
        this.subs.sink = this.eventQueue.on(AppEventType.SuborgSwitch).subscribe(event => {
            this.location.go(`/customers`);
        });
    }

    getData(filters?: GFilterParam[], offset: number = 0) {

        let option = { sort: this.sort, filters: filters };

        this.subs.sink = this.tenatCustService.getAll(option, offset).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    getCustomer() {
        this.subs.sink = this.tenatCustService.getById(this.tenantCustomerId).subscribe(customer => {
            this.selected = customer;
            this.expanded = false;
            this.refreshDataTable();
            this.getCustomerRep();
            this.getCustomerLiceseNumbers();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        let current: TenantCustomer = selected[0];
        if (current && this.UIPermissions.viewInfo) {
            this.tenantCustomerId = current.id;
            this.selected = current;
            this.expanded = false;
            this.location.go(`/customers/${current.id}/view`);
            this.refreshDataTable();
            this.getCustomerRep();
            this.getCustomerLiceseNumbers();
        }
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.location.go(`/customers`);
        this.refreshDataTable();
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    deleteTenantCustomer(id: number) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.tenatCustService.delete(id).subscribe(
                    () => {
                        popup.CompleteDelete('Customer has been deleted.');
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        this.closeDetailedView();
                    },
                    () => this.toastr.error('Unable to delete row')
                );
            }
        });
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.CUSTOMERS, [permission], ResourceConstants.CUSTOMER_MODULE);
    }

    hasPermissionAny(resource: string, permission: string[]) {
        return this.ra.hasPermission(resource, permission, ResourceConstants.CUSTOMER_MODULE)
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        let params = this.dataFilter?.getDataFilters() || [];
        this.getData(params, offset);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            let params = this.dataFilter?.getDataFilters() || [];
            this.getData(params, 0);
        }
    }

    changeStatus() {

        let status = this.selected.is_active;

        let popupOption = {
            title: `Are you sure you want to ${status ? 'Activate Customer' : 'Deactivate Customer'}?`,
            text: this.selected.name,
            current: status,
        };
        popup.ConfirmActivate(popupOption, result => {
            if (!result.isConfirmed) {
                this.selected.is_active = !status;
                this.cdr.markForCheck();
                return;
            }
            this.subs.sink = this.tenatCustService.patch(this.selected.id, { is_active: status }).subscribe(
                () => { },
                () => {
                    this.selected.is_active = !status;
                    this.toastr.error("Failed to change status");
                    this.cdr.markForCheck();
                }
            );
        });
    }

    getCustomerRep() {
        this.customerReps = this.tenatCustService.getAccountRep(this.selected.id).pipe(map(resp => {
            return resp.map(item => item.rep_name).join(', ');
        }));
    }

    getCustomerLiceseNumbers() {
        let request: DataFilterRequest = {
            filters: [{ colname: 'tenant_customer', condition: 'equalto', value: this.selected.id, operator: 'AND' }],
            sort: null
        };
        this.customerLicenses = this.licenseService.getCustomerLicenses(request).pipe(map(resp => {
            return resp.result.map(item => item.license_number).join(', ');
        }))
    }

    onImportSubmit(request: any) {
        this.subs.sink = this.tenatCustService.importCustomers(request).subscribe(
            resp => {
                this.toastr.success('Import has started you will be emailed when it is finished.');
                this.getData()
            },
            err => {
                this.toastr.error('Import failed')
            }
        );
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
