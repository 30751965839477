import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { Action } from '../models/common.models';

@Injectable({
  providedIn: 'root'
})
export class ActionService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<Action[]>(`${this.baseURL}/actions/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0, suborg_id:number = this.suborgId, limit = this.dataLimit) {
    let url = `${this.baseURL}/filter/actions/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<Action[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getById(id: number) {
    return this.http.get<Action>(`${this.baseURL}/actions/${id}/`);
  }

  create(model: Action) {
    return this.http.post<Action>(`${this.baseURL}/actions/`, model);
  }

  update(id: number, model: Action) {
    return this.http.put<Action>(`${this.baseURL}/actions/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<Action>(`${this.baseURL}/actions/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/actions/${id}/`);
  }
}
