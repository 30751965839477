import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { CallCenterSpec,SIPdetailsResponse } from '../../models/call-center.models';
import { CallCenterService } from '../../services/call-center.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SipOutBoundFunctionService } from 'src/app/modules/ring2voice/services/sipoutboundfunctionservice';
import * as moment from 'moment';
import { DataFilterGroupRequest, GFilterGroup, GFilterParam, GSortParam, GridTab, Pagination } from 'src/app/core/models/grid-filter.models';
import { DidsService } from '../../services/dids.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs'; 
import { CallLog } from '../../models/reports.models';
import { SIP } from '../../models/sip.models';
import { SIPService } from '../../services/sip.service';
import { WebSockectService } from '../../services/web-sockect.service';
import { BehaviorSubject } from 'rxjs';
import { CallCenterInfoComponent } from '../../components/call-center-info/call-center-info.component';
import { CallcenterAgentComponent } from '../../components/callcenter-agent/callcenter-agent.component';
import { SipConnectionService } from '../../services/sip-connection.service';
import { sipInboundFunctionService } from '../../services/sipinbound-function.service';
import { CallcenterSharedService } from '../../services/call-center-shared.service';

@Component({
  selector: 'ring2voice-call-center',
  templateUrl: './call-center.component.html',
  styleUrls: ['./call-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallCenterComponent implements OnInit, OnDestroy {

  @ViewChild(CallcenterAgentComponent) sipAgent:CallcenterAgentComponent;

  @ViewChild('nav') nav: NgbNav;
 
  page: Pagination = { count: 0, limit: 5, offset: 0, pageNumber: '0-0' };

  public sip_details:SIPdetailsResponse=new SIPdetailsResponse();

  public ringTone: any;

  public callId: string;

  public isRegistered$=new BehaviorSubject<boolean>(false);

  public isIncomingCall: boolean = false;

  public isActiveCall: boolean; 

  public showMediaPopup: boolean = false;

  public connection: any;

  public isCall: boolean;

  public callLogOpen: boolean = true;

  public name: string = '';
  
  public callQ:any[]=[];

  public rejectedCallsIDs: any[] = [];
  
  @ViewChild('callCenterInfo', {static: false }) private callCenterInfo: CallCenterInfoComponent;

  public subs: SubSink = new SubSink();

  @Input() selectedAudioInput: string;

  @Input() selectedVideoSource: any;

  @Input() activeTabId: number = 1;

  public sort: GSortParam[] = [{ colname: 'date_created', direction: 'desc' }];

  public allCallLogList : {count : number,data :CallLog[]} = {count:0, data:[]}

  public sipList : {count : number,result :SIP[]} = {count:0, result:[]}

  public SIPsort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

  baseFilter: GFilterParam[] =  [{ colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' }];

  public basicLogFilter: GFilterParam[] = [{
    displayName: "Calls This Week", colname: 'date_created_between', condition: 'date_between',
    value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
    operator: 'AND'
  }];

  public gridTabs: GridTab[] = [
  //  { name: "All", id: "all", selected: true },
    { name: "Inbound", id: "inbound", selected: true, icon:"assets/img/svg/incomingcall.svg", iconHeight:16, iconWidth:16 },
    { name: "Outbound", id: "outbound", selected: false, icon:"/assets/img/svg/outgoingcall.svg", iconHeight:16, iconWidth:16 },
    { name: "Missed", id: "missed", selected: false, icon:"assets/img/caller assets/missed.png", iconHeight:11, iconWidth:16 },
  ]
  
  defaults: GFilterParam[]

  newPanelOpened : boolean = false

  @Input() dynamicHeight;

  public outboundDefaults: GFilterParam[] =  [{
    
    displayName: "Calls This Week", colname: 'date_created', condition: 'date_between',
    value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
    operator: 'AND'
  }];
  
  public socketReconnectCount: number = 0;

  public lastSocketPingTime: any;

  public isInternetConnected$=new BehaviorSubject<boolean>(true);

  public processingStatusChange$=new BehaviorSubject<boolean>(false);

  public processingStatusChangeMsg:string='Connecting server..';

  public processingStatusChangeStartedAt;

  public connectLostStatus:string="";

  public connectingSIP$=new BehaviorSubject<boolean>(false);

  public internetJustCame:boolean=false;

  public internetJustGone:boolean=false;

  public sendMsgTimeOut:any;

  public pingMsgTimeOut:any;

  public pingSubs:SubSink=new SubSink();

  public socketStatusSubs:SubSink=new SubSink();

  public pingInterval;

  public socketConnection;

  public pageFirstLoad:boolean=true;

  public alphabets:string[]=['ALL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

  public selectedLetter:string='ALL';

  public modalSpec:CallCenterSpec=new CallCenterSpec();


  public lastPongTime:number=0;

  public lastStatusResponse:any;

  constructor(
    public modal: NgbModal,
    private cdr: ChangeDetectorRef,
    public callCenterService: CallCenterService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    public sipOutBoundFnService: SipOutBoundFunctionService,
    private didService: DidsService,
    private sipService: SIPService,
    private sockectService: WebSockectService,
    public sipConnectionService : SipConnectionService,
    public sipInboundFnService : sipInboundFunctionService, 
    public callcenterSharedService: CallcenterSharedService
  ) { }


  ngOnInit() {

    this.getAllCallLog([{ 'conditions': this.basicLogFilter, 'operator': 'AND' }])
    this.getSIPList();

     this.subs.sink = this.sipOutBoundFnService.isCallTerminated.subscribe((val) => {

      if(val == true)
      {
        this.getAllCallLog([{ 'conditions': this.basicLogFilter, 'operator': 'AND' }])
        this.getSIPList();
      }
    })

   this.subs.sink =  this.sipInboundFnService.isCallTerminated.subscribe((val) => {

      if(val == true)
      {
        this.getAllCallLog([{ 'conditions': this.basicLogFilter, 'operator': 'AND' }])
        this.getSIPList();
      }
    })
  }


  /**
   *? Gets call log
   * @param filters 
   * @param offset 
   */
  
  getAllCallLog(filters: GFilterGroup[] = null, offset: number = 0) {
    let request: DataFilterGroupRequest = {
      base_filters: this.baseFilter,
      filters: filters,
      sort: this.sort
    };
    this.subs.sink = this.didService.getCallReport(request, offset,true).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.allCallLogList.data = response.result;
      this.allCallLogList.count = response.count;
    });

  }
  getSIPList(filters: GFilterParam[] = null, offset: number = 0) {

    let request = { filters: filters, sort: this.SIPsort }

    console.log('agentrequest', request)
/**
 * * 50 for limit & -1 for listing allSuborg sips 
 */
    this.subs.sink = this.sipService.get(request, offset, 50, -1).pipe(
      catchError(() => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.sipList = response;
    });
  }

  onIncomingSessionItemClick(callid) //click from currentcalllist panel
  {
    this.activeTabId = 1
    this.cdr.markForCheck();
    this.sipInboundFnService.getIncomingCallDetailsApi(callid)

  }
  onOutGoingSessionItemClick(event)//click from currentcalllist panel
  {
    this.activeTabId = 2
  }
  clearCompletedCallsfromCallQ(endedWhileOnHold: boolean = false) {
   
    this.sipInboundFnService.clearCompletedCallsfromCallQ(endedWhileOnHold);
    this.cdr.markForCheck();
  }
 
  checkWhichSectionTobeSelected() {  
 
    this.sipInboundFnService.checkWhichSectionTobeSelected();
    this.cdr.markForCheck();
  }
  movetoAcceptedCalls(session: any) { 

    this.sipInboundFnService.movetoAcceptedCalls(session)
  }

  callerInfo(session) {//return caller name/number
    return session.remoteIdentity.uri.user;
  }

  answeredCall(session) {//check whether call ongoing
    return session.state == 'Established';
  }
  endedCall(session) {//check whether call ended
    return session.state == 'Terminated';
  }

  checkActiveCall(session) {
    return session.state != 'Established';
  }
   onMainTabClick(tabId:number)
  {

    if(tabId == 2) // for set default fi.ter in call report on pagination change
    {
      this.basicLogFilter.push(
        {colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' },
        { displayName: 'Missed', colname: 'real_status', condition: 'equalto', value: 'no-answer', operator: 'WHEN' }
      )
    }
    else
    {
      this.basicLogFilter = [{
        displayName: "Calls This Week", colname: 'date_created_between', condition: 'date_between',
        value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
        operator: 'AND'
      }];
    }
    this.activeTabId = tabId
  }

  showOutboundDetails()
  {
    this.sipOutBoundFnService.outBoundShowType = "details"
  }

  onNewPanelOpened(event) //check new panel opened in callinfo
  {
    this.newPanelOpened = event
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sipInboundFnService.destroy();
    this.sipOutBoundFnService.destroy();
  }


}