<div class="row">
    <div class="col-12 col-md-12">
        <div class="form-group has-validation">
            <label class="val-required">Document name</label>

            <input type="text" placeholder="Document name" class="form-control is-invalid" [ngClass]="{'is-invalid':formError&&formError['title']}" [(ngModel)]="terms.title"/>
            <div  class="invalid-feedback" *ngIf="formError&&formError['title']">
                <p *ngFor="let item of formError['title']">
                    {{item}}
                </p>
            </div>
        </div>
    </div>
    <!-- <div class="col-12 col-md-12">
        <div class="form-group">
            <label class="val-required">Terms & Conditions type</label>
            <select class="form-control" [(ngModel)]="terms.category">
                <option >Select one</option>
                <option value="1">Proposal</option>
                <option value="2">Invoice</option>
                <option value="3">Warranty</option>
            </select>
            <ng-select class=""
            [(ngModel)]="terms.category"
            [items]="cat"
            bindValue="id"
            [disabled]="disableCat"
            [placeholder]="placeholder" >
                <ng-template ng-label-tmp let-item="item">
                    {{item.name}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{item.name}}
                </ng-template>
            </ng-select>
        </div>

    </div> -->
    <div class="col-12 col-md-12">
    <div class="form-group">
        <label class="val-required">Terms and conditions</label>
        <textarea class="form-control" style="height: 200px;" placeholder="Write terms and conditions here" [ngClass]="{'is-invalid':formError&&formError['description']}" [(ngModel)]="terms.description"></textarea>
        <div  class="invalid-feedback" *ngIf="formError&&formError['description']">
            <p *ngFor="let item of formError['description']">
                {{item}}
            </p>
        </div>
    </div>
    </div>
    <div class="col-12 col-md-12 justify-content-end d-flex flex-sm-row flex-column mt-3 mt-sm-2">
        <!-- <input type="submit" class="btn btn-primary"/> -->
        <button class="btn btn-secondary mr-sm-2 mb-2" (click)="onCancel()" >CANCEL</button>
        <button class="btn gradient-pomegranate mb-2" (click)="addTerms()">SUBMIT</button>

    </div>
</div>
