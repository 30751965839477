import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { KitDataHelper } from '../../../helpers/kit-data.helper';
import { KitAssembleMaterialLocation, KitAssembleMaterialRow, KitAssembleWrapper } from '../../../models/kit-assemble.models';
import { WareHouse, WareHouseLocation } from '../../../models/warehouse.models';
import { KitAssembleService } from '../../../services/kit-assemble.service';
import { WarehouseService } from '../../../services/warehouse.service';

@Component({
    selector: 'app-build-assemble-select',
    templateUrl: './build-assemble-select.component.html',
    styleUrls: ['./build-assemble-select.component.scss']
})
export class BuildAssembleSelectComponent implements OnInit {

    @ViewChild('buildAssembleModal') buildAssembleModal: any;

    @Output() valueChange = new EventEmitter<number>();

    @Output() change = new EventEmitter<KitAssembleWrapper>();

    @Output() select = new EventEmitter<KitAssembleMaterialLocation[]>();

    @Input() selectBox: boolean = true;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public modalRef: any;

    public buildAssemble: KitAssembleWrapper[];

    public sort: GSortParam[];

    private subs: SubSink = new SubSink();

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public columns: GFColumn[] = KitDataHelper.AssembledKitFilterColumns;

    public defaults: GFilterParam[] = KitDataHelper.AssembledKitDefaultFilters;

    public search: GFilterParam[] = KitDataHelper.AssembledKitSearchFilters;

    public sortLookup = KitDataHelper.AssembledKitSortLookup;

    public scrollbarH: boolean = false;

    public selectedItem: KitAssembleWrapper[] = [];

    public selected: KitAssembleWrapper;

    public SelectionType = SelectionType;

    public expanded: boolean = true;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public Locs: KitAssembleMaterialLocation[] = [];

    public warehouses: WareHouse[];

    public warehouseLocations = {};
    total_quantity: number;
    ChangedItem: KitAssembleMaterialRow;
    bundlecount: number;
    required_quantity: number;
    total_required_quantity: number;
    IsChanged: boolean;
    total_selected_quantity: number;
    isAllocate: boolean;


    constructor(private modal: NgbModal, private buidAssebleService: KitAssembleService,
        private cdr: ChangeDetectorRef,
        private device: DeviceDetectorService,
        private ra: ResourceAccessService,
        private warehouseService: WarehouseService,
        private toastr: ToastrService) {
        this.scrollbarH = this.device.isMobile();
    }

    ngOnInit(): void {
    }

    open(row?: KitAssembleMaterialRow, bundlecount: number = 1, isAllocate: boolean = false) {
        this.expanded = true;
        this.ChangedItem = row;
        this.bundlecount = bundlecount;
        let filters = []
        this.isAllocate = isAllocate
        if (isAllocate && row && row.part_number) {
            filters.push({ colname: "kit_part_number", condition: "is", value: row.part_number, operator: "AND" });
        }
        this.getData(filters);
    }

    private getData(filters?: GFilterParam[], offset: number = 0) {
        let option = { sort: this.sort, filters: filters };
        this.subs.sink = this.buidAssebleService.getAssembledKits(option, offset).subscribe(resp => {
            this.buildAssemble = resp.result;
            this.setPagination(offset, resp.count);
            this.cdr.markForCheck();
            if (this.isAllocate) {
                let selected_kitassemble = this.buildAssemble.filter((item, index) => {
                    return item.id == this.ChangedItem.selected_kitassemble;
                })
                if (selected_kitassemble.length > 0) {
                    this.onSelect({ "selected": selected_kitassemble })
                }
                else {
                    if (this.buildAssemble.length == 1) {
                        this.onSelect({ "selected": this.buildAssemble })
                    }
                    else {
                        this.isAllocate = false;
                    }
                }

            }
            let size = (this.isAllocate) ? 'lg' : 'xl';
            this.modalRef = this.modal.open(this.buildAssembleModal, { size: size, scrollable: true });
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    setPage(pageInfo: any, dataFilter: DataGridFilterComponent) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(dataFilter?.getDataFilters(), offset);
    }

    onSort(event: any, dataFilter: DataGridFilterComponent) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(dataFilter?.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }

    // onItemSelected() {
    //   if (this.selectedItem && this.selectedItem.length > 0) {
    //     let item = this.selectedItem[0];
    //     this.selected = item;
    //     this.valueChange?.emit(item.id);
    //     this.change?.emit(item);
    //   }
    //   this.modalRef?.dismiss();
    // }

    onSelect({ selected }): void {
        let wrapper: KitAssembleWrapper = selected[0];
        if (wrapper) {
            this.selected = wrapper;
            this.required_quantity = this.ChangedItem.quantity;
            this.total_required_quantity = this.bundlecount * this.ChangedItem.quantity
            // if(wrapper.id == this.ChangedItem.item_id){
            //   this.IsChanged = true;
            // }
            // else{
            // this.IsChanged = false;
            // }
            this.expanded = false;
            // this.refreshDataTable();
            this.getWarehouses();
            this.getAssembledWarehouses();
        }
    }

    refreshDataTable() {
        setTimeout(() => {
            this.buildAssemble = [...this.buildAssemble];
            this.cdr.markForCheck();
        });
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.INV_ITEMS, [permission], ResourceConstants.INV_MODULE);
    }

    onCanceled() {
        this.modalRef?.dismiss();
    }

    clearSelection(event: any) {
        event.stopPropagation();
        this.selected = null;
        this.valueChange?.emit(null);
        this.selectedItem = [];
    }

    // onSelectStock(event:KitAssembleWareHouseStock[]){
    //   let val:KitAssembleWareHouseStock[]=event;
    //   console.log(val);
    //   this.selectedItem[0].stockSelected=event;
    //   console.log(this.selectedItem[0]);
    // }

    onItemViewClose(): void {
        if (this.isAllocate) {
            this.modalRef?.dismiss();
        }
        else {
            this.expanded = true;
            this.selected = null;
            this.refreshDataTable();
        }
    }

    onSelected() {
        if (this.total_selected_quantity > this.total_required_quantity) {
            this.toastr.error(`Quantity mismatch`);
            return false;
        }
        if(!this.isAllocate){
            this.change?.emit(this.selected);
        }
        this.select?.emit(this.Locs)
        this.modalRef?.dismiss();
        return true;
    }

    getWarehouses() {
        this.subs.sink = this.warehouseService.getAll().subscribe(resp => {
            this.warehouses = resp;
            this.cdr.markForCheck();
        });
    }

    getAssembledWarehouses() {
        this.subs.sink = this.buidAssebleService.getKitAssembledWarehousestock(this.selected.id).pipe(map(resp => {
            return resp.map(w => {
                if (w.warehouse_location > 0) {
                    let loc = new WareHouseLocation();
                    loc.location = w.location_name;
                    loc.id = w.warehouse_location;
                    w.locations = of([loc]);
                }
                return w;
            });
        })).subscribe(resp => {
            this.Locs = []
            if (this.ChangedItem && this.ChangedItem.selected_stock && this.ChangedItem.selected_stock.length > 0) {
                resp.forEach(
                    (res, inx) => {
                        let exist = this.ChangedItem.selected_stock.filter((row, index) => {
                            return row.warehouse == res.warehouse && row.warehouse_location == res.warehouse_location
                        })
                        let elem = new KitAssembleMaterialLocation()
                        elem.unique_id = res.kit_assemble;
                        elem.warehouse = res.warehouse
                        elem.warehouse_location = res.warehouse_location
                        elem.warehouse_name = res.warehouse_name
                        elem.location_name = res.location_name
                        elem.selected_quantity = 0
                        elem.available_quantity = res.available_quantity

                        if (exist.length > 0) {
                            elem.id = exist[0].id;
                            elem.selected_quantity = exist[0].selected_quantity;
                        }
                        this.Locs.push(elem);
                    })
            }
            else {
                resp.forEach(
                    (res, inx) => {
                        let elem = new KitAssembleMaterialLocation()
                        elem.unique_id = res.kit_assemble;
                        elem.warehouse = res.warehouse
                        elem.warehouse_location = res.warehouse_location
                        elem.warehouse_name = res.warehouse_name
                        elem.location_name = res.location_name
                        elem.selected_quantity = 0
                        elem.available_quantity = res.available_quantity
                        this.Locs.push(elem)
                    }
                )
            }
            this.Locs = this.Locs.sort((a, b) => a.warehouse - b.warehouse);
            this.getAggregateQuantity();
            this.getSelectedAggregateQuantity()
            this.cdr.markForCheck();
        });
    }

    getAggregateQuantity() {
        this.total_quantity = this.Locs.reduce((sum, current) => sum + current.available_quantity, 0)
    }

    getSelectedAggregateQuantity() {
        this.total_selected_quantity = this.Locs.reduce((sum, current) => sum + current.selected_quantity, 0)
    }

    validateQuantity(row: KitAssembleMaterialLocation): boolean {
        if (this.total_selected_quantity > this.total_required_quantity) {
            this.toastr.error(`Quantity mismatch`);
            row.selected_quantity = row.selected_quantity - 1;
            this.getSelectedAggregateQuantity()
            return false;
        }
        return true;
    }

}
