import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-outbound-keypad',
  templateUrl: './outbound-keypad.component.html',
  styleUrls: ['./outbound-keypad.component.scss']
})
export class OutboundKeypadComponent implements OnInit {

  public session : any

  public keyInputs: string = ''

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onClickNumber(val: string) {
   
      this.keyInputs += val
      this.sendKey(val)

  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {

      this.removeLastLetter();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.close()
}

  sendKey(key) 
  {
    const options = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: "Signal="+key+"\r\nDuration=1000"
        }
      }
    };

    this.session.info(options)
  }

  removeLastLetter(): void {
    // Remove the last letter from the inputText
    this.keyInputs = this.keyInputs.slice(0, -1);
  }

  close()
  {
    this.activeModal.close(this.keyInputs);
  }


}
