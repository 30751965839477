import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { WarehouseService } from 'src/app/modules/inventory/services/warehouse.service';
import { SubSink } from 'subsink';
import { WarrantyComments } from '../../../sales/models/warranty.models';
import { WarrantyService } from '../../../sales/services/warranty.service';

@Component({
  selector: 'warranty-comments',
  templateUrl: './warranty-comments.component.html',
  styleUrls: ['./warranty-comments.component.scss']
})
export class WarrantyCommentsComponent implements OnInit {

  @Input() wId: number=0;

  // @Input() set warrantyId(value: number) {
  //   this.wid = value;
  //   this.getComments(this.wid);
  // }

  @Input() timelineView: boolean = true;

  get warrantyId() { return this.wId; }

  public comments: WarrantyComments[];

  public model: WarrantyComments = new WarrantyComments();

  public userName: string;

  public rows: number = 1;

  public currentUser: number;

  public isAdmin: boolean;

  private subs: SubSink = new SubSink();

  constructor(private WarrantyService: WarrantyService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) {

    this.currentUser = WarrantyService.userId;
    this.isAdmin = WarrantyService.isAdmin;
  }

  ngOnInit() {
    if(this.wId>0){
      this.getComments(this.wId);
    }
    let userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
    if (userAuth) {
      this.userName = `${userAuth.firstname || ''} ${userAuth.lastname || ''}`;
    }
    if (!this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  getComments(wId: number) {
    if (wId > 0) {
      this.subs.sink = this.WarrantyService.getComments(wId).subscribe(resp => {
        this.comments = resp;
        this.cdr.markForCheck();
      });
    }
    else {
      this.comments = [];
    }
  }

  headerInputFocus() {
    if (this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  createComment() {

    if(!this.model.comments) return;

    this.model.warranty = this.warrantyId;

    this.model.customer = this.WarrantyService.customerId;
    this.model.inputRows=undefined;
    this.subs.sink = this.WarrantyService.createComment([this.model], this.wId).subscribe(
      resp => {
        this.comments = [...resp, ...this.comments];
        this.model.inputRows = 1;
        this.model.comments = '';
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to save comment')
    );
  }

  updateComment(comment: WarrantyComments, value: string) {

    if(!value) return;

    let com = { ...comment };
    com.comments = value;
    this.subs.sink = this.WarrantyService.updateComment(comment.id, com).subscribe(
      () => {
        comment.comments = value;
        comment.edit = false;
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to modify comment')
    );
  }

  deleteComment(commentid: number) {
    this.subs.sink = this.WarrantyService.deleteComment(commentid).subscribe(
      () => {
        this.comments = this.comments.filter(c => c.id !== commentid);
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to delete comment')
    );
  }

  public saveComment(wId: number, next?: (value: any) => any) {
    if (!this.model.comments && next) {
      next(null);
    }
    else {
      this.model.warranty = wId;
      this.model.customer = this.WarrantyService.customerId;
      this.subs.sink = this.WarrantyService.createComment([this.model], this.wId).pipe(
        catchError(() => [])
      ).subscribe(resp => {
        if (next) { next(resp) }
      });
    }
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }

}
