<section>
    <div class="card col-12" (click)="toggleBanner()" [hidden]="!hideBanner" style="background-color: #380e7f;cursor: pointer;">
        <div class="row p-2">
            <div class="col-11">
                <span class="banner-hidden-heading">View More About Automated Allow List</span>
            </div>
            <div class="col-1 d-flex align-items-center">
                <i class="fa fa-chevron-down text-white" [ngClass]="hideBanner?'':'fa-rotate-180'" aria-hidden="true"></i>
            </div>
        </div>
    </div>
    <div class="card p-0 col-12 mb-0" [ngClass]="hideBanner?'hideBanner':'showBanner'">
        <div class="card-body banner">
            <div class="col-4 centered-div">
                <div>
                    <h2>The smart way to run a call center.</h2>
                    <small>
                        Find out for yourself with a personal demo of Ring2Voice, a proprietary SimplyFuse Call Center solution. 
                        Talk to a dedicated account success manager today.
                    </small>
                    <small></small>
                </div>
            </div>
            <div class="col-4 centered-div">
                <img src="../../../../../assets/img/gallery/simplyfuse-product-img.png" class="banner-img p-3">
            </div>
            <div class="col-4 centered-div">
                <iframe width="560" height="315" class="p-4" src="https://www.youtube.com/embed/T_4nyno2r0o?si=LJW5lgZ-Rsz_jA9o" title="Simplyfuse Intro Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div class="togglebanner" [hidden]="hideBanner" (click)="toggleBanner()"><i class="fa fa-chevron-down text-white" [ngClass]="hideBanner?'':'fa-rotate-180'" aria-hidden="true"></i></div>
    <div class="card p-0 col-12 mt-1" style="border: 2px dotted violet;">
        <div class="card-header">
            <h4 class="card-title">Automated Allow List Settings</h4>
        </div>
        <div class="card-content">
            <div class="card-body pb-0 pt-0">
                <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && onSubmit()" novalidate>
                    <div class="row align-items-center">
                        <div class="col-5">
                            <div class="form-group">
                                <div class="status-toggle-btn">
                                    <label class="title mr-1 mb-0">Default status for any newly created Orgs :</label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" name="active" [(ngModel)]="default_for_all"
                                            class="custom-control-input"  id="superAdminStatus">
                                        <label class="custom-control-label" for="superAdminStatus">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-5" *ngIf="isSuperAdmin">
                            <label class="val-required">Minimum Call Duration in seconds</label>
                            <input type="number" class="form-control mr-1 w-50" name="SA_Call_duration" [min]="minValue" [(ngModel)]="min_call_duration" #duration="ngModel" required PreventSpecialCharacters>
                            <small [ngClass]="sa_lengthError?'text-danger':'text-secondary'">* Minimum value for duration is {{minValue}} seconds</small>
                            <small class="form-text text-muted danger" *ngIf="duration.invalid && (duration.dirty || duration.touched)">
                                <div *ngIf="duration.errors.required">
                                    Duration is required.
                                </div>
                            </small>
                        </div>
                        <div class="col-5" *ngIf="!isSuperAdmin">
                            <div class="form-group">
                                <div class="d-flex align-items-center">
                                    <label for="allSub_Call_duration" class="mr-1">Call Duration</label>
                                    <input type="number" class="form-control mr-1 w-25" name="allSub_Call_duration" [(ngModel)]="min_call_duration" [min]="minValue" required PreventSpecialCharacters>
                                    <label for="call_duration" class="val-required">Seconds or more</label>
                                </div>
                                <small [ngClass]="lengthError?'text-danger':'text-secondary'">* Minimum value for duration is {{minValue}} seconds</small>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="d-flex flex-sm-row flex-column justify-content-start">
                                <button type="submit" [disabled]="!addForm.form.valid" class="btn gradient-purple mb-2 mb-sm-0 mr-sm-2">
                                    save
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row info-row" style="background-color: #EEE4FF;">
                        <div class="col-6">
                            <ul class="info-list mt-1">
                                <li>When this feature is turned on, it will automatically create a CRM contact entry for every incoming phone call that lasts longer than the specified duration.</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <ul class="info-list mt-1">
                                <li>When a CRM entry exists for an incoming phone number, that number will be considered safe and won't be flagged as spam or blocked.</li>
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<section class="common-grid-view suborg_listing">
    <div class="list-parellax pt-2">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter
                                     [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [searchFilters]="searchFilters"
                                     [expanded]="expanded"
                                     (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'created_user'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                        <ng-template filterControl [column]="'customer'" let-row="row">
                          <app-customer-select class="ng-select-sm" [(value)]="row.value"></app-customer-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable [rows]="rows"
                                       class="bootstrap core-bootstrap"
                                       [columnMode]="'force'"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [scrollbarH]="scrollbarH"
                                       [count]="page.count"
                                       [externalPaging]="true"
                                       [offset]="page.offset"
                                       [externalSorting]="true"
                                       [limit]="page.limit"
                                       (page)="setPage($event)"
                                       (sort)="onSort($event)"
                                       [sorts]="[{prop:'name',dir:'asc'}]">

                            <ngx-datatable-row-detail [rowHeight]="75"
                                #rowdetails *ngIf="expanded">
                                <ng-template let-row="row"
                                    ngx-datatable-row-detail-template>
                                    <div class="row">
                                        <div class="col-md-3 d-flex">
                                            <img src="../../../../../assets/img/gallery/user-avatar.jpg" class="rounded-pill img-view">
                                            <span class="badge badge-warning name-badge h-50 w-50">{{row.customer_name}}</span>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="div">
                                                <label  class="detail-head">Address</label>
                                            </div>
                                            <div class="data-group">
                                                <div *ngIf="row.address">{{row.address}}</div>
                                                <div *ngIf="row.city">{{row.city}}</div>
                                                <div *ngIf="row.state || row.zip">{{[row.state,row.zip].join(', ')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="div">
                                                <label  class="detail-head">Email</label>
                                            </div>
                                            <div class="div">
                                                <label>{{row.email}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="div">
                                                <label  class="detail-head">Phone</label>
                                            </div>
                                            <div class="div">
                                                <label>{{row.phone_number|phoneNumber}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-row-detail>
                            <ngx-datatable-column name="Name" prop="name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.name}}</div>
                                    <div class="badge bg-light-primary p-1" *ngIf="row.is_root">Main</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant" prop="customer_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Call Duration" prop="allowlist_settings" [sortable]="false">
                                <ng-template let-duration="value" ngx-datatable-cell-template>
                                    <span *ngIf="duration.call_duration" style="border-bottom: 1px solid #d7d7d7;padding: 3px 10px 3px 10px;">{{duration.call_duration}} sec</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Settings Status" prop="allowlist_settings" [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                         [ngClass]="{'bg-light-success': status.is_active,'bg-light-danger': !status.is_active }">
                                        {{ status.is_active ? 'ON' : 'OFF' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                            (click)="open(content,current)">
                                        <i class="fa fa-edit text-info" style="font-size:22px"></i>
                                    </button>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                             let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                                     [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                     [size]="pageSize" [count]="rowCount"
                                                     [hidden]="!((rowCount / pageSize) > 1)"
                                                     (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Change settings</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="d-flex justify-content-between">
                    <div class="form-group">
                        <div class="status-toggle-btn mb-2">
                            <label class="title">Status :</label>
                            <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                <input type="checkbox" name="active" [(ngModel)]="individual_status"
                                    class="custom-control-input"  id="allSub_default">
                                <label class="custom-control-label" for="allSub_default">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <label class="val-required">Set Minimum call duration {{minValue}} or more</label>
                        <input type="text" mask="00" id="durationField" [(ngModel)]="durationValue" [placeholder]="placeholder" [min]="minValue" name="durationInput" class="form-control"
                                (keyup.enter)="saveIndividualDuration(modal)" required PreventSpecialCharacters>
                        <small [ngClass]="dur_LengthError?'text-danger':'text-secondary'">* Minimum value for duration is {{minValue}} seconds</small>
                        <hr/>
                        <ul class="info-list">
                            <li>When this feature is turned on, it will automatically create a CRM contact entry for every incoming phone call that lasts longer than the specified duration.</li>
                            <li>When a CRM entry exists for an incoming phone number, that number will be considered safe and won't be flagged as spam or blocked.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button [disabled]="!durationValue" class="btn gradient-pomegranate"
                    (click)="saveIndividualDuration(modal)">Save</button>
            <button type="button" class="btn" (click)="dismissModal(modal)">Cancel</button>
        </div>
    </ng-template>
</section>