<form #itemMapForm="ngForm" (ngSubmit)="!mapExist && itemMapForm.form.valid && onItemSMapSubmit()">
    <div class="form-group">
        <label class="val-required">Supplier</label>
        <app-supplier-select #supplierSelect [(value)]="model.supplier" (onChange)="onSupplierChange($event)"
                             (onClear)="onSupplierClear()"></app-supplier-select>
        <small class="form-text text-muted danger" *ngIf="mapExist">
            Supplier already exist!
        </small>
    </div>
    <div class="form-group">
        <label class="val-required">Supplier Part Number</label>
        <input type="text" [(ngModel)]="model.supplier_part_number" name="supplier_part_number" class="form-control"
               required>
    </div>
    <div class="form-group">
        <label class="val-required">Price</label>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">$</span>
            </div>
            <input type="text" [(ngModel)]="model.price" mask="separator.2" separatorLimit="100000" thousandSeparator="," name="price" class="form-control" required>
        </div>
    </div>
    <div class="form-group d-flex flex-row align-items-center">
        <label class="mr-2">Default :</label>
        <div class="custom-switch custom-switch-success custom-control">
            <input type="checkbox" [(ngModel)]="model.is_default" name="active" class="custom-control-input"
                   id="is_default">
            <label class="custom-control-label" for="is_default">
                <span></span>
            </label>
        </div>
    </div>
    <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
        <button type="submit" [disabled]="!itemMapForm.form.valid || mapExist || !model.supplier"
                class="btn gradient-pomegranate mr-sm-2">
            {{itemSMapId > 0 ? 'Save Changes' : 'Save'}}
        </button>
        <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
    </div>
</form>
