<div class="modal-body">
    <div class="div">
        <input type="text" [(ngModel)] = "keyInputs">
    </div>  
    <div class="container rounded-bottom">
  
        <div class="d-flex flex-column justify-content-between h-100 ">
        
            <div>
                <div class="dial-nos">
                    <div class="row mt-2">
                        <div class="col-sm-4" (click)="onClickNumber('1')">
                            <div class="digit" id="one">1</div>
                        </div>
                        <div class="col-sm-4" (click)="onClickNumber('2')">
                            <div class="digit" id="two">2</div>
                        </div>
                        <div class="col-sm-4" (click)="onClickNumber('3')">
                            <div class="digit" id="three">3</div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-sm-4" (click)="onClickNumber('4')">
                            <div class="digit" id="one">4</div>
                        </div>
                        <div class="col-sm-4" (click)="onClickNumber('5')">
                            <div class="digit" id="two">5</div>
                        </div>
                        <div class="col-sm-4" (click)="onClickNumber('6')">
                            <div class="digit" id="three">6</div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-sm-4" (click)="onClickNumber('7')">
                            <div class="digit" id="one">7</div>
                        </div>
                        <div class="col-sm-4" (click)="onClickNumber('8')">
                            <div class="digit" id="two">8</div>
                        </div>
                        <div class="col-sm-4" (click)="onClickNumber('9')">
                            <div class="digit" id="three">9</div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-sm-4" (click)="onClickNumber('*')">
                            <div class="digit" id="one">*</div>
                        </div>
                        <div class="col-sm-4" (click)="onClickNumber('0')">
                            <div class="digit" id="two">0</div>
                        </div>
                        <div class="col-sm-4" (click)="onClickNumber('#')">
                            <div class="digit" id="three">#</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-section mt-1">
        <button class="close-btn" (click) = "close()">Close</button>
    </div>
</div>