<ngx-datatable class="bootstrap core-bootstrap" [scrollbarH]="true" [headerHeight]="40" rowHeight="auto" [columnMode]="'force'" [rows]="rows" [selectionType]="selectionType.single" [sorts]="[{prop:'supplier_name',dir:'asc'}]" (select)="onSelect($event)">
    <ngx-datatable-column name="Name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.first_name}} {{row.last_name}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Title" prop="title"></ngx-datatable-column>
    <ngx-datatable-column name="Phone">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.phone_number | mask:'(000) 000-0000'}} <span *ngIf="row.extension">Ext {{row.extension}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Email" prop="email">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <a (click)="$event.stopPropagation()" [href]="'mailto:'+ value">{{value}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!isAllSuborg" name="Action" prop="id" [sortable]="false" [width]="60" [canAutoResize]="false">
        <ng-template let-id="value" let-row="row" ngx-datatable-cell-template>
            <div ngbDropdown container="body">
                <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                    <i class="ft-more-vertical text-primary"></i>
                </button>
                <div ngbDropdownMenu>
                    <button class="w-100" (click)="onSelect({selected:[row]})" ngbDropdownItem>
                        <i class="ft-edit mr-1"></i> Edit
                    </button>
                    <button class="w-100 bg-light-danger" (click)="deleteContact(id)" ngbDropdownItem>
                        <i class="ft-trash-2 mr-1"></i> Delete
                    </button>
                </div>
            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<ng-template #contactFormModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{contactId > 0 ? 'Edit Contact' : 'New Contact'}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form name="form" #supplierForm="ngForm" (ngSubmit)="supplierForm.form.valid && contactOnSubmit()" novalidate>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="val-required">First Name</label>
                        <input type="text" [(ngModel)]="model.first_name" #firstname="ngModel" name="first_name" class="form-control" required>
                        <small class="form-text text-muted danger" *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)">
                            <div *ngIf="firstname.errors.required">
                                Name is required.
                            </div>
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="val-required">Last Name</label>
                        <input type="text" [(ngModel)]="model.last_name" #lastname="ngModel" name="last_name" class="form-control" required>
                        <small class="form-text text-muted danger" *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)">
                            <div *ngIf="lastname.errors.required">
                                Name is required.
                            </div>
                        </small>
                    </div>
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" [(ngModel)]="model.title" name="title" class="form-control" />
                    </div>
                    <div class="d-flex flex-row">
                        <div class="col-9 p-0 pr-1 form-group">
                            <label>Phone Number</label>
                            <input type="text" [(ngModel)]="model.phone_number" mask="(000) 000-0000" name="phone_number" class="form-control" />
                        </div>
                        <div class="col-3 p-0 pl-1 form-group">
                            <label>Extension</label>
                            <input type="text" [(ngModel)]="model.extension" name="extension" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" [(ngModel)]="model.email" #em="ngModel" name="email" class="form-control">
                        <small class="form-text text-muted danger" *ngIf="em.invalid && (em.dirty || em.touched)">
                            <div *ngIf="em.errors?.email">
                                Please enter a valid email address.
                            </div>
                        </small>
                    </div>
                </div>
                <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                    <button type="submit" [disabled]="!supplierForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                        {{contactId > 0 ? 'Save Changes' : 'Add Contact'}}
                    </button>
                    <button type="button" (click)="d('Cancel')" class="btn btn-secondary">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
