<form *ngIf="model" #timeSheetForm="ngForm" (ngSubmit)="timeSheetForm.form.valid && submit()" novalidate>
    <div class="form-group col-6 p-0" *ngIf="model.id">
        <label>Timesheet Number</label>
        <input
            type="text"
            [value]="model.unique_number"
            class="form-control"
            readonly/>
    </div>
    <div class="d-flex flex-row">
        <div class="form-group col-6 p-0">
            <label class="val-required">Customer</label>
            <tenant-customer-select
                class="select-md"
                [(value)]="model.tenant_customer"
                [readonly]="customerSelectReadonly"
                (default)="onDefaultCustomerSet($event)"
                (change)="customerChanged($event)"
            ></tenant-customer-select>
        </div>
        <div class="form-group col-6 pr-0">
            <label>Customer Contact</label>
            <tenant-customer-contact-select
            [tenantCustomerId]="model.tenant_customer"
            [(value)]="model.tenant_customer_contact"
            [readonly]="!model.tenant_customer"
            (change)="customerContactChanged($event)"
            (locationAdd)="onCustomerLocationAdded()">
            </tenant-customer-contact-select>
        </div>
    </div>
    <div class="d-flex flex-row">
        <div class="form-group col-6 p-0">
            <label>Customer Location</label>
            <tenant-customer-location-select
                [tenantCustomerId]="model.tenant_customer"
                [(value)]="model.tenant_customer_location"
                [readonly]="!model.tenant_customer"
                (default)="onDefaultLocationSet($event)"
                (change)="customerLocationChange($event)">
            </tenant-customer-location-select>
        </div>
        <div class="form-group col-6 pr-0" *ngIf="hasDatePermission()">
            <label>Timesheet Date</label>
            <app-date-picker
                [(value)]="model.assigned_date"
                [valueFormat]="'yyyy-MM-dd hh:mm:ss'">
            </app-date-picker>
        </div>
    </div>
    <div class="form-group">
        <timepicker
            [rangePicker]="true"
            [(value)]="timeModel"
            startRangeLabel="Start Time"
            endRangeLabel="End Time"
            (change)="pickerChanged($event)">
        </timepicker>
    </div>
    <div class="form-group">
        <label>Total Time</label>
        <div class="d-flex align-items-center">
            <span>
                <input
                    type="number"
                    [(ngModel)]="hourDiff"
                    class="form-control"
                    name="hour"/>
            </span>
            <span class="mr-2 ml-2">Hours</span>
            <span
                ><input
                    type="number"
                    [(ngModel)]="minDiff"
                    class="form-control"
                    name="minutes"
            /></span>
            <span class="ml-2">Minutes</span>
        </div>
    </div>
    <div class="form-group">
        <div class="custom-control custom-checkbox">
            <input
                type="checkbox"
                class="custom-control-input"
                [(ngModel)]="model.is_billable"
                name="is_billable"
                id="is_billable"
                (change)="billableOnChange()"
            />
            <label class="custom-control-label" for="is_billable">
                <span>Billable</span>
            </label>
        </div>
    </div>
    <div class="d-flex flex-row">
        <div class="form-group col-6 p-0">
            <label>Total Billable</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                </div>
                <input
                    type="number"
                    class="form-control"
                    [readonly]="!model.is_billable"
                    [(ngModel)]="model.total_amount"
                    name="total_amount"
                />
            </div>
        </div>
        <!-- <div class="form-group col-6 pr-0">
            <label>Billing Status</label>
            <ng-select
                class="select-md"
                [items]="billingStatus"
                placeholder="Select billing status"
                bindLabel="key"
                bindValue="value"
                [(ngModel)]="model.billing_status"
                name="billing_status"
            ></ng-select>
        </div> -->
    </div>
    <div class="form-group">
        <label>Comment</label>
        <textarea
            type="text"
            class="form-control"
            [(ngModel)]="model.comments"
            name="comments"
        ></textarea>
    </div>
    <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
        <button
            type="submit"
            [disabled]="!timeSheetForm.form.valid"
            class="btn gradient-pomegranate mr-sm-2">
            {{ model.id > 0 ? "Save Changes" : "Save" }}
        </button>
        <button type="button" (click)="onCancel()" class="btn btn-secondary">
            Cancel
        </button>
    </div>
</form>
