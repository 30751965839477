import { SalesAccountService } from './../../../services/sales-account.service';
import { SalesAccount } from './../../../models/sales-account';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-sales-account-form',
    templateUrl: './sales-account-form.component.html',
    styleUrls: ['./sales-account-form.component.scss']
})
export class SalesAccountFormComponent implements OnInit {

    public model: SalesAccount;

    @Input() salesAccId: number = 0;

    @Output() onSuccess = new EventEmitter<{ response: SalesAccount, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    public nameExist: boolean = false;

    private getSub$: Subscription;

    private postSub$: Subscription;

    constructor(private service: SalesAccountService, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.salesAccId > 0) {
            this.getSub$ = this.service.getById(this.salesAccId).subscribe(response => {
                this.model = response;
                this.cdr.markForCheck();
            });
        }
        else {
            this.model = this.service.createModel(SalesAccount)
        }
    }

    onSubmit(): void {

        let api: Observable<SalesAccount>;

        if (this.salesAccId > 0) {
            api = this.service.update(this.salesAccId, this.model);
        }
        else {
            api = this.service.create(this.model);
        }

        this.postSub$ = api.subscribe(
            resp => {
                let msg = this.salesAccId > 0 ? 'Sales Account info updated' : 'New Sales Account created';
                this.nameExist = false;
                this.onSuccess?.emit({ response: resp, message: msg });
            },
            error => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.onError?.emit(error);
                }
            }
        );
    }

    onCancelClicked(): void { this.onCancel?.emit(); }

    ngOnDestroy(): void {
        this.getSub$?.unsubscribe();
        this.postSub$?.unsubscribe();
    }
}
