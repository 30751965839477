import { Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerType } from '../../../models/tenant-customer';
import { CustomerTypeService } from '../../../services/customer-type.service';

@Component({
  selector: 'customer-type-form',
  templateUrl: './customer-type-form.component.html',
  styleUrls: ['./customer-type-form.component.scss']
})
export class CustomerTypeFormComponent implements OnInit, OnDestroy {

  public model: CustomerType = new CustomerType();

  @Input() customerTypeId: number = 0;

  @Output() onSuccess = new EventEmitter<{ response: CustomerType, message: string }>();

  @Output() onError = new EventEmitter<any>();

  @Output() onCancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private service: CustomerTypeService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.customerTypeId > 0) {
      this.getSub$ = this.service.getById(this.customerTypeId).subscribe(CustomerType => {
        this.model = CustomerType;
      });
    }
  }

  onSubmit(): void {

    let api: Observable<CustomerType>;

    if (this.customerTypeId > 0)
      api = this.service.update(this.customerTypeId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;
      api = this.service.create(this.model);
    }

    this.postSub$ = api.subscribe(
      response => {
        let msg = this.customerTypeId > 0 ? 'Customer type info updated' : 'New customer Type created';
        this.nameExist = false;
        this.onSuccess?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.onError?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void {
    this.onCancel?.emit();
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
    this.postSub$?.unsubscribe();
  }
}
