<div class="manufacturer-select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" [placeholder]="placeholder" [items]="options | async | orderBy:'manufacturers_name'" bindLabel="manufacturers_name" bindValue="id" [(ngModel)]="value" [readonly]="readonly" (clear)="onSelectClear()" (change)="onValueChanged()">
        <ng-template ng-footer-tmp *ngIf="addButton && hasPermission()">
            <button type="button" class="select-footer-btn" (click)="openModal(content)">
                <i class="icon icon-settings"></i>
                <span class="text">Add Manufacturer</span>
            </button>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Manufacturer</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-manufacturer-form (onSuccess)="onDataSubmittedSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()"></app-manufacturer-form>
        </div>
    </ng-template>
</div>