import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'accepted-call-outbound',
  templateUrl: './accepted-call-outbound.component.html',
  styleUrls: ['./accepted-call-outbound.component.scss']
})
export class AcceptedCallOutboundComponent implements OnInit {

  @Input() session;

  @Output() holdCall = new EventEmitter<boolean>();

  public callDuration = new BehaviorSubject('');

  public callStartTime: Date;

  public timerInterval: any;

  public showAction: boolean = false


  constructor(private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.startTimer();
  }

  callerNumber(session){//return caller number
    return session.remoteIdentity.uri.user;
  }

  callerName(session){//return caller name
    return session.customValues?.callerName;
  }

  startTimer() {
    this.callStartTime = new Date();
    this.timerInterval = setInterval(() => {
      const currentTime = new Date();
      const elapsedSeconds = Math.floor((currentTime.getTime() - this.callStartTime.getTime()) / 1000);
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds % 60;
      let time = `${minutes}min${minutes !== 1 ? 's' : ''} ${seconds}second${seconds !== 1 ? 's' : ''}`;
      if(!(this.session.state=='Terminated' && !this.session.customValues?.isCallOnHold)){
        this.callDuration.next(time);
      }
      this.cdr.markForCheck();
    }, 1000);
  }

  onHoldCall()
  {
    this.holdCall.emit(true);
  }

}
