import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SIP } from 'src/app/modules/ring2voice/models/sip.models';
import { SIPService } from 'src/app/modules/ring2voice/services/sip.service';

@Component({
  selector: 'app-user-sip-select',
  templateUrl: './user-sip-select.component.html',
  styleUrls: ['./user-sip-select.component.scss']
})
export class UserSipSelectComponent implements OnInit {

  public count:number;

  public isAllSuborg:boolean;

  @Input() value: any;

  @Input() suborgId: number;

  @Input() readonly: boolean = false;

  @Input() placeholder: string;

  @Input() multiple: boolean = true;

  @Output() valueChange = new EventEmitter<any>();

  @Output() change = new EventEmitter<any>();

  @Input() maxinput: number;

  public inputValues: string[] = [];

  public selected: any;

  public options: SIP[];

  constructor(
    private sipService: SIPService,
    private cdr: ChangeDetectorRef
  ) { this.isAllSuborg = sipService.isAllSuborg; }

  ngOnInit(): void {
    this.getData();
    this.placeholder = this.multiple ? 'Select SIPs' : 'Select SIP';
  }

  getData(){
    let request = { 
      filters: [], sort: [],
    }
    if (this.isAllSuborg) {
      request.filters.push(...[
          { colname: 'suborg', condition: 'equalto', value: this.suborgId, operator: 'AND' }
      ]);
  }
    this.sipService.get(request, 0 , 50).subscribe(response => {
      this.options = response.result;
      this.cdr.markForCheck();
  });
  }

  onValueChanged(value: any) {
    if (this.multiple) {
      this.value = value ? value.map((item: SIP) => item.username) : null;
      this.valueChange.emit(this.value);
    }
    else {
      this.value = value ? value.id : null;
      this.valueChange.emit(this.value);
      this.sipService.assignSiptoUser=this.value
      
    }
    this.change?.emit(this.value);
    this.count = value.length
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.username.toLocaleLowerCase().indexOf(term) > -1 || item.associated_username.toLocaleLowerCase().indexOf(term) > -1;
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      this.selected = changes.value.currentValue;
      this.cdr.markForCheck();
    }
  }
}
