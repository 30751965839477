import { TimeSheet } from '../../../service-ticket/models/common.models';
import { catchError } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ToastrService } from 'ngx-toastr';
import { BillingStatus } from '../../../service-ticket/models/enums';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ServiceTicketService } from '../../../service-ticket/services/service-ticket.service';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Component, Input, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { SubSink } from 'subsink';
import { of } from 'rxjs';
import * as popup from 'src/app/core/utils/popup.functions';
import { SweetAlertOptions } from 'sweetalert2';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
    selector: 'time-sheet-grid',
    templateUrl: './time-sheet-grid.component.html',
    styleUrls: ['./time-sheet-grid.component.scss']
})
export class TimeSheetGridComponent implements OnInit, OnDestroy {

    @ViewChild('modalContent') modalContent: any;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @Output() onUpdate = new EventEmitter();

    @Output() onDataLoaded = new EventEmitter();

    public rows: TimeSheet[] = [];

    public selectionType = SelectionType;

    private stid: number;

    get serviceTicketId(): number { return this.stid; };

    @Input() set serviceTicketId(value: number) {
        this.stid = value;
        this.getTimeSheet(value);
    }

    @Input() tenantCustomerId: number;

    @Input() tenantCustomerLocationId: number;

    @Input() tenantCustomerContactId: number;

    @Input() suborg_id:number;

    private modalRef: NgbModalRef;

    public scrollbarH: boolean = false;

    private subs: SubSink = new SubSink();

    public billingStatus: { key: string, value: number, class: string }[] = [
        { key: 'Unbilled', value: BillingStatus.Un_billed, class: 'badge bg-warning' },
        { key: 'Invoiced', value: BillingStatus.Invoiced, class: 'badge bg-info' },
        { key: 'Paid', value: BillingStatus.Paid, class: 'badge bg-success' },
        { key: 'Overdue', value: BillingStatus.Overdue, class: 'badge bg-danger' },
    ];

    public Permissions = PermissionConstants;

    public timeSheetId: number = 0;

    public timeSheet: TimeSheet;

    constructor(private service: ServiceTicketService,
        private modal: NgbModal,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService) { }

    ngOnInit(): void { }

    onSelect({ selected }) {
        if (selected.length && this.hasPermission(PermissionConstants.MODIFY)) {
            this.open(selected[0].id);
        }
    }

    getTimeSheet(ticketid: number) {
        if (ticketid) {
            this.subs.sink = this.service.getTimeSheets(ticketid).pipe(
                catchError(err => of([]))
            ).subscribe(resp => {
                this.rows = resp;
                this.onDataLoaded.emit(this.rows)
                this.cdr.markForCheck();
            });
        }
    }

    public open(id: number = 0) {

        this.timeSheetId = id;

        this.timeSheet = id ? { ... this.rows.find(item => item.id == id) } : null;

        this.modalRef = this.modal.open(this.modalContent, { centered: true, scrollable: true, size: 'lg' });
    }

    public close() { this.modalRef?.dismiss(); }

    deleteRow(id: number) {
        popup.ConfirmDelete(result => {
            if (!result.isConfirmed) return;
            this.subs.sink = this.service.deleteTimeSheet(id).subscribe(
                () => {
                    this.rows = this.rows.filter(x => x.id !== id);
                    this.onUpdate?.emit();
                    this.cdr.markForCheck();
                    popup.CompleteDelete('Timesheet has been deleted.')
                },
                () => this.toastr.error('Unable to delete Timesheet')
            );
        });
    }

    getBillingStatus(val: number) { return this.billingStatus.find(item => item.value === val); }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.ST_TIMSHEET, [permission], ResourceConstants.SERVICE_TICKET_MODULE);
    }

    getTime(timeInMi: number) {
        if (timeInMi){
            let h = Math.floor(timeInMi / 60);
            let m = timeInMi % 60;
            return `${h} Hours ${m} Minutes`;
        }
        else{
            return `0`;
        }
    }

    onDataSubmittedSuccess(result: { response: TimeSheet, message: string }): void {
        this.rows = [...this.rows.filter(item => item.id !== result.response.id), ...[result.response]];
        this.onUpdate?.emit();
        this.toastr.success(result.message);
        this.close();
        this.cdr.markForCheck();
    }

    onDataError(): void {
        this.toastr.error('Operation failed');
        this.close();
    }

    calculateTimesheetTotal() {
        let total = 0;
        this.rows.forEach((row, index) => {
            if (!row.is_delete && row.is_billable) {
                total += Number(!isNaN(row.total_amount) ? row.total_amount : 0);

            }
        });
        return total.toFixed(2);
    }

    changeShowInPdf(row: TimeSheet) {
        let option: SweetAlertOptions = {
            title: 'Are you sure want to change the Show in Pdf status',
            text: '',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Change',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn',
                htmlContainer: 'font-small-3'
            },
            buttonsStyling: false
        };
        popup.OpenConfirmBox(option, response => {
            if (response.isConfirmed) {
                this.updateTimesheet(row, 'Successfully changed the Show In PDF status')
            }
            else{
                row.is_show_in_pdf = !row.is_show_in_pdf;
                this.cdr.markForCheck();
            }
        });
    }

    changeIsBillable(row: TimeSheet) {
        let option: SweetAlertOptions = {
            title: 'Are you sure want to change the Is Billable status',
            text: '',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Change',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn',
                htmlContainer: 'font-small-3'
            },
            buttonsStyling: false
        };
        popup.OpenConfirmBox(option, response => {
            if (response.isConfirmed) {
                if(row.is_billable){
                    row.is_show_in_pdf = true;
                }
                this.updateTimesheet(row, 'Successfully changed the Billable status')
            }
            else{
                row.is_billable = !row.is_billable;
                this.cdr.markForCheck();
            }
        });
    }

    updateTimesheet(row: TimeSheet, message?: string){
        this.subs.sink = this.service.updateTimeSheet(row.id, row).subscribe(
            () => {
                this.onUpdate?.emit();
                this.cdr.markForCheck();
                if(message)
                    popup.CompleteDelete(message)
            },
            () => this.toastr.error('Some thing went wrong..')
        );
    }

    afterTabResize(){
        this.table.recalculate();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
        this.rows.map(
            (item, index) => {
                item.order=index;
                this.updateTimesheet(item)
            }
        )
        this.toastr.success("Order changed in Labor section");
        this.cdr.detectChanges()
    }

    ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
