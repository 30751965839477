import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { User } from 'src/app/modules/user/models/user';
import { UserService } from '../../services/user.service';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { Time,TimeRange } from 'src/app/shared/components/time-picker/time-picker.component';
import { ToastrService } from 'ngx-toastr';
import * as momentTz from 'moment-timezone';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { Ring2voiceService } from 'src/app/modules/ring2voice/services/ring2voice.service';
// import * as popup from 'src/app/core/utils/popup.functions';
import swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';


@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserFormComponent implements OnInit {

    @Input() userId: number = 0;

    @Output() success = new EventEmitter<{ response: User, message: string }>();

    @Output() cancel = new EventEmitter();

    @Output() error = new EventEmitter<any>();

    @Input() readOnlyFields: string[] = [];

    @Input() hiddenFields: string[] = [];

    public model: User;

    private customerId: number;

    public isSuperAdmin: boolean;

    private subs: SubSink = new SubSink();

    public emailExists: boolean = false;

    public timeModel: Time | TimeRange;
    public timezones:string[]=[];
    voices: Observable<any>;

    public editEmail:boolean=false;

    public isAdmin:boolean=false;

    public emailMaster:string;

    public currentUserEmail:string;

    constructor(private userService: UserService,
        private cdr: ChangeDetectorRef,
        private toastr:ToastrService,
        private r2vservice: Ring2voiceService) {

        this.currentUserEmail= this.userService.userEmail;
        
        this.isSuperAdmin = userService.isSuperUser;
        this.isAdmin = userService.isAdmin;
        this.customerId = this.userService.customerId;


    }

    ngOnInit(): void {

        this.voices = this.r2vservice.getVoices();
        this.timezones=momentTz.tz.names();

        if(!this.userId){
            this.editEmail=true;
        }


        if (this.userId) {
            this.subs.add(this.userService.getById(this.userId).subscribe(user => {
                this.model = user;
                this.emailMaster=user.email;
                this.initModel();
                this.cdr.markForCheck();
            }));
        }
        else {
            this.model = new User();
            this.initModel();
            if (!this.isSuperAdmin) {
                this.model.customer[0] = this.customerId;
            }
            this.model.is_voicemail_enabled = true;
            this.model.is_voicemail_send = true;
        }

        
        
    }

    initModel() {
        let start = this.model.deactivation_time?.split(':') || ['0', '0'];
        this.timeModel = new Time(parseInt(start[0]), parseInt(start[1]));


        if(this.model.voicemail_message == '' || this.model.voicemail_message == null){

            let caller_name = this.model.first_name?this.model.first_name:''+this.model.last_name?this.model.last_name:''
            this.model.voicemail_message = `Thank you for calling `+caller_name+`. Please leave a detailed message and i'll get back to you as soon as possible.`;
          }
        if(this.model.voicemail_voice == '' || this.model.voicemail_voice == null){
            this.model.voicemail_voice = 'man';   
        }    

        this.cdr.markForCheck();
    }

    pickerChanged(value: any) {
        let model = value as Time;

        if (!model.hour || !model.minute) return;

        if (model.hour || model.minute) {
            this.model.deactivation_time = model.to24Format();
        }
    }

    userFormOnSubmit() {

        if(!this.isSuperAdmin&&!this.isAdmin&&this.userId){

            this.model.email=this.emailMaster;
        }


        let api: Observable<User>;
        if (this.model.deactivate) {
            if(this.model.deactivation_date && (this.timeModel as Time).hour || (this.timeModel as Time).minute){
                this.model.deactivation_time = (this.timeModel as Time).to24Format();
                this.model.deactivation_date = new Date(this.model.deactivation_date);
            }
            else{
                this.toastr.error("select deactivation date and time")
                return;
            }
        }
        else{
            this.model.deactivation_date = null;
            this.model.deactivation_time = null;
        }
        if (this.userId) {
            api = this.userService.update(this.userId, this.model);
        }
        else {
            this.model.email = this.model.email.toLowerCase();
            api = this.userService.create(this.model);
        }

        this.subs.add(api.subscribe(
            resp => {
                this.emailExists = false;

                var User:User= JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));

                if(this.model.time_zone&&this.model.time_zone!=''){
                    User.time_zone=this.model.time_zone;

                    localStorage.setItem(AppConstants.USER_AUTH, JSON.stringify(User));
                }




                let msg = this.userId ? "User info updated" : "User created";
                this.success?.emit({ response: resp, message: msg });
            },
            error => {
                if (error.error && error.error['email']) {
                    this.emailExists = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.error?.emit(error);
                }
            }
        ));
    }

    makeEmailEditable(){

       

        let option: SweetAlertOptions = {
            title: 'Are you sure you want to change the email?',
            text: 'An email will be sent to the new email address for confirmation. A new password will also be generated for security reasons',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Edit',
            customClass: {
                confirmButton: 'btn btn-info',
                cancelButton: 'btn',
                footer: 'flex-row-reverse'
            },
            buttonsStyling: false
        };
        swal.fire(option).then(result => {
            if(result.isConfirmed){
                this.editEmail=true;
            }
            else{
                this.editEmail=false;
            }
            this.cdr.detectChanges();
        });
    }

    isReadOnly(name: string) { return this.readOnlyFields.includes(name); }

    isHidden(name: string) { return this.hiddenFields.includes(name); }

    onCancel() { this.cancel?.emit(); }

    ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
