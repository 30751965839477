
<section class="user-view" *ngIf="user">
    <div class="row mt-3">
        <div class="col-12 col-sm-7">
            <div class="d-flex align-items-center">
                <div class="user-avatar-content bg-primary white">
                    {{ (user.first_name + ' ' + user.last_name ) | shortName}}
                </div>
                <div class="ml-2">
                    <h4 class="profile-title">{{ (user.first_name + ' ' + (user.last_name || '') ) }}</h4>
                    <div>{{user.email}}</div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-5 d-flex justify-content-end align-items-center">
            <a (click)="openPasswordModal(userPassword)" class="btn btn-sm bg-light-danger mr-2 px-3 py-1">
                <i class="ft-lock mr-1"></i> Reset Password
            </a>
            <a (click)="openProfileModal(userProfile)" class="btn btn-sm btn-primary px-3 py-1">
                <i class="ft-edit-2 mr-1"></i> Edit Profile
            </a>
        </div>
    </div>

    <ul ngbNav #nav="ngbNav" class="nav-tabs mt-2">
        <li ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Overview</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <div class="info-tile col-12 col-md-6 mt-2 mb-3">
                    <ul class="list-unstyled mb-2 mt-2">
                        <li class="d-flex align-items-center">
                            <span class="info-label">Role</span>
                            <span>{{user.role_name}}</span>
                        </li>
                        <li class="d-flex align-items-center">
                            <span class="info-label">DOB</span>
                            <span>{{user.date_of_birth | date:'MM/dd/YYYY'}}</span>
                        </li>
                    </ul>
                </div>
                <user-settings [userId]="userId" [user]="user"></user-settings>
            </ng-template>
        </li>



        <li ngbNavItem [ngbNavItem]="2" >
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Voice Mail</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                
                <voice-mail-list (submit)="submit()" [user]="user" [voice_mail_user_id]="userId" [is_delete]="true" ></voice-mail-list>
            </ng-template>
        </li>


        <li ngbNavItem *ngIf="!isAdmin && !isSuperAdmin&&!isCustomer">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Privileges</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <div class="previleges-container col-12 col-md-8 p-0 mt-2">
                    <role-previleges [roleId]="user.role"></role-previleges>
                </div>
            </ng-template>
        </li>
        <!-- <li ngbNavItem *ngIf="isAdmin">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Account</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <div class="card col-12 col-md-6 p-0">
                    <div class="card-header">
                        <div class="card-title">
                            <i class="ft-settings mr-1"></i> Settings
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="card-body">
                            <tenant-settings [tenantId]="tenantId"></tenant-settings>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li> -->
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</section>

<ng-template #userProfile let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Edit Profile</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-user-form
                       [userId]="userId"
                       [readOnlyFields]="['email','role']"
                       [hiddenFields]="['is_active','password','customer']"
                       (success)="onProfileSubmittedSuccess($event)"
                       (error)="onProfileDataError($event)"
                       (cancel)="onProfileCancel()">
        </app-user-form>
    </div>
</ng-template>

<ng-template #userPassword let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Reset Password</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-user-reset-password
                                 [userId]="userId"
                                 (onSuccess)="onPasswordSubmittedSuccess($event)"
                                 (onProfileDataError)="onPasswordDataError($event)">
        </app-user-reset-password>
    </div>
</ng-template>
