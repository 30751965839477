import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AutomatedAllowListService } from '../../services/automated-allow-list.service';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { AllowListResponse, SubOrgResponse, SuperAdminAllowListResponse } from '../../models/automated-allow-list.models';
import { Observable, of } from 'rxjs';
import { DataFilterRequest, GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { catchError } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-automated-allow-list',
  templateUrl: './automated-allow-list.component.html',
  styleUrls: ['./automated-allow-list.component.scss']
})
export class AutomatedAllowListComponent implements OnInit {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild('dataTable') dataTable: DatatableComponent;

  public superAdminModel : SuperAdminAllowListResponse = new SuperAdminAllowListResponse();

  public allSuborgModel : AllowListResponse = new AllowListResponse();

  public min_call_duration:number;

  public minValue:number;

  public isSuperAdmin: boolean;

  public allSuborgs: boolean;

  public default_for_all: boolean = false;

  public individual_status: boolean = false;

  public is_active: boolean = false;

  private subs: SubSink = new SubSink();

  public sa_lengthError: boolean = false;//superAdmin

  public lengthError: boolean = false;//suborg

  public dur_LengthError: boolean = false;

  public placeholder: string;

  public durationValue: number;

  private ngbModalRef: NgbModalRef;

  public expanded:boolean = true;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public scrollbarH: boolean = false;

  public rows: SubOrgResponse[];

  public defaults: GFilterParam[] = [
    { displayName: "All Sub-orgs", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Active Sub-orgs", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
    { displayName: "Inactive Sub-orgs", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
    { displayName: "Main Sub-orgs", colname: 'is_root', condition: 'is', value: 'true', operator: 'AND' },
];

public searchFilters: GFilterParam[] = [
  {
      displayName: 'Combo Search', colname: '', condition: '', value: '',
      children: [
          { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
          { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
          { displayName: 'Phone', colname: 'phone_number', condition: 'contains', value: '', operator: 'OR' },
          { displayName: 'Address', colname: 'address', condition: 'contains', value: '', operator: 'OR' },
      ]
  },
  { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
  { displayName: 'Phone', colname: 'phone_number', condition: 'contains', value: '', operator: 'OR' }
];

public columns: GFColumn[] = [
  { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
  { displayName: 'Email', columnName: 'email', columnType: GFColumnTypes.string },
  { displayName: 'Phone', columnName: 'phone_number', columnType: GFColumnTypes.string },
  { displayName: 'Main Org', columnName: 'is_root', columnType: GFColumnTypes.boolean },
  { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
  { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
];

public sort: GSortParam[];

public selectedSuborg_id: number;

public settings_id: number;

public sortLookup = {
  name: "name",
  email: "email",
  address: "address",
  phone_number: "phone_number",
  created_on: "created_on",
  customer_name: "customer_name",
  created_by: "created_by"
};

public hideBanner:boolean = false;

  constructor(private automatedAllowService: AutomatedAllowListService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService,
    private modalService: NgbModal) {
      this.isSuperAdmin = automatedAllowService.isSuperUser;
      this.allSuborgs = automatedAllowService.suborgId === -1;
      this.placeholder =  `Enter a value greater than ${this.minValue}`;
     }

  ngOnInit(): void {
    this.scrollbarH = this.device.isMobile();
    this.hideBanner = localStorage.getItem('isShowBanner') === 'true';
    this.getData(null);
    if(this.isSuperAdmin){
      this.columns.unshift({ displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref });
      this.getSettingsForSuperAdmin();
    }else{
      this.getSettings();
    }
  }

  ngAfterViewChecked() {
    if (this.dataTable && this.dataTable.rowDetail) {
        this.dataTable.rowDetail.expandAllRows();
        this.cdr.detectChanges();
    }
  }

  getSettingsForSuperAdmin(){
    this.subs.sink = this.automatedAllowService.getAllowSettingsforSuperAdmin().subscribe(response=>{
      this.min_call_duration = response.allowlist_settings?.min_call_duration;
      this.minValue = response.spam_settings?.duration+1;
      this.default_for_all = response.allowlist_settings?.default_for_all?response.allowlist_settings.default_for_all:false;
      this.superAdminModel = response;
      this.cdr.markForCheck();
    })
  }

  getSettings(){
    this.subs.sink = this.automatedAllowService.getAllowSettings().subscribe(response=>{
      this.min_call_duration = response.allowlist_settings_tenant?.call_duration||null;
      this.minValue = response.allowlist_settings_superadmin?.min_call_duration;
      this.default_for_all = response.allowlist_settings_tenant?.default_for_all?response.allowlist_settings_tenant.default_for_all:false;
      this.allSuborgModel = response;
      this.cdr.markForCheck();
    })
  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let request: DataFilterRequest = { filters: filters, sort: this.sort }

    this.subs.sink = this.automatedAllowService.getSubOrgList(request, offset, this.page.limit).pipe(
        catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
        this.rows = response.result;
        this.setPagination(offset, response.count);
        this.cdr.markForCheck();
    });
}

setPagination(offset: number, total: number) {
  this.page.count = total;
  let upperLimit = offset + this.page.limit;
  if (upperLimit > total) {
      upperLimit = total;
  }
  this.page.pageNumber = offset + '-' + upperLimit;
  if (!offset) {
      this.page.offset = 0;
  }
}

setPage(pageInfo: any) {
  let offset = pageInfo.offset * this.page.limit;
  this.page.offset = pageInfo.offset;
  this.getData(this.dataFilter.getDataFilters(), offset);
}

onSort(event: any) {
  if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop]) return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(this.dataFilter.getDataFilters());
  }
}

refreshDataTable() {
  setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
  });
}

dismissModal(modal: any) {
  modal.dismiss('closed');
}

saveIndividualDuration(modal: any){
  if(!this.durationValue||this.durationValue<this.minValue){
    this.dur_LengthError = true;
    return;
  }
  let payload = {
    allowlist_settings:{
    id: this.settings_id,
    call_duration: this.durationValue,
    suborg_id: this.selectedSuborg_id,
    is_active: this.individual_status
    }
  }
  this.subs.sink = this.automatedAllowService.saveIndividualAllowSettings(payload).subscribe(response=>{
    console.log(response);
    this.dur_LengthError = false;
    const foundRow = this.rows.find(bin => bin.id === response.id)
    this.rows[this.rows.indexOf(foundRow)] = response;
    this.toastr.success("Settings Saved Successfully");
    this.refreshDataTable();
  })
  modal.dismiss('closed');
}

open(content: any,selected ) {  
  console.log(selected);
  this.settings_id = selected.allowlist_settings?.id?selected.allowlist_settings.id:null;
  this.selectedSuborg_id = selected.id;
  this.durationValue = selected.allowlist_settings?.call_duration?selected.allowlist_settings.call_duration:this.minValue;
  this.individual_status = selected.allowlist_settings?.is_active;
    
  this.ngbModalRef = this.modalService.open(content, { centered: true });
  let inputField = document.getElementById('durationField') as HTMLInputElement;
  if(inputField){
    inputField.focus();
  }
}

  toggleBanner(){
    this.hideBanner = !this.hideBanner
    localStorage.setItem('isShowBanner',this.hideBanner.toString());
  }

  onSubmit() {
    if(this.isSuperAdmin&&(this.min_call_duration<this.minValue)){
      this.sa_lengthError = true;
      return;
    }else if(this.allSuborgs&&(this.min_call_duration<this.minValue)){
      this.lengthError = true;
      return;
    }else{
      let payload;
      let api: Observable<any>;
      if(this.isSuperAdmin){
        payload = {
          allowlist_settings: {
            id: this.superAdminModel?.allowlist_settings?.id ? this.superAdminModel.allowlist_settings.id : null,
            min_call_duration: this.min_call_duration,
            default_for_all: this.default_for_all
          }
        }
        api = this.automatedAllowService.createAllowSettingsforSuperAdmin(payload);
      }else{
        payload = {
          allowlist_settings: {
            id: this.allSuborgModel?.allowlist_settings_tenant?.id ? this.allSuborgModel.allowlist_settings_tenant.id : null,
            call_duration: this.min_call_duration,
            default_for_all: this.default_for_all
          }
        }
        api = this.automatedAllowService.createAllowSettings(payload);
      }

      this.subs.sink = api.subscribe(resp => {
            this.sa_lengthError = false;
            this.lengthError = false;
            let msg = 'Settings Saved Successfully';
            this.toastr.success(msg);
            this.cdr.markForCheck();
        },
        error => {
          this.toastr.error("Operation Failed");
        });
    }
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }

  ngOnDestroy(): void { this.subs.unsubscribe(); }

}
