import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { ModuleConstants } from '../enums/common.enum';
import { Comments, DataHistory } from '../models/common.models';

@Injectable({
    providedIn: 'root'
})
export class CommonService extends BaseService {

    public mainNavOpen=true;

    constructor(private http: HttpClient) { super() }

    getHistory(module: ModuleConstants, unique_id: number, offset: number = 0, body = null) {
        let url = `${this.baseURL}/history/${unique_id}/${module}/?limit=${this.dataLimit}&offset=${offset}`;
        return this.http.post<{ count: number, result: DataHistory[] }>(url, body);
    }

    getHistoryV2(module:string, unique_id: number, offset: number = 0, body = null) {
        let url = `${this.baseURL}/history/${module}/${unique_id}/?limit=${this.dataLimit}&offset=${offset}`;
        return this.http.post<{ count: number, result: DataHistory[] }>(url, body);
    }

    getHistoryNonGeneric(module:string, unique_id: number, offset: number = 0, body = null) {
        let url = `${this.baseURL}/notgeneric/history/${unique_id}/${module}/?limit=${this.dataLimit}&offset=${offset}`;
        return this.http.post<{ count: number, result: DataHistory[] }>(url, body);
    }

    createComment(comments: Comments[]) {
        return this.http.post<Comments[]>(`${this.baseURL}/core/comments/`, comments);
    }

    updateComment(id: number, comment: Comments) {
        return this.http.put(`${this.baseURL}/core/comments/${id}/`, comment);
    }

    getComments(unique_id: number, module_id: number, suborgId: number) {
        let data = { "unique_id": unique_id, "module_id": module_id, "suborg_id": suborgId };
        return this.http.post<Comments[]>(`${this.baseURL}/core/list_comments/`, data);
    }

    deleteComment(id: number) {
        return this.http.delete(`${this.baseURL}/core/comments/${id}/`);
    }



    set mainNavStatus(status:boolean){
        this.mainNavOpen=status;
    }

    get mainNavStatus(){
        return this.mainNavOpen;
    }
}
