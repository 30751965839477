import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Catalog, catalogFilterRequest, CatalogFilterResponse, CatalogProductFilterResponse, CatalogueCatagoryProductResponse, CatalogueCatagoryResponse, CatalogueCatagoryResponseItems, CatalogueProductListResponse, ProductResponse, SalesCatalogViewFilterResponse } from '../models/catalogue.models';
import { GFilterParam } from 'src/app/core/models/grid-filter.models';
import { CatalogCatDataHelper } from '../helpers/catalogue-data.helpers';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { GridConstants } from 'src/app/core/constants/grid.constants';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService extends BaseService {

  constructor(private http: HttpClient,private ra: ResourceAccessService) {
    super();
  }


  getAllCatalog() {
    return this.http.get<Catalog[]>(`${this.baseURL}/catelogapp/catelog/`);
  }

  filterCatalog(option: any, offset: number = 0, limit: number = this.dataLimit) {
    let url = `${this.baseURL}/catelogapp/catelog/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<CatalogFilterResponse>(url, option);
  }

  createCatalog(data) {
    return this.http.post(`${this.baseURL}/catelogapp/catelog/`, data);

  }

  getCatalogById(id) {
    return this.http.get<{ data: Catalog,associated_products:any,full_check_category:any }>(`${this.baseURL}/catelogapp/catelog/${id}/`);
  }

  updateCatalog(id: number, data) {
    return this.http.put(`${this.baseURL}/catelogapp/catelog/${id}/`, data);
  }

  addAssociatedProducts(id:number,data):any
  {
    return this.http.put(`${this.baseURL}/catelogapp/catelog/${id}/items/`, data);
  }

  getAssociatedProducts(id:number):any
  {
    return this.http.get(`${this.baseURL}/catelogapp/catelog/${id}/items/`);
  }

  filterAssociatedProducts(option: catalogFilterRequest, catalogId:number ) {
    let url = `${this.baseURL}/catelogapp/catelog/${catalogId}/items/`;
    return this.http.post<SalesCatalogViewFilterResponse>(url, option);
  }





  deleteCatalog(id: number) {
    return this.http.delete(`${this.baseURL}/catelogapp/catelog/${id}/`, {});
  }

  getAlllCatalogueProducts() {
    return this.http.get<CatalogueProductListResponse[]>(`${this.baseURL}/catelogapp/catelog/components/`);
  }

  filterCatalogueProducts(option: any = {}, offset: number = 0, limit: number = this.dataLimit) {
    
    let url = `${this.baseURL}/catelogapp/catelog/components/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<CatalogProductFilterResponse>(url, option);
  }


  deleteCatalogueProduct(id: number) {

    return this.http.delete(`${this.baseURL}/catelogapp/catelog/components/${id}/`, {});
  }

  createCatalogueProduct(data) {
    return this.http.post(`${this.baseURL}/catelogapp/catelog/components/`, data);
  }

  updateCatalogueProduct(id: number, data) {
    return this.http.put(`${this.baseURL}/catelogapp/catelog/components/${id}/`, data);
  }

  getCatalogueProduct(id: number) {
    return this.http.get<CatalogueProductListResponse>(`${this.baseURL}/catelogapp/catelog/components/${id}/`);
  }


  getCatalogueCategoryProducts(id: number) {
    let url = `${this.baseURL}/category/${id}/items/lists/`;
    return this.http.get<CatalogueCatagoryProductResponse>(url);
  }

  getCatalogueCategory() {
    let url = `${this.baseURL}/itemcategory/list/`;
    return this.http.get<CatalogueCatagoryResponse[]>(url);

  }



  filterProducts(option: any, offset: number = 0, limit: number = this.dataLimit) {
      // let url = `${this.baseURL}/item/kits/filter/?limit=${limit}&offset=${offset}`;
      let url = `${this.baseURL}/item/kits/filter/?limit=${limit}&offset=${offset}`;
      return this.http.post<ProductResponse>(url,option);
  }

  getPDF(Filename) {

    // let headers = new HttpHeaders();
    // headers = headers.set('Accept', 'application/pdf');
    // return this.http.get(`${this.baseURL}/catelogapp/catelog_download/${Filename}`, { headers: headers, responseType: 'blob' });
     return this.http.get(`${this.baseURL}/catelogapp/catelog_download/?download=${Filename}`,{ responseType: 'blob' });
  }

  getCategories(option: any, offset: number = 0, limit: number = this.dataLimit){
    let url = `${this.baseURL}/itemcategories/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<ProductResponse>(url,option);
  }


  ///filter


  getCatalogSearchColumns(): GFilterParam[] {
    let columns: GFilterParam[] = CatalogCatDataHelper.searchCatalogFilters;
    return this.ra.filterSearchColumns(columns, GridConstants.INV_ITEMS);
  }

  getsearchProductDesignFilters(): GFilterParam[] {
    let columns: GFilterParam[] = CatalogCatDataHelper.searchProductDesignFilters;
    return this.ra.filterSearchColumns(columns, GridConstants.INV_ITEMS);
  }









}
