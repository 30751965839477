<div class="materials-grid-container">
    <!-- <div *ngFor="let section of sections;let index=index">
        <div class="row">
            <div class="col-12 col-md-8 section-title text-info">
                {{section.title}}
            </div>
        </div>
        <ngx-datatable class="bootstrap core-bootstrap" [scrollbarH]="false" [headerHeight]="40" [rows]="section.rows"
        rowHeight="auto" [columnMode]="'force'"
        [sorts]="[{prop:'assigned_date',dir:'desc'}]">
            <ngx-datatable-column name="Image" prop="primary_image">
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="part_number"></ngx-datatable-column>
            <ngx-datatable-column name="Qty/UOM" prop="quantity">
            </ngx-datatable-column>
            <ngx-datatable-column name="Serial Number" prop="quantity">
            </ngx-datatable-column>
            <ngx-datatable-column name="Unit Price" prop="price">
            </ngx-datatable-column>
            <ngx-datatable-column name="Total" prop="created_by" [sortable]="false">
            </ngx-datatable-column>
            <ngx-datatable-column name="Action" prop="created_by" [sortable]="false">
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                    <tr colspan="5">
                        <td>Section Total</td>
                        <td>100</td>
                    </tr>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div> -->
    <div class="col-md-12 group mt-2 p-0">
        <div class="d-flex justify-content-end mb-1">
            <!-- <div class="st-group-header">
                <span style="color: #370D7E;">Material</span>
                <span style="color: #D52095;" class="ml-1">List</span>
            </div> -->
            <div class="st-group-btndiv">
                <button (click)="addSection();"
                class="btn btn-sm st-section-btn">
                    <span class="icon-span">
                        <i class="ft-plus plus-icon" style="color: #370D7E;"></i>
                    </span>
                    Add Section
                </button>
            </div>
        </div>
        <div class="group-body">
            <div class="row m-0" *ngIf="sections&&sections.length>0">
                <div class="table-responsive col-12 p-0" *ngFor="let section of sections; index as index">
                    <div class="d-flex justify-content-between mb-1">
                        <div class="section-title col-md-6 p-0">
                            <input type="text" class="section-text-box edit-box" [(ngModel)]="section.title"
                            placeholder="Enter section title" (change)="onSectionTitleChange(index)">
                        </div>
                        <div class="st-group-btndiv">
                            <button (click)="deleteSectionConfirmation(index)" *ngIf="sections.length>1"
                            class="btn btn-sm st-section-btn ml-1">
                            <span class="icon-span">
                                <i class="ft-trash-2 plus-icon" style="color: #370D7E;"></i>
                            </span>
                                Remove Section
                            </button>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <table class="table invoice-items-table st-items-table">
                            <thead>
                                <tr class="row-head">
                                    <th width="30">#</th>
                                    <th width="90">Image</th>
                                    <th width="240">Name</th>
                                    <th width="90"></th>
                                    <th width="90">Qty</th>
                                    <th width="90">Unit Price</th>
                                    <th width="150" class="text-right">Total</th>
                                    <th width="100">Action</th>
                                </tr>
                            </thead>
                            <tbody cdkDropList (cdkDropListDropped)="drop($event, index)">
                                <ng-container *ngFor="let row of section.rows; index as i;">
                                    <tr *ngIf="!row.is_delete" cdkDrag cdkDragLockAxis="y">
                                        <td cdkDragHandle ><mat-icon>control_camera</mat-icon></td>
                                        <td>
                                            <img *ngIf="row.primary_image" width="80" height="80" [src]="fileUrl + row.primary_image + '.jpg'"/>
                                            <img *ngIf="!row.primary_image" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                                        </td>
                                        <td>
                                            <div class="d-flex; flex-column;">
                                                <div class="item-name">{{row.name}}</div>
                                                <div style="color: #370D7E;margin-top:10px; ">
                                                    <span style="font-size: 12px;">Part-Number :</span>
                                                    <span>
                                                        <a *ngIf="row.type=='item'" [routerLink]="['/inventory/items', row.unique_id, 'view']" target="_blank"
                                                        style="font-weight: 500;opacity: 0.9;">
                                                            {{row.part_number}}
                                                        </a>
                                                        <a *ngIf="row.type=='kit'" [routerLink]="['/inventory/assemblies', row.unique_id, 'view']" target="_blank"
                                                        style="font-weight: 500;opacity: 0.9;">
                                                            {{row.part_number}}
                                                        </a>
                                                    </span>
                                                </div>
                                                <div class="badge badge-info" style="width:fit-content;margin-top: 5px">{{row.from_unique_number}}</div>
                                            </div>
                                        </td>
                                        <td style="height:0;" class="d-flex flex-column justify-content-around h-100">
                                            <div *ngIf="row.from_unique_number" >
                                                <div class="badge badge-info" style="max-width: 300px;width: 100%;">
                                                    Ordered Qty:
                                                    {{row.ordered_quantity}}
                                                </div>
                                                <div class="badge badge-info d-flex flex-column" style="max-width: 300px;width: 100%;margin-top: 6px;">
                                                    <span>Previously Service Ticket Qty:{{row.service_ticket_quantity}}</span>
                                                    <span style="margin-top:2px">(Includes this service ticket)</span>
                                                </div>
                                            </div>
                                            <div *ngIf="row.invoiced_quantity">
                                                <div class="badge badge-info" style="max-width: 300px;width: 100%;margin-top: 6px;">
                                                    Previously Invoiced Qty:
                                                    {{row.invoiced_quantity}}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <input type="number" class="edit-box" [(ngModel)]="row.quantity"
                                            (change)="onQuantityChange(row) && saveSTMaterials('Quantity updated')" style="width: 75px;">
                                            <div class="text-danger error-text font-size-12" *ngIf="row.showErrorMessage&&(row.from_unique_number && row.ordered_quantity < row.qty_tobe_service_ticket)">Quantity can't exceed the limit</div>
                                            <div class="text-danger error-text font-size-12" *ngIf="row.showErrorMessage && row.invoiced_quantity">can't go below invoiced qty</div>
                                            <div class="text-danger error-text font-size-12" *ngIf="row.showErrorMessage&&(row.quantity==0)">Quantity can not be zero</div>
                                            <div *ngIf="row.uom" style="color: #370D7E;margin-top:7px;">
                                                <span style="font-size:12px;">UOM: </span>{{row.uom}}
                                            </div>
                                        </td>
                                        <td class="text-right price-td" style="position:absolute">
                                            <input type="text" class="edit-box"
                                            [(ngModel)]="row.price" (change)="saveSTMaterials('Price updated')"
                                            style="width: 100px;" appTwoDigitDecimaNumber>
                                        </td>
                                        <td class="text-right"><div [ngClass]="row.is_billable? '': 'opacity-25'">{{row.price*row.quantity|currency}}</div></td>
                                        <td>
                                            <div>
                                                <button type="button" class="delete-btn text-danger" (click)="deleteItemConfirmation(row.id, index, i)">
                                                    <i class="ft-trash-2"></i>
                                                </button>
                                                <div class="checkbox checkbox-sm" ngbTooltip="Is Billable">
                                                    <input
                                                        type="checkbox"
                                                        class=""
                                                        (click)="changeIsBillable(row, i)"
                                                        [(ngModel)]="row.is_billable"
                                                        id="billable-check-{{index}}-{{i}}">
                                                    <label
                                                        class=""
                                                        for="billable-check-{{index}}-{{i}}">
                                                        <span>Billable</span>
                                                    </label>
                                                </div>
                                                <div class="checkbox checkbox-sm" ngbTooltip="Show In PDF">
                                                    <input
                                                        type="checkbox"
                                                        class=""
                                                        (click)="changeShowInPdf(row, i)"
                                                        [(ngModel)]="row.is_show_in_pdf"
                                                        id="pdf-check-{{index}}-{{i}}"
                                                        [disabled]="row.is_billable">
                                                    <label
                                                        class=""
                                                        for="pdf-check-{{index}}-{{i}}">
                                                        <span>Show In PDF</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- Preview -->
                                        <div *cdkDragPreview matchSize="true"
                                            class="container dragdrop-placeholder" style="width: 50%;">

                                            <span>{{row.name}}</span>
                                            <span>{{row.part_number}}</span>
                                            <span>{{row.description}}</span>
                                        </div>
                                    </tr>
                                    <tr *ngIf="!row.is_delete">
                                        <td style="border:none;"></td>
                                        <td style="border:none;"></td>
                                        <td colspan="2" style="border:none;">
                                            <div>
                                                <textarea rows="3" [(ngModel)]="row.description" class="form-control material-textarea"
                                                placeholder="write descriptions here" (change)="saveSTMaterials('Description updated')"></textarea>
                                            </div>
                                        </td>
                                        <td colspan="3" style="border:none;">
                                            <div>
                                                <textarea rows="3" [(ngModel)]="row.serial_number" class="form-control material-textarea"
                                                placeholder="enter serial numbers  here" disabled></textarea>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="!section.rows || section.rows.length <= 0">
                                    <td colspan="7">No items selected.</td>
                                </tr>
                                <tr class="summary-tr">
                                    <td colspan="4" class="text-left">
                                        <button (click)="appendRow(index, itemtype.ITEM)"
                                        class="btn btn-sm st-group-btn">
                                        <span class="icon-span">
                                            <i class="ft-plus plus-icon" style="color: #D52095;"></i>
                                        </span>
                                            Add Item
                                        </button>
                                        <button (click)="appendRow(index, itemtype.KIT)"
                                        class="btn btn-sm st-group-btn ml-1">
                                        <span class="icon-span">
                                            <i class="ft-plus plus-icon" style="color: #D52095;"></i>
                                        </span>
                                            Add Assembly
                                        </button>
                                    </td>
                                    <td colspan="2" class="text-right">Section Total</td>
                                    <td class="text-right">{{priceSummaryFunc(index)|currency}}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<item-select #itemSelect [selectBox]="false" (change)="onItemChange($event)" [suborg_id]="suborg_id"></item-select>

<kit-select #kitSelect [selectBox]="false" (change)="onKitChange($event)" [suborg_id]="suborg_id"></kit-select>
