import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SubSink } from 'subsink';
import { AddPaymentValidation, PaymentAdd, PaymentMethods } from '../../models/payments-model';
import { CardsService } from '../../services/cards.service';
import { PaymentsService } from '../../services/payments.service';
import { MakePaymentComponent } from '../../pages/make-payment/make-payment.component';
import * as popup from 'src/app/core/utils/popup.functions';
import { CurrencyPipe } from '@angular/common';
import { AccountingNewPayment } from 'src/app/modules/accounting/models/accounting-model';
import { ActivatedRoute } from '@angular/router';
import { AccountingService } from 'src/app/modules/accounting/services/accounting.service';

@Component({
  selector: 'cash-check-payment',
  templateUrl: './cash-check-payment.component.html',
  styleUrls: ['./cash-check-payment.component.scss']
})
export class CashCheckPaymentComponent implements OnInit {

  private _amount: number;

  get amount() {

    return this._amount ? this._amount : 0;
  }

  @Input() set amount(amount: number) {
    if (amount != null) {
      this._amount = amount;
    }
  }

  @Input() name: string;
  @Input() mobile: number;
  @Input() email: string;
  @Input() invoiceUrlToken?: string;
  @Input() invoiceId?: string;
  @Input() model?: AccountingNewPayment;
  @Input() method?: string;
  @Input() context;

  @Output() onSuccess = new EventEmitter<{ response: any }>();
  @Output() onError = new EventEmitter<any>();

  public subs: SubSink = new SubSink();
  paymentMethods = PaymentMethods;
  public paymentAddModel: PaymentAdd;
  public validationsErrors: AddPaymentValidation = new AddPaymentValidation();
  public over_payment_amount: number = 0;

  constructor(private cardServices: CardsService,
    private services: PaymentsService,
    private cdr: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private accountingServices: AccountingService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.paymentAddModel = new PaymentAdd();
  }

  addPayment(){

    
    let validAmount = this.context.checkIsValidAmount();

    let isValid = validAmount && this.checkValidation()
    if (isValid) {
      let opt = {
        title: `Are you sure to add a payment of ${this.currencyPipe.transform(this.amount, 'USD')} ?`,
        text: '',
        ButtonText: "Proceed",
      };
      popup.ConfirmWithButtonText(opt, result => {
        if (result.isConfirmed) {

          this.paymentAddModel.amount = Number(this.amount);
          this.paymentAddModel.mobile = this.mobile ? this.mobile : null;
          this.paymentAddModel.email = this.email ? this.email : null;
          this.paymentAddModel.name = this.name ? this.name : null;
          this.paymentAddModel.over_payment_amount = this.over_payment_amount;
          this.paymentAddModel.payment_method = this.method;

          if(this.invoiceId){
            this.subs.sink = this.services.makePayment(this.paymentAddModel, this.invoiceId).subscribe(
              resp => {
                this.onSuccess?.emit(resp);
              },
              err => {
                this.onError?.emit(err);
              }
            );
  
          }else if(this.model){

            let url = this.activatedRoute.snapshot.url.join().split(',');
            let customerId = url[1];
          
            this.subs.sink = this.accountingServices.accountingMakePayment(this.paymentAddModel, this.model, customerId).subscribe(
            resp => {
              this.onSuccess?.emit(resp);
            },
            err => {
              this.onError?.emit(err);
            }
          );
  
          }

        }

      });
    }

  }

  checkValidation(){

    return true;

    if (!this.paymentAddModel.deposit_account_name) {

      this.validationsErrors.deposit_account_name = true; return false;
    } else {
      this.validationsErrors.deposit_account_name = false;
    }

    if(this.method == this.paymentMethods.CHECK){

      if (!this.paymentAddModel.check_number) {

        this.validationsErrors.check_number = true; return false;
      } else {
        this.validationsErrors.check_number = false;
      }

    }

    return true;
  }

}
