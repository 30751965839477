import { Component, ElementRef, HostListener, Renderer2, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { fabric } from 'fabric';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { LabelCreateRequest, LabelListResponse } from '../../models/label.models';
import { SubSink } from 'subsink';
import { LabelService } from '../../services/label.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { FilesService } from 'src/app/core/services/files.service';
import { ThumbNail } from 'src/app/core/models/files.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { text } from 'pdfkit';
import * as _ from 'lodash';
import { last, over } from 'lodash';
import { Textbox } from 'fabric/fabric-impl';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'label-create',
  templateUrl: './label-create.component.html',
  styleUrls: ['./label-create.component.scss']
})
export class LabelCreateComponent implements OnInit {

  canvas: any;

  canvas2: any;

  // _category:number;
  public category:number=1;


  @Input() set categoryId(value:number){
    console.log(value);
    this.category=value;
  }



  // set category(value){
  //   this.
  // }

  // get categoryId():boolean{
  //     return this.categoryId;
  // }

  @Input() lockCategory:boolean=false;

  @Output() onCancel=new EventEmitter<any>();

  @Output() onSuccess=new EventEmitter<any>();

  @Output() onFailure=new EventEmitter<any>();

  @ViewChild('categorySelect') categorySelect;

  public labelId:number=0;

  public title:string="";

  public editingMode:boolean=false;


  // public dimention:number=0;

  public width:number=4;

  public height:number=2;

  public subs:SubSink=new SubSink();

  private newLeft:number=10;

  private newRight:number=10;

  public availFields:any;

  public selectedDimention:any;

  public canvasZoom:number;

  public Permissions=PermissionConstants;

  public Resource = ResourceConstants;

  public uploadedImgUrl:string='';

  public LimitedTextbox:any;

  public pickedColor:string;

  public selectedElement:any;

  // public fontSize:number=12;

  public textStyle:any={
    fontSize:12,
    fontWeight:'normal', 
    fontStyle:'normal',
    underline:false

    
  };








  public categoryList=[
                        {id:0, name:'Select value',}, 
                        {id:1, name:'Item',}, 
                        {id:2, name:'Built Assembly',},                  
                        {id:3, name:'Assembly',}                    
                      ];

  public dimentions=[
                        {id:0, name:'Select value',}, 
                        {id:2, name:'4x2"', width:4, height:2,},                    
                        {id:3, name:'5x2"', width:5, height:2,},                    
                        {id:4, name:'6x2"', width:6, height:2,},                    
                        {id:5, name:'4x4"', width:4, height:4,},                    
                        {id:-1, name:'Custom',},                    
                        // {id:2, name:'6x4"',}                    
                      ];
  



  // @ChildView()

  public items=[
    {id:1, title:'Item Name', type:"text", name:"item_name"},
    {id:2, title:'QR Code',type:"img", name:"qr_code"},
    {id:4, title:'Warehouse', type:"text", name:"werehouse"},
    {id:5, title:'Warehouse location', type:"text", name:"werehouse_location"},
    {id:6, title:'Serial Number', type:"text", name:"serial_number"},
    {id:7, title:'SKU', type:"text", name:"sku"},
    {id:8, title:'Item Type', type:"text", name:"item_type" },
    {id:9, title:'Item Category', type:"text", name:"category"},
    {id:10, title:'Manufacturer', type:"text", name:"manufacturer"},
    {id:11, title:'Part number', type:"text", name:"part_number"},
    {id:12, title:'Sell Price', type:"text", name:"sell_price"},
    {id:13, title:'Supplier', type:"text", name:"supplier"},
  ];


  public builtAssebly=[
    {id:1, title:'Built-assembly Name', type:"text", name:"item_name"},
    {id:2, title:'QR Code',type:"img", name:"qr_code"},
    {id:4, title:'Warehouse', type:"text", name:"werehouse"},
    {id:5, title:'Warehouse location', type:"text", name:"werehouse_location"},
    {id:6, title:'Serial Number', type:"text", name:"serial_number"},
    {id:7, title:'SKU', type:"text", name:"sku"},
    {id:8, title:'Built Assembly Type', type:"text", name:"item_type" },
    {id:9, title:'Built Assembly Category', type:"text", name:"category"},
    // {id:10, title:'Manufacturer', type:"text", name:"manufacturer"},
    {id:11, title:'Part number', type:"text", name:"part_number"},
    {id:12, title:'Sell Price', type:"text", name:"sell_price"},
    // {id:13, title:'Supplier', type:"text", name:"supplier"},
  ];

  public assebly=[
    {id:1, title:'Assembly Name', type:"text", name:"item_name"},
    {id:2, title:'QR Code',type:"img", name:"qr_code"},
    {id:4, title:'Warehouse', type:"text", name:"werehouse"},
    {id:5, title:'Warehouse location', type:"text", name:"werehouse_location"},
    {id:6, title:'Serial Number', type:"text", name:"serial_number"},
    {id:7, title:'SKU', type:"text", name:"sku"},
    {id:8, title:'Assembly Type', type:"text", name:"item_type" },
    {id:9, title:'Assembly Category', type:"text", name:"category"},
    // {id:10, title:'Manufacturer', type:"text", name:"manufacturer"},
    {id:11, title:'Part number', type:"text", name:"part_number"},
    {id:12, title:'Sell Price', type:"text", name:"sell_price"},
    // {id:13, title:'Supplier', type:"text", name:"supplier"},
  ];

  public dummyData={
            item_name:"ASUS TUF Gaming NVIDIA GeForce GTX 1650 OC Edition Graphics Card (PCIe 3.0, 4GB GDDR6 Memory, HDMI, DisplayPort, DVI-D, 1x 6-pin Power Connector, IP5X Dust Resistance, Space-Grade Lubricant)",
            werehouse:"Bonadelle Ranchos-Madera Ranchos, California",
            werehouse_location:"Build Alpha, second floor, thired block, fourth rack",
            serial_number:'8ASD9F8S',
            sku:'AS8DFSADF98ASDF',
            item_type:'Assembly',
            category:'Manufacturing Products',
            part_number:'8A9SDF8AS9DF',
            sell_price:'$3324.00',
            manufacturer:'ASUS International',
            supplier:'KINGS GROUP SUPPLIERS OF UNITED STATES',
            plaintext:'Plain text'
          }


      handleDeleteKeyboardEvent(event: KeyboardEvent) {
        // console.log('Pressed delete key'+event.code);

        // if(event.key === 'd'||event.key === 'D'||event.key==='Delete')
        if(event.key==='Delete')
        {
          console.log('Pressed delete key');

          this.deleteElement();
        }
      }

constructor(private renderer: Renderer2, 
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastr:ToastrService, 
    private ra: ResourceAccessService, 
    private labelService:LabelService, 
    private fileService: FilesService,) {




        this.subs.sink = this.activeRoute.params.subscribe(
          params => {
            this.labelId = +params["id"]|| 0;
          }
        );
        

        this.subs.sink = this.activeRoute.queryParams.subscribe(
          params => {
            this.title=params.title||null;
            this.category=params.category||null;
            // this.dimention=params.dimention||null;
            this.width=params.width||null;
            this.height=params.height||null;
            this.editingMode=params.editing||null;
          }
        );

  }



  ngOnInit(): void {

    this.categoryList=[...this.categoryList];

    this.width=4;
    this.height=2;
    // this.category=1;

    if(!this.category){
      this.category=1;
    }

    if(this.category==2){
      this.availFields=this.assebly;
    }
    else{
      this.availFields=this.items;
    }

    // console.log(this.labelId);

    if(this.labelId!=0){
      this.editingMode=true;
      this.edit(this.labelId);

    }
    else{

        this.canvasInit();

    }

  }


  canvasInit(){

    
    this.canvas = new fabric.Canvas('dCanvas');

    this.canvas2 = new fabric.Canvas('dCanvas2');

    this.initTextBox();

    // this.canvas.on('text:changed', window.alert('hi'));

    // this.limitedTextBox();


    this.canvasZoom=Math.round(this.canvas.getZoom()*100);

    if(this.width&&this.height){
      this.canvas.setDimensions({width:this.width*96, height:this.height*96});
      this.canvas2.setDimensions({width:this.width*96, height:this.height*96});
    }
    this.updateCanvasSize();


    // this.canvas.on( 'object:moving', this.preventLeaving);

    


    let preventLeaving=this.preventLeaving;
    let preventTextboxScaling=this.preventTextboxScaling;
    let updateCanvas2=this.updateCanvas2;
    let canvas=this.canvas;
    let canvas2=this.canvas2;
    let dummyData=this.dummyData;
    let wrapCanvasText=this.wrapCanvasText;
    let testFun=this.testFun;
    let checkTextSelection=this.checkTextSelection;

    let checkStyles=this.checkStyles;

    let checkObjModification=this.checkObjModification;

    let textStyle=this.textStyle;
    let athis=this;

    // let wrapCanvasText=this.wrapCanvasText;

    this.canvas.on('object:modified',function(e){
       preventLeaving(e);

    });

    this.canvas.on('after:render',function(e){
      //  preventLeaving(e);
      this.canvas2=updateCanvas2(canvas, canvas2, wrapCanvasText, dummyData);
    });


    this.canvas.on( 'object:scaling', function(e){
       if(preventTextboxScaling(e, canvas, this.getHeight())){
        preventLeaving(e);
       }
       
    });




    this.canvas.on( 'object:resizing', function(e){
       if(preventTextboxScaling(e, canvas, this.getHeight())){
        preventLeaving(e);
       }

       
       
    });
    

    this.canvas.on( 'mouse:up', function(e){

        athis.checkTextSelection(e);

    });

    this.canvas.on('mouse:down', function(e){
      checkObjModification(e,canvas, wrapCanvasText);

    })

    this.canvas.on( 'mouse:dblclick', function(e){
      setTimeout(() => athis.checkTextSelection(e),50); 
    });


    this.canvas.on( 'object:moving', function(e){

      preventLeaving(e);

  });

  this.canvas.on( 'selection:created', function(e){

        checkStyles(e, canvas, textStyle);

  });



    // this.canvas.on( 'before:transform', function(e){
    //   testFun(e);
    // });

    // this.canvas.on('object:modified',);
    // this.canvas.on('selection:created', this.updateTextboxSize);


    var input = document.getElementById('myInput');

    var fn=this;

    // var canvas=this.canvas;

    document.addEventListener('keydown', function(event) {
      //  alert(event.code);
      if (canvas.getActiveObject()&&!canvas.getActiveObject()?.isEditing )
          {
            if (event.code == 'Backspace'||event.code == 'Delete') {
              fn.deleteElement();
            }
          }

    });
  }


  testFun(e){
    // console.log(e);

  //  console.log(e.isCacheDirty());
  }






  initTextBox(){

        this.LimitedTextbox=fabric.util.createClass(fabric.Textbox, {
          drawCacheOnCanvas:function(ctx){
            // console.log(ctx);
            this.height=this.customHeight;
            this.callSuper('drawCacheOnCanvas',ctx);

          },
          _render:function(ctx){
            this.callSuper('_render',ctx);
          },
          _renderControls:function(ctx, styleOverrideopt){
            this.callSuper('_renderControls',ctx);
          },
          initialize:function(text, options){
            this.customHeight=this.height;
            this.callSuper('initialize', text, options);

          },
      });
  }

  



  drop(event: CdkDragDrop<{title: string; poster: string}[]>) {
    // moveItemInArray(this.items, event.previousIndex, event.currentIndex);
     console.log(event);

    //  console.log(this.canvas.);
  }

  addElement(item){

     var obj;

    if(item=='plaintext'){

      var canvas=this.canvas;

        obj = new this.LimitedTextbox("Plain text", {
        width:100,
        height:50,
        left:this.newElementLeft(), 
        top:this.newElementRight(),
        fontSize:14,
        editable: true,
        name:'plaintext',
        customHeight:15,
        // textAlign:item.textAlign,
        scaleX:1, 
        lockScalingY:false,
        scaleY:1,
        styles:{
          0:{
           },
          }
          
    });


      this.canvas.add(obj);
      this.canvas.renderAll();
      this.updateCanvas2(this.canvas, this.canvas2, this.wrapCanvasText, this.dummyData);

    }
    else if(item.type=='text'){
      var canvas=this.canvas;

         obj = new this.LimitedTextbox("<"+item.title+">", {
          width:100,
          height:50,
          left:this.newElementLeft(), 
          top:this.newElementRight(),
          fontSize:14,
          editable: false,
          name:item.name,
          customHeight:15,
          scaleX:1, 
          lockScalingY:false,
          // lockScalingX:true,
          scaleY:1,
            
      });

      

        this.canvas.add(obj);
        this.canvas.renderAll();
        this.updateCanvas2(this.canvas, this.canvas2, this.wrapCanvasText, this.dummyData);

        // this.canvas2.add(obj);
        // this.canvas2.renderAll();




    }
    else if(item.type=='img'&&item.name=="qr_code"){

      var canvas=this.canvas;
      var newElementLeft=this.newElementLeft();
      var newElementRight=this.newElementRight();
  
      fabric.Image.fromURL('../../../../assets/img/label/dummy-qr.png', function(myImg) {
        var img1=myImg.set({left:newElementLeft,top:newElementRight,});
        img1.scaleToHeight(60);
        img1.scaleToWidth(60);
        img1.name='qr_code';
        canvas.add(img1); 
        });
     this.canvas=canvas;
  
    }
  }

  updateTextAlignment(align){
    if(this.canvas.getActiveObject().textAlign!=align){
      this.canvas.getActiveObject().textAlign=align;
       this.canvas.renderAll();
    }
  }

  checkStyles(e, canvas, textStyle){

    if(canvas.getActiveObject()){
        textStyle.fontWeight=canvas.getActiveObject().fontWeight;
        textStyle.underline=canvas.getActiveObject().underline;
        textStyle.fontSize=canvas.getActiveObject().fontSize;
        textStyle.fontStyle=canvas.getActiveObject().fontStyle;
    }

  }

  elementBold(){
    let style;

    if(this.canvas.getActiveObject().getSelectedText().length){

      if(this.getSelectionStyle('fontWeight', 'bold', 'normal')=='bold'){
        style='normal';
      }
      else{
        style='bold';

      }
      this.setSelectionStyle('fontWeight', style);

    }
    else{

      this.unsetSelectionStyle('fontWeight', 'bold');


      if(this.canvas.getActiveObject().fontWeight=='bold'){
            this.canvas.getActiveObject().fontWeight='normal';
      }
      else{
        this.canvas.getActiveObject().fontWeight='bold'
      }

    }




    this.canvas.getActiveObject().dirty=true;

     this.canvas.renderAll();
  }

  elementItalic(){

    let style='';
    if(this.canvas.getActiveObject().getSelectedText().length){

      if(this.getSelectionStyle('fontStyle', 'italic', 'normal')=='italic'){
        style='normal';
      }
      else{
        style='italic';

      }
      this.setSelectionStyle('fontStyle', style);
      this.textStyle.underline=style;


    }
    else{

      this.unsetSelectionStyle('fontStyle', 'italic');

      if(this.canvas.getActiveObject().fontStyle=='italic'){
        this.canvas.getActiveObject().fontStyle='normal';
        this.textStyle.underline='normal';

      }
      else{
        this.canvas.getActiveObject().fontStyle='italic';
        this.textStyle.underline='italic';

      }
      this.canvas.getActiveObject().dirty=true;


    }


    this.canvas.renderAll();
  }

  elementUnderline(){

    let style=false;
    if(this.canvas.getActiveObject().getSelectedText().length){

      if(this.getSelectionStyle('underline', true, false)==true){
        style=false;
      }
      else{
        style=true;

      }
      this.setSelectionStyle('underline', style);
      this.textStyle.underline=style;

    }
    else{

      this.unsetSelectionStyle('underline', true);


          if(this.canvas.getActiveObject().underline)
          {
            this.canvas.getActiveObject().underline=false;
           this.textStyle.underline=false;

          }
          else{
            this.canvas.getActiveObject().underline=true;
           this.textStyle.underline=true;

          }

          this.canvas.getActiveObject().dirty=true;



        }
      this.canvas.renderAll();

  }

  fontSizeChange(){

    if(this.canvas.getActiveObject().getSelectedText().length){

      this.setSelectionStyle('fontSize', this.textStyle.fontSize);

    }
    else{
      this.unsetSelectionStyle('fontSize', this.textStyle.fontSize);

      this.canvas.getActiveObject().fontSize=this.textStyle.fontSize;
    }

    this.canvas.renderAll();


  }

  getSelectionStyle(style, value, defaultVal){

    console.log(this.canvas.getActiveObject().styles);

      if(this.canvas.getActiveObject().getSelectedText()){
        let start=this.canvas.getActiveObject().selectionStart;
        let end=this.canvas.getActiveObject().selectionEnd;
        let count=start;
        let sameCount=0;
        let styleValue, val;
        let isSame=true;

        while(count<end){
          

          if(this.canvas.getActiveObject().styles[0][count]&&this.canvas.getActiveObject().styles[0][count][style]){
              val=this.canvas.getActiveObject().styles[0][count][style];


            if(count!=start&&val!=styleValue){
              isSame=false;
            }

            styleValue=val;
            
          }
          else{
            isSame=false;
          }

          count++;
        }

        if(isSame){
         
          return styleValue;
        }
        else{
          return defaultVal;
        }
           
      }


  }

  setSelectionStyle(style, value){

    console.log(this.canvas.getActiveObject().getSelectedText())
    console.log(this.canvas.getActiveObject().selectionStart)
    console.log(this.canvas.getActiveObject().styles[0])
    

    let start=this.canvas.getActiveObject().selectionStart;
    let end=this.canvas.getActiveObject().selectionEnd;

    let count=start;

    this.textStyle[style]=value;


    console.log(this.canvas.getActiveObject().styles[0]);


     while(count<end){
      if(this.canvas.getActiveObject().styles[0]&&this.canvas.getActiveObject().styles[0][count]){
        if(this.canvas.getActiveObject().styles[0][count][style]){
           this.canvas.getActiveObject().styles[0][count][style]=value;
        }
        else{
         this.canvas.getActiveObject().styles[0][count][style]=value;
        }
     }
     else{
       this.canvas.getActiveObject().styles[0][count]={};
       this.canvas.getActiveObject().styles[0][count][style]=value;
     }
      count++;

     }
    }

  unsetSelectionStyle(style, value){

   
    let count=0;

    console.log(this.canvas.getActiveObject().styles[0]);
    let length=Object.keys(this.canvas.getActiveObject().styles[0]).length;

    console.log(Object.keys(this.canvas.getActiveObject().styles[0]).length);


      while(count<length){

          if(this.canvas.getActiveObject().styles[0]&&this.canvas.getActiveObject().styles[0][count]){
              if(this.canvas.getActiveObject().styles[0][count][style]){
                console.log(this.canvas.getActiveObject().styles[0][count][style]);
                // this.canvas.getActiveObject().styles[0][count][style]=!this.canvas.getActiveObject().styles[0][count][style];
                delete this.canvas.getActiveObject().styles[0][count][style];
              }

          }

          count++;

      }

  }

  checkTextSelection(e){

    if(this.canvas.getActiveObject()&&this.canvas.getActiveObject().type=="textbox"&&this.canvas.getActiveObject().getSelectedText()){

      if(this.canvas.getActiveObject().getSelectedText().length){

        if(this.getSelectionStyle('underline', true, false)==true){
          this.textStyle.underline=true;
        }
        else{
          this.textStyle.underline=false;

  
        }

        if(this.getSelectionStyle('fontStyle', 'italic', 'normal')=='italic'){
          this.textStyle.fontStyle='italic';
        }
        else{
          this.textStyle.fontStyle='normal';
  
        }

        if(this.getSelectionStyle('fontWeight', 'bold', 'normal')=='bold'){
          this.textStyle.fontWeight='bold';
        }
        else{
          this.textStyle.fontWeight='normal';
  
        }

        this.textStyle.fontSize=this.getSelectionStyle('fontSize', this.textStyle.fontSize, 12);


         this.cdr.markForCheck();

  
      }


    }

  }


  // updateElement(attribute, value){

  //   this.canvas.getActiveObject()[attribute]=value;
  //   this.canvas.renderAll();

  // }

  deleteElement(){
    this.canvas.remove(this.canvas.getActiveObject());
    this.updateCanvas2(this.canvas, this.canvas2, this.wrapCanvasText, this.dummyData);

  }

 

  

  keyDown(event){

    console.log(event);

  }


  save(){

    if(this.title==''||!this.title){
      this.toastr.error('Please enter the a title for label design');
    }
    else{
      let data=new ThumbNail();
      data.file=this.convertBase64ToBlob(this.canvas.toDataURL('image/png'));
      data.name=this.title;
      let module_name='LABELS';
      
       this.fileService.uploadFiles([data], module_name).subscribe(
        response=>{
            console.log(response[0].id);
            this.uploadedImgUrl=response[0].id;
            this.saveData(response[0].id);
        },
        ()=>{
          return '';
        }
        
      );
    }


  }
  
  saveData(imageUrl:string){



     let label=new LabelCreateRequest();

     label.image=imageUrl;
    
    //To persis additional fields added in the canvas object
     const originalToObject = fabric.Object.prototype.toObject;
     const myAdditional = ['name', 'customHeight'];
     fabric.Object.prototype.toObject = function (additionalProperties) {
         return originalToObject.call(this, myAdditional.concat(additionalProperties));
     }
    //To persis additional fields added in the canvas object
     

     label.template=this.canvas.toJSON();


     label.title=this.title;
     
     if(this.category==1){
      label.type='item';
     }
     else if(this.category==2){
      label.type='builtAssembly';
     }
     else{
      label.type='assembly'
     }
    //  label.type=this.category==1?'item':'kit';


     label.customer=this.labelService.customerId;

     if(this.width&&this.height){
      label.width=this.width*96;
      label.length=this.height*96;
     }
     else{

      label.width=this.canvas.width;

      label.length=this.canvas.height;
     }


     label.suborg=this.labelService.suborgId;
     
    //  console.log(JSON.stringify(this.canvas));

    let api = this.labelId>0
    ? this.labelService.updateLabel(this.labelId, label)
    : this.labelService.createLabel(label);

     this.subs.sink=api.subscribe(
      response=>{
          this.onSuccess.emit(response);
        
      }
      
     )

  }

  // uploadLabelImage(){

  //   let data=new ThumbNail();
  //   data.file=this.convertBase64ToBlob(this.canvas.toDataURL('image/png'));
  //   data.name=this.title;
  //   let module_name='LABELS';
    
  //   return this.fileService.uploadFiles([data], module_name).subscribe(
  //     response=>{
  //         console.log(response[0].url);
  //         return this.uploadedImgUrl=response[0].url;
  //     },
  //     ()=>{
  //       return '';
  //     }
      
  //   );

  // }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }




  edit(id:number){

    var label:LabelListResponse;


    this.subs.sink=this.labelService.getLabel(id).subscribe(
     response=>{
        
       label=response;
       this.title=label.title;
       if(label.type=='item'){
        this.category=1;
       }
       else if(label.type=='builtAssembly')
       {
        this.category=2;
       }
       else if(label.type=='assembly'){
        this.category=3
       }
      //  this.category=label.type=='item'?1:2;
      //  this.dimention=2;
       this.editingMode=true;

        if(label.template!=null){

        this.canvasInit();

        this.canvas.setWidth(label.width);
        this.canvas.setHeight(label.length);

        this.canvas2.setWidth(label.width);
        this.canvas2.setHeight(label.length);

        this.canvasZoom=Math.round(this.canvas.getZoom()*100);




        let load=this.canvas.loadFromJSON(label.template, this.canvas.renderAll.bind(this.canvas), function(o, object) {
        
          if(object.type=="textbox"&&object.name=="item_name"){
              object.height=o.height;
          }

        });

        this.canvas=this.canvas;



              
        if(load){
        this.canvas2=this.updateCanvas2(this.canvas, this.canvas2, this.wrapCanvasText, this.dummyData);
          
        }

        

        


        this.updateCanvasSize();
        // this.canvas2.renderAll();







        

         this.width=label.width/96;
         this.height=label.length/96;






        }

        this.cdr.markForCheck();
      
      //  this.canvas=label.template!=null?JSON.parse(label.template):this.canvas;
     }
    )

 }


 updateCanvasSize(){

    let newZoom=1;
    let loopCount=0;

    while(newZoom>.2){
      // console.log('inside looop');
      if(newZoom*this.canvas.width<350){
        newZoom=newZoom+0.15;
      }
      else if(newZoom*this.canvas.width>500){
        newZoom=newZoom-0.15;
      }
      else if(loopCount>50){
        break;
      }
      else{
        break;
      }

    }
    // console.log('new Zoom:'+newZoom);
    this.canvas.setZoom(newZoom);
    this.canvas.setWidth(this.canvas.width * newZoom);
    this.canvas.setHeight(this.canvas.height * newZoom);


    this.canvas2.setZoom(newZoom);
    this.canvas2.setWidth(this.canvas2.width * newZoom);
    this.canvas2.setHeight(this.canvas2.height * newZoom);


    this.canvasZoom=Math.round(this.canvas.getZoom()*100);






  

 }



 newElementLeft(){


  if((this.newLeft+200<this.canvas.width)){
    this.newLeft+=10;
    return this.newLeft;
  }
  else{
    return this.newLeft=this.canvas.width/3;
    
  }


 }

 newElementRight()
 {
  if((this.newRight+50<this.canvas.height)){
    this.newRight+=10;
    return this.newRight;
  }
  else{
    return this.newRight=this.canvas.height/3;
    
  }
 }

 zoomChanged(newValue:number){

  this.canvas.setZoom(newValue/100);
  this.canvas.setWidth(this.width*96*this.canvas.getZoom());
  this.canvas.setHeight(this.height*96*this.canvas.getZoom());



  this.canvas2.setZoom(newValue/100);
  this.canvas2.setWidth(this.width*96*this.canvas2.getZoom());
  this.canvas2.setHeight(this.height*96*this.canvas2.getZoom());
  
  this.canvasZoom=Math.round(this.canvas.getZoom()*100);
 }

 changeDimention(){
  this.canvas.setWidth(this.width*96*this.canvas.getZoom());
  this.canvas.setHeight(this.height*96*this.canvas.getZoom());

  this.canvas2.setWidth(this.width*96*this.canvas2.getZoom());
  this.canvas2.setHeight(this.height*96*this.canvas2.getZoom());

  this.canvasZoom=Math.round(this.canvas.getZoom()*100);
 }

 changeType()
 {
  if(this.category==1){
    this.availFields=this.items;
  }
  else if(this.category==2){
    this.availFields=this.builtAssebly;
  }
  else{
    this.availFields=this.assebly;
  }
 }

 checkObjModification(e, canvas, wrapText){


  console.log(e);

    // if(canvas.activeObject()){
    //   if(canvas.activeObject().name=="plaintext"){
    //     canvas.activeObject().text=wrapText(canvas.activeObject().text, e.target.canvas, e.target)
    //   }
    // }


    // if(e.target.name=='plaintext'){
    //   e.target.text=wrapText(e.target.text, e.target.canvas, e.target)
    // }

 }


  preventLeaving(e) {

    console.log(e, 'pl start');


    // console.log("PLeaving:- H: "+e.target.height+", CH:"+e.target.customHeight+", scaleY:"+e.target.scaleY);

    // this.selectedElement=e.target;


 


      var activeObject = e.target;
      
      //x-axis calculations
      /*
        Condition: If width of object is larger than canvas

       */
    
      if(e&&e.transform&&e.transform.action!="drag"){
        return;
      }
    
      if ((activeObject.width * activeObject.scaleX) > e.target.canvas.width)
      {
        let scaleX=1;



          activeObject.set('scaleX', scaleX);
          if(activeObject.left>0){
            activeObject.set('width', e.target.canvas.width-activeObject.left);

          }
          else{

            if((activeObject.width+activeObject.left)<e.target.canvas.width){
              activeObject.set('width', activeObject.width+activeObject.left);
            }
            else{
              activeObject.set('width', e.target.canvas.width);

            }
            activeObject.set('left', 0);

          }

      }
      else 
      if ((activeObject.left + (activeObject.width * activeObject.scaleX)) > e.target.canvas.width)
      {
          var positionX = e.target.canvas.width - (activeObject.left + (activeObject.width * activeObject.scaleX));

          if(activeObject.left>0){
              activeObject.set('left', activeObject.left + positionX);

            // activeObject.set('width', e.target.canvas.width-activeObject.left);

          }
          else{
            activeObject.set('left', 0);
            activeObject.set('width', activeObject.width+positionX);

          }


      }
      else if ((activeObject.left < 0)){
        // activeObject.set('width', activeObject.width+activeObject.left);
        activeObject.set('left', 0);
        activeObject.set('width', activeObject.width);


      }




      if ((activeObject.height * activeObject.scaleY) > e.target.canvas.height)
      {
        let scaleY=1;

          if(activeObject.top>=0){
            
             activeObject.set('height', e.target.canvas.height-activeObject.top);
             activeObject.set('customHeight', e.target.canvas.height-activeObject.top);

          }
          else{
            activeObject.set('height', e.target.canvas.height+activeObject.top);
            activeObject.set('customHeight', e.target.canvas.height+activeObject.top);

            activeObject.set('top', 0);

          }

          activeObject.set('scaleY', scaleY);



      }
      else if (activeObject.top + (activeObject.customHeight * activeObject.scaleY) > e.target.canvas.height)
      {
        let top= activeObject.top;


          var positionY = (top + (activeObject.height * activeObject.scaleY))-e.target.canvas.height;


          if(top>0){

                // activeObject.set('height', e.target.height-positionY);
                // activeObject.set('customHeight', e.target.height-positionY);



              if((top-positionY)>=0){

                activeObject.set('top', top-positionY);
                // activeObject.set('height', e.target.height-positionY);
                // activeObject.set('customHeight', e.target.height-positionY);


              }
              else{
                activeObject.set('top', 0);
                activeObject.set('height', e.target.canvas.height);
                activeObject.set('customHeight', e.target.canvas.height);

              }
              

          }
          else{
            activeObject.set('height', e.target.canvas.height);
            activeObject.set('customHeight', e.target.canvas.height);

            activeObject.set('top', 0);



          }
          
          
          // activeObject.set('scaleY', 1);
          // activeObject.set('height', e.target.canvas.height-activeObject.top);


      }else if ((activeObject.top < 0)){

        activeObject.set('top', 0);



         
      }

      if(e.pointer){
        // e.pointer.x=0;
        // console.log(e.pointer, 'pointer');

      }

      console.log(e, 'pl end');


    }




   //New login to preventing text scaling in textbox
    preventTextboxScaling(e, canvas, getHeight){

      if(e&&e.transform&&e.transform.action=="drag"){
        return false;
      }


            // console.log(e);
            let lastHeight, lastWidth, objBottom, objRight, newLeft, newWidth, newHeight, newTop;

            if(e.target.type=="textbox"&&e.transform.action=="scaleY"){
              console.log("P Scaling:- H: "+e.target.height+", CH:"+e.target.customHeight+", scaleY:"+e.target.scaleY);


                if(e.transform.corner=="mb"||e.transform.corner=="mt"){
                  lastHeight = e.target.customHeight * e.target.scaleY;
                  lastWidth = e.target.width * e.target.scaleX;
                  newTop=e.target.top;

                  objBottom=e.target.top+lastHeight;

                  // if(lastHeight>e.target.canvas.height){
                  //   lastHeight=e.target.canvas.height;
                  // }

                  if(e.transform.corner=="mt"&&e.target.top<0){
                        newTop=0;
                        lastHeight=e.target.customHeight;
                        console.log('top is below zero');
            
                  }
                  else if(e.transform.corner=="mb"&&objBottom>canvas.height){

                        lastHeight=e.target.customHeight;


                  }
                  // else{

                  // }

                  // console.log('scaleY:- last Height:'+lastHeight+', last Width:'+lastWidth+', width:'+e.target.width+', Height:'+e.target.height+', scaleY:'+e.target.scaleY+", scaleY:"+e.target.zoomY);
                  e.target.set({
                    height:lastHeight,
                    customHeight:lastHeight,
                    top:newTop,
                    scaleY: 1,
                   });

                  //  canvas.getActiveObject().height=newHeight;

                }
                else{
                  console.log('You are here here');
                }

                // e.target.canvas.renderAll();

      
                 
              
            }
            else if(e.target.type=="textbox"&&e.transform.action=="scaleX"){

                console.log('You are here');

            }
            else if(e.target.type=="textbox"&&e.transform.action=='scale'){

              console.log("P Scaling:- H: "+e.target.height+", CH:"+e.target.customHeight+", scaleY:"+e.target.scaleY);

              lastHeight = e.target.customHeight * e.target.scaleY;
              lastWidth = e.target.width * e.target.scaleX;

              newTop=e.target.top;
              newLeft=e.target.left;
              newWidth=lastWidth;
              newHeight=lastHeight;

              objRight=e.target.left+(e.target.width * e.target.scaleX);

              objBottom=e.target.top+(e.target.height*e.target.scaleY);

               if(e.target.left<0){
                  newLeft=0;
                  newWidth=e.target.width;
               }
              if(objRight>canvas.width){
                let overFlow=objRight-canvas.width;

                // newLeft=e.target.left;
                newWidth=canvas.width-e.target.left;
               }

               if(objBottom>canvas.height){
                let overFlow=objBottom-canvas.height;
                // newTop=e.target.top;
                newHeight=canvas.height-e.target.top;

               }


              if(e.target.top<0){
                newTop=0;
                newHeight=e.target.height;
               }



              e.target.set({
                width:newWidth,
                customHeight:newHeight,
                top:newTop,
                left:newLeft,
                scaleY: 1,
                scaleX: 1,
               });
               canvas.getActiveObject().height=newHeight;

              //  canvas.renderAll();
            }
            else if(e.target.type=="textbox"&&e.transform.action=="resizing"){

              // console.log("h:"+getHeight.height);
              console.log("P Scaling:- w: "+e.target.width+", scaleX:"+e.target.scaleX);


              let newWidth, newLeft;

              lastHeight = e.target.customHeight * e.target.scaleY;
              lastWidth = e.target.width * e.target.scaleX;

              objRight=e.target.left+lastWidth;

              // console.log('Scale H:- last Height:'+lastHeight+', last Width:'+lastWidth+', width:'+e.target.width+', Height:'+e.target.height+', scaleY:'+e.target.scaleY+", scaleX:"+e.target.scaleX);


              // if(lastHeight>e.target.canvas.height){
              //   lastHeight=e.target.canvas.height;
              // }

              if(objRight>canvas.width){
                newLeft=e.target.left;
                newWidth=lastWidth-(objRight-canvas.width);
              }
              else if(e.target.left<0){
                newLeft=0;
                newWidth=e.target.width+e.target.left;
              }
              else{
                newLeft=e.target.left;
                newWidth=lastWidth;
              }

               

              e.target.set({
                // height:lastHeight,
                customHeight:lastHeight,
                width:newWidth,
                left:newLeft,
                scaleY: 1,
                scaleX: 1,
               });
               canvas.getActiveObject().height=lastHeight;

            }

            return true;
    }
    //New login to preventing text scaling in textbox



    updateCanvas2(canvas1, canvas2, wrapCanvasText, dummyData){

      
      // console.log('Inside updateCanvas2');

      // console.log(dummyData);

        //To persis additional fields added in the canvas object
        const originalToObject = fabric.Object.prototype.toObject;
        const myAdditional = ['name'];
        fabric.Object.prototype.toObject = function (additionalProperties) {
            return originalToObject.call(this, myAdditional.concat(additionalProperties));
        }
       //To persis additional fields added in the canvas object
        
      //  console.log(canvas1.toJSON());
     
      var template=canvas1.toJSON();


      template.objects.forEach((element) => {
          // console.log(element);
         if(element.type=='textbox'||element.type=='i-text'){


          var canvas=canvas2;
          var formatted1;

            // console.log(element.name);
            if(element.name=='plaintext'){
              element.text = wrapCanvasText(element.text, canvas, element);
            }
            else if(element.name=='item_name'||element.name=='assembly_name'){
              element.text = wrapCanvasText(dummyData.item_name, canvas, element);
            }
            else if(element.name=='werehouse'){
              element.text = wrapCanvasText(dummyData.werehouse, canvas, element);
            }
            else if(element.name=='werehouse_location'){
              element.text = wrapCanvasText(dummyData.werehouse_location, canvas, element);
            }
            else if(element.name=='serial_number'){
              element.text = wrapCanvasText(dummyData.serial_number, canvas, element);
            }
            else if(element.name=='sku'){
              element.text = wrapCanvasText(dummyData.sku, canvas, element);
            }
            else if(element.name=='item_type'){
              element.text = wrapCanvasText(dummyData.item_type, canvas, element);
            }
            else if(element.name=='category'){
              element.text = wrapCanvasText(dummyData.category, canvas, element);
            }
            else if(element.name=='part_number'){
              element.text = wrapCanvasText(dummyData.part_number, canvas, element);
            }
            else if(element.name=='sell_price'){
              element.text = wrapCanvasText(dummyData.sell_price, canvas, element);
            }
            else if(element.name=='supplier'){
              element.text = wrapCanvasText(dummyData.supplier, canvas, element);
            }
            else if(element.name=='plaintext'){
              element.text = wrapCanvasText(element.text, canvas, element);
            }
            else if(element.name=='manufacturer'){
              element.text = wrapCanvasText(dummyData.manufacturer, canvas, element);
            }
            
         }
         element.editable=false;
         element.selectable=false;
      });

      canvas2.loadFromJSON(template, canvas2.renderAll.bind(canvas2), function(o, object) {

     });

    return canvas2;
    }


    wrapCanvasText(t, canvas, element) {

      //  console.log("H is here"+element)

      let maxH=0, maxW=0;

      if (typeof maxH === "undefined") {
          maxH = 0;
      }
      else{
        maxH=element.height;
      }

      if (typeof maxW === "undefined") {
        maxW = 0;
      }
      else{
        maxW=element.width;
      }
  
      // var words = t.text.split(" ");
      var words = t.split(" ");
      var formatted = '';
  
      // clear newlines
      // var sansBreaks = t.text.replace(/(\r\n|\n|\r)/gm, "");  
      var sansBreaks = t.replace(/(\r\n|\n|\r)/gm, "");
      // calc line height
      // var lineHeight = new fabric.Text(sansBreaks, {
      //     fontFamily: t.fontFamily,
      //     fontSize: 25 //t.fontSize
      // }).height;
  
      var lineHeight=element.lineHeight;
      // var lineHeight=2;
      
      // adjust for vertical offset
      var maxHAdjusted = maxH > 0 ? maxH - lineHeight : 0;
      var context = canvas.getContext("2d");

      // console.log('maxh adjustes:'+maxHAdjusted);
  
  
      context.font = element.fontSize + "px " + element.fontFamily;
      var currentLine = "";
      var breakLineCount = 0;
  
      for (var n = 0; n < words.length; n++) {
  
          var isNewLine = currentLine == "";
          var testOverlap = currentLine + ' ' + words[n];

        // console.log('maxW:'+maxW+', maxH:'+maxH+' current Word:'+n+", testOverlap:"+w+", word:"+words[n]+", font:"+t.fontSize + "px " + t.fontFamily);

  
          // are we over width?
          var w = context.measureText(testOverlap).width;
  
          if (w < maxW) { // if not, keep adding words
              currentLine += words[n] + ' ';
              formatted += words[n] += ' ';
          } else {
  
              // if this hits, we got a word that need to be hypenated
              if (isNewLine) {
                  var wordOverlap = "";
  
                  // test word length until its over maxW
                  for (var i = 0; i < words[n].length; ++i) {
  
                      wordOverlap += words[n].charAt(i);
                      var withHypeh = wordOverlap + "-";
  
                      if (context.measureText(withHypeh).width >= maxW) {
                          // add hyphen when splitting a word
                          withHypeh = wordOverlap.substring(0, wordOverlap.length - 2) + "-";
                          // update current word with remainder
                          words[n] = words[n].substring(wordOverlap.length - 1, words[n].length);
                          formatted += withHypeh; // add hypenated word
                          break;
                      }
                  }
              }
              n--; // restart cycle
              formatted += '\n';
              breakLineCount++;
              currentLine = "";
          }

          var adjustedBreakLine=breakLineCount==1?breakLineCount:breakLineCount+1;

          var formattedTextboxHeight=(context.measureText(t).fontBoundingBoxAscent+context.measureText(t).fontBoundingBoxDescent)*(breakLineCount+1)*lineHeight;

          // console.log('formattedTextboxHeight: '+formattedTextboxHeight);

          // console.log('line break count'+breakLineCount);
          
          if (breakLineCount>0&&maxHAdjusted > 0 && formattedTextboxHeight > maxHAdjusted) {
              // add ... at the end indicating text was cutoff
              formatted = formatted.substring(0, formatted.length - 3) + "...\n";
              break;
          }
      }
      // get rid of empy newline at the end
      formatted = formatted.substring(0, formatted.length - 1);


  
      return formatted;
  }

    // updateTextboxSize(textbox) {	
    //   console.log(textbox);


    //   let lastHeight;
    
    //   //mr and ml are the only controlPoints that dont modify textbox height
    //   if( controlPoint && controlPoint != "mr" && controlPoint != "ml"){
    //       lastHeight =  textbox.height * textbox.scaleY;			
    //   }
    
    //   textbox.set({		
    //      height: lastHeight || textbox.height,
    //    scaleY:1				
    //   });
      
    //   this.canvas.renderAll();
    // };

    cancel(){
      this.onCancel.emit();
    }



    hasPermission(resource: string, permission: string) {
      return this.ra.hasPermission(resource, [permission, this.Permissions.GLOBAL]);
    }

    ngOnDestroy(){
        this.subs.unsubscribe();
    }
}
