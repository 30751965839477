<section *ngIf="model">
    <form #unitForm="ngForm" (ngSubmit)="unitForm.form.valid && onSubmit()">
        <div class="form-group">
            <label class="val-required">Unit</label>
            <input type="text" [(ngModel)]="model.units" [maxlength]="10" #unit="ngModel" name="units" class="form-control"
                   required>
            <small class="form-text text-muted danger" *ngIf="unit.invalid && (unit.dirty || unit.touched)">
                <div *ngIf="unit.errors.required">
                    Unit is required.
                </div>
            </small>
            <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea type="text" [(ngModel)]="model.description" #desc="ngModel" name="description"
                      class="form-control"></textarea>
        </div>
        <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!unitForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                {{unitId > 0 ? 'Save Changes' : 'Save'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</section>
