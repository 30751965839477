import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { ItemDataHelper } from './../../../helpers/item-data.helpers';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { Item, ItemWrapper } from '../../../models/items';
import { ItemService } from '../../../services/item.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.scss']
})
export class ItemSelectComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('itemsModal') itemsModal: any;

  @Input() value: number;

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<ItemWrapper>();

  @Input() selectBox: boolean = true;

  @Input() suborg_id: number;

  @Input() resetForCallcenter: boolean = false;

  public SelectionType = SelectionType;

  public fileUrl: string = environment.apiURL + '/static/files/';

  public items: ItemWrapper[];

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = ItemDataHelper.DefaultFilters;

  public search: GFilterParam[] = ItemDataHelper.SearchFilters;

  public sortLookup = ItemDataHelper.SortLookup;

  public selectedItem: ItemWrapper[] = [];

  public selected: ItemWrapper;

  public sort: GSortParam[];

  public columns: GFColumn[] = ItemDataHelper.FilterColumns;

  private subs: SubSink = new SubSink();

  private modalRef: NgbModalRef;

  public scrollbarH:boolean=false;
    modalRefPopup: any;

  constructor(private modal: NgbModal,
    private itemService: ItemService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService,
    private toastr:ToastrService) {
      this.scrollbarH = this.device.isMobile();
    }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue > 0) {
      this.setSelected(changes.value.currentValue);
    }else{
      if(this.resetForCallcenter)
      {
      this.selected=null;
      }
    }
  }

  setSelected(id: number) {
    if (this.selected) return;
    this.subs.sink = this.itemService.getById(id).subscribe(item => {
      this.selected = { ...item };
      this.cdr.markForCheck();
    });
  }

  open() {
    this.modalRef = this.modal.open(this.itemsModal, { size: 'xl', scrollable: true });
    if (!this.items || this.items.length <= 0) {
      this.getData(null);
    }
  }

  private getData(filters: GFilterParam[], offset: number = 0) {
    let option = { sort: this.sort, filters: filters };
    this.subs.sink = this.itemService.getAll(option, offset, 50, this.suborg_id).subscribe(resp => {
      this.items = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  onSort(event: any, dataFilter: DataGridFilterComponent) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(dataFilter.getDataFilters());
    }
  }

  setPage(pageInfo: any, dataFilter: DataGridFilterComponent) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(dataFilter.getDataFilters(), offset);
  }

  onItemSelected() {
    if (this.selectedItem && this.selectedItem.length > 0) {
      let item = this.selectedItem[0];
      this.value = item.id;
      this.selected = item;
      this.valueChange?.emit(item.id);
      this.change?.emit(item);
    }
    this.modalRef?.dismiss();
  }

  onCanceled() {
    this.modalRef?.dismiss();
  }

  clearSelection(event: any) {
    event.stopPropagation();
    this.selected = null;
    this.valueChange?.emit(null);
    this.selectedItem = [];
  }

  openModal(content: any){
    this.modalRefPopup = this.modal.open(content, { centered: true, scrollable: true, size: "xl" });
  }

  onDataSuccess(result: { response: Item, message: string }): void {
    this.toastr.success('Success', result?.message);
    this.modalRefPopup?.dismiss()
    this.selectedItem.push(result.response as ItemWrapper)
    this.onItemSelected();
    }

    onDataError(error: any): void {
        console.error(error);
        this.toastr.error('Failed', 'Operation failed');
        this.modalRefPopup?.dismiss()
    }

    onCancel(): void {
        this.modalRefPopup?.dismiss()
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
