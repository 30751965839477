import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat } from 'lodash';
import { GFColumn, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { ServiceTicketDataHelper } from 'src/app/modules/service-ticket/helper/service-ticket-data.helper';
import { ServiceTicket, ServiceTicketStatus, STStatusHelper, STStatusMapItem } from 'src/app/modules/service-ticket/models/service-ticket';
import { ServiceTicketService } from 'src/app/modules/service-ticket/services/service-ticket.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'customer-info-st',
  templateUrl: './customer-info-st.component.html',
  styleUrls: ['./customer-info-st.component.scss']
})
export class CustomerInfoStComponent implements OnInit {

  @Input() tabHeaderHeight:number;

  @Input() set customerId(value:number){
    this._customerId=value;
  };

  @Input() showNewButton : boolean = true;

  @Input() uniqueId : number = 0;

  @Input() filter: GFilterParam[] = []

  public StatusMap = STStatusHelper.maping;

  public selectedStatus: STStatusMapItem;

  public STStatus = ServiceTicketStatus;

  public _event:Event;

  public _st:ServiceTicket[]=[];

  public selected: ServiceTicket= null

  public subs:SubSink=new SubSink();

  public _customerId:number;

  public customerContact:number;

  public customerLocation:number;

  public priority:number=null;

  public reasonForService:number;

  public search:string="";

  public showFilter:boolean=false;

  public resultCount:number=0;

  public resultTotalCount:number=0;

  public searchOffset=0;

  public filterActive:boolean=false;

  public columns: GFColumn[] = ServiceTicketDataHelper.columns;

  public searchData: GFilterParam[] = ServiceTicketDataHelper.search[0].children;

  public statusList:any[]=[];

  public status:number;


  constructor(private stService:ServiceTicketService, private cdr:ChangeDetectorRef,
    private modalService:NgbModal) { }

  ngOnInit(): void {

    // this.customerId=this.stService.customerId;

    this.searchST();
    this.getStatusList();



  }

  ngAfterViewInit(){



  }


  getStatusList(){
    this.columns.forEach((val, index)=>{

      if(val.displayName=='Status'){

        this.statusList=val.options;
      }
    })
  }


  searchST(plainSearch=false,loadMore=false){

    let request={ sort:null, filters: null };

    let searchData=[];

    let tmp;


    if(this.search!=''){
      this.searchData.forEach((val, index)=>{
        tmp={...val};
        tmp.value=this.search.toString();
        searchData.push(tmp);
      })
    }
    searchData = searchData.concat(this.filter)

    if(plainSearch==false){


      if(this.customerContact){
        searchData.push({colname: 'tenant_customer_contact', condition: 'equalto', value: this.customerContact, operator: 'AND' });
      }

      if(this.customerLocation){
        searchData.push({colname: 'tenant_customer_location', condition: 'equalto', value: this.customerLocation, operator: 'AND' });
      }

      if(this.priority){
        searchData.push({colname: 'priority', condition: 'equalto', value: this.priority, operator: 'AND' });
      }

      if(this.reasonForService){
        searchData.push({colname: 'reason_for_call', condition: 'equalto', value: this.reasonForService, operator: 'AND' });
      }
      if(this.status){
        searchData.push({colname: 'status', condition: 'equalto', value: this.status, operator: 'AND' });
      }



    }

    searchData.push({colname: 'tenant_customer', condition: 'equalto', value: this._customerId, operator: 'WHEN' });



    request.filters=searchData;
    request.sort = [{colname: "created_on", direction: "desc"}]

    if(loadMore){
      this.searchOffset+=50;
    }




   this.subs.sink= this.stService.getAll(request, this.searchOffset).subscribe(
      response=>{
          this._st=response.result;

          this.checkFilterActive();
          if(loadMore){
            this._st=response.result=concat(response.result, this._st);


          }
          else{
            this._st=response.result;


          }

          this.resultTotalCount=response.count;

          this.resultCount=response.result.length;

          this.cdr.markForCheck();

      }
    );

    return false;

  }

  customerContactChange(event){

    if(event&&event.id){
      this.customerContact=event?.id;
    }
    else{
      this.customerContact=null;
    }


  }

  customerLocationChange(event){

    if(event&&event.id){
      this.customerLocation=event?.id;

    }
    else{
      this.customerLocation=null;
    }

  }

  changeReason(event){

    if(event&&event.id){
      this.reasonForService=event.id;
    }
    else{
      this.reasonForService=null
    }

  }

  changePriority(id){

    if(id){
      this.priority=id;
    }
    else{
      this.priority=null;
    }


  }

  checkFilterActive(){

    if(this.status||this.priority||this.reasonForService||this.customerContact||this.customerLocation){
      this.filterActive=true;
    }
    else{
      this.filterActive=false;
    }
  }


  clearSearchInput(){

    this.search='';
    this.searchST(true);

  }

  resetFilter(){

    this.customerContact=null;
    this.customerLocation=null;
    this.search='';
    this.showFilter=false;
    this.reasonForService=null;
    this.priority=null;
    this.status=null;

    this.searchST(false);

    this.checkFilterActive();



  }

  toggleFilter(){
    this.showFilter=!this.showFilter;
    this.cdr.markForCheck();
  }

  openSTView(modal_content: any){
    this.modalService.open(modal_content, { centered: true, scrollable: true, size: "xl" })
  }

  onCloseInvoiceView(){
    this.modalService.dismissAll()
  }

  ngOnDestroy(){

    this.subs.unsubscribe();

  }

}
