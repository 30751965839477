import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { LocationSystemType } from '../../../models/location-types';
import { LocationTypeService } from '../../../services/location-type.service';

@Component({
  selector: 'location-system-type-form',
  templateUrl: './location-system-type-form.component.html',
  styleUrls: ['./location-system-type-form.component.scss']
})
export class LocationSystemTypeFormComponent implements OnInit,AfterViewInit, OnDestroy {

  @ViewChild('name', { static: false, read: ElementRef }) nameControl: ElementRef;

  public model: LocationSystemType = new LocationSystemType();

  @Input() locationSystemTypeId: number = 0;

  @Output() success = new EventEmitter<{ response: LocationSystemType, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private service: LocationTypeService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.locationSystemTypeId > 0) {
      this.getSub$ = this.service.getSystemTypeById(this.locationSystemTypeId).subscribe(LocationSystemType => {
        this.model = LocationSystemType;
      });
    }
  }

  ngAfterViewInit(): void {
    this.nameControl?.nativeElement.focus();
  }

  onSubmit(): void {

    let api: Observable<LocationSystemType>;

    if (this.locationSystemTypeId > 0)
      api = this.service.updateSystemType(this.locationSystemTypeId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;
      api = this.service.createSystemType(this.model);
    }

    this.postSub$ = api.subscribe(
      response => {
        let msg = this.locationSystemTypeId > 0 ? 'Location System Type info updated' : 'New Location System Type created';
        this.nameExist = false;
        this.success?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void { this.cancel?.emit(); }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
    this.postSub$?.unsubscribe();
  }
}
