import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ItemSelectComponent } from 'src/app/modules/inventory/components/select/item-select/item-select.component';
import { KitSelectComponent } from 'src/app/modules/inventory/components/select/kit-select/kit-select.component';
import { TimesheetSelectComponent } from '../../../service-ticket-select/components/timesheet-select/timesheet-select.component';
import { environment } from 'src/environments/environment';
import { ItemAdd, ItemRow, STItemRow, STItemsType, Section } from '../../models/service-ticket';
import { SubSink } from 'subsink';
import { ItemWrapper } from 'src/app/modules/inventory/models/items';
import { Kit2Kit, KitItem, KitWrapper } from 'src/app/modules/inventory/models/kit.models';
import { TimeSheet } from '../../models/common.models';
import { DatePipe } from '@angular/common';
import { ServiceTicketService } from '../../services/service-ticket.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { ChangeDetectionStrategy } from '@angular/compiler/src/compiler_facade_interface';
import { KitService } from 'src/app/modules/inventory/services/kit.service';
import { map } from 'rxjs/operators';
import { getLightColor } from 'src/app/core/utils/global.functions';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import * as _ from 'lodash';
import { SalesService } from 'src/app/modules/sales/services/sales.service';

@Component({
  selector: 'st-items',
  templateUrl: './st-items.component.html',
  styleUrls: ['./st-items.component.scss']
})
export class StItemsComponent implements OnInit {

    @ViewChild('itemSelect') itemSelect: ItemSelectComponent;

    @ViewChild('kitSelect') kitSelect: KitSelectComponent;

    @ViewChild('timesheetSelect') timesheetSelect: TimesheetSelectComponent;

    @Input() st_id: number = 0;

    @Input() summaryRow: boolean = true;

    @Input() deleteOption: boolean = true;

    @Input() saveButton: boolean = false;

    @Input() addButton: boolean = true;

    @Input() coverageType: number = 0;

    @Input() coverageTypeSelection: number = 0;

    @Input() submitted: boolean = false;

    @Input() tax: number;

    @Input() customerId: number;

    @Input() suborg_id: number;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public rows: STItemRow[] = [];

    public sections: Section[] = [new Section()];

    public itemtypes = STItemsType;

    public rowIndex: number;

    public sectionIndex: number;

    private deleteItems: number[] = [];

    private deleteKits: number[] = [];

    public hasKits: boolean = false;

    public _itemsAdd: ItemAdd[] = [];

    public newItem: boolean;

    public durationModel;

    private subs: SubSink = new SubSink();

    get itemsAdd() {
        return this.formatDataForSend();
    }

    set itemsAdd(val) {
        this._itemsAdd = this.formatDataForSend();
    }

    public duration = [
        { id: 1, name: '1 Year' },
        { id: 2, name: '2 Years' },
        { id: 3, name: '3 Years' },
        { id: 4, name: '4 Years' },
        { id: 4, name: '5 Years' },
        { id: 0, name: 'Custom end date' },
    ];

    constructor(
        private toastr: ToastrService,
        public service: ServiceTicketService,
        private cdr: ChangeDetectorRef,
        private kitService: KitService,
        private orderBy: OrderByPipe,
        private salesService: SalesService
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.st_id && changes.st_id.currentValue && changes.st_id.currentValue > 0) {
            this.getSTItems()
        }
    }

    private getSTItems() {
        this.service.itemKitList(this.st_id).subscribe(res => {
            let sections: Section[] = [];
            if (res && res.length > 0) {
                sections = _.chain(res)
                    .groupBy("section_title").map((value, key) => ({ title: key, rows: value, section_total:'' })).value();
            }
            else {
                sections = [new Section()];
            }
            this.sections = sections;
        });
    }

    onItemChange(item: ItemWrapper) {
        if(this.newItem){
            let row = this.getRow(STItemsType.ITEM)
            this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
            this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
        }
        if (item && this.sections[this.sectionIndex] && this.sections[this.sectionIndex].rows[this.rowIndex]) {
            this.sections[this.sectionIndex].rows[this.rowIndex].unique_id = item.id;
            this.sections[this.sectionIndex].rows[this.rowIndex].name = item.item_name;
            this.sections[this.sectionIndex].rows[this.rowIndex].part_number = item.part_number;
            this.sections[this.sectionIndex].rows[this.rowIndex].primary_image = item.primary_image;
            this.sections[this.sectionIndex].rows[this.rowIndex].price = item.item_price;
            // this.sections[this.sectionIndex].rows[this.rowIndex].sell_price_calc = item.item_price;
            this.sections[this.sectionIndex].rows = [...this.sections[this.sectionIndex].rows];
        }
    }

    onKitChange(kit: KitWrapper) {
        if(this.newItem){
            let row = this.getRow(STItemsType.KIT)
            this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
            this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
        }
        if (kit && this.sections[this.sectionIndex] && this.sections[this.sectionIndex].rows[this.rowIndex]) {
            this.sections[this.sectionIndex].rows[this.rowIndex].unique_id = kit.id;
            this.sections[this.sectionIndex].rows[this.rowIndex].name = kit.name;
            this.sections[this.sectionIndex].rows[this.rowIndex].part_number = kit.internal_number;
            this.sections[this.sectionIndex].rows[this.rowIndex].price = kit.sell_price;
            this.sections[this.sectionIndex].rows[this.rowIndex].primary_image = kit.primary_image;
            // this.sections[this.sectionIndex].rows[this.rowIndex].sell_price_calc = kit.sell_price;
            this.sections[this.sectionIndex].rows = [...this.sections[this.sectionIndex].rows];
        }
    }

    onTimeSheetChange(timesheet: TimeSheet[]) {
        const pipe = new DatePipe('en-US');
        console.log(timesheet);
        if (timesheet.length > 0) {
            timesheet.forEach((val, index) => {
                if(this.newItem){
                    let row = this.getRow(STItemsType.TIMESHEET)
                    this.sections[this.sectionIndex].rows = this.sections[this.sectionIndex].rows.concat(row);
                    this.rowIndex = this.sections[this.sectionIndex].rows.length - 1;
                }
                if (val && this.sections[this.sectionIndex] && this.sections[this.sectionIndex].rows[this.rowIndex]) {
                    this.sections[this.sectionIndex].rows[this.rowIndex].unique_id = val.id;
                    this.sections[this.sectionIndex].rows[this.rowIndex].name = pipe.transform(val.created_on, 'MM/dd/YYYY') + ": " + val.comments;
                    this.sections[this.sectionIndex].rows[this.rowIndex].quantity = val.time;
                    // this.sections[this.sectionIndex].rows[this.rowIndex].partnumber = val.;
                    this.sections[this.sectionIndex].rows[this.rowIndex].price = val.total_amount;
                    // this.sections[this.sectionIndex].rows[this.rowIndex].sell_price_calc = kit.sell_price;
                    this.sections[this.sectionIndex].rows = [...this.sections[this.sectionIndex].rows];
                }
            });
        }
    }

    getRow(type: STItemsType){
        let row = new ItemRow();
        row.type = type;
        row.customer = this.service.customerId;
        if (type == STItemsType.TIMESHEET) {
            row.quantity = 0;
        }
        return row;
    }

    addSection() {
        this.sections = _.concat(this.sections, new Section);
    }

    removeSection(index: number) {
        if (this.sections.length > 1) {
            this.sections.splice(index, 1);
        }
    }

    appendRow(sectionIndex: number, type: STItemsType) {
        this.newItem = true;
        this.toggleSelect(sectionIndex, type);
    }

    toggleSelect(sectionIndex: number, type: STItemsType, index?: number, ) {
        if(index >=0){
            this.newItem = false;
            this.rowIndex = index;
        }
        this.sectionIndex = sectionIndex;
        console.log(type);
        if (type === STItemsType.ITEM) {
            this.itemSelect.open();
        }
        else if (type === STItemsType.KIT) {
            this.kitSelect.open();
        }
        else  {
            this.timesheetSelect.open();
        }
    }

    quantityOnKeyDown(row: ItemRow) {
        // if (this.validateQuantity(row))
            row.quantity = this.salesService.trimNumber(row.quantity, 5);
        // else
        //     row.quantity = row.ordered_quantity;
    }

    quantityOnChange(row: ItemRow) {
        // if (this.validateQuantity(row))
            this.rows = [...this.rows];
        // else
        //     row.quantity = row.ordered_quantity;
    }

    validateQuantity(row: ItemRow){
        if(row.quantity > 0)
            return true;
        else
            return false
    }

    priceSummaryFunc(index) {
        return this.sections[index].rows.map(row => !isNaN(row.price) && row.type != this.itemtypes.TIMESHEET ? row.price * row.quantity : !isNaN(row.price) && row.type === this.itemtypes.TIMESHEET ? row.price : 0)?.reduce((a, b) => a + b, 0.00)?.toFixed(2);
    }

    calculateTotal() {
        let total = 0;
        this.sections.forEach((sec, index) => {
            if (sec && sec.rows && sec.rows.length > 0) {
                sec.rows.forEach((row, i) => {

                    if (!row.is_delete) {
                        if (row.type != STItemsType.TIMESHEET) {
                            total += Number(!isNaN(row.price) ? row.price : 0) * row.quantity;
                        }
                        else {
                            total += Number(!isNaN(row.price) ? row.price : 0);

                        }
                    }


                })
            }
        });

        return total.toFixed(2);
    }

    calculateGrandTotal() {
        return (Number(this.calculateTotal()) + Number(this.calculateTax())).toFixed(2);
    }

    calculateTax() {
        return this.tax > 0 ? Number((Number(this.calculateTotal()) * this.tax) / 100).toFixed(2) : 0;
    }

    onPriceChange(event, index) {
        if (!isNaN(event)) {
            let val = Number(event).toFixed(2);
            this.rows[index].price = Number(val);
        }
    }

    onPriceKeyDown(event, secIndex, index) {
        this.sections[secIndex].rows[index].price = this.salesService.toFixedNoRounding(event);
    }

    deleteMaterial(id, secIndex, rowIndex) {
        if (this.st_id > 0 && id) {
            this.sections[secIndex].rows[rowIndex].is_delete = true;
        }
        else {
            this.deleteRow(secIndex, rowIndex);
        }
    }

    deleteRow(secIndex, rowIndex) {
        if (this.sections[secIndex] && this.sections[secIndex].rows[rowIndex]) {
            this.sections[secIndex].rows.splice(rowIndex, 1);
            this.cdr.markForCheck();
        }
    }

    formatDataForSend() {
    let itemsAdd: ItemAdd[] = [];
    this.sections.forEach((val, index) => {
        if (val.rows && val.rows.length) {

            val.rows.forEach((item, i) => {
                let row: ItemAdd = new ItemAdd();

                if (this.st_id && this.st_id > 0) {
                    row.service_ticket = this.st_id
                    row.id = item.id;
                }
                else {
                    row.service_ticket = null;
                }
                row.customer = item.customer;
                row.unique_id = item.unique_id;
                row.quantity = item.quantity;
                row.serial_number = item.serial_number;
                row.section_title = val.title ? val.title : '';
                row.price = item.price;
                row.type = item.type;
                row.name = item.name;
                row.is_delete = item.is_delete;
                // row.from_unique_number = item.from_unique_number;
                // row.ordered_quantity = item.ordered_quantity;
                // row.invoiced_quantity += item.quantity;
                itemsAdd.push(row);

            });

        }
    });
    // console.log(this.itemsAdd);
    return itemsAdd;
    }

    expandKit(row: ItemRow, level?:number) {
        if (!row.children) {
            if(level == 0)
                row.level = level;
            this.subs.sink = forkJoin([this.getItemInAssembly(row.unique_id), this.getKitsInAssembly(row.unique_id)]).subscribe(results => {
                if (results && results.length > 0) {
                    let items = [...results[0], ...results[1]];
                    row.children = this.orderBy.transform(items, this.getOrderBy(items));
                    row.expanded = true;
                    this.addColor(row);
                    this.cdr.markForCheck();
                }
            });
        }
        else {
            row.expanded = !row.expanded;
        }
    }

    addColor(row: ItemRow){
        row.children.map((el)=>{
            el.level = row.level + 1;
            el.color = getLightColor(el.level);
        });
    }

    getItemInAssembly(kitid: number) {
        return this.kitService.getItemsByKit(kitid).pipe(map(res => {
            let rows: ItemRow[] = [];
            if (res && res.length > 0) {
                for (let item of res) {

                    console.log(res);
                    let row = new ItemRow();
                    row.type = STItemsType.ITEM;
                    row.id = item.id;
                    row.unique_id = item.items;
                    row.primary_image = item.primary_image
                    row.customer = item.customer;
                    row.name = item.item_name;
                    row.part_number = item.part_number;
                    row.quantity = item.quantity || 1;
                    row.price = item.item_price;
                    rows.push(row);
                }
            }
            return rows;
        }));
    }

    getKitsInAssembly(kitid: number) {
        return this.kitService.getKitsInKit(kitid).pipe(map(res => {
            let rows: ItemRow[] = [];
            if (res && res.length > 0) {
                for (let k2k of res) {
                    let row = new ItemRow();
                    row.type = STItemsType.KIT;
                    row.id = k2k.id;
                    row.unique_id = k2k.kit_kit;
                    row.customer = k2k.customer;
                    row.name = k2k.kit_name;
                    row.part_number = k2k.internal_number;
                    row.quantity = k2k.quantity || 1;
                    row.price = k2k.sell_price;
                    row.primary_image = k2k.primary_image;
                    rows.push(row);
                }
            }
            return rows;
        }));
    }

    getOrderBy(rows: ItemRow[]) {
        return rows.some(item => item.order > 0) ? 'order' : 'item_name';
    }

    minuteToHour(minutes: number) {
        if (minutes > 0) {
            return (minutes / 60).toFixed(2);
        }
        else {
            return 0;
        }
    }

    submit(kitid: number, next?: (value: any) => any, error?: (value: any) => any): void {
        let apis = this.getApis(kitid);
        if (apis.length <= 0) {
            return next([]) || false;
        }
        this.subs.sink = forkJoin(apis).subscribe(
            results => {
                if (next) next(results); else this.toastr.success(`Kit Items updated`);
            },
            err => {
                if (err) error(error); else this.toastr.error(`Operation failed`);
            }
        );
    }

    getApis(kitid: number): Observable<any>[] {
        this.rows.forEach(row => row.kit_id = kitid);
        let items = this.convertKitItemRow();
        let apis: Observable<any>[] = [];
        this.getItemApis(apis, items.items);
        this.getKitApis(apis, items.kits);
        return apis;
    }

    getItemApis(apis: Observable<any>[], items: KitItem[]) {
        // let add = items.filter(ki => !ki.id || ki.id <= 0);
        // if (add.length > 0) {
        //     apis.push(this.kitService.addKitItems(add));
        // }
        // let update = items.filter(ki => ki.id && ki.id > 0);
        // if (update.length > 0) {
        //     apis.push(this.kitService.updateKitItems(update));
        // }
        // if (this.deleteItems.length > 0) {
        //     apis.push(this.kitService.deleteKitItems(this.deleteItems));
        // }
    }

    getKitApis(apis: Observable<any>[], kits: Kit2Kit[]) {
        // let add = kits.filter(ki => !ki.id || ki.id <= 0);
        // if (add.length > 0) {
        //     apis.push(this.kitService.addKits2Kit(add));
        // }
        // let update = kits.filter(ki => ki.id && ki.id > 0);
        // if (update.length > 0) {
        //     apis.push(this.kitService.updateKits2Kit(update));
        // }
        // if (this.deleteKits.length > 0) {
        //     apis.push(this.kitService.deleteKits2Kit(this.deleteKits));
        // }
    }

    convertKitItemRow(): { items?: KitItem[], kits?: Kit2Kit[] } {
        let resp = { items: [], kits: [] };
        for (let i = 0; i < this.rows.length; i++) {
            let row = this.rows[i];
            if (!row.item_id || row.item_id <= 0) {
                return null;
            }
            if (row.itemType === STItemsType.ITEM) {
                let ki = new KitItem();
                ki.id = row.id;
                ki.items = row.item_id;
                ki.kits = row.kit_id;
                ki.quantity = row.quantity;
                ki.customer = row.customer;
                ki.order = row.order;
                resp.items.push(ki);
            }
            else if (row.itemType === STItemsType.KIT) {
                let ki = new Kit2Kit();
                ki.id = row.id;
                ki.kit_kit = row.item_id;
                ki.kit = row.kit_id;
                ki.quantity = row.quantity;
                ki.customer = row.customer;
                ki.order = row.order;
                resp.kits.push(ki);
            }
        }
        return resp;
    }

}
