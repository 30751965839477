<div class="container rounded-bottom">
    <div class="d-flex flex-column justify-content-between h-100 ">
        <div>

            <div class="status-toggle-btn mt-1 pt-1" [ngClass]="{'manual': selectSendAsManual,'auto': !selectSendAsManual}">
                <label class="title">{{autoManualTitle}}</label>
                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                    <input type="checkbox" [(ngModel)]="selectSendAsManual" name="selectSendAsManual" [value] = "selectSendAsManual"
                        class="custom-control-input" (change)="onChangeAutoManual($event)"  id="selectSendAsManual">
                    <label class="custom-control-label" for="selectSendAsManual">
                        <span></span>
                    </label>
                </div>
            </div>

            <div class="form-group mt-1 mb-0">
                <did-select [(value)]="send_as" bindValue="did" [suborgId]="subOrgId" (isOpen) = "ondidSelectOpenChange($event)" 
                [readonly]="selectSendAsManual == false" [dialerView]="true"></did-select>
            </div>

            <div class="output">
                <!-- <input type="text"  class="dialer-input"  [(ngModel)] = "dialNumber"    
                 [ngModelOptions]=" {standalone: true}"> -->
                 <!-- <contact-number-search-select [suborgId]="subOrgId" [value] ="dialNumber" [isDidSelectOpen] = "isDidSelectOpen"
                  #customerselect ></contact-number-search-select> -->


                <div class="dialler-input-div">
                    <input type="text" class="form-control" #dialInput placeholder="Enter number to call" 
                    (paste)="onInputChange()"  
                    (keydown)="keyDown($event)"  
                    (keyup)="keyUp($event)"
                    [(ngModel)]="dialNumber" />

                    <div class="dialler-input-back" *ngIf="displayBackBtn">
                        <img src="../../../../../assets/img/caller assets/delete.png"  (click)="removeNumber()"/>
                    </div>
                </div>

                  <!-- <div class="input-group mb-3 dialler-input-field">
                    <input type="text" class="form-control" [(ngModel)]="dialNumber" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button">
                        <img src="../../../../../assets/img/caller assets/delete.png" />
                      </button>
                    </div>
                  </div> -->
            </div>
            <!-- <div id="output" [(ngModel)] = "dialNumber"  [ngModelOptions]=" 
            {standalone: true}">{{dialNumber}}</div> -->
            <div class="dial-nos">
                <div class="row mt-2">
                    <div class="col-sm-4" (click) = "onClickNumber('1')">
                        <div class="digit" id="one" >1</div>
                    </div>
                    <div class="col-sm-4" (click) = "onClickNumber('2')" >
                        <div class="digit" id="two">2</div>
                    </div>
                    <div class="col-sm-4" (click) = "onClickNumber('3')">
                        <div class="digit" id="three">3</div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-sm-4" (click) = "onClickNumber('4')">
                        <div class="digit" id="one">4</div>
                    </div>
                    <div class="col-sm-4" (click) = "onClickNumber('5')">
                        <div class="digit" id="two">5</div>
                    </div>
                    <div class="col-sm-4" (click) = "onClickNumber('6')">
                        <div class="digit" id="three">6</div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-sm-4" (click) = "onClickNumber('7')">
                        <div class="digit" id="one">7</div>
                    </div>
                    <div class="col-sm-4" (click) = "onClickNumber('8')">
                        <div class="digit" id="two">8</div>
                    </div>
                    <div class="col-sm-4" (click) = "onClickNumber('9')">
                        <div class="digit" id="three">9</div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-sm-4" (click) = "onClickNumber('*')">
                        <div class="digit" id="one">*</div>
                    </div>
                    <div class="col-sm-4" (click) = "onClickNumber('0')">
                        <div class="digit" id="two">0</div>
                    </div>
                    <div class="col-sm-4" (click) = "onClickNumber('#')">
                        <div class="digit" id="three">#</div>
                    </div>
                </div>
            </div>
       
        
        </div>
    
        <div class="bottom-row mt-1 rounded-bottom">
            <div class="d-flex flex-row">
                <div class="p-1 w-100  btn-video d-flex flex-column">
                    <i class="fa fa-video-camera mx-1" aria-hidden="true"></i>Video</div>
                <div class="p-1 w-100 btn-call d-flex flex-column" (click)="onCall()">
                    <i class="fa fa-phone mx-1" aria-hidden="true" ></i>Call</div>
                <div class="p-1 w-100  btn-msg d-flex flex-column">
                    <i class="fa fa-comment mx-1" aria-hidden="true"></i>Message</div>
              </div>
        </div>
    </div>
  </div>
