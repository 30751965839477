import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Distributor } from '../../../models/distributor';
import { DistributorService } from '../../../services/distributor.service';
import { DistributorDataHelper } from '../../../helpers/distributor-data.helper';

@Component({
  selector: 'distributor-select',
  templateUrl: './distributor-select.component.html',
  styleUrls: ['./distributor-select.component.scss']
})
export class DistributorSelectComponent implements OnInit {

  @ViewChild('modalElement') selectModal: any;

  private _value: number;

  get value(): number { return this._value; }

  @Input() set value(val: number) {
    this._value = val;
    if (this.value > 0 && (!this.selected || this.selected.id !== val)) {
      this.setValue(val);
    }
  };

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<Distributor>();

  @Input() selectBox: boolean = true;

  public SelectionType = SelectionType;

  public fileUrl: string = environment.fileURL;

  public items: Distributor[];

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = DistributorDataHelper.DefaultFilters;

  public search: GFilterParam[] = DistributorDataHelper.SearchFilters;

  public columns: GFColumn[] = DistributorDataHelper.FilterColumns;

  public sortLookup = DistributorDataHelper.SortLookup;

  public selectedItem: Distributor[] = [];

  public selected: Distributor;

  public sort: GSortParam[] = [{ colname: 'distributor_name', direction: 'asc' }];

  private modalRef: NgbModalRef;

  public scrollbarH: boolean = false;

  private subs: SubSink = new SubSink();

  constructor(private modal: NgbModal,
    private service: DistributorService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService) {

    this.scrollbarH = this.device.isMobile();
  }

  ngOnInit() { }

  setValue(id: number) {
    this.subs.sink = this.service.getById(id).subscribe(item => {
      this.selected = item;
      this.selectedItem.push(item);
      this.cdr.markForCheck();
    });
  }

  open() {
    this.modalRef = this.modal.open(this.selectModal, { size: 'xl', scrollable: true });
    if (!this.items || this.items.length <= 0) {
      this.getData(null);
    }
  }

  private getData(filters: GFilterParam[], offset: number = 0) {
    let option = { sort: this.sort, filters: filters };
    this.subs.sink = this.service.getAll(option, offset).subscribe(resp => {
      this.items = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  onSort(event: any, dataFilter: DataGridFilterComponent) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(dataFilter?.getDataFilters());
    }
  }

  setPage(pageInfo: any, dataFilter: DataGridFilterComponent) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(dataFilter?.getDataFilters(), offset);
  }

  onItemSelected() {
    if (this.selectedItem && this.selectedItem.length > 0) {
      let item = this.selectedItem[0];
      this.selected = item;
      this.valueChange?.emit(item.id);
      this.change?.emit(item);
    }
    this.modalRef?.dismiss();
  }

  onCanceled() { this.modalRef?.dismiss(); }

  clearSelection(event: any) {
    event.stopPropagation();
    this.selected = null;
    this.valueChange?.emit(undefined);
    this.selectedItem = [];
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
