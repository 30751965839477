<section class="common-grid-view">

    <div class="multi-graph margin" style="    margin-left: 30%;">

        <div class="graph" data-name="Completed"
            style="--percentage : {{complete_percentage}}; --fill: green ;">
        </div>
        <h1 class="percentage_display">{{complete_percentage}}%</h1>
    </div>

    <div style="text-align: center;margin-bottom: 30px;width: 30%;
    float: right;" class="row">
        <div style="padding: 5px;background-color: green;margin-right: 4px;" class="col-1"></div>Completed&nbsp;
        <div style="padding: 5px;background-color: red;margin-right: 4px;" class="col-1"></div>Pending
    </div>

    <table class="item-stock-table w-100">
        <thead>
            <tr>
                <th>SubTask</th>
                <th>Description</th>
                <th style="width: 15%;">Set as Marked</th>
                <th style="width: 20%;">Attachments</th>
                <th style="width: 15%;">Show In Pdf</th>
             </tr>
        </thead>
        <tbody>

            <ng-container *ngFor="let
            sub of this.rows
            let i=index">
            <tr>
                <td>{{ sub.name}}<sup *ngIf="sub.is_mandatory" class="mandatory">*</sup></td>
                <td><div [innerHTML]="sub.description" class="desc_div"></div></td>
                <td style="text-align: center;">

                    <div
                        class="custom-control
                            custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            (click)="makeMarked(sub.is_checked,i,$event)"
                            [(ngModel)]="sub.is_checked"
                            id="custom-check-{{i}}">
                        <label
                            class="custom-control-label"
                            for="custom-check-{{i}}">
                            <span></span>
                        </label>
                    </div>

                </td>
                <td>


                    <div *ngIf="sub.images.length>0" style="padding:15px;">


                        <ngb-carousel [interval]="0" [showNavigationIndicators]="false">
                            <ng-template ngbSlide *ngFor="let image of sub.images_list let i=index">
                              <div class="picsum-img-wrapper">
                                <img (click)="openModal(content,sub, i)" *ngIf="image.type=='image/png' || image.type=='image/jpeg'"  width="100" height="100" [src]="imgURL+image['url']" />
                                <img (click)="openModal(content,sub, i)" *ngIf="image.type=='video/mp4'" width="100" height="100" src="assets/img/gallery/video-icon.png"/>
                                <img (click)="openModal(content,sub, i)" *ngIf="image.type=='application/pdf'" width="100" height="100"  src="assets/img/gallery/pdf-icon.png"/>
                              </div>
                            </ng-template>

                          </ngb-carousel>

                    </div>


                    <div class="plus-thumb" *ngIf="sub.images.length == 0" style="margin: 10px 35px;">
                        <button type="button" (click)="openModal(content,sub)"  class="btn btn-sm btn-info" >+</button>
                    </div>


                    <!--<input type="file" class="file-upload"
                        (change)="uploadAttachment($event)">
                        <form role="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && uploadImg(sub)" novalidate>
                        <app-file-uploader [primary]="sub.image" [files]="itemImages" [multiple]="false" (delete)="onFileDelete($event, sub)">
                        </app-file-uploader>

                        <button type="submit" [disabled]="!formElement.form.valid"
                                    class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                Save
                            </button>
                    </form>-->
                </td>
                <td style="text-align: center;">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            (click)="changeRenderInPdf(sub, i)"
                            [(ngModel)]="sub.is_render_in_pdf"
                            id="pdf-check-{{i}}">
                        <label
                            class="custom-control-label"
                            for="pdf-check-{{i}}">
                            <span></span>
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="8"><textarea rows="1" [(ngModel)]="sub.comments" class="sub-task-comments-textarea" placeholder="write comments here.."></textarea></td>
            </tr>

        </ng-container>


        </tbody>
    </table>


    <div class="d-flex flex-row mt-2" >

        <div class="link-section ml-2" >
            <button type="button" (click)="addComment()" [disabled]="rows.length <= 0" class="btn btn-sm btn-info" disableIfAllsuborg>Save Comments</button>
        </div>
    </div>

</section>


<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Attachments</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form role="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && uploadImg(selected, fileUploader)" novalidate>
            <app-file-uploader #fileUploader [autoFullViewImageIndex]="autoFullViewImageIndex"  [upload_all_files]="true" [files]="itemImages" [multiple]="true" (delete)="onFileDelete($event, selected)" (isAddedNewFile)="toggleSave($event)">
            </app-file-uploader>

            <button (click)="modal.dismiss('Cross click')"  type="button" class="btn btn-secondary" style="float:right">Cancel</button>
            <button *ngIf="isShowSave" type="submit" [disabled]="!formElement.form.valid"
                        class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2" style="float:right;padding-bottom: 8px;">
                    Save
                </button>
        </form>
    </div>
</ng-template>

<div style="width: 50%;">

</div>
