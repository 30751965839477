import { CustomerContact, CustomerLocation } from '../../../tenant-customers/models/tenant-customer';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { TimeSheet } from '../../../service-ticket/models/common.models';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { SubSink } from 'subsink';
import { ServiceTicketService } from '../../../service-ticket/services/service-ticket.service';
import { Observable } from 'rxjs';
import { Time,TimeRange } from 'src/app/shared/components/time-picker/time-picker.component';
import { BillingStatus } from '../../../service-ticket/models/enums';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { TenantCustomer } from 'src/app/modules/tenant-customers/models/tenant-customer';
import * as moment from 'moment';
import { CustomerLocationSelectComponent } from 'src/app/modules/tenant-customers/components/select/customer-location-select/customer-location-select.component';

@Component({
    selector: 'time-sheet-form',
    templateUrl: './time-sheet-form.component.html',
    styleUrls: ['./time-sheet-form.component.scss'],
})
export class TimeSheetFormComponent implements OnInit, OnDestroy {

    @ViewChild('locationSelect') locationSelect: CustomerLocationSelectComponent;

    public model: TimeSheet = new TimeSheet();

    @Input() bindingModel: TimeSheet;

    @Input() uniqueId: number = 0;

    @Input() serviceTicketId: number;

    private customerInput:number;

    private locationInput:number;

    private contactInput:number;

    @Input() set tenantCustomerId(value: number) {
        if (value) {
            this.customerInput = value;
            // this.model.tenant_customer = value;
        }
    }

    @Input() set tenantCustomerLocationId(value: number) {
        if (value) {
            this.locationInput = value;
            // this.model.tenant_customer_location = value;
        }
    }

    @Input() set tenantCustomerContactId(value: number) {
        if (value) {
            this.contactInput = value;
            // this.model.tenant_customer_contact = value;
        }
    }

    @Input() customerSelectReadonly: boolean = false;

    @Input() suborg_id:number;

    public hourDiff: number = 0;

    public minDiff: number = 0;

    public timeModel: Time | TimeRange;

    @Output() success = new EventEmitter<{
        response: TimeSheet;
        message: string;
    }>();

    @Output() error = new EventEmitter<any>();

    @Output() cancel = new EventEmitter<any>();

    public billingStatus: { key: string; value: number; class: string }[] = [
        { key: 'Unbilled', value: BillingStatus.Un_billed, class: 'badge bg-warning', },
        { key: 'Invoiced', value: BillingStatus.Invoiced, class: 'badge bg-info' },
        { key: 'Paid', value: BillingStatus.Paid, class: 'badge bg-success' },
        { key: 'Overdue', value: BillingStatus.Overdue, class: 'badge bg-danger' },
    ];

    public subs: SubSink = new SubSink();

    public test : number

    constructor(
        private service: ServiceTicketService,
        private cdr: ChangeDetectorRef,
        private ra: ResourceAccessService
    ) { }

    ngOnInit() {
        if (this.uniqueId > 0) {
            if (this.bindingModel) {
                this.model = { ...this.bindingModel };
                this.initModel();
                this.cdr.markForCheck();
            } else {
                this.subs.add(
                    this.service.getTimeSheetById(this.uniqueId).subscribe((resp) => {
                        this.model = resp;
                        this.initModel();
                        this.cdr.markForCheck();
                    })
                );
            }
        }
        else{
            this.model.tenant_customer = this.customerInput;
            this.model.tenant_customer_location = this.locationInput;
            this.model.tenant_customer_contact = this.contactInput;
        }
    }

    initModel() {
        let start = this.model.starting_time?.split(':') || ['0', '0'];
        let end = this.model.closing_time?.split(':') || ['0', '0'];

        let range = new TimeRange();
        range.start = new Time(parseInt(start[0]), parseInt(start[1]));
        range.end = new Time(parseInt(end[0]), parseInt(end[1]));
        this.timeModel = range;

        if (this.model.time) {
            this.minDiff = this.model.time % 60;
            this.hourDiff = Math.abs(Math.floor(this.model.time / 60));
        }
    }

    customerChanged(customer: TenantCustomer) {
        if (!customer || this.customerInput !== customer.id) {
            this.model.tenant_customer_location = undefined;
            this.model.tenant_customer_contact = undefined;
            this.customerInput = undefined;
        }
        this.model.is_billable = customer?.is_service_ticket_billable;
        this.model.customer_name = customer?.name;
        this.billableOnChange();
    }

    customerLocationChange(location: CustomerLocation) {
        this.model.location_name = location?.name;
        this.model.is_billable = location?.is_billing;
        this.billableOnChange();
    }

    billableOnChange() {
        if (this.model.is_billable) {
            if (!this.model.billing_status) {
                this.model.billing_status = BillingStatus.Un_billed;
            }
        } else {
            this.model.billing_status = null;
            // this.model.total_amount = null;
        }
    }

    validate(): boolean {
        return this.model.tenant_customer > 0;
    }

    submit() {
        let api: Observable<any>;

        this.hourDiff = this.hourDiff || 0;
        this.minDiff = this.minDiff || 0;

        this.model.time = this.hourDiff * 60 + this.minDiff;

        if (this.model.assigned_date) {
            let _date = this.model.assigned_date.toString() + ' ' + (this.model.starting_time || '00:00:00')
            this.model.assigned_date = new Date(_date);
        }

        if (this.model.id > 0) {
            api = this.service.updateTimeSheet(this.model.id, this.model);
        }
        else {
            this.model.customer = this.service.customerId;
            this.model.suborg = this.suborg_id;
            if (this.serviceTicketId) {
                this.model.service_ticket = this.serviceTicketId;
            }
            api = this.service.createTimeSheet(this.model);
        }

        this.subs.sink = api.subscribe(
            (resp) => {
                let msg = this.model.id
                    ? 'Timesheet info updated'
                    : 'New Timesheet added';
                this.success?.emit({
                    response: { ...this.model, ...resp },
                    message: msg,
                });
            },
            (error) => this.error?.emit(error)
        );
    }

    pickerChanged(value: any) {
        let model = value as TimeRange;

        if (!model.start || !model.end) return;

        if (model.start.hour && model.end.hour) {
            this.calculateTime(model);
        }
    }

    calculateTime(model: TimeRange) {
        this.model.starting_time = model.start.to24Format();
        this.model.closing_time = model.end.to24Format();

        let duration = moment.duration(
            moment(this.model.closing_time, 'HH:mm').diff(
                moment(this.model.starting_time, 'HH:mm')
            )
        );
        let hours = Math.floor(duration.asHours());
        let min = Math.floor(duration.asMinutes() % 60);

        this.hourDiff = hours < 0 ? 24 + hours : hours;
        if (this.isRangeMatch(model)) {
            this.hourDiff = 24;
        }
        this.minDiff = min < 0 ? 60 + min : min;
        this.model.time = this.hourDiff * 60 + this.minDiff;
    }

    isRangeMatch(r: TimeRange) {
        if (
            r.start.hour === r.end.hour &&
            r.start.minute === r.end.minute &&
            r.start.meridian === r.end.meridian
        ) {
            return true;
        }
        return false;
    }

    onCancel(): void {
        this.cancel?.emit();
    }

    hasDatePermission() {
        return this.ra.hasPermission(
            ResourceConstants.ST_TIMSHEET_DATE,
            [PermissionConstants.GLOBAL],
            ResourceConstants.SERVICE_TICKET_MODULE
        );
    }

    onDefaultCustomerSet(customer: TenantCustomer) {
        if (!this.model.id) {
            this.customerChanged(customer);
        }
    }

    onDefaultLocationSet(location: CustomerLocation) {
        if (!this.model.id) {
            this.customerLocationChange(location);
        }
    }

    customerContactChanged(contact: CustomerContact) {
        if (contact && contact.location > 0) {
            this.model.tenant_customer_location = contact?.location;
        }
        else {
            this.locationSelect?.clear();
        }
    }

    onCustomerLocationAdded() { this.locationSelect?.reload(); }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
