<section id="item-list" class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
    <div class="row h-100">
        <div class="col-12 d-flex flex-row h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="card-header d-flex flex-space-bw">
                    <h4 class="card-title">Label Designs</h4>
                    <div class="">
                        <button *ngIf="hasPermission(Permissions.CREATE)" disableIfAllsuborg [routerLink]="['/inventory/add-labels']"  class="btn btn-sm gradient-pomegranate mr-1">
                            <i class="ft-plus mr-1"></i> New
                        </button>
                        <!-- <button  [disabled]="true" *ngIf="suborgId==-1" class="btn btn-sm gradient-pomegranate mr-1">
                            <i class="ft-plus mr-1"></i> New
                        </button> -->
                        <!-- <input #searchInput class="form-control form-control-sm width-200" type="text" placeholder="Search item type..."  /> -->
                    </div>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable  
                        [scrollbarH]="scrollbarH" 
                        class="bootstrap core-bootstrap" 
                        [columnMode]="'force'" 
                        [headerHeight]="50" 
                        [footerHeight]="50" 
                        rowHeight="auto"
                        (select)="onSelect($event)" 
                        [rows]="labelList" 
                        [selectionType]="SelectionType.single" 
                        [sorts]="[{prop:'name',dir:'asc'}]" 
                        >
                            <ngx-datatable-column name="Created Date" prop="created_on" [sortable]="true" [width]="170">
                                <ng-template let-crdate="value" ngx-datatable-cell-template>
                                    {{crdate | date:'MM/dd/YYYY h:mm a'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Title" prop="title" [sortable]="true"></ngx-datatable-column>
                            <ngx-datatable-column name="Category" prop="type" [sortable]="true"></ngx-datatable-column>
                            <ngx-datatable-column name="Dimention" prop="width" [sortable]="true">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.width/96}}x{{row.length/96}} Inch
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Action" prop="id" *ngIf="expanded" [sortable]="false" [width]="100">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button disableIfAllsuborg class="w-100" *ngIf="hasPermission(Permissions.MODIFY)" ngbDropdownItem [routerLink]="['/inventory/labels',id, 'edit']">
                                                <i class="ft-edit mr-1"></i> Edit
                                            </button>
                            
                                            <button *ngIf="hasPermission(Permissions.VIEW_INFO)" class="w-100" (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>

                                            <button class="w-100" disableIfAllsuborg ngbDropdownItem *ngIf="hasPermission(Permissions.DELETE)" (click)="deleteLabel(id)" >
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                            
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>

            <div class="parellax-detail pb-2" *ngIf="!expanded && selected">
                <div class="parellax-detail-content label-details">
                    <div class="d-flex justify-content-between flex-row mt-2">
                        <div class="item-title d-flex flex-column">
                            <h5 class="m-0">{{selected.title}}</h5>
                            <!-- <p class="mt-1 mb-0">{{selected.unique_number}}</p> -->
                        </div>
                        <div class="d-flex justify-content-end align-items-start">
                            <!-- <a *ngIf="hasPermission(Permissions.MODIFY)&&suborgId!=-1"
                                [routerLink]="['/inventory/labels',selected.id,'edit']"
                                ngbTooltip="Edit" class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a> -->
                            <button *ngIf="hasPermission(Permissions.MODIFY)"
                            disableIfAllsuborg
                            [routerLink]="['/inventory/labels',selected.id,'edit']"
                            title="Select a suborg to edit"
                             class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </button>
                            <button (click)="deleteLabel(selected.id)"
                            disableIfAllsuborg
                            *ngIf="hasPermission(Permissions.DELETE)"
                            [title]="suborgId==-1?'Select a suborg to enable delete button':'Delete label'"
                            class="btn btn-sm bg-light-danger ml-1">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex  flex-column">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink class="d-flex align-items-center">
                                    <span class="d-none d-sm-block">Overview</span>
                                </a>
                                <ng-template ngbNavContent class="tab-content-pd">
                                    <div class="ov-row" *ngIf="selected.title" >
                                        <label class="col-md-3 p-0 item-label">Design title</label>
                                        <label class="item-label-value">{{selected.title}}</label>
                                    </div>
                                    <div class="ov-row" *ngIf="selected.type" >
                                        <label class="col-md-3 p-0 item-label">Type</label>
                                        <label class="item-label-value">{{selected.type}}</label>
                                    </div>
                                    <div class="ov-row" *ngIf="selected.width&&selected.length" >
                                        <label class="col-md-3 p-0 item-label">Design Dimention</label>
                                        <label class="item-label-value">{{selected.width/96}}x{{selected.length/96}} Inch</label>
                                    </div>
                                    <div class="ov-row" *ngIf="selected.created_on">
                                        <label class="col-md-3 p-0 item-label">Created On</label>
                                        <label
                                            class="item-label-value">{{selected.created_on | date:'MM/dd/YYYY'}}</label>
                                    </div>
                                    <div class="ov-row" *ngIf="selected.created_by">
                                        <label class="col-md-3 p-0 item-label">Created By</label>
                                        <label class="item-label-value">{{selected.created_by}}</label>
                                    </div>
                                    <div class="ov-row" *ngIf="selected.image" >
                                        <label class="col-md-3 p-0 item-label">Preview</label>
                                        <label class="item-label-value">
                                            <img src="{{baseUrl}}/static/files/{{selected.image}}" />
                                            <!-- <canvas id="canvas" ></canvas> -->
                                        </label>
                                    </div>
                                    <!-- <div class="ov-row" *ngIf="selected.suborg_name && isAllSuborg">
                                        <label class="col-md-3 p-0 item-label">Suborg</label>
                                        <label class="item-label-value">{{selected.suborg_name}}</label>
                                    </div> -->
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>