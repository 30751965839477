import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { CustomerSystemType } from '../models/system-type';
@Injectable({
  providedIn: 'root'
})
export class SystemTypeService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<CustomerSystemType[]>(`${this.baseURL}/system_types/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0) {
    let url = `${this.baseURL}/filter_customer_system_types/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<CustomerSystemType[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<CustomerSystemType>(`${this.baseURL}/customer_system_types/${id}/`);
  }

  create(model: CustomerSystemType) {
    return this.http.post<CustomerSystemType>(`${this.baseURL}/customer_system_types/`, model);
  }

  update(id: number, model: CustomerSystemType) {
    return this.http.put<CustomerSystemType>(`${this.baseURL}/customer_system_types/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<CustomerSystemType>(`${this.baseURL}/customer_system_types/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/customer_system_types/${id}/`);
  }
}
