import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Cards } from '../models/payments-model';

@Injectable({
  providedIn: 'root'
})
export class CardsService extends BaseService {

  constructor(private http: HttpClient) { super() }

  addCard(payLoad: Cards, cust_id:number) {
    return this.http.post<Cards>(`${this.baseURL}/tenant_customer/create_card_details/${cust_id}/`, payLoad);
}

  getCards(cust_id:number){

    return this.http.post<any>(`${this.baseURL}/tenant_customer/list_card/${cust_id}/`,[]);
  }

  getCardDetailsById(id:number){

    return this.http.get<any>(`${this.baseURL}/tenant_customer/card_details/${id}/`);
  }

  updateCard(payLoad: Cards, card_id:number) {
    return this.http.put<Cards>(`${this.baseURL}/tenant_customer/card_details/${card_id}/`, payLoad);
}

updateCardsDefaultStatus(payLoad: {is_default:boolean}, card_id:number) {
  return this.http.patch<{is_default:boolean}>(`${this.baseURL}/tenant_customer/card_details/${card_id}/`, payLoad);
}

deleteCard(id:number){
  return this.http.delete<any>(`${this.baseURL}/tenant_customer/delete_card_details/${id}/`);
}
}
