
<section class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h5 class="mb-3">Print settings</h5>
                <div class="form-group" [hidden]="true">
                    <label class="val-required">Type</label>
                    <ng-select [(ngModel)]="type" placeholder="Select type">
                        <ng-option value="item">Item</ng-option>
                        <ng-option value="assembly">Assembly</ng-option>
                    </ng-select>
                </div>

                <div class="form-group" *ngIf="!editSetting" >
                    <label class="val-required">Print count</label>
                    <input type="number" placeholder="Print Count" style="width:200px" (change)="printCountChange()" class="form-control" [(ngModel)]="printCount" />
                </div>



                <div class="form-group" [hidden]="true" *ngIf="type=='item'">
                    <label class="val-required">Item</label>
                    <ng-select 
                        (click)="itemSelect.open()" 
                        (remove)="itemSelect.open()"
                        [items]="itemList"
                        placeholder="Select Item"
                        bindLabel="item_name" 
                        bindValue="id" 
                        [(ngModel)]="itemId"
                        (change)="selectedItem=$event;uniqueId=$event.id"
                    >

                    </ng-select>
                    <!-- {{selectedItem|json}} -->
                </div>

                <div class="form-group" [hidden]="true" *ngIf="type=='assembly'">
                    <label class="">Assembly</label>
                    <ng-select 
                        (click)="kitSelect.open()" 
                        (remove)="kitSelect.open()"
                        [items]="assemblyList"
                        placeholder="Select Assembly"
                        bindLabel="name"
                        name="assebly"
                        bindValue="id" 
                        [(ngModel)]="assemblyId"
                        (change)="selectedAssembly=$event;uniqueId=$event.id"
                    >

                    </ng-select>
                </div>

                <div class="form-group" [hidden]="true">
                    <label class="">Wherehouse</label>
                    <ng-select 
                        [items]="warehouses" 
                        bindLabel="warehouse_name" 
                        [(ngModel)]="werehouseId" 
                        bindValue="id" 
                        name="werehouse"
                        (change)="werehouse_name=$event.warehouse_name;getWarehouseLocation($event.id);updateCanvas();" 
                        placeholder="Select wherehouse">
                    </ng-select>
                    <!-- --{{werehouse_name}} -->

                </div>


                <div class="form-group" [hidden]="true">
                    <label class="">Wherehouse Location</label>
                    <ng-select 
                        [items]="warehouseLocations" 
                        bindLabel="location" 
                        [(ngModel)]="locationId" 
                        bindValue="id" 
                        name="location"
                        placeholder="Select wherehouse location"
                        (change)="location_name=$event.location;updateCanvas();"
                        >
                    </ng-select>

                    <!-- --{{location_name}} -->


                </div>

                <div class="form-group mb-2" [hidden]="editSetting">

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" [(ngModel)]="customPrint" (change)="updateCanvas()" x type="radio" name="inlineRadioOptions" id="inlineRadio2" [value]="false">
                        <label class="form-check-label" for="inlineRadio2">Use saved settings</label>
                      </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" [(ngModel)]="customPrint"  (change)="updateCanvas()" type="radio" name="inlineRadioOptions" id="inlineRadio1" [value]="true">
                        <label class="form-check-label" for="inlineRadio1">Custom Settings</label>
                      </div>

                </div>




                <div class="form-group" *ngIf="!customPrint||editSetting" >
                    <label class="val-required">Choose a setting</label>
                    <ng-select 
                        [items]="templateList" 
                        bindLabel="title" 
                        [(ngModel)]="printTemplate" 
                        bindValue="id" 
                        [disabled]="editSetting"
                        (change)="templateChange($event);editSetting=false" 
                        placeholder="Select template">
                    </ng-select>
                    <div class="setting-editing" *ngIf="!editSetting&&this.selectedTemplate">
                        <a class="btn-light-info mr-2" *ngIf="hasPermission(Resource.INV_LABELS_SETTINGS, Permissions.MODIFY)" (click)="editSettings()"><i class="ft ft-edit mr-1"></i>Edit this Settings</a>
                        <a class="btn-light-danger " *ngIf="hasPermission(Resource.INV_LABELS_SETTINGS, Permissions.DELETE)" (click)="deleteSetting()"><i class="ft ft-trash-2 mr-1"></i>Delete</a>
                    </div>
                </div>

                <!-- {{this.rollPaper}} -->
                <!-- top:{{this.labelMarginTop}}
                bottom:{{this.labelMarginBottom}} -->


        <div *ngIf="customPrint||editSetting">
                <div class="form-group" *ngIf="!editSetting">
                    <label class="val-required">Label design</label>
                    <ng-select 
                        [items]="labelList" 
                        bindLabel="title" 
                        [(ngModel)]="labelId" 
                        bindValue="id" 
                        (change)="labelDesignChange($event);" 
                        placeholder="Select label design">

                        <ng-template ng-footer-tmp>
                          <button type="button" class="select-footer-btn" (click)="openModal(designLabel)" >
                              <i class="icon icon-settings"></i>
                               <span class="text"> Design new Label</span>
                          </button>
                      </ng-template>
                    </ng-select>
                </div>



                <div class="form-group">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" (change)="paperTypeChange()" [(ngModel)]="rollPaper" name="paperType" id="paperType1" [value]="true">
                        <label class="form-check-label" for="paperType1">Roll paper </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" (change)="paperTypeChange()" [(ngModel)]="rollPaper" name="paperType" id="paperType2" [value]="false">
                        <label class="form-check-label" for="paperType2">Normal paper</label>
                      </div>
                </div>

                <div class="form-group">
                    <label class="val-required">Paper dimention</label>
                    <div class="d-flex">
                        <div class="input-group mr-2" style="max-width:300px">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Width</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="paperCustomWidth" (change)="updateCanvas()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Inch</span>
                            </div>
                          </div>
                          <div class="input-group" [hidden]="rollPaper" style="max-width:300px">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Length</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="paperCustomHeight" (change)="updateCanvas()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Inch</span>
                            </div>
                          </div>
                    </div>
                
                    
                    
                </div>

                <div class="form-group">
                    <label class="val-required">Space around Label</label>
                    <div class="d-flex">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text">&nbsp;&nbsp;&nbsp;Top&nbsp;&nbsp;&nbsp;</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="labelMarginTop" (change)="updateCanvas()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Px</span>
                            </div>
                          </div>

                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">right</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="labelMarginRight" (change)="updateCanvas()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Px</span>
                            </div>
                          </div>
                    </div>
                    <div class="d-flex">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Bottom</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="labelMarginBottom" (change)="updateCanvas()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Px</span>
                            </div>
                          </div>

                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Left</span>
                            </div>
                            <input type="number" class="form-control" [(ngModel)]="labelMarginLeft" (change)="updateCanvas()" aria-label="Left margin">
                            <div class="input-group-append">
                              <span class="input-group-text">Px</span>
                            </div>
                          </div>
                    </div>

                </div>
                <div class="form-group" *ngIf="!editSetting">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" [(ngModel)]="saveSettings" type="checkbox" [value]="true" id="defaultCheck1">
                        <label class="form-check-label" for="defaultCheck1">
                        Save this settings
                        </label>
                    </div>
                </div>

                <div class="form-group" *ngIf="saveSettings||editSetting">
                    <label class="val-required">Settings name</label>
                    <input type="text" class="form-control" [(ngModel)]="settingsName" placeholder="Name this settings" aria-label="Recipient's username" aria-describedby="basic-addon2">

                </div>
            
        </div>





                
               

                <!-- <div class="form-group" style="max-width:150px">
                    <label class="val-required">Label count</label>
                    <input type="number" class="form-control" placeholder="Label count"/>

                </div> -->

                <div class="form-group float-right mt-2">
                    <!-- <a class="btn btn-secondary mr-2" >Cancel</a> -->
                    <button type="submit" class="btn btn-secondary mr-1" *ngIf="editSetting||saveSettings" (click)="cancelEditing()" >Cancel</button>
                    <button type="submit" disableIfAllsuborg  class="btn btn-secondary mr-1" *ngIf="editSetting||saveSettings" (click)="saveSetting()" >Save settings</button>
                     <button type="submit" class="btn btn-secondary mr-1"  *ngIf="!editSetting&&!saveSettings" (click)="goBack()" >Back</button>
                    <button type="submit" class="btn btn-primary" (click)="preview()" >Preview</button>
                </div>

                <!-- {{qrData}} -->
                <qrcode class="qrcode" *ngIf="showQr" #qrElement 
                [allowEmptyString]="true" 
                [qrdata]="qrData"
                [elementType]="'img'" 
                [margin]="0" 
                [width]="256" 
                [errorCorrectionLevel]="'M'"></qrcode>

            </div>
        </div>
    </div>
    <div class="col-md-6 pt-3 canvas-col" [hidden]="!showPreview||printCount<1" >
        <div class="row">
            <div class="col-md-6">
               <!-- <span class="text-muted mt-2">Total <strong>{{totalLabels}}</strong> labels</span> -->

            </div>
            <div class="col-md-6" >
                <button type="submit" *ngIf="hasPermission(Resource.INV_LABELS_PRINT,Permissions.DOWNLOAD)||hasPermission(Resource.INV_MODULE,Permissions.GLOBAL)" class="btn btn-sm btn-primary mr-2 float-right" (click)="download()"><i class="fa ft-download"></i> PDF</button>
                <button type="submit" *ngIf="hasPermission(Resource.INV_LABELS_PRINT,Permissions.PRINT)||hasPermission(Resource.INV_MODULE,Permissions.GLOBAL)" class="btn btn-sm btn-primary mr-2 float-right" (click)="download(true)"><i class="fa ft-printer"></i> Print</button>
                <!-- <button type="submit" *ngIf="hasPermission(Resource.INV_LABELS_PRINT,Permissions.PRINT)||hasPermission(Resource.INV_MODULE,Permissions.GLOBAL)" class="btn btn-sm btn-primary mr-2 float-right" (click)="setRollDownloadContent()"><i class="fa ft-printer"></i> Test</button> -->
                <!-- <button type="submit" class="btn btn-sm btn-primary mr-2 float-right" (click)="setRollDownloadContent(0)"><i class="fa ft-printer"></i> Check SVG</button> -->

            </div>
        </div>
        <br/>

       
            <canvas id="canvas" width="384" height="192"></canvas>
        
          <label-print-pagination [pagination]="pagination" (change)="paginationChange($event)"></label-print-pagination>

        <!-- {{pagination|json}} -->



    </div>

</section>



<item-select #itemSelect [selectBox]="false" (change)="onItemChange($event, qr);"></item-select>

<kit-select #kitSelect [selectBox]="false" (change)="onAssemblyChange($event, qr);"></kit-select>



<ng-template #designLabel let-modal>
   <label-create #designLabel [lockCategory]="true" [categoryId]="typeId" (onSuccess)="createDesignSuccess($event)" (onCancel)="createDesignCancel($event)" (onFailure)="createDesignSuccess($event)" ></label-create>
</ng-template>