<section id="vertical-timeline-left" class="timeline-left timeline-wrapper col-sm-10 col-12">
    <div *ngIf="history | async as groups">
        <ng-container *ngFor="let group of groups">
            <ul class="timeline">
                <li class="timeline-line mt-4"></li>
                <li class="timeline-group">
                    <span class="badge badge-primary cursor-default group-title">
                        {{group.date | date:"EEEE, MMMM d, y"}}
                    </span>
                </li>
            </ul>
            <ul class="timeline" *ngFor="let hist of group.transaction">
                <li class="timeline-line"></li>
                <li class="timeline-item">
                    <div class="timeline-badge">
                        <span class="avatar" data-toggle="tooltip" placement="left">
                            <img src="assets/img/gallery/user-avatar.jpg" class="avatar" alt="avatar" width="40">
                        </span>
                    </div>
                    <div class="timeline-card card shadow-z-1">
                        <div class="card-content">
                            <div class="card-body p-2">
                                <div class="card-subtitle text-muted mt-0">
                                    <span class="font-small-3">{{hist.created_on | date:"h:mm a"}}</span>
                                    <span *ngIf="hist.render_type==2" #copyBtn ngxClipboard
                                      [cbContent]="hist.message | replaceHtml"
                                      ngbTooltip="copied"
                                      triggers="click:blur"
                                      class="ft-copy copy-btn ml-2">
                                    </span>
                                </div>
                                <div class="message-body">
                                    <history-message [history]="hist"></history-message>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </ng-container>
    </div>
</section>
