<div class="kit-item-container mt-2 table-responsive">
    <table class="kit-items-table table m-0">
        <thead>
            <tr>
                <th width="100" class="header-label">Image</th>
                <th width="150" class="header-label">Name</th>
                <th class="header-label">Part Number</th>
                <th class="header-label">Quantity</th>
                <th class="header-label">UOM</th>
                <th class="header-label">Available <br> Quantity</th>
                <th class="header-label">Sell Price</th>
                <th width="70" class="header-label">Action</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let row of rows;let i = index">
                <tr>
                    <td class="text-center align-middle">
                        <img *ngIf="row.primary_image" width="80" height="80" [src]="fileUrl + row.primary_image + '.jpg'"/>
                        <img *ngIf="!row.primary_image" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                        <div class="itemtype" *ngIf="row.itemType == KitItemTypes.ITEM"> Item </div>
                        <div class="assemblytype" *ngIf="row.itemType == KitItemTypes.KIT"> Assembly
                        </div>
                    </td>
                    <td class="align-middle">
                        <div class="position-relative d-flex align-items-center item-selector w-100 h-100">
                            <div class="flex-1">
                                {{row.item_name || 'Click to select ' + (row.itemType === KitItemTypes.ITEM ? 'an Item' : 'an Assembly' )}}
                            </div>
                        </div>
                    </td>
                    <td class="align-middle">{{row.part_number}}</td>
                    <td class="align-middle">
                        <input type="number" min="0" [(ngModel)]="row.quantity" [name]="'quan'+ i"
                            class="selection-grid-input" (keyup)="quantityOnChange(row)"
                            (change)="quantityOnChange(row)" />
                    </td>
                    <td class="align-middle">{{row.unit}}</td>
                    <td class="align-middle">
                        <div>{{row.stock || 0}}</div>
                    </td>
                    <td class="align-middle">
                        <div class="h-100 d-flex flex-column">
                            <div style="font-weight: 500;">$ {{row.quantity * row.sell_price}}</div>
                            <div style="font-size: 10px;color:#7b7b7b">${{row.sell_price || 0}} per unit</div>
                        </div>
                    </td>
                    <td class="align-middle">
                        <div class="d-flex flex-row align-items-center">
                            <div ngbDropdown container="body" *ngIf="!isAllSuborg">
                                <button type="button" class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                    (click)="$event.stopPropagation()" ngbDropdownToggle>
                                    <i class="ft-more-vertical text-primary"></i>
                                </button>
                                <div ngbDropdownMenu>
                                    <a class="w-100" *ngIf="addButton" (click)="toggleSelect(row.itemType, i)" ngbDropdownItem>
                                        <i class="ft-delete mr-1"></i> Replace
                                    </a>
                                    <a class="w-100 bg-light-danger" *ngIf="deleteOption" (click)="deleteRow(row)" ngbDropdownItem>
                                        <i class="ft-trash-2 mr-1"></i> Delete
                                    </a>
                                </div>
                            </div>
                            <a type="button" class="toggle-button text-info" placement="left"
                                ngbTooltip="Expand Assembly" *ngIf="row.item_id && row.itemType === KitItemTypes.KIT"
                                (click)="expandKit(row, 0)">
                                <i *ngIf="!row.expanded" class="ft-chevron-right"></i>
                                <i *ngIf="row.expanded" class="ft-chevron-down"></i>
                            </a>
                        </div>
                    </td>
                </tr>
                <ng-container *ngTemplateOutlet="templateChildren; context:{row: row}">
                </ng-container>
                <ng-container>
                    <tr class="kit-item-comments">
                        <td colspan="8"><textarea rows="1" class="kit-item-comments-textarea" [(ngModel)]="row.note" placeholder="write comments here.."></textarea></td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr class="summary-tr" *ngIf="rows && rows.length > 0">
                <td colspan="5">
                    <span *ngIf="rows && rows.length > 0">
                        <b>{{rows.length}}</b> {{rows.length == 1 ? 'item' : 'items'}} selected
                    </span>
                </td>
                <td>
                    <div class="w-100 text-right">
                        Total ($):
                    </div>
                </td>
                <td colspan="1" *ngIf="isAllSuborg">
                    <span class="summary-title">{{priceSummaryFunc(rows)}}</span>
                </td>
                <td colspan="2" *ngIf="!isAllSuborg">
                    <span class="summary-title">{{priceSummaryFunc(rows)}}</span>
                </td>
            </tr>
            <tr *ngIf="!rows || rows.length <= 0">
                <td colspan="8">No items selected.</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="d-flex flex-row mt-2" *ngIf="!isAllSuborg">
    <div class="link-section" *ngIf="addButton">
        <a (click)="appendRow(KitItemTypes.ITEM)" class="text-info"><i class="ft-plus"></i> Add Item</a>
    </div>
    <div class="link-section ml-2" *ngIf="addButton">
        <a (click)="appendRow(KitItemTypes.KIT)" class="text-info"><i class="ft-plus"></i> Add Assembly</a>
    </div>
    <div class="link-section ml-2" (click)="kitId && submit(kitId)" *ngIf="saveButton">
        <button type="button" [disabled]="rows.length <= 0" class="btn btn-sm btn-info">Save Changes</button>
    </div>
</div>

<item-select #itemSelect [selectBox]="false" (change)="onItemChange($event)"></item-select>

<kit-select #kitSelect [selectBox]="false" (change)="onKitChange($event)"></kit-select>

<ng-template #templateChildren let-row="row">
    <ng-container  *ngIf="row.children && row.expanded">
        <ng-container *ngFor="let innerRow of row.children;let k = index" >
            <tr class="kit-item-childrentr" [style]="'background-color:' + innerRow.color">
                <td class="text-center align-middle">
                    <img *ngIf="innerRow.primary_image" width="80" height="80"
                        [src]="fileUrl + innerRow.primary_image + '.jpg'" />
                    <img *ngIf="!innerRow.primary_image" width="30" height="30"
                        src="/assets/img/svg/product-avatar.svg" />
                    <div class="itemtype" *ngIf="innerRow.itemType == KitItemTypes.ITEM"> Item</div>
                    <div class="assemblytype" *ngIf="innerRow.itemType == KitItemTypes.KIT">Assembly </div>
                </td>
                <td class="align-middle">
                    <div class="item-info flex-1">
                        <div class="item_name">{{innerRow.item_name}}</div>
                        <!-- <div class="item-partnumber">{{innerRow.part_number}}</div> -->
                        <div *ngIf="row.part_number" class="mt-1 badge bg-info">Item from {{row.part_number}}
                        </div>
                    </div>
                </td>
                <td class="align-middle">{{innerRow.part_number}}</td>
                <td class="align-middle">{{innerRow.quantity}}</td>
                <td class="align-middle">{{innerRow.unit}}</td>
                <td class="align-middle">{{innerRow.stock}}</td>
                <td></td>
                <td class="align-middle">
                    <a type="button" class="toggle-button text-info" placement="left"
                                    ngbTooltip="Expand Assembly" *ngIf="innerRow.item_id && innerRow.itemType === KitItemTypes.KIT"
                                    (click)="expandKit(innerRow)">
                                    <i *ngIf="!innerRow.expanded" class="ft-chevron-right"></i>
                                    <i *ngIf="innerRow.expanded" class="ft-chevron-down"></i>
                                </a>
                </td>
            </tr>
            <ng-container *ngTemplateOutlet="templateChildren; context:{row: innerRow}">
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="(!row.children || row.children.length <= 0) && row.expanded">
        <tr class="kit-item-childrentr">
            <td colspan="8">No items in the Assembly</td>
        </tr>
    </ng-container>
</ng-template>
