<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter [searchFilters]="searchFilters" [defaultFilters]="defaults" [columns]="columns" [newButton]="UIPermissions.create" [expanded]="expanded" (newButtonClick)="openModal()" (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'created_user'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable 
                        #dataTable 
                        [scrollbarH]="scrollbarH" 
                        class="bootstrap core-bootstrap" 
                        [columnMode]="'force'" 
                        [headerHeight]="50" 
                        [footerHeight]="50" 
                        rowHeight="auto" 
                        [rows]="rows" 
                        [selectionType]="SelectionType.single" 
                        (select)="onSelect($event)" 
                        [count]="page.count"
                        [externalPaging]="true" 
                        [offset]="page.offset" 
                        [externalSorting]="true" 
                        [limit]="page.limit" 
                        (page)="setPage($event)" 
                        (sort)="onSort($event)" 
                        [sorts]="[{prop:'name',dir:'asc'}]">
                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Description" prop="description">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Is Billable" prop="is_billable">
                                <ng-template let-value="value" prop="is_billable"  ngx-datatable-cell-template>
                                    <div class="d-flex justify-content-center w-100 width-100" >
                                
                                        <i class="ft-check-circle text-success" style="margin:auto" *ngIf="value"></i>
                                        <i class="ft-x-circle text-danger" style="margin:auto" *ngIf="!value"></i>
                                    </div>

                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created Date" prop="created_on" [sortable]="false">
                                <ng-template let-row="row" let-crdate="value" ngx-datatable-cell-template>
                                    <div *ngIf="!row.is_default">{{crdate | date:'MM/dd/YYYY'}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by" [sortable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <user-card [name]="value" *ngIf="!row.is_default"></user-card>
                                    <div class="" *ngIf="row.is_default"><div class="avatar mr-1 ml-0"><div class="avatar-content">SY</div></div>SYSTEM</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [sortable]="false">
                                <ng-template let-row="row" let-status="value" ngx-datatable-cell-template>
                                    <div class="badge" *ngIf="!row.is_default" [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'In Active' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && (UIPermissions.delete || UIPermissions.edit)" name="Action" prop="id" [sortable]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body" *ngIf="!isDefault(id)">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                          <i class="ft-more-vertical text-primary"></i>
                                      </button>
                                        <div ngbDropdownMenu>
                                            <button class="w-100" *ngIf="UIPermissions.edit" (click)="openModal(id, current)" ngbDropdownItem>
                                              <i class="ft-edit mr-1"></i> Edit
                                          </button>
                                            <button class="w-100 bg-light-danger" *ngIf="UIPermissions.delete" (click)="deleteRow(id)" ngbDropdownItem>
                                              <i class="ft-trash-2 mr-1"></i> Delete
                                          </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="warehouse-details">
                        <div class="d-flex flex-row mt-2">
                            <h5 class="item-title m-0">{{selected?.name}}</h5>
                            <div class="d-flex justify-content-end align-items-start" >
                                <a *ngIf="UIPermissions.edit&&!selected.is_default" (click)="openModal(selected.id, selected)" ngbTooltip="Edit" class="btn btn-sm bg-light-secondary">
                                    <i class="ft-edit"></i> Edit
                                </a>
                                <button *ngIf="UIPermissions.delete&&!selected.is_default" class="btn btn-sm bg-light-danger ml-1" (click)="deleteRow(selected.id)">
                                  <i class="ft-trash-2 mr-1"></i> Delete
                              </button>
                                <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                  <i class="ft-x"></i>
                              </button>
                            </div>
                        </div>
                        <div class="group mt-3">
                            <div class="group-header d-flex justify-content-between">
                                Overview
                                <!-- <div class="status-toggle-btn">
                                    <label class="title">Status :</label>
                                    <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                        <input type="checkbox" [(ngModel)]="selected.is_active" id="status" name="active" class="custom-control-input" [disabled]="selected.is_active ? !UIPermissions.deactivate:!UIPermissions.activate" (change)="changeStatus()">
                                        <label class="custom-control-label" for="status">
                                          <span></span>
                                      </label>
                                    </div>
                                </div> -->
                            </div>
                            <div class="group-body">
                                <div class="ov-row" *ngIf="selected?.description">
                                    <label class="col-md-3 p-0 item-label">Description</label>
                                    <label class="item-label-value">{{selected.description}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.created_on&&!selected.is_default">
                                    <label class="col-md-3 p-0 item-label">Created On</label>
                                    <label class="item-label-value">{{selected.created_on | date:'MM/dd/YYYY'}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.is_billable!=null">
                                    <label class="col-md-3 p-0 item-label">Is Billable</label>
                                    <label class="item-label-value">
                                        <i class="ft-check-circle text-success" *ngIf="selected.is_billable"></i>
                                        <i class="ft-x-circle text-danger"  *ngIf="!selected.is_billable"></i>
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.created_by">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value" *ngIf="!selected.is_default">{{selected.created_by}}</label>
                                    <label class="item-label-value" *ngIf="selected.is_default">SYSTEM</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #formModal let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h5 class="modal-title">{{timesheetTypeId > 0 ? 'Edit Timesheet type' : 'New Timesheet type'}}</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <timesheet-type-form [uniqueId]="timesheetTypeId" [bindingModel]="selected" (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()"></timesheet-type-form>
            </div>
        </ng-template>

    </div>
</section>