
export class Distributor {

    public id: number;

    public name: string;

    public account_number: string;

    public distributors_unique_id: string;

    public customer_type: number;

    public customer_system_type:number;

    public industry: number;

    public account_rep: number[];

    public currency: number;

    public payment_terms: number;

    public comments: number;

    public additional_fields: any;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public logo: number;

    public is_delete: boolean;

    public is_active: boolean;

    public customer: number;

    public suborg:number;

    public tax: number;

    public is_service_ticket_billable: boolean;

    public created_user: number;

    public website: string;

    //resolved

    customer_type_name: string;

    customer_system_type_name:string;

    currency_name: string;

    industry_name: string;

    payment_terms_name: string;

    tax_value: string;
}

export class DistributorLocation {

    public id: number;

    public name: string;

    public address: string;

    public city: string;

    public state: string;

    public zip: string;

    public account_number: string;

    public is_billing: boolean;

    public is_shipping: boolean;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public distributor: number;

    public service_ticket_billable: boolean;

    public location_types:number;

    public location_system_types:number;

    location_types_name:string;

    location_system_types_name:string;
}

export class DistributorContact {

    public id: number;

    public first_name: string;

    public last_name: string;

    public email: string;

    public phone: string;

    public extension: string;

    public mobile: string;

    public fax: string;

    public title: string;

    public department: string;

    public portal_access: boolean;

    public twitter: string;

    public facebook: string;

    public instagram: string;

    public linkedin: string;

    public additional_fields: any;

    public send_welcome_email: boolean;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public distributor: number;

    public picture: number;

    public location: number;

    location_name: string;

    bgClass: string;
}

export class DistributorLicense {

    public id: number;

    public distributor: number;

    public license: number;

    public distributor_location: number;

    public customer: number;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public is_delete: boolean;

    public is_active: boolean;

    distributor_name: string;

    distributor_location_name: string;

    distributor_type: string;

    license_number: string;
}

export class DistributorCustomer {

    public id: number;

    public distributor: number;

    public tenant_customer: number;

    public location: number;

    public customer: number;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public is_delete: boolean;

    tenant_customer_name: string;

    location_name: string;

    customer_type:string;
}
