import { TimeSheetsComponent } from './pages/time-sheets/time-sheets.component';
import { ServiceTicketCommentsComponent } from './components/service-ticket-comments/service-ticket-comments.component';
import { InventoryModule } from './../inventory/inventory.module';
import { TenantCustomersModule } from './../tenant-customers/tenant-customers.module';
import { UserModule } from './../user/user.module';
import { EscalationPeriodComponent } from './pages/escalation-period/escalation-period.component';
import { ActionsComponent } from './pages/actions/actions.component';
import { AddServiceTicketComponent } from './pages/add-service-ticket/add-service-ticket.component';
import { ServiceTicketsComponent } from './pages/service-tickets/service-tickets.component';
import { CallReasonsComponent } from './pages/call-reasons/call-reasons.component';
import { ProductTypesComponent } from './pages/product-types/product-types.component';
import { NgModule } from '@angular/core';

import { ServiceTicketRoutingModule } from './service-ticket-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { PrioritiesComponent } from './pages/priorities/priorities.component';
import { TimesheetTypesComponent } from './pages/timesheet-types/timesheet-types.component';
import { TimesheetTypeFormComponent } from './components/forms/timesheet-type-form/timesheet-type-form.component';
import { ChecklistComponent } from './pages/checklist/checklist.component';
import { SubTaskGridComponent } from './components/sub-task-grid/sub-task-grid.component';
import { QuillModule } from 'ngx-quill';
import { TaskSelectComponent } from './components/select/task-select/task-select.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { StListComponent } from './components/st-list/st-list.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { StItemsComponent } from './components/st-items/st-items.component';
//import { ServiceTicketSharedModule } from '../service-ticket-shared/service-ticket-shared.module';
import { RouterModule } from '@angular/router';
import { ServiceTicketSelectModule } from '../service-ticket-select/service-ticket-select.module';
import { SalesSharedModule } from '../sales-shared/sales-shared.module';



@NgModule({
  declarations: [
    ServiceTicketsComponent,
    AddServiceTicketComponent,
    ProductTypesComponent,
    CallReasonsComponent,
    PrioritiesComponent,
    ActionsComponent,
    EscalationPeriodComponent,
    ServiceTicketCommentsComponent,
    TimeSheetsComponent,
    TimesheetTypesComponent,
    TimesheetTypeFormComponent,
    ChecklistComponent,
    SubTaskGridComponent,
    TaskSelectComponent,
    StListComponent,
    StItemsComponent,
    
  ],
  imports: [
    ServiceTicketRoutingModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    UserModule,
    SharedModule,
    InventoryModule,
    TenantCustomersModule,
    QuillModule.forRoot(),
    DragDropModule,
    MatIconModule,
    NgxSummernoteModule,
    //ServiceTicketSharedModule,
    ServiceTicketSelectModule,
    RouterModule,
    SalesSharedModule
  ],
  exports:[
    ServiceTicketCommentsComponent,
    TaskSelectComponent,
    StListComponent,
    AddServiceTicketComponent
  ]
})
export class ServiceTicketModule { }
