<div class="kit-item-container mt-2 table-responsive">
    <table class="kit-items-table table m-0">
        <thead>
            <tr>
                <th width="60" class="header-label">Image</th>
                <th width="250" class="header-label">Name</th>
                <th class="header-label">Part Number</th>
                <th class="header-label">Quantity</th>
                <th class="header-label">Serial number</th>
                <th *ngIf="coverageTypeSelection==2" class="header-label">Coverage type</th>

                <!-- <th class="header-label">Sell Price</th> -->
                <th width="70" class="header-label">Action</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let row of rows;let i = index">
                <tr *ngIf="!row.is_delete">
                    <td>
                        <img *ngIf="row.image_link" width="30" height="30" [src]="fileUrl + '/' + row.image_link" />
                        <img *ngIf="!row.image_link" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                    </td>
                    <td>
                        <div class="position-relative d-flex align-items-center item-selector w-100 h-100 cursor-pointer" (click)="toggleSelect(row.itemType, i)">
                            <div class="flex-1 item-name">
                                {{row.item_name || 'Click to select ' + (row.itemType === KitIemTypes.ITEM ? 'an Item' : 'an Assembly' )}}
                            </div>
                            <span class="arrow-icon-down">
                                <span class="icon-down"></span>
                            </span>
                        </div>
                        <p class="text-danger error-text" *ngIf="submitted&&(!row.item_id||row.item_id==undefined)&&(!row.kit_id||row.kit_id==undefined)">Please select from the list</p>
                    </td>
                    <td>{{row.part_number}}</td>
                    <td>
                        <input type="number" min="0"
                        [(ngModel)]="row.quantity"
                        [name]="'quan'+ i"
                        class="selection-grid-input"
                        (keyup)="quantityOnKeyDown(row)"
                        (change)="quantityOnChange(row)" />
                    </td>
                    <!-- <td><input type="text" class="form-control" placeholder="Enter serial number" [(ngModel)]="row.serial_number"/></td> -->
                    <td><serial-number-select [sno]="row.serial_number" [qty]="row.quantity" (out)="snoUpdate($event, i)"></serial-number-select>

                    </td>
                    <td *ngIf="coverageTypeSelection==2">
                        <select class="form-control" name="coverage_type" [(ngModel)]="row.coverage_type" >
                            <option value="0">Select coverage</option>
                            <option value="1" >Meterial only</option>
                            <option value="2">Labor only</option>
                            <option value="3">Meterial and labor </option>

                       </select>

                       <p class="text-danger error-text" *ngIf="submitted&&(!row.coverage_type||row.coverage_type==0)">Select coverage type</p>

                     </td>

                    <!-- <td>
                        <div class="h-100 d-flex flex-column">
                            <div style="font-weight: 500;">$ {{row.quantity * row.sell_price}}</div>
                            <div style="font-size: 10px;color:#7b7b7b">${{row.sell_price || 0}} per unit</div>
                        </div>
                    </td> -->
                    <td>
                        <div class="d-flex flex-row align-items-center">
                            <button type="button" class="delete-btn text-danger" *ngIf="row.itemType === KitIemTypes.KIT" (click)="deleteKitRow(row, i)">
                                <i class="ft-trash-2"></i>
                            </button>
                            <button type="button" class="delete-btn text-danger" *ngIf="row.itemType === KitIemTypes.ITEM" (click)="deleteItemRow(row, i)">
                                <i class="ft-trash-2"></i>
                            </button>
                            <a type="button" class="toggle-button text-info" placement="left" ngbTooltip="Expand Assembly" *ngIf="row.item_id && row.itemType === KitIemTypes.KIT" (click)="expandKit(row)">
                                <i *ngIf="!row.expanded" class="ft-chevron-right"></i>
                                <i *ngIf="row.expanded" class="ft-chevron-down"></i>
                            </a>
                        </div>
                    </td>
                </tr>
                <ng-container *ngIf="row.children && row.expanded">
                    <tr *ngFor="let innerRow of row.children;let k = index" class="kit-item-childrentr">
                        <td>
                            <img *ngIf="innerRow.image_link" width="30" height="30" [src]="fileUrl + '/' + innerRow.image_link" />
                            <img *ngIf="!innerRow.image_link" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                        </td>
                        <td>{{innerRow.item_name }}</td>
                        <td>{{innerRow.part_number}}</td>
                        <td>{{innerRow.quantity}}</td>
                        <!-- <td><input type="text" class="form-control" placeholder="Enter serial numbers" [(ngModel)]="row.serial_number"></td> -->
                        <td>
                            <serial-number-select [sno]="row.serial_number" [qty]="row.quantity" (out)="snoUpdate($event, k)"></serial-number-select>

                        </td>
                        <td *ngIf="coverageTypeSelection==2">
                            <select class="form-control" name="coverage_type" [(ngModel)]="row.coverage_type" >
                                <option>Select coverage</option>
                                <option value="1">Meterial only</option>
                                <option value="2">Labor only</option>
                                <option value="3">Meterial and labor </option>

                           </select>
                        </td>
                        <td></td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="(!row.children || row.children.length <= 0) && row.expanded">
                    <tr class="kit-item-childrentr">
                        <td colspan="6">No items in the Assembly</td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr *ngIf="!rows || rows.length <= 0">
                <td colspan="4">No items selected.</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="d-flex flex-row mt-2">
    <div class="link-section" *ngIf="addButton">
        <a (click)="appendRow(KitIemTypes.ITEM)" class="text-info"><i class="ft-plus"></i> Add Item</a>
    </div>
    <div class="link-section ml-2" *ngIf="addButton">
        <a (click)="appendRow(KitIemTypes.KIT)" class="text-info"><i class="ft-plus"></i> Add Assembly</a>
    </div>
    <div class="link-section" (click)="wId && submit(wId)" *ngIf="saveButton">
        <button type="button" [disabled]="rows.length <= 0" class="btn btn-sm btn-info">Save Changes</button>
    </div>
</div>

<item-select #itemSelect [selectBox]="false" (change)="onItemChange($event)"></item-select>

<kit-select #kitSelect [selectBox]="false" (change)="onKitChange($event)"></kit-select>
