import { WHBaseModel } from 'src/app/core/models/common.models';
import { id } from 'src/app/core/utils/global.functions';

export class Kit extends WHBaseModel {

    public id: number;

    public name: string;

    public internal_number: string;

    public sku: string;

    public item_type: number;

    public unit_type: number;

    public revision: number;

    public description: string;

    public spec_sheet: string;

    public instructions_sheet: string;

    public notes: string;

    public cost_price: number;

    public sell_price: number;

    public sales_account: number;

    public category: number;

    public additional_fields: any;

    // public tax: number;

    public taxable: boolean= true;

    public images:string[] = [];

    public primary_image:string;

    public product_line:number;

    public material:number;

    public show_on_catelog:boolean;

    public is_inventoried:boolean = true;
}

export class KitWrapper extends Kit {

    public category_name: string;

    public salesaccount_name: string;

    public itemtype_name: string;

    public unittype_name: string;

    public tax_value: number;

    available_stock: number;

    unit: string;
    note: string;

    product_line_name:string;

    material_name:string;
}

export class State extends WHBaseModel{

    public id: number;

    public state_name: string;

    public tax: number;
}

export class KitItem extends WHBaseModel {

    public id: number;

    public quantity: number = 1;

    public kits: number;

    public items: number;

    public order:number;

    primary_image: string;

    item_name: string;

    part_number: string;

    item_price: number = 0;

    item_price_calc: number = 0;

    guid: string;

    stock: number;
    unit: string;
    note: string;
    public is_inventoried:boolean;
}

export class Kit2Kit extends WHBaseModel {

    public id: number;

    public kit: number;

    public kit_kit: number;

    public quantity: number = 1;

    public order:number;

    guid: string;

    kit_name: string;

    internal_number: string;

    public primary_image: string;

    sell_price: number = 0;

    sell_price_calc: number = 0;

    stock: number;
    unit: string;
    note: string;

    public is_inventoried:boolean;
}

export class KitItemRow {

    public id: number;

    public item_id: number;

    public itemType: KitItemsType;

    public quantity: number = 1;

    public kit_id: number;

    public part_number: string;

    public sell_price: number = 0;

    public sell_price_calc: number = 0;

    public primary_image: string;

    public item_name: string;

    public guid: string = id();

    public customer: number;

    public children: KitItemRow[];

    public expanded:boolean = false;

    public order:number;

    public stock:string;

    public unit:string;

    public note:string;

    public color:string;

    public level:number;

    public is_inventoried:boolean;

}

export enum KitItemsType {
    ITEM = 'ITEM',
    KIT = 'KIT',
    BUILD_ASSEMBLY='build_assembly'
}

export class KitWareHouseAggregate {

  public warehouse: number;

  public warehouse_location: number;

  public quantity: number = 0;

  public available_quantity: number = 0;

  public location_name:string;

  public warehouse_name:string;
}
