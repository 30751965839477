export class additionalFieldCreate{
    public field_name:string;

    public values:string[];
}

export class additionalFieldUpdate{

    public field_name:string;

    public values:additionalFieldUpdateValue[];

}

export class catMaterialFilterResponse {

    material_id: number;

    material_name: string;

}
export class additionalFieldUpdateValue{

    public id:number;

    public value:string;

    public action:string;

}

export enum updateStatus{
    new="1", 
    update="2",
    delete="3" 
}


export class additionalFieldsGet{

    additional_field:additionalFields;

    additional_field_values:additional_field_values;

}

export class additionalFieldsList{

    additional_field:additionalFields=new additionalFields();

    values:additional_field_values[]=[new additional_field_values()];


}


export class additionalFields{

    public id:number;

    public field_name:string;

    public created_user_id:number;

    public description:string;

    public suborg_id:number;

    public is_delete:boolean;

    public created_on:string;

    public customer_id:number;

}

export class additional_field_values{

    public id:number;

    public field_value:string;

    public additional_field_id:number;

    public created_user_id:number;

    public description:string;

    public suborg_id:number;

    public is_delete:boolean;

    public created_on:string;

    public customer_id:number;
}


export class catCustomField{

    public category:number;

    public additional_data:catCustomFieldData[]=[];


}

export class itemCustomField{
    public item:number;

    public additional_data:catCustomFieldData[]=[new catCustomFieldData()];

}

export class assemblyCustomField{
    public kit:number;

    public additional_data:catCustomFieldData[]=[new catCustomFieldData()];

}


export class catCustomFieldData{

    public additional_field:number=null;

    public id:number;

    public value:number=null;

    public action:number=1;

    public category_additional_field:number=null;
    
    public custom_additional_field:string='';

    public custom_additional_field_value:string='';

    public type:additionalFieldType=1;

    public category_value_list:catCustomFieldValueList[];

}

export class catCustomFieldResponse{

    public additional_field_id:number;

    public additional_field_name:string;

    public category_id:number;

    public category_value_list:catCustomFieldValueList[];

    public value_id_id:number;

    public selected:number[];


}

export class catCustomFieldValueList{
    public id:number;

    public field_value:string;
}

export enum additionalFieldType{
    formList=1,
    custom=2
}

export class itemCustomFieldResponse{
    status:number;

    message:string;

    data:itemCustomFieldGet[];

    detail:string;
}


export class itemCustomFieldGet{

    public id:number;

    public item_id:number;

    public additional_field_id:number;

    public category_additional_field_id:number;

    public value_id_id:number;

    public created_user_id:number;

    public description:string;

    public suborg_id:number;

    public is_delete:boolean;

    public created_on:string;

    public customer_id:number;

}




