<section class="dashlet-container d-flex flex-row flex-wrap mb-1 mt-2" *ngIf="dashboardData | async as dashValues">
    <div class="status-tile" (click)="setFilter('Calls Today')">
        <div class="card m-0 gradient-mint">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.today || 0}}</h3>
                            <span>Today</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="carddata=='Calls Today'">
            <div class="caret" style="border-top-color: #398ead"></div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('Calls Yesterday')">
        <div class="card m-0 orange_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.yesterday || 0}}</h3>
                            <span>Yesterday</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="carddata=='Calls Yesterday'">
            <div class="caret" style="border-top-color: #fa9845"></div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('Calls This Week')">
        <div class="card m-0 blue_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.current_week || 0}}</h3>
                            <span>This Week</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="carddata=='Calls This Week'">
            <div class="caret" style="border-top-color: #589fe4"></div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('Calls Last Week')">
        <div class="card m-0 green_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.last_week || 0}}</h3>
                            <span>Last Week</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="carddata=='Calls Last Week'">
            <div class="caret" style="border-top-color: #5ac96e"></div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('Calls This Month')">
        <div class="card m-0 gradient-ibiza-sunset">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.current_month || 0}}</h3>
                            <span>This Month</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="carddata=='Calls This Month'">
            <div class="caret" style="border-top-color: #ca5e52"></div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('Calls Last Month')">
        <div class="card m-0 gradient-king-yna">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-1">{{dashValues.last_month || 0}}</h3>
                            <span>Last Month</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="carddata=='Calls Last Month'">
            <div class="caret" style="border-top-color: #a45bb9"></div>
        </div>
    </div>
</section>
