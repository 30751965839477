import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'user-card',
  template: `<div class="d-flex align-items-center">
                <div class="avatar mr-1 ml-0">
                    <div class="avatar-content">{{ name | shortName}}
                    </div>
                </div>
                <div class="cell-line-height">
                    <div class="line-height-1 user-name">{{ name }}</div>
                    <div *ngIf="role" class="text-muted font-small-2">{{ role }}</div>
                </div>
            </div>`,
  styles: [
    `.user-name { white-space: nowrap; }`
  ]
})
export class UserCardComponent implements OnInit {

  @Input() id: number;

  @Input() name: string;

  @Input() role: string;

  constructor() { }

  ngOnInit(): void { }
}
