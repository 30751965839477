<div class="select-wrapper">
    <p class="alert alert-warning pull-right"  *ngIf="count > maxinput-1">! Maximum selection reached</p>

<ng-select  [placeholder]="placeholder"
            appendTo="body"
            [searchable]="true"
            [clearSearchOnAdd]="true"
            [items]="options"
            bindLabel="username"
            bindValue="id" 
            [multiple]="multiple" 
            [(ngModel)]="value"
            [searchFn]="customSearchFn"
            [closeOnSelect]="!multiple" 
            [maxSelectedItems]="maxinput"
            (change)="onValueChanged($event)">
    
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input *ngIf="multiple" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>
        <span class="ml-1">{{item.username}}</span>
        <span class="pull-right">{{item.associated_username}}</span>
    </ng-template>
</ng-select>
</div>
