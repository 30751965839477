import { DataFilterRequest } from 'src/app/core/models/grid-filter.models';
import { CustomerLicense } from './../../models/license-number';
import { LicenseNumberService } from './../../services/license-number.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { TenantCustomerService } from './../../services/tenant-customer.service';
import { SubSink } from 'subsink';
import { ShipmentMethods, TenantCustomer } from './../../models/tenant-customer';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CustomField } from 'src/app/core/models/custom-field';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ShippingMethods } from '../../models/shipping-methods.models';
import { ShippingMethodsService } from '../../services/shipping-methods.service';
import { indexOf } from 'lodash';

@Component({
  selector: 'app-add-tenant-customer',
  templateUrl: './add-tenant-customer.component.html',
  styleUrls: ['./add-tenant-customer.component.scss'],
})
export class AddTenantCustomerComponent implements OnInit, OnDestroy {

  public model: TenantCustomer;

  public customerId: number;

  public additionalFields: CustomField[];

  public customFieldInputs = { key: '', value: '' };

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public nameExist: boolean = false;

  private subs: SubSink = new SubSink();

  public licenseNumbers: number[];

  public licenseNumbersMaped: CustomerLicense[] = [];

  public shipmentMethodList:ShippingMethods[]=[];

  public shipmentMethodId:number;

  public shipmentMethodName:string;

  public shipmentMethodAccNo:string;

  public shipmentMethodIsDefault:boolean=false;



  constructor(
    route: ActivatedRoute,
    private tenantCustService: TenantCustomerService,
    private licenceNumService: LicenseNumberService,
    private ShippingMethodService:ShippingMethodsService,
    public location: Location,
    private toastr: ToastrService,
    private modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private customFields: CustomFieldsService,
    private router: Router,
    private ra: ResourceAccessService
  ) {
    this.subs.sink = route.params.subscribe(params => this.customerId = +params.id || 0);
  }

  ngOnInit() {

    if (this.customerId) {

      this.subs.sink = this.tenantCustService.getById(this.customerId).subscribe(customer => {
        this.model = customer;
        this.additionalFields = this.customFields.obj2CustomFields(customer.additional_fields);
        this.cdr.markForCheck();
      });

      let request: DataFilterRequest = {
        filters: [{ colname: 'tenant_customer', condition: 'equalto', value: this.customerId, operator: 'AND' }],
        sort: null,
      };

      this.subs.sink = this.licenceNumService.getCustomerLicenses(request, 0).subscribe(resp => {
        this.licenseNumbers = resp.result.map((item) => item.license);
        this.licenseNumbersMaped = resp.result;
        this.cdr.markForCheck();
      });

    }
    else {
      this.model = new TenantCustomer();
    }
  }

  onSubmit() {
    if(this.model.taxable){
        if(!this.model.tax){
            this.toastr.error("Please Select a tax");
            return;
        }
    }
    else
        this.model.tax=null;
    if(this.model.website)
        this.model.website = this.model.website.replace("https://",'');
    this.model.additional_fields = this.customFields.customFields2Obj(
      this.additionalFields
    );

    this.model.customer = this.tenantCustService.customerId;
    this.model.suborg = this.tenantCustService.suborgId;


    let api =
      this.customerId
        ? this.tenantCustService.update(this.customerId, this.model)
        : this.tenantCustService.create(this.model);

    this.subs.sink = api.subscribe(
      (resp) => {
        let custLicenses = this.getNewLicenseNumbers(resp.id);

        if (custLicenses && custLicenses.length) {
          this.createCustomerLicense(resp.id, custLicenses);
        } else {
          this.toastr.success(
            this.customerId > 0
              ? `Customer info updated`
              : `New customer created`
          );
          this.navigateOnComplete(resp.id);
        }
      },
      (error) => {
        if (error.error && error.error['non_field_errors']) {
          this.nameExist = true;
          this.toastr.warning('Name already exist');
          this.cdr.markForCheck();
        } else {
          let errorMessage =
            this.customerId > 0
              ? `Failed to update customer`
              : `Failed to create customer`;
          this.toastr.error(errorMessage);
        }
      }
    );
  }

  getNewLicenseNumbers(customerId: number): CustomerLicense[] {
    let ids = this.licenseNumbers?.filter(
      (license_num) =>
        !this.licenseNumbersMaped.some((m) => m.license === license_num)
    );

    if (ids && ids.length) {
      return ids.map((item) => {
        let cl = new CustomerLicense();
        cl.tenant_customer = customerId;
        cl.license = item;
        cl.customer = this.licenceNumService.customerId;
        cl.suborg = this.licenceNumService.suborgId;
        return cl;
      });
    }
    return null;
  }

  createCustomerLicense(customerId: number, custLicenses: CustomerLicense[]) {
    this.subs.sink = this.licenceNumService
      .createCustomerLicense(custLicenses)
      .subscribe((response) => {
        this.toastr.success(
          this.customerId > 0 ? `Customer info updated` : `New customer created`
        );
        this.navigateOnComplete(customerId);
      });
  }

  navigateOnComplete(customerId: number) {
    if (this.hasPermission(this.Permissions.VIEW_INFO)) {
      this.router.navigate(['/customers', customerId, 'view']);
    } else {
      this.location.back();
    }
  }

  getShipmentMethods(){

    let request: DataFilterRequest = { filters: [], sort: [{colname:'name',direction:'asc'}] };

    this.subs.sink = this.ShippingMethodService.getFiltered(request, 0).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.shipmentMethodList = response.result;
    });

  }



  addShippingMethod(){

      if(this.shipmentMethodId!=null){
        var tmp=new ShipmentMethods;


        if(this.isShipmentMethodExists(this.shipmentMethodId)){
        this.toastr.error('This shipment method already added');

        }
        else if(this.isShipmentMethodDefaultExists()&&this.shipmentMethodIsDefault){
          this.toastr.error('Please unmark default. Because existing method already marked as default');
        }
        else{

          tmp.shipping_method=this.shipmentMethodId;

          tmp.shipment_method_name=this.shipmentMethodName;

          tmp.account_number=this.shipmentMethodAccNo;

          tmp.is_default=this.shipmentMethodIsDefault;

          tmp.customer=this.model.customer;

          this.model.shipment_methods.push(tmp);

          this.shipmentMethodId=null;

          this.shipmentMethodAccNo=null;

          this.shipmentMethodIsDefault=false;

          this.modal.dismissAll();
        }


      }
      else{
        this.toastr.error('Please select shipping method to continue');
      }


  }

  isShipmentMethodExists(id:number) {
    if(this.model&&this.model.shipment_methods){
      return this.model.shipment_methods.some(function(el) {
        return el.shipping_method === id;
      });
    }
    else{
      return false;
    }
  }

  isShipmentMethodDefaultExists() {
    if(this.model&&this.model.shipment_methods){
      return this.model.shipment_methods.some(function(el) {
        return el.is_default === true;
      });
    }
    else{
      return false;
    }
  }

  deleteShipmentMethod(index:number){
    if(this.model.shipment_methods[index]){
      // this.model.shipment_methods.splice(index,1);
      this.model.shipment_methods[index].is_delete=true;

      this.cdr.markForCheck();
      console.log(this.model.shipment_methods);

    }
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(
      this.Resource.CUSTOMERS,
      [permission, this.Permissions.GLOBAL],
      this.Resource.CUSTOMER_MODULE
    );
  }

  openCustomFieldModal(content: any) {
    this.customFieldInputs.key = '';
    this.customFieldInputs.value = '';
    this.modal.open(content);
  }

  addCustomField() {
    this.additionalFields = this.additionalFields ?? [];
    if (
      !this.additionalFields.some((x) => x.name === this.customFieldInputs.key)
    ) {
      let customField = new CustomField();
      customField.name = this.customFieldInputs.key;
      customField.value = this.customFieldInputs.value || null;
      this.additionalFields.push(customField);
    } else {
      this.toastr.info(`Field '${this.customFieldInputs.key}' exist`);
    }
    this.modal.dismissAll();
  }

  deleteCustomField(id: string) {
    this.additionalFields = this.additionalFields.filter((x) => x.id !== id);
  }

  onLicenseNumberRemove(licenseId: number) {
    let custLicense = this.licenseNumbersMaped?.find(
      (m) => m.license === licenseId
    );

    if (custLicense) {
      this.subs.sink = this.licenceNumService
        .deleteCustomerLicense(custLicense.id)
        .subscribe(() => {
          this.licenseNumbersMaped = this.licenseNumbersMaped.filter(
            (cl) => cl.id !== custLicense.id
          );
          this.toastr.success('License number removed');
        });
    }
  }


  openSMModal(content: any) {
    this.getShipmentMethods();
    this.customFieldInputs.key = '';
    this.customFieldInputs.value = '';
    this.modal.open(content);
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
