<div class="role-select-wrapper">

    <!-- {{_afList|json}} -->
    <ng-select 
    ngClass="ng-select-custom-footer" 
    [placeholder]="placeholder" 
    [items]="_afList|async" 
    bindLabel="additional_field.field_name" 
    [(ngModel)]="selected" 
    [readonly]="readonly" 
    [minTermLength]="2" 
    bindValue="additional_field.id"
    [trackByFn]="trackByFn" 
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="additionFieldInput" 
    (change)="onValueChanged($event)" 
    (clear)="onSelectionClear()" 
    [appendTo]="'body'"
    [hideSelected]="true"
    (open)="placeholder = 'Search Category...'" 
    (close)="placeholder = 'Select Additional field'">
        <ng-template ng-header-tmp *ngIf="isHeaderVisible">
            <div class="custom-option custom-header">
                <div class="column width-50-per">Name</div>
                <!-- <div class="column width-50-per">No of values</div> -->
            </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <div class="custom-option">
                <div class="column width-50-per" style="padding-right: 3px;">
                    {{item.additional_field.field_name}}
                </div>
                <!-- <div class="column width-50-per" style="padding-left: 3px;">
                    {{item.values.length}}
                </div> -->
            </div>
        </ng-template>
        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
                <a class="add-button text-info" *ngIf="addButton && hasPermission(Permissions.CREATE)" (click)="openModal(content)">
                    <i class="icon icon-settings mr-1"></i>Add new
                </a>
            </div>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add additional field</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <app-additional-field-form 
                *ngIf="addButton" 
                (onSuccess)="onDataSuccess($event)" 
                (onError)="onDataError($event)" 
                (onCancel)="onCancel()">
            </app-additional-field-form>
        </div>
    </ng-template>
</div>