<ngx-datatable class="bootstrap core-bootstrap row-detail-custom" [scrollbarH]="false" [headerHeight]="40" rowHeight="auto" [columnMode]="'force'" [rows]="rows" [selectionType]="SelectionType.single" (select)="onSelect($event)" [sorts]="[{prop:'name',dir:'asc'}]">
    <ngx-datatable-row-detail [rowHeight]="100" #myDetailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <div class="location-detail d-flex flex-row pt-1">
                <div class="col-12">
                    <span class="badge-pill mr-2" [ngClass]="{'bg-light-success':row.service_ticket_billable,'bg-light-danger':!row.service_ticket_billable}">
                        {{ row.service_ticket_billable ? 'Service Ticket Billable' : 'Service Ticket Not Billable' }}
                    </span>
                    <span class="badge-pill mr-2" [ngClass]="{'bg-light-success':row.is_billing,'bg-light-danger':!row.is_billing}">
                        {{ row.is_billing ? 'Billable' : 'Not Billable' }}
                    </span>
                    <span class="badge-pill mr-2" [ngClass]="{'bg-light-success':row.is_shipping,'bg-light-danger':!row.is_shipping}">
                        {{ row.is_shipping ? 'Shipping' : 'Not Shipping' }}
                    </span>
                    <div class="d-flex mt-2" *ngIf="row.location_types_name">
                        <div class="detail-key">Locaton Type</div>
                        <div class="detail-value">{{row.location_types_name}}</div>
                    </div>
                    <div class="d-flex" *ngIf="row.location_system_types_name">
                        <div class="detail-key">Location System Type</div>
                        <div class="detail-value">{{row.location_system_types_name}}</div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="$event.stopPropagation();toggleExpandRow(row)"></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
    <ngx-datatable-column name="Address" prop="address">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="address-column">
                <div *ngIf="row.address">{{row.address}}</div>
                <div *ngIf="row.state || row.city">
                    <span>{{row.state}}</span>,
                    <span>{{row.city}}</span>
                </div>
                <div *ngIf="row.zip">zip: {{row.zip}}</div>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="A/c No" prop="account_number"></ngx-datatable-column>
    <ngx-datatable-column name="Type" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="d-flex flex-column">
                <div *ngIf="row.is_billing">Billing</div>
                <div *ngIf="row.is_shipping">Shipping</div>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="hasPermission(Permissions.DELETE)" name="Action" prop="id" [sortable]="false" [width]="60" [canAutoResize]="false">
        <ng-template let-id="value" ngx-datatable-cell-template>
            <button (click)="$event.stopPropagation();deleteLocation(id)" class="btn text-danger no-hover p-0" type="button">
                <i class="ft-trash-2"></i>
            </button>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<ng-template #FormModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{bindingModel ? 'Edit Location' : 'New Location'}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <distributor-location-form [distributorId]="distributorId" [bindingModel]="bindingModel" (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)" (cancel)="onCancel()"></distributor-location-form>
    </div>
</ng-template>
