<div class="unit-type-select-wrapper">
    <ng-select ngClass="ng-select-custom-footer" [placeholder]="placeholder" [items]="options | orderBy:'name'" bindLabel="name" bindValue="id" [(ngModel)]="value" [readonly]="readonly" (change)="onValueChanged($event)">
        <ng-template ng-footer-tmp *ngIf="hasPermission()">
            <button type="button" class="select-footer-btn" (click)="openModal(content)">
                <i class="icon icon-settings"></i>
                <span class="text">Add Sales Account</span>
            </button>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Sales Account</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-sales-account-form (onSuccess)="onDataSubmittedSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()"></app-sales-account-form>
        </div>
    </ng-template>
</div>