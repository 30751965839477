<div class="toolbar-box" *ngIf="enableControls">

    <div class="d-flex toolbar" *ngIf="canvas&&!canvas.getActiveObject()&&active">
        <div role="group" class="btn-group ml-1">
            <div class="row">
                <div class="col-md-5">
                    <!-- {{canvas.backgroundColor}} -->
                    <div class="change-me bg-color-btn" [style.background-color]="canvas.backgroundColor"
                        [cpPosition]="'right'" [cpPositionOffset]="'50%'" [cpPositionRelativeToArrow]="true"
                        [cpOKButton]="true" [cpCancelButton]="true" [cpSaveClickOutside]="true"
                        [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                        (colorPickerChange)="onPresetColorsChange($event)"   
                        [(colorPicker)]="canvasBgColor" (cpSliderChange)="canvasBgChange($event);">
                    </div>
                </div>  
            </div>


        </div>
    </div>

    <div class="d-flex toolbar" *ngIf="canvas&&canvas.getActiveObject()">
        <div role="group" class="btn-group" *ngIf="canvas.getActiveObject().type=='textbox'">
            <button class="btn btn-sm bg-light-secondary "
                [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='left'}"
                (click)="canvas.getActiveObject().textAlign='left';canvas.renderAll()">
                <i class="ft-align-left font-medium-2"></i>
            </button>
            <button class="btn btn-sm bg-light-secondary"
                [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='center'}"
                (click)="canvas.getActiveObject().textAlign='center';canvas.renderAll()">
                <i class="ft-align-center font-medium-2"></i>
            </button>
            <button class="btn btn-sm bg-light-secondary"
                [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='right'}"
                (click)="canvas.getActiveObject().textAlign='right';canvas.renderAll()">
                <i class="ft-align-right font-medium-2"></i>
            </button>

            <button class="btn btn-sm bg-light-secondary"
                [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='justify'}"
                (click)="canvas.getActiveObject().textAlign='justify';canvas.renderAll()">
                <i class="ft-align-justify font-medium-2"></i>
            </button>

        </div>


        <div role="group" class="btn-group ml-1" *ngIf="canvas.getActiveObject().type=='textbox'">
            <div class="row">
                <div class="col-md-5">
                    <button class="change-me btn btn-sm bg-light-secondary"
                        [style.color]="canvas.getActiveObject().fill" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                        [cpPositionRelativeToArrow]="true" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpSaveClickOutside]="false"
                        [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                        [(colorPicker)]="selectedColor" (cpSliderChange)="changeColor($event)" (colorPickerChange)="presetColorsChange($event)" >
                        <i class="fa fa-font font-small-3" [style.color]="canvas.getActiveObject().fill"></i></button>
                </div>
            </div>


        </div>

        <div role="group" class="btn-group ml-1" *ngIf="canvas.getActiveObject().type=='textbox'">
            <button type="button" class="btn btn-sm bg-light-secondary" data-toggle="tooltip" data-placement="top"
                data-original-title="Make font bold"
                [ngClass]="{'btn-active':canvas.getActiveObject().fontWeight=='bold'}"
                (click)="canvas.getActiveObject().fontWeight=='bold'?canvas.getActiveObject().fontWeight='normal':canvas.getActiveObject().fontWeight='bold';canvas.renderAll()">
                <i class="ft-bold font-small-3"></i>
            </button>
            <button type="button" class="btn btn-sm bg-light-secondary"
                [ngClass]="{'btn-active':canvas.getActiveObject().fontStyle=='italic'}"
                (click)="canvas.getActiveObject().fontStyle=='italic'?canvas.getActiveObject().fontStyle='normal':canvas.getActiveObject().fontStyle='italic';canvas.renderAll()">
                <i class="ft-italic font-small-3"></i>
            </button>
            <button type="button" class="btn btn-sm bg-light-secondary"
                [ngClass]="{'btn-active':canvas.getActiveObject().underline==true}" (click)="elementUnderline()">
                <i class="ft-underline font-small-3"></i>
            </button>
        </div>




        <div class="d-flex input-group input-group-sm font-size-group ml-1"
            *ngIf="canvas.getActiveObject().type=='textbox'">
            <div class="input-group-prepend">
                <button class="btn btn-sm bg-light-secondary" type="button"
                    (click)="canvas.getActiveObject().fontSize>1?canvas.getActiveObject().fontSize=canvas.getActiveObject().fontSize-1:1;canvas.renderAll()"><i
                        class="ft ft-minus"></i></button>
            </div>
            <input type="text" width="30" class="form-control bg-light-secondary"
                [(ngModel)]="canvas.getActiveObject().fontSize" (change)="canvas.renderAll()" placeholder="Font size"
                aria-label="Recipient's username" aria-describedby="basic-addon2">
            <div class="input-group-append">
                <button class="btn btn-sm bg-light-secondary" type="button"
                    (click)="canvas.getActiveObject().fontSize=canvas.getActiveObject().fontSize+1;canvas.renderAll()">
                    <i class="ft ft-plus"></i></button>
            </div>
        </div>

        <div role="group" class="btn-group ml-1">
            <button type="button" class="btn btn-sm bg-light-secondary" data-toggle="tooltip" data-placement="top"
                data-original-title="Bring forward" (click)="canvas.getActiveObject().bringForward()">
                <img src="assets/img/catalogue/bring-forward.png" width="20" />
            </button>
            <button type="button" class="btn btn-sm bg-light-secondary"
                [ngClass]="{'btn-active':canvas.getActiveObject().underline==true}"
                (click)="canvas.getActiveObject().sendBackwards()">
                <img src="assets/img/catalogue/send-backward.png" width="20" />

            </button>
        </div>
        <div role="group" class="btn-group ml-1">
            <button type="button" class="btn btn-sm bg-light-secondary" data-toggle="tooltip" data-placement="top"
            data-original-title="Bring forward" (click)="canvas.remove(canvas.getActiveObject())">
            <i class="fa fa-trash-o font-small-3"></i>
        </button>
        </div>

    </div>
</div>

<!-- -{{canvasBgColor}} -->
<canvas [id]="'canvasid'+id" [ngClass]="{'active-canvas':active}"></canvas>
<!-- ->index start: {{productIndexStart}} -->