import { TenantCustomer, CustomerLocation } from './../../models/tenant-customer';
import { DistributorService } from './../../services/distributor.service';
import { DistributorCustomer } from './../../models/distributor';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GFilterParam, GFColumn, GFColumnTypes, GSortParam, DataFilterRequest } from 'src/app/core/models/grid-filter.models';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { Observable } from 'rxjs';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
  selector: 'distributor-customers',
  templateUrl: './distributor-customers.component.html',
  styleUrls: ['./distributor-customers.component.scss']
})
export class DistributorCustomersComponent implements OnInit, OnDestroy {

  @ViewChild('FormModal') modalContent: any;

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  public page = { count: 0, limit: 25, offset: 0, pageNumber: '0-0' };

  private _distributorId: number;

  @Input() set distributorId(val: number) {
    this._distributorId = val;
    if (val) {
      this.getData();
    }
  }

  get distributorId() { return this._distributorId; };

  public rows: DistributorCustomer[];

  public model: DistributorCustomer;

  public Permissions = PermissionConstants;

  public SelectionType = SelectionType;

  private modalRef: NgbModalRef;

  public searchFilters: GFilterParam[] = [
    {
      displayName: 'Combo', colname: '', condition: '', value: '',
      children: [
        { displayName: 'Customer Name', colname: 'tenant_customer_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Type Name', colname: 'customer_type_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Location Name', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' }
      ]
    },
  ];

  public columns: GFColumn[] = [
    { displayName: 'Customer', columnName: 'tenant_customer', columnType: GFColumnTypes.ref },
    { displayName: 'Customer Type', columnName: 'customer_type', columnType: GFColumnTypes.ref },
    //{ displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
    { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref }
  ];

  public sort: GSortParam[] = [{ colname: 'tenant_customer_name', direction: 'asc' }];

  public sortLookup = {
    tenant_customer_name: 'tenant_customer_name',
    customer_type: 'tenant_customer_type',
    location_name: 'location_name'
  };

  private subs: SubSink = new SubSink();

  constructor(private distributorService: DistributorService,
    private cdr: ChangeDetectorRef,
    private ra: ResourceAccessService,
    private modal: NgbModal,
    private toastr: ToastrService) { }

  ngOnInit() { }

  getData(filters: GFilterParam[] = [], offset: number = 0) {

    filters.unshift({ colname: 'distributor', condition: 'equalto', value: this.distributorId, operator: 'AND' });

    let request: DataFilterRequest = { filters: filters, sort: this.sort };

    this.subs.sink = this.distributorService.getDistributorCustomers(request, offset, this.page.limit).subscribe(resp => {
      this.rows = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: DistributorCustomer = selected[0];
    if (this.hasPermission(this.Permissions.MODIFY)) {
      this.model = { ...this.rows.find(item => item.id === current.id) };
      this.openModal();
    }
  }

  onNewButtonClick() {
    this.model = new DistributorCustomer();
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modal.open(this.modalContent, { scrollable: true, centered: true });
  }

  closeModal() { this.modalRef?.dismiss(); }

  onCustomerChange(customer: TenantCustomer) {
    this.model.tenant_customer_name = customer?.name;
    this.model.customer_type = customer?.customer_type_name;
  }

  onCustomerLocationChange(location: CustomerLocation) {
    this.model.location_name = location?.name;
  }

  submit() {
    let api: Observable<DistributorCustomer>;
    if (this.model.id > 0) {
      api = this.distributorService.updateDistributorCustomer(this.model.id, this.model);
    }
    else {
      this.model.customer = this.distributorService.customerId;
      this.model.distributor = this.distributorId;
      api = this.distributorService.createDistributorCustomer(this.model);
    }

    this.subs.sink = api.subscribe(
      resp => {

        let result = { ...this.model, ...resp };

        if (this.page.count < this.page.limit) {
          this.rows = [...this.rows.filter(item => item.id !== this.model.id), ...[result]];
          this.page.count = this.rows.length;
        }
        this.toastr.success(this.model.id > 0 ? '1 row updated' : 'New customer added');
        this.cdr.markForCheck();
        this.closeModal();
      },
      () => {
        this.toastr.success('Operation failed');
        this.closeModal();
      }
    );
  }

  deleteRow(id: number): void {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.distributorService.deleteDistributorCustomer(id).subscribe(
          () => {
            this.rows = this.rows.filter(x => x.id !== id);
            this.cdr.markForCheck();
            popup.CompleteDelete('Customer has been deleted.')
          },
          () => this.toastr.error('Unable to delete customer')
        );
      }
    });
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.DISTRIBUTORS, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.CUSTOMER_MODULE, [this.Permissions.GLOBAL]);
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    let params = this.dataFilter?.getDataFilters() || [];
    this.getData(params, offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
