import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { KitService } from './../../../services/kit.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { GFilterParam, GSortParam, GFColumn } from 'src/app/core/models/grid-filter.models';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { Kit, KitWrapper } from '../../../models/kit.models';
import { KitDataHelper } from '../../../helpers/kit-data.helper';
import { DeviceDetectorService } from 'ngx-device-detector';
import { KitAssembleMaterialRow } from '../../../models/kit-assemble.models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'kit-select',
  templateUrl: './kit-select.component.html',
  styleUrls: ['./kit-select.component.scss']
})
export class KitSelectComponent implements OnInit, OnChanges {

  @ViewChild('kitsModal') kitsModal: any;

  @Input() suborg_id: number;

  @Input() value: number;

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<KitWrapper>();

  @Input() selectBox: boolean = true;

  @Input() resetForCallcenter: boolean = false;

  public SelectionType = SelectionType;

  public fileUrl: string = environment.apiURL + '/static/files/';

  public kits: KitWrapper[];

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = KitDataHelper.DefaultFilters;

  public search: GFilterParam[] = KitDataHelper.SearchFilters;

  public sortLookup = KitDataHelper.SortLookup;

  public selectedItem: KitWrapper[] = [];

  public selected: KitWrapper;

  public sort: GSortParam[];

  public columns: GFColumn[] = KitDataHelper.FilterColumns;

  private subs: SubSink = new SubSink();

  private modalRef: NgbModalRef;

  public scrollbarH:boolean=false;

  row: KitAssembleMaterialRow;
    modalRefPopup: NgbModalRef;


  constructor(private modal: NgbModal, private kitService: KitService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService,
    private toastr:ToastrService) {
      this.scrollbarH = this.device.isMobile();

     }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue > 0) {
      this.setSelected(changes.value.currentValue);
    }else{
      if(this.resetForCallcenter){
      this.selected = null;
      }
    }
  }

  setSelected(id: number) {
    if (this.selected) return;
    this.subs.sink = this.kitService.getById(id).subscribe(kit => {
      this.selected = { ...kit };
      this.cdr.markForCheck();
    });
  }

  open(row?: KitAssembleMaterialRow) {
    this.modalRef = this.modal.open(this.kitsModal, { size: 'xl', scrollable: true });
    this.row = row;
    if (!this.kits || this.kits.length <= 0) {
      this.getData(null);
    }
  }

  private getData(filters: GFilterParam[], offset: number = 0) {
    let option = { sort: this.sort, filters: filters };
    this.subs.sink = this.kitService.getAllFiltered(option, offset, this.suborg_id).subscribe(resp => {
      this.kits = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSort(event: any, dataFilter: DataGridFilterComponent) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(dataFilter?.getDataFilters());
    }
  }

  setPage(pageInfo: any, dataFilter: DataGridFilterComponent) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(dataFilter?.getDataFilters(), offset);
  }

  onItemSelected() {
    if (this.selectedItem && this.selectedItem.length > 0) {
      let item = this.selectedItem[0];
      this.selected = item;
      this.valueChange?.emit(item.id);
      this.change?.emit(item);
    }
    this.modalRef?.dismiss();
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  onCanceled() {
    this.modalRef?.dismiss();
  }

  clearSelection(event: any) {
    event.stopPropagation();
    this.selected = null;
    this.valueChange?.emit(null);
    this.selectedItem = [];
  }

  openModal(content: any){
    this.modalRefPopup = this.modal.open(content, { centered: true, scrollable: true, size: "xl" });
  }

  onDataSuccess(result: { response: Kit, message: string }): void {
    this.toastr.success('Success', result?.message);
    this.modalRefPopup?.dismiss()
    this.selectedItem.push(result.response as KitWrapper)
    this.onItemSelected();
    }

    onDataError(error: any): void {
        console.error(error);
        this.toastr.error('Failed', 'Operation failed');
        this.modalRefPopup?.dismiss()
    }

    onCancel(): void {
        this.modalRefPopup?.dismiss()
    }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
