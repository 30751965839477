<section class="col-lg-11 col-md-12 col-sm-12">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div class="parellax-expanded card m-0">
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && onSaveClick()" novalidate>
                                <!-- first box -->
                        <div class="row mt-2 d-flex">
                            <div class="col-5">
                                <div class="d-flex first_box justify-content-around">
                                    <div [ngClass]="model.spam_settings[0].live_mode?'':'badge learning_mode_badge'"><b>Learning Mode</b></div>
                                    <div class="d-flex align-items-center">
                                        <div class="custom-switch custom-switch-info custom-control">
                                            <input type="checkbox" [(ngModel)]="model.spam_settings[0].live_mode" name="is_active" class="custom-control-input" id="mode_switch" checked>        
                                            <label class="custom-control-label" for="mode_switch">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div [ngClass]="model.spam_settings[0].live_mode?'badge live_mode_badge':''"><b>Live Mode</b></div>
                                </div>
                            </div>
                        </div>
                        <div class="verticalline"></div>
                        <!-- second box -->
                        <div class="row d-flex">
                            <div class="col-12">
                                <div class="second_box col-sm-12">
                                    <div><b>Spam Checking Conditions</b></div>
                                    <div class="form-group mt-1">
                                        <div class="d-flex align-items-center">
                                            <label for="Call_duration" class="mr-3">Call Duration in</label>
                                            <input type="number" class="form-control mr-1" name="Call_duration" [(ngModel)]="model.spam_settings[0].duration" min="1" required style="width: 7%; height: 6%;" PreventSpecialCharacters>
                                            <label for="call_duration">Seconds or less</label>
                                        </div>
                                    </div>
                                    <!-- conditions -->
                                    <div class="form-group ml-1">
                                        <div class="d-flex align-items-center justify-content-around">
                                            <div class="d-flex align-items-center mr-1">
                                                <label for="noof_Calls" class="mr-2">Number of calls</label>
                                                <input type="number" class="form-control" [(ngModel)]="conditions[0].no_of_calls" name="noof_Calls" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <label for="Time_duration" class="mr-2 ml-3">Within Time Duration</label>
                                                <input type="number" class="form-control" [(ngModel)]="conditions[0].duration" name="Time_duration" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <ng-select class="form-select ml-2" style="width: 95px;" name="duration"
                                                        [(ngModel)]="conditions[0].duration_type"
                                                        [clearable]="false"
                                                        [searchable]="false">
                                                    <ng-option *ngFor="let time of timeDurationSelect" [value]="time">{{time}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <label for="period" class="mr-2">Then Block For a Period of</label>
                                                <input type="number" class="form-control" [(ngModel)]="conditions[0].period" name="period" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <ng-select class="form-select ml-2" style="width: 95px;" name="timePeriod"
                                                        [(ngModel)]="conditions[0].period_type"
                                                        [clearable]="false"
                                                        [searchable]="false">
                                                    <ng-option value="Week">Week</ng-option>
                                                    <ng-option value="Month">Month</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group mt-3 ml-1">
                                        <div class="d-flex align-items-center justify-content-around">
                                            <div class="d-flex align-items-center mr-1">
                                                <label for="no_ofCalls" class="mr-2">Number of calls</label>
                                                <input type="number" [(ngModel)]="conditions[1].no_of_calls" class="form-control" name="no_ofCalls" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <label for="TimeDuration" class="mr-2 ml-3">Within Time Duration</label>
                                                <input type="number" [(ngModel)]="conditions[1].duration" class="form-control" name="TimeDuration" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <ng-select class="form-select ml-2" style="width: 95px;" name="timeDur"
                                                        [(ngModel)]="conditions[1].duration_type"
                                                        [clearable]="false"
                                                        [searchable]="false">
                                                    <ng-option *ngFor="let time of timeDurationSelect" [value]="time">{{time}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <label for="periodOf" class="mr-2">Then Block For a Period of</label>
                                                <input type="number" name="periodOf" [(ngModel)]="conditions[1].period" class="form-control" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <ng-select class="form-select ml-2" style="width: 95px;" name="timePeriod2"
                                                        [(ngModel)]="conditions[1].period_type"
                                                        [clearable]="false"
                                                        [searchable]="false">
                                                    <ng-option value="Week">Week</ng-option>
                                                    <ng-option value="Month">Month</ng-option>
                                                </ng-select>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="form-group mt-3 ml-1">
                                        <div class="d-flex align-items-center justify-content-around">
                                            <div class="d-flex align-items-center mr-1">
                                                <label for="no.of_calls" class="mr-2">Number of calls</label>
                                                <input type="number" [(ngModel)]="conditions[2].no_of_calls" name="no.of_calls" class="form-control" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <label for="durationofTime" class="mr-2 ml-3">Within Time Duration</label>
                                                <input type="number" [(ngModel)]="conditions[2].duration" name="durationofTime" class="form-control" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <ng-select class="form-select ml-2" style="width: 95px;" name="timeduration"
                                                        [(ngModel)]="conditions[2].duration_type"
                                                        [clearable]="false"
                                                        [searchable]="false">
                                                    <ng-option *ngFor="let time of timeDurationSelect" [value]="time">{{time}}</ng-option>
                                                </ng-select>
                                            </div>

                                            <div class="d-flex align-items-center">
                                                <label for="period2" class="mr-2">Then Block For a Period of</label>
                                                <input type="number" name="period2" [(ngModel)]="conditions[2].period" class="form-control" min="1" required style="width: 6rem" PreventSpecialCharacters>
                                                <ng-select class="form-select ml-2" style="width: 95px;" name="periodSelect"
                                                        [(ngModel)]="conditions[2].period_type"
                                                        [clearable]="false"
                                                        [searchable]="false">
                                                    <ng-option value="Week">Week</ng-option>
                                                    <ng-option value="Month">Month</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="verticalline"></div>
                        <!-- third box -->
                        <div class="row d-flex">
                            <div class="col-12">
                                <div class="third_box">
                                    <div><b>Block List Renewal Settings</b></div>
                                    <div class="form-group mt-1">
                                        <div class="d-flex align-items-center">
                                            <label for="Continue_in" class="mr-2">Continue in Block list if</label>
                                            <input type="number" class="form-control mr-2" name="Continue_in" [(ngModel)]="model.spam_settings[0].renewal_call_no" min="1" required style="width: 6%; height: 6%;" PreventSpecialCharacters>

                                            <label for="calls_in" class="mr-2">Calls in</label>
                                            <input type="number" class="form-control mr-2" name="calls_in" [(ngModel)]="model.spam_settings[0].renewal_days" min="1" required style="width: 6%;" PreventSpecialCharacters>
                                            <label class="mr-1">Days,</label>
                                            <label for="ContinuePeriod" class="mr-2">For a Period of</label>
                                            <input type="number" name="ContinuePeriod" [(ngModel)]="model.spam_settings[0].renewal_period" class="form-control" min="1" required style="width: 6%" PreventSpecialCharacters>
                                            <ng-select class="form-select ml-2" style="width: 95px;" name="continuePeriodSelect"
                                                    [(ngModel)]="model.spam_settings[0].renewal_period_type"
                                                    [clearable]="false"
                                                    [searchable]="false">
                                                <ng-option value="Week">Week</ng-option>
                                                <ng-option value="Month">Month</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-sm-row flex-column justify-content-end mt-4">
                            <button type="submit" [disabled]="!addForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">Save</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

