import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Manufacturer } from '../../../models/manufacturer';
import { ManufacturerService } from '../../../services/manufacturer.service';

@Component({
    selector: 'app-manufacturer-form',
    templateUrl: './manufacturer-form.component.html',
    styleUrls: ['./manufacturer-form.component.scss']
})
export class ManufacturerFormComponent implements OnInit, OnDestroy {

    @ViewChild('formElement') form: NgForm;

    public model: Manufacturer = new Manufacturer();

    @Input() manufacturerId: number = 0;

    @Output() onSuccess = new EventEmitter<{ response: Manufacturer, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    public nameExist: boolean = false;

    private getSub$: Subscription;

    private postSub$: Subscription;

    constructor(private manufactureService: ManufacturerService, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.manufacturerId > 0) {
            this.getSub$ = this.manufactureService.getById(this.manufacturerId).subscribe(man => {
                this.model = man;
                this.cdr.markForCheck();
            });
        }
    }

    onSubmit() {

        let api: Observable<Manufacturer>;

        if (this.manufacturerId > 0) {
            api = this.manufactureService.update(this.manufacturerId, this.model);
        }
        else {
            this.model.customer = this.manufactureService.customerId;
            this.model.suborg = this.manufactureService.suborgId;
            api = this.manufactureService.create(this.model);
        }

        this.postSub$ = api.subscribe(
            resp => {
                let msg = this.manufacturerId > 0 ? 'Manufacturer info updated' : 'New manufacturer added';
                this.nameExist = false;
                this.onSuccess?.emit({ response: resp, message: msg });
            },
            error => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.onError?.emit(error);
                }
            }
        );
    }

    onCancelClicked(): void { this.onCancel?.emit(); }

    ngOnDestroy(): void {
        this.getSub$?.unsubscribe();
        this.postSub$?.unsubscribe();
    }
}
