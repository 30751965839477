import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { QuickContactFormComponent } from './quick-contact-form/quick-contact-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TenantCustomersModule } from '../tenant-customers/tenant-customers.module';
import { CustomerModule } from '../customer/customer.module';



@NgModule({
  declarations: [
    CustomerInfoComponent,
    QuickContactFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    CustomerModule,
    TenantCustomersModule
  ],
  exports: [CustomerInfoComponent,
    QuickContactFormComponent]
})
export class CustomerInfoModule { }
