import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { KitAssemble, KitAssembleKitMaterial, KitAssembleMaterial, KitAssembleMaterialRow, KitAssembleWareHouse, KitAssembleWareHouseStock, KitAssembleWrapper } from '../models/kit-assemble.models';
import { KitItemsType } from '../models/kit.models';

@Injectable({
    providedIn: 'root'
})
export class KitAssembleService extends BaseService {

    constructor(private http: HttpClient) { super() }

    getAssembledKits(option: any, offset: number = 0, limit: number = 0) {
        let url = `${this.baseURL}/kitassemble/filter/?limit=${limit > 0 ? limit : this.dataLimit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<KitAssembleWrapper[]>>(url, option);
    }

    getAssembledKitById(id: number) {
        return this.http.get<KitAssembleWrapper>(`${this.baseURL}/kitassemble/${id}/`);
    }

    createAssembledKit(assembled: KitAssemble) {
        return this.http.post<KitAssemble>(`${this.baseURL}/kitassemble/`, assembled);
    }

    updateAssembledKit(id: number, assembled: KitAssemble) {
        return this.http.put<KitAssemble>(`${this.baseURL}/kitassemble/${id}/`, assembled);
    }

    patchAssembledKit(id: number, request: any) {
        return this.http.patch<KitAssemble>(`${this.baseURL}/kitassemble/${id}/`, request);
    }

    deleteAssembledKit(id: number) {
        return this.http.delete(`${this.baseURL}/kitassemble/${id}/`);
    }

    getAssembledWarehouse(assembleId: number) {
        return this.http.get<KitAssembleWareHouse[]>(`${this.baseURL}/kitassemblewarehouse/${assembleId}/items/`);
    }

    addAssembledWarehouse(request: KitAssembleWareHouse[]) {
        return this.http.post(`${this.baseURL}/kitassemblewarehouse/items/`, request);
    }

    updateAssembledWarehouse(request: KitAssembleWareHouse[]) {
        return this.http.put(`${this.baseURL}/kitassemblewarehouse/items/`, request);
    }

    deleteAssembledWarehouse(ids: number[]) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: ids
        };
        return this.http.delete(`${this.baseURL}/kitassemblewarehouse/items/`, options);
    }

    getAssembledKitItems(assembledId: number) {
        return this.http.get<KitAssembleMaterial[]>(`${this.baseURL}/kitassemble/${assembledId}/items/`).pipe(
            map(res => {
                let rows: KitAssembleMaterialRow[] = [];
                if (res && res.length > 0) {
                    for (let material of res) {
                        let row = new KitAssembleMaterialRow();
                        row.id = material.id;
                        row.item_id = material.items;
                        row.itemType = KitItemsType.ITEM;
                        row.primary_image = material.primary_image
                        row.customer = material.customer;
                        row.item_name = material.item_name;
                        row.part_number = material.part_number;
                        row.quantity = material.quantity || 1;
                        row.stock = material.available_stock || 0;
                        row.selected_stock = material.selected_stock;
                        row.status = material.status;
                        row.unit = material.unit || "";
                        rows.push(row);
                    }
                }
                return rows;
            })
        );
    }

    addAssembledKitItems(kitassemble_id: number, ki: KitAssembleMaterial[]) {
        return this.http.post(`${this.baseURL}/kitassemble/${kitassemble_id}/items/`, ki);
    }

    updateAssembledKitItems(kitassemble_id: number, ki: KitAssembleMaterial[]) {
        return this.http.put(`${this.baseURL}/kitassemble/${kitassemble_id}/items/`, ki);
    }

    deleteAssembledKitItems(kitassemble_id: number, id: number) {
        return this.http.delete(`${this.baseURL}/kitassemble/${kitassemble_id}/items/?id=${id}`);
    }

    getKitsInAssembledKit(assembledId: number) {
        return this.http.get<KitAssembleKitMaterial[]>(`${this.baseURL}/kitassemble/${assembledId}/kits/`).pipe(
            map(res => {
                let rows: KitAssembleMaterialRow[] = [];
                if (res && res.length > 0) {
                    for (let material of res) {
                        let row = new KitAssembleMaterialRow();
                        row.id = material.id;
                        row.item_id = material.kit;
                        row.selected_kitassemble = material.selected_kitassemble;
                        row.itemType = KitItemsType.KIT;
                        row.customer = material.customer;
                        row.item_name = material.kit_name;
                        row.part_number = material.internal_number;
                        row.primary_image = material.primary_image;
                        row.quantity = material.quantity || 1;
                        row.stock = material.available_stock || 0;
                        row.selected_stock = material.selected_stock;
                        row.status = material.status;
                        row.unit = material.unit || "";
                        rows.push(row);
                    }
                }
                return rows;
            })
        );
    }

    addKitsInAssembledKit(kitassemble_id: number, ki: KitAssembleKitMaterial[]) {
        return this.http.post(`${this.baseURL}/kitassemble/${kitassemble_id}/kits/`, ki);
    }

    updateKitsInAssembledKit(kitassemble_id: number, ki: KitAssembleKitMaterial[]) {
        return this.http.put(`${this.baseURL}/kitassemble/${kitassemble_id}/kits/`, ki);
    }

    deleteKitsInAssembledKit(kitassemble_id: number, id: number) {
        return this.http.delete(`${this.baseURL}/kitassemble/${kitassemble_id}/kits/?id=${id}`);
    }

    createKitAssembleWareHouseStock(itemwarehousestock: KitAssembleWareHouseStock[],kitasseble: number) {
        return this.http.post<KitAssembleWareHouseStock[]>(`${this.baseURL}/kitassemble/${kitasseble}/warehouse/stock/`, itemwarehousestock);
    }

    updateKitAssembleWareHouseStock(itemwarehousestock: KitAssembleWareHouseStock[],kitasseble: number) {
        return this.http.put<KitAssembleWareHouseStock[]>(`${this.baseURL}/kitassemble/${kitasseble}/warehouse/stock/`, itemwarehousestock);
    }

    deleteKitAssembleWareHouseStock(id: number,kitasseble: number) {
        return this.http.delete(`${this.baseURL}/kitassemble/${kitasseble}/warehouse/stock/?id=${id}`);
    }

    getKitAssembledWarehousestock(kitasseble: number) {
        return this.http.get<KitAssembleWareHouseStock[]>(`${this.baseURL}/kitassemble/${kitasseble}/warehouse/stock/`);
    }

    reverseAllocation(assembleid: number, quantity: number){
        return this.http.get(`${this.baseURL}/kitassemble/${assembleid}/reverse_allocation/${quantity}/`);

    }
}
