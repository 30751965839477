import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { DataFilterRequest } from 'src/app/core/models/grid-filter.models';
import { WorkingTimeResponse } from '../../models/time-frame.model';
import { TimeFrameService } from '../../services/time-frame.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'time-frame-select',
  templateUrl: './time-frame-select.component.html',
  styleUrls: ['./time-frame-select.component.scss']
})
export class TimeFrameSelectComponent implements OnInit {

  @ViewChild('content') modalContent: ElementRef;

  private innerValue: number;

  public options: Observable<WorkingTimeResponse[]>;

  @Input() readonly: boolean = false;

  public textInput = new Subject<string>();

  public totalCount: number = 0;

  public currentCount: number = 0;

  @Input() addButton: boolean = false;

  private modalRef:NgbModalRef;

  @Input() set value(val: number) {

    this.innerValue = val;

    if (val !== this.selected) {
        this.innerSelected = val;
    }
  }

  get value() { return this.innerValue; }

  @Output() valueChange = new EventEmitter<number>();

  private suborgid: number;

  get suborgId() { return this.suborgid; }

  @Input() set suborgId(val: number) {

      this.suborgid = val;

      this.innerSelected = undefined;

      if (this.options) {
          this.initialize();
      }
  }

  private innerSelected: number;

  public get selected(): number { return this.innerSelected; }

  public set selected(value: number) {
      this.innerSelected = value;
      this.valueChange?.emit(value);
  }

  constructor(private service: TimeFrameService,
    private modalService: NgbModal,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.initialize();
  }

  getData(search?: string) {

    let option: DataFilterRequest = {
        sort: [{ colname: 'name', direction: 'asc' }],
        filters: [],
        base_filters: [],
        parent_filters: []
    };
    
    let api = this.service.getTimeFrames(option);
    return api.pipe(
        catchError(err => of({ count: 0, result: [] })),
        map(resp => {
            this.totalCount = resp.count;
            this.currentCount = resp.result.length;
            return resp.result;
        })
    );
  }

  initialize(selectedValue: Observable<any> = of([])) {
    this.options = concat(
        selectedValue,
        this.getData(),
        this.textInput.pipe(
            filter(res => res !== null && res.length >= 2),
            distinctUntilChanged(),
            switchMap(term => this.getData(term))
        )
    );
  }

  openModal(content: any): void {
  this.modalRef = this.modalService.open(content, { size: 'xl', scrollable:true });
  }

  selectOpen() {
    // if (!this.options && this.firstOpen) {
        this.initialize();
        // this.firstOpen = false;
    // }
  }

  onClear() {
  this.initialize();
  }

  onDataSubmittedSuccess(result: { response: WorkingTimeResponse, message: string }): void {
    this.toastr.success(result.message);
    let obj = { ...new WorkingTimeResponse(), ...result.response };
    this.options = concat(this.options, of([obj]));
    this.selected = obj.id;
    this.modalRef?.dismiss();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalRef?.dismiss();
  }

  onCancel(): void {
    this.modalRef?.dismiss();
  }

  trackByFn(item: WorkingTimeResponse) { return item.id; }

}
