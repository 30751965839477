<div class="kit-item-container mt-2">
    <table class="kit-items-table">
        <thead>
            <tr>
                <th width="100" class="header-label">Image</th>
                <th class="header-label" [width]="viewMode ? '35%' : '45%'">Name</th>
                <th class="header-label">UOM</th>
                <th class="header-label">Qty <br> Req Per <br> Assembly</th>
                <th class="header-label">Total <br> Quantity</th>
                <th class="header-label">Available <br> Quantity</th>
                <th class="header-label">Allocation <br> Status</th>
                <th *ngIf="(!viewMode || isAssemblyExist)  && !isAllSuborg" class="header-label">Action</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let row of rows;let i = index">
                <tr>
                    <td class="text-center">
                        <img *ngIf="row.primary_image" width="80" height="80" [src]="fileUrl + row.primary_image + '.jpg'" />
                        <img *ngIf="!row.primary_image" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                        <div class="itemtype" *ngIf="row.itemType == KitIemTypes.ITEM"> Item </div>
                        <div class="assemblytype" *ngIf="row.itemType == KitIemTypes.KIT"> Assembly
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-row align-items-center w-100 h-100">
                            <div class="item-info flex-1">
                                <div class="item_name">{{row.item_name || 'Click to select an Item'}}</div>
                                <div class="item-partnumber">{{row.part_number}}</div>
                            </div>
                        </div>
                    </td>
                    <td>{{row.unit}}</td>
                    <td>
                        <input type="number" min="0" [(ngModel)]="row.quantity" [name]="'quan'+ i"
                            class="selection-grid-input" [disabled]="viewMode" />
                    </td>
                    <td>
                        <div class="h-100 d-flex flex-column">
                            <div class="text-right" style="font-size: 10px;color:#7b7b7b">{{bundleCount}} x
                                {{row.quantity}}
                            </div>
                            <div class="text-right" style="font-weight: 500;">{{bundleCount * row.quantity}}</div>
                        </div>
                    </td>
                    <td class="text-center">{{row.stock}}</td>
                    <td>
                        <div ngbTooltip="ALLOCATED">
                            <i *ngIf="row.status == 'ALLOCATED'" class="icon-check success"></i>
                        </div>
                        <div ngbTooltip="PARTIALY_ALLOCATED">
                            <i *ngIf="row.status == 'PARTIALY_ALLOCATED'" class="icon-clock warning"></i>
                        </div>
                        <div ngbTooltip="UN_ALLOCATED">
                            <i *ngIf="row.status == 'UN_ALLOCATED'" class="icon-close danger"></i>
                        </div>
                    </td>
                    <td *ngIf="(!viewMode || isAssemblyExist)  && !isAllSuborg">
                        <div class="d-flex flex-row justify-content-center">
                            <div  *ngIf="!viewMode" ngbDropdown container="body" [class.pointer-disabled]="row.expanded">
                                <button type="button" class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                    (click)="$event.stopPropagation()" ngbDropdownToggle>
                                    <i class="ft-more-vertical text-primary"></i>
                                </button>
                                <div ngbDropdownMenu>
                                    <a [disabled]="row.stock == 0" class="w-100"
                                        (click)="onAllocateInventory(row.itemType, row, i)"
                                        ngbDropdownItem>
                                        <i class="fa fa-stack-exchange mr-1"></i> Allocate Inventory
                                    </a>
                                    <a class="w-100" (click)="toggleSelect(row.itemType, row, i)" ngbDropdownItem>
                                        <i class="ft-delete mr-1"></i> Replace
                                    </a>
                                    <a class="w-100 bg-light-danger" (click)="deleteRow(row)" ngbDropdownItem>
                                        <i class="ft-trash-2 mr-1"></i> Delete
                                    </a>
                                </div>
                            </div>
                            <a type="button" class="toggle-button text-info" placement="left"
                                ngbTooltip="Expand Assembly" *ngIf="row.item_id && row.itemType === KitIemTypes.KIT"
                                (click)="expandKit(row, 0)">
                                <i *ngIf="!row.expanded" class="ft-chevron-right"></i>
                                <i *ngIf="row.expanded" class="ft-chevron-down"></i>
                            </a>
                            <!-- <div *ngIf="row.itemType === KitIemTypes.KIT && (!row.id || row.id <= 0)"
                                 class="kit-expand text-center">
                                <p class="kit-expand-text">Use Kit or Individual Item</p>
                                <div class="custom-switch custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="row.expanded" name="active"
                                           class="custom-control-input" id="status" (change)="expandKit(row)">
                                    <label class="custom-control-label" for="status">
                                        <span></span>
                                    </label>
                                </div>
                            </div> -->
                        </div>
                    </td>
                </tr>
                <ng-container *ngIf="row.selected_stock && row.selected_stock.length">
                    <!-- <table class="kit-items-innertable">
                    <thead> -->
                    <tr class="kit-items-innerrow">
                        <td></td>
                        <td>
                            <table>
                                <thead>
                                    <tr>
                                        <th>WareHouse</th>
                                        <th>Location</th>
                                        <th>Selected Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                    *ngFor="let innerRow of row.selected_stock;let k = index" >
                                        <tr class="kit-items-innerrow"
                                            *ngIf="innerRow.selected_quantity > 0" >

                                            <td>{{innerRow.warehouse_name}}</td>
                                            <td>{{innerRow.location_name}}</td>
                                            <td class="text-center">{{innerRow.selected_quantity || 0}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td *ngIf="(!viewMode || isAssemblyExist)  && !isAllSuborg"></td>
                    </tr>
                    <!-- </thead> -->
                    <!-- <tbody> -->

                    <!-- </tbody>
                  </table> -->
                </ng-container>
                <ng-container *ngTemplateOutlet="templateChildren; context:{row: row}">
                </ng-container>
            </ng-container>
            <tr *ngIf="!rows || rows.length <= 0">
                <td colspan="2">No items selected.</td>
            </tr>
            <tr class="summary-tr" *ngIf="rows && rows.length > 0">
                <td colspan="2">
                    <span *ngIf="rows && rows.length > 0">
                        Total <b>{{rows.length}}</b> {{rows.length == 1 ? 'item' : 'items'}}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="d-flex flex-row mt-2" *ngIf="!viewMode && !isAllSuborg">
    <div class="link-section">
        <a (click)="appendRow(KitIemTypes.ITEM)" class="text-info"><i class="ft-plus"></i> Add Item</a>
    </div>
    <div class="link-section ml-2">
        <a (click)="appendRow(KitIemTypes.KIT)" class="text-info"><i class="ft-plus"></i> Add Built Assembly</a>
    </div>
</div>

<app-item-warehouse-select [selectBox]="false" (change)="onItemChange($event)"
    (select)="onSelectItemWarehouseStock($event)">
</app-item-warehouse-select>

<app-build-assemble-select [selectBox]="false" (change)="onBuildAssembleChange($event)"
    (select)="onSelectBuildAssembleWarehouseStock($event)">
</app-build-assemble-select>


<ng-template #templateChildren let-row="row">
    <ng-container *ngIf="row.children && row.expanded" >
        <ng-container *ngFor="let innerRow of row.children | orderBy:'item_name';let k = index" >
            <tr class="kit-item-childrentr" [style]="'background-color:' + innerRow.color">
                <td class="text-center">
                    <img *ngIf="innerRow.primary_image" width="80" height="80"
                        [src]="fileUrl + innerRow.primary_image + '.jpg'" />
                    <img *ngIf="!innerRow.primary_image" width="30" height="30"
                        src="/assets/img/svg/product-avatar.svg" />
                    <div class="itemtype" *ngIf="innerRow.itemType == KitIemTypes.ITEM"> Item
                    </div>
                    <div class="assemblytype" *ngIf="innerRow.itemType == KitIemTypes.KIT">
                        Assembly</div>
                </td>
                <td>
                    <div class="item-info flex-1">
                        <div class="item_name">{{innerRow.item_name}}</div>
                        <div class="item-partnumber">{{innerRow.part_number}}</div>
                        <div *ngIf="row.part_number" class="mt-1 badge bg-info">Item from {{row.part_number}}
                        </div>
                    </div>
                </td>
                <td>{{innerRow.unit}}</td>
                <td>{{innerRow.quantity}}</td>
                <td>
                    <div class="h-100 d-flex flex-column">
                        <div class="text-right" style="font-size: 10px;color:#7b7b7b">{{bundleCount}} x
                            {{innerRow.quantity}}
                        </div>
                        <div class="text-right" style="font-weight: 500;">{{bundleCount * innerRow.quantity}}
                        </div>
                    </div>
                </td>
                <td>{{innerRow.stock || 0}}</td>
                <td>
                    <div ngbTooltip="ALLOCATED">
                        <i *ngIf="innerRow.status == 'ALLOCATED'" class="icon-check success"></i>
                    </div>
                    <div ngbTooltip="PARTIALY_ALLOCATED">
                        <i *ngIf="innerRow.status == 'PARTIALY_ALLOCATED'" class="icon-clock warning"></i>
                    </div>
                    <div ngbTooltip="UN_ALLOCATED">
                        <i *ngIf="innerRow.status == 'UN_ALLOCATED'" class="icon-close danger"></i>
                    </div>
                </td>
                <td class="align-middle" *ngIf="(!viewMode || isAssemblyExist)  && !isAllSuborg">
                    <a type="button" class="toggle-button text-info" placement="left"
                                    ngbTooltip="Expand Assembly" *ngIf="innerRow.item_id && innerRow.itemType === KitIemTypes.KIT"
                                    (click)="expandKit(innerRow)">
                                    <i *ngIf="!innerRow.expanded" class="ft-chevron-right"></i>
                                    <i *ngIf="innerRow.expanded" class="ft-chevron-down"></i>
                                </a>
                </td>
            </tr>
            <ng-container *ngTemplateOutlet="templateChildren; context:{row: innerRow}">
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="(!row.children || row.children.length <= 0) && row.expanded">
        <tr class="kit-item-childrentr">
            <td colspan="8">No items in the Assembly</td>
        </tr>
    </ng-container>
</ng-template>

