import { SubSink } from 'subsink';
import { SystemRoles } from './../../../../core/enums/user-types';
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { RoleService } from '../../services/role.service';
import { RoleWrapper } from '../../models/role';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-role-list',
    templateUrl: './role-list.component.html',
    styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit, OnDestroy {

    @ViewChild(DatatableComponent) table: DatatableComponent;

    public ColumnMode = ColumnMode;

    public SelectionType = SelectionType;

    public isSuperUser: boolean;

    public rows: RoleWrapper[];

    private rowsBackup: RoleWrapper[];

    public customerId: number;

    public defaultSort: any[];

    public scrollbarH: boolean = false;

    private sub: SubSink = new SubSink();

    constructor(private roleService: RoleService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private toast: ToastrService,
        private device: DeviceDetectorService) {

        this.scrollbarH = this.device.isMobile();
        this.isSuperUser = roleService.isSuperUser;
        this.customerId = roleService.customerId;
        this.defaultSort = this.isSuperUser ? [{ prop: 'custname', dir: 'asc' }] : [{ prop: 'role.name', dir: 'asc' }];
    }

    ngOnInit(): void {
        this.getData();
    }

    getData(): void {
        this.sub.sink = this.roleService.getAll().subscribe(roles => {
            this.rows = roles.filter(role => role.role.name !== SystemRoles.SuperAdmin);
            this.rowsBackup = this.rows;
            this.cdr.markForCheck();
        });
    }

    searchRoles(event: any) {
        const val = event.target.value?.toLowerCase();
        this.rows = this.rowsBackup.filter(function (d) {
            return d.role.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.table.offset = 0;
    }

    onSelect({ selected }) {
        let roleWrapper: RoleWrapper = selected[0];
        if (roleWrapper) {
            this.router.navigate(['/roles', roleWrapper.role.id, 'view']);
        }
    }

    deleteRole(id: number): void {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.sub.sink = this.roleService.delete(id).subscribe(
                    () => {
                        this.rows = this.rows?.filter(item => item.role.id !== id);
                        this.rowsBackup = this.rows;
                        popup.CompleteDelete('Role has been deleted.');
                    },
                    () => this.toast.error('Failed to delete Role')
                );
            }
        });
    }

    cloneRole(id: number): void {
        this.sub.sink = this.roleService.clone(id).subscribe(
            cloned => {
                this.toast.success('Role cloned');
                this.router.navigate(['/roles', cloned.role.id, 'view']);
            },
            () => this.toast.error('Failed to clone Role')
        );
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }
}
