import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CallReasonService } from 'src/app/modules/service-ticket/services/call-reason.service';
import { SubSink } from 'subsink';
import { imList, rfsList } from '../../../models/important-messages.models';
import { ImportantMessagesService } from '../../../services/important-messages.service';

@Component({
  selector: 'important-messages-form',
  templateUrl: './important-messages-form.component.html',
  styleUrls: ['./important-messages-form.component.scss']
})
export class ImportantMessagesFormComponent implements OnInit {

  @ViewChild('name', { static: false, read: ElementRef }) nameControl: ElementRef;

  public model: imList = new imList();

  @Input() bindingModel: imList;

  @Input() uniqueId: number = 0;

  @Input() tenentCustomerId:number;

  @Output() success = new EventEmitter<{ response: imList, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private subs: SubSink = new SubSink();

  public rfsList;

  constructor(private service: ImportantMessagesService, 
    private cdr: ChangeDetectorRef,
    private callReasonService: CallReasonService,) { }

  ngAfterViewInit(): void { this.nameControl?.nativeElement.focus(); }

  ngOnInit() {

    this.getReasonForService();

    if (this.uniqueId > 0) {

      if (this.bindingModel) {
        this.model = { ... this.bindingModel };
      }
      else {
        this.subs.add(this.service.getById(this.uniqueId).subscribe(resp => {
          this.model = resp;
          this.cdr.markForCheck();
        }));
      }
    }
  }

  getReasonForService(){

      this.subs.sink = this.callReasonService.getFiltered(null, 0).pipe(
        catchError(err => of({ count: 0, result: [] }))
      ).subscribe(response => {
        this.rfsList = response.result;
        // this.setPagination(offset, response.count);
        this.cdr.markForCheck();
      });
    
    
  }

  onSubmit(): void {

    let api: Observable<imList>;

    this.model.tenant_customer=this.tenentCustomerId;

    if (this.uniqueId > 0)
      api = this.service.update(this.uniqueId, this.model);
    else {
      this.model.customer = this.service.customerId;
      api = this.service.create(this.model);
    }

    this.subs.sink = api.subscribe(
      response => {
        let msg = this.uniqueId > 0 ? 'Important message updated' : 'New imoprtant message created';
        this.success?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  rfsChange(rfs:rfsList){
    console.log(rfs);

    this.model.name=rfs.name;
    this.model.message=rfs.message;
    this.model.reason_for_service=rfs.id;
    this.model.required_confirmation=rfs.required_confirmation;
    
  }

  onCancelClicked(): void {
    this.cancel?.emit();
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

}
