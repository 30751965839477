import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TermsService } from '../../services/terms.service';
import { addTerms, TermsCatResponse } from '../../models/terms.models';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Location } from '@angular/common';
import { UIPermission } from 'src/app/core/models/common.models';

@Component({
  selector: 'app-terms-add',
  templateUrl: './terms-add.component.html',
  styleUrls: ['./terms-add.component.scss']
})
export class TermsAddComponent implements OnInit {


  public userAuth=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));

  public terms:addTerms=new addTerms();

  public subs: SubSink = new SubSink();

  public termsId:number=0;

  public api:any;

  public UIPermissions:UIPermission;

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;
  
  constructor(private TermsService:TermsService, 
    private toaster:ToastrService, 
    public location: Location, 
    private router:Router, 
    private cdr: ChangeDetectorRef, 
    private activeRoute: ActivatedRoute,
    private ra: ResourceAccessService ) { 
    this.subs.sink = activeRoute.params.subscribe(params => this.termsId = params.id || 0);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_TERMS, ResourceConstants.SALES_MODULE);


  }

  ngOnInit(): void {
    
  }

  onDataSubmittedSuccess(result: { response: TermsCatResponse, message: string }): void {
    this.router.navigate(['/sales/terms-conditions/'+result.response.id+'/view']);
    this.toaster.success(result.message);
  
  }

  onDataError(_error: any): void {
    this.toaster.error('Operation failed');
  }

  onCancel(): void { 
    this.location.back();
   }





  hasPermission(permission: string) {
    return this.ra.hasPermission(this.Resource.SALES_TERMS, [permission, this.Permissions.GLOBAL]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


}
