import { Observable } from 'rxjs';
import { CommonService } from './../../../core/services/common.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { DataHistoryGroup } from 'src/app/core/models/common.models';
import { groupBy, map, mergeMap, toArray } from 'rxjs/operators';

@Component({
    selector: 'history-view',
    templateUrl: './history-view.component.html',
    styleUrls: ['./history-view.component.scss']
})
export class HistoryViewComponent implements OnInit {

    @Input() module: any;

    private innerUniqueId:number;

    @Input() set uniqueId (val:number) {

        if (val && this.innerUniqueId !== val) {
            this.innerUniqueId = val;
            this.getHistory();
        }
    };

    @Input() nongeneric:boolean=false;

    get uniqueId() { return this.innerUniqueId; }

    public history: Observable<DataHistoryGroup[]>;

    constructor(private commonService: CommonService) { }

    ngOnInit() { }

    getHistory(offset: number = 0) {

        let body = {
            sort: [{ colname: 'created_on', direction: 'desc' }]
        }

        let api;

        if(this.nongeneric){
            
            this.history = this.commonService.getHistoryNonGeneric(this.module, this.uniqueId, offset, body).pipe(
                mergeMap(result => result.result),
                groupBy(item => item.created_on.split('T')[0]),
                mergeMap(obs => {
                    return obs.pipe(
                        toArray(),
                        map(items => { return { date: obs.key, transaction: items }; })
                    )
                }),
                toArray()
            );
        }
        else{

            this.history = this.commonService.getHistoryV2(this.module, this.uniqueId, offset, body).pipe(
                mergeMap(result => result.result),
                groupBy(item => item.created_on.split('T')[0]),
                mergeMap(obs => {
                    return obs.pipe(
                        toArray(),
                        map(items => { return { date: obs.key, transaction: items }; })
                    )
                }),
                toArray()
            );

        }


    }
}
