<section class="add-tenant-customer" *ngIf="model">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">{{distributorId > 0 ? 'Edit Distributor' : 'New Distributor'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && onSubmit()" novalidate>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label class="val-required">Name</label>
                                <input type="text" [(ngModel)]="model.name" #custname="ngModel" name="name" class="form-control" required>
                                <small class="form-text text-muted danger" *ngIf="custname.invalid && (custname.dirty || custname.touched)">
                                    <div *ngIf="custname.errors.required">
                                        Name is required.
                                    </div>
                                </small>
                                <small class="danger mt-1" *ngIf="nameExist">Name must be unique</small>
                            </div>
                            <div class="form-group">
                                <label>Account Number</label>
                                <input type="text" [(ngModel)]="model.account_number" name="account_number" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Distributor Type</label>
                                <customer-type-select [(value)]="model.customer_type"></customer-type-select>
                            </div>
                            <div class="form-group">
                                <label>Distributor System Type</label>
                                <customer-system-type-select [fetchWhenOpen]="true" [(value)]="model.customer_system_type"></customer-system-type-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 d-flex flex-column">
                            <div class="customer-logo w-100">
                                <app-file-uploader [isActive]="false" [limit]="5" [multiple]="false"></app-file-uploader>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>License Number</label>
                                <license-number-select [multiple]="true" [(value)]="licenseNumbers" [fetchWhenOpen]="false" [clearable]="false" (remove)="onLicenseNumberRemove($event)"></license-number-select>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [(ngModel)]="model.is_service_ticket_billable" name="stbillable" id="custom-check-1">
                                    <label class="custom-control-label" for="custom-check-1">
                                        <span>Service Ticket Billable</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>Industry</label>
                                <industry-select [(value)]="model.industry"></industry-select>
                            </div>
                            <div class="form-group">
                                <label>Website</label>
                                <input type="text" [(ngModel)]="model.website" name="website" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row-seperator"></div>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>Currency</label>
                                <currency-select [(value)]="model.currency"></currency-select>
                            </div>
                            <div class="form-group">
                                <label>Payment Terms</label>
                                <payment-term-select [(value)]="model.payment_terms"></payment-term-select>
                            </div>
                            <div class="form-group">
                                <label>Comment</label>
                                <textarea class="form-control" [(ngModel)]="model.comments" name="comment"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <label>Tax</label>
                                <tax-select [(value)]="model.tax"></tax-select>
                            </div>
                            <div class="form-group">
                                <label>Account Rep</label>
                                <user-select [(value)]="model.account_rep" [multiple]="true"></user-select>
                            </div>
                        </div>
                    </div>
                    <h5 class="mt-3 section-title">
                        Additional Fields
                        <button type="button" (click)="openCustomFieldModal(content)" class="btn btn-sm btn-primary ml-4">Add Field</button>
                    </h5>
                    <div class="row mt-3">
                        <div class="form-group col-md-5" *ngFor="let customField of additionalFields">
                            <label>{{customField.name}}</label>
                            <div class="d-flex flex-row">
                                <input [(ngModel)]="customField.value" [name]="customField.id" class="form-control" type="text" />
                                <button (click)="deleteCustomField(customField.id)" class="btn af-delete-btn" type="button">
                                    <i class="ft-trash-2"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-10 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                {{distributorId > 0 ? 'Save Changes' : 'Save'}}
                            </button>
                            <button type="button" (click)="location.back()" class="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Custom Field</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Key</label>
                <input type="text" [(ngModel)]="customFieldInputs.key" name="key" class="form-control">
            </div>
            <div class="form-group">
                <label>Value</label>
                <input type="text" [(ngModel)]="customFieldInputs.value" name="value" class="form-control">
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="customFieldInputs.key && addCustomField()" class="btn gradient-pomegranate">Save</button>
            <button type="button" (click)="modal.close('Save click')" class="btn btn-secondary">Cancel</button>
        </div>
    </ng-template>
</section>
