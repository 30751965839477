 import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnDestroy {

    public tabName: string;

    private $sub: Subscription;

    constructor(route: ActivatedRoute, private location: Location) {
        this.$sub = route.params.subscribe(params => {
            this.tabName = params.tabname || 'calls'
        });
    }

    onNavChange(changeEvent: NgbNavChangeEvent) {
      this.location.go(`/ring2voice/reports/${changeEvent.nextId}`)
    }

    ngOnDestroy(): void {
        this.$sub?.unsubscribe();
    }
}
