import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, Sanitizer } from '@angular/core';
import { CatalogueService } from 'src/app/modules/inventory/services/catalogue-service.service';
import { SubSink } from 'subsink';
import { CatalogueCatagoryResponse, CatalogueProductListResponse, SubNavSettings } from '../../models/catalogue.models';
import { DomSanitizer } from '@angular/platform-browser'
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { FilesService } from 'src/app/core/services/files.service';
import { ThumbNail } from 'src/app/core/models/files.models';
import * as popup from 'src/app/core/utils/popup.functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SweetAlertOptions } from 'sweetalert2';


const MAX_SIZE: number = 1048576;

@Component({
  selector: 'catalogue-subnav',
  templateUrl: './catalogue-subnav.component.html',
  styleUrls: ['./catalogue-subnav.component.scss']
})
export class CatalogueSubnavComponent implements OnInit {

  @Output() sideNavToggled = new EventEmitter<boolean>();

  menuStatus: boolean = false;

  @Input() sideNavStatus: boolean = false;

  @Input() settings: SubNavSettings = new SubNavSettings();

  @Input() isButton: boolean = false;

  selectedMenu: any = '';

  @Output() addElement = new EventEmitter<any>();

  @Output() addElementGroup = new EventEmitter<any>();

  @Output() emitPageToCanvas = new EventEmitter<any>();

  @Output() addBigText = new EventEmitter<any>();

  @Output() addCategory = new EventEmitter<any>();

  @Output() imageToCanvas = new EventEmitter<any>();

  @Output() figureToCanvas = new EventEmitter<any>();

  @Output() dragItem = new EventEmitter<any>();

  @Input() _canvasModified: boolean = false;



  public productTemplates: CatalogueProductListResponse[] = [];

  public pageTemplates: CatalogueProductListResponse[] = [];

  public buttonTemplates: CatalogueProductListResponse[] = [];

  public subs = new SubSink();

  public catalogCatagories: CatalogueCatagoryResponse[] = [];


  public items = [

    { id: 1, title: 'Item Name', type: "text", name: "item_name" },
    { id: 2, title: 'QR Code', type: "img", name: "qr_code" },
    { id: 4, title: 'Warehouse', type: "text", name: "werehouse" },
    { id: 5, title: 'Warehouse location', type: "text", name: "werehouse_location" },
    { id: 6, title: 'Serial Number', type: "text", name: "serial_number" },
    { id: 7, title: 'SKU', type: "text", name: "sku" },
    { id: 8, title: 'Item Type', type: "text", name: "item_type" },
    { id: 9, title: 'Item Category', type: "text", name: "category" },
    { id: 10, title: 'Manufacturer', type: "text", name: "manufacturer" },
    { id: 11, title: 'Part number', type: "text", name: "part_number" },
    { id: 12, title: 'Sell Price', type: "text", name: "sell_price" },
    { id: 13, title: 'Supplier', type: "text", name: "supplier" },
    { id: 14, title: 'Product Image', type: "img", name: "item_image" },
  ];

  public images = [
    { image: "../../../../../assets/img/catalogue/design/catimg-1.jpeg", class: "vertical" },
    { image: "../../../../../assets/img/catalogue/design/catimg-2.jpeg", class: "vertical" },
    { image: "../../../../../assets/img/catalogue/design/catimg-3.jpeg", class: "horizontal" },
    { image: "../../../../../assets/img/catalogue/design/catimg-4.jpg", class: "" },
    { image: "../../../../../assets/img/catalogue/design/catimg-5.jpg", class: "big" },
    { image: "../../../../../assets/img/catalogue/design/catimg-6.jpeg", class: "" },
    { image: "../../../../../assets/img/catalogue/design/catimg-7.jpg", class: "vertical" },
    { image: "../../../../../assets/img/catalogue/design/catimg-8.jpg", class: "" },
    { image: "../../../../../assets/img/catalogue/design/catimg-9.jpg", class: " " },
    { image: "../../../../../assets/img/catalogue/design/catimg-10.jpg", class: "" },

  ];

  public figures = [

    { name: 'Rectangle', type: 'rectangle' },
    { name: 'Square', type: 'square' },
    { name: 'Triangle', type: 'triangle' },
    { name: 'Circle', type: 'circle' }

  ]

  goTo(paramText: string) {
    if (paramText != this.selectedMenu && this.menuStatus == true) {
      this.selectedMenu = paramText

    }
    else {
      this.SideNavToggled();
      this.selectedMenu = paramText

    }
  }

  imageList: any = []
  imgURL: string = environment.imgURL//"http://54.166.108.170:4000/workhorse/api/static/files/"
  isUploadingImg: boolean = false
  albums = [];

  showFlag1: boolean = false; //for stock image preview
  showFlag2: boolean = false; //for dynamic image preview

  selectedImageIndex: number = -1;

  stockImgPreviewList: any = [];
  dynamicImgPreviewList: any = [];



  constructor(private catalogueService: CatalogueService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private fileService: FilesService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    private sanitizer: DomSanitizer

  ) { }

  ngOnInit(): void {
    this.getProductTemplates();
    this.getCategories();
    this.getImageList();
  }

  SideNavToggled() {
    this.menuStatus = !this.menuStatus;
    this.sideNavToggled.emit(this.menuStatus);
  }

  addNewElement(item) {
    console.log('item',item)
    this.addElement.emit(item);
  }

  getProductTemplates() {

    this.subs.sink = this.catalogueService.getAlllCatalogueProducts().subscribe(
      response => {

        if (response.length > 0)
          response.map(val => val.svgString = this.sanitizer.bypassSecurityTrustHtml(val.svg))

        this.productTemplates = response.filter((val) => {
          return val.type == 1 ?? val;
        });

        this.pageTemplates = response.filter((val) => {
          return val.type == 2 ?? val;
        });


        this.buttonTemplates = response.filter((val) => {
          return val.type == 3 ?? val;
        })

      }
    )

  }

  deleteComponent(id: number, index: number, type: number) {

    this.subs.sink = this.catalogueService.deleteCatalogueProduct(id).subscribe(
      response => {

        if (type == 1) {
          this.productTemplates.splice(index, 1);
          this.toastr.success('Product template delete');

        }
        else if (type == 2) {
          this.pageTemplates.splice(index, 1);
          this.toastr.success('Page template delete');
        }
        else if (type == 3) {
          this.buttonTemplates.splice(index, 1);
          this.toastr.success('Page template delete');
        }
        else {
          this.toastr.success('Component deleted');

        }
        this.cdr.markForCheck();
      }
    )
  }

  urlDecode(val) {
    return decodeURIComponent(val)
  }

  addBigTextToCanvas(fontSize, text) {
    this.addBigText.emit({ fontSize, text });
  }

  addElementsGroup(component, componentName) {

    this.addElementGroup.emit({componentName, component });
  }

  pageToCanvas(json) {

    this.emitPageToCanvas.emit(json);

  }

  goToAddPage(tp = 0, id = 0) // tp 0 for add 1 for edit
  {
    var url = tp == 0 ? "/inventory/catalog/design-component" : "/inventory/catalog/design-component/" + id + "/edit"

    if (this._canvasModified) {

      let option: SweetAlertOptions = {
        title: 'Confirm',
        text: 'Do you want to continue to page without saving catalog?.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn',
          footer: 'flex-row-reverse'
        },
        buttonsStyling: false
      };
      popup.OpenConfirmBox(option, result => {
        if (result.isConfirmed) {
          this.router.navigate(
            [url],
            { queryParams: { type: 'page' } }
          );
        }
        else {
          return
        }

      });

    }


    else {
      this.router.navigate(
        [url],
        { queryParams: { type: 'page' } }
      );
    }


  }

  goToAddProduct(tp = 0, id = 0) // tp 0 for add 1 for edit
  {
    var url = tp == 0 ? "/inventory/catalog/design-component" : "/inventory/catalog/design-component/" + id + "/edit"

    if (this._canvasModified) {

      let option: SweetAlertOptions = {
        title: 'Confirm',
        text: 'Do you want to continue to page without saving catalog?.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn',
          footer: 'flex-row-reverse'
        },
        buttonsStyling: false
      };
      popup.OpenConfirmBox(option, result => {
        if (result.isConfirmed) {
          this.router.navigate(
            [url],
            { queryParams: { type: 'product' } }
          );
        }
        else {
          return
        }

      });

    }


    else {
      this.router.navigate(
        [url],
        { queryParams: { type: 'product' } }
      );
    }


  }

  getCategories() {
    this.catalogueService.getCatalogueCategory().subscribe(response => {
      this.catalogCatagories = response;
    });
  }

  addImageToCanvas(src) {

    this.imageToCanvas.emit(src);
  }

  addFigureToCanvas(fig) {

    this.figureToCanvas.emit(fig)
  }
  clickedOnCategory(id) {

    this.addCategory.emit(id);
  }

  onImageChange(event) {

    if (event.target.files && event.target.files.length > 0) {
      // Don't allow file sizes over 1MB
      if (event.target.files[0].size < MAX_SIZE) {
        // Set theFile property
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.uploadFile(reader.result, event.target.files[0].name)
        };
      }
      else {
        // Display error message
        //this.messages.push("File: " + event.target.files[0].name + " is too large to upload.");
      }
    }
  }

  uploadFile(file: any, title: string) {

    let data = new ThumbNail();

    data.file = this.convertBase64ToBlob(file);
    data.name = title;
    this.isUploadingImg = true


    this.subs.sink = this.fileService.uploadFiles([data], 'CATALOG_UPLOADS').subscribe(
      response => {
        this.isUploadingImg = false
        this.getImageList()
      },
      () => {
        this.isUploadingImg = false
        return '';
      })
  }
  getImageList() {

    this.subs.sink = this.fileService.getFiles('CATALOG_UPLOADS').subscribe(
      response => {
        this.imageList = response

        this.stockImgPreviewList = this.imageList.images.map((x) => { return { image: this.imgURL + x } })
        this.cdr.markForCheck();

      },
      () => {
        return '';
      })
  }



  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }
  trackImageId(index: number, img: any) {

    return img;
  }
  deleteImage(item: any) {

    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.fileService.deleteFile(item).subscribe(
          (res) => {
            this.imageList.images = this.imageList.images.filter(m => m !== item);
            this.cdr.markForCheck();
            popup.CompleteDelete('Image has been deleted.');
          },
          () => this.toast.error('Failed to delete resource')
        );
      }
    });

  }
  open(content, index, tp) {
    this.selectedImageIndex = index;
    if (tp == 1) {
      this.showFlag1 = true;
      this.showFlag2 = false;
    }
    else if (tp == 2) {
      this.showFlag2 = true;
      this.showFlag1 = false;
    }
    this.modalService.open(content, { size: 'lg', centered: true });
  }

  next() {
    this.selectedImageIndex += 1;
    //this.fullScreenPreview = this.thumbNails[this.findex];
  }

  prev() {
    this.selectedImageIndex -= 1;
    //this.fullScreenPreview = this.thumbNails[this.findex];
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onDragStart(tp,item) {
   // event.dataTransfer.setData('text/plain', label);
   const data:any = {'type':tp,'val':item}
   this.dragItem.emit(data)
  }

}



