import { ChangeDetectorRef, Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { addSC, SCItemAdd, SCItemsList, SCKitsAdd, SCKitsList, SCList } from '../../models/service-contract.models';
import { FormControl, FormGroup, NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { CustomerLocationSelectComponent } from './../../../tenant-customers/components/select/customer-location-select/customer-location-select.component';
import { CustomerContact, CustomerLocation, TenantCustomer } from 'src/app/modules/tenant-customers/models/tenant-customer';
// import { WarrantyKitItemsComponent } from '../../components/kit-items/kit-items.component';
import { TermsService } from '../../services/terms.service';
import { getTermsCat, TermsCatResponse } from '../../models/terms.models';
import { formatDate, Location } from '@angular/common';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Kit } from 'src/app/modules/inventory/models/kit.models';
import { KitService } from 'src/app/modules/inventory/services/kit.service';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { CustomField } from 'src/app/core/models/custom-field';
import { Observable } from 'rxjs';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UIPermission } from 'src/app/core/models/common.models';
import { SalesService } from '../../services/sales.service';
import { SoItemsComponent } from '../../components/so-items/so-items.component';
import { InvoiceService } from '../../services/invoice-service';
import { addInvoice, ItemAdd, Section } from '../../models/invoice.models';
import { InvoiceItemsComponent } from '../../components/invoice-items/invoice-items.component';
import { InvoiceComponent } from '../../../sales-shared/sales-invoice/invoice/invoice.component';
import { filter } from 'rxjs/internal/operators/filter';
import * as moment from 'moment';

@Component({
  selector: 'app-invoice-add',
  templateUrl: './invoice-add.component.html',
  styleUrls: ['./invoice-add.component.scss']
})
export class InvoiceAddComponent implements OnInit {

  // public addData:addSC=new addSC();

  @ViewChild('locationSelect') locationSelect: CustomerLocationSelectComponent;

  @ViewChild('invoiceForm') public scForm: NgForm;


  public userAuth=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));


  public catId:number=3;

  public kitModel: Kit = new Kit();

  public invoiceId:number=0;

  public termsList;

  public termSelection;

  public termsCategory="";

  public warrantyForm;

  // public itemData:SOItemAdd=new SOItemAdd();

  // public kitData:SOKitsAdd=new SOKitsAdd();

  public subs: SubSink = new SubSink();

  public model:addInvoice=new addInvoice();

  public getTermsData:getTermsCat=new getTermsCat();

  public additionalFields: CustomField[];

  public api;

  public Resource = ResourceConstants;

  public Permissions = PermissionConstants;

  public submitted:boolean=false;

  public formError:boolean=false;

  public UIPermissions:UIPermission;

  public shippingMinDate:NgbDateStruct={day:1,month:3,year:2022}

  public shippingLocation;

  public tax:number;

  public _saveAsDraft:boolean=false;


  public customerSm:number=0;

  public customerSmAccNo:string='';

  public formData=new FormGroup({
    customer: new FormControl(this.userAuth.custid),
    title: new FormControl(),
    unique_number: new FormControl(),
    tenant_customer: new FormControl(),
    tenant_customer_contact: new FormControl(),
    tenant_customer_location: new FormControl(),
    starting_date: new FormControl(),
    ending_date: new FormControl(),
    terms_and_condition: new FormControl(),
  });
    credit_limit: number;
    credit_balance: number;
    credit_enforced: boolean;
    public sections:Section[]



  constructor(private Service: InvoiceService,
    private toaster:ToastrService,
    public location: Location,
    private TermsService:TermsService,
    public datepipe: DatePipe,
    private router:Router,
    private cdr: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private ra: ResourceAccessService,
    public salesService: SalesService){
        this.subs.sink = activeRoute.params.subscribe(params => this.invoiceId = params.id || 0);
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_INVOICES, ResourceConstants.SALES_MODULE);

        // if(this.router.getCurrentNavigation().extras.state){
        //     this.model =JSON.parse(this.router.getCurrentNavigation().extras.state.invoice_item) as addInvoice
        //     this.convertToInvoiceObject();
        //     this.sections = JSON.parse(this.router.getCurrentNavigation().extras.state.sections) as Section[]
        // }
    }





  ngOnInit(): void {
    let test=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
    this.model.suborg=parseInt(localStorage.getItem(AppConstants.CURRENT_SUBORG));
    this.model.coverage_type=3;

    if(this.invoiceId!=0){
        this.getById(this.invoiceId);
    }

    this.shippingMinDate.day=Number(this.datepipe.transform((new Date), 'dd'));
    this.shippingMinDate.month=Number(this.datepipe.transform((new Date), 'MM'));
    this.shippingMinDate.year=Number(this.datepipe.transform((new Date), 'yyyy'));
  }


  getById(id:number){

    this.subs.sink=this.Service.viewIN(id).subscribe(
      response=>{
          // this.warranty=response;
          this.model=response;
          this.cdr.markForCheck();
          this.model.shipping_date=this.salesService.trimTime(this.model.shipping_date);
          this.tax=(this.model&&this.model.tax)?this.model.tax:0;
          // this.setDates(this.model.starting_date, this.model.ending_date);
      },
      ()=>{
        this.toaster.error("Error occured during fetching data");
      }
    );

  }

 convertToInvoiceObject(){
    console.log(this.model)
    this.model["client_viewed"] = false;
    this.model["client_viewed_date"] = null;
    this.model["mail_sent_date"] = null;
    this.model["from_module"] = 13;
    this.model["from_unique_number"] = this.model["unique_number"];
    this.model["unique_number"] = null;
    // this.model["shipping_date"] = (this.model["shipping_date"]) ? moment(this.model["shipping_date"]).utc().format('YYYY-MM-DD') : null;
    this.tax=(this.model&&this.model.tax)?this.model.tax:0;
    console.log(this.model)
 }



  add(saveAsDraft:boolean=false, status=null){

    this.model.customer=this.userAuth.custid;

    this._saveAsDraft=saveAsDraft;
    this.submitted=true;

    this.formError=false;

    if(this.checkValidation(saveAsDraft)){
      if(!saveAsDraft){
        this.toaster.error("You should enter all mandatory fields");
        return;
      }
    }
    this.model.status = saveAsDraft ? 1 : this.invoiceId == 0 ? 8 : status || this.model.status;


    if(this.model.shipping_date&&this.model.shipping_date!=""){
      this.model.shipping_date=this.model.shipping_date!=undefined?this.model.shipping_date+'T00:00:00Z':undefined;
    }
    else{
      this.model.shipping_date=null;
    }

    if(!this.model.shipment_method){
      this.model.shipment_method=null;
    }

    if(!this.model.payment_terms){
      this.model.payment_terms=null;
    }



    this.api = this.invoiceId > 0 ? this.Service.updateIN(this.model, this.invoiceId) : this.Service.addIN(this.model);

    this.subs.sink = this.api.subscribe(
      response => {

        if (this.hasPermission(this.Permissions.VIEW_INFO)) {
           this.router.navigate(['/sales/invoice/'+response.id+'/view']);
        }
        else {
          this.location.back();
        }

        this.toaster.success(this.invoiceId>0?"Invoice updated":"Invoice Added successfully");
      },
      ()=>{
         this.toaster.error("Sorry error occured during process");
      }
    );
  }


  checkValidation(saveAsDraft:boolean=false){
    console.log(this.model.tenant_customer_location);

          if(saveAsDraft){

            if(this.model.tenant_customer==undefined){
              this.toaster.error("You should select atleast customer field to save as draft");
              return true;
            }
            else{
              return false;
            }

          }
          else{

            if(this.model.tenant_customer==undefined||
              this.model.tenant_customer_contact==undefined||
              this.model.tenant_customer_location==undefined){

              this.formError=true;

            }
            console.log("diff"+this.calculateDiff(this.model.shipping_date, new Date()));

            if(!this.model.payment_terms){
                this.toaster.error("You should select payment terms");
                this.formError=true;
            }
          }

          if(this.formError){
            return true;
          }
          else{
            return false;
          }

  }


  calculateDiff(startDate, endDate){
    endDate = new Date(endDate);
    startDate = new Date(startDate);
    let test = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);

    return test;
}

  //On customer change

  customerChanged(customer:TenantCustomer){
    console.log("Customer just changed");
    this.model.tenant_customer_location=null;
    this.model.tenant_customer_contact=null;
    this.tax=this.model.tax =(customer&&customer.tax_percentage)?customer.tax_percentage:0;

    this.model.payment_terms=(customer&&customer.payment_terms)?customer.payment_terms:null;

    this.model.shipment_method=(customer&&customer.shipping_method)?customer.shipping_method:null;

    this.customerSm=(customer&&customer.shipping_method)?customer.shipping_method:null;

    this.customerSmAccNo=(customer&&customer.shipping_method_account_number)?customer.shipping_method_account_number:null;
    this.credit_limit=(customer&&customer.credit_limit)?customer.credit_limit:null;
    this.credit_balance=(customer&&customer.credit_balance)?customer.credit_balance:null;
    this.credit_enforced=(customer&&customer.credit_enforced)?customer.credit_enforced:false;
    this.cdr.markForCheck();

  }

  customerContactChange(customer:CustomerContact){
    console.log('Customer contact just changed');
    console.log(customer);
      this.model.tenant_customer_location=null;
      if(this.model.tenant_customer_contact&&customer&&customer.location){
        this.model.tenant_customer_location=customer.location;
      }
  }




  termsSelected(event){

    this.model.conditions=event.target.value;
    this.model.terms_and_condition=event.target.id;

  }

  termsValueChange(value){
    this.model.terms_and_condition=value;
  }





  termsChange(terms){
    this.model.conditions=terms ? terms.description : null;
  }








  onCustomerLocationAdded() { this.locationSelect?.reload(); }

  hasPermission(permission: string) {
    return this.ra.hasPermission(this.Resource.SALES_INVOICES, [permission, this.Permissions.GLOBAL]);
  }

  hasPermissionWR(resource: string, permission: string) {
    return this.ra.hasPermission(resource, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(this.Resource.SALES_INVOICES, [this.Permissions.GLOBAL]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


}
