import { CustomerService } from 'src/app/modules/customer/services/customer.service';
import { Observable } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Customer } from 'src/app/modules/customer/models/customer';

@Component({
    selector: 'app-customer-select',
    templateUrl: './customer-select.component.html',
    styleUrls: ['./customer-select.component.scss']
})
export class CustomerSelectComponent implements OnInit {

    private _value: number;

    get value() { return this._value; }

    @Input() set value(val: number) {
        this._value = val;
        if (val !== this._selected) {
            this._selected = val;
        }
    };

    @Input() readonly: boolean = false;

    @Output() valueChange = new EventEmitter<number>();

    @Output() change = new EventEmitter<Customer>();

    public customers: Observable<Customer[]>;

    private _selected: number;

    get selected() { return this._selected; }

    set selected(value: number) {
        this._selected = value;
        this.valueChange.emit(value);
    }

    constructor(private customerService: CustomerService) { }

    ngOnInit() {
        this.customers = this.customerService.getAll()
    }

    onValueChanged(value: Customer) {
        this.change.emit(value);
    }
}
