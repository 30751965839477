import { BaseService } from './../../../core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResoureAccess } from '../models/resource-access';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResourceService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getAll() {
        return this.http.get<ResoureAccess[]>(`${this.baseURL}/resourceaccess/`);
    }

    getById(id: number) {
        return this.http.get<ResoureAccess>(`${this.baseURL}/resourceaccess/${id}/`);
    }

    create(perm: ResoureAccess) {
        return this.http.post<ResoureAccess>(`${this.baseURL}/resourceaccess/`, perm);
    }

    update(id: number, perm: ResoureAccess) {
        return this.http.put<ResoureAccess>(`${this.baseURL}/resourceaccess/${id}/`, perm);
    }

    delete(id: number) {
        return this.http.delete<ResoureAccess>(`${this.baseURL}/resourceaccess/${id}/`);
    }

    getAllCached() {
        let resources: ResoureAccess[] = JSON.parse(localStorage.getItem(AppConstants.APP_RESOURCES));
        if (!resources) {
            return this.getAll().pipe(
                map(response => {
                    localStorage.setItem(AppConstants.APP_RESOURCES, JSON.stringify(response));
                    
                    if(this.isCustomer){
                        return response.filter(val=>val.customer_has_access==true);

                    }
                    else{
                        return response;

                    }
                })
            );
        }
        else {
            return of(resources);
        }
    }
}
