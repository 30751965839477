import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { TimeFrameDetailsResponse, TimeFrameRequest, WorkingTimeResponse } from '../models/time-frame.model';
import { DataFilterGroupRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeFrameService extends BaseService {

  constructor(private http: HttpClient) { super();}

  getTimeFrames(request: DataFilterGroupRequest, offset: number = 0, limit: number = this.dataLimit){
    let url = `${this.baseURL}/timeframe/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<WorkingTimeResponse[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
  );
  }

  getTimeFrameDetails(id:number){
    return this.http.get<TimeFrameDetailsResponse[]>(`${this.baseURL}/timeframe/details/${id}/`);
  }

  getById(id:number){
    return this.http.get<WorkingTimeResponse>(`${this.baseURL}/timeframe/${id}/`);
  }

  createTimeFrame(request:TimeFrameRequest){
    return this.http.post<WorkingTimeResponse>(`${this.baseURL}/timeframe/`,request)
  }

  updateTimeFrame(id: number, model: TimeFrameRequest) {
    return this.http.put<WorkingTimeResponse>(`${this.baseURL}/timeframe/${id}/`, model);
}

  deleteTimeFrame(id:number) {
    return this.http.delete<{ status: boolean, message: string }>(`${this.baseURL}/timeframe/${id}/`);
  }
}
