<section class="add-role" *ngIf="model">
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{roleId > 0 ? 'Edit Role' : 'New Role'}}</h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <form name="form" #roleForm="ngForm" (ngSubmit)="roleForm.form.valid && roleFormOnSubmit()" novalidate>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group" *ngIf="isSuperAdmin">
                                        <label>Tenant</label>
                                        <app-customer-select [(value)]="model.customer"></app-customer-select>
                                    </div>
                                    <div class="form-group">
                                        <label class="val-required">Role Name</label>
                                        <input type="text" [(ngModel)]="model.name" #name="ngModel" name="name" class="form-control" required>
                                        <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                                            <div *ngIf="name.errors.required">
                                                Role name is required.
                                            </div>
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <label class="val-required">Description</label>
                                        <input type="text" [(ngModel)]="model.description" #desc="ngModel" name="description" class="form-control" required>
                                        <small class="form-text text-muted danger" *ngIf="desc.invalid && (desc.dirty || desc.touched)">
                                                <div *ngIf="desc.errors.required">
                                                    Description is required.
                                                </div>
                                        </small>
                                    </div>

                                    <div class="form-group d-flex align-items-center" *ngIf="roleId==0">
                                        <label class="m-0 mr-2">Is this a customer role :</label>
                                        <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                                            <input type="checkbox" [checked]="model.is_customer_role" (change)="model.is_customer_role=!model.is_customer_role" name="is_customer_role" class="custom-control-input" id="customerRole">
                                            <label class="custom-control-label mr-1" for="customerRole">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group" *ngIf="model.customer&&!model.is_customer_role">
                                      <label class="val-required">Suborgs</label>
                                      <suborg-select [tenantId]="model.customer" [(value)]="model.suborgs" [multiple]="true"></suborg-select>
                                    </div>



                                    <div class="form-group d-flex align-items-center">
                                        <label class="m-0 mr-2">Status :</label>
                                        <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                                            <input type="checkbox" [(ngModel)]="model.is_active" name="is_active" class="custom-control-input" id="status" checked>
                                            <label class="custom-control-label mr-1" for="status">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                    <button type="submit" [disabled]="!roleForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                        {{roleId > 0 ? 'Save Changes' : 'Create'}}
                                    </button>
                                    <button (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
