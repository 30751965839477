import { BlocksModule } from './blocks/blocks.module';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import './core/utils/global.functions';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailEditorModule } from 'angular-email-editor';
import { CommonModule } from '@angular/common';





@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    BlocksModule,
    NgHttpLoaderModule.forRoot(),
    NgbModule,
    EmailEditorModule,

  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
