import { Injectable } from '@angular/core';
import { CustomField } from '../models/custom-field';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {

  constructor() { }

  parse(obj: any) {
    if (typeof obj === 'string') {
      try { obj = JSON.parse(obj); }
      catch (exception) { obj = null; }
    }
    return obj;
  }

  obj2CustomFields(obj: any): CustomField[] {
    if (!obj) return null;
    let fields = [], keys = [];
    obj = this.parse(obj) || {};
    for (let key in obj) {
      let customField = new CustomField();
      if (!keys.includes(key)) {
        customField.name = key;
        customField.value = obj[key];
        fields.push(customField);
        keys.push(key);
      }
    }
    return fields;
  }

  customFields2Obj(fields: CustomField[]): string {
    if (!fields || fields.length <= 0) return null;
    let obj = {};
    for (let cf of fields) {
      obj[cf.name] = cf.value || null;
    }
    return JSON.stringify(obj);
  }

  removeFieldsByObjectsKeys(target: CustomField[], jsonString: any) {
    if (!jsonString) return target;
    let customFields = this.obj2CustomFields(jsonString);
    return target.filter(c => !customFields.some(cf => cf.name === c.name));
  }

  merge(target: CustomField[], source: CustomField[]) {
    for (let field of source) {
      if (!target.some(x => x.name === field.name)) {
        target.push(field);
      }
    }
    return target;
  }
}
