import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";

export class KitDataHelper {

    static get FilterColumns(): GFColumn[] {
        return [
            { displayName: 'Name', columnName: 'kitname', columnType: GFColumnTypes.string },
            { displayName: 'Part Number', columnName: 'internalnumber', columnType: GFColumnTypes.string },
            { displayName: 'SKU', columnName: 'sku', columnType: GFColumnTypes.string },
            { displayName: 'Category', columnName: 'category', columnType: GFColumnTypes.ref },
            { displayName: 'Sell Price', columnName: 'sellprice', columnType: GFColumnTypes.number },
            { displayName: 'Cost Price', columnName: 'costprice', columnType: GFColumnTypes.number },
            { displayName: 'Associated Item', columnName: 'items', columnType: GFColumnTypes.ref },
            { displayName: 'Quantity', columnName: 'quantity', columnType: GFColumnTypes.number },
        ];
    }

    static get DefaultFilters(): GFilterParam[] {
        return [
            { displayName: "All Assemblies", colname: '', condition: 'is', value: null, operator: 'AND' },
            { displayName: "Active Assemblies", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
            { displayName: "Inactive Assemblies", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' }
        ];
    }

    static get SearchFilters(): GFilterParam[] {
        return [
            {
                displayName: 'Name or P/N or SKU', colname: '', condition: '', value: '',
                children: [
                    { colname: 'kitname', condition: 'contains', value: '', operator: 'OR' },
                    { colname: 'internalnumber', condition: 'contains', value: '', operator: 'OR' },
                    { colname: 'sku', condition: 'contains', value: '', operator: 'OR' },
                ]
            },
            { displayName: 'Category Name', colname: 'categoryname', condition: 'contains', value: '', operator: 'AND' },
            { displayName: 'Associated Item Number', colname: 'part_number', condition: 'contains', value: '', operator: 'AND' },
            { displayName: 'Sales Account', colname: 'salesaccountname', condition: 'contains', value: '', operator: 'AND' },
        ];
    }

    static get SortLookup(): any {
        return {
            name: "kitname",
            internal_number: "internalnumber",
            is_active: "is_active",
            category_name: 'categoryname',
            sku: 'sku',
            sell_price: 'sellprice',
            cost_price: 'costprice'
        };
    }

    static get AssembledKitFilterColumns(): GFColumn[] {
        return [
            { displayName: 'Assembly Name', columnName: 'kit_name', columnType: GFColumnTypes.string },
            { displayName: 'Assembly Part Number', columnName: 'kit_part_number', columnType: GFColumnTypes.string },
            { displayName: 'Assembly SKU', columnName: 'kit_sku', columnType: GFColumnTypes.string },
            {
                displayName: 'Status', columnName: 'status', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
                options: [
                    { displayName: 'Draft', value: 'DRAFT' },
                    { displayName: 'In Progress', value: 'IN_PROGRESS' },
                    { displayName: 'Allocated', value: 'ALLOCATED' },
                    { displayName: 'Specified', value: 'SPECIFIED' },
                    { displayName: 'Allocated and Specified', value: 'ALLOCATED/SPECIFIED' },
                    { displayName: 'Completed', value: 'COMPLETED' }
                ]
            },
            { displayName: 'Unique Id', columnName: 'unique_number', columnType: GFColumnTypes.string },
            { displayName: 'Assembly', columnName: 'kit_id', columnType: GFColumnTypes.ref },
            { displayName: 'Associated Item', columnName: 'items', columnType: GFColumnTypes.ref },
            { displayName: 'Assembly Category', columnName: 'kit_category', columnType: GFColumnTypes.ref },
            { displayName: 'Sell Price', columnName: 'kit_sellprice', columnType: GFColumnTypes.number },
            { displayName: 'Cost Price', columnName: 'kit_costprice', columnType: GFColumnTypes.number },
            { displayName: 'Quantity', columnName: 'quantity', columnType: GFColumnTypes.number },
        ];
    }

    static get AssembledKitDefaultFilters(): GFilterParam[] {
        return [
            { displayName: "All Built Assemblies", colname: '', condition: 'is', value: null, operator: 'AND' },
            // { displayName: "Active Built Assemblies", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
            // { displayName: "Inactive Built Assemblies", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
            // { displayName: "Built Assemblies (Draft)", colname: 'draft', condition: 'is', value: 'true', operator: 'AND' },
            // { displayName: "Built Assemblies (Not Draft)", colname: 'draft', condition: 'is', value: 'false', operator: 'AND' }
        ];
    }

    static get AssembledKitSearchFilters(): GFilterParam[] {
        return [
            {
                displayName: 'Name or P/N or SKU', colname: '', condition: '', value: '',
                children: [
                    { colname: 'kit_name', condition: 'contains', value: '', operator: 'OR' },
                    { colname: 'kit_part_number', condition: 'contains', value: '', operator: 'OR' },
                    { colname: 'kit_sku', condition: 'contains', value: '', operator: 'OR' }
                ]
            },
            { displayName: 'Unique Id', colname: 'unique_number', condition: 'contains', value: '', operator: 'AND' },
            { displayName: 'Associated Item Number', colname: 'part_number', condition: 'contains', value: '', operator: 'AND' },
        ];
    }

    static get AssembledKitSortLookup(): any {
        return {
            kit_name: 'kit_name',
            part_number: 'kit_part_number',
            unique_number: 'unique_number',
            kit_sku: 'kit_sku',
            created_on:'created_on'
        };
    }
}
