import { BaseService } from './../../../core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Permission } from '../models/permission';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getAll() {
        return this.http.get<Permission[]>(`${this.baseURL}/permission/`);
    }

    getById(id: number) {
        return this.http.get<Permission>(`${this.baseURL}/permission/${id}/`);
    }

    create(perm: Permission) {
        return this.http.post<Permission>(`${this.baseURL}/permission/`, perm);
    }

    update(id: number, perm: Permission) {
        return this.http.put<Permission>(`${this.baseURL}/permission/${id}/`, perm);
    }

    delete(id: number) {
        return this.http.delete<Permission>(`${this.baseURL}/permission/${id}/`);
    }

    getAllCached() {
        let permissions: Permission[] = JSON.parse(localStorage.getItem(AppConstants.APP_PERMISSIONS));
        if (!permissions) {
            return this.getAll().pipe(
                map(response => {
                    localStorage.setItem(AppConstants.APP_PERMISSIONS, JSON.stringify(response));
                    return response;
                })
            );
        }
        else {
            return of(permissions);
        }
    }
}
