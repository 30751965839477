import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { CustomField } from 'src/app/core/models/custom-field';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { Distributor, DistributorLicense } from '../../models/distributor';
import { DistributorService } from '../../services/distributor.service';
import { Location } from '@angular/common';
import { DataFilterRequest } from 'src/app/core/models/grid-filter.models';

@Component({
  selector: 'app-add-distributor',
  templateUrl: './add-distributor.component.html',
  styleUrls: ['./add-distributor.component.scss'],
})
export class AddDistributorComponent implements OnInit {

  public model: Distributor;

  public distributorId: number;

  public additionalFields: CustomField[];

  public customFieldInputs = { key: '', value: '' };

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public nameExist: boolean = false;

  public licenseNumbers: number[];

  public licenseNumbersMaped: DistributorLicense[] = [];

  private subs: SubSink = new SubSink();

  constructor(
    route: ActivatedRoute,
    private distributorService: DistributorService,
    public location: Location,
    private toastr: ToastrService,
    private modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private customFields: CustomFieldsService,
    private router: Router,
    private ra: ResourceAccessService
  ) {
    this.subs.sink = route.params.subscribe(params => this.distributorId = +params.id || 0);
  }

  ngOnInit() {

    if (this.distributorId) {

      this.distributorService.getById(this.distributorId).subscribe(distributor => {
        this.model = distributor;
        this.additionalFields = this.customFields.obj2CustomFields(
          distributor.additional_fields
        );
        this.cdr.markForCheck();
      });

      let request: DataFilterRequest = {
        filters: [{ colname: 'distributor', condition: 'equalto', value: this.distributorId, operator: 'AND' }],
        sort: null,
      };

      this.subs.sink = this.distributorService.getDistributorLicenses(request, 0).subscribe((resp) => {
        this.licenseNumbers = resp.result.map((item) => item.license);
        this.licenseNumbersMaped = resp.result;
        this.cdr.markForCheck();
      });
    }
    else {
      this.model = new Distributor();
    }
  }

  onSubmit() {
    if(this.model.website)
        this.model.website = this.model.website.replace("https://",'');
    this.model.additional_fields = this.customFields.customFields2Obj(
      this.additionalFields
    );

    this.model.customer = this.distributorService.customerId;
    this.model.suborg = this.distributorService.suborgId;

    let api =
      this.distributorId > 0
        ? this.distributorService.update(this.distributorId, this.model)
        : this.distributorService.create(this.model);

    this.subs.sink = api.subscribe(resp => {

      let distLicenses = this.getNewLicenseNumbers(resp.id);

      if (distLicenses && distLicenses.length) {
        this.createCustomerLicense(resp.id, distLicenses);
      }
      else {
        this.toastr.success(this.distributorId ? `Distributor info updated` : `New Distributor created`);
        this.navigateOnComplete(resp.id);
      }
    },
      (error) => {
        if (error.error && error.error['non_field_errors']) {
          this.nameExist = true;
          this.toastr.warning('Name already exist');
          this.cdr.markForCheck();
        }
        else {
          let errorMessage = this.distributorId ? `Failed to update distributor` : `Failed to create distributor`;
          this.toastr.error(errorMessage);
        }
      }
    );
  }

  getNewLicenseNumbers(distributorId: number): DistributorLicense[] {

    let ids = this.licenseNumbers?.filter(item => !this.licenseNumbersMaped.some((m) => m.id === item));

    if (ids && ids.length) {
      return ids.map((item) => {
        let cl = new DistributorLicense();
        cl.distributor = distributorId;
        cl.license = item;
        cl.customer = this.distributorService.customerId;
        return cl;
      });
    }
    return null;
  }

  createCustomerLicense(distributorId: number, distLicenses: DistributorLicense[]) {
    this.subs.sink = this.distributorService
      .createDistributorLicense(distLicenses)
      .subscribe((response) => {
        this.toastr.success(
          this.distributorId > 0
            ? `Distributor info updated`
            : `New Distributor created`
        );
        this.navigateOnComplete(distributorId);
      });
  }

  navigateOnComplete(distributorId: number) {
    if (this.hasPermission(this.Permissions.VIEW_INFO)) {
      this.router.navigate(['/customers/distributors', distributorId, 'view']);
    }
    else {
      this.location.back();
    }
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(
      this.Resource.DISTRIBUTORS,
      [permission, this.Permissions.GLOBAL],
      this.Resource.CUSTOMER_MODULE
    );
  }

  openCustomFieldModal(content: any) {
    this.customFieldInputs.key = '';
    this.customFieldInputs.value = '';
    this.modal.open(content);
  }

  addCustomField() {
    this.additionalFields = this.additionalFields ?? [];
    if (!this.additionalFields.some((x) => x.name === this.customFieldInputs.key)) {
      let customField = new CustomField();
      customField.name = this.customFieldInputs.key;
      customField.value = this.customFieldInputs.value || null;
      this.additionalFields.push(customField);
    }
    else {
      this.toastr.info(`Field '${this.customFieldInputs.key}' exist`);
    }
    this.modal.dismissAll();
  }

  deleteCustomField(id: string) {
    this.additionalFields = this.additionalFields.filter((x) => x.id !== id);
  }

  onLicenseNumberRemove(licenseId: number) {
    let distLicense = this.licenseNumbersMaped?.find(
      (m) => m.license === licenseId
    );

    if (distLicense) {
      this.subs.sink = this.distributorService
        .deleteDistributorLicense(distLicense.id)
        .subscribe(() => {
          this.licenseNumbersMaped = this.licenseNumbersMaped.filter(
            (dl) => dl.id !== distLicense.id
          );
          this.toastr.success('License number removed');
        });
    }
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
