
<app-invoice-dashboard *ngIf="viewDashboard" (ApplyFilter)="setFilter($event)" #dashboard></app-invoice-dashboard>
  <section class="common-grid-view invoice">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter
                                     [module]="module"
                                     [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [searchFilters]="search"
                                     [newButton]="UIPermissions.create"
                                     [newButtonUrl]="['/sales/invoice/add']"
                                     [expanded]="expanded"
                                     (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'created_user'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                        <ng-template filterControl [column]="'tenant_customer'" let-row="row">
                            <tenant-customer-select class="ng-select-sm" [(value)]="row.value"></tenant-customer-select>
                        </ng-template>
                        <ng-template filterControl [column]="'shipment_method'" let-row="row">
                            <shipping-methods-select class="ng-select-sm" [(value)]="row.value"></shipping-methods-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                                       [rows]="rows"
                                       [scrollbarH]="scrollbarH"
                                       class="bootstrap core-bootstrap"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [columnMode]="'force'"
                                       [selectionType]="SelectionType.single"
                                       [externalPaging]="true"
                                       [externalSorting]="true"
                                       [offset]="page.offset"
                                       [count]="page.count"
                                       [limit]="page.limit"
                                       (select)="onSelect($event)"
                                       (page)="setPage($event)"
                                       (sort)="onSort($event)"
                                       [sorts]="[{prop:'created_on',dir:'desc'}]">
                            <ngx-datatable-row-detail [rowHeight]="75" #myDetailRow>
                                <ng-template let-row="row" let-value="value" ngx-datatable-row-detail-template>
                                    <div class="row row-detail">
                                        <div class="customer-contact-details row-item" [ngClass]="expanded ? 'col-md-2' : 'col-md-6'">
                                            <div class="customer-name">
                                                <div class="badge badge-warning name-badge w-100">{{row.tenant_customer_name}}</div>
                                            </div>
                                            <div *ngIf="row.tenant_customer_contact_name" class="contact">
                                                <img src="../../../../../../assets/img/gallery/user-avatar.jpg" class="rounded-pill img-view" alt="Cinque Terre">
                                                <span class="contact-name">{{row.tenant_customer_contact_name}}</span>
                                            </div>
                                        </div>
                                        <div class="customer-location-details row-item" [ngClass]="expanded ? 'col-md-3' : 'col-md-6'">
                                            <div class="div">
                                                <label for="" class="item-head">Location</label>
                                            </div>
                                            <div class="location">
                                                <span>{{row?.tenant_customer_location_name}}</span>
                                                <span>{{row?.tenant_customer_location_address}}</span>
                                                <div>
                                                    <span>{{row?.tennant_customer_location_state}}</span>
                                                    <span *ngIf="row?.tennant_customer_location_state && row?.tenant_customer_location_city">,</span>
                                                    <span>{{row?.tenant_customer_location_city}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 row-item" *ngIf="expanded">

                                        </div>
                                        <div class="col-md-2 row-item" *ngIf="expanded">
                                            <div>
                                                <label class="item-head">VIP customer</label>
                                            </div>
                                            <div>
                                                <div>Coming soon</div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 row-item" *ngIf="expanded">
                                            <div>
                                                <label class="item-head">Suborg</label>
                                            </div>
                                            <div>
                                                <div>{{row.suborg_name}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-1 row-item" *ngIf="expanded" style="justify-content: center;">
                                            <div ngbDropdown container="body" style="text-align: center" *ngIf="expanded&&(UIPermissions.viewInfo||UIPermissions.edit||UIPermissions.delete)">
                                                <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                        (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                    <i class="ft-more-vertical" style="font-size: large;color: #370D7E !important;"></i>
                                                </button>
                                                <div ngbDropdownMenu>
                                                    <button *ngIf="UIPermissions.viewInfo" class="w-100" (click)="onSelect({selected:[row]})"
                                                            ngbDropdownItem>
                                                        <i class="ft-book-open mr-1"></i> View
                                                    </button>
                                                    <a class="w-100" *ngIf="UIPermissions.edit" [routerLink]="['/sales/invoice/',row.id,'edit']"
                                                        ngbDropdownItem>
                                                        <i class="ft-edit mr-1"></i> Edit
                                                    </a>
                                                    <button *ngIf="UIPermissions.delete" class="w-100 bg-light-danger" (click)="deleteSC(row)"
                                                            ngbDropdownItem>
                                                        <i class="ft-trash-2 mr-1"></i> Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-row-detail>
                            <ngx-datatable-column name="Created Date" prop="created_on" [minWidth]="150">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.created_on | date:'MM/dd/YYYY h:mm a'}}</div>
                                    <div>{{row.unique_number}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Title" prop="title" [minWidth]="100"></ngx-datatable-column>
                            <ngx-datatable-column  name="Total" prop="total_price" [sortable]="true" [minWidth]="150" [cellClass]="'invoice-total-cell'">
                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                    <ng-container>
                                        <div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <label class="item-head">Inv. Total</label>
                                                </div>
                                                <div>
                                                    <div>{{row.total_price.toFixed(2) | currency}}</div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <label class="item-head">Payment</label>
                                                </div>
                                                <div>
                                                    <div>{{row.payments_made.toFixed(2) | currency}}</div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <label class="item-head">Balance</label>
                                                </div>
                                                <div>
                                                    <div>{{row.balance_due.toFixed(2) | currency}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Payment Terms" prop="payments_terms_name" [sortable]="false" [minWidth]="100">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Due Date" prop="due_date">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.due_date | date:'MM/dd/YYYY'}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by" [sortable]="false">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <user-card [name]="value" *ngIf="value"></user-card>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column  name="Status" prop="status" [sortable]="true">
                                <ng-template let-row="row" let-status="value" ngx-datatable-cell-template>
                                    <ng-container>
                                        <div class="status-badge text-center"
                                             [class]="statusClass(row.status)">{{soStatus[row.status] | replace : '_' : ' '}}</div>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>

            <div class="parellax-detail" parellaxTabSlider
            [width]="1000" [minWidth]="500" (resized)="afterTabResize()" [page]="'invoice'" [target]="slideExpandTarget"
            *ngIf="!expanded && selected">
                <div #slideExpandTarget class="parellax-detail-content">
                    <sales-invoice [item]="selected" (onDelete)="deleteSC($event)"
                    (onClose)="closeDetailedView()" (onAfterSendEmail)="reloadInvoiceView()" #InvoiceView></sales-invoice>

                </div>
            </div>
        </div>
    </div>
  </section>
