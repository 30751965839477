<!-- <div class="select-wrapper">

    <ng-select

        placeholder="Search location"
        [appendTo]="'body'"
        [items]="locations"
        [searchFn]="customSearch"
        bindLabel="place_name"
        [(ngModel)]="selected"
        placeholder="Enter address"
        (search)="onSearch($event)">
        
      </ng-select>

    </div> -->
    <!-- map-dropdown.component.html -->
<div  id="geocoder-container"></div>








    