import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { result } from 'lodash';
import { SipOutBoundFunctionService } from 'src/app/modules/ring2voice/services/sipoutboundfunctionservice';
import { SubSink } from 'subsink';
import { SIPdetailsResponse } from '../../models/call-center.models';
import { SIPService } from '../../services/sip.service';
import { SIP } from '../../models/sip.models';
import { CallCenterService } from '../../services/call-center.service';
import { ToastrService } from 'ngx-toastr';
import { KeyCode } from '@ng-select/ng-select/lib/ng-select.types';
import { SipConnectionService } from '../../services/sip-connection.service';
import { sipInboundFunctionService } from '../../services/sipinbound-function.service';
import { CallcenterSharedService } from '../../services/call-center-shared.service';
@Component({
  selector: 'call-center-dialer',
  templateUrl: './call-center-dialer.component.html',
  styleUrls: ['./call-center-dialer.component.scss']
})
export class CallCenterDialerComponent implements OnInit, OnDestroy {

  public dialNumber: string = ''

  public subs: SubSink = new SubSink();

  public selectSendAsManual: boolean = true;

  public send_as: string = "";

  public subOrgId: number;

  public autoManualTitle:string = "Outbound Send As Manual";

  public isDidSelectOpen: boolean = false;

  public displayBackBtn:boolean=false;


  // public sip_details:SIPdetailsResponse=new SIPdetailsResponse();

  @Output() inputChange=new EventEmitter<any>();


  @ViewChild('dialInput') dialInput:ElementRef;



  constructor(private sipOutBoundFunctionService: SipOutBoundFunctionService,
    private sipInboundFnService : sipInboundFunctionService,
    private toastr: ToastrService,
    private sipService: SIPService,
    public callcenterSharedService: CallcenterSharedService,
    private cdr:ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.subOrgId = this.sipService.suborgId
    this.send_as =  this.callcenterSharedService.sip_details.send_as

     this.subs.sink = this.sipOutBoundFunctionService.getNumber().subscribe(val => {
      this.dialNumber = val
    })

  }

  // @HostListener('document:keydown', ['$event'])
  // onKeyDown(event: KeyboardEvent): void {
  //   if (event.key === 'Backspace' && !this.isDidSelectOpen) {
  //     // Handle Backspace key
  //     this.removeLastLetter();
  //   }
  // }

  removeLastLetter(): void {
    this.dialNumber = this.dialNumber.slice(0, -1);
  }

  onClickNumber(val: string) {
    this.displayBackBtn=true;


    let start=this.dialInput.nativeElement.selectionStart;

    if (this.dialNumber.length <= 10){

      let number1=this.dialNumber.slice(0, start);
      let number2=this.dialNumber.slice(start);

      number1+=val;

        this.dialNumber=number1+number2;

       

    }


    this.cdr.detectChanges();
    this.searchInContact();


    setTimeout(() => {
      this.dialInput.nativeElement.setSelectionRange(start+1, start+1);
      this.dialInput.nativeElement.focus();
     }, 10);

    
  }

  onChangeAutoManual(event)
  {
    if( event.target.checked == false)
    {
      this.send_as = ""
      this.autoManualTitle = "Outbound Send As Auto"
    }
    else
    {
      this.autoManualTitle = "Outbound Send As Manual"
      this.send_as = this.callcenterSharedService.sip_details.send_as
    }
      this.selectSendAsManual = event.target.checked
      
  }

  onCall() {

    if(this.sipInboundFnService.selectedCaller)
    {
       if(this.sipInboundFnService.selectedCaller?.state == "Established" 
       && this.sipInboundFnService.selectedCaller?.customValues.isCallOnHold == false)
       {
        this.toastr.error('Please hold current incoming call');
        return
       }
       if(this.sipInboundFnService.selectedCaller?.state == "Initial")
       {
        this.toastr.error('Please stop current incoming call');
        return
       }
    }
    if(this.sipOutBoundFunctionService.currentCallDetails.active_CallerSession)
    {
      if(this.sipOutBoundFunctionService.currentCallDetails.active_CallerSession?.state == "Established" 
      && this.sipOutBoundFunctionService.currentCallDetails.active_CallerSession?.customValues.isCallOnHold == false)
      {
       this.toastr.error('Please hold current outgoing call');
       return
      }
    }
    let userSipDetails: SIPdetailsResponse =this.callcenterSharedService.sip_details

    if (this.dialNumber.length > 0) {

      if (this.selectSendAsManual == true && this.send_as !=  
        userSipDetails.send_as &&
        this.send_as != "" && this.send_as != null) {

        const model:any = { send_as: this.send_as }
        this.subs.sink =  this.sipService.updateSendAs(userSipDetails.sip_id, model).subscribe(
          resp => {
            this.sipOutBoundFunctionService.makeCall('number', this.send_as, this.dialNumber,userSipDetails.sip_uri)
            userSipDetails.send_as = this.send_as
          },
          error => {
           console.log(error.error)
          })

      }
      else if (this.selectSendAsManual == true  && this.send_as == "" || this.send_as == null) {

        this.sipOutBoundFunctionService.makeCall('number',userSipDetails.send_as, this.dialNumber,userSipDetails.sip_uri)

      }
      else if (this.selectSendAsManual == false)  { //Auto is true

        let sip_sendas_no =this.callcenterSharedService.sip_details.send_as
        let callee_no = this.dialNumber.length > 10? this.dialNumber.slice(this.dialNumber.length - 10) 
                        : this.dialNumber

        let caller_areacode =this.callcenterSharedService.sip_details.send_as.substring(0, 3)
        let callee_areacode = callee_no.substring(0, 3)

        if (caller_areacode != callee_areacode) {
          this.subs.sink = this.sipOutBoundFunctionService.updateSendAsNumberWithAreaCd(callee_areacode).subscribe(result => {

            if (result?.status == true) {
              this.sipOutBoundFunctionService.makeCall('number',result.new_number, this.dialNumber,userSipDetails.sip_uri)
              userSipDetails.send_as = result.new_number
            }
          })
        }
        else {
          this.sipOutBoundFunctionService.makeCall('number',sip_sendas_no, this.dialNumber,userSipDetails.sip_uri)
        }

       
      }
      else
      {
        this.sipOutBoundFunctionService.makeCall('number',userSipDetails.send_as, this.dialNumber,userSipDetails.sip_uri)
      }

  
    }
  }
  ondidSelectOpenChange(event)
  {
    this.isDidSelectOpen = event
  }

  keyDown(event){

    let search=false;
    if (event.which >= 48 && event.which <= 57) {
        // Number

        search=true;

    }
    else if(event.which==8||event.which==46){

      search=true;
      
    }

    if(search){
      this.searchInContact();
    }

    this.onInputChange();

  }

  onInputChange(){

    if(this.dialNumber.length>0&&!this.displayBackBtn){
      this.displayBackBtn=true;
      this.cdr.detectChanges();
    }
    else if(this.dialNumber.length==0||this.dialNumber==''){
      this.displayBackBtn=false;
      this.cdr.detectChanges();
    
    }



  }


  searchInContact(){
     
     setTimeout(() => {
      this.inputChange.emit(this.dialNumber);
     }, 10);


  }

  keyUp(event){
    if(this.dialNumber.length==0){
      this.displayBackBtn=false;
    }
    else{
      this.displayBackBtn=true;
    }

    this.cdr.detectChanges();
  }


  removeNumber(){

    let length=this.dialNumber.length;
    
    if(length){

      let start=this.dialInput.nativeElement.selectionStart;

      if(start==-1||start==0){
        start=length-1;
      }
      else{
        start--;
      }


      let number1=this.dialNumber.slice(0, start);
      let number2=this.dialNumber.slice(start+1);

      this.dialNumber=number1+number2;

      if(this.dialNumber.length==0){
        this.displayBackBtn=false;
      }

      this.cdr.detectChanges();

      setTimeout(() => {
        this.dialInput.nativeElement.setSelectionRange(start, start);
        this.dialInput.nativeElement.focus();
       }, 10);

       this.searchInContact();
    }
  }



  ngOnDestroy(){
    this.subs.unsubscribe();
  }
}








