import { UserGroupSelectComponent } from './components/user-group-select/user-group-select.component';
import { UserSelectGridComponent } from './components/user-select-grid/user-select-grid.component';
import { AddUserGroupComponent } from './pages/add-user-group/add-user-group.component';
import { UserGroupsComponent } from './pages/user-groups/user-groups.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { RolesModule } from './../roles/roles.module';
import { UserFormComponent } from './components/user-form/user-form.component';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { NgModule } from '@angular/core';

import { UserRoutingModule } from './user-routing.module';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomerModule } from '../customer/customer.module';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserResetPasswordComponent } from './components/user-reset-password/user-reset-password.component';
import { FormsModule }   from '@angular/forms';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { UserSipSelectComponent } from './components/user-sip-select/user-sip-select.component';
import { VoiceMailListComponent } from './components/voice-mail-list/voice-mail-list.component';




@NgModule({
  declarations: [
    UserListComponent,
    AddUserComponent,
    UserFormComponent,
    UserProfileComponent,
    UserSelectComponent,
    UserResetPasswordComponent,
    UserGroupsComponent,
    AddUserGroupComponent,
    UserSelectGridComponent,
    UserGroupSelectComponent,
    UserSettingsComponent,
    MyProfileComponent,
    UserSipSelectComponent,
    VoiceMailListComponent
  ],
  imports: [
    UserRoutingModule,
    NgbModule,
    NgxDatatableModule,
    SharedModule,
    RolesModule,
    CustomerModule,
    FormsModule,
    NgSelectModule,
    QRCodeModule,
    ColorPickerModule
  ],
  exports:[
    UserFormComponent,
    UserSelectComponent,
    UserGroupSelectComponent,
    MyProfileComponent
  ],
  providers: [
    NgbActiveModal,
  ]
})
export class UserModule { }
