import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { DataFilterRequest, GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { ProductType } from '../../models/common.models';
import { TimesheetType } from '../../models/timesheet-type.models';
import { TimesheetTypeService } from '../../services/timesheet-type.service';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
  selector: 'app-timesheet-types',
  templateUrl: './timesheet-types.component.html',
  styleUrls: ['./timesheet-types.component.scss']
})
export class TimesheetTypesComponent implements OnInit {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild('formModal') postModal: any;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = [
    { displayName: "All Timesheet Types", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Active Timesheet Types", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
    { displayName: "Inactive Timesheet Types", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
  ];

  public searchFilters: GFilterParam[] = [
    {
      displayName: 'Combo Search', colname: '', condition: '', value: '',
      children: [
        { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Description', colname: 'description', condition: 'contains', value: '', operator: 'OR' }
      ]
    }
  ];

  public columns: GFColumn[] = [
    { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
    { displayName: 'Description', columnName: 'description', columnType: GFColumnTypes.string },
    { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
  ];

  public sort: GSortParam[];

  public sortLookup = { name: 'name', description: 'description' };

  public SelectionType = SelectionType;

  public rows: TimesheetType[];

  public timesheetTypeId: number;

  public expanded: boolean = true;

  public selected: TimesheetType;

  public Permissions = PermissionConstants;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public UIPermissions: UIPermission;


  constructor(private modalService: NgbModal,
    private timesheetTypeService: TimesheetTypeService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private device: DeviceDetectorService) {

    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.ST_TIMSHEET_TYPE, ResourceConstants.SERVICE_TICKET_MODULE);
  }

  ngOnInit(): void {
    this.scrollbarH = this.device.isMobile();
    this.getData(null);
  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let request: DataFilterRequest = { filters: filters, sort: this.sort }

    this.subs.sink = this.timesheetTypeService.filter(request).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }

  onSelect({ selected }) {
    let current: TimesheetType = selected[0];
    if (current && this.UIPermissions.viewInfo) {
      this.timesheetTypeId = current.id;
      this.selected = current;
      this.expanded = false;
      this.refreshDataTable();
    }
  }

  openModal(id: number = 0, current=null) {
    this.selected=current;
    this.timesheetTypeId = id;
    this.modalService.open(this.postModal, { centered: true });
  }

  closeModal() { this.modalService.dismissAll(); }


  onDataSubmittedSuccess(event){

    this.getData([]);
    this.closeModal();

  }

  // onDataSubmittedSuccess(result: { response: ProductType, message: string }): void {
    // this.toastr.success(result.message);
    // if (this.page.count < this.page.limit) {
    //   this.rows = [...this.rows.filter(w => w.id !== this.productTypeId), result.response];
    //   this.page.count = this.rows.length;
    // }
    // this.selected = result.response;
    // this.expanded = false;
    // this.refreshDataTable();
    // this.closeModal();
  // }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
    });
  }


  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter.getDataFilters(), offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop]) return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(this.dataFilter.getDataFilters());
    }
  }

  deleteRow(id: number) {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {

        this.subs.sink=this.timesheetTypeService.delete(id).subscribe(
          response=>{
            popup.CompleteDelete('Timesheet Type has been deleted.');
              this.rows = this.rows.filter(x => x.id !== id);
              this.cdr.markForCheck();
              this.closeDetailedView();
          },
          ()=>{
            this.toastr.error('Error occured during process');
          }
        );
      }
    });
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }


  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  isDefault(id){

    return this.rows.find(val=>val.id==id).is_default;

  }

 


  ngOnDestroy(): void { this.subs?.unsubscribe(); }



}
