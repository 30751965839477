import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AutomatedBlockList, SpamConditions, Test } from '../../models/automated-blocklist.models';
import { SubSink } from 'subsink';
import { AutomatedBlockService } from '../../services/automated-block.service';
import { ToastrService } from 'ngx-toastr';
import * as popup from 'src/app/core/utils/popup.functions';
@Component({
  selector: 'app-automated-block-list',
  templateUrl: './automated-block-list.component.html',
  styleUrls: ['./automated-block-list.component.scss']
})
export class AutomatedBlockListComponent implements OnInit {

  public model: AutomatedBlockList = new AutomatedBlockList();

  public conditions = Test;

  public subs: SubSink = new SubSink();

  public expanded: boolean = true;

  public timeDurationSelect: string[]=["Minute","Hour","Day","Week","Month"];

  constructor(
    private service: AutomatedBlockService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  getList(){
    this.subs.sink = this.service.getBlockList().subscribe(resp=>{
      if(resp.allowlist_settings){
        this.model.allowlist_settings = resp.allowlist_settings;
      }
      if(resp.spam_settings.length>0){
        this.model.spam_settings = resp.spam_settings;
      }
      if(resp.spam_conditions.length>0){
        this.model.spam_conditions = resp.spam_conditions;
        this.conditions = resp.spam_conditions;
      }
      this.cdr.markForCheck();
    })
  }

  onSaveClick() {
    this.model.spam_conditions = this.conditions;
    
    const updateSettings = () => {
      this.subs.sink = this.service.saveSettings(this.model).subscribe(resp => {
        if (resp) {
          this.toastr.success("Settings Saved Successfully");
        } else {
          this.toastr.error("An error occurred");
        }
      }, (error) => {
        this.toastr.error("Failed to update. Please try again");
      });
    };
  
    if (this.model.allowlist_settings?.min_call_duration && (this.model.spam_settings[0].duration >= this.model.allowlist_settings.min_call_duration)) {
      let opt = {
        text: `This action will set the minimum call duration value for automated allowlist settings to ${this.model.spam_settings[0].duration+1} seconds. Additionally,
                it will update the values of automated allowlist settings for suborgs whose values are currently less than ${this.model.spam_settings[0].duration+1} seconds.`,
        title: `Current allow list settings value is ${this.model.spam_settings[0].duration} seconds`,
        ButtonText: "Confirm",
      };
      popup.ConfirmWithButtonText(opt, (result) => {
        if (result.isConfirmed) {
          updateSettings();
        } else {
          return;
        }
      });
    } else {
      updateSettings();
    }
  }
  

}
