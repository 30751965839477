import { UnitsService } from './../../services/units.service';
import { UnitType } from './../../models/items';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UIPermission } from 'src/app/core/models/common.models';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-unit-types',
    templateUrl: './unit-types.component.html',
    styleUrls: ['./unit-types.component.scss']
})
export class UnitTypesComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @ViewChild('unitFormModal') postModal: any;

    @ViewChild('searchInput') searchInput: ElementRef;

    public SelectionType = SelectionType;

    public rows: UnitType[];

    private tempData: UnitType[];

    public selectedUnitId: number = 0;

    private subs: SubSink = new SubSink()

    public scrollbarH: boolean = false;

    public UIPermissions: UIPermission;

    public isAllSuborg: boolean;


    constructor(private modalService: NgbModal,
        private unitService: UnitsService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        private device: DeviceDetectorService) {

        this.isAllSuborg = this.unitService.isAllSuborg;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_UNITS, ResourceConstants.INV_MODULE);
    }

    ngOnInit() {
        this.scrollbarH = this.device.isMobile();
        this.subs.sink = this.unitService.getAll().subscribe(utypes => {
            this.rows = utypes;
            this.tempData = utypes;
            this.cdr.markForCheck();
        });
    }

    ngAfterViewInit(): void { this.searchInput?.nativeElement.focus(); }

    search(event: any) {
        const val = event.target.value?.toLowerCase();
        this.rows = this.tempData.filter(function (d) {
            return d.units.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.table.offset = 0;
    }

    onSelect({ selected }) {
        let current = selected[0];
        if (current && this.UIPermissions.edit && !this.isAllSuborg) {
            this.selectedUnitId = current.id;
            this.modalService.open(this.postModal, { centered: true });
        }
    }

    openModal() {
        this.selectedUnitId = 0;
        this.modalService.open(this.postModal, { centered: true });
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    deleteUnitType(id: number): void {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.unitService.delete(id).subscribe(
                    () => {
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        popup.CompleteDelete('Unit type has been deleted.');
                    },
                    () => this.toastr.error('Unable to delete row')
                );
            }
        });
    }

    onDataSubmittedSuccess(result: { response: UnitType, message: string }): void {
        this.toastr.success(result.message);
        if (this.selectedUnitId <= 0) {
            this.rows = this.rows.concat(result.response);
        }
        else {
            let temp = this.rows.filter(x => x.id !== this.selectedUnitId);
            this.rows = temp.concat(result.response);
        }
        this.cdr.markForCheck();
        this.closeModal();
    }

    onDataError(_error: any): void {
        this.toastr.error('Operation failed');
        this.closeModal();
    }

    onCancel(): void {
        this.closeModal();
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
