import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { concat } from 'lodash';
import { GFColumn, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { ServiceContractDataHelper } from 'src/app/modules/sales/helpers/service-contract-data-helper';
import { SCList, SCStatus } from 'src/app/modules/sales/models/service-contract.models';
import { ServiceContractService } from 'src/app/modules/sales/services/service-contract.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'customer-info-sc',
  templateUrl: './customer-info-sc.component.html',
  styleUrls: ['./customer-info-sc.component.scss']
})
export class CustomerInfoScComponent implements OnInit {

  @Input() set customerId(value:number){
    this._customerId=value;
  }

  public _customerId:number;


  public sc:SCList[]=[];

  public subs:SubSink=new SubSink();

  public customerContact:number;

  public customerLocation:number;

  public priority:number=null;

  public reasonForService:number;

  public search:string="";

  public showFilter:boolean=false;

  public resultCount:number=0;

  public resultTotalCount:number=0;

  public searchOffset=0;

  public filterActive:boolean=false;

  public searchData: GFilterParam[] = ServiceContractDataHelper.search[0].children;

  public SCStatus = SCStatus;

  public columns: GFColumn[] = ServiceContractDataHelper.columns;

  public statusList;

  public status=null;




  constructor(private scService:ServiceContractService, private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {

    // this.customerId=this.stService.customerId;

    this.searchSC();

    this.getStatusList();


  }

  ngAfterViewInit(){



  }

  getStatusList(){
    this.columns.forEach((val, index)=>{

      if(val.displayName=='Status'){

        this.statusList=val.options;
      }
    })
  }




  getStatusClass(status:string){

    if(status){

      let statusClass= ServiceContractDataHelper.statusClass;

      let val= statusClass[status];

      return val;
    }
    else{
      return '';
    }


  }

  getStatusString(status:string){
    if(status){

      const re = /_/gi;
      let s= status.replace(re,' ');

      return s;

    }
    else{
      return '-';
    }

  }


  searchSC(plainSearch=false,loadMore=false){

    let request={ sort:null, filters: null };

    let searchData=[];

    let tmp;


    if(this.search!=''){
      this.searchData.forEach((val, index)=>{
        tmp={...val};
        tmp.value=this.search.toString();
        searchData.push(tmp);
      })
    }


    if(plainSearch==false){


      if(this.customerContact){
        searchData.push({colname: 'tenant_customer_contact', condition: 'equalto', value: this.customerContact, operator: 'AND' });
      }

      if(this.customerLocation){
        searchData.push({colname: 'tenant_customer_location', condition: 'equalto', value: this.customerLocation, operator: 'AND' });
      }

      if(this.status){
        searchData.push({colname: 'status', condition: 'equalto', value: this.status, operator: 'AND' });
      }



    }

    searchData.push({colname: 'tenant_customer', condition: 'equalto', value: this._customerId, operator: 'WHEN' });



    request.filters=searchData;
    request.sort = [{colname: "created_on", direction: "desc"}]

    if(loadMore){
      this.searchOffset+=50;
    }




   this.subs.sink= this.scService.getAll(request, this.searchOffset).subscribe(
      response=>{
          this.sc=response.result;

          this.checkFilterActive();
          if(loadMore){
            this.sc=response.result=concat(response.result, this.sc);


          }
          else{
            this.sc=response.result;


          }

          this.resultTotalCount=response.count;

          this.resultCount=response.result.length;

          this.cdr.markForCheck();

      }
    );

    return false;

  }

  customerContactChange(event){

    if(event&&event.id){
      this.customerContact=event?.id;
    }
    else{
      this.customerContact=null;
    }


  }

  customerLocationChange(event){

    if(event&&event.id){
      this.customerLocation=event?.id;

    }
    else{
      this.customerLocation=null;
    }

  }

  changeReason(event){

    if(event&&event.id){
      this.reasonForService=event.id;
    }
    else{
      this.reasonForService=null
    }

  }

  changePriority(id){

    if(id){
      this.priority=id;
    }
    else{
      this.priority=null;
    }


  }

  checkFilterActive(){

    if(this.priority||this.reasonForService||this.customerContact||this.customerLocation){
      this.filterActive=true;
    }
    else{
      this.filterActive=false;
    }
  }


  clearSearchInput(){

    this.search='';
    this.searchSC(true);

  }

  resetFilter(){

    this.customerContact=null;
    this.customerLocation=null;
    this.search='';
    this.showFilter=false;
    this.status=null;


    this.searchSC(false);

    this.checkFilterActive();



  }

  toggleFilter(){
    this.showFilter=!this.showFilter;
    this.cdr.markForCheck();
  }

  ngOnDestroy(){

    this.subs.unsubscribe();

  }

}
