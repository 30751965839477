import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { R2VSettings } from '../../models/ring2voicesettings.models';
import { SuborgService } from '../../services/suborg.service';
import * as popup from 'src/app/core/utils/popup.functions';

@Component({
    selector: 'ring2voice-settings',
    templateUrl: './r2v-settings.component.html',
    styleUrls: ['./r2v-settings.component.scss']
})
export class R2vSettingsComponent implements OnInit, OnDestroy {

    private sub_org: number;

    public settings: R2VSettings;

    public model: R2VSettings;

    public modalRef: NgbModalRef;

    @Input() set suborgId(value: number) {
        this.sub_org = value;
        if (value) {
            this.getSettings()
        }
    }

    get suborgId() { return this.sub_org; }

    private subs = new SubSink();

    constructor(private suborgService: SuborgService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        public modalService: NgbModal) { }

    ngOnInit(): void { }

    syncForwardNumbers() {
        this.subs.sink = this.suborgService.syncForwardNumbers(this.sub_org).subscribe(
            resp => {
                this.toastr.success('Successfully synced the Forward Numbers');
            },
            error => this.toastr.error(`Failed to sync Forward Numbers please try again`)
        );
    }

    syncCalls() {
        this.subs.sink = this.suborgService.syncCalls(this.sub_org, 1).subscribe(
            resp => {
                this.toastr.success('Successfully synced the Calls');
            },
            error => this.toastr.error(`Failed to syc Calls`)
        )
    }

    syncPhoneNumbers() {
        this.subs.sink = this.suborgService.syncPhonenumbers(this.sub_org).subscribe(
            resp => {
                this.toastr.success('Successfully synced the Phone Numbers');
            },
            error => this.toastr.error(`Failed to sync Phone Numbers please try again`)
        );
    }

    syncMessages() {
        this.subs.sink = this.suborgService.syncMessage(this.sub_org, 1).subscribe(
            resp => {
                this.toastr.success('Successfully synced the Messages');
            },
            error => this.toastr.error(`Failed to sync Messages please try again`)
        );
    }

    syncSIPEnpoints() {
        this.subs.sink = this.suborgService.syncSIP(this.sub_org).subscribe(
            resp => {
                this.toastr.success("Successfully synced the SIP's");
            },
            error => this.toastr.error(`Failed to sync SIP's please try again`)
        );
    }

    getSettings() {
        this.subs.sink = this.suborgService.getR2VSettingsBySuborgId(this.sub_org).subscribe(resp => {
            this.settings = resp?.data;
            this.cdr.markForCheck();
        });
    }

    openSettingsForm(modalContent: any) {
        if (this.settings) {
            this.model = { ...this.settings }
        }
        else {
            this.model = new R2VSettings();
            this.model.sub_org = this.suborgId;
        }
        this.modalRef = this.modalService.open(modalContent, { centered: true });
    }

    onSubmit() {
        let api: Observable<R2VSettings> = null;
        let edit = false
        if (this.model.id) {
            edit = true;
            api = this.suborgService.updateRing2VoiceSettings(this.model.id, this.model)
        }
        else {
            api = this.suborgService.addRing2VoiceSettings(this.model);
        }

        this.subs.sink = api.subscribe(
            resp => {
                this.settings = resp
                this.toastr.success(edit ? `Ring2Voice Settings info updated` : `New Ring2Voice Settings created`);
                this.modalRef?.dismiss();
                this.cdr.markForCheck()
            },
            http_error => {
                if (http_error.error == "INVALID_SW_CREDENTIALS") {
                    this.toastr.error("Invalid credentials");
                }
                else if (http_error.error == "DUPLICATE") {
                    this.toastr.error("Credentials already exists");
                }
                else {
                    this.toastr.error(edit ? `Failed to update Ring2Voice Settings` : `Failed to create Ring2Voice Settings`)
                }
            }
        )
    }

    deleteSettings() {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.suborgService.deleteRing2voiceSettings(this.settings.id).subscribe(
                    () => {
                        popup.CompleteDelete('Ring2voice settings has been deleted.');
                        this.settings = null;
                        this.cdr.markForCheck();
                    },
                    () => this.toastr.error('Unable to delete settings')
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
