import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[InputRangeValidator]'
})
export class InputRangeValidatorDirective {

  @Input() min: number;
  
  @Input() max: number;

  constructor(private el: ElementRef,
    private ngModel: NgModel) { }

  ngOnInit(): void {
    this.el.nativeElement.value = this.ngModel.model;
  }

  @HostListener('input', ['$event']) onInput(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    let value = Number(inputElement.value);

    if (value > this.max) {
      inputElement.value = this.max.toString();
    }

    this.ngModel.update.emit(inputElement.value);
  }


  @HostListener('blur')
  onBlur(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let value = Number(inputElement.value);

    if (isNaN(value)) {
      value = this.min;
    } else if (value < this.min) {
      value = this.min;
    } else if (value > this.max) {
      value = this.max;
    }

    inputElement.value = value.toString();
    this.ngModel.update.emit(value);
  }

}