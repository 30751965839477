export class Account {

    public id: number;

    public start_date: any;

    public end_date: any;

    public paid: boolean;

    public active: boolean;
    
}