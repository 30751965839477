import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CatalogCartService } from '../../services/catalog-cart.service';
import { ItemRow, SOItemsType } from '../../models/sales-orders.models';
import { AppConstants } from 'src/app/core/constants/app.constants';

@Component({
  selector: 'app-product-order-preview',
  templateUrl: './product-order-preview.component.html',
  styleUrls: ['./product-order-preview.component.scss']
})
export class ProductOrderPreviewComponent implements OnInit {

  public selectedProductDetails : any = {}

  public catalogId : number

  public fileUrl: string = environment.apiURL + '/static/files/';

  total: number = 0;

  public KitIemTypes = SOItemsType;

  cartItems: any[] = [];

  public userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));

  constructor(public activeModal: NgbActiveModal, private catalogCartService: CatalogCartService,) { }

  ngOnInit(): void {

    //console.log('productDetails', this.selectedProductDetails)

    this.catalogCartService.currentCartItems.subscribe(res => {

      this.cartItems = res;
    })

    this.findTotal()
  }

  onChangeQuantity(tp) {


    if (tp == 0) //add
    {
      this.selectedProductDetails.quantity = this.selectedProductDetails.quantity + 1;
     // this.selectedProductDetails.totalPrice = this.selectedProductDetails.quantity * this.selectedProductDetails.price;
    }
    else {
      if (this.selectedProductDetails.quantity != 1) {
        this.selectedProductDetails.quantity = this.selectedProductDetails.quantity - 1;
        //item.totalPrice = item.quantity * item.price;
      }

    }

    this.findTotal()
    
    //localStorage.setItem('catalog-cart-items', JSON.stringify(this.cartItems))
  }
  findTotal() {

    this.total = this.selectedProductDetails.quantity * this.selectedProductDetails.sellprice
    // this.total = this.cartItems.reduce((acc, item) => {

    //   return acc + (item.quantity * item.price);
    // }, 0)

    // this.total = this.cartItems.reduce((acc, item) => {

    //   return acc + item.totalPrice + this.shipping
    // }, 0)
  }

  closeModal() {
    //     this.genericHttp.Get("").subscribe(data=>{
    
    // })
      //  if(data.user_created_name != "" || data.description != "" || this.tags.length != 0)
      //  {
       // this.selectedLegacyDocData.tags = this.tags
       
      //  }
    
      // }
  }

  addToCart()
  {
    
    if (localStorage.getItem("cartid"+"_"+this.userAuth.custid+"_"+this.catalogId) === null) {
     
      localStorage.setItem("cartid"+"_"+this.userAuth.custid+"_"+this.catalogId, "1")
    }

    if (this.cartItems.some(x => x.unique_id == this.selectedProductDetails.id)) {
      this.catalogCartService.onChangeCatalogCartItems(this.selectedProductDetails, 1)
    }
    else {

      let row = new ItemRow();

      row.id = 0,
        row.sales_order = 0,
        row.unique_id = this.selectedProductDetails.id,
        // created_on:'',
        row.created_user = this.userAuth.custid,
        // modified_on:'',
        // modified_user:'',
        // is_delete: false,
        // is_active:true,
        row.serial_number = '',
        row.quantity = this.selectedProductDetails.quantity,
        // section_title:'',
        row.price = this.selectedProductDetails.item_price == null ? 0 : this.selectedProductDetails.item_price,
        row.type = this.KitIemTypes.ITEM,
        row.name = this.selectedProductDetails.product_name,
        row.customer = this.userAuth.custid,
        row.partnumber = this.selectedProductDetails.product_part_number,
        // expanded:false,
        // //children
        row.image_link = this.selectedProductDetails.primary_image
      //}

      this.catalogCartService.onChangeCatalogCartItems(row)

    }

    this.activeModal.close();
  }

  changeImage(img:string)
  {
    this.selectedProductDetails.primary_image = img
  }

}
