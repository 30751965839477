import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { Pagination, GFilterParam, GSortParam, DataFilterRequest, GFColumn, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { Priority } from '../../models/common.models';
import { PriorityService } from '../../services/priority.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-priorities',
  templateUrl: './priorities.component.html',
  styleUrls: ['./priorities.component.scss']
})
export class PrioritiesComponent implements OnInit, OnDestroy {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild('formModal') postModal: any;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = [
    { displayName: "All Priorities", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Active Priorities", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
    { displayName: "Inactive Priorities", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
  ];

  public searchFilters: GFilterParam[] = [
    {
      displayName: 'Combo Search', colname: '', condition: '', value: '',
      children: [
        { displayName: 'Name', colname: 'priority_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Description', colname: 'description', condition: 'contains', value: '', operator: 'OR' }
      ]
    }
  ];

  public columns: GFColumn[] = [
    { displayName: 'Name', columnName: 'priority_name', columnType: GFColumnTypes.string },
    { displayName: 'Description', columnName: 'description', columnType: GFColumnTypes.string },
    { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
  ];

  public sort: GSortParam[];

  public sortLookup = { name: 'priority_name', description: 'description' };

  public SelectionType = SelectionType;

  public rows: Priority[];

  public uniqeId: number;

  public expanded: boolean = true;

  public selected: Priority;

  public Permissions = PermissionConstants;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public UIPermissions: UIPermission;

  constructor(private modalService: NgbModal,
    private priorityService: PriorityService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private device: DeviceDetectorService) {

    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.ST_PRIORITIES, ResourceConstants.SERVICE_TICKET_MODULE);
  }

  ngOnInit(): void {
    this.scrollbarH = this.device.isMobile();
    this.getData(null);
  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let request: DataFilterRequest = { filters: filters, sort: this.sort }

    this.subs.sink = this.priorityService.getFiltered(request, offset).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: Priority = selected[0];
    if (current && this.UIPermissions.viewInfo) {
      this.uniqeId = current.id;
      this.selected = current;
      this.expanded = false;
      this.refreshDataTable();
    }
  }

  openModal(id: number = 0) {
    this.uniqeId = id;
    this.modalService.open(this.postModal, { centered: true });
  }

  closeModal() { this.modalService.dismissAll(); }

  onDataSubmittedSuccess(result: { response: Priority, message: string }): void {
    this.toastr.success(result.message);
    if (this.page.count < this.page.limit) {
      this.rows = [...this.rows.filter(w => w.id !== this.uniqeId), result.response];
      this.page.count = this.rows.length;
    }
    this.selected = result.response;
    this.expanded = false;
    this.refreshDataTable();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
    });
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter.getDataFilters(), offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop]) return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(this.dataFilter.getDataFilters());
    }
  }

  deleteRow(id: number) {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.priorityService.delete(id).subscribe(
          () => {
            popup.CompleteDelete('Priority has been deleted.');
            this.rows = this.rows.filter(x => x.id !== id);
            this.cdr.markForCheck();
            this.closeDetailedView();
          },
          () => this.toastr.error('Unable to delete row')
        );
      }
    });
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }

  changeStatus() {

    let status = this.selected.is_active;

    let popupOption = {
      title: `Are you sure you want to ${status ? 'Activate Priority' : 'Deactivate Priority'}?`,
      text: this.selected.name,
      current: status,
    };

    popup.ConfirmActivate(popupOption, result => {

      if (!result.isConfirmed) {
        this.selected.is_active = !status;
        this.cdr.markForCheck();
        return;
      }
      this.subs.sink = this.priorityService.updateStatus(this.selected.id, status).subscribe(resp => {
        if (!resp) {
          this.selected.is_active = !status;
          this.toastr.error("Failed to change status");
          this.cdr.markForCheck();
        }
      });
    });
  }

  changeGlobalSettings(){

    let global = this.selected.is_global;

        let popupOption = {
            title: `Are you sure you want to ${global ? 'Globalize settings' : 'Non Globalize settings'}?`,
            text: "",
            current: global,
        };
        popup.ConfirmActivate(popupOption, result => {
            if (!result.isConfirmed) {
                this.selected.is_global = !global;
                this.cdr.markForCheck();
                return;
            }
            
            this.subs.sink = this.priorityService.patch(this.selected.id, { is_global: global }).subscribe(
              () => { },
              () => {
                this.selected.is_global = !global;
                this.toastr.error("Failed to change status");
                this.cdr.markForCheck();
              }
            );

        });
    
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
