
export class RolePrivilege {

    public id: number;

    public role: number;

    public resource: number;

    public permission: number;

    public allowed: boolean;

    public resource_name:string;

    public permission_name:string;
}
