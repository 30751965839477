import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ShippingMethodsService } from '../../../services/shipping-methods.service';
import { ShippingMethods } from '../../../models/shipping-methods.models';
import { SubSink } from 'subsink';
@Component({
  selector: 'shipping-methods-form',
  templateUrl: './shipping-methods-form.component.html',
  styleUrls: ['./shipping-methods-form.component.scss']
})
export class ShippingMethodsFormComponent implements OnInit, OnDestroy {

  public model: ShippingMethods = new ShippingMethods();

  @Input() suborg_id: number = 0;

  @Input() shippingMethodsId: number = 0;

  @Output() onSuccess = new EventEmitter<{ response: ShippingMethods, message: string }>();

  @Output() onError = new EventEmitter<any>();

  @Output() onCancel = new EventEmitter<any>();

  public nameExist: boolean = false;


  public subs: SubSink = new SubSink();

  constructor(private service: ShippingMethodsService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.shippingMethodsId > 0) {
      this.subs.sink = this.service.getById(this.shippingMethodsId).subscribe(paymentTerm => {
        this.model = paymentTerm;
      });
    }
  }

  onSubmit(): void {

    let api: Observable<ShippingMethods>;

    if (this.shippingMethodsId > 0)
      api = this.service.update(this.shippingMethodsId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;

      if(this.suborg_id > 0){
        this.model.suborg = this.suborg_id;
      }
      api = this.service.create(this.model);
    }

    this.subs.sink = api.subscribe(
      response => {
        let msg = this.shippingMethodsId > 0 ? 'Shipping methods updated' : 'Shipping methods created';
        this.onSuccess?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.onError?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void {
    this.onCancel?.emit();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
