import { ThumbNail, StaticFile } from './../models/files.models';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FilesService extends BaseService {

    constructor(private http: HttpClient) { super() }

    uploadImages(thumbnails: ThumbNail[]) {

        let formData = new FormData();

        for (let i = 0; i < thumbnails.length; i++) {
            formData.append("files", thumbnails[i].file);
        }

        return this.http.post<StaticFile[]>(`${this.baseURL}/static/files/upload/`, formData).pipe(map(resp => {
            for (let m of resp) {
                let match = thumbnails.find(t => t.name === m.name);
                if (match) {
                    match.data_id = m.id;
                    match.uploaded = true;
                }
            }
            return thumbnails;
        }));
    }

    uploadFiles(thumbnails: ThumbNail[], module_name: string) {

        let formData = new FormData();

        for (let i = 0; i < thumbnails.length; i++) {
            formData.append("files", thumbnails[i].file);
        }

        formData.append('module', module_name);

        return this.http.post<StaticFile[]>(`${this.baseURL}/static/files/upload/`, formData);
    }

    getImage(id: number) {
        return this.http.get<StaticFile>(`${this.baseURL}/uploadimage/${id}`);
    }

    getCsvColumns(file: any, opt: any, module: any) {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("option", opt);
        formData.append("module", module);
        formData.append('customer', this.customerId.toString());
        return this.http.post<any>(`${this.baseURL}/import/get_excel_columns/`, formData);
    }

    createProfile(req: any) {
        return this.http.post<any>(`${this.baseURL}/import/profile_save/`, req);
    }

    updateProfile(id: any, req: any) {
        return this.http.put<any>(`${this.baseURL}/import/profile_update/${id}/`, req);
    }

    deleteProfile(id: any) {
        return this.http.delete<any>(`${this.baseURL}/import/profile_delete/${id}/`);
    }

    downloadTemplate(columns: any) {
        let formData = new FormData();
        formData.append("columns", columns);
        return this.http.post(`${this.baseURL}/import/download_template/`, formData, { responseType: 'arraybuffer' });
    }

    getFiles(moduleName: string) {
        return this.http.get<StaticFile>(`${this.baseURL}/static/files/modulename/${moduleName}`)
    }
    deleteFile(id: any) {

        return this.http.delete<any>(`${this.baseURL}/static/files/${id}/delete/`);
    }
}
