import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PermissionConstants } from "src/app/core/constants/permissions.constants";
import { ResourceConstants } from "src/app/core/constants/resources.constants";
import { RouteGuard } from "src/app/core/gaurd/route.guard";
import { PaymentAccountSettingsComponent } from "./pages/payment-account-settings/payment-account-settings.component";
import { MakePaymentComponent } from "./pages/make-payment/make-payment.component";
import { AccountingPaymentsListComponent } from "./pages/accounting-payments-list/accounting-payments-list.component";
import { AccountingNewPaymentComponent } from "../accounting/pages/accounting-new-payment/accounting-new-payment.component";



const routes: Routes =[
    {
        path: 'general-settings',
        component: PaymentAccountSettingsComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.AUTOMATION_MODULE,
            resource: ResourceConstants.AUTOMATION_RULES,
            permissions: [PermissionConstants.VIEW],
            title: 'Accounting'
        }

    },
    {
        path: 'list',
        component: AccountingPaymentsListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.AUTOMATION_MODULE,
            resource: ResourceConstants.AUTOMATION_RULES,
            permissions: [PermissionConstants.VIEW],
            title: 'Accounting'
        }

    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PaymentsRoutingModule {}
