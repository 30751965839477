<div class="container-fluid g-0">
    <main>
        <catalogue-subnav class="p-2" (sideNavToggled)="sideNavStatus=$event;updateZoom();"
            [sideNavStatus]="sideNavStatus" (addElement)="addElement($event)" (imageToCanvas)="addImageToCanvas($event)"
            (addElementGroup)="addElementGroup($event)" (emitPageToCanvas)="addPageToCanvasFromSubNav($event)"
            (addBigText)="addBigText($event)" (addCategory)="getCategoryProducts($event)"
            [settings]="subNavSettings" [_canvasModified] = "activeCanvasModified"
            (dragItem) = "onDragObject($event)"></catalogue-subnav>
        <div class="display-area" [ngClass]="{'display-area-shrink': sideNavStatus}">
            <h5 class="page-title">{{pageTitle|uppercase}}</h5>

            <catalogue-product-select #productSelect (cancelSelector)="cancelProductSelection()"
                (selectedProductPageInfo)="afterProductSelection($event)"></catalogue-product-select>


  <!-- {{catalogProducts|json}}   
  <hr/>
  sections{{sections|json}}  -->
            <section [hidden]="chooseProductView" class="pt-0 mt-0 p-3 mb-3">



                <div>
                    <div class="row  d-flex flex-row">


                        <div class="col-12 label-box p-3">


                            <!-- <div class="d-flex flex-row">
                            <div class="d-flex flex-row">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Width</span>
                                    </div>
                                    <input type="number" width="50" (change)="changeDimention()" style="max-width:60px" class="form-control" [(ngModel)]="width"/>
                                    <div class="input-group-append">
                                      <span class="input-group-text">Inch</span>
                                    </div>
                                  </div>
                            </div>
                            <div class="ml-2 mr-2 pt-1">X</div>
                            <div class="d-flex flex-row">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Height</span>
                                    </div>
                                    <input type="number" class="form-control" (change)="changeDimention()" style="max-width:60px" [(ngModel)]="height" aria-label="Amount (to the nearest dollar)">
                                    <div class="input-group-append">
                                      <span class="input-group-text">Inch</span>
                                    </div>
                                  </div>
                            </div>
                        </div> -->
                            <div class="d-flex justify-content-between mb-1">
                                <div class="row">

                                    <div class="mt-1">
                                        <span class="text-danger">*</span><input type="text" class="title-input"
                                            placeholder="Enter Catalog title" [(ngModel)]="title" (click)="deselectCanvasObject()" />
    
                                    </div>
                                    <div class="status-toggle-btn mt-1 mr-5 ml-5">
                                        <label class="title">Show Price On Catelog :</label>
                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                            <input type="checkbox" [(ngModel)]="show_item_price" name="show_item_price"
                                                class="custom-control-input" (change)="changePriceShowOnCatelog($event)" id="show_item_price"
                                                >
                                            <label class="custom-control-label" for="show_item_price">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="status-toggle-btn mt-1 ml-5">
                                        <label class="title">Show Associated Tab :</label>
                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                            <input type="checkbox" [(ngModel)]="show_associated_tab" name="show_associated_tab"
                                                class="custom-control-input" (change)="onChangeAssocTabToggle($event)" id="show_associated_tab"
                                                >
                                            <label class="custom-control-label" for="show_associated_tab">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                    


                                <div class="d-flex flex-column mt-2 ">
                                    <!-- <small><i>Dimention: {{width}}"x{{height}}"</i> <i class="ft ft-edit"></i></small> -->
                                    <!-- <small><i>Zoom: {{canvas.getZoom()*100|number: '1.0-0'}}%</i></small> -->
                                    <div class="d-flex flex-row align-content-center">
                                        <!-- <small class="pt-1"><i>Zoom: </i></small>
                                        <input type="number" class="percentage-input" width="40"
                                            [(ngModel)]="canvasZoom" (change)="zoomChanged(canvasZoom)" />
                                        <small class="pt-1"><i>%</i></small> -->
                                        <label class="form-label">Zoom: </label>
                                        <input type="range" min="10" step=".1" max="150"
                                            class="form-range percentage-input mr-1" [(ngModel)]="canvasZoom"
                                            (input)="zoomChanged(canvasZoom)" />
                                        <input type="number" step="1" style="max-width:50px" width="30"
                                            (change)="zoomChanged(canvasZoom)" [(ngModel)]="canvasZoom" />
                                        <i>%</i>
                                    </div>
                                </div>
                            </div>

                    <div class="d-flex flex-row">
                        <div>
                            <div class="section-div" *ngFor="let section of sections; let i=index">
                                <div class="d-flex justify-content-between">
                                    <div class="section-title-div d-flex">
                                        <input type="text" placeholder="Section title" class="title-input" [(ngModel)]="section.title" (click)="deselectCanvasObject()" class=""/>
                                    </div>
                                    <div class="text-right">
                                        <p>No of Products: {{getProductCount(i)}}</p>
                                        <a class="btn btn-sm btn-primary" (click)="addProducts(i)">Add product to section</a>
                                    </div>
                                </div>

                                <div *ngFor="let page of section.pages;let index=index" class="canvas-div">
                                                
                                    <div class="d-flex justify-content-between">
                                        <page-canvas-component #canvas1
                                        [id]="findCanvasIndex(i, index)" 
                                        [width]="width" 
                                        [height]="height" 
                                        [zoom]="canvasZoom?canvasZoom/100:1" 
                                        [active]="selectedCanvasIndex==findCanvasIndex(i, index)"                           
                                        (checkProductCount)="checkProductsInCanvas($event)"
                                        (renderAll)="renderAllCanvases()"
                                        (click)="selectedCanvasIndex=findCanvasIndex(i, index);"
                                        (colorChanged)="onColorChange($event)">
                                    </page-canvas-component>
                                    
                                    <!-- <div class="tab-container p-3" *ngIf="productListbyPage.length > 0">


                                        <assoc-items-tab *ngIf="productListbyPage[i][index].length > 0"
                                         [_productListbyPage] = "productListbyPage[i][index]"
                                          [_sectionIndex] = "i" [_pageIndex] = "index"
                                          (_onItemMove) = "onItemPositionChange($event)"
                                          ></assoc-items-tab>

                                    </div>    -->
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="text-left p-1">
                                                <a>Pg No: {{findCanvasIndex(i, index) + 1}}</a>
                                             </div>
                                        </div>
                                        <div class="col">
                                            <div class="text-right p-1">
                                                <a (click)="deletePage(i, index)"><i class="fa ft-trash" ></i></a> 
                                             </div>
                                        </div>      
                                    </div>
                               
                                        <br/>   
                                        <!-- ->{{findCanvasIndex(i, index)}} -->
                                        <!-- =>Product count: {{catalogProductCount[index]}} -->


                                            <div class="add-page d-flex justify-content-center"
                                                (click)="addNewPage(i, index)">
                                                <span><i class="fa fa-plus mr-1"></i></span>
                                                <span class="">Add page</span>
                                            </div>

                                            

                                        </div>

                                        <!-- <button class="btn btn-secondary add-catalogue-btn" (click)="addNewPage()">
                                    Add page
                                </button> -->
                                

                              

                                    </div>

                                    <div class="add-section d-flex flex-row justify-content-center"
                                        (click)="addSection()">
                                        <span><i class="fa fa-plus mr-1"></i></span>
                                        <span class="">Add section</span>

                                    </div>



                                    <!-- <button class="btn btn-secondary add-catalogue-btn" >
                                Add section
                            </button> -->

                                </div>

                                <div class="tab-container p-3 ml-2" *ngIf = "productListbyPageRows?.length > 0 && show_associated_tab" >


                                    <!-- <h5>Associated Items Tab</h5> -->
                                    <div>

                                        <assoc-items-tab  
                                        [_productListbyPage] = "productListbyPageRows"
                                        [_maxProCountInPage] = "maxProCountInPage"
                                        [_canvasHeightInPixel] = "canvasHeightInPixel"
                                         (_onItemMove) = "onItemPositionChange($event)"
                                         (_onItemRemove) = "removeItemOnMove($event)"
                                         (_onItemDelete) = "deleteProduct($event)"


                                         ></assoc-items-tab>
                                    </div>
                                  

                                </div>


                                <!-- <div  class="associated-items card">
                            <div class="card-header"><h6>Associated Products</h6></div>

                            <div class="card-body">

                                <button (click)="addProducts()">Add Products</button>


                                <catalog-associated-products [products]="catalogProducts">

                                </catalog-associated-products>


                            


                            

                            </div>
                            
                        </div> -->


                            </div>
                        </div>
                    </div>
                </div>

                <!-- {{this.catalogueCanvas|json}} -->

                <div class="mt-2 float-right">
                    <a class="btn btn-secondary mr-2" [routerLink]="['/inventory/catalog/list']">Cancel</a>
                    <div class="btn-group">

                        <button class="btn btn-primary" (click)="saveCatalog()">{{catalogId>0?'Update':'Create'}}</button>
                        <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" (click)="saveCatalog(1)">{{catalogId>0?'Update And Continue':'Create And Continue'}}</a>
                          </div>
                    </div>
                  
                    <!-- <button class="btn btn-primary" (click)="onbtnClick()">SaveImages</button> -->
                </div>
            </section>
        </div>
    </main>

</div>