import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProductLine } from '../models/items';

@Injectable({
    providedIn: 'root'
})
export class ProductLineService extends BaseService {

    constructor(private http: HttpClient) { super() }

    get() {
        return this.http.get<ProductLine[]>(`${this.baseURL}/product_lines/`);
    }

    getById(id: number) {
        return this.http.get<ProductLine>(`${this.baseURL}/product_lines/${id}/`);
    }

    create(model: ProductLine) {
        return this.http.post<ProductLine>(`${this.baseURL}/product_lines/`, model);
    }

    update(id: number, model: ProductLine) {
        return this.http.put<ProductLine>(`${this.baseURL}/product_lines/${id}/`, model);
    }

    delete(id: number) {
        return this.http.delete(`${this.baseURL}/product_lines/${id}/`);
    }
}
