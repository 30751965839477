<form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && submit()" novalidate>
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center">
                <i class="ft-settings mr-1 align-middle"></i> <span class="d-none d-sm-block">General</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="media mb-3">
                            <img src="assets/img/gallery/user-avatar.jpg" alt="profile-img" class="rounded mr-3" height="64" width="64">
                            <div class="media-body">
                                <div class="col-12 d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-2">
                                    <label class="btn btn-sm bg-light-primary mb-sm-0" for="select-files">Upload
                                      Photo</label>
                                    <input disabled="true" type="file" id="select-files" hidden>
                                </div>
                                <p class="text-muted mb-0 mt-1 mt-sm-0">
                                    <small>Allowed JPG, GIF or PNG.</small>
                                </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="val-required">First Name</label>
                            <input type="text" [(ngModel)]="model.first_name" #firstname="ngModel" name="first_name" class="form-control" required>
                            <small class="form-text text-muted danger" *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)">
                              <div *ngIf="firstname.errors.required">
                                  Name is required.
                              </div>
                          </small>
                        </div>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" [(ngModel)]="model.last_name" name="last_name" class="form-control">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Email</label>
                            <div class="position-relative has-icon-left">
                                <input type="text" [(ngModel)]="model.email" name="email" class="form-control">
                                <div class="form-control-position">
                                    <i class="ft-mail"></i>
                                </div>
                            </div>
                            <small class="mt-1 text-danger" *ngIf="emailExist">Email already exists</small>
                        </div>
                        <div class="form-group">
                            <label>Location</label>
                            <distributor-location-select [distributorId]="distributorId" [(value)]="model.location" (itemAdded)="onLocationAdded()"></distributor-location-select>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="model.portal_access" name="portal_access" id="portalacc">
                                <label class="custom-control-label" for="portalacc">
                                  <span>Portal Access</span>
                              </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="model.send_welcome_email" name="send_welcome_email" id="welcomem">
                                <label class="custom-control-label" for="welcomem">
                                  <span>Send welcome email</span>
                              </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center">
                <i class="ft-info mr-1 align-middle"></i> <span class="d-none d-sm-block">Info</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" [(ngModel)]="model.title" name="title" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Phone</label>
                            <div class="position-relative has-icon-left">
                                <input type="text" [(ngModel)]="model.phone" mask="(000) 000-0000" name="phone" class="form-control">
                                <div class="form-control-position">
                                    <i class="ft-phone"></i>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Mobile</label>
                            <div class="position-relative has-icon-left">
                                <input type="text" [(ngModel)]="model.mobile" mask="(000) 000-0000" name="mobile" class="form-control">
                                <div class="form-control-position">
                                    <i class="ft-smartphone"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Department</label>
                            <input type="text" [(ngModel)]="model.department" name="department" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Extension</label>
                            <input type="text" [(ngModel)]="model.extension" name="extension" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>FAX</label>
                            <input type="text" [(ngModel)]="model.fax" name="fax" mask="(000) 000-0000" class="form-control">
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center">
                <i class="ft-twitter mr-1 align-middle"></i> <span class="d-none d-sm-block">Social Links</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Twtter</label>
                            <div class="position-relative has-icon-left">
                                <input type="text" [(ngModel)]="model.twitter" name="twitter" class="form-control">
                                <div class="form-control-position">
                                    <i class="ft-twitter"></i>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Facebook</label>
                            <div class="position-relative has-icon-left">
                                <input type="text" [(ngModel)]="model.facebook" name="facebook" class="form-control">
                                <div class="form-control-position">
                                    <i class="ft-facebook"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Instagram</label>
                            <div class="position-relative has-icon-left">
                                <input type="text" [(ngModel)]="model.instagram" name="instagram" class="form-control">
                                <div class="form-control-position">
                                    <i class="ft-instagram"></i>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>linkedin</label>
                            <div class="position-relative has-icon-left">
                                <input type="text" [(ngModel)]="model.linkedin" name="linkedin" class="form-control">
                                <div class="form-control-position">
                                    <i class="ft-linkedin"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center">
                <i class="ft-plus mr-1 align-middle"></i> <span class="d-none d-sm-block">Aditional
                  Fields</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <div class="row">
                    <div class="col-12 d-flex mb-1">
                        <button type="button" (click)="addCustomField()" class="btn btn-sm btn-primary">
                          <i class="ft-plus mr-1 align-middle"></i>Add Field
                      </button>
                    </div>
                    <div class="col-12 d-flex flex-column">
                        <div class="d-flex flex-row mt-1" *ngFor="let customField of additionalFields">
                            <input [(ngModel)]="customField.name" [name]="customField.id + '_key'" class="form-control width-40-per mr-2" type="text" placeholder="Enter the key" />
                            <input [(ngModel)]="customField.value" [name]="customField.id + '_val'" class="form-control" type="text" placeholder="Enter the value" />
                            <button (click)="deleteCustomField(customField.id)" class="btn text-danger" type="button">
                              <i class="ft-trash-2"></i>
                          </button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>
    <div class="pt-2 modal-min-height" [ngbNavOutlet]="nav"></div>
    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
        <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
          {{bindingModel ? 'Save Changes' : 'Add Contact'}}
      </button>
        <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
    </div>
</form>