import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { SubSink } from 'subsink';
import { GridConstants } from 'src/app/core/constants/grid.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ItemWrapper } from '../../../models/items';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from '../../../services/item.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, Input, DebugElement } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { Location } from '@angular/common';
import { GridViewInfo } from 'src/app/modules/grid-profiling/models/grid-profiling.models';
import { environment } from 'src/environments/environment';
import { ItemDataHelper } from '../../../helpers/item-data.helpers';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataImportMeta, UIPermission } from 'src/app/core/models/common.models';
import { CatalogueService } from '../../../services/catalogue-service.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CatalogueCatagoryResponse, CatalogueProductListResponse, Product } from '../../../models/catalogue.models';
import { CatalogCatDataHelper, CatalogueDataHelper } from '../../../helpers/catalogue-data.helpers';
import { filter } from 'lodash';
import { SweetAlertOptions } from 'sweetalert2';
import { CatalogueFunctionalService } from '../../../services/catalogue-functional.service';
import { ProductService } from '../../../services/product.service';
import { ProductDataHelper } from '../../../helpers/product-data.helpers';
// import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

export class CatalogProductSelectExtended{

    public currentStep:number=1;

    public rows: Product[] = [];

    public subs:SubSink=new SubSink();

    public pageTemplates:CatalogueProductListResponse[]=[];

    public fileBasePath=environment.apiURL+'/static/files/';

    public selectedPage:any;
    
    public Subs:SubSink=new SubSink();

    public noOfPageToAdd:number;

    public totalSelectedProductCount:number = 0;
    public totalPageCountToAdd : number = 0;

    public newlySelectedProducts:any = [] //to track newly added products in section
    public newlySelectedCategories:any = []  //to track newly added products in categories
    
    public selectedCategories:any =[]
    selectedSectionIndex : number

    public is_gridTemplate : boolean = false

    // public dataSource: MatTreeFlatDataSource<CatalogueProductListResponse, CatalogueProductListResponse>;

    constructor(private catalogService:CatalogueService,    public catalogueFnService: CatalogueFunctionalService){

  
    }

    next(){


        if(this.currentStep == 1)
        { 
            //console.log('selectedProducts',this.selectedProducts)
            
           

            if( this.catalogueFnService.catalogData[this.selectedSectionIndex])
            {
                this.totalSelectedProductCount =  this.catalogueFnService.catalogData[this.selectedSectionIndex].filter(x=>x?.is_delete != true ).length 
            }
  
            

          
            if(this.totalSelectedProductCount == 0)
            {
              
                let option: SweetAlertOptions = {
                    title: 'Alert',
                    text:'Please select products to add in catalog',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                    customClass: {
                      confirmButton: 'btn btn-success',
                      cancelButton: 'btn',
                      footer: 'flex-row-reverse'
                    },
                    buttonsStyling: false
                  };
                  popup.OpenConfirmBox(option, result => {
                    if (result.isConfirmed) {
                         return
                        }  

                      });
                      return
              }
              else
              {
                // this.getPageTemplates();
              }
            }
        

        if(this.currentStep == 2)//on page layout
        {

            if(this.selectedPage?.template)
            {
                
                let totalProductCountInPage: number = 0

                let gridTemplate = this.selectedPage.template?.objects.find(x => x.name == 'grid_Layout' )
                 
                if(gridTemplate)
                { 
                    this.is_gridTemplate = true   
                    gridTemplate.objects.forEach(element => { 

                        
                        var proTemplate = element.objects.find(x => x.name == 'productComponent')
                        if(proTemplate)
                        {
                            totalProductCountInPage ++
                        }
                        
                    }); 

                }

                else
                {
                    totalProductCountInPage = this.selectedPage.template.objects.filter(x => x.name == 'productComponent').length
                }
    
                this.totalPageCountToAdd =  Math.ceil(this.totalSelectedProductCount / totalProductCountInPage)   
            }
            else{

                  
                    let option: SweetAlertOptions = {
                        title: 'Alert',
                        text:'Please select product template to add in catalog',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Ok',
                        customClass: {
                          confirmButton: 'btn btn-success',
                          cancelButton: 'btn',
                          footer: 'flex-row-reverse'
                        },
                        buttonsStyling: false
                      };
                      popup.OpenConfirmBox(option, result => {
                        if (result.isConfirmed) {
                             return
                            }  
    
                          });
                          return
                  
            }

                  
        }

        if(this.currentStep<3){
            this.currentStep++;
        }
    }

    prev(){
        if(this.currentStep>1){
            this.currentStep--;
        }
    }

    getPageTemplates(){

        console.log('You are here');
        this.subs.sink=this.catalogService.getAlllCatalogueProducts().subscribe(
            response=>{

              this.pageTemplates=response.filter((val)=>{
                return val.type==2??val;
              });

              console.log('pageTemplates',this.pageTemplates)
      
              // console.log(this.productTemplates[0].test);
            }
          )
    }

    onPageSelect(page){

        
        this.selectedPage=page
    }
}

@Component({
    selector: 'catalogue-product-select',
    templateUrl: './catalogue-product-select.component.html',
    styleUrls: ['./catalogue-product-select.component.scss']
  })
export class CatalogueProductSelectComponent extends CatalogProductSelectExtended implements OnInit {

  @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

  @Output() cancelSelector=new EventEmitter<any>();

  @Input() value: number;


  @Output() valueChange = new EventEmitter<number>();


  @Output() selectedProductPageInfo = new EventEmitter<any>();


  @Input() selectBox: boolean = false;



  @ViewChild('productModal') itemsModal: any;



  public products:Product[];

  public productId:number;

  public SelectionType = SelectionType;

  public fileUrl: string = environment.apiURL + '/static/files/';



  public columns: GFColumn[]=CatalogCatDataHelper.columns;

  public defaults: GFilterParam[] = ProductDataHelper.defaults;

  public search: GFilterParam[];

  public selected: ItemWrapper;

  public expanded: boolean = true;


  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public grid: GridViewInfo;

  public isAdmin: boolean;

  public module = ModuleConstants.CATALOG;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;


  private modalRef: NgbModalRef;

  public page = { count: 0, limit: 10, offset: 0, pageNumber: '0-0' };

  public sortLookup = { "category_name": 'category_name', 'category_prefix': 'categoryprefix',
                     'parent_name': 'parentname','product_name' : 'product_name' };

  public sort: GSortParam[] = [{ colname: 'category_name', direction: 'asc' }];


  public isAllSuborg: boolean;

  public UIPermissions:UIPermission;

  public catalogCatagories:CatalogueCatagoryResponse[]=[];


  constructor(
     private modal:NgbModal,
      route: ActivatedRoute,
      private productService: ProductService,
      private toastr: ToastrService,
      private cdr: ChangeDetectorRef,
      private ra: ResourceAccessService,
      public location: Location,
      private device: DeviceDetectorService, 
      private catalogueService: CatalogueService,
      public catalogueFnService: CatalogueFunctionalService) {
        
     super(catalogueService,catalogueFnService);

  
      this.isAdmin = this.productService.isAdmin;
      this.subs.sink = route.params.subscribe(params => this.productId = +params.id || 0);
      this.grid = ra.getGridView(GridConstants.INV_ITEMS);
      this.initFilters();
      this.scrollbarH = this.device.isMobile();
      this.isAllSuborg = productService.isAllSuborg;

      this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_ITEMS, ResourceConstants.INV_MODULE);

      if (this.isAllSuborg) {
          this.columns.push({ displayName: 'Sub Org', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentValue: this.productService.customerId });
      }
  }

  initFilters() {
    
      this.columns = this.productService.getFilterColumns();
      this.search = this.productService.getSearchColumns();

      console.log('search', this.search)
  }

  ngOnInit(): void {
    
    
    this.getPageTemplates();
  
    
  }

  open() {

    //super.getPageTemplates(); 
    this.modalRef = this.modal.open(this.itemsModal, { size: 'xl', scrollable: true });

    
     if (this.isAdmin || this.grid) {

        // if(this.selectedCategories[this.selectedSectionIndex])
        // {
        //     this.filterProductListByCategory(this.selectedCategories[this.selectedSectionIndex])
        // }

       // else
       // {
            this.getData();
       // }
      
      }
  }
  getData(filters?: GFilterParam[], offset: number = 0) { //tp 0 is not filtered by category else filter by catagory

    //    console.log(new GFilterParam(), 'console filters');\\
    
  
    let isCategorySelected : boolean = false
    if(this.selectedCategories[this.selectedSectionIndex])
    {
            let Catfilters:GFilterParam[]=[];
            Catfilters = this.selectedCategories[this.selectedSectionIndex].map((x,i)=>{
                return({colname:"category",condition:"equalto",operator: i == 0 ? "AND":"OR",value:x.id})
            })
            if(Catfilters?.length > 0)
            {
                isCategorySelected = true
                if(filters)
                {
                    filters.forEach(x=>{
                        Catfilters.push(x)
                    })
                }
               filters = Catfilters
            }
            console.log('Filters', filters)
            console.log('Catfilters', Catfilters)
    }

      let option = { sort: this.sort, filters: filters };
      this.subs.sink = this.catalogueService.filterProducts(option, offset).subscribe(
          response => { 
            
        
            response.result = response.result.map((obj) => {
                obj.selected = isCategorySelected == false ? false : true;
                return obj;
            }) 

            if(this.catalogueFnService.catalogData[this.selectedSectionIndex] && isCategorySelected)
            {

                var res = response.result.filter(item1 => 
                    !this.catalogueFnService.catalogData[this.selectedSectionIndex].some(item2 => (item2.id === item1.id)))

                    if(res.length > 0)
                    {
                        res.forEach(x=>{
                            if(x.id)
                            {
                                this.catalogueFnService.catalogData[this.selectedSectionIndex].push(x)
                            }
                        })
                    }
            }
            else if(!this.catalogueFnService.catalogData[this.selectedSectionIndex] && isCategorySelected)
            {
                this.catalogueFnService.catalogData.splice(this.selectedSectionIndex)
                this.catalogueFnService.catalogData[this.selectedSectionIndex] = response.result
                
            }
            else if(this.catalogueFnService.catalogData[this.selectedSectionIndex] && !isCategorySelected) // is product already selected
            {
                response.result.forEach(x1 => {
                    this.catalogueFnService.catalogData[this.selectedSectionIndex].forEach(x2 => {
    
                        if(x1.id==x2.id) //if product already selected
                        {
                            if(x2?.is_delete != true)
                            {
                                x1.selected = true
                            }
                           
                        }});            
                });
            }

            console.log('selected pros',this.catalogueFnService.catalogData[this.selectedSectionIndex])
   
            this.rows =   response.result;

            console.log('response',response.result)
             
              this.setPagination(offset, response.count);
              this.cdr.markForCheck();
          },
          () => {
              this.rows = [];
              this.setPagination(offset, 0);
              this.cdr.markForCheck();
          }
      );
  }
  onChangeProductSelect(isSelected,rowData)
  {
    // alert(isSeleted)
    // console.log('rowData',rowData)
    // 

    
    if(isSelected)
    {
        if(this.catalogueFnService.catalogData[this.selectedSectionIndex]){

            this.catalogueFnService.catalogData[this.selectedSectionIndex].push(rowData) //= this.rows.filter(x=>x.selected == true)
        }
        else
        {
            this.catalogueFnService.catalogData.splice(this.selectedSectionIndex,0, this.rows.filter(x=>x.selected == true)) 
        }
    }     
    else
    {
        const index: number = this.catalogueFnService.catalogData[this.selectedSectionIndex].findIndex(x => x.id  == rowData.id);

        if (index !== -1 && rowData.id ) {

            this.catalogueFnService.catalogData[this.selectedSectionIndex].splice(index, 1);
        } 
        
        //this.selectedProducts.splice(this.selectedSectionIndex,0, this.rows.filter(x=>x.selected == true)) 
    }   
   // console.log('selectedProducts', this.catalogueFnService.catalogData[this.selectedSectionIndex])
  }
  filterProductListByCategory(event)
  {

    
        if(this.selectedCategories[this.selectedSectionIndex])
        {
            this.selectedCategories[this.selectedSectionIndex] = event
        }
        else
        {
            this.selectedCategories.splice(this.selectedSectionIndex,0, event)
        }

        if(this.catalogueFnService.catalogData[this.selectedSectionIndex])
        {
            this.catalogueFnService.catalogData[this.selectedSectionIndex] = []
        }



        this.getData();
        

  }


  onCategorySelect(id:number, dataFilter){

    let filters:GFilterParam[]=[];

    let dFilter=dataFilter.getDataFilters();
    console.log(dFilter, 'dFilter')

    if(dFilter&&dFilter.length>0){
        filters=dFilter;
    }


    let catFilter:GFilterParam=new GFilterParam();

    catFilter.colname="category";
    catFilter.condition="equalto";
    catFilter.operator="AND";
    catFilter.value=id;

    if(filters&&filters.length>0){
        filters.push(catFilter);
    }
    else{
        filters=[];
        filters.push(catFilter);
    }



    this.getData(filters);

  }
  
  onSort(event: any) {
      if (event.sorts && event.sorts.length > 0) {
          let current = event.sorts[0];
          if (!this.sortLookup[current.prop])
              return;
          let sort = new GSortParam();
          sort.colname = this.sortLookup[current.prop];
          sort.direction = current.dir;
          this.sort = [sort];
          this.getData(this.dataFilter?.getDataFilters() || []);
      }
  }

  refreshDataTable() {
      setTimeout(() => {
          this.rows = [...this.rows];
          this.cdr.markForCheck();
      });
  }

  setPagination(offset: number, total: number) {
      this.page.count = total;
      let upperLimit = offset + this.page.limit;
      if (upperLimit > total) {
          upperLimit = total;
      }
      this.page.pageNumber = offset + '-' + upperLimit;
  }

  onItemViewClose(): void {
      this.expanded = true;
      this.selected = null;
      this.productId = 0;
      this.location.go(`/inventory/items`);
      this.refreshDataTable();
  }

  hasPermission(resource: string, permission: string) {
      return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE);
  }

  setPage(pageInfo: any) {
      let offset = pageInfo.offset * this.page.limit;
      this.page.offset = pageInfo.offset;
      this.getData(this.dataFilter?.getDataFilters() || [], offset);
  }

  isProfiled(columName: string, admin_column = false) {

    var permission = this.ra.isColumnProfiled(this.grid, columName, admin_column);
      return this.ra.isColumnProfiled(this.grid, columName, admin_column);
  }

  applyFilter(params: GFilterParam[]) {
    
    // params =[
    //     {
    //         "colname": "itemname",
    //         "condition": "contains",
    //         "value": "42",
    //         "operator": "OR"
    //     },
    //     {
    //         "colname": "part_number",
    //         "condition": "contains",
    //         "value": "42",
    //         "operator": "OR"
    //     },
    //     {
    //         "colname": "sku",
    //         "condition": "contains",
    //         "value": "42",
    //         "operator": "OR"
    //     }
    // ]
      this.getData(params);
  }

//   onImportSubmit(request: any) {
//       this.subs.sink = this.itemService.importItems(request).subscribe(
//           resp => {
//               this.toastr.success('Import has started you will be emailed when it is finished.');
//               this.getData()
//           },
//           err => {
//               this.toastr.error('Import failed')
//           }
//       );
//   }

  getId(row) {    
    // console.log(row);
    return row.id;
  }


  onFinish() {

//   this.catalogueFnService.catalogData[this.selectedSectionIndex].forEach((element,i) => {
    
//     element.section = this.selectedSectionIndex + 1
 
//   });

    const data = {
                  isGridTemplate :this.is_gridTemplate, 
                  totalPageCountToAdd:this.totalPageCountToAdd,
                  selectedPageLayout:this.selectedPage,
                  selectedCategories:this.selectedCategories}

    this.selectedProductPageInfo.emit(data)
    this.modalRef?.dismiss();
  }

  onCanceled() {
    this.modalRef?.dismiss();
  }

  clearSelection(event: any) {
    event.stopPropagation();
    this.selected = null;
    this.valueChange?.emit(null);
   // this.selectedProducts = [];
  }

  ngOnDestroy(): void {
      this.subs?.unsubscribe();
  }

}
