import { Injectable } from '@angular/core';
import { ENETUNREACH } from 'constants';
import { generateKeyPairSync } from 'crypto';
import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { reject } from 'lodash';
import { promise } from 'protractor';
import { CommonService } from 'src/app/core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogueFunctionalService {

  public height;

  public customHeight;

  public limitedTextbox: any;

  public newLeft = 10;

  public newGroupLeft = 10

  public newGroupTop = 10

  public newRight = 10;

  public catalogData: any = [];

  public filterMode: boolean = false;

  constructor(private commonService: CommonService) {
    this.limitedTextbox = this.customTextBox();
  }


  findNewGroupLeft(canvas) {
    if ((this.newGroupLeft + 200 < canvas.width)) {
      this.newGroupLeft += 20;

      console.log('in true')
      return this.newGroupLeft;

    }
    else {
      console.log('in false')
      return this.newGroupLeft = canvas.width / 3;

    }
  }

  findNewGroupTop(canvas) {

    if ((this.newGroupTop + 50 < canvas.height)) {
      this.newGroupTop += 20;
      return this.newGroupTop;
    }
    else {
      return this.newGroupTop = canvas.height / 3;

    }

  }


  newElementLeft(canvas) {


    if ((this.newLeft + 200 < canvas.width)) {
      this.newLeft += 10;
      return this.newLeft;
    }
    else {
      return this.newLeft = canvas.width / 3;

    }


  }

  newElementTop(canvas) {

    if ((this.newRight + 50 < canvas.height)) {
      this.newRight += 10;
      return this.newRight;
    }
    else {
      return this.newRight = canvas.height / 3;

    }
  }




  customTextBox() {

    return fabric.util.createClass(fabric.Textbox, {
      drawCacheOnCanvas: function (ctx) {
        this.height = this.customHeight;
        // ctx.height=ctx.customHeight;
        this.callSuper('drawCacheOnCanvas', ctx);

      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);
      },
      _renderControls: function (ctx, styleOverrideopt) {
        this.callSuper('_renderControls', ctx);
      },
      initialize: function (text, options) {
        // options.customHeight=options.height;
        this.customHeight = this.height;
        this.callSuper('initialize', text, options);

      },
    });
  }


  addElementToGroup(item, canvas, group, newLeft1, newTop1, newScaleX1, newScaleY1) {

    let newLeft, newTop, newScaleX, newScaleY;

    if (newLeft1) {
      newLeft = newLeft1;
      newTop = newTop1;
    }
    else {
      newLeft = item.left + this.newGroupLeft;
      newTop = item.top + this.newGroupTop 
    }

    if (newScaleX1) {
      newScaleX = newScaleX1;
      newScaleY = newScaleY1;
    }
    else {
      newScaleX = item.scaleX;
      newScaleY = item.scaleY;
    }





    if (item.type == 'textbox') {

      var obj = new this.limitedTextbox(item.text, {
        width: item.width,
        // height:item.customHeight,
        left: newLeft,
        top: newTop,
        fontSize: item.fontSize,
        editable: true,
        name: item.name,
        fill: item.fill,
        customHeight: item.customHeight,
        lockScalingY: false,
        scaleY: newScaleY,
        scaleX: newScaleX,
        textAlign: item.textAlign
      });
      obj.set({ height: item.height });





      group.addWithUpdate(obj, { width: group.width, height: group.height, scaleX: item.scaleX, scaleY: group.scaleY });


    }
    else if (item.type == 'image') {



      var img1

      
      fabric.Image.fromURL(item.src, function (myImg) {


        img1 = myImg.set({ left: newLeft, top: newTop , crossOrigin: 'anonymous' });
        img1.scaleToHeight(item.height * newScaleY);
        img1.scaleToWidth(item.width * newScaleX);
        img1.editable = false;
        img1.name = item.name;

        group.addWithUpdate(img1, { width: group.width, height: group.height, scaleX: group.scaleX, scaleY: group.scaleY });

        console.log('group', group)
        canvas.renderAll();



      }
        .bind(this), {
        crossOrigin: 'anonymous',

      });
    
    }
   
    else if (item.type == 'rect') {
      var rect = new fabric.Rect({
        top: newTop,
        left: newLeft,
        width: item.width,
        height: item.height,
        scaleX: newScaleX,
        scaleY: newScaleY,
        fill: item.fill,
        rx: item.rx,
        ry: item.ry,
        opacity: item.opacity,
        shadow: item.shadow
      });

      group.addWithUpdate(rect, { width: group.width, height: group.height, scaleX: group.scaleX, scaleY: group.scaleY });

    }

    return canvas;

  }


  addElementGroup(canvas, componentName = '', 
  component, subElement = false, group = null, designType = 'page',left = 0,top = 0) {

    
    let objects, gup;
    let fillcolor

    if(designType != 'page') // to adjust top left only in page template design
    {
      
      this.newGroupLeft = 0
      this.newGroupTop = 0
      
    }
    if(left == 0) // if top left not passed
    {
      this.findNewGroupLeft(canvas)
      this.findNewGroupTop(canvas)
    }
    else
    {
      this.newGroupLeft = left;
      this.newGroupTop = top
    } 
    if (subElement) {

      objects = component.objects;
      fillcolor = '#ffffff'
    }
    else {
      objects = component.template.objects;
      fillcolor = component.template.background
    }

    // var fillcolor = componentName == 'button'? component.template.background : '#ffffff' 
    var rect1 = new fabric.Rect({
      top: 0,
      left: 0,
      width: component.width,
      height: component.height,
      fill: fillcolor,
      opacity: 1,
      name: 'rect1',
      // stroke: 'red',
      //  strokeWidth: 1
      //   shadow:new fabric.Shadow({
      //     color: '#999999',
      //     blur: 5
      // })
    });

    var rect2 = new fabric.Rect({
      top: 0,
      left: 0,
      width: 1,
      height: 1,
      opacity: 0,
      name: 'rect2',
      // stroke: 'blue',
      //strokeWidth: 1
    });

    var rect3 = new fabric.Rect({
      top: 0,
      left: 0,
      width: 1,
      height: 1,
      opacity: 0,
      name: 'rect3',
      // stroke: 'white',
      // strokeWidth: 1
    });

    group = new fabric.Group([rect1], {

      top: this.newGroupTop,
      left: this.newGroupLeft,
      width: component.width,
      height: component.height,
      name: componentName,
      fill: '#cccccc'
    });
    //group = group.removeWithUpdate([rect1,rect2]); //new
    //canvas.remove(rect2) //new
    canvas.add(group);
    
    
    // }

    let newLeft, newTop, newScaleX, newScaleY;


    objects.forEach((val, i) => {
      // canvas=this.canvas;

      let g
      if (val.type == 'group') {

        
        // 
        // //this.addElementGroup(canvas, val.name, val, true, group);

        var groupObj = val
        gup = new fabric.Group([rect2], {
          width: groupObj.width,
          height: groupObj.height,
          name: groupObj.name,
          fill: '#cccccc'
        });

        group.addWithUpdate(gup);

        var groupobjects = groupObj.objects;
        fillcolor = '#ffffff'
        groupobjects.forEach(object => {


          newLeft = object.type == 'rect' ? groupObj.left : (object.left + (groupObj.left) + ((groupObj.width) / 2))// - 5.835;
          newTop = object.type == 'rect' ? groupObj.top : (object.top + (groupObj.top) + ((groupObj.height) / 2))// - 17;

          g = gup;


          g.removeWithUpdate(rect2);
          g.removeWithUpdate(rect3);
          g.removeWithUpdate(rect1);


          this.addElementToGroup(object, canvas, g, newLeft + this.newGroupLeft, newTop + this.newGroupTop, newScaleX, newScaleY);


        });

        gup.scaleX = groupObj.scaleX;
        gup.scaleY = groupObj.scaleY;

        canvas.renderAll()
      }
      else {
        g = group;

        g.removeWithUpdate(rect2);
        g.removeWithUpdate(rect3);

        this.addElementToGroup(val, canvas, g, newLeft, newTop, newScaleX, newScaleY);
      }

    });
    canvas.setActiveObject(group);
    canvas.renderAll();
  }
  addNewAttribute(item,  canvas, selectable = true,left = 0,top = 0) {

    // let limitedTextBox=this.customTextBox();
    let newLeft;
    let newTop;
    if(left > 0)
    {
      newLeft = left;
      newTop = top;

    }
    else
    {
      newLeft = this.newElementLeft(canvas);
      newTop = this.newElementTop(canvas);
    }

    if (item == 'plaintext') {

      var obj = new this.limitedTextbox("Plain text", {
        width: 100,
        height: 50,
        left: newLeft,
        top: newTop,
        fontSize: 14,
        editable: true,
        name: item.name,
        customHeight: 15,
        scaleX: 1,
        lockScalingY: false,
        scaleY: 1,

      });

      obj.selectable = selectable;

      canvas.add(obj);
      canvas.renderAll();

      // this.updateCanvas2(this);

    }
    else if (item.type == 'text') {

      var obj = new this.limitedTextbox("<" + item.title + ">", {
        width: 100,
        height: 50,
        left: newLeft,
        top: newTop,
        fontSize: 14,
        editable: true,
        name: item.name,
        customHeight: 15,
        scaleX: 1,
        lockScalingY: false,
        // lockScalingX:true,
        scaleY: 1,

      });
      obj.selectable = selectable;




      canvas.add(obj);
      canvas.renderAll();
      // this.updateCanvas2(this);




    }
    else if (item.type == 'img' && item.name == "qr_code") {

      var newElementLeft = newLeft;
      var newElementRight = newTop;

      fabric.Image.fromURL('../../../../assets/img/label/dummy-qr.png', function (myImg) {
        var img1 = myImg.set({ left: newElementLeft, top: newElementRight, crossOrigin: 'anonymous' });
        img1.scaleToHeight(60);
        img1.scaleToWidth(60);
        img1.name = 'qr_code';
        img1.selectable = selectable;
        canvas.add(img1);
      } .bind(this), {
        crossOrigin: 'anonymous'
      });
      //  this.canvas=canvas;

    }
    else if (item.type == 'img' && item.name == 'item_image') {

      // var canvas=this.canvas;
      var newElementLeft = newLeft;
      var newElementRight = newTop;

      fabric.Image.fromURL('../../../../assets/img/catalogue/item-image-placeholder.jpeg', function (myImg) {
        var img1 = myImg.set({ left: newElementLeft, top: newElementRight, });
        img1.scaleToHeight(67.5);
        img1.scaleToWidth(100);
        img1.selectable = selectable;

        img1.name = 'item_image';
        canvas.add(img1);
      });
      //  this.canvas=canvas;

    }
    else if (item.type == 'button') {

      // var canvas=this.canvas;
      var newElementLeft = newLeft;
      var newElementRight = newTop;

      var button = new fabric.Rect({
        top: newElementRight,
        left: newElementLeft,
        width: 100,
        height: 25,
        fill: 'green',
        rx: 10,
        ry: 10,
        // stroke: 'black',
        strokeWidth: 1,
        shadow: new fabric.Shadow({
          color: '#999999',
          blur: 5
        })

      });


      var buttonText = new fabric.Textbox("Add to Cart", {
        width: 80,
        height: 50,
        left: newElementLeft + 15,
        top: newElementRight + 5,
        fill: '#fff',

        fontSize: 12,
        editable: false,
      });

      var group = new fabric.Group([button, buttonText]);

      group.selectable = selectable;

      canvas.add(group);
      // canvas.add(buttonText);
      // this.canvas=canvas;


    }
  }

  convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }


  preventLeaving(e) {
    var activeObject = e.target;



    if (activeObject) {


      let maxWidth = e.target.canvas.width;
      let maxHeight = e.target.canvas.height;
      //x-axis calculations
      if ((activeObject.width * activeObject.scaleX) > maxWidth) {
        let scaleX = 1;
        if (activeObject.width <= maxWidth) {
          scaleX = maxWidth / activeObject.width;
        }
        else {
          scaleX = 1 - (activeObject.width / maxWidth);
        }
        activeObject.set('scaleX', scaleX);
      }

      if ((activeObject.left < 0)) {
        activeObject.set('left', 0);
      }

      if ((activeObject.left + (activeObject.width * activeObject.scaleX)) > maxWidth) {
        var positionX = maxWidth - (activeObject.left + (activeObject.width * activeObject.scaleX));
        activeObject.set('left', activeObject.left + positionX);
      }


      if ((activeObject.top < 0)) {
        activeObject.set('top', 0);
      }

      if ((activeObject.height * activeObject.scaleY) > maxHeight) {
        let scaleY = 1;
        if (activeObject.height <= maxHeight) {
          scaleY = maxHeight / activeObject.height;
        }
        else {
          scaleY = Math.abs(maxHeight / activeObject.height);
        }
        activeObject.set('scaleY', scaleY);
      }


      if (activeObject.top + (activeObject.height * activeObject.scaleY) > maxHeight) {
        var positionY = maxHeight - (activeObject.top + (activeObject.height * activeObject.scaleY));
        activeObject.set('top', activeObject.top + positionY);
      }

    }




  }

  preventTextboxScaling(e, canvas) {

    // console.log(e);
    let lastHeight, lastWidth;

    if (e.target.type == "textbox" && e.transform.action == "scaleY") {


      if (e.transform.corner == "mb" || e.transform.corner == "mt") {
        lastHeight = e.target.customHeight * e.target.scaleY;
        lastWidth = e.target.width * e.target.scaleX;

        // console.log('scaleY:- last Height:'+lastHeight+', last Width:'+lastWidth+', width:'+e.target.width+', Height:'+e.target.height+', scaleY:'+e.target.scaleY+", scaleY:"+e.target.zoomY);
        e.target.set({
          height: lastHeight,
          customHeight: lastHeight,
          scaleY: 1,
        });
      }
      else {

      }

      // e.target.canvas.renderAll();




    }
    else if (e.target.type == "textbox" && e.transform.action == "scaleX") {



    }
    else if (e.target.type == "textbox" && e.transform.action == 'scale') {
      lastHeight = e.target.customHeight * e.target.scaleY;
      lastWidth = e.target.width * e.target.scaleX;


      e.target.set({
        width: lastWidth,
        customHeight: lastHeight,
        // height:lastHeight,
        scaleY: 1,
        scaleX: 1,
      });
      canvas.getActiveObject().height = lastHeight;

      //  canvas.renderAll();
    }
    else if (e.target.type == "textbox" && e.transform.action == "resizing") {

      // console.log("h:"+getHeight.height);

      lastHeight = e.target.customHeight * e.target.scaleY;
      lastWidth = e.target.width * e.target.scaleX;

      // console.log('Scale H:- last Height:'+lastHeight+', last Width:'+lastWidth+', width:'+e.target.width+', Height:'+e.target.height+', scaleY:'+e.target.scaleY+", scaleX:"+e.target.scaleX);


      e.target.set({
        // height:lastHeight,
        customHeight: lastHeight,
        // width:lastWidth,
        scaleY: 1,
        scaleX: 1,
      });
      canvas.getActiveObject().height = lastHeight;

    }

    // return e;
  }


  wrapCanvasText(t, canvas, element) {

    //  console.log("H is here"+element)

    let maxH = 0, maxW = 0;

    if (typeof maxH === "undefined") {
      maxH = 0;
    }
    else {
      maxH = element.height;
    }

    if (typeof maxW === "undefined") {
      maxW = 0;
    }
    else {
      maxW = element.width;
    }

    // var words = t.text.split(" ");
    var words = t.split(" ");
    var formatted = '';

    // clear newlines
    // var sansBreaks = t.text.replace(/(\r\n|\n|\r)/gm, "");  
    var sansBreaks = t.replace(/(\r\n|\n|\r)/gm, "");
    // calc line height
    // var lineHeight = new fabric.Text(sansBreaks, {
    //     fontFamily: t.fontFamily,
    //     fontSize: 25 //t.fontSize
    // }).height;

    var lineHeight = element.lineHeight;
    // var lineHeight=2;

    // adjust for vertical offset
    var maxHAdjusted = maxH > 0 ? maxH - lineHeight : 0;
    var context = canvas.getContext("2d");

    // console.log('maxh adjustes:'+maxHAdjusted);


    context.font = element.fontSize + "px " + element.fontFamily;
    var currentLine = "";
    var breakLineCount = 0;

    for (var n = 0; n < words.length; n++) {

      var isNewLine = currentLine == "";
      var testOverlap = currentLine + ' ' + words[n];

      // console.log('maxW:'+maxW+', maxH:'+maxH+' current Word:'+n+", testOverlap:"+w+", word:"+words[n]+", font:"+t.fontSize + "px " + t.fontFamily);


      // are we over width?
      var w = context.measureText(testOverlap).width;

      if (w < maxW) { // if not, keep adding words
        currentLine += words[n] + ' ';
        formatted += words[n] += ' ';
      } else {

        // if this hits, we got a word that need to be hypenated
        if (isNewLine) {
          var wordOverlap = "";

          // test word length until its over maxW
          for (var i = 0; i < words[n].length; ++i) {

            wordOverlap += words[n].charAt(i);
            var withHypeh = wordOverlap + "-";

            if (context.measureText(withHypeh).width >= maxW) {
              // add hyphen when splitting a word
              withHypeh = wordOverlap.substring(0, wordOverlap.length - 2) + "-";
              // update current word with remainder
              words[n] = words[n].substring(wordOverlap.length - 1, words[n].length);
              formatted += withHypeh; // add hypenated word
              break;
            }
          }
        }
        n--; // restart cycle
        formatted += '\n';
        breakLineCount++;
        currentLine = "";
      }

      var adjustedBreakLine = breakLineCount == 1 ? breakLineCount : breakLineCount + 1;

      var formattedTextboxHeight = (context.measureText(t).fontBoundingBoxAscent + context.measureText(t).fontBoundingBoxDescent) * (breakLineCount + 1) * lineHeight;

      // console.log('formattedTextboxHeight: '+formattedTextboxHeight);

      // console.log('line break count'+breakLineCount);

      if (breakLineCount > 0 && maxHAdjusted > 0 && formattedTextboxHeight > maxHAdjusted) {
        // add ... at the end indicating text was cutoff
        formatted = formatted.substring(0, formatted.length - 3) + "...\n";
        break;
      }
    }
    // get rid of empy newline at the end
    formatted = formatted.substring(0, formatted.length - 1);



    return formatted;
  }

  calculateZoom(width, subNavStatus) {

    let newZoom = 1;
    let loopCount = 0;

    // width=width*96;

    let screenWidth = window.innerWidth;
    // console.log(screenWidth);
    let usableWidth = screenWidth - 680;

    if (!subNavStatus) {
      usableWidth += 250;
    }

    // console.log(this.commonService.mainNavStatus);

    if (this.commonService.mainNavStatus && window.innerWidth >= 1200) {
      usableWidth += 190;
    }
    else if (window.innerWidth < 1200) {
      usableWidth += 250;
    }



    while (newZoom > .2) {

      let newWidth = newZoom * (width * 96);

      if (newWidth < (usableWidth - 100)) {
        newZoom = newZoom + 0.01;
      }
      else if (newWidth > usableWidth) {
        newZoom = newZoom - 0.01;
      }
      else {
        break;
      }

      if (loopCount > 150) {
        break;
      }

      loopCount++;

    }

    newZoom = Math.round(newZoom * 100) / 100;


    return newZoom


  }

  calculateZoomWithHeight(height, subNavStatus) {

    let newZoom = 1;
    let loopCount = 0;

    // width=width*96;

    let screenHeight = window.innerHeight;
    // console.log(screenWidth);
    let usableWidth = screenHeight - 300;





    while (newZoom > .2) {

      let newHeight = newZoom * (height * 96);

      if (newHeight < (usableWidth - 100)) {
        newZoom = newZoom + 0.01;
      }
      else if (newHeight > usableWidth) {
        newZoom = newZoom - 0.01;
      }
      else {
        break;
      }

      if (loopCount > 150) {
        break;
      }

      loopCount++;

    }

    newZoom = Math.round(newZoom * 100) / 100;


    return newZoom;


  }


  makeElementBold(canvas) {

    if (canvas.getActiveObject().fontWeight == 'bold') {
      canvas.getActiveObject().fontWeight = 'normal';
    }
    else {
      canvas.getActiveObject().fontWeight = 'bold'
    }

    // let text = this.wrapCanvasText(this.canvas.getActiveObject().text, this.canvas, this.canvas.getActiveObject());

    // this.canvas.getActiveObject().text=text;
    canvas.getActiveObject().dirty = true;

    canvas.renderAll();
  }



  makeElementItalic(canvas) {

    if (canvas.getActiveObject().fontStyle == 'italic') {
      canvas.getActiveObject().fontStyle = 'normal';
    }
    else {
      canvas.getActiveObject().fontStyle = 'italic';
    }
    canvas.getActiveObject().dirty = true;


    canvas.renderAll();
  }

  makeElementUnderline(canvas) {

    if (canvas.getActiveObject().underline) {
      canvas.getActiveObject().underline = false;
    }
    else {
      canvas.getActiveObject().underline = true;
    }
    canvas.getActiveObject().dirty = true;
    canvas.renderAll();
  }

  deleteElement(canvas) {

    canvas.remove(canvas.getActiveObject());

  }
  addFigure(figure, canvas) {
    let add: any;
    switch (figure) {
      case 'rectangle':
        add = new fabric.Rect({
          width: 25, height: 10, left: 10, top: 10, angle: 0,
          fill: '#3f51b5'
        });
        break;
      case 'square':
        add = new fabric.Rect({
          width: 15, height: 15, left: 10, top: 10, angle: 0,
          fill: '#4caf50'
        });
        break;
      case 'triangle':
        add = new fabric.Triangle({
          width: 15, height: 15, left: 10, top: 10, fill: '#2196f3'
        });
        break;
      case 'circle':
        add = new fabric.Circle({
          radius: 15, left: 15, top: 10, fill: '#ff5722'
        });
        break;
    }
    // this.extend(add, this.randomId());
    canvas.add(add);
    canvas.renderAll();
    //this.selectItemAfterAdded(add);
  }


  addGrid(canvas:any,col:number,row:number,width:number,height:number,fill:string) {
 
    // Define the table properties
    
    var column_width = (width * 96) / col//
    var cell_height = (height * 96) / row//

    const tableOptions = {

      left: 0,
      top: 0,
      columns: col, // Number of columns in the table
      columnWidth: column_width,//[column_width, column_width, column_width], // Width of each column
      cellHeight: cell_height,//30, // Height of each cell
    //  cellPadding: 5, // Padding inside each cell
      fill: fill, // Table background color
      stroke: 'blue', // Table border color
      strokeWidth: 1,
      strokeDashArray: [9, 2],
      rows: row

    };

    // Create the table-like structure
    const tableGroup = new fabric.Group([], {

      name:'grid_Layout',
      left: tableOptions.left,
      top: tableOptions.top,
    });



    // Create the cells in each row and column
    for (let row = 0; row < tableOptions.rows; row++) {
      for (let col = 0; col < tableOptions.columns; col++) {
        // Calculate the position of the current cell
        const cellLeft = col * tableOptions.columnWidth;
        const cellTop = row * tableOptions.cellHeight;

        // Create the cell object
        const cell = new fabric.Rect({
          left: cellLeft,
          top: cellTop,
          width: tableOptions.columnWidth,
          height: tableOptions.cellHeight,
          fill: tableOptions.fill,
          stroke: tableOptions.stroke,
          strokeWidth: 1,
          strokeDashArray: [9, 2],
          name:'cell_grp'+row+'_'+col
        });

        console.log('cell',cell)

        var lbltext1 = new fabric.Text('Select Product', {
          name:'lbl1',
          left: cell.left + cell.width / 2 - 80,
          top: cell.top + cell.height / 2 - 20 ,
          textAlign: 'center',
          fill: '#999999',
          fontSize: 30,
        });

        var lbltext2 = new fabric.Text('From Left', {
          name:'lbl2',
          left: cell.left + cell.width / 2 - 56,
          top: cell.top + cell.height / 2 + 20 ,
          textAlign: 'center',
          fill: '#999999',
          fontSize: 30,
        });

        var group = new fabric.Group([cell,lbltext1,lbltext2],{
           name:'cell_grp'+row+'_'+col
        })

        tableGroup.addWithUpdate(group);
      }

    
    }

    tableGroup.setControlsVisibility({
      mt: false, // Top
      mb: false, // Bottom
      ml: false,  // Left
      mr: false,  // Right
      tr: true,  // Top-Right
      tl: true,  // Top-Left
      br: true,  // Bottom-Right
      bl: true,  // Bottom-Left
    });

    canvas.add(tableGroup);
    canvas.renderAll();

  } 

  addImageElement(item:any,canvas:any,group:any,newLeft1,newTop1,newScaleX1,newScaleY1)
  {

    let newLeft, newTop, newScaleX, newScaleY;

    if (newLeft1) {
      newLeft = newLeft1;
      newTop = newTop1;
    }
    else {
      newLeft = item.left + this.newGroupLeft;
      newTop = item.top + this.newGroupTop 
    }

    if (newScaleX1) {
      newScaleX = newScaleX1;
      newScaleY = newScaleY1;
    }
    else {
      newScaleX = item.scaleX;
      newScaleY = item.scaleY;
    }
    return new Promise ((resolve, reject,) => {
    //  if (item.type == 'image') {
        var img1
  
        
        fabric.Image.fromURL(item.src, function (myImg) {
  
  
          img1 = myImg.set({ left: newLeft, top: newTop, crossOrigin: 'anonymous' });
          img1.scaleToHeight(item.height * newScaleY);
          img1.scaleToWidth(item.width * newScaleX);
          img1.editable = false;
          img1.name = item.name;
  
          group.addWithUpdate(img1, { width: group.width, height: group.height, scaleX: group.scaleX, scaleY: group.scaleY });
  
          // console.log('group', group)
           canvas.renderAll();
  
          resolve(group)
  
  
        }
          .bind(this), {
          crossOrigin: 'anonymous',
  
        });
      })
  }
  

  addGroupElementOnGridCell(canvas:any,template:any, cellrect:any)
  {
    
    let gup
    var objects =  template.component.template.objects

    var rect1 = new   fabric.Rect({
      top: 0,
      left: 0,
      width:  template.component.width, 
      height: template.component.height,
      fill: template.component.template.background,
      opacity: 1,
      name: 'rect1',
    });

    var group:any = new fabric.Group([rect1], {

      name: template.componentName,
      width: template.component.width, 
      height: template.component.height,
    });

    group.left =  cellrect.left //+ 10 //( cellrect.left + (cellrect.width - rect1.width) / 2)
    group.top =  cellrect.top// + 10 //(cellrect.top + (cellrect.height - rect1.height) / 2)


    return new Promise((resolve, reject) => {
      objects.forEach(item => {
      
        let newLeft, newTop, newScaleX, newScaleY;
        if(item.type == 'group')
        {
  
          var groupObj = item
  
          gup = new fabric.Group([], {
            width: groupObj.width,
            height: groupObj.height,
            name: groupObj.name,
            fill: '#cccccc'
          });
  
          group.addWithUpdate(gup);
  
          var groupobjects = groupObj.objects;
          groupobjects.forEach(object => {
  
  
            newLeft = object.type == 'rect' ? groupObj.left : (object.left + (groupObj.left) + ((groupObj.width) / 2))// - 5.835;
            newTop = object.type == 'rect' ? groupObj.top : (object.top + (groupObj.top) + ((groupObj.height) / 2))// - 17;
   
      
              this.addElementToGroup(object, canvas, gup, newLeft  + group.left , newTop  + group.top , newScaleX, newScaleY);
              canvas.renderAll() 
               
          })
          gup.scaleX = groupObj.scaleX;
          gup.scaleY = groupObj.scaleY;

          resolve(group)
        }
        else if (item.type == 'image') {
  
          let newLeft = item.left + group.left;
          let newTop = item.top + group.top;
  
          const promise = this.addImageElement(item,canvas,group,newLeft,newTop,newScaleX,newScaleY)
  
          promise.then((result:any) => { 
            
  
            resolve(group)
  
          }) 
        }
        else
        {
          let newLeft = item.left + group.left;
          let newTop = item.top + group.top;  
      
            this.addElementToGroup(item,canvas,group,newLeft,newTop,newScaleX,newScaleY)
            resolve(group)
            canvas.renderAll()      
        } 
  
      });

    })
   // adjust group scale
  
  }

  setProductOnGridCell(cellrect,prod_rect,prodGroup)
  {
    
    var rect1 = prod_rect
    var group = prodGroup 

    var cellrectGroup = new fabric.Group([cellrect], {

      name: 'cellrectGroup',
      width: cellrect.width, 
      height: cellrect.height,
    });

    let bounds = cellrectGroup.getBoundingRect();

    const scaleFactor = Math.min(
      Math.min(2, (bounds.width / (rect1.width + 20))), // /2 for middle
      Math.min(2, (bounds.height / (rect1.height + 20)))  
    );

    group.scale(scaleFactor);
    group.set({ 
      top: bounds.top + (Math.max(bounds.height - rect1.height * scaleFactor, 0) / 2),
      left: bounds.left + (Math.max(bounds.width - rect1.width * scaleFactor, 0) / 2),
   }); 
     return group
  }

  getProTemplatesFromGrid(gridTemplate) //get product template from grid with canvas top-left
  {

      var cellGroups : any = {}
      var table_group : any = {}

      var productTemplates : any = []

        table_group = gridTemplate
        cellGroups =  gridTemplate.objects

        var prod_component:any = {}
        var cell_left_incanvas =0
        var cell_top_incanvas =0

         cellGroups.forEach(cell_element => {

          
            prod_component = {...cell_element.objects.find(x=> x.name == 'productComponent')}


            var  cell_left_in_grid = cell_element.left < 0 ?  ((table_group.width/2)- Math.abs(cell_element.left)):
             ((table_group.width/2) + Math.abs(cell_element.left))

            var cell_top_in_grid =  cell_element.top < 0 ? ((table_group.height/2)- Math.abs(cell_element.top)) :
             ((table_group.height/2) + Math.abs(cell_element.top))

             cell_left_incanvas = cell_left_in_grid + table_group.left
             cell_top_incanvas = cell_top_in_grid + table_group.top

             prod_component.left = cell_left_incanvas + ((cell_element.width/2) - Math.abs(prod_component.left)) //((Math.abs(prod_component.left)) / 2) //+ ((cell_element.width/2) - (prod_component.width/2))
             prod_component.top = cell_top_incanvas + ((cell_element.height/2) - Math.abs(prod_component.top)) //+ ((cell_element.height/2) - (prod_component.height/2))


             productTemplates.push({...prod_component})
         });

        return productTemplates 
    }

  
}
