import { Component, ElementRef, HostListener, Renderer2, OnInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList, Input } from '@angular/core';
import { fabric } from 'fabric';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { LabelCreateRequest, LabelListResponse } from 'src/app/modules/inventory/models/label.models';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
// import { HttpParams } from '@angular/common/http';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { FilesService } from 'src/app/core/services/files.service';
import { ThumbNail } from 'src/app/core/models/files.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';
import { Catalog, CatalogueCatagoryProductResponse, CatalogueCatagoryResponse, CatalogueProductCreateRequest, Sections, SubNavSettings } from '../../../models/catalogue.models';
import { CatalogueService } from '../../../services/catalogue-service.service';
import { PageCanvasComponentComponent } from '../_page-canvas-component/page-canvas-component.component';
import { CatalogueFunctionalService } from '../../../services/catalogue-functional.service';
import { element } from 'protractor';

@Component({
  selector: 'app-design-catalog',
  templateUrl: './design-catalog.component.html',
  styleUrls: ['./design-catalog.component.scss']
})
export class DesignCatalogComponent implements OnInit {

  canvas: any;

  canvas2: any;

  @ViewChild('categorySelect') categorySelect;

  @ViewChildren('pageCanvas') pageCanvas;

  @ViewChildren('canvas1') childCanvas: QueryList<PageCanvasComponentComponent>;

  @ViewChild('productSelect') productSelect;

  public sideNavStatus: boolean = false;

  public catalogId: number = 0;

  public title: string = "";

  public editingMode: boolean = false;

  public category: number = 1;

  // public dimention:number=0;

  public width: number = 2;

  public height: number = 2;

  public subs: SubSink = new SubSink();

  private newLeft: number = 10;

  private newRight: number = 10;

  public availFields: any;

  public selectedDimention: any;

  public canvasZoom: number;

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public uploadedImgUrl: string = '';

  public LimitedTextbox: any;

  public pickedColor: string;

  public groupImageAddCount: number = 0;


  public catalogueType: string;

  public pageTitle: string;

  public cataloguePages = [];

  public catalogueCanvas = [];

  public selectedCanvas;

  public selectedCanvasIndex = 0;

  public catalogCatagoryProducts: CatalogueCatagoryProductResponse;


  public chooseProductView: boolean = false;


  public catalogueData: any = [];


  public catalogProductCount: any = [];
  public pageIndexStart: any = [];
  public productIndexStart: number = 0

  public subNavSettings = new SubNavSettings();

  public sections: Sections[] = [];
  public selectedSectionIndex: number = 0

  public previousSectionIndex: number = -1
  public previousPageIndex: number = -1
  public activePageIndexInSection: number = -1

  public activeCanvasModified: boolean = false

  public sectionPageImageList: any[] = [];

  public show_item_price: boolean = true // to specify show price on catalog on view

  public show_associated_tab: boolean = true
  // public associatedDataOnEdit:any = [];

  // public designSetting={
  //   page:{width:10.5, height:5.5, title:'Design catalogue page', typeId:2},
  //   product:{width:1, height:1.5, title:'Design a product', typeId:1},
  // }
  public categoryList = [
    { id: 0, name: 'Select value', },
    { id: 1, name: 'Item', },
    { id: 2, name: 'Built Assembly', },
    { id: 3, name: 'Assembly', }
  ];

  public dimentions = [
    { id: 0, name: 'Select value', },
    { id: 2, name: '4x2"', width: 4, height: 2, },
    { id: 3, name: '5x2"', width: 5, height: 2, },
    { id: 4, name: '6x2"', width: 6, height: 2, },
    { id: 5, name: '4x4"', width: 4, height: 4, },
    { id: -1, name: 'Custom', },
    // {id:2, name:'6x4"',}
  ];
  // @ChildView()

  public items = [
    { id: 1, title: 'Item Name', type: "text", name: "item_name" },
    { id: 2, title: 'QR Code', type: "img", name: "qr_code" },
    { id: 4, title: 'Warehouse', type: "text", name: "werehouse" },
    { id: 5, title: 'Warehouse location', type: "text", name: "werehouse_location" },
    { id: 6, title: 'Serial Number', type: "text", name: "serial_number" },
    { id: 7, title: 'SKU', type: "text", name: "sku" },
    { id: 8, title: 'Item Type', type: "text", name: "item_type" },
    { id: 9, title: 'Item Category', type: "text", name: "category" },
    { id: 10, title: 'Manufacturer', type: "text", name: "manufacturer" },
    { id: 11, title: 'Part number', type: "text", name: "part_number" },
    { id: 12, title: 'Sell Price', type: "text", name: "sell_price" },
    { id: 13, title: 'Supplier', type: "text", name: "supplier" },
    { id: 13, title: 'Item Image', type: "img", name: "item_image" },
    { id: 13, title: 'Button', type: "button", name: "button_primary" },
  ];


  public builtAssebly = [
    { id: 1, title: 'Built-assembly Name', type: "text", name: "assembly_name" },
    { id: 2, title: 'QR Code', type: "img", name: "qr_code" },
    { id: 4, title: 'Warehouse', type: "text", name: "werehouse" },
    { id: 5, title: 'Warehouse location', type: "text", name: "werehouse_location" },
    { id: 6, title: 'Serial Number', type: "text", name: "serial_number" },
    { id: 7, title: 'SKU', type: "text", name: "sku" },
    { id: 8, title: 'Assembly Type', type: "text", name: "item_type" },
    { id: 9, title: 'Assembly Category', type: "text", name: "category" },
    { id: 10, title: 'Manufacturer', type: "text", name: "manufacturer" },
    { id: 11, title: 'Part number', type: "text", name: "part_number" },
    { id: 12, title: 'Sell Price', type: "text", name: "sell_price" },
    { id: 13, title: 'Supplier', type: "text", name: "supplier" },
    { id: 13, title: 'Built Assembly Image', type: "img", name: "item_image" },

  ];

  public assebly = [
    { id: 1, title: 'Assembly Name', type: "text", name: "assembly_name" },
    { id: 2, title: 'QR Code', type: "img", name: "qr_code" },
    { id: 4, title: 'Warehouse', type: "text", name: "werehouse" },
    { id: 5, title: 'Warehouse location', type: "text", name: "werehouse_location" },
    { id: 6, title: 'Serial Number', type: "text", name: "serial_number" },
    { id: 7, title: 'SKU', type: "text", name: "sku" },
    { id: 8, title: 'Assembly Type', type: "text", name: "item_type" },
    { id: 9, title: 'Assembly Category', type: "text", name: "category" },
    { id: 10, title: 'Manufacturer', type: "text", name: "manufacturer" },
    { id: 11, title: 'Part number', type: "text", name: "part_number" },
    { id: 12, title: 'Sell Price', type: "text", name: "sell_price" },
    { id: 13, title: 'Supplier', type: "text", name: "supplier" },
    { id: 13, title: 'Assembly Image', type: "img", name: "item_image" },

  ];

  public dummyData = {
    item_name: "ASUS TUF Gaming NVIDIA GeForce GTX 1650 OC Edition Graphics Card (PCIe 3.0, 4GB GDDR6 Memory, HDMI, DisplayPort, DVI-D, 1x 6-pin Power Connector, IP5X Dust Resistance, Space-Grade Lubricant)",
    assembly_name: "ASUS TUF Gaming NVIDIA GeForce GTX 1650 OC Edition Graphics Card (PCIe 3.0, 4GB GDDR6 Memory, HDMI, DisplayPort, DVI-D, 1x 6-pin Power Connector, IP5X Dust Resistance, Space-Grade Lubricant)",
    werehouse: "Bonadelle Ranchos-Madera Ranchos, California",
    werehouse_location: "Build Alpha, second floor, thired block, fourth rack",
    serial_number: '8ASD9F8S',
    sku: 'AS8DFSADF98ASDF',
    item_type: 'Assembly',
    category: 'Manufacturing Products',
    part_number: '8A9SDF8AS9DF',
    sell_price: '23823923',
    supplier: 'KINGS GROUP SUPPLIERS OF UNITED STATES',
    item_image_url: '../../../../assets/img/catalogue/product-image.jpg'
  }

  public isUpdate: boolean = false

  public isSaveAndContinue: boolean = false

  public selectedProductCategories: any = []

  public productListbyPage: any = []
  public productListbyPageRows: any = []
  public maxProCountInPage:number = 0

  public canvasWidthInPixel :any  // to pass assoc_itemtab
  public canvasHeightInPixel :any  // to pass assoc_itemtab

  public dragObject : any

  constructor(private renderer: Renderer2,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private catalogueService: CatalogueService,
    private fileService: FilesService,
    private currencyPipe: CurrencyPipe,
    public catalogueFnService: CatalogueFunctionalService
  ) {



    this.subs.sink = this.activeRoute.params.subscribe(
      params => {
        this.catalogId = +params["id"] || 0;
      }
    );


    this.subs.sink = this.activeRoute.queryParams.subscribe(
      params => {
        this.title = params.title || null;
        this.category = params.category || null;
        // this.dimention=params.dimention||null;
        this.width = params.width || null;
        this.height = params.height || null;
        this.editingMode = params.editing || null;
        this.catalogueType = params.type || null;

        // if(this.catalogueType=="product"){
        //   this.width=1;
        //   this.height=1.5;

        // }
        // else if(this.catalogueType=="page"){

        //   this.width=10.5;
        //   this.height=5.5;

        // }


        // console.log(this.catalogueType);
      }
    );


    this.subNavSettings.pages = false;
    this.subNavSettings.products = false;
    this.subNavSettings.images = true;
    this.subNavSettings.addProducts = true;
    this.subNavSettings.text = true;

  }


  ngOnInit(): void {

    this.catalogueFnService.catalogData = []

    this.width = 8.5;
    this.height = 11;


    if (this.catalogId > 0) {
      this.edit(this.catalogId);
      this.isUpdate = true
    }
    this.categoryList = [...this.categoryList];
    this.category = 1;

    if (this.category == 2) {
      this.availFields = this.assebly;
    }
    else {
      this.availFields = this.items;
    }
    this.sections.push({ title: '', pages: [] });
    this.sections[0].pages.push([]);

    // this.cataloguePages.push([]);
    this.catalogProductCount[0] = 0;
    this.pageIndexStart[0] = 0;

    let newZoom = this.catalogueFnService.calculateZoomWithHeight(this.height, this.sideNavStatus);
    this.zoomChanged(newZoom * 100);

    // this.getCategoryProducts();
  }

  getProductCount(secIndex) {

    return this.catalogueFnService.catalogData[secIndex] ? this.catalogueFnService.catalogData[secIndex]
      .filter(x => x?.is_delete != true).length : 0
  }

  ngAfterViewInit() {

    this.catalogueFnService.calculateZoomWithHeight(this.height, this.sideNavStatus);

    // this.setEventsonCanvas()

    setTimeout(() => {
      this.canvasInit();

    }, 9);

  }
  canvasInit() {
    this.setEventsonCanvas(this.selectedCanvasIndex)
  }

  onColorChange(event) {
    this.activeCanvasModified = true
  }
  setEventsonCanvas(canvasindex) {

    setTimeout(() => {

      this.childCanvas.toArray()[canvasindex].canvas.on('object:added', (event: fabric.IEvent,) => {
        this.activeCanvasModified = true
      });
      this.childCanvas.toArray()[canvasindex].canvas.on('object:removed', (event: fabric.IEvent,) => {
        this.activeCanvasModified = true
      });
      this.childCanvas.toArray()[canvasindex].canvas.on('object:modified', (event: fabric.IEvent,) => {
        this.activeCanvasModified = true
      });

      this.childCanvas.toArray()[canvasindex].canvas.on('mouse:over', (event: fabric.IEvent,) => {
   
        const target = this.childCanvas.toArray()[canvasindex].canvas.findTarget(event.e);

        if (target && target?.type == 'group' && target?.name == "productComponent") {
          target.selectable = false
        }

     
      });

      this.childCanvas.toArray()[canvasindex].canvas.on('drop', (evn: any) => {

        this.activeCanvasModified = true
        const sizeof100 = (this.width * 96 * 100) / 100
        let newLeft = (evn.e.offsetX * (sizeof100 / this.childCanvas.toArray()[canvasindex].canvas.width))
        let newTop = (evn.e.offsetY * (sizeof100 / this.childCanvas.toArray()[canvasindex].canvas.width))

        if(this.dragObject?.type == 'image')
        {
          this.childCanvas.toArray()[canvasindex].addImageToCanvasOnDrag(evn)
        }
        if(this.dragObject?.type == 'text')
        {
          
          this.childCanvas.toArray()[canvasindex].addBigText(this.dragObject.val?.fontSize, this.dragObject.val?.text,newLeft,newTop)
        }
        if(this.dragObject?.type == 'plaintext')
        {
          this.addElement('plaintext',newLeft,newTop)
        }

      });

    }, 100);
  }
  initTextBox() {

    this.LimitedTextbox = fabric.util.createClass(fabric.Textbox, {
      drawCacheOnCanvas: function (ctx) {
        this.height = this.customHeight;
        this.callSuper('drawCacheOnCanvas', ctx);

      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);
      },
      _renderControls: function (ctx, styleOverrideopt) {
        this.callSuper('_renderControls', ctx);
      },
      initialize: function (text, options) {
        this.customHeight = this.height;
        this.callSuper('initialize', text, options);

      },
    });
  }

  addElement(item,left=0,top=0) {
    this.childCanvas.toArray()[this.selectedCanvasIndex].addCanvasElement(item,left,top);
  }

  addElementGroup(item) {

    this.childCanvas.toArray()[this.selectedCanvasIndex].addElementGroup(item);

  }
  addPageToCanvas(json, index = this.selectedCanvasIndex, prodIndex = 0, data: any = []) {

    
    
      if (this.childCanvas.toArray()[index]) {
        this.childCanvas.toArray()[index].addPageToCanvas(json, this.selectedSectionIndex, prodIndex, data)
        // .then(function(data){
        // }.bind(this));
        this.setEventsonCanvas(index)
      }
      else {
        console.log('Looks like that index is not there');
      }
  }

  addNewPage(sectionIndex, positionIndex = this.sections[sectionIndex].pages.length - 1) {

    let pageCount = this.sections[sectionIndex].pages.length;
    let newPosition = positionIndex + 1;

    if (this.sections[sectionIndex] && positionIndex >= 0 && positionIndex <= this.sections[sectionIndex].pages.length) {


      if (this.sections[sectionIndex].pages.length == positionIndex) {
        this.sections[sectionIndex].pages.push([]);
      }
      else {
        let lastPage = this.sections[sectionIndex].pages[pageCount - 1];
        this.sections[sectionIndex].pages.push(lastPage);

        for (let i = pageCount - 1; i > newPosition; i--) {

          this.sections[sectionIndex].pages[i] = this.sections[sectionIndex].pages[i - 1]

        }
        this.sections[sectionIndex].pages[newPosition] = { 'title': '', pages: [] };
      }

      // this.cataloguePages.push([]);
      this.catalogProductCount.push(0);
      this.pageIndexStart.push(0);
      this.selectedCanvasIndex = this.cataloguePages.length - 1;
      // this.catalogueData=[];



      this.findPageIndex();
      this.cdr.markForCheck();



    }
  }
  findFirstPageIndexInSection(secIndx)
  {
    const firstPageIndexInSection = this.sections.slice(0, secIndx).reduce((accumulator, currentValue) => {

      return accumulator += currentValue.pages.length
    }, 0)

    return firstPageIndexInSection
  }

  deletePage(sectionIndex, pageIndex) {

    this.selectedSectionIndex = sectionIndex
    
    if (this.catalogueFnService.catalogData.length > 0) {
    
      const firstPageIndexInSection = this.findFirstPageIndexInSection(sectionIndex)

      let selectedPageIndexInCanvasArray = firstPageIndexInSection + pageIndex

      let totlProdCountInSelPage = 0

      this.childCanvas.toArray()[selectedPageIndexInCanvasArray].canvas.getObjects().forEach(element => {

        if (element.type == 'group' && element.name == 'productComponent') {
          totlProdCountInSelPage++
        }
      });

      if (totlProdCountInSelPage > 0) {
        let totlProdCountBfrSelePage = 0; //find out count product on before selected page

        for (let i = firstPageIndexInSection; i < selectedPageIndexInCanvasArray; i++) {

          this.childCanvas.toArray()[i].canvas.getObjects().forEach(element => {

            if (element.type == 'group' && element.name == 'productComponent') {
              totlProdCountBfrSelePage++
            }
          });
        }
        let removeindxFrom = totlProdCountBfrSelePage == 0 ? 0 : totlProdCountBfrSelePage
        let removeindxTo = (removeindxFrom + totlProdCountInSelPage) - 1

        if (this.isUpdate) {
          for (let i = removeindxFrom; i <= removeindxTo; i++) {


            if (this.catalogueFnService.catalogData[this.selectedSectionIndex][i]?.assoc_id) {
              this.catalogueFnService.catalogData[this.selectedSectionIndex][i].is_delete = true
              this.catalogueFnService.catalogData[this.selectedSectionIndex][i].selected = false
            }
            else {
              this.catalogueFnService.catalogData[this.selectedSectionIndex].splice(i, 1)
            }
          }
        }
        else {
          this.catalogueFnService.catalogData[this.selectedSectionIndex].splice(removeindxFrom, removeindxTo - removeindxFrom + 1)
        }
        console.log('assoc after delete', this.catalogueFnService.catalogData)

      }
    }

    this.sections[sectionIndex].pages.splice(pageIndex, 1);
    this.productListbyPage[sectionIndex].splice(pageIndex, 1);

    if (this.sections[sectionIndex].pages.length == 0) {
      this.sections.splice(sectionIndex, 1)
    }
  }

  deleteProduct(event) // delete product from assoctab
  {
    
    if (this.catalogueFnService.catalogData[event.secIndx][event.productIndx]?.assoc_id) {
      this.catalogueFnService.catalogData[event.secIndx][event.productIndx].is_delete = true
    }
    else {
      this.catalogueFnService.catalogData[event.secIndx].splice(event.productIndx, 1)
    }

    this.productListbyPage[event.secIndx][event.pageIndx].splice(event.productIndxByPage)

    
    var proTemplates = this.childCanvas.toArray()[event.pageIndxInCanvasArray].canvas.getObjects().filter
                        (x => x?.type == 'group' && x?.name == 'productComponent' && x?.visible == true )



    proTemplates[event.productIndxByPage].visible = false
    //this.childCanvas.toArray()[event.pageIndxInCanvasArray].canvas.remove(proTemplates[event.productIndxByPage])
    this.childCanvas.toArray()[event.pageIndxInCanvasArray].canvas.renderAll()

    if(proTemplates.length == 1) // if no products after remove
    {
      this.productListbyPage[event.secIndx].splice(event.pageIndx,1)
      this.sections[event.secIndx].pages.splice(event.pageIndx,1)
    }


  }

  addBigText(value) {

    this.childCanvas.toArray()[this.selectedCanvasIndex].addBigText(value.fontSize, value.text);

  }

  selectCanvas(index) {

    this.selectedCanvas = this.catalogueCanvas[index];
    this.selectedCanvasIndex = index;

  }
  updateTextAlignment(align) {
    if (this.canvas.getActiveObject().textAlign != align) {
      this.canvas.getActiveObject().textAlign = align;
      this.canvas.renderAll();
    }
  }

  addSection() {

    this.sections.push({ title: '', pages: [] });
    let length = this.sections.length - 1;
    this.sections[length].pages.push([]);

  }

  checkSectionTitle() {
    let isValid: boolean = true
    this.sections.forEach((x, i) => {
      if (x.title == '') {
        isValid = false;
      }

    })
    return isValid
  }
  saveCatalog(tp = 0) { //tp = 0 for create tp = 1 for create and continue

    if (tp == 1) {
      this.isSaveAndContinue = true
    }

    if (this.title == '' || !this.title) {
      this.toastr.error('Please enter the a title for catalog design');
      return

    }
    else if (this.checkSectionTitle() == false) {
      this.toastr.error('Please enter the missing section titles');
      return
    }
    else {

      this.checkPageTemplatesBeforeSave()//to remove proTemplates that not visible

      let canvasArrayIndx: number = 0 //Canvas Array Index
      this.sections.forEach((secelement, secIndex) => {

        if (!this.sectionPageImageList[secIndex]) {
          this.sectionPageImageList.splice(secIndex, 0)
          this.sectionPageImageList[secIndex] = []
        }

        this.sectionPageImageList[secIndex] = { sectionName: '', images: [], svg: [] }

       this.sectionPageImageList[secIndex].sectionName = this.sections[secIndex].title
        secelement.pages.forEach((pageelmnt, sectionpageindex) => {

          
          var svgImg = this.childCanvas.toArray()[canvasArrayIndx].canvas.toSVG()
          this.sectionPageImageList[secIndex].svg.push(svgImg)
          this.sectionPageImageList[secIndex].images.push('')
          canvasArrayIndx++


         })

       })
     this.save()
   }
  }
  save() {

  
    let data = new ThumbNail();

    data.file = this.convertBase64ToBlob(this.childCanvas.toArray()[0].canvas.toDataURL('image/png'));
    data.name = this.title;
    let module_name = 'CATALOG';

    this.fileService.uploadFiles([data], module_name).subscribe(
      response => {
        this.uploadedImgUrl = response[0].id;
        this.saveData(response[0].id);
      },
      () => {
        return '';
      });

  }

  saveData(imageUrl: string, tp = 0) {



    let templates = [];

    let product = new Catalog();

    product.image = imageUrl;

    //To persis additional fields added in the canvas object
    const originalToObject = fabric.Object.prototype.toObject;
    const myAdditional = ['name', 'customHeight'];
    fabric.Object.prototype.toObject = function (additionalProperties) {
      return originalToObject.call(this, myAdditional.concat(additionalProperties));
    }
  

    let count = 0

    let template: any[] = [];
    this.sections.forEach((section, sectionIndex) => {
      template.push({ title: section.title, pages: [] });

      section.pages.forEach((page, pageIndex) => {

        if (!template[sectionIndex].pages[pageIndex]) {
          template[sectionIndex].pages.push([]);
        }
        template[sectionIndex].pages[pageIndex] = this.childCanvas.toArray()[count].canvas.toJSON();

        count++;
      })
    });

    console.log('template',template)
    
 

    product.category = 3;

    product.template = JSON.stringify(template);

    product.name = this.title;

    product.customer = this.catalogueService.customerId;

    product.width = this.width * 96;
    product.height = this.height * 96;

    product.suborg = this.catalogueService.suborgId;

    product.no_of_pages = this.childCanvas.toArray().length;

    product.no_of_products = this.catalogueFnService.catalogData.flat().length

    product.section_info = this.sectionPageImageList;

    product.show_price = this.show_item_price

    product.full_check_category = this.getSelectedProductCategories()

    let api: any = this.catalogId > 0
      ? this.catalogueService.updateCatalog(this.catalogId, product)
      : this.catalogueService.createCatalog(product);

    this.subs.sink = api.subscribe(
      response => {

        if (this.catalogueFnService.catalogData.flat().length > 0) {
          this.saveAssociatedProducts(response.id);
          this.activeCanvasModified = false
        }
        else {
          this.activeCanvasModified = false
          this.toastr.success("Catalog saved successfully");

          if (!this.isSaveAndContinue) {
            this.router.navigate(['/inventory/catalog/list'])
          }
          else// if save and continue
          {
            this.isSaveAndContinue = false
            this.router.navigate(['/inventory/catalog/' + response.id + '/edit'])

          }

        }
      }
    )

  }

  getSelectedProductCategories()
  {
    var proCategories:any=[];

  this.selectedProductCategories.forEach((element,indx)=>{
    
    
    if (!proCategories[indx]) {
          proCategories.splice(indx, 0)
          proCategories[indx] = []
        }

        proCategories[indx] = { 'section' : indx + 1, 'category': [] }

        element.forEach(catelement => {
          proCategories[indx].category.push(catelement.id)
        });
  

    })

    console.log('categories', proCategories)
    return proCategories
}

  saveAssociatedProducts(id) {

    this.setAssocItemsOrderValuesToSave();

    const body: any = {
      "associated_data": this.catalogueFnService.catalogData.flat().map((x) => {

        let action
        if (x?.is_delete) {
          if (x.is_delete == true) {
            x.action = 3
          }
        }
        else if (this.isUpdate) {
          x.action = x?.assoc_id ? 2 : 1
        }
        else { // if new

          x.action = 1
        }

        if (this.isUpdate) {
          return {
            "id": x?.assoc_id ? x.assoc_id : null, "unique_id": x.id,
            "section": x.section, "action": x.action, "order_number": x.order_number, "page_number": x.page_number,
            'section_page_index': x.section_page_index
          };
        }
        else {
          return {
            "unique_id": x.id, "section": x.section, "action": x.action, "order_number": x.order_number, "page_number": x.page_number,
            'section_page_index': x.section_page_index
          };
        }

      })
    }

    console.log('associated body', body)

    this.subs.sink = this.catalogueService.addAssociatedProducts(id, body).subscribe(res => {



      if (res.message == 'success') {
        this.toastr.success("Catalog saved successfully");
        if (!this.isSaveAndContinue) {
          this.router.navigate(['/inventory/catalog/list']);
        }
        else {
          this.isSaveAndContinue = false
          this.router.navigate(['/inventory/catalog/' + id + '/edit'])
        }
        this.catalogueFnService.catalogData = []
      }


    })
  }

  setAssocItemsOrderValuesToSave() // for setting page orderno,page no section no
  {
    let childCanvasArrayNo: number = 0

    let product_index: number = 0
    let sectionPageIndex: number = 0

    this.sections.forEach((secelmnt, secindx) => {

      let orderNo = 0
      product_index = 0
      sectionPageIndex = 0 // to save product section page

      secelmnt.pages.forEach((pgelmnt, pgindx) => {

        let countToUpdate: number = this.childCanvas.toArray()[childCanvasArrayNo + pgindx].canvas.getObjects().filter
          (x => x?.type == 'group' && x?.name == 'productComponent' && x?.visible == true).length

        let updatedCount: number = 0

        if (countToUpdate == 0) {
          sectionPageIndex = childCanvasArrayNo + pgindx
        }
        else if (countToUpdate > 0) {

          if (this.catalogueFnService.catalogData[secindx][product_index]) {
            for (let i = 0; i < this.catalogueFnService.catalogData[secindx].length; i++) {

              var product_data: any = this.catalogueFnService.catalogData[secindx][product_index]
              if (product_data.is_delete && product_data.is_delete == true) {
                product_index++  //for skipping deleted elements
              }
              else {

                orderNo++;

                this.catalogueFnService.catalogData[secindx][product_index].section = secindx + 1
                this.catalogueFnService.catalogData[secindx][product_index].page_number = pgindx + 1
                this.catalogueFnService.catalogData[secindx][product_index].order_number = orderNo
                this.catalogueFnService.catalogData[secindx][product_index].section_page_index = sectionPageIndex

                product_index++;
                updatedCount++

                if (countToUpdate == updatedCount) {
                  break;
                }
              }
            }
          }

        }
      })
      childCanvasArrayNo += secelmnt.pages.length
    })
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }
  edit(id: number) {

    var label: LabelListResponse;
    this.productIndexStart = 0
    this.productListbyPage = []
    this.productListbyPageRows = []

    this.subs.sink = this.catalogueService.getCatalogById(id).subscribe(
      response => {

        this.title = response.data.name;
        this.show_item_price = response.data.show_price

        //  this.category=label.type=='item'?1:2;
        //  this.dimention=2;
        //  this.editingMode=true;
        if (response.data.template != null) {


          this.sections = [];

          let templateData = JSON.parse(response.data.template);

          console.log('templateData', templateData)

          let associated_pros = response.associated_products;
  
          let selectedProCategories = response.full_check_category
  

          this.selectedProductCategories = selectedProCategories.map((x,i)=>
          {
            return x.category.map((c)=>{
             return  {'id': c}
            })
          })

          console.log('selectedProductCategories', this.selectedProductCategories)
          templateData.forEach((element, index) => {

            this.sections.push({ title: element.title, pages: element.pages });
            this.setEmpty_ProductListbyPageIndex(element.pages.length,index)
          });

          associated_pros.forEach(element => {

            if (!this.catalogueFnService.catalogData[element.section - 1]) {
              this.catalogueFnService.catalogData.splice(element.section - 1, 0)
              this.catalogueFnService.catalogData[element.section - 1] = []      
            }
           
            this.productListbyPage[element.section - 1][element.page_number - 1].push(element)

            this.catalogueFnService.catalogData[element.section - 1].push(element)
          });

          this.productListbyPageRows = this.productListbyPage

          console.log('productListbyPage',this.productListbyPage)

          console.log('sections',this.sections)

          this.updateCanvasTemplates()          
        }
    
        this.cdr.markForCheck();
      }
    )

  }

  updateCanvasTemplates()
  {

    setTimeout(() => {

      this.sections.forEach((sec, secIndex) => {

       // var data = this.catalogueFnService.catalogData[secIndex]

       //this.productIndexStart = 0
        sec.pages.forEach((val, i) => {
          let c = this.findCanvasIndex(secIndex, i);
          let ccLendth = this.childCanvas.toArray().length;
          setTimeout(() => {
            if (this.childCanvas.toArray()[c]) {

              
              // this.childCanvas.toArray()[c].addPageToCanvas(val, secIndex, this.pageIndexStart,  data);
              this.childCanvas.toArray()[c].addPageToCanvas(val, secIndex, this.productIndexStart,  this.catalogueFnService?.catalogData.flat());       
              this.productIndexStart += Number(val.objects.filter((x) => x.name == 'productComponent').length)
              
              this.setEventsonCanvas(c)
            }
        
          }, 10);


        });
      });
     
    }, 11);

  }


  @HostListener('window:resize', ['$event'])
  updateZoom() {
    let newZoom = this.catalogueFnService.calculateZoomWithHeight(this.height, this.sideNavStatus);
    this.zoomChanged(newZoom * 100);
  }

  zoomChanged(newValue: number) {

    let newZoom;
    setTimeout(() => {

      if (this.childCanvas && this.childCanvas.toArray().length) {

        this.childCanvas.toArray().forEach((val) => {
          val?.canvas?.setZoom(newValue / 100);
          // val.canvas.set({width:val.canvas.getZoom()*val.canvas.width,height:val.canvas.height*val.canvas.getZoom()});

          newZoom = val?.canvas?.getZoom();
          val?.canvas?.setWidth(this.width * 96 * newZoom);
          val?.canvas?.setHeight(this.height * 96 * newZoom);

        });

        this.canvasWidthInPixel = this.width * 96 * newZoom
        this.canvasHeightInPixel = this.height * 96 * newZoom

        this.canvasZoom = Math.round(this.childCanvas.toArray()[0].canvas?.getZoom() * 100);

      }

    }, 20);
  }





  changeDimention() {
    
    this.canvas.setWidth(this.width * 96 * this.catalogueCanvas[this.selectedCanvasIndex].getZoom());
    this.canvas.setHeight(this.height * 96 * this.catalogueCanvas[this.selectedCanvasIndex].getZoom());

    this.canvas2.setWidth(this.width * 96 * this.canvas2.getZoom());
    this.canvas2.setHeight(this.height * 96 * this.canvas2.getZoom());

    this.canvasZoom = Math.round(this.canvas.getZoom() * 100);
  }

  canvas2UpdateValue(element, aThis) {

    if (element.type == 'textbox' || element.type == 'i-text') {

      var canvas = aThis.canvas2;
      var formatted1;

      // var currencyPipe=this.currencyPipe;

      // console.log(element.name);
      if (element.name == 'item_name' || element.name == 'assembly_name') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.item_name, canvas, element);
      }
      else if (element.name == 'werehouse') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.werehouse, canvas, element);
      }
      else if (element.name == 'werehouse_location') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.werehouse_location, canvas, element);
      }
      else if (element.name == 'serial_number') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.serial_number, canvas, element);
      }
      else if (element.name == 'sku') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.sku, canvas, element);
      }
      else if (element.name == 'item_type') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.item_type, canvas, element);
      }
      else if (element.name == 'category') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.category, canvas, element);
      }
      else if (element.name == 'part_number') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.part_number, canvas, element);
      }
      else if (element.name == 'sell_price') {

        let text = aThis.dummyData.sell_price ? aThis.dummyData.sell_price.toString() : '';
        text = aThis.currencyPipe.transform(text, 'USD');
        element.text = aThis.wrapCanvasText(text, aThis.canvas2, element);
      }
      else if (element.name == 'supplier') {
        element.text = aThis.wrapCanvasText(aThis.dummyData.supplier, canvas, element);
      }

    }
    else if (element.type == "image" && element.name == "item_image") {
      element.src = aThis.dummyData.item_image_url;
    }

    element.editable = false;
    element.selectable = false;

    return element;

  }



  changeType() {
    if (this.category == 1) {
      this.availFields = this.items;
    }
    else if (this.category == 2) {
      this.availFields = this.builtAssebly;
    }
    else {
      this.availFields = this.assebly;
    }
  }




  getCategoryProducts(id) {
    this.chooseProductView = true;
    this.catalogueService.getCatalogueCategoryProducts(id).subscribe(response => {
      this.catalogCatagoryProducts = response;
      this.cdr.markForCheck();
    });
  }
  cancelProductSelection() {
    this.chooseProductView = false;
  }

  get totalProducts() {
    let count = 0;
    this.catalogProductCount.forEach(val => {
      count += val;
    });
    return count;
  }
  addPageToCanvasFromSubNav(val) {

    let totlProdCountInSelPage = 0


    this.childCanvas.toArray()[this.selectedCanvasIndex].canvas.getObjects().forEach(element => {

      if (element.type == 'group' && element.name == 'productComponent') {
        totlProdCountInSelPage++
      }
    });

    if (totlProdCountInSelPage > 0) {
      let totlProdCountBfrSelePage = 0; //find out count product on before selected page
      let count = 0
      var obj = []

      const firstPageIndexInSection = this.sections.slice(0, this.selectedSectionIndex).reduce((accumulator, currentValue) => {

        return accumulator += currentValue.pages.length
      }, 0)

      for (let i = firstPageIndexInSection; i < this.selectedCanvasIndex; i++) {

        this.childCanvas.toArray()[i].canvas.getObjects().forEach(element => {

          if (element.type == 'group' && element.name == 'productComponent') {
            totlProdCountBfrSelePage++
          }
        });
      }

      val.objects.forEach(x => {

        if (x.name == 'productComponent') {
          if (count < totlProdCountInSelPage) {
            count++
            obj.push(x)
          }
        }
        else {
          obj.push(x)
        }

      });

      var data = this.catalogueFnService.catalogData[this.selectedSectionIndex].filter(x => x?.is_delete != true)


      this.addPageToCanvas({ objects: obj }, this.selectedCanvasIndex, totlProdCountBfrSelePage, data)

    }
    else {
      this.addPageToCanvas(val, this.selectedCanvasIndex)
    }
  }
  addSelectedProductsToCanvas(event, i, pageIndexToMap, pageTemplate, data: any,gridTemplate) {

    return new Promise((resolve, reject) => {
      setTimeout(() => {


        let productCountInLastPage = 0
        let productObjectRemoveCount = 0
        let productTemplateCount:number


        if (i == event.totalPageCountToAdd-1) {

          productCountInLastPage = this.catalogueFnService.catalogData[this.selectedSectionIndex].filter(x => x.is_delete != true).length %
            pageTemplate.objects.filter((x) => x.name == 'productComponent').length

          productObjectRemoveCount = pageTemplate.objects.filter((x) => x.name == 'productComponent').length - productCountInLastPage
        }
        if (productCountInLastPage != 0) {
          let count = 0
          for (i = pageTemplate.objects.length - 1; i >= 1; i--) { 
            var obj = pageTemplate.objects[i]
            if (obj.name == 'productComponent') {
              count++
              if (count <= productObjectRemoveCount) {

                obj.visible = false
                //pageTemplate.objects.splice(i, 1)
              }
            }

          }
          console.log('lastPageTemplate', pageTemplate)
          this.addPageToCanvas(pageTemplate, pageIndexToMap, this.productIndexStart, data);
        }
       else {
          this.addPageToCanvas(pageTemplate, pageIndexToMap, this.productIndexStart, data);
        }
        
        productTemplateCount= pageTemplate.objects.filter((x) => x.name == 'productComponent').length
        this.set_ProductListbyPageIndex(pageIndexToMap,data,productTemplateCount, this.productIndexStart)
        this.productIndexStart += productTemplateCount
    
        resolve(true);

      }, 200);
    })

  }

  onMoveAssocTabItem(items:any,sectionIndx:number)
  {
    // items.forEach((element,i) => {
      
    //   this.catalogueFnService[sectionIndx][]
    // });
    
  }
    setEmpty_ProductListbyPageIndex(totalPagesCount,sectionIndx)
    { 
      if (!this.productListbyPage[sectionIndx]) {
        this.productListbyPage.splice(sectionIndx, 0)
        this.productListbyPage[sectionIndx] = []      
      }     
      this.productListbyPage[sectionIndx] =( Array.from({ length:totalPagesCount }, () => []))
    }

    set_ProductListbyPageIndex(pageIndxStart,data,maxProCount,proindex)
    {

    
      this.maxProCountInPage = maxProCount
    //   const firstPageIndexInSection = this.sections.slice(0, this.selectedSectionIndex).reduce((accumulator, currentValue) => {

    //     return accumulator += currentValue.pages.length
    //   }, 0)

      pageIndxStart = pageIndxStart - this.findFirstPageIndexInSection(this.selectedSectionIndex) 

      for(let i = 0;i < maxProCount; i++)
      {
        if(data[proindex + i])
        {
          data[proindex + i].order_number = (proindex + i) + 1 
          this.productListbyPage[this.selectedSectionIndex][pageIndxStart].push(data[proindex + i])
        }
      }

       this.productListbyPageRows = this.productListbyPage
      //  console.log('productListbyPage', this.productListbyPage)


    }

    onItemPositionChange(event)
    {
      console.log('itemMoveEvent',event)
      
     
      const originalToObject = fabric.Object.prototype.toObject;
    const myAdditional = ['name', 'customHeight'];
    fabric.Object.prototype.toObject = function (additionalProperties) {
      return originalToObject.call(this, myAdditional.concat(additionalProperties));
    }
      
      var template = this.childCanvas.toArray()[event.pageIndex].canvas.toJSON()

      console.log('template2',template)

      this.addPageToCanvas(template,
      event.pageIndex,event.startIndex,this.catalogueFnService.catalogData[event.sectionIndex])
    }

    removeItemOnMove(event)
    {

      console.log('page index to remove', event.pageIndex )
      var proTemplates =   this.childCanvas.toArray()[event.pageIndex].canvas.getObjects().filter(x => x.name == 'productComponent')

      setTimeout(() => {
        
        proTemplates[event.productIndex].visible = false
        //this.childCanvas.toArray()[event.pageIndex].canvas.remove(proTemplates[event.productIndex])
        this.childCanvas.toArray()[event.pageIndex].canvas.renderAll()   
      }, 100);


    }

    findPageIndextoMap(secFirstPageIndx:number)
    {
      let index = secFirstPageIndx
      
      for(let i = secFirstPageIndx;i< this.childCanvas.toArray().length;i++)
      {
        let proTemplateCountPage = this.childCanvas.toArray()[i].canvas.getObjects().filter(x=> x.name == 'productComponent')
        if(proTemplateCountPage.length > 0)
        {
          break
        }
        else
        {
          index ++
        }
      }
       return index
    }


   afterProductSelection(event) {

    if(this.productListbyPage[this.selectedSectionIndex])
    {
      this.productListbyPage[this.selectedSectionIndex] = []
    } 

    var pageLength: number = this.sections[this.selectedSectionIndex].pages.length
     this.productIndexStart = 0
    var firstPageIndxInSection = 0
    var pageIndexToMap = 0

    if (pageLength > 0) {
    
      firstPageIndxInSection = this.findFirstPageIndexInSection(this.selectedCanvasIndex)
      pageIndexToMap = this.findPageIndextoMap(firstPageIndxInSection)
      this.sections[this.selectedSectionIndex].pages.splice(pageIndexToMap, pageLength - 1)
    }
     // for create empty product list by page index to show in associated product table
     this.setEmpty_ProductListbyPageIndex(this.sections[this.selectedSectionIndex].pages.length + event.totalPageCountToAdd,this.selectedSectionIndex)
    
     var data = this.catalogueFnService.catalogData[this.selectedSectionIndex].filter(x => x?.is_delete != true)

    var pageTemplate: any = {objects:[]}
    let gridTemplate

    
    if (event.isGridTemplate) {
      var template = event.selectedPageLayout.template

      gridTemplate = template?.objects.find(x => x.name == 'grid_Layout')

      pageTemplate.objects = this.catalogueFnService.getProTemplatesFromGrid(gridTemplate)

    }
    else
    {
      pageTemplate = event.selectedPageLayout.template
    }

    const promises = [];

    for (let i = 0; i < event.totalPageCountToAdd; i++) {

      this.addNewPage(this.selectedSectionIndex);
      promises.push(this.addSelectedProductsToCanvas(event, i, pageIndexToMap + i, pageTemplate, data,gridTemplate));
    }
  }
  findPageIndex() {
    let count = 0;
    this.catalogProductCount.forEach((val, i) => {
      count = count + val;
      this.pageIndexStart[i + 1] = count;

    });
  }
  checkProductsInCanvas(value) {

    let count = 0

    //  let objects=this.childCanvas.toArray()[index].getCanvasObjects();

    if (value.objects && value.objects.length) {

      value.objects.forEach(element => {

        count++;

      });
    }

    this.catalogProductCount[value.index] = count;

    this.findPageIndex();

  }
  renderAllCanvases() {

    this.childCanvas.toArray().forEach((val) => {
      setTimeout(() => {
        val.checkDataChange();
      }, 10);
    });

  }

  findCanvasIndex(sectionIndex, pageIndex) {


    //this.selectedSectionIndex = sectionIndex

    let count = 0;
    let prevPagesCount = 0;


    if (sectionIndex > 0) {

      for (let i = 0; i < sectionIndex; i++) {

        prevPagesCount += this.sections[i].pages.length;

      }
      count = prevPagesCount + pageIndex;

      //  count=this.sections[sectionIndex-1].pages.length+pageIndex;
    }
    else {
      count = pageIndex;
    }

    return count;

  }
  get totalPages() {
    if (this.childCanvas && this.childCanvas.toArray()) {
      return this.childCanvas.toArray().length;

    }
    else {
      return 0;
    }
  }

  get totalProductData() {

    return this.catalogueFnService.catalogData.length;
  }

  get catalogProducts() {

    return this.catalogueFnService.catalogData;
  }


  addImageToCanvas(src) {
    if (this.childCanvas.toArray()[this.selectedCanvasIndex]) {
      this.childCanvas.toArray()[this.selectedCanvasIndex].addImageToCanvas(src);
    }
  }

  addProducts(i) {

    this.selectedSectionIndex = i;
    this.productSelect.currentStep = 1;
    this.productSelect.selectedSectionIndex = this.selectedSectionIndex
    this.productSelect.selectedPage = {}
    this.productSelect.is_gridTemplate = false
    this.productSelect.selectedCategories = this.selectedProductCategories
    this.productSelect.open();
  }
  hasPermission(resource: string, permission: string) {
    return this.ra.hasPermission(resource, [permission, this.Permissions.GLOBAL]);
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  changePriceShowOnCatelog(event) {
    this.show_item_price = event.target.checked
  }

  onChangeAssocTabToggle(event)
  {
    this.show_associated_tab = event.target.checked
  }

  deselectCanvasObject()
  {
    this.canvas.discardActiveObject().renderAll();
  }

  checkPageTemplatesBeforeSave()
  {
    var pageIndex = 0;
    var sectionPageIndx = 0

    this.sections.forEach((secElemnt, secIndx) => {

      secElemnt.pages.forEach((pgElement,pgIndx) => {

        if(sectionPageIndx != secElemnt.pages.length - 1) // if not last page in section
        {
        //  var proTemplates = []
          var proTemplates = this.childCanvas.toArray()[pageIndex].canvas.getObjects().filter
          (x => x?.type == 'group' && x?.name == 'productComponent' && x?.visible == false )

          if(proTemplates.length > 0)
          {
           // proTemplates.push(proTemplate)

            proTemplates.forEach((template,i)=>{

              this.childCanvas.toArray()[pageIndex].canvas.remove(template)
            })
          }

          
        }

        pageIndex ++
      
    
      })
      sectionPageIndx ++
      
    });

  }

  onDragObject(event)
  {
      this.dragObject = event
      console.log('drag event',event)
  }

}
