import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { DataFilterRequest } from 'src/app/core/models/grid-filter.models';
import { Customer } from 'src/app/modules/customer/models/customer';
import { SubSink } from 'subsink';
import { TenantCustomerService } from '../../../tenant-customers/services/tenant-customer.service';
import { CustomerContact } from '../../../tenant-customers/models/tenant-customer';
import { SipOutBoundFunctionService } from '../../services/sipoutboundfunctionservice';

@Component({
  selector: 'contact-number-search-select',
  templateUrl: './contact-number-search.component.html',
  styleUrls: ['./contact-number-search.component.scss']
})
export class ContactNumberSearchComponent implements OnInit {

  @ViewChild('phoneNumberInput', { static: false }) phoneNumberInput: ElementRef | undefined;
  
  @Input() isGlobal_Route: boolean = false;

  @Input("size") size;

  public subs: SubSink = new SubSink();
  
  search:string

  @Input() value: string = "Search Contacts or Type Number";

  @Input() suborgId : number

  keyword = "";

  public options: CustomerContact[] = []

  public textInput = new Subject<string>();

  public selectedIndex : number = -1
  
  public selNoType:string = "phone"

  //new
  shown = false;

  @Output() afterChange = new EventEmitter();

  onChange: any = () => { };

  @Input() isDidSelectOpen: boolean = false

  // @ViewChild("input", { static: false }) input: ElementRef;

  constructor(
    private tenatCustService: TenantCustomerService,
    private ele: ElementRef,
    private sipOutBoundFunctionService: SipOutBoundFunctionService,
    private renderer: Renderer2) { }

  ngOnInit(): void {

  }

  @HostListener('window:keydown', ['$event'])

  handleKeyboardEvent(event: KeyboardEvent) {

    if(!this.isDidSelectOpen)
    {
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft' ) {

        this.selNoType = this.selNoType == "phone" ? "mobile" : "phone";
      } 
       else if (event.key === 'ArrowDown' && this.selectedIndex < this.options.length - 1) {
        this.selectedIndex++;
      } else if (event.key === 'ArrowUp' && this.selectedIndex > 0) {
        this.selectedIndex--;
      }
      else if (event.key === 'Enter') {
  
        if(this.selectedIndex == -1)
        {
          if(!isNaN(+this.keyword)) //+ for string covert to number
          {
            this.value = this.keyword
            this.setDialNumber()
            this.resetToDefault() 
          }
        
        }
        else
        {
            this.selectOption()
            this.resetToDefault() 
        }
      }
      else{
        if(!this.shown)
        {
          const digit = this.getDigitFromKey(event.key);
          if (digit !== undefined) {
            this.value += digit;
            this.setDialNumber()
            event.preventDefault(); // Prevent the default behavior of the key
           }
        }
  
      }
    }
  

    
  }

  private getDigitFromKey(key: string): string | undefined {
    // Check if the key is a digit (0-9)
    if (/^\d$/.test(key)) {
      return key;
    }
    return undefined;
  }

  selectOption()
  {
    if(this.selNoType == "phone" && this.options[this.selectedIndex].phone.length>0)
    {
      this.value = this.options[this.selectedIndex].phone
      this.setDialNumber()
    }
    else if (this.selNoType == "mobile" && this.options[this.selectedIndex].mobile.length>0)
    {
      this.value = this.options[this.selectedIndex].mobile
      this.setDialNumber()
    }
    this.resetToDefault()
  }

  setDialNumber()
  {
    this.sipOutBoundFunctionService.setOutBoundNumber(this.value)
  }

  resetToDefault()
  {
    this.options = []
    this.selectedIndex = -1
    this.shown = false
    this.keyword = ""
  }
  getData(search:string) {

    if(search && search != null)
    {
      if(search.length > 2)
      {
        let option: DataFilterRequest = {
          sort: [{ colname: 'first_name', direction: 'asc' }],
          filters: [],
          base_filters: [],
          parent_filters: []
        };
        option.filters.push(...[
          { colname: 'first_name', condition: 'contains', value: search, operator: 'AND' },
          { colname: 'mobile', condition: 'startswith', value: search, operator: 'OR' },
          { colname: 'phone', condition: 'startswith', value: search, operator: 'OR' }
        ]);

        this.subs.sink = this.tenatCustService.getCustomerContactsFiltered(option).subscribe((resp)=>{

          if(resp.result.length > 0)
          {
            this.selectedIndex = 0
            this.options = resp.result
          }
          else
          {
            this.options = []
            this.selectedIndex = -1
          }
       })

      }
    }
    else {
      this.selectedIndex = -1
      this.options = []
    }
  }
  show() {
    this.shown = this.shown ? false : true;
    setTimeout(() => {
      this.phoneNumberInput.nativeElement.focus();
      if(this.shown)
      {
        this.keyword = this.value
        this.setCursorPosition(this.phoneNumberInput.nativeElement.value.length);
      }
    }, 200);

  }
  private setCursorPosition(position: number) {
    if (this.phoneNumberInput) {
      this.renderer.setProperty(this.phoneNumberInput.nativeElement, 'selectionStart', position);
      this.renderer.setProperty(this.phoneNumberInput.nativeElement, 'selectionEnd', position);
    }
  }
  @HostListener("document:click", ["$event"]) onClick(e) {
    if (!this.ele.nativeElement.contains(e.target)) {
      this.shown = false;
    }
  }
}
