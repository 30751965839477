<div class="main-div">
    <div class="side-nav-content">
        <ul class="nav-list" [ngClass]="{'nav-list-open': sideNavStatus}">
            <li class="nav-list-item" *ngIf="settings.attributes" (click)="goTo('Home')"
                [ngClass]="{'onActive': selectedMenu == 'Home'}">
                <i class="ft ft-book"></i>
                <span>Attributes</span>
            </li>
            <li class="nav-list-item" *ngIf="settings.text" (click)="goTo('Text')"
                [ngClass]="{'onActive': selectedMenu == 'Text'}">
                <i class="fa fa-font"></i>
                <span>Text</span>
            </li>
            <li class="nav-list-item" *ngIf="settings.images" (click)="goTo('Images')"
                [ngClass]="{'onActive': selectedMenu == 'Images'}">
                <i class="ft ft-image"></i>
                <span>Images</span>
            </li>
            <li class="nav-list-item" *ngIf="settings.buttons" (click)="goTo('Buttons')"
                [ngClass]="{'onActive': selectedMenu == 'Buttons'}">
                <i class="ft ft-link-2"></i>
                <span>Buttons</span>
            </li>
            <li class="nav-list-item" *ngIf="settings.pages" (click)="goTo('Pages')"
                [ngClass]="{'onActive': selectedMenu == 'Pages'}">
                <i class="ft ft-layout"></i>
                <span>Pages</span>
            </li>
            <li class="nav-list-item" *ngIf="settings.products" (click)="goTo('Products')"
                [ngClass]="{'onActive': selectedMenu == 'Products'}">
                <i class="ft ft-grid"></i>
                <span>Products</span>
            </li>
            <!-- <li class="nav-list-item" *ngIf="settings.shapes" (click)="goTo('Shapes')"
                [ngClass]="{'onActive': selectedMenu == 'shapes'}">
                <i class="ft ft-grid"></i>
                <span>Shapes</span>
            </li> -->
            <!-- <li class="nav-list-item" *ngIf="settings.addProducts" (click)="goTo('Categories')" [ngClass]="{'onActive': selectedMenu == 'Categories'}">
                <i class="ft ft-plus"></i>
                <span>Items</span>
            </li> -->
        </ul>
    </div>

    <div class="expanding-div" [hidden]="!sideNavStatus">
        <!-- <form class="input-group" [ngClass]="{'expanded-div': sideNavStatus}" action="/action_page.php" style="margin:auto;max-width:350px">
            <input type="text" placeholder="Search.." name="search2">
            <button type="submit"><i class="fa fa-search"></i></button>
        </form> -->

        <div class="sub-navbar" [ngClass]="{'app-side-nav-open': sideNavStatus}">
            <div class="content-details attributes-content" *ngIf="selectedMenu == 'Home'">
                <ul class="item-box-ul d-flex flex-column p-0" cdkDropList>

                    <li class="p-2 d-flex " (click)="addNewElement('plaintext')" (dragstart)="onDragStart('plaintext', {'val':'plaintext'})" draggable="true">
                        <span class="flex-grow-1">Plain text</span>
                    </li>
                    <!-- cdkDrag -->
                    <li class="p-2  d-flex " *ngFor="let item of items" (click)="addNewElement(item)" (dragstart)="onDragStart('attribute', item)"  draggable="true">
                        <span class="flex-grow-1">{{item.title}}</span>
                        <!-- <img src="../../../../../assets/img/gallery/arrows-drag.svg" /> -->
                    </li>
                </ul>
            </div>
            <div class="content-details text-content" *ngIf="selectedMenu == 'Text'">


                <ul class="item-box-ul d-flex flex-column p-0">

                    <li class="d-flex" (click)="addBigTextToCanvas(64,'Big Heading')" *ngIf = "!isButton"
                      draggable="true" (dragstart)="onDragStart('text', {'fontSize':64,'text':'Big Heading'})">
                        <span class="flex-grow-1 add-big-heading">Add big heading</span>
                    </li>
                    <li class="d-flex " (click)="addBigTextToCanvas(40, 'Medium Heading')" *ngIf = "!isButton"
                     draggable="true" (dragstart)="onDragStart('text', {'fontSize':40,'text':'Medium Heading'})">
                        <span class="flex-grow-1 add-medium-heading">Add medium heading</span>
                    </li>
                    <li class="d-flex add-small-heading " (click)="addBigTextToCanvas(18,'Small Heading')"
                     *ngIf = "!isButton" draggable="true" (dragstart)="onDragStart('text', {'fontSize':18,'text':'Medium Heading'})">
                        <span class="flex-grow-1">Add small heading</span>
                    </li>
                    <li class="d-flex add-normal-text " (click)="   ('plaintext')"
                     draggable="true"  (dragstart)="onDragStart('plaintext', {'val':'plaintext'})">
                        <span class="flex-grow-1">Add normal text</span>
                    </li>
                </ul>

                <!-- <h5>Font combos</h5> -->
            </div>

            <div class="content-details text-content" *ngIf="selectedMenu == 'Shapes'">


                <ul class="item-box-ul d-flex flex-column p-0">

                    <li class="d-flex add-small-heading " *ngFor='let item of figures'
                        (click)="addFigureToCanvas(item)">
                        <span class="flex-grow-1 cursor-pointer">{{item.name}}</span>
                    </li>

                </ul>

                <!-- <h5>Font combos</h5> -->
            </div>

            <div class="content-details" *ngIf="selectedMenu == 'Images'">
                <ul class="nav mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link text-white active" id="img-upload-tab" data-toggle="pill" href="#pills-home"
                            role="tab" aria-controls="pills-home" aria-selected="true">Upload</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" id="img-stock-tab" data-toggle="pill" href="#pills-profile"
                            role="tab" aria-controls="pills-profile" aria-selected="false">Stock</a>
                    </li>
                </ul>

                <!-- content -->
                <div class="tab-content" id="pills-tabContent p-3">

                    <!-- 1st card -->
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                        aria-labelledby="img-upload-tab">

                        <div class="upload-btn mb-2">
                            <button type="button" class="btn btn-primary btn-sm" (click)="fileInput.click()"
                                [disabled]="isUploadingImg">
                                <i class="fa fa-upload fa-lg"></i>Upload Images
                                <input #fileInput type="file" (change)="onImageChange($event)" style="display:none;" />
                            </button>
                        </div>

                        <div class="container">
                            <a *ngFor="let item of imageList.images ; trackBy: trackImageId; let i = index"
                                [ngClass]="{vertical: i % 2 === 0, horizontal: i % 2 !== 0}" class="svg-div mb-2">
                                <img alt="" src={{imgURL}}{{item}} (click)="addImageToCanvas(imgURL + item)"
                                    id="img-list" (dragstart)="onDragStart('image', {})"/>
                                <div ngbDropdown container="body" class="dropdown-div">
                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                        (click)="$event.stopPropagation()" ngbDropdownToggle>
                                        <i class="ft-more-vertical text-primary"></i>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <button class="w-100"
                                        ngbDropdownItem (click)="open(content,i,1)">
                                       <i class="fa fa-eye mr-1"></i> View
                                         </button>
                                        <button class="w-100 bg-light-danger"
                                             ngbDropdownItem (click)="deleteImage(item)">
                                            <i class="ft-trash-2 mr-1"></i> Delete
                                        </button>
                                    </div>
                                </div>
                            </a>
                            
                        </div>

                        <!-- Gallery -->
                    </div>
                    <!-- 2nd card -->
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="img-stock-tab">

                        <div class="container">
                            <a *ngFor="let item of images ; let i = index" [ngClass]="item.class"
                                class="svg-div mb-2">
                                <img alt="" src={{item.image}} (click)="addImageToCanvas(item.image)" id="img-list" />
                                <!-- <div class="edit"><a><i class="fa fa-expand fa-lgt" (click)="open(content,i,2)"></i></a>
                                </div> -->
                                <div ngbDropdown container="body" class="dropdown-div">
                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                        (click)="$event.stopPropagation()" ngbDropdownToggle>
                                        <i class="ft-more-vertical text-primary"></i>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <button class="w-100"
                                        ngbDropdownItem (click)="open(content,i,2)">
                                       <i class="fa fa-eye mr-1"></i> View
                                         </button>
                                       
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>

            <div class="content-details" *ngIf="selectedMenu == 'Buttons'">
                <div class="title-bar d-flex justify-content-between">
                    <h6 class="content-title">Buttons</h6>
                    <a class="btn" [routerLink]="['/inventory/catalog/design-component']"
                        [queryParams]="{type: 'button'}"><i class="ft-plus"></i></a>
                </div>
                <!-- {{buttonTemplates.length}} -->
                <div *ngFor="let component of buttonTemplates;let i=index" class="svg-div mb-2"
                (click)="addElementsGroup(component, 'button')"  draggable="true" (dragstart)="onDragStart('component',{componentName:'button', component:component } )">
                <!-- <img *ngIf="component.image&&component.svg==''"
                    [src]="imgURL+component.image" /> -->
                <!-- {{component?.template?.objects[0]?.objects[2]?.text?component?.template?.objects[0]?.objects[2]?.text:''}} -->
                <!-- {{component.svgString}} -->
               <div class="svgDiv" *ngIf="component.svg!=''" [innerHtml]="component.svgString"></div>
                <div ngbDropdown container="body" class="dropdown-div">
                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                        (click)="$event.stopPropagation()" ngbDropdownToggle>
                        <i class="ft-more-vertical text-primary"></i>
                    </button>
                    <div ngbDropdownMenu>
                        <a class="w-100"  [routerLink]="['/inventory/catalog/design-component',component.id,'edit']"
                        [queryParams]="{type: 'button'}"
                         ngbDropdownItem>
                            <i class="ft-edit mr-1"></i> Edit
                        </a>
                        <button class="w-100 bg-light-danger"
                            (click)="deleteComponent(component.id, i, component.type)" ngbDropdownItem>
                            <i class="ft-trash-2 mr-1"></i> Delete
                        </button>
                    </div>
                </div>
            </div>
            </div>

           


            <div class="content-details product-content" *ngIf="selectedMenu == 'Pages'">
                <div class="title-bar d-flex justify-content-between">
                    <h6 class="content-title">Page templates</h6>
                    <a class="btn" (click) = "goToAddPage()"><i class="ft-plus"></i></a>
                </div>

                <div *ngFor="let component of pageTemplates;let i=index" class="svg-div mb-2"
                   (click)="pageToCanvas(component.template)">
                    <!-- <img *ngIf="component.image"
                        [src]="imgURL+component.image" /> -->
                    <!-- {{component?.template?.objects[0]?.objects[2]?.text?component?.template?.objects[0]?.objects[2]?.text:''}} -->
                    <div class="svgDiv" [innerHtml]="component.svgString"></div>

                    <div ngbDropdown container="body" class="dropdown-div">
                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                            (click)="$event.stopPropagation()" ngbDropdownToggle>
                            <i class="ft-more-vertical text-primary"></i>
                        </button>
                        <div ngbDropdownMenu>
                            <a class="w-100"  (click) = "goToAddPage(1,component.id)"
                             ngbDropdownItem>
                                <i class="ft-edit mr-1"></i> Edit
                            </a>
                            <button class="w-100 bg-light-danger"
                                (click)="deleteComponent(component.id, i, component.type)" ngbDropdownItem>
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="content-details product-content" *ngIf="selectedMenu == 'Products'">

                <div class="title-bar d-flex justify-content-between">
                    <h6 class="content-title">Product templates</h6>
                    <a class="btn" (click) = "goToAddProduct()"><i class="ft-plus"></i></a>
                </div>

                <div *ngFor="let component of productTemplates; let i=index" class="svg-div mb-2"
                    (click)="addElementsGroup(component, 'productComponent')" draggable="true" (dragstart)="onDragStart('component',{componentName:'productComponent', component:component } )">
                    <!-- <img *ngIf="component.image"
                        [src]="imgURL+component.image" /> -->

                    <div class="svgDiv" [innerHtml]="component.svgString"></div>

                    <div ngbDropdown container="body" class="dropdown-div">
                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                            (click)="$event.stopPropagation()" ngbDropdownToggle>
                            <i class="ft-more-vertical text-primary"></i>
                        </button>
                        <div ngbDropdownMenu>
                            <!-- <button  class="w-100" ngbDropdownItem>
                                <i class="ft-book-open mr-1"></i> View
                            </button> -->
                            <!-- <a  class="w-100" ngbDropdownItem>
                                <i class="ft-plus mr-1"></i> Create
                            </a> -->
                            <a class="w-100"   (click) = "goToAddProduct(1,component.id)"
                             ngbDropdownItem>
                                <i class="ft-edit mr-1"></i> Edit
                            </a>
                            <button class="w-100 bg-light-danger"
                                (click)="deleteComponent(component.id, i, component.type)" ngbDropdownItem>
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="content-details category-content" *ngIf="selectedMenu == 'Categories'">

                <ul class="item-box-ul d-flex flex-column p-0">
                    <li *ngFor="let cat of catalogCatagories" (click)="clickedOnCategory(cat.id)">{{cat.category_name}}
                    </li>
                </ul>

            </div>


            <span id="b" *ngIf="sideNavStatus">
                <button (click)="SideNavToggled()" class='btntoggle' [ngClass]="{'app-side-nav-open': sideNavStatus}"><i
                        id="b" class="fa fa-angle-left" aria-hidden="true" style="color: white;"></i></button>
            </span>
        </div>

    </div>

</div>
<ng-template #content let-modal>
    <div class="modal-header" style="border:none">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="preview-container">
            <div *ngIf="showFlag1">
                <img [src]="imgURL + imageList.images[selectedImageIndex]" alt="" />
            </div>
            <div *ngIf="showFlag2">
                <img [src]="images[selectedImageIndex].image" alt="" />
            </div>

            <div class="actions d-flex position-absolute w-100 pl-2 pr-2">
                <button *ngIf="selectedImageIndex > 0" class="btn btn-info btn-icon round" (click)="prev()"
                    type="button">
                    <i class="ft-chevron-left"></i>
                </button>
                <button *ngIf="selectedImageIndex < imageList.images.length - 1"
                    class="btn btn-info btn-icon round ml-auto" (click)="next()" type="button">
                    <i class="ft-chevron-right"></i>
                </button>
            </div>
        </div>
    </div>
</ng-template>