import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TenantCustomerService } from './../../services/tenant-customer.service';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { CustomerLocation } from '../../models/tenant-customer';
import * as popup from 'src/app/core/utils/popup.functions';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { GFilterParam, GFColumn, GFColumnTypes, DataFilterRequest, GSortParam } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';

@Component({
  selector: 'customer-locations',
  templateUrl: './customer-locations.component.html',
  styleUrls: ['./customer-locations.component.scss']
})
export class CustomerLocationsComponent implements OnInit, OnDestroy {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ViewChild('FormModal') modalContent: any;

  private _teantcustid: number;

  get tenantCustId() { return this._teantcustid; };

  @Input() set tenantCustId(value: number) {
    this._teantcustid = value;
    if (value) {
      this.getData();
    }
  }

  @Input() suborg_id:number

  public page = { count: 0, limit: 25, offset: 0, pageNumber: '0-0' };

  public searchFilters: GFilterParam[] = [
    {
      displayName: 'Combo', colname: '', condition: '', value: '',
      children: [
        { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Account Number', colname: 'account_number', condition: 'contains', value: '', operator: 'OR' },
      ]
    }
  ];

  public columns: GFColumn[] = [
    { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
    { displayName: 'Account Number', columnName: 'account_number', columnType: GFColumnTypes.string },
    { displayName: 'Active', columnName: 'is_active', columnType: GFColumnTypes.boolean },
    { displayName: 'Service Ticket Billable', columnName: 'service_ticket_billable', columnType: GFColumnTypes.boolean },
    { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
    { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
  ];

  public sort: GSortParam[] = [{ colname: 'name', direction: 'asc' }];

  public sortLookup = {
    name: 'name',
    account_number: 'account_number',
    address : 'address'
  };

  public rows: CustomerLocation[];

  public SelectionType = SelectionType;

  public bindingModel: CustomerLocation;

  public Permissions = PermissionConstants;

  private subs: SubSink = new SubSink();

  private modalRef: NgbModalRef;

  constructor(private tenantCustService: TenantCustomerService,
    private modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() { }

  getData(filters: GFilterParam[] = [], offset: number = 0) {

    let request: DataFilterRequest = {
      filters: filters,
      sort: this.sort,
      base_filters: [{ colname: 'tenant_customer', condition: 'equalto', value: this.tenantCustId, 'operator':'AND' }]
    };

    this.subs.sink = this.tenantCustService.getCustomerLocationsFiltered(request, this.page.limit, offset).subscribe(resp => {
      this.rows = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  openForm() {
    this.bindingModel = undefined;
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modal.open(this.modalContent, { size: 'lg', scrollable: true, centered: true });
  }

  onSelect({ selected }) {
    let current: CustomerLocation = selected[0];
    if (this.hasPermission(this.Permissions.MODIFY)) {
      this.bindingModel = { ...this.rows.find(item => item.id === current.id) };
      this.openModal();
    }
  }

  onDataSubmittedSuccess(result: { response: CustomerLocation, message: string }): void {
    this.toastr.success(result.message);
    this.rows = [...this.rows.filter(item => item.id !== result.response.id), ...[result.response]];
    if(!this.bindingModel){
      this.page.count += 1;
    }
    this.cdr.markForCheck();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeModal() { this.modalRef?.dismiss(); }

  deleteLocation(id: number): void {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.tenantCustService.deleteCustomerLocation(id).subscribe(
          () => {
            this.rows = this.rows.filter(x => x.id !== id);
            this.setPagination(this.page.offset,this.page.count - 1);
            this.cdr.markForCheck();
            popup.CompleteDelete('Location has been deleted.')
          },
          () => this.toastr.error('Unable to delete location')
        );
      }
    });
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.CUSTOMER_LOCATIONS,
      [
        permission,
        this.Permissions.GLOBAL
      ],
      ResourceConstants.CUSTOMER_MODULE);
  }

  toggleExpandRow(row: CustomerLocation) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    let params = this.dataFilter?.getDataFilters() || [];
    this.getData(params, offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
