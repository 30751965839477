export class imList{
    
    public id: number;

    public name: string;

    public message: string;

    public created_by: string;

    public created_on: Date;

    public modified_on: Date;

    public modified_by: string;

    public customer: number;

    public is_active: boolean;

    public is_delete: boolean;

    public created_user: number;

    public required_confirmation:boolean=false;

    public reason_for_service:number;

    public tenant_customer:number;

    public module_id:number;

}

// export class imAdd{
//     public name:string;
//     public required_confirmation:boolean;
//     public is_delete:boolean;
//     public tenant_customer:number;
//     public reason_for_service:number;
//     public customer:number;
// }

export class rfsList{
    public id:number;
    public name:string;
    public message:string;
    public required_confirmation:boolean;
    public module_id:number;
}