<section id="item-list">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-flex flex-space-bw">
                    <h4 class="card-title">Unit Types</h4>
                    <div class="d-flex flex-row">
                        <button *ngIf="UIPermissions.create" (click)="openModal()" class="btn btn-sm gradient-pomegranate mr-1" disableIfAllsuborg>
                            <i class="ft-plus mr-1"></i> New
                        </button>
                        <input #searchInput class="form-control form-control-sm width-200" type="text" placeholder="Search unit..." (keyup)="search($event)" />
                    </div>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <ngx-datatable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" [sorts]="[{prop:'units',dir:'asc'}]" (select)="onSelect($event)">
                            <ngx-datatable-column name="Unit" prop="units" [width]="150" [sortable]="true"></ngx-datatable-column>
                            <ngx-datatable-column name="Description" prop="description" [sortable]="true"></ngx-datatable-column>
                            <ngx-datatable-column name="Created Date" prop="created_on" [sortable]="true">
                                <ng-template let-crdate="value" ngx-datatable-cell-template>
                                    {{crdate | date:'MM/dd/YYYY' }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Created By" prop="created_by" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="isAllSuborg" name="Suborg" prop="suborg_name" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="!isAllSuborg" name="Action" prop="id" [sortable]="false" [width]="100">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button class="w-100" *ngIf="UIPermissions.edit" (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </button>
                                            <button class="w-100 bg-light-danger" *ngIf="UIPermissions.delete" (click)="deleteUnitType(id)" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                                    <div style="padding: 0 .7rem;">
                                        <span>{{rowCount}} Total</span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #unitFormModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{selectedUnitId > 0 ? 'Edit Unit Type' : 'New Unit Type'}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            <app-unit-form [unitId]="selectedUnitId" (onSuccess)="onDataSubmittedSuccess($event)" (onError)="onDataError($event)" (onCancel)="onCancel()"></app-unit-form>
        </div>
    </ng-template>

</section>
