import { UserWrapper, UserWrapperFlat } from './../models/user-wrapper';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { UserGroup } from '../models/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll(option: any, offset: number = 0, limit:number=this.dataLimit) {
    let url = `${this.baseURL}/usergroups/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<{ count: number, result: UserGroup[] }>(url, option);
  }

  getById(id: number) {
    return this.http.get<UserGroup>(`${this.baseURL}/usergroups/${id}/`);
  }

  create(userGroup: UserGroup) {
    return this.http.post<UserGroup>(`${this.baseURL}/usergroups/`, userGroup);
  }

  update(id: number, userGroup: UserGroup) {
    return this.http.put<UserGroup>(`${this.baseURL}/usergroups/${id}/`, userGroup);
  }

  patch(id: number, request: any) {
    return this.http.patch<UserGroup>(`${this.baseURL}/usergroups/${id}/`, request);
  }

  delete(id: number) {
    return this.http.delete<{ status: boolean, message: string }>(`${this.baseURL}/usergroups/${id}/`);
  }

  getUsersByUserGroup(id: number, request: any = null) {
    return this.http.post<UserWrapper[]>(`${this.baseURL}/usergroup/${id}/users/`,request).pipe(
      map(resp => {
        let flat: UserWrapperFlat[] = [];
        for (let item of resp) {
          let obj = { ...new UserWrapperFlat(), ...item.user };
          obj.id = item.id;
          obj.customer_name = item.custname;
          obj.preffered_user = item.preffered_user;
          obj.first_name = item.user_firstname;
          obj.last_name = item.user_lastname;
          obj.role_name = item.rolename;
          obj.email = item.user_email;
          obj.user_status = item.user_status;
          obj.sip_name = item.sip_name;
          obj.call_end_time = item.call_end_time;
          flat.push(obj);
        }

        return flat;
      })
    );
  }

  smartSipDetails(id: number) {
    return this.http.get<any>(`${this.baseURL}/user/group/${id}/users/`);
  }
}
