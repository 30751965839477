import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, map, debounceTime } from 'rxjs/operators';
import { DataFilterRequest } from 'src/app/core/models/grid-filter.models';
import { SubOrg } from 'src/app/modules/suborg/models/suborg.models';
import { SuborgService } from 'src/app/modules/suborg/services/suborg.service';

@Component({
    selector: 'suborg-select',
    templateUrl: './suborg-select.component.html',
    styleUrls: ['./suborg-select.component.scss']
})
export class SuborgSelectComponent implements OnInit {

    private innerTenantId: number;

    @Input() set tenantId(value: number) {
        if (this.innerTenantId) {
            this.innerValue = undefined;
            this.valueChange.emit(undefined)
        }
        if (value != this.innerTenantId) {
            this.innerTenantId = value;
            this.loadData();
        }
    }

    get tenantId() { return this.innerTenantId; }

    private innerValue: any;

    @Input() set value(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.valueChange.emit(this.innerValue);
        }
    }

    get value() { return this.innerValue; }

    @Input() readonly: boolean = false;

    @Input() placeholder: string;

    @Input() multiple: boolean = false;

    @Output() valueChange = new EventEmitter<any>();

    @Output() change = new EventEmitter<any>();

    public options: Observable<SubOrg[]>;

    public textInput = new Subject<string>();

    public totalCount: number = 0;

    public currentCount: number = 0;

    constructor(private service: SuborgService) { }

    ngOnInit() {
        this.placeholder = this.multiple ? 'Select Suborgs' : 'Select Suborg';
    }

    loadData(selectedValue: Observable<any> = of([])) {
        this.options = concat(
            selectedValue,
            this.getData(),
            this.textInput.pipe(
                filter(res => !res || res.length >= 2),
                distinctUntilChanged(),
                debounceTime(300),
                switchMap(term => this.getData(term))
            )
        );
    }

    getData(search?: string) {
        if (this.service.suborgId === -1 && !this.service.isAdmin) {
            const suborgs = this.service.getSuborgs().filter(suborg => suborg.name.toLowerCase().indexOf(search) !== -1 || !search);
            this.totalCount = suborgs.length;
            this.currentCount = this.totalCount;
            return of(suborgs)
        }
        let option: DataFilterRequest = {
            sort: [{ colname: 'name', direction: 'asc' }],
            filters: [{ colname: "customer", condition: "equalto", value: this.tenantId, operator: "AND" }]
        };
        if (search) {
            option.filters.push({ colname: 'name', condition: 'contains', value: search, operator: 'AND' });
        }
        return this.service.getFiltered(option, 0, 1000).pipe(
            map(resp => {
                this.totalCount = resp.count;
                this.currentCount = resp.result.length;
                return resp.result;
            })
        );
    }

    trackByFn(item: SubOrg) { return item.id; }

    valueChanged(val: any) {
        this.change?.emit(val);
    }

    onClear() {
        this.loadData();
    }
}
