<section id="suborgs">
    <div class="filter">
        <datagrid-filter #dataGridFilter
                         [searchFilters]="searchFilters"
                         [columns]="columns"
                         [refresh]="false"
                         [newButton]="false"
                         (newButtonClick)="openSuborgForm()"
                         (apply)="applyFilter($event)">
        </datagrid-filter>
    </div>
    <div class="dt-container mt-2">
        <ngx-datatable #dataTable [rows]="rows"
                       class="bootstrap core-bootstrap"
                       [columnMode]="'force'"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       rowHeight="auto"
                       [scrollbarH]="scrollbarH"
                       [count]="page.count"
                       [externalPaging]="true"
                       [offset]="page.offset"
                       [externalSorting]="true"
                       [limit]="page.limit"
                       (page)="setPage($event)"
                       (sort)="onSort($event)"
                       [sorts]="[{prop:'name',dir:'asc'}]">

            <ngx-datatable-column name="Name" prop="name">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.name}}
                    <span class="badge badge-primary" *ngIf="row.is_root">Main</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="address">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="data-group">
                        <div *ngIf="row.address">{{row.address}}</div>
                        <div *ngIf="row.city">{{row.city}}</div>
                        <div *ngIf="row.state || row.zip">{{[row.state,row.zip].join(', ')}}</div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" prop="email"></ngx-datatable-column>
            <ngx-datatable-column name="Phone" prop="phone_number">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | mask:'(000) 000-0000'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Created Date" prop="created_on">
                <ng-template let-crdate="value" ngx-datatable-cell-template>
                    {{crdate | date:'MM/dd/YYYY'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="is_active" [sortable]="false">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <div class="badge"
                         [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                        {{ status ? 'Active' : 'Inactive' }}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                             let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                    <div class="page-count">
                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                    </div>
                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                     [pagerPreviousIcon]="'datatable-icon-prev'"
                                     [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                     [size]="pageSize" [count]="rowCount"
                                     [hidden]="!((rowCount / pageSize) > 1)"
                                     (change)="dataTable.onFooterPage($event)">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</section>
