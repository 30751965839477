import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { User } from 'src/app/modules/user/models/user';
import { Location } from '@angular/common';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

    public userId: number = 0;

    public resetType: string = "resetUserPass";

    public model: User = new User();

    public hiddenFields: string[] = [];

    public readOnlyFields: string[] = [];

    constructor(route: ActivatedRoute,
        private toastr: ToastrService,
        private router: Router,
        private location: Location) {

        this.userId = +route.snapshot.paramMap.get('id');
    }

    ngOnInit(): void {
        if (this.userId > 0) {
            this.hiddenFields.push("password");
            this.hiddenFields.push("current_password");
            // this.readOnlyFields.push("email");
        }
    }

    onDataSubmittedSuccess(result: { response: User, message: string }) {
        if (result) {
            this.toastr.success('Success', result.message);
            this.router.navigate(['/users', result.response.id, 'view']);
        }
    }

    onCancel() { this.location.back(); }

    onDataError(error: any) {
        this.toastr.error('Failed', 'Operation failed');
    }
}
