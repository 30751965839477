import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { KitDataHelper } from './../../helpers/kit-data.helper';
import { KitService } from './../../services/kit.service';
import { KitWrapper } from './../../models/kit.models';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from '../../../../core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { Location } from '@angular/common';
import * as popup from 'src/app/core/utils/popup.functions';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UIPermission } from 'src/app/core/models/common.models';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-kit-list',
    templateUrl: './kit-list.component.html',
    styleUrls: ['./kit-list.component.scss']
})
export class KitListComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    public SelectionType = SelectionType;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public rows: KitWrapper[] = [];

    public defaults: GFilterParam[] = KitDataHelper.DefaultFilters;

    public search: GFilterParam[] = KitDataHelper.SearchFilters;

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public sortLookup = KitDataHelper.SortLookup;

    public sort: GSortParam[] = [{ colname: 'internalnumber', direction: 'asc' }];

    public columns: GFColumn[] = KitDataHelper.FilterColumns;

    public selected: KitWrapper;

    public expanded: boolean = true;

    private kitId: number;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public module = ModuleConstants.KIT;

    private subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public isAllSuborg: boolean;

    public UIPermissions:UIPermission;

    constructor(route: ActivatedRoute,
        private kitService: KitService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private ra: ResourceAccessService,
        private location: Location,
        private device: DeviceDetectorService,
        private router: Router) {

        this.kitId = +route.snapshot.paramMap.get('id');
        this.isAllSuborg = kitService.isAllSuborg;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_KITS, ResourceConstants.INV_MODULE);
        this.scrollbarH = this.device.isMobile();

        if (this.isAllSuborg) {
            this.columns.push({ displayName: 'Sub Org', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentValue: this.kitService.customerId });
        }
    }

    ngOnInit(): void {

        this.getData();

        if (this.kitId) {
            this.subs.sink = this.kitService.getById(this.kitId).subscribe(kit => {
                this.selected = kit;
                this.expanded = false;
                this.refreshDataTable();
            });
        }
    }

    getData(filters: GFilterParam[] = null, offset: number = 0) {
        let option = { sort: this.sort, filters: filters };
        if(this.expanded)
            this.subs.sink = this.kitService.getAllFiltered(option, offset).subscribe(response => {
                this.rows = response.result;
                this.setPagination(offset, response.count);
                this.cdr.markForCheck();
            });
        else{
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/inventory/assemblies', this.selected.id, 'view']);
        }
    }

    onSelect({ selected }): void {
        let wrapper: KitWrapper = selected[0];
        if (wrapper && this.UIPermissions.viewInfo) {
            this.selected = wrapper;
            this.expanded = false;
            this.location.go(`/inventory/assemblies/${wrapper.id}/view`);
            this.refreshDataTable();
        }
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }

    deleteKit(id: number): void {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.kitService.delete(id).subscribe(
                    () => {
                        this.selected = null;
                        this.expanded = true;
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        this.location.go('/inventory/assemblies');
                        popup.CompleteDelete('Assembly has been deleted.');
                        this.refreshDataTable();
                    },
                    () => this.toastr.error('Unable to delete Assembly')
                );
            }
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onKitViewClose(): void {
        this.expanded = true;
        this.selected = null;
        this.kitId = 0;
        this.location.go(`/inventory/assemblies`);
        this.refreshDataTable();
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    hasPermission(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE);
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
