import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Mapboxservice } from 'src/app/core/services/mapboxservice.service';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'geo-location-selector',
  templateUrl: './geo-location-selector.component.html',
  styleUrls: ['./geo-location-selector.component.scss']
})
export class GeoLocationSelectorComponent implements OnInit {

  private innerValue: gioLocation;

  get value(): gioLocation { return this.innerValue; }

  locations: any[] = [];

  @Input() set value(val: gioLocation) {

    this.innerValue = val
  }

  public get selected(): gioLocation { return this.innerValue; }

  public set selected(value: gioLocation) {

    this.innerValue = value;
    this.valueChange?.emit(value);
  }

  public options: Observable<any[]>;

  public textInput = new Subject<string>();

  @Output() valueChange = new EventEmitter<any>();

  @Input() readonly: boolean = false;

  private map: mapboxgl.Map;

  private geocoder: MapboxGeocoder;

  constructor(private mapboxService: Mapboxservice) { }

  ngOnInit(): void {  

    Object.getOwnPropertyDescriptor(mapboxgl, "accessToken").set(environment.mapbox.accessToken);

    this.geocoder = new MapboxGeocoder({

      accessToken: mapboxgl.accessToken,

      mapboxgl: mapboxgl,
      marker: false,
      placeholder: 'Search for places...',
      countries: 'us', // Restrict to the United States for example
      limit: 3 // Limit the number of results
    });

    // Listen for the result event
    this.geocoder.on('result', (event) => {
      this.selected = event.result;
      console.log('Selected Location:', this.selected);
    });
    
    
    document.getElementById('geocoder-container').appendChild(this.geocoder.onAdd(this.map));
    if(this.selected?.place_name)
    {
      this.geocoder._inputEl.value = this.selected.place_name;
    } 
  }
}

export interface gioLocation {
  place_name: string, geometry: { coordinates: [number, number] }
}

