import { StateTaxService } from './../../../services/state-tax.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { State } from '../../../models/kit.models';

@Component({
  selector: 'tax-select',
  templateUrl: './tax-select.component.html',
  styleUrls: ['./tax-select.component.scss']
})
export class TaxSelectComponent implements OnInit, OnDestroy {

  @Input() value: number;

  @Input() readonly: boolean = false;

  @Input() required: boolean = false;

  @Output() valueChange = new EventEmitter<number>();

  public placeholder: string;

  public Permissions = PermissionConstants;

  public options: State[];

  private sub: Subscription;

  constructor(private stateService: StateTaxService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) {

    this.placeholder = this.hasPermission() ? "Select or Add Tax" : "Select Tax";
  }

  ngOnInit() {
    this.sub = this.stateService.getAll().subscribe(response => {
      this.options = response;
      this.cdr.markForCheck();
    });
  }

  openModal(content: any): void {
    this.modalService.open(content);
  }

  onValueChanged(value: State) {
    this.valueChange.emit(this.value);
  }

  onDataSubmittedSuccess(result: { response: State, message: string }): void {
    this.toastr.success(result.message);
    this.options = this.options.concat(result.response);
    this.value = result.response.id;
    this.onValueChanged(result.response);
    this.modalService.dismissAll();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalService.dismissAll();
  }

  onCancel(): void {
    this.modalService.dismissAll();
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.INV_UNITS, [this.Permissions.CREATE, this.Permissions.GLOBAL]);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
