import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersPaymentDetailsTabComponent } from './components/customers-payment-details-tab/customers-payment-details-tab.component';
import { AddCardComponent } from './components/add-card/add-card.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PaymentAccountSettingsComponent } from './pages/payment-account-settings/payment-account-settings.component';
import { PaymentsRoutingModule } from './payments-routing.module';
import { SuborgPaymentSettingsTabComponent } from './components/suborg-payment-settings-tab/suborg-payment-settings-tab.component';
import { PayNowComponent } from './pages/pay-now/pay-now.component';
import { PaymentComponent } from './components/payment/payment.component';
import {MatCardModule} from '@angular/material/card';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { InvoicesPaymentHistoryComponent } from './components/invoices-payment-history/invoices-payment-history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MakePaymentComponent } from './pages/make-payment/make-payment.component';
import { CashCheckPaymentComponent } from './components/cash-check-payment/cash-check-payment.component';
import { AccountingPaymentsListComponent } from './pages/accounting-payments-list/accounting-payments-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountingPayNowComponent } from './pages/accounting-pay-now/accounting-pay-now.component';




@NgModule({
  declarations: [CustomersPaymentDetailsTabComponent, 
    AddCardComponent, 
    PaymentAccountSettingsComponent,
    SuborgPaymentSettingsTabComponent, 
    PayNowComponent, PaymentComponent, 
    PaymentSuccessComponent, 
    InvoicesPaymentHistoryComponent, 
    MakePaymentComponent, 
    CashCheckPaymentComponent, 
    AccountingPaymentsListComponent, AccountingPayNowComponent, 
  ],
  imports: [
    CommonModule,
    SharedModule,
    PaymentsRoutingModule,
    MatCardModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgxDatatableModule,
    NgbModule
  ],
  exports: [
    CustomersPaymentDetailsTabComponent,
    SuborgPaymentSettingsTabComponent,
    InvoicesPaymentHistoryComponent
  ]
})
export class PaymentsModule { }
