import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridConstants } from 'src/app/core/constants/grid.constants';
import { GFColumn } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { AdditionalFieldsDataHelper } from '../helpers/additional-fields-data.helpers';
import { additionalFieldCreate, additionalFieldsGet, additionalFieldsList, additionalFieldUpdate } from '../models/additional-fields.models';

@Injectable({
  providedIn: 'root'
})
export class AdditionFieldsService extends BaseService {

  constructor(private http: HttpClient, private ra:ResourceAccessService) { super() }




  getAll() {
      return this.http.get<{data:additionalFieldsList[]}>(`${this.baseURL}/additionalfield/`);
  }

  getById(id: number) {
      return this.http.get<additionalFieldsGet>(`${this.baseURL}/additionalfield/${id}/`);
  }

  create(model: additionalFieldCreate) {
      return this.http.post<additionalFieldCreate>(`${this.baseURL}/additionalfield/`, model);
  }

  update(id: number, model: additionalFieldUpdate) {
      return this.http.put<additionalFieldUpdate>(`${this.baseURL}/additionalfield/${id}/`, model);
  }

  patch(id: number, model: any) {
      // return this.http.patch<WareHouse>(`${this.baseURL}/warehouse/${id}/`, model);
  }

  delete(id: number) {
      return this.http.delete(`${this.baseURL}/additionalfield/${id}/`);
  }



  //Category additional field operations


  getCatAdditionalField(catId){
    return this.http.get<any>(`${this.baseURL}/category/custom/${catId}/`);

  }

  createCatAdditionalField(data){

    return this.http.post<additionalFieldCreate>(`${this.baseURL}/category/custom/`, data);

  }

  updateCatAdditionalField(catId,data){

    return this.http.put<any>(`${this.baseURL}/category/custom/update/${catId}/`, data);

  }


  //Item additional fieldds


  getItemAdditionalField(catId){
    return this.http.get<any>(`${this.baseURL}/item/custom/${catId}/`);

  }

  createItemAdditionalField(data){

    return this.http.post<additionalFieldCreate>(`${this.baseURL}/item/custom/`, data);

  }

  updateItemAdditionalField(catId,data){

    return this.http.put<any>(`${this.baseURL}/item/custom/update/${catId}/`, data);

  }


  //Assembly additional fields

  getAssemblyAdditionalField(catId){
    return this.http.get<any>(`${this.baseURL}/kit/custom/${catId}/`);

  }

  createAssemblyAdditionalField(data){

    return this.http.post<additionalFieldCreate>(`${this.baseURL}/kit/custom/`, data);

  }

  updateAssemblyAdditionalField(catId,data){

    return this.http.put<any>(`${this.baseURL}/kit/custom/update/${catId}/`, data);

  }



    /* This function validates all the 
       additional fields values entered.
    */
    
    validateAF(additional_data){

        let errorMsg="";
        var keepGoing = true;

        if(additional_data.length>0){

            additional_data.forEach(val=>{

                if(keepGoing){
                  if(val.action&&val.action!=3){

                    if(val.additional_field&&val.additional_field>0){
                      if(!val.value){
                          errorMsg="Please select values for all the additional fields added";
                          keepGoing=false;
                      }
                      }
                      else if(!val.custom_additional_field&&val.custom_additional_field==""){
                          
                          errorMsg="Please enter name for Custom additional field";
                          keepGoing=false;

                      }
                      else if(!val.custom_additional_field_value||val.custom_additional_field_value==""){
                          errorMsg="Please enter value for the Custom additional field";
                          keepGoing=false;
                      }

                  }

                }

            });
    

        }

        return errorMsg;


    }




}
