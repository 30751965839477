<div class="card">
    <div class="card-header">
        <h4 class="card-title">
            <b style="color: #370D7E;">{{invoiceId != 0 ? 'Edit ' : 'New '}}</b>
            <b style="color: #D52095;">Invoice</b>
        </h4>
    </div>
    <div class="card-content">
        <div class="card-body">
            <form #invoiceForm="ngForm" (ngSubmit)="add();">
                <div class="row">
                    <div class="col-12 col-lg-4 col-md-4">
                        <div class="group">
                            <div class="form-group">
                                <label class="val-required">Select customer name</label>
                                <!-- <select class="form-control" formControlName="customerName">
                                    <option>Select customer name</option>
                                </select> -->
                                <tenant-customer-select class="select-md"
                                    [(value)]="model.tenant_customer"
                                    (valueChange)="customerChanged(null)"
                                    (change)="customerChanged($event)">
                                </tenant-customer-select>
                                <p class="text-danger error-text" *ngIf="submitted&&model.tenant_customer==undefined">
                                    Please select
                                    customer</p>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Customer contact</label>
                                <tenant-customer-contact-select
                                    [tenantCustomerId]="model.tenant_customer"
                                    [(value)]="model.tenant_customer_contact"
                                    [readonly]="!model.tenant_customer"
                                    (change)="customerContactChange($event)"
                                    (locationAdd)="onCustomerLocationAdded()"
                                >
                                </tenant-customer-contact-select>
                                <p class="text-danger error-text"
                                    *ngIf="submitted&&!_saveAsDraft&&!model.tenant_customer_contact&&model.tenant_customer_contact==undefined">Please
                                    select customer contact</p>

                                <!-- <select class="form-control" formControlName="tenant_customer_contact">
                                    <option>Select customer contact</option>
                                </select> -->
                            </div>
                            <div class="form-group">
                                <label class="val-required">Customer location</label>
                                <tenant-customer-location-select
                                #locationSelect
                                [tenantCustomerId]="model.tenant_customer"
                                [(value)]="model.tenant_customer_location"
                                [readonly]="!model.tenant_customer_contact"
                                >
                                </tenant-customer-location-select>
                                <p class="text-danger error-text"
                                    *ngIf="submitted&&!_saveAsDraft&&model.tenant_customer_location==undefined">Please
                                    select customer location</p>
                            </div>

                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" [(ngModel)]="shippingLocation"  name="shipping_location" id="shipping_location" class=""
                                    />
                                    <label class="" for="shipping_location">Ship to a different location</label>
                                </div>

                            </div>
                            <div class="form-group" *ngIf="shippingLocation">
                                <label class="val-required">Shipping location</label>
                                <tenant-customer-location-select
                                #locationSelect
                                [tenantCustomerId]="model.tenant_customer"
                                [(value)]="model.shipping_location"
                                [readonly]="!model.tenant_customer_contact"
                                >
                                </tenant-customer-location-select>
                                <p class="text-danger error-text"
                                *ngIf="submitted&&!_saveAsDraft&&model.tenant_customer_location==undefined">Please
                                    select shipping location</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-md-4">
                        <div class="group">
                            <div class="form-group">
                                <label>Title</label>
                                <input type="text" [(ngModel)]="model.title" name="title" class="form-control"
                                    placeholder="Enter title for this document" />
                            </div>
                            <div class="form-group">
                                <label>Reference ID</label>
                                <input type="text" [(ngModel)]="model.reference_id" name="Reference Id" class="form-control"
                                    placeholder="Enter reference ID" />
                            </div>
                            <div class="form-group">
                                <label class="val-required">Payment terms</label>

                                <payment-term-select [(value)]="model.payment_terms"></payment-term-select>

                            </div>
                            <div class="form-group">
                                <label class="">Shipment method</label>

                                <shipping-methods-select
                                [(value)]="model.shipment_method"
                                [customerSmAcNo]="customerSmAccNo"
                                [customerSm]="customerSm"
                                ></shipping-methods-select>

                                <!-- <ng-select
                                bindLabel="name" bindValue="id"
                                placeholder="Select shipment method" name="sc_begins"
                                [(ngModel)]="model.sc_begins" >

                                </ng-select> -->

                                <!-- <select class="form-control" formControlName="tenant_customer_location">
                                    <option>Select customer location</option>
                                </select> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-md-4">
                        <div class="group">
                            <div class="form-group">
                                <label class="">Terms and Conditions</label>
                                <app-terms-select (change)="termsChange($event)" (valueChange)="termsValueChange($event)"
                                [selected]="model.terms_and_condition" [suborg_id]="model.suborg"></app-terms-select>
                                <!-- <p class="text-danger error-text" *ngIf="submitted&&(!model.terms_and_condition||model.terms_and_condition==0)">
                                    Select terms & conditions
                                </p> -->
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" [(ngModel)]="model.conditions" name="terms_and_condition"
                                            (ngModelChange)="model.conditions = $event" style="height: 200px;"
                                            placeholder="Terms and Conditions"></textarea>
                                <!-- <p class="text-danger error-text" *ngIf="submitted&&model.conditions&&model.conditions.length<1">Terms and
                                    conditions can
                                    not be empty</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">

                        <!-- <button type="button" class="btn gradient-pomegranate mb-2 mr-sm-2 mb-sm-0"
                                (click)="addSO();submitted=true">
                            {{invoiceId > 0 && model.status!=2 ? 'Save Changes' : invoiceId > 0 && model.status==2 ? 'Save draft': 'Create'}}
                        </button> -->

                        <button type="button" class="btn gradient-pomegranate mb-2 mr-sm-2 mb-sm-0"
                        (click)="add(model.status==1?true:false);submitted=true">
                            {{invoiceId > 0 && model.status!=1 ? 'Save Changes' : invoiceId > 0 && model.status==1 ? 'Save draft': 'Create'}}
                        </button>

                        <button type="button" (click)="add(true);" class="btn btn-secondary mb-2 mr-sm-2 mb-sm-0" *ngIf="model.status!=1">{{invoiceId > 0 ? 'Move to draft' : 'Save as draft'}}</button>
                        <button type="button" (click)="add(false, 8);" class="btn btn-secondary mb-2 mr-sm-2 mb-sm-0" *ngIf="invoiceId > 0 && model.status==1">Remove from draft</button>
                        <button type="button" (click)="location.back()" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
