import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";



export class CatalogCatDataHelper {

  static get defaults(): GFilterParam[] {
    return [
      { displayName: "All Categories", colname: '', condition: 'is', value: null, operator: 'AND' },
      { displayName: "Active Categories", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
      { displayName: "Inactive Categories", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
    ];
  }

  static get searchCatalogFilters(): GFilterParam[] {

    return [
      {
        displayName: 'Name or Prefix', colname: '', condition: '', value: '',
        children: [
          { displayName: 'Name', colname: 'categoryname', condition: 'contains', value: '', operator: 'OR' },
          // { displayName: 'Prefix', colname: 'categoryprefix', condition: 'contains', value: '', operator: 'OR' }
        ]
      },
      // { displayName: 'Parent Category', colname: 'parentname', condition: 'contains', value: '', operator: 'AND' },
    ];

  }

  static get searchProductDesignFilters(): GFilterParam[] {

    return [
      {
        displayName: 'Name or Prefix', colname: '', condition: '', value: '',
        children: [
          { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
          // { displayName: 'Prefix', colname: 'categoryprefix', condition: 'contains', value: '', operator: 'OR' }
        ]
      },
      // { displayName: 'Parent Category', colname: 'parentname', condition: 'contains', value: '', operator: 'AND' },
    ];

  }

  static get columns(): GFColumn[] {
    return [
      { displayName: 'Name', columnName: 'categoryname', columnType: GFColumnTypes.string },
      { displayName: 'Prefix', columnName: 'categoryprefix', columnType: GFColumnTypes.string },
      { displayName: 'Parent Category', columnName: 'parentname', columnType: GFColumnTypes.string },
      { displayName: 'Status', columnName: 'is_active', columnType: GFColumnTypes.boolean },
      { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date }
    ];
  }


}


export class CatalogueDataHelper {




  static get DefaultFilters(): GFilterParam[] {
    return [
      { displayName: "All", colname: '', condition: 'is', value: null, operator: 'AND' },
      { displayName: "Active", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
      { displayName: "Inactive", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' }
    ];
  }

  static get SortLookup(): any {
    return {
      name: 'name',
    };
  }

  static get componentDimention() {
    return {
      page: { width: 8.5, height: 11, title: 'Design catalog page', typeId: 2 },
      product: { width: 1, height: 1.5, title: 'Design a product', typeId: 1 },
      button: { width: 1, height: .5, title: 'Design catalog button', typeId: 3 },
    };
  }
  static get itemFormat() {
    return [
      { id: 1, title: 'Item Name', type: "text", name: "item_name" },
      { id: 2, title: 'QR Code', type: "img", name: "qr_code" },
      { id: 4, title: 'Warehouse', type: "text", name: "werehouse" },
      { id: 5, title: 'Warehouse location', type: "text", name: "werehouse_location" },
      { id: 6, title: 'Serial Number', type: "text", name: "serial_number" },
      { id: 7, title: 'SKU', type: "text", name: "sku" },
      { id: 8, title: 'Item Type', type: "text", name: "item_type" },
      { id: 9, title: 'Item Category', type: "text", name: "category" },
      { id: 10, title: 'Manufacturer', type: "text", name: "manufacturer" },
      { id: 11, title: 'Part number', type: "text", name: "part_number" },
      { id: 12, title: 'Sell Price', type: "text", name: "sell_price" },
      { id: 13, title: 'Supplier', type: "text", name: "supplier" },
      { id: 13, title: 'Item Image', type: "img", name: "item_image" },
      { id: 13, title: 'Button', type: "button", name: "button_primary" },
    ];
  }

  static get builtAssemblyFormat() {
    return [
      { id: 1, title: 'Built-assembly Name', type: "text", name: "assembly_name" },
      { id: 2, title: 'QR Code', type: "img", name: "qr_code" },
      { id: 4, title: 'Warehouse', type: "text", name: "werehouse" },
      { id: 5, title: 'Warehouse location', type: "text", name: "werehouse_location" },
      { id: 6, title: 'Serial Number', type: "text", name: "serial_number" },
      { id: 7, title: 'SKU', type: "text", name: "sku" },
      { id: 8, title: 'Assembly Type', type: "text", name: "item_type" },
      { id: 9, title: 'Assembly Category', type: "text", name: "category" },
      { id: 10, title: 'Manufacturer', type: "text", name: "manufacturer" },
      { id: 11, title: 'Part number', type: "text", name: "part_number" },
      { id: 12, title: 'Sell Price', type: "text", name: "sell_price" },
      { id: 13, title: 'Supplier', type: "text", name: "supplier" },
      { id: 13, title: 'Built Assembly Image', type: "img", name: "item_image" },

    ];

  }
  static get assemblyFormat() {

    return [
      { id: 1, title: 'Assembly Name', type: "text", name: "assembly_name" },
      { id: 2, title: 'QR Code', type: "img", name: "qr_code" },
      { id: 4, title: 'Warehouse', type: "text", name: "werehouse" },
      { id: 5, title: 'Warehouse location', type: "text", name: "werehouse_location" },
      { id: 6, title: 'Serial Number', type: "text", name: "serial_number" },
      { id: 7, title: 'SKU', type: "text", name: "sku" },
      { id: 8, title: 'Assembly Type', type: "text", name: "item_type" },
      { id: 9, title: 'Assembly Category', type: "text", name: "category" },
      { id: 10, title: 'Manufacturer', type: "text", name: "manufacturer" },
      { id: 11, title: 'Part number', type: "text", name: "part_number" },
      { id: 12, title: 'Sell Price', type: "text", name: "sell_price" },
      { id: 13, title: 'Supplier', type: "text", name: "supplier" },
      { id: 13, title: 'Assembly Image', type: "img", name: "item_image" },

    ];
  }

  static get dummyData() {
    return {
      item_name: "ASUS TUF Gaming NVIDIA GeForce GTX 1650 OC Edition Graphics Card (PCIe 3.0, 4GB GDDR6 Memory, HDMI, DisplayPort, DVI-D, 1x 6-pin Power Connector, IP5X Dust Resistance, Space-Grade Lubricant)",
      assembly_name: "ASUS TUF Gaming NVIDIA GeForce GTX 1650 OC Edition Graphics Card (PCIe 3.0, 4GB GDDR6 Memory, HDMI, DisplayPort, DVI-D, 1x 6-pin Power Connector, IP5X Dust Resistance, Space-Grade Lubricant)",
      werehouse: "Bonadelle Ranchos-Madera Ranchos, California",
      werehouse_location: "Build Alpha, second floor, thired block, fourth rack",
      serial_number: '8ASD9F8S',
      sku: 'AS8DFSADF98ASDF',
      item_type: 'Assembly',
      category: 'Manufacturing Products',
      part_number: '8A9SDF8AS9DF',
      sell_price: '23823923',
      supplier: 'KINGS GROUP SUPPLIERS OF UNITED STATES',
      item_image_url: '../../../../assets/img/catalogue/product-image.jpg'
    }
  }


  static get samplePageTemplate() {
    return {
      "objects": [
        {
          "top": 278.76,
          "fill": "#cccccc",
          "left": 414.23,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.78,
          "scaleY": 1.78,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 277.48,
          "fill": "#cccccc",
          "left": 621.3,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.72,
          "scaleY": 1.72,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 277.48,
          "fill": "#cccccc",
          "left": 818.23,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.67,
          "scaleY": 1.67,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 21.28,
          "fill": "#cccccc",
          "left": 199.05,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.73,
          "scaleY": 1.73,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 18.72,
          "fill": "#cccccc",
          "left": 388.96,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.75,
          "scaleY": 1.75,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 18.72,
          "fill": "#cccccc",
          "left": 601.49,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.73,
          "scaleY": 1.73,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 18.72,
          "fill": "#cccccc",
          "left": 809.6,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.71,
          "scaleY": 1.71,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 282.6,
          "fill": "#cccccc",
          "left": 215.69,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.79,
          "scaleY": 1.79,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 25.12,
          "fill": "#cccccc",
          "left": 15.57,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.64,
          "scaleY": 1.64,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        },
        {
          "top": 283.88,
          "fill": "#cccccc",
          "left": 19.73,
          "name": "productComponent",
          "type": "group",
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "skewX": 0,
          "skewY": 0,
          "width": 97.35,
          "height": 116.2,
          "scaleX": 1.74,
          "scaleY": 1.74,
          "shadow": null,
          "stroke": null,
          "objects": [
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "#ffffff",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96,
              "height": 115.2,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": {
                "blur": 5,
                "color": "#999999",
                "offsetX": 0,
                "offsetY": 0,
                "nonScaling": false,
                "affectStroke": false
              },
              "stroke": null,
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "rx": 0,
              "ry": 0,
              "top": -58.1,
              "fill": "rgb(0,0,0)",
              "left": -48.68,
              "type": "rect",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 1,
              "height": 1,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "opacity": 0,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 1,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "top": 8.4,
              "fill": "rgb(0,0,0)",
              "left": -46.85,
              "name": "item_name",
              "path": null,
              "text": "<Item Name>",
              "type": "textbox",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 93.33,
              "height": 34.36,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "styles": {},
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "fontSize": 12,
              "minWidth": 20,
              "overline": false,
              "pathSide": "left",
              "direction": "ltr",
              "fontStyle": "normal",
              "pathAlign": "baseline",
              "textAlign": "left",
              "underline": false,
              "fontFamily": "Times New Roman",
              "fontWeight": "normal",
              "lineHeight": 1.16,
              "paintFirst": "fill",
              "charSpacing": 0,
              "linethrough": false,
              "strokeWidth": 1,
              "customHeight": 34.36043544916251,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "pathStartOffset": 0,
              "splitByGrapheme": false,
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "textBackgroundColor": "",
              "globalCompositeOperation": "source-over"
            },
            {
              "top": -57.6,
              "fill": "#cccccc",
              "left": -48.18,
              "name": "productComponent",
              "type": "group",
              "angle": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 96.85,
              "height": 111.29,
              "scaleX": 1,
              "scaleY": 1,
              "shadow": null,
              "stroke": null,
              "objects": [
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 0,
                  "ry": 0,
                  "top": -55.64,
                  "fill": "rgb(0,0,0)",
                  "left": -48.43,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 1,
                  "height": 1,
                  "scaleX": 1,
                  "scaleY": 1,
                  "shadow": null,
                  "stroke": null,
                  "opacity": 0,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "rx": 10,
                  "ry": 10,
                  "top": 41.35,
                  "fill": "green",
                  "left": -11.13,
                  "type": "rect",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 100,
                  "height": 25,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": {
                    "blur": 5,
                    "color": "#999999",
                    "offsetX": 0,
                    "offsetY": 0,
                    "nonScaling": false,
                    "affectStroke": false
                  },
                  "stroke": null,
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "paintFirst": "fill",
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "globalCompositeOperation": "source-over"
                },
                {
                  "top": 46.35,
                  "fill": "#fff",
                  "left": 3.87,
                  "path": null,
                  "text": "Add to Cart",
                  "type": "textbox",
                  "angle": 0,
                  "flipX": false,
                  "flipY": false,
                  "skewX": 0,
                  "skewY": 0,
                  "width": 80,
                  "height": 13.56,
                  "scaleX": 0.55,
                  "scaleY": 0.55,
                  "shadow": null,
                  "stroke": null,
                  "styles": {},
                  "opacity": 1,
                  "originX": "left",
                  "originY": "top",
                  "version": "5.2.1",
                  "visible": true,
                  "fillRule": "nonzero",
                  "fontSize": 12,
                  "minWidth": 20,
                  "overline": false,
                  "pathSide": "left",
                  "direction": "ltr",
                  "fontStyle": "normal",
                  "pathAlign": "baseline",
                  "textAlign": "left",
                  "underline": false,
                  "fontFamily": "Times New Roman",
                  "fontWeight": "normal",
                  "lineHeight": 1.16,
                  "paintFirst": "fill",
                  "charSpacing": 0,
                  "linethrough": false,
                  "strokeWidth": 1,
                  "strokeLineCap": "butt",
                  "strokeUniform": false,
                  "strokeLineJoin": "miter",
                  "backgroundColor": "",
                  "pathStartOffset": 0,
                  "splitByGrapheme": false,
                  "strokeDashArray": null,
                  "strokeDashOffset": 0,
                  "strokeMiterLimit": 4,
                  "textBackgroundColor": "",
                  "globalCompositeOperation": "source-over"
                }
              ],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            },
            {
              "src": "http://localhost:4200/assets/img/catalogue/item-image-placeholder.jpeg",
              "top": -57.59,
              "fill": "rgb(0,0,0)",
              "left": -48.18,
              "name": "item_image",
              "type": "image",
              "angle": 0,
              "cropX": 0,
              "cropY": 0,
              "flipX": false,
              "flipY": false,
              "skewX": 0,
              "skewY": 0,
              "width": 800,
              "height": 540,
              "scaleX": 0.12,
              "scaleY": 0.12,
              "shadow": null,
              "stroke": null,
              "filters": [],
              "opacity": 1,
              "originX": "left",
              "originY": "top",
              "version": "5.2.1",
              "visible": true,
              "fillRule": "nonzero",
              "paintFirst": "fill",
              "crossOrigin": null,
              "strokeWidth": 0,
              "strokeLineCap": "butt",
              "strokeUniform": false,
              "strokeLineJoin": "miter",
              "backgroundColor": "",
              "strokeDashArray": null,
              "strokeDashOffset": 0,
              "strokeMiterLimit": 4,
              "globalCompositeOperation": "source-over"
            }
          ],
          "opacity": 1,
          "originX": "left",
          "originY": "top",
          "version": "5.2.1",
          "visible": true,
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "strokeWidth": 0,
          "strokeLineCap": "butt",
          "strokeUniform": false,
          "strokeLineJoin": "miter",
          "backgroundColor": "",
          "strokeDashArray": null,
          "strokeDashOffset": 0,
          "strokeMiterLimit": 4,
          "globalCompositeOperation": "source-over"
        }
      ],
      "version": "5.2.1"
    };
  }
}
