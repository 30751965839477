<div class="docList">


    <div class="d-flex">
        <form class="mr-1 w-100" (submit)="getWarranty(true);">
            <div class="input-group input-clear input-group-sm mb-0 mr-1">
                <input type="text" [(ngModel)]="search" name="search" class="form-control search-input" placeholder="Search Warranty" />


                <div class="input-group-append">
                    <button [hidden]="!search.length" type="button" (click)="search='';getWarranty(true);" class="input-clear-btn btn btn-sm">
                        <i class="ft-x"></i>
                    </button>
                <button class="btn btn-outline-secondary"  type="submit" ><i class="ft ft-search"></i></button>
                </div>
            </div>
        </form>
        <button class="btn btn-secondary btn-sm mb-2 mr-1 filter-btn-active" [ngClass]="{'filter-btn-active':filterActive}" type="button" (click)="toggleFilter()"><i class="ft-filter"></i></button>

        <button class="btn btn-primary btn-sm mb-2">New</button>

    </div>

    <div class="d-flex flex-wrap mb-1 filter-row" *ngIf="showFilter">

        <tenant-customer-contact-select 
        [tenantCustomerId]="_customerId"
        [readonly]="false"
        [addButton]="false"
        [size]="'sm'"
        [value]="customerContact"
        (change)="customerContactChange($event)"
        class="mr-2 mb-1"
        (clear)="customerContact=null;getWarranty()"
        >
        </tenant-customer-contact-select>

        <tenant-customer-location-select 
        #locationSelect
        style="min-width:200px"
        [size]="'sm'"
        [addButton]="false"
        [value]="customerLocation"
        (change)="customerLocationChange($event)"
        (clear)="customerLocation=null;getWarranty()"
        [tenantCustomerId]="_customerId"
         class="mr-1 mb-1"
        >
        </tenant-customer-location-select>

        <ng-select
            style="min-width:150px"
            placeholder="Select Coverate type"
            class="ng-select-sm mr-1 mb-1" 
            [items]="coverateTypeList"
            [(ngModel)]="coverageType"
            bindLabel="label"
            bindValue="value"
            >
        </ng-select>

        <ng-select
            style="min-width:200px"
            placeholder="Select status"
            class="ng-select-sm mr-1 mb-1" 
            [items]="statusList"
            [(ngModel)]="status"
            bindLabel="displayName"
            bindValue="value"
            >
        </ng-select>


        <button class="btn btn-info btn-sm mr-2 mb-1" (click)="getWarranty(false)">Apply Filter</button>
        <a class="text-warning pt-1 text-sm mb-1" (click)="resetFilter()">Reset</a>


    </div>

    <p class="mt-2 mb-2 no-record" *ngIf="warranty&&warranty?.length==0">Sorry, No warranty to display</p>

    
    <div class="d-flex doc-list-row">

        <div class="doc-box" *ngFor="let wa of warranty">

            <div class="doc-body">

               <div class="doc-row d-flex">
                    <div class="info-box">
                        <label>#</label>
                        <p>{{wa.unique_number}}</p>
                    </div>
                    <!-- <div class="info-box">
                        <label>Price</label>
                        <p><strong></strong></p>
                    </div> -->
               </div>


               <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Customer name</label>
                        <p>{{wa.tenant_customer_name}}</p>
                    </div>
                    <div class="info-box">
                        <label>Created contact name</label>
                        <p>{{wa.tenant_customer_contact_name}}</p>
                    </div>

                </div>
                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Customer location</label>
                        <p>{{wa.tenant_customer_location_name}}</p>
                    </div>
                    <div class="info-box">
                        <label>Coverage Type</label>
                        <p>{{coverageTypes[wa.coverage_type]}}</p>
                    </div>

                </div>

                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Duration</label>
                        <p class="">{{wa.starting_date|date:'MM-dd-YYYY'}} To {{wa.ending_date|date:'MM-dd-YYYY'}}</p>
                    </div>
                    <div class="info-box">
                        <label>Status</label>
                        <p class="" ><span class="badge" [class]="getStatusClass(wa.warranty_status)">{{getStatusString(wa.warranty_status)}}</span></p>
                    </div>

                 </div>

                <div class="doc-row d-flex">

                    <div class="info-box">
                        <label>Created On</label>
                        <p>{{wa.created_on|date:'mm-dd-YYYY hh:mm a'}}</p>
                    </div>
                    <div class="info-box">
                        <label>Created By</label>
                        <p>{{wa.created_by}}</p>
                    </div>

               </div>





            </div>

            <div class="doc-footer mt-1">
                <ul class="d-flex justify-content-between">
                    <li><i class="ft-eye"></i> View</li>
                    <!-- <li><i class="ft-printer"></i></li>
                    <li><i class="ft-edit-2"></i></li>
                    <li class="text-warning"><i class="ft-trash-2"></i></li> -->
                </ul>

            </div>


        </div>

        <!-- <div class="invoice-box">

        </div> -->
        
    </div>
</div>