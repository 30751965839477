
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubOrgsComponent } from './pages/sub-orgs/sub-orgs.component';
import { SUAdminRouteGuard } from 'src/app/core/gaurd/su-or-admin.route.gaurd';
import { AddSubOrgComponent } from './pages/add-sub-org/add-sub-org.component';

const routes: Routes = [
    {
        path: '',
        component: SubOrgsComponent,
        canActivate: [SUAdminRouteGuard],
        data: { title: 'Sub Orgs' }
    },
    {
        path: 'new',
        pathMatch: 'full',
        component: AddSubOrgComponent,
        canActivate: [SUAdminRouteGuard],
        data: { title: 'Add Suborg' }
    },
    {
        path: ':id/edit',
        pathMatch: 'full',
        component: AddSubOrgComponent,
        canActivate: [SUAdminRouteGuard],
        data: { title: 'Edit Suborg' }
    },
    {
        path: ':id/view',
        pathMatch: 'full',
        component: SubOrgsComponent,
        canActivate: [SUAdminRouteGuard],
        data: { title: 'Suborgs' }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SuborgRoutingModule { }
