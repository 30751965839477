<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter
                        [searchFilters]="contacts.searchFilters"
                        [columns]="contacts.columns"
                        [newButton]="contacts.hasPermission(Permissions.CREATE)"
                        newButtonText="Add Contact"
                        [refresh]="false"
                        [advanceFilter]="contacts.advanceFilter"
                        [dontDisableIfAllsuborg]="true"
                        (newButtonClick)="contacts.openForm()"
                        (apply)="contacts.applyFilter($event,false,false,dataGridFilter)">
                    <ng-template filterControl [column]="'created_user'" let-row="row">
                        <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                    </ng-template>
                    <ng-template filterControl [column]="'location'" let-row="row">
                    <tenant-customer-location-select [tenantCustomerId]="tenantCustId" [addButton]="false" class="ng-select-sm" [(value)]="row.value"></tenant-customer-location-select>
                </ng-template>
                </datagrid-filter>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <customer-contacts #contacts [showFilter]="false" [showDataTableSection]="true"></customer-contacts>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Add New Block number</h5>
            <button type="button" class="close" aria-label="Close" (click)="dismissModal(modal)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="d-flex justify-content-between">
                    <input type="text" mask="(000) 000-0000" id="blockNumberField" [(ngModel)]="enteredNumber" placeholder="Enter Phone Number" name="name" class="form-control"
                    (keyup.enter)="searchFilterApply()" required>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button [disabled]="enteredNumber&&enteredNumber.length!=10" class="btn gradient-pomegranate"
                    (click)="blockNumber()">Create</button>
            <button type="button" class="btn" (click)="dismissModal(modal)">Cancel</button>
        </div>
    </ng-template> -->
</section>
