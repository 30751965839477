import { ItemSupplierMap, Supplier } from './../models/supplier';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { DataFilterResponse } from 'src/app/core/models/grid-filter.models';

@Injectable({
    providedIn: 'root'
})
export class SupplierService extends BaseService {

    constructor(private http: HttpClient) { super() }

    getAll() {
        return this.http.get<Supplier[]>(`${this.baseURL}/suppliers/`);
    }

    getAllFiltered(option: any, offset: number = 0, limit: number = this.dataLimit) {
        let url = `${this.baseURL}/suppliers/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<Supplier[]>>(url, option);
    }

    getById(id: number) {
        return this.http.get<Supplier>(`${this.baseURL}/suppliers/${id}/`);
    }

    create(supplier: Supplier) {
        return this.http.post<Supplier>(`${this.baseURL}/suppliers/`, supplier);
    }

    update(id: number, item: Supplier) {
        return this.http.put<Supplier>(`${this.baseURL}/suppliers/${id}/`, item);
    }

    patch(id: number, request: any) {
        return this.http.patch<Supplier>(`${this.baseURL}/suppliers/${id}/`, request);
    }

    delete(id: number) {
        return this.http.delete<Supplier>(`${this.baseURL}/suppliers/${id}/`);
    }

    getItemSupplierMaps() {
        return this.http.get<ItemSupplierMap[]>(`${this.baseURL}/items/supplier/`);
    }

    getItemSupplierMapById(id: number) {
        return this.http.get<ItemSupplierMap>(`${this.baseURL}/items/supplier/${id}/`);
    }

    createItemSupplierMap(itemMap: ItemSupplierMap) {
        return this.http.post<ItemSupplierMap>(`${this.baseURL}/items/supplier/`, itemMap);
    }

    updateItemSupplierMap(id: number, itemMap: ItemSupplierMap) {
        return this.http.put<ItemSupplierMap>(`${this.baseURL}/items/supplier/${id}/`, itemMap);
    }

    deleteItemSupplierMap(id: number) {
        return this.http.delete<ItemSupplierMap>(`${this.baseURL}/items/supplier/${id}/`);
    }

    getSuppliersByItem(itemid: number) {
        return this.http.get<ItemSupplierMap[]>(`${this.baseURL}/items/${itemid}/suppliers/`);
    }
}
