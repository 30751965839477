import { KitItem, Kit2Kit, KitWareHouseAggregate } from './../models/kit.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Kit, KitWrapper } from '../models/kit.models';
import { DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { Item } from '../models/items';
import { StaticFile } from 'src/app/core/models/files.models';

@Injectable({
    providedIn: 'root'
})
export class KitService extends BaseService {

    constructor(private http: HttpClient) { super() }

    getAllFiltered(option: any, offset: number = 0, suborg_id:number = this.suborgId) {
        let url = `${this.baseURL}/kits/filter/?limit=${this.dataLimit}&offset=${offset}&suborg=${suborg_id}`;
        return this.http.post<DataFilterResponse<KitWrapper[]>>(url, option);
    }

    getById(id: number) {
        return this.http.get<KitWrapper>(`${this.baseURL}/kits/${id}/`);
    }

    create(kit: Kit) {
        return this.http.post<Kit>(`${this.baseURL}/kits/`, kit);
    }

    update(id: number, kit: Kit) {
        return this.http.put<Kit>(`${this.baseURL}/kits/${id}/`, kit);
    }

    patch(id: number, request: any) {
        return this.http.patch<Kit>(`${this.baseURL}/kits/${id}/`, request);
    }

    delete(id: number) {
        return this.http.delete(`${this.baseURL}/kits/${id}/`);
    }

    getItemsByKit(kitid: number) {
        return this.http.get<KitItem[]>(`${this.baseURL}/kits/${kitid}/items/`);
    }

    addKitItems(kitid: number, ki: KitItem[]) {
        return this.http.post(`${this.baseURL}/kits/${kitid}/items/`, ki);
    }

    updateKitItems(kitid: number, ki: KitItem[]) {
        return this.http.put(`${this.baseURL}/kits/${kitid}/items/`, ki);
    }

    deleteKitItem(kitid: number, id: number) {
        return this.http.delete(`${this.baseURL}/kits/${kitid}/items/?id=${id}`);
    }

    getKitsInKit(kitid: number) {
        return this.http.get<Kit2Kit[]>(`${this.baseURL}/kits/${kitid}/kits/`);
    }

    addKits2Kit(kitid: number, ki: Kit2Kit[]) {
        return this.http.post(`${this.baseURL}/kits/${kitid}/kits/`, ki);
    }

    updateKits2Kit(kitid: number, ki: Kit2Kit[]) {
        return this.http.put(`${this.baseURL}/kits/${kitid}/kits/`, ki);
    }

    deleteKits2Kit(kitid: number, id: number) {
        return this.http.delete(`${this.baseURL}/kits/${kitid}/kits/?id=${id}`);
    }

    convertToItem(kitid: number) {
        return this.http.get<Item>(`${this.baseURL}/kits/${kitid}/convert_to_item/`);
    }

    getLocationWiseStockByKit(kitId: number) {
        return this.http.get<KitWareHouseAggregate[]>(`${this.baseURL}/kits/${kitId}/stock/`);
    }

    getKitImages(kitId: number) {
        return this.http.get<StaticFile[]>(`${this.baseURL}/kits/${kitId}/images/`);
    }

    getKitStockInfo(kitId: number){
        return this.http.get<any>(`${this.baseURL}/kits/${kitId}/info/stock/`);
    }
}
