import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SuborgsPaymentAccountSettings } from '../../models/payments-model';
import { PaymentsService } from '../../services/payments.service';
import { SubSink } from 'subsink';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-account-settings',
  templateUrl: './payment-account-settings.component.html',
  styleUrls: ['./payment-account-settings.component.scss']
})
export class PaymentAccountSettingsComponent implements OnInit {

  public subs: SubSink = new SubSink();
  public expanded: boolean = true;
  public settings: SuborgsPaymentAccountSettings;

  constructor(public services: PaymentsService,
    public location: Location,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.settings = new SuborgsPaymentAccountSettings();
    this.settings.is_over_payment = false;
    this.settings.is_partial_payment = false;

    if (this.services.suborgId > 0) {
      this.getSettings();
    }
  }

  getSettings() {

    this.subs.sink = this.services.getSuborgsPaymentAccountSettings(this.services.suborgId).subscribe(
      resp => {
        this.settings = resp;
      },
      err => {
        this.toastr.error('error')
      }
    );

  }

  updateSettings() {

    if (this.services.suborgId > 0) {

      this.subs.sink = this.services.updateSuborgsPaymentAccountSettings(this.settings, this.services.suborgId).subscribe(
        resp => {
          this.toastr.success('Settings updated successfully')
        },
        err => {
          this.toastr.error('error')
        }
      );

    }else{
      this.toastr.error('Switch Suborg')
    }
  }

}
