<div class="select-wrapper">
    <ng-select ngClass="ng-select-custom-footer"
               [placeholder]="placeholder"
               [items]="options | async"
               bindLabel="name"
               bindValue="id" [(ngModel)]="selected"
               [typeahead]="textInput"
               [trackByFn]="trackByFn"
               (clear)="reset()"
               typeToSearchText="Please enter 2 or more characters"
               (open)="selectOpen()"
               (clear)="onClear()"
               (search)="onSearch()">
        <ng-template ng-option-tmp let-item="item">
                {{item.name}} <span *ngIf="item.is_global" class="badge bg-warning">Global</span>
        </ng-template>       
        <ng-template ng-footer-tmp>
            <div class="footer-container p-1 d-flex flex-row justify-content-between">
                <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
                <a *ngIf="addButton && hasPermission()" class="add-button text-info" (click)="openModal(content)">
                    <i class="icon icon-settings"></i> Add Product Type
                </a>
            </div>
        </ng-template>
    </ng-select>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Product Type</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <product-type-form (success)="onDataSubmittedSuccess($event)" (error)="onDataError($event)"
                               (cancel)="onCancel()"></product-type-form>
        </div>
    </ng-template>
</div>
