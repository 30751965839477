import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef, Component, HostListener, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../core/services/config.service';
import { LayoutService } from '../../../core/services/layout.service';
import { RouteInfo } from 'src/app/core/models/route-info';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';
import { AppConstants } from 'src/app/core/constants/app.constants';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

    public menuItems: RouteInfo[];

    public config: any = {};

    protected innerWidth: any;

    private layoutSub$: Subscription;

    private configSub$: Subscription;

    public collapseSidebar = false;

    public resizeTimeout: any;

    public appVersion: string = environment.appVersion;

    public subs:SubSink=new SubSink();

    public toggleDelayedRouterList =['catalog/design','catalog/design-component']


    constructor(
        private layoutService: LayoutService,
        private configService: ConfigService,
        private cdr: ChangeDetectorRef,
        private ra: ResourceAccessService,
        private router: Router,
        private activeRoute:ActivatedRoute,
        private location:Location, 
        private commonService:CommonService
    ) {
        this.config = this.configService.templateConf;
        this.innerWidth = window.innerWidth;     
    }

    ngOnInit() { 
        
        this.menuItems = this.ra.getRoutes(); 

        // console.log(this.menuItems);

        this.sideBarCollapseForCatalogue();
    }

    ngAfterViewInit() {
        this.configSub$ = this.configService.templateConf$.subscribe((templateConf) => {
            if (templateConf) { this.config = templateConf; }
            this.cdr.markForCheck();
        });
        this.layoutSub$ = this.layoutService.overlaySidebarToggle$.subscribe(collapse => {

            this.collapseSidebar = collapse;
            // this.commonService.mainNavStatus=collapse;
        });

        let currentUrl=this.router.url;

        this.checkUrlIncludesDelayedRoute(this.toggleDelayedRouterList,currentUrl)
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event: any) {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout((() => {
            this.innerWidth = event.target.innerWidth;
        }).bind(this), 500);
    }
    checkUrlIncludesDelayedRoute(array, targetString) {
        for (let i = 0; i < array.length; i++) {
          if (targetString.includes(array[i])) {
         
         
            let conf = this.config;
            conf.layout.sidebar.toggleDelay = true;
            this.configService.applyTemplateConfigChange({ layout: conf.layout });
            break

          }
          else
          {
            let conf = this.config;
            conf.layout.sidebar.toggleDelay = false;
            this.configService.applyTemplateConfigChange({ layout: conf.layout });
          }
        }
       
      }
    sideBarCollapseForCatalogue(){

        let currentUrl=this.router.url;

        if(this.router.url&&this.router.url.length){
            let urlScrums=this.router.url.split('/');
            // console.log(urlScrums);

            if(urlScrums[1]=="inventory"&&urlScrums[2]=="catalog"&&urlScrums[3]!='list'&&((urlScrums[4]&&urlScrums[4]!='view')||!urlScrums[4])){
                // console.log('You are herer');
                this.toggleSidebar(true);

            }   
            else if(urlScrums[1]=='dispatch'&&urlScrums[2]=='board'){
                this.toggleSidebar(true);


            }
        }

        this.router.events
        // .pipe(filter(event => event instanceof NavigationEnd))  
                .subscribe((event)=>{
                    if(event instanceof NavigationEnd) {

                       

                            let urlScrums=event.urlAfterRedirects.split('/');
                            let current=currentUrl.split('/');
                            // console.log(urlScrums);
                
                            if(urlScrums[1]=="inventory"&&urlScrums[2]=="catalog"&&urlScrums[3]!='list'){
                                // console.log('You are herer');
                                this.toggleSidebar(true);
                
                            }
                            else if(current[1]=="inventory"&&current[2]=="catalog"&&current[3]!='list'){
                                this.toggleSidebar(false);

                            }   

                            else if(current[1]=="dispatch"&&current[2]=="board"){
                                this.toggleSidebar(false);

                            }   
                        
                        currentUrl=event.urlAfterRedirects;

                        this.checkUrlIncludesDelayedRoute(this.toggleDelayedRouterList,currentUrl)

                    }
                }


            );

    }

    toggleSidebar(forceCollapsed=null) {
        const conf = this.config;
        if(forceCollapsed!=null){
            conf.layout.sidebar.collapsed=forceCollapsed;
            this.commonService.mainNavStatus=forceCollapsed;
        }
        else{
            conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
            this.commonService.mainNavStatus=conf.layout.sidebar.collapsed;
        }

        this.configService.applyTemplateConfigChange({ layout: conf.layout });
        setTimeout(() => this.fireRefreshEventOnWindow(), 300);
    }

    fireRefreshEventOnWindow = function () {
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent("resize", true, false);
        window.dispatchEvent(evt);
    };

    closeSidebar() { 
        this.layoutService.toggleSidebarSmallScreen(false);
        // this.commonService.mainNavStatus=false;
     }

    isLinkVisible(menuItem: RouteInfo) {
   
        // let userTypeAllowed=false;
        // let userType= localStorage.getItem(AppConstants.USER_TYPE);
        // if(menuItem.userTypes.length){
        //         menuItem.userTypes.forEach((val)=>{
        //             if(val==userType){
        //                 userTypeAllowed=true;
        //             }
        //         })
        // }
        return (menuItem.path || menuItem.submenu.some(mi => mi.path || mi.submenu.length > 0));
    }

    navigateByUrl(event: any, url: string) {
        event.stopPropagation();
        event.preventDefault();
        this.router.navigate([url]);
    }

    ngOnDestroy() {
        this.layoutSub$?.unsubscribe();
        this.configSub$?.unsubscribe();
        this.subs.unsubscribe();
    }
}
