<section class="add-item col-12 col-md-8">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">{{sid ? 'Edit Incoming Routes' : 'Add Incoming Routes'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <forward-bin-form
                    [sid]="sid"
                    (success)="onDataSuccess($event)"
                    (error)="onDataError($event)"
                    (cancel)="onCancel()">
                </forward-bin-form>
            </div>
        </div>
    </div>
</section>
