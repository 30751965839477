import { SystemRoles } from './../../../core/enums/user-types';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Role, RoleWrapper } from '../models/role';
import { RolePrivilege } from '../models/role-previlege';

@Injectable({
    providedIn: 'root'
})
export class RoleService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getAll() {
        return this.http.get<RoleWrapper[]>(`${this.baseURL}/roles/`);
    }

    rolesByCustomer(customerId:number) {
        return this.http.get<Role[]>(`${this.baseURL}/roles_by_customer/${customerId}/`);
    }

    getById(id: number) {
        return this.http.get<Role>(`${this.baseURL}/roles/${id}/`);
    }

    create(role: Role) {
        return this.http.post<Role>(`${this.baseURL}/roles/`, role);
    }

    update(id: number, role: Role) {
        return this.http.put<Role>(`${this.baseURL}/roles/${id}/`, role);
    }

    delete(id: number) {
        return this.http.delete<Role>(`${this.baseURL}/roles/${id}/`);
    }

    clone(roleid: number) {
        return this.http.post<any>(`${this.baseURL}/roles/cloner/`, { id: roleid });
    }

    patch(id: number, req: any) {
        return this.http.patch<Role>(`${this.baseURL}/roles/${id}/`, req);
    }

    getPrivileges(roleid: number) {
        return this.http.get<RolePrivilege[]>(`${this.baseURL}/role/${roleid}/privileges/`);
    }

    getPrivilegeById(roleid: number, previd: number) {
        return this.http.get<RolePrivilege>(`${this.baseURL}/role/${roleid}/privileges/${previd}/`);
    }

    createPrivileges(prev: RolePrivilege) {
        return this.http.post<RolePrivilege>(`${this.baseURL}/role/${prev.role}/privileges/`, prev);
    }

    patchPrevilage(id: number, roleid: number, req: any) {
        return this.http.patch<RolePrivilege>(`${this.baseURL}/role/${roleid}/privileges/${id}/`, req);
    }

    deletePrivilege(roleid: number, id: number) {
        return this.http.delete(`${this.baseURL}/role/${roleid}/privileges/${id}/`);
    }

    isSystemRole(role: Role) {
        return Object.values(SystemRoles).includes(role.name as unknown as SystemRoles);
    }
}
