<section class="datagrid-filter-container">
    <div class="filter-header">
        <div class="header-left-child">
            <div class="title" *ngIf="title">
                {{title || 'Untitled'}}
            </div>
            <div class="title-dropdown" *ngIf="!title && defaultFilters && showDefaultFilter">
                <div ngbDropdown container="body">
                    <button class="cursor-pointer mr-2 title-dropdown-button"
                        [ngbTooltip]="defaultSelected?.displayName" placement="bottom" ngbDropdownToggle>
                        <span style="color: #370D7E;">{{defaultSelected?.displayName.split(" ")[0]}}</span>
                        <span style="color: #D52095;white-space: break-spaces;"> {{defaultSelected?.displayName.split(" ").slice(1).join(' ')}}</span>
                    </button>
                    <div ngbDropdownMenu>
                        <button *ngFor="let opt of defaultFilters" class="w-100" (click)="onDefaultFilterChanged(opt)"
                            ngbDropdownItem>
                            {{opt.displayName}}
                        </button>
                    </div>
                </div>
            </div>
            <button *ngIf="refresh" class="refresh-button" (click)="resetFilter()">
                <i class="icon-refresh"></i> 
            </button>

<!-- 
            <div class="repport-type-container">
                <div class="report-type-box mr-2" *ngIf = "showreportType">
                   
                    <a class="type-switch" [ngClass]="{'active-inbound' :reportTypeData.type1Det.active == true}" 
                    (click)="onChangReportType(reportTypeData.type1Det)">
                         <img src="../../../../assets/img/svg/incomingcall.svg" alt="">{{reportTypeData.type1Det.title}}
                    </a>
                    <a class="type-switch" [ngClass]="{'active-outbound' :reportTypeData.type2Det.active == true}"  
                    (click)="onChangReportType(reportTypeData.type2Det)">
                         <img src="../../../../assets/img/svg/outgoingcall.svg" alt="">{{reportTypeData.type2Det.title}}
                    </a>
                </div>
            </div> -->

            <div class="repport-type-container" *ngIf="_gridTabs.length" >
                <ul sfBtnGroup class="sf-group-btn">
                    <li sfBtnGroupItem *ngFor="let tabItem of _gridTabs"
                    [icon]="tabItem.icon"
                    [iconWidth]="tabItem.iconWidth"
                    [iconHeight]="tabItem.iconHeight"
                    [text]="tabItem.name"
                    [ngClass]="{'active':_activeTab==tabItem.id}"
                    (click)="ongridTabSelect(tabItem);"
                    >
                    </li>
                </ul>
            </div>






            <!-- <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="selectedTabId" *ngIf = "gridTabs">
                <li ngbNavItem *ngFor="let tabItem of gridTabs " [ngbNavItem]="tabItem.id">
                    <a ngbNavLink class="d-flex align-items-center"  (click)="ongridTabSelect(tabItem)">
                        <span class="d-none d-sm-block">{{tabItem.name}}</span>
                    </a>
               </li>
            </ul> -->
            <!-- <div class="quick-filter-menu" *ngIf = "gridTabs">
                <ul class="">
                    <li class="" *ngFor="let tabItem of gridTabs" (click)="ongridTabSelect(tabItem)" 
                    [ngClass]="{'tabselect': tabItem.id == selectedGridTabId}" >{{tabItem.name}}</li>
                </ul>
            </div> -->
        </div>
        <div class="header-right-child">
            <button *ngIf="expanded && import" (click)="openImportModal(importModal)"
                class="btn btn-sm mr-1 bg-light-primary text-nowrap filter-button" disableIfAllsuborg>
                <i class="ft-download mr-1"></i> Import
            </button>
            <button *ngIf="expanded && export" (click)="onexportButtonClick()"
                class="btn btn-sm mr-1 bg-light-primary text-nowrap filter-button">
                <i class="ft-upload mr-1"></i> Export
            </button>
            <button *ngIf="expanded && columns && advanceFilter" class="btn btn-sm mr-1 bg-light-secondary text-nowrap filter-button"
                (click)="advancedFilterVisible = !advancedFilterVisible">
                <i class="ft-filter mr-1"></i> {{advancedFilterVisible ? 'Hide Filter' : 'Show Filter'}}
            </button>
            <a *ngIf="newButton && !newButtonEnabled && !dontDisableIfAllsuborg" (click)="onNewButtonClick()"
                class="btn btn-text-center btn-sm text-nowrap  mr-1 add-btn" disableIfAllsuborg>
                <i class="ft-plus mr-1"></i> {{newButtonText}}
            </a>
            <a *ngIf="newButton && !newButtonEnabled && dontDisableIfAllsuborg" (click)="onNewButtonClick()"
                class="btn btn-text-center btn-sm text-nowrap  mr-1 add-btn">
                <i class="ft-plus mr-1"></i> {{newButtonText}}
            </a>
            <a *ngIf="newButton && newButtonEnabled" (click)="onNewButtonClick()"
                class="btn btn-text-center btn-sm text-nowrap  mr-1 add-btn">
                <i class="ft-plus mr-1"></i> {{newButtonText}}
            </a>
            <a *ngIf="bulk_delete_btn" (click)="onBulkDeleteClick()"
                class="btn btn-text-center btn-sm text-nowrap text-light bg-light-danger mr-1" disableIfAllsuborg>
                <i class="fa fa-pencil-square-o mr-1"></i> Bulk Delete
            </a>
            <a *ngIf="bulk_update_btn" (click)="onNewButtonClick()"
                class="btn btn-text-center btn-sm text-nowrap text-light gradient-purple mr-1 add-btn" disableIfAllsuborg>
                <i class="fa fa-pencil-square-o mr-1"></i> {{newButtonText}}
            </a>
            <input *ngIf="localSearch && expanded" [placeholder]="searchPlaceholder"
                class="form-control form-control-sm width-200" type="text" (keyup)="onLocalSearch($event)" />
            <div class="input-group m-0 server-search" *ngIf="searchSelected && expanded && showSearch">
                <div class="input-clear">
                    <input #searchInput class="form-control input-group-control form-control-sm"
                        [(ngModel)]="searchText" name="searchText" type="text" placeholder="Search..."
                        (keyup.enter)="searchFilterApply()" />
                    <button *ngIf="searchText" (click)="clearSearch()" class="input-clear-btn">
                        <i class="ft-x"></i>
                    </button>
                </div>
                <div ngbDropdown class="input-group-append dropup">
                    <button type="button" class="btn search-dropdown f-14 dropdown-toggle no-hover"
                        [class.search-dropdown-single]="searchFilters && searchFilters.length === 1" placement="bottom"
                        [ngbTooltip]="!isMobile?searchToolTip:null" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" ngbDropdownToggle>
                        <span>{{searchSelected?.displayName}}</span>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <button type="button" class="w-100 f-14" *ngFor="let search of searchFilters"
                            (click)="searchSelected = search" ngbDropdownItem>
                            {{search.displayName}}
                        </button>
                    </div>
                    <button type="button" (click)="searchFilterApply()"
                        class="btn f-14 btn-secondary radius-form-control">
                        <i class="fa fa-search"></i>
                        {{!isMobile?'Search':''}}
                    </button>
                    <ng-template #searchToolTip>
                        <div class="d-flex flex-column text-left" *ngIf="searchSelected.children">
                            <span *ngFor="let ch of searchSelected.children">{{ch.displayName}}</span>
                        </div>
                        <span *ngIf="!searchSelected.children">{{searchSelected.displayName}}</span>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-body d-none mt-3" [class.d-block]="advancedFilterVisible && expanded" *ngIf = "rows">
        <div class="filter-row d-flex flex-row lign-items-center" *ngFor="let row of rows;let i = index">
            <div class="operator width-15-per pr-1">
                <ng-select class="ng-select-sm" [ngClass]="{'ng-select-nocaret':i === 0}" [disabled]="i===0"
                    [(ngModel)]="row.operator">
                    <ng-option *ngIf="i === 0" [value]="'WHEN'">WHEN</ng-option>
                    <ng-option value="AND">AND</ng-option>
                    <ng-option value="OR">OR</ng-option>
                </ng-select>
            </div>
            <div class="column width-25-per pr-1">
                <ng-select class="ng-select-sm" [(ngModel)]="row.column" placeholder="Select a Field" [items]="columns"
                    bindLabel="displayName" (clear)="columnClear(row)" (change)="columnChanged($event,row)">
                </ng-select>
            </div>
            <div class="condition width-25-per pl-1 pr1">
                <ng-select class="ng-select-sm" [(ngModel)]="row.condition" placeholder="Select a Condition"
                    [items]="row.allowedConditions" bindLabel="displayName"></ng-select>
            </div>
            <div class="control flex-1 pl-1 d-flex align-items-center">

                <input *ngIf="!row.column || row.column.columnType === ColumnType.string" type="text"
                    [(ngModel)]="row.value" class="form-control form-control-sm" />

                <input *ngIf="row.column && row.column.columnType === ColumnType.number" type="number"
                    [(ngModel)]="row.value" class="form-control form-control-sm" />

                <div class="w-100" *ngIf="row.column && row.column.columnType === ColumnType.ref">
                    <ng-container
                        [ngTemplateOutlet]="getControlTemplate(row.column.columnName)"
                        [ngTemplateOutletContext]="{row:row}">
                    </ng-container>
                </div>

                <app-date-picker *ngIf="row.column && row.column.columnType === ColumnType.date" size="sm"
                    [(value)]="row.value"></app-date-picker>

                <div *ngIf="row.column && row.column.columnType === ColumnType.boolean"
                    class="ml-2 custom-switch custom-switch-activator custom-switch-success custom-control">
                    <input type="checkbox" [(ngModel)]="row.value" [id]="toggleId+i" class="custom-control-input">
                    <label class="custom-control-label" [for]="toggleId+i"><span></span></label>
                </div>

                <div *ngIf="row.column && row.column.columnType === ColumnType.select">
                    <ng-select class="ng-select-sm" [items]="row.options" bindLabel="displayName" bindValue="value"
                        [(ngModel)]="row.value" placeholder="Select option"></ng-select>
                </div>

                <input *ngIf="row.column && row.column.columnType === ColumnType.phone" type="text"
                    mask="(000) 000-0000" [(ngModel)]="row.value" class="form-control form-control-sm" />

                <date-range-picker *ngIf="row.column && row.column.columnType === ColumnType.date_range"
                    [(value)]="row.value">
                </date-range-picker>
            </div>
            <div class="action pl-1">
                <button [disabled]="i === 0" (click)="removeRow(row.id)" [ngClass]="{'text-white':i === 0}"
                    class="frow-remove-btn text-danger">
                    <i class="ft-minus-circle"></i>
                </button>
            </div>
        </div>

        <div div class="filter-actions d-flex flex-row align-items-center mt-1">

            <button (click)="applyAdvancedFilter()" class="btn btn-sm btn-info mr-2">Apply Filter</button>

            <a (click)="resetFilter()" class="text-warning mr-2">Reset</a>

            <a *ngIf="module && !isAllSuborg" (click)="modal.open(saveFilterModal)" class="ml-1">
                <i class="ft-save"></i> {{customFilterId > 0 ? 'Save Changes' : 'Save'}}
            </a>

            <a (click)="deleteSavedFilter()" *ngIf="customFilterId && !isAllSuborg" class="ml-2 text-danger">
                <i class="ft-trash-2"></i> Delete
            </a>

            <a (click)="appendRow()" style="margin-left: auto;" class="text-info">
                <i class="ft-plus"></i> Add Criteria
            </a>
        </div>
    </div>

    <ng-template #saveFilterModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{customFilterId > 0 ? 'Edit Filter' : 'Save Filter'}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && onFilterFormSubmit()">
                <div class="form-group">
                    <label class="val-required">Name</label>
                    <input type="text" [(ngModel)]="customFilterName" #fn="ngModel" name="name" class="form-control"
                        required>
                    <small class="form-text text-muted danger" *ngIf="fn.invalid && (fn.dirty || fn.touched)">
                        <div *ngIf="fn.errors.required">
                            Name is required.
                        </div>
                    </small>
                </div>
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="default_filter" id="default_filter"/>
                        <label class="custom-control-label" for="default">
                            <span>Default</span>
                        </label>
                    </div>
                </div>
                <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                    <button type="submit" [disabled]="!formElement.form.valid"
                        class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                        Save
                    </button>
                    <button type="button" (click)="c('cancel clicked')" class="btn btn-secondary">Cancel</button>
                </div>
            </form>
        </div>
    </ng-template>

    <ng-template #importModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">Import</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <data-import [columns]="importColumns" [module]="module"
                (submit)="onImportSubmitted($event)"
                (cancel)="closeImportModal()"></data-import>
        </div>
    </ng-template>
</section>
