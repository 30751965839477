<div class="col-12 col-md-12 p-0 card">
    <ul ngbNav #nav="ngbNav" class="nav-tabs p-2" [(activeId)]="selectedTabId">
        <li ngbNavItem [ngbNavItem]="1"  (click) = "addParameterToURL(1)">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Catalog List</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <section id="item-list" class="pt-2">
                    <div class="row">       
                        <div class="col-12 d-flex flex-row" [ngClass]="{'row-shrinked':!expanded}">               
                            <div class="m-0" [ngClass]="{'dt-shrinked items-datatable':!expanded,'dt-expanded':expanded}">
                                
                                <div class="d-flex flex-space-bw filter-bar"
                                    [ngClass]="{'card-header':expanded,'items-datatable-header mb-2':!expanded}">
                                    <datagrid-filter #dataGridFilter [defaultFilters]="defaults" [module]="module"
                                        [searchFilters]="search" [newButtonUrl]="['/inventory/catalog/design']" [expanded]="expanded"
                                        [columns]="columns" [import]="false" [newButton]="UIPermissions.create||isSuperAdmin()"
                                        (apply)="applyFilter($event)">
                
                                        <ng-template filterControl [column]="'category'" let-row="row">
                                            <category-select class="ng-select-sm" [(value)]="row.value"></category-select>
                                        </ng-template>
                                        <ng-template filterControl [column]="'manufacturer'" let-row="row">
                                            <manufacturer-select class="ng-select-sm" [(value)]="row.value" [addButton]="false">
                                            </manufacturer-select>
                                        </ng-template>
                                        <ng-template filterControl [column]="'supplier'" let-row="row">
                                            <app-supplier-select class="ng-select-sm" [(value)]="row.value"></app-supplier-select>
                                        </ng-template>
                                        <ng-template filterControl [column]="'suborg'" let-row="row">
                                            <suborg-select class="ng-select-sm" [(value)]="row.value"
                                                [tenantId]="row.dependentValue"></suborg-select>
                                        </ng-template>
                                    </datagrid-filter>
                                </div>
                                <div class="table-responsive">
                                    <div class="card-content ">
                                        <div [ngClass]="{'card-body':expanded}">
                                            <ngx-datatable #dataTable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH"
                                                [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                                                [rows]="rows" [selectionType]="SelectionType.single" [count]="page.count"
                                                [externalPaging]="true" [offset]="page.offset" [externalSorting]="true"
                                                [sorts]="[{prop:'name',dir:'asc'}]" [limit]="page.limit" (select)="onSelect($event)"
                                                (page)="setPage($event)" (sort)="onSort($event)">
                                                <ngx-datatable-column *ngIf="isProfiled('Image',true)" name="Image" prop="image"
                                                    [sortable]="false" [width]="220" [canAutoResize]="false">
                                                    <ng-template let-image="value" ngx-datatable-cell-template>
                                                        <img *ngIf="image" width="200" class="catalog-image" height="auto"
                                                            [src]="fileUrl + image" />
                                                        <img *ngIf="!image" width="200" class="" height="auto"
                                                            src="/assets/img/svg/product-avatar.svg" />
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column *ngIf="isProfiled('Name',true)" name="Name" prop="name">
                                                </ngx-datatable-column>
                
                                                <ngx-datatable-column *ngIf="expanded && isProfiled('no_of_pages',true)"
                                                    name="Total pages" prop="no_of_pages" [sortable]="false"></ngx-datatable-column>
                
                                                <ngx-datatable-column *ngIf="expanded && isProfiled('no_of_products',true)"
                                                    name="Total products" prop="no_of_products"
                                                    [sortable]="false"></ngx-datatable-column>
                
                                                <ngx-datatable-column *ngIf="expanded && isProfiled('Status',true)" name="Status"
                                                    prop="is_active" [width]="80" [canAutoResize]="false" [sortable]="false">
                                                    <ng-template let-is_active="value" ngx-datatable-cell-template>
                                                        <div class="badge"
                                                            [ngClass]="{'bg-light-success': is_active,'bg-light-danger': !is_active }">
                                                            {{ is_active ? 'Active' : 'Inactive' }}
                                                        </div>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column *ngIf="expanded && (!isAllSuborg||isSuperAdmin)" name="Action"
                                                    prop="id" [sortable]="false" [canAutoResize]="false" [width]="80">
                                                    <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                                        <div ngbDropdown container="body">
                                                            <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                                <i class="ft-more-vertical text-primary"></i>
                                                            </button>
                                                            <div ngbDropdownMenu>
                                                                <button *ngIf="UIPermissions.viewInfo||isSuperAdmin()" class="w-100"
                                                                    (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                                    <i class="ft-book-open mr-1"></i> View
                                                                </button>
                                                                <a *ngIf="UIPermissions.edit||isSuperAdmin()" class="w-100"
                                                                    [routerLink]="['/inventory/catalog',id,'edit']" ngbDropdownItem>
                                                                    <i class="ft-edit mr-1"></i> Edit
                                                                </a>
                                                                <button *ngIf="UIPermissions.delete||isSuperAdmin()"
                                                                    class="w-100 bg-light-danger" (click)="deleteItem(id)"
                                                                    ngbDropdownItem>
                                                                    <i class="ft-trash-2 mr-1"></i> Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-footer>
                                                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                        let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                                        <div class="page-count" *ngIf="expanded">
                                                            <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                                        </div>
                                                        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                            [pagerRightArrowIcon]="'datatable-icon-right'"
                                                            [pagerPreviousIcon]="'datatable-icon-prev'"
                                                            [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                                            [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                                            (change)="dataTable.onFooterPage($event)">
                                                        </datatable-pager>
                                                    </ng-template>
                                                </ngx-datatable-footer>
                                            </ngx-datatable>        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="items-view" *ngIf="!expanded && selected">
                                <div class="items-view-content">
                                    <catalog-view [catalog]="selected" (onDelete)="deleteItem($event)" (onClose)="onItemViewClose()">
                                    </catalog-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-template>
        </li>
        <li ngbNavItem [ngbNavItem]="2" (click) = "addParameterToURL(2)">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Page Template</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                
               <app-list-page-template></app-list-page-template>
            </ng-template>
        </li>
        <li ngbNavItem [ngbNavItem]="3" (click) = "addParameterToURL(3)">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Product Template</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
              <app-list-product-template></app-list-product-template>
                
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>



