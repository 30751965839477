export const environment = {
    production: false,
    apiURL:'https://test.simplyfuse.com/workhorse/api',
    webhookURL: 'https://webhooktest.simplyfuse.com/',
    fileURL:'https://test.simplyfuse.com/media',
    imgURL:'https://test.simplyfuse.com/workhorse/api/static/files/',
    siteUrl:'https://test.simplyfuse.com/',
    callcenterSocketURL: 'wss://gu4imu7ovk.execute-api.us-east-1.amazonaws.com/Test_Prod/',
    appVersion:"1.5.0",

    mapbox: {
      accessToken: 'pk.eyJ1Ijoic2ltcGx5ZnVzZSIsImEiOiJjbHI5YjNkd2kwMTZsMmxzNDF2cmlsMmRzIn0.Q8e-CC9o56m90HM-KEfHhg'
    },
    agentStatusURL:'https://g66gd4fs00.execute-api.us-east-1.amazonaws.com/test/test_get_callcenter_log'
  };
