<section class="add-user">
    <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        {{userId>0 ? 'Edit User' : 'New User'}}
                    </h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <app-user-form
                          [userId]="userId"
                          [hiddenFields]="hiddenFields"
                          [readOnlyFields]="readOnlyFields"
                          (success)="onDataSubmittedSuccess($event)"
                          (error)="onDataError($event)"
                          (cancel)="onCancel()">
                        </app-user-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6" *ngIf="userId>0">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        Reset Password
                    </h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <app-user-reset-password
                          [userId]="userId"
                          [resetType]="resetType"
                          [hiddenFields]="hiddenFields"
                          (onSuccess)="onDataSubmittedSuccess($event)"
                          (onError)="onDataError($event)">
                        </app-user-reset-password>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
