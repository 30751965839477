import { CustomerLocationSelectComponent } from './../../select/customer-location-select/customer-location-select.component';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { TenantCustomerService } from './../../../services/tenant-customer.service';
import { CustomerContact, CustomerLocation, CustomerRoles } from './../../../models/tenant-customer';
import { Component, EventEmitter, Input, Output, OnDestroy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CustomField } from 'src/app/core/models/custom-field';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tenant-customer-contact-form',
  templateUrl: './customer-contact-form.component.html',
  styleUrls: ['./customer-contact-form.component.scss']
})
export class CustomerContactFormComponent implements AfterViewInit, OnDestroy {

  @ViewChild('firstname', { static: false, read: ElementRef }) nameControl: ElementRef;

  @ViewChild(CustomerLocationSelectComponent) locationSelect: CustomerLocationSelectComponent;

  @ViewChild('FormModal') modalContent: any;

  public model: CustomerContact = new CustomerContact();

  @Input() tenantCustomerId: number;

  @Input() tenentSelection:boolean;

  @Input() disable_change:boolean;

  @Input() suborg_id:number;

  @Input() incomingcall_Details;

  @Output() success = new EventEmitter<{ response: CustomerContact, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  private modalRef: NgbModalRef;

  private binding: CustomerContact;

  get bindingModel(): CustomerContact { return this.binding; };

  public hasPortAccess:boolean;

  public emailExist:string;

  @Input() set bindingModel(value: CustomerContact) {
    if (value) {
      this.model = value;
      this.binding = value;
      this.hasPortAccess=value.portal_access;
      this.additionalFields = this.customFields.obj2CustomFields(this.model.additional_fields) || [];
    }
  };

  @Output() locationAdd = new EventEmitter();

  public additionalFields: CustomField[] = [];

  private subs: SubSink = new SubSink();

  public customerRoles:CustomerRoles[]=[];

  public locationModel: CustomerLocation;

  constructor(private service: TenantCustomerService,
    private customFields: CustomFieldsService,
    private cdr: ChangeDetectorRef,
    private toastr:ToastrService,
    private modal: NgbModal) { }

  ngOnInit(){
    this.getCustomerRole();
    if(this.tenantCustomerId){
      this.model.tenant_customer = this.tenantCustomerId;
    }
    console.table(this.model);

  }

  ngAfterViewInit(): void {
    this.nameControl?.nativeElement.focus();
  }


  submit() {
    let locationDetailsforCallLog;

    if(this.tenentSelection && this.model.tenant_customer==null){
    this.toastr.error('Select a customer')
    
    }else{

    let api: Observable<CustomerContact>;

    this.model.customer = this.service.customerId;
    this.model.suborg = this.suborg_id? this.suborg_id:this.service.suborgId;

    if(this.model.portal_access&&!this.model.email){
      this.toastr.error('Email address is mandatory if you enable portal access');
    }
    else{

      if(!this.model.role){
        this.model.role=null;
      }
  
      if (this.bindingModel) {
        api = this.service.updateCustomerContact(this.bindingModel.id, this.model);
      }
      else {
        this.model.tenant_customer = this.tenantCustomerId;
        api = this.service.createCustomerContact(this.model);
      }
  
      this.model.additional_fields = this.customFields.customFields2Obj(this.additionalFields);
  
      this.subs.sink = api.subscribe(
        resp => {
          if(resp.id){
            if (resp.location > 0) {
              resp.location_name = this.locationSelect?.getLocation(resp.location)?.name;
              if(this.incomingcall_Details){
                locationDetailsforCallLog = this.locationSelect?.getLocation(resp.location);
                this.locationMappingtoCallLog(locationDetailsforCallLog,resp);
              }
            }else if(this.incomingcall_Details){
              this.locationMappingtoCallLog(null,resp);
            }
            let msg = this.bindingModel ? 'Contact info updated' : 'New Contact added';
            this.emailExist = null;
            this.success?.emit({ response: resp, message: msg });
          }else if(resp.message){
            this.emailExist = resp.message;
            this.cdr.markForCheck();
          }
          
        },
        (err) => {
          if (err.error && err.error['non_field_errors']) {
            this.toastr.error(err.error['non_field_errors'][0])
          }
          else {
            this.error?.emit(err);
          }
        }
      );

    }
    }
  }

  locationMappingtoCallLog(locationDetails,contact){
    this.incomingcall_Details.location_address = locationDetails?.address;
    this.incomingcall_Details.location_city = locationDetails?.city;
    this.incomingcall_Details.location_name = locationDetails?.name;
    this.incomingcall_Details.location_state = locationDetails?.state;
    this.incomingcall_Details.location_zip = locationDetails?.zip;
    this.incomingcall_Details.contact_email = contact.email;
    this.incomingcall_Details.contact_name = contact.last_name?contact.first_name+' '+contact.last_name:contact.first_name;
    this.incomingcall_Details.contact_mobile = contact.mobile;
    this.incomingcall_Details.contact_phone = contact.phone;
    this.incomingcall_Details.location_id = contact.location
    this.cdr.markForCheck();
}

  addCustomField() {
    this.additionalFields.push(new CustomField());
  }

  deleteCustomField(id: string) {
    this.additionalFields = this.additionalFields.filter(x => x.id !== id);
  }


  //Get customer role
  getCustomerRole(){
    this.service.getCustomerRoles().subscribe(
      response=>{
        this.customerRoles=response;
      }
    );
  }  


  onCancelClicked(): void { this.cancel?.emit(); }

  onLocationAdded() { this.locationAdd?.emit(); }

  customerChanged(){
    console.log("Customer just changed");
    this.tenantCustomerId = this.model.tenant_customer;
  }

  openModal() {
    //console.log(this.model,'this.model++')
    this.tenantCustomerId = null;
    this.service.getCustomerLocationDetails(this.model.location).subscribe(
      response=>{
        this.locationModel = response;
        this.modalRef = this.modal.open(this.modalContent, { size: 'md', scrollable: true, centered: true });
      }
    );
    
  }

  onDataSubmittedSuccess(result: { response: CustomerLocation, message: string }): void {
    this.toastr.success(result.message);
    this.model.location = this.model.location;
    this.cdr.markForCheck();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeModal() { this.modalRef?.dismiss(); this.tenantCustomerId = this.model.tenant_customer; }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
