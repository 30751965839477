export class SIP {

    public id:string;

    public username:string;

    public password:string;

    public caller_id:string;

    public send_as:string;

    public ciphers:any;

    public codecs:any;

    public encryption:string = "default";

    public call_handler:string = "default";

    public associated_user:number;

    public type:string;

    public is_delete: boolean;

    public is_sync:boolean;

    public sub_org: number;

    public created_on: Date;

    public modified_on: Date;

    suborg_name: string;

    customer_name: string;

    created_by:string;

    customer:number;

    associated_username?:string

    status: string;
}

export class AgentStatusLog{

    public origin:string;

    public userId:string;

    public updatedAt:string;

    public connectionId:string;

    public sip:string;

    public userStatus:string;

    public reason:string;

    public oldStatus:string;
}
