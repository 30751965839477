import { UserStatusUpdateRequest} from "src/app/modules/ring2voice/models/call-center.models";
import { Inviter, InviterInviteOptions, RegistererState, SessionState,UserAgent } from "sip.js";
import { Injectable, Renderer2, ViewChild } from '@angular/core';
import { SubSink } from "subsink";
import { BehaviorSubject, Observable, Subject, from } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { outCallDetailsModel } from "../models/outbound-calldetails.model";
import { BaseService } from "src/app/core/services/base.service";
import { CallCenterService } from "./call-center.service";
import { StatusValueEnum } from "src/app/core/enums/common.enum";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { OutgoingInviteRequest } from "sip.js/lib/core";
import { CallCenterComponent } from "../pages/call-center/call-center.component";
import { SipConnectionService } from "./sip-connection.service";
import { sipInboundFunctionService } from "./sipinbound-function.service";
import { CallcenterSharedService } from "./call-center-shared.service";

@Injectable({
  providedIn: 'root'
})
export class SipOutBoundFunctionService extends BaseService {

  
  @ViewChild(CallCenterComponent) callcenter: CallCenterComponent;

  public currentCallDetails : outCallDetailsModel = new outCallDetailsModel()

  public connection: any;

  public declineTone = new Audio();
 
  public allowClearCallQ: boolean = false;

  callQ: any[] = [];

  public Subs: SubSink = new SubSink();

  private number = new BehaviorSubject<string>('');

  public ringAudio = new Audio();

  public acceptedCallQ: any[] = [];

  public callId: string;

  public subs: SubSink = new SubSink();

 // public isActiveCall: boolean;

  public isOutgoingCall:boolean = false

  public totalCallCount: number = 0;

  public acceptTone = new Audio();

  public selectedAudioInput: string;

  public selectedVideoSource:any;

  public remoteAudio = new Audio();

  public selectedOutputAudio:string;

  public mediaElement : any

  public isCallTerminated: Subject<boolean> = new Subject<boolean>();

  public outBoundShowType: string = 'dialer'

  public isMuted: boolean = false;

  constructor(private http: HttpClient,
    private callCenterService: CallCenterService,
    private sipConnectionService : SipConnectionService,
    private sipInboundFnService: sipInboundFunctionService,
    public callcenterSharedService: CallcenterSharedService,
    private datePipe: DatePipe,
    private toastr: ToastrService) {
    super();
  }

  public async makeCall(type:string, from, to,agentsipuri:string,callto_agent_id : string = "") {  //type 'number' or sip

    if (type == 'number' && to.length > 10) {
      to = to.slice(to.length - 10);
    }
    
    if(this.callcenterSharedService.sip_details.status == StatusValueEnum.OFFLINE)
    { 
      this.toastr.info("Agent status changing to online...")
      this.sipConnectionService.initialiseConnection().then(state=>{
        if(state ===  RegistererState.Registered)
        {
          this.toastr.clear()
          this.sipConnectionService.agentStatusChange.next(true)
          this.invite(type,from,to,agentsipuri,callto_agent_id)
        }
        else{
          this.toastr.info("Error on connection...")
        }
      
      })
    }
    else{

       this.invite(type,from,to,agentsipuri,callto_agent_id)
     }
  }


  
  invite(type:string, from,to,agentsipuri,callto_agent_id)
  { 
    console.log('callto',to)
    this.sipInboundFnService.selectedCaller = null
    const isAgentCall = type == 'sip'? true : false
    let tis = this;
    var target;
    if(type == 'number')
    {
      target = UserAgent.makeURI("sip:" + to + '@' + agentsipuri.split('@')[1]);//"@sip.signalwire.com");
    }
    else
    {
      target = UserAgent.makeURI("sip:" + to)
    }

    const customHeaders = ['X-Send-As:' + from,'X-To-Number:'+ to,'X-Call-Type:' + type ];

    this.connection = this.callcenterSharedService.connection
    const session =  new Inviter(this.connection, target,{
      extraHeaders: customHeaders
    });

    tis.allowClearCallQ = false;
    tis.pushtoOutgoingCallQ(session, from, to,isAgentCall,callto_agent_id);
    tis.callCenterService.isActiveCallOutbound = false
    tis.isOutgoingCall = false
    tis.activeCallCount();

    // if (localStorage.getItem('isOnCall') == 'false') {
      tis.ring();
   // }
    this.checkWhichSectionTobeSelected()

 

    session.stateChange.addListener((newState: SessionState) => {


      this.currentCallDetails.state = newState
     // this.updateCurrentCallDetails()

      let callIDs = JSON.parse(localStorage.getItem('Outbound-CallID'));
      if (callIDs) {
        callIDs = callIDs.filter(id => !tis.currentCallDetails.acceptedCallsIDs.includes(id));
        tis.callQ = tis.callQ.filter(inv => !callIDs.includes(inv.id));
      }
      console.log('outboundstatus', newState)
      switch (newState) {
        case SessionState.Initial:
      break;
        case SessionState.Establishing:
          // this.getOutboundCallLogtId(fromno,tono)

          // console.log('sessionstr',this.getSessionStr(session))     
          break;
        case SessionState.Established:

        
   

        console.log('invitesession', session)
        console.log(session)
        console.log('invite id', session.request.getHeader)

        this.callCenterService.isActiveCallOutbound = true
        tis.callCenterService.agentStatusChange.next({ status: StatusValueEnum.ON_CALL, updateStatus: true })
 
        this.stopRing()
        //session.accept(options);
        this.callAcceptTone();
        this.movetoAcceptedCalls(this.currentCallDetails.active_CallerSession)
       // localStorage.setItem('isOnCall', 'true');
       // const mediaElement = document.getElementById('outaudio') as HTMLAudioElement;
        const remoteStream = new MediaStream();
        
        this.currentCallDetails.active_CallerSession.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
          if (receiver.track) {
            remoteStream.addTrack(receiver.track);
          }
        });

         this.mediaElement.srcObject = remoteStream;
         this.mediaElement.play();



          break;
        case SessionState.Terminating:  

          break
        // fall through
        case SessionState.Terminated:
        
          if(tis.callcenterSharedService.sip_details.status=='On Call'){
            tis.updateLastCallTime(session);
          }
          tis.isMuted = false;
          if (tis.currentCallDetails.active_CallerSession.customValues) {
            tis.currentCallDetails.active_CallerSession.customValues.isCallOnHold = false;
          }
          tis.callCenterService.isActiveCallOutbound = false;
          tis.isOutgoingCall = false
          tis.allowClearCallQ = true;
          tis.clearCompletedCallsfromCallQ();
          tis.callDeclineTone();
          this.stopRing()
          tis.activeCallCount().then(() => {

          });
          tis.checkWhichSectionTobeSelected();
          tis.setOutBoundNumber('')
          tis.isCallTerminated.next(true)
          
          break;
          default:
          break;

      }
    })
    const inviteOptions: InviterInviteOptions = {
      requestDelegate: {
        onAccept: (response) => {
          const callSID = response;
          console.log('Call SID:', callSID);

          console.log("accept responce",response.message);
          console.log("Positive response = " + response);
        },
        onReject: (response) => {

          const callSID = response;
          console.log('R-Call SID:', callSID);
          console.log("Negative response = " + response);
        },
      },
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false,
    
        },
      },
    }

 
    
    session.invite(inviteOptions).then((request: OutgoingInviteRequest) => {

      console.log("Successfully sent INVITE");
      console.log("INVITE request = ", request);

       // Handle key events to send DTMF tones

    })
      .catch((error) => {
        console.error(' Failed to INVITE');
        console.error(error.toString());
      });
  }

  getSessionStr(obj)
  {
    let cache = [];
  let str = JSON.stringify(obj, function(key, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
  }

  updateLastCallTime(endedCall){

    const isCallPresent = this.acceptedCallQ.includes(endedCall);
    if(isCallPresent){
      let date = new Date();
      let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
      payload.call_end_time=this.datePipe.transform(date,'yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZZZ')
      this.Subs.sink=this.callCenterService.update(payload).subscribe(responds=>{}
      ,(err)=>{
        console.log(err);
      });
    }
  }

  
  changeAgentStatusApi(status) {
    let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    payload.status = status;

    this.Subs.sink = this.callCenterService.update(payload).subscribe(responds => {
      if (responds) {
        //this.sipConnectionService.sip_details.status = status;
        localStorage.setItem('statusAfterReload', status) //reload connection
        //this.cdr.markForCheck();
      }
    })
  }

  callAcceptTone() {
    this.acceptTone.src = "../../../../assets/ringtone/pickup tone.mp3"
    this.acceptTone.play();
  }

  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play(); 
  }

  

  checkWhichSectionTobeSelected() {

    var toBeSelected;
    var isAcceptedCall: boolean = false;
    this.callQ.forEach(call => {

      if(!toBeSelected&&call.state=='Initial'){
        this.isOutgoingCall = true
        toBeSelected=call;
      }
      if(call.state=='Established'){
        isAcceptedCall=true;
      }
    });
    
    if (toBeSelected && !isAcceptedCall) {
      this.currentCallDetails.active_CallerSession = toBeSelected;
      this.outBoundShowType = 'details'

      if(toBeSelected.customValues.agentCall)
      {
          this.get_DetailsSIPCall(toBeSelected.customValues.callFrom,toBeSelected.customValues.call_to_agentid)
      }
      else
      {
          this.get_DetailsofNonCallCenterNumber();
      }
      if (this.currentCallDetails.active_CallerSession.userAgent.instanceId) {

        this.currentCallDetails.callID = this.currentCallDetails.active_CallerSession.userAgent.instanceId
      } else {
        this.callId = null;
      }
    }
  
  }
  clearCompletedCallsfromCallQ(endedWhileOnHold: boolean = false) {

    this.callQ = this.callQ?.filter(invitation => invitation.state !== 'Terminated');
    // this.callQ = this.callQ?.filter(invitation => invitation.state !== 'Terminated');
    if (endedWhileOnHold) { 
      // this.acceptedCallQ = this.acceptedCallQ.filter(call=> call.state!='Terminated');
      this.acceptedCallQ = this.acceptedCallQ.filter(call => call.state != 'Terminated');
      this.activeCallCount();
    } else {
      this.acceptedCallQ?.forEach((call, index) => {
        if (call.state === 'Terminated' && !call.customValues.isCallOnHold && !call.customValues.isOnGoingSt && !call.customValues.isOnGoingSo) {
          this.acceptedCallQ.splice(index, 1);
          return false;
        }
        return true;
      });
      this.activeCallCount();
    }

  }

  ring() {
    if (this.ringAudio) {
      this.ringAudio.pause();
    }
    //if (this.isOutgoingCall && !this.isActiveCall && (localStorage.getItem('isOnCall') == 'false')) {
      this.ringSound();
    // } else {
    //   this.ringAudio.pause();
    // }
  }

  ringSound() {
    
    this.ringAudio.src = "../../../../assets/ringtone/outbound-call_tone.mp3"
    this.ringAudio.muted = false;
    this.ringAudio.loop = true;
    this.ringAudio.play();
  }

  async activeCallCount(callQ = this.callQ) {

    
    let callCount = 0;
    if (callQ && callQ.length > 0) {
      callQ.forEach(value => {

        // if(value.state=='Established'){
        //   this.isActiveCall=true;
        // }0
        if(value.state=='Initial'){
          this.isOutgoingCall=true;
        }
        if (value.state=='Initial' || value.state == 'Established') {
          this.isOutgoingCall = true
          callCount++;
        }
        if (callCount == 0 && value.state != 'Established') {
          this.isOutgoingCall = false
        }
        else {
          this.isOutgoingCall = true
        }
      });
    }
    if (this.acceptedCallQ && this.acceptedCallQ.length > 0) {
      this.acceptedCallQ.forEach(call => {
        if (call.state == 'Established') {
          this.callCenterService.isActiveCallOutbound = true;
          callCount++;
        } else {
          this.callCenterService.isActiveCallOutbound = false;
        }
      })
    }
    setTimeout(() => {
      
      if (!this.callCenterService.isActiveCallOutbound) {

        localStorage.setItem('isOnCall', 'false');
        this.callCenterService.agentStatusChange.next({ status: StatusValueEnum.READY,updateStatus: true});  //need to handle

      }
    }, 500);
   this.currentCallDetails.totalCallCount = callCount;
   //this.updateCurrentCallDetails()
    
    return callCount;
  }

  pushtoOutgoingCallQ(session, fromno, tono,isAgentCall : boolean,callto_agent_id) {

    session.customValues = {
      'isCallOnHold': false, 'callerName': '', 'callFrom': fromno,
      'callTo': tono,'agentCall':isAgentCall, 'call_to_agentid':callto_agent_id
    };//adding new variables for hold&name

    this.callQ.push(session);
  }


  setOutBoundNumber(newValue: string): void { //to set number for outbound call
    this.number.next(newValue);
  }

  getNumber(): Observable<string> {  //to get number for outbound call
    return this.number.asObservable();
  }

  movetoAcceptedCalls(session:any){
    this.acceptedCallQ.push(session);
    console.log('acceptedCallQ', this.acceptedCallQ)
    this.callQ = this.callQ?.filter(invt=> invt.id !== session.id);
  }
  updateSendAsNumberWithAreaCd(code: string) {
    const data = { "area_code": code }
    return this.http.post<any>(`${this.baseURL}/change_send_as_number/`, data)
  }

  get_DetailsofNonCallCenterNumber( ) {

    
    let fromNumber  = this.currentCallDetails.active_CallerSession.customValues.callFrom //'+1'+tono
    let toNumber = this.currentCallDetails.active_CallerSession.customValues.callTo//'+1'+fromno
    this.subs.sink = this.callCenterService.getNonCallCenterDetailsOutbound('+1'+fromNumber, '+1'+toNumber).subscribe(resp => {

      this.currentCallDetails.contactDetails = resp

      console.log('contactDetails',resp)

    })
  }

  get_DetailsSIPCall(from:string,sipid:string)
  {
    let request = { from_number: from, sip_id: sipid }
    
    this.subs.sink = this.callCenterService.getSIPCallDetails(request).subscribe(response => {
      this.currentCallDetails.agentDetails = response;
      console.log('sipcalldet',response)
    })
  }
    stopRing() {
      this.ringAudio.pause()
    }
    clearCallQ()
    {
      this.callQ = [];
    }
    destroy() {
      // this.subject.complete();
       this.subs.unsubscribe();
     }

  
}

