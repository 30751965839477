import { DistributorService } from '../../../services/distributor.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DistributorLocation } from '../../../models/distributor';

@Component({
  selector: 'distributor-location-select',
  templateUrl: './distributor-location-select.component.html',
  styleUrls: ['./distributor-location-select.component.scss']
})
export class DistributorLocationSelectComponent implements OnInit {

  private _distId: number;

  get distributorId(): number { return this._distId; }

  @Input() set distributorId(val: number) {
    this._distId = val;
    if (this._distId > 0) {
      this.getData();
    }
  };

  private _value: number;

  get value(): number { return this._value; }

  @Input() set value(val: number) {
    this._value = val;
    if (this._value && this.selected !== val) {
      this.selected = this._value;
    }
  };

  @Input() appendToBody:boolean;

  @Output() valueChange = new EventEmitter<number>();

  private _selected: number;

  public get selected(): number { return this._selected; }

  public set selected(value: number) {
    this._selected = value;
    this.valueChange?.emit(value);
  }

  @Input() addButton: boolean = true;

  @Input() readonly: boolean = false;

  @Output() itemAdded = new EventEmitter();

  public options: Observable<DistributorLocation[]>;

  private optionsFlat: DistributorLocation[];

  public placeholder: string;

  private modalRef: NgbModalRef;

  constructor(private service: DistributorService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    this.placeholder = this.hasPermission() && this.addButton ? "Select or add Location" : "Select Location";
  }

  getData() {
    this.options = this.service.getDistributorLocations(this.distributorId).pipe(
      map(resp => { this.optionsFlat = resp; return resp; })
    );
  }

  openModal(content: any): void {
    this.modalRef = this.modalService.open(content, { centered: true, size: 'lg' });
  }

  onDataSubmittedSuccess(result: { response: DistributorLocation, message: string }): void {
    this.toastr.success(result.message);
    this.options = concat(this.options, of([result.response]));
    this.selected = result.response.id;
    this.itemAdded?.emit();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  clear() { this.selected = undefined; }

  closeModal() { this.modalRef?.dismiss(); }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.DISTRIBUTOR_LOCATIONS, [PermissionConstants.CREATE, PermissionConstants.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.CUSTOMER_MODULE, [PermissionConstants.GLOBAL]);
  }

  public getLocation(locid: number) { return this.optionsFlat?.find(item => item.id === locid); }

  public reload() { this.getData(); }
}
