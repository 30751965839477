import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { CustomField } from 'src/app/core/models/custom-field';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { FilesService } from 'src/app/core/services/files.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { SubSink } from 'subsink';
import { ItemCategory } from '../../../models/items';
import { Kit } from '../../../models/kit.models';
import { CategoryService } from '../../../services/category.service';
import { KitService } from '../../../services/kit.service';
import { KitItemsComponent } from '../../kit-items/kit-items.component';

@Component({
  selector: 'app-kit-form',
  templateUrl: './kit-form.component.html',
  styleUrls: ['./kit-form.component.scss']
})
export class KitFormComponent implements OnInit {

    @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;

    @ViewChild('kitItemGrid') kitItemsGrid: KitItemsComponent;

    @Input() suborg_id: number = 0;

    @Output() onSuccess = new EventEmitter<{ response: Kit, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    public model: Kit;

    private partNumberInitial: string;

    public kitId: number = 0;

    public kitImages: StaticFile[];

    public additionalFields: CustomField[];

    public customFieldInputs = { key: '', value: '' };

    public partNumberHelper = { valid: true, message: '', visible: false };

    public lastGenPartNumber: Observable<string>;

    public currentCategory: ItemCategory;

    public previousCategory: ItemCategory;

    public Permissions = PermissionConstants;

    public Resources = ResourceConstants;

    public isClone: boolean = false;

    private subs = new SubSink();

    constructor(
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private kitService: KitService,
        private modalService: NgbModal,
        private catService: CategoryService,
        private customFields: CustomFieldsService,
        private fileService: FilesService,
        private ra: ResourceAccessService) { }

    ngOnInit(): void {
        this.model = this.kitService.createModel(Kit);
        if(this.suborg_id > 0){
            this.model.suborg = this.suborg_id;
          }
    }

    onSubmit() {
        if (!this.kitItemsGrid.validate()) {
            this.toastr.info('Select at least one item or Assembly');
            return;
        }

        this.model.additional_fields = this.customFields.customFields2Obj(this.additionalFields);

        let thumbails: ThumbNail[] = this.fileUploader.getFiles();

        if (thumbails.length) {

            this.subs.sink = this.fileService.uploadFiles(thumbails, 'KITS').subscribe(response => {

                let primary = thumbails.find(t => t.isPrimary);

                if (primary) {
                    this.model.primary_image = response.find(f => f.name = primary.name)?.id;
                }
                else if(response.length) {
                    this.model.primary_image = response[0].id;
                }

                this.model.images.push(...response.map(item => item.id));

                this.submit();
            });
        }
        else {
            this.model.primary_image = this.fileUploader.getPrimary()?.data_id || null;
            this.submit();
        }
    }

    private submit() {

        let isedit = this.kitId && !this.isClone;

        let api = this.kitService.create(this.model);

        this.subs.sink = api.subscribe(
            (kit) => {
                this.updateItems(kit);
            },
            () =>{
                this.onError.emit()
            }
        );
    }

    updateItems(kit: Kit) {
        this.kitItemsGrid.submit(kit.id, result => {
            this.onSuccess.emit({response:kit, message:"New Assembly created"})
        });
    }

    addCustomField() {
        this.additionalFields = this.additionalFields ?? [];
        if (!this.additionalFields.some(x => x.name === this.customFieldInputs.key)) {
            let customField = new CustomField();
            customField.name = this.customFieldInputs.key;
            customField.value = this.customFieldInputs.value || null;
            this.additionalFields.push(customField);
        }
        else {
            this.toastr.info(`Field '${this.customFieldInputs.key}' exist`);
        }
        this.modalService.dismissAll();
    }

    openModal(content: any) {
        this.customFieldInputs.key, this.customFieldInputs.value = '';
        this.modalService.open(content);
    }

    deleteCustomField(id: string) {
        this.additionalFields = this.additionalFields.filter(x => x.id !== id);
    }

    onCategoryChanged(category: ItemCategory) {
        this.previousCategory = this.currentCategory;
        this.currentCategory = category;
        this.updateAdditionalFields(category);
        this.lastGenPartNumber = !category.create_partnumber ? null : this.catService.getLastGeneratedPartnumber(category.id);
    }

    generatePartNumber() {

        if (!this.model.category || !this.currentCategory?.create_partnumber) return;

        this.subs.sink = this.catService.generatePartNumber(this.model.category).subscribe(
            response => {
                this.model.internal_number = response.part_number;
                this.partNumberHelper.valid = true;
                this.cdr.markForCheck();
            },
            error => {
                this.model.internal_number = '';
                this.partNumberHelper.valid = false;
                this.partNumberHelper.message = error?.message || 'Unable to create Part Number';
                this.cdr.markForCheck();
            }
        );
    }

    updateAdditionalFields(current: ItemCategory) {
        let removeTemplate = this.previousCategory?.additional_fields;
        let fields = this.customFields.removeFieldsByObjectsKeys(this.additionalFields || [], removeTemplate);
        let currentFields = this.customFields.obj2CustomFields(current.additional_fields);
        this.additionalFields = currentFields ? this.customFields.merge(fields, currentFields) : fields;
    }

    categoryClear() {
        this.model.internal_number = '';
        this.lastGenPartNumber = null;
        if (this.currentCategory && this.currentCategory.additional_fields) {
            let removeTemplate = this.currentCategory.additional_fields;
            this.additionalFields = this.customFields.removeFieldsByObjectsKeys(this.additionalFields || [], removeTemplate);
        }
    }

    partNumberOnChange(event: any) {

        let value = event.target.value;

        if (!value || value === this.partNumberInitial) {
            this.partNumberHelper.valid = true;
            return
        }
        this.subs.sink = this.catService.isPartNumberUnique(value).subscribe(
            response => {
                this.partNumberHelper.valid = response.is_unique;
                this.cdr.markForCheck();
            },
            () => {
                this.partNumberHelper.valid = false;
                this.partNumberHelper.message = "Part Number exist";
                this.cdr.markForCheck();
            }
        );
    }

    saveSheet(prop: string) {
        if (this.kitId > 0) {
            let req = {};
            this.model.revision = this.model.revision += 1;
            req[prop] = this.model[prop];
            req['revision'] = this.model.revision;
            this.subs.sink = this.kitService.patch(this.kitId, req).subscribe(resp => {
                this.toastr.success('Sheet updated');
            });
        }
    }

    hasPermission(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE)
    }

    hasPermissionAny(resource: string, permission: string[]) {
        return this.ra.hasPermission(resource, permission, ResourceConstants.INV_MODULE)
    }

    // hasPermission(permission: string) {
    //     return this.ra.hasPermission(this.Resources.INV_KITS, [permission], this.Resources.INV_MODULE);
    // }

    hasPermissionWR(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], this.Resources.INV_MODULE);
    }

    hasPricePermission(resource: string) {
        let perm = this.kitId > 0 ? this.Permissions.MODIFY : this.Permissions.CREATE;
        return this.hasPermissionWR(resource, perm);
    }

    onFileDelete(fileId:string) {
        if (this.model.images) {
            this.model.images = this.model.images.filter(id => id != fileId);
            if (this.model.primary_image === fileId) {
                this.model.primary_image = null;
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
