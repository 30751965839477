import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Currency } from '../../../models/currency';
import { CurrencyService } from '../../../services/currency.service';

@Component({
  selector: 'currency-form',
  templateUrl: './currency-form.component.html',
  styleUrls: ['./currency-form.component.scss']
})
export class CurrencyFormComponent implements OnInit, OnDestroy {

  public model: Currency = new Currency();

  @Input() currencyId: number = 0;

  @Output() onSuccess = new EventEmitter<{ response: Currency, message: string }>();

  @Output() onError = new EventEmitter<any>();

  @Output() onCancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private service: CurrencyService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.currencyId > 0) {
      this.getSub$ = this.service.getById(this.currencyId).subscribe(Currency => {
        this.model = Currency;
      });
    }
  }

  onSubmit(): void {

    let api: Observable<Currency>;

    if (this.currencyId > 0)
      api = this.service.update(this.currencyId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;
      api = this.service.create(this.model);
    }

    this.postSub$ = api.subscribe(
      response => {
        let msg = this.currencyId > 0 ? 'Currency info updated' : 'New Currency created';
        this.nameExist = false;
        this.onSuccess?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.onError?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void {
    this.onCancel?.emit();
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
    this.postSub$?.unsubscribe();
  }
}
