<section class="dashlet-container d-flex flex-row flex-wrap mb-2" *ngIf="dashlet">
    <div class="status-tile" (click)="setFilter('service_contract_status','Pending')">
        <div class="card m-0 h-100 new_ticket_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Pending || 0}}</h3>
                            <span>Pending</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('service_contract_status','Active')">
        <div class="card m-0 h-100 in_progress_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Active || 0}}</h3>
                            <span>Active</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('service_contract_status','Draft')">
        <div class="card m-0 h-100 waiting_gradient esc_to_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body text-left">
                            <h3 class="mb-0">{{dashlet?.Draft || 0}}</h3>
                            <span>Draft</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('service_contract_status','Expired')">
        <div class="card m-0 h-100 esc_to_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Expired || 0}}</h3>
                            <span>Expired</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="status-tile" (click)="setFilter('expired_in_90_days','Expired_in_90_days')">
        <div class="card m-0 h-100 closed_gradient">
            <div class="card-content">
                <div class="card-body">
                    <div class="media pb-1">
                        <div class="media-body white text-left">
                            <h3 class="white mb-0">{{dashlet?.Expired_in_90_days || 0}}</h3>
                            <span>Expiring in 90 days</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                     [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter
                                     [module]="module"
                                     [defaultFilters]="defaults"
                                     [columns]="columns"
                                     [searchFilters]="search"
                                     [newButton]="UIPermissions.create"
                                     [newButtonUrl]="['/sales/service-contracts/add']"
                                     [expanded]="expanded"
                                     (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'created_user'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                                       [rows]="rows"
                                       [scrollbarH]="scrollbarH"
                                       class="bootstrap core-bootstrap"
                                       [headerHeight]="50"
                                       [footerHeight]="50"
                                       rowHeight="auto"
                                       [columnMode]="'force'"
                                       [selectionType]="SelectionType.single"
                                       [externalPaging]="true"
                                       [externalSorting]="true"
                                       [offset]="page.offset"
                                       [count]="page.count"
                                       [limit]="page.limit"
                                       (select)="onSelect($event)"
                                       (page)="setPage($event)"
                                       (sort)="onSort($event)"
                                       [sorts]="[{prop:'created_on',dir:'desc'}]">
                            <ngx-datatable-column name="Created Date" prop="created_on">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div>{{row.created_on | date:'MM/dd/YYYY h:mm a'}}</div>
                                    <div>{{row.unique_number}}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Title" prop="title"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Customer" prop="tenant_customer_name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="customer-details">
                                        <div class="cname">{{row.tenant_customer_name}}</div>
                                        <div *ngIf="row.tenant_customer_contact_name" class="contact">
                                            Contact : {{row.tenant_customer_contact_name}}
                                        </div>
                                        <div *ngIf="row.tenant_customer_location_name" class="location">
                                            Location : {{row.tenant_customer_location_name}}
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="SC covers" *ngIf="expanded" prop="coverage_type" [sortable]="true">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span *ngIf="row.coverage_type!=0">{{coverageType[row.coverage_type]}}</span>
                                    <span *ngIf="row.coverage_type==0">Line by line</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="SC Start/End Date" *ngIf="expanded" [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="row.starting_date" class="contact">
                                        Start : {{ trimTime(row.starting_date) | date:'MM/dd/YYYY'}}
                                    </div>
                                    <div *ngIf="row.ending_date" class="location">
                                        End : {{ trimTime(row.ending_date) | date:'MM/dd/YYYY'}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by" [sortable]="false">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <user-card [name]="value"></user-card>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="service_contract_status" [sortable]="true">
                                <ng-template let-row="row" let-status="value" ngx-datatable-cell-template>
                                    <ng-container>
                                        <div class="status-badge text-center"
                                             [class]="statusClass(row.service_contract_status)">{{row.service_contract_status}}</div>
                                        <span class="text-danger text-center mt-1 d-block" *ngIf="row.expired_in_90_days">Expiring soon</span>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Actions" *ngIf="expanded&&(UIPermissions.viewInfo||UIPermissions.edit||UIPermissions.delete)" [minWidth]="20" [sortable]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                                (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="UIPermissions.viewInfo" class="w-100" (click)="onSelect({selected:[current]})"
                                                    ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>
                                            <a class="w-100" *ngIf="UIPermissions.edit" [routerLink]="['/sales/service-contracts/',current.id,'edit']"
                                               ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button *ngIf="UIPermissions.delete" class="w-100 bg-light-danger" (click)="deleteSC(current)"
                                                    ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>

                        </ngx-datatable>
                    </div>
                </div>
            </div>

            <div class="parellax-detail" *ngIf="!expanded && selected">

                <div class="parellax-detail-content"  >
                    <app-service-contract [item]="selected"  (onDelete)="deleteSC($event)" (onClose)="closeDetailedView()" #ServiceContractView></app-service-contract>
                    <!-- <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0 text-left">{{selected?.unique_number}}</h5>
                        <div class="d-flex justify-content-e nd align-items-start">
                            <a *ngIf="UIPermissions.edit" [routerLink]="['/sales/service-contracts', selected.id, 'edit']" ngbTooltip="Edit"
                               class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button *ngIf="UIPermissions.delete" class="btn btn-sm bg-light-danger ml-1" (click)="deleteSC(selected)">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>

                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                        <li ngbNavItem>
                            <a ngbNavLink class="d-flex align-items-center">
                                <span class="d-none d-sm-block">Overview</span>
                            </a>
                            <ng-template ngbNavContent class="tab-content-pd">
                                <div class="group mt-2">
                                    <app-service-contract [item]="selected" #WarrantyView></app-service-contract>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink class="d-flex align-items-center">
                                <span class="d-none d-sm-block">Comments</span>
                            </a>
                            <ng-template ngbNavContent class="tab-content-pd">
                                <div style="max-width: 500px;">
                                    <sc-comments [scId]="selected.id"></sc-comments>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink class="d-flex align-items-center">
                                <span class="d-none d-sm-block">History</span>
                            </a>
                            <ng-template ngbNavContent class="tab-content-pd">
                                <div style="max-width: 500px;">
                                    <history-view [module]="'SERVICE_CONTRACT'" [uniqueId]="selected.id"></history-view>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" ></div> -->
                </div>
            </div>
        </div>
    </div>
</section>
