import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { imList } from '../models/important-messages.models';
import { BaseService } from 'src/app/core/services/base.service';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportantMessagesService extends BaseService {

  
  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<imList[]>(`${this.baseURL}/tenant_customers/messages/`);
  }

  getFiltered(id:number, request: DataFilterRequest, offset: number = 0) {
    let url = `${this.baseURL}/tenant_customer/`+id+`/messages/filter/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<imList[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getById(id: number) {
    return this.http.get<imList>(`${this.baseURL}/tenant_customers/messages/${id}/`);
  }

  create(model: imList) {
   
    return this.http.post<imList>(`${this.baseURL}/tenant_customers/messages/`, model);
  }

  update(id: number, model: imList) {
   
    return this.http.put<imList>(`${this.baseURL}/tenant_customers/messages/${id}/`, model);
  }

  // patch(id: number, req: any) {
  //   return this.http.patch<imList>(`${this.baseURL}/tenantcustomer/reasonforservice/${id}/`, req);
  // }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/tenant_customers/messages/${id}/`);
  }

  // updateStatus(id: number, status: boolean): Observable<boolean> {
  //   return this.patch(id, { is_active: status }).pipe(
  //     map(resp => true),
  //     catchError(() => of(false))
  //   );
  // }
  
}
