<section class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
    <div class="d-flex flex-row flex-1 h-100">
        <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
            <div class="d-flex" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                <datagrid-filter #dataGridFilter [defaultFilters]="defaults" [searchFilters]="search"
                    [newButton]="UIPermissions.create"
                    (newButtonClick)="openSupplierForm(supplierFormModal)"
                    [expanded]="expanded"
                    [columns]="columns"
                    (apply)="applyFilter($event)">
                    <ng-template filterControl [column]="'category'" let-row="row">
                        <category-select class="ng-select-sm" [(value)]="row.value"></category-select>
                    </ng-template>
                    <ng-template filterControl [column]="'items'" let-row="row">
                        <item-select class="select-sm" [selectBox]="true" [(value)]="row.value"></item-select>
                    </ng-template>
                    <ng-template filterControl [column]="'suborg'" let-row="row">
                        <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue"></suborg-select>
                    </ng-template>
                </datagrid-filter>
            </div>
            <div class="card-content">
                <div [ngClass]="{'card-body':expanded}">
                    <ngx-datatable #dataTable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" [sorts]="[{prop:'supplier_name',dir:'asc'}]"
                        (select)="onSelect($event)" [count]="page.count" [externalPaging]="true" [offset]="page.offset" [externalSorting]="true" [limit]="page.limit" (page)="setPage($event)" (sort)="onSort($event)">
                        <ngx-datatable-column name="Name" prop="supplier_name"></ngx-datatable-column>
                        <ngx-datatable-column name="Address" prop="address"></ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded" name="Account Number" prop="account_number">
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded" name="Created Date" prop="created_on">
                            <ng-template let-crdate="value" ngx-datatable-cell-template>
                                {{crdate | date:'MM/dd/YYYY' }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by" [sortable]="false">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{ row.quickbook_id ? 'Quickbooks Admin' : row.created_by }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded && isAllSuborg" name="Suborg" prop="suborg_name" [sortable]="false"></ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [sortable]="false">
                            <ng-template let-status="value" ngx-datatable-cell-template>
                                <div class="badge" [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                    {{ status ? 'Active' : 'Inactive' }}
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded && !isAllSuborg" name="Action" prop="id" [sortable]="false" [width]="80" [canAutoResize]="false">
                            <ng-template let-id="value" ngx-datatable-cell-template>
                                <div ngbDropdown container="body">
                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                        <i class="ft-more-vertical text-primary"></i>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <button class="w-100" *ngIf="UIPermissions.edit" (click)="openSupplierForm(supplierFormModal,id)" ngbDropdownItem>
                                            <i class="ft-edit mr-1"></i> Edit
                                        </button>
                                        <button class="w-100 bg-light-danger" *ngIf="UIPermissions.delete" (click)="deleteSupplier(id)" ngbDropdownItem>
                                            <i class="ft-trash-2 mr-1"></i> Delete
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                <div class="page-count" *ngIf="expanded">
                                    <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                </div>
                                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                    (change)="dataTable.onFooterPage($event)">
                                </datatable-pager>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
        <div class="parellax-detail" *ngIf="!expanded && selected">
            <div class="parellax-detail-content">
                <div class="supplier-details">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">{{selected?.supplier_name}}</h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a (click)="openSupplierForm(supplierFormModal,selected.id)" *ngIf="UIPermissions.edit && !isAllSuborg" ngbTooltip="Edit" class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button *ngIf="UIPermissions.delete && !isAllSuborg" class="btn btn-sm bg-light-danger ml-1" (click)="deleteSupplier(selected.id)">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="group mt-3">
                        <div class="group-header d-flex justify-content-between">
                            Overview
                            <div class="status-toggle-btn">
                                <label class="title">Status :</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="selected.is_active" (change)="changeStatus()" id="status" name="active" class="custom-control-input"
                                        [disabled]="selected.is_active ? !UIPermissions.deactivate : !UIPermissions.activate">
                                    <label class="custom-control-label" for="status">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="group-body">
                            <div class="ov-row" *ngIf="selected.address">
                                <label class="col-md-3 p-0 item-label">Address</label>
                                <label class="item-label-value">{{selected.address}}</label>
                            </div>
                            <div class="ov-row" *ngIf="selected.account_number">
                                <label class="col-md-3 p-0 item-label">Account Number</label>
                                <label class="item-label-value">{{selected.account_number}}</label>
                            </div>
                            <div class="ov-row" *ngIf="selected?.created_on">
                                <label class="col-md-3 p-0 item-label">Created On</label>
                                <label class="item-label-value">{{selected.created_on | date:'MM/dd/YYYY'}}</label>
                            </div>
                            <div class="ov-row" *ngIf="selected">
                                <label class="col-md-3 p-0 item-label">Created By</label>
                                <label class="item-label-value">
                                    {{selected.quickbook_id? 'Quickbooks Admin' : (selected.created_by || '')}}
                                </label>
                            </div>
                            <div class="ov-row" *ngIf="selected.suborg_name">
                                <label class="col-md-3 p-0 item-label">Suborg</label>
                                <label class="item-label-value">{{selected.suborg_name}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="group group-supplier mt-3" *ngIf="selected">
                        <div class="group-header">
                            Contacts
                            <button *ngIf="!isAllSuborg" class="btn btn-sm btn-secondary float-right" (click)="contactComponent.toggleForm()">
                                Add Contact
                            </button>
                        </div>
                        <div class="group-body-bordered col-12 p-0">
                            <contacts #contactComponent [module]="module" [unique_id]="selected.id"></contacts>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #supplierFormModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{supplierId > 0 ? 'Edit Supplier' : 'New Supplier'}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form name="form" #supplierForm="ngForm" (ngSubmit)="supplierForm.form.valid && supplierOnSubmit()" novalidate>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="val-required">Name</label>
                            <input type="text" [(ngModel)]="model.supplier_name" #name="ngModel" name="supplier_name" class="form-control" required>
                            <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                                <div *ngIf="name.errors.required">
                                    Name is required.
                                </div>
                            </small>
                            <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
                        </div>
                        <div class="form-group">
                            <label>Address</label>
                            <textarea type="text" [(ngModel)]="model.address" #add="ngModel" name="description" class="form-control"></textarea>
                        </div>
                        <div class="form-group">
                            <label>Account Number</label>
                            <input type="text" [(ngModel)]="model.account_number" name="account_number" class="form-control">
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                        <button type="submit" [disabled]="!supplierForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                            {{supplierId > 0 ? 'Save Changes' : 'Add Supplier'}}
                        </button>
                        <button type="button" (click)="d('Cancel')" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>
</section>
