import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { CallCenterService } from '../../services/call-center.service';
import { SipConnectionService } from '../../services/sip-connection.service';
import { StatusValueEnum } from 'src/app/core/enums/common.enum';
import { ToastrService } from 'ngx-toastr';
import { CallCenterSpec, UserStatusUpdateRequest } from '../../models/call-center.models';
import { SubSink } from 'subsink';
import { SipOutBoundFunctionService } from '../../services/sipoutboundfunctionservice';
import { CallCenterComponent } from '../../pages/call-center/call-center.component';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RegistererState } from 'sip.js';
import { sipInboundFunctionService } from '../../services/sipinbound-function.service';
import { BehaviorSubject } from 'rxjs';
import { WebSockectService } from '../../services/web-sockect.service';
import * as moment from 'moment';
import { AgentStatusService } from '../../services/agent-status.service';
import { CallcenterSharedService } from '../../services/call-center-shared.service';
@Component({
  selector: 'ring2voice-callcenter-agent',
  templateUrl: './callcenter-agent.component.html',
  styleUrls: ['./callcenter-agent.component.scss']
})
export class CallcenterAgentComponent implements OnInit {

  @Output() openCallCenter = new EventEmitter<boolean>()

  // @ViewChild(CallCenterComponent)
  
  // private callcenterInbound: CallCenterComponent;

  public subs: SubSink = new SubSink();

  public showList: boolean = false;

  public showMediaPopup: boolean = false;

  public modalSpec: CallCenterSpec = new CallCenterSpec();

  public callcntr_activeTabId: number = 1

  public callLogOpen: boolean = true;

  public callModal: NgbModalRef;

  public pingInterval;

  @ViewChild('callcentertModal') callcentertModal: ElementRef;

  constructor(public callCenterService: CallCenterService,
    private toastr: ToastrService,
    public sipConnectionService: SipConnectionService,
    public sipOutBoundFnService: SipOutBoundFunctionService,
    public modal: NgbModal,
    private cdr: ChangeDetectorRef,
    public sipInboundFnService: sipInboundFunctionService, 
    public agentStatusService : AgentStatusService,
    public callcenterSharedService: CallcenterSharedService,
    public sockectService:WebSockectService) { }

  ngOnInit(): void {

    let data = localStorage.getItem(AppConstants.CALLCENTER_SPEC);
    if (data) {
      this.modalSpec = JSON.parse(data);
    }

    if (!this.callCenterService.isCustomer) {

      this.getAgentDetailsApi();
    }
    this.subs.sink =this.sipConnectionService.agentStatusChange.subscribe((val) => {
      this.showList = false
      this.cdr.markForCheck()
    })

    this.subs.sink = this.callCenterService.popupOpenStatus.subscribe((val) => {
      if (val.isOpen == true && this.callCenterService.isOpenCallCenter == false) {
        this.openModal(this.callcentertModal)
        this.callcntr_activeTabId = val.selectedTab
      }
      else {
        this.callcntr_activeTabId = val.selectedTab
      }
    });

   this.subs.sink = this.callCenterService.agentStatusChange.subscribe((val) => {

      this.agentStatusService.checkStatus(val.status, 'From ngOnInit', val.updateStatus)
    });
  }



  ngAfterViewInit() {

    this.sipOutBoundFnService.mediaElement = document.getElementById('audio') as HTMLAudioElement;

    
    this.sipInboundFnService.remoteAudio = document.getElementById('audio') as HTMLAudioElement;

    this.agentStatusService.checkUserMedia();
    // this.socketReconnect();
    // this.connectSocket2(false, 'From ngAfterViewInit');
    //Ping Interval start
    this.pingInterval=setInterval(()=>{this.agentStatusService.pingSocket()},2000);
    //Ping Interval End
    this.agentStatusService.watchForChanges()
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.showMediaPopup = false;
  }

  getAgentDetailsApi() {

    this.subs.sink = this.callCenterService.getById().subscribe(responds => {
      this.callcenterSharedService.sip_details = responds
      console.log('sipdet', this.callcenterSharedService.sip_details)
      this.callCenterService.isalwaysOpen = responds.auto_open;
      this.callCenterService.isalwaysOpenAddContact = responds.auto_open_add_contact;
      if (this.callcenterSharedService.sip_details.status == StatusValueEnum.ON_CALL) {
        this.agentStatusService.checkStatus(StatusValueEnum.READY, 'agent details api', true);
      }
      this.cdr.markForCheck();
    });
  }


  openModal(content: any) {


    if (this.sipInboundFnService.isIncomingCall == true) {
      this.callcntr_activeTabId = 1
    }
    else {
      this.callcntr_activeTabId = 2

    }
    this.callCenterService.isOpenCallCenter = true
    this.callModal = this.modal.open(content, { scrollable: true, size: 'xl', backdrop: false, windowClass: 'callcenter-modal' });
    this.callLogOpen = false
    this.cdr.markForCheck();
  }

  closeModal() {   //del

    if (this.callModal) {
      if (this.sipInboundFnService.allowClearCallQ && !this.sipInboundFnService.isCall) {
        this.sipInboundFnService.clearCallQ()
      }
      if (this.sipOutBoundFnService.allowClearCallQ && !this.callCenterService.isActiveCallOutbound) {
        this.sipInboundFnService.clearCallQ()
      }
      this.callModal.close();

      this.callCenterService.isOpenCallCenter = false
      this.callLogOpen = true;
    }

    if (!this.sipInboundFnService.isIncomingCall) {
      this.sipInboundFnService.selectedCaller = null
    }

    this.sipOutBoundFnService.outBoundShowType = "dialer"

    this.cdr.markForCheck();


  }

  changeUserMedia() {
    navigator.mediaDevices.getUserMedia({ 'audio': { deviceId: this.callcenterSharedService.selectedAudioInput ? { exact: this.callcenterSharedService.selectedAudioInput } : undefined } })
      .then(stream => console.log("Media changed"))
      .catch(err => alert('Allow permission for microphone and try again'));
  }


  toggleList() {
    this.showList = !this.showList;
  }

  selectOption(option: string) {
    this.callcenterSharedService.sip_details.status = option;
    this.agentStatusService.checkStatus(this.callcenterSharedService.sip_details.status,'Select option', true)
    this.showList = true;
  }

  openMediaPopup($event) {
    $event.stopPropagation();
    this.showMediaPopup = !this.showMediaPopup;
  }

  modalResized(event) {

    if (event?.complete) {
      let data = localStorage.getItem(AppConstants.CALLCENTER_SPEC);
      let spec: CallCenterSpec;
      if (data) {
        spec = JSON.parse(data);
      }
      else {
        spec = new CallCenterSpec();
      }

      spec.height = event.height;

      this.modalSpec.height = event.height;

      localStorage.setItem(AppConstants.CALLCENTER_SPEC, JSON.stringify(spec));

    }

  }
    ngOnDestory(){
      this.subs.unsubscribe();
      this.agentStatusService.onDestroy()
    }

}
