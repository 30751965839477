import { Pipe, PipeTransform } from '@angular/core';
import { MaskPipe } from 'ngx-mask';

@Pipe({
    name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

    constructor(private maskPipe:MaskPipe) {}

    transform(value: any, ...args: any[]): any {
        if (!value) return "";
        value = value.replace(/\D/g, '').slice(-10);
        return this.maskPipe.transform(value,'(000) 000-0000');
    }
}
