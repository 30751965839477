import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CustomerLocationSelectComponent } from 'src/app/modules/tenant-customers/components/select/customer-location-select/customer-location-select.component';
import { CustomerContact, QuickContactRequest } from 'src/app/modules/tenant-customers/models/tenant-customer';
import { TenantCustomerService } from 'src/app/modules/tenant-customers/services/tenant-customer.service';
import { SubSink } from 'subsink';
import { CallCenterService } from '../../services/call-center.service';
import { CallCenterComponent } from '../../pages/call-center/call-center.component';

@Component({
  selector: 'app-call-center-quick-contact-form',
  templateUrl: './call-center-quick-contact-form.component.html',
  styleUrls: ['./call-center-quick-contact-form.component.scss']
})
export class CallCenterQuickContactFormComponent implements OnInit {

  public model: QuickContactRequest = new QuickContactRequest();

  @ViewChild(CustomerLocationSelectComponent) locationSelect: CustomerLocationSelectComponent;

  public customerCheckBox:boolean=true;

  public locationCheckBox:boolean=true;

  public tenantCustomerId: number;

  @Input() incomingcall_Details;
  
  @Output() locationAdd = new EventEmitter();

  @Output() success = new EventEmitter<{ response: CustomerContact, message: string }>();

  @Output() cancel = new EventEmitter<any>();

  @Output() error = new EventEmitter<any>();

  @Output() edit = new EventEmitter<any>();

  private subs: SubSink = new SubSink();

  public contact_id: number = 0;


  constructor(private service: TenantCustomerService,
    private callCenter:CallCenterComponent,
    private callCenterService: CallCenterService,
    private toastr:ToastrService) { }

  ngOnInit(): void {
    this.model.contact_mobile = this.incomingcall_Details?.call_from;
  }

  onLocationAdded() { this.locationAdd?.emit(); }

  customerChanged(){
    this.contact_id=null;
    console.log("Customer just changed");
    this.tenantCustomerId = this.model.tenant_customer_id;
    this.locationSelect?.clear();
  }

  submit() {
    this.model.customer = this.service.customerId;
    this.model.suborg = this.incomingcall_Details.call_to_suborg_id;
    if(this.customerCheckBox&&!this.model.tenant_customer_id){
      this.model.tenant_customer_name = this.model.contact_last_name?this.model.contact_first_name+' '+this.model.contact_last_name:this.model.contact_first_name;
    }else if(this.model.tenant_customer_id){
      this.model.tenant_customer_name = null;
    }
    this.model.location_name = this.locationCheckBox?this.model.location_address: this.model.location_name;
    this.model.location_id =  this.model.tenant_customer_id? this.model.location_id: null;
    if(this.model.location_id){
      this.model.location_address=null;
      this.model.location_name=null;
      this.model.location_city=null;
      this.model.location_state=null;
      this.model.location_zip=null;
    }
    console.table(this.model)

    let api: Observable<CustomerContact>;
      // this.model.tenant_customer_id = this.tenantCustomerId;
    api = this.service.createQuickCustomerContact(this.model);
    this.subs.sink = api.subscribe(
      resp => {
        this.mapingCallDetails(resp);
        if (resp.location > 0) {
          resp.location_name = this.locationSelect?.getLocation(resp.location)?.name;
        }
        let msg = 'New Contact added';
        this.success?.emit({ response: resp, message: msg });
      },
      (err) => {
        if (err.error && err.error['non_field_errors']) {
          this.toastr.error(err.error['non_field_errors'][0])
        }
        else {
          this.error?.emit(err);
        }
      }
    );

  }

  mapingCallDetails(resp){
    this.incomingcall_Details.contact_name = resp.last_name?resp.first_name+' '+resp.last_name:resp.first_name;
    this.incomingcall_Details.contact_email = resp.email
    this.incomingcall_Details.contact_phone = resp.phone
    this.incomingcall_Details.contacts_id = resp.id;
    this.incomingcall_Details.tenant_customer_name = resp.tenant_customer_name
    this.incomingcall_Details.tenant_customer_id = resp.tenant_customer
    this.incomingcall_Details.location_name = resp.location_name
    this.incomingcall_Details.location_address = resp.location_address
    this.incomingcall_Details.location_city = resp.location_city
    this.incomingcall_Details.location_state = resp.location_state
    this.incomingcall_Details.location_zip = resp.location_zip
    this.incomingcall_Details.location_id = resp.location
  }

  onCancelClicked(): void { this.cancel?.emit(); }

  openEdit(){
    this.onUpdateContact();
  }

  onUpdateContact(){
    if(this.contact_id){
    this.edit?.emit({contact_id:this.contact_id});
    }
  }


}
