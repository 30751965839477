<section>

    <div class="d-flex mt-4 flex-space-bw">
        <h4 class="card-title">Label page template</h4>
        <div class="">
            <a [routerLink]="['/inventory/labels/pages/add']"  class="btn btn-sm gradient-pomegranate mr-1">
                <i class="ft-plus mr-1"></i> New
            </a>
            <!-- <input #searchInput class="form-control form-control-sm width-200" type="text" placeholder="Search item type..."  /> -->
        </div>
    </div>


    <div class="d-flex row">
           <div class="col-md-2" *ngFor="let page of pageList;let index=index">
                <div class="card label-paper-card" >
                    <div class="card-body">
                        <img src="../../../../../assets/img/label/page1.png" class="label-paper-img"/>
                        <p class="label-page-name">{{page.title}}</p>
                        <p class="label-template-name">Template: <span>{{page.label_template_title}}</span></p>
                        <!-- <p class="label-page-color">White</p> -->
                        <p class="label-page-count">{{page.total_labels}} per sheet</p>

                        <div ngbDropdown container="body" class="action-button">
                            <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                            <i class="ft-more-vertical text-primary"></i>
                        </button>
                            <div ngbDropdownMenu>
                                <!-- <button  class="w-100"  ngbDropdownItem>
                                <i class="ft-book-open mr-1"></i> View
                                </button> -->
                                <a  class="w-100" [routerLink]="['/inventory/labels/pages',page.id, 'edit']"  ngbDropdownItem>
                                    <i class="ft-edit mr-1"></i> Edit
                                </a>
                                <button  class="w-100 bg-light-danger" (click)="deletePage(page.id, index)"  ngbDropdownItem>
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            </div>
                        </div>

                    </div>
                </div>  
           </div>
  
    </div>
</section>
