<div class="timesheet-container">
    <!-- <ngx-datatable class="bootstrap core-bootstrap timesheet-table" [scrollbarH]="false" [headerHeight]="40" rowHeight="auto" [columnMode]="'force'" [rows]="rows" [selectionType]="selectionType.single" [sorts]="[{prop:'assigned_date',dir:'desc'}]" (select)="onSelect($event)">
        <ngx-datatable-column name="Timesheet Date" prop="assigned_date">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div>{{row.assigned_date |date:'MM/dd/YYYY'}}</div>
                <div>{{row.unique_number}}</div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Comments" prop="comments"></ngx-datatable-column>
        <ngx-datatable-column name="Total Time" prop="time">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span *ngIf="value">{{getTime(value)}}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Billing" prop="is_billable">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div>{{value?'Billable':'Not Billable'}}</div>
                <div *ngIf="getBillingStatus(row.billing_status) as stat" [class]="stat.class">
                    {{stat.key}}
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Total Amt" prop="total_amount">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span *ngIf="value">$ {{value}}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created By" prop="created_by" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <user-card [name]="value"></user-card>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Action" prop="id" [sortable]="false" [width]="70" [canAutoResize]="false">
            <ng-template let-id="value" ngx-datatable-cell-template>
                <div ngbDropdown container="body">
                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                        <i class="ft-more-vertical text-primary"></i>
                    </button>
                    <div ngbDropdownMenu>
                        <button *ngIf="hasPermission(Permissions.MODIFY)" (click)="open(id)" ngbDropdownItem>
                            <i class="ft-edit mr-1"></i> Edit
                        </button>
                        <button *ngIf="hasPermission(Permissions.DELETE)" (click)="deleteRow(id)" class="w-100 bg-light-danger" ngbDropdownItem>
                            <i class="ft-trash-2 mr-1"></i> Delete
                        </button>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable> -->

    <div class="col-md-12 group mt-2 p-0">
        <div class="d-flex justify-content-between mb-1">
            <div class="st-group-header">
                <span style="color: #370D7E;">Labor</span>
                <span style="color: #D52095;" class="ml-1">Section</span>
            </div>
        </div>
        <div class="group-body">
            <div class="row m-0">
                <div class="table-responsive col-12 p-0">
                    <div class="col-12 p-0">
                        <table class="table invoice-items-table st-items-table">
                            <thead>
                                <tr class="row-head">
                                    <th width="30">#</th>
                                    <th width="100">Date</th>
                                    <th width="350">Comments</th>
                                    <th width="150">Total Time</th>
                                    <th width="150" class="text-right">Total Amount</th>
                                    <th width="100">Action</th>
                                </tr>
                            </thead>
                            <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                                <ng-container *ngFor="let row of rows; index as i;">
                                    <tr (click)="open(row.id)" class="timesheet-tr" cdkDrag cdkDragLockAxis="y">
                                        <td cdkDragHandle ><mat-icon>control_camera</mat-icon></td>
                                        <td>
                                            <div>{{row.assigned_date |date:'MM/dd/YYYY'}}</div>
                                            <div>{{row.unique_number}}</div>
                                        </td>
                                        <td>
                                            <div>{{row.comments}}</div>
                                        </td>
                                        <td>
                                            <div>{{getTime(row.time)}}</div>
                                        </td>
                                        <td class="text-right">
                                            <div [ngClass]="row.is_billable? '': 'opacity-25'">
                                                {{row.total_amount|currency}}
                                            </div>
                                        </td>
                                        <td (click)="$event.stopPropagation();">
                                            <div>
                                                <button type="button" class="delete-btn text-danger" (click)="deleteRow(row.id)">
                                                    <i class="ft-trash-2"></i>
                                                </button>
                                                <div class="checkbox checkbox-sm" ngbTooltip="Is Billable">
                                                    <input
                                                        type="checkbox"
                                                        class=""
                                                        (click)="changeIsBillable(row)"
                                                        [(ngModel)]="row.is_billable"
                                                        id="billable-check-{{i}}">
                                                    <label
                                                        class=""
                                                        for="billable-check-{{i}}">
                                                        <span>Billable</span>
                                                    </label>
                                                </div>
                                                <div class="checkbox checkbox-sm" ngbTooltip="Show In PDF">
                                                    <input
                                                        type="checkbox"
                                                        class=""
                                                        (click)="changeShowInPdf(row)"
                                                        [(ngModel)]="row.is_show_in_pdf"
                                                        id="pdf-check-{{i}}"
                                                        [disabled]="row.is_billable">
                                                    <label
                                                        class=""
                                                        for="pdf-check-{{i}}">
                                                        <span>Show In PDF</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- Preview -->
                                        <div *cdkDragPreview matchSize="true"
                                            class="container dragdrop-placeholder" style="width: 50%;">

                                            <span>{{row.unique_number}}</span>
                                            <span>{{getTime(row.time)}}</span>
                                            <span>{{row.total_amount|currency}}</span>
                                        </div>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="!rows || rows.length <= 0">
                                    <td colspan="7">No items selected.</td>
                                </tr>
                                <tr class="summary-tr">
                                    <td colspan="3" class="text-left">
                                        <button *ngIf="hasPermission(Permissions.CREATE)" (click)="open()"
                                        class="btn btn-sm st-group-btn">
                                        <span class="icon-span">
                                            <i class="ft-plus plus-icon" style="color: #D52095;"></i>
                                        </span>
                                            Add Labor
                                        </button>
                                    </td>
                                    <td class="text-right">Total</td>
                                    <td class="text-right">{{calculateTimesheetTotal()|currency}}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{this.timeSheetId > 0 ? 'Edit TimeSheet' : 'Add TimeSheet'}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <time-sheet-form
            [uniqueId]="timeSheetId"
            [suborg_id]="suborg_id"
            [bindingModel]="timeSheet"
            [serviceTicketId]="serviceTicketId"
            [tenantCustomerId]="tenantCustomerId"
            [tenantCustomerLocationId]="tenantCustomerLocationId"
            [tenantCustomerContactId]="tenantCustomerContactId"
            [customerSelectReadonly]="true"
            (success)="onDataSubmittedSuccess($event)"
            (error)="onDataError()" (cancel)="close()">
        </time-sheet-form>
    </div>
</ng-template>
