<ng-select [placeholder]="placeholder"
           [items]="options | async | orderBy:'name'"
           bindLabel="name"
           bindValue="id"
           [multiple]="multiple"
           [(ngModel)]="value"
           [readonly]="readonly"
           [typeahead]="textInput"
           [trackByFn]="trackByFn"
           [closeOnSelect]="!multiple"
           (change)="valueChanged($event)"
           (clear)="onClear()">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input *ngIf="multiple" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
        <span class="ml-1">{{item.name}}</span>
    </ng-template>
    <ng-template ng-footer-tmp>
        <div class="footer-container p-1 d-flex flex-row justify-content-between">
            <div class="paging-label">Showing {{currentCount}} of {{totalCount}}</div>
        </div>
    </ng-template>
</ng-select>
