import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { CustomerLicense } from './../../models/license-number';
import { LicenseNumberService } from './../../services/license-number.service';
import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TenantCustomer } from '../../models/tenant-customer';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as popup from 'src/app/core/utils/popup.functions';
import { DataFilterRequest, GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';

@Component({
  selector: 'license-number-customers',
  templateUrl: './license-number-customers.component.html',
  styleUrls: ['./license-number-customers.component.scss']
})
export class LicenseNumberCustomersComponent implements OnInit, OnDestroy {

  @ViewChild('FormModal') modalContent: any;

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  public page = { count: 0, limit: 25, offset: 0, pageNumber: '0-0' };

  private _licenseId: number;

  private _customerid: number;

  @Input() set licenseId(val: number) {
    this._licenseId = val;
    if (val) { this.getData(); }
  }

  get licenseId() { return this._licenseId; };

  @Input() set customerId(val: number) {
    this._customerid = val;
    if (val) { this.getData(); }
  }

  get customerId() { return this._customerid; };

  public rows: CustomerLicense[];

  public model: CustomerLicense;

  public Permissions = PermissionConstants;

  public SelectionType = SelectionType;

  private subs: SubSink = new SubSink();

  public tenantCustomer: TenantCustomer;

  private modalRef: NgbModalRef;

  public searchFilters: GFilterParam[] = [
    {
      displayName: 'Combo', colname: '', condition: '', value: '',
      children: [
        { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Location Name', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Customer Type Name', colname: 'customer_type_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact First Name', colname: 'first_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact Last Name', colname: 'last_name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact Email', colname: 'contact_email', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact Phone', colname: 'contact_phone', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Contact Mobile', colname: 'contact_mobile', condition: 'contains', value: '', operator: 'OR' },
      ]
    },
    { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'OR' },
    { displayName: 'Customer Type', colname: 'customer_type_name', condition: 'contains', value: '', operator: 'OR' },
  ];

  public columns: GFColumn[] = [
    { displayName: 'Customer', columnName: 'tenant_customer', columnType: GFColumnTypes.ref },
    { displayName: 'Customer Type', columnName: 'customer_type_id', columnType: GFColumnTypes.ref },
    { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
    { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref }
  ];

  public sort: GSortParam[] = [{ colname: 'customer_name', direction: 'asc' }];

  public sortLookup = {
    tenant_customer_name: 'customer_name',
    tenant_customer_type: 'tenant_customer_type',
    customer_location_name: 'location_name'
  };

  constructor(private licenseService: LicenseNumberService,
    private cdr: ChangeDetectorRef,
    private ra: ResourceAccessService,
    private modal: NgbModal,
    private toastr: ToastrService) { }

  ngOnInit() { }

  getData(filters: GFilterParam[] = [], offset: number = 0) {

    if (this.licenseId) {
      filters.unshift({ colname: 'license', condition: 'equalto', value: this.licenseId, operator: 'AND' });
    }
    else if (this.customerId) {
      filters.unshift({ colname: 'tenant_customer', condition: 'equalto', value: this.customerId, operator: 'AND' });
    }

    let request: DataFilterRequest = { filters: filters, sort: this.sort };

    this.subs.sink = this.licenseService.getCustomerLicenses(request, offset, this.page.limit).subscribe(resp => {
      this.rows = resp.result;
      this.setPagination(offset, resp.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: CustomerLicense = selected[0];
    if (this.hasPermission(this.Permissions.MODIFY)) {
      this.model = { ...this.rows.find(item => item.id === current.id) };
      this.openModal();
    }
  }

  onNewButtonClick() {
    this.model = new CustomerLicense();
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modal.open(this.modalContent, { scrollable: true, centered: true });
  }

  closeModal() { this.modalRef?.dismiss(); }

  submit() {
    let api: Observable<CustomerLicense | CustomerLicense[]>;
    if (this.model.id > 0) {
      api = this.licenseService.updateCustomerLicense(this.model.id, this.model);
    }
    else {
      this.model.customer = this.licenseService.customerId;
      this.model.license = this.licenseId;
      api = this.licenseService.createCustomerLicense([this.model]);
    }

    this.subs.sink = api.subscribe(
      resp => {
        if (this.model.id > 0) {
          this.rows = [...this.rows.filter(item => item.id !== this.model.id), ...[resp as CustomerLicense]];
          this.toastr.success('1 row updated');
        }
        else {
          this.toastr.success('New customer added');
          if (this.page.count < this.page.limit) {
            this.rows = [...this.rows, ...resp as CustomerLicense[]];
            this.page.count = this.rows.length;
          }
        }
        this.cdr.markForCheck();
        this.closeModal();
      },
      () => {
        this.toastr.success('Operation failed');
        this.closeModal();
      }
    );
  }

  deleteRow(id: number): void {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.licenseService.deleteCustomerLicense(id).subscribe(
          () => {
            this.rows = this.rows.filter(x => x.id !== id);
            this.setPagination(this.page.offset, this.page.count - 1);
            this.cdr.markForCheck();
            popup.CompleteDelete('Customer has been deleted.')
          },
          () => this.toastr.error('Unable to delete customer')
        );
      }
    });
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.CUSTOMER_LICENSE_NUMBERS, [permission, this.Permissions.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.CUSTOMER_MODULE, [this.Permissions.GLOBAL]);
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    let params = this.dataFilter?.getDataFilters() || [];
    this.getData(params, offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
