<section>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">{{sid ? 'Edit Time Frames' : 'Add Time Frames'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <app-add-time-frame
                    [selected_Id]="sid"
                    (success)="onDataSuccess($event)"
                    (error)="onDataError($event)"
                    (cancel)="onCancel()">
                </app-add-time-frame>
            </div>
        </div>
    </div>
</section>
