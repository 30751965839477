import { Task } from './../../../models/common.models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, of, Subject } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { filter, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { ChecklistService } from '../../../services/checklist.service';

@Component({
  selector: 'task-select',
  templateUrl: './task-select.component.html',
  styleUrls: ['./task-select.component.scss']
})
export class TaskSelectComponent implements OnInit {

  private _value: number;

  get value(): number {
    return this._value;
  }

  @Input() set value(val: number) {
    this._value = val;
    if (val > 0 && this.selected !== val) {
      this.setValue(val);
    }
    
    if(val==null){
      this.selected=null;
    }
    
    
  };

  @Output() valueChange = new EventEmitter<number>();

  @Input() addButton: boolean = false;

  @Input() fetchWhenOpen: boolean = false;

  @Input() readonly:boolean=false;

  @Input() suborg_id: number;

  public options: Observable<Task[]>;

  public placeholder: string;

  public totalCount: number = 0;

  public currentCount: number = 0;

  private _selected: number;

  public get selected(): number {
    return this._selected;
  }

  public set selected(value: number) {
    this._selected = value;
    this.valueChange?.emit(value);
  }

  public textInput = new Subject<string>();

  public initial: Task;

  public firstOpen: boolean = true;

  constructor(private service: ChecklistService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    this.placeholder = this.hasPermission() && this.addButton ? "Select or add Task" : "Select Task";
    if (!this.fetchWhenOpen) {
      this.loadInitial();
    }
  }

  loadInitial() {
    this.options = concat(this.getData(), this.textInput.pipe(
      filter(res => res !== null),
      distinctUntilChanged(),
      switchMap(term => this.getData(term))
    ));
    console.log(this.options,'options')
  }

  

  getData(search?: string) {
    let option = {
      sort: [{ colname: 'task_name', direction: 'asc' }],
      filters: search ? [{ colname: 'task_name', condition: 'contains', value: search, operator: 'AND' }] : null,
      parent_filters: [{ colname: 'is_global', condition: 'is', value: "true", operator: 'AND' }]
    };
    return this.service.getFiltered(option, 0, this.suborg_id,-1).pipe(
      map(resp => {
        if (this.initial && this.initial.id === this.selected) {
          resp.result = resp.result.filter(item => item.id !== this.selected).concat(this.initial);
        }
        this.totalCount = resp.count;
        this.currentCount = resp.result.length;
        return resp.result;
      })
    );
  }

  setValue(id: number) {
    this.options = this.service.getById(id).pipe(map(response => {
      this.initial = response;
      return [response];
    }));
    this._selected = id;
  }

  selectOpen() {
    if (this.fetchWhenOpen && (!this.options || this.firstOpen)) {
      this.loadInitial();
      this.firstOpen = false;
    }
  }

  onClear(){
    this.loadInitial();
  }

  onSearch(){
    this._selected = 0;
  }

  trackByFn(item: Task) { return item.id; }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.ST_REASONS_FOR_SERVICE, [PermissionConstants.CREATE, PermissionConstants.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.SERVICE_TICKET_MODULE, [PermissionConstants.GLOBAL]);
  }

}
