import { RouteInfo } from '../../core/models/route-info';
import { Directive, HostBinding, Inject, Input, OnInit, ElementRef, Renderer2, TemplateRef } from '@angular/core';
import { SidebarDirective } from "./sidebar.directive";

@Directive({
  selector: "[appSidebarlink]"
})
export class SidebarLinkDirective implements OnInit {

  @Input() public parent: string;

  @Input() public level: number;

  @Input() public hasSub: boolean;

  @Input() public path: string;

  @Input() public menuItem: RouteInfo;

  @HostBinding('class.open')
  @Input()
  get open(): boolean {
    return this._open;
  }
  set open(value: boolean) {
    this._open = value;
  }

  @HostBinding('class.sidebar-group-active')
  @Input()
  get sidebarGroupActive(): boolean {
    return this._sidebarGroupActive;
  }
  set sidebarGroupActive(value: boolean) {
    this._sidebarGroupActive = value;
  }

  @HostBinding('class.nav-collapsed-open')
  @Input()
  get navCollapsedOpen(): boolean {
    return this._navCollapsedOpen;
  }
  set navCollapsedOpen(value: boolean) {
    this._navCollapsedOpen = value;
  }

  protected _open: boolean;
  protected _sidebarGroupActive: boolean;
  protected _navCollapsedOpen: boolean;

  protected sideNav: SidebarDirective;

  public constructor(@Inject(SidebarDirective) sideNav: SidebarDirective,
    private element: ElementRef) {
    this.sideNav = sideNav;
  }

  public ngOnInit(): any {

    this.sideNav.addLink(this);

    if (this.menuItem) {
      this.parent = this.menuItem.title;
      this.hasSub = this.menuItem.submenu.length > 0;
      this.path = this.menuItem.path;
    }
  }

  public toggle(): any {
    this.open = !this.open;
    if (this.open) {
      this.sideNav.closeOtherLinks(this);
    }
    if (!this.open && this.level === 1 && this.hasSub) {
      this.sidebarGroupActive = false;
    }
  }
}
