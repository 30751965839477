<div class="form-container">
    <form #formElement="ngForm" (ngSubmit)="formElement.form.valid && submit()">
        <div class="form-group">
            <label class="val-required">Name</label>
            <input type="text" [(ngModel)]="model.name" #name="ngModel" name="name" class="form-control" required>
            <small class="form-text text-muted danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors.required">
                    Name is required.
                </div>
            </small>
            <small class="danger mt-1" *ngIf="nameExist">Name already exists</small>
        </div>
        <div class="form-group d-flex align-items-center">
            <label class="mr-2">Color</label>
            <div class="color-picker">
                <input #colorPicker type="color" [(ngModel)]="model.color" name="color" />
                <div class="color-picker-inner">
                    <div class="selection">
                        <div class="selected-color" [style.background-color]="model.color"></div>
                    </div>
                    <div (click)="colorPicker.click()" class="action">
                        <i class="ft-chevron-down"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Escalation Period</label>
            <escalation-select [fetchWhenOpen]="true" [(value)]="model.escalation_period">
            </escalation-select>
        </div>
        
        <div class="form-group">
            <label>Description</label>
            <textarea type="text" [(ngModel)]="model.description" name="description" class="form-control"></textarea>
        </div>

       


        <div class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                {{uniqueId > 0 ? 'Save Changes' : 'Save'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</div>