import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { catCustomFieldData } from '../../models/additional-fields.models';

@Component({
  selector: 'cat-additional-field-add',
  templateUrl: './cat-additional-field-add.component.html',
  styleUrls: ['./cat-additional-field-add.component.scss']
})
export class CatAdditionalFieldAddComponent implements OnInit {


  public _customField:catCustomFieldData;
  @Input() set customField(value:catCustomFieldData){

    this.valueOptions=value.category_value_list;

    this._customField=value;

  }

  get customField(){
    return this._customField;
  }

  @Input() index:number;

  public valueOptions=[];

  constructor(private cdr: ChangeDetectorRef,) { }

  ngOnInit(): void {

  }


  additionalFieldSelected(value:any){

    this.valueOptions=this.customField.category_value_list;

    this.cdr.markForCheck();

}

  deleteCustomField(index:number){

  }



}
