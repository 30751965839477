<div class="container">
    <div class="modal-header" style="border:none">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="card">
        <div class="row g-0">
            <div class="col-md-6 border-end">
                <div class="d-flex flex-column justify-content-center">
                    <div class="main_image"> <img [src]="fileUrl + selectedProductDetails.primary_image" id="main_product_image"
                            width="350" height="100%"> </div>
                    <div class="thumbnail_images">
                        <ul id="thumbnail">
                            <li *ngFor = 'let img of selectedProductDetails.images'><img (click)="changeImage(img)" [src]="fileUrl + img"  width="70" height="80"></li>
                        </ul>   
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="p-3 right-side">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3>{{selectedProductDetails.product_name}}</h3> 
                        <!-- <span class="   heart"><i class='bx bx-heart'></i></span> -->
                    </div>
                    <div class="mt-2 pr-3 content" style="min-height: 118px;">
                        <p *ngIf = "selectedProductDetails.product_description != null">{{selectedProductDetails.product_description}}</p>
                        <p *ngIf = "selectedProductDetails.product_description == null">No Details Available</p>
                    </div>
                    <h3>{{ (selectedProductDetails.sellprice) | currency : 'USD'}}</h3>
                    <!-- <div class="ratings d-flex flex-row align-items-center">
                        <div class="d-flex flex-row"> <i class='bx bxs-star'></i> <i class='bx bxs-star'></i> <i
                                class='bx bxs-star'></i> <i class='bx bxs-star'></i> <i class='bx bx -star'></i> </div>
                        <span>441 reviews</span>
                    </div> -->
                    <!-- <div class="mt-5"> <span class="fw-bold">Color</span>
                        <div class="colors">
                            <ul id="marker">
                                <li id="marker-1"></li>
                                <li id="marker-2"></li>
                                <li id="marker-3"></li>
                                <li id="marker-4"></li>
                                <li id="marker-5"></li>
                            </ul>
                        </div>
                    </div> -->
                  

                            <div class="input-group">
                                <input type="button" style="width: 30px;" value="-"
                                    class="button-minus border-0   icon-shape icon-sm mx-1 "  (click)="onChangeQuantity(1)" data-field="quantity">
                                <input type="number" step="1" [(ngModel)]="selectedProductDetails.quantity" max="100" name="quantity"
                                    class="quantity-field border text-center mr-1" style="width:46px" readonly>
                                <input type="button" style="width: 30px;" value="+"
                                    class="button-plus border-0  icon-shape icon-sm lh-0"  (click)="onChangeQuantity(0)" data-field="quantity">
                            </div>
                              <div class="buttons d-flex flex-row mt-5 gap-3"> 
                                <!-- <button class="btn btn-outline-dark">Buy Now</button>  -->
                            <button class="btn btn-dark" (click) = "addToCart()">+ Add to Cart</button> </div>
                    <!-- <div class="search-option"> <i class='bx bx-search-alt-2 first-search'></i>
                        <div class="inputs"> <input type="text" name=""> </div> <i class='bx bx-share-alt share'></i>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal-container">
 
    <div class="modal-header" style="border:none">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="preview-container">
    
    
    
            <div class="d-flex align-items-center justify-content-center" style="height: 250px;">
                <img [src]="fileUrl + selectedProductDetails.primary_image" width="150" height="220" alt="">
            </div>
    
            <div class="row det-container mt-3 ">
    
                <div class="col-md-6">
                    <p class="pro-title">{{selectedProductDetails.product_name}}</p>
                </div>
                <div class="col-md-6">
                    <div style="text-align: right;">
                        <p style="color:#999">Item # {{selectedProductDetails.product_part_number}}</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <p>{{selectedProductDetails.category_name}}</p> 
                    <p>Part Number: {{selectedProductDetails.part_number}}</p>
                    <p>SKU: {{selectedProductDetails.sku}}</p>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-start">
                        <div class="div">
                            <p >Unit Price {{ (selectedProductDetails.sellprice) | currency : 'USD'}}</p>
                            <div class="input-group">
                                <input type="button" style="width: 30px;" value="-"
                                    class="button-minus border-0   icon-shape icon-sm mx-1 "  (click)="onChangeQuantity(1)" data-field="quantity">
                                <input type="number" step="1" [(ngModel)]="selectedProductDetails.quantity" max="100" name="quantity"
                                    class="quantity-field border text-center mr-1" style="width:46px" readonly>
                                <input type="button" style="width: 30px;" value="+"
                                    class="button-plus border-0  icon-shape icon-sm lh-0"  (click)="onChangeQuantity(0)" data-field="quantity">
                            </div>
                            <p>Sub Total: <span style="font-weight: bold;">{{total | currency : 'USD'}}</span> </p>
                        </div>
    
                    </div>
    
                </div>
            </div>
            <div class="d-flex justify-content-center mt-1">
                <button type="button" class="btn btn-primary" style="width: 60%" (click) = "addToCart()">+ Add To Cart</button>
            </div>
    
    
        </div>
    </div>
</div> -->