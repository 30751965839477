<div *ngIf="selectBox" class="item-select-container select-sm" (click)="open()">
    <div class="value-container">
        <div *ngIf="!selected" class="placeholder">Select Build Assembly</div>
        <div class="combobox">
            <label class="w-100 m-0 item-name-label" *ngIf="selected">{{selected.kit_name}}</label>
        </div>
    </div>
    <span *ngIf="selected" (click)="clearSelection($event)" class="clear-wrapper d-flex" title="Clear all">
        <span aria-hidden="true" class="clear">×</span>
    </span>
    <span class="arrow-wrapper"><span class="arrow"></span></span>
</div>

<ng-template #buildAssembleModal let-c="close" let-d="dismiss">
    <div class="modal-body p-0">
        <section id="item-list">
            <div class="d-flex flex-row">
                <div class="col-12 d-flex flex-row p-0" [ngClass]="{'row-shrinked':!expanded}">
                    <div class="m-0" *ngIf="!isAllocate"
                         [ngClass]="{'dt-shrinked items-datatable':!expanded,'dt-expanded card':expanded}">
                        <div class="d-flex flex-space-bw filter-bar"
                             [ngClass]="{'card-header':expanded,'items-datatable-header mb-2':!expanded}">

                            <div class="w-100 p-2">
                                <datagrid-filter #dataFilter
                                                 [columns]="columns"
                                                 [searchFilters]="search"
                                                 [defaultFilters]="defaults"
                                                 [newButton]="false"
                                                 [expanded]="expanded"
                                                 (apply)="applyFilter($event)">
                                    <ng-template filterControl [column]="'category'" let-row="row">
                                        <category-select class="ng-select-sm" [(value)]="row.value"></category-select>
                                    </ng-template>
                                    <ng-template filterControl [column]="'items'" let-row="row">
                                        <item-select class="select-sm" [selectBox]="true" [(value)]="row.value">
                                        </item-select>
                                    </ng-template>
                                </datagrid-filter>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <div class="card-content ">
                                <div [ngClass]="{'card-body':expanded}">
                                    <ngx-datatable #dataTable
                                                   [scrollbarH]="scrollbarH"
                                                   class="bootstrap core-bootstrap grid-selection-custom-style"
                                                   [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                                                   rowHeight="auto"
                                                   [rows]="buildAssemble" [selectionType]="SelectionType.single"
                                                   [count]="page.count"
                                                   [externalPaging]="true" [offset]="page.offset"
                                                   [externalSorting]="true"
                                                   [sorts]="[{prop:'internal_number',dir:'asc'}]"
                                                   [limit]="page.limit" [selected]="selectedItem"
                                                   (page)="setPage($event,dataFilter)"
                                                   (sort)="onSort($event,dataFilter)"
                                                   (select)="onSelect($event)">
                                        <ngx-datatable-column [style.width]="expanded ? '60' : '90'" name="Image" prop="kit_image" [sortable]="false" >
                                            <ng-template let-url="value" ngx-datatable-cell-template>
                                                <img *ngIf="url" width="80" height="80" [src]="fileUrl + url + '.jpg'" />
                                                <img *ngIf="!url" width="30" height="30" src="/assets/img/svg/product-avatar.svg" />
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Name" prop="kit_name"></ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Part Number" prop="part_number">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="SKU" prop="kit_sku">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Category" prop="kit_category_name">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Sell Price" prop="kit_sellprice"
                                                              [sortable]="true">
                                            <ng-template let-price="value" ngx-datatable-cell-template>
                                                <span *ngIf="price">
                                                    ${{price || 0}}
                                                </span>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Cost Price" prop="kit_costprice"
                                                              [sortable]="true">
                                            <ng-template let-price="value" ngx-datatable-cell-template>
                                                <span *ngIf="price">
                                                    ${{price || 0}}
                                                </span>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Available Qty" prop="available_stock"
                                                              [sortable]="false"></ngx-datatable-column>
                                        <ngx-datatable-column name="Status" prop="status" [sortable]="false">
                                            <ng-template let-status="value" ngx-datatable-cell-template>
                                                <div class="badge bg-light-success">
                                                    {{ status }}
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-footer>
                                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                         let-pageSize="pageSize" let-curPage="curPage"
                                                         let-offset="offset">
                                                <div class="page-count">
                                                    <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                                </div>
                                                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                                 [pagerRightArrowIcon]="'datatable-icon-right'"
                                                                 [pagerPreviousIcon]="'datatable-icon-prev'"
                                                                 [pagerNextIcon]="'datatable-icon-skip'"
                                                                 [page]="curPage" [size]="pageSize" [count]="rowCount"
                                                                 [hidden]="!((rowCount / pageSize) > 1)"
                                                                 (change)="dataTable.onFooterPage($event)">
                                                </datatable-pager>
                                            </ng-template>
                                        </ngx-datatable-footer>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="items-view border-0" *ngIf="!expanded && selected">
                        <div class="items-view-content">
                            <section id="item-view">
                                <div class="d-flex flex-row mt-2 justify-content-between">
                                    <h5 class="item-title m-0">{{selected?.kit_name}}</h5>
                                    <div class="d-flex justify-content-end align-items-start">
                                        <button class="btn btn-sm ml-1 font-medium-3 pt-0 pb-0"
                                                (click)="onItemViewClose()">
                                            <i class="ft-x"></i>
                                        </button>
                                    </div>
                                </div>
                                <div>Total Assembly Built : {{bundlecount}}</div>
                                <div>Total Quantity Required per Assembly: {{required_quantity}}</div>
                                <div>Total Quantity Required : {{total_required_quantity}}</div>
                                <div class="col-12 col-md-12 p-0">
                                    <ng-container *ngIf="hasPermission(Resource.INV_ITEMS_STOCK)">
                                        <div class="group mt-3">
                                            <div class="group-header d-flex flex-row">
                                                Select Stock Location
                                            </div>
                                            <section>
                                                <table class="item-stock-table w-100">
                                                    <thead>
                                                        <tr>
                                                            <th>WareHouse</th>
                                                            <th>Warehouse Location</th>
                                                            <th [width]="150">Available Qty</th>
                                                            <th>Select Qty </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let row of Locs;let i = index">
                                                            <td class="p-2">
                                                                {{row.warehouse_name}}
                                                            </td>
                                                            <td class="P-2">
                                                                {{row.location_name}}
                                                            </td>
                                                            <td class="quantity-td">
                                                                {{row.available_quantity}}
                                                            </td>
                                                            <td>
                                                                <input type="number" width="40"
                                                                       [disabled]="row.available_quantity==0"
                                                                       (change)="getSelectedAggregateQuantity();validateQuantity(row)"
                                                                       class="select-qty-input form-control p-0"
                                                                       placeholder="Enter quantity"
                                                                       min="0" [(ngModel)]="row.selected_quantity" />
                                                                <span class="text-danger"
                                                                      *ngIf="row.selected_quantity>row.available_quantity">Qty
                                                                    over selected</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="1"></td>
                                                            <td class="text-bold-500">Total Qty</td>
                                                            <td class="text-bold-500">{{total_quantity}}
                                                            </td>
                                                            <td class="text-bold-500">
                                                                {{total_selected_quantity}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                            </section>

                                            <div>

                                            </div>

                                        </div>
                                    </ng-container>
                                </div>


                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <div class="modal-footer">
        <button type="button" *ngIf="selected" (click)="onSelected()" class="btn gradient-pomegranate">Select</button>
        <button type="button" (click)="onCanceled()" class="btn btn-secondary">Cancel</button>
    </div>
</ng-template>
