<div class="st-comments">
    <div class="st-comments-header d-flex flex-row align-items-start">
        <div *ngIf="timelineView" class="created-user avatar bg-success mr-2">
            <div class="avatar-content">{{ userName | shortName}}</div>
        </div>
        <div class="input-container w-100">
            <textarea class="form-control" [(ngModel)]="model.comments" name="comments" [rows]="model.inputRows" placeholder="Add a comment..." (focus)="headerInputFocus()"></textarea>
            <div *ngIf="model.inputRows > 1 && timelineView" class="header-footer mt-2 d-flex flex-row">
                <button type="button" class="btn btn-sm btn-info mr-1" [disabled]="!model.comments" (click)="createComment()">Save</button>
                <button type="button" class="btn btn-sm btn-light-secondary" (click)="model.inputRows = 1;model.comments = ''">Cancel</button>
            </div>
        </div>
    </div>
    <div class="st-comments-body mt-3" *ngIf="comments">
        <div *ngFor="let com of comments" class="st-comment d-flex flex-row mt-2">
            <div class="w-100 d-flex flex-row align-items-start">
                <div class="created-user avatar bg-success mr-2">
                    <div class="avatar-content">{{ com.created_by || '' | shortName}}</div>
                </div>
                <div class="w-100">
                    <div class="created-meta">
                        <span class="username">{{com.created_by}}</span>
                        <span class="crd">{{com.created_on | date:'MM/dd/YYYY h:mm a'}}</span>
                    </div>
                    <ng-container *ngIf="!com.edit else inputElement">
                        <div class="note mt-1">{{com.comments}}</div>
                        <div class="links mt-1">
                            <a *ngIf="com.created_user === currentUser" (click)="com.edit = true;com.inputRows = 3" class="mr-2 text-info">Edit</a>
                            <a *ngIf="com.created_user === currentUser || isAdmin" (click)="deleteComment(com.id)" class="mr-2 text-danger">Delete</a>
                        </div>
                    </ng-container>
                    <ng-template #inputElement>
                        <div class="input-container w-100">
                            <textarea class="form-control" #cmInput [value]="com.comments" [rows]="com.inputRows" placeholder="Add a comment..."></textarea>
                            <div class="header-footer mt-2 d-flex flex-row">
                                <button type="button" class="btn btn-sm btn-info mr-1" [disabled]="!com.comments" (click)="updateComment(com,cmInput.value)">Save</button>
                                <button type="button" class="btn btn-sm btn-light-secondary" (click)="com.edit = false">Cancel</button>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

    </div>
</div>