import { AddUserGroupComponent } from './pages/add-user-group/add-user-group.component';
import { UserGroupsComponent } from './pages/user-groups/user-groups.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { SUAdminRouteGuard } from 'src/app/core/gaurd/su-or-admin.route.gaurd';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';

const routes: Routes = [
  {
    path: '',
    component: UserListComponent,
    canActivate: [SUAdminRouteGuard],
    data: { title: 'Users' }
  },
  {
    path: ':id/edit',
    component: AddUserComponent,
    pathMatch: 'full',
    canActivate: [SUAdminRouteGuard],
    data: { title: 'Edit User' }
  },
  {
    path: ':id/view',
    component: UserListComponent,
    pathMatch: 'full',
    canActivate: [SUAdminRouteGuard],
    data: { title: 'User Info' }
  },
  {
    path: 'new',
    component: AddUserComponent,
    pathMatch: 'full',
    canActivate: [SUAdminRouteGuard],
    data: { title: 'New User' }
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    pathMatch: 'full',
    data: { title: 'My Profile' }
  },
  {
    path: 'groups',
    component: UserGroupsComponent,
    pathMatch: 'full',
    data: { title: 'User Groups' }
  },
  {
    path: 'groups/:id/view',
    component: UserGroupsComponent,
    pathMatch: 'full',
    data: { title: 'User Group Info' }
  },
  {
    path: 'groups/new',
    component: AddUserGroupComponent,
    pathMatch: 'full',
    data: { title: 'Add User Groups' }
  },
  {
    path: 'groups/:id/edit',
    component: AddUserGroupComponent,
    pathMatch: 'full',
    data: { title: 'Add User Groups' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
