import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { STComment } from './../../models/service-ticket';
import { ServiceTicketService } from './../../services/service-ticket.service';
import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { SubSink } from 'subsink';

@Component({
  selector: 'service-ticket-comments',
  templateUrl: './service-ticket-comments.component.html',
  styleUrls: ['./service-ticket-comments.component.scss']
})
export class ServiceTicketCommentsComponent implements OnInit, OnDestroy {

  private stid: number;

  @Input() set serviceTicketId(value: number) {
    this.stid = value;
    this.getComments(this.stid);
  }

  @Input() timelineView: boolean = true;

  @Input() suborg_id: number;

  get serviceTicketId() { return this.stid; }

  public comments: STComment[];

  public model: STComment = new STComment();

  public userName: string;

  public rows: number = 1;

  public currentUser: number;

  public isAdmin: boolean;

  private subs: SubSink = new SubSink();

  constructor(private service: ServiceTicketService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) {

    this.currentUser = service.userId;
    this.isAdmin = service.isAdmin;
  }

  ngOnInit() {
    let userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
    if (userAuth) {
      this.userName = `${userAuth.firstname || ''} ${userAuth.lastname || ''}`;
    }
    if (!this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  getComments(stid: number) {
    if (stid > 0) {
      this.subs.sink = this.service.getComments(stid).subscribe(resp => {
        this.comments = resp;
        this.cdr.markForCheck();
      });
    }
    else {
      this.comments = [];
    }
  }

  headerInputFocus() {
    if (this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  createComment() {

    if(!this.model.comments) return;

    this.model.service_ticket = this.serviceTicketId;

    this.model.customer = this.service.customerId;

    this.model.suborg = this.suborg_id;

    this.subs.sink = this.service.createComment([this.model]).subscribe(
      resp => {
        this.comments = [...resp, ...this.comments];
        this.model.inputRows = 1;
        this.model.comments = '';
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to save comment')
    );
  }

  updateComment(comment: STComment, value: string) {

    if(!value) return;

    let com = { ...comment };
    com.comments = value;
    this.subs.sink = this.service.updateComment(comment.id, com).subscribe(
      () => {
        comment.comments = value;
        comment.edit = false;
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to modify comment')
    );
  }

  deleteComment(commentid: number) {
    this.subs.sink = this.service.deleteComment(commentid).subscribe(
      () => {
        this.comments = this.comments.filter(c => c.id !== commentid);
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to delete comment')
    );
  }

  public saveComment(stid: number, next?: (value: any) => any, suborg: number=null) {
    if (!this.model.comments && next) {
      next(null);
    }
    else {
      this.model.service_ticket = stid;
      this.model.customer = this.service.customerId;
      if(suborg){
        this.model.suborg = suborg;
      }
      this.subs.sink = this.service.createComment([this.model]).pipe(
        catchError(() => [])
      ).subscribe(resp => {
        if (next) { next(resp) }
      });
    }
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
