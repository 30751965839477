import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-date-picker',
    template: `<div class="date-picker">
                <div class="input-group mb-0 w-100" [class.input-group-sm]="size === 'sm'">
                  <input type="text" class="form-control"
                        #dateInput="ngModel"
                        [disabled]="readonly"
                        placeholder="mm/dd/yyyy"
                        mask="M0/d0/0000"
                        [leadZeroDateTime]="true"
                        [(ngModel)]="displayValue"
                        name="date-input"
                        (change)="onDateInputChange($event)"/>
                  <input class="form-control d-none"
                        [(ngModel)]="binding"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        (dateSelect)="onDateSelect($event)"
                        ngbDatepicker #picker="ngbDatepicker">
                  <div class="input-group-append">
                      <div class="input-group-text" (click)="!readonly && picker.toggle()" style="cursor: pointer;">
                          <i class="fa fa-calendar"></i>
                      </div>
                  </div>
                </div>
                <small class="text-muted danger" *ngIf="dateInput.invalid && (dateInput.dirty || dateInput.touched)">
                  Please enter a valid date
                </small>
              </div>`,
    styles: [
        `.input-group-sm {
        margin-bottom: 0 !important;
      }`
    ]
})
export class DatePickerComponent implements OnInit {

    @ViewChild('dateInput') dateInput: NgModel;

    private _value: any;

    @Input() set value(val: any) {
        if (val && this._value !== val) {
            this._value = val;
            this.setDateStruct(val);
        }
    }

    get value() { return this._value; }

    @Output() valueChange = new EventEmitter<any>();

    @Input() minDate: NgbDateStruct;

    @Input() maxDate: NgbDateStruct;

    @Input() size: string = "md";

    @Input() valueFormat = "yyyy-MM-dd";

    @Input() readonly: boolean;

    public displayValue: any = null;

    public binding: NgbDateStruct;

    constructor(private parserFormatter: NgbDateParserFormatter) { }

    ngOnInit() { }

    onDateSelect(struct: NgbDateStruct) {
        if (struct) {
            let dateString = this.parserFormatter.format(struct);
            this._value = dateString;
            this.valueChange.emit(dateString);
            this.displayValue = this.getDisplayValue(struct);
        }
    }

    setDateStruct(dateString: string) {
        this.binding = this.parserFormatter.parse(dateString);
        this.displayValue = this.getDisplayValue(this.binding);

        if (this.emitValueFormat(this.displayValue) != null) {
            this.valueChange.emit(this.emitValueFormat(this.displayValue));
        }

    }

    getDisplayValue(struct: NgbDateStruct): string {
        return `${this.padNumber(struct.month)}/${this.padNumber(struct.day)}/${struct.year}`;
    }

    padNumber(value: number) {
        return value <= 9 ? `0${value}` : `${value}`;
    }

    toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    isNumber(value: any): value is number {
        return !isNaN(this.toInteger(value));
    }

    onDateInputChange(event: any) {
        let inputValue = event.target.value;
        if (!inputValue || this.dateInput.invalid) return;
        let parts = inputValue.split('/');
        let dateString = `${parts[2]}-${parts[0]}-${parts[1]}`;
        if (this.isNumber(Date.parse(dateString))) {
            this._value = dateString;
            console.log(dateString);
            this.valueChange.emit(dateString);
            this.binding = this.parserFormatter.parse(dateString);
        }
    }

    emitValueFormat(dateString: string) {
        if (!dateString) return null;
        let parts = dateString.split('/');
        let dString = `${parts[2]}-${parts[0]}-${parts[1]}`;
        if (this.isNumber(Date.parse(dString))) {
            return dString;
        }
        else {
            return null;
        }
    }
}
