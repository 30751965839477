
export class LicenseNumber {

    public id: number;

    public number: string;

    public description: string;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public is_delete: boolean;

    public is_active: boolean;

    public customer: number;

    public suborg:number;
}

export class LicenseNote {

    public id: number;

    public license: number;

    public comments: string;

    public parent: number;

    public created_on: Date;

    public created_by: string;

    public modified_on: Date;

    public modified_by: string;

    public is_delete: boolean;

    public is_active: boolean;

    public customer: number;

    public created_user: number;

    edit: boolean;

    inputRows: number = 1;
}

export class CustomerLicense {

    public id:number;

    public tenant_customer:number;

    public license:number;

    public customer_location:number;

    public customer:number;

    public suborg:number;

    public created_on: Date;

    public created_by: string;

    public created_user: number;

    public modified_on: Date;

    public modified_by: string;

    public is_delete: boolean;

    public is_active: boolean;

    //resolved

    tenant_customer_name:string;

    customer_location_name:string;

    license_number:string;

    tenant_customer_type:string;
}
