<div class="d-flex flex-row mt-2">
    <h5 class="item-title m-0">From {{selected.call_from | phoneNumber }}
        <span #copyBtn ngxClipboard
                [cbContent]="selected.call_from.toString()"
                ngbTooltip="copied"
                triggers="click:blur"
                class="ft-copy copy-btn mr-1">
        </span> 
        to
        {{selected.call_to | phoneNumber }}
        <span #copyBtn ngxClipboard
                [cbContent]="selected.call_to"
                ngbTooltip="copied"
                triggers="click:blur"
                class="ft-copy copy-btn">
        </span>
    </h5>
    <div class="d-flex justify-content-end align-items-start">
        <button class="btn ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
            <i class="ft-x"></i>
        </button>
    </div> 

</div>
<div class="row flex-column">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Call Info</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                

                <div class="group">
                    <div class="row">
                    <div class="col-md-6"> 
                    <div class="group-header d-flex justify-content-between">
                        Call Info
                        <div class="status-toggle-btn" *ngIf="!selected.blocked_status&&hasPermission(permission.CREATE)">
                            <label class="title">Block Number :</label>
                            <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                <input type="checkbox" [(ngModel)]="selected.blocked_status"
                                    id="status" name="active"
                                    class="custom-control-input"
                                    (change)="addToBlockList()">
                                <label class="custom-control-label" for="status">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <button class="badge bg-light-danger" *ngIf="selected.blocked_status&&hasPermission(permission.DELETE)" (click)="unblockNumber()">
                            Unblock
                        </button>
                    </div>
                    <div class="group-body">
                        <div class="ov-row" *ngIf="selected.sid">
                            <label class="col-md-3 p-0 item-label">Call SID</label>
                            <label class="item-label-value">{{selected.sid}}</label>
                        </div>
                        <div class="ov-row" *ngIf="selected.status">
                            <label class="col-md-3 p-0 item-label">Status</label>
                            <label class="item-label-value">{{selected.real_status}}</label>
                        </div>
                        <div class="ov-row" *ngIf="selected.direction">
                            <label class="col-md-3 p-0 item-label">Direction</label>
                            <label class="item-label-value">{{selected.direction}}</label>
                        </div>
                        <div class="ov-row" *ngIf="selected.call_from">
                            <label class="col-md-3 p-0 item-label">Call from</label>
                            <label class="item-label-value" *ngIf = "pattern.test(selected.call_from.toString())">{{selected.call_from | phoneNumber }}</label>
                            <label class="item-label-value" *ngIf = "!pattern.test(selected.call_from.toString())">{{selected.call_from}}</label>
                        </div>
                        <div class="ov-row" *ngIf="selected.call_to">
                            <label class="col-md-3 p-0 item-label">Call to</label>
                                <label class="item-label-value" *ngIf = "pattern.test(selected.call_to.toString())">{{selected.call_to | phoneNumber }}</label>
                                <label class="item-label-value" *ngIf = "!pattern.test(selected.call_to.toString())">{{selected.call_to}}</label>
                        </div>
                        <div class="ov-row" *ngIf="selected.duration">
                            <label class="col-md-3 p-0 item-label">Call duration</label>
                            <label class="item-label-value">{{selected.duration}} Seconds</label>
                        </div>
                        <div class="ov-row" *ngIf="selected.recording_url">
                            <label class="col-md-3 p-0 item-label">Recording</label>
                            <div class="d-flex flex-column">
                                <span *ngFor="let urls of selected.recording_url_list">
                                    <audio *ngIf="urls" controls class="audio-control" #audioElement
                                        (play)="audioPlayerPlay(audioElement)">
                                    <source [src]="urls" type="audio/wav">
                                    not supported
                                </audio>
                                </span>
                            </div>
                        </div>
                        <div class="ov-row" *ngIf="selected.start_time">
                            <label class="col-md-3 p-0 item-label">Start time</label>
                            <label
                                class="item-label-value">{{selected.start_time | dateTz:'MM/DD/YYYY h:mm:ss a'}}</label>
                        </div>
                        <div class="ov-row" *ngIf="selected?.end_time">
                            <label class="col-md-3 p-0 item-label">End time</label>
                            <label
                                class="item-label-value">{{selected.end_time | dateTz:'MM/DD/YYYY h:mm:ss a'}}</label>
                        </div>
                        <div class="ov-row" *ngIf="isSuperAdmin">
                            <label class="col-md-3 p-0 item-label">Tenant</label>
                            <label class="item-label-value">{{selected.customer_name}}</label>
                        </div>
                        <div class="ov-row" *ngIf="isSuperAdmin">
                            <label class="col-md-3 p-0 item-label">Sub Org</label>
                            <label class="item-label-value">{{selected.suborg_name}}</label>
                        </div>
                    </div>
                    </div>

                    <div class="col-md-6"> 
                        <customer-info
                        [tenant_customer_id]="selected.tenant_customer_id"
                        [cust_name]="selected.tenant_customer_name"
                        [cust_contact_name]="selected.customer_contact_name"
                        [cust_contact_email]="selected.customer_contact_email"
                        [cust_contact_phone]="selected.call_from"
                        [location_name]="selected.customer_contact_location_name"
                        [location_address]="selected.customer_contact_location_address"
                        [location_city]="selected.customer_contact_location_city"
                        [location_state]="selected.customer_contact_location_state"
                        [location_zip]="selected.customer_contact_location_zip"

                        [call_to_suborg_id]="selected.sub_org"
                        ></customer-info>
                    </div>
                    </div>
                </div>




                <div class="group mt-2" *ngIf="selected.forward_number_sid">
                    <div class="group-header">
                        Incoming Routes
                    </div>
                    <div class="group-body">
                        <div class="ov-row">
                            <label class="col-md-3 p-0 item-label">Internal Id</label>
                            <label class="item-label-value">{{selected.forward_number_sid}}</label>
                        </div>
                        <div class="ov-row">
                            <label class="col-md-3 p-0 item-label">Forward Number</label>
                            <label class="item-label-value">{{selected.forward_number | phoneNumber }}</label>
                        </div>
                        <div class="ov-row" *ngIf="selected.forward_number_name">
                        <label class="col-md-3 p-0 item-label">Incoming Routes Name</label>
                        <label class="item-label-value">
                            <a *ngIf="hasBinViewInfoPermission() else binGeneral"
                                target="_blank"
                                [routerLink]="['/ring2voice/incoming-routes', selected.forward_number_sid,'view']"
                                class="text-info">
                                {{selected.forward_number_name}}
                            </a>
                        </label>
                        <ng-template #binGeneral><span>{{selected.forward_number_name}}</span></ng-template>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-12 col-md-10">
                <div class="group mt-3" *ngIf="childCalls | async as childCalls">
                    <div class="group-header">
                        Child Calls
                    </div>
                    
                    <div class="group-body group-body-bordered">
                        <ngx-datatable [rows]="childCalls" class="bootstrap core-bootstrap"
                                    [columnMode]="'force'"
                                    [headerHeight]="50"
                                    [footerHeight]="50"
                                    rowHeight="auto"
                                    [scrollbarH]="scrollbarH"
                                    [sorts]="[{prop:'date_created',dir:'desc'}]">
                            <ngx-datatable-column name="Sid" prop="sid" [sortable]="false">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Date" prop="date_created">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{ value | dateTz:'MM/DD/YYYY h:mm a' }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="From/To" [sortable]="false" [width]="180">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="d-flex flex-row">
                                        <span class="number-label">From</span>
                                        <span>{{row.call_from | phoneNumber}}</span>
                                    </div>
                                    <div class="d-flex flex-row">
                                        <span class="number-label">To</span>
                                        <span>{{row.answered ? (row.call_to) : (row.call_to | phoneNumber)}}</span>
                                        
                                    </div>
                                    <div class="d-flex flex-column" *ngIf="row.answered">
                                        <span class="float-left">Answered :</span>       
                                        <div class="media py-1">
                                            <span class="avatar avatar-md mr-1 mytooltip tooltip-effect-1" [style.backgroundColor]="row.color">
                                            <div *ngIf="row.answered" class="user-avatar-content tooltip-item">{{ row.answered | shortName }}</div>
                                            <span class="tooltip-content clearfix">
                                                <div class="media py-1">
                                                <span class="avatar avatar-md mr-1" [style.backgroundColor]="row.color">
                                                <div *ngIf="row.answered" class="user-avatar-content ">{{ row.answered | shortName }}</div>
                                                </span>
                                            </div>
                                            <span class="tooltip-text">{{row.answered}} <br>
                                            <a type="button" class="btn btn-dark btn-sm profile-btn" [routerLink]="['/users']" target="_blank">View profile</a>
                                            </span>
                                            </span>
                                            </span>
                                            <div class="media-body">
                                                <label class="mt-2 font-weight-bold">
                                                    {{row.answered}} <span *ngIf="row.answered!=row.call_to">- {{row.call_to}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Duration" prop="duration">
                                <ng-template let-duration="value" ngx-datatable-cell-template>
                                    {{duration}} Sec
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Status" prop="real_status"
                                                [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                        [ngClass]="{'bg-light-success': status=='completed','bg-light-danger': status=='no-answer' }">
                                        {{ status }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>

            </div>
            </div>
        

        
            
            </ng-template>
        </li>

        <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Comments</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <div style="max-width: 500px;">
                    <app-comments [uniqueId]="selected.sid" [moduleId]="22"></app-comments>
                </div>
            </ng-template>
        </li>

        <li ngbNavItem [ngbNavItem]="2" *ngIf="selected">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Service Ticket</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <customer-info-st 
                #stTab
                [tabHeaderHeight]="tabHeaderHeight"
                [customerId]="selected.tenant_customer_id"

                >

                </customer-info-st>
            </ng-template>
        </li>
        <li ngbNavItem [ngbNavItem]="4" *ngIf="selected">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Invoices</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">


                <customer-info-invoice

                    [customerId]="selected.tenant_customer_id"
                >

                </customer-info-invoice>
                <!-- <dispatch-event-info-invoice
                [tabHeaderHeight]="tabHeaderHeight"
                [invoices]="eventDetails?.invoices"
                >

                </dispatch-event-info-invoice> -->

            </ng-template>
        </li>
        <li ngbNavItem [ngbNavItem]="5" *ngIf="selected">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Sales Order</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <!-- <dispatch-event-info-so
                [tabHeaderHeight]="tabHeaderHeight"
                [salesOrders]="eventDetails?.sales_order"
                >

                </dispatch-event-info-so> -->

                <customer-info-so
                [customerId]="selected.tenant_customer_id" 
                >
                    
                </customer-info-so>
            </ng-template>
        </li>
        <li ngbNavItem [ngbNavItem]="6" *ngIf="selected">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Warranty</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <!-- <dispatch-event-info-warranty
                [tabHeaderHeight]="tabHeaderHeight"
                [warranty]="eventDetails?.warranty"
                >

                </dispatch-event-info-warranty> -->

                <customer-info-warranty
                    [customerId]="selected.tenant_customer_id"
                >

                </customer-info-warranty>


            </ng-template>
        </li>
        <li ngbNavItem [ngbNavItem]="7" *ngIf="selected">
            <a ngbNavLink class="d-flex align-items-center">
                <span class="d-none d-sm-block">Service Contracts</span>
            </a>
            <ng-template ngbNavContent class="tab-content-pd">
                <!-- <dispatch-event-info-sc
                    [tabHeaderHeight]="tabHeaderHeight"
                    [serviceContracts]="eventDetails?.service_contract"
                >

                </dispatch-event-info-sc> -->

                <customer-info-sc
                [customerId]="selected.tenant_customer_id"
                >

                </customer-info-sc>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>