import { CallRoutingOptionEnum, CallRingTypeEnum, NumberBlockTypeEnum, AfterTimeoutOption } from "src/app/core/enums/common.enum";

export class ForwardBin {

  public sid:string;

  public name:string;

  public announce: boolean;

  public announce_gender: string;

  public announce_message: string;

  public forward_number: string;

  public forward_number_list: string[];

  public sip : string;

  public sip_list: string[];

  public forwardbin_or_sip: boolean=false;

  public gather: boolean;

  public gather_message: string;

  public gather_gender:string;

  public is_active: boolean;

  public whisper: boolean;

  public whisper_sid:string;

  public whisper_gender: string;

  public whisper_message: string;

  public no_of_loops: number;

  public not_responding_message: string;

  public sub_org:number;

  public call_center:boolean;
  
  public global_route:boolean;

  public smartsipgroup:number;

  created_on:any;

  created_by:any;

  customer:number;

  customer_name:string;

  suborg_name:string;

  maping_sid:string;

  background:boolean;
}

export class RoutingSection{
  
  time_frame_id: number;

  ring_option: string = 'Ring';

  ring_type: CallRingTypeEnum = CallRingTypeEnum.NUMBER;

  after_timeout?: AfterTimeout = new AfterTimeout();

  smartsipgroup_id: number;

  ring_timeout: number;

  // voicemail_smartsip_group_id: number;

  numbers: any=[];

  sip: string[]=[];

  smartsipgroup: string;

  announce_gender: string;

  announce_message: string;

  gather_message: string;

  gather_gender: string;

  user_or_group: boolean;

  sip_username: string;

  user_group: number;
  
  no_of_loops: number;

  not_responding_message: string;
  
  call_router: IntelligentMenuRouting[]=[];

  menu_settings: MenuSettings = new MenuSettings();
}

export class IntelligentMenuRouting {

  public input: number = null;

  public ring_type: CallRingTypeEnum = CallRingTypeEnum.NUMBER;

  public nested_routing_option: string = 'Ring';

  after_timeout?: AfterTimeout = new AfterTimeout();

  public smartsipgroup_id: number;

  public ring_timeout:number;

  // public voicemail_smartsip_group_id:number;

  public numbers: any=[];

  public sip: string[]=[];

  public announce_gender: string;

  public announce_message: string;

  public gather_gender: string;

  public no_of_loops: number;

  public gather_message: string;

  public user_or_group: boolean = false;

  public sip_username: string;

  public user_group: number;

  public not_responding_message: string;

  public call_router: MenuDetailsResponse[]=[];

  public menu_settings: MenuSettings = new MenuSettings();

}

// advanced settings response

export class MenuDetailsResponse {

  public input: number;

  public nested_routing_option: string = 'Ring';

  public ring_type: CallRingTypeEnum = CallRingTypeEnum.NUMBER;

  public after_timeout?: AfterTimeout = new AfterTimeout();

  public smartsipgroup_id: number;

  public ring_timeout: number;

  // public voicemail_smartsip_group_id:number;

  public numbers: string[]=[];

  public sip: string[]=[];

  public announce_gender: string;

  public announce_message: string;
  
  public gather_gender: string;

  public no_of_loops: number;

  public gather_message: string;

  public user_or_group: boolean;

  public sip_username: string;

  public user_group: number;

  public not_responding_message: string;

  public call_router:MenuDetailsResponse[]=[];

  public menu_settings: MenuSettings = new MenuSettings();

}
export class AdvSettingsTimeFrame {

  public time_frame_id: number;

  public ring_option: string;

  public ring_type: CallRingTypeEnum = CallRingTypeEnum.NUMBER;

  public after_timeout?: AfterTimeout = new AfterTimeout();

  public smartsipgroup_id: number;

  public ring_timeout: number;

  public numbers: string[]=[];

  public sip: string[]=[];

  smartsipgroup: string;

  public announce_gender: string;

  public announce_message: string;

  public  gather_gender: string;

  public user_or_group: boolean = false;

  public sip_username: string;

  public user_group: number;

  public no_of_loops: number;

  public gather_message: string;

  public not_responding_message: string;

  public call_router: MenuDetailsResponse[]=[];

  public menu_settings: MenuSettings = new MenuSettings();

}

export class AllOtherTimeResponse {

  public ring_option: string = 'Ring';

  public ring_type: CallRingTypeEnum = CallRingTypeEnum.NUMBER;

  public numbers: any=[];

  public sip: string[]=[];

  public smartsipgroup_id: number;

  public after_timeout: AfterTimeout = new AfterTimeout();

  public ring_timeout: number;

  // public voicemail_smartsip_group_id: number;

  public smartsipgroup: string;

  public announce_gender: string;

  public announce_message: string;

  public gather_gender: string;

  public no_of_loops: number;

  public gather_message: string;

  public not_responding_message: string;

  public user_or_group: boolean = false;

  public sip_username: string;

  public user_group: number;

  public call_router: MenuDetailsResponse[] = [];

  public menu_settings: MenuSettings = new MenuSettings();

}

export class AdvancedCallSettingsResponse {

  public is_updated: boolean;

  public block_list_enabled: boolean;

  public block_list_type: NumberBlockTypeEnum.NONE;

  public routing_option: CallRoutingOptionEnum = CallRoutingOptionEnum.RING;

  public time_zone: string;

  public time_frames: AdvSettingsTimeFrame[]=[];

  public all_other_time: AllOtherTimeResponse = new AllOtherTimeResponse();

  public voicemail_smartsip_group_id: number;

  status?: boolean;

  field_empty?: boolean;

}

// payload

export class IntelligentMenuPayload {

  public menu_option: number;

  public ring_type: string;

  public ring_option: string;

  public agents: any=[];

  public after_timeout: AfterTimeout = new AfterTimeout();

  public ring_timeout: number;

  public announce_gender: string;

  public announce_message: string;

  public gather_gender: string;

  public gather_message: string;

  public user_or_group: boolean;

  public sip_username: string;

  public user_group: number;

  public no_of_loops: number;

  public not_responding_message: string;

  public menus: IntelligentMenuPayload [] = [];

  public menu_settings: MenuSettings = new MenuSettings();

}

export class nestedAfterTimeOut {

  public ring_option: AfterTimeoutOption = AfterTimeoutOption.VOICEMAIL;

  public announce_gender: string;

  public announce_message: string;

  public user_or_group: boolean = false;

  public sip_username: string;

  public user_group: number;

}

export class AfterTimeout {

  public ring_option: AfterTimeoutOption = AfterTimeoutOption.RING;

  public user_or_group: boolean = false;

  public sip_username: string = null;

  public user_group: number = null;

  public ring_type: any = CallRingTypeEnum.NUMBER;//

  public numbers: any=[];

  public sip: string[]=[];

  public smartsipgroup_id: number;

  public agents: any=[];

  public announce_gender: string;

  public announce_message: string;

  public ring_timeout: number;

  public nested_after_timeout: nestedAfterTimeOut = new nestedAfterTimeOut();

}

export class MenuSettings {

  public no_key_is_pressed: string;

  public unassigned_key_is_pressed: string;

  public timeout_before_first_key_press: string;

  public timeout_after_first_key_press: string;

  public maximum_key_press_to_collect: string;

}
export class TimeFramePayload {

  public time_frame_id: number;

  public ring_option: string;

  public ring_type: CallRingTypeEnum;

  public agents: any=[];

  public ring_timeout: number;

  public after_timeout: AfterTimeout = new AfterTimeout();

  public announce_gender: string;

  public announce_message: string;

  public gather_gender: string;

  public no_of_loops: number;

  public gather_message: string;

  public user_or_group: boolean;

  public sip_username: string;

  public user_group: number;

  public not_responding_message: string;

  public menus: IntelligentMenuPayload [] = [];

  public menu_settings: MenuSettings = new MenuSettings();

}

export class OtherTimePayoad {

  public other_time_ring_option: string;

  public other_time_ring_type: CallRingTypeEnum;

  public smartsip_group_id: number;

  public agents: any=[];

  // public voicemail_smartsip_group_id:number;

  public after_timeout: AfterTimeout = new AfterTimeout();

  public ring_timeout:number;

  public announce_gender: string;

  public announce_message: string;

  public  gather_gender: string;

  public no_of_loops: number;

  public gather_message: string;

  public not_responding_message: string;

  public user_or_group: boolean;

  public sip_username: string;

  public user_group: number;

  public menus: IntelligentMenuPayload [] = [];

  public menu_settings: MenuSettings = new MenuSettings();

}
export class AdvancedCallSettingsReqest {

  public block_list_enabled: boolean = false;

  public block_list_type: NumberBlockTypeEnum = NumberBlockTypeEnum.NONE;

  public routing_option: CallRoutingOptionEnum;

  public ring_type: CallRingTypeEnum;

  public time_zone: string;

  public time_frames: TimeFramePayload[]=[];

  public all_other_time: OtherTimePayoad = new OtherTimePayoad();

}