import { KitService } from './../../services/kit.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Component, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { StaticFile } from 'src/app/core/models/files.models';
import { environment } from 'src/environments/environment';
import { KitWrapper } from '../../models/kit.models';
import { CustomField } from 'src/app/core/models/custom-field';
import { CreatedSource, ModuleConstants } from 'src/app/core/enums/common.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as popup from 'src/app/core/utils/popup.functions';
import { SubSink } from 'subsink';
import { SweetAlertOptions } from 'sweetalert2';
import { Router } from '@angular/router';
import { KitStockComponent } from '../kit-stock/kit-stock/kit-stock.component';
import { LabelPageTemplate } from '../../models/label.models';
import { WareHouseLocation } from '../../models/warehouse.models';
import { LabelService } from '../../services/label.service';
import { WarehouseService } from '../../services/warehouse.service';

@Component({
    selector: 'app-kit-view',
    templateUrl: './kit-view.component.html',
    styleUrls: ['./kit-view.component.scss']
})
export class KitViewComponent implements OnInit, OnDestroy {

    public fileURL: string = environment.apiURL + '/static/files/';

    private innerKit: KitWrapper;

    get kit() { return this.innerKit; }

    @Input() set kit(value: KitWrapper) {

        if (this.innerKit !== value) {

            this.innerKit = value;

            if (value) {
                this.getImages();
            }
        }
    };

    @Output() onClose = new EventEmitter<void>();

    @Output() onDelete = new EventEmitter<number>();

    public customFields: CustomField[];

    public selectionType = SelectionType;

    public images: StaticFile[];

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public ModuleConst = ModuleConstants;

    private subs: SubSink = new SubSink();

    public isAllSuborg:boolean;

    public labelLocationList:WareHouseLocation[]=[];

    public labelTitle:string;

    public labelWherehouse:number=null;

    public labelLocation:number;

    public labelSlNo:{'selected':boolean, 'slNo':number}[]=[];

    public labelTemplate:number=null;

    public printCount:number;

    @ViewChild('labelModal') labelModal: ElementRef;




    constructor(
        private kitService: KitService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private ra: ResourceAccessService,
        private router: Router,
        private labelService:LabelService,
        private warehouseService:WarehouseService,
        private modalService: NgbModal,
        ) {
    }

    ngOnInit(): void {

        this.isAllSuborg = this.kitService.isAllSuborg;
    }


    getImages() {
        this.subs.sink = this.kitService.getKitImages(this.kit.id).subscribe(resp=> {
            this.images = resp;
            this.cdr.markForCheck();
        });
    }

    deleteKit() { this.onDelete?.emit(this.kit.id); }

    closeView(): void {
        this.kit = null;
        this.onClose?.emit();
    }

    changeStatus() {
        let opt = {
            title: `Are you sure you want to ${this.kit.is_active ? 'Activate Assembly' : 'Deactivate Assembly'}?`,
            text: this.kit.name,
            current: this.kit.is_active,
        };
        popup.ConfirmActivate(opt, result => {
            if (result.isConfirmed) {
                let patch = { is_active: this.kit.is_active, revision: this.kit.revision += 1 };
                this.subs.sink = this.kitService.patch(this.kit.id, patch).subscribe(
                    () => { },
                    () => {
                        this.kit.is_active = !this.kit.is_active;
                        this.cdr.markForCheck();
                        this.toastr.error("Failed to change status");
                    }
                );
            }
            else {
                this.kit.is_active = !this.kit.is_active;
                this.cdr.markForCheck();
            }
        });
    }

    changeShowOnCatelog() {
        let opt = {
            title: `Are you sure you want to ${this.kit.show_on_catelog ? 'Add to' : 'Remove from'} Catelog?`,
            text: this.kit.name,
            current: this.kit.show_on_catelog,
        };
        popup.ConfirmActivate(opt, result => {
            if (result.isConfirmed) {
                let patch = { show_on_catelog: this.kit.show_on_catelog, revision: this.kit.revision += 1 };
                this.subs.sink = this.kitService.patch(this.kit.id, patch).subscribe(
                    () => { },
                    () => {
                        this.kit.show_on_catelog = !this.kit.show_on_catelog;
                        this.cdr.markForCheck();
                        this.toastr.error("Failed to change status");
                    }
                );
            }
            else {
                this.kit.show_on_catelog = !this.kit.show_on_catelog;
                this.cdr.markForCheck();
            }
        });
    }

    changeInventoried(){
        let opt = {
            title: `Are you sure you want to change type?`,
            text: this.kit.name,
            ButtonText: "Yes",
        };
        popup.ConfirmWithButtonText(opt, (result) => {
            if (result.isConfirmed) {
                this.subs.sink = this.kitService
                    .patch(this.kit.id, { is_inventoried: this.kit.is_inventoried })
                    .subscribe(
                        () => { },
                        () => {
                            this.kit.is_inventoried = !this.kit.is_inventoried;
                            this.cdr.markForCheck();
                            this.toastr.error('Failed to change type');
                        }
                    );
            } else {
                this.kit.is_inventoried = !this.kit.is_inventoried;
                this.cdr.markForCheck();
            }
        });
    }

    hasPermission(resource: string, permission: string) {
        return this.ra.hasGlobalPermission([ResourceConstants.INV_KITS]) ||
            this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE)
    }

    hasPermissionAny(resource: string, permission: string[]) {
        return this.ra.hasGlobalPermission([ResourceConstants.INV_KITS]) ||
        this.ra.hasPermission(resource, permission, ResourceConstants.INV_MODULE)
    }

    convertToItem() {
        let option: SweetAlertOptions = {
            title: 'Are you sure you want to convert Assembly to Item',
            text: this.kit.name,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Convert',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn',
                htmlContainer: 'font-small-3'
            },
            buttonsStyling: false
        };
        popup.OpenConfirmBox(option, response => {
            if (response.isConfirmed) {
                this.subs.sink = this.kitService.convertToItem(this.kit.id).subscribe(
                    item => {
                        this.toastr.success('Assembly converted to Item');
                        this.router.navigate(['/inventory/items', item.id, 'view']);
                    },
                    () => this.toastr.error('Failed to convert Assembly')
                );
            }
        });
    }


    toggleLabelModal(content: any) {


        let options: NgbModalOptions = {
          size: 'lg'
        };

        this.modalService.open(content, options);

    }

    proceedToPrinting(){


        let urlValue="";

        let selectedSlNoCount=0;

          if(this.labelWherehouse){
              urlValue+="&wherehouse="+this.labelWherehouse;
          }

          if(this.labelLocation){
              urlValue+="&location="+this.labelLocation;
          }

          if(!this.printCount){
            this.toastr.error('Please enter required print counts');

          }
          else if(this.printCount<1){
            this.toastr.error('Please enter a valid required print count which is greater than zero');
          }
          else if(selectedSlNoCount>this.printCount){
            this.toastr.error('Selected serial numbers are more than given print count');
          }
        else{

          this.modalService.dismissAll();
          this.router.navigate([`/inventory/labels/print`],{queryParams:{type:'assembly', uniqueId:this.kit.id, werehouse: this.labelWherehouse, location: this.labelLocation,template:this.labelTemplate, printCount:this.printCount}});

        }



          // this.router.navigateByUrl('./inventory/labels/print?type=item&uniqueId='+this.item.id);

    }

    openPrintView(values){




        let options: NgbModalOptions = {
            size: 'lg'
          };

          this.modalService.open(this.labelModal, options);


        //   this.getLabelPageTemplates();

          this.labelLocation=values.location;

          this.labelWherehouse=values.werehouse;



          this.cdr.markForCheck();

            // this.canvas.loadFromJSON(this.tmpLabelTemplate, this.canvas.renderAll.bind(this.canvas), function (o, object) {
            // });
        }




    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
