import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { Location } from '@angular/common';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as popup from 'src/app/core/utils/popup.functions';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { UIPermission } from 'src/app/core/models/common.models';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SODashBoardCounts, SOList, SOStatus, Status } from '../../models/sales-orders.models';
import { SalesOrdersService } from '../../services/sales-orders.service';
import { SalesOrdersComponent } from '../../../sales-shared/sales-order/sales-order-view/sales-orders.component';
import { SalesOrderDataHelper } from '../../helpers/sales-orders-data.helper';
import * as _ from 'lodash';
import { InvoiceDashboardComponent } from '../invoice-dashboard/invoice-dashboard.component';
import { SalesOrdersDashboardComponent } from '../sales-orders-dashboard/sales-orders-dashboard.component';


@Component({
  selector: 'app-sales-orders-list',
  templateUrl: './sales-orders-list.component.html',
  styleUrls: ['./sales-orders-list.component.scss']
})
export class SalesOrdersListComponent implements OnInit {


  public expanded: boolean = true;

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild(SalesOrdersComponent) SalesOrderView: SalesOrdersComponent;

  @ViewChild('dataTable') dataTable:DatatableComponent;

  @ViewChild(SalesOrdersDashboardComponent) dashboard: SalesOrdersDashboardComponent;

  public selected: any;

  private soId = 0;

  public subs: SubSink = new SubSink();

  public SelectionType = SelectionType;

  public rows: SOList[] = [];

  public dashlet: SODashBoardCounts = new SODashBoardCounts();

  public SOStatus = SOStatus;


  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

  public module: ModuleConstants = ModuleConstants.SALES_ORDERS;

  public scrollbarH: boolean = false;

  public defaults: GFilterParam[] = SalesOrderDataHelper.defaults;

  public search: GFilterParam[] = SalesOrderDataHelper.search;


  public columns: GFColumn[] = SalesOrderDataHelper.columns

  public sortLookup = SalesOrderDataHelper.sortLookup

  public UIPermissions: UIPermission;

  public Permissions = PermissionConstants;

  public soStatus=SOStatus;

  // public statusButton:number=3;

  // public dropdownStatus=[
  //                 {"id":1,"value":"Mark as New"},
  //                 {"id":2,"value":"Move to Draft"},
  //                 {"id":3,"value":"Send to Customers"},
  //                 {"id":4,"value":"Mark as Rejected"},
  //                 {"id":5,"value":"Mark as Approved"},
  //                 {"id":6,"value":"Convert to Invoice"},
  //                 {"id":7,"value":"Convert to Workorder"}
  //               ];

  // public statusActionId=3;

  // public get activeDropdownStatus(){
  //     let test=this.statusActionId;
  //     return _.find(this.dropdownStatus,function(val)  {
  //       return val.id==test?val.value:'';
  //     });
  // }



  constructor(private toastr: ToastrService,
    public location: Location,
    private service: SalesOrdersService,
    private cdr: ChangeDetectorRef,
    private ra: ResourceAccessService,
    activeRoute: ActivatedRoute,
    private device: DeviceDetectorService) {

    this.subs.sink = activeRoute.params.subscribe(params => this.soId = params.id || 0);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_SALES_ORDERS, ResourceConstants.SALES_MODULE);

    // this.UIPermissions.viewInfo=false;

    // console.log(this.UIPermissions);

    this.scrollbarH = this.device.isMobile();
  }

  ngOnInit(): void {
    this.getData(null);
    if (this.soId != 0) {
      this.getById(this.soId);
    }
    // this.dataTable.recalculate();
  }
  ngAfterViewInit() {

    setTimeout(()=>{
      this.dataTable.recalculate();
    }, 800);

  }
  ngAfterViewChecked() {
    if (this.dataTable && this.dataTable.rowDetail) {
      this.dataTable.rowDetail.expandAllRows();
      this.cdr.detectChanges();
    }
  }

  goToLocation(path: string) {
    this.location.go(path);
  }

  onSelect({ selected }): void {
    let wrapper = selected[0];
    this.selected = wrapper;
    if(this.selected&&this.UIPermissions.viewInfo){
        this.getById(this.selected.id);
        this.expanded = false;
        this.location.go('sales/sales-orders/' + this.selected.id + '/view');
    }
  }

  getData(filters: GFilterParam[], offset: number = 0) {
    let option = { sort: this.sort, filters: filters };
    this.subs.sink = this.service.getAll(option, offset).subscribe(response => {
      this.rows = response.result;
      this.page.count=response.count;

      console.log(response.result);
      // this.setPagination(offset, response.count);
      this.cdr.markForCheck();
      this.dataTable.recalculate();
    });
  }

  updateSO(){
    this.service.updateSC(this.selected, this.selected.id).subscribe(
      response=>{
        this.toastr.error("Sales Order updated successfully");

    },
    ()=>{
      this.toastr.error("Sorry error occured during process");
   });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }


  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter?.getDataFilters() || [], offset);
  }

  getById(id: number) {
    this.subs.sink = this.service.viewSC(id).subscribe(response => {
      this.selected = response;
      this.expanded = false;
    });
  }

  deleteSC(row: SOList) {
    let data = { "is_delete": true };
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.service.patchSC(data, row.id).subscribe(
          () => {
            this.rows = this.rows.filter(x => x.id != row.id);
            this.cdr.markForCheck();
            this.closeDetailedView();
            popup.CompleteDelete('Sales Order deleted successfully');
            this.reloadData();
          },
          () => {
            this.toastr.error("Unable to delete Sales Order");
          }
        );
      }
    });
  }

  applyFilter(params: GFilterParam[]) {

    params.forEach((value,index)=>{
      if(value.colname=="service_contract_status"&&value.value=="Expired_in_90_days"){
        value.colname="expired_in_90_days";
      }
    });

    this.getData(params);
  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go(`/sales/sales-orders`);
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.cdr.markForCheck();
      this.rows = [...this.rows];
    });
  }

  setFilter(obj: any) {
    console.log(obj.colname+','+obj.value);
    this.dataFilter.setDefaultFilter(obj.colname, obj.value);
  }

  statusClass(id: number) {

    let status = {
                    "1": "status-new",
                    "2": "status-draft",
                    "3": "status-send-customer",
                    "4": "status-rejected",
                    "5": "status-approved",
                    "6": "status-partially-invoiced",
                    "7": "status-fully-invoiced",
                    "8": "status-converted-workorder",
                  };

    return status[id];

  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.SALES_SALES_ORDERS, [permission, this.Permissions.GLOBAL], ResourceConstants.SALES_MODULE)
  }

  reloadData() {
    this.getData(this.dataFilter?.getDataFilters() || [], this.page.offset);
    this.dashboard.getDashboardCounts();
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }

  trimTime(date:string){
    if(date&&date.length>0){
      let data=date.split('T');
      return data[0];
    }
    else{
      return '';
    }
 }

 comingInFuture(){
   window.alert("Sit tight, this feature is coming soon");
 }

 downloadPdf(isdownload: boolean){
    this.SalesOrderView.download(isdownload)
 }
 afterTabResize(){
    this.dataTable.recalculate();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
