export class R2VSettings {

    name: string;

    created_at: string;

    id: number;

    status: boolean;

    is_delete:boolean;

    url: string;

    did_count: number;

    did_purchase_rate:number;

    did_call_rate:number;

    sw_project_id:string;

    sw_space_url:string;

    sw_token:string;

    created_by: string;

    sub_org:number;

    created_on:string;

    modified_on:string;

    sip_uri:string;
  }
