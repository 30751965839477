import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { CustomerLocation } from '../../../models/tenant-customer';
import { TenantCustomerService } from '../../../services/tenant-customer.service';

@Component({
  selector: 'tenant-customer-location-select',
  templateUrl: './customer-location-select.component.html',
  styleUrls: ['./customer-location-select.component.scss'],
})
export class CustomerLocationSelectComponent implements OnInit {
  private _custId: number;

  get tenantCustomerId(): number {
    return this._custId;
  }

  @Input() set tenantCustomerId(val: number) {
    this._custId = val;
    if (this._custId > 0) {
      this.getData();
    }
  }

  private _value: number;

  get value(): number {
    return this._value;
  }

  @Input() set value(val: number) {
    this._value = val;
    if (this._value && this.selected !== val) {
      this.selected = this._value;
    }
    else if(val==null){
      this.selected=null;
    }
    
  }

  @Input() appendToBody: boolean;

  @Input() customer_suborg_id: number;

  @Input() size:string;

  @Output() valueChange = new EventEmitter<number>();

  private _selected: number;

  public get selected(): number {
    return this._selected;
  }

  public set selected(value: number) {
    this._selected = value;
    this.valueChange?.emit(value);
  }

  @Output() change = new EventEmitter<CustomerLocation>();

  @Output('default') defaultSetEvent = new EventEmitter<CustomerLocation>();

  @Input() addButton: boolean = true;

  @Input() readonly: boolean = false;

  @Input() newContact_model;

  @Output() itemAdded = new EventEmitter();

  @Output() cleared=new EventEmitter();

  public options: Observable<CustomerLocation[]>;

  private optionsFlat: CustomerLocation[];

  public placeholder: string;

  private modalRef: NgbModalRef;

  constructor(
    private tenantCustService: TenantCustomerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService
  ) {}

  ngOnInit() {
    this.placeholder =
      this.hasPermission() && this.addButton
        ? 'Select or add Location'
        : 'Select Location';
  }

  getData() {
    this.options = this.tenantCustService
      .getCustomerLocations(this.tenantCustomerId)
      .pipe(
        map((resp) => {
          this.optionsFlat = resp;
          this.notifyIfSelected();
          return resp;
        })
      );
  }

  openModal(content: any): void {
    this.modalRef = this.modalService.open(content, {
      centered: true,
      size: 'lg',
    });
  }

  onDataSubmittedSuccess(result: {
    response: CustomerLocation;
    message: string;
  }): void {
    this.toastr.success(result.message);
    // this.options = concat(this.options, of([result.response]));
    this.getData();
    this.selected = result.response.id;
    this.itemAdded?.emit();
    this.change?.emit(result.response);
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void {
    this.closeModal();
  }

  clear() {
    this.selected = undefined;
  }



  closeModal() {
    this.modalRef?.dismiss();
  }

  hasPermission() {
    return this.ra.hasPermission(
      ResourceConstants.CUSTOMER_LOCATIONS,
      [PermissionConstants.CREATE, PermissionConstants.GLOBAL],
      ResourceConstants.CUSTOMER_MODULE
    );
  }

  public getLocation(locid: number) {
    return this.optionsFlat?.find((item) => item.id === locid);
  }

  public reload() {
    this.getData();
  }

  itemSelected(location: CustomerLocation) {
    this.change?.emit(location);
    if(this.newContact_model){
      this.locationMappingtoCallLog(location);
    }
  }

  locationMappingtoCallLog(location){
      this.newContact_model.location_address = location.address;
      this.newContact_model.location_city = location.city;
      this.newContact_model.location_name = location.name;
      this.newContact_model.location_state = location.state;
      this.newContact_model.location_zip = location.zip;
  }

  notifyIfSelected() {
    if (this.selected) {
      this.defaultSetEvent?.emit(
        this.optionsFlat?.find((o) => o.id === this.selected)
      );
    }
  }

  reset(){
    this.cleared.emit();
  }
}
