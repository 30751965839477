import { STSubTask, SubTask, Task } from '../../../service-ticket/models/common.models';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Component, Input, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { SubSink } from 'subsink';
import { ChecklistService } from '../../../service-ticket/services/checklist.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { SweetAlertOptions } from 'sweetalert2';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { FilesService } from 'src/app/core/services/files.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'st-task-list-form',
  templateUrl: './st-task-list-form.component.html',
  styleUrls: ['./st-task-list-form.component.scss']
})
export class StTaskListFormComponent implements OnInit, OnDestroy {

  @ViewChild('modalContent') modalContent: any;

  @Output() setStTaskCount = new EventEmitter();

  public model: Task;

  public rows: STSubTask[] = [];

  public selectionType = SelectionType;

  private stid: number;

  public sid: number;

  public complete_percentage: number = 0;

  public itemImages: StaticFile[];

  modalRefPopup: NgbModalRef;

  imgURL: string = environment.imgURL

  @Input() set stId(value: number) {
    this.stid = value;
    if (value) {
      this.getTaskDetailsByStId(value);
    }
  }

  get stId(): number { return this.stid; };

  public scrollbarH: boolean = false;

  private subs: SubSink = new SubSink();

  public Permissions = PermissionConstants;

  public subTask: STSubTask;

  public selected: STSubTask;

  public autoFullViewImageIndex:number;

  isShowSave:boolean = false;

  constructor(private modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private checklistService: ChecklistService,
    private fileService: FilesService) { }

  ngOnInit(): void {
  }

  getTaskDetailsByStId(id: number) {
    this.subs.add(this.checklistService.getTaskDetailsByStId(id).subscribe(resp => {

      this.rows = resp;
      this.calculatePercentage();
      this.cdr.markForCheck();
    }));
  }

  makeMarked(val: boolean, index: number, event) {


    let option: SweetAlertOptions = {
      title: 'Are you sure you want to change the Marked status',
      text: '',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Change',
      customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn',
          htmlContainer: 'font-small-3'
      },
      buttonsStyling: false
  };
  popup.OpenConfirmBox(option, response => {

      if (response.isConfirmed) {
        this.subs.add(this.checklistService.updateStTaskList(this.rows[index]).subscribe(resp => {
          this.cdr.markForCheck();
          if (!val == true) {
            this.toastr.success('You marked this task is Complete');
            this.setStTaskCount.emit();
          } else {
            this.toastr.success('You unmarked this task');
            this.setStTaskCount.emit();
          }
        }));

        this.calculatePercentage()
      }else{
        this.rows[index].is_checked = !this.rows[index].is_checked;
        this.cdr.markForCheck();
      }
  });

  }

  changeRenderInPdf(subtask: STSubTask, index: number) {
    let option: SweetAlertOptions = {
      title: 'Are you sure you want to change the Render in Pdf status',
      text: '',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Change',
      customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn',
          htmlContainer: 'font-small-3'
      },
      buttonsStyling: false
  };
  popup.OpenConfirmBox(option, response => {
      if (response.isConfirmed) {
        this.subs.add(this.checklistService.updateStTaskList(subtask).subscribe(resp => {
            this.cdr.markForCheck();
            if (subtask.is_render_in_pdf)
                this.toastr.success('You marked this sub task is renderd in Pdf');
            else
                this.toastr.success('You unmarked this subttask');
        }));

      }
      else{
        subtask.is_render_in_pdf = !subtask.is_render_in_pdf;
        this.cdr.markForCheck();
      }
  });

  }

  uploadAttachment(event) {
    console.log(event.target.files)
  }

  calculatePercentage() {
    let total_count = this.rows.length;
    let checked_count = this.rows.filter(x => x.is_checked == true).length;
    if (total_count > 0 && checked_count > 0) {
      this.complete_percentage = Math.ceil((100 * checked_count) / total_count)
    } else {
      this.complete_percentage = 0;
    }
  }

  onFileDelete(fileId:string, sub) {
    if (sub.images) {
      sub.images = sub.images.filter(id => id != fileId);

      this.subs.add(this.checklistService.updateStTaskList(sub).subscribe(resp => {
        this.cdr.markForCheck();

          this.toastr.success('File Deleted');

      }));

    }
}


uploadImg(row, fileUploader) {
  let thumbails: ThumbNail[] = fileUploader.getFiles();
  if (thumbails.length) {
      this.subs.sink = this.fileService.uploadFiles(thumbails, 'SERVICE_TICKET_SUBTASK').subscribe(
          response => {
              row.images.push(...response.map(item => item.id));
              this.subs.add(this.checklistService.updateStTaskList(row).subscribe(resp => {
                this.cdr.markForCheck();

                  this.toastr.success('File uploaded');
                  this.getTaskDetailsByStId(this.stId);
                  this.modalRefPopup?.dismiss()

              }));
          },
          (error) =>{
              console.log(error);
          });
  }

}

addComment(){

  this.rows.forEach(row => {

    this.subs.add(this.checklistService.updateStTaskList(row).subscribe(resp => {
    }));
  });

  this.toastr.success('Changes has been updated.');

}

openModal(content: any, sub:STSubTask, index:number=-1){

  this.isShowSave = false;

  this.autoFullViewImageIndex = index;
  this.getImages(sub);
  this.selected = sub;
  this.modalRefPopup = this.modal.open(content, { centered: true, scrollable: true, size: "lg" });
}

getImages(sub:STSubTask): void {
  this.itemImages=[];
  this.subs.sink = this.checklistService.getSubTaskImages(this.stid,sub.sub_task).subscribe(resp=> {
      this.itemImages = resp;
      sub.images = resp.map(file => file.id);
      this.cdr.markForCheck();
  });
}

toggleSave(isShowSave:boolean){

  this.isShowSave = isShowSave;

}

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
