import { CustomFieldsService } from './../../../../core/services/custom-fields.service';
import { CategoryService } from './../../services/category.service';
import { observable, Observable } from 'rxjs';
import { FilesService } from './../../../../core/services/files.service';
import { ItemCategory } from './../../models/items';
import { ItemService } from './../../services/item.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Item } from '../../models/items';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { CustomField } from 'src/app/core/models/custom-field';
import { SubSink } from 'subsink';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { additionalFieldsList, additional_field_values, catCustomField, catCustomFieldData, itemCustomField, itemCustomFieldGet } from '../../models/additional-fields.models';
import { AdditionFieldsService } from '../../services/addition-fields.service';
import { ServiceTicketRoutingModule } from 'src/app/modules/service-ticket/service-ticket-routing.module';
import { ItemTypes } from 'src/app/core/enums/common.enum';

@Component({
    selector: 'app-add-item',
    templateUrl: './add-item.component.html',
    styleUrls: ['./add-item.component.scss'],
})
export class AddItemComponent implements OnInit, OnDestroy {

    @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;

    public model: Item;

    private partNumberInitial: string;

    public itemId: number = 0;

    public itemImages:StaticFile[];

    public additionalFields: CustomField[];



    public customFieldInputs = { key: '', value: '' };

    public partNumberHelper = { valid: true, message: '', visible: false };

    public lastGenPartNumber: Observable<string>;

    public currentCategory: ItemCategory;

    public previousCategory: ItemCategory;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public isClone: boolean = false;

    private subs: SubSink = new SubSink();

    public additionalFieldz: additionalFieldsList[];

    public itemCustomField:itemCustomField=new itemCustomField();

    public addAdditionalFieldValues:any[];

    public additionalFieldId:any;

    public additionalFieldValue:any;

    public customAdditionalFieldKey:string;

    public customAdditionalFieldValue:string;

    public itemTypes = ItemTypes;

    constructor(
        route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        public location: Location,
        private cdr: ChangeDetectorRef,
        private itemService: ItemService,
        private modalService: NgbModal,
        private catService: CategoryService,
        private customFields: CustomFieldsService,
        private fileService: FilesService,
        private ra: ResourceAccessService,
        private additionalFieldService:AdditionFieldsService
    ) {
        this.itemId = +route.snapshot.paramMap.get('id')
        this.isClone = this.router.url.includes('clone');
    }

    ngOnInit(): void {
        if (this.itemId) {
            this.getItem();
        }
        else {
            this.model = this.itemService.createModel(Item);
        }
        this.getAdditionalFields();
    }

    getItem(): void {
        this.subs.sink = this.itemService.getById(this.itemId).subscribe((item) => {
            this.model = item;
            if (this.isClone) {
                this.model.id = 0;
                this.model.part_number = '';
                this.lastGenPartNumber = item.category
                    ? this.catService.getLastGeneratedPartnumber(item.category)
                    : null;
            }
            this.additionalFields = this.customFields.obj2CustomFields(
                item.additional_fields
            );
            this.partNumberInitial = item?.part_number;
            this.cdr.markForCheck();
            this.getImages();
        });
    }

    getImages(): void {
        this.subs.sink = this.itemService.getItemImages(this.itemId).subscribe(resp=> {
            this.itemImages = resp;
            this.model.images = resp.map(file => file.id);
        });
    }

    onSubmit() {
        this.model.additional_fields = this.customFields.customFields2Obj(
            this.additionalFields
        );

        let thumbails: ThumbNail[] = this.fileUploader.getFiles();

        if (thumbails.length) {

            this.subs.sink = this.fileService.uploadFiles(thumbails, 'ITEMS').subscribe(response => {

                let primary = thumbails.find(t=> t.isPrimary);

                if (primary) {
                    this.model.primary_image = response.find(f=> f.name = primary.name)?.id;
                }
                else if(response.length){
                    primary = this.fileUploader.getPrimary();
                    this.model.primary_image = (primary) ? primary.data_id : response[0].id;
                }

                this.model.images.push(...response.map(item => item.id));

                this.submit();
            });
        }
        else {
            this.model.primary_image = this.fileUploader.getPrimary()?.data_id || null;
            this.submit();
        }
    }

    submit() {
        this.model.customer = this.itemService.customerId;

        let isedit = this.itemId && !this.isClone;

        let api = isedit
            ? this.itemService.update(this.itemId, this.model)
            : this.itemService.create(this.model);

        let error=this.additionalFieldService.validateAF(this.itemCustomField.additional_data);

        if(error!=""){
            this.toastr.error(error);            
        }
        else{

            this.subs.sink = api.subscribe(
                (resp) => {
                    this.updateAdditionalField(resp.id);
                    // isedit?this.updateAdditionalField(resp.id):this.saveAdditionalField();
                    this.toastr.success(isedit ? `Item info updated` : `New Item created`);
                    if (this.hasPermission(this.Permissions.VIEW_INFO)) {
                        this.router.navigate(['/inventory/items', resp.id, 'view']);
                    } else {
                        this.location.back();
                    }
                },
                () =>
                    this.toastr.error(
                        isedit ? `Failed to update Item` : `Failed to create Item`
                    )
            );
        }
    }

    addCustomField() {
        this.additionalFields = this.additionalFields ?? [];

        if (
            !this.additionalFields.some((x) => x.name === this.customFieldInputs.key)
        ) {
            let customField = new CustomField();
            customField.name = this.customFieldInputs.key;
            customField.value = this.customFieldInputs.value || null;
            this.additionalFields.push(customField);
        } else {
            this.toastr.info(`Field '${this.customFieldInputs.key}' exist`);
        }
        this.modalService.dismissAll();
    }

    openModal(content: any) {
        this.customFieldInputs.key, (this.customFieldInputs.value = '');
        this.modalService.open(content, { centered: true });
    }

    deleteCustomField(index: number) {
        // this.additionalFields = this.additionalFields.filter((x) => x.id !== id.toString());
        // this.itemCustomField.additional_data.forEach((x) => {
        //     if( x.id === id){
        //         x.action=3;
        //     }
        // });
        if(this.itemCustomField.additional_data[index]){
            this.itemCustomField.additional_data[index].action=3;
        }
        // else{
        //     this.itemCustomField.additional_data.splice(index,1);
        // }
        
        this.cdr.markForCheck();
    }

    onCategoryChanged(category: ItemCategory) {

        if(this.itemId==0&&category?.id){
            this.getCategoryAdditionalFields(category.id);
        }
        this.previousCategory = this.currentCategory;
        this.currentCategory = category;
        this.updateAdditionalFields(category);
        this.lastGenPartNumber = !category.create_partnumber
            ? null
            : this.catService.getLastGeneratedPartnumber(category.id);
    }

    generatePartNumber() {
        if (!this.model.category || !this.currentCategory?.create_partnumber)
            return;

        this.subs.sink = this.catService
            .generatePartNumber(this.model.category)
            .subscribe(
                (response) => {
                    this.model.part_number = response.part_number;
                    this.partNumberHelper.valid = true;
                    this.cdr.markForCheck();
                },
                (error) => {
                    this.model.part_number = '';
                    this.partNumberHelper.valid = false;
                    this.partNumberHelper.message =
                        error?.message || 'Unable to create Part Number';
                    this.cdr.markForCheck();
                }
            );
    }

    updateAdditionalFields(current: ItemCategory) {
        let removeTemplate = this.previousCategory?.additional_fields;
        let fields = this.customFields.removeFieldsByObjectsKeys(
            this.additionalFields || [],
            removeTemplate
        );
        let currentFields = this.customFields.obj2CustomFields(
            current.additional_fields
        );
        this.additionalFields = currentFields
            ? this.customFields.merge(fields, currentFields)
            : fields;
    }

    categoryClear() {
        this.model.part_number = '';
        this.lastGenPartNumber = null;
        if (this.currentCategory && this.currentCategory.additional_fields) {
            let removeTemplate = this.currentCategory.additional_fields;
            this.additionalFields = this.customFields.removeFieldsByObjectsKeys(
                this.additionalFields || [],
                removeTemplate
            );
        }
    }

    partNumberOnChange(event: any) {
        let value: string = event.target.value;

        if (!value || value === this.partNumberInitial) {
            this.partNumberHelper.valid = true;
            return;
        }

        this.subs.sink = this.catService.isPartNumberUnique(value).subscribe(
            (response) => {
                this.partNumberHelper.valid = response.is_unique;
                this.cdr.markForCheck();
            },
            () => {
                this.partNumberHelper.valid = false;
                this.partNumberHelper.message = 'Part Number exist';
                this.cdr.markForCheck();
            }
        );
    }

    saveSheet(prop: string) {
        let req = {};
        req[prop] = this.model[prop];
        if (this.itemId > 0) {
            this.subs.sink = this.itemService
                .patch(this.itemId, req)
                .subscribe((resp) => {
                    this.toastr.success('Sheet updated');
                });
        }
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(this.Resource.INV_ITEMS, [permission], this.Resource.INV_MODULE);
    }

    hasPermissionWR(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], this.Resource.INV_MODULE);
    }

    priceVisible() {
        let perm =
            this.itemId > 0 ? this.Permissions.MODIFY : this.Permissions.CREATE;
        return this.hasPermissionWR(this.Resource.INV_ITEMS_PRICE, perm);
    }

    onFileDelete(fileId:string) {
        if (this.model.images) {
            this.model.images = this.model.images.filter(id => id != fileId);
            if (this.model.primary_image === fileId) {
                this.model.primary_image = null;
            }
        }
    }

    addAdditionalField(type:string){

        let field:catCustomFieldData=new catCustomFieldData();

        field.action=1;
        if(type=='fromlist'){
            field.additional_field=this.additionalFieldId;
            field.value=this.additionalFieldValue;
            field.type=1;
        }
        else if(type=='custom'){
            field.custom_additional_field=this.customAdditionalFieldKey;
            field.custom_additional_field_value=this.customAdditionalFieldValue;
            field.type=2;
        }



        if((type=='fromlist'&&!this.itemCustomField.additional_data.find(val=>val.additional_field==this.additionalFieldId))||type=='custom'&&!this.itemCustomField.additional_data.find(val=>val.custom_additional_field==this.customAdditionalFieldKey)){

            if(!this.additionalFieldValue&&!this.customAdditionalFieldValue){
                this.toastr.error('Please select a value');

                return;

            }
            this.itemCustomField.additional_data.push(field);
            this.additionalFieldId=null;
            this.additionalFieldValue=null;

            this.cdr.markForCheck();
    
            this.modalService.dismissAll();

        }
        else{
            this.toastr.error('The additional field already exists');
        }


        




    }


    getAdditionalFields(callItemAF=true){

        this.additionalFieldService.getAll().subscribe(response=>{

            this.additionalFieldz=response.data;

            if(this.itemId>0&&callItemAF){
                this.getItemAdditionalField();

            }   

        });

    }

    // selectableAdditionalFields(){
    //     let addedFieldIds=[];
    //     addedFieldIds=this.catCustomField.additional_data.map((e)=>e.additional_field);
    //     // console.log(addedFieldIds, 'ids');
    //      return  this.additionalFieldz.filter((val)=>!addedFieldIds.includes(val.additional_field.id))

        
    // }

    

    getAdditionFieldValues(fieldId){

        let values=[];

        if(this.additionalFieldz&&this.additionalFieldz.length){
           values= this.additionalFieldz.find((val)=>val.additional_field.id==fieldId)?.values;
        }
        return values;
    }

    clearAdditionalFieldValue(fieldId){

        if(this.itemCustomField.additional_data&&this.itemCustomField.additional_data.length){
            this.itemCustomField.additional_data.find((val)=>val.additional_field==fieldId).value=null;
         }

    }

    getCategoryAdditionalFields(catId:number){

        this.itemCustomField.additional_data=[];

        this.additionalFieldService.getCatAdditionalField(catId).subscribe(response=>{

            if(response&&response?.data.data&&response.data.data.length){
                // console.log(response.data, 'get response');



                response.data.data.forEach(element => {

                    let newVal:catCustomFieldData=new catCustomFieldData();
                    newVal.additional_field=element.additional_field_id;
                    newVal.id=element.category_id?null:element.id;
                    newVal.action=element.category_id?1:2;
                    newVal.category_additional_field=element.category_id?element.id:null;
                    newVal.type=element.type?element.type:element.additional_field_id?1:2;
                    newVal.value=element.value_id_id;
                    newVal.custom_additional_field=element.custom_additional_field;
                    newVal.custom_additional_field_value=element.custom_additional_field_value;

                    this.itemCustomField.additional_data.push(newVal);
                    // this.assemblyCustomField.additional_data.push({'additional_field':element.additional_field_id,'id':element.kit_id?element.id:null,'value':element.value_id_id, 'action':2, 'category_additional_field':element.category_id?element.id:null, 'custom_additional_field':'', 'custom_additional_field_value':''});
                    
                });
            }
            else{
                // this.addCustomField();
            }
            this.cdr.markForCheck();
        })



    }



    getItemAdditionalField(){

        console.log('You are here');

        this.itemCustomField.additional_data=[];

        this.additionalFieldService.getItemAdditionalField(this.itemId).subscribe(response=>{

            if(response.data&&response.data.data&&response.data.data.length){
                console.log(response.data.data, 'get response');
                response.data.data.forEach(element => {
                    let newVal:catCustomFieldData=new catCustomFieldData();
                     newVal.additional_field=element.additional_field_id;
                     newVal.id=!element.category_id?element.id:null;
                     newVal.action=element.category_id?1:2;
                     newVal.value=element.value_id_id;
                     newVal.category_additional_field=element.category_id?element.id:null;
                     newVal.type=element.type;
                     newVal.custom_additional_field=element.custom_additional_field;
                     newVal.custom_additional_field_value=element.custom_additional_field_value;
                     
                    this.itemCustomField.additional_data.push(newVal);
                    // this.itemCustomField.additional_data.push({'additional_field':element.additional_field_id,'id':element.item_id?element.id:null,'value':element.value_id_id, 'action':2, 'category_additional_field':element.category_id?element.id:null, 'custom_additional_field':'', 'custom_additional_field_value':''});
                    
                });
            }
            else{
                // this.addCustomField();
            }
            this.cdr.markForCheck();
        })
    }

    saveAdditionalField(){

        if(this.itemCustomField.additional_data.length>0){

            if(this.itemCustomField.additional_data.length==1&&!this.itemCustomField.additional_data[0].additional_field){
                return;
            }
            this.itemCustomField.item=this.itemId;

            this.additionalFieldService.createItemAdditionalField(this.itemCustomField).subscribe(response=>{

                console.log(response, 'Item additional field');

            });

        }

    }

    updateAdditionalField(itemId=this.itemId){



            if(this.itemCustomField.additional_data.length>0){

                if(this.itemCustomField.additional_data.length==1&&!this.itemCustomField.additional_data[0].additional_field){
                    return;
                }
                this.itemCustomField.item=itemId;
    
                this.additionalFieldService.updateItemAdditionalField(itemId,this.itemCustomField).subscribe(response=>{
    
                    console.log(response, 'category additional field');
    
                });
    
            }



    }


    nameFromAdditionalFields(id:number){

        let name="";
        if(this.additionalFieldz&&this.additionalFieldz.length){
            name=this.additionalFieldz.filter(val=>val.additional_field.id==id)[0]?.additional_field?.field_name.toString();

            console.log(name);
        }


        return name;
    }

    valuesOfAdditionalField(id:number):Observable<additional_field_values[]>{


        // return values;

        return new Observable(observer=>{

            let values=[];
            if(this.additionalFieldz&&this.additionalFieldz.length){
                values=this.additionalFieldz.filter(val=>val.additional_field.id==id)[0]?.values;
            }

            observer.next(values);
            observer.complete();

        });
    }

    additionalFieldSelected(value){
        ;
        this.getAdditionalFields(false);
        this.additionalFieldId=value.additional_field.id;
        this.addAdditionalFieldValues=value.values;
        this.cdr.markForCheck();
    }


 

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
