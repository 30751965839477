import swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

export function OpenConfirmBox(option: SweetAlertOptions, callback?: (value: SweetAlertResult) => any) {
    swal.fire(option).then(result => {
        if (callback) callback(result);
    });
}

export function ConfirmDelete(callback?: (value: SweetAlertResult) => any) {
    let option: SweetAlertOptions = {
        title: 'Are you sure you want to Delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn',
            footer: 'flex-row-reverse'
        },
        buttonsStyling: false
    };
    swal.fire(option).then(result => {
        if (callback) callback(result);
    });
}

export function ConfirmDeleteWithTitle(title: string, callback?: (value: SweetAlertResult) => any) {
    let option: SweetAlertOptions = {
        title: title,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn',
            footer: 'flex-row-reverse'
        },
        buttonsStyling: false
    };
    swal.fire(option).then(result => {
        if (callback) callback(result);
    });
}

export function CompleteDelete(message: string, callback?: () => any) {
    swal.fire({
        icon: "success",
        title: 'Deleted!',
        text: message,
        showConfirmButton: false,
        timer: 1000
    }).then(() => {
        if (callback) callback();
    });
}

export function ConfirmActivate(opt: { title: string, text: string, current: boolean }, callback?: (value: SweetAlertResult) => any) {
    let option: SweetAlertOptions = {
        title: opt.title,
        text: opt.text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: opt.current ? 'Activate' : 'Deactivate',
        customClass: {
            confirmButton: opt.current ? 'btn btn-success' : 'btn btn-danger',
            cancelButton: 'btn',
            footer: 'flex-row-reverse'
        },
        buttonsStyling: false
    };
    swal.fire(option).then(result => {
        if (callback) callback(result);
    });
}

export function ConfirmWithButtonText(opt: { title: string, text: string, ButtonText: string }, callback?: (value: SweetAlertResult) => any) {
    let option: SweetAlertOptions = {
        title: opt.title,
        text: opt.text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: opt.ButtonText || 'Yes',
        customClass: {
            confirmButton: opt.ButtonText ? 'btn btn-success' : 'btn btn-danger',
            cancelButton: 'btn',
            footer: 'flex-row-reverse'
        },
        buttonsStyling: false
    };
    swal.fire(option).then(result => {
        if (callback) callback(result);
    });
}

export function AllSuborgCreateAlert() {
    swal.fire({
        icon: 'error',
        title: 'Switch Suborg to create Record',
        text: 'Current Suborg : All',
        footer: 'You cannot create or update record if suborg is All'
    })
}

export function ShowMessage(message: string, callback?: () => any) {
    swal.fire({
        icon: "success",
        title: '',
        text: message,
        showConfirmButton: false,
        timer: 1000
    }).then(() => {
        if (callback) callback();
    });
}
