import { DistributorService } from '../../../services/distributor.service';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DistributorLocation } from '../../../models/distributor';
import { LocationType, LocationSystemType } from '../../../models/location-types';
@Component({
  selector: 'distributor-location-form',
  templateUrl: './distributor-location-form.component.html',
  styleUrls: ['./distributor-location-form.component.scss']
})
export class DistributorLocationFormComponent implements OnDestroy {

  public model: DistributorLocation = new DistributorLocation();

  @Input() distributorId: number;

  @Output() success = new EventEmitter<{ response: DistributorLocation, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  private binding: DistributorLocation;

  get bindingModel(): DistributorLocation { return this.binding; };

  @Input() set bindingModel(value: DistributorLocation) {
    if(value){
      this.model = value;
      this.binding = value;
    }
  };

  private sub: Subscription;

  constructor(private service: DistributorService) { }

  locationTypeChange(type: LocationType) {
    this.model.location_types_name = type?.name;
  }

  locationSystemTypeChange(type: LocationSystemType) {
    this.model.location_system_types_name = type?.name;
  }

  submit() {
    let api: Observable<DistributorLocation>;

    if (this.bindingModel) {
      api = this.service.updateDistributorLocation(this.bindingModel.id, this.model);
    }
    else {
      this.model.distributor = this.distributorId;
      api = this.service.createDistributorLocation(this.model);
    }

    this.sub = api.subscribe(
      resp => {
        let msg = this.bindingModel ? 'Location info updated' : 'New Location added';
        resp.location_types_name = this.model.location_types_name;
        resp.location_system_types_name = this.model.location_system_types_name;
        this.success?.emit({ response: resp, message: msg });
      },
      (error) => { this.error?.emit(error); }
    );
  }

  onCancelClicked(): void { this.cancel?.emit(); }

  ngOnDestroy(): void { this.sub?.unsubscribe(); }
}
