
<div *ngIf="reportType !== 'outbound'">
    <section  *ngIf="showCount">
        <app-callreport-dashboard [dashboardData] = "dashboardData"  [carddata] = "carddata"
        (displayNameChange) = "setFilter($event)"></app-callreport-dashboard>
    </section>


    <section class="common-grid-view call-history">
        <div class="list-parellax" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
            <div class="d-flex flex-row flex-1 h-100">

                <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                    <div class="d-flex align-items-center pb-10 flex-space-bw"
                        [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}" *ngIf = "showGridFilter && reportType == 'inbound'||reportType == 'missed'">
                        <datagrid-filter #dataFilter
                                        [searchFilters]="search"
                                        [expanded]="expanded"
                                        [columns]="columns"
                                        [defaultFilters]="defaults"
                                        [resetToCurrentDashData]="true"
                                        [module]="module"
                                        [export]="showExport"
                                        (exportButtonClick)="bulkExport()"
                                        (apply2)="applyFilterGroup($event)"
                                        [gridTabs] = "_gridTabs"
                                        [selectedTabId]="reportType"
                                        [showSearch] = "showSearch"
                                        [showSavedFilter] = "showSavedFilter"
                                        (selectedGridTab)="onSelectGridTabs($event)"
                                        [reportTypeData] = "reportTypeData"
                                
                                        [showreportType] = "showreportType"
                                        [showDefaultFilter] = "_showDefaultFilter">
                                        <!-- (onTabSelect) = "onChangeReportType($event)" -->
                            <ng-template filterControl [column]="'customer'" let-row="row">
                                <app-customer-select class="ng-select-sm" [(value)]="row.value"
                                                    (change)="dataFilter.controlValueChanged('customer')">
                                </app-customer-select>
                            </ng-template>
                            <ng-template filterControl [column]="'suborg'" let-row="row">
                                <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue">
                                </suborg-select>
                            </ng-template>
                            <ng-template filterControl column="forward_number_sid" let-row="row">
                                <forward-bin-select class="ng-select-sm"
                                    [(value)]="row.value"
                                    [suborgId]="suborg_id">
                                </forward-bin-select>
                            </ng-template>
                        </datagrid-filter>
                    </div>
                    <ng-container [ngSwitch]="selectedGridTabId">
                        <ng-container *ngSwitchCase="'all'">
                            <div class="card-content">
                                <div [ngClass]="{'card-body':expanded}">
                                
                                    <ngx-datatable #dataTable
                                                [rows]="rows" 
                                                class="bootstrap core-bootstrap"
                                                [columnMode]="'force'"
                                                [footerHeight]="50"
                                                [rowHeight] ="50"
                                                [scrollbarH]="scrollbarH"
                                                [selectionType]="SelectionType.single"
                                                [count]="page.count"
                                                [externalPaging]="true"
                                                [offset]="page.offset"
                                                [externalSorting]="true"
                                                [limit]="page.limit"
                                                trackByProp="sid"
                                                (select)="onSelect($event)"
                                                (page)="setPage($event)"
                                                (sort)="onSort($event)"
                                                [sorts]="[{prop:'date_created',dir:'desc'}]">

                                                
            
            
                                        <ngx-datatable-row-detail [rowHeight]="75" #myDetailRow>
                                        <ng-template let-row="row" let-value="value" ngx-datatable-row-detail-template>
                                        <div class="row row-detail">
            
                                            <div class="row-item" [ngClass]="expanded?'col-md-1':'col-md-4'"  style="margin-right: 25px;">
                                            <!-- <div>
                                                {{ row.date_created | dateTz:'MM/DD/YYYY h:mm a' }}
                                            </div> -->
                                                <div>
                                                    {{ (row.date_created | dateTz:'MM/DD/YYYY h:mm a').substring(0, (row.date_created | dateTz:'MM/DD/YYYY h:mm a').indexOf(' ')) }}
                                                </div>
                                                <div>
                                                    {{ (row.date_created | dateTz:'MM/DD/YYYY h:mm a').substring((row.date_created | dateTz:'MM/DD/YYYY h:mm a').indexOf(' '))}}
                                                </div>
                                            </div>
            
                                            <div class="col-md-2 row-item" *ngIf="expanded">
                                            <div  class="contact">
                                                
                                                    <div class="avatar mr-1 ml-0" [class]="randomDpBackground(row)">
                                                        <div class="avatar-content">{{ row.customer_contact_name | shortName}}
                                                        </div>
                                                    </div>
                                                
                                                
                                                <div>
                                                <span class="contact-name">{{row.customer_contact_name?row.customer_contact_name:'Unknown'}}</span>
                                                <div *ngIf="row.customer_contact_title">{{row.customer_contact_title?row.customer_contact_title:'Unknown'}}</div>
                                                </div>
                                            
                                            </div>
                                            
                                            </div>
            
                                            <div class=" row-item" [ngClass]="expanded?'col-md-3':'col-md-7'">
                                                <div class="d-flex flex-row">
                                                    <span class="number-label">From</span>
                                                    <span *ngIf = "!pattern.test(row.call_from)">{{row.call_from}} 
                                                        <img width="30" height="30" title="Call Back" (click)="callBack(row)"
                                                        src="assets/img/svg/callback.svg" style="margin-top: -5px; margin-left: 7px;cursor: pointer;" />
                                                    </span>

                                                    <span *ngIf = "pattern.test(row.call_from)">{{row.call_from | phoneNumber}} 
                                                        <img width="30" height="30" title="Call Back" (click)="callBack(row)"
                                                        src="assets/img/svg/callback.svg" style="margin-top: -5px; margin-left: 7px;cursor: pointer;" />
                                                    </span>
                                                    
                                                </div>
                                                <div class="d-flex flex-row">
                                                    <span class="number-label">To</span>
                                                    <span *ngIf = "!pattern.test(row.call_to)">{{row.call_to}} </span>
                                                    <span  *ngIf = "pattern.test(row.call_to)">{{row.call_to | phoneNumber}}</span>
                                                </div>
                                                <div class="d-flex flex-row">
                                                    <span class="number-label">Name</span>
                                                    <span>{{row.phone_number_name}}</span>
                                                </div>
                                            </div>
            
                                            <div class="col-md-2 row-item" *ngIf="expanded">
                                                <div>Duration:{{row.duration}}</div>
                                            
                                            </div>
            
                                            <div class="col-md-2 row-item" *ngIf="expanded">
                                                <div>Route:</div>
                                                <div>{{row.forward_number_name}}</div>
                                                <div class="bold" *ngIf="row.forward_number">
                                                    <span *ngFor="let num of row.forward_number.split(',') as numList; let i = index">
                                                        <span>{{num | phoneNumber}}</span>
                                                        <span *ngIf="i !== numList.length-1">, </span>
                                                    </span>
                                                </div>
                                            </div>
            
                                            <div class="col-md-1 row-item" *ngIf="expanded">
            
                                                <div class="badge"
                                                    [ngClass]="{'bg-light-success': row.real_status=='completed','bg-light-danger': row.real_status=='no-answer' }">
                                                    {{ row.real_status }}
                                                </div>
                                                <div class="badge bg-secondary justify-content-around d-flex mt-1" placement="left" *ngIf="row.is_voicemail">
                                                    Voicemail
                                                </div>
                                                <div placement="left" [ngbTooltip]="row.spam_reason?row.spam_reason:''" 
                                                    class="badge bg-light-danger justify-content-around d-flex mt-1" *ngIf="row.is_spam">
                                                    Spam
                                                </div>
                                            
                                            </div>
            
                                            
            
                                        </div>
                                        </ng-template>
                                        </ngx-datatable-row-detail>               
            
                                        <ngx-datatable-column  name="Direction" [sortable]="false" [minWidth]="145" [maxWidth]="125">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div style="align-items: center; display: flex;">
                                                <i  aria-hidden="true">
                                                    <img *ngIf="row.direction=='outbound-dial' " src="../../../../assets/img/svg/outgoingcall.svg" alt="">
                                                    <img *ngIf="row.direction=='inbound'&&row.real_status!='no-answer'" src="../../../../assets/img/svg/incomingcall.svg" alt="">
                                                    <img *ngIf="row.direction=='inbound'&&row.real_status=='no-answer'" src="../../../../assets/img/caller assets/missed.png" width="18" height="13" alt="">
                                                </i>
                                                
                                                &nbsp;{{row.direction}} {{row.direction=='inbound'&&row.real_status=='no-answer'?' - missed':''}}
                                            </div>
                                            <!-- <div *ngIf="!expanded">
                                                {{ row.created_on | dateTz:'MM/DD/YYYY h:mm a' }}
                                            </div> -->
                                        </ng-template>
                                        </ngx-datatable-column>
                                    
                                        
                                        <ngx-datatable-column [sortable]="false"  name="Customer" prop="customer_name" [minWidth]="100" *ngIf="expanded">
                                            <ng-template let-row="row" ngx-datatable-cell-template>   
                                            <div class="customer-name">
                                                <div class="badge badge-warning name-badge w-100">{{row.tenant_customer_name?row.tenant_customer_name:'Unknown'}}</div>
                                            </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        
                                        <ngx-datatable-column [sortable]="false"  name="Answered By" prop="answered_by" [minWidth]="115">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div class="flexColDiv">
                                                <!-- <label>Answered By:</label> -->
                                                <div>{{row.answered || '--'}}</div>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="expanded" name="Recording" prop="recording_url_list"
                                                            [sortable]="false" [width]="250" [canAutoResize]="false">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div *ngIf="row.recording_url_list">
                                                    <audio *ngIf="row.recording_url_list[0]" controls class="audio-control" #audioElement
                                                        (play)="audioPlayerPlay(audioElement)">
                                                    <source [src]="row.recording_url_list[0]" type="audio/wav">
                                                    not supported
                                                    </audio>
                                                    <small class="pull-right mr-1" *ngIf="row.recording_url_list.length>1">+{{row.recording_url_list.length-1}} {{row.recording_url_list.length-1 === 1 ? 'recording' : 'recordings'}}</small>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
            
                                        <ngx-datatable-column [sortable]="false" *ngIf="expanded && !isSuperAdmin" name="Suborg" prop="suborg_name" [minWidth]="100">
                                            <ng-template let-suborg="value" ngx-datatable-cell-template>
                                                
                                                <div>
                                                    <div>{{suborg}}</div>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                    
                                        <ngx-datatable-column *ngIf="expanded" name="Actions"  [sortable]="false" [minWidth]="90">
                                            <ng-template let-row="row" ngx-datatable-cell-template >
                                                <!-- <button type="button" class="btn btn-info btn-callback"  style="margin-left: 7px;" (click)="callBack(row)">
                                                    <i class="fa fa-phone mx-1" aria-hidden="true"></i>Call back</button> -->
                                                    <a  (click)="$event.stopPropagation();readComments(row.sid, row.sub_org)" class="text-info">{{row.comments >0?'Read':'Add'}} Comments</a>
                                            </ng-template>
                                        </ngx-datatable-column> 
                                        <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg"
                                                            [sortable]="false">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div>{{row.customer_name}}</div>
                                                <div>Sub org : {{row.suborg_name}}</div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        
            
                                        <!-- <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false" [minWidth]="50"> 
                                            <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                                <div ngbDropdown container="body">
                                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                                        <i class="ft-more-vertical text-primary"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <button [routerLink]="['/users/groups',id,'edit']" class="w-100" ngbDropdownItem>
                                                            <i class="ft-edit mr-1"></i> Edit
                                                        </button>
                                                        <button class="w-100 bg-light-danger"  ngbDropdownItem>
                                                            <i class="fa fa-ban mr-1"></i> Add to Block
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column> -->
            
                                        <ngx-datatable-footer>
                                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                        let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                                <div class="page-count" *ngIf="expanded">
                                                    <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                                </div>
                                                <datatable-pager 
                                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                                    [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                    [size]="pageSize"
                                                    [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                                    (change)="dataTable.onFooterPage($event)">
                                                </datatable-pager>
                                            </ng-template>
                                        </ngx-datatable-footer>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </ng-container>        
                    </ng-container>       
                </div>
                <div class="parellax-detail" 
                parellaxTabSlider 
                [minWidth]="800"
                [width]="950"
                [target]="target"
                *ngIf="!expanded && selected">
                <div #target>
                    <div class="parellax-detail-content">
                        <call-history-view-details 
                        [selected]="selected"
                        (closeView)="closeDetailedView()"
                        (playAudio)="playAudio($event)"
                        [childCalls]="childCalls">
                        </call-history-view-details>
                    </div>
                </div>

                </div>
            </div>
        </div>

        <ng-template #commentsModal let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h5 class="modal-title">Comments</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="max-width: 500px;">
                    <app-comments [uniqueId]="sIdForComment" [moduleId]="22"></app-comments>
                </div>
            </div>
        </ng-template>
    </section>
</div>
<div *ngIf="reportType !== 'inbound'">
    <app-outbound-call-reports 
    
        (reportTypeToggleVal) = "onChangeReportType($event)"

        (onSelectFilterTab)="onSelectGridTabs($event)"
        [activeTab]="reportType"
        [gridTabs] = "_gridTabs"

        [showreportType] = "showreportType" 
        [showExport] = "showExport" 
        [isAllSuborg] = "isAllSuborg"
        [showSavedFilter] = "showSavedFilter" 
        [showAdvancedFilter] = "showAdvancedFilter"
        [reportTypeData] = "reportTypeData" 
        [defaultFilter] = "outboundDefaults" 
        [showCount] = "showCount"
        [enableViewDetails] = "enableViewDetails" 
        [showDefaultFilter] = "_showDefaultFilter">
    </app-outbound-call-reports>
</div>
