import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { map } from 'rxjs/operators';
import { AgentStatusLog, SIP } from '../models/sip.models';

@Injectable({
    providedIn: 'root'
})
export class SIPService extends BaseService {

    public assignSiptoUser: string;

    constructor(private http: HttpClient) { super(); }

    get(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit, suborg_id:number = this.suborgId) {
        let headers = new HttpHeaders();
        if (!isNaN(suborg_id)) {
            headers = headers.set('Suborg', suborg_id.toString());
        }
        let url = `${this.baseURL}/sip/data/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<SIP[]>>(url, request, { headers: headers });
    }

    getById(sid: string) {
        const request: DataFilterRequest = {
            base_filters: [{ colname: 'id', condition: 'is', value: sid, operator: 'AND' }],
            filters: null,
            sort: null
        }
        return this.get(request, 0, 1).pipe(map(resp => {
            if (resp.result.length) {
                let obj = resp.result[0];
                obj.ciphers = obj.ciphers ? obj.ciphers.split(',') : [];
                obj.codecs = obj.codecs ? obj.codecs.split(','): [];
                return obj;
            }
            return null;
        }));
    }

    create(model: SIP) {
        return this.http.post<SIP>(`${this.baseURL}/sip/`, model);
    }

    update(id: string, model: SIP) {
        return this.http.put<SIP>(`${this.baseURL}/sip/${id}/`, model);
    }

    updateSendAs(id: string, model: SIP) {
        return this.http.patch<SIP>(`${this.baseURL}/sip/${id}/`, model);
    }

    delete(sid: string) {
        return this.http.delete(`${this.baseURL}/sip/${sid}/`);
    }

    sendSIPVerificationCode(id:string) {
        return this.http.get(`${this.baseURL}/sip/${id}/send_verification_code/`);
    }

    verifySIPVerificationCode(id:string, otp:string) {
        return this.http.post(`${this.baseURL}/sip/${id}/verify_code/`, {otp: otp});
    }

    getAgentStatusLog(userId: number, limit: number = this.dataLimit) {
        let url = `${this.agentStatusURL}?userId=${userId}&limit=${limit}`;
        return this.http.get<AgentStatusLog[]>(url);
    }
}
