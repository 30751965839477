<div class="d-flex" [ngClass]="{'flex-row':isHor,'flex-column':!isHor}">
    <div class="form-group" class="{{classes}}">
        <label>WareHouse</label>
        <ng-select placeholder="Select Warehouse" [items]="warehouses | async | orderBy:'warehouse_name'" bindLabel="warehouse_name" [(ngModel)]="warehouse" bindValue="id" (change)="warehouseSelectChange($event)" (open)="warehouseSelectOpen()">
        </ng-select>
    </div>
    <div class="form-group" class="{{classes}}">
        <label>WareHouse Location</label>
        <ng-select placeholder="Select Warehouse Location" [items]="warehouseLocations | orderBy:'location'" bindLabel="location" bindValue="id" [(ngModel)]="location" (change)="warehouseLocationSelectChange($event)" [readonly]="!warehouseLocations || warehouseLocations.length <= 0">
        </ng-select>
    </div>
</div>