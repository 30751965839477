<section id="suborg-form" *ngIf="model">
    <div class="col-md-8 card p-0">
        <div class="card-header">
            <h4 class="card-title">{{suborgId ? 'Edit Sub-Org' : 'Add Sub-Org'}}</h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form role="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && submit()" novalidate>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label class="val-required">Name</label>
                                <input type="text" [(ngModel)]="model.name" class="form-control" name="name"
                                       #name="ngModel" required>
                                <small class="form-text text-muted danger"
                                       *ngIf="name.invalid && (name.dirty || name.touched)">
                                    <div *ngIf="name.errors?.required">
                                        Name is required.
                                    </div>
                                </small>
                                <small class="danger mt-1" *ngIf="model.name && nameExist">Name already exists</small>
                            </div>
                            <div class="form-group" *ngIf="isSuperAdmin">
                                <label class="val-required">Tenant</label>
                                <app-customer-select [(value)]="model.customer" [readonly]="suborgId > 0">
                                </app-customer-select>
                            </div>
                            <div class="form-group">
                                <label class="">Email</label>
                                <input type="email"
                                       [(ngModel)]="model.email"
                                       (ngModelChange)="model.email = $event.toLowerCase()"
                                       class="form-control" name="email" />
                            </div>
                            <div class="form-group">
                                <label>Phone number</label>
                                <input type="text" [(ngModel)]="model.phone_number" mask="(000) 000-0000"
                                       class="form-control"
                                       name="phone_number">
                            </div>
                        </div>
                        <div class="col-12 col-md-6" style="padding-top: 25px;height:180px">
                            <!-- <app-file-uploader #fileUploader [multiple]="false" [isActive]="false"></app-file-uploader> -->
                            <app-file-uploader [primary]="model.primary_image" [files]="itemImages" [multiple]="false" (delete)="onFileDelete($event)">
                            </app-file-uploader>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Address</label>
                                <input type="text" [(ngModel)]="model.address" class="form-control" name="address">
                            </div>
                            <div class="form-group">
                                <label>City</label>
                                <input type="text" [(ngModel)]="model.city" class="form-control" name="city">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>State</label>
                                <input type="text" [(ngModel)]="model.state" class="form-control" name="state">
                            </div>
                            <div class="form-group">
                                <label class="">Zip</label>
                                <input type="text" [(ngModel)]="model.zip" class="form-control" name="zip">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Website</label>
                                <input type="text" [(ngModel)]="model.website" class="form-control" name="website">
                            </div>
                            <div class="form-group">
                                <label>Tax ID(EIN)</label>
                                <input type="text" [(ngModel)]="model.tax_id" class="form-control" name="tax_id">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Time Zone</label>
                                <input type="text" [(ngModel)]="model.time_zone" class="form-control" name="timezone">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button type="submit" [disabled]="!formElement.form.valid"
                                    class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                {{suborgId > 0 ? 'Save Changes' : 'Save'}}
                            </button>
                            <button type="button" (click)="location.back()" class="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
