<div class="timesheet-container">

    <table class="table">
        <thead>
            <tr>
                <th scope="col" style="padding: 15px 2px;"></th>
                <th scope="col">
                    Name
                </th>
                <th scope="col">
                    Description
                </th>
                <th scope="col">
                    Mandatory
                </th>
                <th scope="col">
                    Action
                </th>
            </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
            <tr cdkDrag cdkDragLockAxis="y" *ngFor="let data of rows">
                <td cdkDragHandle style="padding: 15px 2px;"><mat-icon>control_camera</mat-icon></td>
                <td >{{data.name}}</td>
                <td [innerHTML]="data.description" class="desc_td"></td>
                <td>
                    <div class="badge"
                        [ngClass]="{'bg-light-success': data.is_mandatory,'bg-light-danger': !data.is_mandatory }">
                        {{ data.is_mandatory ? 'Yes' : 'No' }}
                    </div>
                </td>
                <td>

                    <div ngbDropdown container="body">
                        <button
                            class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                            (click)="$event.stopPropagation()" ngbDropdownToggle
                            style="border:none;background-color: white;">
                            <i class="ft-more-vertical text-primary"></i>
                        </button>
                        <div ngbDropdownMenu>
                            <button *ngIf="hasPermission(Permissions.MODIFY)"
                                (click)="open(data.id)" ngbDropdownItem>
                                <i class="ft-edit mr-1"></i> Edit
                            </button>
                            <button *ngIf="hasPermission(Permissions.DELETE)"
                                (click)="deleteRow(data.id)"
                                class="w-100 bg-light-danger" ngbDropdownItem>
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                        </div>
                    </div>

                </td>

                <!-- Preview -->
                <div *cdkDragPreview matchSize="true"
                    class="container dragdrop-placeholder" style="width: 40%;">
                    {{data.name}}
                </div>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #modalContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title"> Add New SubTask</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="form-container">
            <form #formElement="ngForm"
                (ngSubmit)="formElement.form.valid && onSubmit()">

                <div class="d-flex flex-row">
                    <div class="form-group col-md-6 p-0">
                        <label class="val-required">Name</label>
                        <input type="text" [(ngModel)]="subTask.name"
                            #name="ngModel" name="name" class="form-control"
                            required>
                        <div class="form-text text-muted danger"
                            *ngIf="name.invalid && (name.dirty || name.touched)">
                            <small *ngIf="name.errors.required">Name is
                                required.</small>
                        </div>

                    </div>

                    <div class="form-group col-md-6 pr-0">
                        <label class="title">Mandatory </label>

                        <div
                            class="custom-switch custom-switch-activator custom-switch-success custom-control">
                            <input type="checkbox"
                                [(ngModel)]="subTask.is_mandatory"
                                id="is_mandatory" name="active"
                                class="custom-control-input">
                            <label class="custom-control-label"
                                for="is_mandatory">
                                <span></span>
                            </label>
                        </div>

                    </div>
                </div>

                <div class="form-group col-md-12 p-0">
                    <label class="val">Descriptions</label>
                    <!--<input type="text" [(ngModel)]="subTask.description"
                        #name="ngModel" name="description" class="form-control" >-->

                    <div class="quil-container">
                        <quill-editor name="description"
                            [(ngModel)]="subTask.description"
                            [styles]="{'min-height': '250px'}"></quill-editor>

                    </div>

                </div>

                <div
                    class="col-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                    <button type="submit"
                        [disabled]="!formElement.form.valid"
                        class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                        Save
                    </button>
                    <button type="button" (click)="close()"
                        class="btn btn-secondary">Cancel</button>
                </div>

            </form>
        </div>
    </div>
</ng-template>
