
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { Email, InvoiceList } from '../models/invoice.models';
import { addSO, ItemAdd, ItemRow, Section, SOComments, SODashBoardCounts, SOItemAdd, SOItemsList, SOKitsAdd, SOKitsList, SOList, SOPdf } from '../models/sales-orders.models';

@Injectable({
  providedIn: 'root'
})
export class SalesOrdersService extends BaseService{

  constructor(private http: HttpClient) {
    super();
}

getAll(option: any, offset: number = 0) {
    let url = `${this.baseURL}/sales_app/sales_order/filter/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<{ count: number, result: SOList[] }>(url, option).pipe(
        catchError(() => of({ count: 0, result: [] }))
    );
}

listSC() {
    let url = `${this.baseURL}/sales_app/sales_order/`;
    return this.http.get<SOList[]>(url);
}

addSC(data) {
    let url = `${this.baseURL}/sales_app/sales_order/`;
    return this.http.post<SOList>(url, data);
}

viewSC(id: number) {
    let url = `${this.baseURL}/sales_app/sales_order/` + id + `/`;
    return this.http.get<addSO>(url);
}

updateSC(data, id) {
    let url = `${this.baseURL}/sales_app/sales_order/` + id + `/`;
    return this.http.put<{ data }>(url, data);
}

patchSC(request: any, id: number) {
    return this.http.patch(`${this.baseURL}/sales_app/sales_order/${id}/`, request);
}

getDashboardCounts() {
    let url = `${this.baseURL}/sales_app/sales_order/dashboard/`;
    return this.http.post<SODashBoardCounts>(url,null);
}




itemsAdd(data: SOItemAdd[]) {
    let url = `${this.baseURL}/sales_app/sales_order/items/`;
    return this.http.post<{ data }>(url, data);
}
itemsUpdate(data: SOItemAdd[], id: number) {
    let url = `${this.baseURL}/sales_app/sales_order/items/edit/delete/`;
    return this.http.put<{ data }>(url, data);
}

itemsList(warrantyId: number) {
    let url = `${this.baseURL}/sales_app/sales_order/` + warrantyId + `/items/`;
    return this.http.get<SOItemsList[]>(url);
}

itemDelete(id: number) {
    const options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        body: {
            id: id,
        },
    };
    // let value={"id":id};
    let url = `${this.baseURL}/sales_app/sales_order/items/edit/delete/`;
    return this.http.delete<{ data }>(url, options);
}

kitsAdd(data: SOKitsAdd[]) {
    let url = `${this.baseURL}/sales_app/sales_order/kits/`;
    return this.http.post<{ data }>(url, data);
}

kitsUpdate(data: SOKitsAdd[], id) {
    let url = `${this.baseURL}/sales_app/sales_order/kits/edit/delete/`;
    return this.http.put<{ data }>(url, data);
}

kitsList(warrantyId: number) {
    let url = `${this.baseURL}/sales_app/sales_order/` + warrantyId + `/kits/`;
    return this.http.get<SOKitsList[]>(url);
}

kitsDelete(id: number) {
    const options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        body: {
            id: id,
        },
    };
    // let value={"id":id};
    let url = `${this.baseURL}/sales_app/sales_order/kits/edit/delete/`;
    return this.http.delete<{ data }>(url, options);
}

generate_pdf(pdf: SOPdf){
    let url = `${this.baseURL}/sales_app/sales_order/generate_pdf/`;
    return this.http.post(url, {"item":pdf.item, "sections": pdf.sections}, { responseType: 'blob' });
}

downloadpdf(unique_number: string){
    let url = `${this.baseURL}/sales_app/sales_order/download_pdf/${unique_number}.pdf`;
    return this.http.get(url,{ responseType: 'blob' });
}

sendMail(email:Email, pdf: SOPdf){
    let temp_email = JSON.parse(JSON.stringify(email));
    let url = `${this.baseURL}/sales_app/sales_order/sendmail/`;
    return this.http.post(url, {"email": temp_email, "item":pdf.item, "sections": pdf.sections});
}

getEmailViewedHistory(unique_number: string){
    let url = `${this.baseURL}/automation/notification_history/${unique_number}/`;
    return this.http.get(url);
}

convertToInvoice(unique_number: string){
    let url = `${this.baseURL}/sales_app/sales_order_to_invoice/`;
    return this.http.post<{"item": number}>(url, {"unique_number": unique_number});
}

convertToServiceTicket(unique_number: string){
    let url = `${this.baseURL}/sales_app/sales_order_to_service_ticket/`;
    return this.http.post<{"item": number}>(url, {"unique_number": unique_number});
}


//Items and kits on single API

itemKitList(id:number){
    let url = `${this.baseURL}/sales_app/items/kits/covered/`+id+`/sales_order/`;
    return this.http.get<ItemRow[]>(url);
}

itemKitAdd(data:ItemAdd[]){
    let url = `${this.baseURL}/sales_app/items/kits/covered/sales_order/`;
    return this.http.post<{ data }>(url, data);
}

itemKitUpdate(data:ItemAdd[]){
    let url = `${this.baseURL}/sales_app/items/kits/covered/sales_order/edit/delete/`;
    return this.http.put<{ data }>(url, data);
}

itemKitDelete(id: number) {
    const options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        body: {
            id: id,
        },
    };
    // let value={"id":id};
    let url = `${this.baseURL}/sales_app/items/kits/covered/sales_order/edit/delete/`;
    return this.http.delete<{ data }>(url, options);
}


//Items and kits on single API



createComment(comments: SOComments[], id: number) {
    return this.http.post<SOComments[]>(`${this.baseURL}/sales_app/sales_order/comment/`, comments);
}

updateComment(id: number, comment: SOComments) {
    return this.http.put(`${this.baseURL}/sales_app/sales_order/comment/${id}/`, comment);
}

getComments(ticketId: number) {
    return this.http.get<SOComments[]>(`${this.baseURL}/sales_app/sales_order/${ticketId}/comment/`);
}

deleteComment(id: number) {
    return this.http.delete(`${this.baseURL}/sales_app/sales_order/comment/${id}/`);
}

}
