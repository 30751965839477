import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import { CallCenterService } from '../../services/call-center.service';

@Component({
  selector: 'incoming-calls-info',
  templateUrl: './incoming-calls-info.component.html',
  styleUrls: ['./incoming-calls-info.component.scss']
})
export class IncomingCallsInfoComponent implements OnInit {

  @Input() session;

  public callDuration = new BehaviorSubject('');

  public callStartTime: Date;

  public timerInterval: any;

  public Subs: SubSink = new SubSink();

  public contact_name: string = '';

  constructor(private cdr: ChangeDetectorRef, public callCenterService: CallCenterService) { }

  ngOnInit(): void {
    this.startTimer();

    let callId = this.session.request.headers['X-Signalwire-Callsid'] ? this.session.request.headers['X-Signalwire-Callsid'][0].raw
      : null;
    if (callId) {
      this.Subs.sink = this.callCenterService.getCallDetails(callId).subscribe(response => {
        if (response.status) {

          this.contact_name = response.contact_name || null;
        } else {

          let toNumber = this.session.request.headers['X-Dialed-Number'][0].raw || null;
          let fromNumber = this.session.remoteIdentity.uri.user;


          this.Subs.sink = this.callCenterService.getNonCallCenterDetails(toNumber, fromNumber).subscribe(response => {

            this.contact_name = response.contact_name || null;
            this.cdr.markForCheck();
          });
        }
        this.cdr.markForCheck();
      });
    }



  }

  callerNumber(session) {//return caller number
    return session.remoteIdentity.uri.user;
  }

  callerName(session) {//return caller name
    return this.contact_name; //session.customValues?.callerName;
  }

  startTimer() {
    this.callStartTime = new Date();
    this.timerInterval = setInterval(() => {
      const currentTime = new Date();
      const elapsedSeconds = Math.floor((currentTime.getTime() - this.callStartTime.getTime()) / 1000);
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds % 60;
      let time = `${minutes}min${minutes !== 1 ? 's' : ''} ${seconds}second${seconds !== 1 ? 's' : ''}`;

      this.callDuration.next(time);
      this.cdr.markForCheck();
    }, 1000);
  }

}
