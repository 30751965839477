import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { LocationType } from '../../../models/location-types';
import { LocationTypeService } from '../../../services/location-type.service';

@Component({
  selector: 'location-type-form',
  templateUrl: './location-type-form.component.html',
  styleUrls: ['./location-type-form.component.scss']
})
export class LocationTypeFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('name', { static: false, read: ElementRef }) nameControl: ElementRef;

  public model: LocationType = new LocationType();

  @Input() locationTypeId: number = 0;

  @Output() success = new EventEmitter<{ response: LocationType, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private service: LocationTypeService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.locationTypeId > 0) {
      this.getSub$ = this.service.getById(this.locationTypeId).subscribe(LocationType => {
        this.model = LocationType;
      });
    }
  }

  ngAfterViewInit(): void {
    this.nameControl?.nativeElement.focus();
  }

  onSubmit(): void {

    let api: Observable<LocationType>;

    if (this.locationTypeId > 0)
      api = this.service.update(this.locationTypeId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;
      api = this.service.create(this.model);
    }

    this.postSub$ = api.subscribe(
      response => {
        let msg = this.locationTypeId > 0 ? 'Location Type info updated' : 'New Location Type created';
        this.nameExist = false;
        this.success?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void { this.cancel?.emit(); }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
    this.postSub$?.unsubscribe();
  }
}
