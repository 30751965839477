import { SubSink } from 'subsink';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Priority } from '../../../service-ticket/models/common.models';
import { PriorityService } from '../../../service-ticket/services/priority.service';

@Component({
  selector: 'priority-form',
  templateUrl: './priority-form.component.html',
  styleUrls: ['./priority-form.component.scss']
})
export class PriorityFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('name', { static: false, read: ElementRef }) nameControl: ElementRef;

  public model: Priority = new Priority();

  @Input() suborg_id: number = 0;

  @Input() bindingModel: Priority;

  @Input() uniqueId: number = 0;

  @Output() success = new EventEmitter<{ response: Priority, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  public subs: SubSink = new SubSink();

  constructor(private service: PriorityService, private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void { this.nameControl?.nativeElement.focus(); }

  ngOnInit() {
    if (this.uniqueId > 0) {
        if (this.bindingModel) {
            this.model = { ... this.bindingModel };
        }
        else{
            this.subs.sink = this.service.getById(this.uniqueId).subscribe(resp => {
            this.model = resp;
            this.cdr.markForCheck();
            });
        }
    }
    else{
        this.model = this.service.createModel(Priority)

        if(this.suborg_id > 0){
          this.model.suborg = this.suborg_id;
        }
    }
  }

  submit(): void {

    let api: Observable<Priority>;

    if (this.uniqueId > 0)
      api = this.service.update(this.uniqueId, this.model);
    else {
      this.model.customer = this.service.customerId;
      api = this.service.create(this.model);
    }

    this.subs.sink = api.subscribe(
      response => {
        let msg = this.uniqueId > 0 ? 'Priority info updated' : 'New Priority created';
        this.success?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void {
    this.cancel?.emit();
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
