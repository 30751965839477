<form *ngIf="model" name="form" #userForm="ngForm" (ngSubmit)="userForm.form.valid && userFormOnSubmit()" novalidate>
    <div class="row">
        <div class="col-12">
            <div class="d-flex">
                <div class="col-12 col-md-6 col-lg-6 pl-0 form-group">
                    <label class="val-required">First Name</label>
                    <input type="text" [(ngModel)]="model.first_name" #fn="ngModel" name="first_name"
                           class="form-control"
                           required>
                    <small class="form-text text-muted danger" *ngIf="fn.invalid && (fn.dirty || fn.touched)">
                        <div *ngIf="fn.errors.required">
                            First name is required.
                        </div>
                    </small>
                </div>
                <div class="col-12 col-md-6 col-lg-6 p-0 form-group">
                    <label>Last Name</label>
                    <input type="text" [(ngModel)]="model.last_name" name="last_name" class="form-control">
                </div>
            </div>
            <div class="form-group">
                <label>DOB</label>
                <app-date-picker [minDate]="{year:1900 , month: 1, day: 1}" [(value)]="model.date_of_birth">
                </app-date-picker>
            </div>
            <div class="form-group">
                <label class="val-required">E-mail</label>
                <div class="d-flex email-row justify-content-start" *ngIf="!editEmail">
                    <div>{{model.email}}</div>
                    <div class="ml-1">
                        <i class="ft ft-edit-2" *ngIf="(this.isSuperAdmin||this.isAdmin)&&!isReadOnly('email')&&currentUserEmail!=model.email" (click)="makeEmailEditable()"></i>
                    </div>
                </div>
                <input type="email" [(ngModel)]="model.email" #em="ngModel" name="email"
                       *ngIf="editEmail"
                       (ngModelChange)="model.email = $event.toLowerCase()"
                       autocomplete="off" class="form-control" email required>
                <small class="form-text text-muted danger" *ngIf="em.invalid && (em.dirty || em.touched)">
                    <div *ngIf="em.errors.required">
                        Email is required.
                    </div>
                    <div *ngIf="em.errors?.email">
                        Please enter a valid email address.
                    </div>
                </small>
                <small class="form-text text-muted danger mt-1" *ngIf="emailExists">This email address already in use</small>
            </div>
            <div class="d-flex">
                <div class="col-12 col-md-6 col-lg-6 pl-0 form-group">
                    <label>Phone</label>
                    <div class="position-relative has-icon-left">
                        <input type="text" [(ngModel)]="model.phone_number" mask="(000) 000-0000" name="phone" class="form-control">
                        <div class="form-control-position">
                            <i class="ft-phone"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 p-0 form-group">
                    <label>Mobile</label>
                    <div class="position-relative has-icon-left">
                        <input type="text" [(ngModel)]="model.mobile"  mask="(000) 000-0000" name="mobile" class="form-control">
                        <div class="form-control-position">
                            <i class="ft-smartphone"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="val-required">Time zone</label>
                <!-- <input type="email" [(ngModel)]="model.time_zone" #em="ngModel" name="timezone"
                       [readonly]="isReadOnly('timezone')"
                       (ngModelChange)="model.email = $event.toLowerCase()"
                       autocomplete="off" class="form-control" email required> -->

                       <ng-select [items]="timezones" class="" placeholder="Select timezone" required [(ngModel)]="model.time_zone" #em="ngModel" name="timezone" [readonly]="isReadOnly('timezone')"></ng-select>
                <small class="form-text text-muted danger" *ngIf="em.invalid && (em.dirty || em.touched)">
                    <div *ngIf="em.errors.required">
                        Timezone is required.
                    </div>
                    <div *ngIf="em.errors?.time_zone">
                        Please timezone
                    </div>
                </small>
                <!-- <small class="form-text text-muted danger mt-1" *ngIf="emailExists">This email address already in use</small> -->
                <div class="checkbox mt-2 ml-2">
                    <input type="checkbox" class="mr-1" id="auto-update" [(ngModel)]="model.time_zone_auto_update" name="auto-update"> 
                    <label for="auto-update" class="text-capitalize">Auto update local timezone everytime I login</label>
                </div>
                <small class="text-danger ml-3" *ngIf="model?.time_zone_auto_update"><i>(You must log out and log back in for this feature to work.)</i></small>
                
            </div>
            <div class="form-group" *ngIf="!userId">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" [(ngModel)]="model.generate_password"
                           name="gen_password" id="custom-check-1">
                    <label class="custom-control-label" for="custom-check-1">
                        <span>Generate Temporary Password</span>
                    </label>
                </div>
            </div>
            <div class="form-group" *ngIf="!isHidden('password') && !model.generate_password">
                <label class="val-required">Password</label>
                <input type="password" [(ngModel)]="model.password" #pw="ngModel" name="password" class="form-control"
                       autocomplete="off" [required]="!model.generate_password">
                <small class="form-text text-muted danger" *ngIf="pw.invalid && (pw.dirty || pw.touched)">
                    <div *ngIf="pw.errors.required">
                        Password required.
                    </div>
                </small>
            </div>
            <div class="form-group" *ngIf="isSuperAdmin && !isHidden('customer')">
                <label class="val-required">Tenant</label>
                <app-customer-select [(value)]="model.customer[0]"></app-customer-select>
            </div>
            <div class="form-group">
                <label class="val-required">Role</label>
                <app-role-select [(value)]="model.role" [customerId]="model.customer[0]" [readonly]="isReadOnly('role')"></app-role-select>
            </div>
            <div class="d-flex">
                <div class="col-12 col-md-6 col-lg-6 pl-0 form-group d-flex align-items-center" *ngIf="!isHidden('is_active')">
                    <label class="m-0 mr-2">Status :</label>
                    <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                        <input type="checkbox" [(ngModel)]="model.is_active" name="is_active" class="custom-control-input"
                               id="color-switch-3" checked>
                        <label class="custom-control-label mr-1" for="color-switch-3">
                            <span></span>
                        </label>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 pl-0 form-group">
                    <label class="m-0 mr-2">Pick user color :</label>
                    <span
                        class="change-me btn btn-sm form-group"
                        [cpPosition]="'top'"
                        [cpPositionOffset]="'50%'"
                        [cpPositionRelativeToArrow]="true"
                        [cpOKButton]="true"
                        [cpCancelButton]="true"
                        [cpSaveClickOutside]="false"
                        [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                        [(colorPicker)]="model.color"
                        [style.background]="model.color">
                    </span>
                </div>
            </div>


            <div class="group1">
                <div class="d-flex">
                    <div class="col-12 col-md-6 col-lg-6 pl-0 form-group d-flex align-items-center" >
                        <label class="m-0 mr-2">VoiceMail Enabled :</label>
                        <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                            <input type="checkbox" [(ngModel)]="model.is_voicemail_enabled" name="is_voice_mail" class="custom-control-input"
                                id="color-switch-6" checked>
                            <label class="custom-control-label mr-1" for="color-switch-6">
                                <span></span>
                            </label>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 pl-0 form-group d-flex align-items-center" *ngIf="model.is_voicemail_enabled">
                        <label class="m-0 mr-2">VoiceMail Send :</label>
                        <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                            <input type="checkbox" [(ngModel)]="model.is_voicemail_send" name="is_mail_send" class="custom-control-input"
                                id="color-switch-5" checked>
                            <label class="custom-control-label mr-1" for="color-switch-5">
                                <span></span>
                            </label>
                        </div>
                    </div>


                </div>  
                <div class="form-group" *ngIf="model.is_voicemail_enabled">
                    <label class="m-0 mr-2">VoiceMail Voice :</label>
                    <ng-select
                        name="voicemail_voice"
                        placeholder="Select voice"
                        [items]="voices | async"
                        bindLabel="name"
                        bindValue="value"
                        [(ngModel)]="model.voicemail_voice">
                    </ng-select>
                    <!--<ng-select  placeholder="Select a Type" class="form-select " [ngModelOptions]="{standalone: true}"  [(ngModel)]="model.voicemail_voice" >
                        
                        <ng-option value="male">Male</ng-option>
                        <ng-option value="female">Female</ng-option>
                    </ng-select>-->
                </div>

                <div class="form-group " *ngIf="model.is_voicemail_enabled">
                        <label>Message</label>
                        <textarea [(ngModel)]="model.voicemail_message" name="voicemail_message" class="form-control"></textarea>
                </div> 
            </div>               



            <div class="form-group"></div>


            <div class="d-flex mb-2">

                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [(ngModel)]="model.deactivate"
                            name="deactivate" id="deactivate">
                        <label class="custom-control-label" for="deactivate">
                            <span>Deactivate User</span>
                        </label>
                    </div>
                </div>
            </div>
            
            <div class="d-flex" *ngIf="model.deactivate">
                <div class="form-group col-6 col-md-6 pl-0">
                    <label class="val-required">Deactivation Date</label>
                    <app-date-picker
                        [(value)]="model.deactivation_date"
                        [valueFormat]="'yyyy-MM-dd hh:mm:ss'">
                    </app-date-picker>
                </div>
                <div class="form-group col-6 col-md-6 p-0">
                    <timepicker
                    [rangePicker]="false"
                    label="Time"
                    [(value)]="timeModel"
                    (change)="pickerChanged($event)">
                    </timepicker>
                </div>
            </div>
            
            <!-- <div class="form-group d-flex align-items-center" *ngIf="!isHidden('is_active')">
                <label class="m-0 mr-2">Status :</label>
                <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                    <input type="checkbox" [(ngModel)]="model.is_active" name="is_active" class="custom-control-input"
                           id="color-switch-3" checked>
                    <label class="custom-control-label mr-1" for="color-switch-3">
                        <span></span>
                    </label>
                </div>
            </div> -->

            
             
        </div>
        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <a type="button" (click)="onCancel()" class="btn btn-secondary mr-1">Cancel</a>
            <button type="submit" [disabled]="!userForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0">
                {{userId > 0 ? 'Save Changes' : 'Create'}}
            </button>
        </div>
    </div>
</form>
