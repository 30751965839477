import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { AccountType, CustomerCard, CustomerCheck, PaymentMethods, PaymentRequest, PaymentValidation } from '../../models/payments-model';
import { SubSink } from 'subsink';
import { CardsService } from '../../services/cards.service';
import { PaymentsService } from '../../services/payments.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { MakePaymentComponent } from '../../pages/make-payment/make-payment.component';
import { CurrencyPipe } from '@angular/common';
import { AccountingNewPayment } from 'src/app/modules/accounting/models/accounting-model';
import { AccountingService } from 'src/app/modules/accounting/services/accounting.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() myCards: CustomerCard[];
  @Input() myChecks: CustomerCheck[];
  @Input() default_card?:CustomerCard;
  @Input() default_check?:CustomerCheck;

  // @Input() amount:number;

  private _amount: number;

  get amount() {

    return this._amount ? this._amount : 0;
  }

  @Input() set amount(amount: number) {
    if (amount != null) {
      this._amount = amount;
    }
  }

  @Input() mobile: number;
  @Input() email: string;
  @Input() invoiceUrlToken?: string;
  @Input() invoiceId?: string;
  @Input() model?: AccountingNewPayment;
  @Input() context;

  @Output() onSuccess = new EventEmitter<{ response: any }>();
  @Output() onError = new EventEmitter<any>();

  public subs: SubSink = new SubSink();

  public paymentReqModel: PaymentRequest;

  public validationsErrors: PaymentValidation = new PaymentValidation();

  public over_payment_amount: number = 0;

  selectedCardId:number =0;

  selectedCheckId:number =0;

  paymentMethods = PaymentMethods;

  selectedPaymentMethode:string = this.paymentMethods.CARD;

  accountTypes = AccountType;

  constructor(private cardServices: CardsService,
    private services: PaymentsService,
    private cdr: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private accountingServices: AccountingService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.paymentReqModel = new PaymentRequest();
   
    if(this.default_card){
      this.selectedCardId = this.default_card.id;
      this.cardSelected(this.default_card);
    }

  }

  cardSelected(selectedCard: CustomerCard) {
    this.validationsErrors = new PaymentValidation();
    if (selectedCard) {
      //this.paymentReqModel.token = selectedCard.token;
      this.getCardDetails(selectedCard.id)

    } else {
      this.paymentReqModel = new PaymentRequest();

    }
  }

  getCardDetails(cardId: number) {

    this.subs.sink = this.cardServices.getCardDetailsById(cardId).subscribe(
      resp => {

        this.paymentReqModel.card_number = resp.masked_card_number;
        this.paymentReqModel.expiry_date = resp.expiry_date;
        this.paymentReqModel.name = resp.name;
        this.paymentReqModel.token = resp.token;
        this.paymentReqModel.zip_code = resp.zip;
        this.cdr.markForCheck();
      },
      err => {
      }
    );

  }

  checkSelected(selectedCheck: CustomerCheck) {
    this.validationsErrors = new PaymentValidation();
    if (selectedCheck) {
      //this.paymentReqModel.token = selectedCard.token;
      this.getCheckDetails(selectedCheck.id)

    } else {
      this.paymentReqModel = new PaymentRequest();

    }
  }

  getCheckDetails(checkId: number) {

    this.subs.sink = this.cardServices.getCardDetailsById(checkId).subscribe(
      resp => {

        this.paymentReqModel.account = resp.masked_account_number;
        this.paymentReqModel.routing = resp.masked_routing_number;
        this.paymentReqModel.account_type = resp.account_type;
        this.paymentReqModel.name = resp.name;
        this.paymentReqModel.token = resp.token;
        this.paymentReqModel.zip_code = resp.zip;
        this.cdr.markForCheck();
      },
      err => {
      }
    );

  }

  pay() {

    let validAmount = this.context.checkIsValidAmount();

    let isValid = validAmount && this.checkValidation()
    if (isValid) {
      
      this.over_payment_amount = this.context.getOverPaidAmount();

      let opt = {
        title: `If you proceed you will charge ${this.currencyPipe.transform(this.amount, 'USD')} ?`,
        text: 'Do not refresh or close while processing the payment',
        ButtonText: "Proceed",
      };
      popup.ConfirmWithButtonText(opt, result => {
        if (result.isConfirmed) {
          this.paymentReqModel.amount = Number(this.amount);
          this.paymentReqModel.mobile = this.mobile ? this.mobile : null;
          this.paymentReqModel.email = this.email ? this.email : null;
          this.paymentReqModel.over_payment_amount = this.over_payment_amount;
          this.paymentReqModel.payment_way = this.selectedPaymentMethode;

          console.log(this.paymentReqModel,'for pay')

          if(this.invoiceUrlToken){

          this.subs.sink = this.services.payNow(this.paymentReqModel, this.invoiceUrlToken).subscribe(
            resp => {
              this.onSuccess?.emit(resp);
            },
            err => {
              this.onError?.emit(err);
            }
          );

        }

        else if(this.invoiceId){

          this.paymentReqModel.payment_method = this.selectedPaymentMethode;
          this.subs.sink = this.services.makePayment(this.paymentReqModel, this.invoiceId).subscribe(
            resp => {
              this.onSuccess?.emit(resp);
            },
            err => {
              this.onError?.emit(err);
            }
          );

        }else if(this.model){

          let url = this.activatedRoute.snapshot.url.join().split(',');
          let customerId = url[1];
          
          this.paymentReqModel.payment_method = this.selectedPaymentMethode;
          this.subs.sink = this.accountingServices.accountingMakePayment(this.paymentReqModel, this.model, customerId).subscribe(
            resp => {
              this.onSuccess?.emit(resp);
            },
            err => {
              this.onError?.emit(err);
            }
          );

        }

        }

      });
    }

  }

  checkValidation() {

    if(this.selectedPaymentMethode == this.paymentMethods.CARD){

    if (!this.paymentReqModel.card_number) {

      this.validationsErrors.number = true; return false;
    } else {
      this.validationsErrors.number = false;
    }

    if (!this.paymentReqModel.expiry_date) {

      this.validationsErrors.exp = true; return false;
    } else {
      this.validationsErrors.exp = false;
    }

    if (!this.paymentReqModel.name) {

      this.validationsErrors.name = true; return false;
    } else {
      this.validationsErrors.name = false;
    }

    if (!this.paymentReqModel.cvv) {

      this.validationsErrors.cvv = true; return false;
    } else {
      this.validationsErrors.cvv = false;
    }

  }

  else if(this.selectedPaymentMethode == this.paymentMethods.ACH){

    if (!this.paymentReqModel.account) {

      this.validationsErrors.account = true; return false;
    } else {
      this.validationsErrors.account = false;
    }

    if (!this.paymentReqModel.routing) {

      this.validationsErrors.routing = true; return false;
    } else {
      this.validationsErrors.routing = false;
    }

    if (!this.paymentReqModel.name) {

      this.validationsErrors.name = true; return false;
    } else {
      this.validationsErrors.name = false;
    }

    if (!this.paymentReqModel.account_type) {

      this.validationsErrors.account_type = true; return false;
    } else {
      this.validationsErrors.account_type = false;
    }

  }

    return true;

  }

  changePaymentMethode(methode:string){

    this.paymentReqModel = new PaymentRequest();
    this.selectedPaymentMethode = methode;

    if(methode == this.paymentMethods.ACH && this.default_check){
      this.selectedCheckId = this.default_check.id;
      this.checkSelected(this.default_check);
    }

    if(methode == this.paymentMethods.CARD && this.default_card){
      this.selectedCardId = this.default_card.id;
      this.cardSelected(this.default_card);
    }
  }

}
