import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { TenantCustomer } from 'src/app/modules/tenant-customers/models/tenant-customer';
import { SubSink } from 'subsink/dist/subsink';
import { WarrantyService } from '../../../services/warranty.service';
import { WarrantyDataHelper } from '../../../helpers/warranty-data.helper';
import { SelectionType } from '@swimlane/ngx-datatable';
import { addWarranty, WarrantyList } from '../../../models/warranty.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { ModuleConstants } from 'src/app/core/enums/common.enum';

@Component({
  selector: 'warranty-select',
  templateUrl: './warranty-select.component.html',
  styleUrls: ['./warranty-select.component.scss']
})
export class WarrantySelectComponent implements OnInit {

  @ViewChild('modalElement') selectModal: any;

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;


  private _value: number;

  get value(): number { return this._value; }

  @Input() set value(val: number) {

    this._value = val;
    if (this.value > 0 && (!this.selected || this.selected.id !== val)) {
      this.setValue(val);
    }

  };

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<WarrantyList>();

  @Output('default') defaultSetEvent = new EventEmitter<WarrantyList>();

  @Input() clear = new EventEmitter();

  @Input() reset:boolean=false;

  @Input() selectBox: boolean = true;

  @Input() readonly: boolean;

  @Input() tenentCustomerId:number;


  public SelectionType = SelectionType;

  // public fileUrl: string = environment.fileURL;

  public items: WarrantyList[];

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = WarrantyDataHelper.defaults;

  public search: GFilterParam[] = WarrantyDataHelper.search;

  public columns: GFColumn[] = WarrantyDataHelper.columns;

  public sortLookup = WarrantyDataHelper.sortLookup;

  public selectedItem: WarrantyList[] = [];

  public selected: WarrantyList;

  public sort: GSortParam[] = [{ colname: 'title', direction: 'asc' }];

  private modalRef: NgbModalRef;

  public scrollbarH: boolean = false;

  private subs: SubSink = new SubSink();

  public module: ModuleConstants = ModuleConstants.WARRANTY;

  public coverageType = { 1: "Meterial only", 2: "Labor only", 3: "Meterial & Labor", };

  constructor(private modal: NgbModal,
    private WarrantyService: WarrantyService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService) {

    this.scrollbarH = this.device.isMobile();
  }

  ngOnInit() {
    if(this.reset){
      
    }
   }

  setValue(id: number) {
    this.subs.sink = this.WarrantyService.getById(id).subscribe(item => {
      this.selected = item;
      this.defaultSetEvent?.emit(item);
      this.selectedItem.push(item);
      this.cdr.markForCheck();
    });
  }

  open() {
    this.modalRef = this.modal.open(this.selectModal, { size: 'xl', scrollable: true });
    if (!this.items || this.items.length <= 0) {
      this.getData(null);
    }
    else if(this.reset){
      this.items=[];
    }
  }

  getData(filters: GFilterParam[], offset: number = 0) {
    filters=[{colname: "tenant_customer", condition: "equalto", value: this.tenentCustomerId, operator: "AND"}]
    let option = { sort: this.sort, filters: filters };
    this.subs.sink = this.WarrantyService.getAll(option, offset).subscribe(response => {
      this.items = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  setPage(pageInfo: any, dataFilter: DataGridFilterComponent) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(dataFilter?.getDataFilters(), offset);
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  onSort(event: any, dataFilter: DataGridFilterComponent) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(dataFilter?.getDataFilters());
    }
  }

  onCanceled() { this.modalRef?.dismiss(); }


  onItemSelected() {
    if (this.selectedItem && this.selectedItem.length > 0) {
      let item = this.selectedItem[0];
      this.selected = item;
      this.valueChange?.emit(item.id);
      this.change?.emit(item);
    }
    this.modalRef?.dismiss();
  }

  clearSelection(event: any) {
    event.stopPropagation();
    this.selected = null;
    this.valueChange?.emit(undefined);
    this.clear?.emit();
    this.selectedItem = [];
  }

  statusClass(id: number) {
    let status = { "Active": "wty-status-active", "Pending": "wty-status-pending", "Draft": "wty-status-draft", "Expired": "wty-status-expired" };
    return status[id];
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
