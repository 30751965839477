<section class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
    <div class="d-flex flex-row flex-1 h-100">
        <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
            <div class="d-flex align-items-center flex-space-bw" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">


                <!-- [newButton]="UIPermissions.create"
                [newButtonUrl]="['/inventory/items/new']" -->


                <div class="col-xs-12">
                    <h4 class="card-title title-common m-0">Additional Fields</h4>
                </div>
                <div class="d-flex flex-row col-xs-12">
                    <button *ngIf="UIPermissions.create" (click)="openModal(additionalFieldModal)" class="btn btn-sm btn-text-center gradient-pomegranate mr-1" disableIfAllsuborg>
                            <i class="ft-plus mr-1"></i> New
                        </button>
                    <!-- <input #searchInput *ngIf="expanded" class="form-control form-control-sm width-200" type="text" placeholder="Search warehouse..." /> -->
                </div>
            </div>
            <div class="card-content">
                <div [ngClass]="{'card-body':expanded}">

                    <!-- {{rows|json}} -->
                    <ngx-datatable #dataTable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" (select)="onSelect($event)" [sorts]="[{prop:'warehouse_name',dir:'asc'}]">
                        <ngx-datatable-column name="Additional field" prop="additional_field.field_name"></ngx-datatable-column>
                        <!-- <ngx-datatable-column name="Description" prop="description" [sortable]="false"></ngx-datatable-column> -->
                        <ngx-datatable-column *ngIf="expanded" name="Created Date" prop="additional_field.created_on">
                            <ng-template let-crdate="value" ngx-datatable-cell-template>
                                {{crdate | date:'MM/dd/YYYY'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded" name="Created By" prop="additional_field.created_by"></ngx-datatable-column>
                        <ngx-datatable-column *ngIf="expanded && !isAllSuborg" name="Action" prop="id" [sortable]="false">
                            <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                <div ngbDropdown container="body">
                                    <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                        <i class="ft-more-vertical text-primary"></i>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <button class="w-100" *ngIf="UIPermissions.edit" (click)="openModal(additionalFieldModal,current.additional_field.id)" ngbDropdownItem>
                                            <i class="ft-edit mr-1"></i> Edit
                                        </button>
                                        <button class="w-100 bg-light-danger" *ngIf="UIPermissions.delete" (click)="delete(current.additional_field.id)" ngbDropdownItem>
                                            <i class="ft-trash-2 mr-1"></i> Delete
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                                <div style="padding: 0 .7rem;">
                                    <span>{{rowCount}} Total</span>
                                </div>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
        <div class="parellax-detail" *ngIf="!expanded && selected">
            <div class="parellax-detail-content">
                <div class="warehouse-details">
                    <div class="d-flex justify-content-between flex-row mt-2">
                        <h5 class="item-title m-0">{{selected?.additional_field.field_name}}</h5>
                        <div class="d-flex justify-content-end align-items-start">
                            <a *ngIf="UIPermissions.edit && !isAllSuborg" (click)="openModal(additionalFieldModal,selected.additional_field.id)" class="btn btn-sm bg-light-secondary">
                                <i class="ft-edit"></i> Edit
                            </a>
                            <button *ngIf="UIPermissions.delete && !isAllSuborg" class="btn btn-sm bg-light-danger ml-1" (click)="delete(selected.additional_field.id)">
                                <i class="ft-trash-2 mr-1"></i> Delete
                            </button>
                            <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="group mt-2">
                        <div class="group-header">
                            Overview
                        </div>
                        <div class="group-body">
                            <div class="ov-row" *ngIf="selected.additional_field.created_on">
                                <label class="col-md-3 p-0 item-label">Created On</label>
                                <label class="item-label-value">{{selected.additional_field.created_on | date:'MM/dd/YYYY'}}</label>
                            </div>
                            <div class="ov-row" *ngIf="selected?.additional_field.created_user_id">
                                <label class="col-md-3 p-0 item-label">Created By</label>
                                <label class="item-label-value">{{selected.additional_field.created_user_id}}</label>
                            </div>
                            <div class="ov-row" *ngIf="selected?.additional_field.description">
                                <label class="col-md-3 p-0 item-label">Description</label>
                                <label class="item-label-value">{{selected.additional_field.description}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="group mt-2">
                        <div class="group-header">
                            Values
                        </div>
                        <div class="group-body">
                            <ngx-datatable 
                                class="bootstrap core-bootstrap"
                                [selectionType]="SelectionType.single"
                                rowHeight="auto"
                                [headerHeight]="50" 
                                [footerHeight]="50" 
                                [columnMode]="'force'"
                                [scrollbarH]="scrollbarH"
                                [rows]="selected.values"
                            >
                            <ngx-datatable-column name="Value" prop="field_value"></ngx-datatable-column>
                            <ngx-datatable-column name="Created Date" prop="created_on">
                                <ng-template let-crdate="value" ngx-datatable-cell-template>
                                    {{crdate | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Created By" prop="created_by"></ngx-datatable-column>

                            </ngx-datatable>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>



    <ng-template #additionalFieldModal let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Additional Field</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-additional-field-form  
                [value]="model"
                (onSuccess)="onDataSuccess($event)" 
                (onError)="onDataError($event)" 
                (onCancel)="onCancel()">
            </app-additional-field-form>
        </div>
    </ng-template>

</section>
