import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StaticFile } from 'src/app/core/models/files.models';
import { GFilterParam, DataFilterRequest, GSortParam, Pagination, GFColumn, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { PaymentDetails, PaymentMethods, PaymentReccord } from '../../models/payments-model';
import { PaymentsService } from '../../services/payments.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accounting-payments-list',
  templateUrl: './accounting-payments-list.component.html',
  styleUrls: ['./accounting-payments-list.component.scss']
})
export class AccountingPaymentsListComponent implements OnInit, OnDestroy {

  

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ViewChild('dataTable') dataTable:DatatableComponent;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = [
      { displayName: "All Payments", colname: '', condition: 'is', value: null, operator: 'AND' },
     
  ];

  public searchFilters: GFilterParam[] = [
      {
          displayName: 'Combo Search', colname: '', condition: '', value: '',
          children: [
              { displayName: 'Customer', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
              { displayName: 'Suborg', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
              { displayName: 'Payment Method', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
          ]
      }
  ];

  public columns: GFColumn[] = [
      { displayName: 'Customer', columnName: 'tenant_customer_name', columnType: GFColumnTypes.string },
      { displayName: 'Suborg', columnName: 'suborg_name', columnType: GFColumnTypes.string },
    //   { displayName: 'Payment Method', columnName: 'name', columnType: GFColumnTypes.string },
    //   { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
    //   { displayName: 'Email', columnName: 'email', columnType: GFColumnTypes.string },
    //   { displayName: 'Phone', columnName: 'mobile', columnType: GFColumnTypes.string },
      { displayName: 'Amount', columnName: 'payment_amount', columnType: GFColumnTypes.number },
      { displayName: 'Date', columnName: 'created_on', columnType: GFColumnTypes.date },
  ];

  public rows: PaymentReccord[];

  public expanded: boolean = true;

  public selected: PaymentDetails;

  public suborgId: number;

  public SelectionType = SelectionType;

  private subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public isSuperAdmin: boolean;

  public sort: GSortParam[];

  public sortLookup = {
      name: "name",
      email: "email",
      address: "address",
      phone_number: "phone_number",
      created_on: "created_on",
      customer_name: "customer_name",
      created_by: "created_by"
  };
  itemImages: StaticFile[];

  public paymentMethods = PaymentMethods;

  public fileUrl: string = environment.apiURL + '/static/files/';

  constructor(private paymentService: PaymentsService,
      private device: DeviceDetectorService,
      private cdr: ChangeDetectorRef) {

      this.isSuperAdmin = paymentService.isSuperUser;
  }

  ngOnInit(): void {
      this.scrollbarH = this.device.isMobile();
      this.getData(null);
      if (this.isSuperAdmin) {
          this.columns.unshift({ displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref })
      }
  }

  ngAfterViewChecked() {
    if (this.dataTable && this.dataTable.rowDetail) {
      this.dataTable.rowDetail.expandAllRows();
      this.cdr.detectChanges();
    }
  }

  getData(filters: GFilterParam[], offset: number = 0) {

      let request: DataFilterRequest = { filters: filters, sort: this.sort }

      this.subs.sink = this.paymentService.getFiltered(request, offset, this.page.limit).pipe(
          catchError(err => of({ count: 0, result: [] }))
      ).subscribe(response => {
          this.rows = response.result;
          this.setPagination(offset, response.count);
          this.cdr.markForCheck();
      });
  }

  setPagination(offset: number, total: number) {
      this.page.count = total;
      let upperLimit = offset + this.page.limit;
      if (upperLimit > total) {
          upperLimit = total;
      }
      this.page.pageNumber = offset + '-' + upperLimit;
      if (!offset) {
          this.page.offset = 0;
      }
  }

  onSelect({ selected }) {
      let current: PaymentReccord = selected[0];
      if (current) {
         
          this.subs.sink = this.paymentService.getPaymentDetailsById(current.id).subscribe(
            resp => {
              
                this.selected = resp;
                this.expanded = false;
                this.refreshDataTable();
            }
          );


          
      }
  }

 

  closeDetailedView() {
      this.expanded = true;
      this.selected = null;
      this.refreshDataTable();
  }

  refreshDataTable() {
      setTimeout(() => {
          this.rows = [...this.rows];
          this.cdr.markForCheck();
      });
  }

  setPage(pageInfo: any) {
      let offset = pageInfo.offset * this.page.limit;
      this.page.offset = pageInfo.offset;
      this.getData(this.dataFilter.getDataFilters(), offset);
  }

  onSort(event: any) {
      if (event.sorts && event.sorts.length > 0) {
          let current = event.sorts[0];
          if (!this.sortLookup[current.prop]) return;
          let sort = new GSortParam();
          sort.colname = this.sortLookup[current.prop];
          sort.direction = current.dir;
          this.sort = [sort];
          this.getData(this.dataFilter.getDataFilters());
      }
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

}
