<datagrid-filter #dataGridFilter [searchFilters]="searchFilters" [columns]="columns" [newButton]="true" [newButtonText]="'Add'" (newButtonClick)="onNewButtonClick()" (apply)="applyFilter($event)">
    <ng-template filterControl [column]="'created_user'" let-row="row">
        <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
    </ng-template>
    <ng-template filterControl [column]="'distributor'" let-row="row">
        <distributor-select class="ng-select-sm" [(value)]="row.value"></distributor-select>
    </ng-template>
    <ng-template filterControl [column]="'distributor_type'" let-row="row">
        <customer-type-select class="ng-select-sm" [(value)]="row.value" [addButton]="false"></customer-type-select>
    </ng-template>
</datagrid-filter>

<div class="dt-container mt-2">
    <ngx-datatable #dataTable class="bootstrap core-bootstrap" [scrollbarH]="false" [headerHeight]="40" rowHeight="auto" [footerHeight]="50" [columnMode]="'force'" [rows]="rows" [selectionType]="SelectionType.single" (select)="onSelect($event)" [count]="page.count"
        [sorts]="[{prop:'distributor_name',dir:'asc'}]" [externalPaging]="true" [offset]="page.offset" [externalSorting]="true" [limit]="page.limit" (page)="setPage($event)" (sort)="onSort($event)">
        <ngx-datatable-column name="Distributor Name" prop="distributor_name"></ngx-datatable-column>
        <ngx-datatable-column name="Distributor Type" prop="distributor_type" [sortable]="false"></ngx-datatable-column>
        <ngx-datatable-column name="Location" prop="distributor_location_name"></ngx-datatable-column>
        <ngx-datatable-column name="Created Date" prop="created_on" [sortable]="false">
            <ng-template let-crdate="value" ngx-datatable-cell-template>
                {{crdate | date:'MM/dd/YYYY'}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created By" prop="created_by" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <user-card [name]="value"></user-card>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="hasPermission(Permissions.DELETE)" name="Action" prop="id" [sortable]="false" [width]="60" [canAutoResize]="false">
            <ng-template let-id="value" ngx-datatable-cell-template>
                <button (click)="$event.stopPropagation();deleteRow(id)" class="btn text-danger no-hover p-0" type="button">
                  <i class="ft-trash-2"></i>
              </button>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                <div class="page-count">
                    <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                </div>
                <datatable-pager class="pr-1" [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)" (change)="dataTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>

<ng-template #FormModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{model.id > 0 ? 'Edit Distributor' : 'Add Distributor'}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
    <div class="modal-body">
        <form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && submit()" novalidate>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="val-required">Distributors</label>
                        <distributor-select class="select-md" [(value)]="model.distributor"></distributor-select>
                    </div>
                    <div class="form-group">
                        <label>Distributor Location</label>
                        <distributor-location-select [appendToBody]="true" [distributorId]="model.distributor" [(value)]="model.distributor_location"></distributor-location-select>
                    </div>
                </div>
                <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                    <button type="submit" [disabled]="!formElement.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                    {{model ? 'Save Changes' : 'Add Distributor'}}
                  </button>
                    <button type="button" (click)="d('cancel click')" class="btn btn-secondary">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>