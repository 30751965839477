import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CustomersPaymentDatas, PaymentMethods } from '../../models/payments-model';
import { AccountingNewPayment } from 'src/app/modules/accounting/models/accounting-model';
import { AccountingService } from 'src/app/modules/accounting/services/accounting.service';
import { PaymentsService } from '../../services/payments.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-accounting-pay-now',
  templateUrl: './accounting-pay-now.component.html',
  styleUrls: ['./accounting-pay-now.component.scss']
})
export class AccountingPayNowComponent implements OnInit {

  public invalidLink: boolean = false;
  public model: AccountingNewPayment;
  public customerId:string;
  public customersPaymentDatas:CustomersPaymentDatas;

  paymentMethods = PaymentMethods;
  selectedPaymentMethod:string = this.paymentMethods.CARD;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private accountingServices: AccountingService,
    private paymentServices: PaymentsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.invalidLink = false;
    this.model = this.accountingServices.getNewPaymentReqModel();

    let url = this.activatedRoute.snapshot.url.join().split(',');
    this.customerId = url[1];

     this.paymentServices.getCustomersPaymentDatas(this.customerId)
    .subscribe(response =>{
      this.customersPaymentDatas = response;
      this.cdr.markForCheck();

    });
    

    /*window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "\o/";
      console.log("cond");
      e.returnValue = confirmationMessage; 
      return confirmationMessage; 
  });*/

  if(!this.customerId || !this.model || !this.model.amount){
    this.location.back();
  }
  }

  goBack($event){

    $event.preventDefault()
    this.location.back();
    }
    changePaymentMethod(method:string){
      this.selectedPaymentMethod = method;

    }

    checkIsValidAmount() {

      return true;//checked in landing page(invoice selection page)
    }
  
    getOverPaidAmount(){
      
        return 0;//checked in landing page(invoice selection page)
    }
    
    paymentSuccess() {

      this.toastr.success(`Payment Done!`);
      this.router.navigate(['accountings/new/payment']);
    }
  
    paymentError(resp) {
      this.toastr.error((resp?.error?.message) ? resp.error.message : 'Payment Error.Please try again after some time.');
    }

}
