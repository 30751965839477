import { CustomerService } from './../../services/customer.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Customer, TenantSettings } from '../../models/customer';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SubSink } from 'subsink';
import { DataFilterRequest, GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import * as popup from 'src/app/core/utils/popup.functions';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = [
        { displayName: "Tenants", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "Active Tenants", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
        { displayName: "In Active Tenants", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
    ];

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Combo Search', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Email', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Phone', colname: 'phone', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                { displayName: 'Address', colname: 'address', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'State', colname: 'state', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'City', colname: 'city', condition: 'contains', value: '', operator: 'OR' },
            ]
        },
        { displayName: 'Name', colname: 'name', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Eamil', colname: 'email', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Phone', colname: 'phone', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Address', colname: 'address', condition: 'contains', value: '', operator: 'OR' },
    ];

    public columns: GFColumn[] = [
        { displayName: 'Name', columnName: 'name', columnType: GFColumnTypes.string },
        { displayName: 'Email', columnName: 'email', columnType: GFColumnTypes.string },
        { displayName: 'Phone', columnName: 'phone', columnType: GFColumnTypes.string },
        { displayName: 'Address', columnName: 'address', columnType: GFColumnTypes.string }
    ];

    public sort: GSortParam[] = [{ colname: 'name', direction: 'asc' }];

    public sortLookup = {
        name: 'name',
        email: 'email',
        phone: 'phone',
        address: 'address'
    };

    public rows: Customer[];

    public SelectionType = SelectionType;

    public selected: Customer;

    public expanded: boolean = true;

    public scrollbarH: boolean = false;

    public tenantSettings: TenantSettings;

    public subs: SubSink = new SubSink();

    private customerId: number;

    constructor(private customerService: CustomerService,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private device: DeviceDetectorService,
        public location: Location) {

        this.subs.sink = route.params.subscribe(params => this.customerId = +params.id || 0);
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
        this.getData()
        if (this.customerId) {
            this.getCustomer();
        }
    }

    getCustomer() {
        this.subs.sink = this.customerService.getById(this.customerId).subscribe(cust => {
            this.selected = cust;
            this.expanded = false;
            this.refreshDataTable();
        });
    }

    getData(filters: GFilterParam[] = null, offset: number = 0) {

        let request: DataFilterRequest = { filters: filters, sort: this.sort }

        this.subs.sink = this.customerService.getFiltered(request, offset).pipe(
            catchError(err => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    getTenantSettings(tenantId: number) {
        this.subs.sink = this.customerService.getTenantSettings(tenantId).subscribe(resp => {
            this.tenantSettings = resp;
            this.cdr.markForCheck();
        });
    }

    onSelect({ selected }) {
        let current: Customer = selected[0];
        if (current) {
            this.selected = current;
            this.expanded = false;
            this.getTenantSettings(this.selected.id);
            this.location.go(`/tenants/${current.id}/view`);
            this.refreshDataTable();
        }
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.location.go(`/tenants`);
        this.refreshDataTable();
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    update2FAsettings() {
        let req = { two_factor_authentication: this.tenantSettings.two_factor_authentication };
        this.subs.sink = this.customerService.updateTenantSettings(this.selected.id, req).subscribe(
            () => { },
            error => {
                this.tenantSettings.two_factor_authentication = !this.tenantSettings.two_factor_authentication;
                console.error(error);
            }
        );
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter.getDataFilters(), offset);
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) { this.getData(params); }

    changeStatus() {
        let status = this.selected.active;
        let popupOption = {
            title: `Are you sure you want to ${status ? 'Activate Tenant' : 'Deactivate Tenant'}?`,
            text: this.selected.name,
            current: status,
        };
        popup.ConfirmActivate(popupOption, result => {
            if (!result.isConfirmed) {
                this.selected.active = !status;
                this.cdr.markForCheck();
                return;
            }
            this.subs.sink = this.customerService.patch(this.selected.id, { active: status }).subscribe(
                () => { },
                () => {
                    this.selected.active = !status;
                    this.toastr.error("Failed to change status");
                    this.cdr.markForCheck();
                }
            );
        });
    }

    subscriptionChange(sub:string) {

        console.log('sub '+sub);


        let status:boolean = this.selected[sub];

        let popupOption = {
            title: `Are you sure you want to ${status ? 'activate' : 'deactivate'} this subscription`,
            text: this.selected.name,
            current: status,
        };
        popup.ConfirmActivate(popupOption, result => {
            if (!result.isConfirmed) {
                this.selected[sub] = !status;
                this.cdr.markForCheck();
                return;
            }

            // type d={[key:string]:string};
            let data={};
            data[sub]=status;

            this.subs.sink = this.customerService.patch(this.selected.id, data).subscribe(
                () => { },
                () => {
                    this.selected[sub] = !status;
                    this.toastr.error("Failed to change status");
                    this.cdr.markForCheck();
                }
            );
        });
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
