import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { ReasonForCall } from '../models/common.models';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallReasonService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<ReasonForCall[]>(`${this.baseURL}/reason_for_call/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0, suborg_id:number = this.suborgId, limit = this.dataLimit) {
    let url = `${this.baseURL}/filter/reason_for_call/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<ReasonForCall[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getById(id: number) {
    return this.http.get<ReasonForCall>(`${this.baseURL}/reason_for_call/${id}/`);
  }

  create(model: ReasonForCall) {
    return this.http.post<ReasonForCall>(`${this.baseURL}/reason_for_call/`, model);
  }

  update(id: number, model: ReasonForCall) {
    return this.http.put<ReasonForCall>(`${this.baseURL}/reason_for_call/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<ReasonForCall>(`${this.baseURL}/reason_for_call/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/reason_for_call/${id}/`);
  }

  updateStatus(id: number, status: boolean): Observable<boolean> {
    return this.patch(id, { is_active: status }).pipe(
      map(resp => true),
      catchError(() => of(false))
    );
  }
}
