<section *ngIf="model">
    <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && onSubmit()" novalidate>
        <!-- <div class="row">
            <div class="col-md-6" *ngIf="isSuperAdmin || allSuborgs">
                <div class="form-group">
                    <label class="val-required">Suborg</label>
                    <suborg-select [tenantId]="model.customer" [readonly]="!model.customer || (sipId && sipId.length > 0)" [(value)]="model.sub_org">
                    </suborg-select>
                </div>
            </div>
        </div> -->
        <!-- <div class="form-group">
            <label>Associated User</label>
            <user-select [(value)]="model.associated_user"></user-select>
        </div> -->
        <div class="form-group">
            <label class="val-required">NAME</label>
            <input type="text" name="username" class="form-control w-50" [(ngModel)]="model.name" required>
            <small class="danger mt-1" *ngIf="model.name && nameError">Name already exists</small>
        </div>
        <div class="form-group">
            <label>Description </label>
            <textarea type="text" name="caller_id"  class="form-control w-50" [(ngModel)]="model.description"></textarea>
        </div>
        <div class="form-group pl-0 col-lg-6">
            <div class="row">
                <div class="col-2"><label class="val-required">When</label></div>
                <div class="col mt-1">
                    <div class="radio radio-sm mb-2">
                        <input type="radio" value="Always" [(ngModel)]="model.type" name="codec_radio" id="codec_radio">
                        <label for="codec_radio">Always</label>
                    </div>
                    <div class="radio radio-sm mb-2">
                        <input type="radio" value="Days/Time" [(ngModel)]="model.type" name="codec_radio" id="codec_radio1">
                        <label for="codec_radio1">Days/Time</label>
                    </div>
                    <div class="radio radio-sm">
                        <input type="radio" value="Specific Date/Ranges" [(ngModel)]="model.type" name="codec_radio" id="codec_radio2">
                        <label for="codec_radio2">Specific Date or Ranges</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group" [hidden]="model.type==='Always'">
            <ul class="day-selection-ul d-flex flex-wrap mb-3" *ngIf="model.type!='Specific Date/Ranges'">
                <li class="cursor-pointer"> <input type="checkbox" name="Monday" [(ngModel)]="weekDays[0].checked" /> Monday</li>
                <li> <input type="checkbox" name="Tuesday" [(ngModel)]="weekDays[1].checked" /> Tuesday</li>
                <li> <input type="checkbox" name="Wednesday" [(ngModel)]="weekDays[2].checked" /> wednesday</li>
                <li> <input type="checkbox" name="Thursday" [(ngModel)]="weekDays[3].checked" /> Thursday</li>
                <li> <input type="checkbox" name="Friday" [(ngModel)]="weekDays[4].checked" /> Friday</li>
                <li> <input type="checkbox" name="Saturday" [(ngModel)]="weekDays[5].checked" /> Saturday</li>
                <li> <input type="checkbox" name="Sunday" [(ngModel)]="weekDays[6].checked" [checked]="false"/> Sunday</li>
           </ul>
           <div class="mb-2 d-flex justify-content-between align-items-center timeranage-div" *ngFor="let time of workingTimes;let index=index" [ngClass]="model.type==='Specific Date/Ranges' ? 'col-md-12 col-lg-9' : 'col-sm-10 col-md-8 col-lg-6'">
                <app-date-picker size="sm" [(value)]="time.startDate" *ngIf="model.type==='Specific Date/Ranges'" ></app-date-picker>
                <ngb-timepicker size="small" name="start_time" [(ngModel)]="time.startTime" [meridian]="true" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                <div class="mr-1 ml-1">To</div>
                <ngb-timepicker size="small" name="end_time" [(ngModel)]="time.endTime" [meridian]="true" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                <app-date-picker size="sm" [(value)]="time.endDate" *ngIf="model.type==='Specific Date/Ranges'"></app-date-picker>
                <a (click)="removeTimeRow(index)" *ngIf="workingTimes.length>1"><i class="ft-trash-2"></i></a>
                
           </div>
           <div class="mt-2">
            <div class="btn btn-sm btn-info" (click)="addNewTime()">Add time</div>
           </div>
    
        </div>
        <div class="d-flex flex-sm-row flex-column justify-content-end mt-3">
            <button type="submit" [disabled]="!addForm.form.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                {{ selected_Id ? 'Save Changes' : 'Save'}}
            </button>
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</section>