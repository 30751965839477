import { WareHouse, WareHouseLocation } from './../models/warehouse.models';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataFilterResponse } from 'src/app/core/models/grid-filter.models';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService extends BaseService {

    constructor(private http: HttpClient) { super() }

    getAll() {
        return this.http.get<WareHouse[]>(`${this.baseURL}/warehouse/`);
    }

    getById(id: number) {
        return this.http.get<WareHouse>(`${this.baseURL}/warehouse/${id}/`);
    }

    create(model: WareHouse) {
        return this.http.post<WareHouse>(`${this.baseURL}/warehouse/`, model);
    }

    update(id: number, model: WareHouse) {
        return this.http.put<WareHouse>(`${this.baseURL}/warehouse/${id}/`, model);
    }

    patch(id: number, model: any) {
        return this.http.patch<WareHouse>(`${this.baseURL}/warehouse/${id}/`, model);
    }

    delete(id: number) {
        return this.http.delete(`${this.baseURL}/warehouse/${id}/`);
    }

    getWareHouseLocations(warehouseid: number) {
        return this.http.get<WareHouseLocation[]>(`${this.baseURL}/warehouse/${warehouseid}/locations/`);
    }

    createWareHouseLocation(model: WareHouseLocation) {
        return this.http.post<WareHouseLocation>(`${this.baseURL}/warehouse/location/`, model);
    }

    updateWareHouseLocation(id: number, model: WareHouseLocation) {
        return this.http.put<WareHouseLocation>(`${this.baseURL}/warehouse/location/${id}/`, model);
    }

    deleteWareHouseLocation(id: number) {
        return this.http.delete(`${this.baseURL}/warehouse/location/${id}/`);
    }
}
