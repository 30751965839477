                    <div>
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link" id="nav-incoming-tab" [ngClass]="{ 'active':activeTabId==1}" data-toggle="tab" href="#nav-incoming" role="tab" aria-controls="nav-incoming" aria-selected="true" (click)="onMainTabClick(1)">Inbound Call</a>
                                <a class="nav-item nav-link" id="nav-outbound-tab" [ngClass]="{ 'active':activeTabId==2}"  data-toggle="tab" href="#nav-outbound" role="tab" aria-controls="nav-outbound" aria-selected="false" (click)="onMainTabClick(2)">Outbound Dialer</a>
                                <a class="nav-item nav-link" id="nav-callreport-tab" [ngClass]="{ 'active':activeTabId==3}" data-toggle="tab" href="#nav-callreport" role="tab" aria-controls="nav-callreport" aria-selected="false" (click)="onMainTabClick(3)">All Call Report</a>
                                <a class="nav-item nav-link" id="nav-agents" [ngClass]="{ 'active':activeTabId==4}" data-toggle="tab" href="#nav-agents" role="tab" aria-controls="nav-agents" aria-selected="false" (click)="onMainTabClick(4)"> Agents </a>                                
                            </div>
                        </nav>
                        <div class="tab-content px-3 px-sm-0" id="nav-tabContent">
                            <div class="tab-pane fade"  [ngClass]="{ 'active show':activeTabId==1}" id="nav-incoming" role="tabpanel" aria-labelledby="nav-incoming-tab">
                                <div  *ngIf="!sipInboundFnService.selectedCaller">
                                    <h5 class="text-muted text-center" style="padding: 200px;">No Incoming Calls</h5>
                                </div>

                                <app-call-center-info #callCenterInfo *ngIf="sipInboundFnService.selectedCaller"
                                    [audioInputSource]="selectedAudioInput" [videoSource]="selectedVideoSource"nav-incoming
                                    [ringAudio]="sipInboundFnService.ringAudio"
                                    [session]="sipInboundFnService.selectedCaller" [block_id]="sipInboundFnService.block_id" 
                                    [wh_CallId]="sipInboundFnService.callId" [call_Details]="sipInboundFnService.incoming_callDetails"
                                    (movetoAcceptedCallsQ)="movetoAcceptedCalls($event)" (clearCallQ)="sipInboundFnService.clearCallQ"
                                    [isActiveCall]="callCenterService.isActiveCallInbound"
                                    [acceptedCallQ]="sipInboundFnService.acceptedCallQ" [agentDetails]="callcenterSharedService.sip_details"
                                    [iscallcenterEnabled]="sipInboundFnService.iscallcenterEnabled"
                                    (clearEndedWhileonHoldCall)="clearCompletedCallsfromCallQ($event)"
                                    (checkWhichSectionTobeSelected)="checkWhichSectionTobeSelected()"
                                    (newPanelOpened) = "onNewPanelOpened($event)">
                                </app-call-center-info>

                            </div>
                            <div class="tab-pane fade"  [ngClass]="{ 'active show':activeTabId==2}" id="nav-outbound"  role="tabpanel" aria-labelledby="nav-outbound-tab">

                                <app-outbound-call [dynamicHeight]="dynamicHeight"></app-outbound-call>
                              
                            </div>
                            <div class="tab-pane fade"  [ngClass]="{ 'active show':activeTabId==3}" id="nav-callreport" role="tabpanel" aria-labelledby="nav-callreport-tab">
                        
                                <div class="report-container" *ngIf = 'activeTabId == 3'>
                                    <app-call-reports  
                                        [showCount]="false"  
                                          
                                        [enableViewDetails] = "false"
                                        [defaultFilter]="basicLogFilter" 
                                        [gridTabs]="gridTabs" 
                                       
                                        [outboundDefaults]="undefined"
                                        [defaults]="undefined"
                                        [showSavedFilter] = "false" 
                                        [showDefaultFilter]="false"
                                        [showreportType] = "true"
                                        [page]="page" 
                                        [rows2]="allCallLogList" 
                                        [showExport] = "false" 
                                        [tableHeight]="dynamicHeight-220"
                                        [showAdvancedFilter] = "false">
                                    </app-call-reports>
                                </div>
                            
                            </div>
                            <div div class="tab-pane fade"  [ngClass]="{ 'active show':activeTabId==4}" id="nav-agents" role="tabpanel" aria-labelledby="nav-agents">
                                <app-sip-endpoints *ngIf='activeTabId == 4' [showGridFilter] = "false" [sipData] = "sipList" [enableViewDetails] = "false"
                                [showAction] = "false" [showQuickFilter] = "true" [dynamicHeight]="dynamicHeight-215" [forCallCenter]="true"></app-sip-endpoints>
                            </div>
                        </div>
                    </div>
                <app-current-call-list-panel
                    (sel_IncomingCallId)="onIncomingSessionItemClick($event)" (sel_OugoingCallInfo) = "onOutGoingSessionItemClick($event)">
                </app-current-call-list-panel>
