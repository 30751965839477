import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>
             <ng-http-loader [filteredUrlPatterns]="['callcenter_updated/api/agent/call','/did/call_center/disable/']"></ng-http-loader>`
})
export class AppComponent implements OnInit {

  title = 'workhorse-web-ui';

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    ) { }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => this.titleService.setTitle(data?.title || 'SimplyFuse'));
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    return activatedRoute.firstChild ? this.getChild(activatedRoute.firstChild) : activatedRoute;
  }
}
