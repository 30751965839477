import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";
import { STBillingStatus, ServiceTicketStatus } from "../models/service-ticket";


export class ServiceTicketDataHelper{


    static get defaults():GFilterParam[]{
        return [
            { displayName: "All Open Service Tickets", colname: 'status', condition: 'not_equalto', value: ServiceTicketStatus.Closed, default:true,  operator: 'AND'},
            { displayName: "New Tickets", colname: 'status', condition: 'equalto', value: ServiceTicketStatus.New, operator: 'AND' },
            { displayName: "Tickets In Progress", colname: 'status', condition: 'equalto', value: ServiceTicketStatus.In_Progress, operator: 'AND' },
            {
              displayName: "Tickets Escalated to Manager", colname: 'status', condition: 'equalto', value: ServiceTicketStatus.Escalated_To_Manager,
              operator: 'AND'
            },
            { displayName: "Tickets Waiting", colname: 'status', condition: 'equalto', value: ServiceTicketStatus.Waiting, operator: 'AND' },
            { displayName: "Tickets Closed", colname: 'status', condition: 'equalto', value: ServiceTicketStatus.Closed, operator: 'AND' },
            { displayName: "Un Billed", colname: 'billing_status', condition: 'equalto', value: STBillingStatus.Un_Billed, operator: 'AND' },
            { displayName: "Not Billable", colname: 'billing_status', condition: 'equalto', value: STBillingStatus.Not_Billable, operator: 'AND' },
            { displayName: "Partially Invoiced", colname: 'billing_status', condition: 'equalto', value: STBillingStatus.Partially_Invoiced, operator: 'AND' },
            { displayName: "Fully Invoiced", colname: 'billing_status', condition: 'equalto', value: STBillingStatus.Fully_Invoiced, operator: 'AND' }
          ];
    }


    static get search():GFilterParam[]{

        return [
            {
              displayName: 'Combo Search', colname: '', condition: '', value: '',
              children: [
                { displayName: 'Title', colname: 'title', condition: 'contains', value: '', operator: 'AND' },
                { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Customer Contact Name', colname: 'customer_contact_firstname', condition: 'contains', value: '', operator: 'OR' },
                { colname: 'customer_contact_lastname', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Customer Contact Phone', colname: 'customer_contact_phone', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Customer Contact Email', colname: 'customer_contact_email', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Customer A/c Number', colname: 'customer_account', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Customer Location', colname: 'location_name', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Customer Location A/c Number', colname: 'location_account', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Service Ticket Number', colname: 'unique_number', condition: 'contains', value: '', operator: 'OR' },
              ]
            },
            { displayName: 'Customer Name', colname: 'customer_name', condition: 'contains', value: '', operator: 'AND' },
            {
              displayName: 'Customer Contact Name', colname: '', condition: '', value: '',
              children: [
                { colname: 'customer_contact_firstname', condition: 'contains', value: '', operator: 'AND' },
                { colname: 'customer_contact_lastname', condition: 'contains', value: '', operator: 'OR' },
              ]
            },
            { displayName: 'Customer Contact Phone', colname: 'customer_contact_phone', condition: 'contains', value: '', operator: 'AND' },
            { displayName: 'Customer Contact Email', colname: 'customer_contact_email', condition: 'contains', value: '', operator: 'AND' },
            { displayName: 'Customer A/c Number', colname: 'customer_account', condition: 'contains', value: '', operator: 'AND' },
            { displayName: 'Customer Location', colname: 'location_name', condition: 'contains', value: '', operator: 'AND' },
            { displayName: 'Customer Location A/c Number', colname: 'location_account', condition: 'contains', value: '', operator: 'AND' },
            { displayName: 'Service Ticket Number', colname: 'unique_number', condition: 'contains', value: '', operator: 'AND' },
          ];

    }

    static get columns():GFColumn[]{

        return [
            {
              displayName: 'Status', columnName: 'status', columnType: GFColumnTypes.select, optionValueType: GFColumnTypes.ref,
              options: [
                { displayName: 'New', value: ServiceTicketStatus.New },
                { displayName: 'In Progress', value: ServiceTicketStatus.In_Progress },
                { displayName: 'Escalated to Manager', value: ServiceTicketStatus.Escalated_To_Manager },
                { displayName: 'Waiting', value: ServiceTicketStatus.Waiting },
                { displayName: 'Closed', value: ServiceTicketStatus.Closed },
              ]
            },
            { displayName: 'Customer Type', columnName: 'customer_type', columnType: GFColumnTypes.ref },
            { displayName: 'Industry', columnName: 'industry', columnType: GFColumnTypes.ref },
            { displayName: 'Account Rep', columnName: 'rep_id', columnType: GFColumnTypes.ref },
            { displayName: 'Service Ticket Billable', columnName: 'service_ticket_billable', columnType: GFColumnTypes.boolean },
            { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
            { displayName: 'Product Type', columnName: 'product_type', columnType: GFColumnTypes.ref },
            { displayName: 'Escalation', columnName: 'escalation_period', columnType: GFColumnTypes.ref },
            { displayName: 'Reason For Service', columnName: 'reason_for_call', columnType: GFColumnTypes.ref },
            { displayName: 'Priority', columnName: 'priority', columnType: GFColumnTypes.ref },
            { displayName: 'Assigned User', columnName: 'assigned_user', columnType: GFColumnTypes.ref },
            { displayName: 'Assigned User Group', columnName: 'assigned_user_group', columnType: GFColumnTypes.ref },
            { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
          ];
    }

    static get sortLookup(){

        return {
            unique_number: 'unique_number',
            tenant_customer_name: 'customer_name',
            created_on: 'created_on',
            title: 'title',
          };

    }

}
