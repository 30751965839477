<div class="top_banner_section">

  <div class="card-body">
    <img class="float-left mr-2 mb-2" width="50" height="55"
      src="/assets/img/svg/cc/sf_logo.svg" />
    <h4 class="text-white mb-1 font-weight-normal">SimplyFuse</h4>
    <p class="logo-sub text-white">
      <img class="mr-1" width="13" height="13"
        src="/assets/img/svg/cc/verified.svg" />
        CardKnox trusted</p>

    <div class="row" style="clear: both;">
      <div class="col-md-5 text-white text_total_amt">Total Amount<br> {{amount|currency}}</div>
      <div class="col-md-7 align-self-end text-right">
        <img width="15" height="18" src="/assets/img/svg/cc/secured.svg" />
        <small class="text-white ml-1 mr-1" style="font-size: 12px;">Secured By</small>
        <img width="84" height="18" src="/assets/img/svg/cc/cardknox.svg" />
      </div>
    </div>
  </div>

</div>
<div class="content_section">

  <div class="d-flex payment_methods justify-content-center mt-2"
    style="margin-bottom: -10px;">
    <div class="icon_div"
      [ngClass]="{'selected_icon_div': (this.selectedPaymentMethode == paymentMethods.CARD)}"
      (click)="changePaymentMethode(paymentMethods.CARD)">
      <img class="m-1" width="45" height="22"
        src="/assets/img/svg/cc/debit_card.svg" />
      <p class="s_title">Credit/Debit Card</p>
    </div>

    <!-- <div class="icon_div"
      [ngClass]="{'selected_icon_div': (this.selectedPaymentMethode == paymentMethods.CARD)}"
      (click)="changePaymentMethode(paymentMethods.CARD)">
      <img class="m-1" width="45" height="22"
        src="/assets/img/svg/cc/credit_card.svg" />
      <p class="s_title">Debit Card</p>
    </div> -->
    <div class="icon_div"
      [ngClass]="{'selected_icon_div': (this.selectedPaymentMethode == paymentMethods.ACH)}"
      (click)="changePaymentMethode(paymentMethods.ACH)">
      <img class="m-1" width="45" height="22" src="/assets/img/svg/cc/bank.svg" />
      <p class="s_title">ACH</p>
    </div>

  </div>

  <div class="row">
    <div class="col-12 card-info">

      <mat-card class="mat-card">
        <mat-card-content>

          <form  *ngIf="this.selectedPaymentMethode == paymentMethods.CARD" class="mat-form">
            <div class="mb-2" *ngIf="myCards">
              <ng-select class="form-select "
                bindLabel="masked_card_number"
                bindValue="id"
                placeholder="My Cards"
                [items]="myCards"
                [(ngModel)]="selectedCardId"
                (change)="cardSelected($event)"
                name="mycards">
              </ng-select>
            </div>

            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="inputEmail4">Card number<sup>*</sup></label>
                <input type="text" [(ngModel)]="paymentReqModel.card_number"
                  [readOnly]="paymentReqModel.token" class="form-control"
                  name="card_number" placeholder="Card number"
                  (keyup)="checkValidation()">
                <small *ngIf="validationsErrors.number" class="pl-1"
                  style="color: red;">
                  Card number is required.
                </small>
              </div>
              <div class="form-group col-md-4">
                <label for="inputPassword4">Exp Date<sup>*</sup></label>
                <input type="text" mask="00/00"
                  [(ngModel)]="paymentReqModel.expiry_date"
                  [readOnly]="paymentReqModel.token" class="form-control"
                  name="exp" placeholder="Exp Date" (keyup)="checkValidation()">
                <small *ngIf="validationsErrors.exp" class="pl-1"
                  style="color: red;">
                  Exp is required.
                </small>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="inputEmail4">Name on the Card<sup>*</sup></label>
                <input type="text" [(ngModel)]="paymentReqModel.name"
                  [readOnly]="paymentReqModel.token" class="form-control"
                  name="name" placeholder="Name on the Card"
                  (keyup)="checkValidation()">
                <small *ngIf="validationsErrors.name" class="pl-1"
                  style="color: red;">
                  Name is required.
                </small>
              </div>
              <div class="form-group col-md-4">
                <label for="inputPassword4">Cvv<sup>*</sup></label>
                <input type="text"
                  [(ngModel)]="paymentReqModel.cvv" class="form-control"
                  name="cvv" placeholder="Cvv" (keyup)="checkValidation()">
                <small *ngIf="validationsErrors.cvv" class="pl-1"
                  style="color: red;">
                  Cvv is required.
                </small>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="inputEmail4">Comments</label>
                <input type="text" [(ngModel)]="paymentReqModel.comments"
                  class="form-control" name="comments" placeholder="Comments">

              </div>
              <div class="form-group col-md-4">
                <label for="inputPassword4">Zip</label>
                <input type="text" maxlength="6"
                  [(ngModel)]="paymentReqModel.zip_code"
                  [readOnly]="paymentReqModel.token" class="form-control"
                  name="zip" placeholder="Zip">

              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <div class="form-check">
                  <input [(ngModel)]="paymentReqModel.is_saved_to_file"
                    [disabled]="paymentReqModel.token" class="form-check-input"
                    type="checkbox" name="is_save" id="gridCheck">
                  <label class="form-check-label" for="gridCheck">
                    Save Card
                  </label>
                </div>
              </div>
              <div class="form-group col-md-8">
                <div class="form-check">
                  <input [(ngModel)]="paymentReqModel.send_email_to_customer"
                    class="form-check-input" type="checkbox"
                    name="send_email_to_customer" id="send_email_to_customer">
                  <label class="form-check-label" for="send_email_to_customer">
                    Email Receipts
                  </label>
                </div>
              </div>
            </div>
          </form>



          <form  *ngIf="this.selectedPaymentMethode == paymentMethods.ACH" class="mat-form">
            <div class="mb-2" *ngIf="myChecks">
              <ng-select class="form-select "
                bindLabel="masked_account_number"
                bindValue="id"
                placeholder="My ACH"
                [items]="myChecks"
                [(ngModel)]="selectedCheckId"
                (change)="checkSelected($event)"
                name="mychecks">
              </ng-select>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Account number<sup>*</sup></label>
                <input type="text" [(ngModel)]="paymentReqModel.account"
                  [readOnly]="paymentReqModel.token" class="form-control"
                  name="account" placeholder="Account number"
                  (keyup)="checkValidation()">
                <small *ngIf="validationsErrors.account" class="pl-1"
                  style="color: red;">
                  Account number is required.
                </small>
              </div>
              <div class="form-group col-md-6">
                <label for="inputEmail4">Routing number<sup>*</sup></label>
                <input type="text" [(ngModel)]="paymentReqModel.routing"
                  [readOnly]="paymentReqModel.token" class="form-control"
                  name="routing" placeholder="Routing number"
                  (keyup)="checkValidation()">
                <small *ngIf="validationsErrors.routing" class="pl-1"
                  style="color: red;">
                  Routing number is required.
                </small>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Name on ACH<sup>*</sup></label>
                <input type="text" [(ngModel)]="paymentReqModel.name"
                  [readOnly]="paymentReqModel.token" class="form-control"
                  name="name" placeholder="Name on the Card"
                  (keyup)="checkValidation()">
                <small *ngIf="validationsErrors.name" class="pl-1"
                  style="color: red;">
                  Name is required.
                </small>
              </div>
              <div class="form-group col-md-6 account_type">
                <label for="inputPassword4">Account Type<sup>*</sup></label>
                <ng-select  placeholder="Select Account Type" [(ngModel)]="paymentReqModel.account_type"  name="account_type" >
                  <!-- <ng-option value="">Select Type</ng-option> -->
                   <ng-option value="{{accountTypes.CHECKING}}">{{accountTypes.CHECKING}}</ng-option>
                   <ng-option value="{{accountTypes.SAVINGS}}">{{accountTypes.SAVINGS }}</ng-option>
               </ng-select>
                <small *ngIf="validationsErrors.account_type" class="pl-1"
                  style="color: red;">
                  Account Type is required.
                </small>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="inputEmail4">Comments</label>
                <input type="text" [(ngModel)]="paymentReqModel.comments"
                  class="form-control" name="comments" placeholder="Comments">

              </div>
              <div class="form-group col-md-4">
                <label for="inputPassword4">Zip</label>
                <input type="text" maxlength="6"
                  [(ngModel)]="paymentReqModel.zip_code"
                  [readOnly]="paymentReqModel.token" class="form-control"
                  name="zip" placeholder="Zip">

              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <div class="form-check">
                  <input [(ngModel)]="paymentReqModel.is_saved_to_file"
                    [disabled]="paymentReqModel.token" class="form-check-input"
                    type="checkbox" name="is_save" id="gridCheck">
                  <label class="form-check-label" for="gridCheck">
                    Save ACH
                  </label>
                </div>
              </div>
              <div class="form-group col-md-8">
                <div class="form-check">
                  <input [(ngModel)]="paymentReqModel.send_email_to_customer"
                    class="form-check-input" type="checkbox"
                    name="send_email_to_customer" id="send_email_to_customer">
                  <label class="form-check-label" for="send_email_to_customer">
                    Email Receipts
                  </label>
                </div>
              </div>
            </div>
          </form>


        </mat-card-content>

        <div class="d-flex justify-content-end">
          <img class="m-1" width="40" height="22"
            src="/assets/img/svg/cc/visa.svg" />
          <img class="m-1" width="40" height="22"
            src="/assets/img/svg/cc/master.svg" />
          <img class="m-1" width="40" height="22"
            src="/assets/img/svg/cc/amex.svg" />
        </div>

      </mat-card>
    </div>
  </div>

  <div class="d-flex justify-content-center ">
    <button (click)="pay()" type="submit" class="btn btn-primary btn-proceed">Proceed</button>
  </div>

</div>