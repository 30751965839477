import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { TimeSheet } from '../../../service-ticket/models/common.models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { InvoiceService } from '../../../sales/services/invoice-service';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { InvoiceItemsType, ItemAdd } from '../../../sales/models/invoice.models';
import { ServiceTicketService } from '../../../service-ticket/services/service-ticket.service';
import { DatePipe } from '@angular/common';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'invoice-timesheet-grid',
  templateUrl: './invoice-timesheet-grid.component.html',
  styleUrls: ['./invoice-timesheet-grid.component.scss']
})
export class InvoiceTimesheetGridComponent implements OnInit {

    @ViewChild('modalContent') modalContent: any;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @Output() onUpdate = new EventEmitter();

    @Output() onDataLoaded = new EventEmitter();

    public rows: TimeSheet[] = [];

    public selectionType = SelectionType;

    private inv_id: number;

    get InvoiceId(): number { return this.inv_id; };

    @Input() set InvoiceId(value: number) {
        this.inv_id = value;
        this.getTimeSheet();
    }

    @Input() tenantCustomerId: number;

    @Input() tenantCustomerLocationId: number;

    @Input() tenantCustomerContactId: number;

    @Input() suborg_id:number;

    private modalRef: NgbModalRef;

    public scrollbarH: boolean = false;

    private subs: SubSink = new SubSink();

    public Permissions = PermissionConstants;

    public timeSheetId: number = 0;

    public timeSheet: TimeSheet;

    constructor(private service: InvoiceService,
        private modal: NgbModal,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        private st_service: ServiceTicketService)
         { }

    ngOnInit(): void { }

    onSelect({ selected }) {
        if (selected.length && this.hasPermission(PermissionConstants.MODIFY)) {
            this.open(selected[0].id);
        }
    }

    getTimeSheet(){
        this.subs.sink=this.service.getTimeSheets(this.inv_id).subscribe(
        response=>{
            this.rows = response;
            this.onDataLoaded.emit(this.rows)
            this.cdr.markForCheck();
        },
        ()=>{
            this.toastr.error("Unable to get items");
        }
        );
    }

    public open(id: number = 0) {

        this.timeSheetId = id;

        this.timeSheet = id ? { ... this.rows.find(item => item.id == id) } : null;

        this.modalRef = this.modal.open(this.modalContent, { centered: true, scrollable: true, size: 'lg' });
    }

    public close() { this.modalRef?.dismiss(); }

    deleteRow(id: number) {
        popup.ConfirmDelete(result => {
            if (!result.isConfirmed) return;
            this.subs.sink = this.st_service.deleteTimeSheet(id).subscribe(
                () => {
                    let timesheet = this.rows.filter(x => x.id == id)[0]
                    timesheet.is_delete = true;
                    this.updateInvoice()
                    this.onUpdate?.emit();
                    this.cdr.markForCheck();
                    popup.CompleteDelete('Timesheet has been deleted.')
                },
                () => this.toastr.error('Unable to delete Timesheet')
            );
        });
    }

    hasPermission(permission: string) {
        return this.ra.hasPermission(ResourceConstants.ST_TIMSHEET, [permission], ResourceConstants.SERVICE_TICKET_MODULE);
    }

    getTime(timeInMi: number) {
        if (timeInMi){
            let h = Math.floor(timeInMi / 60);
            let m = timeInMi % 60;
            return `${h} Hours ${m} Minutes`;
        }
        else{
            return `0`;
        }
    }

    onDataSubmittedSuccess(result: { response: TimeSheet, message: string }): void {
        if (result.response.is_billable)
            this.rows = [...this.rows.filter(item => item.id !== result.response.id), ...[result.response]];
        else{
            let timesheet = this.rows.filter(item => item.id == result.response.id)[0]
            if (timesheet){
                timesheet.is_delete=true;
            }
        }

        this.updateInvoice()
        this.onUpdate?.emit();
        this.toastr.success(result.message);
        this.close();
        this.cdr.markForCheck();
    }

    onDataError(): void {
        this.toastr.error('Operation failed');
        this.close();
    }

    calculateTimesheetTotal() {
        let total = 0;
        this.rows.forEach((row, index) => {
            if (!row.is_delete) {
                total += Number(!isNaN(row.total_amount) ? row.total_amount : 0);
            }
        });
        return total.toFixed(2);
    }

    formatDataForSend() {
        const pipe = new DatePipe('en-US');
        let itemsAdd: ItemAdd[] = [];
        this.rows.forEach((timesheet, index) => {
            let row: ItemAdd = new ItemAdd();
            if (this.inv_id && this.inv_id > 0) {
                row.invoice = this.inv_id
                row.id = timesheet.pk_id;
            }
            else {
                row.invoice = null;
            }
            row.customer = timesheet.customer;
            row.is_delete = timesheet.is_delete;
            row.suborg = this.suborg_id;
            row.unique_id = timesheet.id;
            row.quantity = 0;
            row.price = timesheet.total_amount;
            row.total = Number(timesheet.total_amount).toFixed(2);
            row.type = InvoiceItemsType.TIMESHEET;
            row.name = pipe.transform(timesheet.created_on, 'MM/dd/YYYY') + ": " + timesheet.comments;
            row.order = timesheet.order;
            itemsAdd.push(row);
        });
        return itemsAdd;
    }

    updateInvoice(){
        let data = this.formatDataForSend();
        if(data.length>0){
            let api = (this.inv_id>0) ? this.service.itemKitUpdate(data) : this.service.itemKitAdd(data);
            api.subscribe(
                (response)=>{
                    this.getTimeSheet()
                },
                ()=>{
                  }
            );
        }
        this.onUpdate?.emit();
    }

    afterTabResize(){
        this.table.recalculate();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
        this.rows.map(
            (item, index) => {
                item.order=index;
            }
        )
        this.updateInvoice()
        this.toastr.success("Order changed in Labor section");
        this.cdr.detectChanges()
    }

    ngOnDestroy(): void { this.subs?.unsubscribe(); }

}
