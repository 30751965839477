import { Priority, ReasonForCall } from '../../../service-ticket/models/common.models';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { CallReasonService } from '../../../service-ticket/services/call-reason.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'call-reason-form',
  templateUrl: './call-reason-form.component.html',
  styleUrls: ['./call-reason-form.component.scss']
})
export class CallReasonFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('name', { static: false, read: ElementRef }) nameControl: ElementRef;

  public model: ReasonForCall = new ReasonForCall();

  @Input() bindingModel: ReasonForCall;

  @Input() uniqueId: number = 0;

  @Input() suborg_id: number = 0;

  @Output() success = new EventEmitter<{ response: ReasonForCall, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private subs: SubSink = new SubSink();

  constructor(private service: CallReasonService, private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void { this.nameControl?.nativeElement.focus(); }

  ngOnInit() {

    if (this.uniqueId > 0) {
        if (this.bindingModel) {
            this.model = { ... this.bindingModel };
        }
        else{
            this.subs.add(this.service.getById(this.uniqueId).subscribe(resp => {
                this.model = resp;
                this.cdr.markForCheck();
            }));
        }
    }
    else{
        this.model = this.service.createModel(ReasonForCall)
        if(this.suborg_id > 0){
          this.model.suborg = this.suborg_id;
        }
    }
  }

  onSubmit(): void {

    let api: Observable<ReasonForCall>;

    if (this.uniqueId > 0)
      api = this.service.update(this.uniqueId, this.model);
    else {
      this.model.customer = this.service.customerId;
      api = this.service.create(this.model);
    }

    this.subs.sink = api.subscribe(
      response => {
        let msg = this.uniqueId > 0 ? 'Call Reason info updated' : 'New Call Reason created';
        this.success?.emit({ response: this.model, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  priorityChange(priority:Priority){

    this.model.priority_name=priority.name;

    this.model.priority=priority.id;

    this.model.priority_color=priority.color;
  }

  onCancelClicked(): void {
    this.cancel?.emit();
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
