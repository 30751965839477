import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { SIP } from '../../models/sip.models';
import { SIPService } from '../../services/sip.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-add-sip',
    templateUrl: './add-sip.component.html',
    styleUrls: ['./add-sip.component.scss']
})
export class AddSipComponent implements OnInit, OnDestroy {

    public model: SIP;

    public sipId: string;

    public isSuperAdmin: boolean;

    public allSuborgs: boolean;

    public nameError: boolean;

    private innerCodec:string = "default";

    get codec() { return this.innerCodec; }

    set codec(val:string) {
        this.innerCodec = val;
        this.model.codecs = [];
    }

    private innerCipher:string = "default";

    get cipher() { return this.innerCipher; }

    set cipher(val:string){
        this.innerCipher = val;
        this.model.ciphers = [];
    }

    showPasswordField:boolean = true;

    private subs: SubSink = new SubSink();

    constructor(private service: SIPService,
        route: ActivatedRoute,
        private router:Router,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        public location: Location) {

        this.isSuperAdmin = service.isSuperUser;
        this.allSuborgs = service.suborgId === -1;
        this.sipId = route.snapshot.paramMap.get('id');
    }

    ngOnInit(): void {
        if (this.sipId) {
            this.subs.add(this.service.getById(this.sipId).subscribe(bin => {
                this.model = bin;
                if(this.model.ciphers.length) {
                    this.innerCipher = 'custom';
                }

                if(this.model.codecs.length) {
                    this.innerCodec = 'custom';
                }
                this.showPasswordField = this.model.is_sync;
                this.cdr.markForCheck();
            }));
        }
        else {
            this.model = new SIP();
            if (this.allSuborgs) {
                this.model.customer = this.service.customerId;
            }
            else {
                this.model.sub_org = this.service.suborgId;
            }
        }
    }

    onSubmit() {
        if(!this.model.associated_user){
            this.toastr.warning("Please assign a user");
            return;
        }
        let api: Observable<SIP> = this.sipId ? this.service.update(this.sipId, this.model)
            : this.service.create(this.model);

        this.subs.sink = api.subscribe(
            resp => {
                this.nameError = false;
                let msg = this.sipId ? `SIP Endpoint info updated` : `New SIP Endpoint created`;
                this.toastr.success(msg);
                this.router.navigate(['ring2voice/sip-endpoints', resp.id,'view']);
            },
            error => {
                if (error.error === 'NAME_DUPLICATION') {
                    this.nameError = true;
                }
                else {
                    this.nameError = false;
                    this.toastr.error("Operation Failed");
                }
            }
        )
    }

    onCancelClicked(): void {
        this.location.back();
    }

    makePasswordEditable() {
        this.showPasswordField = false;
        this.model.password = null;
    }

    onlyNumberKey(event) {
        return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
