<section id="item-list" class="pt-2" *ngIf="isAdmin || grid">
    <div class="row">
        <div class="col-12 d-flex flex-row" [ngClass]="{'row-shrinked':!expanded}">
            <div class="m-0" [ngClass]="{'dt-shrinked items-datatable':!expanded,'dt-expanded card':expanded}">
                <div class="d-flex flex-space-bw filter-bar"
                    [ngClass]="{'card-header':expanded,'items-datatable-header mb-2':!expanded}">
                </div>
                <div class="table-responsive">
                    <div class="card-content ">
                        <div [ngClass]="{'card-body':expanded}">
                            <ngx-datatable #dataTable
                                class="bootstrap core-bootstrap"
                                [scrollbarH]="scrollbarH"
                                [columnMode]="'force'"
                                [headerHeight]="50"
                                [footerHeight]="50"
                                rowHeight="auto"
                                [rows]="rows"
                                [selectionType]="SelectionType.single"
                                [count]="page.count"
                                [externalPaging]="true"
                                [offset]="page.offset"
                                [externalSorting]="true"
                                [sorts]="[{prop:'part_number',dir:'asc'}]"
                                [limit]="page.limit"
                                (select)="onSelect($event)"
                                (page)="setPage($event)"
                                (sort)="onSort($event)">
                                <ngx-datatable-column *ngIf="isProfiled('Image',true)" name="Image" prop="primary_image"
                                    [sortable]="false" [width]="90" [canAutoResize]="false">
                                    <ng-template let-url="value" ngx-datatable-cell-template>
                                        <img *ngIf="url" width="80" height="80" [src]="fileUrl + url" />
                                        <img *ngIf="!url" width="30" height="30"
                                            src="/assets/img/svg/product-avatar.svg" />
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column *ngIf="isProfiled('Name',true)" name="Name" prop="item_name">
                                </ngx-datatable-column>
                                <ngx-datatable-column *ngIf="isProfiled('Part Number',true)" name="Part Number"
                                    prop="part_number"></ngx-datatable-column>
                                <ngx-datatable-column *ngIf="expanded && isProfiled('SKU',true)" name="SKU" prop="sku">
                                </ngx-datatable-column>
                                <ngx-datatable-column *ngIf="expanded && isProfiled('Category',true)" name="Category"
                                    prop="category_name"></ngx-datatable-column>
                                <ngx-datatable-column *ngIf="expanded && isProfiled('Available Qty',true)"
                                    name="Available Qty" prop="available_stock" [sortable]="false">
                                    <ng-template let-quantity="value" ngx-datatable-cell-template>
                                        {{quantity || '0'}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column *ngIf="expanded && isProfiled('Manufacturer')" name="Manufacturer"
                                    prop="manufacturers_name"></ngx-datatable-column>
                                <ngx-datatable-column *ngIf="expanded && isProfiled('Status',true)" name="Status"
                                    prop="is_active" [width]="80" [canAutoResize]="false" [sortable]="false">
                                    <ng-template let-status="value" ngx-datatable-cell-template>
                                        <div class="badge"
                                            [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                            {{ status ? 'Active' : 'Inactive' }}
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                        let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                        <div class="page-count" *ngIf="expanded">
                                            <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                        </div>
                                        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                            [pagerRightArrowIcon]="'datatable-icon-right'"
                                            [pagerPreviousIcon]="'datatable-icon-prev'"
                                            [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                            [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                            (change)="dataTable.onFooterPage($event)">
                                        </datatable-pager>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                        </div>
                    </div>
                </div>
            </div>
            <div class="items-view" *ngIf="!expanded && selected">
                <div class="items-view-content">
                    view
                    <!-- <app-item-view [item]="selected" (onDelete)="deleteItem($event)" (onClose)="onItemViewClose()">
                    </app-item-view> -->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="profiling-message" *ngIf="!isAdmin && !grid">
    <div class="message card align-items-center">
        <h5 class="mb-4">Grid View not configured</h5>
        <!-- <label>
            <button (click)="location.back()" class="btn btn-primary width-auto">Back to Home</button>
        </label> -->
    </div>
</div>
