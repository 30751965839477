
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { BaseService } from 'src/app/core/services/base.service';
import { addTerms, getTermsCat, listTerms, TermsCatResponse, TermsComments } from '../models/terms.models';

@Injectable({
    providedIn: 'root'
  })
export class TermsService extends BaseService{
    constructor(private http: HttpClient){
        super();
    }

    getAll(suborg_id:number = this.suborgId) {
        return this.http.get<listTerms[]>(`${this.baseURL}/terms_and_conditions/?suborg=${suborg_id}`);
    }

    listTerms(option: any, offset: number = 0){
        let url = `${this.baseURL}/terms_and_conditions/filter/?limit=${this.dataLimit}&offset=${offset}`;
        return this.http.post<{count:number,result:listTerms[]}>(url,option).pipe(
            catchError(() => of({ count: 0, result: [] }))
        );
    }

    addTerms(data:addTerms){
        let url = `${this.baseURL}/terms_and_conditions/`;
        return this.http.post<{data}>(url,data);
    }
    // getTerms(id:number, suborg_id:number = this.suborgId){

    //     let url = `${this.baseURL}/get_terms_and_conditions/`+id+`/?suborg=${suborg_id}`;
    //     return this.http.get<TermsCatResponse[]>(url);
    // }


    getByid(id:number){
        let url = `${this.baseURL}/terms_and_conditions/`+id+`/`;
        return this.http.get<addTerms>(url);
    }

    getByid2(id:number){
        let url = `${this.baseURL}/terms_and_conditions/`+id+`/`;
        return this.http.get<listTerms>(url);
    }

    updateTerms(data, id:number, ){
        let url = `${this.baseURL}/terms_and_conditions/`+id+`/`;
        return this.http.put<{data}>(url,data);
    }

    deleteTerms(data, id:number,){
        let url = `${this.baseURL}/terms_and_conditions/`+id+`/`;
        return this.http.delete<{data}>(url,data);
    }

    getCategory(id:number){
        let category=["","Proposal", "Invoice", "Warranty", "Service Contract"];
        return category[id]??"";
    }


    createComment(comments: TermsComments[], id:number) {
    return this.http.post<TermsComments[]>(`${this.baseURL}/terms_and_conditions/`, comments);
    }

    updateComment(id: number, comment: TermsComments) {
    return this.http.put(`${this.baseURL}/terms_and_conditions/${id}/`, comment);
    }

    getComments(ticketId: number) {
    return this.http.get<TermsComments[]>(`${this.baseURL}/terms_and_conditions/${ticketId}/`);
    }

    deleteComment(id: number) {
    return this.http.delete(`${this.baseURL}/terms_and_conditions/${id}/`);
    }


}
