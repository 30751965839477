
<ng-select

#geoSearch
[ngClass]="{'size-sm':size=='sm', 'size-md':size=='md', 'size-lg':size=='lg'}"
[items]="searchSuggestions|async"
bindLabel="label"
bindValue="label"
clearAllText="Clear"
[(ngModel)]="value" 
[searchable]="isSearchable"
placeholder="Search location"
name="location"
(open)="onOpen($event)"
[minTermLength]="2"
[searchFn]="searchFn"
(search)="keySearch($event)"
[searchWhileComposing]="false"
[editableSearchTerm]="false"
(change)="valueSelected($event)"
[appendTo]="'body'"
[loading]="searchLoading"
[class.hide-arrow]="true"
typeToSearchText="Please enter 2 or more characters"
(clear)="clearSearch()"
>

<ng-template ng-option-tmp let-item="item" let-index="index">
 <div class="d-flex justify-content-start ng-options-div">
     {{item.label.toString()}}
 </div>
</ng-template>

</ng-select>
