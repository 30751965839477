import { UserWrapper, UserWrapperFlat } from './../models/user-wrapper';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserSettings, WorkingRates, WorkingTimeResponse } from 'src/app/modules/user/models/user';
import { BaseService } from 'src/app/core/services/base.service';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  filter(option: any, offset: number = 0, limit:number=50) { 
    let url = `${this.baseURL}/users/data/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<{ count: number, result: User[] }>(url, option);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) { //latest filterAPI
    let url = `${this.baseURL}/user/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<UserWrapperFlat[]>>(url, request);
}

  getAll() {
    return this.http.get<UserWrapper[]>(`${this.baseURL}/users/`);
  }

  getById(id: number) {
    return this.http.get<User>(`${this.baseURL}/users/${id}/`);
  }

  create(user: User) {
    return this.http.post<User>(`${this.baseURL}/users/`, user);
  }

  update(id: number, user: User) {
    return this.http.put<User>(`${this.baseURL}/users/${id}/`, user);
  }

  patch(id: number, req: any) {
    return this.http.patch<User>(`${this.baseURL}/users/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/users/${id}/`);
  }

  getUsersForDropdown() {
    return this.http.get<UserWrapperFlat[]>(`${this.baseURL}/get_users/`);
  }

  getUserSettings(userId: number) {
    return this.http.get<UserSettings>(`${this.baseURL}/users/${userId}/settings/`);
  }

  updateUserSettings(userId: number, request: any) {
    return this.http.patch<UserSettings>(`${this.baseURL}/users/${userId}/settings/`, request);
  }

  assignSiptoUser(id: number, sid: string){
    return this.http.post<{status: boolean, message: string}>(`${this.baseURL}/assign/sip/user/?user_id=${id}&sip_id=${sid}`,'')
  }




  //hr


  addWorkingTime(id: number, request){
    return this.http.post<{status: boolean, message: string}>(`${this.baseURL}/dispatch/user/working_time/${id}/`,request)
  }

  getWorkingTime(id:number){

    return this.http.get<{data:WorkingTimeResponse[]}>(`${this.baseURL}/dispatch/user/working_time/?user=${id}`);
  }

  deleteWorkingTime(id: number) {
    return this.http.delete(`${this.baseURL}/dispatch/user/working_time/${id}/`);
  }

  getWorkingRates(id){
    return this.http.get<{data:WorkingRates[]}>(`${this.baseURL}/dispatch/user_working_rate/${id}/`);

  }

  updateWorkingRates(id:number, workingRates:WorkingRates[]){
    
    return this.http.put<User>(`${this.baseURL}/dispatch/user_working_rate/${id}/`, workingRates);

  }


}
