import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'serial-number-select',
  templateUrl: './serial-number-select.component.html',
  styleUrls: ['./serial-number-select.component.scss']
})
export class SerialNumberSelectComponent implements OnInit {

  @Input() sno:string="";

  @Input() qty:number;

  public snoArray:[string];

  public snoInput:string="";

  @Output() out: EventEmitter<string>= new EventEmitter();

  constructor(
    private toastr: ToastrService
  ) { }

  ngOnChange(changes: SimpleChange){

  }


  ngOnInit(): void {
    this.snoInput=this.sno;
    this.checkForComa(true);
  }

  checkForComa(init=false, onChange=false){
    let tmp:any;
    if(this.snoInput&&this.snoInput.length>0){
      tmp=this.snoInput.split(",");

      console.log(tmp);

      if(tmp.length>1){
        tmp.forEach(element => {
          this.addToArray(element);
        });
      }
      else if(onChange==true&&tmp.length>0){
        tmp.forEach(element => {
          this.addToArray(element);
        });
      }
      else if(init){
        this.addToArray(this.snoInput);
      }

    }
  }

  addToArray(value:string){

    if(value!=''){
      if(this.snoArray){
        if(this.qty>this.snoArray.length){
          this.snoArray.push(value);
        }
        // else if(this.qty==this.snoArray.length){
        //   this.toastr.error("You have added warranty numbers for the quantity");
        // }
        
      }
      else{
      this.snoArray=[value];
      }
    }

    this.snoInput="";
    this.sno=this.snoArray.map(x=>x).join(',');

    this.out.emit(this.sno);
  }

  snoDelete(index:number){
      this.snoArray.splice(index,1);
      this.sno=this.snoArray.map(x=>x).join(',');
      this.out.emit(this.sno);
  }

}
