import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path:'',
    component: CustomerListComponent,
    data:{ title: 'Tenants' }
  },
  {
    path: ':id/edit',
    component: AddCustomerComponent,
    pathMatch:'full',
    data:{ title: 'Edit Tenants' }
  },
  {
    path: 'new',
    component: AddCustomerComponent,
    pathMatch:'full',
    data:{ title: 'New Tenants' }
  },
  {
    path:':id/view',
    component: CustomerListComponent,
    data:{ title: 'Tenants' }
  },
  {
      path: '**',
      component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
