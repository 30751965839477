<div id="data-import">
    <div class="file-controls">
      <div class="file-input">
        <input type="file" class="d-none" accept=".xlsx,.xls,.csv" (change)="importFileOnChange($event)" #fileInput />
        <button (click)="fileInput.click();" class="btn btn-sm mr-1 bg-light-primary text-nowrap filter-button">
            <i class="ft-download mr-1"></i> Choose File
        </button>
        <span *ngIf="file">{{file.name}}</span>
      </div>
      <button type="button" (click)="onTemplateBtnClicked()" class="template-btn btn btn-text-center btn-sm text-nowrap gradient-pomegranate mr-1">
        Download Template
      </button>
    </div>
    <div class="delimiter-class" *ngIf="ext=='csv'">
      <select class="form-control" (change)="changedValue($event)">
        <option selected value="0">Select delimiter</option>
        <option value="1">,</option>
        <option value="2">\t</option>
        <option value="3">|</option>
      </select>
    </div>
    <div class="profile-div">
      <div class="ngselct-div" *ngIf="profile">
        <ng-select placeholder="Choose any saved profiles or Custom"
           [items]="profile"
           [(ngModel)]="selectedProfile"
           bindLabel="name"
           bindValue="details"
           (change)="onChangeNgSelect($event)">
        </ng-select>
      </div>
      <button type="button" class="custom-btn btn btn-text-center btn-sm text-nowrap gradient-pomegranate mr-1" *ngIf="csvColumns" (click)="onCustomButtonClicked()">Custom</button>
    </div>
    <div class="profile-controls-div">
      <div class="form-group profile-name-div mr-1" *ngIf="isCustom">
        <label>Profile Name</label>
        <input type="text" [(ngModel)]="profile_name" name="name" class="form-control">
      </div>
      <button [disabled]="!profile_name" *ngIf="isCustom" type="button" placement="top" ngbTooltip="Save this profile" class="btn btn-sm bg-light-secondary mr-1 profile-btn" (click)="onSaveButtonClicked()">Save</button>
      <button *ngIf="selectedProfile" type="button" placement="top" ngbTooltip="Delete this profile" class="btn btn-sm bg-light-danger profile-btn" (click)="onDeleteButtonClicked()">Delete</button>
    </div>
  <div class="columns-map">
    <ng-container *ngIf="isCustom">
      <div *ngFor="let col of columns" ngClass="columns-map-row mt-2">
        <label>{{col.display_name}}</label>
        <div class="width-50-per">
          <ng-select class="ng-select-sm"
            [(ngModel)]="col.excel_column_name"
            [items]="csvColumns"
            bindLabel="column_name"
            bindValue="column_name"
            placeholder="Choose column"
            appendTo="body"
            (change)="onChangeColumnNgselect($event, col)">
          </ng-select>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
    <button [disabled]="!file" type="button" class="btn btn-sm gradient-pomegranate mr-2" (click)="onSubmit()">Submit</button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="onCancelButtonClicked()">Cancel</button>
  </div>
</div>
