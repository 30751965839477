import { GridViewSelectComponent } from './component/grid-view-select/grid-view-select.component';
import { Views2RoleComponent } from './component/views2role/views2role.component';
import { GridMetaSelectComponent } from './component/grid-meta-select/grid-meta-select.component';
import { AddGridViewComponent } from './pages/add-grid-view/add-grid-view.component';
import { GridViewListComponent } from './pages/grid-view-list/grid-view-list.component';
import { AddGridMetaComponent } from './pages/add-grid-meta/add-grid-meta.component';
import { GridMetaListComponent } from './pages/grid-meta-list/grid-meta-list.component';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';

import { GridProfilingRoutingModule } from './grid-profiling-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    GridMetaListComponent,
    AddGridMetaComponent,
    GridViewListComponent,
    AddGridViewComponent,
    GridMetaSelectComponent,
    Views2RoleComponent,
    GridViewSelectComponent
  ],
  imports: [
    GridProfilingRoutingModule,
    NgxDatatableModule,
    NgbModule,
    NgSelectModule,
    SharedModule
  ],
  exports:[
    Views2RoleComponent
  ]
})
export class GridProfilingModule { }
