import { UnitsService } from './../../../services/units.service';

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { UnitType } from '../../../models/items';

@Component({
  selector: 'unit-type-select',
  templateUrl: './unit-type-select.component.html',
  styleUrls: ['./unit-type-select.component.scss']
})
export class UnitTypeSelectComponent implements OnInit, OnDestroy {

  @Input() value: number;

  @Input() readonly: boolean = false;

  @Output() valueChange = new EventEmitter<number>();

  public placeholder: string;

  public options: UnitType[];

  private getSub$: Subscription;

  constructor(
    private unitService: UnitsService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) {

    this.placeholder = this.hasPermission() ? "Select or add Unit" : "Select Unit";
  }

  ngOnInit() {
    this.getSub$ = this.unitService.getAll().subscribe(units => {
      this.options = units;
      this.cdr.markForCheck();
    });
  }

  openModal(content: any): void {
    this.modalService.open(content);
  }

  onValueChanged() {
    this.valueChange.emit(this.value);
  }

  onDataSubmittedSuccess(result: { response: UnitType, message: string }): void {
    this.toastr.success(result.message);
    this.options = this.options.concat(result.response);
    this.value = result.response.id;
    this.onValueChanged();
    this.modalService.dismissAll();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalService.dismissAll();
  }

  onCancel(): void {
    this.modalService.dismissAll();
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.INV_UNITS, [PermissionConstants.CREATE, PermissionConstants.GLOBAL]);
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
  }
}
