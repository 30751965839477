
<div class="group1">
    <div class="group-body">

        <div class="d-flex">
            <div
                class="col-12 col-md-6 col-lg-6 pl-0 form-group d-flex align-items-center">
                <label class="m-0 mr-2">VoiceMail Enabled :</label>
                <div
                    class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                    <input type="checkbox"
                        [(ngModel)]="user.is_voicemail_enabled"
                        name="is_voice_mail" class="custom-control-input"
                        id="color-switch-6" checked>
                    <label class="custom-control-label mr-1"
                        for="color-switch-6">
                        <span></span>
                    </label>
                </div>
            </div>

            <div
                class="col-12 col-md-6 col-lg-6 pl-0 form-group d-flex align-items-center"
                *ngIf="user.is_voicemail_enabled" style="margin-left: 11px;">
                <label class="m-0 mr-2">VoiceMail Send :</label>
                <div
                    class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                    <input type="checkbox" [(ngModel)]="user.is_voicemail_send"
                        name="is_mail_send" class="custom-control-input"
                        id="color-switch-5" checked>
                    <label class="custom-control-label mr-1"
                        for="color-switch-5">
                        <span></span>
                    </label>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-12 col-md-6 " *ngIf="user.is_voicemail_enabled">
                <label class="m-0 mr-2">VoiceMail Voice :</label>

                <ng-select
                    placeholder="Select voice"
                    [items]="voices | async"
                    bindLabel="name"
                    bindValue="value"
                    [(ngModel)]="user.voicemail_voice">
                </ng-select>
                <!--<ng-select   placeholder="Select a Type" class="form-select " [ngModelOptions]="{standalone: true}"  [(ngModel)]="user.voicemail_voice" >
                
                 <ng-option value="male">Male</ng-option>
                 <ng-option value="female">Female</ng-option>
             </ng-select>-->
            </div>

            <div class="col-12 col-md-6" *ngIf="user.is_voicemail_enabled">
                <label>Message</label>
                <textarea [(ngModel)]="user.voicemail_message"
                    name="voicemail_message" class="form-control"></textarea>
            </div>
        </div>

        <div
            class="col-md-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button type="submit" (click)="submit.emit()"
                class="btn gradient-pomegranate mb-2 mb-sm-0">
                Save
            </button>

        </div>

    </div>
</div>

<section class="common-grid-view mail_list_section"
    *ngIf="user.is_voicemail_enabled">
    <div class="list-parellax"
        [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div
                [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center pb-10 flex-space-bw"
                    [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataFilter
                        [searchFilters]="search"
                        [expanded]="expanded"
                        [columns]="columns"
                        [defaultFilters]="defaults"
                        [resetToCurrentDashData]="true"
                        [module]="module"
                        [export]="true"
                        (exportButtonClick)="bulkExport()"
                        (apply)="applyFilterGroup($event)">

                        <ng-template filterControl [column]="'smartsipgroup'"
                            let-row="row">
                            <user-group-select class="ng-select-sm"
                                [(value)]="row.value"></user-group-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable
                            [rows]="rows" class="bootstrap core-bootstrap"
                            [columnMode]="'force'"
                            [headerHeight]="50"
                            [footerHeight]="50"
                            rowHeight="auto"
                            [scrollbarH]="scrollbarH"
                            [selectionType]="SelectionType.single"
                            [count]="page.count"
                            [externalPaging]="true"
                            [offset]="page.offset"
                            [externalSorting]="true"
                            [limit]="page.limit"
                            trackByProp="sid"
                            (select)="onSelect($event)"
                            (page)="setPage($event)"
                            (sort)="onSort($event)"
                            [sorts]="[{prop:'created_on',dir:'desc'}]">

                            <!-- Row Detail Template -->
                            <ngx-datatable-row-detail [rowHeight]="75" #myDetailRow>
                                <ng-template let-row="row" let-value="value" ngx-datatable-row-detail-template>
                                <div class="row row-detail">
    
                                    <div class="col-md-1 row-item" *ngIf="expanded" style="margin-right: 25px;">
                                       
                                        <div>
                                            {{ row.created_on | dateTz:'MM/DD/YYYY h:mm a' }}
                                        </div>
                                    </div>
    
                                    <div class="col-md-2 row-item" *ngIf="expanded">
                                        <div  class="contact">
                                            
                                                <div class="avatar mr-1 ml-0" [class]="randomDpBackground(row)">
                                                    <div class="avatar-content">{{ row.customer_contact_name | shortName}}
                                                    </div>
                                                </div>
                                            
                                            
                                            <div>
                                            <span class="contact-name">{{row.customer_contact_name?row.customer_contact_name:'Unknown'}}</span>
                                            <div *ngIf="row.customer_contact_title">{{row.customer_contact_title?row.customer_contact_title:'Unknown'}}</div>
                                            </div>
                                        
                                        </div>
                                        
                                        </div>
    
                                    <div class="col-md-3 row-item" *ngIf="expanded">
                                        <div class="d-flex flex-row">
                                            <span class="number-label">From</span>
                                            <span>{{row.from_number | phoneNumber}}
                                                <!-- <img width="30" height="30" title="Call Back" (click)="callBack(row)"
                                                src="assets/img/svg/callback.svg" style="margin-top: -5px; margin-left: 7px;cursor: pointer;" /> -->
                                            </span>
                                        </div>
                                        <div class="d-flex flex-row">
                                            <span class="number-label">To</span>
                                            <span>{{row.to_number | phoneNumber}}</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                            <span class="number-label">Name</span>
                                            <span>{{row.phone_number_name}}</span>
                                        </div>
                                    </div>
    
                                    <div class="col-md-2 row-item" *ngIf="expanded">
                                        <div>Duration:{{row.duration}}</div>
                                       
                                    </div>
    
                                    <div class="col-md-2 row-item" *ngIf="expanded">
                                        <div>Route:</div>
                                        <div>{{row.incoming_route}}</div>
                                        
                                    </div>
    
                                    <div class="col-md-1 row-item" *ngIf="expanded">
    
                                        <div class="badge"
                                             [ngClass]="{'bg-light-success': row.call_log_status=='completed','bg-light-danger': row.call_log_status=='no-answer' }">
                                            {{ row.call_log_status }}
                                        </div>
                                        <div placement="left" 
                                            class="badge bg-light-danger justify-content-around d-flex mt-1" *ngIf="row.is_spam">
                                            Spam
                                        </div>

                                        <div class="badge bg-secondary justify-content-around d-flex mt-1" placement="left" *ngIf="row.group">
                                            {{row.group}}
                                        </div>
                                       
                                    </div>
    
                                    
    
                                </div>
                                </ng-template>
                                </ngx-datatable-row-detail> 

                                <ngx-datatable-column  name="Direction" [sortable]="false" [minWidth]="50">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div style="align-items: center; display: flex;"><i  aria-hidden="true"><img src="{{row.direction == 'inbound' ? '../../../../assets/img/svg/incomingcall.svg':'../../../../assets/img/svg/outgoingcall.svg' }}" alt=""></i>&nbsp;{{row.direction}}</div>
                                        <div *ngIf="!expanded">
                                            {{ row.created_on | dateTz:'MM/DD/YYYY h:mm a' }}
                                        </div>
                                    </ng-template>
                                    </ngx-datatable-column>
                                 
                                    
                                    <ngx-datatable-column [sortable]="false"  name="Customer" prop="customer_name" [minWidth]="100">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                        
                                        <div class="customer-name">
                                            <div class="badge badge-warning name-badge w-100">{{row.customer_name?row.customer_name:'Unknown'}}</div>
                                        </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    
                                    <ngx-datatable-column *ngIf="expanded" [sortable]="false" name="Answered By" prop="answered_by" [minWidth]="115">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div class="flexColDiv">
                                            <div>{{row.answered_by || '--'}}</div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    
                                    <ngx-datatable-column *ngIf="expanded"
                                    name="Recording" prop="recording_url"
                                    [sortable]="false" [width]="250"
                                    [canAutoResize]="false">
                                    <ng-template let-recording_url="value"
                                        ngx-datatable-cell-template>
                                        <audio *ngIf="recording_url" controls
                                            class="audio-control" #audioElement
                                            (play)="audioPlayerPlay(audioElement)">
                                            <source [src]="recording_url"
                                                type="audio/wav">
                                            not supported
                                        </audio>
                                    </ng-template>
                                </ngx-datatable-column>
        
                                    <ngx-datatable-column [sortable]="false" *ngIf="expanded && !isSuperAdmin" name="Suborg" prop="sub_org_name" [minWidth]="100">
                                        <ng-template let-suborg="value" ngx-datatable-cell-template>
                                            
                                            <div>
                                                <div>{{suborg}}</div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                   
                                    <ngx-datatable-column *ngIf="expanded" name="Actions"  [sortable]="false" [minWidth]="90">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                           
                                        </ng-template>
                                    </ngx-datatable-column> 
                                    <ngx-datatable-column *ngIf="expanded && isSuperAdmin" name="Tenant/Suborg"
                                                          [sortable]="false">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div>{{row.customer_name}}</div>
                                            <div>Sub org : {{row.suborg_name}}</div>
                                        </ng-template>
                                    </ngx-datatable-column>

                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template
                                    let-rowCount="rowCount"
                                    let-pageSize="pageSize"
                                    let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}}
                                        </span>
                                    </div>
                                    <datatable-pager
                                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'"
                                        [page]="curPage"
                                        [size]="pageSize"
                                        [count]="rowCount"
                                        [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>

            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="d-flex flex-row mt-2">
                        <h5 class="item-title m-0">From {{selected.call_from |
                            phoneNumber }}
                            <span #copyBtn ngxClipboard
                                [cbContent]="selected.call_from.toString()"
                                ngbTooltip="copied"
                                triggers="click:blur"
                                class="ft-copy copy-btn mr-1">
                            </span>
                            to
                            {{selected.call_to | phoneNumber }}
                            <span #copyBtn ngxClipboard
                                [cbContent]="selected.call_to"
                                ngbTooltip="copied"
                                triggers="click:blur"
                                class="ft-copy copy-btn">
                            </span>
                        </h5>
                        <div
                            class="d-flex justify-content-end align-items-start">
                            <button class="btn ml-2 p-0 no-hover font-medium-3"
                                (click)="closeDetailedView()">
                                <i class="ft-x"></i>
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
