import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GridConstants } from 'src/app/core/constants/grid.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { UIPermission } from 'src/app/core/models/common.models';
import { GFColumn, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { GridViewInfo } from 'src/app/modules/grid-profiling/models/grid-profiling.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { CatalogueDataHelper } from '../../../helpers/catalogue-data.helpers';
import { Catalog } from '../../../models/catalogue.models';
import { CatalogueService } from '../../../services/catalogue-service.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { ToastrService } from 'ngx-toastr';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-catalog',
  templateUrl: './list-catalog.component.html',
  styleUrls: ['./list-catalog.component.scss']
})
export class ListCatalogComponent implements OnInit {



  @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

  
 // @ViewChild('nav') ngbNav: NgbNav;

  //@ViewChild('nav', { static: true }) ngbNav: NgbNav;
  
  public grid: GridViewInfo;

  public isAdmin: boolean;

  public selected: Catalog;

  public defaults: GFilterParam[] = CatalogueDataHelper.DefaultFilters;

  public SelectionType = SelectionType;

  public rows: Catalog[] = [];

  public expanded: boolean = true;

  public module = ModuleConstants.CATALOG;

  public search: GFilterParam[];

  public UIPermissions:UIPermission;

  public columns: GFColumn[];

  public sort: GSortParam[] = [{ colname: 'name', direction: 'asc' }];

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public sortLookup = CatalogueDataHelper.SortLookup;

  public isAllSuborg: boolean;

  public catalogId:number=0;

  public fileUrl: string = environment.apiURL + '/static/files/';

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public listType:string = ''

  public selectedTabId:number = 1


  constructor(
    private router:Router,
    private activeRoute: ActivatedRoute,
    private ra: ResourceAccessService,
    public location:Location, 
    private cdr:ChangeDetectorRef, 
    private catalogService:CatalogueService, 
    private device: DeviceDetectorService,
    private toastr: ToastrService) {

      this.isAdmin = this.catalogService.isAdmin;
      this.grid = ra.getGridView(GridConstants.INV_ITEMS);

      // this.initFilters();
      this.scrollbarH = this.device.isMobile();
      this.isAllSuborg = catalogService.isAllSuborg;

      this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_ITEMS, ResourceConstants.INV_MODULE);
      this.initFilters()

      this.subs.sink = this.activeRoute.queryParams.subscribe(
        params => {

          

          this.listType = params.type || null;
          this.selectedTabId = this.listType == null? 1 : Number(this.listType)
        });

     }

  ngOnInit(): void {

   // localStorage.removeItem('currentGame') //

        // if (this.isAdmin || this.grid) {

          this.getData();

          // if (this.catalogId) {
          //     this.getItem();
          // }
      // }
  }

  addParameterToURL(tp) {
    const newParameter = tp; // Replace 'example' with the desired parameter value
    this.router.navigate([], {
      queryParams: { type: newParameter },
      queryParamsHandling: 'merge'
    });
  }
  initFilters() {
    this.search = this.catalogService.getsearchProductDesignFilters();
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }

  isProfiled(columName: string, admin_column = false) {
    return this.ra.isColumnProfiled(this.grid, columName, admin_column)||this.isSuperAdmin();
  }

    onSelect({ selected }): void {
      console.log('selected', selected)
      let wrapper: Catalog = selected[0];
      if (wrapper && (this.UIPermissions.viewInfo||this.isSuperAdmin())) {

          console.log('selected', this.selected)
          this.selected = wrapper;
          this.expanded = false;
          this.location.go(`/inventory/catalog/${wrapper.id}/view`);
          this.refreshDataTable();
      }
  }

  refreshDataTable() {
    setTimeout(() => {
        this.rows = [...this.rows];
        this.cdr.markForCheck();
    });
  }

  setPage(pageInfo: any) {
      let offset = pageInfo.offset * this.page.limit;
      this.page.offset = pageInfo.offset;
      this.getData(this.dataFilter?.getDataFilters() || [], offset);
  }

  onSort(event: any) {
      if (event.sorts && event.sorts.length > 0) {
          let current = event.sorts[0];
          if (!this.sortLookup[current.prop])
              return;
          let sort = new GSortParam();
          sort.colname = this.sortLookup[current.prop];
          sort.direction = current.dir;
          this.sort = [sort];
          this.getData(this.dataFilter?.getDataFilters() || []);
      }
  }

    onItemViewClose(): void {
      this.expanded = true;
      this.selected = null;
      this.catalogId = 0;
      this.location.go(`/inventory/items`);
      this.refreshDataTable();
    }

    hasPermission(resource: string, permission: string) {
      return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE)||this.isSuperAdmin();
  }



  getData(filters?: GFilterParam[], offset: number = 0) {
      console.log(this.expanded)
      let option = { sort: this.sort, filters: filters };
      if(this.expanded)
          this.subs.sink = this.catalogService.filterCatalog(option, offset).subscribe(
            // this.subs.sink = this.catalogService.getAllCatalog(option, offset).subscribe(
              response => {
                  this.rows = response.result;
                  this.setPagination(offset, response.count);
                  this.cdr.markForCheck();
              },
              () => {
                  this.rows = [];
                  this.setPagination(offset, 0);
                  this.cdr.markForCheck();
              }
          );
      else{
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/inventory/items', this.selected.id, 'view']);
      }
  }

  setPagination(offset: number, total: number) {
      this.page.count = total;
      let upperLimit = offset + this.page.limit;
      if (upperLimit > total) {
          upperLimit = total;
      }
      this.page.pageNumber = offset + '-' + upperLimit;
  }


  deleteItem(id): void {
    popup.ConfirmDelete(result => {
        if (result.isConfirmed) {
            this.subs.sink = this.catalogService.deleteCatalog(id).subscribe(
                () => {
                    this.selected = null;
                    this.expanded = true;
                    this.rows = this.rows.filter(x => x.id !== id);
                    this.cdr.markForCheck();
                    this.location.go('/inventory/catalog/list');
                    popup.CompleteDelete('Item has been deleted.');
                    this.refreshDataTable();
                },
                () => this.toastr.error('Unable to delete item')
            );
        }
    });
  }

  isSuperAdmin(){
    return this.catalogService.isSuperUser;
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

}
