import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[PreventSpecialCharacters]'
})
export class PreventSpecialCharactersDirective {

  constructor() { }

  // @HostListener('input', ['$event'])
  // onInput(event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   const value = input.value;
  //   const sanitizedValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  //   input.value = sanitizedValue;
  // }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight','ArrowUp','ArrowDown',' ']; // Add more allowed keys if needed
    if (event.key && !/^[0-9a-zA-Z]$/.test(event.key) && !allowedKeys.includes(event.key)) { //only allow 0-9/a-z/A-Z
      event.preventDefault();
    }
  }

}
