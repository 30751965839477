<div class="row">
    <div class="col-md-12 mt-2">
        <div class="stepper-wrapper">
            <div class="stepper-item" [ngClass]="didService.currentStep==1?'active':didService.currentStep>1?'completed':''">
                <div class="step-counter" [ngClass]="didService.currentStep==1?'active':didService.currentStep>1?'completed':''">
                    <i class="fa fa-file-text-o text-white" aria-hidden="true"></i>
                </div>
              <div class="step-name">Select action</div>
            </div>
            <div class="stepper-item" [ngClass]="didService.currentStep==2?'active':didService.currentStep>2?'completed':''">
                <div class="step-counter" [ngClass]="didService.currentStep==2?'active':didService.currentStep>2?'completed':''">
                    <i class="fa fa-check-square text-white" aria-hidden="true"></i>
                </div>
                <div class="step-name">Select numbers to be updated</div>
            </div>
            
            <div class="stepper-item" [ngClass]="didService.currentStep==3?'active':''">
                <div class="step-counter" [ngClass]="didService.currentStep==3?'active':''">
                    <i class="fa fa-check text-white" aria-hidden="true"></i>
                </div>
                <div class="step-name">Confirm update</div>
            </div>
        </div>
    </div>
</div>

<section class="common-grid-view" [hidden]="didService.currentStep!=1">
    <div class="container mt-3">
        <div class="row justify-content-md-center">
            <div class="col justify-content-center d-flex">
                <div class="card " [ngClass]="selectedCard === 'reassign'?'selected-card':'bulk-update-card'" (click)="selectCard('reassign')">
                    <div class="card-body align-items-center d-flex flex-column">
                      <h1 class="card-text">Reassign</h1>
                      <h6 class="card-subtitle mb-2 text-muted">Reassign from one incoming route to another incoming route</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-auto align-self-center">
              <h1 style="color: #3A1180;">OR</h1>
            </div>
            <div class="col justify-content-center d-flex">
                <div class="card " [ngClass]="selectedCard === 'rename'?'selected-card':'bulk-update-card'" (click)="selectCard('rename')">
                    <div class="card-body align-items-center d-flex flex-column">
                      <h1 class="card-text">Rename</h1>
                      <h6 class="card-subtitle mb-2 text-muted">Rename selected numbers</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-md-center p-5" *ngIf="selectedCard=='reassign'">
            <div style="width: 50%;">
                <div class="form-group">
                    <div class="d-flex justify-content-between mb-1">
                        <label class="val-required">Select Incoming Routes</label>
                        <label class="status-toggle-btn">
                            <label class="title">Global Routes :</label>
                            <div class="custom-switch custom-switch-activator custom-switch-success custom-control mb-1">
                                <input type="checkbox" [(ngModel)]="isGlobal_route" id="BulkGlobalRoute" name="active" 
                                (change)="globalRouteChange()" class="custom-control-input">
                                <label class="custom-control-label" for="BulkGlobalRoute">
                                    <span></span>
                                </label>
                            </div>
                        </label>
                        <button type="button" class="btn btn-sm gradient-pomegranate pull-right" style="position: relative; bottom: 6px;" (click)="frwdbin.openModal(frwdbin.modalContent)">&#x2B; New</button>
                    </div>
                    <forward-bin-select [suborgId]="suborgId" #frwdbin [(value)]="bulk_route" [isGlobal_Route]="isGlobal_route"></forward-bin-select>
                </div>
            </div>
        </div>
        <div class="row justify-content-md-center p-5" *ngIf="selectedCard=='rename'">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="val-required">Rename Number</label>
                    <input type="text" placeholder="Enter name" name="forwardbin_name" class="form-control" maxlength="255" [(ngModel)]="bulk_name" required>
                    <small class="danger mt-1" *ngIf="bulk_name && nameError">Name already exists</small>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="justify-content-md-center d-flex text-secondary">
        <i>* You have selected {{totalSelectedNumbers}} number(s) to update {{selectedCard=='rename'?'Name':'Incoming Route'}}</i>
    </div> -->
</section>

<section class="common-grid-view" [hidden]="didService.currentStep!=2">
    <div class="card-header d-flex align-items-center flex-space-bw">
        <datagrid-filter #dataFilter [searchFilters]="searchFilters" [defaultFilters]="defaults"
                            [columns]="columns"
                            (apply)="applyFilter($event)"
                            [module]="module"
                            [toggleId]="'unassignToggle'">

            <ng-template filterControl [column]="'area_code'" let-row="row">
                <area-code-select class="ng-select-sm" [(value)]="row.value"></area-code-select>
            </ng-template>
            <ng-template filterControl [column]="'customer'" let-row="row">
                <app-customer-select class="ng-select-sm" [(value)]="row.value"
                                        (change)="dataFilter.controlValueChanged('customer')">
                </app-customer-select>
            </ng-template>
            <ng-template filterControl [column]="'suborg'" let-row="row">
                <suborg-select class="ng-select-sm" [(value)]="row.value"
                                [tenantId]="row.dependentValue"
                                (change)="dataFilter.controlValueChanged('suborg')">
                </suborg-select>
            </ng-template>
            <ng-template filterControl [column]="'forward_bin_sid_resolved'" let-row="row">
                <forward-bin-select class="ng-select-sm" [(value)]="row.value"
                                    [suborgId]="suborgId" [show_RouteType]="true"></forward-bin-select>
            </ng-template>
        </datagrid-filter>
    </div>
    <div class="card-content">
        <div class="">
            <ngx-datatable #dataTable
                            [scrollbarH]="scrollbarH"
                            class="bootstrap core-bootstrap"
                            [columnMode]="'force'"
                            [headerHeight]="50"
                            [footerHeight]="50"
                            rowHeight="auto"
                            [rows]="rows"
                            [selectionType]="SelectionType.checkbox"
                            [count]="page.count"
                            [externalPaging]="true"
                            [offset]="page.offset"
                            [externalSorting]="true"
                            [limit]="page.limit"
                            [trackByProp]="'sid'"
                            (sort)="onSort($event)"
                            [selected]="selectedNumbers"
                            (select)="numberOnSelect($event)"
                            [sorts]="[{prop:'purchased_on',dir:'desc'}]">

                <ngx-datatable-column [sortable]="false" name="Number" prop="did">

                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                        <div class="custom-checkbox ml-1">
                            <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/> All
                        </div>
                    </ng-template>
                    

                    <ng-template let-did="value" ngx-datatable-cell-template let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <div class="custom-checkbox ml-1">
                            <input type="checkbox" [checked]="isSelected" (click)=" onCheckboxChangeFn($event,row);"/>
                        </div>
                        
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Number" prop="did">
                    <ng-template let-did="value" ngx-datatable-cell-template>
                        {{did | phoneNumber }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                <ngx-datatable-column name="Number Type" prop="did_type"
                                        [sortable]="false"></ngx-datatable-column>
                <ngx-datatable-column name="Area Code" prop="area_code">
                </ngx-datatable-column>
                <ngx-datatable-column name="Incoming Routes" prop="forward_bin_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div>{{row.forward_bin_name}}</div>
                        <div class="bold" *ngIf="row.forward_number">
                            <span
                                    *ngFor="let num of row.forward_number.split(',') as numList; let i = index">
                                <span>{{num | phoneNumber}}</span>
                                <span *ngIf="i !== numList.length-1">, </span>
                            </span>
                        </div>
                        <div class="bold" *ngIf="row.sip">
                            <span
                                    *ngFor="let num of row.sip.split(',') as numList; let i = index">
                                <span>{{num}}</span>
                                <span *ngIf="i !== numList.length-1">, </span>
                            </span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Purchased On" prop="purchased_on">
                    <ng-template let-purchase="value" ngx-datatable-cell-template>
                        {{purchase | dateTz}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="deletedRecords" name="Deleted On"
                                        prop="modified_on">
                    <ng-template let-purchase="value" ngx-datatable-cell-template>
                        {{purchase | dateTz}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="deletedRecords" name="Deleted By"
                                        prop="modified_by"></ngx-datatable-column>
                <ngx-datatable-column *ngIf="isSuperAdmin" name="Tenant/Suborg"
                                        prop="suborg_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div>{{row.customer_name}}</div>
                        <div>Sub org : {{row.suborg_name}}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Outbound SMS" prop="is_sms_outbound_active">
                    <ng-template let-status="value" ngx-datatable-cell-template>
                        <div class="badge" style="width: 5.5rem;"
                                [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                            {{ status ? 'Activated' : ' Deactivated' }}
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf="!isSuperAdmin" name="Suborg" prop="suborg_name">
                </ngx-datatable-column>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                        <div class="page-count" >
                            <span>Total {{rowCount}} Numbers </span>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </div>
    </div>
</section>



<section class="common-grid-view mt-5" [hidden]="didService.currentStep!=3">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col justify-content-center d-flex">
                <div class="card selected-card confirmCard rounded">
                    <div class="card-body align-items-center d-flex flex-column">
                      <h1 class="card-text">{{selectedCard=='rename'?'Rename':'Reassign'}}</h1>
                      <h6 class="card-subtitle mt-2 text-muted"><span>{{totalSelectedNumbers}} number(s) will be updated with new {{selectedCard=='rename'?'Name':'Incoming Route'}} : <span class="text-primary">{{selectedCard=='rename'?bulk_name:selectedBinName}}</span></span></h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col justify-content-center d-flex">
                <ul>
                    <li><i>Click <span style="color: #bf525d;"> Finish </span> To Complete Updation</i></li>
                    <li><i>Click <span style="color: #616d89;"> Previous </span> To Change Current Selection</i></li>
                </ul>
            </div>
        </div>
    </div>
</section>
