<section id="resources">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-flex flex-space-bw">
                    <h4 class="card-title">Grid Meta</h4>
                    <div class="d-flex flex-row">
                        <a [routerLink]="['/grids/meta/new']" class="btn btn-sm btn-text-center gradient-pomegranate mr-1">
                            <i class="ft-plus mr-1"></i> New
                        </a>
                        <input class="form-control form-control-sm width-200" type="text" placeholder="Search meta..." (keyup)="search($event)" />
                    </div>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <ngx-datatable class="bootstrap core-bootstrap" [scrollbarH]="true" [rows]="rows | async" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [selectionType]="SelectionType.single" (select)="onSelect($event)">
                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Description" prop="description"></ngx-datatable-column>
                            <ngx-datatable-column name="Action" [sortable]="false" prop="id" [width]="60">
                                <ng-template let-id="value" let-row="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <a class="w-100" [routerLink]="['/grids/meta',id,'edit']" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <button class="w-100 bg-light-danger" (click)="deleteMeta(id)" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                                    <div style="padding: 0 .7rem;">
                                        <span>{{rowCount}} Total</span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>