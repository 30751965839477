

  <table>
    <tr *ngFor="let row of generatedArrayRows; let rowIndex = index">
      <td *ngFor="let column of generatedArrayColumns ; let colIndex = index">
        <div class="cell-box" [attr.id] =  "rowIndex +'-'+ colIndex"
         [ngClass]="{'cell-active' : row.isSelected == true && column.isSelected == true}" 
         (mouseover)="handleMouseOver(rowIndex,  colIndex)"
         (click)="setRowColCount()"
         ></div>
      </td>
    </tr>
  </table>
  