<div class="row form-group">
    <div class="col-12 col-md-12">
        <label class="val-required">Select SIP or SIP Group to receive voicemail</label>
        <div class="status-toggle-btn mb-2">
            <label class="title mb-0 badge badge-info">SIP</label>
            <div class="custom-switch custom-switch-activator custom-switch-secondary user_or_group custom-control">
                <input type="checkbox" [(ngModel)]="model.user_or_group" id="user_or_group" name="active" class="custom-control-input">
                <label class="custom-control-label" for="user_or_group">
                    <span></span>
                </label>
            </div>
            <label class="title mb-0 badge badge-secondary">SIP Group</label>
        </div>
        <div *ngIf="!model.user_or_group">
            <app-sip-select [userAssignedSip]="true" [multiple]="false" (valueChange)="model.sip_username=$event" [value]="model.sip_username" [suborgId]="subOrg_id"></app-sip-select>
        </div>
        <user-group-select *ngIf="model.user_or_group" [(value)]="model.user_group" [smartSipGroup]="true"></user-group-select>
    </div>
</div>
