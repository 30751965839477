import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import * as popup from 'src/app/core/utils/popup.functions';
import { IPagingView, Pagination, GFilterParam, GSortParam, DataFilterRequest, GFColumn, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UIPermission } from 'src/app/core/models/common.models';
import { LocationType } from '../../models/location-types';
import { LocationTypeService } from '../../services/location-type.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-location-types',
  templateUrl: './location-types.component.html',
  styleUrls: ['./location-types.component.scss']
})
export class LocationTypesComponent implements OnInit, OnDestroy, IPagingView {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild('formModal') postModal: any;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public searchFilters: GFilterParam[] = [
    { displayName: 'Name', colname: 'location_type_name', condition: 'contains', value: '', operator: 'AND' }
  ];

  public defaults: GFilterParam[] = [
    { displayName: "All Location Types", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Active Location Types", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
    { displayName: "Inactive Location Types", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
  ];

  public columns: GFColumn[] = [
    { displayName: 'Name', columnName: 'location_type_name', columnType: GFColumnTypes.string },
    { displayName: 'Created On', columnName: 'created_on', columnType: GFColumnTypes.date },
    { displayName: 'Created User', columnName: 'created_user', columnType: GFColumnTypes.ref }
  ];

  public sort: GSortParam[];

  public sortLookup = { name: 'location_type_name',created_on: 'created_on' };

  public SelectionType = SelectionType;

  public rows: LocationType[];

  public locationTypeId: number;

  public expanded: boolean = true;

  public selected: LocationType;

  public Permissions = PermissionConstants;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public UIPermissions: UIPermission;

  constructor(private modalService: NgbModal,
    private service: LocationTypeService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private device: DeviceDetectorService) {

    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.CUSTOMER_LOCATION_TYPES, ResourceConstants.CUSTOMER_MODULE);
  }

  ngOnInit(): void {
    this.scrollbarH = this.device.isMobile();
    this.getData(null);
  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let request: DataFilterRequest = { filters: filters, sort: this.sort }

    this.subs.sink = this.service.getAll(request, offset).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    let current: LocationType = selected[0];
    if (current && this.UIPermissions.viewInfo) {
      this.locationTypeId = current.id;
      this.selected = current;
      this.expanded = false;
      this.refreshDataTable();
    }
  }

  openModal(id: number = 0) {
    this.locationTypeId = id;
    this.modalService.open(this.postModal, { centered: true });
  }

  closeModal() { this.modalService.dismissAll(); }

  onDataSubmittedSuccess(result: { response: LocationType, message: string }): void {
    this.toastr.success(result.message);
    if (this.page.count < this.page.limit) {
      this.rows = [...this.rows.filter(w => w.id !== this.locationTypeId), result.response];
      this.page.count = this.rows.length;
    }
    this.selected = result.response;
    this.expanded = false;
    this.refreshDataTable();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
    });
  }

  deleteRow(id: number) {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.service.delete(id).subscribe(
          () => {
            popup.CompleteDelete('Location Type has been deleted.');
            this.rows = this.rows.filter(x => x.id !== id);
            this.cdr.markForCheck();
            this.closeDetailedView();
          },
          () => this.toastr.error('Unable to delete row')
        );
      }
    });
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter.getDataFilters(), offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop]) return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(this.dataFilter.getDataFilters());
    }
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }

  changeStatus() {

    let status = this.selected.is_active;

    let popupOption = {
      title: `Are you sure you want to ${status ? 'Activate Location Type' : 'Deactivate Location Type'}?`,
      text: this.selected.name,
      current: status,
    };

    popup.ConfirmActivate(popupOption, result => {

      if (!result.isConfirmed) {
        this.selected.is_active = !status;
        this.cdr.markForCheck();
        return;
      }

      this.subs.sink = this.service.patch(this.selected.id, { is_active: status }).subscribe(
        () => { },
        () => {
          this.selected.is_active = !status;
          this.toastr.error("Failed to change status");
          this.cdr.markForCheck();
        }
      );
    });
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }
}
