<section class="purchase-section card">
    <div class="card-body d-flex flex-row">
        <form name="form" #formElement="ngForm" class="col-12 col-md-3 pl-0"
              (ngSubmit)="formElement.form.valid && searchPhoneNumbers()" novalidate>
            <h5 class="section-title">Search Available Phone Numbers</h5>
            <div class="form-group" *ngIf="isSuperAdmin">
                <label class="val-required">Tenant</label>
                <app-customer-select [(value)]="tenantId"></app-customer-select>
            </div>
            <div class="form-group" *ngIf="isSuperAdmin || allSuborgs">
                <label class="val-required">Sub Org</label>
                <suborg-select [tenantId]="tenantId" [(value)]="searchRequest.suborg_id" [readonly]="!tenantId" (change)="suborgChange()"></suborg-select>
            </div>
            <div class="form-group">
                <label class="val-required">Area Code</label>
                <input type="text" mask="000" class="form-control" name="area_code" [(ngModel)]="searchRequest.area_code" required />
            </div>
            <div class="form-group">
                <label class="val-required">Quantity</label>
                <input type="number" [(ngModel)]="searchRequest.quantity" class="form-control" name="quantity" min="0" required PreventSpecialCharacters>
            </div>
            <div class="form-group">
                <label class="val-required">Number Type</label>
                <select class="form-control" [(ngModel)]="searchRequest.number_type" name="num-type" required>
                    <option [value]="didTypes.LOCAL">Local</option>
                    <option [value]="didTypes.TOLLFREE">Toll Free</option>
                </select>
            </div>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [(ngModel)]="searchRequest.random" name="random"
                       id="random">
                <label class="custom-control-label" for="random">
                    <span>Random</span>
                </label>
            </div>
            <button [disabled]="!formElement.form.valid" class="btn btn-info w-100 mt-3">Search Numbers</button>
        </form>
        <div class="col-12 col-md-7 d-flex flex-column">
            <h5 class="section-title">Available Phone Numbers <span *ngIf="numbers">({{numbers?.length}})</span></h5>
            <div class="available-numbers d-flex flex-1">
                <p class="m-auto" *ngIf="numbers && !numbers.length">No Numbers Found</p>
                <div class="phone-number" *ngFor="let num of numbers; let i = index">
                    <div class="inner"
                         [ngClass]="{'bg-light-secondary':!num.selected,'btn-outline-success':num.selected}"
                         (click)="addToCart(num)">
                        {{num.phone_number | phoneNumber }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2 pr-0 d-flex flex-column">
            <h5 class="section-title">Selected Numbers ({{selectedNumbers.length}})</h5>
            <div class="selected-numbers flex-1">
                <ng-container *ngFor="let selected_num of selectedNumbers; let i = index;">
                    <div class="phone-number d-flex align-items-center">
                        <div class="inner bg-success w-100">
                            {{selected_num.phone_number | phoneNumber }}
                            <span class="ft ft-x float-right" (click)="removeFromCart(selected_num)"></span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <button *ngIf="selectedNumbers.length" class="btn gradient-pomegranate mt-1"
                    (click)="openPurchaseModal(purchaseModal)">Purchase</button>
        </div>
    </div>

    <ng-template #purchaseModal let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Purchase</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!submitted">
                <div *ngIf="selectedNumbers.length">
                    <h5>
                        Total {{selectedNumbers.length}} x $ {{purchaseRate}} = <span
                              class="text-success">${{ selectedNumbers.length * purchaseRate || '0'}}</span>
                    </h5>
                </div>
                <div class="form-group col-12 p-0">
                <div class="d-flex justify-content-between">
                    <label class="val-required">Incoming Routes</label>
                    <label class="status-toggle-btn">
                        <label class="title">Global Routes :</label>
                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control mb-1">
                            <input type="checkbox" [(ngModel)]="isGlobalRoute" id="GlobalRoute"
                                name="active"
                                class="custom-control-input">
                            <label class="custom-control-label" for="GlobalRoute">
                                <span></span>
                            </label>
                        </div>
                    </label>
                    <button type="button" class="btn btn-sm gradient-pomegranate pull-right" style="position: relative; bottom: 6px;" (click)="frwdbin.openModal(frwdbin.modalContent)">&#x2B; New</button>
                </div>
                    <forward-bin-select
                        #frwdbin
                        [isGlobal_Route]="isGlobalRoute"
                        [suborgId]="searchRequest.suborg_id"
                        [(value)]="model.forward_bin">
                    </forward-bin-select>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span>Phone Numbers</span>
                    <button class="btn btn-sm mb-1" [ngClass]="{'btn-success':copied,'btn-default':!copied}"
                            (click)="copyToClipboard()">
                        <i class="fa fa-files-o mr-1"></i> {{copied ? 'Copied' : 'Copy'}}
                    </button>
                </div>
                <div *ngIf="selectedNumbers.length" class="pn_div">
                    <button class="btn btn-sm pnumbtn bg-light-secondary"
                            *ngFor="let num of selectedNumbers; let i = index;">
                        {{num.phone_number | phoneNumber }}
                    </button>
                </div>
            </div>
            <div *ngIf="submitted">
                <div *ngIf="purchased_numbers">
                    <label>Purchased Numbers</label>
                    <div class="pn_div mb-2">
                        <button class="btn btn-sm pnumbtn bg-light-success" *ngFor="let num of purchased_numbers">
                            {{num | phoneNumber }}
                        </button>
                    </div>
                </div>
                <div *ngIf="non_purchased_numbers!=''">
                    <label>Numbers that weren't able to be purchased</label>
                    <div class="pn_div">
                        <button class="btn btn-sm pnumbtn bg-light-danger" *ngFor="let num of non_purchased_numbers">
                            {{num | phoneNumber }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="!submitted" type="button" [disabled]="!model.forward_bin" class="btn gradient-pomegranate mr-1" (click)="purchaseDids()">
                Buy Now
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="closeModal()">
                {{submitted ? 'Close' : 'Cancel'}}
            </button>
        </div>
    </ng-template>
</section>
