import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridConstants } from 'src/app/core/constants/grid.constants';
import { GFColumn, GFilterParam } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ProductDataHelper } from '../helpers/product-data.helpers';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {

  constructor(private http: HttpClient, private ra: ResourceAccessService) {

    super()
   }

   getFilterColumns(): GFColumn[] {
    let columns: GFColumn[] = ProductDataHelper.FilterColumns;
    return this.ra.filterGFColumns(columns, GridConstants.INV_ITEMS);
  }

  getSearchColumns(): GFilterParam[] {
    let columns: GFilterParam[] = ProductDataHelper.SearchFilters;
    return this.ra.filterSearchColumns(columns, GridConstants.INV_ITEMS);
  }
  
}
