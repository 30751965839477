import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CustomerLocation } from 'src/app/modules/tenant-customers/models/tenant-customer';

@Component({
  selector: 'geo-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {

  @ViewChildren('suggestItem') suggestItem: QueryList<ElementRef>;

  @ViewChild('geoSearch') geoSerach: NgSelectComponent;


  public searchSub$:any;

  public searchLoading:boolean=false;

  public searchKey:string='';

  public searchSuggestions:BehaviorSubject<any[]>=new BehaviorSubject([]);

  public searchReqCount:number=0;

  public isSearchable:boolean=true;

  public lastRequestAt:any;

  public searchTimmer:any;

  private OSMProvider = new OpenStreetMapProvider({
    params: {
      'accept-language': 'en', // render results in Dutch
      countrycodes: 'us', // limit search results to the country
      addressdetails: 1, // include additional address detail parts
    },
  });

  @Output() valueChange=new EventEmitter<any>();

  @Output() ngModelChange=new EventEmitter<any>();

  @Output() clear=new EventEmitter<any>();

  @Input() value:string;

  @Input() size:string='md';

  // @ViewChild(NgSelectComponent, { static: false }) select: NgSelectComponent;

  constructor(private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {

    if(this.value&&this.value.length){
        this.isSearchable=false;
        this.searchSuggestions.next([{label:this.value}]);

     }
  }

  valueSelected(event){

    if(event&&event.label){
      this.isSearchable=false;
      this.searchSuggestions.next([{label:event.label}]);
      this.valueChange.emit(event);
      this.ngModelChange.emit(event.label);

    }

  }

  searchFn(term, item){

    return item;
  }


  keySearch(event:any, timmer=false){

    let timeNow=moment();

    let diff=0;

    if(this.lastRequestAt){

     diff= timeNow.diff(this.lastRequestAt);
    }

    this.lastRequestAt=timeNow;


    console.log(diff, 'the diff');

    console.log(timeNow, 'time now');

    if(diff<500){
      if(this.searchTimmer){
        clearTimeout(this.searchTimmer);
      }
      this.searchTimmer=setTimeout(() => {

        this.keySearch(event, true);
        
      }, 510);
    }
    else{

      if(event&&event.term){

        this.searchKey=event.term;
  
        if(event.term!=""){
          this.addressSearch2(event.term);
        }
        else{
          this.searchSuggestions.next([]);
        }
  
      }
      else{
          this.searchSuggestions.next([]);

      }

    }




  }

  async addressSearch2(term:string){
    // async addressSearch(term: string, item: any){
      this.searchLoading=true;
      this.searchReqCount++;
      console.log('You are here');
      if(term){
        let query=term;

        if(this.searchSub$){
          this.searchSub$.cancel();
        }
       this.searchSub$=await this.OSMProvider.search({query:query}).then(val=>{
          this.searchSuggestions.next(val);
          this.cdr.markForCheck();
          
          if(val.length)
          this.suggestItem.toArray()[0]?.nativeElement.focus()


          this.searchReqCount--;

          if(this.searchReqCount==0){
            this.searchLoading=false;

          }

        },()=>{
          this.searchReqCount--;


          if(this.searchReqCount==0){
            this.searchLoading=false;

          }

        })
      }

  }

  onOpen(event){

    if(this.searchKey==''){
      this.geoSerach.close();
    }
    console.log('On open');

  }

  clearSearch(){

    this.value="";
    this.isSearchable=true;
    this.searchSuggestions.next([]);
    this.clear.emit();
  }

  ngOnDestroy(){
    if(this.searchSub$)
    this.searchSub$.unsubscribe();
  }

}
