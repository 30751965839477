import { LocationSystemTypeSelectComponent } from './components/select/location-system-type-select/location-system-type-select.component';
import { DistributorCustomersComponent } from './components/distributor-customers/distributor-customers.component';
import { DistributorsComponent } from './pages/distributors/distributors.component';
import { LicenseNumberDistributorsComponent } from './components/license-number-distributors/license-number-distributors.component';
import { DistributorLocationSelectComponent } from './components/select/distributor-location-select/distributor-location-select.component';
import { DistributorContactSelectComponent } from './components/select/distributor-contact-select/distributor-contact-select.component';
import { DistributorLocationFormComponent } from './components/forms/distributor-location-form/distributor-location-form.component';
import { DistributorContactsComponent } from './components/distributor-contacts/distributor-contacts.component';
import { CustomerSystemTypesComponent } from './pages/customer-system-types/customer-system-types.component';
import { LicenseNumberCustomersComponent } from './components/license-number-customers/license-number-customers.component';
import { LicenseNumberFormComponent } from './components/forms/license-number-form/license-number-form.component';
import { CustomerLocationFormComponent } from './components/forms/customer-location-form/customer-location-form.component';
import { CustomerLocationSelectComponent } from './components/select/customer-location-select/customer-location-select.component';
import { CustomerContactSelectComponent } from './components/select/customer-contact-select/customer-contact-select.component';
import { TenantCustomerSelectComponent } from './components/select/tenant-customer-select/tenant-customer-select.component';
import { UserModule } from './../user/user.module';

import { InventoryModule } from './../inventory/inventory.module';
import { PaymentTermSelectComponent } from './components/select/payment-term-select/payment-term-select.component';
import { IndustrySelectComponent } from './components/select/industry-select/industry-select.component';
import { CurrencySelectComponent } from './components/select/currency-select/currency-select.component';
import { CustomerTypeSelectComponent } from './components/select/customer-type-select/customer-type-select.component';
import { IndustryFormComponent } from './components/forms/industry-form/industry-form.component';
import { CustomerTypeFormComponent } from './components/forms/customer-type-form/customer-type-form.component';
import { PaymentTermsComponent } from './pages/payment-terms/payment-terms.component';
import { CurrenciesComponent } from './pages/currencies/currencies.component';
import { IndustriesComponent } from './pages/industries/industries.component';
import { CustomerTypesComponent } from './pages/customer-types/customer-types.component';
import { AddTenantCustomerComponent } from './pages/add-tenant-customer/add-tenant-customer.component';
import { NgModule } from '@angular/core';

import { TenantCustomersRoutingModule } from './tenant-customers-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { TenantCustomersComponent } from './pages/tenant-customers/tenant-customers.component';
import { CurrencyFormComponent } from './components/forms/currency-form/currency-form.component';
import { PaymentTermFormComponent } from './components/forms/payment-term-form/payment-term-form.component';
import { CustomerLocationsComponent } from './components/customer-locations/customer-locations.component';
import { CustomerContactsComponent } from './components/customer-contacts/customer-contacts.component';
import { CustomerContactFormComponent } from './components/forms/customer-contact-form/customer-contact-form.component';
import { LicenseNumberComponent } from './pages/license-number/license-number.component';
import { LicenseNumberSelectComponent } from './components/select/license-number-select/license-number-select.component';
import { LicenseNumberCommentsComponent } from './components/license-number-comments/license-number-comments.component';
import { CustomerSystemTypeFormComponent } from './components/forms/customer-system-type-form/customer-system-type-form.component';
import { CustomerSystemTypeSelectComponent } from './components/select/customer-system-type-select/customer-system-type-select.component';
import { DistributorLocationsComponent } from './components/distributor-locations/distributor-locations.component';
import { DistributorContactFormComponent } from './components/forms/distributor-contact-form/distributor-contact-form.component';
import { AddDistributorComponent } from './pages/add-distributor/add-distributor.component';
import { DistributorSelectComponent } from './components/select/distributor-select/distributor-select.component';
import { LocationTypesComponent } from './pages/location-types/location-types.component';
import { LocationTypeFormComponent } from './components/forms/location-type-form/location-type-form.component';
import { LocationTypeSelectComponent } from './components/select/location-type-select/location-type-select.component';
import { LocationSystemTypesComponent } from './pages/location-system-types/location-system-types.component';
import { LocationSystemTypeFormComponent } from './components/forms/location-system-type-form/location-system-type-form.component';
import { ImportantMessagesComponent } from './pages/important-messages/important-messages.component';
import { ImportantMessagesFormComponent } from './components/forms/important-messages-form/important-messages-form.component';
import { ShippingMethodsComponent } from './pages/shipping-methods/shipping-methods.component';
import { ShippingMethodsFormComponent } from './components/forms/shipping-methods-form/shipping-methods-form.component';
import { ShippingMethodsSelectComponent } from './components/select/shipping-methods-select/shipping-methods-select.component';
import { CallCenterInfoComponent } from '../ring2voice/components/call-center-info/call-center-info.component';
import { GeoLocationSelectorComponent } from './components/geo-location-selector/geo-location-selector.component';
import { PaymentsModule } from '../payments/payments.module';
import { ContactsComponent } from './pages/contacts/contacts.component';


@NgModule({
  declarations: [
    TenantCustomersComponent,
    AddTenantCustomerComponent,
    CustomerTypesComponent,
    IndustriesComponent,
    CurrenciesComponent,
    PaymentTermsComponent,
    CustomerTypeFormComponent,
    CurrencyFormComponent,
    IndustryFormComponent,
    PaymentTermFormComponent,
    CustomerTypeSelectComponent,
    CurrencySelectComponent,
    IndustrySelectComponent,
    PaymentTermSelectComponent,
    CustomerLocationsComponent,
    CustomerContactsComponent,
    TenantCustomerSelectComponent,
    CustomerContactSelectComponent,
    CustomerLocationSelectComponent,
    CustomerContactFormComponent,
    CustomerLocationFormComponent,
    LicenseNumberComponent,
    LicenseNumberFormComponent,
    LicenseNumberSelectComponent,
    LicenseNumberCommentsComponent,
    LicenseNumberCustomersComponent,
    CustomerSystemTypesComponent,
    CustomerSystemTypeFormComponent,
    CustomerSystemTypeSelectComponent,
    DistributorContactsComponent,
    DistributorLocationsComponent,
    DistributorLocationFormComponent,
    DistributorContactFormComponent,
    DistributorContactSelectComponent,
    DistributorLocationSelectComponent,
    LicenseNumberDistributorsComponent,
    AddDistributorComponent,
    DistributorsComponent,
    DistributorSelectComponent,
    DistributorCustomersComponent,
    LocationTypesComponent,
    LocationTypeFormComponent,
    LocationTypeSelectComponent,
    LocationSystemTypesComponent,
    LocationSystemTypeFormComponent,
    LocationSystemTypeSelectComponent,
    ImportantMessagesComponent,
    ImportantMessagesFormComponent,
    ShippingMethodsComponent,
    ShippingMethodsFormComponent,
    ShippingMethodsSelectComponent,
    GeoLocationSelectorComponent,
    ContactsComponent,
  ],
  imports: [
    TenantCustomersRoutingModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    InventoryModule,
    UserModule, 
    PaymentsModule
  ],
  exports: [
    TenantCustomerSelectComponent,
    CustomerTypeSelectComponent,
    IndustrySelectComponent,
    PaymentTermSelectComponent,
    CurrencySelectComponent,
    CustomerContactSelectComponent,
    CustomerLocationSelectComponent,
    LicenseNumberSelectComponent,
    ShippingMethodsSelectComponent,
    CustomerContactFormComponent,
    CustomerContactsComponent
    
  ],
  providers: [
    CallCenterInfoComponent,
    CustomerContactFormComponent,
    CustomerContactSelectComponent,
    CustomerLocationSelectComponent,
  ]
})
export class TenantCustomersModule { }
 