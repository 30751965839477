import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { ShippingMethods } from '../models/shipping-methods.models';
@Injectable({
  providedIn: 'root'
})
export class ShippingMethodsService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll(suborg_id:number = this.suborgId) {
    return this.http.get<ShippingMethods[]>(`${this.baseURL}/sales_app/shipment_method/?suborg=${suborg_id}`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0) {
    let url = `${this.baseURL}/sales_app/shipmentmethod/filter/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<ShippingMethods[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<ShippingMethods>(`${this.baseURL}/sales_app/shipment_method/${id}/`);
  }

  create(model: ShippingMethods) {
    return this.http.post<ShippingMethods>(`${this.baseURL}/sales_app/shipment_method/`, model);
  }

  update(id: number, model: ShippingMethods) {
    return this.http.put<ShippingMethods>(`${this.baseURL}/sales_app/shipment_method/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<ShippingMethods>(`${this.baseURL}/sales_app/shipment_method/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/sales_app/shipment_method/${id}/`);
  }
}
