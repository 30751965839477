import { UserGroupService } from './../../services/user-group.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { UserGroup } from '../../models/user';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';

@Component({
  selector: 'user-group-select',
  templateUrl: './user-group-select.component.html',
  styleUrls: ['./user-group-select.component.scss']
})
export class UserGroupSelectComponent implements OnInit {

  private _value: number;

  get value(): number { return this._value; }

  @Input() set value(val: number) {
    this._value = val;
    if (val > 0 && this.selected !== val) {
      this.setValue(val);
    }
    else {
      this._selected = val;
    }
  };

  private _selected: number;

  public get selected(): number { return this._selected; }

  public set selected(value: number) {
    this._selected = value;
    this.valueChange?.emit(value);
  }

  @Input() readonly:boolean = false;

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<any>();

  @Input() addButton: boolean = true;

  @Input() fetchWhenOpen: boolean = false;

  @Input() smartSipGroup: boolean;

  public options: Observable<UserGroup[]>;

  public totalCount: number = 0;

  public currentCount: number = 0;

  public textInput = new Subject<string>();

  public initial: UserGroup;

  public firstOpen: boolean = true;

  constructor(private service: UserGroupService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) { }

  ngOnInit() {
    if (!this.fetchWhenOpen) {
      this.loadInitial();
    }
  }

  loadInitial() {
    this.options = concat(this.getData(), this.textInput.pipe(
      filter(res => res !== null && res.length >= 2),
      distinctUntilChanged(),
      switchMap(term => this.getData(term))
    ));
  }

  getData(search?: string) {
    let option = {
      sort: [{ colname: 'gropuname', direction: 'asc' }],
      filters: search ? [{ colname: 'gropuname', condition: 'contains', value: search, operator: 'AND' }] : null,
      base_filters: this.smartSipGroup? [{ colname: 'smart_sip_group', condition: 'equalto', value: true, operator: 'AND' }] : []
    };
    return this.service.getAll(option).pipe(
      map(resp => {
        if (this.initial && this.initial.id === this.selected) {
          resp.result = resp.result.filter(item => item.id !== this.selected).concat(this.initial);
        }
        this.totalCount = resp.count;
        this.currentCount = resp.result.length;
        return resp.result;
      })
    );
  }

  setValue(id: number) {
    this.options = this.service.getById(id).pipe(map(response => {
      this.initial = response;
      return [response];
    }));
    this._selected = id;
  }

  selectOpen() {
    if (this.fetchWhenOpen && (!this.options || this.firstOpen)) {
      this.loadInitial();
      this.firstOpen = false;
    }
  }

  trackByFn(item: UserGroup) { return item.id; }

  openModal(content: any): void { this.modalService.open(content, { centered: true }); }

  onValueChanged(group: UserGroup) {
    this.change?.emit(group);
  }

  onDataSubmittedSuccess(result: { response: UserGroup, message: string }): void {
    this.toastr.success(result.message);
    this.options = concat(this.options, of([result.response]));
    this.selected = result.response.id;
    this.onValueChanged(result.response);
    this.modalService.dismissAll();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalService.dismissAll();
  }

  onCancel(): void { this.modalService.dismissAll(); }
}
