import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { ModuleConstants } from './../../../../core/enums/common.enum';
import { SubSink } from 'subsink';
import { GridConstants } from './../../../../core/constants/grid.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from './../../../../core/constants/permissions.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ItemWrapper } from './../../models/items';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from './../../services/item.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { Location } from '@angular/common';
import { GridViewInfo } from 'src/app/modules/grid-profiling/models/grid-profiling.models';
import { environment } from 'src/environments/environment';
import { ItemDataHelper } from '../../helpers/item-data.helpers';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataImportMeta, UIPermission } from 'src/app/core/models/common.models';

@Component({
    selector: 'app-item-list',
    templateUrl: './item-list.component.html',
    styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit, OnDestroy {

    @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

    public SelectionType = SelectionType;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public rows: ItemWrapper[] = [];

    public columns: GFColumn[];

    public defaults: GFilterParam[] = ItemDataHelper.DefaultFilters;

    public search: GFilterParam[];

    public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public sortLookup = ItemDataHelper.SortLookup;

    public sort: GSortParam[] = [{ colname: 'part_number', direction: 'asc' }];

    public selected: ItemWrapper;

    public expanded: boolean = true;

    private itemId: number;

    public Permissions = PermissionConstants;

    public Resource = ResourceConstants;

    public grid: GridViewInfo;

    public isAdmin: boolean;

    public module = ModuleConstants.ITEM;

    public subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public importColumns: DataImportMeta[] = [
        { table_name: 'Items', actual_column_name: 'item_name', display_name: 'Items - Name', excel_column_name: undefined },
        { table_name: 'Items', actual_column_name: 'part_number', display_name: 'Items - PartNumber', excel_column_name: undefined },
        { table_name: 'Items', actual_column_name: 'part_description', display_name: 'Items - Description', excel_column_name: undefined },
        { table_name: 'Items', actual_column_name: 'category', display_name: 'Items - Category Name', excel_column_name: undefined },
        { table_name: 'Items', actual_column_name: 'type', display_name: 'Items - ItemTypes', excel_column_name: undefined },
        { table_name: 'Items', actual_column_name: 'manufacturer', display_name: 'Items - Manufacturers', excel_column_name: undefined },
        { table_name: 'Items', actual_column_name: 'unit_types', display_name: 'Items - UnitTypes', excel_column_name: undefined },
        { table_name: 'Items', actual_column_name: 'sku', display_name: 'Items - SKU', excel_column_name: undefined },
        { table_name: 'Items', actual_column_name: 'item_price', display_name: 'Items - Item Price', excel_column_name: undefined }
    ];

    public isAllSuborg: boolean;

    public UIPermissions:UIPermission;

    constructor(route: ActivatedRoute,
        private itemService: ItemService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private ra: ResourceAccessService,
        public location: Location,
        private device: DeviceDetectorService,
        private router: Router) {

        this.isAdmin = this.itemService.isAdmin;
        this.subs.sink = route.params.subscribe(params => this.itemId = +params.id || 0);
        this.grid = ra.getGridView(GridConstants.INV_ITEMS);
        this.initFilters();
        this.scrollbarH = this.device.isMobile();
        this.isAllSuborg = itemService.isAllSuborg;

        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_ITEMS, ResourceConstants.INV_MODULE);

        if (this.isAllSuborg) {
            this.columns.push({ displayName: 'Sub Org', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentValue: this.itemService.customerId });
        }
    }

    initFilters() {
        this.columns = this.itemService.getFilterColumns();
        this.search = this.itemService.getSearchColumns();
    }

    ngOnInit(): void {
        if (this.isAdmin || this.grid) {

            this.getData();

            if (this.itemId) {
                this.getItem();
            }
        }
    }

    getItem() {
        this.subs.sink = this.itemService.getById(this.itemId).subscribe(item => {
            this.selected = item;
            this.expanded = false;
            this.refreshDataTable();
        });
    }

    getData(filters?: GFilterParam[], offset: number = 0) {
        console.log(this.expanded)
        let option = { sort: this.sort, filters: filters };
        if(this.expanded)
            this.subs.sink = this.itemService.getAll(option, offset).subscribe(
                response => {
                    this.rows = response.result;
                    this.setPagination(offset, response.count);
                    this.cdr.markForCheck();
                },
                () => {
                    this.rows = [];
                    this.setPagination(offset, 0);
                    this.cdr.markForCheck();
                }
            );
        else{
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/inventory/items', this.selected.id, 'view']);
        }
    }

    onSelect({ selected }): void {
        let wrapper: ItemWrapper = selected[0];
        if (wrapper && this.UIPermissions.viewInfo) {
            this.selected = wrapper;
            this.expanded = false;
            this.location.go(`/inventory/items/${wrapper.id}/view`);
            this.refreshDataTable();
        }
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop])
                return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter?.getDataFilters() || []);
        }
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    deleteItem(id: number): void {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.itemService.delete(id).subscribe(
                    () => {
                        this.selected = null;
                        this.expanded = true;
                        this.rows = this.rows.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        this.location.go('/inventory/items');
                        popup.CompleteDelete('Item has been deleted.');
                        this.refreshDataTable();
                    },
                    () => this.toastr.error('Unable to delete item')
                );
            }
        });
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onItemViewClose(): void {
        this.expanded = true;
        this.selected = null;
        this.itemId = 0;
        this.location.go(`/inventory/items`);
        this.refreshDataTable();
    }

    hasPermission(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], ResourceConstants.INV_MODULE);
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        this.getData(this.dataFilter?.getDataFilters() || [], offset);
    }

    isProfiled(columName: string, admin_column = false) {
        return this.ra.isColumnProfiled(this.grid, columName, admin_column);
    }

    applyFilter(params: GFilterParam[]) {
        this.getData(params);
    }

    onImportSubmit(request: any) {
        this.subs.sink = this.itemService.importItems(request).subscribe(
            resp => {
                this.toastr.success('Import has started you will be emailed when it is finished.');
                this.getData()
            },
            err => {
                this.toastr.error('Import failed')
            }
        );
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
