import { Subscription } from 'rxjs';
import { CustomerService } from './../../services/customer.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { Customer } from '../../models/customer';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  customerId: number;

  getSub$: Subscription;

  customer: Customer;

  accountForm = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    paid: new FormControl(false),
    active: new FormControl(false)
  });

  constructor(private customerService: CustomerService, private datePipe: DatePipe) {
    const customerId = localStorage.getItem(AppConstants.CUSTOMER_ID);
    if (customerId) {
      this.customerId = parseInt(customerId);
    }
  }

  ngOnInit() {
    if (this.customerId > 0) {
      this.getSub$ = this.customerService.getById(this.customerId).subscribe(customer => {
        this.customer = customer;
        this.setValues(customer);
      });
    }
  }

  setValues(customer: Customer) {
    if (customer && customer.account) {
      this.accountForm.patchValue({
        start_date: this.datePipe.transform(customer.account.start_date, 'dd-MM-yyyy'),
        end_date: this.datePipe.transform(customer.account.end_date, 'dd-MM-yyyy'),
        paid: customer.account.paid,
        active: customer.account.active,
      });
    }
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
  }
}
