import { ChecklistService } from './../../services/checklist.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { Pagination, GFilterParam, GSortParam, DataFilterRequest, GFColumn, GFColumnTypes } from 'src/app/core/models/grid-filter.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import { Task } from '../../models/common.models';
import * as popup from 'src/app/core/utils/popup.functions';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit, OnDestroy {

  @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

  @ViewChild('formModal') postModal: any;

  public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public defaults: GFilterParam[] = [
    { displayName: "All Tasks", colname: '', condition: 'is', value: null, operator: 'AND' }
  ];

  public searchFilters: GFilterParam[] = [
    {
      displayName: 'Combo Search', colname: '', condition: '', value: '',
      children: [
        { displayName: 'Task Name', colname: 'task_name', condition: 'contains', value: '', operator: 'OR' }
      ]
    }
  ];

  public columns: GFColumn[] = [
    { displayName: 'Task Name', columnName: 'task_name', columnType: GFColumnTypes.string }
  ];

  public sort: GSortParam[];

  public sortLookup = { name: 'task_name' };

  public SelectionType = SelectionType;

  public rows: Task[];

  public taskId: number;

  public expanded: boolean = true;

  public selected: Task;

  public model:Task;

  public nameExist: boolean = false;

  public Permissions = PermissionConstants;

  public subs: SubSink = new SubSink();

  public scrollbarH: boolean = false;

  public UIPermissions: UIPermission;

  constructor(private modalService: NgbModal,
    private checklistService: ChecklistService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private device: DeviceDetectorService) {

    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.ST_ESCALATION_PERIODS, ResourceConstants.SERVICE_TICKET_MODULE);
  }

  ngOnInit(): void {
    this.scrollbarH = this.device.isMobile();
    this.getData(null);
  }

  getData(filters: GFilterParam[], offset: number = 0) {

    let request: DataFilterRequest = { filters: filters, sort: this.sort }

    this.subs.sink = this.checklistService.getFiltered(request, offset).pipe(
      catchError(err => of({ count: 0, result: [] }))
    ).subscribe(response => {
      this.rows = response.result;
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }

  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }

  onSelect({ selected }) {
    
    let current: Task = selected[0];
    if (current && this.UIPermissions.viewInfo) {
      this.taskId = current.id;
      this.selected = current;
      this.expanded = false;
      this.refreshDataTable();
      this.cdr.markForCheck();
    }
  }

  openModal(id: number = 0) {
   
    if(id>0){
      //this.model = this.selected;

      this.subs.add(this.checklistService.getById(id).subscribe(resp => {
        this.model = resp;
        this.cdr.markForCheck();
    }));

    }else{
      this.model = this.checklistService.createModel(Task)
    }
    this.taskId = id;
    this.modalService.open(this.postModal, { centered: true, size: 'lg', scrollable: true });
  }

  closeModal() { this.modalService.dismissAll(); }

  onDataSubmittedSuccess(result: { response: Task, message: string }): void {
    this.toastr.success(result.message);
    if (this.page.count < this.page.limit) {
      this.rows = [...this.rows.filter(w => w.id !== this.taskId), result.response];
      this.page.count = this.rows.length;
    }
    this.selected = result.response;
    this.taskId = this.selected.id;
    this.expanded = false;
    this.refreshDataTable();
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.rows = [...this.rows];
      this.cdr.markForCheck();
    });
  }

  setPage(pageInfo: any) {
    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter.getDataFilters(), offset);
  }

  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop]) return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      this.getData(this.dataFilter.getDataFilters());
    }
  }

  deleteRow(id: number) {
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.checklistService.delete(id).subscribe(
          () => {
            popup.CompleteDelete('Task has been deleted.');
            this.rows = this.rows.filter(x => x.id !== id);
            this.cdr.markForCheck();
            this.closeDetailedView();
          },
          () => this.toastr.error('Unable to delete row')
        );
      }
    });
  }

  applyFilter(params: GFilterParam[]) { this.getData(params); }

  changeStatus() {

    let status = this.selected.is_active;

    let popupOption = {
      title: `Are you sure you want to ${status ? 'Activate Task' : 'Deactivate Task Period'}?`,
      text: this.selected.name,
      current: status,
    };

    popup.ConfirmActivate(popupOption, result => {

      if (!result.isConfirmed) {
        this.selected.is_active = !status;
        this.cdr.markForCheck();
        return;
      }

      this.subs.sink = this.checklistService.patch(this.selected.id, { is_active: status }).subscribe(
        () => { },
        () => {
          this.selected.is_active = !status;
          this.toastr.error("Failed to change status");
          this.cdr.markForCheck();
        }
      );
    });
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }


  onSubmit(): void {

    let api: Observable<Task>;

    if (this.taskId > 0)
      api = this.checklistService.update(this.taskId, this.model);
    else {
      api = this.checklistService.create(this.model);
    }

    this.subs.sink = api.subscribe(
      response => {
        let msg = this.taskId > 0 ? 'Task info updated' : 'New Task created';
        this.onDataSubmittedSuccess({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.onDataError(error);
        }
      }
    );
  }

  changeGlobalSettings(){

    let global = this.selected.is_global;

        let popupOption = {
            title: `Are you sure you want to ${global ? 'Globalize settings' : 'Non Globalize settings'}?`,
            text: "",
            current: global,
        };
        popup.ConfirmActivate(popupOption, result => {
            if (!result.isConfirmed) {
                this.selected.is_global = !global;
                this.cdr.markForCheck();
                return;
            }
            
            this.subs.sink = this.checklistService.patch(this.selected.id, { is_global: global }).subscribe(
              () => { },
              () => {
                this.selected.is_global = !global;
                this.toastr.error("Failed to change status");
                this.cdr.markForCheck();
              }
            );

        });
    
  }

}
