<section class="add-tenant-customer">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">
                <b style="color: #370D7E;">{{serviceTicketId > 0 ? 'Edit ' : 'New '}}</b>
                <b style="color: #D52095;">Service Ticket</b>
                <b class = "text-secondary ml-2" *ngIf = "callCenter">(For : {{from_Number_name == 'null' ? 'Unknown' : from_Number_name}} )</b>
            </h4>
        </div>
        <div class="card-content">
            <div class="card-body" *ngIf="model">
                <form name="form" #formElement="ngForm" (ngSubmit)="formElement.form.valid && submit()" novalidate>
                    <div class="row">
                        <div class="col-12 col-md-4">

                            <div class="group" style="background: #F6F5FD 0% 0% no-repeat padding-box">
                            <div class="form-group" *ngIf="model.unique_number">
                                <input class="form-control" type="text" [value]="model.unique_number" readonly />
                            </div>
                            <div class="form-group">
                                <label>Title</label>
                                <input class="form-control" type="text" [(ngModel)]="model.title" name="title" />
                            </div>
                            <div class="form-group">
                                <label class="val-required">Customer</label>
                                <tenant-customer-select
                                    class="select-md"
                                    [(value)]="model.tenant_customer"
                                    (change)="customerChanged($event)"
                                    [suborg_id]="model.suborg">
                                </tenant-customer-select>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Customer Contact</label>
                                <tenant-customer-contact-select
                                [tenantCustomerId]="model.tenant_customer"
                                [(value)]="model.tenant_customer_contact"
                                [readonly]="!model.tenant_customer"
                                (change)="customerContactChanged($event)"
                                (locationAdd)="onCustomerLocationAdded()">
                                </tenant-customer-contact-select>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Customer Location</label>
                                <tenant-customer-location-select
                                #locationSelect
                                [tenantCustomerId]="model.tenant_customer"
                                [(value)]="model.tenant_customer_location"
                                [readonly]="!model.tenant_customer"
                                (change)="customerLocationChanged($event)">
                                </tenant-customer-location-select>
                            </div>

                            <div class="form-group" style="margin-top: 36px;">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [(ngModel)]="model.is_billable" (ngModelChange)="onBillableChanged($event)" name="is_billable" id="billable">
                                    <label class="custom-control-label" for="billable">
                                      <span>Billable</span>
                                  </label>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="form-group">
                            <label class="mb-2">{{serviceTicketId > 0 ? 'Comments' : 'Comment'}}</label>
                            <app-comments #stcomment [uniqueId]="serviceTicketId" [timelineView]="serviceTicketId > 0" [moduleId]="7" [suborgId]="model.suborg"></app-comments>
                        </div>
                        </div>
                        <div class="col-12 col-md-4 d-flex flex-column">
                            <div class="group">
                                <div class="form-group">
                                    <label>Reason for Service</label>
                                    <call-reason-select [fetchWhenOpen]="true" [(value)]="model.reason_for_call"
                                        [readonly]="!model.tenant_customer" [suborg_id]="model.suborg"
                                        (change)="callReasonChange($event)">
                                    </call-reason-select>
                                    <div class="bg-light-danger border border-danger rounded mt-1 p-2"
                                        *ngIf="model.reason_for_call_message&&model.reason_for_call">
                                        <span>{{model.reason_for_call_message}}</span>

                                        <div class="mt-2 justify-content-end d-flex"
                                            *ngIf="model.confirmation_required&&model.message_isuser_confirmation==null">
                                            <button class="btn btn-sm btn-secondary mr-1"
                                                (click)="model.message_isuser_confirmation=false">Not Confirm</button>
                                            <button class="btn btn-sm btn-primary"
                                                (click)="model.message_isuser_confirmation=true">Confirm</button>
                                        </div>
                                        <div class="mt-2 justify-content-end d-flex"
                                            *ngIf="model.message_isuser_confirmation!=null">
                                            <span class="text-success" *ngIf="model.message_isuser_confirmation"><i
                                                    class="ft-check-circle text-success"></i> <small>Confirmed</small>
                                            </span>
                                            <span class="text-danger" *ngIf="!model.message_isuser_confirmation"><i
                                                    class="ft-x-circle text-danger"></i> <small>Not confirmed </small>
                                            </span>
                                        </div>

                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Priority</label>
                                    <priority-select [suborg_id]="model.suborg" (change)="priorityChange($event)"
                                        [fetchWhenOpen]="true" [(value)]="model.priority"></priority-select>
                                </div>

                                <div class="form-group">
                                    <label>Escalation Period</label>
                                    <escalation-select [suborg_id]="model.suborg" [fetchWhenOpen]="true"
                                        [(value)]="model.escalation_period">
                                    </escalation-select>
                                </div>

                                <div class="form-group">
                                    <label>Next Action</label>
                                    <action-select [suborg_id]="model.suborg" [fetchWhenOpen]="true"
                                        [(value)]="model.next_action"></action-select>
                                </div>

                                <div class="form-group" *ngIf="!callCenter">
                                    <label>Expected Service Date</label>
                                    <app-date-picker [(value)]="model.expected_service_start_date"
                                        [valueFormat]="'yyyy-MM-dd hh:mm:ss'">
                                    </app-date-picker>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-4 d-flex flex-column">

                            <div class="group">
                            <div class="form-group">
                                <label>Assign To User</label>
                                <user-select [(value)]="model.assigned_user" [readonly]="model.assigned_user_group > 0" [multiple]="false">
                                </user-select>
                            </div>
                            <div class="or-seperator text-info">OR</div>
                            <div class="form-group">
                                <label>Assign To User Group</label>
                                <user-group-select [fetchWhenOpen]="true" [(value)]="model.assigned_user_group" [readonly]="model.assigned_user > 0"></user-group-select>
                            </div>
                            <div class="form-group">
                                <label class="position-relative width-100">Task</label>
                                <div class="flex-1">
                                    <task-select [suborg_id]="model.suborg" [fetchWhenOpen]="true" [(value)]="model.task"></task-select>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="!callCenter">
                                <label>Product Type</label>
                                <product-type-select [suborg_id]="model.suborg" [fetchWhenOpen]="true" [(value)]="model.product_type">
                                </product-type-select>
                            </div>

                            <!-- <div class="form-group">
                                <label>Associated Item</label>
                                <item-select class="select-md" [selectBox]="true" [(value)]="model.associate_item"></item-select>
                            </div>
                            <div class="form-group">
                                <label>Assembly</label>
                                <kit-select class="select-md" [selectBox]="true" [(value)]="model.associate_kit"></kit-select>
                            </div> -->
                            </div>

                            <br />
                            <div class="customer-logo w-100" *ngIf="!callCenter">
                                <app-file-uploader [limit]="5" [primary]="model.primary_image" [files]="images"
                                    [multiple]="true" (delete)="onFileDelete($event)"></app-file-uploader>
                            </div>

                        </div>
                    </div>
                    <br />
                    <!-- <h5>Materials</h5> -->
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <sales-so-items [soId]="soId"
                            [submitted]="submitted"
                            [tax]="tax"
                            [showButton] = "isCustomer? false : true"
                            [suborg_id]="model.suborg"
                            #kitItemGrid>
                            </sales-so-items> -->
                            <!-- <st-items [st_id]="serviceTicketId"
                            [submitted]="submitted"
                            [tax]="model.tax"
                            [suborg_id]="model.suborg"
                            #MatrialGrid>
                            </st-items> -->
                        </div>
                    </div>
                    <div class="row-seperator"></div>
                    <div class="row mt-4">
                        <div class="col-12 col-md-12 d-flex flex-sm-row flex-column justify-content-end">
                            <button type="submit" [disabled]="!formElement.form.valid"
                                class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                                {{serviceTicketId > 0 ? 'Save Changes' : 'Save'}}
                            </button>
                            <button type="button" (click)="onCancel()" class="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
