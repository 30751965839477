import { Component, Input, OnInit } from '@angular/core';
import { MenuSettings } from 'src/app/modules/ring2voice/models/forward-bin.models';

@Component({
  selector: 'ring2voice-menu-settings',
  templateUrl: './menu-settings.component.html',
  styleUrls: ['./menu-settings.component.scss']
})
export class MenuSettingsComponent implements OnInit {

  public noKeyPressed: string = 'Hang up';

  public unAssignedKey: string = 'Hang up';

  @Input() d;

  @Input() route;

  @Input() activeMenuInputs=[];

  public menuNumbers = [
    { id: 'hangup', name: 'Hang up' },
    { id: '1', name: 'Follow 1' },
    { id: '2', name: 'Follow 2' },
    { id: '3', name: 'Follow 3' },
    { id: '4', name: 'Follow 4' },
    { id: '5', name: 'Follow 5' },
    { id: '6', name: 'Follow 6' },
    { id: '7', name: 'Follow 7' },
    { id: '8', name: 'Follow 8' },
    { id: '9', name: 'Follow 9' }
  ];

  constructor() {}

  ngOnInit(): void {
    this.isNumberSelected();

    if(this.route.menu_settings?.no_key_is_pressed){
      if(this.route.menu_settings?.no_key_is_pressed=='hangup'){
        this.noKeyPressed = 'Hang up';
      }else{
        this.noKeyPressed = this.menuNumbers.find((menuItem) => menuItem.id === this.route.menu_settings?.no_key_is_pressed).name;
      }
    }else{
      this.route.menu_settings = new MenuSettings();
    }
    if(this.route.menu_settings?.unassigned_key_is_pressed){
      if(this.route.menu_settings?.unassigned_key_is_pressed=='hangup'){
        this.unAssignedKey = 'Hang up';
      }else{
        this.unAssignedKey = this.menuNumbers.find((menuItem) => menuItem.id === this.route.menu_settings?.unassigned_key_is_pressed.toString()).name;
      }
    }else{
      this.route.menu_settings = new MenuSettings();
    }
  }

  isNumberSelected() {
    this.menuNumbers = this.menuNumbers.filter(item => item.id === 'hangup' || this.activeMenuInputs.includes(Number(item.id)));
    
  }

  noKeyChange(){
    this.route.menu_settings.no_key_is_pressed = this.noKeyPressed;
  }

  unAssignedChange(){
    this.route.menu_settings.unassigned_key_is_pressed = this.unAssignedKey;
  }

}
