import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PermissionConstants } from "src/app/core/constants/permissions.constants";
import { ResourceConstants } from "src/app/core/constants/resources.constants";
import { RouteGuard } from "src/app/core/gaurd/route.guard";
import { CartViewComponent } from "./pages/cart-view/cart-view.component";
import { CatalogCustomerViewComponent } from "./pages/catalog-customer-view/catalog-customer-view/catalog-customer-view.component";
import { CustomerCatalogListComponent } from "./pages/catalog-customer-view/customer-catalog-list/customer-catalog-list.component";
import { InvoiceAddComponent } from "./pages/invoice-add/invoice-add.component";
import { InvoiceListComponent } from "./pages/invoice-list/invoice-list.component";
import { InvoiceComponent } from "../sales-shared/sales-invoice/invoice/invoice.component";
import { NotificationComponent } from "./pages/notification/notification.component";
import { ProposalComponent } from "./pages/proposal/proposal.component";
import { SalesOrdersAddComponent } from "./pages/sales-orders-add/sales-orders-add.component";
import { SalesOrdersListComponent } from "./pages/sales-orders-list/sales-orders-list.component";
//import { SalesOrdersComponent } from "./pages/sales-orders/sales-orders.component";
import { ServiceContractAddComponent } from "./pages/service-contract-add/service-contract-add.component";
import { ServiceContractListComponent } from "./pages/service-contract-list/service-contract-list.component";
//import { ServiceContractComponent } from "./pages/service-contract/service-contract.component";
import { TermsAddComponent } from "./pages/terms-add/terms-add.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { WarrantyAddComponent } from "./pages/warranty-add/warranty-add.component";
import { WarrantyListComponent } from "./pages/warranty-list/warranty-list.component";
//import { WarrantyComponent } from "./pages/warranty/warranty.component";
import { PageNotFoundComponent } from "src/app/shared/components/page-not-found/page-not-found.component";

const routes: Routes =[

    {
        path: 'catalog',
        component: CustomerCatalogListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_CATALOG,
            permissions: [PermissionConstants.VIEW],
            title: 'Catalog'
        }
    },
    {
        path: 'catalog/:id/salesview',
        component: CatalogCustomerViewComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_CATALOG,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Catalog sales view'
        }
    },
    {
        path: 'invoice',
        component: InvoiceListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_INVOICES,
            permissions: [PermissionConstants.VIEW],
            title: 'Invoices'
        }
    },
    {
        path: 'invoice/:id/view',
        component: InvoiceListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_INVOICES,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Invoice view'
        }
    },
    {
        path: 'invoice/add',
        component: InvoiceAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_INVOICES,
            permissions: [PermissionConstants.CREATE],
            title: 'New Invoice'
        }
    },
    {
        path: 'invoice/:id/edit',
        component: InvoiceAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_INVOICES,
            permissions: [PermissionConstants.MODIFY],
            title: 'Invoice edit'
        }
    },
    {
        path: 'sales-orders',
        component: SalesOrdersListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_SALES_ORDERS,
            permissions: [PermissionConstants.VIEW],
            title: 'Sales Order'
        }
    },
    {
        path: 'sales-orders/:id/view',
        component: SalesOrdersListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_SALES_ORDERS,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Sales Order'
        }
    },
    {
        path: 'sales-orders/add',
        component: SalesOrdersAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_SALES_ORDERS,
            permissions: [PermissionConstants.CREATE],
            title: 'Create Sales Orders'
        }
    },
    {
        path: 'sales-orders/:id/edit',
        component: SalesOrdersAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_SALES_ORDERS,
            permissions: [PermissionConstants.MODIFY],
            title: 'Edit sales order'
        }
    },
    {
        path: 'service-contracts',
        component: ServiceContractListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_SERVICE_CONTRACTS,
            permissions: [PermissionConstants.VIEW],
            title: 'Service Contracts'
        }
    },
    {
        path: 'service-contracts/:id/view',
        component: ServiceContractListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_SERVICE_CONTRACTS,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'View Service Contract'
        }
    },
    {
        path: 'service-contracts/add',
        component: ServiceContractAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_SERVICE_CONTRACTS,
            permissions: [PermissionConstants.CREATE],
            title: 'Create Service Contract'
        }
    },
    {
        path: 'service-contracts/:id/edit',
        component: ServiceContractAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_SERVICE_CONTRACTS,
            permissions: [PermissionConstants.MODIFY],
            title: 'Modify Service Contract'
        }
    },
    {
        path: 'warranty-add',
        component: WarrantyAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_WARRANTY,
            permissions: [PermissionConstants.CREATE],
            title: 'Create warranty'
        }
    },
    {
        path: 'warranty',
        component: WarrantyListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_WARRANTY,
            permissions: [PermissionConstants.VIEW],
            title: 'Warranty'
        }
    },
    {
        path: 'warranty/:id/view',
        component: WarrantyListComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_WARRANTY,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Warranty advance view'
        }
    },
    {
        path: 'warranty/:id/edit',
        component: WarrantyAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_WARRANTY,
            permissions: [PermissionConstants.MODIFY],
            title: 'Warranty Edit'
        }
    },
    {
        path: 'terms-conditions',
        component: TermsComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_TERMS,
            permissions: [PermissionConstants.VIEW],
            title: 'Terms and conditions'
        }
    },
    {
        path: 'terms-conditions/add',
        component: TermsAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_TERMS,
            permissions: [PermissionConstants.CREATE],
            title: 'Create Terms and conditions '
        }
    },
    {
        path: 'terms-conditions/:id/edit',
        component: TermsAddComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_TERMS,
            permissions: [PermissionConstants.MODIFY],
            title: 'Edit Terms and conditions '
        }
    },
    {
        path: 'terms-conditions/:id/view',
        component: TermsComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_TERMS,
            permissions: [PermissionConstants.VIEW_INFO],
            title: 'Advance view'
        }
    },
    {
        path: 'notification',
        component: NotificationComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            permissions: [PermissionConstants],
            title: 'Notifications'
        }
    },
    {
        path: 'cart-view',
        component: CartViewComponent,
        pathMatch: 'full',
        canActivate: [RouteGuard],
        data:{
            globalResource: ResourceConstants.SALES_MODULE,
            resource: ResourceConstants.SALES_INVOICES,
            permissions: [PermissionConstants.VIEW],
            title: 'Cart View'
        }
    },
    {
      path: '**',
      component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SalesRoutingModule {}
