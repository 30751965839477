<span class="badge bg-warning ml-3" *ngIf="counter>9&&smart_sip_group">Maximum selection reached</span>
<div class="d-flex mb-1">
    <a class="btn btn-info btn-text-center btn-sm text-nowrap mr-1"
        (click)="getGroupUsers()" style ="width:60px">
        All
    </a>
    <a class="btn btn-success btn-text-center btn-sm text-nowrap  mr-1"
        (click)="filterOnlineOffline(1)" style ="width:60px">
        Online
    </a>
    <a class="btn btn-danger btn-text-center btn-sm text-nowrap"
        (click)="filterOnlineOffline(2)" style ="width:60px">
        Offline
    </a>
</div>
<table class="user-select-table w-100">
    <thead>
        <tr>
            <th *ngIf="smart_sip_group">Preferred Users</th>
            <th>Name</th>
            <th *ngIf="smart_sip_group">SIP</th>
            <th *ngIf="smart_sip_group&&viewMode">Last time on call</th>
            <th *ngIf="smart_sip_group&&viewMode">Currently on call</th>
            <th>Role</th>
            <th>Email</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of rows| orderBy:'first_name';let index=index; ">
            <td [width]="10" *ngIf="smart_sip_group">
                <input #checkBox type="checkbox" [(ngModel)]="row.preffered_user" (click)="checked(row)" name="preferred" 
                    [ngStyle]="{'pointer-events': viewMode||(!checkBox.checked&&counter>9) ? 'none':'','cursor':!viewMode?'pointer':''}">
            </td>
            <td>
                <div class="d-flex align-items-center">
                    <div class="avatar mr-1 ml-0">
                        <div class="avatar-content">{{ (row.first_name + ' ' + row.last_name) | shortName }}</div>
                    </div>
                    <div>{{row.first_name}} {{row.last_name}}</div>
                </div>
            </td>
            <td *ngIf="smart_sip_group">
                <div class="d-flex align-items-center"><img src="assets/img/caller assets/agent.svg" class="avatar" height="20" width="20" [ngClass]="{'agentOnline': row.user_status=='Ready'||row.user_status=='On Call','agentBusy': row.user_status=='Busy', 'agentOffline':row.user_status=='Offline'}">
                    <div class="ml-1">{{row.sip_name}}</div>
                </div>
            </td>
            <td *ngIf="smart_sip_group&&viewMode">
                <div class="d-flex align-items-center" style="width: max-content;">
                    {{ moment(row.call_end_time).fromNow() }}
                </div>
            </td>
            <td *ngIf="smart_sip_group&&viewMode">
                <div class="badge" [ngClass]="{'bg-light-success': row.user_status=='On Call','bg-light-danger': row.user_status != 'On Call' }">
                    {{ row.user_status=='On Call' ? 'Yes' : 'No' }}
                </div>
            </td>
            <td>{{row.role_name}}</td>
            <td>
                <a [href]="'mailto:'+row.email">{{row.email}}</a>
            </td>
            <td class="d-flex justify-content-center">
                <button (click)="removeSelected(row)" class="btn text-danger no-hover p-0" type="button">
                    <i class="ft-trash-2"></i>
                </button>
            </td>
        </tr>
        <tr *ngIf="!rows || rows.length <= 0">
            <td colspan="4">No user selected.</td>
        </tr>
    </tbody>
</table>
<div class="link-section mt-1" *ngIf="!viewMode">
    <a class="text-info" (click)="openModal(userSelectModal)">
        <i class="ft-plus"></i> Add User
    </a>
</div>

<ng-template #userSelectModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Select Users</h5>
        <div class="justify-content-end d-flex ml-auto">
            <button type="button" class="btn-sm gradient-pomegranate text-white border-0 mr-1" (click)="removeUnassignedUsers()">Unselect Unassigned Users</button>
            <input placeholder="Search..." class="form-control form-control-sm width-200 ml-auto" type="text" (keyup)="searchUser($event)" />
        </div>
        <button type="button" class="close ml-1" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <ngx-datatable class="bootstrap core-bootstrap" [rows]="users | orderBy:'first_name'" [columnMode]="'force'" rowHeight="auto" [selectionType]="SelectionType.checkbox" [selected]="selectedUsers" (select)="userOnSelect($event)" (activate)="onActivate($event)">
            <ngx-datatable-column prop="sip_name" [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                    <div class="custom-checkbox ml-1">
                        <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
                    </div>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                    <div class="custom-checkbox ml-1">
                        <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" [disabled]="!row.sip_name&&smart_sip_group&&!isSelected"/>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="first_name">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                        <div class="avatar mr-1 ml-0">
                            <div class="avatar-content">{{ (row.first_name + ' ' + row.last_name) | shortName }}</div>
                        </div>
                        <div>{{row.first_name}} {{row.last_name}}</div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Role" prop="role_name"></ngx-datatable-column>
            <ngx-datatable-column name="Email" prop="email">
                <ng-template let-email="value" ngx-datatable-cell-template>
                    <span class="pr-1">{{email}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="SIP" prop="sip_name" *ngIf="smart_sip_group">
                <ng-template let-row="row" let-sip_name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center"><img *ngIf="smart_sip_group&&sip_name" src="assets/img/caller assets/agent.svg" class="avatar" height="20" width="20" [ngClass]="{'agentOnline': row.user_status=='Ready','agentBusy': row.user_status=='Busy', 'agentOffline':row.user_status=='Offline'}">
                            <div class="ml-1" *ngIf="sip_name; else assignButton">{{sip_name}}</div>
                        </div>
                    </div>
                    <ng-template #assignButton >
                        <button class="btn btn-sm gradient-pomegranate" (click)="openAssignModal(assignSip,row.id)">Assign</button>
                    </ng-template>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
    <div class="modal-footer">
        <span class="selectedCount">Selected {{selectedUsersCount}}/{{totalUsersCount}}</span>
        <button (click)="closeModal()" class="btn btn-sm btn-secondary">Cancel</button>
        <button (click)="applySelection()" class="btn btn-sm btn-primary">Apply Selection</button>
    </div>
</ng-template>

<ng-template #assignSip let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Assign SIP</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="">SIPs</label>
            <app-user-sip-select [value]="sipservice.assignSiptoUser" [multiple]="false" [suborgId]="suborg_id"></app-user-sip-select>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"  class="btn gradient-pomegranate" (click)="assignSipOnSave()">Save</button>
        <button type="button" class="btn" (click)="closeAssignModal()">Cancel</button>
    </div>
</ng-template>