import { CustomerLocation, CustomerRep, CustomerRoles, QuickContactRequest, TenantCustomer } from './../models/tenant-customer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { CustomerContact } from '../models/tenant-customer';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LicenseNumber } from '../models/license-number';
import { DataFilterResponse, GFilterGroup, GFilterParam } from 'src/app/core/models/grid-filter.models';

@Injectable({
  providedIn: 'root'
})
export class TenantCustomerService extends BaseService {

  public externalFilter:GFilterParam[]=[];

  constructor(private http: HttpClient) { super() }

  getAllTenantCustomers(suborg_id = this.suborgId){
    return this.http.get<TenantCustomer[]>(`${this.baseURL}/tenant_customer/?suborg=${suborg_id}`);
  }

  getAll(option: any, offset: number = 0, suborg_id:number = this.suborgId) {
    let url = `${this.baseURL}/tenant_customer/filter/?limit=${this.dataLimit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<{ count: number, result: TenantCustomer[] }>(url, option).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getById(id: number) {
    return this.http.get<TenantCustomer>(`${this.baseURL}/tenant_customer/${id}/`);
  }

  create(model: TenantCustomer) {
    return this.http.post<TenantCustomer>(`${this.baseURL}/tenant_customer/`, model);
  }

  update(id: number, model: TenantCustomer) {
    return this.http.put<TenantCustomer>(`${this.baseURL}/tenant_customer/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<TenantCustomer>(`${this.baseURL}/tenant_customer/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/tenant_customer/${id}/`);
  }

  getCustomerContacts(tenant_customer_id: number) {
    return this.http.get<CustomerContact[]>(`${this.baseURL}/tenant_customer/${tenant_customer_id}/contacts/`);
  }

  getCustomerContactsFiltered(option: any={}, limit: number=0, offset: number = 0, hideLoading:boolean=false, suborg_id:number = this.suborgId) {

    const headers = new HttpHeaders().set('Suborg', suborg_id.toString())
    if(hideLoading){
      headers.set('Hideloading', 'true');
    }

    let url = `${this.baseURL}/filter_customer_contacts/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<CustomerContact[]>>(url, option, { headers: headers }).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getCustomerContactById(contactid: number) {
    return this.http.get<CustomerContact>(`${this.baseURL}/tenant_customer/contacts/${contactid}/`);
  }

  createCustomerContact(model: CustomerContact) {
    return this.http.post<CustomerContact>(`${this.baseURL}/tenant_customer/contacts/`, model);
  }

  createQuickCustomerContact(model:QuickContactRequest) {
    return this.http.post<CustomerContact>(`${this.baseURL}/tenant/customer/contacts/calllog/`, model)
  }

  updateCustomerContact(id: number, model: CustomerContact) {
    return this.http.put<CustomerContact>(`${this.baseURL}/tenant_customer/contacts/${id}/`, model);
  }

  patchCustomerContact(id: number, request: any) {
    return this.http.patch<CustomerContact>(`${this.baseURL}/tenant_customer/contacts/${id}/`, request);
  }

  deleteCustomerContact(id: number) {
    return this.http.delete(`${this.baseURL}/tenant_customer/contacts/${id}/`);
  }

  getCustomerLocations(tenant_customer_id: number) {
    return this.http.get<CustomerLocation[]>(`${this.baseURL}/tenant_customer/${tenant_customer_id}/locations/`);
  }

  getCustomerLocationsFiltered(option: any, limit: number, offset: number = 0) {
    let url = `${this.baseURL}/filter_customer_locations/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<CustomerLocation[]>>(url, option).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  createCustomerLocation(model: CustomerLocation) {
    return this.http.post<CustomerLocation>(`${this.baseURL}/tenant_customer/locations/`, model);
  }

  getCustomerLocationDetails(id:number, suborg_id:number = this.suborgId){
    return this.http.get<CustomerLocation>(`${this.baseURL}/tenant_customer/locations/${id}/?suborg=${suborg_id}`);
  }

  updateCustomerLocation(id: number, model: CustomerLocation) {
    return this.http.put<CustomerLocation>(`${this.baseURL}/tenant_customer/locations/${id}/`, model);
  }

  patchCustomerLocation(id: number, request: any) {
    return this.http.patch<CustomerLocation>(`${this.baseURL}/tenant_customer/locations/${id}/`, request);
  }

  deleteCustomerLocation(id: number) {
    return this.http.delete(`${this.baseURL}/tenant_customer/locations/${id}/`);
  }

  getAccountRep(customerId: number) {
    return this.http.get<CustomerRep[]>(`${this.baseURL}/tenant_customer/${customerId}/accountrep/`);
  }

  getLicenseNumbers(customerId: number) {
    return this.http.get<LicenseNumber>(`${this.baseURL}/tenant_customer/${customerId}/license/`);
  }

  importCustomers(file: any) {

    let formData = new FormData();
    console.log(file);
    formData.append("file", file.file);
    formData.append("map_object", JSON.stringify(file.columns_map));
    formData.append('customer', this.customerId.toString());
    formData.append('option', file.option.toString());
    return this.http.post(`${this.baseURL}/tenant_customer/import/`, formData);
  }

  getCustomerRoles(){
    return this.http.get<CustomerRoles[]>(`${this.baseURL}/customer_roles/`);
  }
}
