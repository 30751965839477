<section id="item-view">
    <div class="d-flex flex-row mt-2">
        <h5 class="item-title m-0">{{assemble?.kit_name}}</h5>
        <div class="d-flex justify-content-end align-items-start">
            <button class="btn btn-sm ml-1 font-medium-3 pt-0 pb-0" (click)="closeItemView()">
                <i class="ft-x"></i>
            </button>
        </div>
    </div>
    <div class="col-12 col-md-12 p-2">
        <ng-container *ngIf="hasPermission(Resource.INV_ITEMS_STOCK,Permissions.VIEW)">
            <div class="group mt-3">
                <div class="group-header d-flex flex-row">
                    Select Stock Location
                </div>
                <section>
                    <table class="item-stock-table w-100">
                        <thead>
                            <tr>
                                <th>WareHouse</th>
                                <th>Warehouse Location</th>
                                <th>Serial Number</th>
                                <th [width]="150">Available Qty</th>
                                <th>Select Qty </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows;let i = index">
                                <td class="p-2">
                                    {{row.warehouse_name}}
                                </td>
                                <td class="P-2">
                                    {{row.location_name}}
                                </td>
                                <td>
                                    {{row.serial_number}}
                                </td>
                                <td class="quantity-td">
                                    {{row.quantity}}
                                </td>
                                <td>
                                    <input type="number" width="40" [disabled]="row.quantity==0"
                                           (change)="getAggregateQuantity()"
                                           class="select-qty-input form-control" placeholder="Enter quantity"
                                           min="0" [(ngModel)]="row.selected_qty" />
                                    <span class="text-danger" *ngIf="row.selected_qty>row.quantity">Qty over
                                        selected</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <td class="text-bold-500">Total Selected Qty</td>
                                <td class="text-bold-500 quantity-td">{{total_quantity}}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </ng-container>
    </div>
</section>
