import { Pipe, PipeTransform } from '@angular/core';
import * as momentTz from 'moment-timezone';
import { userInfo } from 'os';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { User } from 'src/app/modules/user/models/user';


@Pipe({
    name: 'dateTz'
})
export class timezoneCheckPipe implements PipeTransform {


    transform(date: any, format:string='MM/DD/YYYY'): any {

        var User:User= JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));


        let utc=momentTz(date).tz("utc");

        let localTz=momentTz.tz.guess();

        if(User.time_zone&&User.time_zone!=''){

            localTz=User.time_zone;
        }


        let newDate=utc.clone().tz(localTz).format(format);

        return newDate;
    }
}
