import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';

@Component({
    selector: 'audio-player',
    template: `<div *ngIf="showPlayer">
                  <audio controls>
                    <source src="{{src}}" type="audio/wav">
                    Your browser does not support the audio element.
                  </audio>
              </div>`
})
export class AudioPlayerComponent implements OnInit, OnChanges {

    @Input() src: string;

    showPlayer: boolean = false;

    constructor() { }

    ngOnInit(): void {
        if (this.src != '') {
            this.showPlayer = true;
        }
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['src'].previousValue !== changes['src'].currentValue && changes['src'].currentValue !== '') {
            this.showPlayer = false;
            setTimeout(() => this.showPlayer = true, 0);
        }
    }
}
