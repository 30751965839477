import { GridViewsService } from './../../services/grid-views.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { GridViewInfo } from '../../models/grid-profiling.models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-grid-view-select',
  templateUrl: './grid-view-select.component.html',
  styleUrls: ['./grid-view-select.component.scss']
})
export class GridViewSelectComponent implements OnInit {

  @Input() value: number;

  @Input() placeholder: string = "Select grid view";

  @Output() valueChange = new EventEmitter<number>();

  @Output() onChange = new EventEmitter<GridViewInfo>();

  public selected: number;

  public selectedObject: GridViewInfo;

  public options = new Observable<GridViewInfo[]>();

  public get hasValue(): boolean {
    return this.selected && this.selected > 0;
  }

  constructor(private viewService: GridViewsService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.options = this.viewService.getAll().pipe(map(resp => {
      return resp.gridviews;
    }));
  }

  onValueChanged(value: GridViewInfo) {
    this.value = value ? value.id : null;
    this.selectedObject = value;
    this.valueChange.emit(value ? value.id : null);
    this.onChange?.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      this.selected = changes.value.currentValue;
      this.cdr.markForCheck();
    }
  }

  getValue(): GridViewInfo {
    return this.selectedObject;
  }
}
