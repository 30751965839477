<section id="kits-list" class="pt-2">
    <div class="row">
        <div class="col-12 d-flex flex-row" [ngClass]="{'row-shrinked':!expanded}">
            <div class="m-0" [ngClass]="{'dt-shrinked items-datatable':!expanded,'dt-expanded card':expanded}">
                <div class="d-flex align-items-center"
                    [ngClass]="{'card-header':expanded,'items-datatable-header mb-2':!expanded}">
                    <datagrid-filter
                        [defaultFilters]="defaults"
                        [module]="module"
                        [searchFilters]="search"
                        [newButton]="UIPermissions.create"
                        [newButtonUrl]="['/inventory/assemblies/new']"
                        [expanded]="expanded"
                        [columns]="columns"
                        (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'category'" let-row="row">
                            <category-select class="ng-select-sm" [(value)]="row.value"></category-select>
                        </ng-template>
                        <ng-template filterControl [column]="'items'" let-row="row">
                            <item-select class="select-sm" [selectBox]="true" [(value)]="row.value"></item-select>
                        </ng-template>
                        <ng-template filterControl [column]="'suborg'" let-row="row">
                            <suborg-select class="ng-select-sm" [(value)]="row.value" [tenantId]="row.dependentValue"></suborg-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="items-datatable-content" [ngClass]="{'card-content':expanded}">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #kitTable
                            [scrollbarH]="scrollbarH"
                            class="bootstrap core-bootstrap"
                            [columnMode]="'force'"
                            [headerHeight]="50"
                            [footerHeight]="50"
                            rowHeight="auto"
                            [rows]="rows"
                            [selectionType]="SelectionType.single"
                            [count]="page.count"
                            [externalPaging]="true"
                            [offset]="page.offset"
                            [externalSorting]="true"
                            [sorts]="[{prop:'internal_number',dir:'asc'}]"
                            [limit]="page.limit"
                            (select)="onSelect($event)"
                            (page)="setPage($event)"
                            (sort)="onSort($event)">

                            <ngx-datatable-column name="Image" prop="primary_image"
                                    [sortable]="false" [width]="90" [canAutoResize]="false">
                                    <ng-template let-url="value" ngx-datatable-cell-template>
                                        <img *ngIf="url" width="80" height="80" [src]="fileUrl + url + '.jpg'" />
                                        <img *ngIf="!url" width="30" height="30"
                                            src="/assets/img/svg/product-avatar.svg" />
                                    </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Part Number" prop="internal_number"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="SKU" prop="sku"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Category" prop="category_name">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Available Qty" prop="available_stock">
                                <ng-template let-quantity="value" ngx-datatable-cell-template>
                                    {{quantity || 0}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                *ngIf="expanded && hasPermission(Resource.INV_KITS_SELL_PRICE,Permissions.VIEW)"
                                name="Sell Price" prop="sell_price" [sortable]="true">
                                <ng-template let-price="value" ngx-datatable-cell-template>
                                    <span *ngIf="price">
                                        ${{price || 0}}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                *ngIf="expanded && hasPermission(Resource.INV_KITS_COST_PRICE,Permissions.VIEW)"
                                name="Cost Price" prop="cost_price" [sortable]="true">
                                <ng-template let-price="value" ngx-datatable-cell-template>
                                    <span *ngIf="price">
                                        ${{price || 0}}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && isAllSuborg" name="Suborg" prop="suborg_name"
                                [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [sortable]="false"
                                [canAutoResize]="false" [width]="70">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge"
                                        [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'Inactive' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded && !isAllSuborg" name="Action" prop="id"
                                [sortable]="false" [width]="80" [canAutoResize]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after"
                                            (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="UIPermissions.viewInfo" class="w-100"
                                                (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                <i class="ft-book-open mr-1"></i> View
                                            </button>
                                            <a *ngIf="UIPermissions.edit" class="w-100"
                                                [routerLink]="['/inventory/assemblies',id,'edit']" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </a>
                                            <a *ngIf="UIPermissions.create" class="w-100"
                                                [routerLink]="['/inventory/assemblies',id,'clone']" ngbDropdownItem>
                                                <i class="ft-copy mr-1"></i> Clone
                                            </a>
                                            <a *ngIf="hasPermission(Resource.INV_KITS_ASSEMBLE,Permissions.CREATE)"
                                                class="w-100" [routerLink]="['/inventory/assemblies',id,'assemble']"
                                                ngbDropdownItem>
                                                <i class="ft-package mr-1"></i> Build
                                            </a>
                                            <button *ngIf="UIPermissions.delete"
                                                class="w-100 bg-light-danger" (click)="deleteKit(id)" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                        [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="kitTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="detail-view" *ngIf="!expanded && selected">
                <div class="detail-view-content">
                    <app-kit-view [kit]="selected" (onDelete)="deleteKit($event)" (onClose)="onKitViewClose()">
                    </app-kit-view>
                </div>
            </div>
        </div>
    </div>
</section>
