import { SalesAccountService } from './../../../services/sales-account.service';
import { SalesAccount } from './../../../models/sales-account';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';

@Component({
  selector: 'sales-account-select',
  templateUrl: './sales-account-select.component.html',
  styleUrls: ['./sales-account-select.component.scss']
})
export class SalesAccountSelectComponent implements OnInit, OnDestroy {

  @Input() value: number;

  @Input() readonly: boolean = false;

  @Output() valueChange = new EventEmitter<number>();

  public placeholder: string;

  public options: SalesAccount[];

  private getSub$: Subscription;

  constructor(private salesService: SalesAccountService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService) {

    this.placeholder = this.hasPermission() ? "Select or add Account" : "Select Account";
  }

  ngOnInit() {
    this.getSub$ = this.salesService.getAll().subscribe(accounts => {
      this.options = accounts;
      this.cdr.markForCheck();
    });
  }

  openModal(content: any): void {
    this.modalService.open(content);
  }

  onValueChanged(value: SalesAccount) {
    this.valueChange.emit(this.value);
  }

  onDataSubmittedSuccess(result: { response: SalesAccount, message: string }): void {
    this.toastr.success(result.message);
    this.options = this.options.concat(result.response);
    this.value = result.response.id;
    this.onValueChanged(result.response);
    this.modalService.dismissAll();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.modalService.dismissAll();
  }

  onCancel(): void {
    this.modalService.dismissAll();
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.INV_UNITS, [
      PermissionConstants.CREATE, PermissionConstants.GLOBAL
    ]);
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
  }
}
