import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CatalogCartService {

  catalogCartItems: Array<any> = localStorage.getItem('catalog-cart-items') !== null ? JSON.parse(localStorage.getItem('catalog-cart-items')) : [];
  public currentCartItems = new BehaviorSubject(this.catalogCartItems);


  constructor() {

  }
  onChangeCatalogCartItems(item: any,tp=0) {

    if(tp==0) //new item
    {
      this.catalogCartItems.push(item)
     
    }

    else
    {
      let indx = this.catalogCartItems.findIndex(x=> x.unique_id == item.id)
      this.catalogCartItems[indx].quantity = item.quantity

    }

    this.currentCartItems.next(this.catalogCartItems);
    localStorage.setItem('catalog-cart-items', JSON.stringify(this.catalogCartItems))

  }

  clearCart()
  {
    localStorage.removeItem('catalog-cart-items')
    this.currentCartItems.next([]);
    this.catalogCartItems = []
  }

}


