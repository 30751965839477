<section class="common-grid-view">
    <div class="list-parellax pt-2" [ngClass]="{'height-min-fit':expanded,'height-fit':!expanded}">
        <div class="d-flex flex-row flex-1 h-100">
            <div [ngClass]="{'parellax-shrinked parellax-shrinked-bg':!expanded,'parellax-expanded card m-0':expanded}">
                <div class="d-flex align-items-center flex-space-bw" [ngClass]="{'card-header':expanded,'parellax-dt-header mb-2':!expanded}">
                    <datagrid-filter #dataGridFilter [searchFilters]="searchFilters" [defaultFilters]="defaults" [columns]="columns" [newButton]="true" [newButtonUrl]="['/users/groups/new']" [newButtonEnabled]="true" [expanded]="expanded" (apply)="applyFilter($event)">
                        <ng-template filterControl [column]="'users'" let-row="row">
                            <user-select class="ng-select-sm" [(value)]="row.value" [multiple]="false"></user-select>
                        </ng-template>
                    </datagrid-filter>
                </div>
                <div class="card-content">
                    <div [ngClass]="{'card-body':expanded}">
                        <ngx-datatable #dataTable [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" (select)="onSelect($event)" [count]="page.count"
                            [externalPaging]="true" [offset]="page.offset" [externalSorting]="true" [limit]="page.limit" (page)="setPage($event)" (sort)="onSort($event)" [sorts]="[{prop:'name',dir:'asc'}]">
                            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Description" prop="description">
                            </ngx-datatable-column>

                            <ngx-datatable-column *ngIf="expanded" cellClass="dispatch-user-column" name="Dispatch Group" prop="is_dispatch">
                                <ng-template let-dispatch="value" ngx-datatable-cell-template >
                                        <span *ngIf="dispatch" class="text-success dispatch-user-badge" style="margin:auto;" >
                                            <i class="ft-check-circle "></i>
                                        </span>
                                        <!-- <span *ngIf="!dispatch" class="text-danger dispatch-user-badge" style="margin:auto;" >
                                            <i class="ft-x-circle"></i>
                                        </span> -->
                                </ng-template>
                            </ngx-datatable-column>


                            <ngx-datatable-column *ngIf="expanded" name="Created Date" prop="created_on">
                                <ng-template let-crdate="value" ngx-datatable-cell-template>
                                    {{crdate | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Created By" prop="created_by" [sortable]="false">
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Status" prop="is_active" [sortable]="false">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div class="badge" [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                                        {{ status ? 'Active' : 'In Active' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="expanded" name="Action" prop="id" [sortable]="false">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button [routerLink]="['/users/groups',id,'edit']" class="w-100" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </button>
                                            <button class="w-100 bg-light-danger" (click)="deleteGroup(id)" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                    <div class="page-count" *ngIf="expanded">
                                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="dataTable.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="parellax-detail" *ngIf="!expanded && selected">
                <div class="parellax-detail-content">
                    <div class="warehouse-details">
                        <div class="d-flex flex-row mt-2">
                            <h5 class="item-title m-0">{{selected?.name}}</h5>
                            <div class="d-flex justify-content-end align-items-start">
                                <a [routerLink]="['/users/groups',selected.id,'edit']" ngbTooltip="Edit" class="btn btn-sm bg-light-secondary">
                                    <i class="ft-edit"></i> Edit
                                </a>
                                <button class="btn btn-sm bg-light-danger ml-1" (click)="deleteGroup(selected.id)">
                                    <i class="ft-trash-2 mr-1"></i> Delete
                                </button>
                                <button class="btn btn-sm ml-2 p-0 no-hover font-medium-3" (click)="closeDetailedView()">
                                    <i class="ft-x"></i>
                                </button>
                            </div>
                        </div>
                        <div class="group mt-3">
                            <div class="group-header d-flex justify-content-between mb-0">
                                Overview
                                <div class="d-flex flex-column align-items-end">
                                    <div class="status-toggle-btn">
                                        <label class="title">Status :</label>
                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                            <input type="checkbox" [(ngModel)]="selected.is_active" id="status" name="active" class="custom-control-input" (change)="changeStatus()">
                                            <label class="custom-control-label" for="status">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="status-toggle-btn">
                                        <label class="title">Smart SIP group :</label>
                                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                            <input type="checkbox" [(ngModel)]="selected.smart_sip_group" id="smart_sip_group" name="active" class="custom-control-input" (change)="changeSmartSipGroupStatus()">
                                            <label class="custom-control-label" for="smart_sip_group">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="group-body">
                                <div class="ov-row" *ngIf="selected?.created_on">
                                    <label class="col-md-3 p-0 item-label">Created On</label>
                                    <label class="item-label-value">{{selected.created_on | date:'MM/dd/YYYY'}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.created_by">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value">{{selected.created_by}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.description">
                                    <label class="col-md-3 p-0 item-label">Description</label>
                                    <label class="item-label-value">{{selected.description}}</label>
                                </div>
                                <div class="ov-row" *ngIf="selected?.is_dispatch!=null">
                                    <label class="col-md-3 p-0 item-label">Dispatch Group</label>
                                    <label class="item-label-value">
                                        <span *ngIf="selected.is_dispatch" class="text-success dispatch-user-badge" style="margin:auto;" >
                                            <i class="ft-check-circle "></i>
                                        </span>

                                        <span *ngIf="!selected.is_dispatch" class="text-danger dispatch-user-badge" style="margin:auto;" >
                                            <i class="ft-x-circle"></i>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                        <div class="col-12 col-md-8 group1" style="margin-left: 17px;"> 
                            <form name="form" #userForm="ngForm" (ngSubmit)="userForm.form.valid && submit()" novalidate>
                            <div class="d-flex" style="justify-content: space-between;">
                                <div class="form-group d-flex align-items-center" >
                                    <label class="m-0 mr-2">VoiceMail Enabled :</label>
                                    <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                                        <input type="checkbox" [(ngModel)]="selected.is_voicemail_enabled" name="is_voice_mail" class="custom-control-input"
                                               id="color-switch-4" checked>
                                        <label class="custom-control-label mr-1" for="color-switch-4">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                
                                <div class="form-group d-flex align-items-center" *ngIf="selected.is_voicemail_enabled">
                                    <label class="m-0 mr-2">VoiceMail Send :</label>
                                    <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                                        <input type="checkbox" [(ngModel)]="selected.is_voicemail_send" name="is_mail_send" class="custom-control-input"
                                               id="color-switch-5" checked>
                                        <label class="custom-control-label mr-1" for="color-switch-5">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                
                            </div> 

                            <div class="row" style="margin-top: 10px;margin-bottom: 10px;">
                            
                            <div class=" col-12 col-md-6" *ngIf="selected.is_voicemail_enabled">
                                <label class="m-0 mr-2">VoiceMail Voice :</label>

                                <ng-select
                                name="voicemail_voice"
                                placeholder="Select voice"
                                [items]="voices | async"
                                bindLabel="name"
                                bindValue="value"
                                [(ngModel)]="selected.voicemail_voice">
                              </ng-select>
                                <!--<ng-select   placeholder="Select a Type" class="form-select " [ngModelOptions]="{standalone: true}"  [(ngModel)]="selected.voicemail_voice" >
                                    
                                     <ng-option value="male">Male</ng-option>
                                     <ng-option value="female">Female</ng-option>
                                 </ng-select>-->
                                </div>
                            
                            
                            <div class=" col-12 col-md-6" *ngIf="selected.is_voicemail_enabled && selected.is_voicemail_send">
                                <label class="val-required m-0 mr-2">E-MAIL</label>
                                <input type="email" [(ngModel)]="selected.voicemail_email" #fn="ngModel" name="voicemail_email" class="form-control" email required>
                                <div class="form-text text-muted danger" *ngIf="fn.invalid && (fn.dirty || fn.touched)">
                                    <small *ngIf="fn.errors.required">
                                        Email is required.
                                    </small>
                                    <small *ngIf="fn.errors?.email">
                                        Please enter a valid email address.
                                    </small>
                                </div>
                            </div>

                            </div>



                            <div class="form-group " *ngIf="selected.is_voicemail_enabled">
                                <label>Group message</label>
                                <textarea [(ngModel)]="selected.voicemail_message" name="voicemail_message" class="form-control"></textarea>
                            </div>

                            <div class="col-md-12 p-0 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                <button type="submit" [disabled]="!userForm.form.valid && selected.is_voicemail_send" class="btn gradient-pomegranate mb-2 mb-sm-0">
                                    Save
                                </button>
                                
                            </div>
                        </form>
                        </div>
                        </div>





                        <div class="group mt-3">
                            <div class="group-header">
                                <div>Users</div>
                                <button style="border: none;background: white;"  class="refresh-button" (click)="refreshSmartSip()">
                                    <i class="icon-refresh"></i>
                                </button>
                            </div>
                            <div class="group-body">
                                <user-select-grid [userGroupId]="selected.id" [viewMode]="true" [smart_sip_group]="selected.smart_sip_group"></user-select-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>