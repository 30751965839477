import { AppEventType } from "../enums/common.enum";

export interface CommonFields {

    created_by: string;

    created_on: Date;

    modified_on: Date;

    modified_by: string;
}

export class DataHistory {

    id: number;

    customer:number;

    created_user: number;

    created_on: string;

    message: string;

    unique_id: number;

    module: number;

    changes: FieldChange[]

    created_by:string;

    render_type:number

}

export interface DataHistoryGroup {

    date: string;

    transaction: DataHistory[];
}

export interface FieldChange {

    name:string;

    old_value:any;

    new_value:any;

    old_value_display_name:string;

    new_value_display_name:string;

    message_source:string;
}

export class UIPermission {

    view?: boolean;

    create?: boolean;

    edit?: boolean;

    delete?: boolean;

    viewInfo?: boolean;

    activate?: boolean;

    deactivate?: boolean;

    export?: boolean;

    import?:boolean;
}

export interface DataImportMeta {

    excel_column_index?: string;

    table_name: string;

    actual_column_name: string;

    display_name: string;

    excel_column_name?: string;
}


export class AppEvent<T> {
    constructor(
        public type: AppEventType,
        public payload: T,
    ) { }
}


export class Comments {

    public id: number;

    public unique_id: number | string;

    public module_id: number;

    public comments: string;

    public parent: number;

    public customer: number;

    public created_by: string;

    public created_on: Date;

    public modified_on: Date;

    public modified_by: string;

    public created_user: number;

    public is_delete: boolean;

    public is_active: boolean;

    edit: boolean;

    inputRows: number = 1;

    public suborg:number;
}

export class WHBaseModel {

    public customer:number;

    public suborg:number;

    public created_user: number;

    public modified_user: number;

    public created_on:Date;

    public modified_on:Date;

    public is_delete: boolean;

    public is_active: boolean;

    // readonly field
    created_by:string;

    // readonly field
    modified_by:string;

    // readonly field;
    suborg_name:string;
}
