import { GridProfilingModule } from './../grid-profiling/grid-profiling.module';
import { RoleViewComponent } from './pages/role-view/role-view.component';
import { CustomerModule } from './../customer/customer.module';
import { PrevilegesComponent } from './components/previleges/previleges.component';
import { Role2permissionComponent } from './pages/role2permission/role2permission.component';
import { SharedModule } from './../../shared/shared.module';
import { RoleListComponent } from './pages/role-list/role-list.component';
import { AddRoleComponent } from './pages/add-role/add-role.component';
import { NgModule } from '@angular/core';

import { RolesRoutingModule } from './roles-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { RoleSelectComponent } from './components/role-select/role-select.component';
import { SuborgSelectComponent } from './components/suborg-select/suborg-select.component';


@NgModule({
  declarations: [
    AddRoleComponent,
    RoleListComponent,
    Role2permissionComponent,
    PrevilegesComponent,
    RoleSelectComponent,
    RoleViewComponent,
    SuborgSelectComponent
  ],
  imports: [
    RolesRoutingModule,
    NgbModule,
    NgxDatatableModule,
    SharedModule,
    NgSelectModule,
    CustomerModule,
    GridProfilingModule
  ],
  exports:[
    RoleSelectComponent,
    PrevilegesComponent,
    SuborgSelectComponent
  ]
})
export class RolesModule { }
