import { KitAssembleService } from './../../services/kit-assemble.service';
import { KitAssembleKitMaterial, KitAssembleMaterial, KitAssembleMaterialLocation, KitAssembleMaterialRow, KitAssembleWrapper } from './../../models/kit-assemble.models';
import { Component, ViewChild, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { forkJoin, observable, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { KitItemsType, KitWrapper } from '../../models/kit.models';
import { KitService } from '../../services/kit.service';
import { ItemWrapper } from '../../models/items';
import { map } from 'rxjs/operators';
import { BuildAssembleSelectComponent } from '../select/build-assemble-select/build-assemble-select.component';
import { ItemWarehouseSelectComponent } from '../select/item-warehouse-select/item-warehouse-select.component';
import * as popup from 'src/app/core/utils/popup.functions';
import { ToastrService } from 'ngx-toastr';
import { getLightColor } from 'src/app/core/utils/global.functions';


@Component({
    selector: 'assembled-kit-items',
    templateUrl: './assembled-kit-items.component.html',
    styleUrls: ['./assembled-kit-items.component.scss']
})
export class AssembledKitItemsComponent implements OnInit, OnDestroy {

    @ViewChild(ItemWarehouseSelectComponent) itemSelect: ItemWarehouseSelectComponent;

    @ViewChild(BuildAssembleSelectComponent) buildAssembleSelect: BuildAssembleSelectComponent;

    @Input() viewMode: boolean = false;

    private innerKitId: number;

    get kitId() { return this.innerKitId; }

    @Input() set kitId(value: number) {

        if (value && value !== this.innerKitId) {

            this.innerKitId = value;
            this.getKitItems();
        }
    };

    private innerAssembleId: number;

    get assembledId() { return this.innerAssembleId; }

    @Input() set assembledId(value: number) {

        if (value && value !== this.innerAssembleId) {
            this.innerAssembleId = value;
            this.rows.length = 0;
            this.rowIndex = 0;
            this.getAssembledItems();
        }
    };

    @Input() bundleCount: number = 1;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public rows: KitAssembleMaterialRow[] = [];

    public deletebuildAssemble: number[] = [];

    public rowIndex: number;

    public KitIemTypes = KitItemsType;

    private subs: SubSink = new SubSink();

    newItem: boolean = false;

    isAssemblyExist: boolean = false;

    public isAllSuborg: boolean;

    constructor(private kitService: KitService,
        private assembleService: KitAssembleService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService) {
            this.isAllSuborg = assembleService.isAllSuborg;
        }

    ngOnInit() { }

    private getKitItems() {
        this.subs.sink = forkJoin([this.getItemsInKit(this.kitId), this.getKitsInKit(this.kitId)]).subscribe(
            results => {
                if (results && results.length > 0) {
                    this.rows = [...results[0], ...results[1]].sort((a, b) => a.id - b.id);
                    this.cdr.markForCheck();
                }
            },
            error => {
                this.rows = [];
                this.cdr.markForCheck();
            }
        );
    }

    getItemsInKit(kitid: number) {
        return this.kitService.getItemsByKit(kitid).pipe(
            map(res => {
                let rows: KitAssembleMaterialRow[] = [];
                if (res && res.length > 0) {
                    for (let item of res) {
                        let row = new KitAssembleMaterialRow();
                        row.item_id = item.items;
                        row.itemType = KitItemsType.ITEM;
                        row.primary_image = item.primary_image
                        row.customer = item.customer;
                        row.item_name = item.item_name;
                        row.part_number = item.part_number;
                        row.quantity = item.quantity || 1;
                        row.stock = item.stock || 0;
                        row.status = "UN_ALLOCATED";
                        row.unit = item.unit || "";
                        rows.push(row);
                    }
                }
                return rows;
            })
        )
    }

    getKitsInKit(kitid: number) {
        return this.kitService.getKitsInKit(kitid).pipe(
            map(res => {
                let rows: KitAssembleMaterialRow[] = [];
                if (res && res.length > 0) {
                    for (let k2k of res) {
                        let row = new KitAssembleMaterialRow();
                        row.item_id = k2k.kit_kit;
                        row.itemType = KitItemsType.KIT;
                        row.customer = k2k.customer;
                        row.item_name = k2k.kit_name;
                        row.part_number = k2k.internal_number;
                        row.primary_image = k2k.primary_image;
                        row.quantity = k2k.quantity || 1;
                        row.stock = k2k.stock || 0;
                        row.status = "UN_ALLOCATED";
                        row.unit = k2k.unit || "";
                        rows.push(row);
                        this.isAssemblyExist = true;
                    }
                }
                return rows;
            })
        )
    }

    private getAssembledItems() {
        let assembledItems = this.assembleService.getAssembledKitItems(this.assembledId);
        let assembledKits = this.assembleService.getKitsInAssembledKit(this.assembledId);
        this.subs.sink = forkJoin([assembledItems, assembledKits]).subscribe(results => {
            if (results && results.length > 0) {
                this.rows = [...this.rows, ...results[0], ...results[1]].sort((a, b) => a.id - b.id);
                this.checkforAssembly();
                this.refreshDataTable();
            }
        });
    }

    checkforAssembly(){
        if(this.rows.filter((elem, index) => {return elem.itemType == KitItemsType.KIT}).length > 0){
            this.isAssemblyExist = true;
        }
        else{
            this.isAssemblyExist = false;
        }
    }

    appendRow(type: KitItemsType) {
        this.newItem = true;
        this.toggleSelect(type, this.getRow(type))
    }

    getRow(type: KitItemsType) {
        let row = new KitAssembleMaterialRow();
        row.itemType = type;
        row.customer = this.assembleService.customerId;
        return row;
    }

    onItemChange(item: ItemWrapper) {
        if (item && this.rows.length > 0) {
            if(this.rows.filter((elem, index) => {
                return elem.item_id == item.id
            }).length > 0){
            this.toastr.info('Already exist this Item');
            return;
            }
        }
        if (this.newItem) {
            let row = this.getRow(KitItemsType.ITEM)
            this.rows = this.rows.concat(row);
            this.rowIndex = this.rows.length - 1;
        }
        if (item && this.rows[this.rowIndex]) {
            this.rows[this.rowIndex].item_id = item.id;
            this.rows[this.rowIndex].customer = item.customer;
            this.rows[this.rowIndex].item_name = item.item_name;
            this.rows[this.rowIndex].part_number = item.part_number;
            this.rows[this.rowIndex].primary_image = item.primary_image;
            this.rows[this.rowIndex].stock = item.available_stock || 0;
            this.rows = [...this.rows];
        }
    }

    onKitChange(kit: KitWrapper) {
        if (this.newItem) {
            let row = this.getRow(KitItemsType.KIT)
            this.rows = this.rows.concat(row);
            this.rowIndex = this.rows.length - 1;
        }
        if (kit && this.rows[this.rowIndex]) {
            this.rows[this.rowIndex].item_id = kit.id;
            this.rows[this.rowIndex].customer = kit.customer;
            this.rows[this.rowIndex].item_name = kit.name;
            this.rows[this.rowIndex].part_number = kit.internal_number;
            this.rows = [...this.rows];
        }
    }

    onBuildAssembleChange(kitassemble: KitAssembleWrapper) {
        if(this.assembledId == kitassemble.id){
            this.toastr.info('You can not add Parent Built Assemble as Child item');
            return;
        }
        if (kitassemble && this.rows.length > 0) {
            if(this.rows.filter((elem, index) => {
                return elem.selected_kitassemble == kitassemble.id
            }).length > 0){
            this.toastr.info('Already exist this Built Assemble');
            return;
            }
        }
        if (this.newItem) {
            let row = this.getRow(KitItemsType.KIT)
            this.rows = this.rows.concat(row);
            this.rowIndex = this.rows.length - 1;
        }
        if (kitassemble && this.rows[this.rowIndex]) {
            this.rows[this.rowIndex].item_id = kitassemble.kits;
            this.rows[this.rowIndex].selected_kitassemble = kitassemble.id;
            this.rows[this.rowIndex].customer = kitassemble.customer;
            this.rows[this.rowIndex].item_name = kitassemble.kit_name;
            this.rows[this.rowIndex].primary_image = kitassemble.kit_image;
            this.rows[this.rowIndex].part_number = kitassemble.part_number;
            this.rows[this.rowIndex].stock = kitassemble.available_stock || 0;
            this.rows = [...this.rows];
        }
    }

    onAllocateInventory(type: KitItemsType, row?: KitAssembleMaterialRow, index?: number) {
        this.toggleSelect(type, row, index, true);
    }

    toggleSelect(type: KitItemsType, row?: KitAssembleMaterialRow, index?: number, isAllocate: boolean = false) {
        if (index >= 0) {
            this.rowIndex = index;
            this.newItem = false;
        }
        if (type === KitItemsType.ITEM) {
            this.itemSelect.open(row, this.bundleCount, isAllocate);
        }
        else if (type === KitItemsType.KIT) {
            this.buildAssembleSelect.open(row, this.bundleCount, isAllocate);
        }
    }

    deleteRow(row: KitAssembleMaterialRow) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                if (row.id) {
                    if (row.itemType === KitItemsType.ITEM) {
                        this.subs.add(this.assembleService.deleteAssembledKitItems(this.assembledId, row.id).subscribe(resp => {
                            this.rows = this.rows.filter(item => item.id !== row.id);
                            this.cdr.markForCheck();
                        }));
                    }
                    else if (row.itemType === KitItemsType.KIT) {
                        this.subs.add(this.assembleService.deleteKitsInAssembledKit(this.assembledId, row.id).subscribe(resp => {
                            this.rows = this.rows.filter(item => item.id !== row.id);
                            this.cdr.markForCheck();
                        }));
                    }
                }
                else {
                    this.rows = this.rows.filter(item => item !== row);
                    this.cdr.markForCheck();
                }
                popup.CompleteDelete(row.itemType + ' has been deleted.');
                this.refreshDataTable();
            }
        });
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    deleteChildRow(parent: KitAssembleMaterialRow, row: KitAssembleMaterialRow) {
        parent.children = parent.children.filter(item => item.guid !== row.guid);
    }

    onSelectItemWarehouseStock(stock: KitAssembleMaterialLocation[]) {
        stock = stock.filter(st => st.id > 0 || st.selected_quantity > 0)
        stock.map(
            (item) => {
                item.selected_quantity = item.selected_quantity || 0
                item.type = KitItemsType.ITEM;
                item.customer = this.rows[this.rowIndex].customer
            }
        )
        this.rows[this.rowIndex].selected_stock = stock

    }

    onSelectBuildAssembleWarehouseStock(stock: KitAssembleMaterialLocation[]) {
        stock = stock.filter(st => st.id > 0 || st.selected_quantity > 0)
        stock.map(
            (item) => {
                item.selected_quantity = item.selected_quantity || 0
                item.type = KitItemsType.KIT
                item.customer = this.rows[this.rowIndex].customer
            }
        )
        this.rows[this.rowIndex].selected_stock = stock

    }

    getItemWarehouseApi(apis: Observable<any>[], assembleid: number) {
        let rows = this.rows.filter(row => row.itemType == KitItemsType.ITEM)
        let array = []
        rows.forEach(element => {
            if (element.selected_stock) {
                array = array.concat(element.selected_stock)
            }
        });
    }

    getBuilAssembleWarehouseApi(apis: Observable<any>[], assembleid: number) {
        let rows = this.rows.filter(row => row.itemType == KitItemsType.KIT)
        let array = []
        rows.forEach(element => {
            if (element.selected_stock) {
                array = array.concat(element.selected_stock)
            }
        });
    }

    fillAssembleIdAndReqQuantity(materialRows: KitAssembleMaterialRow[], assembleid: number) {
        for (let row of materialRows) {
            row.kitassemble = assembleid;
            row.requested_quantity = this.bundleCount * row.quantity;
            if (row.children && row.children.length > 0) {
                this.fillAssembleIdAndReqQuantity(row.children, assembleid)
            }
        }
    }

    public getApis(assembleid: number): Observable<any>[] {
        this.fillAssembleIdAndReqQuantity(this.rows, assembleid);
        let materials = this.convertKitItemRow(this.rows);
        let apis: Observable<any>[] = [];
        this.getItemApis(assembleid, apis, materials.items);
        this.getKitApis(assembleid, apis, materials.kits);
        return apis;
    }

    getItemApis(assembleid: number, apis: Observable<any>[], items: KitAssembleMaterial[]) {
        let add = items.filter(ki => !ki.id);
        if (add.length) {
            apis.push(this.assembleService.addAssembledKitItems(assembleid, add));
        }
        let update = items.filter(ki => ki.id);
        if (update.length) {
            apis.push(this.assembleService.updateAssembledKitItems(assembleid, update));
        }
    }

    getKitApis(assembleid: number, apis: Observable<any>[], kits: KitAssembleKitMaterial[]) {
        let add = kits.filter(ki => !ki.id);
        if (add.length) {
            apis.push(this.assembleService.addKitsInAssembledKit(assembleid, add));
        }
        let update = kits.filter(ki => ki.id);
        if (update.length) {
            apis.push(this.assembleService.updateKitsInAssembledKit(assembleid, update));
        }
    }


    convertKitItemRow(materialRows: KitAssembleMaterialRow[]): { items?: KitAssembleMaterial[], kits?: KitAssembleKitMaterial[] } {
        let resp = { items: [], kits: [] };
        for (let row of materialRows) {
            if (!row.item_id || row.item_id <= 0) {
                return null;
            }
            if (row.itemType === KitItemsType.ITEM) {
                let item = new KitAssembleMaterial();
                item.id = row.id;
                item.items = row.item_id;
                item.kitassemble = row.kitassemble;
                item.quantity = row.quantity;
                item.customer = row.customer;
                item.requested_quantity = row.requested_quantity;
                item.selected_stock = row.selected_stock || [];
                item.selected_stock.map(el => el.kit_assemble = row.kitassemble)
                resp.items.push(item);
            }
            else if (row.itemType === KitItemsType.KIT) {
                if (row.expanded && row.children && row.children.length > 0) {
                    let innerItems = this.convertKitItemRow(row.children);
                    resp.items = resp.items.concat(innerItems.items);
                    resp.kits = resp.kits.concat(innerItems.kits);
                }
                else {
                    let kit = new KitAssembleKitMaterial();
                    kit.id = row.id;
                    kit.kit = row.item_id;
                    kit.kitassemble = row.kitassemble;
                    kit.selected_kitassemble = row.selected_kitassemble;
                    kit.quantity = row.quantity;
                    kit.customer = row.customer;
                    kit.requested_quantity = row.requested_quantity;
                    kit.selected_stock = row.selected_stock || [];
                    kit.selected_stock.map(el => el.kit_assemble = row.kitassemble)
                    resp.kits.push(kit);
                }
            }
        }
        return resp;
    }

    validate(): boolean {
        return this.rows.filter(row => row.item_id > 0).length > 0;
    }

    getItemIds() {
        return this.rows.filter(ka => ka.id > 0 && ka.itemType === KitItemsType.ITEM).map(ka => ka.id) || [];
    }

    getKitIds() {
        return this.rows.filter(ka => ka.id > 0 && ka.itemType === KitItemsType.KIT).map(ka => ka.id) || [];
    }

    expandKit(row: KitAssembleMaterialRow, level?:number) {
        if (!row.children) {
            if(level == 0)
                row.level = level;
            if(row.selected_kitassemble){
                let assembledItems = this.assembleService.getAssembledKitItems(row.selected_kitassemble);
                let assembledKits = this.assembleService.getKitsInAssembledKit(row.selected_kitassemble);
                this.subs.sink = forkJoin([assembledItems, assembledKits]).subscribe(results => {
                    if (results && results.length > 0) {
                        row.children = [...results[0], ...results[1]];
                        row.expanded = true;
                        this.addColor(row);
                        this.cdr.markForCheck();
                    }
                });
            }
            else{
                let assembledItems = this.getItemsInKit(row.item_id);
                let assembledKits = this.getKitsInKit(row.item_id);
                this.subs.sink = forkJoin([assembledItems, assembledKits]).subscribe(results => {
                    if (results && results.length > 0) {
                        row.children = [...results[0], ...results[1]];
                        row.expanded = true;
                        this.addColor(row);
                        this.cdr.markForCheck();
                    }
                });
            }
        }
        else {
            row.expanded = !row.expanded;
        }
    }

    addColor(row: KitAssembleMaterialRow){
        row.children.map((el)=>{
            el.level = row.level + 1;
            el.color = getLightColor(el.level);
        });
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
