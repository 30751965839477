import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { Comments } from 'src/app/core/models/common.models';
import { CommonService } from 'src/app/core/services/common.service';
import { SOComments } from 'src/app/modules/sales/models/sales-orders.models';
import { SalesOrdersService } from 'src/app/modules/sales/services/sales-orders.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {


  @Input() uniqueId: any = 0; // string sid from callreports

  @Input() moduleId: number=0;

  @Input() suborgId: number=0;

  // @Input() set warrantyId(value: number) {
  //   this.uniqueId = value;
  //   this.getComments(this.uniqueId);
  // }

  @Input() timelineView: boolean = true;

//   get _uniqueId() { return this.uniqueId; }

  public comments: Comments[];

  public model: Comments = new Comments();

  public userName: string;

  public rows: number = 1;

  public currentUser: number;

  public isAdmin: boolean;

  private subs: SubSink = new SubSink();

  constructor(private service: CommonService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) {

    this.currentUser = service.userId;
    this.isAdmin = service.isAdmin;
  }

  ngOnInit() {
    if(this.uniqueId>0){
      this.getComments(this.uniqueId, this.moduleId, this.suborgId);
    }
    let userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
    if (userAuth) {
      this.userName = `${userAuth.firstname || ''} ${userAuth.lastname || ''}`;
    }
    if (!this.timelineView) {
      this.model.inputRows = 3;
    }
  }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes)
        if(changes.uniqueId.currentValue && changes.uniqueId.previousValue && changes.uniqueId.currentValue != changes.uniqueId.previousValue)
            this.getComments(this.uniqueId, this.moduleId, this.suborgId);
    }

  getComments(uniqueId: number, moduleId: number, suborgId: number) {
    if (uniqueId > 0) {
      this.subs.sink = this.service.getComments(uniqueId, moduleId, suborgId).subscribe(resp => {
        this.comments = resp;
        this.cdr.markForCheck();
      });
    }
    else {
      this.comments = [];
    }
  }

  headerInputFocus() {
    if (this.timelineView) {
      this.model.inputRows = 3;
    }
  }

  createComment() {

    if(!this.model.comments) return;

    console.log(this.model.comments);

    this.model.unique_id = this.uniqueId;

    this.model.module_id = this.moduleId;

    this.model.customer = this.service.customerId;
    this.model.suborg = this.suborgId;
    this.model.inputRows=undefined;
    this.subs.sink = this.service.createComment([this.model]).subscribe(
      resp => {
        this.comments = [...resp, ...this.comments];
        this.model.inputRows = 1;
        this.model.comments = '';
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to save comment')
    );
  }

  updateComment(comment: Comments, value: string) {

    if(!value) return;

    let com = { ...comment };
    com.comments = value;
    this.subs.sink = this.service.updateComment(comment.id, com).subscribe(
      () => {
        comment.comments = value;
        comment.edit = false;
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to modify comment')
    );
  }

  deleteComment(commentid: number) {
    this.subs.sink = this.service.deleteComment(commentid).subscribe(
      () => {
        this.comments = this.comments.filter(c => c.id !== commentid);
        this.cdr.markForCheck();
      },
      () => this.toastr.error('Unable to delete comment')
    );
  }

  public saveComment(uniqueId: any, suborgId :any, next?: (value: any) => any) {
    if (!this.model.comments && next) {
      next(null);
    }
    else {
      this.model.unique_id = uniqueId;
      this.model.suborg = suborgId;
     this.model.module_id = this.moduleId;
      this.model.customer = this.service.customerId;
      this.subs.sink = this.service.createComment([this.model]).pipe(
        catchError(() => [])
      ).subscribe(resp => {
        if (next) { next(resp) }
      });
    }
  }

  ngOnDestroy(): void { this.subs?.unsubscribe(); }

}
