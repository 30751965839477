import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UnitType } from '../models/items';

@Injectable({
  providedIn: 'root'
})
export class UnitsService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<UnitType[]>(`${this.baseURL}/unittypes/`);
  }

  getById(id: number) {
    return this.http.get<UnitType>(`${this.baseURL}/unittypes/${id}/`);
  }

  create(unit: UnitType) {
    return this.http.post<UnitType>(`${this.baseURL}/unittypes/`, unit);
  }

  update(id: number, unit: UnitType) {
    return this.http.put<UnitType>(`${this.baseURL}/unittypes/${id}/`, unit);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/unittypes/${id}/`);
  }
}
