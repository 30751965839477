<div *ngIf="selectBox" class="item-select-container select-sm" [class.readonly]="readonly" (click)="!readonly && open()">
    <div class="value-container h-100">
        <div *ngIf="!selected" class="placeholder">Select Customer</div>
        <div class="combobox h-100">
            <label class="w-100 m-0 item-name-label" *ngIf="selected">{{selected.name}}</label>
        </div>
    </div>
    <span *ngIf="selected && !readonly" (click)="clearSelection($event)" class="clear-wrapper d-flex" title="Clear all">
        <span aria-hidden="true" class="clear">×</span>
    </span>
    <span class="arrow-wrapper"><span class="arrow"></span></span>
</div>

<ng-template #modalElement let-c="close" let-d="dismiss">
    <div class="modal-body p-0">
        <div class="w-100 p-2">
            <datagrid-filter #dataFilter [defaultFilters]="defaults" [searchFilters]="search" [columns]="columns" (apply)="applyFilter($event)">
                <ng-template filterControl [column]="'customer_type_id'" let-row="row">
                    <customer-type-select [addButton]="false" class="ng-select-sm" [(value)]="row.value"></customer-type-select>
                </ng-template>
                <ng-template filterControl [column]="'customer_system_type_id'" let-row="row">
                    <customer-system-type-select [addButton]="false" class="ng-select-sm" [(value)]="row.value">
                    </customer-system-type-select>
                </ng-template>
                <ng-template filterControl [column]="'currency_id'" let-row="row">
                    <currency-select [addButton]="false" class="ng-select-sm" [(value)]="row.value"></currency-select>
                </ng-template>
                <ng-template filterControl [column]="'industry_id'" let-row="row">
                    <industry-select [addButton]="false" class="ng-select-sm" [(value)]="row.value"></industry-select>
                </ng-template>
                <ng-template filterControl [column]="'payment_terms_id'" let-row="row">
                    <payment-term-select [addButton]="false" class="ng-select-sm" [(value)]="row.value"></payment-term-select>
                </ng-template>
                <ng-template filterControl [column]="'rep_id'" let-row="row">
                    <user-select class="ng-select-sm" [multiple]="false" [(value)]="row.value"></user-select>
                </ng-template>
                <ng-template filterControl [column]="'created_user'" let-row="row">
                    <user-select class="ng-select-sm" [(value)]="row.value"></user-select>
                </ng-template>
            </datagrid-filter>
        </div>
        <ngx-datatable #itemTable [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap grid-selection-custom-style" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="items" [selectionType]="SelectionType.single" [count]="page.count"
            [externalPaging]="true" [offset]="page.offset" [externalSorting]="true" [sorts]="[{prop:'name',dir:'asc'}]" [limit]="page.limit" [selected]="selectedItem" (page)="setPage($event,dataFilter)" (sort)="onSort($event,dataFilter)">
            <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
            <ngx-datatable-column name="Customer Type" prop="customer_type_name"></ngx-datatable-column>
            <ngx-datatable-column name="System Type" prop="customer_system_type_name"></ngx-datatable-column>
            <ngx-datatable-column name="Industry" prop="industry_name"></ngx-datatable-column>
            <ngx-datatable-column name="Currency" prop="currency_name"></ngx-datatable-column>
            <ngx-datatable-column name="Payment Terms" prop="payment_terms_name"></ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="is_active" [sortable]="false">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <div class="badge" [ngClass]="{'bg-light-success': status,'bg-light-danger': !status }">
                        {{ status ? 'Active' : 'On Hold' }}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                    <div class="page-count">
                        <span>{{this.page.pageNumber}}/{{rowCount}} </span>
                    </div>
                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                        (change)="itemTable.onFooterPage($event)">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="onItemSelected()" class="btn gradient-pomegranate">Select</button>
        <button type="button" (click)="onCanceled()" class="btn btn-secondary">Cancel</button>
    </div>
</ng-template>

<ng-template #newCustomer let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Create New Customer</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="d-flex justify-content-between">
                <input type="text" [(ngModel)]="customerName" placeholder="Enter customer name" name="name" class="form-control"
                (keyup.enter)="applyValues()" required>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button [disabled]="!customerName" class="btn gradient-pomegranate"
                (click)="createCustomer()">Create</button>
        <button type="button" class="btn" (click)="dismissModal(modal)">Cancel</button>
    </div>
</ng-template>