import { ItemCategory } from './../../models/items';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
    selector: 'app-add-category',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {

    public categoryId: number = 0;

    private routeSub$: Subscription;

    constructor(route: ActivatedRoute,
        private toastr: ToastrService,
        private location: Location) {
        this.setParameters(route);
    }

    ngOnInit(): void { }

    setParameters(route: ActivatedRoute) {
        this.routeSub$ = route.params.subscribe(params => {
            this.categoryId = +params.id || 0;
        });
    }

    onDataSuccess(result: { response: ItemCategory, message: string }): void {
        this.toastr.success('Success', result.message);
        this.location.back();
    }

    onDataError(error: any): void {
        console.error(error);
        this.toastr.error('Failed', 'Operation failed');
    }

    onCancel(): void {
        this.location.back();
    }

    ngOnDestroy(): void {
        this.routeSub$?.unsubscribe();
    }
}
