import { ResourceConstants } from "src/app/core/constants/resources.constants";
import { GFColumn, GFColumnTypes, GFilterParam } from "src/app/core/models/grid-filter.models";

export class AdditionalFieldsDataHelper {

    static get FilterColumns(): GFColumn[] {
        return [
            {
                displayName: 'Name',
                columnName: 'field_name', columnType: GFColumnTypes.string, gridColumn: 'Name'
            },
            // {
            //     displayName: 'Part Number',
            //     columnName: 'part_number', columnType: GFColumnTypes.string, gridColumn: 'Part Number'
            // },
            // {
            //     displayName: 'SKU',
            //     columnName: 'sku', columnType: GFColumnTypes.string, gridColumn: 'SKU'
            // },
            // {
            //     displayName: 'Category',
            //     columnName: 'category', columnType: GFColumnTypes.ref, gridColumn: 'Category'
            // },
            // {
            //     displayName: 'Description',
            //     columnName: 'part_description', columnType: GFColumnTypes.string, gridColumn: 'Description'
            // },
            // {
            //     displayName: 'Item Price',
            //     columnName: 'item_price',
            //     columnType: GFColumnTypes.number,
            //     gridColumn: 'Price',
            //     resource: ResourceConstants.INV_ITEMS_PRICE
            // },
            // {
            //     displayName: 'Manufacturer',
            //     columnName: 'manufacturer', columnType: GFColumnTypes.ref, gridColumn: 'Manufacturer'
            // },
            // {
            //     displayName: 'Supplier',
            //     columnName: 'supplier', columnType: GFColumnTypes.ref, resource: ResourceConstants.INV_ITEMS_SUPPLIERS
            // },
            // {
            //     displayName: 'Supplier Part Number',
            //     columnName: 'supplier_part_number',
            //     columnType: GFColumnTypes.string,
            //     resource: ResourceConstants.INV_ITEMS_SUPPLIERS_PN
            // },
            // {
            //     displayName: 'Quantity',
            //     columnName: 'quantity', columnType: GFColumnTypes.number, gridColumn: 'Quantity'
            // },
        ];
    }

    static get DefaultFilters(): GFilterParam[] {
        return [
            { displayName: "All Additional Fields", colname: '', condition: 'is', value: null, operator: 'AND' },
            { displayName: "Active Additional Fields", colname: 'is_active', condition: 'is', value: 'true', operator: 'AND' },
            { displayName: "Inactive Additional Fields", colname: 'is_active', condition: 'is', value: 'false', operator: 'AND' },
        ];
    }

    static get SearchFilters(): GFilterParam[] {
        return [
            {
                displayName: 'Name', colname: '', condition: '', value: '',
                children: [
                    { colname: 'field_name', condition: 'contains', value: '', operator: 'OR' },
                ]
            },
        ];
    }

    static get SortLookup(): any {
        return {
            item_name: 'itemname',
            part_number: 'part_number',
            category_name: 'categoryname',
            item_price: 'item_price',
            manufacturers_name: 'manufacturername',
            part_description: 'part_description',
            sku:'sku'
        };
    }
}