import { ShortNamePipe } from './pipes/short-name.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { GridFilterControlDirective } from './directives/grid-filter-control.directive';
import { DatePickerComponent } from './components/date-picker.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomFieldsPipe } from './pipes/custom-fields.pipe';
import { QuillModalComponent } from './components/quill-modal.component';
import { QuillModule } from 'ngx-quill';
import { HistoryViewComponent } from './components/history-view/history-view.component';
import { DataGridFilterComponent } from './components/data-grid-filter/data-grid-filter.component';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { UserCardComponent } from './components/user-card.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { DataImportComponent } from './components/data-import/data-import.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { AudioPlayerComponent } from './components/audio-player.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CommentsComponent } from './components/comments/comments.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DisableIfAllsuborgDirective } from './directives/disable-if-allsuborg.directive';
import { HistoryViewMessageComponent } from './components/history-view-message/history-view-message.component';
import { timezoneCheckPipe } from './pipes/timezone.pipe';
import { SafeHtmlPipe } from './pipes/common.pipe';
import { MapViewComponent } from './components/map-view/map-view.component';
import { LocationSearchComponent } from './components/location-search/location-search.component';
import { PreventSpecialCharactersDirective } from './directives/prevent-special_char.directive';
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decimal-number.directive';
import { ReplacePipe } from './pipes/replace.pipe';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ReplaceHtmlPipe } from './pipes/replace-html-pipe';
import { ParellaxTabSliderDirective } from './directives/parellax-tab-slider.directive';
import { PaymentsModule } from '../modules/payments/payments.module';
import { InputRangeValidatorDirective } from './directives/input-range-validator.directive';
import { SFBtnGroupDirective, SFBtnGroupItemDirective } from './directives/sf-btn-group.directive';
import { DateTimeCheckPipe } from './pipes/date-time.pipe';

@NgModule({
  declarations: [
    FileUploaderComponent,
    DatePickerComponent,
    CustomFieldsPipe,
    QuillModalComponent,
    GridFilterControlDirective,
    OrderByPipe,
    HistoryViewComponent,
    DataGridFilterComponent,
    ShortNamePipe,
    UserCardComponent,
    TimePickerComponent,
    DataImportComponent,
    PhoneNumberPipe,
    AudioPlayerComponent,
    CommentsComponent,
    TagsInputComponent,
    DateRangePickerComponent,
    DisableIfAllsuborgDirective,
    HistoryViewMessageComponent,
    timezoneCheckPipe,
    SafeHtmlPipe,
    MapViewComponent,
    LocationSearchComponent,
    PreventSpecialCharactersDirective,
    TwoDigitDecimaNumberDirective,
    ReplacePipe,
    ReplaceHtmlPipe,
    InputRangeValidatorDirective,
    ParellaxTabSliderDirective,
    SFBtnGroupDirective,
    SFBtnGroupItemDirective,
    DateTimeCheckPipe
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    QuillModule.forRoot(),
    NgxMaskModule.forRoot(),
    ClipboardModule,
    NgxDaterangepickerMd.forRoot(),
    NgxSummernoteModule,
    // PaymentsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploaderComponent,
    DatePickerComponent,
    CustomFieldsPipe,
    QuillModalComponent,
    GridFilterControlDirective,
    OrderByPipe,
    HistoryViewComponent,
    DataGridFilterComponent,
    ShortNamePipe,
    NgxMaskModule,
    UserCardComponent,
    TimePickerComponent,
    PhoneNumberPipe,
    AudioPlayerComponent,
    ClipboardModule,
    CommentsComponent,
    TagsInputComponent,
    DisableIfAllsuborgDirective,
    timezoneCheckPipe,
    SafeHtmlPipe,
    MapViewComponent,
    LocationSearchComponent,
    PreventSpecialCharactersDirective,
    TwoDigitDecimaNumberDirective,
    ReplacePipe,
    ParellaxTabSliderDirective,
    DataImportComponent,
    InputRangeValidatorDirective,
    DateTimeCheckPipe
  ],
  providers:[
    OrderByPipe,
    MaskPipe,
    PhoneNumberPipe
  ]
})
export class SharedModule { }
