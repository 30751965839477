import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallReasonSelectComponent } from './components/call-reason-select/call-reason-select.component';
import { PrioritySelectComponent } from './components/priority-select/priority-select.component';
import { TimesheetSelectComponent } from './components/timesheet-select/timesheet-select.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TenantCustomersModule } from '../tenant-customers/tenant-customers.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UserModule } from '../user/user.module';
import { TimeSheetFormComponent } from './components/time-sheet-form/time-sheet-form.component';
import { EscalationSelectComponent } from './components/escalation-select/escalation-select.component';
import { ActionSelectComponent } from './components/action-select/action-select.component';
import { ProductTypeSelectComponent } from './components/product-type-select/product-type-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CallReasonFormComponent } from './components/call-reason-form/call-reason-form.component';
import { PriorityFormComponent } from './components/priority-form/priority-form.component';
import { EscalationFormComponent } from './components/escalation-form/escalation-form.component';
import { ActionFormComponent } from './components/action-form/action-form.component';
import { ProductTypeFormComponent } from './components/product-type-form/product-type-form.component';



@NgModule({
    declarations: [
        CallReasonSelectComponent,
        PrioritySelectComponent,
        TimesheetSelectComponent,
        TimeSheetFormComponent,
        EscalationSelectComponent,
        ActionSelectComponent,
        ProductTypeSelectComponent,
        CallReasonFormComponent,
        PriorityFormComponent,
        EscalationFormComponent,
        ActionFormComponent,
        ProductTypeFormComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        TenantCustomersModule,
        UserModule,
        NgxDatatableModule,
        NgSelectModule
    ],
    exports:[
        CallReasonSelectComponent,
        PrioritySelectComponent,
        TimesheetSelectComponent,
        TimeSheetFormComponent,
        EscalationSelectComponent,
        ActionSelectComponent,
        ProductTypeSelectComponent,
        CallReasonFormComponent,
        PriorityFormComponent,
        EscalationFormComponent,
        ActionFormComponent,
        ProductTypeFormComponent
    ]
})
export class ServiceTicketSelectModule { }
