<section *ngIf="model">
    <form name="form" #itemForm="ngForm" (ngSubmit)="itemForm.form.valid && partNumberHelper.valid && onSubmit()" novalidate>
        <div class="row">
            <div class="col-12 col-md-5">
                <div class="form-group">
                    <label class="val-required">Name</label>
                    <input type="text" [(ngModel)]="model.item_name" #itemname="ngModel" name="item_name" class="form-control" required>
                    <small class="form-text text-muted danger" *ngIf="itemname.invalid && (itemname.dirty || itemname.touched)">
                        <div *ngIf="itemname.errors.required">
                            Name is required.
                        </div>
                    </small>
                </div>
                <div class="form-group">
                    <label class="val-required">Category</label>
                    <category-select [(value)]="model.category" (setDefault)="currentCategory = $event" [addButton]="true" (change)="onCategoryChanged($event)" (clear)="categoryClear()"> </category-select>
                    <div *ngIf="currentCategory && !currentCategory.create_partnumber" class="mt-1 category-warning-text">
                        <span class="warning">&#8226; This category is not configured to generate Part Number.</span>
                    </div>
                    <div class="mt-1" *ngIf="lastGenPartNumber | async as lastpn">
                        <small class="text-info">{{lastpn}}</small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="val-required">Part Number</label>
                    <div class="d-flex flex-row">
                        <input type="text" [(ngModel)]="model.part_number" (change)="partNumberOnChange($event)" #partnum="ngModel" name="part_number" class="form-control" required>
                        <button type="button" [disabled]="!model.category || !currentCategory?.create_partnumber" (click)="generatePartNumber()" class="btn btn-info text-nowrap ml-1 pl-2 pr-2">
                          <i class="fa fa-cog"></i> Generate
                        </button>
                    </div>
                    <small *ngIf="!partNumberHelper.valid" class="text-muted danger">
                        {{partNumberHelper.message}}
                    </small>
                    <small class="form-text text-muted danger" *ngIf="partnum.invalid && (partnum.dirty || partnum.touched)">
                        <div *ngIf="partnum.errors.required">
                            Part Number is required.
                        </div>
                    </small>
                </div>
                <div class="form-group">
                    <label>Type</label>
                    <item-type-select [(value)]="model.type"></item-type-select>
                </div>
                <div class="form-group">
                    <label>Sku</label>
                    <input type="text" [(ngModel)]="model.sku" name="sku" class="form-control">
                </div>
            </div>
            <div class="col-12 col-md-5" style="padding-top: 25px;height:180px">
                <app-file-uploader [limit]="5" [primary]="model.primary_image" [files]="itemImages" [multiple]="true" (delete)="onFileDelete($event)">
                </app-file-uploader>
            </div>
        </div>
        <div class="row-seperator"></div>
        <div class="row">
            <div class="col-12 col-md-5">
                <div class="form-group" *ngIf="priceVisible()">
                    <label>Price</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input type="text" [(ngModel)]="model.item_price" #price="ngModel" name="item_price" class="form-control">
                    </div>
                </div>
                <div class="form-group">
                    <label>Account</label>
                    <sales-account-select [(value)]="model.sales_account"></sales-account-select>
                </div>
                <div class="form-group">
                    <label>Unit Of Measure</label>
                    <unit-type-select [(value)]="model.unit_types"></unit-type-select>
                </div>
                <div class="form-group">
                    <label>Product Line</label>
                    <app-product-line-select [(value)]="model.product_line"></app-product-line-select>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <!-- <div class="form-group">
                    <label>Tax</label>
                    <tax-select [(value)]="model.tax"></tax-select>
                </div> -->
                <div class="form-group taxable-toggle-div">
                    <div class="taxable-toggle-btn">
                        <label>Taxable : </label>
                        <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                            <input type="checkbox" [(ngModel)]="model.taxable" name="taxable" class="custom-control-input" id="taxable">
                            <label class="custom-control-label" for="taxable">
                                <span></span>
                            </label>
                        </div>
                    </div>
                    <!-- <tax-select [(value)]="model.tax"></tax-select> -->
                </div>
                <div class="form-group">
                    <label>Manufacturer</label>
                    <manufacturer-select [(value)]="model.manufacturer"></manufacturer-select>
                </div>
                <div class="form-group">
                    <label>Measurement</label>
                    <input type="text" [(ngModel)]="model.measurement" name="measurement" class="form-control">
                </div>
                <div class="form-group">
                    <label>Material</label>
                    <app-material-select [(value)]="model.material"></app-material-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-5">
                <div class="form-group">
                    <label>Description</label>
                    <textarea type="text" [(ngModel)]="model.part_description" #desc="ngModel" name="description" class="form-control"></textarea>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="form-group">
                    <label>Comments</label>
                    <textarea type="text" [(ngModel)]="model.comments" #cmd="ngModel" name="comments" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="row-seperator"></div>
        <div class="row">
            <div class="col-12 d-flex flex-row">
                <div class="sheet">
                    <label class="sheet-label">
                        Spec Sheet
                    </label>
                    <div class="sheet-link">
                        <i class="ft-file-text file-icon"></i>
                        <button (click)="specModal.toggle()" type="button" class="btn btn-sm sheet-edit-btn btn-info">
                            <i class="ft-edit"></i> Edit
                        </button>
                        <quill-modal #specModal title="Spec Sheet" [(value)]="model.spec_sheet" (submit)="saveSheet('spec_sheet')"></quill-modal>
                    </div>
                </div>
                <div class="sheet ml-4">
                    <label class="sheet-label">
                        Instruction Sheet
                    </label>
                    <div class="sheet-link">
                        <i class="ft-file-text file-icon"></i>
                        <button (click)="instModal.toggle()" type="button" class="btn btn-sm sheet-edit-btn btn-info">
                            <i class="ft-edit"></i> Edit
                        </button>
                        <quill-modal #instModal title="Instruction Sheet" [(value)]="model.instructions_sheet" (submit)="saveSheet('instructions_sheet')"></quill-modal>
                    </div>
                </div>
            </div>
        </div>
        <h5 class="mt-3 section-title">
            Additional Fields
            <button type="button" (click)="openModal(content)" class="btn btn-sm bg-light-secondary ml-4">Add New</button>
        </h5>
        <div class="row mt-3">
            <div class="form-group col-md-5" *ngFor="let customField of additionalFields">
                <label>{{customField.name}}</label>
                <div class="d-flex flex-row">
                    <input [(ngModel)]="customField.value" [name]="customField.id" class="form-control" type="text" />
                    <button (click)="deleteCustomField(customField.id)" class="btn af-delete-btn" type="button">
                        <i class="ft-trash-2"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-10 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                <button type="submit" [disabled]="!itemForm.form.valid || !model.category || !partNumberHelper.valid" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                    Save
                </button>
                <button type="button" (click)="onCancel.emit()" class="btn btn-secondary">Cancel</button>
            </div>
        </div>
    </form>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add Custom Field</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Key</label>
                <input type="text" [(ngModel)]="customFieldInputs.key" name="key" class="form-control">
            </div>
            <div class="form-group">
                <label>Value</label>
                <input type="text" [(ngModel)]="customFieldInputs.value" name="value" class="form-control">
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="customFieldInputs.key && addCustomField()" class="btn gradient-pomegranate">Save</button>
            <button type="button" (click)="modal.close('Save click')" class="btn btn-secondary">Cancel</button>
        </div>
    </ng-template>
</section>>
