import { ChangeDetectorRef, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SODashBoardCounts } from '../../models/sales-orders.models';
import { SalesOrdersService } from '../../services/sales-orders.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-sales-orders-dashboard',
  templateUrl: './sales-orders-dashboard.component.html',
  styleUrls: ['./sales-orders-dashboard.component.scss']
})
export class SalesOrdersDashboardComponent implements OnInit {

    @Output() ApplyFilter = new EventEmitter<any>();

    public dashlet: SODashBoardCounts = new SODashBoardCounts();

    public subs: SubSink = new SubSink();

    constructor(private service: SalesOrdersService,
        private cdr: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.getDashboardCounts();
    }

    getDashboardCounts() {
        this.subs.sink = this.service.getDashboardCounts().subscribe(response => {
          this.dashlet = response;
          this.cdr.markForCheck();
        });
    }

    setFilter(colname: string, value: any) {
        console.log(colname+','+value);
        this.ApplyFilter.emit({"colname":colname, "value":value});
    }

}
