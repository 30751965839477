import { PermissionConstants } from './../constants/permissions.constants';
import { ResourceConstants } from './../constants/resources.constants';
import { UserTypes } from './../enums/user-types';
import { RouteInfo } from "../models/route-info";

export const APP_ROUTES: RouteInfo[] = [
    {
        path: '/users/my-profile',
        title: 'My Profile',
        icon: 'ft-arrow-right submenu-icon',
        submenu: [],
        resource: '',
        permissions: [],
        userTypes: [UserTypes.Customer]
    },
    {
        path: '',
        title: 'Organization',
        icon: 'ft-aperture',
        class: 'has-sub',
        resource: '',
        permissions: [],
        userTypes: [UserTypes.SuperUser, UserTypes.Admin, UserTypes.User],
        submenu: [
            {
                path: '/users/my-profile',
                title: 'Profile',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.SuperUser, UserTypes.Admin, UserTypes.User]
            },
            {
                path: '/suborgs',
                title: 'Org/Sub-org',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.SuperUser, UserTypes.Admin]
            },
            {
                path: '/users',
                title: 'Users',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.SuperUser, UserTypes.Admin]
            },
            {
                path: '/users/groups',
                title: 'User Groups',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.Admin]
            },
            {
                path: '/roles',
                title: 'Roles',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.SuperUser, UserTypes.Admin]
            },
            {
                path: '/permissions',
                title: 'Permissions',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.SuperUser]
            },
            {
                path: '/resources',
                title: 'Resources',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.SuperUser],
            },
            {
                path: '/grids/meta',
                title: 'Grids',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.SuperUser],
            },
            {
                path: '/grids/views',
                title: 'Views',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: '',
                permissions: [],
                userTypes: [UserTypes.Admin],
            }
        ]
    },
    {
        path: '/tenants',
        title: 'Tenants',
        icon: 'ft-home',
        submenu: [],
        resource: '',
        permissions: [],
        userTypes: [UserTypes.SuperUser],
    },
    {
        path: '',
        title: 'Ring2Voice',
        icon: 'icon-call-end',
        class: 'has-sub',
        resource: '',
        permissions: [],
        userTypes: [UserTypes.SuperUser, UserTypes.Admin, UserTypes.User],
        submenu: [
            {
                path: '/ring2voice/buy-dids',
                title: "Buy Numbers",
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_PHONE_NUMBER_PURCHASE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/dids',
                title: "Phone Numbers",
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_PHONE_NUMBERS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/incoming-routes',
                title: 'Incoming Routes',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_FORWARD_NUMBERS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/reports/calls',
                title: 'Call History',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_REPORTS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/block-list',
                title: 'Block List',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_BLOCK_LIST,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/chats',
                title: 'Chats',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_CHAT,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/sip-endpoints',
                title: 'SIP Endpoints',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_CHAT,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/time-frames',
                title: 'Time Frames',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_TIME_FRAME,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/automated-block-list',
                title: 'Automated Block List',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_AUTOMATED_BLOCK_LIST,
                permissions: [PermissionConstants.VIEW,PermissionConstants.CREATE, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.SuperUser],
            },
            {
                path: '/ring2voice/automated-allow-list',
                title: 'Automated Allow List',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.R2V_MODULE,
                resource: ResourceConstants.R2V_AUTOMATED_ALLOW_LIST,
                permissions: [PermissionConstants.VIEW,PermissionConstants.CREATE, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            }
        ]
    },
    {
        path: '',
        title: 'Inventory',
        icon: 'icon-social-dropbox',
        class: 'has-sub',
        resource: '',
        permissions: [],
        userTypes: [UserTypes.SuperUser,UserTypes.Admin, UserTypes.User],
        submenu: [
            {
                path: '/inventory/items',
                title: 'Items/Service',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.INV_MODULE,
                resource: ResourceConstants.INV_ITEMS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/inventory/item-categories',
                title: 'Categories',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.INV_MODULE,
                resource: ResourceConstants.INV_CATEGORIES,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/inventory/assemblies',
                title: 'Assemblies',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.INV_MODULE,
                resource: ResourceConstants.INV_KITS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/inventory/assemblies/assembled',
                title: 'Built Assemblies',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.INV_MODULE,
                resource: ResourceConstants.INV_KITS_ASSEMBLE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/inventory/suppliers',
                title: 'Suppliers',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.INV_MODULE,
                resource: ResourceConstants.INV_SUPPLIERS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/inventory/manufacturers',
                title: 'Manufacturers',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.INV_MODULE,
                resource: ResourceConstants.INV_MANUFACTURERS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/inventory/catalog/list',
                title: 'Catalog',
                icon: 'ft-arrow-right submenu-icon',
                // class:'has-sub',
                globalResource: ResourceConstants.INV_MODULE,
                resource: ResourceConstants.INV_CATALOG,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.SuperUser,UserTypes.Admin],
                submenu: [
                ]
            },
            {
                path: '',
                title: 'Settings',
                icon: 'ft-settings',
                class: 'has-sub',
                resource: '',
                permissions: [],
                userTypes: [UserTypes.Admin, UserTypes.User],
                submenu: [
                    {
                        path: '/inventory/item-types',
                        title: 'Item Types',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.INV_MODULE,
                        resource: ResourceConstants.INV_ITEMTYPES,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/inventory/product-lines',
                        title: 'Product Lines',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.INV_MODULE,
                        resource: ResourceConstants.INV_PRODUCT_LINES,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/inventory/materials',
                        title: 'Materials',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.INV_MODULE,
                        resource: ResourceConstants.INV_MATERIALS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/inventory/units',
                        title: 'Unit Types',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.INV_MODULE,
                        resource: ResourceConstants.INV_UNITS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/inventory/states',
                        title: 'Tax Table',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.INV_MODULE,
                        resource: ResourceConstants.INV_STATE_TAX,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/inventory/warehouse',
                        title: 'Warehouse',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.INV_MODULE,
                        resource: ResourceConstants.INV_WAREHOUSE,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/inventory/labels',
                        title: 'Design labels',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.INV_MODULE,
                        resource: ResourceConstants.INV_LABELS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/inventory/additional-fields',
                        title: 'Additional Fields',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.INV_MODULE,
                        resource: ResourceConstants.INV_ADDITIONAL_FIELDS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },

                    // {
                    //     path: '/inventory/labels/pages',
                    //     title: 'Label templates',
                    //     icon: 'ft-arrow-right submenu-icon',
                    //     submenu: [],
                    //     globalResource: ResourceConstants.INV_MODULE,
                    //     resource: ResourceConstants.INV_LABELS,
                    //     permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                    //     userTypes: [UserTypes.Admin],
                    // },
                    // {
                    //     path: '/inventory/labels/print',
                    //     title: 'Print Label',
                    //     icon: 'ft-arrow-right submenu-icon',
                    //     submenu: [],
                    //     globalResource: ResourceConstants.INV_MODULE,
                    //     resource: ResourceConstants.INV_LABELS,
                    //     permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                    //     userTypes: [UserTypes.Admin],
                    // },

                ]
            }
        ]
    },
    {
        path: '',
        title: 'Customers',
        icon: 'ft-users',
        class: 'has-sub',
        resource: '',
        permissions: [],
        userTypes: [UserTypes.Admin, UserTypes.User],
        submenu: [
            {
                path: '/customers',
                title: 'Customers',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.CUSTOMER_MODULE,
                resource: ResourceConstants.CUSTOMERS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin]
            },
            {
                path: '/customers/contacts',
                title: 'Contacts',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.CUSTOMER_MODULE,
                resource: ResourceConstants.CUSTOMER_CONTACTS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin]
            },
            {
                path: 'customers/distributors',
                title: 'Distributors',
                icon: 'ft-arrow-right submenu-icon',
                globalResource: ResourceConstants.CUSTOMER_MODULE,
                resource: ResourceConstants.DISTRIBUTORS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
                submenu: []
            },
            {
                path: '/customers/license-numbers',
                title: 'License Numbers',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.CUSTOMER_MODULE,
                resource: ResourceConstants.CUSTOMER_LICENSE_NUMBERS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '',
                title: 'Settings',
                icon: 'ft-settings',
                resource: '',
                permissions: [],
                userTypes: [UserTypes.Admin, UserTypes.User],
                submenu: [
                    {
                        path: '/customers/types',
                        title: 'Customer Types',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.CUSTOMER_MODULE,
                        resource: ResourceConstants.CUSTOMER_TYPES,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/customers/system-types',
                        title: 'System Types',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.CUSTOMER_MODULE,
                        resource: ResourceConstants.CUSTOMER_SYSTEM_TYPES,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/customers/industries',
                        title: 'Industry',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.CUSTOMER_MODULE,
                        resource: ResourceConstants.CUSTOMER_INDUSTRY,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/customers/currency',
                        title: 'Currency',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.CUSTOMER_MODULE,
                        resource: ResourceConstants.CUSTOMER_CURRENCY,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/customers/payment-terms',
                        title: 'Payment Terms',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.CUSTOMER_MODULE,
                        resource: ResourceConstants.CUSTOMER_PAYMENT_TERMS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/customers/location-types',
                        title: 'Location Types',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.CUSTOMER_MODULE,
                        resource: ResourceConstants.CUSTOMER_LOCATION_TYPES,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/customers/location-system-types',
                        title: 'Location System Types',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.CUSTOMER_MODULE,
                        resource: ResourceConstants.CUSTOMER_LOCATION_SYSTEM_TYPES,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/customers/shipping-methods',
                        title: 'Shipping methods',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.CUSTOMER_MODULE,
                        resource: ResourceConstants.CUSTOMER_SHIPPING_METHODS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                ]
            }
        ]
    },
    {
        path: '',
        title: 'Service Tickets',
        icon: 'icon-bulb',
        class: 'has-sub',
        resource: '',
        permissions: [],
        userTypes: [UserTypes.Admin, UserTypes.User],
        submenu: [
            {
                path: '/service-tickets',
                title: 'Service Tickets',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                resource: ResourceConstants.SERVICE_TICKETS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/service-tickets/time-sheets',
                title: 'Timesheets',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                resource: ResourceConstants.ST_TIMSHEET,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '',
                title: 'Settings',
                icon: 'ft-settings',
                class: 'has-sub',
                resource: '',
                permissions: [],
                userTypes: [UserTypes.Admin, UserTypes.User],
                submenu: [
                    {
                        path: '/service-tickets/product-types',
                        title: 'Product Types',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                        resource: ResourceConstants.ST_PRODUCT_TYPES,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/service-tickets/timesheet-type',
                        title: 'Timesheet type',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                        resource: ResourceConstants.ST_TIMSHEET_TYPE,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/service-tickets/reasons-for-service',
                        title: 'Reasons for Service',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                        resource: ResourceConstants.ST_REASONS_FOR_SERVICE,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/service-tickets/priorities',
                        title: 'Priorities',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                        resource: ResourceConstants.ST_PRIORITIES,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/service-tickets/actions',
                        title: 'Actions',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                        resource: ResourceConstants.ST_ACTIONS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/service-tickets/escalations',
                        title: 'Escalation Periods',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                        resource: ResourceConstants.ST_ESCALATION_PERIODS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    },
                    {
                        path: '/service-tickets/tasklist',
                        title: 'Task List',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        globalResource: ResourceConstants.SERVICE_TICKET_MODULE,
                        resource: ResourceConstants.ST_ESCALATION_PERIODS,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin],
                    }
                ]
            }
        ]
    },
    {
        path: '',
        title: 'Sales',
        icon: 'ft-dollar-sign',
        resource: '',
        class:'has-sub',
        permissions: [],
        userTypes: [UserTypes.Admin, UserTypes.User,UserTypes.Customer],
        submenu: [
            {
                path: '/sales/catalog',
                title: 'Catalogs',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: ResourceConstants.SALES_CATALOG,
                globalResource: ResourceConstants.SALES_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin,UserTypes.Customer]
            },
            {
                path: '/sales/invoice',
                title: 'Invoice',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: ResourceConstants.SALES_INVOICES,
                globalResource: ResourceConstants.SALES_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin,UserTypes.Customer]
            },
            {
                path: '/sales/sales-orders',
                title: 'Sales Orders',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: ResourceConstants.SALES_SALES_ORDERS,
                globalResource: ResourceConstants.SALES_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin,UserTypes.Customer]
            },
            {
                path: '/sales/service-contracts',
                title: 'Service Contracts',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: ResourceConstants.SALES_SERVICE_CONTRACTS,
                globalResource: ResourceConstants.SALES_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin,UserTypes.Customer]
            },
            {
                path: '/sales/warranty',
                title: 'Warranty',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: ResourceConstants.SALES_WARRANTY,
                globalResource: ResourceConstants.SALES_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin,UserTypes.Customer],
            },
            {
                path: '',
                title: 'Settings',
                icon: 'ft-settings',
                class: 'has-sub',
                resource: ResourceConstants.SALES_SETTINGS,
                globalResource: ResourceConstants.SALES_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
                submenu: [
                    {
                        path: '/sales/terms-conditions',
                        title: 'Terms & Conditions',
                        icon: 'ft-arrow-right submenu-icon',
                        submenu: [],
                        resource: ResourceConstants.SALES_TERMS,
                        globalResource: ResourceConstants.SALES_MODULE,
                        permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                        userTypes: [UserTypes.Admin]
                    }
                ]
            }
        ]
    },
    {
        path: '',
        title: 'Dispatch',
        icon: 'icon-wrench',
        resource: '',
        class:'has-sub',
        permissions: [],
        userTypes: [UserTypes.Admin, UserTypes.User],
        submenu: [
            {
                path: '/dispatch/board',
                title: 'Dispatch Board',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                resource: ResourceConstants.DISPATCH_BOARD,
                globalResource: ResourceConstants.DISPATCH_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin]
            },
            {
                path: '/dispatch/general-settings',
                title: 'Settings',
                icon: 'ft-settings',
                class: '',
                resource: ResourceConstants.DISPATCH_SETTINGS,
                globalResource: ResourceConstants.DISPATCH_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'Automation Rules',
        icon: 'fa fa-cogs',
        class: 'has-sub',
        resource: '',
        permissions: [],
        userTypes: [UserTypes.Admin, UserTypes.User],
        submenu: [
            {
                path: '/automation/rules',
                title: 'Rules',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.AUTOMATION_MODULE,
                resource: ResourceConstants.AUTOMATION_RULES,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/automation/templates',
                title: 'Templates',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.AUTOMATION_MODULE,
                resource: ResourceConstants.AUTOMATION_TEMPLATES,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            },
            {
                path: '/automation/settings',
                title: 'Settings',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.AUTOMATION_MODULE,
                resource: ResourceConstants.AUTOMATION_SETTINGS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
            }
        ]
    },
    {
        path: '',
        title: 'Accounting',
        icon: 'fa fa-money',
        class: 'has-sub',
        resource: '',
        permissions: [],
        userTypes: [UserTypes.Admin, UserTypes.SuperUser, UserTypes.User],
        submenu: [
            {
                path: '/payments/list',
                title: 'Payments Recieved',
                icon: 'ft-dollar-sign',
                class: '',
                resource: ResourceConstants.DISPATCH_SETTINGS,
                globalResource: ResourceConstants.DISPATCH_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
                submenu: []
            },
            {
                path: '/accountings/chart-of-accounts',
                title: 'Chart of Accounts',
                icon: 'ft-arrow-right submenu-icon',
                submenu: [],
                globalResource: ResourceConstants.INV_MODULE,
                resource: ResourceConstants.INV_SALES_ACCOUNTS,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin, UserTypes.SuperUser],
            },
            {
                path: '/payments/general-settings',
                title: 'Settings',
                icon: 'ft-settings',
                class: '',
                resource: ResourceConstants.DISPATCH_SETTINGS,
                globalResource: ResourceConstants.DISPATCH_MODULE,
                permissions: [PermissionConstants.VIEW, PermissionConstants.GLOBAL],
                userTypes: [UserTypes.Admin],
                submenu: []
            }
        ]
    }
]
