import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam, Pagination } from 'src/app/core/models/grid-filter.models';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { WINDOW } from 'src/app/core/services/window.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { UIPermission } from 'src/app/core/models/common.models';
import { SIP } from '../../models/sip.models';
import { SIPService } from '../../services/sip.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SipOutBoundFunctionService } from '../../services/sipoutboundfunctionservice';
import { CallCenterService } from '../../services/call-center.service';
import { SipConnectionService } from '../../services/sip-connection.service';
import { CallcenterSharedService } from '../../services/call-center-shared.service';

@Component({
    selector: 'app-sip-endpoints',
    templateUrl: './sip-endpoints.component.html',
    styleUrls: ['./sip-endpoints.component.scss']
})
export class SipEndpointsComponent implements OnInit, OnDestroy {

    @ViewChild(DataGridFilterComponent) dataFilter: DataGridFilterComponent;

    public page: Pagination = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    public defaults: GFilterParam[] = [
        { displayName: "All SIP Endpoints", colname: '', condition: 'is', value: null, operator: 'AND' },
        { displayName: "All Online SIP Endpoints", colname: 'status', condition: 'is', value: 'Ready', operator: 'AND' },
        { displayName: "All Offline SIP Endpoints", colname: 'status', condition: 'is', value: 'Offline', operator: 'AND' }
    ];

    public searchFilters: GFilterParam[] = [
        {
            displayName: 'Combo Search', colname: '', condition: '', value: '',
            children: [
                { displayName: 'Username', colname: 'username', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Caller ID', colname: 'caller_id', condition: 'contains', value: '', operator: 'OR' },
                { displayName: 'Send As', colname: 'send_as', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                { displayName: 'Assigned To', colname: 'associated_username', condition: 'contains', value: '', operator: 'OR' }
            ],
        }
    ];

    public columns: GFColumn[] = [
        { displayName: 'Username', columnName: 'username', columnType: GFColumnTypes.string },
        { displayName: 'Caller ID', columnName: 'caller_id', columnType: GFColumnTypes.string },
        { displayName: 'Send As', columnName: 'send_as', columnType: GFColumnTypes.string },
        { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date }
    ];

    public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];

    public sortLookup = {
        username: 'username',
        caller_id: 'caller_id',
        send_as: 'send_as',
        created_on: 'created_on',
        suborg_name: 'suborg_name',
        customer_name: 'customer_name',
        associated_username: 'associated_username'
    };
    @Input() showGridFilter: boolean = true
    
    public SelectionType = SelectionType

    @Input() rows: SIP[] = [];

    @Input() sipData : {count : number,result :SIP[]} = {count:0, result:[]}

    @Input() showQuickFilter:boolean = false

    @Input() enableViewDetails:boolean = true

    @Input() showAction : boolean = true

    public uniqeId: string;

    public expanded: boolean = true;

    public selected: SIP;

    public subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public isSuperAdmin: boolean;

    public module = ModuleConstants.RING2VOICE_SIP_ENDPOINTS;

    public UIPermissions: UIPermission;

    public selectedId: string;

    public verificationSend: boolean;

    public code: string;

    @Input() set dynamicHeight(value:number){
        if(value){
            this._tableHeight=value;
            const newHeight = this._tableHeight;
            this.elementRef.nativeElement
              .querySelector('.datatable-body')
              .setAttribute('style', `max-height: ${newHeight}px; overflow: hidden auto;`);
        }
    } 

    public _tableHeight:number;

    @Input() forCallCenter: boolean;

    constructor(private cdr: ChangeDetectorRef,
        route: ActivatedRoute,
        public location: Location,
        private toastr: ToastrService,
        private device: DeviceDetectorService,
        private sipService: SIPService,
        private ra: ResourceAccessService,
        private outboundService : SipOutBoundFunctionService,
        private callCenterService : CallCenterService,
        public sipConnectionService : SipConnectionService,
        public callcenterSharedService: CallcenterSharedService,
        private elementRef:ElementRef,
        @Inject(WINDOW) private window: Window) {

        this.isSuperAdmin = sipService.isSuperUser;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.R2V_SIP_ENDPOINTS, ResourceConstants.R2V_MODULE, true);
        this.addFilterColumns();
        this.selectedId = route.snapshot.paramMap.get('id');
    }

    addFilterColumns() {
        if (this.isSuperAdmin) {
            this.columns.push(...[
                { displayName: 'Tenant', columnName: 'customer', columnType: GFColumnTypes.ref },
                { displayName: 'Sub Org   (Choose Tenant First)', columnName: 'suborg', columnType: GFColumnTypes.ref, dependentColumn: 'customer' }
            ]);
        }
    }

    ngOnInit(): void {
        this.scrollbarH = this.device.isMobile();
        

        if (this.selectedId) {
            this.expanded = false;
            this.getById(this.selectedId);
        }

        if(this.sipData.count == 0){
            this.getData();
        }else{
            this.rows = this.sipData.result;
            this.setPagination(0, this.sipData.count);
        }

    }

    getData(filters: GFilterParam[] = null, offset: number = 0) {

        let request = { filters: filters, sort: this.sort }
        let suborg_id: number = this.forCallCenter? -1 : this.sipService.suborgId;// -1 for listing allSuborg sips in callcenter

        console.log('agentrequest', request)

        this.subs.sink = this.sipService.get(request, offset, this.page.limit, suborg_id).pipe(
            catchError(() => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.rows = response.result;
            this.setPagination(offset, response.count);
            this.cdr.markForCheck();
        });
    }

    filterOnlineOffline(tp)
    {
        debugger
        let filter :any
        if(tp == 0)
        {
            this.getData()
        }
        else if(tp == 1)
        {
            filter = this.defaults.filter((x)=>x.displayName == "All Online SIP Endpoints")
        }
        else
        {
            filter = this.defaults.filter((x)=>x.displayName == "All Offline SIP Endpoints")
        }

        this.getData(filter)       
    }


    getById(id: string) {
        this.subs.sink = this.sipService.getById(id).subscribe(response => {
            this.selected = response;
            this.cdr.markForCheck()
        })
    }

    setPagination(offset: number, total: number) {
        this.page.count = total;
        let upperLimit = offset + this.page.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.page.pageNumber = offset + '-' + upperLimit;
    }

    onSelect({ selected }) {
        if(this.enableViewDetails == true)
        {
            let current: SIP = selected[0];
            if (current && this.UIPermissions.viewInfo) {
                this.uniqeId = current.id;
                this.selected = current;
                this.expanded = false;
                this.verificationSend = false;
                this.location.go(`/ring2voice/sip-endpoints/${current.id}/view`);
                this.refreshDataTable();
            }
        }
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    setPage(pageInfo: any) {
        let offset = pageInfo.offset * this.page.limit;
        this.page.offset = pageInfo.offset;
        const filters = this.dataFilter?.getDataFilters();
        this.getData(filters, offset);
        this.scrollToTop();
    }

    onSort(event: any) {
        if (event.sorts && event.sorts.length > 0) {
            let current = event.sorts[0];
            if (!this.sortLookup[current.prop]) return;
            let sort = new GSortParam();
            sort.colname = this.sortLookup[current.prop];
            sort.direction = current.dir;
            this.sort = [sort];
            this.getData(this.dataFilter.getDataFilters());
        }
    }

    applyFilter(params: GFilterParam[]) {
        if(params&&params.length>0){
            this.getData(params);
        }else{
            let offset = this.page.offset * this.page.limit;
            this.getData(this.dataFilter.getDataFilters(),offset);
        };
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        this.location.go(`/ring2voice/sip-endpoints`);
        this.refreshDataTable();
    }

    deleteSIP(id: string) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.sipService.delete(id).subscribe(
                    () => {
                        this.toastr.success(`Successfully deleted SIP Endpoint`);
                        this.closeDetailedView();
                        this.applyFilter(null);
                    },
                    () => {
                        this.toastr.error("Failed to delete SIP Endpoint")
                    }
                )
            }
        });
    }

    scrollToTop() {
        this.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    sendVerificationCode() {
        this.subs.sink = this.sipService.sendSIPVerificationCode(this.selected.id).subscribe(
            resp => {
                this.toastr.success("A verification code has been sent to your email address");
                this.verificationSend = true;
                this.cdr.markForCheck();
            },
            error => {
                this.toastr.error("Unable to send Verificaton code");
            }
        );
    }

    verifyCode() {
        this.subs.sink = this.sipService.verifySIPVerificationCode(this.selected.id, this.code).subscribe(
            resp => {
                this.selected.password = resp['password'];
                this.rows.find(item=> item.id === this.selected.id).password = resp['password'];
                this.verificationSend = false;
                this.cdr.markForCheck();
            },
            error => {
                this.toastr.error("The Verificaton code you entered is Invalid");
            }
        );
    }

    callAgent(row:any)
    {
        this.callCenterService.popupOpenStatus.next({ isOpen: true, selectedTab: 2, })
        this.outboundService.makeCall('sip',this.callcenterSharedService.sip_details.send_as,row.sip_uri,row.id)
    }

    ngOnDestroy(): void { this.subs.unsubscribe(); }
}
