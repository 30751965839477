import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,} from '@angular/cdk/drag-drop';
import {MatTable} from '@angular/material/table';
import { CatalogueFunctionalService } from '../../services/catalogue-functional.service';
import { SweetAlertOptions } from 'sweetalert2';
import * as popup from 'src/app/core/utils/popup.functions';
import { MatMenuTrigger } from '@angular/material/menu';
import { debug } from 'console';

@Component({
  selector: 'assoc-items-tab',
  templateUrl: './assoc-items-tab.component.html',
  styleUrls: ['./assoc-items-tab.component.scss'],
  
})
export class AssocItemsTabComponent implements OnInit {

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  public currentPageIndex: number = 0

  displayedColumns = ['product_name','product_part_number','product_type','sku','category_name','sellprice','action']

  dragDisabled = true;

  @Input() _productListbyPage : any[] = [] 

  @Input() _sectionIndex : number

  @Input() _pageIndex : number

  @Input() _maxProCountInPage : number
 
  @Output() _onItemMove : any = new EventEmitter<any>()

  @Output() _onItemRemove : any = new EventEmitter<any>()

  @Output() _onItemDelete : any = new EventEmitter<any>()

  @Input() _canvasHeightInPixel :any


  constructor(
    private catalogueFnService:CatalogueFunctionalService,
    private cdr: ChangeDetectorRef
  ){}

  ngOnInit(): void {
   
  }


  drop(event: CdkDragDrop<any>,pageindx,secindx) {  //test

    this.dragDisabled = true;

    console.log('pageindx',pageindx)

    if (event.previousContainer === event.container) {

     
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.onItemMoveInPage(pageindx,secindx)
    
    } else {  // if Moving to different page in section

      if(event.container.data.length >= this._maxProCountInPage)
    {
    

      let option: SweetAlertOptions = {
        title: 'Alert',
        text:'Maximun product count reached',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn',
          footer: 'flex-row-reverse'
        },
        buttonsStyling: false
      };

      popup.OpenConfirmBox(option, result => {
        if (result.isConfirmed) {
             return
            }  

          });
      return
    }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      this.removeItemOnMove(event,secindx)
      this.onItemMoveBetweenPage(event,secindx,pageindx)
       console.log('event', event)
    }
    this.reRenderAllRows()
   
  
  }

  reRenderAllRows()
  {
    this.tables.forEach((table, index) => {
      table.renderRows(); 
   });
  }
  onItemMoveInPage(pageindx,secindx)
  {
    
    const startOrdNo = this._productListbyPage[secindx][pageindx].reduce((min, obj) => {
      return obj.order_number < min ? obj.order_number : min;
    }, Infinity);

    const endOrdNo = this._productListbyPage[secindx][pageindx].reduce((max, obj) => {
      return obj.order_number > max ? obj.order_number : max;
    }, -Infinity);

    
    let startIndex:number =  startOrdNo - 1
    let endIndex:number =  endOrdNo - 1

    let indx = 0

    var productInfo:any

    for(let i = startIndex;i <= endIndex; i++)
    {
      this._productListbyPage[secindx][pageindx][indx].order_number = i + 1 // to set changed associtemindex on move
      productInfo =  this._productListbyPage[secindx][pageindx][indx]
      this.catalogueFnService.catalogData[secindx][i] = productInfo
      indx++
    }  

    console.log("assoc after move",this.catalogueFnService.catalogData)

    const data:any = {'startIndex':startIndex,'endIndex':endIndex,'pageIndex':this.getPageIndexInCanvasArray(secindx,pageindx),'sectionIndex':secindx}

    this._onItemMove.emit(data)
  }


  onItemMoveBetweenPage(event,secIndex,pageindx)
  {

    console.log('event',event)
    console.log('pageindx',pageindx)

    ///For set associndx in productlist and catalog data corresponding to move
    
    
    this.reAssignOrderNos(secIndex)

    const startOrdNo = this._productListbyPage[secIndex][pageindx].reduce((min, obj) => {
      return obj.order_number < min ? obj.order_number : min;
    }, Infinity);

    const endOrdNo = this._productListbyPage[secIndex][pageindx].reduce((max, obj) => {
      return obj.order_number > max ? obj.order_number : max;
    }, -Infinity);

   
    let startIndex:number = startOrdNo - 1
    let endIndex:number =  endOrdNo - 1

    const data:any = {'startIndex': startIndex,'endIndex':endIndex,
    'pageIndex':this.getPageIndexInCanvasArray(secIndex,pageindx),'sectionIndex':secIndex}

    console.log('dataaa',data)

    this._onItemMove.emit(data)

    console.log('catalod data on between move', this._productListbyPage)
  }

  getPageIndexInCanvasArray(secIndx:number,pageIndxInSec:number)
  {
    var pageIndxInCanvasArray

    if(secIndx == 0)
    {
      pageIndxInCanvasArray = pageIndxInSec 
    }
    else
    {
      var prev_SectPageCount = this._productListbyPage.slice(0,secIndx).reduce((accum,current)=>{
        return accum += current.length
      },0)
      pageIndxInCanvasArray = prev_SectPageCount + pageIndxInSec 
    }  
    return pageIndxInCanvasArray; 
  }

  reAssignOrderNos(secIndex)
  {
    let ordno = 1
    this._productListbyPage[secIndex].forEach((pageobjects,pageindex)=>{

      
      if(pageobjects.length > 0)
      {
        pageobjects.forEach((element,i) => {
          
          element.order_number = ordno;
          this.catalogueFnService.catalogData[secIndex][ordno - 1]=element
          ordno ++
        });
      }
    })
  }
  setPageListOnReOrder(item,secIndex,pageIndx)
  {
    this.catalogueFnService.catalogData[secIndex].splice(item.order_number - 1,1) 

    let orderno = 0
    this._productListbyPage[secIndex].forEach((pageelement,pagei)=>{
    
    
        pageelement.forEach((item,itemindx) => {

          if(pageelement.length > 0)
          {
            orderno++
          }
         
          if(pagei >= pageIndx)
          {

            
        
          if(this.catalogueFnService.catalogData[secIndex][orderno - 1])
          {
            this._productListbyPage[secIndex][pagei][itemindx] = this.catalogueFnService.catalogData[secIndex][orderno - 1]
            this._productListbyPage[secIndex][pagei][itemindx].order_number = orderno
          }
          else
          {
            this._productListbyPage[secIndex][pagei].splice(itemindx,1)
          }
        }
        });
           
      });

  
    this.reRenderAllRows()
    console.log('pageprolistafterreorder',this._productListbyPage)
  }

  onReorderItems(item,secIndex,pageIndx)
  {
    this.setPageListOnReOrder(item,secIndex,pageIndx)

    for(let i = pageIndx;i<=this._productListbyPage[secIndex].length;i++)
    {
      const startOrdNo = this._productListbyPage[secIndex][i].reduce((min, obj) => {
        return obj.order_number < min ? obj.order_number : min;
      }, Infinity);
  
      const endOrdNo = this._productListbyPage[secIndex][i].reduce((max, obj) => {
        return obj.order_number > max ? obj.order_number : max;
      }, -Infinity);
  
     
      let startIndex:number = startOrdNo - 1
      let endIndex:number =  endOrdNo - 1
  
      const data:any = {'startIndex': startIndex,'endIndex':endIndex,
      'pageIndex':this.getPageIndexInCanvasArray(secIndex,i),'sectionIndex':secIndex}
  
      console.log('dataaa',data)
  
      this._onItemMove.emit(data)
    }
  }

  removeItemOnMove(event,secindx) // for remove product template when item move to athore pages
  {
  
    const data = {'pageIndex':this.getPageIndexInCanvasArray(secindx,this.currentPageIndex), 'productIndex':  event.previousIndex}
    console.log('event', data)
    console.log('data', data)
    this._onItemRemove.emit(data) 
  }

  onItemDelete(item,secIndx:number, pageIndx:number,productIndxByPage:number)
  {
    const data = {'secIndx':secIndx,'pageIndx':pageIndx,'pageIndxInCanvasArray':this.getPageIndexInCanvasArray(secIndx,pageIndx),
    'productIndx':item.order_number - 1,'productIndxByPage':productIndxByPage}

    console.log('data delete',data)
    this._onItemDelete.emit(data)
    this.reRenderAllRows()
    this.reAssignOrderNos(secIndx)

  }

  setPageIndex(indx)  
  {
    this.currentPageIndex = indx
  }

  openMenu(): void {
    if (this.menuTrigger?.menuOpen) {
      this.menuTrigger.closeMenu();
    } else {
      this.menuTrigger.openMenu();
    }
  }
}

