<ngx-datatable class="bootstrap core-bootstrap"
  [scrollbarH]="scrollbarH"
  columnMode="force"
  [headerHeight]="50"
  [footerHeight]="footer ? 50 : 0"
  rowHeight="auto"
  [rows]="privilleges"
  [selected]="selected"
  [selectionType]="selectionType"
    [sorts]="[{prop:'resource_name',dir:'asc'}]">
    <ngx-datatable-column *ngIf="markable" name="" prop="selected" [sortable]="false" [canAutoResize]="false" [headerCheckboxable]="true" [checkboxable]="true" [width]="30">
    </ngx-datatable-column>
    <ngx-datatable-column name="Resource" prop="resource_name"></ngx-datatable-column>
    <ngx-datatable-column name="Permission" prop="permission_name"></ngx-datatable-column>
    <ngx-datatable-column name="Allowed" [sortable]="false" prop="allowed" [width]="60">
        <ng-template let-status="value" ngx-datatable-cell-template>
            {{status ? 'Yes' : 'No'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="actions" name="Status" [sortable]="false" [width]="60">
        <ng-template let-row="row" let-index="rowIndex" ngx-datatable-cell-template>
            <div class="custom-switch custom-switch-success custom-control mb-1 mb-xl-0">
                <input type="checkbox" class="custom-control-input" [id]="'switch_' + index" (change)="changePrevilegeStatus(row)" [(ngModel)]="row.allowed">
                <label class="custom-control-label mr-1" [for]="'switch_' + index">
                  <span></span>
                </label>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="actions" name="Action" [sortable]="false" [width]="60">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <button type="button" class="delete-btn text-danger" (click)="deletePrivilege(row.id)">
                    <i class="ft-trash-2"></i>
                </button>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="!footer"></ngx-datatable-footer>
</ngx-datatable>
