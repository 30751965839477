import { WHBaseModel } from "src/app/core/models/common.models";

export class SalesAccount extends WHBaseModel{

    public id: number;

    public name: string;

    public description:string;

    public customer: number;

    public account_type?: string;
}
