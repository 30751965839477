
export enum PermissionConstants {
    CREATE = "CREATE",
    VIEW = "VIEW",
    DELETE = "DELETE",
    MODIFY = "MODIFY",
    GLOBAL = "GLOBAL",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    HIDE = "HIDE",
    VIEW_INFO = "VIEW_INFO",
    IMPORT = "IMPORT",
    EXPORT = "EXPORT",
    PRINT = "PRINT",
    DOWNLOAD = "DOWNLOAD"
}
