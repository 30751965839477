import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { EscalationPeriod } from '../models/common.models';

@Injectable({
  providedIn: 'root'
})
export class EscalationService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<EscalationPeriod[]>(`${this.baseURL}/escalation_period/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0, suborg_id:number = this.suborgId, limit = this.dataLimit) {
    let url = `${this.baseURL}/filter/escalation_period/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<EscalationPeriod[]>>(url, request).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getById(id: number) {
    return this.http.get<EscalationPeriod>(`${this.baseURL}/escalation_period/${id}/`);
  }

  create(model: EscalationPeriod) {
    return this.http.post<EscalationPeriod>(`${this.baseURL}/escalation_period/`, model);
  }

  update(id: number, model: EscalationPeriod) {
    return this.http.put<EscalationPeriod>(`${this.baseURL}/escalation_period/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<EscalationPeriod>(`${this.baseURL}/escalation_period/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/escalation_period/${id}/`);
  }
}
