import { OrderByPipe } from './../../../../shared/pipes/order-by.pipe';
import { forkJoin, Observable } from 'rxjs';
import { KitService } from './../../services/kit.service';
import { KitItem, KitItemRow, KitItemsType, KitWrapper, Kit2Kit } from './../../models/kit.models';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ItemWrapper } from '../../models/items';
import { ItemSelectComponent } from '../select/item-select/item-select.component';
import { KitSelectComponent } from '../select/kit-select/kit-select.component';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from '../../services/item.service';
import { SubSink } from 'subsink';
import { map } from 'rxjs/operators';
import * as popup from 'src/app/core/utils/popup.functions';
import { getLightColor, getRandomLightColor } from 'src/app/core/utils/global.functions';

@Component({
    selector: 'kit-items',
    templateUrl: './kit-items.component.html',
    styleUrls: ['./kit-items.component.scss'],
})
export class KitItemsComponent implements OnInit, OnDestroy {

    @ViewChild('itemSelect') itemSelect: ItemSelectComponent;

    @ViewChild('kitSelect') kitSelect: KitSelectComponent;

    @Input() summaryRow: boolean = true;

    @Input() deleteOption: boolean = true;

    @Input() saveButton: boolean = false;

    @Input() addButton: boolean = true;

    private _kitid: number = 0;

    @Input() set kitId(value: number) {
        this._kitid = value;
        if (value) {
            this.getKitItems();
        }
    };

    get kitId() { return this._kitid; }

    @Input() clone: boolean = false;

    public fileUrl: string = environment.apiURL + '/static/files/';

    public rows: KitItemRow[] = [];

    public KitItemTypes = KitItemsType;

    public rowIndex: number;

    public hasKits: boolean = false;

    public newItem: boolean = false;

    private subs: SubSink = new SubSink();

    public isAllSuborg: boolean;

    constructor(
        private toastr: ToastrService,
        private itemService: ItemService,
        private kitService: KitService,
        private cdr: ChangeDetectorRef,
        private orderBy: OrderByPipe
    ) {
        this.isAllSuborg = this.itemService.isAllSuborg;
    }

    ngOnInit() { }

    private getKitItems() {
        this.subs.sink = forkJoin([this.getItemInKit(this.kitId), this.getKitsInKit(this.kitId)]).subscribe((results) => {
            if (results && results.length) {
                this.rows = [...results[0], ...results[1]].sort((a, b) => a.id - b.id);
                if (this.clone) {
                    this.kitId = 0;
                }
                this.cdr.markForCheck();
            }
        });
    }

    getItemInKit(kitid: number) {
        return this.kitService.getItemsByKit(kitid).pipe(
            map((res) => {
                let rows: KitItemRow[] = [];
                if (res && res.length) {
                    for (let item of res) {
                        let row = new KitItemRow();
                        row.itemType = KitItemsType.ITEM;

                        row.id = this.clone ? 0 : item.id;
                        row.kit_id = this.clone ? 0 : item.kits;

                        row.item_id = item.items;
                        row.primary_image = item.primary_image;
                        row.customer = item.customer;
                        row.item_name = item.item_name;
                        row.part_number = item.part_number;
                        row.quantity = item.quantity || 1;
                        row.sell_price = item.item_price;
                        row.sell_price_calc = item.item_price * item.quantity;
                        row.order = item.order;
                        row.stock =item.is_inventoried ?  item.stock ? item.stock.toString() : '0' : 'Non-Inventoried';
                        row.unit = item.unit || "";
                        row.note = item.note;
                        rows.push(row);
                    }
                }
                return rows;
            })
        );
    }

    getKitsInKit(kitid: number) {
        return this.kitService.getKitsInKit(kitid).pipe(
            map((res) => {
                let rows: KitItemRow[] = [];
                if (res && res.length) {
                    for (let k2k of res) {
                        let row = new KitItemRow();
                        row.itemType = KitItemsType.KIT;

                        row.id = this.clone ? 0 : k2k.id;
                        row.kit_id = this.clone ? 0 : k2k.kit;
                        row.primary_image = k2k.primary_image;
                        row.item_id = k2k.kit_kit;
                        row.customer = k2k.customer;
                        row.item_name = k2k.kit_name;
                        row.part_number = k2k.internal_number;
                        row.quantity = k2k.quantity || 1;
                        row.sell_price = k2k.sell_price;
                        row.sell_price_calc = k2k.sell_price * k2k.quantity;
                        row.order = k2k.order;
                        row.stock = k2k.is_inventoried ?  k2k.stock ? k2k.stock.toString() : '0' : 'Non-Inventoried';
                        row.unit = k2k.unit || "";
                        row.note = k2k.note;
                        rows.push(row);
                    }
                }
                return rows;
            })
        );
    }

    getOrderBy(rows: KitItemRow[]) {
        return rows.some((item) => item.order > 0) ? 'order' : 'item_name';
    }

    appendRow(type: KitItemsType) {
        this.newItem = true;
        this.toggleSelect(type)
    }

    toggleSelect(type: KitItemsType, index?: number) {
        if (index >= 0) {
            this.rowIndex = index;
            this.newItem = false;
        }
        if (type === KitItemsType.ITEM) {
            this.itemSelect.open();
        } else if (type === KitItemsType.KIT) {
            this.kitSelect.open();
        }
    }

    defineRow(type: KitItemsType) {
        let row = new KitItemRow();
        row.kit_id = this.kitId;
        row.itemType = type;
        row.customer = this.itemService.customerId;
        this.rows = this.rows.concat(row);
        this.rowIndex = this.rows.length - 1;
    }

    quantityOnChange(row: KitItemRow) {
        row.sell_price_calc = row.quantity * row.sell_price;
        this.rows = [...this.rows];
    }

    priceSummaryFunc(rows: KitItemRow[]) {
        return ('$ ' + rows.map((row) => row.sell_price_calc)?.reduce((a, b) => a + b, 0.0)?.toFixed(2));
    }

    deleteRow(row: KitItemRow) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                if (row.id) {
                    if (row.itemType === KitItemsType.ITEM) {
                        this.subs.add(this.kitService.deleteKitItem(this.kitId, row.id).subscribe(() => {
                            this.rows = this.rows.filter((ki) => ki.id !== row.id);
                            this.cdr.markForCheck();
                        }));
                    }
                    else if (row.itemType === KitItemsType.KIT) {
                        this.subs.add(this.kitService.deleteKits2Kit(this.kitId, row.id).subscribe(() => {
                            this.rows = this.rows.filter((ki) => ki.id !== row.id);
                            this.cdr.markForCheck();
                        }));
                    }
                }
                else {
                    this.rows = this.rows.filter((ki) => ki.guid !== row.guid);
                    this.cdr.markForCheck();
                }
                popup.CompleteDelete(row.itemType + ' has been deleted.');
                this.refreshDataTable();
            }
        });
    }

    refreshDataTable() {
        setTimeout(() => {
            this.rows = [...this.rows];
            this.cdr.markForCheck();
        });
    }

    onItemChange(item: ItemWrapper) {
        if (item && this.rows.length > 0) {
            if(this.rows.filter((elem, index) => {
                return elem.item_id == item.id
            }).length > 0){
            this.toastr.info('Already exist this Item');
            return;
            }
        }
        if (this.newItem) {
            this.defineRow(KitItemsType.ITEM)
        }
        if (item && this.rows[this.rowIndex]) {
            this.rows[this.rowIndex].item_id = item.id;
            this.rows[this.rowIndex].item_name = item.item_name;
            this.rows[this.rowIndex].part_number = item.part_number;
            this.rows[this.rowIndex].primary_image = item.primary_image;
            this.rows[this.rowIndex].sell_price = item.item_price;
            this.rows[this.rowIndex].sell_price_calc = item.item_price;
            this.rows[this.rowIndex].stock = item.is_inventoried ?  item.available_stock ? item.available_stock.toString() : '0' : 'Non-Inventoried';
            this.rows[this.rowIndex].unit = item.unit;
            this.rows = [...this.rows];
        }
    }

    onKitChange(kit: KitWrapper) {
        if (this.kitId === kit.id) {
            this.toastr.info('Cannot select this Assembly');
            return;
        }
        if (kit && this.rows.length > 0) {
            if(this.rows.filter((elem, index) => {
                return elem.item_id == kit.id
            }).length > 0){
            this.toastr.info('Already exist this Assembly');
            return;
            }
        }
        if (this.newItem) {
            this.defineRow(KitItemsType.KIT)
        }
        if (kit && this.rows[this.rowIndex]) {
            this.rows[this.rowIndex].item_id = kit.id;
            this.rows[this.rowIndex].item_name = kit.name;
            this.rows[this.rowIndex].part_number = kit.internal_number;
            this.rows[this.rowIndex].primary_image = kit.primary_image;
            this.rows[this.rowIndex].sell_price = kit.sell_price;
            this.rows[this.rowIndex].sell_price_calc = kit.sell_price;
            this.rows[this.rowIndex].stock = kit.is_inventoried ?  kit.available_stock ? kit.available_stock.toString() : '0' : 'Non-Inventoried';
            this.rows[this.rowIndex].unit = kit.unit;
            this.rows = [...this.rows];
        }
    }

    validate() {
        return this.rows.filter((item) => item.item_id > 0).length > 0;
    }

    getApis(kitid: number): Observable<any>[] {
        this.rows.forEach((row) => (row.kit_id = kitid));
        let items = this.convertKitItemRow();
        let apis: Observable<any>[] = [];
        apis.push(...this.getItemApis(kitid, items.items));
        apis.push(...this.getKitApis(kitid, items.kits));
        return apis;
    }

    getItemApis(kitid: number, items: KitItem[]) {
        let apis = []
        let add = items.filter((ki) => !ki.id);
        if (add.length) {
            apis.push(this.kitService.addKitItems(kitid, add));
        }
        let update = items.filter((ki) => ki.id);
        if (update.length) {
            apis.push(this.kitService.updateKitItems(kitid, update));
        }
        return apis;
    }

    getKitApis(kitid: number, kits: Kit2Kit[]) {
        let apis = []
        let add = kits.filter((ki) => !ki.id);
        if (add.length) {
            apis.push(this.kitService.addKits2Kit(kitid, add));
        }
        let update = kits.filter((ki) => ki.id);
        if (update.length) {
            apis.push(this.kitService.updateKits2Kit(kitid, update));
        }
        return apis;
    }

    convertKitItemRow(): { items?: KitItem[]; kits?: Kit2Kit[] } {
        let resp = { items: [], kits: [] };
        for (let i = 0; i < this.rows.length; i++) {
            let row = this.rows[i];

            if (!row.item_id) return null;

            if (row.itemType === KitItemsType.ITEM) {
                let ki = new KitItem();
                ki.id = row.id;
                ki.items = row.item_id;
                ki.kits = row.kit_id;
                ki.quantity = row.quantity;
                ki.customer = row.customer;
                ki.order = row.order;
                ki.note = row.note || "";
                resp.items.push(ki);
            }
            else if (row.itemType === KitItemsType.KIT) {
                let ki = new Kit2Kit();
                ki.id = row.id;
                ki.kit_kit = row.item_id;
                ki.kit = row.kit_id;
                ki.quantity = row.quantity;
                ki.customer = row.customer;
                ki.order = row.order;
                ki.note = row.note || "";
                resp.kits.push(ki);
            }
        }
        return resp;
    }

    submit(kitid: number, next?: (value: any) => any, error?: (value: any) => any): void {
        let apis = this.getApis(kitid);
        if (!apis.length) return next([])
        this.subs.sink = forkJoin(apis).subscribe(
            results => {
                if (next) next(results);
                else {
                    this.toastr.success(`Assembly Items updated`);
                    this.getKitItems();
                }
            },
            err => {
                if (err) error(error);
                else this.toastr.error(`Operation failed`);
            }
        );
    }

    expandKit(row: KitItemRow, level?:number) {
        if (!row.children) {
            this.subs.sink = forkJoin([this.getItemInKit(row.item_id), this.getKitsInKit(row.item_id)]).subscribe((results) => {
                if (results && results.length > 0) {
                    let items = [...results[0], ...results[1]];
                    if(level == 0)
                    row.level = level;
                    row.children = this.orderBy.transform(items, this.getOrderBy(items));
                    row.expanded = true;
                    row.children.map((el)=>{
                        el.level = row.level + 1;
                        el.color = getLightColor(el.level);
                    });
                    this.cdr.markForCheck();
                }
            });
        } else {
            row.expanded = !row.expanded;
        }
    }

    toggleOtherRows(row: KitItemRow) {
        for (let item of this.rows) {
            if (item.guid !== row.guid) {
                row.expanded = false;
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
