import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { addTerms, getTermsCat, listTerms, TermsCatResponse } from '../../../models/terms.models';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { concat, Observable, of } from 'rxjs';
import { TermsService } from '../../../services/terms.service';
import { AppConstants } from 'src/app/core/constants/app.constants';



@Component({
  selector: 'app-terms-select',
  templateUrl: './terms-select.component.html',
  styleUrls: ['./terms-select.component.scss']
})
export class TermsSelectComponent implements OnInit {



  public userAuth=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));

  private modalRef: NgbModalRef;

  private _selected: number;



  @Input() suborg_id: number;

  @Output() valueChange = new EventEmitter<number>();

  @Input() addButton: boolean = true;

  @Input() readonly: boolean=false;

  @Input() termsCatId:number;

  @Output() change = new EventEmitter<addTerms>();

  get selected(): number { return this._selected; }

  @Input() set selected(value: number) {
    this._selected = value;
    this.valueChange?.emit(value);
  }


  public placeholder: string;

  public options:Observable<listTerms[]>;

  public getTermsData:getTermsCat=new getTermsCat();

  // public catId:number=3;





  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private TermsService:TermsService,
  ) { }

  ngOnInit(): void {
    this.placeholder = this.hasPermission() && this.addButton ? "Select or Add Terms & Conditions" : "Select terms & conditions";
    this.getData();
  }

  getData(){
    // this.getTermsData.customer=this.userAuth.custid;

    // this.options=this.TermsService.getTerms(this.termsCatId,this.suborg_id);
    this.options = this.TermsService.getAll(this.suborg_id);
  }

  openModal(content: any): void {
    this.modalRef = this.modalService.open(content, { centered: true, size: 'lg' });
  }
  closeModal() { this.modalRef?.dismiss(); }

  onDataSubmittedSuccess(result: { response: TermsCatResponse, message: string }): void {
    this.toastr.success(result.message);
    // this.options = concat(this.options, of([result.response]));
    this.getData();
    this.selected=result.response.id;
    this.change?.emit(result.response);
    console.log(this.options);
    this.closeModal();
  }

  onDataError(_error: any): void {
    this.toastr.error('Operation failed');
    this.closeModal();
  }

  onCancel(): void { this.closeModal(); }

  onChange(terms: any) { this.change?.emit(terms); }

  searchFn(term: string, item: addTerms) {
    return (item.title).toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1;
  }

  hasPermission() {
    return this.ra.hasPermission(ResourceConstants.SALES_TERMS, [PermissionConstants.CREATE, PermissionConstants.GLOBAL])
      || this.ra.hasPermission(ResourceConstants.SALES_TERMS, [PermissionConstants.GLOBAL]);
  }





}
