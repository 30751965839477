import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CallsDashBoardData } from '../../models/dids.models';


@Component({
  selector: 'app-callreport-dashboard',
  templateUrl: './callreport-dashboard.component.html',
  styleUrls: ['./callreport-dashboard.component.scss']
})
export class CallreportDashboardComponent implements OnInit {

  @Input() dashboardData: Observable<CallsDashBoardData>;

  @Output() displayNameChange = new EventEmitter<string>()

  @Input() carddata: string;
  
  constructor() { }

  ngOnInit(): void {
  }

  setFilter(value:string)
  {
    this.displayNameChange.emit(value)
  }



}
