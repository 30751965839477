import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ResourceAccessService } from '../services/resource-access.service';

@Injectable({
    providedIn: 'root'
})
export class RouteGuard implements CanActivate {

    constructor(private ra: ResourceAccessService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const data = route.data;

        /** Allow current route if allowSuperAdmin = true*/
        if (data.allowSuperAdmin && this.ra.isSuperUser) {
            return true;
        }

        if (data.resource && data.permissions) {

            if (this.ra.hasRoutePermission(data.resource, data.permissions, data.globalResource)) {
                return true;
            }
            else {
                this.router.navigate(['not-found']);
            }
        }
        return false;
    }
}
