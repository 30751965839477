import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {

  @ViewChild('mapWrapper') mapWrapper:ElementRef;

  private map;

  public loadMap:boolean=false;

  public _width;

  public _refresh:boolean=false;


  @Input() set width(value:number){

    if(!value||value<1&&this.mapWrapper){
      this._width=this.mapWrapper?.nativeElement?.getBoundingClientRect().width;

    }
    else{
      this._width=value;
    }

    window.dispatchEvent(new Event("resize"));

  };

  @Input() height:number;

  @Input() lat:string;

  @Input() lon:string;

  @Input() zoomLevel:number=6;

  



  constructor(private cdr:ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {

    console.log(changes, 'changes');

    if(changes.lat&&!changes.lat.firstChange){
      this.refreshMap();
    }

    if(changes.lon&&!changes.lon.firstChange){
      this.refreshMap();
    }
    
  }

  ngOnInit(): void {
    this.initMap();
    // this._width=this.mapWrapper.nativeElement.getBoundingClientRect().width;

    // console.log(this.width,this.height);

  }

  ngAfterViewInit(){

    // if(!this._width||Number(this._width)<1){

       
    //     console.log(this.mapWrapper.nativeElement.getBoundingClientRect().width, 'width');


    //   this._width=this.mapWrapper.nativeElement.getBoundingClientRect().width;
    //   //  window.dispatchEvent(new Event("resize"));

    //   this.cdr.markForCheck();

    // }

    
    

  }

  ngAfterViewChecked(){

    // let wrapperSpec=this.mapWrapper.nativeElement.getBoundingClientRect();

    // if(this.width!=wrapperSpec.width){
    //   this.width=wrapperSpec.width;
    //     window.dispatchEvent(new Event("resize"));
    //   this.cdr.markForCheck();
    // }

  }

  initMap(){

    this.map = L.map('map', {
      center: [ this.lat, this.lon ],
      zoom: this.zoomLevel,
      attributionControl: false
    });

    // this.map=L.map("map").setView([39.8282, -98.5795], 7);

    // this.map=L.map('map').setView([51.505, -0.09], 13);

    // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   // maxZoom: 18,
    //   // minZoom: 3,
    //   // attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // });

    const tiles=L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    })

    tiles.addTo(this.map);

    var greenIcon = L.icon({
      iconUrl: 'assets/img/dispatch/customer-marker.png',
      // shadowUrl: 'leaf-shadow.png',
  
      iconSize:     [23.0, 34.5], // size of the icon
      // shadowSize:   [50, 64], // size of the shadow
      iconAnchor:   [11.5, 34.5], // point of the icon which will correspond to marker's location
      // shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
  });


    var marker = L.marker([this.lat, this.lon ],{icon: greenIcon}).addTo(this.map);

    // var latLngs = [ marker.getLatLng() ];
    // var markerBounds = L.latLngBounds(latLngs);
    // this.map.fitBounds(markerBounds);

    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
   }, 500);
   
}

refreshMap(){
  if(this.map){
    this.map.remove();
    this.initMap();
  }
// window.dispatchEvent(new Event("resize"));
// this.cdr.markForCheck();

}

}
