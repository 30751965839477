<div class="card">
    <div class="card-header">

    </div>
    <div class="card-content">

        <div class="card-body">

            <form #serviceContractForm="ngForm" (ngSubmit)="addSC();">
                <div class="">

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="form-group">

                                <label class="val-required">Select customer name</label>
                                <!-- <select class="form-control" formControlName="customerName">
                                    <option>Select customer name</option>
                                </select> -->
                                <tenant-customer-select class="select-md"
                                    [(value)]="model.tenant_customer"
                                    (valueChange)="customerChanged(null)"
                                    (change)="customerChanged($event)">
                                </tenant-customer-select>
                                <p class="text-danger error-text" *ngIf="submitted&&model.tenant_customer==undefined">
                                    Please select
                                    customer</p>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Customer contact</label>
                                <tenant-customer-contact-select
                                    [tenantCustomerId]="model.tenant_customer"
                                    [(value)]="model.tenant_customer_contact"
                                    [readonly]="!model.tenant_customer"
                                    (change)="customerContactChange($event)"
                                    (locationAdd)="onCustomerLocationAdded()"
                                >
                                </tenant-customer-contact-select>
                                <p class="text-danger error-text"
                                   *ngIf="submitted&&!_saveAsDraft&&model.tenant_customer_contact==undefined">Please
                                    select customer contact</p>

                                <!-- <select class="form-control" formControlName="tenant_customer_contact">
                                    <option>Select customer contact</option>
                                </select> -->
                            </div>
                            <div class="form-group">
                                <label class="val-required">Customer location</label>
                                <tenant-customer-location-select
                                #locationSelect
                                [tenantCustomerId]="model.tenant_customer"
                                [(value)]="model.tenant_customer_location"
                                [readonly]="!model.tenant_customer_contact"
                                >
                                </tenant-customer-location-select>
                                <p class="text-danger error-text"
                                   *ngIf="submitted&&!_saveAsDraft&&model.tenant_customer_location==undefined">Please
                                    select customer location</p>

                                <!-- <select class="form-control" formControlName="tenant_customer_location">
                                    <option>Select customer location</option>
                                </select> -->
                            </div>

                            <div class="form-group" *ngIf="model.tenant_customer_location">
                                <div class="checkbox">
                                    <input type="checkbox" [(ngModel)]="model.location_restriction"  name="location_restriction" id="location_restriction" class=""
                                    placeholder="Enter title for this document" />
                                    <label class="" for="location_restriction">Restricted to this location</label>
                                </div>

                            </div>

                            <div class="form-group">
                                <label>Title</label>
                                <input type="text" [(ngModel)]="model.title" name="title" class="form-control"
                                       placeholder="Enter title for this document" />
                            </div>
                            <div class="form-group">
                                <label class="val-required">Coverage type selection</label>
                                <div class="mt-1">
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn"
                                                [ngClass]="model.coverage_type_selection==1?'btn-secondary':'btn-outline-secondary'"
                                                (click)="model.coverage_type_selection=1">Common</button>
                                        <button type="button" class="btn"
                                                [ngClass]="model.coverage_type_selection==2?'btn-secondary':'btn-outline-secondary'"
                                                (click)="model.coverage_type_selection=2;model.coverage_type=0">Item by Item</button>
                                    </div>
                                </div>
                                <p class="text-danger error-text"
                                   *ngIf="submitted&&!_saveAsDraft&&(model.coverage_type_selection==0||model.coverage_type_selection==undefined)">Please
                                    select coverage selection type</p>
                            </div>
                            <div class="form-group" *ngIf="model.coverage_type_selection==1">
                                <label class="val-required">Service contract coverage</label>
                                <div class="mt-1">
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn"
                                                [ngClass]="model.coverage_type==1?'btn-secondary':'btn-outline-secondary'"
                                                (click)="changeCoverageType(1)">Meterial only</button>
                                        <button type="button" class="btn"
                                                [ngClass]="model.coverage_type==2?'btn-secondary':'btn-outline-secondary'"
                                                (click)="changeCoverageType(2)">Labor only</button>
                                        <button type="button" class="btn"
                                                [ngClass]="model.coverage_type==3?'btn-secondary':'btn-outline-secondary'"
                                                (click)="changeCoverageType(3)">Meterial & Labor</button>
                                    </div>
                                </div>
                                <p class="text-danger error-text"
                                *ngIf="submitted&&!_saveAsDraft&&model.coverage_type==0">Please
                                 select coverage type</p>

                            </div>



                        </div>
                        <div class="col-12 col-lg-6">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="val-required">SC Starts on</label>
                                       <ng-select [items]="scStarts"
                                       bindLabel="name" bindValue="id"
                                       placeholder="Select an option" name="sc_begins"
                                       [(ngModel)]="model.sc_begins" (change)="scStartChange($event)">

                                       </ng-select>

                                        <p class="text-danger error-text" *ngIf="submitted&&!_saveAsDraft&&!model.sc_begins">
                                            Select when the service contract begins
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="model.sc_begins==2">
                                    <div class="form-group">
                                        <label class="val-required">Select warranty</label>
                                            <warranty-select
                                            (change)="warrantyChange($event)"
                                            [readonly]="!model.tenant_customer"
                                            [tenentCustomerId]="model.tenant_customer"
                                            [value]="model.warranty"
                                            [reset]="warrantyReset"
                                            ></warranty-select>

                                        <p class="text-danger error-text" *ngIf="submitted&&!_saveAsDraft&&!model.warranty">Select associated warranty document
                                        </p>
                                    </div>
                                </div>
                            </div>
                                <!-- {{model.sc_begins}}||{{model.warranty}} -->
                            <div class="row" [hidden]="hideDateSection()">
                                <div class="col-md-4">
                                    <div class="form-group" id="starting_date">
                                        <label class="val-required">Start date</label>
                                        <!-- {{model.starting_date}} -->
                                        <app-date-picker [(value)]="model.starting_date" (valueChange)="model.starting_date=$event"  [valueFormat]="'yyyy-MM-dd'" >
                                        </app-date-picker>

                                        <!-- {{model.starting_date}} -->
                                        <p class="text-danger error-text" *ngIf="submitted&&!_saveAsDraft&&!model.starting_date">Select
                                            Service Contract start date
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label  [ngClass]="{'val-required':scId==0}">Duration</label>
                                       <ng-select [items]="duration"
                                       bindLabel="name"
                                       bindValue="id"
                                       placeholder="Select duration"
                                       name="durationModel"
                                       [readonly]="model.starting_date==null"
                                       [(ngModel)]="model.duration"
                                       (change)="durationChange($event)"
                                       >

                                       </ng-select>

                                        <p class="text-danger error-text" *ngIf="submitted&&!_saveAsDraft&&!model.duration&&!model.ending_date">Select
                                            duration
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4" [hidden]="scId==0&&model.duration==null&&!model.ending_date">
                                    <div class="form-group">
                                        <label class="val-required">End date</label>
                                        <!-- {{model.ending_date}} -->
                                        <app-date-picker [(value)]="model.ending_date"  (valueChange)="model.ending_date=$event" [valueFormat]="'yyyy-MM-dd'" >
                                        </app-date-picker>

                                        <!-- {{model.ending_date}} -->
                                        <p class="text-danger error-text" *ngIf="submitted&&!_saveAsDraft&&!model.ending_date">Select
                                            service contract end date</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="val-required">Billing interval</label>
                                <div class="mt-1">
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn"
                                                [ngClass]="model.billing_interval==1?'btn-secondary':'btn-outline-secondary'"
                                                (click)="model.billing_interval=1">Monthly</button>
                                        <button type="button" class="btn"
                                                [ngClass]="model.billing_interval==2?'btn-secondary':'btn-outline-secondary'"
                                                (click)="model.billing_interval=2">Yearly</button>
                                    </div>
                                </div>
                                <p class="text-danger error-text" *ngIf="submitted&&!_saveAsDraft&&(model.billing_interval==0||model.billing_interval==undefined)">Select
                                    billing interval
                                </p>

                            </div>
                            <div class="form-group">
                                <label class="">Terms and Conditions</label>
                                <app-terms-select (change)="termsChange($event)"
                                (valueChange)="termsValueChange($event)"
                                [selected]="model.terms_and_condition"
                                [suborg_id]="model.suborg"
                                ></app-terms-select>
                                <!-- <p class="text-danger error-text" *ngIf="submitted&&(!model.terms_and_condition||model.terms_and_condition==0)">
                                    Select terms & conditions
                                </p> -->
                            </div>

                            <div class="form-group">
                                <textarea class="form-control" [(ngModel)]="model.conditions" name="terms_and_condition"
                                          (ngModelChange)="model.conditions = $event" style="height: 200px;"
                                          placeholder="Terms and Conditions"></textarea>
                                <!-- <p class="text-danger error-text" *ngIf="submitted&&model.conditions&&model.conditions.length<1">Terms and
                                    conditions can
                                    not be empty</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <!-- {{model|json}} -->
                <!-- <hr/> -->
                <br />
                <h5>Items Covered Under Service Contract </h5>
                <br />
                <div class="col-md-12">

                    <!-- {{SCItemsGrid.rows}} -->

                    <sales-sc-items [scId]="scId"
                    [coverageType]="model.coverage_type"
                    [coverageTypeSelection]="model.coverage_type_selection"
                    [submitted]="submitted"
                     #kitItemGrid>
                    </sales-sc-items>


                    <div style="text-align: center; width: 100%;">
                        <!-- <button class="btn btn-sm btn-secondary">Add item</button> -->
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />

                </div>
                <div class="row">
                    <div class="col-12 col-md-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">

                        <button type="button" class="btn gradient-pomegranate mb-2 mr-sm-2 mb-sm-0"
                                (click)="addSC(model.status==4?true:false);submitted=true">
                            {{scId > 0 && model.status!=4 ? 'Save Changes' : scId > 0 && model.status==4 ? 'Save draft': 'Create'}}
                        </button>
                        <!-- <button type="button" (click)="saveAsDraft()" class="btn btn-secondary mr-sm-2">Save as draft</button> -->

                        <!-- <div class="btn-group gradient-pomegranate" role="group">

                        <button id="btnGroupDrop1" type="button" class="btn  mb-2 mb-sm-0 mr-sm-2 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <a class="dropdown-item" href="#">Save as draft</a>
                        </div>
                      </div> -->
                        <button type="button" (click)="addSC(true);" class="btn btn-secondary mb-2 mr-sm-2 mb-sm-0" *ngIf="model.status!=4">{{scId > 0 ? 'Move to draft' : 'Save as draft'}}</button>
                        <button type="button" (click)="addSC(false);" class="btn btn-secondary mb-2 mr-sm-2 mb-sm-0" *ngIf="scId > 0 && model.status==4">Remove from draft</button>
                        <button type="button" (click)="location.back()" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>

            </form>
        </div>
    </div>
