import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { SuborgService } from '../../services/suborg.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SubOrg } from '../../models/suborg.models';
import { StaticFile, ThumbNail } from 'src/app/core/models/files.models';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { FilesService } from 'src/app/core/services/files.service';

@Component({
    selector: 'app-add-sub-org',
    templateUrl: './add-sub-org.component.html',
    styleUrls: ['./add-sub-org.component.scss']
})
export class AddSubOrgComponent implements OnInit, OnDestroy {

    @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;

    public suborgId: number;

    public model: SubOrg;

    public isSuperAdmin: boolean;

    public nameExist: boolean = false;

    public subs: SubSink = new SubSink();

    public itemImages: StaticFile[];

    constructor(route: ActivatedRoute,
        private suborgService: SuborgService,
        private router: Router,
        public location: Location,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private fileService: FilesService) {

        this.isSuperAdmin = suborgService.isSuperUser;
        this.subs.add(route.params.subscribe(params => this.suborgId = +params.id || 0));
    }

    ngOnInit(): void {
        if (this.suborgId) {
            this.subs.add(this.suborgService.getById(this.suborgId).subscribe(user => {
                this.model = user;
                this.cdr.markForCheck();
                this.getImages()
            }));
        }
        else {
            this.model = new SubOrg();
            this.model.customer = this.suborgService.customerId;
        }
    }

    getImages(): void {
        this.subs.sink = this.suborgService.getSuborgImages(this.suborgId).subscribe(resp=> {
            this.itemImages = resp;
            this.model.images = resp.map(file => file.id);
            this.cdr.markForCheck();
        });
    }

    onFileDelete(fileId:string) {
        if (this.model.images) {
            this.model.images = this.model.images.filter(id => id != fileId);
            if (this.model.primary_image === fileId) {
                this.model.primary_image = null;
            }
        }
    }

    submit() {
        let thumbails: ThumbNail[] = this.fileUploader.getFiles();
        if (thumbails.length) {
            this.subs.sink = this.fileService.uploadFiles(thumbails, 'SUBORG').subscribe(
                response => {
                    let primary = this.fileUploader.thumbNails.find(t=> t.isPrimary);
                    if (primary) {
                        this.model.primary_image = primary.data_id || response.find(f=> f.name = primary.name)?.id;
                    }
                    else if(response.length){
                        this.model.primary_image = this.fileUploader.thumbNails[0].data_id || response[0].id;
                    }
                    this.model.images.push(...response.map(item => item.id));
                    this.onSubmit();
                },
                (error) =>{
                    console.log(error);
                });
        }
        else {
            this.model.primary_image = this.fileUploader.getPrimary()?.data_id || null;
            this.onSubmit();
        }
    }


    onSubmit() {

        let api: Observable<SubOrg> = this.suborgId ? this.suborgService.update(this.suborgId, this.model)
            : this.suborgService.create(this.model);

        this.subs.sink = api.subscribe(
            resp => {
                this.toastr.success(this.suborgId > 0 ? `Sub-Org info updated` : `New Sub-Org created`);
                this.router.navigate(['/suborgs', resp.id, 'view']);
            },
            (error) => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.toastr.error(this.suborgId > 0 ? `Failed to update Sub-Org` : `Failed to create Sub-Org`)
                }
            }
        )
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
