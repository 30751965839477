import { Component, ViewContainerRef, ElementRef, HostListener, Renderer2, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { fabric } from 'fabric';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { LabelCreateRequest, LabelListResponse } from 'src/app/modules/inventory/models/label.models';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
// import { HttpParams } from '@angular/common/http';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { FilesService } from 'src/app/core/services/files.service';
import { ThumbNail } from 'src/app/core/models/files.models';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { CurrencyPipe } from '@angular/common';
import { text } from 'pdfkit';
import * as _ from 'lodash';
import { last } from 'lodash';
import { CatalogueProductCreateRequest, SubNavSettings } from '../../../models/catalogue.models';
import { CatalogueService } from '../../../services/catalogue-service.service';
import { CatalogueSubnavComponent } from '../../../components/catalogue-subnav/catalogue-subnav.component';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { CommonService } from 'src/app/core/services/common.service';
import { CatalogueFunctionalService } from '../../../services/catalogue-functional.service';
import { CatalogueDataHelper } from '../../../helpers/catalogue-data.helpers';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { promise } from 'protractor';

@Component({
  selector: 'app-design-product',
  templateUrl: './design-product.component.html',
  styleUrls: ['./design-product.component.scss']
})
export class CatalogueDesignComponent implements OnInit {

  public selectedColor: string = '#000000';

  canvas: any;

  canvas2: any;

  pagePreviewCanvas: any;

  @ViewChild('categorySelect') categorySelect;

  @ViewChild('subNav') subNav: CatalogueSubnavComponent;

  @ViewChild('canvas2', { static: false }) canvasRef: ElementRef<HTMLCanvasElement>;

  @ViewChild('pagecanvas', { static: false }) pageCanvasRef: ElementRef<HTMLCanvasElement>;


  public sideNavStatus: boolean = false;

  public labelId: number = 0;

  public title: string = "";

  public editingMode: boolean = false;

  public category: number = 1;

  public width: number = 2;

  public showSizeSettings: boolean = true;

  public height: number = 2;

  public subs: SubSink = new SubSink();


  public availFields: any;

  public selectedDimention: any;

  public canvasZoom: number;

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public uploadedImgUrl: string = '';

  public LimitedTextbox: any;

  public pickedColor: string;

  public groupImageAddCount: number = 0;


  public componentType: string;
  public pageTitle: string;

  public designSetting = CatalogueDataHelper.componentDimention;

  public items = CatalogueDataHelper.itemFormat;


  public builtAssebly = CatalogueDataHelper.builtAssemblyFormat;

  public assebly = CatalogueDataHelper.assemblyFormat;

  public dummyData = CatalogueDataHelper.dummyData;


  public canvasBgColor = "#ffffff";


  public subNavSettings: SubNavSettings = new SubNavSettings();

  public newLeft: number = 0; newTop: number = 0;

  public showPreview: boolean = true;

  public isButton: boolean = false

  // public gridRow : number = 3

  // public gridColumn : number = 3

  public _rowcolCount: number = 6

  public showPageView: boolean = false

  public dragObject: any




  constructor(private renderer: Renderer2,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private catalogueService: CatalogueService,
    private fileService: FilesService,
    private currencyPipe: CurrencyPipe,
    public vcRef: ViewContainerRef,
    private cpService: ColorPickerService,
    private commonService: CommonService,
    private catalogFnService: CatalogueFunctionalService,
    private _routeLoc: Location) {



    // this.subs.sink = this.activeRoute.params.subscribe(
    //   params => {
    //     this.labelId = +params["id"]|| 0;
    //   }
    // );

    this.subs.sink = this.activeRoute.queryParams.subscribe(
      params => {
        this.componentType = params.type || null;


        this.subs.sink = this.activeRoute.params.subscribe(
          params2 => {

            this.labelId = +params2["id"] || 0;
          })
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      });
  }



  ngOnInit(): void {


    if (this.componentType) {

      this.width = this.designSetting[this.componentType].width;
      this.height = this.designSetting[this.componentType].height;
      this.pageTitle = this.designSetting[this.componentType].title;

      if (this.componentType == "product") {
        this.subNavSettings = new SubNavSettings();

        this.subNavSettings.attributes = true;
        this.subNavSettings.images = true;
        this.subNavSettings.buttons = true;
        this.subNavSettings.text = true;
        this.showSizeSettings = true;
      }
      else if (this.componentType == "page") {
        this.subNavSettings = new SubNavSettings();
        this.subNavSettings.images = true;
        this.subNavSettings.products = true;
        this.subNavSettings.text = true;
        this.showSizeSettings = false;

      }
      else if (this.componentType == "button") {

        this.subNavSettings = new SubNavSettings();
        this.subNavSettings.text = true;
        this.showSizeSettings = false;
        this.subNavSettings.shapes = true;
        this.showPreview = false;
        this.isButton = true


      }

    }
    else {
      this.width = 1;
      this.height = 1.5;
      this.pageTitle = "";
    }





    this.category = 1;

    if (this.category == 2) {
      this.availFields = this.assebly;
    }
    else {
      this.availFields = this.items;
    }

    // console.log(this.labelId);

    if (this.labelId != 0) {
      this.editingMode = true;
      this.edit(this.labelId);

    }
    // else {
    //   console.log('canvasInit');
    //   //this.canvasInit();

    // }




    // this.canvas.setWidth(this.width);
    // this.canvas.setHeight(this.height);
    // this.updateCanvasSize();

  }

  handleDeleteKeyboardEvent(event: KeyboardEvent) {

    // console.log('Pressed delete key'+event.code);

    // if(event.key === 'd'||event.key === 'D'||event.key==='Delete')
    if (event.key === 'Delete') {
      this.deleteElement();
    }


  }
  ngAfterViewInit(): void {
    // if(this.id==0)
    setTimeout(() => {

      this.canvasInit();

    }, 9);

  }


  canvasInit() {


    this.canvas = new fabric.Canvas('canvas', {
      backgroundColor: 'white'
    });

    this.canvas.preserveObjectStacking = true;

    // this.canvas2 = new fabric.Canvas('canvas2'); 

    this.canvas2 = new fabric.Canvas(this.canvasRef.nativeElement);
    var rect = new fabric.Rect();

    rect.toObject = (function (toObject) {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          name: 'dfgsdgsdfg'
        });
      };
    })(rect.toObject);

    this.canvas2.add(rect);

    rect.name = 'trololo';

    this.canvasZoom = Math.round(this.canvas.getZoom() * 100);



    if (this.width && this.height) {
      this.canvas.setDimensions({ width: this.width * 96, height: this.height * 96 });
      this.canvas2.setDimensions({ width: this.width * 96, height: this.height * 96 });
    }
    // this.updateCanvasSize();

    let newZoom = this.catalogFnService.calculateZoom(this.width, this.sideNavStatus);

    // this.canvas.on( 'object:moving', this.preventLeaving);
    let preventLeaving = this.catalogFnService.preventLeaving;
    let preventTextboxScaling = this.catalogFnService.preventTextboxScaling;
    let updateCanvas2 = this.updateCanvas2;
    let canvas = this.canvas;
    let canvas2 = this.canvas2;
    let dummyData = this.dummyData;
    let wrapCanvasText = this.catalogFnService.wrapCanvasText

    let onObjectSelection = this.onObjectSelection;
    // let testFun=this.testFun;
    let aThis = this;
    this.LimitedTextbox = this.catalogFnService.customTextBox();


    this.canvas.on('after:render', function (e) {
      //  preventLeaving(e);
      this.canvas2 = updateCanvas2(aThis);
      if(aThis.showPageView == true)
      {
        aThis.onChangePreview()
      }
     
    });


    this.canvas.on('object:scaling', function (e) {
      preventTextboxScaling(e, canvas);
      //  preventLeaving(e);
      //  testFun(e);
    });

    this.canvas.on('object:resizing', function (e) {
      preventTextboxScaling(e, canvas);

      //  preventLeaving(e);

    });


    this.canvas.on('object:moving', function (e) {

      // preventLeaving(e);

    });

    this.canvas.on('selection:created', function (e) {

      onObjectSelection(aThis);

    });

    this.canvas.on('drop', (evn: any) => {

      
      const sizeof100 = (aThis.width * 96 * 100) / 100
      let newLeft = (evn.e.offsetX * (sizeof100 / aThis.canvas.width))
      let newTop = (evn.e.offsetY * (sizeof100 / aThis.canvas.width))

      if (aThis.dragObject?.type == 'image') {
        aThis.addImageToCanvasOnDrag(evn)
      }
      else if (aThis.dragObject?.type == 'text') {
        aThis.addBigText(aThis.dragObject.val, newLeft, newTop)
      }
      else if (this.dragObject?.type == 'plaintext') {
        this.addElement('plaintext', newLeft, newTop)
      }
      else if (this.dragObject?.type == 'attribute') {
        
        this.addElement(aThis.dragObject.val, newLeft, newTop)
      }
      else if (this.dragObject?.type == 'component') {
        
        this.addElementGroup(aThis.dragObject.val, newLeft, newTop)
      }
    });

    this.canvas.on('object:over', function (evn) {
    });

    this.canvas.on('mouse:over', function (evn: any) {
    }.bind(this));

    canvas.on('mouse:down', (event: fabric.IEvent) => {
    })

    // this.canvas.on( 'before:transform', function(e){
    //   testFun(e);
    // });

    // this.canvas.on('object:modified',);
    // this.canvas.on('selection:created', this.updateTextboxSize);


    var input = document.getElementById('myInput');

    var fn = this;

    // var canvas=this.canvas;

    document.addEventListener('keydown', function (event) {


      if (canvas.getActiveObject() && !canvas.getActiveObject()?.isEditing) {

        if (event.code == 'Backspace' || event.code == 'Delete') {
          fn.deleteElement();
        }
      }

    });


    this.catalogFnService.newLeft = 10;
    this.catalogFnService.newRight = 10;

    this.zoomChanged(newZoom * 100);
  }

  newElementLeft(canvas = this.canvas) {


    if ((this.newLeft + 200 < canvas.width)) {
      this.newLeft += 10;
      return this.newLeft;
    }
    else {
      return this.newLeft = canvas.width / 3;

    }

  }

  newElementTop(canvas = this.canvas) {

    if ((this.newTop + 50 < canvas.height)) {
      this.newTop += 10;
      return this.newTop;
    }
    else {
      return this.newTop = canvas.height / 3;

    }
  }

  drop(event: CdkDragDrop<{ title: string; poster: string }[]>) {
    // moveItemInArray(this.items, event.previousIndex, event.currentIndex);


    //  console.log(this.canvas.);
  }

  addElement(item, left = 0, top = 0) {

    this.catalogFnService.addNewAttribute(item, this.canvas, true, left, top);
    this.updateCanvas2(this);

  }

  addElementGroup(event,left = 0,top = 0) {


    if (event.component.template.objects) {

      var objects = event.component.template.objects
      let is_Grid: boolean = false

      if (event.componentName == "productComponent") {

        is_Grid = this.canvas.getObjects().find((x) => x.name == "grid_Layout") ? true : false

      }
      if (is_Grid) {

        this.placeProductTemplateInGrid(event)
      }


      else {
        var obj = objects.find(x => x.name == 'button' && x.type == 'group')

        if (obj) {
          let indexToReplace = event.component.template.objects.indexOf(obj);
          let lastIndex = event.component.template.objects.length - 1;

          let temp = event.component.template.objects[indexToReplace];
          event.component.template.objects[indexToReplace] = event.component.template.objects[lastIndex];
          event.component.template.objects[lastIndex] = temp;

        }

        this.catalogFnService.addElementGroup(this.canvas, event.componentName, event.component, event.subElement, event.group,'page',left,top);
        this.updateCanvas2(this);
      }

    }


  }

  addFigure(event) {

    this.catalogFnService.addFigure(event.type, this.canvas)

  }

  addImageToCanvasOnDrag(evn) {

    let html = evn.e.dataTransfer.getData('text/html');
    let src = new DOMParser().parseFromString(html, "text/html")
      .querySelector('img').src;


    const sizeof100 = (this.width * 96 * 100) / 100
    let img1, canvas = this.canvas;
    let newLeft = (evn.e.offsetX * (sizeof100 / this.canvas.width))// - ((84.38 / 2) * (sizeof100 / this.canvas.width))
    let newTop = (evn.e.offsetY * (sizeof100 / this.canvas.width)) //- ((84.38 / 2) * (sizeof100 / this.canvas.width))

    fabric.Image.fromURL(src, function (myImg) {
      img1 = myImg.set({ left: newLeft, top: newTop });
      img1.scaleToHeight(67.5);
      img1.scaleToWidth(100);
      img1.name = 'prod_image';
      canvas.add(img1);
    }.bind(this), {
      crossOrigin: 'anonymous'
    });
    this.canvas = canvas
  }

  addImageToCanvas(src) {

    var img1, canvas = this.canvas;
    let newLeft = this.newElementLeft();
    let newTop = this.newElementTop();

    fabric.Image.fromURL(src, function (myImg) {
      var img1 = myImg.set({ left: newLeft, top: newTop });
      img1.scaleToHeight(67.5);
      img1.scaleToWidth(100);
      img1.name = 'prod_image';
      canvas.add(img1);
    }.bind(this), {
      crossOrigin: 'anonymous'
    });
    this.canvas = canvas
  }


  addPageToCanvas(json) {

    this.canvas.clear();

    this.canvas.loadFromJSON(json);
    this.canvas.renderAll();
  }

  updateTextAlignment(align) {
    if (this.canvas.getActiveObject().textAlign != align) {
      this.canvas.getActiveObject().textAlign = align;
      this.canvas.renderAll();
    }
  }

  elementBold() {

    this.catalogFnService.makeElementBold(this.canvas);
  }

  elementItalic() {

    this.catalogFnService.makeElementItalic(this.canvas);
  }

  elementUnderline() {

    this.catalogFnService.makeElementUnderline(this.canvas);
  }


  deleteElement() {

    this.catalogFnService.deleteElement(this.canvas);
    this.updateCanvas2(this);
    this.updateCanvas1()

  }
  changeGridColor(grid, color) {

    grid._objects.forEach(element => {

      var cell_rect = element._objects.find(x => x.name.includes('cell_grp'))
      if (cell_rect) {

        cell_rect.set('stroke', color);
      }
    });

  }

  save() {


    if (this.componentType == "page") {
      this.sortProductsByPosition()
    }

    if (this.title == '' || !this.title) {
      this.toastr.error('Please enter the a title for label design');
    }
    else {

      let data = new ThumbNail();



      var gridTemplate: any = this.canvas.getObjects().find((x) => x.name == "grid_Layout")

      if (gridTemplate) {

        this.changeGridColor(gridTemplate, this.canvas.backgroundColor)
        this.canvas.renderAll()

      }

      // console.log('gridTemplate',gridTemplate)

      data.file = this.catalogFnService.convertBase64ToBlob(this.canvas.toDataURL('image/png'));
      data.name = this.title;
      let module_name = 'CATALOGUE';



      this.fileService.uploadFiles([data], module_name).subscribe(
        response => {
          this.uploadedImgUrl = response[0].id;
          this.saveData(response[0].id);
        },
        () => {
          return '';
        }

      );
    }
  }

  findFirstProductInRow(objects: any) //this function for get first product in canvas
  {
    let startObject = null;
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      //if (!startObject || object.top < startObject.top && object.left < startObject.left) {

      if (!startObject || object.top <= startObject.top) {
        startObject = object;
      }
    }
    return startObject;

  }

  sortProductsByPosition() {
    var objectsToSort = this.canvas.getObjects().filter(obj => obj.type === 'group' && obj.name === 'productComponent');
    let sortedObjects = []


    for (let i = 0; i <= objectsToSort.length; i++) {

      // this.canvas.getObjects().filter(obj => obj.type === 'group' && obj.name === 'productComponent').forEach((obj) => {

      //   
      //   var value = sortedObjects.find(x => x.top == obj.top)
      //   if (value) {

      //     objectsToSort.splice(objectsToSort.findIndex(x => x.top == value.top), 1);
      //   }

      // });


      if (objectsToSort.length > 0) {
        var rowStartObject = this.findFirstProductInRow(objectsToSort)

        var top = rowStartObject.top
        var bottom = rowStartObject.top + rowStartObject.height;
        var rowObjects = objectsToSort.filter(x => x.top < bottom).sort((a, b) => { return a.left - b.left });

        // var rowObjectswithLeftValue = rowObjects.sort((a, b) => { return a.left < b.left })


        for (let j = 0; j < rowObjects.length; j++) {
          sortedObjects.push(rowObjects[j])
          let indxToRemove = objectsToSort.findIndex(x => x.left == rowObjects[j].left && x.top == rowObjects[j].top)
          objectsToSort.splice(indxToRemove, 1)
        }

      }
    }

    console.log('sortedObjects', sortedObjects)

    this.canvas.getObjects().filter(obj => obj.type === 'group' && obj.name === 'productComponent').forEach(group => this.canvas.remove(group));

    if (sortedObjects.length > 0) {
      for (let i = 0; i < sortedObjects.length; i++) {

        this.canvas.insertAt(sortedObjects[i], i);
        this.canvas.renderAll();
      }
    }
  }

  saveData(imageUrl: string) {


    let product = new CatalogueProductCreateRequest();

    product.image = imageUrl;

    //To persis additional fields added in the canvas object
    const originalToObject = fabric.Object.prototype.toObject;
    const myAdditional = ['name', 'customHeight'];
    fabric.Object.prototype.toObject = function (additionalProperties) {
      return originalToObject.call(this, myAdditional.concat(additionalProperties));
    }
    //To persis additional fields added in the canvas object


    product.template = this.canvas.toJSON();

    console.log('proTemplate', product.template)

    product.title = this.title;

    product.is_global_component = this.catalogueService.isSuperUser;

    if (this.componentType) {
      product.type = this.designSetting[this.componentType].typeId;
    }
    else {

      product.type = "0"
    }

    let svg = this.canvas.toSVG();
    product.svg = svg;

    //  label.tenant_customer=this.catalogueService.

    //  if(this.category==1){
    //   label.type='item';
    //  }
    //  else if(this.category==2){
    //   label.type='kit';
    //  }
    //  else{
    //   label.type='assembly'
    //  }
    //  label.type=this.category==1?'item':'kit';

    product.customer = this.catalogueService.customerId;


    if (this.width && this.height) {
      product.width = this.width * 96;
      product.height = this.height * 96;
    }
    else {

      product.width = this.canvas.width;

      product.height = this.canvas.height;
    }


    product.suborg = this.catalogueService.suborgId;

    let api = this.labelId > 0
      ? this.catalogueService.updateCatalogueProduct(this.labelId, product)
      : this.catalogueService.createCatalogueProduct(product);

    this.subs.sink = api.subscribe(
      response => {
        this.toastr.success("Catalog component saved successfully");
        this._routeLoc.back()

      }
    )

  }

  edit(id: number) {

    //var label: LabelListResponse;
    this.subs.sink = this.catalogueService.getCatalogueProduct(id).subscribe(
      response => {


        console.log('response', response)
        var label = response;


        this.title = label.title;
        // if (label.type == 'item') {
        //   this.category = 1;
        // }
        // else if (label.type == 'kit') {
        //   this.category = 2;
        // }
        // else {
        //   this.category = 3
        // }
        // //  this.category=label.type=='item'?1:2;
        // //  this.dimention=2;
        // this.editingMode = true;

        if (label.template != null) {

          //this.canvasInit();

          this.canvas.setWidth(label.width);
          this.canvas.setHeight(label.height);

          this.canvas2.setWidth(label.width);
          this.canvas2.setHeight(label.height);

          this.canvasZoom = Math.round(this.canvas.getZoom() * 100);


          let load = this.canvas.loadFromJSON(label.template, this.canvas.renderAll.bind(this.canvas), function (o, object) {


            console.log('objetcy', object)

          });

          this.canvas.renderAll()

          //   this.canvas = this.canvas;

          if (load) {

            setTimeout(() => {
              
              var gridTemplate: any = this.canvas.getObjects().find((x) => x.name == "grid_Layout")

              if (gridTemplate) {

                this.changeGridColor(gridTemplate, 'blue')
                this.canvas.renderAll()

              }
            }, 50);

            this.canvas2 = this.updateCanvas2(this);

          }

          this.width = label.width / 96;
          this.height = label.height / 96;

          let newzoom = this.catalogFnService.calculateZoomWithHeight(this.height, this.sideNavStatus)


          this.zoomChanged(newzoom * 100);
          // this.updateCanvasSize();
          // this.canvas2.renderAll();

        }

        this.cdr.markForCheck();




        // this.canvas=label.template!=null?JSON.parse(label.template):this.canvas;
      }
    )
  }

  @HostListener('window:resize', ['$event'])
  updateZoom() {
    // let newZoom = this.catalogFnService.calculateZoom(this.width, this.sideNavStatus);

    let newZoom = this.catalogFnService.calculateZoomWithHeight(this.height, false);
    this.zoomChanged(newZoom * 100);

  }


  zoomChanged(newValue: number) {



    this.canvas.setZoom(newValue / 100);
    console.log('newValue', newValue / 100)
    console.log('width', this.width * 96 * this.canvas.getZoom())
    console.log('zoom', this.canvas.getZoom())


    this.canvas.setWidth(this.width * 96 * this.canvas.getZoom());
    this.canvas.setHeight(this.height * 96 * this.canvas.getZoom());


    this.canvas2.setZoom(newValue / 100);
    this.canvas2.setWidth(this.width * 96 * this.canvas2.getZoom());
    this.canvas2.setHeight(this.height * 96 * this.canvas2.getZoom());

    this.canvasZoom = Math.round(this.canvas.getZoom() * 100);
    // this.width = Math.round(this.canvas.getWidth())
    // this.height = Math.round(this.canvas.getHight())

    this.cdr.markForCheck();

  }


  onChangePreview() {

    if (this.showPageView == true) {
      var zoom = 42
      var height = 11
      var width = 8.5

      setTimeout(() => {
        this.pagePreviewCanvas = new fabric.Canvas(this.pageCanvasRef.nativeElement);
        this.pagePreviewCanvas.setZoom(zoom / 100);

        this.pagePreviewCanvas.setWidth(width * 96 * this.pagePreviewCanvas.getZoom());
        this.pagePreviewCanvas.setHeight(height * 96 * this.pagePreviewCanvas.getZoom());
        this.pagePreviewCanvas.renderAll()
        this.addPagePreview()
      }, 0);

    }
    if (this.showPageView == false) {
      setTimeout(() => {
        this.canvas2 = new fabric.Canvas(this.canvasRef.nativeElement);

        this.updateZoom()
      }, 0);

    }
  }

  addPagePreview() {

    this.pagePreviewCanvas.clear();

    var component = {

      name: "productComponent",
      height: this.height * 96, //144 
      width: this.width * 96, // 96
      template: this.canvas.toJSON(),
    }

    this.catalogFnService.addElementGroup(this.pagePreviewCanvas, 'productComponent', component, false, null, 'product')
   // setTimeout(() => {
      this.pagePreviewCanvas.forEachObject(function(object){ 
        object.set({
          selectable: false,
          evented: false,
          hasControls: false,
          hasBorders: false,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true
        });
        this.pagePreviewCanvas.renderAll()
      }.bind(this));   
   // }, 100);

  }

  changeDimention() {
    this.canvas.setWidth(this.width * 96 * this.canvas.getZoom());
    this.canvas.setHeight(this.height * 96 * this.canvas.getZoom());

    this.canvas2.setWidth(this.width * 96 * this.canvas2.getZoom());
    this.canvas2.setHeight(this.height * 96 * this.canvas2.getZoom());

    this.canvasZoom = Math.round(this.canvas.getZoom() * 100);
  }

  changeType() {
    if (this.category == 1) {
      this.availFields = this.items;
    }
    else if (this.category == 2) {
      this.availFields = this.builtAssebly;
    }
    else {
      this.availFields = this.assebly;
    }
  }

  updateCanvas2(aThis) {


    const originalToObject = fabric.Object.prototype.toObject;
    const myAdditional = ['name'];
    fabric.Object.prototype.toObject = function (additionalProperties) {
      return originalToObject.call(this, myAdditional.concat(additionalProperties));
    }

    var template = aThis.canvas.toJSON();

    template.objects.forEach((element) => {

      if (element.type == "group") {

        if (element.objects.length) {
          element.objects.forEach((element) => {

            element = aThis.canvas2UpdateValue(element, aThis);
          });
        }
      }
      else {

        element = aThis.canvas2UpdateValue(element, aThis);
      }
    });

    aThis.canvas2.loadFromJSON(template, aThis.canvas2.renderAll.bind(aThis.canvas2), function (o, object) {

    });
    return aThis.canvas2;
  }

  canvas2UpdateValue(element, aThis) {



    // console.log(element);
    if (element.type == 'textbox' || element.type == 'i-text') {

      var canvas = aThis.canvas2;
      var formatted1;

      // var currencyPipe=this.currencyPipe;
      if (element.name == 'item_name' || element.name == 'assembly_name') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.item_name, canvas, element);
      }
      else if (element.name == 'werehouse') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.werehouse, canvas, element);
      }
      else if (element.name == 'werehouse_location') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.werehouse_location, canvas, element);
      }
      else if (element.name == 'serial_number') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.serial_number, canvas, element);
      }
      else if (element.name == 'sku') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.sku, canvas, element);
      }
      else if (element.name == 'item_type') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.item_type, canvas, element);
      }
      else if (element.name == 'category') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.category, canvas, element);
      }
      else if (element.name == 'part_number') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.part_number, canvas, element);
      }
      else if (element.name == 'sell_price') {

        let text = aThis.dummyData.sell_price ? aThis.dummyData.sell_price.toString() : '';
        text = aThis.currencyPipe.transform(text, 'USD');
        element.text = this.catalogFnService.wrapCanvasText(text, aThis.canvas2, element);
      }
      else if (element.name == 'supplier') {
        element.text = this.catalogFnService.wrapCanvasText(aThis.dummyData.supplier, canvas, element);
      }

    }
    else if (element.type == "image" && element.name == "item_image") {
      element.src = aThis.dummyData.item_image_url;
      element.crossOrigin = 'anonymous';
    }
    else if (element.name.includes('cell_grp')) {

      var cellrect = element.objects.find(x => x.name.includes('cell_grp'))
      var placeText1 = element.objects.find(x => x.name.includes('lbl1'))
      var placeText2 = element.objects.find(x => x.name.includes('lbl2'))

      if (cellrect) {
        element.objects.find(x => x.name.includes('cell_grp')).stroke = aThis.canvas2.backgroundColor
      }
      if (placeText1) {
        element.objects.find(x => x.name.includes('lbl1')).fill = aThis.canvas2.backgroundColor
      }
      if (placeText2) {
        element.objects.find(x => x.name.includes('lbl2')).fill = aThis.canvas2.backgroundColor
      }

    }

    element.editable = false;
    element.selectable = false;

    return element;

  }

  changeColor(value) {


    this.canvas.getActiveObject().set({ fill: value.color });

    this.canvas.renderAll();

  }

  changePresetColor(color) {
    this.canvas.getActiveObject().set({ fill: color });

    this.canvas.renderAll();
  }

  onObjectSelection(aThis) {
    aThis.selectedColor = aThis.canvas.getActiveObject().fill ?? aThis.canvas.getActiveObject().fill;
  }

  canvasBgChange(value) {
    this.canvas.set({ backgroundColor: value.color });

    this.canvas.renderAll();
  }

  canvasPresetBgChange(color) {
    this.canvas.set({ backgroundColor: color });
    this.canvas.renderAll();
  }


  addBigText(value, left = 0, top = 0) {

    

    let newLeft;
    let newTop;

    if (left > 0) {
      newLeft = left;
      newTop = top;
    }
    else {
      newLeft = this.newElementLeft(this.canvas);
      newTop = this.newElementTop(this.canvas);
    }

    console.log('font', value.fontSize)
    console.log('text', value.text)
    var obj = new this.LimitedTextbox(value.text, {
      left: newLeft,
      top: newTop,
      fontSize: value.fontSize,
      height: 50,
      width: 400,
      fill: '#333333',
      customHeight: 50,
      fontWeight: 'bold',
      editable: true,
      name: 'plaintext',
    });

    console.log('obj', obj)

    this.canvas.add(obj);
    this.canvas.renderAll();

  }
  hasPermission(resource: string, permission: string) {
    return this.ra.hasPermission(resource, [permission, this.Permissions.GLOBAL]);
  }

  onClickCancel() {

    this._routeLoc.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  deselectCanvasObject() {
    this.canvas.discardActiveObject().renderAll();
  }

  addGrid(event) {
    console.log('event', event)

    let gridTemplate: any = this.canvas.getObjects().find((x) => x.name == "grid_Layout")


    if (gridTemplate) {

      this.canvas.remove(gridTemplate)
    }
    this.catalogFnService.addGrid(this.canvas, event.col, event.row, this.width, this.height, this.canvas.backgroundColor)
  }

  placeProductTemplateInGrid(event) {

 
    var cellGroups: any
    var table_group: any
    const promises = [];

    this.canvas.getObjects().forEach(element => {

     

      if (element.name == 'grid_Layout') {

        table_group = element
        cellGroups = element._objects
       
        let updatedData;

        cellGroups.forEach(cell_element => {

          cell_element._objects.splice(1, 2)  // for remove place labels
          var cell_rect = cell_element._objects[0]

          const promise = this.catalogFnService.addGroupElementOnGridCell(this.canvas, event, cell_rect)
          promises.push(promise)
           promise.then((result: any) => {

            setTimeout(() => {
             
              const prodRect = result._objects[0]
              updatedData = this.setTemplatePositionInCell(cell_element,cell_rect,prodRect,result)
              
            }, 100);
          })
        })
       
      }
    })
    // Promise.all(promises).then(() => {

    //   table_group.setControlsVisibility({
    //     mt: false, // Top
    //     mb: false, // Bottom
    //     ml: false,  // Left
    //     mr: false,  // Right
    //     tr: true,  // Top-Right
    //     tl: true,  // Top-Left
    //     br: true,  // Bottom-Right 
    //     bl: true,  // Bottom-Left
    //   });

    // })
  }
  setTemplatePositionInCell(cell_element,cell_rect,prodRect,prodGroup)
  {
    return new Promise((resolve, reject) => {
      var object = this.catalogFnService.setProductOnGridCell(cell_rect, prodRect, prodGroup)
      cell_element._objects.push(object)
  
      this.updateCanvas1()
      this.updateCanvas2(this)
      resolve(true)
    })
  }
  updateCanvas1() {
    setTimeout(() => {
      var template = this.canvas.toJSON();
      this.canvas.loadFromJSON(template, this.canvas.renderAll.bind(this.canvas), function (o, object) {
      });
    }, 10);
  }
  onDragObject(event) {
    this.dragObject = event
    console.log('drag event', this.dragObject)
  }
}

