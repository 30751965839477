<div class="row">
    <div class="col-12 col-md-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Add Terms and Conditions</h4>
            </div>
            <!-- {{terms|json}} -->
            <div class="card-content">


                <div class="card-body">
                    <app-terms-form
                    [termsId]="termsId"
                    (success)="onDataSubmittedSuccess($event)"
                    (error)="onDataError($event)"
                    (cancel)="onCancel()"
                    ></app-terms-form>
                </div>
            </div>
        </div>
    </div>
</div>