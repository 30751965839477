<section id = "orderlist" class="add-user-group">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">
                Sales Order View
            </h4>
        </div>
        <div class="card-content">
            <div class="card-body">
                <div class="row">
                    <aside class="col-lg-9">
                        <div class="card">
                            <div class="table-responsive">
                                <table class="table table-borderless table-shopping-cart">
                                    <thead class="text-muted">
                                        <tr class="small text-uppercase">
                                            <th scope="col">Product</th>
                                            <th scope="col" width="120">Quantity</th>
                                            <th scope="col" width="120">Price</th>
                                            <th scope="col" class="text-right d-none d-md-block" width="200"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of cartItems ; let i = index">
                                            <td>
                                                <figure class="itemside align-items-center">
                                                    <div class="aside"><img  [src]="item.img" class="img-sm"></div>
                                                    <figcaption class="info"> <a href="#" class="title text-dark" data-abc="true">{{item.productName}}</a>
                                                        <p class="text-muted small">{{item.productDescription}}<br> Brand: MAXTRA</p>
                                                    </figcaption>
                                                </figure>
                                            </td>
                                            <td> 
                                                <div class="qty">                                                  
                                                   <button (click)="onChangeQuantity(item,1)">-</button>
                                                   <input type="text" [value]="item.quantity" readonly/>                                     
                                                   <button (click)="onChangeQuantity(item,0)">+</button>
                                                </div>
                                                
                                            </td>
                                            <td>
                                                <div class="price-wrap"> <span class="price">${{item.totalPrice}}</span> <small class="text-muted"> $9.20 each </small> </div>
                                            </td>
                                            <td class="text-right d-none d-md-block">
                                                <button type="button" class="btn-md btn-outline-secondary waves-effect" (click)="onCartItemDelete(i)">Remove</button> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </aside>
                    <aside class="col-lg-3">
                        <div class="card mb-3">
                            <div class="card-body">
                                <form>
                                    <div class="form-group"> <label>Add Promo Code</label>
                                        <div class="input-group"> <input type="text" class="form-control coupon" name="" placeholder="Promo code"> <span class="input-group-append"> <button class="btn btn-primary btn-apply coupon">Apply</button> </span> </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                    <div class="row">                            
                                            <div class="col">
                                                <dt> Price({{cartItems.length}} items)</dt>                                         
                                            </div>
                                            <div class="col">                          
                                                <dd class="text-right ml-3">${{subTotal}} </dd>                      
                                            </div>                              
                                    </div>
                                    <div class="row">                            
                                        <div class="col">
                                            <dt> Shipping</dt>                                         
                                        </div>
                                        <div class="col">                          
                                            <dd class="text-right text-danger ml-3">${{shipping}} </dd>                      
                                        </div>                              
                                    </div>
                                    <div class="row">                            
                                        <div class="col">
                                            <dt>Total Price</dt>                                         
                                        </div>
                                        <div class="col">                          
                                            <dd class="text-right text-dark ml-3"><strong>${{total}}</strong></dd>                      
                                        </div>                              
                                    </div>

                                <hr> <a href="#" class="btn btn-out btn-primary btn-square btn-main" data-abc="true"> Submit Sales Order </a>
                                 <a href="#" class="btn btn-out btn-success btn-square btn-main mt-2" data-abc="true">Continue Shopping</a>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
         </div> 
     </div>  
</section>