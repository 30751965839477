import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { Industry } from '../models/industry';

@Injectable({
  providedIn: 'root'
})
export class IndustryService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<Industry[]>(`${this.baseURL}/industry_types/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0) {
    let url = `${this.baseURL}/filter_industry_type/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<Industry[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<Industry>(`${this.baseURL}/industry_types/${id}/`);
  }

  create(model: Industry) {
    return this.http.post<Industry>(`${this.baseURL}/industry_types/`, model);
  }

  update(id: number, model: Industry) {
    return this.http.put<Industry>(`${this.baseURL}/industry_types/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<Industry>(`${this.baseURL}/industry_types/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/industry_types/${id}/`);
  }
}
