import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SVGInfo, Sections } from 'src/app/modules/inventory/models/catalogue.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-section-index-view',
  templateUrl: './side-section-index-view.component.html',
  styleUrls: ['./side-section-index-view.component.scss']
})
export class SideSectionViewComponent implements OnInit {

  @Input() sectionInfo : any
  @Input() _showPanel:boolean = true
  @Output() _pageIndex =  new EventEmitter<number>()
  @Output() _sectionIndex:number = 0
  @Input() showMode : number


  imgURL: string = environment.imgURL
 // _lastFlippedIndex:number = 0
  public lastFlippedPageIndex  : number = -1

  public collapseIndx : number

  selectedPageIndexInSection
  selectedSection

  svgContent: SafeHtml;
  //svgList : any =[{sectionName:'',svgString:[]}]

  public svgList: SVGInfo[] = [];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {



   // this.svgContent = this.sanitizer.bypassSecurityTrustHtml(svgString);

  }



  // onSectionClosed(): void {
  //   //this.sectionViewClosed.emit(true);
  //  // this._showSectionCanvas = false;
  // }


  onClickPages(pageindx,secindx,secPageNo)
  {
    const TotalPageCountInPrevSections= this.sectionInfo.slice(0,secindx).reduce((accumulator,currentValue)=>{

      return accumulator += currentValue.images.length
    },0)

   const indx = pageindx + TotalPageCountInPrevSections

  //  this._pageIndex.emit(indx)
  this._pageIndex.emit(secPageNo - 1)

   this.selectedPageIndexInSection = TotalPageCountInPrevSections == 0 ? indx : indx - TotalPageCountInPrevSections
   this.selectedSection = secindx

  }

}
