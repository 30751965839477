import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, concat, of, Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { additionalFieldsList } from '../../../models/additional-fields.models';
import { AdditionFieldsService } from '../../../services/addition-fields.service';

@Component({
  selector: 'additional-field-select',
  templateUrl: './additional-field-select.component.html',
  styleUrls: ['./additional-field-select.component.scss']
})
export class AdditionalFieldSelectComponent implements OnInit {

  public Permissions = PermissionConstants;

  public additionalFields:Observable<additionalFieldsList[]>;
  
  public _selected:number;


  public additionFieldInput=new Subject<string>();

  public isHeaderVisible:boolean=false;

  public currentCount:number=0;

  public totalCount:number=0;

  public addModalReference:any;

  public _value:number;

  get value():number{
    return this._value;
  }

  @Input() set value(val:number){
    // console.log(Date.now(), "time now",val);

    this._value=val;
    this._selected=val;
  };

  get selected():number{
    return this._selected;
  }

  set selected(val:number){
    this._selected=val;
  }


  public _afList:BehaviorSubject<additionalFieldsList[]>=new BehaviorSubject<additionalFieldsList[]>([]);

  @Input() readonly: boolean = false;

  @Input() set afList(list:additionalFieldsList[]){
    // this._afList=list;
    if(this._afList)
    this._afList.next(list);

    //  this._afList.toPromise().then(data=>{
    //   console.log(data, 'after promis');
    //  })

    // console.log(Date.now(), "-", list, 'selected->',this._selected);
    this.totalCount = list.length;
      
    this.isHeaderVisible = list.length > 0;
    this.currentCount = list.length;
  }

  @Input() placeholder: string = "Select additional field";

  @Input() addButton: boolean = false;

  @Output() valueChange = new EventEmitter<number>();

  @Output() change = new EventEmitter<additionalFieldsList>();

  @Output() clear = new EventEmitter();

  @Output() onAFListChange = new EventEmitter<additionalFieldsList[]>();


  constructor(
    private additionalFieldService:AdditionFieldsService, 
    private ra: ResourceAccessService,
    private cdr: ChangeDetectorRef,
    private modalService:NgbModal) { }

  ngOnInit(): void {
    // this.getAdditionalFields();
  }

  getAdditionalFields(){

    this.additionalFields=this.additionalFieldService.getAll().pipe(
      map(resp=>{ 

      
      this.totalCount = resp.data.length;
      
      this.isHeaderVisible = resp.data.length > 0;
      this.currentCount = resp.data.length;
        return resp.data
      }),
      catchError(() => of([])),
    );

  }

  onValueChanged(value){

    // ;

    this.valueChange.emit(value);

  }

  onSelectionClear(){

  }
  openModal(content){

    this.addModalReference=this.modalService.open(content);
    
  }

  trackByFn(additionalField: additionalFieldsList) {

    return additionalField?.additional_field?.id?additionalField.additional_field.id:'';
  }

  hasPermission(permission: string) {
    return this.ra.hasPermission(ResourceConstants.INV_CATEGORIES, [permission, this.Permissions.GLOBAL]);
  }

  onDataSuccess(value){

    // this.getAdditionalFields();\
    // console.log(this.afList.toString(),'af length');



    // this.afList.push(value.response);
    // this._afList.pipe(tap(afList=>afList.push(value.response)));
    
    this._afList.next([...this._afList.getValue(),value.response]);
  
    // console.log(JSON.stringify(this._afList), 'response')

    this._afList.subscribe(data=>{
      console.table(data);

      this.totalCount = data.length;
      
      this.isHeaderVisible = data.length > 0;
      this.currentCount = data.length;

      this.onAFListChange.emit(data);

      this.cdr.markForCheck();

    });


    

    this.selected=value.response.additional_field.id;

    console.log(this.afList, '+',value,'+selected:', this.selected);


    this.valueChange.emit(value.response);


    


    this.addModalReference.close();

  }

  onDataError(value){

  }

  onCancel(){

  }

}
