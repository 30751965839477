export enum CreatedSource {
    User = "user",
    Import = "import"
}

export enum ModuleConstants {
    ITEM = 1,
    KIT = 2,
    KIT_ASSEMBLE = 3,
    SUPPLIER = 4,
    MANUFACTURER = 5,
    CUSTOMERS = 6,
    SERVICE_TICKETS = 7,
    DISTRIBUTORS = 8,
    WARRANTY = 9,
    TERMS = 10,
    SERVICE_CONTRACTS = 11,
    ITEM_CATEGORIES = 12,
    SALES_ORDERS = 13,
    INVOICE = 14,
    CATALOG=15,
    CATALOG_VIEW=16,
    DISPATCH=17,

    RING2VOICE_DIDS = 20,
    RING2VOICE_FORWARD_BINS = 21,
    RING2VOICE_CALL_REPORTS = 22,
    RING2VOICE_DID_REPORTS = 23,
    RING2VOICE_SIP_ENDPOINTS = 24,
    RING2VOICE_BLOCK_LIST = 25,
    SERVICE_TICKET_SUBTASK = 26,
    RING2VOICE_TIME_FRAME = 27,

    CHART_OF_ACCOUNTS = 28,
}

export enum TwoFactorTypes {
  EMAIL = 1,
  GOOGLE_AUTHENTICATOR = 2
}

export enum AppEventType {
  SuborgSwitch = 'SUBORG_SWITCH'
}

export enum StatusValueEnum {
  READY = 'Ready',
  BUSY = 'Busy',
  OFFLINE = 'Offline',
  ON_CALL = 'On Call'
}

export enum NumberBlockTypeEnum {
  USER_ADDED = 'User_added',
  AUTO_ADDED = 'Auto_added',
  BOTH = 'Both',
  NONE = 'None'
}

export enum CallRingTypeEnum {
  NUMBER = "Number",
  SIP = "Sip",
  SMART_SIP_GROUP = "Smart_Sip_Group"
}

export enum CallRoutingOptionEnum {
  RING = 'Ring',
  INTELLIGENT_MENU = 'Intelligent Menu',
  ANNOUNCEMENT = 'Announcement',
  VOICEMAIL = 'Voicemail',
  TIME_BASED_ROUTING = 'Time Based Routing'
}

export enum AfterTimeoutOption {
  VOICEMAIL = 'Voicemail',
  RING = 'Ring',
  ANNOUNCEMENT = 'Announcement',
  QUEUE = 'Queue'
}

export enum ChartOfAccountsTypes {
  bank = 'BANK',
  AP   = 'Accounts payable (A/P)',
  AR   = 'Accounts receivable (A/R)',
  COS  = 'Cost of Goods Sold',
  CreditCard  = 'Credit Card',
  Equity  = 'Equity',
  Expenses = 'Expenses',
  FixedAssets = 'Fixed Assets',
  Income = 'Income',
  LongTermLiabilities = 'Long Term Liabilities',
  OtherAssets = 'Other Assets',
  OtherCurrentAssets = 'Other Current Assets',
  OtherCurrentLiabilities = 'Other Current Liabilities',
  OtherExpense = 'Other Expense',
  OtherIncome = 'Other Income',

}
export enum ItemTypes {
  INVENTORY = 'Inventory',
  NONINVENTORY = 'Non Inventory',
  SERVICE = 'Service'
}
