import { AppConstants } from './../constants/app.constants';
import { UserTypes } from 'src/app/core/enums/user-types';
import { AuthResponse, IResetPasswordRequest, UserPrivileges } from '../../modules/auth/models/auth-response';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { SystemRoles } from '../enums/user-types';
import { TwoFactorTypes } from '../enums/common.enum';
import { SubOrg } from 'src/app/modules/suborg/models/suborg.models';
import * as momentTz from 'moment-timezone';


@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {

    constructor(private http: HttpClient, private router: Router) {
        super();
    }

    get isLoggedIn(): boolean {
        return localStorage.getItem(AppConstants.AUTH_TOKEN) !== null;
    }

    authenticate(email: string, password: string) {
        return this.http.post<AuthResponse>(`${this.baseURL}/token/`, {
            email: email.toLowerCase(),
            password: password,
            time_zone:momentTz.tz.guess()
        });
    }

    getUserPrivileges(token: string) {
        return this.http.get<UserPrivileges>(`${this.baseURL}/user_privileges/`, {
            headers: new HttpHeaders({ "Authorization": "Token " + token })
        });
    }

    setSession(authResp: AuthResponse, previleges: UserPrivileges): void {


        localStorage.setItem(AppConstants.AUTH_TOKEN, authResp.token);
        localStorage.setItem(AppConstants.USER_ID, authResp.userid?.toString());
        localStorage.setItem(AppConstants.CUSTOMER_ID, authResp.custid?.toString());
        localStorage.setItem(AppConstants.USER_AUTH, JSON.stringify(authResp));

        localStorage.setItem(AppConstants.USER_PREVILEGES, JSON.stringify(previleges.resource_privileges));
        localStorage.setItem(AppConstants.USER_GRIDS, JSON.stringify(previleges.grid_views));

        if (authResp.root) {
            localStorage.setItem(AppConstants.USER_SUBORGS, '[]');
        }
        else if(authResp.tenant_customer_contact_user) // if customer contact
        {
            localStorage.setItem(AppConstants.CURRENT_SUBORG, authResp?.suborg_id?.toString());
        }
        else {
            const suborgs = previleges.suborgs || [];
            if (suborgs.length) {
                if(suborgs.length > 1){
                    const org = new SubOrg();
                    org.id = -1;
                    org.name = 'All';
                    suborgs.unshift(org);
                }
                localStorage.setItem(AppConstants.CURRENT_SUBORG, suborgs[0].id.toString());
            }
            localStorage.setItem(AppConstants.USER_SUBORGS, JSON.stringify(suborgs));
        }

        let userType: UserTypes;

        if (authResp.root) {
            userType = UserTypes.SuperUser;
        }
        else {
            userType = authResp.rolename === SystemRoles.Admin ? UserTypes.Admin :authResp.tenant_customer_contact_user ? UserTypes.Customer  : UserTypes.User;
        }
        localStorage.setItem(AppConstants.USER_TYPE, userType);
    }

    getUserType(): UserTypes {
        return localStorage.getItem(AppConstants.USER_TYPE) as UserTypes || UserTypes.User;
    }

    navigate(): void {

        const userType = this.getUserType();
        this.router.navigate(['/users/my-profile']);

        if (userType == UserTypes.SuperUser) {
            this.router.navigate(['/tenants']);
        }
        else if (userType === UserTypes.Admin) {
            this.router.navigate(['/users']);
        }
        else if(userType === UserTypes.Customer)
        {
            this.router.navigate(['/users/my-profile']);
        }

        else {
            this.router.navigate(['/dashboard/user']);
        }
    }

    resetPassword(request: IResetPasswordRequest) {
        return this.http.post(`${this.baseURL}/resetpassword/`, request);
    }

    resetUserPassword(request: IResetPasswordRequest) {
        return this.http.post(`${this.baseURL}/resetuserpassword/`, request);
    }

    logout(): void {
        localStorage.clear();
        this.router.navigate(['login']);
    }

    verifyOtp(request: { userid: number, otp: string, type: TwoFactorTypes, secret?: string }) {
        return this.http.post<{ valid: boolean, auth: AuthResponse }>(`${this.baseURL}/two_factor/otp/verify/`, request);
    }

    send2faOtp(request: { userid: number, type: TwoFactorTypes, secret?: string }) {
        return this.http.post(`${this.baseURL}/two_factor/otp/send/`, request);
    }

    sendEmailVerificationCode(userid: number, secret: string) {
        return this.http.get(`${this.baseURL}/users/${userid}/email_verification/${secret}/`);
    }

    verifyEmail(userid: number, code: string, secret: string) {
        return this.http.post<{ valid: boolean, auth: AuthResponse }>(`${this.baseURL}/users/${userid}/email_verification/${secret}/`, { otp: code })
    }

    sendUserActivationLink(userId: number) {
        return this.http.get(`${this.baseURL}/users/${userId}/activation/`);
    }

    verifyUserEmail(userid: number, token: string) {
        return this.http.post<{
            status: boolean,
            message: string
        }>(`${this.baseURL}/users/${userid}/verify/`, { token: token });
    }

    getUserStatus(userid: number) {
        return this.http.get<{
            verified: boolean,
            active: boolean
        }>(`${this.baseURL}/users/${userid}/status/`);
    }

    sendSesVerificationEmailByUser() {
        return this.http.get(`${this.baseURL}/users/ses_verify/`);
    }

    sendSesVerificationEmailByAdmin(userId: number) {
        return this.http.get(`${this.baseURL}/users/${userId}/ses_verify/`);
    }

    checkSesVerified() {
        return this.http.get(`${this.baseURL}/users/check_ses_verified/`);
    }

    sendSesVerificationByEmail(email: string) {
        return this.http.get(`${this.baseURL}/users/${email}/ses_verify/`);
    }
}
