import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { CustomFieldsService } from './../../../../../core/services/custom-fields.service';
import { ItemCategory } from './../../../models/items';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryService } from '../../../services/category.service';
import { CustomField } from 'src/app/core/models/custom-field';
import { AdditionFieldsService } from '../../../services/addition-fields.service';
import { additionalFieldsList, catCustomField, catCustomFieldData } from '../../../models/additional-fields.models';

@Component({
    selector: 'app-item-category-form',
    templateUrl: './item-category-form.component.html',
    styleUrls: ['./item-category-form.component.scss']
})
export class ItemCategoryFormComponent implements OnInit, OnDestroy {

    public model: ItemCategory;

    @Input() categoryId: number = 0;

    @Output() onSuccess = new EventEmitter<{ response: ItemCategory, message: string }>();

    @Output() onError = new EventEmitter<any>();

    @Output() onCancel = new EventEmitter<any>();

    public additionalFields: CustomField[];

    private subs: SubSink = new SubSink();

    public additionalFieldz: additionalFieldsList[];

    public catCustomField:catCustomField=new catCustomField();

    constructor(
        private categoryService: CategoryService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private additionalFieldService: AdditionFieldsService,
        private customFields: CustomFieldsService) {
            
         }



    ngOnInit() {

        if (this.categoryId) {
            this.subs.sink = this.categoryService.getById(this.categoryId).subscribe(category => {
                this.model = category;
                this.additionalFields = this.customFields.obj2CustomFields(category.additional_fields);

                console.table(this.additionalFields);


                if(this.model.additional_field_migrated==false&&this.additionalFields&&this.additionalFields.length>0){

                     let newVal;


                    this.additionalFields.forEach((val,index)=>{
                        newVal=new catCustomFieldData();
      
                        newVal.custom_additional_field=val.name;
                        newVal.custom_additional_field_value=val.value;
                        newVal.type=2;
      
                        this.catCustomField.additional_data.push(newVal);
      
                        
      
      
                      });

                      this.model.additional_field_migrated=true;
                }

                this.cdr.markForCheck();
            });
        }
        else {
            this.model = this.categoryService.createModel(ItemCategory);
        }
        this.getAdditionalFields();
        
    }



    validate() {
        if (!this.model.create_partnumber)
            return true;
        return this.model.start_range >= 0
            && (this.model.end_range == -1 || this.model.end_range > this.model.start_range)
            && this.model.digits > 0;
    }

    onSubmit(): void {

        if (!this.validate()) return;
        
        let error=this.validateAf();

        if(error!=""){
            this.toastr.error(error);
        }
        else{

            this.model.additional_fields = this.customFields.customFields2Obj(this.additionalFields);

            let api: Observable<ItemCategory>;
    
            if (this.categoryId > 0) {
                api = this.categoryService.update(this.categoryId, this.model);
            }
            else {
                api = this.categoryService.create(this.model);
            }
    
            this.subs.sink = api.subscribe(
                category => {
    
    
                        this.updateAdditionalField(category.id);
                    
                    // else {
                    //     this.saveAdditionalField();
                    // }
    
                    let message = this.categoryId > 0 ? 'Category info updated' : 'New category created';
                    this.onSuccess?.emit({ response: category, message: message });
                },
                error => {
                    if (error.error && error.error.status_code === "149") {
                        this.toastr.info('Combination already exists');
                    }
                    else {
                        this.onError?.emit(error);
                    }
                }
            );

        }


    }



    onCancelClicked(): void {
        this.onCancel?.emit();
    }

    onCategoryChanged(selected: ItemCategory): void {
        this.model.category_prefix = selected.category_prefix;
    }

    addCustomField(type=1) {
        // this.additionalFields = this.additionalFields ?? [];
        // this.additionalFields.push(new CustomField());

        // 

        let newVal=new catCustomFieldData();
        newVal.type=type;
        if(this.catCustomField.additional_data.length>0){
            this.catCustomField.additional_data.push(newVal);
        }
        else{
            this.catCustomField.additional_data=[newVal];
        }


    }

    deleteCustomField(index: number) {
        // this.additionalFields = this.additionalFields.filter(x => x.id !== id);
        this.catCustomField.additional_data[index].action=3;
    }

    onParentSelectClear() {
        this.model.category_prefix = '';
    }

    getAdditionalFields(){

        this.additionalFieldService.getAll().subscribe(response=>{

            this.additionalFieldz=response.data;

            this.getCatAdditionalField();

        });

    }

    // selectableAdditionalFields(){
    //     let addedFieldIds=[];
    //     addedFieldIds=this.catCustomField.additional_data.map((e)=>e.additional_field);
    //     // console.log(addedFieldIds, 'ids');
    //      return  this.additionalFieldz.filter((val)=>!addedFieldIds.includes(val.additional_field.id))

        
    // }

    

    getAdditionFieldValues(fieldId){

        let values=[];

        if(this.additionalFieldz&&this.additionalFieldz.length){
           values= this.additionalFieldz.find((val)=>val.additional_field.id==fieldId)?.values;
        }
        return values;
    }

    clearAdditionalFieldValue(fieldId){

        if(this.catCustomField.additional_data&&this.catCustomField.additional_data.length){
            this.catCustomField.additional_data.find((val)=>val.additional_field==fieldId).value=null;
         }

    }

    getCatAdditionalField(){

        console.log('You are here');

        // this.catCustomField.additional_data=[];

        this.additionalFieldService.getCatAdditionalField(this.categoryId).subscribe(response=>{

            if(response&&response.data&&response.data.data&&response.data.data.length){
                // console.log(response.data, 'get response');
                response.data.data.forEach(element => {

                    let newVal:catCustomFieldData=new catCustomFieldData();
                    newVal.additional_field=element.additional_field_id;
                    newVal.id=element.id?element.id:null;
                    newVal.action=2;
                    newVal.category_additional_field=element.category_id?element.category_id:null;
                    newVal.type=element.type;
                    newVal.custom_additional_field=element.custom_additional_field;
                    newVal.custom_additional_field_value=element.custom_additional_field_value;
                    newVal.value=element.value_id_id;
                    newVal.category_value_list=element.category_value_list;

                    this.catCustomField.additional_data.push(newVal);
                    // this.catCustomField.additional_data.push({'additional_field':element.additional_field_id,'id':element.id,'value':element.value_id_id, 'action':2, 'category_additional_field':element.category_additional_field_id, 'custom_additional_field':'', 'custom_additional_field_value':''});
                    
                });
            }
            else{
                this.addCustomField();
            }
            this.cdr.markForCheck();
        })
    }

    saveAdditionalField(){

        if(this.catCustomField.additional_data.length>0){

            if(this.catCustomField.additional_data.length==1&&!this.catCustomField.additional_data[0].additional_field){
                return;
            }
            this.catCustomField.category=this.categoryId;

            this.additionalFieldService.createCatAdditionalField(this.catCustomField).subscribe(response=>{

                console.log(response, 'category additional field');

            });

        }

    }

    updateAdditionalField(catId=this.categoryId){

        if(this.catCustomField.additional_data.length>0){

            if(this.catCustomField.additional_data.length==1&&!this.catCustomField.additional_data[0].additional_field){
                return;
            }

            this.catCustomField.additional_data.forEach(val=>{delete val.category_value_list});

            this.catCustomField.category=catId;

            this.additionalFieldService.updateCatAdditionalField(catId,this.catCustomField).subscribe(response=>{

                console.log(response, 'category additional field');

            });

        }

    }

    validateAf(){

        let error="";

        let goAhead=true;

        if(this.catCustomField.additional_data.length>0){

            this.catCustomField.additional_data.forEach(val=>{

                if(goAhead&&val.action!=3){

                    if(val.type==1&&!val.additional_field){
                        error="Please select additional field in all rows";
                        goAhead=false;
                    }
                    else if(val.type==2&&(!val.custom_additional_field||val.custom_additional_field=="")){
                        error="Please enter additional field names";
                        goAhead=false;
                    }

                }

            })


        }

        return error;

    }

    additionalFieldSelected(value:any, index){

        // ;
        // console.log(this.additionalFieldz)

        if(value){
            this.additionalFieldz.push(value);
        }


        if(this.catCustomField.additional_data[index]){

            this.catCustomField.additional_data[index].additional_field=value&&value.additional_field?value.additional_field.id:null;

            // theVal.additional_field=

            this.catCustomField.additional_data[index].value=null;
            if(this.catCustomField.additional_data[index].category_value_list){
                this.catCustomField.additional_data[index].category_value_list=value&&value.values?value.values:null;
            }
            else{
            this.catCustomField.additional_data[index]["category_value_list"]=value.values;

            }

            // console.log(this.catCustomField.additional_data,'h');
        }
        else{
            let newVal=new catCustomFieldData();

            newVal.additional_field=value.additional_field.id;
            newVal.type=1;
            newVal.category_value_list=value.values;
            newVal.action=1;
            this.catCustomField.additional_data.push(newVal);
        }

        // this.getAdditionalFields();

        this.cdr.markForCheck();

    }

    onAFListChange(afList:additionalFieldsList[]){
        if(afList&&afList.length>0){
             this.additionalFieldz=afList;
             this.cdr.markForCheck();

        }
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }
}
