<section>
    <div class="card col-12" (click)="toggleBanner()" [hidden]="!hideBanner" style="background-color: #380e7f;cursor: pointer;">
        <div class="row p-2">
            <div class="col-11">
                <span class="banner-hidden-heading">View More About Payment Details</span>
            </div>
            <div class="col-1 d-flex align-items-center">
                <i class="fa fa-chevron-down text-white" [ngClass]="hideBanner?'':'fa-rotate-180'" aria-hidden="true"></i>
            </div>
        </div>
    </div>
    <div class="card p-0 col-12 mb-0" [ngClass]="hideBanner?'hideBanner':'showBanner'">
        <div class="card-body banner">
            <div class="col-6 centered-div">
                <div>
                    <p>How to Generate Cardknox Keys </p>
                    
                    <p class="font-small-2">1. Sign in to the Cardknox Merchant Portal.<br/>
                    2. Select "Account Settings" from the navigation bar.<br/>
                    3. Select "Keys" from the sub-menu.<br/>
                    4. Click "Create a Key" in the top-right corner.<br/>
                    5. Choose the desired key type (API or iFields), description (software, etc.), and permissions.<br/>
                    6. Click "Create and View" and copy your key.</p>
                   
                </div>
            </div>

            <div class="col-1 centered-div"></div>
            
            <div class="col-5 centered-div">
                <iframe width="300" height="215" class="p-4" src="https://www.youtube.com/embed/T_4nyno2r0o?si=LJW5lgZ-Rsz_jA9o" title="Simplyfuse Intro Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div class="togglebanner" [hidden]="hideBanner" (click)="toggleBanner()"><i class="fa fa-chevron-down text-white" [ngClass]="hideBanner?'':'fa-rotate-180'" aria-hidden="true"></i></div>
    
    <!-- <button (click)="editKey()"  class="btn btn-sm bg-light-secondary float-right"><i  class="ft-edit mr-1"></i> Edit Cardknox Key </button> -->
    
    <div class="cards_section">

        <div class="mycards mb-2" >

            <div class="d-flex justify-content-center">
            <div class="card_icon" >
                <img   width="60" height="60" src="/assets/img/svg/cc/cardknox.png" />
            </div>
            </div>

            <div class="text-muted m-1 mt-2 mb-2" style="text-align: center;">
             <h3>Cardknox</h3>
            <p class="text-muted m-1 mb-2 font-small-3">465 Oak Glen Rd</p>
            <p class="text-muted m-1 mb-2 font-small-3">Howell Township</p>
            <p class="text-muted m-1 mb-2 font-small-3">NJ 07731, United States</p>
            <p class="text-muted m-1 mb-2 font-small-3">Email : jlieberman@cardknox.com</p>
            <p class="text-muted m-1 mb-2 font-small-3">Phone : +1 844-227-3566</p>
            </div>

            <div class="actions">
                <div>
                    <a title="Edit Cardknox Key" (click)="editKey()"><i class="ft-edit-2" style="color: #975AFF;"></i></a> 
                </div>
            </div>

        </div>

       

    </div>

    </section>


   

    <ng-template #EditModal let-c="close" let-d="dismiss">
        <div class="modal-header border-0">
            <h5 class="modal-title">Edit Cardknox Key</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body pt-0">
           <div class="container">
            <div class="row">

                <div *ngIf="!isVerified" class="col">
                    <div class="form-group">
                        <label class="val-required">Verification code</label>
                        <input  type="text" [(ngModel)]="verificationCode" name="otp" placeholder="Enter the verification code" class="form-control" required>
                        
                    </div>

                    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                        <button  (click)="verifyKey()" class="btn btn-success mb-2 mb-sm-0 mr-sm-2">
                          Verify
                      </button>
                        <button type="button" (click)="d('Cross click')" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>


                <div *ngIf="isVerified" class="col">
                    <div class="form-group">
                        <label class="val-required">Cardknox Key</label>
                        <input  type="text" [(ngModel)]="cardKnoxKey" name="card_number" class="form-control" required>
                        
                    </div>

                    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                        <button  (click)="updateKey()" class="btn gradient-pomegranate mb-2 mb-sm-0 mr-sm-2">
                          Save
                      </button>
                        <button type="button" (click)="d('Cross click')" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </ng-template>
