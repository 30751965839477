import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { element } from 'protractor';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { SubSink } from 'subsink';

@Directive({
  selector: '[parellaxTabSlider]'
})
export class ParellaxTabSliderDirective {

  public startX:number;

  public mouseDown:boolean=false;

  public _width:number;

  public startWidth:number;

  public startLeft:number;

  public _maxWidth:number;

  public _page:string;

  @Input() target:any;

  @Input() set page(val:string){
    this._page=val;
  }

  @Input() minWidth:number=400;

  public initWidth:number;

  @Input() set width(value:number){

    if(value){


        setTimeout(() => {
          this.getWidth();
          if(!this.initWidth){
            this.initWidth=value;
          }
        this.renderer.setStyle(this.target, 'width',  this.initWidth+ 'px');

        }, 100);



    }
    else{
      this.initWidth=200;
    }

  };

  @Output() resized=new EventEmitter<any>();


  @ViewChild('sliderHandle') sliderHandle:ElementRef;

  public subs:SubSink=new SubSink();



  private listenerFn:() => void;

  private listenerFn1:()=>void;


  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;


  public sliderHandleHtml=`<div class="panel-drag-handle"
                            #sliderHandle
                            >
                            <img src="/assets/img/dispatch/drag-handle.png" />
                            </div>`;


  public tabWidth:{[key:string]:number}={}

  constructor(private elementRef:ElementRef,
    private cdr:ChangeDetectorRef,
    private renderer:Renderer2,
    private ra:ResourceAccessService){

      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', this.sliderHandleHtml);

      this.renderer.addClass(this.elementRef.nativeElement, 'parellax-detail-dragable');

      if(this.target){
        this.renderer.addClass(this.target, 'slide-expand-target');

      }

      let sibling=document.getElementsByClassName('parellax-shrinked');

      if(sibling&&sibling[0]){
       this.renderer.addClass(sibling[0], 'parellax-shrinked-drag');

      }

      if(this.elementRef.nativeElement){
        let parent=document.getElementsByClassName('list-parellax')[0];

          if(parent){
            this._maxWidth=parent.getBoundingClientRect().width;

          }

      }
      this.getWidth()

  }
  ngOnInit(){

  }

  ngAfterViewInit(){

    setTimeout(() => {
    this.resized.emit({width:this.target.getBoundingClientRect().width, complete:false});

    }, 10);

    if(!this._maxWidth){

      if(this.elementRef.nativeElement){
        let parent=document.getElementsByClassName('list-parellax')[0];

          if(parent){
            this._maxWidth=parent.getBoundingClientRect().width;

          }

      }
    }

    let handle=this.elementRef.nativeElement.children[0];

    if(handle){
      this.subs.sink=handle.addEventListener("mousedown",this.onMouseDown.bind(this));
    }




  }


  ngAfterViewChecked(){

      let parentHeight=this.elementRef.nativeElement.getBoundingClientRect().height;
      this.renderer.setStyle(this.target, 'height',  parentHeight+ 'px');



  }

  getWidth(){

    if(this._page&&this._page!=""){
     this.tabWidth=JSON.parse(localStorage.getItem(AppConstants.TAB_WIDTH));
     if(this.tabWidth){

        if(this.tabWidth[this._page]){
          this.initWidth=this.tabWidth[this._page];
        }
     }
    }

  }

  setWidth(width:number){
    if(this._page&&this._page!=""){
      if(this.tabWidth){
        if(this.tabWidth[this._page]){
          this.tabWidth[this._page]=width;

        }
        else{
          this.tabWidth[this._page]=width;
        }

      }
      else{
        let data:{}={}
        data[this._page]=width;
        this.tabWidth=data;
      }
      localStorage.setItem(AppConstants.TAB_WIDTH, JSON.stringify(this.tabWidth))

    }
  }


  // @HostListener('mousedown', ['$event'])

  onMouseDown(event: MouseEvent) {

        this.mouseDown=true;

        event.preventDefault();

        this.startX = event.clientX;

        this.startWidth=this.target.getBoundingClientRect().width;

        this.startLeft=this.elementRef.nativeElement.getBoundingClientRect().left;

        this.renderer.addClass(document.body, 'slide-expand-selection');

        this.listenerFn=this.renderer.listen('window', 'mousemove', this.onMouseMove.bind(this));


        this.listenerFn1=this.renderer.listen('window', 'mouseup', this.onMouseUp.bind(this));




  }

  onMouseMove(event: MouseEvent) {


      if(this.mouseDown&&this.startX&&this.startX>0&&event.clientX&&event.clientX>0){

          if(this.startX&&this.startX!=null&&event.clientX!=null){
              const diff=(event.clientX - this.startX);
            this._width=diff;

            let newWidth=this.startWidth-diff;

            if(newWidth>=this.minWidth&&newWidth<=this._maxWidth){
              this.renderer.setStyle(this.target, 'width',  newWidth+ 'px');
               this.resized.emit({width:this.target.getBoundingClientRect().width, complete:false});
            }
            else if(newWidth>this._maxWidth){
              this.renderer.setStyle(this.target, 'width',  this._maxWidth-15+ 'px');
               this.resized.emit({width:this.target.getBoundingClientRect().width, complete:false});

            }
            else if(newWidth<this.minWidth){
              this.renderer.setStyle(this.target, 'width',  this.minWidth+ 'px');
              this.resized.emit({width:this.target.getBoundingClientRect().width, complete:false});

            }
            else{
              // console.log('Out of range');
              // console.log('less than min Width of '+this.minWidth, 'Current Width:'+newWidth);
            }

          }
          else{
              this._width=0;
          }




      }
      else{
          // console.log('Moving over an event');
      }

    }

    onMouseUp(event:MouseEvent) {


          if(this.mouseDown&&this.startX&&this.initWidth){

              this.renderer.removeClass(document.body, 'slide-expand-selection');


                  //   console.log('mouse up');

                    this.listenerFn();
                    this.listenerFn1();




              this.resized.emit({width:this.target.getBoundingClientRect().width, complete:true});

              this.setWidth(this.target.getBoundingClientRect().width);

              // this.cdr.markForCheck();



            }
            else{
              console.log('Not a valid drag');
            }


            this.mouseDown=false;
            this.startX=null;
            // this.initWidth=200;



     }




      hasPermission(resource: string, permission: string) {
        return this.ra.hasPermission(resource, [permission], ResourceConstants.DISPATCH_MODULE);
      }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

}
