import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { GridView, GridViewInfo } from '../models/grid-profiling.models';

@Injectable({
  providedIn: 'root'
})
export class GridViewsService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<{ gridviews: GridViewInfo[] }>(`${this.baseURL}/grid/views/`);
  }

  getById(id: number) {
    return this.http.get<{ gridview: GridViewInfo }>(`${this.baseURL}/grid/views/${id}`);
  }

  create(view: GridView) {
    return this.http.post<GridView>(`${this.baseURL}/grid/views/`, view);
  }

  update(id: number, view: GridView) {
    return this.http.put<{ status: boolean }>(`${this.baseURL}/grid/views/${id}`, view);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/grid/views/${id}`);
  }

  getViewsByRole(roleid: number) {
    return this.http.get<{ gridviews: GridViewInfo[] }>(`${this.baseURL}/grid/role/${roleid}/views/`);
  }

  assignView2Role(roleId: number, viewId: number) {
    return this.http.post(`${this.baseURL}/grid/role/views/`, {
      roleid: roleId,
      gridviewid: viewId,
      is_active: true
    });
  }

  unAssignView2Role(roleId: number, viewId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        roleid: roleId,
        gridviewid: viewId,
      },
    };
    return this.http.delete(`${this.baseURL}/grid/role/views/`, options);
  }
}
