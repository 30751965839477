import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { Currency } from '../models/currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<Currency[]>(`${this.baseURL}/currency/`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0) {
    let url = `${this.baseURL}/filter_currency/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<Currency[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<Currency>(`${this.baseURL}/currency/${id}/`);
  }

  create(model: Currency) {
    return this.http.post<Currency>(`${this.baseURL}/currency/`, model);
  }

  update(id: number, model: Currency) {
    return this.http.put<Currency>(`${this.baseURL}/currency/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<Currency>(`${this.baseURL}/currency/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/currency/${id}/`);
  }
}
