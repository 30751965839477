import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-customer-info-tab',
  templateUrl: './customer-info-tab.component.html',
  styleUrls: ['./customer-info-tab.component.scss']
})
export class CustomerInfoTabComponent implements OnInit {

  

  @ViewChild('tabHeader') tabHeader:ElementRef;
  
  @Input() set customerId(value:number){
    this._customerId=value;
  }

  @Output() close=new EventEmitter<any>();

  public tabHeaderHeight:number=50;


  public _customerId:number;

  public activeTab:number=1;

  public subs=new SubSink();

  public selected={type:1};



  constructor(private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked(){

    this.tabResizeCheck();

  }

  closePanel(){
    this.close.emit();
  }

  tabResizeCheck(){
    this.tabHeaderHeight=this.tabHeader.nativeElement.getBoundingClientRect().height;
    this.cdr.markForCheck();

  }

  ngOnDestroy(){

    this.subs.unsubscribe();

  }

}
