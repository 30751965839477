<section id="item-view" *ngIf="catalog">
    <div class="d-flex mt-2" [ngClass]="catalog.name.length <= 30 ? 'flex-row': 'flex-column'">
        <div class="item-title m-0">{{catalog.name}}</div>
        <div class="d-flex justify-content-end align-items-start">


            <div ngbDropdown placement="bottom-right" disableIfAllsuborg [hide]="true">
                <button class="btn btn-sm" ngbDropdownToggle>
                    More
                </button>
                <div ngbDropdownMenu>
                    <a class="w-100" ngbDropdownItem *ngIf="hasPermission(Resource.INV_ITEMS,Permissions.MODIFY)"
                        [routerLink]="['/inventory/catalog',catalog.id,'edit']" ngbTooltip="Edit">
                        <i class="ft-edit mr-1"></i> Edit
                    </a>
                    <!-- <a ngbDropdownItem [routerLink]="['/inventory/items',catalog.id,'clone']"
                        *ngIf="hasPermission(Resource.INV_ITEMS,Permissions.CREATE)" class="w-100">
                        <i class="ft-copy mr-1"></i> Clone
                    </a> -->
                    <button ngbDropdownItem *ngIf="hasPermission(Resource.INV_ITEMS,Permissions.DELETE)"
                        class="bg-light-danger w-100" (click)="deleteItem()">
                        <i class="ft-trash-2 mr-1"></i> Delete
                    </button>
                </div>
            </div>
            <button class="btn btn-sm ml-1 font-medium-3 pt-0 pb-0" (click)="closeItemView()">
                <i class="ft-x"></i>
            </button>
        </div>
    </div>
    <div class="col-12 col-md-12 p-0">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">Overview</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div class="group">
                        <div class="group-header d-flex flex-row justify-content-between">
                            Overview

                            <button  *ngIf = "catalog.file_name?.length > 0" class="btn btn-sm btn-primary ml-auto mr-2" (click) = "downloadPDF()"> <i
                                class="ft-download"></i> Download</button>
 
                            <div class="status-toggle-btn">
                                <label class="title">Status :</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="catalog.is_active" name="active"
                                        class="custom-control-input" (change)="changeStatus()" id="status"
                                        [disabled]="catalog.is_active ? !hasPermission(Resource.INV_ITEMS,Permissions.INACTIVE):!hasPermission(Resource.INV_ITEMS,Permissions.ACTIVE)">
                                    <label class="custom-control-label" for="status">
                                        <span></span>
                                    </label>
                                </div>

                                <label class="title">Show Price :</label>
                                <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                    <input type="checkbox" [(ngModel)]="catalog.show_price" name="active"
                                        class="custom-control-input" (change)="changeShowPrice()" id="pricestatus"
                                        [disabled]="catalog.show_price ? !hasPermission(Resource.INV_ITEMS,Permissions.INACTIVE):!hasPermission(Resource.INV_ITEMS,Permissions.ACTIVE)">
                                    <label class="custom-control-label" for="pricestatus">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row">
                            <div class="col-12 p-0" [ngClass]="{'col-md-7':catalog.image}">

                                <div class="ov-row" *ngIf="catalog.image">
                                    <!-- <label class="col-md-3 p-0 item-label">Cover page</label> -->
                                    <label class="item-label-value">

                                            <img *ngIf="catalog.image" width="200" class="catalog-image" height="auto" [src]="fileUrl + catalog.image" />
                                            <img *ngIf="!catalog.image" width="200" class="" height="auto"
                                                src="/assets/img/svg/product-avatar.svg" />
                                    </label>
                                </div>
                                <div class="ov-row" *ngIf="catalog.no_of_pages">
                                    <label class="col-md-3 p-0 item-label">No of Pages</label>
                                    <label class="item-label-value">{{catalog.no_of_pages}}</label>
                                </div>
                                <div class="ov-row" *ngIf="catalog.no_of_products">
                                    <label class="col-md-3 p-0 item-label">No of products</label>
                                    <label class="item-label-value">{{catalog.no_of_products}}</label>
                                </div>
                                <div class="ov-row" *ngIf="catalog.created_on">
                                    <label class="col-md-3 p-0 item-label">Created On</label>
                                    <label class="item-label-value">{{catalog.created_on| date:'MM/dd/YYYY'}}</label>
                                </div>

                                <div class="ov-row" *ngIf="catalog.created_by">
                                    <label class="col-md-3 p-0 item-label">Created By</label>
                                    <label class="item-label-value">{{catalog.created_by}}</label>
                                </div>

                            </div>

                        </div>
                    </div>



                </ng-template>
            </li>
            <!-- <li ngbNavItem>
                <a ngbNavLink class="d-flex align-items-center">
                    <span class="d-none d-sm-block">History</span>
                </a>
                <ng-template ngbNavContent class="tab-content-pd">
                    <div class="history-container" *ngIf="catalog">
                        <history-view [module]="'ITEM'" [uniqueId]="catalog.id"></history-view>
                    </div>
                </ng-template>
            </li> -->
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>

</section>

<!-- {{toggleLabelModal(labelModal)}} -->
