import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'src/app/core/models/grid-filter.models';
import { BaseService } from 'src/app/core/services/base.service';
import { PaymentTerm } from '../models/payment-terms';

@Injectable({
  providedIn: 'root'
})
export class PaymentTermsService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll(suborg_id:number = this.suborgId) {
    return this.http.get<PaymentTerm[]>(`${this.baseURL}/payment_terms/?suborg=${suborg_id}`);
  }

  getFiltered(request: DataFilterRequest, offset: number = 0) {
    let url = `${this.baseURL}/filter_payment_terms/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<PaymentTerm[]>>(url, request);
  }

  getById(id: number) {
    return this.http.get<PaymentTerm>(`${this.baseURL}/payment_terms/${id}/`);
  }

  create(model: PaymentTerm) {
    return this.http.post<PaymentTerm>(`${this.baseURL}/payment_terms/`, model);
  }

  update(id: number, model: PaymentTerm) {
    return this.http.put<PaymentTerm>(`${this.baseURL}/payment_terms/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<PaymentTerm>(`${this.baseURL}/payment_terms/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/payment_terms/${id}/`);
  }
}
