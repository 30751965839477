<audio id="audio" #audio></audio>
<div class="float-container callcenter d-flex align-items-center" *ngIf="callcenterSharedService.sip_details&&callcenterSharedService.sip_details.sip_uri
    &&callcenterSharedService.sip_details.tenant_subscription&&callcenterSharedService.sip_details.user_subscription">


    <div class="agent-section ml-2 d-flex">
        <div class="mx-2">
            <div class="button-container" id="myButton" (click)="openModal(callcentertModal)"   
                [ngClass]="(sipInboundFnService.totalCallCount | async) >0 ? 'blink' : ''">
                <div class="button-icon-container">
                    <span class="material-icons">dialpad</span>
                </div>

                <div class="button-text-container"
                    [ngClass]="(sipInboundFnService.totalCallCount | async) >0 ? 'expand blink' : 'shrink'">
                    <span>Incoming Call</span>
                    <img class="ml-2" src="assets/img/caller assets/incoming call.svg" height="22" />
                </div>
            </div>
        </div>

        <span class="dropdown align-items-center">
            <img class="avatar" src="assets/img/caller assets/agent.svg" alt="avatar" height="24" width="24" [ngClass]="{'agentConnecting':callcenterSharedService.processingStatusChange$.getValue(), 'agentOnline': (callcenterSharedService.agentStatus$|async)=='Ready', 'agentOffline':(callcenterSharedService.agentStatus$|async)=='Offline','agentBusy': (callcenterSharedService.agentStatus$|async)=='Busy'}" (click) ="openMediaPopup($event)"/>
            <div class="select" *ngIf="showMediaPopup" (click)="$event.stopPropagation()">
                <label for="audioSource">Select Microphone: </label>&nbsp;
                <ng-select
                            [items]="callcenterSharedService.availableAudioInputDevices"
                            bindLabel="label" 
                            bindValue="deviceId" 
                            [(ngModel)]="callcenterSharedService.selectedAudioInput"
                            (ngModelChange)="changeUserMedia()"
                            >
                </ng-select>
            </div>
        </span>
        <!-- <span class="avatar-name mr-2 ml-2"> {{agentStatus}}-{{connectingSIP}}</span> -->
        <span class="avatar-name mr-2 ml-2">Agent: {{callcenterSharedService.sip_details.agent_name}}</span>
        <!-- <span class="avatar-name mr-2 ml-2"> <button (click)="connect2()">Test</button></span> -->
        <div class="custom-select mr-2" (click)="toggleList()" [ngStyle]="{'background-color': (callcenterSharedService.processingStatusChange$|async)?'#adadad':(callcenterSharedService.agentStatus$|async)=='Ready'? '#419901':(callcenterSharedService.agentStatus$|async)=='Busy'?'#f86b0c':(callcenterSharedService.agentStatus$|async)=='Offline'?'#ec2020':'#adadad'}">
            <div class="select-selected" *ngIf="!(callcenterSharedService.processingStatusChange$|async)">{{ (callcenterSharedService.agentStatus$|async)=='Ready'?'Ready: Available for calls':(callcenterSharedService.agentStatus$|async)=='Busy'?'Busy: Will not get calls from smart routing':(callcenterSharedService.agentStatus$|async)=='On Call'?'On Call':(callcenterSharedService.agentStatus$|async)=='Offline'?'Offline: Will not receive calls':'Agent status changing ....'}}</div>
            <div class="select-selected1" *ngIf="callcenterSharedService.processingStatusChange$|async">{{callcenterSharedService.processingStatusChangeMsg}}</div>
            <div class="select-items" *ngIf="showList&&!(callcenterSharedService.processingStatusChange$|async)">
                <div class="select-item status-ready" [hidden]="(callcenterSharedService.agentStatus$|async)=='Ready'||(callcenterSharedService.agentStatus$|async)=='On Call'" (click)="agentStatusService.checkStatus('Ready', '', true)" >Ready: Available for calls</div>
                <div class="select-item status-busy" [hidden]="(callcenterSharedService.agentStatus$|async)=='Offline'||(callcenterSharedService.agentStatus$|async)=='Busy'||(callcenterSharedService.agentStatus$|async)=='On Call'" (click)="agentStatusService.checkStatus('Busy','', true)" >Busy: Will not get calls from smart routing</div>
                <div class="select-item status-offline" [hidden]="(callcenterSharedService.agentStatus$|async)=='Offline'||(callcenterSharedService.agentStatus$|async)=='On Call'" (click)="agentStatusService.checkStatus('Offline')" >Offline: Will not receive calls</div>
            </div>
        </div>
    </div>
</div>



    <ng-template #callcentertModal let-c="close" let-d="dismiss" class="modalTemplate" hidden="false">

        <section #section id="calcenter-panel" [style.height.px]="modalSpec.height">

            <div class="modal-body p-0 model-report-container" [style.height.px]="modalSpec.height" style="overflow: hidden;">

                <ring2voice-call-center #callcenter [activeTabId]="callcntr_activeTabId" 
                [selectedAudioInput] = "callcenterSharedService.selectedAudioInput"
                [selectedVideoSource] = "callcenterSharedService.selectedVideoSource"
                [dynamicHeight]="modalSpec.height">
                </ring2voice-call-center>
            </div>

        </section>
        <div class="drag-bar" callCenterDragExpand [minHeight]="500" [target]="section"
            (resized)="modalResized($event)">
            <img src="../../../../../assets/img/gallery/drag_hori.png" />
        </div>

        <button type="button" class="close ml-1 text-right" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
    </ng-template>
