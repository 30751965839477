<section id="role2-perm" *ngIf="role | async as role">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Add Privilege</h4>
                </div>
                <div class="card-content">
                    <div class="card-body add-prev-container d-flex">
                        <div class="form-group col-12 col-md-4 pl-0">
                            <label>Role</label>
                            <input type="text" class="form-control" [value]="role.name" readonly/>
                        </div>
                        <div class="form-group col-12 col-md-4">
                            <label>Resource</label>
                            <ng-select #resourceSelect *ngIf="resList.length"
                                       [items]="getResources(role) | orderBy:'displayname'"
                                       bindValue="id"
                                       bindLabel="displayname"
                                       [(ngModel)]="resourceId"
                                       placeholder="Select Resource">
                            </ng-select>
                        </div>
                        <div class="form-group col-12 col-md-4 pr-0">
                            <label>Permission</label>
                            <ng-select #permissionSelect
                                       [items]="permissions | async | orderBy:'displayname'"
                                       bindValue="id"
                                       bindLabel="displayname"
                                       [(ngModel)]="permissionId"
                                       placeholder="Select Permission">
                            </ng-select>
                        </div>
                        <div class="col-12 p-0">
                            <button (click)="onSaveClicked()" class="btn btn-primary float-right">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Privileges</h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <role-previleges [roleId]="roleId" [actions]="true"></role-previleges>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
