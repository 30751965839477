import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ring2voice-voicemail-settings',
  templateUrl: './voicemail-settings.component.html',
  styleUrls: ['./voicemail-settings.component.scss']
})
export class VoicemailSettingsComponent implements OnInit {

  @Input() model;

  @Input() subOrg_id;

  constructor() { }

  ngOnInit(): void {
  }

}
