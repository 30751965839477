import { Observable } from 'rxjs';
import { CreatedSource, ItemTypes } from './../../../core/enums/common.enum';
import { CommonFields, WHBaseModel } from './../../../core/models/common.models';

export class Item extends WHBaseModel{

    public id: number;

    public item_name: string;

    public item_price: number;

    public part_number: string;

    public part_description: string;

    public sku: string;

    public comments: string;

    public created_source: string = CreatedSource.User;

    public category: number;

    public type: number;

    public measurement: string;

    public manufacturer: number;

    public unit_types: number;

    public additional_fields: any;

    public replace_by: any;

    public images: string[] = [];

    public primary_image: string;

    public spec_sheet: string;

    public instructions_sheet: string;

    public sales_account: number;

    // public tax: number;

    public taxable: boolean = true;

    public warehouse: number;

    public warehouse_location: number;

    public suplier: number;

    public product_line:number;

    public material:number;

    public show_on_catelog:boolean=true;

    public is_inventoried: boolean= true;

    public item_type: ItemTypes = ItemTypes.INVENTORY;

    public asset_account: number;
    
    public expense_account: number;
}

export class ItemWrapper extends Item {

    unit: string;
    note: string;

    constructor() { super() }

    public category_name: string;

    public manufacturers_name: string;

    public image_link: string;

    public unittype_name: string;

    public replace_by_part_number: string;

    public itemtype_name: string;

    public salesaccount_name: string;

    public tax_value: number;

    public warehouse_name: string;

    public warehouselocattion: string;

    public stockSelected: ItemWareHouseStock[] = [];

    available_stock: number;

    product_line_name:string;

    material_name:string;

    quickbook_id:string;
}

export class ItemStock extends WHBaseModel {

    public id: number;

    public reserved_quantity: number = 0;

    public reorder_level: number = 0;

    public stock_in_hand: number = 0;

    public item: number;
}

export class ItemWareHouseStock extends WHBaseModel {

    public id: number;

    public item: number;

    public warehouse: number;

    public warehouse_location: number;

    public quantity: number = 0;

    public serial_number: string[];

    public comments: string;

    public reserved_quantity: number = 0

    public available_quantity: number = 0

    locations: Observable<any>;

    location_name: string;

    warehouse_name: string;

    public selected_qty: number = 0
}

export class ItemCategory extends WHBaseModel {

    public id: number;

    public category_name: string;

    public category_prefix: string;

    public description: string;

    public parent: number;

    public additional_fields: any;

    public additional_field_migrated:boolean=false;

    public start_range: number = 0;

    public end_range: number = -1;

    public digits: number = 1;

    public create_partnumber: boolean = true;

    public created_source: string = "user";

    public show_on_catelog:boolean=true;
}

export class ItemCategoryWrapper extends ItemCategory {

    public parent_name: string;
}

export class ItemType extends WHBaseModel {

    id: number;

    name: string;

    description: string;
}

export class ProductLine extends WHBaseModel {

    id: number;

    name: string;

    description: string;
}


export class Material extends WHBaseModel {

    id: number;

    name: string;

    description: string;
}




export class UnitType extends WHBaseModel {

    id: number;

    units: string;

    description: string;
}

export class StockInfo  {

    total_stock: number;

    available_stock: number;

    commited_on_assemblies: number;

    draft_on_assemblies: number;

}
