import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Material } from '../../../models/items';
import { MaterialService } from '../../../services/materials.service';

@Component({
  selector: 'app-material-select',
  templateUrl: './material-select.component.html',
  styleUrls: ['./material-select.component.scss']
})
export class MaterialSelectComponent implements OnInit {

    @Input() value: number;

    @Input() readonly: boolean = false;

    @Output() valueChange = new EventEmitter<number>();

    public options: Material[];

    public placeholder: string;

    private getSub$: Subscription;

    constructor(private materialService: MaterialService,
        private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private ra: ResourceAccessService) {

        this.placeholder = this.hasPermission() ? "Select or add Material" : "Select Material";
    }

    ngOnInit() {
        this.getSub$ = this.materialService.get().subscribe(categories => {
            this.options = categories;
            this.cdr.markForCheck();
        });
    }

    onValueChanged() {
        this.valueChange.emit(this.value);
    }

    openModal(content: any): void {
        this.modalService.open(content);
    }

    onDataSubmittedSuccess(result: { response: Material, message: string }): void {
        this.toastr.success(result.message);
        this.options = this.options.concat(result.response);
        this.value = result.response.id;
        this.onValueChanged();
        this.modalService.dismissAll();
    }

    onDataError(_error: any): void {
        this.toastr.error('Operation failed');
        this.modalService.dismissAll();
    }

    onCancel(): void {
        this.modalService.dismissAll();
    }

    hasPermission() {
        return this.ra.hasPermission(ResourceConstants.INV_ITEMTYPES, [PermissionConstants.CREATE, PermissionConstants.GLOBAL]);
    }

    ngOnDestroy(): void {
        this.getSub$?.unsubscribe();
    }

}
