
export class Role {

    id: number;

    customer: number;

    name: string;

    description: string;

    is_active: boolean = true;

    created_by:string;

    created_on:Date;

    modified_by:string;

    modified_on:Date;

    is_customer_role:boolean=false;

    suborgs?:number[]
}


export class RoleWrapper {

    role:Role;

    isroot:boolean;

    custname:string;
}
