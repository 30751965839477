import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { NgModule } from '@angular/core';

import { CustomerRoutingModule } from './customer-routing.module';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AccountComponent } from './components/account/account.component';
import { CustomerSelectComponent } from './components/customer-select/customer-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TenantSettingsComponent } from './components/tenant-settings/tenant-settings.component';
import { TenantSubOrgsComponent } from './components/tenant-suborgs/tenant-suborgs.component';



@NgModule({
  declarations: [
    CustomerListComponent,
    AddCustomerComponent,
    AccountComponent,
    CustomerSelectComponent,
    TenantSettingsComponent,
    TenantSubOrgsComponent,
    
  ],
  imports: [
    CustomerRoutingModule,
    NgxDatatableModule,
    NgbModule,
    NgSelectModule,
    SharedModule
  ],
  exports:[
    AccountComponent,
    CustomerSelectComponent,
    TenantSettingsComponent
  ]
})
export class CustomerModule { }
