import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Ring2voiceService } from '../../services/ring2voice.service';

@Component({
    selector: 'voice-select',
    templateUrl: './voice-select.component.html',
    styleUrls: ['./voice-select.component.scss']
})
export class VoiceSelectComponent implements OnInit {

    @Input() value: string = 'woman';

    @Output() valueChange = new EventEmitter<string>();

    rows: Observable<any>;

    constructor(private r2vService: Ring2voiceService) { }

    ngOnInit(): void {
        this.rows = this.r2vService.getVoices();
    }

    onValueChanged() {
        this.valueChange.emit(this.value);
    }
}
