<div class="chat-application overflow-hidden">
    <div class="chat-sidebar d-md-block">
        <div class="chat-sidebar-content">
            <div class="chat-fixed-search py-2 pl-2">
                <div class="search-button">
                    <div class="input-clear">
                        <input class="form-control" [(ngModel)]="searchTerm" type="text"
                               placeholder="Search number or Name" (keyup.enter)="searchPhoneNumbers()" />
                        <button *ngIf="searchTerm" (click)="clearSearch()" class="input-clear-btn">
                            <i class="ft-x"></i>
                        </button>
                    </div>
                    <button type="button" class="btn btn-secondary search-btn" (click)="searchPhoneNumbers()">
                        <i class="ft ft-search"></i>
                    </button>
                </div>
                <button class="btn btn-default refresh-btn" (click)="refreshChatWindow()">
                    <i class="ft-refresh-cw"></i>
                </button>
            </div>
            <hr class="m-0">
            <div id="users-list" class="list-group">
                <div class="users-list-padding position-relative d-flex flex-column">
                    <a class="list-group-item" *ngFor="let head of chatHeads;let i = index"
                       (click)="onChatHeadClicked(head)"
                       [class.selected-chat]="head==selected">
                        <div class="media py-1">
                            <span class="avatar avatar-md mr-2">
                                <div *ngIf="head.name" class="user-avatar-content">{{ head.name | shortName }}</div>
                                <div *ngIf="!head.name" class="user-avatar-content">..{{head.did.slice(-2)}}</div>
                            </span>
                            <div class="media-body">
                                <h6 class="list-group-item-heading mb-1 chat-head-label">
                                    {{head.did | phoneNumber}}
                                    <span class="chat-list-text font-small-2 float-right chat-time"
                                          *ngIf="head.last_message_on">
                                        {{moment(head.last_message_on).fromNow()}}
                                    </span>
                                </h6>
                                <p class="list-group-item-text chat-user-list-text m-0">
                                    <span class="friendly-name" *ngIf="head.name">{{head.name}}</span>
                                    <span class="float-right primary" *ngIf="!head.read">
                                        <span class="badge badge-pill bg-light-primary badge-round"
                                              *ngIf="head.count > 0 ">{{head.count}}
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </a>
                    <div class="w-100 text-center load-more" *ngIf="loadMoreVisible">
                        <a class="text-primary" (click)="loadMoreChatHeads()">
                            Load More ...
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="chat-name d-flex align-items-center p-2">
        <div class="media p-1 align-items-center" *ngIf="selected">
            <span class="avatar avatar-md mr-2">
                <div *ngIf="selected.name" class="user-avatar-content">{{ selected.name | shortName }}</div>
                <div *ngIf="!selected.name" class="user-avatar-content">..{{selected.did.slice(-2)}}</div>
            </span>
            <div class="media-body">
                <h6 class="list-group-item-heading mb-1 chat-head-label">
                    {{selected.name || selected.did | phoneNumber }}
                </h6>
            </div>
        </div>
        <div class="d-flex flex-row ml-auto" *ngIf="isSuperAdmin">
            <div class="ctrl mr-1">
                <app-customer-select class="ng-select-sm" [(value)]="chatRequest.customer_id"></app-customer-select>
            </div>
            <div class="ctrl">
                <suborg-select class="ng-select-sm"
                               [readonly]="!chatRequest.customer_id"
                               [tenantId]="chatRequest.customer_id"
                               [(value)]="chatRequest.suborg_id"
                               (valueChange)="this.onSuborgChanged()">
                </suborg-select>
            </div>
        </div>
    </div>
    <section class="chat-app-window position-relative" #scrollMe [scrollTop]="scrollMe.scrollHeight">
        <div class="chat-background"></div>
        <div class="chats">
            <div class="chat" [ngClass]="{'chat-left': true}">
                <div class="chat-avatar">
                    <a class="avatar avatar-md" data-toggle="tooltip" data-original-title=""></a>
                </div>
                <div class="chat-body">
                    <div class="chat-content" *ngFor="let message of messages">
                        <p class="text-primary">{{ message.sent_from | phoneNumber}}</p>
                        <p>{{ message.body }}</p>
                        <p><span class=" mb-1 timeBadge">{{moment(message.date_sent).fromNow()}}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
