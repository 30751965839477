import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { RouteInfo } from './../models/route-info';
import { AppConstants } from './../constants/app.constants';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { ResourcePrivilege } from 'src/app/modules/auth/models/auth-response';
import { APP_ROUTES } from '../routes/app.routes';
import { GFColumn, GFilterParam } from '../models/grid-filter.models';
import { UIPermission } from '../models/common.models';

@Injectable({
    providedIn: 'root'
})
export class ResourceAccessService extends BaseService {

    private _previlages: ResourcePrivilege[]

    public get previleges(): ResourcePrivilege[] {
        if (!this._previlages) {
            this._previlages = this.getPrivileges();
        }
        return this._previlages;
    }

    constructor() { super(); }

    public getRoutes() {
        this._previlages = null;
        let tempRoutes: RouteInfo[] = [];
        this.generateRecursive(APP_ROUTES, tempRoutes);
        return tempRoutes;
    }

    private generateRecursive(source: RouteInfo[], result: RouteInfo[]) {
        for (let route of source) {
            if (this.isValidRoute(route)) {
                let clone = this.cloneRoute(route);
                result.push(clone);
                if (route.submenu.length > 0) {
                    this.generateRecursive(route.submenu, clone.submenu);
                }
            }
        }
    }

    private isValidRoute(routeInfo: RouteInfo): boolean {

        return routeInfo.userTypes.includes(this.userType)||this.hasPermissionInternal(routeInfo.resource, routeInfo.permissions, routeInfo.globalResource);

    }


    //Function in progress
    isSubMenuHasPermission(routeInfo: RouteInfo){

        
        let hasPermission:number=0;

        if(routeInfo.submenu&&routeInfo.submenu.length){

            routeInfo.submenu.forEach((val)=>{
                if(this.isPrivileged(val.resource, val.permissions)){
                    hasPermission++;
                }

                if(val.submenu&&val.submenu.length){

                    val.submenu.forEach((val1)=>{
                        if(this.isPrivileged(val1.resource, val1.permissions)){
                            hasPermission++;
                        }
                        if(val1.submenu&&val1.submenu.length){

                            val1.submenu.forEach((val2)=>{
                                if(this.isPrivileged(val2.resource, val2.permissions)){
                                    hasPermission++;
                                } 
                            });
                        }

                    })

                }
            })
        }

        return hasPermission>0?true:false;


    }

    private cloneRoute(route: RouteInfo): RouteInfo {
        return {
            path: route.path,
            title: route.title,
            icon: route.icon,
            class: route.class,
            badge: route.badge,
            badgeClass: route.badgeClass,
            isExternalLink: route.isExternalLink,
            submenu: [],
            resource: route.resource,
            globalResource: route.globalResource,
            permissions: route.permissions,
            userTypes: route.userTypes,
            newLink: route.newLink
        };
    }

    private hasPermissionInternal(resourceName: string, permissions: string[], globalResource: string): boolean {
        
        if (!permissions.includes(PermissionConstants.GLOBAL)) {
            permissions.push(PermissionConstants.GLOBAL);
        }
        let globalAccess = false;
        if (globalResource) {
            globalAccess = this.isPrivileged(globalResource, permissions);
        }
        return globalAccess || this.isPrivileged(resourceName, permissions);
    }

    public getPrivileges(): ResourcePrivilege[] {
        return JSON.parse(localStorage.getItem(AppConstants.USER_PREVILEGES) || '[]');
    }

    private isPrivileged(resourceName: string, permissions: string[]): boolean {
 
        const resources = this.previleges?.find(p => p.resourcename === resourceName);
        if (resources && resources.privileges) {
            
            return resources.privileges.some(x => permissions.includes(x));

        }
        return false;
    }

    public hasPermission(resource: string, permissions: string[], globalResource: string = null): boolean {

        return this.isAdmin || this.hasPermissionInternal(resource, permissions, globalResource);
    }

    public hasGlobalPermission(globalresources: string[]): boolean {
        const resources = this.previleges?.filter(element => globalresources.includes(element.resourcename));
        var isHavePermission:boolean=false;
        resources.forEach(resource => {
            if(isHavePermission)return;
            resource.privileges.some(x => isHavePermission=PermissionConstants.GLOBAL== x ?true:false)
        });
        return this.isAdmin || isHavePermission;
    }

    public hasRoutePermission(resource: string, permissions: string[], globalResource: string = null): boolean {

        return this.isAdmin || this.hasPermission(resource, permissions, globalResource);
    }

    public getGridView(grid: string) {
        let allViews = JSON.parse(localStorage.getItem(AppConstants.USER_GRIDS) || '[]');
        if (allViews.length > 0) {
            return allViews.find((item: any) => item.resource.name === grid);
        }
        return null;
    }

    public isColumnProfiled(grid: any, columName: string, admin_column = false) {
        if (this.isAdmin && admin_column) {
            return true;
        }
        return grid?.columns?.some(c => c.name === columName) || false;
    }

    public filterGFColumns(source: GFColumn[], gridName: string = null) {

        if (this.isAdmin || this.isSuperUser) return source;

        let grid = gridName ? this.getGridView(gridName) : null;

        return source.filter(col => {
            if (col.gridColumn && col.resource) {
                return this.hasPermission(col.resource, [PermissionConstants.VIEW]) && this.isColumnProfiled(grid, col.gridColumn);
            }
            else if (col.gridColumn && !col.resource) {
                return this.isColumnProfiled(grid, col.gridColumn);
            }
            else if (!col.gridColumn && col.resource) {
                return this.hasPermission(col.resource, [PermissionConstants.VIEW]);
            }
            return true;
        });
    }

    public filterSearchColumns(source: GFilterParam[], gridName: string = null) {

        if (this.isAdmin || this.isSuperUser) return source;

        let grid = gridName ? this.getGridView(gridName) : null;

        return source.filter(col => {
            if (col.gridColumn && col.resource) {
                return this.hasPermission(col.resource, [PermissionConstants.VIEW]) && this.isColumnProfiled(grid, col.gridColumn);
            }
            else if (col.gridColumn && !col.resource) {
                return this.isColumnProfiled(grid, col.gridColumn);
            }
            else if (!col.gridColumn && col.resource) {
                return this.hasPermission(col.resource, [PermissionConstants.VIEW]);
            }
            return true;
        });
    }

    public getUIPermissions(resource: string, globalResource: string = null, allowSuperAdmin: boolean = false): UIPermission {
        const perm: UIPermission = new UIPermission();
        perm.view = true;
        perm.viewInfo = allowSuperAdmin || this.hasPermission(resource, [PermissionConstants.VIEW_INFO], globalResource);
        perm.create = allowSuperAdmin || this.hasPermission(resource, [PermissionConstants.CREATE], globalResource);
        perm.edit = allowSuperAdmin || this.hasPermission(resource, [PermissionConstants.MODIFY], globalResource);
        perm.delete = allowSuperAdmin || this.hasPermission(resource, [PermissionConstants.DELETE], globalResource);
        perm.activate = allowSuperAdmin || this.hasPermission(resource, [PermissionConstants.ACTIVE], globalResource);
        perm.deactivate = allowSuperAdmin || this.hasPermission(resource, [PermissionConstants.INACTIVE], globalResource);
        perm.export = allowSuperAdmin || this.hasPermission(resource, [PermissionConstants.EXPORT], globalResource);
        return perm;
    }

    public reset() {
        this._previlages = null;
    }
}
