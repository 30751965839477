import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ChatService } from '../../services/chat.service';
import { ChatHead, ChatHeadRequest, Message } from '../../models/chat.model';
import * as moment from 'moment';
import { DataFilterRequest, GFilterParam } from 'src/app/core/models/grid-filter.models';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

    public SelectionType = SelectionType;

    public chatHeads: ChatHead[] = [];

    public chatHeadsCopy: ChatHead[] = [];

    public uniqeId: number;

    public subs: SubSink = new SubSink();

    public scrollbarH: boolean = false;

    public chatRequest: ChatHeadRequest = new ChatHeadRequest();

    public sort = [];

    public messages: Message[] = []

    public isSuperAdmin: boolean;

    public selected: ChatHead;

    public moment = moment;

    public loadMoreVisible: boolean;

    public searchTerm: string;

    constructor(private cdr: ChangeDetectorRef, private chatService: ChatService) {
        this.isSuperAdmin = chatService.isSuperUser;
        if (!this.isSuperAdmin) {
            this.chatRequest.suborg_id = chatService.suborgId;
        }
    }

    ngOnInit(): void {
        if (!this.isSuperAdmin) {
            this.getChatHeads();
        }
    }

    getChatHeads() {
        this.subs.add(this.chatService.getChatHeads(this.chatRequest).pipe(
            catchError(() => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.chatHeads = this.chatHeads.concat(response.result);
            this.chatHeadsCopy.push(...response.result);
            this.loadMoreVisible = response.count > this.chatHeads.length;
            this.cdr.markForCheck();
        }))
    }

    getMessages(filters: GFilterParam[], offset: number = 0) {

        let request: DataFilterRequest = { filters: filters, sort: this.sort }

        this.subs.sink = this.chatService.getMessages(request, offset).pipe(
            catchError(() => of({ count: 0, result: [] }))
        ).subscribe(response => {
            this.messages = response.result;
            this.cdr.markForCheck();
        });
    }

    onChatHeadClicked(chatHead: ChatHead) {
        this.selected = chatHead;
        let filter: GFilterParam[] = [
            { colname: 'suborg', condition: 'equalto', value: this.selected.sub_org_id, operator: 'AND' },
            { colname: 'to', condition: 'is', value: this.selected.did, operator: 'AND' }
        ]
        this.getMessages(filter, 0);
        if (chatHead.count) {
            this.markMessage(this.selected.did);
        }
        chatHead.count = 0;
    }

    markMessage(sent_to: string) {
        this.subs.add(this.chatService.markMessages(sent_to).subscribe());
    }

    onSuborgChanged() {
        this.chatHeads = [];
        this.chatHeadsCopy = [];
        this.messages = [];
        this.chatRequest.reset();
        this.getChatHeads();
    }

    loadMoreChatHeads() {
        this.chatRequest.updateOffset();
        this.getChatHeads();
    }

    searchPhoneNumbers() {
        if (this.searchTerm) {
            let request: ChatHeadRequest = new ChatHeadRequest();
            request.suborg_id = this.chatRequest.suborg_id;
            request.search = this.searchTerm;

            this.subs.add(this.chatService.getChatHeads(request).pipe(
                catchError(() => of({ count: 0, result: [] }))
            ).subscribe(response => {
                this.chatHeads = response.result;
                this.loadMoreVisible = false;
                this.cdr.markForCheck();
            }))
        }
    }

    clearSearch() {
        this.searchTerm = '';
        if (this.chatHeadsCopy.length) {
            this.chatHeads = [...this.chatHeadsCopy];
        }
    }

    refreshChatWindow() {
        if (this.chatRequest.suborg_id) {
            this.chatHeads.length = 0
            this.chatHeadsCopy.length = 0;
            this.chatRequest.offset = 0;
            this.chatRequest.search = null;
            this.getChatHeads();
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
