import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BaseService } from "src/app/core/services/base.service";
import { SIPdetailsResponse } from "../models/call-center.models";
import { StatusValueEnum } from "src/app/core/enums/common.enum";

@Injectable({
    providedIn: 'root'
})
export class CallcenterSharedService extends BaseService{

    public isSIPRegistered$=new BehaviorSubject<boolean>(false);

    public sip_details: SIPdetailsResponse = new SIPdetailsResponse();

    public connection: any;

    public connectingSIP$=new BehaviorSubject<boolean>(false);

    public processingStatusChange$=new BehaviorSubject<boolean>(false);
  
    public processingStatusChangeMsg:string='Connecting server..';

    public agentStatus$  = new BehaviorSubject<string>(StatusValueEnum.OFFLINE);

    public toBeStatus$=new BehaviorSubject<string>(StatusValueEnum.OFFLINE);
  
    public isInternetConnected$=new BehaviorSubject<boolean>(true);

    public selectedAudioInput: string;

    public selectedOutputAudio: string;

    public selectedVideoSource: any;

    public availableAudioInputDevices: any[] = [];
} 