import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';


@Component({
  selector: 'label-add',
  templateUrl: './label-add.component.html',
  styleUrls: ['./label-add.component.scss'],

})
export class LabelAddComponent implements OnInit {

  public labelId:number;

  public subs:SubSink=new SubSink();


  constructor(private location:Location, 
    private toaster:ToastrService,
    private activeRoute: ActivatedRoute,) {
      this.subs.sink = this.activeRoute.params.subscribe(
        params => {
          this.labelId = +params["id"]|| 0;
        }
      );

  }

  onFailure(event){

  }
 

  onSuccess(event){
    if(this.labelId){
      this.toaster.success('Label Design updated successfully');

    }
    else{
      this.toaster.success('Label Design created successfully');

    }
    this.location.back();
  }

  onCancel(event){
    this.location.back();

  }


  ngOnInit(): void {



  }



    ngOnDestroy(){
    }

}
