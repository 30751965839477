import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DataGridFilterComponent } from 'src/app/shared/components/data-grid-filter/data-grid-filter.component';
import { listTerms } from '../../models/terms.models';
import { TermsService } from '../../services/terms.service';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import * as popup from 'src/app/core/utils/popup.functions';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UIPermission } from 'src/app/core/models/common.models';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ModuleConstants } from 'src/app/core/enums/common.enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { GFColumn, GFColumnTypes, GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public SelectionType = SelectionType;

  public termsList: listTerms[];

  public expanded: boolean = true;

  @ViewChild('dataGridFilter') dataFilter: DataGridFilterComponent;

  @ViewChild('dataTable') dataTable:DatatableComponent;

  public selected: listTerms;

  public scrollbarH: boolean = false;

  public subs: SubSink = new SubSink();

  public termId: number = 0;

  public UIPermissions: UIPermission;

  public Permissions = PermissionConstants;

  public module: ModuleConstants = ModuleConstants.TERMS;
  
  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  public sort: GSortParam[] = [{ colname: 'created_on', direction: 'desc' }];


  public defaults: GFilterParam[] = [
    { displayName: "All Terms & Conditions", colname: '', condition: 'is', value: null, operator: 'AND' },
    { displayName: "Proposal's Terms & Conditions", colname: 'category', condition: 'equalto', value: '1', operator: 'AND' },
    { displayName: "Invoices's Terms & Conditions", colname: 'category', condition: 'equalto', value: '2', operator: 'AND' },
    { displayName: "Warranty's Terms & Conditions", colname: 'category', condition: 'equalto', value: '3', operator: 'AND' },
  ];

  public search: GFilterParam[] = [
    {
      displayName: 'Combo Search', colname: '', condition: '', value: '',
      children: [
        { displayName: 'Title', colname: 'title', condition: 'contains', value: '', operator: 'OR' },
        { displayName: 'Description', colname: 'description', condition: 'contains', value: '', operator: 'OR' },
      ]
    },
    { displayName: 'Title', colname: 'title', condition: 'contains', value: '', operator: 'OR' },
    { displayName: 'Description', colname: 'description', condition: 'contains', value: '', operator: 'OR' },
  ];


  public columns: GFColumn[] = [
    { displayName: 'Created By', columnName: 'created_user', columnType: GFColumnTypes.ref },
    { displayName: 'Created Date', columnName: 'created_on', columnType: GFColumnTypes.date },
  ];

  public sortLookup = {
    created_on:"created_on",
    title:"title",
  };
  


  

  constructor(private TermsService: TermsService,
    private toastr: ToastrService,
    public location: Location,
    activeRoute: ActivatedRoute,
    private ra: ResourceAccessService,
    private cdr: ChangeDetectorRef,
    private device: DeviceDetectorService) {
    this.scrollbarH = this.device.isMobile();
    this.subs.sink = activeRoute.params.subscribe(params => this.termId = params.id || 0);
    this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.SALES_TERMS, ResourceConstants.SALES_MODULE);

  }

  ngOnInit(): void {

    this.getData(null);

    if (this.termId != 0) {
      this.getById(this.termId);
    }
  }

  ngAfterViewInit() {

    setTimeout(()=>{                        
      this.dataTable.recalculate();
    }, 800);
    
  }

  getById(id: number) {
    this.subs.sink = this.TermsService.getByid2(id).subscribe(
      response => {
        this.expanded = false;
        this.selected = response;
        this.refreshDataTable();
        this.cdr.markForCheck();
      }
    );

  }

  getData(filters: GFilterParam[], offset: number = 0) {
    let option = { sort: this.sort, filters: filters };
    this.TermsService.listTerms(option, offset).subscribe(response => {
      this.termsList = response.result;
      console.log("You are here");
      console.log(this.termsList);
      this.setPagination(offset, response.count);
      this.cdr.markForCheck();
    });
  }
  setPagination(offset: number, total: number) {
    this.page.count = total;
    let upperLimit = offset + this.page.limit;
    if (upperLimit > total) {
      upperLimit = total;
    }
    this.page.pageNumber = offset + '-' + upperLimit;
  }


  onSelect({ selected }): void {
    let wrapper = selected[0];
    this.selected = wrapper;
    if(this.selected&&this.UIPermissions.viewInfo){
      this.expanded = false;
      this.location.go('sales/terms-conditions/' + this.selected.id + '/view');
    }
  }

  getCategory(id: number) {
    return this.TermsService.getCategory(id);
  }

  deleteTerms(row: any) {
    let data = { "is_delete": true };
    popup.ConfirmDelete(result => {
      if (result.isConfirmed) {
        this.subs.sink = this.TermsService.deleteTerms(data, row.id).subscribe(
          () => {
            this.termsList = this.termsList.filter(x => x.id != row.id);
            this.cdr.markForCheck();
            this.closeDetailedView();
            this.refreshDataTable();
            popup.CompleteDelete('Terms and conditions deleted successfully');
          },
          () => {
            this.toastr.error("Unable to delete terms and conditions");
          });
      }
    });
  }

  closeDetailedView() {
    this.expanded = true;
    this.selected = null;
    this.location.go(`/sales/terms-conditions`);
    this.refreshDataTable();
  }

  refreshDataTable() {
    setTimeout(() => {
      this.cdr.markForCheck();
      this.termsList = [...this.termsList];
    });
  }


  onSort(event: any) {
    if (event.sorts && event.sorts.length > 0) {
      let current = event.sorts[0];
      if (!this.sortLookup[current.prop])
        return;
      let sort = new GSortParam();
      sort.colname = this.sortLookup[current.prop];
      sort.direction = current.dir;
      this.sort = [sort];
      let params = this.dataFilter?.getDataFilters() || [];
      this.getData(params, 0);
    }
  }



  setPage(pageInfo: any) {

    let offset = pageInfo.offset * this.page.limit;
    this.page.offset = pageInfo.offset;
    this.getData(this.dataFilter?.getDataFilters() || [], offset);
  }

  applyFilter(params: GFilterParam[]) {
    this.getData(params);
  }
}
