import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { CustomerSystemType } from '../../../models/system-type';
import { SystemTypeService } from '../../../services/system-type.service';

@Component({
  selector: 'customer-system-type-form',
  templateUrl: './customer-system-type-form.component.html',
  styleUrls: ['./customer-system-type-form.component.scss']
})
export class CustomerSystemTypeFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('name', { static: false, read: ElementRef }) nameControl: ElementRef;

  public model: CustomerSystemType = new CustomerSystemType();

  @Input() uniqueId: number = 0;

  @Output() success = new EventEmitter<{ response: CustomerSystemType, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter();

  public nameExist: boolean = false;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private service: SystemTypeService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.uniqueId > 0) {
      this.getSub$ = this.service.getById(this.uniqueId).subscribe(SystemType => {
        this.model = SystemType;
        this.cdr.markForCheck();
      });
    }
  }

  ngAfterViewInit(): void { this.nameControl?.nativeElement.focus(); }

  onSubmit(): void {

    let api: Observable<CustomerSystemType>;

    if (this.uniqueId > 0)
      api = this.service.update(this.uniqueId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;
      api = this.service.create(this.model);
    }

    this.postSub$ = api.subscribe(
      response => {
        let msg = this.uniqueId > 0 ? 'System-Type info updated' : 'New System-Type created';
        this.nameExist = false;
        this.success?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void { this.cancel?.emit(); }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
    this.postSub$?.unsubscribe();
  }
}
