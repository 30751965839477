import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ItemType } from '../models/items';

@Injectable({
  providedIn: 'root'
})
export class ItemTypesService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<ItemType[]>(`${this.baseURL}/itemtypes/`);
  }

  getById(id: number) {
    return this.http.get<ItemType>(`${this.baseURL}/itemtypes/${id}/`);
  }

  create(itemType: ItemType) {
    return this.http.post<ItemType>(`${this.baseURL}/itemtypes/`, itemType);
  }

  update(id: number, itemType: ItemType) {
    return this.http.put<ItemType>(`${this.baseURL}/itemtypes/${id}/`, itemType);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/itemtypes/${id}/`);
  }
}
