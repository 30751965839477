import { AnyAaaaRecord } from "dns";
import { Item } from "./items";




export class Sections {

    public title: string;

    public pages: any[];


}

export class SVGInfo {

    public title: string;

    public svgString: any[];


}

export class CatalogFilterResponse {
    public result: Catalog[];

    public count: number;
}

export class SalesCatalogViewFilterResponse{

    public data:Product[];

    public message:string;

    public selected_values:string[];


}

export class SelectProductCategory{

    public section : number;

    public category : number[];
}

export class Catalog {

    public id: number;

    public customer: number;

    public suborg: number;

    public created_on: string;

    public created_by: string;

    public modified_on: string;

    public created_user: number;

    public modified_user: number;

    public is_delete: boolean;

    public is_active: boolean;

    public name: string;

    public category: number;

    public width: number;

    public height: number;

    public no_of_pages: number;

    public no_of_products: number;

    public image: string;

    public template: any;

    public section_info :any

    public file_name : string

    public file_uuid : string

    public show_price: boolean

    public full_check_category:SelectProductCategory[]

    




}

export class CatalogProductFilterResponse {

    public result: CatalogueProductListResponse[];
    public count: number;

}

export class CatalogueProductListResponse {

    public id: number;

    public title: string;

    public type: number;

    public template: CanvasObject;

    public customer: number;

    public created_user: number;

    public created_on: string;

    public modified_on: string;

    public modified_user: number;

    public is_delete: boolean;

    public created_by: string;

    public width: number;

    public height: number;

    public image: string;

    public svg: string;

    public svgString:any;


}

export class CatalogueProductCreateRequest {

    public id: number;

    public title: string;

    public type: string;

    public template: string;

    public tenant_customer: number;

    public customer: number;

    public width: number;

    public height: number;

    public suborg: number;

    public image: string;

    public svg: string;

    public is_global_component:boolean=false;


}


export class CatalogueCatagoryResponse {

    public id: number;

    // public customer: number;

    // public suborg: number;

    // public created_on: string;

    // public modified_on: string;

    // public created_user: number;

    // public modified_user: number;

    // public is_delete: boolean;

    // public is_active: boolean;

    // public is_imported: boolean;

    // public source: string;

       public category_name: string;

    // public category_prefix: string;

    // public description: string;

    // public parent: number;

    // public additional_fields: string;

    // public start_range: string;

    // public end_range: number;

    // public digits: number;

    // public create_partnumber: boolean;

    // public show_on_catelog: boolean;

    public child_record: CatalogueCatagoryResponse[];


}


export class CatalogueCatagoryProductResponse {

    public id: number;

    public category_name: string;

    public child_record: CatalogueCatagoryProductResponse[];

    public items: CatalogueCatagoryResponseItems[];
}


export class CatalogueCatagoryResponseItems extends Item {

    // public id:number;

    // public customer:number;

    // public suborg:number;

    // public created_on:string;

    // public modified_on:string;

    // public created_user:number;

    // public modified_user:number;

    // public is_delete:boolean;

    // public is_active:boolean;

    // public is_imported:boolean;

    // public source:string;

    // public item_name:string;

    // public part_number:string;

    // public part_description:string;

    // public comments:string;

    // public additional_fields:any;

    // public category:number;

}

export class ProductResponse {

    public result: Product[];

    public count: number;
}

export class Product {

    public id: number;

    public sku: string;

    public created_on: string;

    public modified_on: string;

    public is_active: boolean;

    public category: number;

    public product_name: string;

    public product_part_number: string;

    public product_image: string;

    public item_types: number;

    public item_types_name: string;

    public category_name: string;

    public product_type: string;

    public sellprice: number;

    public manufacturer_name: string;

    public created_by: string;

    public modified_by: string;

    public suppliers: number;

    public selected :boolean;


}





// ---------Label page template model -----------//


export class CanvasObject {

    public objects: CanvasObjects[];

    public version: string;
}


export class CanvasObjects {


    public left: number;

    public top: number;

    public fill: string;

    public text: string = null;

    public type: string;

    public angle: number;

    public width: number;

    public height: number;

    public opacity: number;

    public fontSize: number;

    public src: string = "";

    public scaleX: number;

    public scaleY: number;

    public filters: [];

    public crossOrigin: string;

    public path: string;

    public cropX: number;

    public cropY: number;

    public flipX: boolean;

    public flipY: boolean;

    public skewX: number;

    public skewY: number;

    public shadow: string;

    public stroke: number;

    public styles: {} = {};

    public originX: string;

    public originY: string;

    public version: string;

    public visible: boolean;

    public fillRule: string;

    public overline: boolean;

    public pathSide: string;

    public direction: string;

    public fontStyle: string;

    public pathAlign: string;

    public textAlign: string;

    public underline: boolean;

    public fontFamily: string;

    public fontWeight: string;

    public lineHeight: number;

    public paintFirst: string;

    public charSpacing: number;

    public linethrough: boolean;

    public strokeWidth: number;

    public strokeLineCap: string;

    public strokeUniform: boolean;

    public strokeLineJoin: string;

    public backgroundColor: string;

    public pathStartOffset: number;

    public strokeDashArray: [];

    public strokeDashOffset: number;

    public strokeMiterLimit: number;

    public textBackgroundColor: string;

    public globalCompositeOperation: string;

    public name: string;

    public objects: CanvasObjects[];




}

export class SubNavSettings {

    public attributes: boolean = false;

    public text: boolean = false;

    public images: boolean = false;

    public buttons: boolean = false;

    public pages: boolean = false;

    public products: boolean = false;

    public addProducts: boolean = false;

    public shapes: boolean = false;
}

export class qrData {

    // public type:string;

    // public uniqueId:string;

    public partNo: string;


    // public sku:string;

    public slNo: string;
}


export class catalogFilterRequest{

    public select_category:number[];

    public select_additional:number[];

    public select_material:number[];

}




