import { Observable } from 'rxjs';
import { WHBaseModel } from 'src/app/core/models/common.models';
import { id } from 'src/app/core/utils/global.functions';
import { KitItemsType } from './kit.models';

export class KitAssemble extends WHBaseModel {

    public id: number;

    public unique_number: string;

    public kits: number;

    public quantity: number = 1;

    public status: string;

}

export class KitAssembleWrapper extends KitAssemble {

    public kit_name: string;

    public part_number: string;

    public kit_sku: string;

    public kit_costprice: number;

    public kit_sellprice: number;

    public image_link: string;

    public stockSelected:any

    public kit_category_name:string;

    available_stock: number;

    kit_image:string;
}

export class KitAssembleMaterialRow {

    id: number;

    kitassemble: number;

    itemType: KitItemsType;

    quantity: number = 1;

    requested_quantity: number;

    //kitid/itemid
    item_id: number;

    customer: number;

    primary_image: string;

    item_name: string;

    part_number: string;

    guid: string;

    stock: number;

    public children: KitAssembleMaterialRow[];

    public expanded: boolean = false;


    public selected_stock: KitAssembleMaterialLocation[];

    public selected_kitassemble:number

    status: string;

    unit: string;
    level: number;
    color: any;

    constructor() {
        this.guid = id();
    }
}

export class KitAssembleMaterialLocation extends WHBaseModel{

  public id: number;

  public kit_assemble ?: number;

  public type ?: KitItemsType;

  public unique_id ?: number; // item or assemble

  public warehouse: number;

  public warehouse_location: number;

  public selected_quantity: number = 0;

  available_quantity:number = 0;

  location_name:string;

  warehouse_name:string;
}

export class KitAssembleMaterial extends WHBaseModel{

    public id: number;

    public kitassemble: number;

    public quantity: number = 1;

    public requested_quantity: number;

    public items: number;

    primary_image: string;

    item_name: string;

    part_number: string;

    guid: string;

    available_stock: number;

    public status: string;

    public selected_stock: KitAssembleMaterialLocation[];
    unit: string;
}

export class KitAssembleWareHouse extends WHBaseModel{

    public id: number;

    public serial_number: string;

    public kitassemble: number;

    public warehouse_location: number;

    locations: Observable<any>;

    warehouseid: number;

    warehousename: string;

    locationname: string;
}

export class KitAssembleKitMaterial extends WHBaseModel{

    public id: number;

    public kitassemble: number;

    public selected_kitassemble: number;

    public quantity: number = 1;

    public requested_quantity: number;

    public kit: number;

    kit_name: string;

    internal_number: string;

    primary_image: string;

    guid: string;

    available_stock: number;

    public status: string;

    public selected_stock: KitAssembleMaterialLocation[];
    unit: string;
}

export class KitAssembleWareHouseStock extends WHBaseModel{

  public id: number;

  public kit_assemble: number;

  public warehouse: number;

  public warehouse_location: number;

  public quantity: number = 0;

  public serial_number: string[];

  public comments: string;

  locations: Observable<any>;

  location_name:string;

  warehouse_name:string;

  selected_qty: number = 0

  public reserved_quantity: number = 0;

  public available_quantity: number = 0;
}
