import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/constants/app.constants';
import { PermissionConstants } from 'src/app/core/constants/permissions.constants';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { FilesService } from 'src/app/core/services/files.service';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { Catalog, catalogFilterRequest, CatalogueCatagoryProductResponse, Sections } from 'src/app/modules/inventory/models/catalogue.models';
import { PageCanvasComponentComponent } from 'src/app/modules/inventory/pages/catalogue/_page-canvas-component/page-canvas-component.component';
import { CatalogueFunctionalService } from 'src/app/modules/inventory/services/catalogue-functional.service';
import { CatalogueService } from 'src/app/modules/inventory/services/catalogue-service.service';
import { SubSink } from 'subsink';

import { ItemRow, SOItemRow, SOItemsType } from '../../../models/sales-orders.models';
import { CatalogCartService } from '../../../services/catalog-cart.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { CategoryService } from 'src/app/modules/inventory/services/category.service';
import { GFilterParam, GSortParam } from 'src/app/core/models/grid-filter.models';
import { ItemCategory } from 'src/app/modules/inventory/models/items';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catMaterialFilterResponse, catCustomFieldResponse, catCustomFieldValueList } from 'src/app/modules/inventory/models/additional-fields.models';
import { AdditionFieldsService } from 'src/app/modules/inventory/services/addition-fields.service';
import { filter, map } from 'rxjs/operators';
import { CanvasObject } from 'src/app/modules/inventory/models/label.models';
import { DomSanitizer } from '@angular/platform-browser';
import { resolve } from 'dns';
import { reject, result } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductOrderPreviewComponent } from '../../../components/product-order-preview/product-order-preview.component';

@Component({
  selector: 'app-catalog-customer-view',
  templateUrl: './catalog-customer-view.component.html',
  styleUrls: ['./catalog-customer-view.component.scss'],

})
export class CatalogCustomerViewComponent implements OnInit {



  @ViewChildren('canvas1') childCanvas: QueryList<PageCanvasComponentComponent>;

  @ViewChildren('content') content

  @ViewChild('fullScreen') divRef;

  public width: number = 2;

  public height: number = 2;

  public canvasZoom: number = 0;

  public Permissions = PermissionConstants;

  public Resource = ResourceConstants;

  public catalogueType: string;

  public catalogTitle: string;

  public catalogProductCount: any = [];

  public cataloguePages = [];

  public catalogueCanvas = [];

  public selectedCanvas;

  public selectedCanvasIndex = 0;

  public catalogCatagoryProducts: CatalogueCatagoryProductResponse;

  public pageIndexStart: any = [];

  public title: string = "";

  public subs: SubSink = new SubSink();

  public catalogId: number;

  public editingMode: boolean = true;

  public sections: Sections[] = [];

  public totalPageCount = 0;

  public catalogPages: any = [];

  public catalogPagesRow: any = [];

  public showedCatalogIndex: number = 0;

  public newZoom = 0;

  public showCanvas: boolean = false;

  public associatedProducts = []

  public userAuth = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));

  public KitIemTypes = SOItemsType;

  public sectionInfo: any

  imgURL: string = environment.imgURL

  public notFlippableIndex: number = 0

  public collapseIndx: number

  public canvasLoaded: boolean = false

  public lastFlippedPageIndex: number = -1;

  public sectionPageImageList: any[] = [];

  public btnScaleX : any

  public btnScaleY : any

  public isFullscreen:boolean = false;

  public categoryList$: BehaviorSubject<ItemCategory[]> = new BehaviorSubject([]);
  public filterMaterialList$: BehaviorSubject<catMaterialFilterResponse[]> = new BehaviorSubject([]);

  public filterAdditionalFields$: BehaviorSubject<catCustomFieldResponse[]> = new BehaviorSubject([]);
  // public dataFetch:boolean=false;
  cartItems: any[] = [];

  //
  public group: any = {}
  public groupIndex: number = 0
  public main_rect: any
  public main_rect_color: any
  public objects: any
  public flag: number

  public filterCategories: number[] = [];
  public filterMaterials: number[] = [];

  public showFilter: boolean = false;
  public hideTbIndexView = false
  public hideThumbnailView = true

  public selectedPageIndex: number = 0;

  public viewedPageIndx: number = 0

  public lastFlippedIndex: number = 0

  public showSectionCanvas: boolean = true

  public showSectionTableOfContent: boolean = true
  public showSectionThumbnail: boolean = false


  public fileName: string = '' //for download pdf

  public productIndex: number = 0

  public showMode: number = 0

  public show_price: boolean = true

  public show_material_drop: boolean = false

  public materialData: catMaterialFilterResponse[] = []

  public catalogData: Catalog;

  constructor(
    private catalogueFnService: CatalogueFunctionalService,
    private catalogCartService: CatalogCartService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private ra: ResourceAccessService,
    private catalogueService: CatalogueService,
    private fileService: FilesService,
    private currencyPipe: CurrencyPipe,
    private categoryService: CategoryService,
    private additionalFieldService: AdditionFieldsService,
    private modalService: NgbModal,

    private sanitizer: DomSanitizer

  ) {
    this.subs.sink = this.activeRoute.params.subscribe(
      params => {
        this.catalogId = +params["id"] || 0;
      }
    );
  }

  ngOnInit(): void {

    this.catalogueFnService.catalogData = []

    this.width = 8.5;
    this.height = 11;

    if (this.catalogId > 0) {
      this.edit(this.catalogId);
      // this.getAssociatedProducts(this.catalogId)
    }

    let cartid = sessionStorage.getItem("cartid") //id for already saved cart items

    if (cartid != this.userAuth.custid+"_"+this.catalogId) {
     
      this.catalogCartService.clearCart()
    }

    this.catalogCartService.currentCartItems.subscribe(res => {

      this.cartItems = res;
    })

    this.getCategories();

    console.log('custid',this.userAuth.custid)

    

  



  }

  @HostListener('document:fullscreenchange', ['$event'])
  fullscreenChange(event: Event) {
    //alert('here')
    this.isFullscreen = document.fullscreenElement !== null;
    
  }

  @HostListener('window:resize', ['$event'])
  updateZoom() {

    let newZoom = this.catalogueFnService.calculateZoomWithHeight(this.height, false);
    this.zoomChanged(newZoom * 100);
  }

  exitFullScreen()
  {
    document.exitFullscreen()
  }


  edit(id: number) {

    this.totalPageCount = 0;

    this.subs.sink = this.catalogueService.getCatalogById(id).subscribe(
      response => {

        
        
        this.catalogData = response.data;

        this.catalogTitle = response.data.name;
        this.editingMode = true;

        var secInfo = response.data.section_info

        this.show_price = response.data.show_price

        this.associatedProducts = response.associated_products

        let associated_pros = response.associated_products;


        associated_pros.forEach(element => {

          if (!this.catalogueFnService.catalogData[element.section - 1]) {
            this.catalogueFnService.catalogData.splice(element.section - 1, 0)
            this.catalogueFnService.catalogData[element.section - 1] = []
          }

          this.catalogueFnService.catalogData[element.section - 1].push(element)
        });
        let pagecount = 0

        secInfo.forEach((secelement, secindx) => {

          if (secelement.svg) {
            secelement['svgString'] = []
            secelement['secPageNumber'] = pagecount + 1

            secelement.svg.forEach((x, i) => {

              pagecount++
              secelement.svgString.push(this.sanitizer.bypassSecurityTrustHtml(x))
            })
          }

        })
        this.sectionInfo = secInfo //response.data.section_info

        console.log('sectionInfo', this.sectionInfo)


        this.fileName = response.data.file_name

        if (response.data.template != null) {


          this.sections = [];

          let templateData = JSON.parse(response.data.template);

          templateData.forEach((element, index) => {

            this.sections.push({ title: element.title, pages: element.pages });


          });

          const cat_Pages = templateData.map(function (el) {
            return el.pages//{ page: el.pages, flipped: 0 };
          });

          const cat_pageslist = cat_Pages.flat(1);

          this.catalogPagesRow = cat_pageslist;

          this.pageFilterCheck();

          setTimeout(() => {
            this.newZoom = this.catalogueFnService.calculateZoomWithHeight(this.height, false);
            this.zoomChanged(this.newZoom * 100);
            this.canvasLoaded = true
          }, 30);
        }

        this.cdr.markForCheck();
      }
    )
  }
  setAllSectionPageSVG() {
    let canvasArrayIndx: number = 0 //Canvas Array Index
    this.sections.forEach((secelement, secIndex) => {

      if (!this.sectionPageImageList[secIndex]) {
        this.sectionPageImageList.splice(secIndex, 0)
        this.sectionPageImageList[secIndex] = []
      }

      this.sectionPageImageList[secIndex] = { sectionName: '', images: [], svg: [] }

      this.sectionPageImageList[secIndex].sectionName = this.sections[secIndex].title
      secelement.pages.forEach((pageelmnt, sectionpageindex) => {


        var svgImg = this.childCanvas.toArray()[canvasArrayIndx].canvas.toSVG()
        this.sectionPageImageList[secIndex].svg.push(svgImg)
        this.sectionPageImageList[secIndex].images.push('')
        canvasArrayIndx++


      })

    })

    console.log('section page svg', this.sectionPageImageList)
  }

  updateCatalog() {

    
    this.setAllSectionPageSVG()

    this.catalogData.section_info = this.sectionPageImageList

    this.subs.sink = this.catalogueService.updateCatalog(this.catalogId, this.catalogData).subscribe(
      response => {

       
      }
    )

  }

  getSectioPageIndex(pageIndex: number) {
    
    let secPageIndx = 0
    for (let i = pageIndex; i >= 0; i--) {
      

      var pageTemplate = this.catalogPagesRow[i]
      let isSectionPage = pageTemplate?.objects.filter(x => x?.name == 'productComponent').length > 0 ?
        false : true;

      if (isSectionPage == true) {
        secPageIndx = i;
        break;
      }

    }
    return secPageIndx;
  }
  groupArray(array: Array<any>, field) {
    if (array) {
      const groupedObj = array.reduce((prev, cur) => {
        if (!prev[cur[field]]) {
          prev[cur[field]] = [cur];
        } else {
          prev[cur[field]].push(cur);
        }
        return prev;
      }, {});
      return Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
    }
    return [];
  }

  removeLastPageObjects(index, proRemoveCount) {
    
    let removedCount = 0
    for (let j = proRemoveCount; j >= 1; j--) {


      if (removedCount <= proRemoveCount) {
        this.catalogPages[index].objects.splice(j, 1)
        removedCount++
      }
      else {
        break
      }
    }
  }

  checkShowItemPrice() {
    if (this.show_price == false) {
      this.catalogPagesRow.forEach(page_element => {


        var objects = page_element.objects

        objects.forEach((objelement, objindx) => {

          if (objelement?.name == 'productComponent') {

            var indx = objelement.objects.findIndex(x => x.name == 'sell_price')
            if (indx != -1) {
              objelement.objects.splice(indx, 1)
            }
          }

        });

      });
    }
  }


  pageFilterCheck() {

    
    if (!this.isFilterMode) {

      this.catalogPages = [];
      this.checkShowItemPrice()
      this.catalogPages = [...this.catalogPagesRow]

    }
    else {
      this.catalogPages = [];
      // let catalogDataLength = this.catalogueFnService.catalogData[0] ? this.catalogueFnService.catalogData[0].length : 0;
      let total_productsInthePages = 0;
      var pageInfos = this.groupArray(this.catalogueFnService.catalogData[0], 'section')

      console.log('pageInfosLngth', pageInfos)



      pageInfos.forEach((pginfo_element, infoIndx) => {

        
        let sectionPageIndex = pginfo_element.value[0].section_page_index //this.getSectioPageIndex(firstPageIndex)
        let firstPageIndex = sectionPageIndex + (pginfo_element.value[0].page_number - 1)


        var productSectionTemplate = this.catalogPagesRow[sectionPageIndex]
        var productPageTemplate = { ...this.catalogPagesRow[firstPageIndex] }

        this.catalogPages.push(productSectionTemplate)

        for (let i = 0; i <= pginfo_element.value.length; i++) {

          total_productsInthePages += this.findProductObjectsCount(productPageTemplate);

          if (total_productsInthePages > pginfo_element.value.length) {
            
            let proRemoveCount = total_productsInthePages - pginfo_element.value.length
            this.catalogPages.push(productPageTemplate)
            var objects = [...productPageTemplate.objects]
            var count: number = 0
            for (let i = objects.length - 1; i >= 1; i--) {

              if (objects[i].name == 'productComponent') {

                if (count <= proRemoveCount) {
                  objects.splice(i, 1)
                  count++
                }
                else {
                  break
                }
              }

            }
            let lastindx = this.catalogPages.length - 1
            this.catalogPages[lastindx].objects = objects //objects[1] ///objects

            break;

          }
          else {
            this.catalogPages.push({ ...productPageTemplate })
          }
        }
      })

    }
    console.log('catpages', this.catalogPages)

    let length = this.catalogPages.length;

    if (length == 0) {
      this.catalogPages.push('');
      this.catalogPages.push('');
    }

    this.catalogPages = this.catalogPages.map((el, i) => {
      return { page: el, flipped: 0, zindex: length - i };

    });

    console.log('catalogPages', this.catalogPages)

    // this.renderPages();

    const promises = [];
    promises.push(this.renderPages())

    Promise.all(promises).then(el => {

        //this.updateCatalog()
    })
  }

  renderPages() {

    let data = [];
    let productIndex = 0;
    let balProsToAdd = 0;
    let maxProsObjects = 0;
    let totalProsAdded = 0


    return new Promise((resolve, reject) => {
      setTimeout(() => {

        this.catalogPages.forEach((val, i) => {


          console.log('val', val)
          setTimeout(() => {
            if (this.childCanvas.toArray()[i]) {

              //  if (this.isFilterMode) {
              
              data = this.catalogueFnService.catalogData.flat();

              

              if (balProsToAdd != 0 && maxProsObjects != 0 && balProsToAdd < maxProsObjects) {

                let objectsToRemoveCount = maxProsObjects - balProsToAdd
                let removedCount = 0

                for (let j = val.page.objects.length - 1; j >= 1; j--) {

                  var obj = val.page.objects[j]

                  if (removedCount < objectsToRemoveCount) {
                    if (obj?.type == 'group' && obj?.name == 'productComponent') {
                      val.page.objects.splice(j, 1)
                      removedCount++
                    }
                  }
                }

              }
              const promise = this.childCanvas.toArray()[i].addPageToCanvas(val.page, 0, productIndex, data);

              promise.then((result) => {
                resolve(true)
              })

              productIndex += this.findProductObjectsCount(val.page);
              totalProsAdded = productIndex
              balProsToAdd = data[0].length - totalProsAdded
              maxProsObjects = this.findProductObjectsCount(val.page);
              //}
              // else {
              //   this.childCanvas.toArray()[i].addPageToCanvas(val.page, 0, productIndex, []);
              // }

              console.log('assocprosAfterfilter', data[0])



              // this.childCanvas.toArray()[i].canvas.setOption('hoverCursor', 'pointer');

              this.childCanvas.toArray()[i].canvas.on('mouse:over', (event: fabric.IEvent,) => {
                this.onCanvasMouseOver(this.childCanvas.toArray()[i].canvas, event, i)
              });

              this.childCanvas.toArray()[i].canvas.on('mouse:up', (event: fabric.IEvent,) => {
                this.onCanvasMouseUp(this.childCanvas.toArray()[i].canvas, event)
              });

              this.childCanvas.toArray()[i].canvas.on('mouse:out', (event: fabric.IEvent,) => {
                this.onCanvasMouseOut(this.childCanvas.toArray()[i].canvas, event)
              });

              this.childCanvas.toArray()[i].canvas.on('mouse:down', (event: fabric.IEvent,) => {
                this.onCanvasMouseDown(this.childCanvas.toArray()[i].canvas, event, i)
              });
            }
          }, 10);

        });
      }, 11);
    })


  }

  findProductObjectsCount(object: CanvasObject) {

    let count = 0;

    if (object && object.objects.length > 0) {


      count = object.objects.filter(val => val.name == "productComponent").length;


    }

    return count;

  }

  getAssociatedProducts(id: number) {

    this.subs.sink = this.catalogueService.getAssociatedProducts(id).subscribe(res => {
      this.associatedProducts = res.data
    })

  }

  findPrvPageProdCount(index) {
    let count: number = 0
    for (let i = 0; i < index; i++) {
      this.childCanvas.toArray()[i].canvas.getObjects().forEach(element => {

        if (element.type == 'group' && element.name == 'productComponent') {
          count++
        }
      });
    }

    return count

  }
  onCanvasMouseOver(canvas, event: any, index: number) {
    const target = canvas.findTarget(event.e);
    this.productIndex = this.findPrvPageProdCount(index) + this.childCanvas.toArray()[index].canvas.getObjects().indexOf(target)
    if (target && target?.type == 'group' && target?.name == "productComponent") {

      this.group = target;


     
      this.groupIndex = this.childCanvas.toArray()[index].canvas.getObjects().indexOf(target)



      this.objects = this.group.getObjects();
      canvas.remove(this.group);
      canvas.add.apply(canvas, this.objects);

      this.objects.forEach(element => {
        element.selectable = false;

        element.setOptions({
          hoverCursor: 'pointer'
        });

        if (element && element?.type == 'rect') {
          //alert('here')
          this.main_rect = element
          this.main_rect_color = element.stroke;
          element.set('stroke', 'gray');
          this.flag = 1
        }
      });
      canvas.renderAll();
    }
    else if (target) {
      target.setOptions({
        hoverCursor: 'pointer'
      });

    }

  }

  onCanvasMouseUp(canvas, event: any) {

    const target = canvas.findTarget(event.e);

    if (target && target?.type == 'group' && target?.name == "button") {

      //target.scale(1 / 1.1);
      // target.scale(this.btnScaleX,this.btnScaleY);

      target.scaleX = this.btnScaleX;
      target.scaleY = this.btnScaleY;

      canvas.renderAll();

    }
  }
  onCanvasMouseOut(canvas, event: any) {

    if (this.main_rect && this.flag == 1) {
      this.main_rect.set('stroke', this.main_rect_color);


      this.objects.forEach(element => {

        canvas.remove(element)
      })
      //canvas.add(this.group);

      canvas.insertAt(this.group, this.groupIndex);
      canvas.renderAll();

      this.flag = 0
    }
  }

  onCanvasMouseDown(canvas, event: any, index: number) {

    const target = canvas.findTarget(event.e);
    if (target && target?.type == 'group' && target?.name == "button") {



      this.btnScaleX = target.scaleX
      this.btnScaleY = target.scaleY


      let scaleX = 1 / this.group.width;
      let scaleY = 1 / this.group.height;

      //setTimeout(() => {
      target.scaleX = 0
      target.scaleY = 0
      canvas.renderAll();
      //}, 100);

      // target.scaleX =  this.btnScaleX 
      // target.scaleY = this.btnScaleY
      // canvas.renderAll();

      setTimeout(() => {
        target.scaleX = this.btnScaleX
        target.scaleY = this.btnScaleY
        canvas.renderAll();
      }, 150);




      //target.scale(1.1);
      //canvas.renderAll();

      this.addToCart(this.productIndex)
    }
    else
    {
      this.openProductDetails(this.productIndex)
    }
  }

  zoomChanged(newValue: number) {



    //let newZoom;
    setTimeout(() => {

      if (this.childCanvas && this.childCanvas.toArray().length) {

        this.childCanvas.toArray().forEach((val) => {
          val.canvas.setZoom(newValue / 100);
          // val.canvas.set({width:val.canvas.getZoom()*val.canvas.width,height:val.canvas.height*val.canvas.getZoom()});

          this.newZoom = val.canvas.getZoom();
          val.canvas.setWidth(this.width * 96 * this.newZoom);
          val.canvas.setHeight(this.height * 96 * this.newZoom);

        });

        this.canvasZoom = Math.round(this.childCanvas.toArray()[0].canvas.getZoom() * 100);
        this.cdr.markForCheck();

      }
    }, 20)


  }
  findPageIndex() {

    let count = 0;

    this.catalogProductCount.forEach((val, i) => {
      count = count + val;
      this.pageIndexStart[i + 1] = count;

    });

  }
  cartClosed() {
    this.showCanvas = false;
  }

  addToCart(index: number) {

    

    let cart_id = sessionStorage.getItem("cartid")

    if (cart_id != this.userAuth.custid+"_"+this.catalogId) {
     
      sessionStorage.setItem("cartid", this.userAuth.custid+"_"+this.catalogId)
    }

    const selectedItem = this.associatedProducts[index]

    console.log('selectedItem', selectedItem)

    if (this.cartItems.some(x => x.unique_id == selectedItem.id)) {
      
      let indx = this.cartItems.findIndex(x=> x.unique_id == selectedItem.id)
      selectedItem.quantity = this.cartItems[indx].quantity + 1
      this.catalogCartService.onChangeCatalogCartItems(selectedItem, 1)
    }
    else {

      let row = new ItemRow();

      row.id = 0,
        row.sales_order = 0,
        row.unique_id = selectedItem.id,
        // created_on:'',
        row.created_user = this.userAuth.custid,
        // modified_on:'',
        // modified_user:'',
        // is_delete: false,
        // is_active:true,
        row.serial_number = '',
        row.quantity = 1,
        // section_title:'',
        row.price = selectedItem.item_price == null ? 0 : selectedItem.item_price,
        row.type = this.KitIemTypes.ITEM,
        row.name = selectedItem.product_name,
        row.customer = this.userAuth.custid,
        row.partnumber = selectedItem.product_part_number,
        // expanded:false,
        // //children
        row.image_link = selectedItem.primary_image
      //}

      this.catalogCartService.onChangeCatalogCartItems(row)

    }




  }

  openProductDetails(productIndx) {
   
    if(productIndx >= 0)
    {

      const modalRef = this.modalService
      .open(ProductOrderPreviewComponent, {
        size: "lg",
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
        windowClass: 'myCustomModalClass',
        
       // centered: true

      });
      var itemDetails:any =  this.associatedProducts[productIndx]
     // if (this.cartItems.some(x => x.id == itemDetails.id)) {

     
      var _quantity;
      if(this.cartItems.length > 0)
      {
        _quantity = this.cartItems.find(x => x.unique_id == itemDetails.id)?.quantity
      }
      if(_quantity)
      {
        itemDetails.quantity = _quantity
      }
      else
      {
        _quantity = 1;
      }
      itemDetails.quantity = _quantity
      modalRef.componentInstance.selectedProductDetails = itemDetails  
      modalRef.componentInstance.catalogId = this.catalogId
      console.log('itemDetails', itemDetails)
    }
    
  }

  next() {
    this.lastFlippedPageIndex = this.lastFlippedPageIndex + 1
    this.catalogPages[this.lastFlippedPageIndex].flipped = 1
    this.lastFlippedIndex = this.lastFlippedPageIndex == 0 ? 1 : this.lastFlippedIndex + 2
  }
  prev() {

    // this.lastFlippedPageIndex = this.isFlippedFromSectionView && this.lastFlippedPageIndex != 0 && this.lastFlippedPageIndex !== 1 ? 
    //                             this.lastFlippedPageIndex - 1 :  this.lastFlippedPageIndex




    this.catalogPages[this.lastFlippedPageIndex].flipped = 0;
    this.lastFlippedPageIndex = this.lastFlippedPageIndex - 1
    this.lastFlippedIndex = this.lastFlippedIndex == 1 ? 0 : this.lastFlippedIndex - 2

    //this.isFlippedFromSectionView = false
  }

  flipFromSectionView(event) {

    const indx = event


    if (indx != this.lastFlippedIndex) {
      let isForward: boolean = indx > this.lastFlippedIndex ? true : false
      var indextoflip
      if (isForward) {

        for (let i = 0; i <= indx; i++) {
          indextoflip = i == 0 ? 0 : i == 1 ? 0 : i % 2 == 0 ? (i / 2) - 1 : (i - 1) / 2
          this.catalogPages[indextoflip].flipped = 1
        }

        console.log('indexToFlip', indextoflip)
      }

      else {

        for (let i = this.lastFlippedIndex; i > indx; i--) {
          indextoflip = i == 0 ? 0 : i == 1 ? 0 : i % 2 == 0 ? i / 2 : (i - 1) / 2
          this.catalogPages[indextoflip].flipped = 0
        }
      }
      this.lastFlippedIndex = indx
      this.lastFlippedPageIndex = indx == 0 ? 0 - 1 : indx - 1 == 0 ? 0 : indx % 2 == 0 ? (indx / 2) - 1 : (indx - 1) / 2

      console.log('lastFlippedIndex', this.lastFlippedIndex)
      console.log('lastFlippedPageIndex', this.lastFlippedPageIndex)
    }
  }

  checkFlipped() {
    return this.selectedPageIndex
  }

  onSectionIndexViewBtnClick() {
    if (this.hideThumbnailView == false) {
      this.hideThumbnailView = true
      this.showSectionThumbnail = false
    }
    this.showSectionTableOfContent = !this.showSectionTableOfContent


    if (this.showSectionTableOfContent == true) {
      this.hideTbIndexView = false
      //this.showTableIndexView()
    }
    else {
      this.hideTbIndexView = true
      //this.hideTableIndexView()
    }
  }

  hideTableIndexView() {
    setTimeout(() => {

      this.hideTbIndexView = true
    }, 1000);
  }

  showTableIndexView() {
    setTimeout(() => {

      this.hideTbIndexView = false
    }, 1000);
  }

  onThumbnailViewBtnClick() {
    if (this.hideTbIndexView == false) {
      this.hideTbIndexView = true
      this.showSectionTableOfContent = false
    }



    this.showSectionThumbnail = !this.showSectionThumbnail
    //this.hideThumbnailSectionView()

    if (this.showSectionThumbnail == true) {
      this.hideThumbnailView = false
      //this.showTableIndexView()
    }
    else {
      this.hideThumbnailView = true
      //this.hideTableIndexView()
    }

  }

  hideThumbnailSectionView() {
    setTimeout(() => {
      this.hideThumbnailView = !this.hideThumbnailView
    }, 1000);
  }



  downloadFile(blob: any, TP) {

    // const byteCharacters = atob(data);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([blob], { type: 'application/pdf' });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(file, this.fileName);

  }

  getCategories() {

    // let filters:GFilterParam[]=[];
    let filters: GFilterParam[] = [{ colname: 'show_on_catelog', condition: 'equalto', value: true, operator: 'AND' }];

    let sort: GSortParam[] = [];
    // let sort:GSortParam[] = [{ colname: 'id', direction: 'desc' }];
    let option = { sort: sort, filters: filters };

    this.categoryService.getAllFiltered(option).subscribe(res => {
      this.categoryList$.next(res.result);
    });

  }

  clearFilterCategories() {
    this.filterAdditionalFields$.next([]);
    this.filterMaterialList$.next([]);
    this.materialData = []
  }

  getFilterAdditionalFields() {

    if (this.filterCategories.length > 0) {
      this.filterCategories.forEach(val => {


        this.additionalFieldService.getCatAdditionalField(val).subscribe(response => {

          if (response && response.data && response.data.data && response.data.data.length) {
            this.filterAdditionalFields$.next([]);
            this.filterMaterialList$.next([])
            let data: catCustomFieldResponse[] = response.data.data;

            this.materialData = response.data.material_list





            const currentValue = this.filterAdditionalFields$.value;
            let updatedValue = currentValue;


            data.forEach((v, i) => {

              let exists = this.filterAdditionalFields$.value.filter(val => val.additional_field_id == v.additional_field_id).length > 0 ? true : false;

              if (!exists && v.additional_field_id && v.additional_field_id > 0) {

                updatedValue = [...updatedValue, v];
              }

            });
            this.filterAdditionalFields$.next(updatedValue);
            this.filterMaterialList$.next(this.materialData)

          }



        })

      });



    }
    else {

      this.filterAdditionalFields$.next([]);

    }

    // this.cdr.markForCheck();


  }

  resetFilter() {

    this.catalogueFnService.catalogData = [];

    this.filterAdditionalFields$.next([]);
    this.showFilter = false;
    this.filterCategories = [];
    this.filterMaterials = []
    this.catalogueFnService.filterMode = false;
    this.resetFlip();
    this.catalogPages=[]

   
    
    this.edit(this.catalogId)
    //this.pageFilterCheck();

    setTimeout(() => {

      //this.next();
      this.cdr.markForCheck();

    }, 50);


  }

  resetFlip() {
    this.lastFlippedPageIndex = -1;
  }

  get isFilterMode() {
    return this.catalogueFnService.filterMode;
  }

  applyFilter() {



    let data: catalogFilterRequest = new catalogFilterRequest();

    data.select_category = this.filterCategories;
    data.select_material = this.filterMaterials;
    data.select_additional = [];



    this.filterAdditionalFields$.value.forEach((val, index) => {

      data.select_additional = data.select_additional.concat(val.selected);
    });

    // this.filterMaterialList$.value.forEach((val,index) => {

    //   data.select_material = data.select_additional.concat(val.selected)

    // })



    data.select_additional = data.select_additional.filter(val => Number(val) > 0);

    this.catalogueService.filterAssociatedProducts(data, this.catalogId).subscribe(res => {

      console.log('filterassocpros', res)
      this.catalogueFnService.catalogData[0] = res.data;

      this.cdr.markForCheck();

      this.catalogueFnService.filterMode = true;


      this.pageFilterCheck();


      this.resetFlip();


      setTimeout(() => {

        this.next();
        this.cdr.markForCheck();

      }, 50);

    });




  }

  get catalogProducts() {
    // return [];
    return this.catalogueFnService.catalogData[0] ? this.catalogueFnService.catalogData[0] : [];
  }



  canShowCanvasFront(i) {
    // return (!this.isFilterMode&&this.catalogPages[i*2].page!=''&&this.catalogPages.length-1!=i*2)||i==0||(this.isFilterMode&&this.catalogProducts.length>0);
    // return (!this.isFilterMode&& this.catalogPages.length < i&&this.catalogPages.length-1!=i*2)||i==0||(this.isFilterMode&&this.catalogProducts.length>0);
    //return true
    if (this.isFilterMode && this.catalogPages[0].page == '') {
      return false
    }
    else if (this.catalogPages.length % 2 == 0) {
      return i <= this.catalogPages.length
    }
    else {
      return i < this.catalogPages.length
    }

  }

  canShowCanvasBack(i, item) {
    // return (!this.isFilterMode&&this.catalogPages.length!=(i+1)*2&&item.page!='')||(this.isFilterMode&&this.catalogProducts.length>0)
    // return (!this.isFilterMode&&this.catalogPages.length > i)||(this.isFilterMode&&this.catalogProducts.length>0)
    //  return (!this.isFilterMode&&this.catalogPages.length > i)
    //  return true


    //new

    if (this.isFilterMode && this.catalogPages[0].page == '') {
      return false
    }
    else if (this.catalogPages.length % 2 == 0) {
      return i < this.catalogPages.length
    }
    else {
      return i <= this.catalogPages.length
    }

    return true
  }

  isNoProductFoundPage(i, front = false) {

    // if (front && i == 0) {
    //   return false;
    // }
    // else {
    return this.isFilterMode && this.catalogProducts.length == 0;

    // }
  }

  isBlankPage(i, front = false) {
    if (front) {
      return !this.isFilterMode && i != 0 && this.catalogPages[(i * 2)].page == '' && this.catalogPages.length - 1 == i * 2 + 1;
    }
    else {
      return !this.isFilterMode && this.catalogPages[((i + 1) * 2)].page == '' && this.catalogPages.length - 1 == (i + 1) * 2;
    }

  }

  isEndPage(i, front = false) {

    // return !this.isFilterMode&&this.catalogPages[((i+1)*2)-1].page==''&&this.catalogPages.length==(i+1)*2

    return true

  }

  findValueName(values:catCustomFieldValueList[], id:number){

    if(values){

      let filtered=[];
      filtered=values.filter(val=>val.id==id);
  
      if(filtered.length>0){
        return filtered[0].field_value;
      }
      else{
        return "";
      }

    }


  }

  ngOnDestroy(){

    this.subs.unsubscribe();

  }


  openFullscreen() {
    // Use this.divRef.nativeElement here to request fullscreen
    const elem = this.divRef.nativeElement;
  
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }


}
