import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'tags-input',
    templateUrl: './tags-input.component.html',
    styleUrls: ['./tags-input.component.scss']
})
export class TagsInputComponent implements OnInit {

    @Input('mask') maskPattern: string;

    private innerValue: any;

    get value() { return this.innerValue; }

    @Input() set value(val: any) {
        if (val != this.innerValue) {
            this.innerValue = val;
            this.setInputValue(val)
        }
    };

    @Output() valueChange = new EventEmitter<any>();

    @Input() placeholder: string;

    @Input() stringValue: boolean = false;

    @Input() maxLength: number = 10;

    @Input() insertOnLengthMatch: boolean = false;

    public inputValues: string[] = [];

    public inputBinding: string = '';

    public count: number=0 ;

    constructor() { }

    ngOnInit(): void { }

    setInputValue(val: any) {
        if (Array.isArray(val)) {
            this.inputValues = val;
        }
        else if (typeof val === 'string') {
            this.inputValues = val.split(',');
        }
    }

    submit($event: any): void {
        $event.preventDefault();
        this.appendTag();
    }

    appendTag() {
        if (!this.inputValues.includes(this.inputBinding)) {
            this.inputValues.push(this.inputBinding);
            this.inputBinding = null;
            this.bindResult();
            this.count++
            console.log(this.count)
        }
    }

    removeValue(value: string) {
        this.inputValues = this.inputValues.filter(val => val !== value);
        this.count--
        this.bindResult();
    }

    bindResult() {
        this.innerValue = this.stringValue ? this.inputValues.join(',') : [...this.inputValues];
        this.valueChange.emit(this.innerValue);
    }

    modelValueChanged(value: string) {
        if (value && this.insertOnLengthMatch) {
            if (value.length === this.maxLength) {
                this.appendTag();
            }
        }
    }
}
