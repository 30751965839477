import { UserWrapperFlat } from './../../models/user-wrapper';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';

@Component({
  selector: 'user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {

  @Input() value: any;

  @Input() readonly: boolean = false;

  @Input() placeholder: string;

  @Input() multiple: boolean = false;

  @Input() resetForCallcenter: boolean = false;

  @Output() valueChange = new EventEmitter<any>();

  @Output() change = new EventEmitter<any>();

  public selected: any;

  public options: Observable<UserWrapperFlat[]>;

  constructor(private userService: UserService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.options = this.userService.getUsersForDropdown();
    this.placeholder = this.multiple ? 'Select Users' : 'Select User';
  }

  onValueChanged(value: any) {
    if (this.multiple) {
      this.value = value ? value.map((item: User) => item.id) : null;
      this.valueChange.emit(this.value);
    }
    else {
      this.value = value ? value.id : null;
      this.valueChange.emit(this.value);
    }
    this.change?.emit(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      this.selected = changes.value.currentValue;
      this.cdr.markForCheck();
    }else{
      if(this.resetForCallcenter){
      this.selected = null;
      this.cdr.markForCheck();
      }
    }
  }
}
