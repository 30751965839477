<ngx-datatable class="bootstrap core-bootstrap" [scrollbarH]="scrollbarH" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" rowHeight="auto">
    <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
    <ngx-datatable-column name="Description" prop="description"></ngx-datatable-column>
    <ngx-datatable-column name="Grid Name" prop="resource.name"></ngx-datatable-column>
    <ngx-datatable-column name="Action" prop="id" [sortable]="false" [width]="50">
        <ng-template let-id="value" let-row="row" ngx-datatable-cell-template>
            <button class="delete-btn text-danger" (click)="deleteView2Role(id)">
                <i class="ft-trash-2"></i>
            </button>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer></ngx-datatable-footer>
</ngx-datatable>

<ng-template #content let-modal>
    <div class="modal-header border-0">
        <h5 class="modal-title" id="modal-basic-title">Add Grid View</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form #formElement="ngForm" (ngSubmit)="viewSelect.hasValue && !viewExist && onSubmit(viewSelect.getValue())">
            <div class="form-group">
                <label>Role</label>
                <input type="text" [value]="roleName" class="form-control" readonly>
            </div>
            <div class="form-group">
                <label>View</label>
                <app-grid-view-select (onChange)="viewSelectOnChange($event)" #viewSelect></app-grid-view-select>
                <small *ngIf="viewExist" class="danger">
                    View already exist 
                </small>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0">
        <button type="button" [disabled]="viewExist" class="btn gradient-pomegranate" (click)="formElement.onSubmit(undefined)">Save</button>
    </div>
</ng-template>