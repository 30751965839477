import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { LicenseNumber } from '../../../models/license-number';
import { LicenseNumberService } from '../../../services/license-number.service';

@Component({
  selector: 'license-number-form',
  templateUrl: './license-number-form.component.html',
  styleUrls: ['./license-number-form.component.scss']
})
export class LicenseNumberFormComponent implements OnInit, OnDestroy {

  public model: LicenseNumber = new LicenseNumber();

  @Input() LicenseNumberId: number = 0;

  @Output() success = new EventEmitter<{ response: LicenseNumber, message: string }>();

  @Output() error = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  public nameExist: boolean = false;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private service: LicenseNumberService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.LicenseNumberId > 0) {
      this.getSub$ = this.service.getById(this.LicenseNumberId).subscribe(LicenseNumber => {
        this.model = LicenseNumber;
      });
    }
  }

  onSubmit(): void {

    let api: Observable<LicenseNumber>;

    if (this.LicenseNumberId > 0)
      api = this.service.update(this.LicenseNumberId, this.model);
    else {
      this.model.customer = this.service.customerId;
      this.model.suborg = this.service.suborgId;
      api = this.service.create(this.model);
    }

    this.postSub$ = api.subscribe(
      response => {
        let msg = this.LicenseNumberId > 0 ? 'License Number info updated' : 'New License Number created';
        this.success?.emit({ response: response, message: msg });
      },
      error => {
        if (error.error && error.error["non_field_errors"]) {
          this.nameExist = true;
          this.cdr.markForCheck();
        }
        else {
          this.error?.emit(error);
        }
      }
    );
  }

  onCancelClicked(): void {
    this.cancel?.emit();
  }

  ngOnDestroy(): void {
    this.getSub$?.unsubscribe();
    this.postSub$?.unsubscribe();
  }
}
