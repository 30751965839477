import { SupplierSelectComponent } from '../../select/supplier-select.component';
import { Subscription } from 'rxjs';
import { SupplierService } from './../../../services/supplier.service';
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { ItemSupplierMap, Supplier } from '../../../models/supplier';

@Component({
  selector: 'app-item-supplier-form',
  templateUrl: './item-supplier-form.component.html',
  styleUrls: ['./item-supplier-form.component.scss']
})
export class ItemSupplierFormComponent implements OnInit, OnDestroy {

  @ViewChild('supplierSelect') supplierSelect: SupplierSelectComponent;

  @Input() itemSMapId: number = 0;

  @Input() itemId: number = 0;

  @Input() currentValues: number[];

  @Output() onSuccess = new EventEmitter<{ response: ItemSupplierMap, message: string }>();

  @Output() onError = new EventEmitter<any>();

  @Output() onCancel = new EventEmitter<any>();

  public model: ItemSupplierMap = new ItemSupplierMap();

  public mapExist: boolean = false;

  private oldSupplierId: number;

  private getSub$: Subscription;

  private postSub$: Subscription;

  constructor(private supplierService: SupplierService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.currentValues = this.currentValues ?? [];
    if (this.itemSMapId > 0) {
      this.getSub$ = this.supplierService.getItemSupplierMapById(this.itemSMapId).subscribe(map => {
        this.model = map;
        this.oldSupplierId = this.model.supplier;
        this.cdr.markForCheck();
      });
    }
    else {
      this.oldSupplierId = 0;
      this.model.customer = this.supplierService.customerId;
      this.model.items = this.itemId;
    }
  }

  onItemSMapSubmit(): void {
    if (this.itemSMapId > 0)
      this.update();
    else
      this.create();
  }

  create() {
    this.postSub$ = this.supplierService.createItemSupplierMap(this.model).subscribe(
      map => {
        map.supplier_name = this.supplierSelect?.getSelected()?.supplier_name;
        this.onSuccess?.emit({ response: map, message: "New Supplier added" });
      },
      error => this.onError?.emit(error)
    );
  }

  update() {
    this.postSub$ = this.supplierService.updateItemSupplierMap(this.itemSMapId, this.model).subscribe(
      map => {
        map.supplier_name = this.supplierSelect?.getSelected()?.supplier_name;
        this.onSuccess?.emit({ response: map, message: "Supplier info updated" });
      },
      error => this.onError?.emit(error)
    );
  }

  onSupplierChange(supplier: Supplier) {
    if (this.oldSupplierId === supplier.id) {
      this.mapExist = false;
      return;
    }
    this.mapExist = this.currentValues.includes(supplier.id);
  }

  onSupplierClear() {
    this.mapExist = false;
  }

  onCancelClicked() {
    this.onCancel?.emit();
  }

  ngOnDestroy(): void {
    this.postSub$?.unsubscribe();
    this.getSub$?.unsubscribe();
  }
}
