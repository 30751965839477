import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { GridMetaColumn, GridMeta, GridMetaInfo } from '../models/grid-profiling.models';
@Injectable({
  providedIn: 'root'
})
export class GridMetaService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll() {
    return this.http.get<{ gridresources: GridMeta[] }>(`${this.baseURL}/grid/meta/`);
  }

  getById(metaid: number) {
    return this.http.get<GridMetaInfo>(`${this.baseURL}/grid/meta/${metaid}/`);
  }

  create(meta: GridMeta) {
    return this.http.post<GridMeta>(`${this.baseURL}/grid/meta/`, meta);
  }

  update(id: number, meta: GridMetaInfo) {
    return this.http.put<GridMetaInfo>(`${this.baseURL}/grid/meta/${id}/`, meta);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/grid/meta/${id}/`);
  }

  getMetaColumns(metaid: number) {
    return this.http.get<{ gridcolumns: GridMetaColumn[] }>(`${this.baseURL}/grid/meta/${metaid}/columns/`);
  }

  updateGridMetaColumns(id: number, columns: string[]) {
    return this.http.post<GridMeta>(`${this.baseURL}/grid/meta/${id}/columns/`, columns);
  }

  deleteGridMetaColumns(id: number, ids: number[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: ids
    };
    return this.http.delete(`${this.baseURL}/grid/meta/${id}/columns/`, options);
  }
}
