import { STSubTask, TimeSheet } from './../models/common.models';
import { ServiceTicket, STDashBoardData, STComment, Email, Section, ItemRow, ItemAdd, STPdf, StCallHistory } from './../models/service-ticket';
import { BaseService } from 'src/app/core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StaticFile } from 'src/app/core/models/files.models';

@Injectable({
  providedIn: 'root'
})
export class ServiceTicketService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getAll(option: any, offset: number = 0) {
    let url = `${this.baseURL}/filter/service_ticket/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<{ count: number, result: ServiceTicket[] }>(url, option).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  getById(id: number) {
    return this.http.get<ServiceTicket>(`${this.baseURL}/service_ticket/${id}/`);
  }

  create(model: ServiceTicket) {
    return this.http.post<ServiceTicket>(`${this.baseURL}/service_ticket/`, model);
  }

  update(id: number, model: ServiceTicket) {
    return this.http.put<ServiceTicket>(`${this.baseURL}/service_ticket/${id}/`, model);
  }

  patch(id: number, req: any) {
    return this.http.patch<ServiceTicket>(`${this.baseURL}/service_ticket/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/service_ticket/${id}/`);
  }

    getSTImages(itemId: number) {
        return this.http.get<StaticFile[]>(`${this.baseURL}/service_ticket/${itemId}/images/`);
    }

    sendMail(email: Email, pdf: STPdf){
        let temp_email = JSON.parse(JSON.stringify(email));
        let url = `${this.baseURL}/service_ticket/sendmail/`;
        return this.http.post(url, {"email": temp_email, "item":pdf.item, "sections": pdf.sections, "time_sheets": pdf.timesheets, "sub_tasks": pdf.subtasks});
    }

    generate_pdf(pdf: STPdf){
        let url = `${this.baseURL}/service_ticket/generate_pdf/`;
        return this.http.post(url, {"item":pdf.item, "sections": pdf.sections, "time_sheets": pdf.timesheets, "sub_tasks": pdf.subtasks}, { responseType: 'blob' });
    }

  getDashBoardData(): Observable<STDashBoardData> {
    return this.http.get<any>(`${this.baseURL}/service_ticket_dashboard/${this.customerId}/`).pipe(
      map(resp => {
        return {
          new_tickets: resp.New,
          in_progress: resp.In_Progress,
          waiting: resp.Waiting,
          escalated_to_manager: resp.Escalated_To_Manager,
          closed: resp.Closed,
          un_billed: resp.un_billed,
          not_billable: resp.not_billable,
          partially_invoiced:resp.partially_invoiced,
          fully_invoiced:resp.fully_invoiced
        }
      })
    );
  }

    itemKitList(id:number){
        let url = `${this.baseURL}/service_ticket/${id}/materials/`;
        return this.http.get<ItemRow[]>(url);
    }

    itemKitAdd(data:ItemAdd[]){
        let url = `${this.baseURL}/service_ticket/materials/`;
        return this.http.post<{ data }>(url, data);
    }

    itemKitUpdate(data:ItemAdd[]){
        let url = `${this.baseURL}/service_ticket/materials/detail/`;
        return this.http.put<{ data }>(url, data);
    }

    itemKitDelete(id: number) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: id,
            },
        };
        let url = `${this.baseURL}/service_ticket/materials/detail/`;
        return this.http.delete<{ data }>(url, options);
    }

  createComment(comments: STComment[]) {
    return this.http.post<STComment[]>(`${this.baseURL}/create_st_comments/`, comments);
  }

  updateComment(id: number, comment: STComment) {
    return this.http.put(`${this.baseURL}/st_comments/${id}/`, comment);
  }

  getComments(ticketId: number) {
    return this.http.get<STComment[]>(`${this.baseURL}/get_st_comments/${ticketId}/`);
  }

  deleteComment(id: number) {
    return this.http.delete(`${this.baseURL}/st_comments/${id}/`);
  }

  createTimeSheet(timesheet: TimeSheet) {
    return this.http.post<TimeSheet>(`${this.baseURL}/create_time_sheet/`, timesheet);
  }

  updateTimeSheet(id: number, timesheet: TimeSheet) {
    return this.http.put(`${this.baseURL}/time_sheet/${id}/`, timesheet);
  }

  getTimeSheets(ticketId: number) {
    return this.http.get<TimeSheet[]>(`${this.baseURL}/get_time_sheet/${ticketId}/`);
  }

  getTimeSheetById(id: number) {
    return this.http.get<TimeSheet>(`${this.baseURL}/time_sheet/${id}/`);
  }

  getTimesheetsFiltered(option: any, offset: number = 0) {
    let url = `${this.baseURL}/filter/timesheet/?limit=${this.dataLimit}&offset=${offset}`;
    return this.http.post<{ count: number, result: TimeSheet[] }>(url, option).pipe(
      catchError(() => of({ count: 0, result: [] }))
    );
  }

  deleteTimeSheet(id: number) {
    return this.http.delete(`${this.baseURL}/time_sheet/${id}/`);
  }

  getTimesheetDashlet() {
    return this.http.get<{
      Billable: number,
      Invoiced: number,
      Non_billable: number,
      Overdue: number,
      Paid: number,
      Un_billed: number
    }>(`${this.baseURL}/billing_status/${this.customerId}/`);
  }

    convertToInvoice(unique_number: string){
        let url = `${this.baseURL}/service_ticket_to_invoice/`;
        return this.http.post<{"item": number}>(url, {"unique_number": unique_number});
    }

    getStCallHistory(id:number) {
      return this.http.get<{call_history:StCallHistory}>(`${this.baseURL}/service_ticket/call_history/${id}/`);
    }
}
