<div >
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="form-group">
                <span class="infomsg"> A message TO CALLER. For example: Thank you for calling, Our offices are currently closed. 
                    Our business hours are from Monday to Friday 9 AM to 8 PM Eastern Time.
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label class="val-required">ANNOUNCE voice</label>
                <voice-select [(value)]="model.announce_gender"></voice-select>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label class="val-required">ANNOUNCE MESSAGE</label>
                <input type="text" name="announce_message_inside_TF" class="form-control"
                       [(ngModel)]="model.announce_message" required>
            </div>
        </div>
    </div>
</div>
