import { SubSink } from 'subsink';
import { WareHouseLocation } from './../../models/warehouse.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarehouseService } from './../../services/warehouse.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ResourceConstants } from 'src/app/core/constants/resources.constants';
import { ResourceAccessService } from 'src/app/core/services/resource-access.service';
import { WareHouse } from '../../models/warehouse.models';
import * as popup from 'src/app/core/utils/popup.functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UIPermission } from 'src/app/core/models/common.models';

@Component({
    selector: 'app-warehouse',
    templateUrl: './warehouse.component.html',
    styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @ViewChild('searchInput') searchInput: ElementRef;

    public SelectionType = SelectionType;

    public rows: WareHouse[];

    public rowsFlat: WareHouse[];

    public locations: WareHouseLocation[];

    public wareHouseId: number = 0;

    public locationId: number = 0;

    public model: WareHouse;

    public locationModel: WareHouseLocation;

    public expanded: boolean = true;

    private _selected: WareHouse;

    public get selected() { return this._selected; }

    public set selected(value: WareHouse) {
        if (this._selected !== value) {
            this._selected = value;
            if (value) {
                this.getWareHouseLocations(value.id);
            }
        }
    }

    public locationPage = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

    private subs = new SubSink();

    public scrollbarH: boolean = false;

    public nameExist: boolean = false;

    public isAllSuborg: boolean;

    public UIPermissions: UIPermission;

    constructor(private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private warehouseService: WarehouseService,
        private toastr: ToastrService,
        private ra: ResourceAccessService,
        private device: DeviceDetectorService) {

        this.isAllSuborg = this.warehouseService.isAllSuborg;
        this.UIPermissions = this.ra.getUIPermissions(ResourceConstants.INV_WAREHOUSE, ResourceConstants.INV_MODULE);
    }

    ngOnInit() {

        this.scrollbarH = this.device.isMobile();

        this.subs.sink = this.warehouseService.getAll().subscribe(response => {
            this.rows = response;
            this.rowsFlat = response;
            this.cdr.markForCheck();
        });
    }

    ngAfterViewInit(): void { this.searchInput?.nativeElement.focus(); }

    search(event: any) {
        const val = event.target.value?.toLowerCase();
        this.rows = this.rowsFlat.filter(function (d) {
            return d.warehouse_name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.table.offset = 0;
    }

    onSelect({ selected }) {
        let current: WareHouse = selected[0];
        if (current && this.UIPermissions.viewInfo) {
            this.selected = current;
            this.wareHouseId = current.id;
            this.expanded = false;
            this.rows = this.rows.concat([]);
        }
    }

    deleteWarehouse(id: number) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.warehouseService.delete(id).subscribe(
                    () => {
                        this.rows = this.rowsFlat.filter(x => x.id !== id);
                        this.closeDetailedView();
                        this.cdr.markForCheck();
                        popup.CompleteDelete('Warehouse has been deleted.');
                    },
                    () => this.toastr.error('Unable to delete row')
                );
            }
        });
    }

    openWareHouseModal(content: any, id: number = 0) {
        this.wareHouseId = id;
        this.model = id > 0 ? { ...this.rows.find(w => w.id === id) } : new WareHouse();
        this.modalService.open(content, { centered: true });
    }

    openLocationModal(content: any, id: number = 0) {
        if (this.isAllSuborg) return;
        this.locationId = id;
        this.locationModel = id > 0 ? { ...this.locations.find(l => l.id === id) } : new WareHouseLocation();
        this.modalService.open(content, { centered: true });
    }

    onSubmit() {
        let api: Observable<WareHouse>;
        if (this.wareHouseId > 0) {
            api = this.warehouseService.update(this.wareHouseId, this.model);
        }
        else {
            this.model.customer = this.warehouseService.customerId;
            this.model.suborg = this.warehouseService.suborgId;
            api = this.warehouseService.create(this.model);
        }

        this.subs.sink = api.subscribe(
            warehouse => {
                this.nameExist = false;
                this.rows = [...this.rows.filter(w => w.id !== this.wareHouseId), warehouse];
                this.toastr.success(this.wareHouseId > 0 ? 'Warehouse info updated' : "New warehouse created");
                this.selected = warehouse;
                this.wareHouseId = warehouse.id;
                this.expanded = false;
                this.cdr.markForCheck();
                this.modalService.dismissAll();
            },
            error => {
                if (error.error && error.error["non_field_errors"]) {
                    this.nameExist = true;
                    this.cdr.markForCheck();
                }
                else {
                    this.toastr.error("Failed to create warehouse");
                    this.modalService.dismissAll();
                }
            }
        );
    }

    getWareHouseLocations(warehouseid: number) {
        this.subs.sink = this.warehouseService.getWareHouseLocations(warehouseid).subscribe(resp => {
            this.locations = resp;
            this.cdr.markForCheck();
        });
    }

    closeDetailedView() {
        this.expanded = true;
        this.selected = null;
        setTimeout(() => {
            this.rows = this.rows.concat([]);
            this.cdr.markForCheck();
        });
    }

    onLocationSubmit() {
        let api: Observable<WareHouseLocation>;
        if (this.locationId > 0) {
            api = this.warehouseService.updateWareHouseLocation(this.locationId, this.locationModel);
        }
        else {
            this.locationModel.customer = this.warehouseService.customerId;
            this.locationModel.suborg = this.warehouseService.suborgId;
            this.locationModel.warehouse = this.wareHouseId;
            api = this.warehouseService.createWareHouseLocation(this.locationModel);
        }

        this.subs.sink = api.subscribe(
            result => {
                this.locations = [...this.locations.filter(w => w.id !== this.locationId), result];
                this.toastr.success(this.locationId > 0 ? 'Location Info updated' : "New location added");
                this.cdr.markForCheck();
                this.modalService.dismissAll();
            },
            () => {
                this.toastr.error("Operation failed!");
                this.modalService.dismissAll();
            }
        );
    }

    deleteWarehouseLocation(id: number) {
        popup.ConfirmDelete(result => {
            if (result.isConfirmed) {
                this.subs.sink = this.warehouseService.deleteWareHouseLocation(id).subscribe(
                    () => {
                        this.locations = this.locations.filter(x => x.id !== id);
                        this.cdr.markForCheck();
                        popup.CompleteDelete('Location has been deleted.');
                    },
                    () => this.toastr.error('Unable to delete location')
                );
            }
        });
    }

    setLocationsPage(pageInfo: any) {
        let offset = pageInfo.offset * this.locationPage.limit;
        this.locationPage.offset = pageInfo.offset;
    }

    setLocationPagination(offset: number, total: number) {
        this.locationPage.count = total;
        let upperLimit = offset + this.locationPage.limit;
        if (upperLimit > total) {
            upperLimit = total;
        }
        this.locationPage.pageNumber = offset + '-' + upperLimit;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
