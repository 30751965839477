import { NgModule } from '@angular/core';
import { DidsComponent } from './pages/dids/dids.component';
import { ForwardNumbersComponent } from './pages/forward-numbers/forward-numbers.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { Ring2VoiceRoutingModule } from './ring2voice-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { CallReportsComponent } from './pages/reports/call-reports/call-reports.component';
import { DidReportsComponent } from './pages/reports/did-reports/did-reports.component';
import { ScheduleComponent } from './pages/reports/schedule/schedule.component';
import { AddComponent } from './pages/forward-numbers/add/add.component';
import { CustomerModule } from '../customer/customer.module';
import { AreaCodeSelectComponent } from './components/area-code-select/area-code-select.component';
import { DidPurchaseComponent } from './pages/did-purchase/did-purchase.component';
import { UserModule } from '../user/user.module';
import { SuborgModule } from '../suborg/suborg.module';
import { ForwardBinSelectComponent } from './components/forward-bin-select/forward-bin-select.component';
import { ChatComponent } from './pages/chat/chat.component';
import { RolesModule } from '../roles/roles.module';
import { ForwardBinDidsComponent } from './components/forward-bin-dids/forward-bin-dids.component';
import { VoiceSelectComponent } from './components/voice-select/voice-select.component';
import { ForwardBinFormComponent } from './components/forward-bin-form/forward-bin-form.component';
import { SipEndpointsComponent } from './pages/sip-endpoints/sip-endpoints.component';
import { AddSipComponent } from './pages/add-sip/add-sip.component';
import { DidSelectComponent } from './components/did-select/did-select.component';
import { SipSelectComponent } from './components/sip-select/sip-select.component';
import { CallCenterComponent } from '../ring2voice/pages/call-center/call-center.component';
import { CallCenterInfoComponent } from './components/call-center-info/call-center-info.component';
import { BlockListComponent } from './pages/block-list/block-list.component';
import { TenantCustomersModule } from '../tenant-customers/tenant-customers.module';
import { ServiceTicketModule } from '../service-ticket/service-ticket.module';
import { InventoryModule } from '../inventory/inventory.module';
import { SalesModule } from '../sales/sales.module';
import { CallCenterQuickContactFormComponent } from './components/call-center-quick-contact-form/call-center-quick-contact-form.component';
import { AutomatedBlockListComponent } from './pages/automated-block-list/automated-block-list.component';
import { CallCenterDialerComponent } from './components/call-center-dialer/call-center-dialer.component';
import { OutboundCallCenterInfoComponent } from './components/outbound-call-center-info/outbound-call-center-info.component';
import { AcceptedCallsInfoComponent } from './components/accepted-calls-info/accepted-calls-info.component';
import { IncomingCallsInfoComponent } from './components/incoming-calls-info/incoming-calls-info.component';
import { TimeFramesComponent } from './pages/time-frames/time-frames.component';
import { AddTimeFrameComponent } from './pages/add-time-frame/add-time-frame.component';
import { CurrentCallListPanelComponent } from './components/current-call-list-panel/current-call-list-panel.component';
import { ContactNumberSearchComponent } from './components/contact-number-search/contact-number-search.component';
import { DidsBulkUpdateComponent } from './pages/dids/dids-bulk-update/dids-bulk-update.component';
import { TimeFrameSelectComponent } from './components/time-frame-select/time-frame-select.component';
import { AddTfComponent } from './pages/time-frames/add-tf/add-tf/add-tf.component';
import { AutomatedAllowListComponent } from './pages/automated-allow-list/automated-allow-list.component';
import { TenantCustomerInfoModule } from '../tenant-customer-info/tenant-customer-info.module';
import { CustomerInfoModule } from '../customer-info/customer-info.module';
import { OutboundCallReportsComponent } from './pages/reports/outbound-call-reports/outbound-call-reports.component';
import { OutboundKeypadComponent } from './components/outbound-keypad/outbound-keypad.component';
//import { ServiceTicketSharedModule } from '../service-ticket-shared/service-ticket-shared.module';
import { ServiceTicketSelectModule } from '../service-ticket-select/service-ticket-select.module';
import { SalesSharedModule } from '../sales-shared/sales-shared.module';
import { AnnouncementSettingsComponent } from './components/advance-settings-blocks/announcement-block/announcement-settings/announcement-settings.component';
import { RingSettingsComponent } from './components/advance-settings-blocks/ring-block/ring-settings/ring-settings.component';
import { VoicemailSettingsComponent } from './components/advance-settings-blocks/voicemail-block/voicemail-settings/voicemail-settings.component';
import { MenuSettingsComponent } from './components/advance-settings-blocks/menu-settings-block/menu-settings/menu-settings.component';
import { CallreportDashboardComponent } from './components/callreport-dashboard/callreport-dashboard.component';
import { callCenterDragExpandDirective } from './directive/drag-expand.directive';
import { DidsBulkDeleteComponent } from './pages/dids/dids-bulk-delete/dids-bulk-delete.component';
import { AgentStatusLogComponent } from './components/agent-status-log/agent-status-log.component';
import { OutboundCallComponent } from './pages/outbound-call/outbound-call.component';
import { CallcenterAgentComponent } from './components/callcenter-agent/callcenter-agent.component';
import { AcceptedCallOutboundComponent } from './components/accepted-call-outbound/accepted-call-outbound.component';
import { CallHistoryViewDetailsComponent } from './components/call-history-view-details/call-history-view-details.component';
//import { ServiceTicketSharedModule } from '../service-ticket-shared/service-ticket-shared.module';


@NgModule({
    declarations: [
        DidsComponent,
        ForwardNumbersComponent,
        ReportsComponent,
        CallReportsComponent,
        DidReportsComponent,
        ScheduleComponent,
        AddComponent,
        DidsComponent,
        ForwardNumbersComponent,
        ReportsComponent,
        CallReportsComponent,
        DidReportsComponent,
        ScheduleComponent,
        AddComponent,
        AreaCodeSelectComponent,
        DidPurchaseComponent,
        ForwardBinSelectComponent,
        ChatComponent,
        ForwardBinDidsComponent,
        VoiceSelectComponent,
        ForwardBinFormComponent,
        SipEndpointsComponent,
        AddSipComponent,
        DidSelectComponent,
        SipSelectComponent,
        CallCenterComponent,
        BlockListComponent,
        CallCenterQuickContactFormComponent,
        AutomatedBlockListComponent,
        AcceptedCallsInfoComponent,
        IncomingCallsInfoComponent,
        TimeFramesComponent,
        AddTimeFrameComponent,
        CallCenterDialerComponent,
        OutboundCallCenterInfoComponent,
        CallCenterInfoComponent,
        DidsBulkUpdateComponent,
        CurrentCallListPanelComponent,
        TimeFrameSelectComponent,
        AddTfComponent,
        AutomatedAllowListComponent,
        ContactNumberSearchComponent,
        OutboundCallReportsComponent,
        OutboundKeypadComponent,
        AnnouncementSettingsComponent,
        RingSettingsComponent,
        VoicemailSettingsComponent,
        MenuSettingsComponent,
        CallreportDashboardComponent,
        callCenterDragExpandDirective,
        DidsBulkDeleteComponent,
        AgentStatusLogComponent,
        OutboundCallComponent,
        CallcenterAgentComponent,
        AcceptedCallOutboundComponent,
        CallHistoryViewDetailsComponent
    ],
    exports: [
        CallCenterComponent,
        DidSelectComponent,
        CallCenterDialerComponent,
        CallcenterAgentComponent

    ],
    imports: [
        NgbModule,
        NgbNavModule,
        NgSelectModule,
        Ring2VoiceRoutingModule,
        NgxDatatableModule,
        SharedModule,
        CustomerModule,
        UserModule,
        SuborgModule,
        RolesModule,
        TenantCustomersModule,
        ServiceTicketModule,
        InventoryModule,
        SalesModule,
        TenantCustomerInfoModule,
        CustomerInfoModule,
        ServiceTicketSelectModule,
        SalesSharedModule
    ]
})
export class Ring2VoiceModule { }
