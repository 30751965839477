<section id="item-list">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-flex flex-space-bw">
                    <h4 class="card-title">Materials</h4>
                    <div class="d-flex flex-row">
                        <button *ngIf="UIPermissions.create" (click)="openModal()" class="btn btn-sm gradient-pomegranate mr-1" disableIfAllsuborg>
                            <i class="ft-plus mr-1"></i> New
                        </button>
                        <input #searchInput class="form-control form-control-sm width-200" type="text" placeholder="Search product lines..." (keyup)="search($event)" />
                    </div>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <ngx-datatable  [scrollbarH]="scrollbarH" class="bootstrap core-bootstrap" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [rows]="rows" [selectionType]="SelectionType.single" [sorts]="[{prop:'name',dir:'asc'}]" (select)="onSelect($event)">
                            <ngx-datatable-column name="Name" prop="name" [sortable]="true"></ngx-datatable-column>
                            <ngx-datatable-column name="Description" prop="description" [sortable]="true"></ngx-datatable-column>
                            <ngx-datatable-column name="Created Date" prop="created_on" [sortable]="true" [width]="100">
                                <ng-template let-crdate="value" ngx-datatable-cell-template>
                                    {{crdate | date:'MM/dd/YYYY'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Created By" prop="created_by" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="isAllSuborg" name="Suborg" prop="suborg_name" [sortable]="false"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="!isAllSuborg" name="Action" prop="id" [sortable]="false" [width]="100">
                                <ng-template let-id="value" let-current="row" ngx-datatable-cell-template>
                                    <div ngbDropdown container="body">
                                        <button class="dt-dropdown-btn cursor-pointer mr-2 hide-pseudo-after" (click)="$event.stopPropagation()" ngbDropdownToggle>
                                            <i class="ft-more-vertical text-primary"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button class="w-100" *ngIf="UIPermissions.edit" (click)="onSelect({selected:[current]})" ngbDropdownItem>
                                                <i class="ft-edit mr-1"></i> Edit
                                            </button>
                                            <button class="w-100 bg-light-danger" *ngIf="UIPermissions.delete" (click)="deleteMaterial(id)" ngbDropdownItem>
                                                <i class="ft-trash-2 mr-1"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #materialsModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{materialId > 0 ? 'Edit Material' : 'New Material'}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <material-form
                [materialId]="materialId"
                (onSuccess)="onDataSubmittedSuccess($event)"
                (onError)="onDataError($event)"
                (onCancel)="onCancel()">
            </material-form>
        </div>
    </ng-template>
</section>
