<div class="container-fluid g-0">
    <main>
        <catalogue-subnav #subNav class="p-2" (sideNavToggled)="sideNavStatus=$event;updateZoom();"
            [sideNavStatus]="sideNavStatus"  [isButton] = "isButton" (addElement)="addElement($event)"
            (addElementGroup)="addElementGroup($event)" (addBigText)="addBigText($event)"
            (emitPageToCanvas)="addPageToCanvas($event)" [settings]="subNavSettings" (dragItem) = "onDragObject($event)"
            (imageToCanvas)="addImageToCanvas($event)" (figureToCanvas)="addFigure($event)"></catalogue-subnav>
        <div class="display-area" [ngClass]="{'display-area-shrink': sideNavStatus}">
            <h5 class="page-title">{{pageTitle|uppercase}}</h5>

            <section class="pt-0 p-3 mb-3">
                <div>
                    <div class="row  d-flex flex-row">


                        <div class="col-12 label-box p-3">

                            <div class="d-flex flex-row" *ngIf="showSizeSettings">
                                <div class="d-flex flex-row">
                                    <div class="input-group input-group-sm mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Width</span>
                                        </div>
                                        <input type="number" width="50" (change)="changeDimention()"
                                            style="max-width:60px" class="form-control" [(ngModel)]="width" />
                                        <div class="input-group-append">
                                            <span class="input-group-text">Inch</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-2 mr-2 pt-1">X</div>
                                <div class="d-flex flex-row">
                                    <div class="input-group input-group-sm mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Height</span>
                                        </div>
                                        <input type="number" class="form-control" (change)="changeDimention()"
                                            style="max-width:60px" [(ngModel)]="height"
                                            aria-label="Amount (to the nearest dollar)">
                                        <div class="input-group-append">
                                            <span class="input-group-text">Inch</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mb-1">
                                <div class="row">
                                    <div class="mt-1">
                                        <span class="text-danger">*</span><input type="text" class="title-input"
                                            placeholder="Enter component title" [(ngModel)]="title" (click)="deselectCanvasObject()" />
    
                                    </div>
                                    
                                </div>
                               

                                <div class="d-flex flex-column mt-2 ">
                                    <!-- <small><i>Dimention: {{width}}"x{{height}}"</i> <i class="ft ft-edit"></i></small> -->
                                    <!-- <small><i>Zoom: {{canvas.getZoom()*100|number: '1.0-0'}}%</i></small> -->
                                    <div class="d-flex flex-row align-content-center">
                                        <!-- <small class="pt-1"><i>Zoom: </i></small> -->
                                        <!-- <input type="number" class="percentage-input" width="40"
                                            [(ngModel)]="canvasZoom" (change)="zoomChanged(canvasZoom)" />
                                        <small class="pt-1"><i>%</i></small> -->

                                        <!-- --------zoom slider----------- -->
                                        <label class="form-label">Zoom: </label>
                                        <input type="range" min="10" step=".1" max="150"
                                            class="form-range percentage-input mr-1" [(ngModel)]="canvasZoom"
                                            (input)="zoomChanged(canvasZoom)" />
                                        <input type="number" step="1" style="max-width:50px" width="30"
                                            (change)="zoomChanged(canvasZoom)" [(ngModel)]="canvasZoom" />
                                        <i>%</i>

                                    </div>
                                </div>

                            </div>


                            <!-- <div class="d-flex flex-column toolbar mt-5" *ngIf="canvas&&canvas.getActiveObject()&&canvas.getActiveObject().type=='textbox'">
                            <div role="group" class="btn-group mb-2">
                                <button class="btn btn-sm bg-light-secondary " [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='left'}" 
                                    (click)="updateTextAlignment('left')">
                                    <i class="ft-align-left font-medium-2"></i>
                                </button>
                                <button class="btn btn-sm bg-light-secondary" 
                                    [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='center'}" 
                                    (click)="updateTextAlignment('center')">
                                    <i class="ft-align-center font-medium-2"></i>
                                </button>
                                <button class="btn btn-sm bg-light-secondary" 
                                    [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='right'}" 
                                    (click)="updateTextAlignment('right');">
                                    <i class="ft-align-right font-medium-2"></i>
                                </button>
                                
                                <button class="btn btn-sm bg-light-secondary" 
                                [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='justify'}" 
                                (click)="updateTextAlignment('justify')">
                                <i class="ft-align-justify font-medium-2"></i>
                                 </button>
                                
                            </div>
            
                            <div role="group" class="btn-group mb-2 ml-1">
                                <button type="button" class="btn btn-sm bg-light-secondary" 
                                    data-toggle="tooltip" 
                                    data-placement="top" 
                                    data-original-title="Make font bold" 
                                    [ngClass]="{'btn-active':canvas.getActiveObject().fontWeight=='bold'}"
                                    (click)="elementBold()"
                                    >
                                    <i class="ft-bold font-small-3"></i>
                                </button>
                                <button type="button" class="btn btn-sm bg-light-secondary"
                                    [ngClass]="{'btn-active':canvas.getActiveObject().fontStyle=='italic'}"
                                    (click)="elementItalic()"
                                >
                                    <i class="ft-italic font-small-3"></i>
                                </button>
                                <button type="button" class="btn btn-sm bg-light-secondary"
                                    [ngClass]="{'btn-active':canvas.getActiveObject().underline==true}"
                                    (click)="elementUnderline()"
                                >
                                    <i class="ft-underline font-small-3"></i>
                                </button>
                            </div>
            
            
            
                            <div class="font-size d-flex mb-2" >
                                <div class="mr-1 font-small-3" style="width:100px">Font size </div>
                                <div class="mr-1 font-small-3"><input type="number" [(ngModel)]="canvas.getActiveObject().fontSize" (change)="canvas.renderAll()"/></div>
                            </div>
            
                            <div role="group" class="btn-group mb-2 ml-1">
                                <button type="button" class="btn btn-sm bg-light-secondary" data-toggle="tooltip" data-placement="top" data-original-title="Delete" (click)="deleteElement()" >
                                    <i class="ft-trash-2 font-small-3"></i> Delete
                                </button>
                            </div>
                        </div> -->
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="toolbar-box" >

                                            <div class="d-flex toolbar" *ngIf="canvas&&!canvas.getActiveObject()">
                                                <div role="group" class="btn-group ml-1">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <!-- {{canvas.backgroundColor}} -->
                                                            <div class="change-me bg-color-btn" 
                                                                [style.background-color]="canvas.backgroundColor"
                                                                [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                                                                [cpPositionRelativeToArrow]="true" [cpOKButton]="true"
                                                                [cpCancelButton]="true" [cpSaveClickOutside]="true"
                                                                [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                                                                [(colorPicker)]="canvasBgColor"
                                                                (cpSliderChange)="canvasBgChange($event)"
                                                                (colorPickerChange) = "canvasPresetBgChange($event)">
                                                            </div>
                                                        </div>
                                                    </div>
            
            
                                                </div>
                                                <div class="btn-group  ml-4"  *ngIf = "componentType == 'page'">
                                                    <a href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="addgrid">
                                                      Add Grid Layout
                                                      <i class="fa fa-angle-down"></i>
                                                    </a>
                                                    <div class="dropdown-menu">
                
                                                        <div class="px-2 py-2">
                                                            <row-col-selector [rowColCount] = "_rowcolCount" (selRowColCount) = "addGrid($event)" ></row-col-selector>
                                                        </div>
                                             
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex toolbar" *ngIf="canvas&&canvas.getActiveObject()">
        
                                           
        
                                                <div role="group" class="btn-group"
                                                    *ngIf="canvas.getActiveObject().type=='textbox'">
                                                    <button class="btn btn-sm bg-light-secondary "
                                                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='left'}"
                                                        (click)="canvas.getActiveObject().textAlign='left';canvas.renderAll()">
                                                        <i class="ft-align-left font-medium-2"></i>
                                                    </button>
                                                    <button class="btn btn-sm bg-light-secondary"
                                                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='center'}"
                                                        (click)="canvas.getActiveObject().textAlign='center';canvas.renderAll()">
                                                        <i class="ft-align-center font-medium-2"></i>
                                                    </button>
                                                    <button class="btn btn-sm bg-light-secondary"
                                                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='right'}"
                                                        (click)="canvas.getActiveObject().textAlign='right';canvas.renderAll()">
                                                        <i class="ft-align-right font-medium-2"></i>
                                                    </button>
            
                                                    <button class="btn btn-sm bg-light-secondary"
                                                        [ngClass]="{'btn-active':canvas.getActiveObject().textAlign=='justify'}"
                                                        (click)="canvas.getActiveObject().textAlign='justify';canvas.renderAll()">
                                                        <i class="ft-align-justify font-medium-2"></i>
                                                    </button>
            
                                                </div>
                                                <div role="group" class="btn-group ml-1"
                                                    *ngIf="canvas.getActiveObject().type=='textbox'">
            
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <button class="change-me btn btn-sm bg-light-secondary" canvas-container
                                                                [style.color]="canvas.getActiveObject().fill"
                                                                [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                                                                [cpPositionRelativeToArrow]="true" [cpOKButton]="true"
                                                                [cpCancelButton]="true" [cpSaveClickOutside]="true"
                                                                [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                                                                [(colorPicker)]="selectedColor"
                                                                (cpSliderChange)="changeColor($event)"
                                                                (colorPickerChange) = "changePresetColor($event)">
                                                                <i class="fa fa-font font-small-3"
                                                                    [style.color]="canvas.getActiveObject().fill"></i></button>
                                                        </div>
                                                    </div>
            
            
                                                </div>
            
                                                <div role="group" class="btn-group ml-1"
                                                    *ngIf="canvas.getActiveObject().type=='textbox'">
                                                    <button type="button" class="btn btn-sm bg-light-secondary"
                                                        data-toggle="tooltip" data-placement="top"
                                                        data-original-title="Make font bold"
                                                        [ngClass]="{'btn-active':canvas.getActiveObject().fontWeight=='bold'}"
                                                        (click)="canvas.getActiveObject().fontWeight=='bold'?canvas.getActiveObject().fontWeight='normal':canvas.getActiveObject().fontWeight='bold';canvas.renderAll()">
            
                                                        <i class="ft-bold font-small-3"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-sm bg-light-secondary"
                                                        [ngClass]="{'btn-active':canvas.getActiveObject().fontStyle=='italic'}"
                                                        (click)="canvas.getActiveObject().fontStyle=='italic'?canvas.getActiveObject().fontStyle='normal':canvas.getActiveObject().fontStyle='italic';canvas.renderAll()">
                                                        <i class="ft-italic font-small-3"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-sm bg-light-secondary"
                                                        [ngClass]="{'btn-active':canvas.getActiveObject().underline==true}"
                                                        (click)="elementUnderline()">
                                                        <i class="ft-underline font-small-3"></i>
                                                    </button>
                                                </div>
            
                                                <div class="d-flex input-group input-group-sm font-size-group ml-1"
                                                    *ngIf="canvas.getActiveObject().type=='textbox'">
                                                    <div class="input-group-prepend">
                                                        <button class="btn btn-sm bg-light-secondary" type="button"
                                                            (click)="canvas.getActiveObject().fontSize>1?canvas.getActiveObject().fontSize=canvas.getActiveObject().fontSize-1:1;canvas.renderAll()"><i
                                                                class="ft ft-minus"></i></button>
                                                    </div>
                                                    <input type="text" width="30" class="form-control bg-light-secondary"
                                                        [(ngModel)]="canvas.getActiveObject().fontSize"
                                                        (change)="canvas.renderAll()" placeholder="Font size"
                                                        aria-label="Recipient's username" aria-describedby="basic-addon2">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-sm bg-light-secondary" type="button"
                                                            (click)="canvas.getActiveObject().fontSize=canvas.getActiveObject().fontSize+1;canvas.renderAll()"><i
                                                                class="ft ft-plus"></i></button>
                                                    </div>
                                                </div>
                                                <div role="group" class="btn-group ml-1" *ngIf="canvas.getActiveObject().type=='rect' ||  canvas.getActiveObject().type=='triangle' ||
                                                    canvas.getActiveObject().type=='circle'">
                                                    <button class="change-me btn btn-sm bg-light-secondary" canvas-container
                                                        [style.color]="canvas.getActiveObject().fill" [cpPosition]="'bottom'"
                                                        [cpPositionOffset]="'50%'" [cpPositionRelativeToArrow]="true"
                                                        [cpOKButton]="true" [cpCancelButton]="true" [cpSaveClickOutside]="true"
                                                        [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                                                        [(colorPicker)]="selectedColor" (cpSliderChange)="changeColor($event)"
                                                        (colorPickerChange) = "changePresetColor($event)">
                                                        <i class="fa fa-square font-small-3"
                                                            [style.color]="canvas.getActiveObject().fill"></i></button>
                                                </div>
            
                                                <div role="group" class="btn-group mb-2 ml-1">
                                                    <button type="button" class="btn btn-sm bg-light-secondary"
                                                        data-toggle="tooltip" data-placement="top" data-original-title="Delete"
                                                        (click)="deleteElement()">  
                                                        <i class="ft-trash-2 font-small-3"></i>
                                                    </button>
            
                                                </div>
            
            
                                                <div role="group" class="btn-group ml-1">
                                                    <button type="button" class="btn btn-sm bg-light-secondary"
                                                        data-toggle="tooltip" data-placement="top"
                                                        data-original-title="Bring forward"
                                                        (click)="canvas.getActiveObject().bringForward()">
                                                        <img src="assets/img/catalogue/bring-forward.png" width="20" />
                                                    </button>
                                                    <button type="button" class="btn btn-sm bg-light-secondary"
                                                        [ngClass]="{'btn-active':canvas.getActiveObject().underline==true}"
                                                        (click)="canvas.getActiveObject().sendBackwards()">
                                                        <img src="assets/img/catalogue/send-backward.png" width="20" />
                                                    </button>
                                                </div>
                                            </div>  
                                        </div> 
                                    </div>
                                    <div class="col-md-6" >
                                        <div class="status-toggle-btn" *ngIf = "componentType == 'product'">
                                            <label class="title">Page Preview: </label>
                                            <div class="custom-switch custom-switch-activator custom-switch-success custom-control">
                                                <input type="checkbox" name="page_view"
                                                    class="custom-control-input"  id="page_view"
                                                    [(ngModel)] = "showPageView" (change)="onChangePreview()">
                                                <label class="custom-control-label" for="page_view">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                
                          
                                </div>
                              

                              

                            

                            <!-- <canvas id="canvas" cdkDropList
                                            style="border: 1px gray solid;"></canvas> -->

                            <!-- Width:{{canvas.width}} | Height:{{canvas.height}} | Zoom:{{canvas.getZoom()}} -->
                            <!-- {{selectedColor}} -->

                            

                            <div class="row" *ngIf = "componentType == 'product'">

                                <div [ngClass]="{'col-md-6': width <= 2, 'col-md-12': width > 2}">
                    
                                    <div class="col-md-6">
                                        <canvas id="canvas" cdkDropList
                                            style="border: 1px gray solid;"></canvas>
                                    </div>
                                </div>

                                <div *ngIf='showPreview  && !showPageView'
                                    [ngClass]="{'col-md-6': width <= 2 , 'col-md-12 mt-2': width > 2 }">
                                    <div class="col-md-6">
                                        <canvas #canvas2 cdkDropList
                                            style="border: 1px gray solid;"></canvas>
                                    </div>
                                </div>
                                <div *ngIf='showPreview  && showPageView'
                                [ngClass]="{'col-md-6': width <= 2 , 'col-md-12 mt-2': width > 2 }">
                                <div class="col-md-6">
                                <div class="pagepreview">
                                    <canvas #pagecanvas cdkDropList
                                            style="border: 1px gray solid;"></canvas>
                                </div>
                            </div>
                        </div>   
                        </div>
                            <div class="row" *ngIf = "componentType == 'page'">
                                
                                <div [ngClass]="{'col-md-6':  canvasZoom < 74 , 'col-md-12':  canvasZoom > 74}">
                                    <div class="col-md-6">
                                        <canvas id="canvas" cdkDropList 
                                            style="border: 1px gray solid;"></canvas>
                                    </div>
                                </div>

                                <div *ngIf='showPreview'
                                    [ngClass]="{'col-md-6':  canvasZoom < 74 , 'col-md-12 mt-2':  canvasZoom > 74}">
                                    <div class="col-md-6">

                                        <canvas #canvas2 cdkDropList
                                            style="border: 1px gray solid;"></canvas>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf = "componentType == 'button'">
                                <div class="col-md-6">
                                    <canvas id="canvas" cdkDropList
                                        style="border: 1px gray solid;"></canvas>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div class="mt-5 float-right">
                    <!-- <a class="btn btn-secondary mr-2" [routerLink]="['/inventory/labels/']">Cancel</a> -->
                    <!-- <button class="btn btn-secondary mr-2">Print</button> -->
                    <a class="btn btn-secondary mr-2" (click)="onClickCancel()">Cancel</a>
                    <button class="btn btn-primary" (click)="save()">{{labelId>0?'Update':'Create'}}</button>
                </div>
            </section>
        </div>
    </main>
</div>